import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import {
  BackButtonIcon,
  CloseIcon,
  FilterOutlinedIcon,
  SearchIcon,
} from "../../IconsComp/Icons";
import commonCssStyle from "../../UI/CommonCss.module.css";
import styles from "../../Screens/Dashboard/ContentPage/ContentPage.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCandidatesFilterAction } from "../../../Reusable/Store/Action/CandidatesAction";
import { connect } from "react-redux";
import commonStyleCss from "../../UI/CommonCss.module.css";
import Filter from "../AddCandidatesComp/candidateDetailsTable/Filter";
import { postAssignedEmployeeAction } from "../../../Reusable/Store/Action/RecruitmentAction";
import { getCandidateAssignedToRecruitmentByIdAction } from "../../../Reusable/Store/Action/RecruitmentAction";
import BreadcrumbForRequirement from "../Requirement/BreadcrumbForRequirement";
import BreadcrumbForRecruitment from "./BreadcrumbForRecruitment";
import moment from "moment";
import CandidateForPoolFilter from "./CandidateForPoolFilter";

function CandidateForPool(props) {
  const [dataSelected, setDataSelected] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [cfilter, setCFilter] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageNo, setPageNo] = useState("1");
  const [idForSkills, setIdForSkills] = useState("");
  const [tagsModalOpen, setTagsModalOpen] = useState(false);

  // const [tableData, setTableData] = useState([]);
  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      const data = {
        current_page: 1,
      };
      // props.getCandidateFilterHandler(data);
      // if (
      //   location.state &&
      //   location.state.from &&
      //   location.state.from === "clientCompany"
      // ) {
      //   props.getFectchCandidateHandler(location.state.id.id);
      // } else {
      //   props.getFectchCandidateHandler(location.state.id);
      // }
      props.getFectchCandidateHandler(location.state.id);
    }
    return () => {
      unsubscribed = true;
    };
    // setTableData(data);
  }, []);

  const obj = JSON.parse(localStorage.getItem("candidateListForPool"));

  useEffect(() => {
    if (obj) {
      props.getCandidateFilterHandler(obj);
    } else {
      props.getCandidateFilterHandler({ current_page: 1 });
    }
  }, []);

  useEffect(() => {
    if (props.candidateAssignedRequirement) {
      setSelectedRowKeys(
        props.candidateAssignedRequirement.map((data) => {
          return data.id;
        })
      );

      // let data = props.candidatesFilterData.data.filter((data) =>
      //   props.candidateAssignedRequirement.includes(data.id)
      // );
    }
  }, [props.candidateAssignedRequirement]);
  // function onSearch(val) {
  //   if (val.target.value.length <= 0) {
  //     // setTableData(data);
  //     return;
  //   }

  //   let datas = data.find((d) => {
  //     // return d.name == val.target.value;
  //   });

  //   // setTableData(datas);
  // }

  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {

  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === "Disabled User",
  //     // Column configuration not to be checked
  //     name: record.name,
  //   }),
  // };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys, ...newSelectedRowKeys]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const closeTagModalHandler = () => {
    setTagsModalOpen(false);
  };

  const dateFormate =
    props.settings && props.settings.dateFormat
      ? props.settings.dateFormat
      : "DD/MM/YYYY";

  const submitData = () => {
    const val = {
      type: "pool",
      candidates: selectedRowKeys,
      jobId: location.state.id,
    };
    props.postAssignCandidateHandler(val, (success) => {
      if (success) {
        props.getCandidateFilterHandler({ current_page: pageNo });
        props.getFectchCandidateHandler(location.state.id);
      }
    });
  };

  const clearData = () => {
    setSelectedRowKeys("");
    setDataSelected("");
    const val = {
      type: "pool",
      candidates: [],
      jobId: location.state.id,
    };
    props.postAssignCandidateHandler(val, (success) => {});
  };
  function showFilterHandler() {
    setOpenFilter(true);
  }
  const closeFilterHandler = () => {
    setOpenFilter(false);
  };

  const candidateFilterHandleer = (val) => {
    setCFilter(val);
    localStorage.setItem("candidateListForPool", JSON.stringify(val));
    props.getCandidateFilterHandler(val, (success) => {
      if (success) {
        closeFilterHandler();
      }
    });
  };

  const tagsColumn = [
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Skills
        </p>
      ),
      dataIndex: "",
      render: (text, render) => {
        return (
          <>
            <p
              className={`${commonCssStyle.tableData} ${commonStyleCss.marginBottomRemove}`}
            >
              {text ? text : ""}
            </p>
          </>
        );
      },
    },
  ];

  const columns = [
    {
      dataIndex: "id",
      render: (text, render) => (
        <>
          <Checkbox
            checked={selectedRowKeys.find((x) => x === text)}
            onChange={(val) => {
              if (val.target.checked) {
                setSelectedRowKeys([...selectedRowKeys, text]);
              } else {
                let index = selectedRowKeys.indexOf(text);
                if (index != -1) {
                  setSelectedRowKeys(
                    selectedRowKeys.filter((data) => data !== text)
                  );
                }
              }
            }}
          />
        </>
      ),
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Name
        </p>
      ),
      dataIndex: "name",
      render: (text) => (
        <p
          style={{
            color: "var(--primaryColor)",
            fontFamily: "Helvetica,ProductSans",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "17px",
            margin: "0px",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Email and Number
        </p>
      ),
      dataIndex: "phone",
      render: (data, render) => {
        return (
          <>
            <p
              className={`${commonCssStyle.tableData} ${commonStyleCss.marginBottomRemove}`}
            >
              {data ? data : ""}
            </p>
            <p
              className={`${commonCssStyle.tableData} ${commonStyleCss.marginBottomRemove}`}
            >
              {render && render.email ? render.email : "NA"}
            </p>
          </>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Skills
        </p>
      ),
      dataIndex: "tags",
      render: (data) => {
        return (
          // <p
          //   className={`${commonCssStyle.tableData} ${commonStyleCss.marginBottomRemove}`}
          // >
          //   {data ? data : ""}
          // </p>
          <>
            {data && data.length > 1 ? (
              <p
                className={`${commonCssStyle.tableData}
          ${commonStyleCss.marginBottomRemove} ${commonCssStyle.coursorPointer}`}
                onClick={() => {
                  setIdForSkills(data);
                  setTagsModalOpen(true);
                }}
              >
                {data.length > 1 ? (
                  <> &#40; {"+" + data.length} &#41; </>
                ) : (
                  data
                )}
              </p>
            ) : (
              <p
                className={`${commonCssStyle.tableData}
          ${commonStyleCss.marginBottomRemove}`}
              >
                {data}
              </p>
            )}
          </>
        );
      },
    },
    // {
    //   title: (
    //     <p
    //       className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
    //     >
    //       Email id
    //     </p>
    //   ),
    //   dataIndex: "email",
    //   render: (data) => {
    //     return (
    //       <p
    //         className={`${commonCssStyle.tableData} ${commonStyleCss.marginBottomRemove}`}
    //       >
    //         {data ? data : ""}
    //       </p>
    //     );
    //   },
    // },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Source
        </p>
      ),
      dataIndex: "leadSource",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Created Date
        </p>
      ),
      dataIndex: "createdAt",
      render: (data) => {
        return (
          <p
          style={{whiteSpace:"nowrap"}}
            className={`${commonCssStyle.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? moment(data).format(dateFormate) : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Status
        </p>
      ),
      render: (text, render, i) =>
        props.candidateAssignedRequirement &&
        props.candidateAssignedRequirement.find(
          (data) => data.id === render.id
        ) && (
          // selectedRowKeys &&
          // selectedRowKeys.find((data) => data === render.id) && (
          <Tag
            style={{
              padding: "5px 0px",
              backgroundColor: "var(--secondary-card-color)",
              color: "var(--primaryColor)",
              width: "73px",
              textAlign: "center",
            }}
          >
            Added
          </Tag>
        ),
      // ),
    },
  ];

  const handleProvinceChange = (e) => {
    let candidateListForPoolFilter = JSON.parse(
      localStorage.getItem("candidateListForPool")
    );
    if (candidateListForPoolFilter) {
      if (e === "Name_ASC") {
        props.getCandidateFilterHandler({
          ...cfilter,
          sortBy: "name",
          current_page: 1,
          sortOrder: "ASC",
          ...candidateListForPoolFilter,
        });
        setCFilter({
          ...cfilter,
          sortBy: "name",
          sortOrder: "ASC",
          ...candidateListForPoolFilter,
        });
      } else if (e === "Name_DESC") {
        props.getCandidateFilterHandler({
          ...cfilter,
          sortBy: "name",
          current_page: 1,
          sortOrder: "DESC",
          ...candidateListForPoolFilter,
        });
        setCFilter({
          ...cfilter,
          sortBy: "name",

          sortOrder: "DESC",
          ...candidateListForPoolFilter,
        });
      } else if (e === "Applied_ASC") {
        props.getCandidateFilterHandler({
          ...cfilter,
          sortBy: "appliedDate",
          current_page: 1,
          sortOrder: "ASC",
          ...candidateListForPoolFilter,
        });
        setCFilter({
          ...cfilter,
          sortBy: "appliedDate",

          sortOrder: "ASC",
          ...candidateListForPoolFilter,
        });
      } else if (e === "Applied_DESC") {
        props.getCandidateFilterHandler({
          ...cfilter,
          sortBy: "appliedDate",
          current_page: 1,
          sortOrder: "DESC",
          ...candidateListForPoolFilter,
        });
        setCFilter({
          ...cfilter,
          sortBy: "appliedDate",

          sortOrder: "DESC",
          ...candidateListForPoolFilter,
        });
      } else if (e === "Last_ASC") {
        props.getCandidateFilterHandler({
          ...cfilter,
          sortBy: "lastUpdated",
          current_page: 1,
          sortOrder: "ASC",
          ...candidateListForPoolFilter,
        });
        setCFilter({
          ...cfilter,
          sortBy: "lastUpdated",

          sortOrder: "ASC",
          ...candidateListForPoolFilter,
        });
      } else if (e === "Last_DESC") {
        props.getCandidateFilterHandler({
          ...cfilter,
          sortBy: "lastUpdated",
          current_page: 1,
          sortOrder: "DESC",
          ...candidateListForPoolFilter,
        });
      }
      setCFilter({
        ...cfilter,
        sortBy: "lastUpdated",
        sortOrder: "DESC",
        ...candidateListForPoolFilter,
      });
    } else {
      if (e === "Name_ASC") {
        props.getCandidateFilterHandler({
          ...cfilter,
          sortBy: "name",
          current_page: 1,
          sortOrder: "ASC",
        });
        setCFilter({ ...cfilter, sortBy: "name", sortOrder: "ASC" });
      } else if (e === "Name_DESC") {
        props.getCandidateFilterHandler({
          ...cfilter,
          sortBy: "name",
          current_page: 1,
          sortOrder: "DESC",
        });
        setCFilter({
          ...cfilter,
          sortBy: "name",

          sortOrder: "DESC",
        });
      } else if (e === "Applied_ASC") {
        props.getCandidateFilterHandler({
          ...cfilter,
          sortBy: "appliedDate",
          current_page: 1,
          sortOrder: "ASC",
        });
        setCFilter({
          ...cfilter,
          sortBy: "appliedDate",

          sortOrder: "ASC",
        });
      } else if (e === "Applied_DESC") {
        props.getCandidateFilterHandler({
          ...cfilter,
          sortBy: "appliedDate",
          current_page: 1,
          sortOrder: "DESC",
        });
        setCFilter({
          ...cfilter,
          sortBy: "appliedDate",

          sortOrder: "DESC",
        });
      } else if (e === "Last_ASC") {
        props.getCandidateFilterHandler({
          ...cfilter,
          sortBy: "lastUpdated",
          current_page: 1,
          sortOrder: "ASC",
        });
        setCFilter({
          ...cfilter,
          sortBy: "lastUpdated",

          sortOrder: "ASC",
        });
      } else if (e === "Last_DESC") {
        props.getCandidateFilterHandler({
          ...cfilter,
          sortBy: "lastUpdated",
          current_page: 1,
          sortOrder: "DESC",
        });
      }
      setCFilter({ ...cfilter, sortBy: "lastUpdated", sortOrder: "DESC" });
    }
  };

  const fetchRecords = (page) => {
    setPageNo(page);
    let candidateListForPoolLocal = JSON.parse(
      localStorage.getItem("candidateListForPool")
    );

    if (candidateListForPoolLocal) {
      const val = {
        ...cfilter,
        ...props.candidatesFilterData,
        ...candidateListForPoolLocal,
        current_page: page,
      };
      localStorage.setItem(
        "candidateListForPool",
        JSON.stringify({ ...candidateListForPoolLocal, current_page: page })
      );
      props.getCandidateFilterHandler(val);
    } else {
      const val = {
        ...cfilter,
        ...props.candidatesFilterData,
        current_page: page,
      };
      localStorage.setItem(
        "candidateListForPool",
        JSON.stringify({ current_page: page })
      );

      props.getCandidateFilterHandler(val);
    }
  };
  return (
    <>
      <Row className={commonCssStyle.breadcrumbBottomMargin}>
        <Col md={24}>
          {location.state.onPathname === "/JobDetailPage" ? (
            <BreadcrumbForRequirement
              name={location.state.name}
              slide={[1, 2, 3]}
            />
          ) : (
            <BreadcrumbForRecruitment
              name={location.state.name.title}
              recruitment={location.state}
              slide={[1, 2, 4]}
            />
          )}
        </Col>
      </Row>
      <Card style={{ padding: "10px 40px", marginBottom: "36px" }}>
        <Row align="middle">
          <Col md={24}>
            <Row align="middle" justify="space-between">
              <Col>
                <Row align="middle">
                  <Col>
                    {/* <Link
                      to="/recruitment/recruitmentJobdetails"
                      state={{ id: location.state.id, activeTabKey: "2" }}
                    > */}
                    <span
                      className={commonCssStyle.coursorPointer}
                      onClick={() => {
                        if (location.state.onPathname === "/JobDetailPage") {
                          navigate("/JobDetailPage", {
                            state: { id: location.state.id, activeTabKey: "4" },
                          });
                        } else {
                          navigate("/recruitment/recruitmentJobdetails", {
                            state: {
                              id: location.state.id,
                              // activeTabKey: "2",
                              activeTabKey: "addCandidate",
                              recuritmentJobDetailsName:
                                location.state.name.title,
                            },
                          });
                        }
                      }}
                    >
                      <BackButtonIcon color={"var(--icons-color)"} />
                    </span>
                    {/* </Link> */}
                  </Col>
                  <Col>
                    <h1
                      style={{ marginRight: "5px" }}
                      className={`${commonCssStyle.mainHeadingText} ${commonCssStyle.inttitle} ${commonCssStyle.marginBottomRemove}`}
                    >
                      Candidate List &#40;{" "}
                      {props.candidatesFilterData &&
                      props.candidatesFilterData.total
                        ? props.candidatesFilterData.total
                        : "0"}{" "}
                      &#41;
                    </h1>
                  </Col>
                </Row>
              </Col>
              {/* <Col md={10}>
                    <Input.Search
                      placeholder="Search Anything.."
                      //   onSearch={onSearch}
                      // onChange={onSearch}
                      allowClear={true}
                      className={`searchBarPlaceHoderClr `}
                      prefix={<SearchIcon color={"var(--primaryColor)"} />}
                      style={{
                        borderRadius: 50,
                        width: "100%",
                        // height: "42px",
                        fontSize: "20px",
                      }}
                    />
                  </Col> */}
              <Col>
                <Button
                  onClick={showFilterHandler}
                  className={`${commonStyleCss.filterButton} ${commonStyleCss.filterButtonBorder}`}
                >
                  <Row style={{ gap: "11px" }}>
                    <Col>
                      <div style={{ transform: "translateY(2px)" }}>
                        <FilterOutlinedIcon color={"var(--primaryColor)"} />
                      </div>
                    </Col>
                    <Col>
                      <p
                        className={`${commonCssStyle.filterAndSortTextStyle} ${commonCssStyle.marginBottomRemove}`}
                      >
                        Filter
                      </p>
                    </Col>
                  </Row>
                </Button>
                <Button
                  style={{
                    height: "40px",
                  }}
                  className={` ${commonStyleCss.sortBtn} ${commonStyleCss.sortBtnBorder}`}
                >
                  <Row justify="space-between">
                    <Col>
                      <p style={{ transform: "translateY(2px)" }}>Sort By</p>
                    </Col>
                    <Col>
                      <Select
                        style={{ width: "100%", transform: "translateY(3px)" }}
                        onChange={handleProvinceChange}
                        bordered={false}
                        placeholder={
                          <p
                            style={{
                              margin: "0px",
                              color: "var(--primaryColor)",
                            }}
                          >
                            Select
                          </p>
                        }
                        size="small"
                        className={`${commonStyleCss.filterDrop}  selectInput sortBySelectStyle selectTag selectTag1`}
                      >
                        <Select.Option key="1" value="Name_ASC">
                          Name Ascending
                        </Select.Option>
                        <Select.Option key="2" value="Name_DESC">
                          Name Descending
                        </Select.Option>
                        {/* <Select.Option key="3" value="Applied_ASC">
                          Applied Date Ascending
                        </Select.Option>
                        <Select.Option key="4" value="Applied_DESC">
                          Applied Date Descending
                        </Select.Option>
                        <Select.Option key="5" value="Last_ASC">
                          Last Updated Ascending
                        </Select.Option>
                        <Select.Option key="6" value="Last_DESC">
                          Last Updated Descending
                        </Select.Option> */}
                      </Select>
                    </Col>
                  </Row>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <Row>
        <Col md={24}>
          <Col style={{ marginTop: "20px" }} xs={24}>
            <Table
              scroll={{ x: "auto" }}
              pagination={{
                size: "small",
                current:
                  props.candidatesFilterData &&
                  props.candidatesFilterData.current_page,
                pageSize:
                  props.candidatesFilterData &&
                  props.candidatesFilterData.per_page,
                total:
                  props.candidatesFilterData &&
                  props.candidatesFilterData.total,
                showSizeChanger: false,
                onChange: (props) => {
                  fetchRecords(props);
                },
              }}
              rowKey="id"
              columns={columns}
              // dataSource={[
              //   props.candidatesFilterData.data,
              //   props.candidateAssignedRequirement,
              // ]}
              dataSource={props.candidatesFilterData.data}
            />
          </Col>
        </Col>
        <Col flex={1}>
          <Row style={{ gap: "10px", float: "right" }}>
            {/* <Col> <Button
             type="default"
             className={commonCssStyle.ButtonForNormalSecondary}
             onClick={clearData}
            >Clear</Button></Col> */}
            <Col>
              <Button
                type="primary"
                onClick={submitData}
                className={commonCssStyle.ButtonForNormalPrimary}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <CandidateForPoolFilter
        candidateFilterHandleer={candidateFilterHandleer}
        onOpenFilter={openFilter}
        candidateFilterData={props.candidatesFilterData}
        onCloseFilter={closeFilterHandler}
        settindsData={props.settindsData}
      />

      <Modal
        title={
          <Row>
            <Col md={20}>
              <p
                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.popUpHeadingText} ${commonCssStyle.textBreak}`}
              >
                Skills
              </p>
            </Col>
            <Col md={4}>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  closeTagModalHandler();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
        closable={false}
        open={tagsModalOpen}
        onCancel={closeTagModalHandler}
        footer={false}
      >
        <Table
          style={{ marginTop: "-20px" }}
          scroll={{ x: "auto" }}
          className="AssignRecruiterTable"
          columns={tagsColumn}
          dataSource={idForSkills && idForSkills}
          pagination={false}
        />
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    candidatesFilterData: state.candidates.candidatesFilter,
    candidateAssignedRequirement:
      state.recruitment.candidateAssignedRequirement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCandidateFilterHandler: (val, callback) => {
      dispatch(getCandidatesFilterAction(val, callback));
    },
    postAssignCandidateHandler: (val, callback) => {
      dispatch(postAssignedEmployeeAction(val, callback));
    },
    getFectchCandidateHandler: (id) => {
      dispatch(getCandidateAssignedToRecruitmentByIdAction(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CandidateForPool);
