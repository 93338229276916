import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  permissions: [],
  permission: null,
};
export const MasterpermissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    getPermissionsSlice(state, action) {
      state.permissions = action.payload.result;
    },
    postPermissionSlice(state, action) {
      state.permissions = [...state.permissions, action.payload.result];
    },
    getPermissionSlice(state, action) {
      state.permission = action.payload.result;
    },
    
  },
});
export const {
  getPermissionSlice,getPermissionsSlice,postPermissionSlice
} = MasterpermissionSlice.actions;
export default MasterpermissionSlice.reducer;
