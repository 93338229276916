import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Reusable/Store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export const StepsTour = [
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Search Anything
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Search Anything</p>,
    target: "#Search-anything-bar",
    placement: "bottom",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>Help</p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Help</p>,
    target: "#headers-help-icon",
    placement: "bottom",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Notification
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Notification</p>,
    target: "#headers-notification-icon",
    placement: "bottom",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Profile
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Profile</p>,
    target: "#headers-profile-icon",
    placement: "bottom",
  },
  {
    // title: (
    //   <p
    //     style={{

    //       color: "var(--Joyride-Clr)",
    //       textAlign: "initial",
    //     }}
    //   >
    //     Dashboard
    //   </p>
    // ),
    content: (
      <p style={{ marginBottom: "0px", color: "var(--Joyride-Clr)" }}>
        Dashboard Page
      </p>
    ),
    target: "#Dashboard-sidebar-icon",
    placement: "right-end",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        My Task
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>My task</p>,
    target: ".Dashboard-myTask-class",
    placement: "bottom",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        My Interview
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>My interview</p>,
    target: ".Dashboard-myinterview",
    placement: "bottom",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        My Joinee
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>My Joinee</p>,
    target: "#Dashboard-myjoinee",
    placement: "bottom",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Candidate Summary
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Candidate Summary</p>,
    target: "#Dashboard-candidateSummary",
    placement: "bottom",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Job Summary
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Job Summary</p>,
    target: "#Dashboard-jobSummary",
    placement: "bottom",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Task Aging
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Task Aging</p>,
    target: "#Dashboard-taskAging",
    placement: "bottom",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Interview Aging
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Interview Aging</p>,
    target: "#Dashboard-interviewAging",
    placement: "bottom",
  },
  //end of dashborad
  //recruitment start

  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Recruitment
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Recruitment Page</p>,
    target: "#Recruitment-sidebar-icon",
    placement: "right",
  },
  // ];
  // export const StepsTour1 = [
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Recruitment Filter
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Recruitment Filter </p>,
    target: "#Recruitment-Filter-Btn",
    placement: "bottom",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Recruitment SortBy
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Recruitment SortBy</p>,
    target: "#RecruitmentSortByBtn",
    placement: "right",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Recruitment Add new Job
      </p>
    ),
    content: (
      <p style={{ color: "var(--Joyride-Clr)" }}>Recruitment Add new Job</p>
    ),
    target: "#RecruitmentAddNewJobBtn",
    placement: "right",
  },

  //end of recruitment page
  //start of task and calendar
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Task and Calendar
      </p>
    ),
    content: (
      <p style={{ color: "var(--Joyride-Clr)" }}>Task and Calendar Page</p>
    ),
    target: "#Task-sidebar-icon",
    placement: "right",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Task and Calendar Filter
      </p>
    ),
    content: (
      <p style={{ color: "var(--Joyride-Clr)" }}>Task and Calendar Filter</p>
    ),
    target: "#taskAndCalendarFilter",
    placement: "right",
  },
  //end of task and calendar
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Candidate
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Candidate Page</p>,
    target: "#Candidate-sidebar-icon",
    placement: "right",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Requirement
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Requirement Page</p>,
    target: "#Requirement-sidebar-icon",
    placement: "right",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Client Company
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Client Company Page</p>,
    target: "#Client-sidebar-icon",
    placement: "right",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Employee
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Employee Page</p>,
    target: "#Employee-sidebar-icon",
    placement: "right",
  },
  {
    title: (
      <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
        Settings
      </p>
    ),
    content: <p style={{ color: "var(--Joyride-Clr)" }}>Settings Page</p>,
    target: "#Settings-sidebar-icon",
    placement: "right",
  },
  //   {
  //     title: (
  //       <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
  //         Candidates Filter
  //       </p>
  //     ),
  //     content: <p style={{ color: "var(--Joyride-Clr)" }}>Candidates Filter</p>,
  //     target: "#candidates-table-filter-btn",
  //     placement: "bottom",
  //   },
  //   {
  //     title: (
  //       <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
  //         Candidates Sortby
  //       </p>
  //     ),
  //     content: <p style={{ color: "var(--Joyride-Clr)" }}>Candidates Sortby</p>,
  //     target: "#candidates-table-sortby-btn",
  //     placement: "bottom",
  //   },
  //   {
  //     title: (
  //       <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
  //         Data Export
  //       </p>
  //     ),
  //     content: (
  //       <p style={{ color: "var(--Joyride-Clr)" }}>
  //         Click this button to export Candidates
  //       </p>
  //     ),
  //     target: "#data-export-button",
  //     placement: "bottom",
  //   },
  //   {
  //     title: (
  //       <p style={{ color: "var(--Joyride-Clr)", textAlign: "initial" }}>
  //         Add Candidates
  //       </p>
  //     ),
  //     content: <p style={{ color: "var(--Joyride-Clr)" }}>Add Candidates</p>,
  //     target: "#candidates-table-AddCandidate-btn",
  //     placement: "bottom",
  //   },
];
