import { Breadcrumb } from "antd";
import { useNavigate } from "react-router";
import commonCssStyle from "../../../UI/CommonCss.module.css";
function BreadcrumbForRandR(props) {
  const navigate = useNavigate();
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <p
          onClick={() => {
            navigate("/");
          }}
          className={` ${commonCssStyle.breadcrumbText} ${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove}`}
        >
          Home
        </p>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <p
          onClick={() => {
            navigate("/reports/employee");
          }}
          className={` ${commonCssStyle.breadcrumbText} ${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove}`}
        >
          {props.page === 1
            ? `Employee Report (${props.name && props.name})`
            : "Employee Report"}
        </p>
      </Breadcrumb.Item>
      {props.page && props.page === 1 ? (
        <Breadcrumb.Item>
          <p
            className={` ${commonCssStyle.breadcrumbText} ${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove}`}
          >
            Employee Report
          </p>
        </Breadcrumb.Item>
      ) : (
        ""
      )}
    </Breadcrumb>
  );
}
export default BreadcrumbForRandR;
