import { Form, Select, Input } from "antd";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
function InOffice(props) {
  // const onChangeCountry = (val, data) => {
  //   props.stateData(data);
  // };
  // const onChangeState = (val, data) => {
  //   props.cityData(data);
  // };
  return (
    <>
      {/* <Form.Item
        label="Country"
        name="country"
        rules={[
          {
            required: false,
            message: "Please select country",
          },
        ]}
      >
        <Select
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={onChangeCountry}
          placeholder="Select country"
        >
          {props.onCountriesData &&
            props.onCountriesData.map((data, index) => {
              return (
                <Select.Option key={index} id={data.id} value={data.name}>
                  {data.name}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: false,
            message: "Please select state",
          },
        ]}
        label="State"
        name="state"
      >
        {props.onStateData && props.onStateData.length === 0 ? (
          <Input placeholder="Enter state" />
        ) : (
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Select State"
            onChange={onChangeState}
          >
            {props.onStateData &&
              props.onStateData.map((data, index) => {
                return (
                  <Select.Option key={index} id={data.id} value={data.name}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: false,
            message: "Please select city",
          },
        ]}
        label="City"
        name="city"
      >
        {props.onCityData && props.onCityData.length === 0 ? (
          <Input placeholder="Enter city" />
        ) : (
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Select City"
          >
            {props.onCityData &&
              props.onCityData.map((data, index) => {
                return (
                  <Select.Option key={index} id={data.id} value={data.name}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: false,
            message: "Please enter address",
          },
        ]}
        label="Office Address"
        name="address"
      >
        <Input.TextArea placeholder="Enter office address" />
      </Form.Item>
      <Form.Item
        label="Office Contact Number"
        name="phone"
        rules={[
          {
            required: false,
            message: "Please enter phone number",
          },
        ]}
      >
        <PhoneInput
          placeholder="Enter contact number"
          international
          className={`ant-input ant-cust-inputs antPhoneHeight`}
          defaultCountry="IN"
        />
      </Form.Item> */}
      <Form.Item label="Office Address" name="officeAddress">
        <Input.TextArea placeholder="Enter address" />
      </Form.Item>
      <Form.Item
        label="Point of Contact "
        name="pointOfContact"
        rules={[
          {
            required: false,
            message: "Please enter phone number",
          },
        ]}
      >
        <Input placeholder="Enter name and phone number" />
      </Form.Item>
    </>
  );
}
export default InOffice;
