// import { Badge, Calendar } from "antd";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useCallback } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { Button, Col, Modal, Popover, Row, Tooltip } from "antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import {
  getFilterCalendarAction,
  getTasksFilterAction,
  postTaskAndCalendarAction,
} from "../../../Reusable/Store/Action/TaskAndCalendarAction";
import overlap from "react-big-calendar/lib/utils/layout-algorithms/overlap";
import CalendarToolTip from "./CalendarToolTip";
import React from "react";
import ScheduleInterviewDrawer from "../AddCandidatesComp/CandidatesSummary/ScheduleInterviewDrawer";
import RegularTaskDrawer from "./RegularTaskDrawer";
import PermissionMessage from "../../UI/PermissionMessage";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import { checkPermissionReducer } from "../../../Reusable/Store/Slice/AuthPermissionsSlice";
import { useNavigate } from "react-router";
function TaskCalendarInterviews(props) {
  //onViewName varable is to find task page or interview page, it is comming from props
  const localizer = momentLocalizer(moment);
  const [myEvents, setEvents] = useState([]);
  const [modalName, setModalName] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [popOverStatus, setPopOverStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [taskDatas, setTaskDatas] = useState([]);
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  const dateFormat =
    props.settings && props.settings.dateFormat
      ? props.settings.dateFormat
      : "DD,MM,YYYY";

  // const navigator = useNavigate();
  // console.log(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));

  useEffect(() => {
    /*
     *calling the api when the page is loaded
     *
     */

    let unsubscribed = false;
    if (!unsubscribed) {
      // setEvents([]);
      if (props.filterStatus) {
        // setTaskDatas(props.tasksFilterData.data);
      } else {
        if (props.onViewName) {
          let localFilterDataForTask = localStorage.getItem("taskFilterData");
          let data = JSON.parse(localFilterDataForTask);
          if (data) {
            props.getTaskFilterHandler({
              viewType: "calendar",
              type: "task",
              ...data,
            });
          } else {
            props.getTaskFilterHandler({ viewType: "calendar", type: "task" });
          }
        } else {
          let localFilterDataForInterview = localStorage.getItem(
            "interviewFilterData"
          );
          let data = JSON.parse(localFilterDataForInterview);
          if (data) {
            props.getTaskFilterHandler({
              viewType: "calendar",
              type: "interview",
              ...data,
              interviewDate: data.interviewDate
                ? moment(data.interviewDate).format("YYYY-MM-DD")
                : "",
            });
          } else {
            props.getTaskFilterHandler({
              viewType: "calendar",
              type: "interview",
            });
          }
        }
      }
    }
    return () => {
      unsubscribed = true;
    };
  }, [props.taskStatusData, props.filterStatus]);

  const handleOpenChange = (newOpen) => {
    setPopOverStatus(newOpen);
  };

  useEffect(() => {
    /*
     *
     *use effect to store backend data to events variable
     */
    let unsubscribed = false;
    if (!unsubscribed) {
      let myEventsList = [];
      let datas = props.filterStatus
        ? props.tasksFilterData.data
          ? props.tasksFilterData.data
          : []
        : props.taskData;

      if (datas) {
        for (let i = 0; i < datas.length; i++) {
          // let date = new Date(
          //   moment(
          //     datas[i].startDate &&
          //       datas[i].startDate +
          //         " " +
          //         (datas[i].startTime ? datas[i].startTime : "8:00 AM")
          //   ).format("YYYY,MM,DD h:mm a")
          // );
          // let startD = moment(
          //   datas[i].startDate &&
          //     datas[i].startDate +
          //       " " +
          //       (datas[i].startTime ? datas[i].startTime : "8:00 AM")
          // ).format("YYYY,MM,DD HH:mm z");
          // console.log(+startD);
          // console.log(date);
          // console.log("-----");
          // console.log(new Date(2016, 2, 13, 0, 0, 0));
          // console.log("///////");

          myEventsList.push({
            id: i,
            title: (
              <Popover
                onOpenChange={handleOpenChange}
                overlayStyle={{ maxWidth: "100%" }}
                trigger="click"
                content={
                  <CalendarToolTip
                    onMouseLeaveDelay={() => {
                      setPopOverStatus(false);
                    }}
                    onViewName={props.onViewName}
                    data={datas[i]}
                  />
                }
                color={"var(--theme)"}
                key={i}
              >
                <p
                  style={{
                    color: "var(--headings-color)",
                    margin: "0px",
                    fontSize: "16px",
                  }}
                >
                  {datas[i].title}
                </p>
              </Popover>
            ),
            // allDay: true,
            // start: new Date(
            //   moment(
            //     datas[i].startDate &&
            //       datas[i].startDate +
            //         " " +
            //         (datas[i].startTime ? datas[i].startTime : "8:00 AM")
            //   ).format("YYYY,MM,DD h:mm a")
            // ),
            // end: new Date(
            //   moment(
            //     props.onViewName
            //       ? datas[i].dueDate
            //         ? datas[i].dueDate
            //         : datas[i].startDate
            //       : (datas[i].endDate ? datas[i].endDate : datas[i].startDate) +
            //           " " +
            //           (datas[i].endTime ? datas[i].endTime : "9:00 AM")
            //   ).format("YYYY,MM,DD h:mm a")
            // ),
            start: new Date(
              moment(
                datas[i].startDate &&
                  datas[i].startDate +
                    " " +
                    (datas[i].startTime ? datas[i].startTime : "8:00 AM"),
                "YYYY,MM,DD HH:mm z a"
              ).toISOString()
            ),
            end: new Date(
              moment(
                props.onViewName
                  ? datas[i].dueDate
                    ? datas[i].dueDate
                    : datas[i].startDate
                  : (datas[i].endDate ? datas[i].endDate : datas[i].startDate) +
                      " " +
                      (datas[i].endTime ? datas[i].endTime : "9:00 AM"),
                "YYYY,MM,DD HH:mm z a"
              ).toISOString()
            ),
          });
        }
        setEvents(myEventsList);
      }
      return () => {
        unsubscribed = true;
      };
    }
  }, [
    props.taskData,
    // props.onViewName,
    // props.onClanderView,
    // taskDatas,
    props.tasksFilterData.data,
    // props.tasksFilterData.data,
    // props.taskStatusData,
    // props.filterStatus,
  ]);

  const handleSelectSlot = (data) => {
    /*
     *select slot data function
     */
    if (props.onViewName) {
      props.getCheckPermissionHandler("Task-add", (success) => {
        if (success) {
          setDrawer(true);
        } else {
          setOpenPMModal({
            open: true,
            permission: "Task Add",
          });
        }
      });
    } else {
      props.getCheckPermissionHandler("interview-add", (success) => {
        if (success) {
          setOpen(true);
        } else {
          setOpenPMModal({
            open: true,
            permission: "Candidate Add",
          });
        }
      });
    }
    setSelectedData(data);
  };
  const closeDrawer = () => {
    setDrawer(false);
    props.setIsHavepermissionsHandler(false);
  };
  const today = new Date();
  return (
    <>
      {/* <Calendar
        // toolbar={false}
        timeslots={1}
        selectable
        defaultView="week"
        onSelectSlot={handleSelectSlot}
        views={["month", "week", "day"]}
        localizer={localizer}
        events={myEvents}
        tooltipAccessor={null}
        showMultiDayTimes
        dayLayoutAlgorithm="no-overlap"
        // dayLayoutAlgorithm={(params) => {
        //   return overlap({ ...params, minimumStartDifference: 30 });
        // }}

        // eventPropGetter={eventStyleGetter}
      /> */}
      <Calendar
        timeslots={1}
        selectable={
          props.onViewName &&
          props.permissions &&
          props.permissions.find((data) => data === "Task-add")
            ? true
            : !props.onViewName &&
              props.permissions &&
              props.permissions.find((data) => data === "interview-add")
            ? true
            : false
        }
        // culture="en"
        // onSelectSlot={handleSelectSlot}
        min={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8)
        }
        // max={
        //   new Date(today.getFullYear(), today.getMonth(), today.getDate(), 24)
        // }
        dayLayoutAlgorithm="no-overlap"
        // type="custom"
        showMultiDayTimes
        tooltipAccessor={(events) => (events = "View details")}
        views={["month", "week", "day"]}
        defaultView="week"
        localizer={localizer}
        events={myEvents}
        // startAccessor="start"
        // endAccessor="end"
      />
      {!props.onViewName && open && (
        <ScheduleInterviewDrawer
          onInterviewName={"task"} //specifing from schedule drawer from which page the data is comming like example task and calendar page, it's noting but modal name
          onModalName={"addFromCalendar"} //specifing from schedule drawer from which page the data is comming like example task and calendar page, it's noting but modal name, it is used to set time field data in schedule interview
          onSelectedData={selectedData} //selected time sloat data will send in this prop
          onOpenSchedileInterview={open} //used to open drawer
          onJobData={props.onJobsData} //
          onCandidateData={props.onCandidateData}
          onCloseSchedileInterview={() => {
            setOpen(false);
            props.setIsHavepermissionsHandler(false);
          }}
        />
      )}
      {props.onViewName && drawer && (
        <RegularTaskDrawer
          onSelectedData={selectedData}
          onModalName={"addFromCalendar"}
          onCloseDrawer={closeDrawer}
          onOpen={drawer}
          onSumbitData={(val) => {
            props.postTaskHandler(val, (success) => {
              if (success) {
                closeDrawer();
                props.getTaskFilterHandler({
                  current_page: 1,
                  task: "task",
                });
              }
            });
          }}
          employeesData={props.employeesData}
          onJobData={props.onJobsData}
          onCandidateData={props.onCandidateData}
          onCompanyData={props.onCompanyData}
        />
      )}
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
          props.setIsHavepermissionsHandler(false);
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    calendarData: state.taskAndCalendar.calendarFilterData,
    taskData: state.taskAndCalendar.calendar,
    filterStatus: state.taskAndCalendar.filterStatus,
    tasksFilterData: state.taskAndCalendar.tasks,
    permissions: state.authpermissions.permissions,
    ishavepermissions: state.authpermissions.ishavepermissions,
    taskStatusData: state.taskAndCalendar.taskStatusData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getFilterCalendarHandler: (data) => {
    //   dispatch(getFilterCalendarAction(data));
    // },

    postTaskHandler: (data, callback) => {
      dispatch(postTaskAndCalendarAction(data, callback));
    },
    getTaskFilterHandler: (data) => {
      dispatch(getTasksFilterAction(data));
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
    setIsHavepermissionsHandler: (data) => {
      dispatch(checkPermissionReducer(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskCalendarInterviews);
