import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Pagination,
  Row,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getExternalJobsListAction } from "../../../../Reusable/Store/Action/PublicRequirementAction";
import { GridFour, ListBulletsIcon } from "../../../IconsComp/Icons";
import commonCssStyle from "../../../UI/CommonCss.module.css";

function ExternalJobsListingPage(props) {
  const params = useParams();
  const [form] = Form.useForm();

  const [colorKey, setColorKey] = useState("0");
  const [calendarViewStatus, setCalendarViewStatus] = useState(false);
  const [cfilter, setCFilter] = useState({});

  const fetchRecords = (page) => {
    const val = {
      ...cfilter,
      ...props.publicRequirement,
      current_page: page,
      id: params.id,
    };

    props.getJobsListFilterHandler(val);
  };

  const columns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Job Title
        </p>
      ),
      dataIndex: "title",
      render: (text, render) => (
        <>
          <Link
            to={`/jobs/jobdetail/${render.jobDetailCode}`}
            state={{
              id: render.jobDetailCode,
              commingFrom: "list page",
              BackButtonIcons: params.id,
            }}
            style={{
              color: "var(--primaryColor)",
              fontFamily: "Helvetica,ProductSans",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "17px",
            }}
          >
            {text}
          </Link>
        </>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Location
        </p>
      ),
      dataIndex: "locationDetails",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Job Type
        </p>
      ),
      dataIndex: "jobType",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Work Mode
        </p>
      ),
      dataIndex: "workMode",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Department
        </p>
      ),
      dataIndex: "department",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
  ];

  // useEffect(() => {
  //   props.getJobsListFilterHandler({
  //     id: params.id,
  //     current_page: 1,
  //   });
  // }, []);

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("externalJobListingPage"));
    if (obj) {
      props.getJobsListFilterHandler({
        id: params.id,
        ...obj,
      });
    } else {
      props.getJobsListFilterHandler({
        id: params.id,
        current_page: 1,
      });
    }
  }, []);

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("externalJobListingPage"));
    if (obj) {
      form.setFieldsValue({
        department: obj.department,
        jobType: obj.jobType,
        location: obj.location,
        maxExperience: obj.maxExperience,
        minExperience: obj.minExperience,
        role: obj.role,
        skills: obj.skills,
        title: obj.title,
        workMode: obj.workMode,
      });
    }
  }, []);

  const setListViewHandler = () => {
    setCalendarViewStatus(false);
  };

  const calendarViewHandler = () => {
    setCalendarViewStatus(true);
  };

  const onFinishFilter = (val) => {
    const data = {
      ...val,
      current_page: 1,
    };
    setCFilter(data);
    props.getJobsListFilterHandler({ ...data, id: params.id });
    localStorage.setItem("externalJobListingPage", JSON.stringify(data));
  };

  const resetFilter = () => {
    form.resetFields();
    const data = {
      id: params.id,
      current_page: 1,
    };
    props.getJobsListFilterHandler(data);
    localStorage.setItem("externalJobListingPage", JSON.stringify(data));
  };

  const handleProvinceChange = (value) => {
    let sort = value.split("_");
    props.getJobsListFilterHandler({
      ...cfilter,
      sortBy: sort[0],
      sortOrder: sort[1],
      current_page: 1,
      id: params.id,
    });
  };

  return (
    <>
      <div style={{ overflowY: "scroll", height: "100vh", paddingTop: "30px" }}>
        <Row justify="space-between" style={{ marginBottom: "1%" }}>
          <Col xs={24} md={5} lg={6}>
            <h1
              style={{ marginLeft: "10%" }}
              // style={{ marginRight: "51px", marginLeft: "10px" }}
              className={`${commonCssStyle.mainHeadingText}
              ${commonCssStyle.marginBottomRemove}`}
            >
              Jobs &#40;
              {props.publicRequirement && props.publicRequirement.total
                ? props.publicRequirement.total
                : "0"}
              &#41;
            </h1>
          </Col>
          <Col xs={24} md={19} lg={17}>
            <Row style={{ gap: "20px" }}>
              <Col  /* xs={24} md={10} lg={5} */>
                <div
                  style={{
                    height: "43px",
                    width: "auto",
                    padding: "1px 5px",
                    // marginBottom: "31px",
                    backgroundColor: "#E0E0E0",
                    borderRadius: "6px",
                  }}
                >
                  <Row align="middle" justify="space-between">
                    <Col>
                      <Card
                        style={{ padding: "5px 10px" }}
                        onMouseEnter={() => {
                          setColorKey("1");
                        }}
                        onMouseLeave={() => {
                          setColorKey("0");
                        }}
                        onClick={setListViewHandler}
                        className={`${
                          calendarViewStatus
                            ? commonCssStyle.taskBtn
                            : commonCssStyle.taskBackClrBtn
                        }`}
                      >
                        <Row
                          className={`${commonCssStyle.coursorPointer}`}
                          style={{ gap: "8px" }}
                          align="middle"
                        >
                          <Col style={{ transform: "translateY(3px)" }}>
                            <ListBulletsIcon
                              color={calendarViewStatus ? "#1E1E1E" : "white"}
                            />
                          </Col>
                          <Col>
                            <p
                              className={` ${
                                calendarViewStatus
                                  ? commonCssStyle.taskColor
                                  : commonCssStyle.taskBtnTextColor
                              } ${commonCssStyle.marginBottomRemove} `}
                              style={{
                                fontSize: "14px",
                                paddingLeft: "5px",
                              }}
                            >
                              List View
                            </p>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col>
                      <Card
                        style={{ padding: "5px 10px" }}
                        onMouseEnter={() => {
                          setColorKey("2");
                        }}
                        onMouseLeave={() => {
                          setColorKey("0");
                        }}
                        onClick={calendarViewHandler}
                        className={`${
                          !calendarViewStatus
                            ? commonCssStyle.taskBtn
                            : commonCssStyle.taskBackClrBtn
                        }`}
                      >
                        <Row
                          style={{ gap: "8px" }}
                          align="middle"
                          className={`${commonCssStyle.coursorPointer}`}
                        >
                          <Col style={{ transform: "translateY(1px)" }}>
                            <GridFour
                              color={!calendarViewStatus ? "#1E1E1E" : "white"}
                            />
                          </Col>
                          <Col>
                            <p
                              className={` ${
                                calendarViewStatus
                                  ? commonCssStyle.taskBtnTextColor
                                  : commonCssStyle.taskColor
                              } ${commonCssStyle.marginBottomRemove}`}
                              style={{
                                fontSize: "14px",
                                paddingLeft: "1px",
                              }}
                            >
                              Grid View
                            </p>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col /* xs={24} md={10} */>
                <Button
                  style={{
                    height: "40px",
                  }}
                  className={`${commonCssStyle.sortBtn}`}
                  // type="default"
                >
                  <Row align="middle" justify="space-between">
                    <Col>
                      <p
                        style={{
                          fontSize: "16px",
                        }}
                      >
                        Sort By
                      </p>
                    </Col>
                    <Col>
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        style={{ width: "100%" }}
                        // style={{ width: "210px" }}
                        bordered={false}
                        onChange={handleProvinceChange}
                        placeholder={
                          <p
                            style={{
                              margin: "0px",
                              color: "var(--primaryColor)",
                            }}
                          >
                            Select
                          </p>
                        }
                        size="small"
                        className={`${commonCssStyle.filterDrop} selectInput sortBySelectStyle selectTag selectTag1`}
                      >
                        <Select.Option
                          value="postedDate_ASC"
                          key="0"
                          style={{ padding: "10px" }}
                        >
                          Posted Date Ascending
                        </Select.Option>
                        <Select.Option
                          value="postedDate_DESC"
                          key="1"
                          style={{ padding: "10px" }}
                        >
                          Posted Date Descending
                        </Select.Option>
                        <Select.Option
                          value="title_ASC"
                          key="2"
                          style={{ padding: "10px" }}
                        >
                          Name Ascending
                        </Select.Option>
                        <Select.Option
                          value="title_DESC"
                          key="3"
                          style={{ padding: "10px" }}
                        >
                          Name Descending
                        </Select.Option>
                      </Select>
                    </Col>
                  </Row>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="space-between" style={{ padding: "20px" }}>
          <Col xs={24} md={24} lg={5}>
            <Card style={{ marginBottom: "20px" }}>
              <Form layout="vertical" form={form} onFinish={onFinishFilter}>
                <Row>
                  <Col style={{ margin: "20px 22px 10px 22px" }}>
                    <p className={commonCssStyle.externalFltName}>Filters</p>
                  </Col>
                </Row>
                <Row style={{ margin: "0px 25px 25px 25px" }}>
                  <Col md={24}>
                    <Form.Item name="title" label="Job Title">
                      <Input />
                    </Form.Item>

                    <Form.Item name="role" label="Job Role">
                      <Input />
                    </Form.Item>

                    <Form.Item name="skills" label="Key Skills">
                      <Input placeholder="Enter key skills" />
                    </Form.Item>

                    <Row style={{ gap: "10px" }}>
                      <Col md={24}>
                        <div className="ant-col ant-form-item-label">
                          <label>Experience in years</label>
                        </div>
                      </Col>
                      <Col xs={24} md={11}>
                        <Form.Item name="minExperience">
                          <Input type="number" placeholder="Min" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={11}>
                        <Form.Item name="maxExperience">
                          <Input type="number" placeholder="Max" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item name="location" label="Location">
                      <Input placeholder="Enter location" />
                    </Form.Item>

                    <Form.Item name="department" label="Department">
                      <Input />
                    </Form.Item>

                    <Form.Item label="Work Mode" name="workMode">
                      <Select placeholder="Select work mode">
                        <Select.Option value="Remote">Remote</Select.Option>
                        <Select.Option value="Office">Office</Select.Option>
                        <Select.Option value="Hybrid">Hybrid</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item label="Job Type" name="jobType">
                      <Select placeholder="Select job type">
                        <Select.Option value="part time">
                          Part time
                        </Select.Option>
                        <Select.Option value="full time">
                          Full time
                        </Select.Option>
                        <Select.Option value="Internship">
                          Internship
                        </Select.Option>
                        <Select.Option value="Contract">Contract</Select.Option>
                        <Select.Option value="Temporary">
                          Temporary
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Row gutter={[16,16]}  justify="end">
                      <Col /* xs={24} md={5} lg={12} */>
                        <Button
                          className={commonCssStyle.buttonSettingsSecondary}
                          type="default"
                          onClick={() => {
                            resetFilter();
                            props.getJobsListFilterHandler({
                              id: params.id,
                              current_page: 1,
                            });
                            props.onCloseFilter();
                          }}
                        >
                          Reset Filter
                        </Button>
                      </Col>
                      <Col /* xs={24} md={19} lg={12} */>
                        <Button
                          htmlType="submit"
                          className={commonCssStyle.buttonSettingsPrimary}
                          type="primary"
                        >
                          Apply Filter
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>

          {!calendarViewStatus ? (
            <Col xs={24} md={24} lg={18}>
              <Table
                className="importCandidateTable"
                scroll={{ x: "auto" }}
                rowKey="key"
                columns={columns}
                dataSource={
                  props.publicRequirement &&
                  props.publicRequirement.data &&
                  props.publicRequirement.data
                }
                pagination={{
                  size: "small",
                  current:
                    props.publicRequirement &&
                    props.publicRequirement.current_page
                      ? props.publicRequirement.current_page
                      : 1,
                  pageSize:
                    props.publicRequirement && props.publicRequirement.per_page,
                  total:
                    props.publicRequirement && props.publicRequirement.total,
                  showSizeChanger: false,
                  onChange: (props) => {
                    fetchRecords(props);
                  },
                }}
              />
            </Col>
          ) : (
            <>
              <Col xs={24} md={24} lg={18}>
                <Row gutter={[16, 16]}>
                  {props.publicRequirement &&
                    props.publicRequirement.data &&
                    props.publicRequirement.data.map((data, index) => {
                      return (
                        <Col xs={24} sm={24} md={12} lg={8}>
                          <Card
                            style={{
                              height: "225px",
                              width: "80%",
                              borderRadius: "6px",
                              // margin:"8px"
                            }}
                          >
                            <Row
                              style={{
                                padding: "15px",
                                marginLeft: "5px",
                              }}
                            >
                              <Col span={23}>
                                <p
                                  style={{ padding: "5px" }}
                                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.externalJobTextdep} ${commonCssStyle.textEllipsis1}  `}
                                  title={data.department ? data.department : ""}
                                >
                                  {data.department ? data.department : "N/A"}
                                </p>
                                <Link
                                  // to={"/recruitmentexternaljobdetailpage"}
                                  to={`/jobs/jobdetail/${data.jobDetailCode}`}
                                  state={{
                                    id: data.jobDetailCode,
                                    commingFrom: "list page",
                                    BackButtonIcons: params.id,
                                  }}
                                >
                                  <p
                                    style={{ padding: "5px" }}
                                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.externalJobBlClr} ${commonCssStyle.textEllipsis1} `}
                                    title={data.title ? data.title : ""}
                                  >
                                    {data.title ? data.title : "N/A"}
                                  </p>
                                </Link>

                                <p
                                  style={{ padding: "5px" }}
                                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor} ${commonCssStyle.textEllipsis1} `}
                                  title={data.role ? data.role : ""}
                                >
                                  {data.role ? data.role : "N/A"}
                                </p>
                                <p
                                  style={{ padding: "5px" }}
                                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.externalJobLocationclr} ${commonCssStyle.textEllipsis1}`}
                                  title={
                                    data.locationDetails
                                      ? data.locationDetails
                                      : ""
                                  }
                                >
                                  {data.locationDetails
                                    ? data.locationDetails
                                    : "N/A"}
                                </p>
                                <p
                                  style={{ padding: "5px" }}
                                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.externalJobLocationclr} ${commonCssStyle.textEllipsis1}  `}
                                  title={data.workMode ? data.workMode : ""}
                                >
                                  {data.workMode ? data.workMode : "N/A"}{" "}
                                  {data.jobType ? ". " + data.jobType : "N/A"}
                                </p>
                              </Col>
                            </Row>
                            <p
                              style={{ borderBottom: "1px solid #BCB8B8" }}
                            ></p>
                            <Row
                              style={{
                                padding: "5px",
                                marginLeft: "5px",
                              }}
                            >
                              <Col md={24} lg={24}>
                                <p
                                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor} `}
                                >
                                  <Row
                                    //  justify="space-between"
                                    style={{ gap: "30px" }}
                                  >
                                    <Col
                                      className={commonCssStyle.coursorPointer}
                                      md={12}
                                    >
                                      <Link
                                        // to={"/recruitmentexternaljobdetailpage"}
                                        state={{
                                          id: data.jobDetailCode,
                                          commingFrom: "list page",
                                          BackButtonIcons: params.id,
                                        }}
                                        to={`/jobs/jobdetail/${data.jobDetailCode}`}
                                      >
                                        View Details &gt;&gt;
                                      </Link>
                                    </Col>
                                    <Col
                                    //  md={10}
                                    >
                                      <Link
                                        // to={`/jobs/jobdetail/${data.jobDetailCode}`}
                                        to={"/jobs/apply"}
                                        state={{
                                          // id: data.jobDetailCode,
                                          type: "Apply now",
                                          id: data.jobDetailCode,
                                          commingFrom: "list page",
                                          BackButtonIcons: params.id,
                                          trackingCode: data.jobDetailCode,
                                        }}
                                      >
                                        Apply now
                                      </Link>
                                    </Col>
                                  </Row>
                                </p>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
                <Row
                  justify="end"
                  style={{ marginBottom: "2%", marginTop: "2%" }}
                >
                  <Col>
                    <Pagination
                      onChange={(val) => {
                        fetchRecords(val);
                      }}
                      current={
                        props.publicRequirement &&
                        props.publicRequirement.current_page
                          ? props.publicRequirement.current_page
                          : 1
                      }
                      pageSize={
                        props.publicRequirement &&
                        props.publicRequirement.per_page &&
                        props.publicRequirement.per_page
                          ? props.publicRequirement.per_page
                          : 2
                      }
                      total={
                        props.publicRequirement &&
                        props.publicRequirement.total &&
                        props.publicRequirement.total
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    publicRequirement: state.publicRequirement.publicRequirement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobsListFilterHandler: (id, callback) => {
      dispatch(getExternalJobsListAction(id, callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalJobsListingPage);
