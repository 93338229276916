import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clients: [],
  client: null,
  client_filter: [],
  client_jobs: [],
  import_Client_Company_List_data: [],
};

export const ClientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    getClientsSlice(state, action) {
      state.clients = action.payload.result;
    },
    getClientSlice(state, action) {
      state.client = action.payload.result;
    },
    postClientSlice(state, action) {
      state.client_filter.result = [
        action.payload.result,
        ...state.client_filter.result,
      ];
    },
    deleteClientSlice(state, action) {
      state.client_filter = state.client_filter.result.filter(
        (data) => data.id !== action.payload
      );
    },
    putClientSlice(state, action) {
      state.client = action.payload.result;
    },
    client_FilterSlice(state, action) {
      state.client_filter = action.payload.result;
    },
    getClientsJobsSlice(state, action) {
      state.client_jobs = action.payload.result;
    },
    //import client list slice
    getImportClientCompanySlice(state, action) {
      state.import_Client_Company_List_data = action.payload.result;
    },
  },
});
export const {
  client_FilterSlice,
  deleteClientSlice,
  getClientSlice,
  getClientsSlice,
  postClientSlice,
  putClientSlice,
  getClientsJobsSlice,
  getImportClientCompanySlice,
} = ClientSlice.actions;
export default ClientSlice.reducer;
