//login and profile and settings
export const LOGIN_URL = "login";
export const LOGOUT_URL = "logout";
export const REFRESH_URL = "refresh";
export const SEND_RESET_LINK_URL = "password/send_reset_link";
export const VERIFY_LINK_URL = "password/verify_link";
export const RESET_PASSWORD_URL = "password/reset_password";
export const PASSWORD_RESET_URL = "profile/password_reset";
export const PROFILE_URL = "profile";
export const PROFILE_UPDATE_URL = "profile/profile_update";
export const SETTINGS_URL = "settings";
export const SETTINGS_SUBSCRIPTION_DETAILS_URL = "subscription_details";
export const SETTINGS_WORK_LOCATION_URL = "work_location";
export const WORK_LOCATION_REMOVE_IN_ORGANIZATION_URL = "work_location_remove";
export const TEST_CREDENTIALS_URL = "test_credentials";

export const CANDIDATES_URL = "candidates";
export const CANDIDATES_ADDRESS_URL = "candidates/address";
export const CANDIDATES_EDUCATION_URL = "candidates/education";
export const CANDIDATES_EXPERIENCE_URL = "candidates/experience";
export const CANDIDATES_UPLOADS_URL = "candidates/uploads";
export const CANDIDATES_TIMELINE_URL = "candidate_timeline";
export const CANDIDATE_EXPORT_URL = "export/candidate";
export const CANDIDATE_EMAIL_URL = "candidates/email";
export const CANDIDATE_LIST__IMPORT_DATA_URL = "import/data/candidate";
export const CANDIDATE_IMPORT_FORMAT_URL = "/import/format/candidate";
export const CANDIDATE_CALL_LOGS_URL = "/call_logs/candidate";
export const CANDIDATE_TIMELINE_FILTER_URL = "/timeline_filter";
export const CANDIDATE_RESUME_PARSING_URL = "/get_chunk";
export const CANDIDATE_EMAIL_OR_PHONE_CHECK = "/candidate_check";

export const EMPLOYEES_URL = "employees";
export const EMPLOYEE_ROLE_URL = "employee_role";
export const EMPLOYEE_PERMISSION_URL = "employee_permission";
export const EMPLOYEE_FILTER_URL = "employee_filter?page=";
export const EMPLOYEE_DOCUMENTS_URL = "employee_documents";
export const EMPLOYEE_TIMELINE_URL = "employee_timeline";
export const EMPLOYEE_CALL_LOGS_URL = "/call_logs/employee";
export const EMPLOYEE_JOBS_URL = "/employee_jobs";

//for for completed interview (interview_performance_rating)
export const INTERVIEW_PERFORMANCE_RATIO = "interview_performance_rating";
//end of for completed interview (interview_performance_rating)

export const CANDIDATES_FILTER_URL = "candidate_filter?page=";
export const CANDIDATE_INTERVIEW_URL = "candidate_interview";
export const CANDIDATE_INSIGHT_URL = "candidate_insight";
// export const CANDIDATES_FILTER_URL = "candidate_filter";
export const CLIENT_URL = "client";
export const CLIENT_FILTER_URL = "client_filter?page=";
export const CLIENT_JOBS_ID_URL = "client_jobs";
export const CLIENT_COMPANY_POST_IMPORT_URL = "import/company";
//for getting list of company
export const COMPANY_LIST__IMPORT_DATA_URL = "import/data/company";
//for downloading company excel format
export const COMPANY_IMPORT_FORMAT_URL = "/import/format/company";
//end

export const CHECK_PERMISSION_URL = "auth/check_permission";
export const AUTH_PERMISSIONS_URL = "auth/permissions";

export const PERMISSIONS_URL = "permission";

export const TASK_AND_CALENDAR_URL = "get_task";

export const TASK_URL = "task";
export const TASK_FILTER_URL = "task_filter?page=";
export const TASK_CALENDAR_FILTER_URL = "task_calendar_filter";

//for candidate fetching candidate related job

export const FETCH_CANDIDATE_RELATED_JOBS_URL = "candidate_jobs";
//end of fetching candidate realted job

export const REQUIREMENT_URL = "jobs";

export const REQUIREMENT_FILTER_URL = "job_filter?page=";
export const REQUIREMENT_JOB_HIRING_TEAM = "job_hiring_team";
export const REQUIREMENT_JOB_POST_IMPORT_URL = "import/job";

//unlink  candidates  from job add and unlink
export const REQUIREMENT_ADD_CANDIDATE_JOS_URL = "candidate_add_to_job";

export const REQUIREMENT_UNLINK_CANDIDATE_JOS_URL = "candidate_unlink_job";
//end of unlink job for

//for downloading excel format
export const JOB_IMPORT_FORMAT_URL = "/import/format/job";
//
//for getting the list of import data
export const JOB_LIST__IMPORT_DATA_URL = "import/data/job";

//

export const RECRUITMENT_FILTER_URL = "recruitment_filter?page=";
export const RECRUITMENT_PROCESS_FROM_POOL_URL = "recruitment";
export const RECRUITMENT_POST_IMPORT_URL = "import/candidate";
export const RECRUITMENT_DELETE_IMPORT_DATA_URL = "import/clear";
export const RECRUITMENT_CANDIDATE_ALL_FILTER_DATA_URL =
  "recruitment_candidate_filter";
export const RECRUITMENT_CANDIDATE_JOB_STATUS_URL = "candidate_job_status";
export const RECRUITMENT_ALL_STATUS = "all_status";
export const RECRUITMENT_CANDIDATES_URL = "candidate_summary";
export const RECRUITMENT_CALL_LOGS_URL = "/call_logs/candidate";
//recruitent export
export const RECRUITMENT_EXPORT_URL = "export/recruitment_candidate";
//

//Recruitment interview feedback

export const RECRUITMENT_INTERVIEW_FEEDBACK_URL =
  "job_candidate_interview_feedback";
//end of recruitment interview feedback

//Settings recruitment data import
export const IMPORT_SETTINGS_COMPANY_DATA_URL = "/import_data";

//recruitment job suggesstion
export const RECRUITMENT_CANDIDATE_SUGGESTION =
  "/recruitment_candidate_suggestion";

export const JOB_CODE_URL = "jobcode";

export const INTERVIEW_URL = "interview";

//Dashboard Apis
export const DASHBOARD_GET_API = "/dashboard";
//end dashboard api

//notification api
export const NOTIFICATION_GET_API = "/notifications";
export const NOTIFICATION_MARK_READ = "/notification_mark_read";
export const NOTIFICATION_STORE_TOKEN = "store_token";

//end of notification api

//for external job apply
// export const EXTERNAL_JOB_APPLY_JOBS_LIST_API="/public_jobs_filter?page=";
export const EXTERNAL_JOB_APPLY_JOBS_LIST_API = "/public_jobs_filter";
export const EXTERNAL_JOB_DETAILS_API = "/public_jobs/";
export const GET_COUNTRY_URL = "loation_list";
export const POST_EXTERNAL_CANDIDATES_URL = "/public_jobs/apply";
export const VERIFY_EXTERNAL_CANDIDATES_URL = "/apply_check";
//end of external job apply

//global search api
export const GET_GLOBAL_SEARCH_URL = "global_search";
//end of global search api

//Reports
export const GET_REPORTS_CANDIDATES_SUMMARY = "/reports/summary";

export const GET_REPORT_CANDIDATES_DETAILS = "/reports/candidate";

export const GET_REPORT_COMPANY_DETAILS = "/reports/client";

export const GET_REPORT_JOB_DETAILS = "/reports/job";

export const GET_REPORT_JOB_CANDIDATES_DETAILS = "/reports/job_candidates";
// export const GET_REPORT_RECURITMENT_JOB_URL = "/reports/recruiter_jobs?page=";
export const GET_REPORT_RECURITMENT_JOB_URL = "/reports/employee_jobs?page=";
export const EXPORT_REPORT_RECURITMENT_JOB_URL = "/reports/employee_jobs";
// export const GET_RECURITMENT_URL = "/reports/recruiter";
export const GET_RECURITMENT_URL = "/reports/employee?page=";
export const GET_Export_RECURITMENT_URL = "/reports/employee";

// export const GET_RECURITMENT_KPI_URL = "/reports/recruiter_kpi";
export const GET_RECURITMENT_KPI_URL = "/reports/employee_kpi";
//reports export
export const REPORTS_CANDIDATE_EXPORT_URL = "/reports/candidate";
export const REPORTS_COMPANY_EXPORT_URL = "/reports/client";
export const REPORTS_JOB_EXPORT_URL = "/reports/job";
export const REPORTS_JOB_CANDIDATE_EXPORT_URL = "/reports/job_candidates";

//end of report exports

//reports graph
export const REPORTS_EMPLOYEE_HIT_RATIO_GRAPH_URL =
  "/reports/recruiter_hit_ratio";
export const REPORTS_REQUIREMENT_GRAPH = "/reports/requirement_graph";
export const REPORTS_REQUIREMENT_TIME_GRAPH_URL =
  "/reports/requirement_time_graph";
//end of reports graph

//export
export const EXPORT_INTERVIEW_FEEDBACK_URL = "/export/interview_feedback";
