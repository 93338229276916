import {
  getTagsSlice,
  getTagSlice,
  postTagsSlice,
  putTagsSlice,
  deleteTagsSlice,
} from "../../Slice/Master/MasterTagsSlice";
import { TAGS_URL } from "../../masterpath";
import { message } from "antd";
import AxiosApi from "../../api";
const api = new AxiosApi();
export const getTagsAction = () => {
  return (dispatch) => {
    api.invoke(TAGS_URL, "get", (data, success, stateCode) => {
      if (success) {
        if (stateCode === 200) {
          dispatch(getTagsSlice(data));
        } else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const postTagsAction = (data) => {
  return (dispatch) => {
    api.invoke(
      TAGS_URL,
      "post",
      (data, success, stateCode) => {
        if (success) {
          if (stateCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(postTagsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const getTagAction = (data) => {
  return (dispatch) => {
    api.invoke(TAGS_URL + "/" + data.id, "get", (data, success, stateCode) => {
      if (success) {
        if (stateCode === 200) {
          dispatch(getTagSlice(data));
        } else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const putTagsAction = (data) => {
  return (dispatch) => {
    api.invoke(
      TAGS_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3); dispatch(putTagsSlice(data));}
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteTagsAction = (id) => {
  return (dispatch) => {
    api.invoke(TAGS_URL + "/" + id, "delete", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200){
          message.destroy();
          message.success(data.message, 3);
          dispatch(deleteTagsSlice(id));
        } 
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
