import {
  getJobDescriptionsSlice,
  getJobDescriptionSlice,
  postJobDescriptionsSlice,
  putJobDescriptionsSlice,
  deleteJobDescriptionsSlice,
} from "../../Slice/Master/MasterJobDescriptionSlice";
import { JOB_DESCRIPTION_URL } from "../../masterpath";
import { message } from "antd";
import AxiosApi from "../../api";
const api = new AxiosApi();

export const getJobDescriptionsAction = () => {
  return (dispatch) => {
    api.invoke(JOB_DESCRIPTION_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getJobDescriptionsSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getJobDescriptionAction = (id, callback) => {
  return (dispatch) => {
    api.invoke(
      JOB_DESCRIPTION_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            dispatch(getJobDescriptionSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postJobDescriptionsAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      JOB_DESCRIPTION_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(postJobDescriptionsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const putJobDescriptionsAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      JOB_DESCRIPTION_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(putJobDescriptionsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteJobDescriptionsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      JOB_DESCRIPTION_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(deleteJobDescriptionsSlice(id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
