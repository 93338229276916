import React, { useEffect, useRef, useState } from "react";
import { Tabs, Row, Col, Avatar, Button, Image, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { CameraIcon } from "../../IconsComp/Icons";
import Card from "antd/lib/card/Card";
import {
  CakeIcon,
  CaretRightIcon,
  EditIcon,
  EnvelopeIcon,
  GenderIcon,
  HomeIcon,
  PhoneIcon,
  SignOutIcon,
  MapPinIcon,
  BuildIcon,
  UserIconCol,
  SutCaseTab,
} from "../../IconsComp/Icons";
import JobModal from "./JobModal";
import styles from "../ProfileComp/Profile.module.css";
import commonCssStyle from "../../UI/CommonCss.module.css";
import PersonalInformationModal from "./PresonalInformationModal";
import NameModal from "./NameModal";
import moment from "moment";
import { connect } from "react-redux";
import {
  logoutAction,
  passwordResetAction,
  profileAction,
  profileUpdateAction,
} from "../../../Reusable/Store/Action/AuthenticationAction";
import ResetPasswordModal from "./ResetPasswordModal";
import { getEmployeesAssignToJobAction } from "../../../Reusable/Store/Action/EmployeesAction";
import commonStyleCss from "../../UI/CommonCss.module.css";
import jwt_decode from "jwt-decode";
function TabsPage({
  logoutAction,
  profileAction,
  profile,
  passwordResetAction,
  profileUpdateAction,
  settings,
  getEmployeesAssignToJobHandler,
  employeeJobs,
  tokenData,
}) {
  let btnref = useRef(null);
  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      let decoded = jwt_decode(tokenData && tokenData);
      profileAction();
      getEmployeesAssignToJobHandler({ id: decoded.id });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    return () => {
      unsubscribed = true;
    };
  }, []);
  const handleChange = async (info) => {
    profileUpdateAction(
      { profileImage: info.target.files[0] },
      (success) => {}
    );
  };

  const dataFormate =
    settings && settings.dateFormat ? settings.dateFormat : "DD/MM/YYYY";

  const [addResetPasswordModal, setAddResetPasswordModal] = useState(false);
  let navigate = useNavigate();
  const [key, setKey] = useState("1");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenPINfo, setIsModalOpenPINfo] = useState(false);
  const [isModalOpenName, setIsModalOpenName] = useState(false);
  const [resetColor, setResetColor] = useState("var(--primaryColor)");
  const [resetBackColor, setResetBackColor] = useState("white");
  const [hoverKey, setHoverKey] = useState("0");
  if (window.location.hash === "#/profile") {
    localStorage.removeItem("clientCompany");
    localStorage.removeItem("employee");
    localStorage.removeItem("requirementFilterData");
    localStorage.removeItem("recuritmentFilterData");
    localStorage.removeItem("taskFilterData");
    localStorage.removeItem("interviewFilterData");
    localStorage.removeItem("candidateDetailFromRequirement");
    localStorage.removeItem("candidateListForPool");
    localStorage.removeItem("recuritmentCandidateFilter");
    localStorage.removeItem("reportsCompanyDetailFilterData");
    localStorage.removeItem("reportsjobdetailfilterdata");
    localStorage.removeItem("reportsjobcandidatefilterdata");
    localStorage.removeItem("recuritmentReportFilter");
    localStorage.removeItem("fromWhichPage");
    localStorage.removeItem("employeeReportsFilter");
    localStorage.removeItem("ClientCompanySort");
    localStorage.removeItem("employeeSort");
    localStorage.removeItem("ReportsCompanyReport1Sort");
    localStorage.removeItem("reportsjobcandidatefilterdataSort");
    localStorage.removeItem("ReportsJobDetailFilterOneSort");
    localStorage.removeItem("reportRecruitmentEmployeeSort");
    localStorage.removeItem("candidateSort");
    localStorage.removeItem("interivewSort");
    localStorage.removeItem("taskSort");
    localStorage.removeItem("recuritmentSort");
    localStorage.removeItem("requirementSort");
  }
  const columns = [
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Job Title
        </p>
      ),
      dataIndex: "title",
      render: (data, render) => {
        return (
          <p
            // onClick={() => {
            //   navigate("/recruitment/recruitmentJobdetails", {
            //     state: {
            //       id: render.jobId,
            //       activeTabKey: "3",
            //       recuritmentJobDetailsName: data,
            //       from: "clientCompany",
            //     },
            //   });
            // }}
            // style={{ color: "var(--primaryColor)" }}
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Job Department
        </p>
      ),
      dataIndex: "department",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Position Required
        </p>
      ),
      dataIndex: "positionRequired",
      render: (data, render) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : "0"}
          </p>
        );
      },
    },
    // profile?.roleType !== "External Recruiter"
    //   ? {
    //       title: (
    //         <p
    //           className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
    //         >
    //           Job Revenue
    //         </p>
    //       ),
    //       dataIndex: "revenueGenerated",
    //       render: (data) => {
    //         return (
    //           <p
    //             className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
    //           >
    //             {settings.currency && settings.currency} {data ? data : "0"}
    //           </p>
    //         );
    //       },
    //     }
    //   : {},
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Active
        </p>
      ),
      dataIndex: "activeCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Screening
        </p>
      ),
      dataIndex: "screeningCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Interviewing
        </p>
      ),
      dataIndex: "interviewedCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Offered
        </p>
      ),
      dataIndex: "offeredCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Hired
        </p>
      ),
      dataIndex: "hiredCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Declined
        </p>
      ),
      dataIndex: "declinedCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
  ];

  const dataArray1 = [
    {
      key: "1",
      label: (
        <Card
          size="large"
          extra={
            <div
              onClick={() => {
                setIsModalOpenName(true);
              }}
              style={{ padding: "6px 0px 0px 0px" }}
            >
              <EditIcon color={"var(--primaryColor)"} />
            </div>
          }
          style={{ height: "290px" }}
        >
          <Row>
            <Col flex={1}>
              <Avatar
                style={{
                  width: "120px",
                  height: "120px",
                }}
              >
                <Image
                  onClick={(e) => {
                    e.preventDefault();
                    btnref.click();
                    return false;
                  }}
                  alt={<CameraIcon />}
                  preview={false}
                  style={{
                    margin: "-12px",
                    height: "150px",
                    width: "150px",
                    borderRadius: "50%",
                  }}
                  src={profile && profile.profileImage && profile.profileImage}
                />
                <span
                  style={{
                    position: "absolute",
                    bottom: "30px",
                    right: "20px",
                  }}
                >
                  <CameraIcon />
                </span>
              </Avatar>
              <div hidden>
                <input
                  type="file"
                  name="fileUpload"
                  onChange={handleChange}
                  ref={(input) => {
                    btnref = input;
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col flex={1}>
              <p
                className={commonCssStyle.profileNameText}
                style={{
                  textAlign: "center",
                  marginTop: "17px",
                }}
              >
                {profile && profile.firstName + " " + profile.lastName}
              </p>
            </Col>
          </Row>
        </Card>
      ),
      children: (
        <Card
          size="large"
          style={{
            height:
              profile?.roleType === "External Recruiter" ? "auto" : "290px",
          }}
          // className={`${commonCssStyle.coverBoady}`}
          title={
            <>
              <p
                style={{ color: "var(--headings-color)" }}
                className={` ${commonCssStyle.subHeadingText}`}
              >
                <Avatar
                  size={50}
                  className={commonCssStyle.avtarBgClr}
                  style={{ marginRight: "12px", paddingTop: "5px" }}
                >
                  <UserIconCol color={"var(--primaryColor)"} />
                </Avatar>
                Personal Information
              </p>
            </>
          }
          extra={
            <div
              onClick={() => {
                setIsModalOpenPINfo(true);
              }}
              className={commonCssStyle.coursorPointer}
            >
              <EditIcon color={"var(--primaryColor)"} />
            </div>
          }
        >
          {profile ? (
            <>
              <Row style={{ padding: "24px" }} gutter={[16, 16]}>
                <Col md={8} sm={24} /* xs={24} */>
                  <Row align="middle">
                    <Col span={6} style={{ paddingRight: "20px" }}>
                      <CakeIcon color={"var(--body-text-color)"} />
                    </Col>
                    <Col span={18}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${styles.PersonalInform}`}
                      >
                        {profile.dob
                          ? moment(profile.dob).format(
                              settings && settings.dateFormat
                                ? settings.dateFormat
                                : "DD/MM/YYYY"
                            )
                          : "None"}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col md={8} sm={24} /* xs={24} */>
                  <Row align="middle">
                    <Col span={6} style={{ paddingRight: "20px" }}>
                      <GenderIcon color={"var(--body-text-color)"} />
                    </Col>
                    <Col span={18}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${styles.PersonalInform}`}
                      >
                        {profile && profile.gender ? profile.gender : "None"}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col md={8} sm={24} /* xs={24} */>
                  <Row align="middle">
                    <Col span={6} style={{ paddingRight: "20px" }}>
                      <PhoneIcon color={"var(--body-text-color)"} />
                    </Col>
                    <Col span={18}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${styles.PersonalInform}`}
                      >
                        {profile && profile.phone}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col md={8} sm={24} /* xs={24} */>
                  <Row align="middle">
                    <Col span={6} style={{ paddingRight: "20px" }}>
                      <HomeIcon color={"var(--body-text-color)"} />
                    </Col>
                    <Col span={18}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${styles.PersonalInform}`}
                      >
                        {profile && profile.address ? profile.address : "None"}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col md={8} sm={24} /* xs={24} */>
                  <Row align="middle">
                    <Col span={6} style={{ paddingRight: "20px" }}>
                      <EnvelopeIcon color={"var(--body-text-color)"} />
                    </Col>
                    <Col span={18}>
                      <p
                        // style={{ wordBreak: "break-all" }}
                        className={`${commonCssStyle.marginBottomRemove} ${styles.PersonalInform} ${commonCssStyle.textEllipsis}`}
                      >
                        {profile.email}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {profile?.roleType === "External Recruiter" ? (
                <Row>
                  <Col
                    style={{
                      padding: "24px",
                    }}
                    md={24}
                  >
                    <Table
                      title={() => (
                        <Row align="middle">
                          <Col>
                            <Avatar
                              className={commonCssStyle.avtarBgClr}
                              style={{ marginRight: "12px", paddingTop: "5px" }}
                              size={50}
                            >
                              <SutCaseTab color={"var(--menu-text-color)"} />
                            </Avatar>
                          </Col>
                          <Col>
                            <p
                              style={{
                                color: "var(--headings-color)",
                                margin: "0px",
                              }}
                              className={` ${commonCssStyle.subHeadingText}`}
                            >
                              Job
                            </p>
                          </Col>
                        </Row>
                      )}
                      scroll={{ x: "auto" }}
                      // pagination={{
                      //   size: "small",
                      // }}
                      pagination={false}
                      style={{ margin: "0px 5px" }}
                      size="small"
                      className="JobTable"
                      columns={columns}
                      dataSource={employeeJobs && employeeJobs}
                    />
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </>
          ) : (
            ""
          )}
        </Card>
      ),
    },
  ];
  const dataArray = [
    {
      key: "1",
      label: (
        <>
          <Row
            onMouseEnter={() => {
              setHoverKey("1");
            }}
            onMouseLeave={() => {
              setHoverKey("0");
            }}
            span={8}
            className={styles.tabRows}
          >
            <Col>
              <SutCaseTab
                color={
                  key === "1" || hoverKey === "1"
                    ? "var(--menu-text-color)"
                    : "var(--body-text-color)"
                }
              />
            </Col>
            <Col>
              <p
                className={`${commonCssStyle.tabsText} ${styles.tabsTitle}`}
                style={{
                  color:
                    key === "1" || hoverKey === "1"
                      ? "var(--menu-text-color)"
                      : "var(--body-text-color)",
                }}
              >
                Job
              </p>
            </Col>
          </Row>
        </>
      ),
      children: (
        <>
          <Card
            className={` ${styles.cardHeight}`}
            size="large"
            title={
              <>
                <p
                  style={{ color: "var(--headings-color)" }}
                  className={commonCssStyle.subHeadingText}
                >
                  <Avatar
                    size={50}
                    className={commonCssStyle.avtarBgClr}
                    style={{ marginRight: "12px", paddingTop: "5px" }}
                  >
                    <SutCaseTab
                      color={
                        key === "1"
                          ? "var(--primaryColor)"
                          : "var(--headings-color)"
                      }
                    />
                  </Avatar>
                  Job
                </p>
              </>
            }
            extra={
              <div
                onClick={() => {
                  setIsModalOpen(true);
                }}
                className={commonCssStyle.coursorPointer}
              >
                <EditIcon color={"var(--primaryColor)"} />
              </div>
            }
          >
            <div className={styles.tabInfo}>
              <p>
                Employee Id{" "}
                <b>: {profile && profile.empId ? profile.empId : "None"}</b>
              </p>
              <p>
                Employee Status{" "}
                <b>: {profile && profile.status ? profile.status : "None"}</b>
              </p>
              <p>
                Date of Joining{" "}
                <b>
                  :{" "}
                  {profile && profile.joiningDate
                    ? moment(profile.joiningDate).format(
                        dataFormate ? dataFormate : "DD/MM/YYYY"
                      )
                    : "None"}
                </b>
              </p>
              <p>
                Department{" "}
                <b>
                  :{" "}
                  {profile && profile.department ? profile.department : "None"}
                </b>
              </p>
              <p>
                Designation{" "}
                <b>
                  :{" "}
                  {profile && profile.designation
                    ? profile.designation
                    : "None"}
                </b>
              </p>
              <p>
                Work Location{" "}
                <b>
                  :{" "}
                  {profile && profile.workLocation.city
                    ? profile.workLocation.city
                    : "None"}
                </b>
              </p>
            </div>
          </Card>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <Row
            onMouseEnter={() => {
              setHoverKey("2");
            }}
            onMouseLeave={() => {
              setHoverKey("0");
            }}
            span={8}
            className={styles.tabRows}
          >
            <Col>
              <MapPinIcon
                color={
                  key === "2" || hoverKey === "2"
                    ? "var(--menu-text-color)"
                    : "var(--body-text-color)"
                }
              />
            </Col>
            <Col>
              <p
                className={`${commonCssStyle.tabsText} ${styles.tabsTitle}`}
                style={{
                  color:
                    key === "2" || hoverKey === "2"
                      ? "var(--menu-text-color)"
                      : "var(--body-text-color)",
                }}
              >
                Office Address
              </p>
            </Col>
          </Row>
        </>
      ),
      children: (
        <>
          <Card
            className={` ${styles.cardHeight}`}
            size="large"
            title={
              <>
                <p
                  style={{ color: "var(--headings-color)" }}
                  className={commonCssStyle.subHeadingText}
                >
                  <Avatar
                    size={50}
                    className={commonCssStyle.avtarBgClr}
                    style={{ marginRight: "12px", paddingTop: "5px" }}
                  >
                    <MapPinIcon
                      color={
                        key === "2"
                          ? "var(--primaryColor)"
                          : "var(--headings-color)"
                      }
                    />
                  </Avatar>
                  Office Address
                </p>
              </>
            }
          >
            <div className={styles.tabInfo}>
              <p>
                Office Address
                <b>
                  :{" "}
                  {profile && profile.workLocation.address
                    ? profile.workLocation.address
                    : "None"}
                </b>
              </p>
              <p>
                Country{" "}
                <b>
                  :{" "}
                  {profile && profile.workLocation.country
                    ? profile.workLocation.country
                    : "None"}
                </b>
              </p>
              <p>
                City{" "}
                <b>
                  :{" "}
                  {profile && profile.workLocation.city
                    ? profile.workLocation.city
                    : "None"}
                </b>
              </p>
              <p>
                State <b>: Karnataka</b>
              </p>
            </div>
          </Card>
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          <Row
            onMouseEnter={() => {
              setHoverKey("3");
            }}
            onMouseLeave={() => {
              setHoverKey("0");
            }}
            className={styles.tabRows}
          >
            <Col>
              <BuildIcon
                color={
                  key === "3" || hoverKey === "3"
                    ? "var(--menu-text-color)"
                    : "var(--body-text-color)"
                }
              />
            </Col>
            <Col>
              <p
                className={`${commonCssStyle.tabsText} ${styles.tabsTitle}`}
                style={{
                  color:
                    key === "3" || hoverKey === "3"
                      ? "var(--menu-text-color)"
                      : "var(--body-text-color)",
                }}
              >
                Company
              </p>
            </Col>
          </Row>
        </>
      ),
      children: (
        <>
          <Card
            className={` ${styles.cardHeight}`}
            size="large"
            title={
              <>
                <p
                  style={{ color: "var(--headings-color)" }}
                  className={commonCssStyle.subHeadingText}
                >
                  <Avatar
                    size={50}
                    className={commonCssStyle.avtarBgClr}
                    style={{ marginRight: "12px", paddingTop: "5px" }}
                  >
                    <BuildIcon
                      color={
                        key === "3"
                          ? "var(--primaryColor)"
                          : "var(--headings-color)"
                      }
                    />
                  </Avatar>
                  Company
                </p>
              </>
            }
          >
            <div className={styles.tabInfo}>
              <p>
                Name{" "}
                <b>
                  : {profile && profile.org_name ? profile.org_name : "None"}
                </b>
              </p>
              <p>
                Company Logo : &nbsp;&nbsp;
                {profile && profile.org_logo ? (
                  <Image
                    preview={false}
                    width={20}
                    src={profile.org_logo}
                    fallback={<Avatar />}
                  />
                ) : (
                  <Avatar />
                )}
              </p>
              <p>
                Email{" "}
                <b>
                  : {profile && profile.org_email ? profile.org_email : "None"}
                </b>
              </p>
              <p>
                Company contact number <b>: 9876654321</b>
              </p>
            </div>
          </Card>
        </>
      ),
    },
  ];

  return (
    <div>
      <Row style={{ marginBottom: "24px" }}>
        <Col
          md={24}
          style={{
            backgroundColor: "white",
            marginBottom: "24px",
          }}
        >
          <Tabs
            className="profiletab1"
            style={{ left: "0px", borderRadius: "5px" }}
            defaultActiveKey="2"
            tabPosition="left"
            items={dataArray1}
          />
        </Col>
      </Row>
      {profile?.roleType !== "External Recruiter" ? (
        <Row>
          <Col
            md={24}
            style={{
              backgroundColor: "white",
              marginBottom: "24px",
            }}
          >
            <Tabs
              className="profiletab leftTabsStyle"
              style={{ left: "0px", height: "317px", borderRadius: "5px" }}
              defaultActiveKey="1"
              tabPosition="left"
              onChange={(key) => setKey(key)}
              items={dataArray}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <Row>
        <Col md={8} xs={24}>
          <Button
            className={styles.resetPasswordBtn}
            onMouseEnter={() => {
              setResetColor("var(--primaryColor)");
              setResetBackColor("white");
            }}
            onMouseLeave={() => {
              setResetColor("var(--primaryColor)");
              setResetBackColor("");
            }}
            onClick={() => {
              setAddResetPasswordModal(true);
            }}
          >
            <Row>
              <Col sm={22}>
                <p
                  className={styles.resetPasswordBtnName}
                  style={{ float: "left" }}
                >
                  Reset Password
                </p>
              </Col>
              <Col sm={2}>
                <CaretRightIcon color={resetColor} />
              </Col>
            </Row>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={8} xs={24}>
          <Button
            className={styles.logOutBtn}
            onClick={() => {
              logoutAction(navigate);
            }}
            // style={{
            //   width: "419px",
            //   height: "60px",
            //   marginTop: "24px",
            //   borderRadius: "8px",
            //   border: "3px solid var(--primaryColor)",
            //   background: "transparent",
            //   color: "var(--primaryColor)",
            // }}
          >
            <Row
              style={{
                width: "100%",
                display: "block",
                position: "relative",
                margin: "9px auto",
              }}
            >
              <Col md={24}>
                <Row justify="center" style={{ gap: "5px" }}>
                  <Col /* style={{ margin: "10px 0px 16px 135px" }} */>
                    <p className={styles.logoutPasswordBtnName}>Log Out</p>
                  </Col>
                  <Col /* style={{ margin: "11px 10px 16px 16px" }} */>
                    <SignOutIcon color={"var(--primaryColor)"} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Button>
        </Col>
      </Row>
      {profile ? (
        <>
          {isModalOpenName ? (
            <NameModal
              onOpenName={isModalOpenName}
              onCloseName={() => setIsModalOpenName(false)}
              profile={profile}
              restData={profileUpdateAction}
            />
          ) : (
            ""
          )}
          {isModalOpenPINfo ? (
            <PersonalInformationModal
              onOpenPINfo={isModalOpenPINfo}
              onClosePINfo={() => setIsModalOpenPINfo(false)}
              profile={profile}
              settings={settings}
              updatedProfileInfo={profileUpdateAction}
            />
          ) : (
            ""
          )}
          {isModalOpen ? (
            <JobModal
              onOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              profile={profile}
              updatedJobIngo={profileUpdateAction}
            />
          ) : (
            ""
          )}
          {addResetPasswordModal ? (
            <ResetPasswordModal
              profile={profile}
              onOpenModal={addResetPasswordModal}
              onCloseModal={() => setAddResetPasswordModal(false)}
              passwordResetActionHandle={passwordResetAction}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    profile: state.authentication.profileData,
    settings: state.settings.settings,
    employeeJobs: state.employee.employee_jobs,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutAction: (navigate) => {
      dispatch(logoutAction(navigate));
    },
    passwordResetAction: (val, callback) => {
      dispatch(passwordResetAction(val, callback));
    },
    profileAction: () => {
      dispatch(profileAction());
    },
    profileUpdateAction: (val, callback) => {
      dispatch(profileUpdateAction(val, callback));
    },
    getEmployeesAssignToJobHandler: (id) => {
      dispatch(getEmployeesAssignToJobAction(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TabsPage);
