import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  DatePicker,
  Image,
  message,
} from "antd";
import React, { useEffect } from "react";
import { CloseIcon } from "../../IconsComp/Icons";
import "antd-country-phone-input/dist/index.css";
import "flagpack/dist/flagpack.css";
import commonStyles from "../../UI/CommonCss.module.css";
import pdf from "../../../Assets/Image/pdf.png";
import word from "../../../Assets/Image/word.png";

import { UploadIcon } from "../../IconsComp/Icons";
import { useState } from "react";
import { useRef } from "react";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
import AadTagsForSkills from "../../UI/AadTagsForSkills";

const EmployeeDetailsDrawer = (props) => {
  let btnref1 = useRef(null);
  const [filesData, setFilesData] = useState("");
  const [fileType, setFileType] = useState("");
  const [isExternal, setIsExternal] = useState(true);
  const [form] = useForm();
  let btnref = useRef(null);

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const handleChangeForDocument = (info) => {
    if (info.target.files[0].size >= 5000000) {
      message.warning("This file should be less than 5 MB", 3);
      return;
    }
    setFilesData(info.target.files[0]);
    setFileType(info.target.files[0].type);
  };
  const onSubmit = (val) => {
    let data = {
      ...val,
      joiningDate: moment(val.joiningDate).format("YYYY-MM-DD"),
      document: filesData,
    };
    props.onSubmit(data);
  };

  useEffect(() => {
    if (props?.employee?.isExternal == 1) {
      setIsExternal(false);
    }
  }, []);

  return (
    <Drawer
      className={"modalModified"}
      extra={
        <div
          onClick={() => props.employeeDetailsClose()}
          className={`${commonStyles.coursorPointer} `}
        >
          <CloseIcon color={"var(--icons-color)"} />
        </div>
      }
      footer={false}
      style={{
        paddingBottom: "0px",
      }}
      // className={styles.addcandidatetop}
      title={
        <p
          style={{ fontSize: "20px", margin: "0px" }}
          className={commonStyles.modalHeading}
        >
          Edit Employement Details
        </p>
      }
      height={48}
      width={806}
      closable={false}
      open={props.employeeDetailsOpen}
    >
      <Form
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
        form={form}
        initialValues={
          props.employee && {
            empId: props.employee.empId,
            joiningDate:
              props.employee.joiningDate && moment(props.employee.joiningDate),
            employmentType: props.employee.employmentType,
            designation: props.employee.designation,
            department: props.employee.department,
            reportingManagerId: props.employee.reportingManagerId,
            workLocationId: props.employee.workLocationId,
            monthlySalary: props.employee.monthlySalary,
            perDayCost: props.employee.perDayCost,
          }
        }
        layout="vertical"
        onFinish={onSubmit}
      >
        <Row className={commonStyles.modalRowStyling}>
          <Col md={24}>
            <Form.Item
              label="Employee ID"
              name="empId"
              rules={[
                {
                  required: isExternal,
                  message: "Employee id is required",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9\s]+$/,
                //   message: "Invalid employee id",
                // },
              ]}
            >
              <Input placeholder="Enter employee id"></Input>
            </Form.Item>

            <Form.Item label="Joining Date " name="joiningDate">
              <DatePicker
                format={dataFormate}
                className={commonStyles.datePickerBgColor}
                locale={"en"}
                style={{ width: "100%" }}
                placeholder="Select joining date"
              ></DatePicker>
            </Form.Item>

            <Form.Item
              label="Employment Type"
              name="employmentType"
              rules={[
                {
                  required: isExternal,
                  message: "Employment type is required",
                },
              ]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                allowClear
                style={{
                  width: "100%",
                }}
                className={`${commonStyles.border} candidateSelectHeight`}
                placeholder="Select employment type"
              >
                {props.employmentType &&
                  props.employmentType.map((data, index) => {
                    return (
                      <Select.Option key={index} value={data.jobType}>
                        {data.jobType}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Department"
              name="department"
              rules={[
                {
                  required: isExternal,
                  message: "Department is required",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                //   message:
                //     "It allows only alphabets,no whitespace at the beginning and end",
                // },
              ]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                style={{
                  width: "100%",
                }}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <AadTagsForSkills onType={"department"} />
                  </>
                )}
                className={`${commonStyles.border} candidateSelectHeight`}
                placeholder="Select department"
              >
                {props.departments &&
                  props.departments.map((data, index) => {
                    return (
                      <Select.Option key={index} value={data.departmentName}>
                        {data.departmentName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Reporting Manager ID"
              name="reportingManagerId"
              rules={[
                {
                  required: false,
                  message: "Reporting manager iD is required",
                },
              ]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                allowClear
                style={{
                  width: "100%",
                }}
                className={`${commonStyles.border} candidateSelectHeight`}
                placeholder="Select reporting manager"
              >
                {props.employee &&
                  props.employee.reportingManagers &&
                  props.employee.reportingManagers.map((data, index) => {
                    return (
                      <Select.Option key={index} value={data.id}>
                        {data.firstName + " " + data.lastName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Work Location"
              name="workLocationId"
              rules={[
                {
                  required: isExternal,
                  message: "Work location is required",
                },
              ]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder="Select work location"
              >
                {props.workLocation &&
                  props.workLocation.map((data, index) => {
                    return (
                      <Select.Option key={index} value={data.id}>
                        {data.locationName}, {data.address}, {data.city},{" "}
                        {data.state}, {data.country}, {data.pincode}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              name="monthlySalary"
              label="Salary (CTC in Lakhs)"
              rules={[
                {
                  required: isExternal,
                  message: "Salary  is required",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9\s]+$/,
                //   message:
                //     "It allows only alphanumerics,no whitespace at the beginning and end",
                // },
              ]}
            >
              <Input placeholder="Enter salary" />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: "110px" }}
              name="perDayCost"
              label="Per day cost"
              rules={[
                {
                  required: isExternal,
                  message: "Per day cost  is required",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9\s]+$/,
                //   message:
                //     "It allows only Alphanumerics,No whitespace at the beginning and end",
                // },
              ]}
            >
              <Input placeholder="Enter per day cost" />
            </Form.Item>
          </Col>
        </Row>

        <Col xs={24} md={24} className={commonStyles.footerShadow}>
          <Row justify="end" style={{ gap: "17px", width: "770px" }}>
            <Col>
              <Button
                onClick={props.employeeDetailsClose}
                style={{
                  width: "150px",
                  height: "48px",
                }}
                className={commonStyles.footerButtonAddCandidateDrawerSecondary}
              >
                Cancel
              </Button>
            </Col>

            <Col>
              <Button
                onClick={() => {
                  form.submit();
                }}
                htmlType="button"
                className={commonStyles.footerButtonAddCandidateDrawerPrimary}
                type="primary"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Form>
    </Drawer>
  );
};

export default EmployeeDetailsDrawer;
