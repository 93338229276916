import React, { useEffect, useState } from "react";
import styles from "../../Dashboard/ContentPage/ContentPage.module.css";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import { Breadcrumb, Card, Col, Row } from "antd";
import { connect } from "react-redux";
// import { getDashboardAction } from "../../../../Reusable/Store/Action/DashboardAction";
import { getCandidatesSummaryReportsAction } from "../../../../Reusable/Store/Action/ReportsAction";
import { Link } from "react-router-dom";
function ReportsCandidateSummary(props) {
  const [mouseKey, setMouseKey] = useState("");

  //color for candidatesummary report
  const [cardBackgroundColor1, setCardBackgroundColor1] =
    useState("var(--theme)");
  const [cardBackgroundColor2, setCardBackgroundColor2] =
    useState("var(--theme)");
  const [cardBackgroundColor3, setCardBackgroundColor3] =
    useState("var(--theme)");
  const [cardBackgroundColor4, setCardBackgroundColor4] =
    useState("var(--theme)");
  const [cardBackgroundColor5, setCardBackgroundColor5] =
    useState("var(--theme)");
  const [cardBackgroundColor6, setCardBackgroundColor6] =
    useState("var(--theme)");
  const [cardBackgroundColor7, setCardBackgroundColor7] =
    useState("var(--theme)");
  const [cardBackgroundColor8, setCardBackgroundColor8] =
    useState("var(--theme)");
  const [cardBackgroundColor11, setCardBackgroundColor11] =
    useState("var(--theme)");
  const [taskRowBgColor, setTaskRowBgColor] = useState("var(--theme)");
  //end


  const [keys,setKeys]=useState(0);


  useEffect(() => {
    props.getReportsCandidateSummaryHandler();
  }, []);

  return (
    <>
      <Breadcrumb className={commonCssStyle.breadcrumbBottomMargin}>
        <Breadcrumb.Item>
          <Link to={"/"}>
            <p
              className={`${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove} ${commonCssStyle.breadcrumbText}`}
            >
              Home
            </p>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/reports/candidatesummary"}>
          <p
              className={`${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove} ${commonCssStyle.breadcrumbText}`}
            >
              Candidate Summary
            </p>
           </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.contentContainer} id="Dashboard-candidateSummary">
        <h3
          className={commonCssStyle.subHeadingText}
          style={{ marginBottom: "25px", color: "var(--headings-color)" }}
        >
          Candidate Summary
        </h3>

        <Row justify="space-evenly">
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor1("#2f7bf5");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor1("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor1}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "active",breadcrum:"Total" }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.ReportsCandidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary
                          .activeCount &&
                        props.ReportsCandidateSummary.candidateSummary
                          .activeCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>Total</p>
                  </Col>
                </Row>
              </Link>
            </Card>
          </Col>
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor7("#0CA2D1");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor7("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor7}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "applied",breadcrum:"Applied" }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#0CA2D1" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.ReportsCandidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary
                          .appliedCount &&
                        props.ReportsCandidateSummary.candidateSummary
                          .appliedCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>
                      Applied
                    </p>
                  </Col>
                </Row>
              </Link>
            </Card>
          </Col>
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor2("#0CA2D1");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor2("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor2}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "screening",breadcrum:"Screening" }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#0CA2D1" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.ReportsCandidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary
                          .screeningCount &&
                        props.ReportsCandidateSummary.candidateSummary
                          .screeningCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>
                      Screening
                    </p>
                  </Col>
                </Row>
              </Link>
            </Card>
          </Col>
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor3("#FF9A02");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor3("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor3}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "interviewed",breadcrum:"Interviewing" }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#FF9A02" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.ReportsCandidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary
                          .interviewCount &&
                        props.ReportsCandidateSummary.candidateSummary
                          .interviewCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>
                      Interviewing
                    </p>
                  </Col>
                </Row>
              </Link>
            </Card>
          </Col>
        </Row>
        <Row justify="space-evenly">
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor4("#0E8553");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor4("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor4}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "offered",breadcrum:"Offered" }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#0E8553" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.ReportsCandidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary
                          .offeredCount &&
                        props.ReportsCandidateSummary.candidateSummary
                          .offeredCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>
                      Offered
                    </p>
                  </Col>
                </Row>
              </Link>
            </Card>
          </Col>
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor5("#0E8553");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor5("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor5}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "hired",breadcrum:"Hired" }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#0E8553" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.ReportsCandidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary
                          .hiredCount &&
                        props.ReportsCandidateSummary.candidateSummary
                          .hiredCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>Hired</p>
                  </Col>
                </Row>
              </Link>
            </Card>
          </Col>
         
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor8("#FD5858");
                setKeys(8);
              }}
              onMouseLeave={() => {
                setCardBackgroundColor8("var(--theme)");
                setKeys(0);
              }}
              style={{
                border: keys===8&& `3px solid ${cardBackgroundColor8}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "offerDeclined",breadcrum:"Offer Declined" }}
              >
              <Row>
                <Col md={24}>
                  <h3
                    style={{ color: "#FD5858" }}
                    className={styles.candidateSummaryCardTitle}
                  >
                   {props.ReportsCandidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary
                          .offerdeclinedCount &&
                        props.ReportsCandidateSummary.candidateSummary
                          .offerdeclinedCount}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <p className={styles.candidateSummaryCardSubTitle}>
                  Offer Declined

                  </p>
                </Col>
              </Row>
              </Link>
            </Card>
          </Col>


          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor8("#FD5858");
                setKeys(9);
              }}
              onMouseLeave={() => {
                setCardBackgroundColor8("var(--theme)");
                setKeys(0);
              }}
              style={{
                border: keys===9&& `3px solid ${cardBackgroundColor8}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "candidateDeclined",breadcrum:"Candidate Declined" }}
              >
              <Row>
                <Col md={24}>
                  <h3
                    style={{ color: "#FD5858" }}
                    className={styles.candidateSummaryCardTitle}
                  >
                   {props.ReportsCandidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary
                          .candiatedeclinedCount &&
                        props.ReportsCandidateSummary.candidateSummary
                          .candiatedeclinedCount}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <p className={styles.candidateSummaryCardSubTitle}>
                  Candidate Declined
                  </p>
                </Col>
              </Row>
              </Link>
            </Card>
          </Col>

        </Row>

        <Row justify="space-evenly">
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor8("#FD5858");
                setKeys(10);
              }}
              onMouseLeave={() => {
                setCardBackgroundColor8("var(--theme)");
                setKeys(0);
              }}
              style={{
                border: keys===10&& `3px solid ${cardBackgroundColor8}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "notInConsideration",breadcrum:"Not In Consideration" }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#FD5858" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.ReportsCandidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary
                          .notInConsiderationCount &&
                        props.ReportsCandidateSummary.candidateSummary
                          .notInConsiderationCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>
                    Not In Consideration
                    </p>
                  </Col>
                </Row>
              </Link>
            </Card>
          </Col>
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor8("#FD5858");
                setKeys(11);
              }}
              onMouseLeave={() => {
                setCardBackgroundColor8("var(--theme)");
                setKeys(0);
              }}
              style={{
                border: keys===11&& `3px solid ${cardBackgroundColor8}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "rejected",breadcrum:"Rejected" }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#FD5858" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.ReportsCandidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary
                          .rejectedCount &&
                        props.ReportsCandidateSummary.candidateSummary
                          .rejectedCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>Rejected</p>
                  </Col>
                </Row>
              </Link>
            </Card>
          </Col>
         
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor8("#FD5858");
                setKeys(12);
              }}
              onMouseLeave={() => {
                setCardBackgroundColor8("var(--theme)");
                setKeys(0);
              }}
              style={{
                border: keys===12&& `3px solid ${cardBackgroundColor8}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "absconded",breadcrum:"Absconded" }}
              >
              <Row>
                <Col md={24}>
                  <h3
                    style={{ color: "#FD5858" }}
                    className={styles.candidateSummaryCardTitle}
                  >
                   {props.ReportsCandidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary
                          .abscondedCount &&
                        props.ReportsCandidateSummary.candidateSummary
                          .abscondedCount}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <p className={styles.candidateSummaryCardSubTitle}>
                  Absconded
                  </p>
                </Col>
              </Row>
              </Link>
            </Card>
          </Col>


          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor8("#FD5858");
                setKeys(13);
              }}
              onMouseLeave={() => {
                setCardBackgroundColor8("var(--theme)");
                setKeys(0);
              }}
              style={{
                border: keys===13&& `3px solid ${cardBackgroundColor8}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "blacklisted",breadcrum:"Blacklisted" }}
              >
              <Row>
                <Col md={24}>
                  <h3
                    style={{ color: "#FD5858" }}
                    className={styles.candidateSummaryCardTitle}
                  >
                   {props.ReportsCandidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary
                          .blacklistedCount &&
                        props.ReportsCandidateSummary.candidateSummary
                          .blacklistedCount}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <p className={styles.candidateSummaryCardSubTitle}>
                  Blacklisted
                  </p>
                </Col>
              </Row>
              </Link>
            </Card>
          </Col>

         
        </Row>


        <Row justify="space-evenly">
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor8("#FD5858");
                setKeys(15);
              }}
              onMouseLeave={() => {
                setCardBackgroundColor8("var(--theme)");
                setKeys(0);
              }}
              style={{
                border: keys===15&& `3px solid ${cardBackgroundColor8}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "quit",breadcrum:"Quit" }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#FD5858" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.ReportsCandidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary &&
                        props.ReportsCandidateSummary.candidateSummary
                          .quitCount &&
                        props.ReportsCandidateSummary.candidateSummary
                          .quitCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>
                    Quit
                    </p>
                  </Col>
                </Row>
              </Link>
            </Card>
          </Col>

          <Col style={{visibility:"hidden"}}>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor5("#0E8553");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor5("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor5}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "hired",breadcrum:"Hired" }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#0E8553" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                     
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>deleted</p>
                  </Col>
                </Row>
              </Link>
            </Card>
          </Col>
         
          <Col style={{visibility:"hidden"}}>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor8("#FD5858");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor8("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor8}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "notInConsideration",breadcrum:"Not in Consideration" }}
              >
              <Row>
                <Col md={24}>
                  <h3
                    style={{ color: "#FD5858" }}
                    className={styles.candidateSummaryCardTitle}
                  >
                  
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <p className={styles.candidateSummaryCardSubTitle}>
                  Absconded
                  </p>
                </Col>
              </Row>
              </Link>
            </Card>
          </Col>


          <Col style={{visibility:"hidden"}}>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor8("#FD5858");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor8("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor8}`,
              }}
            >
              <Link
                to={"/reports/candidatedetailtable"}
                state={{ from: "notInConsideration",breadcrum:"Not in Consideration" }}
              >
              <Row>
                <Col md={24}>
                  <h3
                    style={{ color: "#FD5858" }}
                    className={styles.candidateSummaryCardTitle}
                  >
                  
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <p className={styles.candidateSummaryCardSubTitle}>
                  Blacklisted
                  </p>
                </Col>
              </Row>
              </Link>
            </Card>
          </Col>

         
        </Row>
      </div>

      <div className={styles.contentContainer} id="Dashboard-candidateSummary">
        <h3
          className={commonCssStyle.subHeadingText}
          style={{ marginBottom: "25px", color: "var(--headings-color)" }}
        >
          Lead Summary
        </h3>

        <Row justify="space-evenly">
          {props.ReportsCandidateSummary &&
            props.ReportsCandidateSummary.leadSummary &&
            props.ReportsCandidateSummary.leadSummary.map((data, index) => {
              return (
                <>
                  <Col>
                    <Card
                      key={index}
                      className={styles.cards}
                      onMouseEnter={() => {
                        setMouseKey(index);
                        setCardBackgroundColor11("#2f7bf5");
                      }}
                      onMouseLeave={() => {
                        setCardBackgroundColor11("var(--theme)");
                        setMouseKey("");
                      }}
                      style={{
                        border:
                          mouseKey === index &&
                          `3px solid ${cardBackgroundColor11}`,
                      }}
                    >
                      <Link
                        to={"/reports/candidatedetailtable"}
                        state={{
                          leadSource:
                            data && data.source && data.source && data.source,
                            breadcrum:data && data.source && data.source && data.source,
                        }}
                      >
                        <Row>
                          <Col md={24}>
                            <h3
                              style={{ color: "#2f7bf5" }}
                              className={styles.candidateSummaryCardTitle}
                            >
                              {data && data.count && data.count}
                            </h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={24}>
                            <p className={styles.candidateSummaryCardSubTitle}>
                              {data && data.source && data.source}
                            </p>
                          </Col>
                        </Row>
                      </Link>
                    </Card>
                  </Col>
                </>
              );
            })}
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ReportsCandidateSummary: state.reports.reportsCandidateSummary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReportsCandidateSummaryHandler: () => {
      dispatch(getCandidatesSummaryReportsAction());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsCandidateSummary);
