import { Button, Card, Col, Modal, Row, Table } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  CloseIcon,
  CopyIcon,
  EditBorderIcon,
  EditIconBlueClr,
} from "../../IconsComp/Icons";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import commonCssStyle from "../../UI/CommonCss.module.css";
import JobInfoEditDrawer from "./JobInfoEditDrawer";
import { getRequirementAction } from "../../../Reusable/Store/Action/RequirementAction";
import { connect } from "react-redux";
import { putRequirementsAction } from "../../../Reusable/Store/Action/RequirementAction";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getJobDescriptionsAction } from "../../../Reusable/Store/Action/Master/MasterJobDescriptionAction";
import { getSharingMediumsAction } from "../../../Reusable/Store/Action/Master/MasterSharingMediumAction";
import { Link } from "react-router-dom";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import { checkPermissionReducer } from "../../../Reusable/Store/Slice/AuthPermissionsSlice";
import PermissionMessage from "../../UI/PermissionMessage";

function JobInfo(props) {
  const [editRequirementDrawer, setEditRequirementDrawer] = useState(false);
  const [modalName, setModalName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [copyValues, setCopyValues] = useState("");
  const [copied, setCopied] = useState(false);
  const [mouseKey, setMouseKey] = useState(-1);
  const [mouseKey1, setMouseKey1] = useState(-1);
  const closeJobInfoHandler = () => {
    setEditRequirementDrawer(false);
  };
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });

  const clearCopied = () => {
    setTimeout(() => {
      setMouseKey(-1);
      setMouseKey1(-1);
    }, 2000);
  };

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Name
        </p>
      ),
      width: "40%",
      dataIndex: "name",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
      key: "name",
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Email
        </p>
      ),
      width: "50%",
      dataIndex: "email",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
      key: "email",
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Phone
        </p>
      ),
      dataIndex: "phone",
      width: "40%",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
      key: "phone",
    },
  ];

  return (
    <>
      <Card className={` ${styles.jobInfoCard} ${commonCssStyle.overflow}`}>
        {props.requiremtIdData &&
          props.permissions &&
          props.permissions.find((data) => data === "job-edit") && (
            <Row justify="end">
              <Col>
                <span
                  onClick={() => {
                    props.getCheckPermissionHandler("job-edit", (success) => {
                      if (success) {
                        setEditRequirementDrawer(true);
                        setModalName("jobLocation");
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Job Edit",
                        });
                      }
                    });
                  }}
                  className={commonCssStyle.coursorPointer}
                >
                  <EditBorderIcon color={"var(--primaryColor)"} />
                </span>
              </Col>
            </Row>
          )}

        {/* //demo  */}

        <Row className={commonCssStyle.gapOnTop}>
          <Col md={24}>
            <Row gutter={[0, 16]}>
              <Col xs={24}>
                {props.requiremtIdData && (
                  <Button
                    width={"400px"}
                    type="primary"
                    shape="round"
                    className={commonCssStyle.intaddButtonp}
                  >
                    {props.requiremtIdData &&
                    props.requiremtIdData.makePublic == 1
                      ? "Public Job"
                      : "Private Job"}
                  </Button>
                )}

                {props.recruitmentIdData && (
                  <Button
                    className={commonCssStyle.intaddButtonp}
                    width={"400px"}
                    type="primary"
                    shape="round"
                  >
                    {props.recruitmentIdData &&
                    props.recruitmentIdData.makePublic == 1
                      ? "Public Job"
                      : "Private Job"}
                  </Button>
                )}
              </Col>

              <Col xs={24}>
                {props.requiremtIdData && (
                  <Button
                    width={"400px"}
                    type="primary"
                    shape="round"
                    className={commonCssStyle.intaddButtonp}
                  >
                    {props.requiremtIdData &&
                    props.requiremtIdData.hiringType == "internal"
                      ? "Internal Hiring"
                      : "External Hiring"}
                  </Button>
                )}
                {props.recruitmentIdData && (
                  <Button
                    className={commonCssStyle.intaddButtonp}
                    width={"400px"}
                    type="primary"
                    shape="round"
                  >
                    {props.recruitmentIdData &&
                    props.recruitmentIdData.hiringType == "internal"
                      ? "Internal Hiring"
                      : "External Hiring"}
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        {/* end of demo  */}

        <Row className={commonCssStyle.gapOnTop}>
          <Col md={24}>
            <Row justify="space-between">
              <Col xs={24} md={11}>
                <p
                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor} `}
                >
                  Job Location
                </p>
              </Col>
              <Col xs={24} md={11}>
                {props.requiremtIdData && (
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment}  ${commonCssStyle.textBreak} `}
                  >
                    {props.requiremtIdData && props.requiremtIdData
                      ? props.requiremtIdData.locationDetails
                      : "NA"}
                  </p>
                )}
                {props.recruitmentIdData && (
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment}  ${commonCssStyle.textBreak}`}
                  >
                    {props.recruitmentIdData && props.recruitmentIdData
                      ? props.recruitmentIdData.locationDetails
                      : "NA"}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className={commonCssStyle.gapOnTop}>
          <Col md={24}>
            <Row justify="space-between">
              <Col xs={24} md={11}>
                <p
                  className={`${commonCssStyle.marginBottomRemove}  ${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor} `}
                >
                  Area
                </p>
              </Col>
              <Col xs={24} md={11}>
                {props.requiremtIdData && (
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment}   ${commonCssStyle.textBreak}  `}
                  >
                    {props.requiremtIdData &&
                    props.requiremtIdData &&
                    props.requiremtIdData.areaDetails
                      ? props.requiremtIdData.areaDetails
                      : "NA"}
                  </p>
                )}
                {props.recruitmentIdData && (
                  <p
                    className={`${commonCssStyle.tableData}  ${commonCssStyle.textAlignment}  ${commonCssStyle.textBreak} `}
                  >
                    {props.recruitmentIdData &&
                    props.recruitmentIdData &&
                    props.recruitmentIdData.areaDetails
                      ? props.recruitmentIdData.areaDetails
                      : "NA"}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        {props.requiremtIdData && (
          <Row className={commonCssStyle.gapOnTop}>
            <Col md={24}>
              <Row justify="space-between">
                <Col xs={24} md={11}>
                  <p
                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                  >
                    Work Mode
                  </p>
                </Col>
                <Col xs={24} md={11}>
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment}  `}
                  >
                    {props.requiremtIdData
                      ? props.requiremtIdData.workMode
                      : "NA"}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Row className={commonCssStyle.gapOnTop}>
          <Col md={24}>
            <Row justify="space-between">
              <Col xs={24} md={11}>
                <p
                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.textBreak}  ${commonCssStyle.tableHeadingsColor}`}
                >
                  Department
                </p>
              </Col>
              <Col xs={24} md={11}>
                {props.requiremtIdData && (
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment}  ${commonCssStyle.textBreak} `}
                  >
                    {props.requiremtIdData
                      ? props.requiremtIdData.department
                      : "NA"}
                  </p>
                )}
                {props.recruitmentIdData && (
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment}  ${commonCssStyle.textBreak} `}
                  >
                    {props.recruitmentIdData
                      ? props.recruitmentIdData.department
                      : "NA"}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={commonCssStyle.gapOnTop}>
          <Col md={24}>
            <Row justify="space-between">
              <Col xs={24} md={11}>
                <p
                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor}`}
                >
                  Work Duration
                </p>
              </Col>
              <Col xs={24} md={11}>
                {props.requiremtIdData && (
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment} `}
                  >
                    {props.requiremtIdData
                      ? props.requiremtIdData.workDuration
                      : "NA"}
                  </p>
                )}
                {props.recruitmentIdData && (
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment} `}
                  >
                    {props.recruitmentIdData
                      ? props.recruitmentIdData.workDuration
                      : "NA"}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={commonCssStyle.gapOnTop}>
          <Col md={24}>
            <Row justify="space-between">
              <Col xs={24} md={11}>
                <p
                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                >
                  CTC (in Lakhs)
                </p>
              </Col>
              <Col xs={24} md={11}>
                {props.requiremtIdData && (
                  <p
                    className={`${commonCssStyle.tableData}  ${commonCssStyle.textAlignment} `}
                  >
                    {`${
                      props.requiremtIdData
                        ? props.requiremtIdData.minSalary
                        : "NA"
                    } - ${
                      props.requiremtIdData
                        ? props.requiremtIdData.maxSalary
                        : "NA"
                    }`}
                  </p>
                )}
                {props.recruitmentIdData && (
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment} `}
                  >
                    {`${
                      props.recruitmentIdData
                        ? props.recruitmentIdData.minSalary
                        : "NA"
                    } - ${
                      props.recruitmentIdData
                        ? props.recruitmentIdData.maxSalary
                        : "NA"
                    }`}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className={commonCssStyle.gapOnTop}>
          <Col md={24}>
            <Row justify="space-between">
              <Col xs={24} md={11}>
                <p
                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor}`}
                >
                  Currency
                </p>
              </Col>
              <Col xs={24} md={11}>
                {props.requiremtIdData && (
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment} `}
                  >
                    {props.requiremtIdData
                      ? props.requiremtIdData.currency
                      : "NA"}{" "}
                    &nbsp;{" "}
                    {props.requiremtIdData
                      ? props.requiremtIdData.currencyText
                      : "NA"}
                  </p>
                )}
                {props.recruitmentIdData && (
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment} `}
                  >
                    {props.recruitmentIdData
                      ? props.recruitmentIdData.currency
                      : "NA"}{" "}
                    &nbsp;
                    {props.recruitmentIdData
                      ? props.recruitmentIdData.currencyText
                      : "NA"}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={commonCssStyle.gapOnTop}>
          <Col md={24}>
            <Row justify="space-between">
              <Col xs={24} md={11}>
                <p
                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                >
                  Job Code
                </p>
              </Col>
              <Col xs={24} md={11}>
                {props.requiremtIdData && (
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment}   ${commonCssStyle.textBreak}`}
                  >
                    {props.requiremtIdData
                      ? props.requiremtIdData.jobCode
                      : "NA"}
                  </p>
                )}
                {props.recruitmentIdData && (
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment}  ${commonCssStyle.textBreak}`}
                  >
                    {props.recruitmentIdData
                      ? props.recruitmentIdData.jobCode
                      : "NA"}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {props.recruitmentIdData && (
          <Row className={commonCssStyle.gapOnTop}>
            <Col md={24}>
              <Row justify="space-between">
                <Col xs={24} md={11}>
                  <p
                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                  >
                    Required positions
                  </p>
                </Col>
                <Col xs={24} md={11}>
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment}  ${commonCssStyle.textBreak} `}
                  >
                    {props.recruitmentIdData &&
                    props.recruitmentIdData.positionRequired
                      ? props.recruitmentIdData.positionRequired
                      : "NA"}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {props.requiremtIdData && (
          <Row className={commonCssStyle.gapOnTop}>
            <Col md={24}>
              <Row justify="space-between">
                <Col xs={24} md={11}>
                  <p
                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                  >
                    Required positions
                  </p>
                </Col>
                <Col xs={24} md={11}>
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment}  ${commonCssStyle.textBreak} `}
                  >
                    {props.requiremtIdData &&
                    props.requiremtIdData.positionRequired
                      ? props.requiremtIdData.positionRequired
                      : "NA"}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {props.requiremtIdData && (
          <Row className={commonCssStyle.gapOnTop}>
            <Col md={24}>
              <Row justify="space-between">
                <Col xs={24} md={11}>
                  <p
                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                  >
                    Job Priority
                  </p>
                </Col>
                <Col xs={24} md={11}>
                  <p
                    className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment}  ${commonCssStyle.textBreak} `}
                  >
                    {props.requiremtIdData
                      ? props.requiremtIdData.priority
                      : "NA"}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {/* start demo code  */}

        {props.recruitmentIdData &&
          props.recruitmentIdData.recruiters.length <= 1 && (
            <Row className={commonCssStyle.gapOnTop}>
              <Col md={24}>
                <Row justify="space-between">
                  <Col xs={24} md={11}>
                    <p
                      className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor}`}
                    >
                      Recruiter Assigned
                    </p>
                  </Col>
                  <Col xs={24} md={11}>
                    <p
                      className={`${commonCssStyle.tableData} ${commonCssStyle.textAlignment}  ${commonCssStyle.textBreak} `}
                    >
                      {props.recruitmentIdData &&
                      props.recruitmentIdData.recruiters &&
                      props.recruitmentIdData.recruiters.length != 0 &&
                      props.recruitmentIdData.recruiters[0].name
                        ? props.recruitmentIdData.recruiters[0].name
                        : "NA"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        {props.recruitmentIdData &&
          props.recruitmentIdData.recruiters.length > 1 && (
            <Row className={commonCssStyle.gapOnTop}>
              <Col>
                <p
                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor}`}
                >
                  Recruiter Assigned
                </p>
                <p
                  className={`${commonCssStyle.tableData} ${commonCssStyle.textBreak} `}
                >
                  <Row>
                    <Col>
                      {" "}
                      {props.recruitmentIdData
                        ? props.recruitmentIdData.recruiters[0].name
                        : "NA"}
                    </Col>
                    <Col
                      className={`${commonCssStyle.coursorPointer}`}
                      onClick={() => {
                        setModalOpen(true);
                      }}
                    >
                      {" "}
                      &nbsp;&nbsp; &#40; +
                      {props.recruitmentIdData
                        ? props.recruitmentIdData.recruiters.length - 1
                        : "NA"}{" "}
                      &#41;
                    </Col>
                  </Row>
                </p>
              </Col>
            </Row>
          )}

        {props.recruitmentIdData && (
          <Row className={commonCssStyle.gapOnTop}>
            <Col>
              <p
                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor} ${commonCssStyle.textBreak}`}
              >
                Recruiter Assigned Details
              </p>
              <p
                className={`${commonCssStyle.tableData}  ${commonCssStyle.textBreak}`}
              >
                {props.recruitmentIdData &&
                props.recruitmentIdData.recruiters &&
                props.recruitmentIdData.recruiters.length != 0 &&
                props.recruitmentIdData.recruiters[0].email
                  ? props.recruitmentIdData.recruiters[0].email
                  : "NA"}
              </p>
              <p
                className={`${commonCssStyle.tableData} ${commonCssStyle.textBreak} `}
                style={{ marginTop: "-10px" }}
              >
                {props.recruitmentIdData &&
                props.recruitmentIdData.recruiters &&
                props.recruitmentIdData.recruiters.length != 0 &&
                props.recruitmentIdData.recruiters[0].phone
                  ? props.recruitmentIdData.recruiters[0].phone
                  : "NA"}
              </p>
            </Col>
          </Row>
        )}

        {/* end of demo code  */}

        <Row className={commonCssStyle.gapOnTop}>
          {props.requiremtIdData && (
            <Col>
              <p
                className={`${commonCssStyle.textBreak} ${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
              >
                Job Link
              </p>
              {props.requiremtIdData &&
              props.requiremtIdData.trackingLink.length > 0
                ? props.requiremtIdData.trackingLink.map((data, index) => {
                    return (
                      <Row key={data.index} style={{ flexDirection: "column" }}>
                        <Col style={{ marginTop: "7px" }}>
                          <span
                            className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                          >
                            Medium:&nbsp;
                          </span>
                          <span className={`${commonCssStyle.tableData} `}>
                            {data.sharingMedium}
                          </span>
                        </Col>
                        <Col style={{ marginTop: "3px" }}>
                          <Row>
                            <Col>
                              <span
                                className={`${commonCssStyle.textBreak} ${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                              >
                                Link:&nbsp;
                              </span>
                              <span
                                className={`${commonCssStyle.textBreak} ${commonCssStyle.tableData} ${commonCssStyle.marginTop}`}
                                value={copyValues}
                                // setCopyValues={data.trackingLink}
                              >
                                {/* <Link
                                  href={data.trackingLink && data.trackingLink}
                                  target="_blank"
                                >
                                  {data.trackingLink.substring(0, 20) + "..."}
                                </Link> */}
                                <a
                                  href={data.trackingLink && data.trackingLink}
                                  target="_blank"
                                >
                                  {data.trackingLink.substring(0, 20) + "..."}
                                </a>
                              </span>
                            </Col>
                            <Col
                              push={1}
                              className={commonCssStyle.coursorPointer}
                            >
                              <CopyToClipboard
                                text={data.trackingLink}
                                onCopy={() => setCopied(true)}
                              >
                                {/* <span
                                  onClick={() => {
                                    setCopyValues(data.trackingLink);
                                  }}
                                >
                                  <CopyIcon />
                                </span> */}
                                <Button
                                  style={{
                                    width: "auto",
                                    fontSize: "14px",
                                    height: "auto",
                                  }}
                                  type="primary"
                                  className={
                                    commonCssStyle.buttonSettingsPrimary
                                  }
                                  onClick={() => {
                                    setCopyValues(data.trackingLink);
                                    setMouseKey(index);
                                    clearCopied();
                                  }}
                                >
                                  {mouseKey == index ? "Copied" : "Copy"}
                                </Button>
                              </CopyToClipboard>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })
                : ""}
            </Col>
          )}
          {props.recruitmentIdData && (
            <Col>
              <p
                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
              >
                Job Link
              </p>
              {props.recruitmentIdData &&
              props.recruitmentIdData.trackingLink.length > 0
                ? props.recruitmentIdData.trackingLink.map((data, index) => {
                    return (
                      <Row key={data.index} style={{ flexDirection: "column" }}>
                        <Col style={{ marginTop: "7px" }}>
                          <span
                            className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor} ${commonCssStyle.textBreak}`}
                          >
                            Medium:&nbsp;
                          </span>
                          <span
                            className={`${commonCssStyle.tableData} ${commonCssStyle.marginTop} ${commonCssStyle.textBreak}`}
                          >
                            {data.sharingMedium}
                          </span>
                        </Col>
                        <Col style={{ marginTop: "3px" }}>
                          <Row>
                            <Col>
                              <span
                                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor} ${commonCssStyle.textBreak}`}
                              >
                                Link:&nbsp;
                              </span>
                              <span
                                className={`${commonCssStyle.tableData} ${commonCssStyle.marginTop} ${commonCssStyle.textBreak}`}
                                value={copyValues}
                                // setCopyValues={data.trackingLink}
                              >
                                {/* <Link
                                  href={data.trackingLink && data.trackingLink}
                                  target="_blank"
                                >
                                  {data.trackingLink.substring(0, 20) + "..."}
                                </Link> */}
                                <a
                                  href={data.trackingLink && data.trackingLink}
                                  target="_blank"
                                >
                                  {data.trackingLink.substring(0, 20) + "..."}
                                </a>
                              </span>
                            </Col>
                            <Col
                              push={1}
                              className={commonCssStyle.coursorPointer}
                            >
                              <CopyToClipboard
                                text={data.trackingLink}
                                onCopy={() => setCopied(true)}
                              >
                                {/* <span
                                  onClick={() => {
                                    setCopyValues(data.trackingLink);
                                  }}
                                >
                                  <CopyIcon />
                                </span> */}
                                <Button
                                  style={{
                                    width: "auto",
                                    fontSize: "14px",
                                    height: "auto",
                                  }}
                                  type="primary"
                                  className={
                                    commonCssStyle.buttonSettingsPrimary
                                  }
                                  onClick={() => {
                                    setCopyValues(data.trackingLink);
                                    setMouseKey1(index);
                                    clearCopied();
                                  }}
                                >
                                  {mouseKey1 == index ? "Copied" : "Copy"}
                                </Button>
                              </CopyToClipboard>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })
                : ""}

              <Modal
                open={modalOpen}
                closable={false}
                footer={false}
                width={550}
                title={
                  <Row>
                    <Col md={20}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.popUpHeadingText} ${commonCssStyle.textBreak}`}
                      >
                        Recruiter Assigned Details
                      </p>
                    </Col>
                    <Col md={4}>
                      <div
                        style={{ float: "right" }}
                        onClick={() => {
                          setModalOpen(false);
                        }}
                        className={`${commonCssStyle.coursorPointer} `}
                      >
                        <CloseIcon
                          color={"var(--body-text-color)" || "var(--theme)"}
                        />
                      </div>
                    </Col>
                  </Row>
                }
              >
                <Table
                  style={{ marginTop: "-20px" }}
                  scroll={{ x: "auto" }}
                  className="AssignRecruiterTable"
                  dataSource={props.recruitmentIdData.recruiters}
                  columns={columns}
                  pagination={false}
                />
              </Modal>
            </Col>
          )}
        </Row>
      </Card>
      <JobInfoEditDrawer
        openJobInfoDrawer={editRequirementDrawer}
        onCloseJobInfo={closeJobInfoHandler}
        modalNameHandler={modalName}
        departments={props.departments}
        requiremtIdData={props.requiremtIdData}
        settindsData={props.settindsData}
        sharingMediumsData={props.sharingMediumsData}
        onSubmit={(val) => {
          const data = { ...val, _method: "PUT" };
          props.putRequirementHandler(data, "details", (success) => {
            if (success) setEditRequirementDrawer(false);
          });
        }}
      />
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
          props.setIsHavepermissionsHandler(false);
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    settindsData: state.settings.settings,
    departments: state.departments.departments,
    sharingMediumsData: state.sharingMedium.sharingMediums,
    permissions: state.authpermissions.permissions,
    ishavepermissions: state.authpermissions.ishavepermissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    putRequirementHandler: (data, type, callback) => {
      dispatch(putRequirementsAction(data, type, callback));
    },
    getJobDescriptionHandler: () => {
      dispatch(getJobDescriptionsAction());
    },
    getSharingMediumsActionHandler: () => {
      dispatch(getSharingMediumsAction());
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
    setIsHavepermissionsHandler: (data) => {
      dispatch(checkPermissionReducer(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobInfo);
