import {
  Drawer,
  Row,
  Col,
  Button,
  Tag,
  Input,
  Dropdown,
  Typography,
  Space,
  Menu,
  Rate,
  Form,
} from "antd";
import moment from "moment";
import styles from "../../AddCandidatesComp/AddCandidatesComp.module.css";
import commonCssStyles from "../../../UI/CommonCss.module.css";
import {
  CalendarBlankIcon,
  CaretBlackDownIcon,
  ClockIcon,
  CloseIcon,
  DesktopTowerIcon,
  HourglassIcon,
} from "../../../IconsComp/Icons";
import commonStyles from "../../../UI/CommonCss.module.css";
import { useState } from "react";
import { putInterViewAction } from "../../../../Reusable/Store/Action/RecruitmentAction";
import { connect } from "react-redux";
import { getTasksFilterAction } from "../../../../Reusable/Store/Action/TaskAndCalendarAction";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { useForm } from "antd/lib/form/Form";
import { activeMenuKeySlice } from "../../../../Reusable/Store/Slice/SettingsSlice";

function InterviewPendingDetails(props) {
  const [form] = useForm();
  const [menuKey, setMenuKey] = useState("1");
  const navigate = useNavigate();
  let rattingArray = [];
  const [ratingData, setRatingData] = useState([]);
  useEffect(() => {
    if (
      props.onInterviewDetails.performanceRating &&
      props.onInterviewDetails.performanceRating &&
      props.onInterviewDetails.performanceRating.length > 0
    ) {
      props.onInterviewDetails.performanceRating.map((x, i) => {
        if (
          props.onInterviewDetails.performanceRating.length !==
          rattingArray.length
        ) {
          return rattingArray.push(x.rating);
        }
      });

      form.setFieldsValue({
        comment:
          props.onInterviewDetails && props.onInterviewDetails.comment
            ? props.onInterviewDetails.comment
            : "",
      });

      setRatingData(rattingArray);
    }
  }, [props.onInterviewDetails]);

  const interviewQuestions = (data) => {
    // props.onAnswers({
    //   ...data,
    //   id: props.onJobQuestions.id,
    // });
    const interviewFeedback = {
      //id: props.onJobQuestions && props.onJobQuestions.id,
      candidateInterviewId:
        props.onInterviewDetails && props.onInterviewDetails.id,
      comment: data.comment ? data.comment : "",
      rating: ratingData,
    };
    props.onAnswers(interviewFeedback);
  };
  const menu2 = (
    <Menu
      style={{ width: "214px", margin: "11px 0px" }}
      selectable
      items={[
        {
          key: "1",
          label: (
            <p
              onClick={() => {
                setMenuKey("1");

                props.editInterviewStatusHandler(
                  {
                    id: props.onInterviewDetails.id,
                    // status: "pending",
                    status: "Pending",
                  },
                  (success) => {
                    if (success) {
                      if (props.typeData === "task") {
                        // props.getTaskFilterHandler({
                        //   type: "interview",
                        //   current_page: 1,
                        // });
                        let interviewFilterDataFromLocal = JSON.parse(
                          localStorage.getItem("interviewFilterData")
                        );
                        if (interviewFilterDataFromLocal) {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                            ...interviewFilterDataFromLocal,
                          });
                        } else {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                          });
                        }
                      } else {
                        props.fetchInterviewData();
                      }
                    }
                  }
                );
                props.onCloseInterViewDetails();
              }}
              style={{
                color:
                  menuKey === "1"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonStyles.marginBottomRemove} ${commonStyles.darkText}`}
            >
              Pending
            </p>
          ),
        },
        {
          key: "2",
          label: (
            <p
              onClick={() => {
                setMenuKey("2");

                props.editInterviewStatusHandler(
                  {
                    id: props.onInterviewDetails.id,
                    // status: "completed",
                    status: "Completed",
                  },
                  (success) => {
                    if (success) {
                      if (props.typeData === "task") {
                        // props.getTaskFilterHandler({
                        //   type: "interview",
                        //   current_page: 1,
                        // });
                        let interviewFilterDataFromLocal = JSON.parse(
                          localStorage.getItem("interviewFilterData")
                        );
                        if (interviewFilterDataFromLocal) {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                            ...interviewFilterDataFromLocal,
                          });
                        } else {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                          });
                        }
                      } else {
                        props.fetchInterviewData();
                      }
                    }
                  }
                );
                props.onCloseInterViewDetails();
              }}
              style={{
                color:
                  menuKey === "2"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonStyles.marginBottomRemove}  ${commonStyles.darkText}`}
            >
              Completed
            </p>
          ),
        },
        {
          key: "3",
          label: (
            <p
              onClick={() => {
                setMenuKey("3");

                props.editInterviewStatusHandler(
                  {
                    id: props.onInterviewDetails.id,
                    status: "Rescheduled by Client",
                  },
                  (success) => {
                    if (success) {
                      if (props.typeData === "task") {
                        // props.getTaskFilterHandler({
                        //   type: "interview",
                        //   current_page: 1,
                        // });
                        let interviewFilterDataFromLocal = JSON.parse(
                          localStorage.getItem("interviewFilterData")
                        );
                        if (interviewFilterDataFromLocal) {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                            ...interviewFilterDataFromLocal,
                          });
                        } else {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                          });
                        }
                      } else {
                        props.fetchInterviewData();
                      }
                    }
                  }
                );
                props.onCloseInterViewDetails();
              }}
              style={{
                color:
                  menuKey === "3"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonStyles.marginBottomRemove}  ${commonStyles.darkText}`}
            >
              Rescheduled by Client
            </p>
          ),
        },
        {
          key: "4",
          label: (
            <p
              onClick={() => {
                setMenuKey("4");

                props.editInterviewStatusHandler(
                  {
                    id: props.onInterviewDetails.id,
                    status: "Candidate No Show",
                  },
                  (success) => {
                    if (success) {
                      if (props.typeData === "task") {
                        // props.getTaskFilterHandler({
                        //   type: "interview",
                        //   current_page: 1,
                        // });
                        let interviewFilterDataFromLocal = JSON.parse(
                          localStorage.getItem("interviewFilterData")
                        );
                        if (interviewFilterDataFromLocal) {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                            ...interviewFilterDataFromLocal,
                          });
                        } else {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                          });
                        }
                      } else {
                        props.fetchInterviewData();
                      }
                    }
                  }
                );
                props.onCloseInterViewDetails();
              }}
              style={{
                color:
                  menuKey === "4"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonStyles.marginBottomRemove}  ${commonStyles.darkText}`}
            >
              Candidate No Show
            </p>
          ),
        },
        {
          key: "5",
          label: (
            <p
              onClick={() => {
                setMenuKey("5");

                props.editInterviewStatusHandler(
                  {
                    id: props.onInterviewDetails.id,
                    status: "Rescheduled by Candidate",
                  },
                  (success) => {
                    if (success) {
                      if (props.typeData === "task") {
                        let interviewFilterDataFromLocal = JSON.parse(
                          localStorage.getItem("interviewFilterData")
                        );
                        if (interviewFilterDataFromLocal) {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                            ...interviewFilterDataFromLocal,
                          });
                        } else {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                          });
                        }
                      } else {
                        props.fetchInterviewData();
                      }
                    }
                  }
                );
                props.onCloseInterViewDetails();
              }}
              style={{
                color:
                  menuKey === "5"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonStyles.marginBottomRemove}  ${commonStyles.darkText}`}
            >
              Rescheduled by Candidate
            </p>
          ),
        },
      ]}
    />
  );
  return (
    <>
      <Drawer
        width={584}
        className="modalModified"
        closable={false}
        extra={
          <span
            onClick={() => props.onCloseInterViewDetails()}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)"} />
          </span>
        }
        title={
          <p
            style={{ fontSize: "20px", margin: "0px" }}
            className={`${commonStyles.modalHeading}`}
          >
            Interview Details
          </p>
        }
        open={props.onOpenPendDetails}
        onClose={props.onCloasePendingDetails}
        footer={
          <Row justify="end" style={{ gap: "10px" }}>
            {props.permissions &&
            // props.typeData &&
            // props.onProfile &&
            // props.onProfile.roleType &&
            // props.typeData === "typeData" &&
            // props.onProfile.roleType !== "Recruiter" &&
            props.permissions.find(
              (data) => data === "interview-status-update"
            ) ? (
              <Col>
                <Button className={commonStyles.intaddButton} type="primary">
                  <Row align="middle" justify="space-around">
                    <Col>
                      <p className={commonStyles.marginBottomRemove}>
                        Interview Status
                      </p>
                    </Col>
                    <Col>
                      <Dropdown placement="top" overlay={menu2}>
                        <Typography.Link>
                          <Space className={commonStyles.tableHeadingTextData}>
                            <CaretBlackDownIcon color={"white"} />
                          </Space>
                        </Typography.Link>
                      </Dropdown>
                    </Col>
                  </Row>
                </Button>
              </Col>
            ) : (
              ""
            )}
            {/* ) : props.permissions.find((data) => data === "interview-edit") ? (
               <Col>
                 <Button className={commonStyles.intaddButton} type="primary">
                   <Row align="middle" justify="space-around">
                     <Col>
                       <p className={commonStyles.marginBottomRemove}>
                         Interview Status
                       </p>
                     </Col>
                     <Col>
                       <Dropdown placement="top" overlay={menu2}>
                         <Typography.Link>
                           <Space className={commonStyles.tableHeadingTextData}>
                             <CaretBlackDownIcon color={"white"} />
                           </Space>
                         </Typography.Link>
                       </Dropdown>
                     </Col>
                   </Row>
                 </Button>
               </Col>
             ) : ( */}
            <Col>
              <Button
                className={commonStyles.buttonSettingsPrimary}
                type="primary"
                htmlType="button"
                onClick={() => {
                  // setNotify(true); //notify 1
                  props.editInterviewStatusHandler(
                    { id: props.onInterviewDetails.id, notify: "1" },
                    (success) => {}
                  );
                }}
              >
                Notify
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  props.onCloseInterViewDetails();
                }}
                className={commonStyles.buttonSettingsSecondary}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        }
      >
        <Row style={{ padding: "0px 20px  80px 20px" }}>
          <Col md={24}>
            <Row gutter={[0, 16]} align="middle">
              {props.onInterviewDetails &&
              props.onInterviewDetails.headingText ? (
                <Col md={24}>
                  <p
                    className={`${commonStyles.marginBottomRemove} ${commonStyles.subHeadingText} `}
                  >
                    {props.onInterviewDetails.headingText}
                  </p>
                </Col>
              ) : (
                <></>
              )}

              {props.onInterviewDetails && props.onInterviewDetails.jobName ? (
                <Col md={24}>
                  <p
                    className={`${commonStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}  `}
                  >
                    {` Job name : ${props.onInterviewDetails.jobName}`}
                  </p>
                </Col>
              ) : (
                <></>
              )}
              {props.onInterviewDetails &&
              props.onInterviewDetails.clientName ? (
                <Col style={{ marginTop: "20px" }} md={24}>
                  <p
                    className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}  `}
                  >
                    {` Client name : ${props.onInterviewDetails.clientName}`}
                  </p>
                </Col>
              ) : (
                <></>
              )}
              <Col style={{ marginTop: "20px" }} md={24}>
                <Row style={{ gap: "17px" }}>
                  <Col>
                    <Row align="middle" style={{ gap: "10px" }}>
                      <Col>
                        <CalendarBlankIcon color={"var(--primaryColor)"} />
                      </Col>
                      <Col>
                        <p
                          className={`${commonStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}`}
                        >
                          {/* Wednesday, March 12 2022 */}
                          {moment(
                            props.onInterviewDetails &&
                              props.onInterviewDetails.interviewDate
                          ).format("MMMM DD YYYY")}
                        </p>
                      </Col>
                    </Row>
                  </Col>

                  <Col>
                    <Row align="middle" style={{ gap: "10px" }}>
                      <Col>
                        <ClockIcon color={"var(--primaryColor)"} />
                      </Col>
                      <Col>
                        <p
                          className={`${commonStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}`}
                        >
                          {`${
                            props.onInterviewDetails &&
                            props.onInterviewDetails.interviewStartTime &&
                            props.onInterviewDetails.interviewStartTime
                          } -
                  ${
                    props.onInterviewDetails &&
                    props.onInterviewDetails.interviewEndTime &&
                    props.onInterviewDetails.interviewEndTime
                  }`}
                        </p>
                      </Col>
                    </Row>
                    <p style={{ margin: "0px" }}>
                      <span
                        className={styles.candidateSummaryLeftSubCont}
                        style={{ verticalAlign: "text-bottom" }}
                      ></span>
                    </p>
                  </Col>
                  <Col>
                    <Row align="middle" style={{ gap: "10px" }}>
                      <Col>
                        <HourglassIcon color={"var(--primaryColor)"} />
                      </Col>
                      <Col>
                        <p
                          className={`${commonStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}`}
                        >
                          {`${
                            props.onInterviewDetails &&
                            props.onInterviewDetails.duration
                          } hour`}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col md={24}>
                <Row align="middle" style={{ gap: "10px" }}>
                  <Col>
                    <DesktopTowerIcon />
                  </Col>
                  <Col>
                    <p
                      className={`${commonStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}`}
                    >
                      {props.onInterviewDetails &&
                      props.onInterviewDetails.interviewType &&
                      props.onInterviewDetails.interviewType === "web"
                        ? "Virtual"
                        : props.onInterviewDetails.interviewType}
                    </p>
                  </Col>
                  <Col>
                    {(props.onInterviewDetails &&
                      props.onInterviewDetails.status === "pending") ||
                    props.onInterviewDetails.status === "Pending" ? (
                      <Tag
                        style={{
                          backgroundColor: "#FFF0DB",
                          color: "#ff9a02",
                          width: "85px",
                          textAlign: "center",
                          padding: "6px 0px 5px 0px",
                          fontSize: "14px",
                        }}
                      >
                        Pending
                      </Tag>
                    ) : (
                      <Tag
                        style={{
                          backgroundColor: "#CCFAE4",
                          color: "#0E8553",
                          width: "auto",
                          textAlign: "center",
                          padding: "6px 10px 5px 10px",
                          fontSize: "14px",
                        }}
                      >
                        {props.onInterviewDetails &&
                        props.onInterviewDetails.status === "completed"
                          ? "Completed"
                          : props.onInterviewDetails &&
                            props.onInterviewDetails.status ===
                              "Rescheduled by Candidate"
                          ? "Rescheduled by Candidate"
                          : props.onInterviewDetails &&
                            props.onInterviewDetails.status ===
                              "Rescheduled by Client"
                          ? "Rescheduled by Client"
                          : props.onInterviewDetails &&
                            props.onInterviewDetails.status ===
                              "Candidate No Show"
                          ? "Candidate No Show"
                          : "Completed"}
                      </Tag>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {props.onInterviewDetails &&
            props.onInterviewDetails.interviewTypeDetails &&
            (props.onInterviewDetails &&
            props.onInterviewDetails.interviewType === "web" ? (
              <Col style={{ margin: "20px 0px" }} md={24}>
                {/* <p
              className={styles.drawerFormText}
              style={{ margin: "0px", paddingBottom: "10px" }}
            ></p> */}

                {/* {props.onInterviewDetails &&
                  props.onInterviewDetails.interviewType === "web" && ( */}
                <Row justify="space-between" align="middle">
                  <Col xs={20} md={20}>
                    <Input
                      defaultValue={
                        props.onInterviewDetails.interviewTypeDetails
                      }
                      style={{
                        height: "35px",
                        color: "var(--primaryColor)",
                      }}
                      readOnly
                    />
                  </Col>
                  <Col xs={3} md={3}>
                    <Button
                      onClick={() => {
                        window.open(
                          props.onInterviewDetails &&
                            props.onInterviewDetails.interviewTypeDetails,
                          "_blank"
                        );
                      }}
                      className={commonCssStyles.buttonSettingsPrimary}
                      style={{ height: "35px", width: "85px" }}
                      type="primary"
                    >
                      Join
                    </Button>
                  </Col>
                </Row>
                {/* )} */}
              </Col>
            ) : props.onInterviewDetails &&
              props.onInterviewDetails.interviewType === "office" ? (
              <Col style={{ margin: "20px 0px" }} md={24}>
                <p className={` ${styles.candidateSummaryLeftSubCont}`}>
                  Office Address :&nbsp;
                  <span>
                    {props.onInterviewDetails.interviewTypeDetails &&
                    props.onInterviewDetails.interviewTypeDetails
                      .officeAddress &&
                    props.onInterviewDetails.interviewTypeDetails.officeAddress
                      ? props.onInterviewDetails.interviewTypeDetails
                          .officeAddress
                      : "NA"}
                  </span>
                </p>
                <p className={` ${styles.candidateSummaryLeftSubCont}`}>
                  Point Of Contact :&nbsp;
                  <span>
                    {props.onInterviewDetails.interviewTypeDetails &&
                    props.onInterviewDetails.interviewTypeDetails
                      .pointOfContact &&
                    props.onInterviewDetails.interviewTypeDetails.pointOfContact
                      ? props.onInterviewDetails.interviewTypeDetails
                          .pointOfContact
                      : "NA"}
                  </span>
                </p>
              </Col>
            ) : (
              <Col style={{ margin: "20px 0px" }} md={24}>
                <p className={` ${styles.candidateSummaryLeftSubCont}`}>
                  Contact Name :&nbsp;
                  <span>
                    {props.onInterviewDetails.interviewTypeDetails &&
                    props.onInterviewDetails.interviewTypeDetails.contactName &&
                    props.onInterviewDetails.interviewTypeDetails.contactName
                      ? props.onInterviewDetails.interviewTypeDetails
                          .contactName
                      : "NA"}
                  </span>
                </p>
                <p className={` ${styles.candidateSummaryLeftSubCont}`}>
                  Phone :&nbsp;
                  <span>
                    {props.onInterviewDetails.interviewTypeDetails &&
                    props.onInterviewDetails.interviewTypeDetails.phone &&
                    props.onInterviewDetails.interviewTypeDetails.phone
                      ? props.onInterviewDetails.interviewTypeDetails.phone
                      : "NA"}
                  </span>
                </p>
              </Col>
            ))}
          {props.onInterviewDetails &&
          props.onInterviewDetails.interviewers &&
          props.onInterviewDetails.interviewers.length > 0 ? (
            <Col style={{ marginTop: "20px" }} md={24}>
              <p
                className={styles.drawerFormText}
                style={{ margin: "0px", paddingBottom: "14px" }}
              >
                Interviewer Detail
              </p>
              <Row align="middle">
                <Col md={8}>
                  <p className={commonStyles.formInputLabel}>Name</p>
                </Col>
                <Col md={8}>
                  <p className={commonStyles.formInputLabel}>Designation</p>
                </Col>
                <Col md={8}>
                  <p className={commonStyles.formInputLabel}>Email</p>
                </Col>
              </Row>
              {props.onInterviewDetails.interviewers.map((data, index) => {
                return (
                  <Row key={index} align="middle">
                    <Col md={8}>
                      <p
                        className={styles.interviewSubHeading}
                        style={{ margin: "0px", padding: "5px" }}
                      >
                        {data.name}
                      </p>
                    </Col>

                    <Col md={8}>
                      <p
                        className={styles.interviewSubHeading}
                        style={{ margin: "0px", padding: "5px" }}
                      >
                        {data.designation}
                      </p>
                    </Col>

                    <Col md={8}>
                      <p
                        className={`${commonStyles.textBreak} ${styles.interviewSubHeading}`}
                        style={{ margin: "0px" }}
                      >
                        {data.email}
                      </p>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          ) : (
            ""
          )}

          {props.onInterviewDetails && props.onInterviewDetails.instruction && (
            <>
              <Col style={{ marginTop: "20px" }} md={24}>
                <p className={styles.drawerFormText}>Interview Details</p>
              </Col>
              <Col md={24}>
                <p className={styles.interviewSubHeading}>
                  {props.onInterviewDetails.instruction
                    ? props.onInterviewDetails.instruction
                    : "NA"}
                </p>
              </Col>
            </>
          )}
          {props.onInterviewDetails &&
            props.onInterviewDetails.performanceRating &&
            props.onInterviewDetails.performanceRating.length > 0 && (
              <Col xs={24} md={24} style={{ marginTop: "20px" }}>
                <p className={styles.drawerFormText}>Interview Feedback</p>
              </Col>
            )}
          {/* <Col>
            {props.onInterviewDetails &&
              props.onInterviewDetails.jobQuestions &&
              props.onInterviewDetails.jobQuestions.map((data, index) => {
                return (
                  <Row justify="space-between" key={index}>
                    <Col md={1}>
                      <p
                        style={{ textAlign: "left" }}
                        className={styles.interviewSubHeading}
                      >
                        {`${index + 1} )`}
                      </p>
                    </Col>
                    <Col md={22}>
                      <p className={styles.interviewSubHeading}>{data}</p>
                    </Col>
                  </Row>
                );
              })}
          </Col> */}
          <Col xs={24} md={24}>
            {/* performanceRating */}
            {props.onInterviewDetails &&
              props.onInterviewDetails.performanceRating &&
              props.onInterviewDetails.performanceRating.length > 0 &&
              props.onInterviewDetails.performanceRating.map((x, index) => {
                return (
                  <div style={{ marginBottom: "20px" }} key={index}>
                    <p
                      className={`${commonCssStyles.marginBottomRemove} ${styles.answersText}`}
                    >{`${index + 1}) ${x.question}`}</p>
                    <Rate
                      defaultValue={x.rating}
                      // value={
                      //   ratingData && ratingData.length > 0
                      //     ? ratingData[index]
                      //     : x.rating
                      // }
                      value={ratingData ? ratingData[index] : x.rating}
                      onChange={(data) => {
                        if (ratingData[index] || ratingData[index] === 0) {
                          setRatingData((v) => {
                            const newStateValue = [...v];
                            newStateValue[index] = data;
                            return newStateValue;
                          });
                        } else {
                          setRatingData((rate) => [...rate, data]);
                        }
                        // const val = {
                        //   id:
                        //     props.onInterviewDetails &&
                        //     props.onInterviewDetails.id,
                        //   rating: data,
                        // };
                        // props.onRating(val);
                      }}
                      style={{ color: "var(--primaryColor)" }}
                    />
                  </div>
                );
              })}
          </Col>
          <Col style={{ marginTop: "20px" }} xs={24} md={24}>
            <Form
              form={form}
              onFinish={(val) => {
                interviewQuestions(val);
              }}
              // initialValues={
              //   props.onInterviewDetails &&
              //   props.onInterviewDetails.comment && {
              //     comment: props.onInterviewDetails.comment,
              //   }
              // }
              layout="vertical"
            >
              <Form.Item label="Comment/Notes" name={"comment"}>
                <Input.TextArea />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Button
                  htmlType="button"
                  onClick={() => {
                    form.submit();
                  }}
                  style={{ width: "auto" }}
                  type="default"
                  className={commonCssStyles.buttonSettingsSecondary}
                >
                  Save Feedback
                </Button>
              </div>
            </Form>
          </Col>
          <Col xs={24} md={24}>
            <p
              className={`${commonCssStyles.marginBottomRemove}  ${styles.drawerFormText}`}
            >
              Overall Rating
            </p>
            <Rate
              disabled={true}
              style={{ color: "var(--primaryColor)" }}
              value={
                props.onInterviewDetails && props.onInterviewDetails.rating
              }
            />
          </Col>
          {props.typeData &&
            props.typeData === "task" &&
            props.onInterviewDetails &&
            props.onInterviewDetails.candidateId && (
              <Col style={{ marginTop: "20px" }}>
                <p
                  onClick={() => {
                    props.activeMenuKeyHandle("/taskCalendar");
                    navigate("/candidatesDetailsPage/summary", {
                      state: {
                        from: "candidateList",
                        id: props.onInterviewDetails.candidateId,
                        comingFrom: "interviewList",
                        interviewDetals: props.onInterAndTaskDetals,
                      },
                    });
                  }}
                  style={{
                    color: "var(--primaryColor)",
                  }}
                  className={`${commonStyles.marginBottomRemove} ${commonStyles.coursorPointer}`}
                >
                  Candidate detail
                </p>
              </Col>
            )}
        </Row>
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    permissions: state.authpermissions.permissions,
    filterData: state.taskAndCalendar.filterData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editInterviewStatusHandler: (data, callback) => {
      dispatch(putInterViewAction(data, callback));
    },
    getTaskFilterHandler: (data) => {
      dispatch(getTasksFilterAction(data));
    },
    activeMenuKeyHandle: (data) => {
      dispatch(activeMenuKeySlice(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewPendingDetails);
