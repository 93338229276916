import {
  getEmailNotificationTypesSlice,
  getEmailNotificationTypeSlice,
  postEmailNotificationTypesSlice,
  putEmailNotificationTypesSlice,
  deleteEmailNotificationTypesSlice,
} from "../../Slice/Master/MasterEmailNotificationTypesSlice";
import { EMAIL_NOTIFICATION_TYPES_URL } from "../../masterpath";

import { message } from "antd";
import AxiosApi from "../../api";
const api = new AxiosApi();
export const getEmailNotificationTypesAction = () => {
  return (dispatch) => {
    api.invoke(
      EMAIL_NOTIFICATION_TYPES_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200)
            dispatch(getEmailNotificationTypesSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const getEmailNotificationTypeAction = (id) => {
  return (dispatch) => {
    api.invoke(
      EMAIL_NOTIFICATION_TYPES_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getEmailNotificationTypeSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postEmailNotificationTypesAction = (data) => {
  return (dispatch) => {
    api.invoke(
      EMAIL_NOTIFICATION_TYPES_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200){
            message.destroy();
            message.success(data.message, 3);
            dispatch(postEmailNotificationTypesSlice(data));
           } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const putEmailNotificationTypesAction = (data) => {
  return (dispatch) => {
    api.invoke(
      EMAIL_NOTIFICATION_TYPES_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200){message.destroy();
            message.success(data.message, 3);
            dispatch(putEmailNotificationTypesSlice(data));
             }   else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteEmailNotificationTypesAction = (id) => {
  return (dispatch) => {
    api.invoke(
      EMAIL_NOTIFICATION_TYPES_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200){message.destroy();
            message.success(data.message, 3);
            dispatch(deleteEmailNotificationTypesSlice(id));}
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
