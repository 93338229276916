import {
  Drawer,
  Button,
  Row,
  Col,
  Tag,
  Form,
  Input,
  Image,
  Dropdown,
  Space,
  Select,
  Menu,
  Typography,
} from "antd";
import styles from "../../AddCandidatesComp/AddCandidatesComp.module.css";
import {
  CalendarBlankIcon,
  ClockIcon,
  DesktopTowerIcon,
  HourglassIcon,
  CloseIcon,
  CaretBlackDownIcon,
} from "../../../IconsComp/Icons";
import InterviewPending from "./InterviewPending";
import React, { useEffect } from "react";
import InterviewCompleted from "./InterviewCompleted";
import commonCssStyles from "../../../UI/CommonCss.module.css";
import moment from "moment";
import { useState } from "react";
import { connect } from "react-redux";
import { putInterViewAction } from "../../../../Reusable/Store/Action/RecruitmentAction";
import {
  getTasksFilterAction,
  putTaskAndCalendarAction,
  postCompletedInterviewPerformanceAction,
} from "../../../../Reusable/Store/Action/TaskAndCalendarAction";
import InterviewPendingDetails from "./InterviewPendingDetails";
import { useNavigate } from "react-router";
import { getClientsAction } from "../../../../Reusable/Store/Action/ClientAction";

function InterviewDetails(props) {
  const navigate = useNavigate();
  const [icon, setIcon] = useState(false);
  const [menuKey, setMenuKey] = useState("1");
  const [key, setKey] = useState("1");
  const [reschedule, setReschedule] = useState(false);
  const [notify, setNotify] = useState(false);
  const [openPendDetails, setOpenPendDetails] = useState(false);

  // useEffect(() => {
  //   props.getClientsHandler();
  // }, []);
  // console.log(props.clients, props.onInterviewDetails.job.clientId);
  const menu2 = (
    <Menu
      style={{ width: "214px", margin: "11px 0px" }}
      selectable
      items={[
        {
          key: "1",
          label: (
            <p
              onClick={() => {
                setMenuKey("1");

                props.editInterviewStatusHandler(
                  {
                    id: props.onInterviewDetails.id,
                    // status: "pending",
                    status: "Pending",
                  },
                  (success) => {
                    if (success) {
                      if (props.typeData === "task") {
                        // props.getTaskFilterHandler({
                        //   type: "interview",
                        //   current_page: 1,
                        // });
                        let interviewFilterDataFromLocal = JSON.parse(
                          localStorage.getItem("interviewFilterData")
                        );
                        if (interviewFilterDataFromLocal) {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                            ...interviewFilterDataFromLocal,
                          });
                        } else {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                          });
                        }
                      } else {
                        props.fetchInterviewData();
                      }
                      props.onCloseInterViewDetails();
                    }
                  }
                );
              }}
              style={{
                color:
                  menuKey === "1"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.darkText}`}
            >
              Pending
            </p>
          ),
        },
        {
          key: "2",
          label: (
            <p
              onClick={() => {
                setMenuKey("2");
                props.editInterviewStatusHandler(
                  {
                    id: props.onInterviewDetails.id,
                    status: "completed",
                  },
                  (success) => {
                    if (success) {
                      if (props.typeData === "task") {
                        // props.getTaskFilterHandler({
                        //   type: "interview",
                        //   current_page: 1,
                        // });
                        let interviewFilterDataFromLocal = JSON.parse(
                          localStorage.getItem("interviewFilterData")
                        );
                        if (interviewFilterDataFromLocal) {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                            ...interviewFilterDataFromLocal,
                          });
                        } else {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                          });
                        }
                      } else {
                        props.fetchInterviewData();
                      }
                    }
                  }
                );
                props.onCloseInterViewDetails();
              }}
              style={{
                color:
                  menuKey === "2"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonCssStyles.marginBottomRemove}  ${commonCssStyles.darkText}`}
            >
              Completed
            </p>
          ),
        },
        {
          key: "3",
          label: (
            <p
              onClick={() => {
                setMenuKey("3");
                props.editInterviewStatusHandler(
                  {
                    id: props.onInterviewDetails.id,
                    status: "Rescheduled by Client",
                  },
                  (success) => {
                    if (success) {
                      if (props.typeData === "task") {
                        // props.getTaskFilterHandler({
                        //   type: "interview",
                        //   current_page: 1,
                        // });
                        let interviewFilterDataFromLocal = JSON.parse(
                          localStorage.getItem("interviewFilterData")
                        );
                        if (interviewFilterDataFromLocal) {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                            ...interviewFilterDataFromLocal,
                          });
                        } else {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                          });
                        }
                      } else {
                        props.fetchInterviewData();
                      }
                    }
                  }
                );
                props.onCloseInterViewDetails();
              }}
              style={{
                color:
                  menuKey === "3"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonCssStyles.marginBottomRemove}  ${commonCssStyles.darkText}`}
            >
              Rescheduled by Client
            </p>
          ),
        },
        {
          key: "4",
          label: (
            <p
              onClick={() => {
                setMenuKey("4");
                props.editInterviewStatusHandler(
                  {
                    id: props.onInterviewDetails.id,
                    status: "Candidate No Show",
                  },
                  (success) => {
                    if (success) {
                      if (props.typeData === "task") {
                        // props.getTaskFilterHandler({
                        //   type: "interview",
                        //   current_page: 1,
                        // });
                        let interviewFilterDataFromLocal = JSON.parse(
                          localStorage.getItem("interviewFilterData")
                        );
                        if (interviewFilterDataFromLocal) {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                            ...interviewFilterDataFromLocal,
                          });
                        } else {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                          });
                        }
                      } else {
                        props.fetchInterviewData();
                      }
                    }
                  }
                );
                props.onCloseInterViewDetails();
              }}
              style={{
                color:
                  menuKey === "4"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonCssStyles.marginBottomRemove}  ${commonCssStyles.darkText}`}
            >
              Candidate No Show
            </p>
          ),
        },
        {
          key: "5",
          label: (
            <p
              onClick={() => {
                setMenuKey("5");
                props.editInterviewStatusHandler(
                  {
                    id: props.onInterviewDetails.id,
                    status: "Rescheduled by Candidate",
                  },
                  (success) => {
                    if (success) {
                      if (props.typeData === "task") {
                        // props.getTaskFilterHandler({
                        //   type: "interview",
                        //   current_page: 1,
                        // });
                        let interviewFilterDataFromLocal = JSON.parse(
                          localStorage.getItem("interviewFilterData")
                        );
                        if (interviewFilterDataFromLocal) {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                            ...interviewFilterDataFromLocal,
                          });
                        } else {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...props.filterData,
                          });
                        }
                      } else {
                        props.fetchInterviewData();
                      }
                    }
                  }
                );
                props.onCloseInterViewDetails();
              }}
              style={{
                color:
                  menuKey === "5"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonCssStyles.marginBottomRemove}  ${commonCssStyles.darkText}`}
            >
              Rescheduled by Candidate
            </p>
          ),
        },
      ]}
    />
  );

  return (
    <>
      <Drawer
        closable={false}
        extra={
          <span
            className={commonCssStyles.coursorPointer}
            onClick={() => props.onCloseInterViewDetails()}
          >
            <CloseIcon color="var(--icons-color)" />
          </span>
        }
        width={584}
        title={
          <>
            <h3
              className={commonCssStyles.modalHeading}
              style={{ fontSize: "20px", margin: "0px" }}
            >
              Interview Details
            </h3>
          </>
        }
        footer={
          <Row
            onMouseEnter={() => {
              setIcon(true);
            }}
            onMouseLeave={() => {
              setIcon(false);
            }}
            align="middle"
            justify="space-around"
          >
            {
              <>
                {props.onProfile &&
                  props.onProfile.roleType &&
                  props.permissions &&
                  // props.onProfile.roleType !== "Recruiter" &&
                  props.permissions.find(
                    (data) => data === "interview-status-update"
                  ) && (
                    <Col>
                      <Button
                        className={commonCssStyles.intaddButton}
                        type="primary"
                      >
                        <Row align="middle" justify="space-around">
                          <Col>
                            <p className={commonCssStyles.marginBottomRemove}>
                              Interview Status
                            </p>
                          </Col>
                          <Col>
                            <Dropdown placement="top" overlay={menu2}>
                              <Typography.Link>
                                <Space
                                  className={
                                    commonCssStyles.tableHeadingTextData
                                  }
                                >
                                  <CaretBlackDownIcon color={"white"} />
                                </Space>
                              </Typography.Link>
                            </Dropdown>
                          </Col>
                        </Row>
                      </Button>
                    </Col>
                  )}
                {/* <Col>
                  <Button
                    onClick={() => {
                      props.onCloseInterViewDetails();
                    }}
                    className={commonCssStyles.buttonSettingsSecondary}
                  >
                    Cancel
                  </Button>
                </Col> */}
              </>
            }
            {props.onModalName && props.onModalName === "display" ? (
              <>
                {(props.onInterviewDetails &&
                  props.onInterviewDetails.status === "pending") ||
                props.onInterviewDetails.status === "Pending" ? (
                  <>
                    <Col>
                      <Button
                        onClick={() => {
                          // setNotify(true);
                          props.editInterviewStatusHandler(
                            {
                              id: props.onInterviewDetails.id,
                              // notify: notify ? "1" : "0",
                              notify: "1",
                            },
                            (success) => {
                              if (success) {
                                props.fetchInterviewData();
                                props.onCloseInterViewDetails();
                              }
                            }
                          );
                        }}
                        style={{ width: "auto" }}
                        className={commonCssStyles.buttonSettingsPrimary}
                        type="primary"
                      >
                        Notify Candidate
                      </Button>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col>
                  <Button
                    onClick={() => {
                      props.onCloseInterViewDetails();
                    }}
                    className={commonCssStyles.buttonSettingsSecondary}
                  >
                    Cancel
                  </Button>
                </Col>
              </>
            ) : (
              <>
                {(props.onInterviewDetails.status &&
                  props.onInterviewDetails.status === "pending") ||
                props.onInterviewDetails.status === "Pending" ? (
                  <>
                    <Col>
                      <Button
                        onClick={() => {
                          // setReschedule(true);
                          props.editInterviewStatusHandler(
                            {
                              id: props.onInterviewDetails.id,
                              // reschedule: reschedule ? "1" : "0",
                              reschedule: "1",
                            },
                            (success) => {
                              if (success) {
                                props.fetchInterviewData();
                                props.onCloseInterViewDetails();
                              }
                            }
                          );
                        }}
                        type="default"
                        style={{ width: "auto" }}
                        className={commonCssStyles.buttonSettingsSecondary}
                      >
                        Reschedule Interview
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => {
                          // setNotify(true);
                          props.editInterviewStatusHandler(
                            {
                              id: props.onInterviewDetails.id,
                              // notify: notify ? "1" : "0",
                              notify: "1",
                            },
                            (success) => {
                              if (success) {
                                props.fetchInterviewData();
                                props.onCloseInterViewDetails();
                              }
                            }
                          );
                        }}
                        style={{ width: "auto" }}
                        className={commonCssStyles.buttonSettingsPrimary}
                        type="primary"
                      >
                        Notify Candidate
                      </Button>
                    </Col>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </Row>
        }
        onClose={props.onCloseInterViewDetails}
        open={props.onOpenInterViewDetails}
      >
        <p className={styles.candidateSummaryLeftSubCont}>
          {props.onInterviewDetails &&
            props.onInterviewDetails.headingText &&
            props.onInterviewDetails.headingText}
          {/* Interview with{" "}
          <span>title
            <b className={styles.candidateSummaryLeftSubContBold}>
              Sania Malhotra , UX Designer
            </b>
          </span>
          &nbsp; candidate by&nbsp;
          <span>
            {props.statusHandle &&
              props.statusHandle.interviewers.map((data, index) => {
                return (
                  <b
                    key={index}
                    className={styles.candidateSummaryLeftSubContBold}
                  >
                    {data.name}
                  </b>
                );
              })}
          </span> */}
        </p>
        {props.onInterviewDetails && props.onInterviewDetails.jobName ? (
          <Col md={24}>
            <p
              className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}  `}
            >
              {` Job name : ${props.onInterviewDetails.jobName}`}
            </p>
          </Col>
        ) : (
          <></>
        )}
        {props.onInterviewDetails && props.onInterviewDetails.clientName ? (
          <Col style={{ marginTop: "20px" }} md={24}>
            <p
              className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}  `}
            >
              {` Client name : ${props.onInterviewDetails.clientName}`}
            </p>
          </Col>
        ) : (
          <></>
        )}
        {/* {props.onInterviewDetails &&
        props.onInterviewDetails.job &&
        props.onInterviewDetails.job.clientId ? (
          <Col md={24}>
            {props.clients &&
              props.clients.find((x) => {
                if (x.id === props.onInterviewDetails.job.clientId) {
                  return <p>{x.clientName}</p>;
                }
              })}
            <p
              className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}  `}
            >
              {` Job name : ${props.onInterviewDetails.jobName}`}
            </p>
          </Col>
        ) : (
          <></>
        )} */}
        <Row style={{ marginTop: "20px" }} gutter={[0, 16]} align="middle">
          <Col md={24}>
            <Row style={{ gap: "17px" }}>
              <Col>
                <Row align="middle" style={{ gap: "10px" }}>
                  <Col>
                    <CalendarBlankIcon color={"var(--primaryColor)"} />
                  </Col>
                  <Col>
                    <p
                      className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}`}
                    >
                      {/* Wednesday, March 12 2022 */}
                      {moment(
                        props.onInterviewDetails &&
                          props.onInterviewDetails.interviewDate
                      ).format("MMMM DD YYYY")}
                    </p>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row align="middle" style={{ gap: "10px" }}>
                  <Col>
                    <ClockIcon color={"var(--primaryColor)"} />
                  </Col>
                  <Col>
                    <p
                      className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}`}
                    >
                      {`${
                        props.onInterviewDetails &&
                        props.onInterviewDetails.interviewStartTime &&
                        props.onInterviewDetails.interviewStartTime
                      } -
                  ${
                    props.onInterviewDetails &&
                    props.onInterviewDetails.interviewEndTime &&
                    props.onInterviewDetails.interviewEndTime
                  }`}
                    </p>
                  </Col>
                </Row>
                <p style={{ margin: "0px" }}>
                  <span
                    className={styles.candidateSummaryLeftSubCont}
                    style={{ verticalAlign: "text-bottom" }}
                  ></span>
                </p>
              </Col>
              <Col>
                <Row align="middle" style={{ gap: "10px" }}>
                  <Col>
                    <HourglassIcon color={"var(--primaryColor)"} />
                  </Col>
                  <Col>
                    <p
                      className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}`}
                    >
                      {`${
                        props.onInterviewDetails &&
                        props.onInterviewDetails.duration
                      } hour`}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col md={24}>
            <Row align="middle" style={{ gap: "10px" }}>
              <Col>
                <DesktopTowerIcon />
              </Col>
              <Col>
                <p
                  className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}`}
                >
                  {props.onInterviewDetails &&
                  props.onInterviewDetails.interviewType &&
                  props.onInterviewDetails.interviewType === "web"
                    ? "Virtual"
                    : props.onInterviewDetails.interviewType}
                </p>
              </Col>
              <Col>
                {(props.onInterviewDetails &&
                  props.onInterviewDetails.status === "pending") ||
                props.onInterviewDetails.status === "Pending" ? (
                  <Tag
                    style={{
                      backgroundColor: "#FFF0DB",
                      color: "#ff9a02",
                      width: "85px",
                      textAlign: "center",
                      padding: "6px 0px 5px 0px",
                      fontSize: "14px",
                    }}
                  >
                    Pending
                  </Tag>
                ) : (
                  <Tag
                    style={{
                      backgroundColor: "#CCFAE4",
                      color: "#0E8553",
                      // width: "85px",
                      width: "auto",
                      textAlign: "center",
                      padding: "6px 10px 5px 10px",
                      fontSize: "14px",
                    }}
                  >
                    {props.onInterviewDetails &&
                    props.onInterviewDetails.status === "completed"
                      ? "Completed"
                      : props.onInterviewDetails.status === "Completed"
                      ? "Completed"
                      : props.onInterviewDetails &&
                        props.onInterviewDetails.status ===
                          "Rescheduled by Candidate"
                      ? "Rescheduled by Candidate"
                      : props.onInterviewDetails &&
                        props.onInterviewDetails.status ===
                          "Rescheduled by Client"
                      ? "Rescheduled by Client"
                      : props.onInterviewDetails &&
                        props.onInterviewDetails.status === "Candidate No Show"
                      ? "Candidate No Show"
                      : "Completed"}
                    {/* Completed */}
                  </Tag>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {props.onInterviewDetails &&
          props.onInterviewDetails.interviewTypeDetails && (
            <Row style={{ marginTop: "20px" }}>
              {props.onInterviewDetails.interviewType === "web" ? (
                <Col md={24}>
                  {/* <p
              className={styles.drawerFormText}
              style={{ margin: "0px", paddingBottom: "10px" }}
            ></p> */}

                  {props.onInterviewDetails.interviewTypeDetails && (
                    <Form layout="vertical">
                      {props.onInterviewDetails &&
                        props.onInterviewDetails.interviewType === "web" && (
                          <Row justify="space-between" align="middle">
                            <Col md={19}>
                              <Form.Item label="Interview Link">
                                {(props.onInterviewDetails &&
                                  props.onInterviewDetails.status ===
                                    "pending") ||
                                props.onInterviewDetails.status ===
                                  "Pending" ? (
                                  <Input
                                    defaultValue={
                                      props.onInterviewDetails &&
                                      props.onInterviewDetails
                                        .interviewTypeDetails
                                    }
                                    style={{
                                      height: "35px",
                                      color: "var(--primaryColor)",
                                    }}
                                  />
                                ) : (
                                  <Input
                                    defaultValue={
                                      props.onInterviewDetails &&
                                      props.onInterviewDetails
                                        .interviewTypeDetails
                                    }
                                    style={{ height: "35px" }}
                                    readOnly={true}
                                  />
                                )}
                              </Form.Item>
                            </Col>
                            <Col>
                              <Button
                                onClick={() => {
                                  window.open(
                                    props.onInterviewDetails &&
                                      props.onInterviewDetails
                                        .interviewTypeDetails,
                                    "_blank"
                                  );
                                }}
                                className={
                                  commonCssStyles.buttonSettingsPrimary
                                }
                                style={{ height: "35px", width: "85px" }}
                                type="primary"
                              >
                                Join
                              </Button>
                            </Col>
                          </Row>
                        )}
                    </Form>
                  )}
                </Col>
              ) : props.onInterviewDetails.interviewType === "office" ? (
                <Col md={24}>
                  <p className={` ${styles.candidateSummaryLeftSubCont}`}>
                    Office Address :&nbsp;
                    <span>
                      {props.onInterviewDetails.interviewTypeDetails &&
                      props.onInterviewDetails.interviewTypeDetails
                        .officeAddress &&
                      props.onInterviewDetails.interviewTypeDetails
                        .officeAddress
                        ? props.onInterviewDetails.interviewTypeDetails
                            .officeAddress
                        : "NA"}
                    </span>
                  </p>
                  <p className={` ${styles.candidateSummaryLeftSubCont}`}>
                    Point Of Contact :&nbsp;
                    <span>
                      {props.onInterviewDetails.interviewTypeDetails &&
                      props.onInterviewDetails.interviewTypeDetails
                        .pointOfContact &&
                      props.onInterviewDetails.interviewTypeDetails
                        .pointOfContact
                        ? props.onInterviewDetails.interviewTypeDetails
                            .pointOfContact
                        : "NA"}
                    </span>
                  </p>
                </Col>
              ) : (
                <Col md={24}>
                  <p className={` ${styles.candidateSummaryLeftSubCont}`}>
                    Contact Name :&nbsp;
                    <span>
                      {props.onInterviewDetails.interviewTypeDetails &&
                      props.onInterviewDetails.interviewTypeDetails
                        .contactName &&
                      props.onInterviewDetails.interviewTypeDetails.contactName
                        ? props.onInterviewDetails.interviewTypeDetails
                            .contactName
                        : "NA"}
                    </span>
                  </p>
                  <p className={` ${styles.candidateSummaryLeftSubCont}`}>
                    Phone :&nbsp;
                    <span>
                      {props.onInterviewDetails.interviewTypeDetails &&
                      props.onInterviewDetails.interviewTypeDetails.phone &&
                      props.onInterviewDetails.interviewTypeDetails.phone
                        ? props.onInterviewDetails.interviewTypeDetails.phone
                        : "NA"}
                    </span>
                  </p>
                </Col>
              )}
            </Row>
          )}
        {props.onInterviewDetails &&
        props.onInterviewDetails.interviewers &&
        props.onInterviewDetails.interviewers.length > 0 ? (
          <Row>
            <Col md={24}>
              <p
                className={styles.drawerFormText}
                style={{ margin: "0px", paddingBottom: "14px" }}
              >
                Interview Details
              </p>
              <Row align="middle">
                <Col md={8}>
                  <p className={commonCssStyles.formInputLabel}>Name</p>
                </Col>
                <Col md={8}>
                  <p className={commonCssStyles.formInputLabel}>Designation</p>
                </Col>
                <Col md={8}>
                  <p className={commonCssStyles.formInputLabel}>Email</p>
                </Col>
              </Row>
              {props.onInterviewDetails.interviewers.map((data, index) => {
                return (
                  <Row key={index} align="middle">
                    <Col md={8}>
                      <p
                        className={styles.interviewSubHeading}
                        style={{
                          margin: "0px",
                          padding: "5px",
                        }}
                      >
                        {data.name}
                      </p>
                    </Col>

                    <Col md={8}>
                      <p
                        className={styles.interviewSubHeading}
                        style={{ margin: "0px", padding: "5px" }}
                      >
                        {data.designation}
                      </p>
                    </Col>

                    <Col md={8}>
                      <p
                        className={styles.interviewSubHeading}
                        style={{ margin: "0px", wordBreak: "break-all" }}
                      >
                        {data.email}
                      </p>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        ) : (
          ""
        )}
        {props.onInterviewDetails && props.onInterviewDetails.instruction && (
          <Row style={{ marginTop: "20px" }}>
            <Col md={24}>
              <p className={styles.drawerFormText}>Instruction</p>
            </Col>
            <Col md={24}>
              <p className={styles.interviewSubHeading}>
                {props.onInterviewDetails.instruction
                  ? props.onInterviewDetails.instruction
                  : "NA"}
              </p>
            </Col>
          </Row>
        )}
        {(props.onInterviewDetails &&
          props.onInterviewDetails.status !== "pending") ||
        props.onInterviewDetails.status !== "Pending" ? (
          <>
            {/* if interview is pending this component will open  */}
            {/* <InterviewPending
              onAnswers={(val) => {
                if (props.typeData === "task") {
                  const data = {
                    ...val,
                    link: 1,
                  };
                  props.putTaskInterviewData(data, (success) => {
                    if (success) {
                      // props.fetchData();
                      props.fetchInterviewData();
                      props.onCloseInterViewDetails();
                    }
                  });
                } else {
                  props.editInterviewStatusHandler(val, (success) => {
                    if (success) {
                      props.fetchInterviewData();
                      props.onCloseInterViewDetails();
                    }
                  });
                }
              }}
              onJobQuestions={props.onInterviewDetails}
            /> */}
            {/* if interview is other than pending this component will open */}
            <InterviewCompleted
              onJobQuestions={props.onInterviewDetails}
              onAnswers={(val) => {
                if (props.typeData === "task") {
                  const data = {
                    ...val,
                    // link: 1,
                  };
                  props.postInterviewPerformanceRatingHandler(
                    data,
                    (success) => {
                      if (success) {
                        // props.fetchData();
                        // props.fetchInterviewData();
                        props.onCloseInterViewDetails();
                      }
                    }
                  );
                  // props.putTaskInterviewData(data, (success) => {
                  //   if (success) {
                  //     // props.fetchData();
                  //     props.fetchInterviewData();
                  //     props.onCloseInterViewDetails();
                  //   }
                  // });
                } else {
                  const data = {
                    ...val,
                    // link: 1,
                  };
                  props.postInterviewPerformanceRatingHandler(
                    data,
                    (success) => {
                      if (success) {
                        // props.fetchData();
                        props.fetchInterviewData();
                        props.onCloseInterViewDetails();
                      }
                    }
                  );
                  // props.editInterviewStatusHandler(val, (success) => {
                  //   if (success) {
                  //     props.fetchInterviewData();
                  //     props.onCloseInterViewDetails();
                  //   }
                  // });
                }
              }}
              onRating={(data) => {
                props.editInterviewStatusHandler(data, (success) => {
                  if (success) {
                    // debugger;
                    // props.fetchData();
                    // props.fetchInterviewData();
                    props.onCloseInterViewDetails();
                  }
                });
              }}
            />
          </>
        ) : (
          <></>
        )}
        {props.typeData &&
          props.typeData === "task" &&
          props.onInterviewDetails &&
          props.onInterviewDetails.candidateId && (
            <Col style={{ marginTop: "20px" }}>
              <p
                onClick={() => {
                  navigate("/candidatesDetailsPage/summary", {
                    state: {
                      from: "candidateList",
                      id: props.onInterviewDetails.candidateId,
                      comingFrom: "interviewList",
                      interviewDetals: props.onInterAndTaskDetals,
                    },
                  });
                }}
                style={{
                  color: "var(--primaryColor)",
                }}
                className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.coursorPointer}`}
              >
                Candidate detail
              </p>
            </Col>
          )}
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    permissions: state.authpermissions.permissions,
    filterData: state.taskAndCalendar.filterData,
    onProfile: state.authentication.profileData,
    clients: state.client.clients,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    putTaskInterviewData: (data, callback) => {
      dispatch(putTaskAndCalendarAction(data, callback));
    },
    editInterviewStatusHandler: (data, callback) => {
      dispatch(putInterViewAction(data, callback));
    },
    getTaskFilterHandler: (data) => {
      dispatch(getTasksFilterAction(data));
    },
    postInterviewPerformanceRatingHandler: (data, callback) => {
      dispatch(postCompletedInterviewPerformanceAction(data, callback));
    },
    // getClientsHandler: () => {
    //   dispatch(getClientsAction());
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewDetails);
