import styles from "../Application/Application.module.css";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Switch,
  Modal,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  BackButtonIcon,
  TrashIcon,
  EditIconBlueClr,
  PlusIcon,
  ThreeDotsIcon,
  CloseIcon
} from "../../../IconsComp/Icons";
import AddJobDescriptionModal from "./AddJobDescriptionModal";
import { connect } from "react-redux";
import {
  getJobDescriptionsAction,
  putJobDescriptionsAction,
  deleteJobDescriptionsAction,
  getJobDescriptionAction,
  postJobDescriptionsAction,
} from "../../../../Reusable/Store/Action/Master/MasterJobDescriptionAction";
/*  */

/*  */
function JobDescription(props) {
  const [addTemplateModal, setAddTemplateModal] = useState(false);
  const [deleteJDModal, setDeleteJDModal] = useState(false);
  const [deleteJDId, setDeleteJDId] = useState("");
  const [modalName, setModalName] = useState("");
  const [deleteColor, setDeleteColor] = useState("var(--primaryColor)");
  useEffect(() => {
    props.getJobDescriptionHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function setAddTemplateModalOpenHandler1() {
    setAddTemplateModal(true);
    setModalName("add");
  }
  function setCancelTemplateModalOpenHandler1() {
    setAddTemplateModal(false);
  }

  function setOkTemplateModalOpenHandler1() {
    setAddTemplateModal(false);
  }
  function setOkEditOpenHandler1(data) {
    setAddTemplateModal(false);
    props.getJobDescriptionByIdHandler(data);
  }

  function confirmDeleteHandler() {
    setDeleteJDModal(false);
    props.deleteJobDescriptionsHandler(deleteJDId);
    setDeleteJDId("");
  }
  function closeDeleteHandler() {
    setDeleteJDModal(false);
  }

  const columns = [
    {
      key: "1",
      title: (
        <>
          <p
            style={{ margin: "0px" }}
            className={commonCssStyle.tableHeadingText}
          >
            Job Title
          </p>
        </>
      ),
      dataIndex: "descriptionTitle",
      render: (data) => {
        return (
          <p
            style={{ margin: "0px" }}
            className={commonCssStyle.tableJobDescriptionTextData}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      key: "2",
      title: (
        <>
          <p
            style={{ margin: "0px" }}
            className={commonCssStyle.tableHeadingText}
          >
            Department
          </p>
        </>
      ),
      dataIndex: "department",
      render: (data) => {
        return (
          <p
            style={{ margin: "0px" }}
            className={commonCssStyle.tableJobDescriptionTextData}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      key: "3",
      title: (
        <>
          <p
            style={{ margin: "0px" }}
            className={commonCssStyle.tableHeadingText}
          >
            Status
          </p>
        </>
      ),
      dataIndex: "actions",

      render: (text, render) => {
        return (
          <>
            <Row style={{ gap: "10px" }} align="middle">
              <Col>
                <Switch
                  onChange={(val) => {
                    props.putJobDescriptionHandler(
                      {
                        id: render.id,
                        status: val ? 1 : 0,
                      },
                      (success) => {
                        props.getJobDescriptionHandler();
                      }
                    );
                  }}
                  defaultChecked={render.status === 1}
                />
              </Col>
              <Col className={styles.jobDescEnableBtn}>
                {render.status === 1 ? (
                  <p
                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableEmailDescriptionTextData}`}
                    style={{ margin: "0px" }}
                  >
                    Enable
                  </p>
                ) : (
                  <p
                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableEmailDescriptionTextData}`}
                    style={{ margin: "0px" }}
                  >
                    Disable
                  </p>
                )}
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      dataIndex: "",
      title: (
        <>
          <p
            style={{ margin: "0px" }}
            className={commonCssStyle.tableHeadingText}
          >
            Action
          </p>
        </>
      ),
      render: (text, render) => (
        <Dropdown
          overlay={
            <Menu
              className="emailMenu"
              style={{
                width: "193px",
                height: "100px",
                borderRadius: "6px",
                padding: "16px,0,0,16px",
              }}
              items={[
                {
                  key: "1",
                  label: (
                    <Row
                      style={{ gap: "10px" }}
                      align="middle"
                      onClick={() => {
                        props.getJobDescriptionByIdHandler(
                          render.id,
                          (success) => {
                            if (success) {
                              setAddTemplateModal(true);
                              setModalName("edit");
                            }
                          }
                        );
                      }}
                    >
                      <Col>
                        <EditIconBlueClr color={"var(--primaryColor)"} />
                      </Col>
                      <Col>
                        <h3
                          style={{
                            marginBottom: "3px",
                            color: "var(--primaryColor)",
                            padding: "15px 0px 12px 12px",
                          }}
                        >
                          Edit
                        </h3>
                      </Col>
                    </Row>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <Row
                      onClick={() => {
                        setDeleteJDModal(true);
                        setDeleteJDId(render.id);
                      }}
                      onMouseEnter={() => {
                        setDeleteColor("red");
                      }}
                      onMouseLeave={() => {
                        setDeleteColor("var(--primaryColor)");
                      }}
                      style={{ gap: "10px" }}
                      align="middle"
                    >
                      <Col>
                        <TrashIcon color={deleteColor} />
                      </Col>
                      <Col>
                        <h3
                          style={{
                            marginBottom: "5px",
                            color: deleteColor,
                            padding: "15px 0px 12px 12px",
                          }}
                        >
                          Delete
                        </h3>
                      </Col>
                    </Row>
                  ),
                },
              ]}
            />
          }
          placement="bottomRight"
        >
          <a href onClick={(e) => e.preventDefault()}>
            <Space>
              <ThreeDotsIcon color={"var(--table-text-color)"} />
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <Card
        size="large"
        style={{
          backgroundColor: "var(--background-color)",
        }}
      >
        <Card className={styles.EmailTemplatesCard}>
          <Row justify="space-between">
            <Col>
              <Row align="middle">
                <Col
                  style={{ cursor: "pointer", paddingLeft: "5px" }}
                  className={styles.EmailTempBackBtn}
                  onClick={() => {
                    props.backToApp();
                  }}
                >
                  <BackButtonIcon color={"var(--icons-color)"} />
                </Col>
                <Col>
                  <h4 className={commonCssStyle.ApplicationTemplatesName}>
                    Job Description
                  </h4>
                </Col>
              </Row>
            </Col>

            <Col className={styles.EmailAddTemplateBtn}>
              <Button
                onClick={setAddTemplateModalOpenHandler1}
                type="primary"
                className={`${commonCssStyle.intaddButton} ${styles.EmailTemplatesAddBtn}`}
                style={{ width: "200px" }}
              >
                <Row align="middle" justify="space-around">
                  <Col style={{ marginTop: "2px" }}>
                    <PlusIcon color={"white"} />
                  </Col>
                  <Col className={styles.EmailAddTemplateName}>
                    Add Job Description
                  </Col>
                </Row>
              </Button>
            </Col>
          </Row>
        </Card>
      </Card>
      <Card>
        <Table
          pagination={false}
          rowKey="key"
          columns={columns}
          dataSource={props.jobDescriptionsData}
        />
      </Card>

      <Modal
                  closable={false}

        title={
         <Row style={{gap:"245px"}}>
          <Col> <p
            className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.popUpHeadingText}`}
          >
            Delete Job Description
          </p></Col>
          <Col><div
        style={{float:"right"}}
        onClick={() => {
          closeDeleteHandler();
        }}
        className={`${commonCssStyle.coursorPointer} `}
        >
          <CloseIcon   color={"var(--body-text-color)" || "var(--theme)"} />
        </div></Col>
         </Row>
        }
        footer={
          <>
            <Row style={{ gap: "10px" }} justify="end" align="middle">
              <Col>
                <Button
                  className={commonCssStyle.buttonSettingsSecondary}
                  onClick={() => {
                    closeDeleteHandler();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className={commonCssStyle.buttonSettingsPrimary}
                  onClick={() => {
                    confirmDeleteHandler();
                  }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </>
        }
        open={deleteJDModal}
        onOk={confirmDeleteHandler}
        // onCancel={closeDeleteHandler}
      >
        <Row>
          <Col>
            <p
              className={`${commonCssStyle.deleteConfiTextColor} ${commonCssStyle.deleteConfiTextColor}`}
            >
              Are you sure you want to delete
            </p>
          </Col>
        </Row>
      </Modal>
      {addTemplateModal && modalName === "add" ? (
        <AddJobDescriptionModal
          onOpen1={addTemplateModal}
          onCancel1={setCancelTemplateModalOpenHandler1}
          onOk1={setOkTemplateModalOpenHandler1}
          modalNameHandler={modalName}
          propsJDIdHandler={props.editJobDescriptionsData}
        />
      ) : (
        ""
      )}
      {addTemplateModal &&
      modalName === "edit" &&
      props.editJobDescriptionsData ? (
        <AddJobDescriptionModal
          onOpen1={addTemplateModal}
          onCancel1={setCancelTemplateModalOpenHandler1}
          onOk1={setOkEditOpenHandler1}
          modalNameHandler={modalName}
          propsJDIdHandler={props.editJobDescriptionsData}
        />
      ) : (
        ""
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    jobDescriptionsData: state.jobDescription.jobDescriptions,
    editJobDescriptionsData: state.jobDescription.jobDescription,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getJobDescriptionHandler: () => {
      dispatch(getJobDescriptionsAction());
    },
    putJobDescriptionHandler: (val, callback) => {
      dispatch(putJobDescriptionsAction(val, callback));
    },
    deleteJobDescriptionsHandler: (id) => {
      dispatch(deleteJobDescriptionsAction(id));
    },
    getJobDescriptionByIdHandler: (id, callback) => {
      dispatch(getJobDescriptionAction(id, callback));
    },
    postJobDescriptionHandler: (data) => {
      dispatch(postJobDescriptionsAction(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(JobDescription);
