import { Breadcrumb } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { checkPermissionAction } from "../../../../Reusable/Store/Action/AuthPermissionsAction";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import PermissionMessage from "../../../UI/PermissionMessage";
function BreadcrumbForCandidate(props) {
  const navigate = useNavigate();
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  const array =
    props.pageFrom === "recuriter"
      ? [
          "Home",
          "Recruitment",
          `Job Details (${props.name})`,
          "Candidate",
          "Candidate details",
        ]
      : props.pageFrom === "requirement"
      ? [
          "Home",
          "Requirement",
          `Job Details (${props.name})`,
          "Candidate",
          "Candidate details",
        ]
      : ["Home", "Candidate", "Candidate details"];
  return (
    <>
      <Breadcrumb>
        {props.slide &&
          props.slide.map((x, index) => {
            return (
              <Breadcrumb.Item key={index}>
                <p
                  className={`${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove} ${commonCssStyle.breadcrumbText}`}
                  onClick={() => {
                    if (x === 1) {
                      props.getCheckPermissionHandler(
                        "dashboard",
                        (success) => {
                          if (success) {
                            navigate("/dashboard");
                          } else {
                            setOpenPMModal({
                              open: true,
                              permission: "Dashboard",
                            });
                          }
                        }
                      );
                    } else if (x === 2) {
                      if (props.pageFrom === "requirement") {
                        props.permissions &&
                        props.permissions.find(
                          (data) =>
                            data === "job-list" ||
                            data === "job-add" ||
                            data === "job-edit" ||
                            data === "job-delete" ||
                            data === "job-detail" ||
                            data === "job-assign-employee" ||
                            data === "job-task-list"
                        )
                          ? navigate("/requirement", {
                              state: {
                                pageFrom: "requirement",
                                from: "fromRequirement",
                              },
                            })
                          : setOpenPMModal({
                              open: true,
                              permission: "Requirement Page",
                            });
                      } else if (props.pageFrom === "recuriter") {
                        props.permissions &&
                        props.permissions.find(
                          (data) =>
                            data === "recruitment-list" ||
                            data === "recruitment-delete" ||
                            // data === "recruitment-add" ||
                            data === "recruitment-detail" ||
                            data === "recruitment-candidate-pool" ||
                            data === "recruitment-add-candidate" ||
                            data === "recruitment-candidate-detail" ||
                            data === "recruitment-candidate-status update" ||
                            data === "recruitment-candidate-mail-list" ||
                            data === "recruitment-candidate-mail-add" ||
                            data === "recruitment-candidate-timeline" ||
                            data === "recruitment-candidate-task-list" ||
                            data === "recruitment-candidate-download-resume" ||
                            data ===
                              "recruitment-candidate-download-recording" ||
                            data === "recruitment-candidate-call" ||
                            data === "recruitment-candidate-interview-list" ||
                            data === "recruitment-candidate-offerLetter" ||
                            data === "recruitment-delete-candidate"
                          // data === "recruitment-candidate-jobs"
                        )
                          ? navigate("/recruitment")
                          : setOpenPMModal({
                              open: true,
                              permission: "Recuritment Page",
                            });
                      } else if (props.pageFrom === "candidate") {
                        navigate("/candidatesDetailsPage");
                      }
                    } else if (x === 3) {
                      if (props.pageFrom === "requirement") {
                        props.permissions &&
                        props.permissions.find(
                          (data) =>
                            data === "job-list" ||
                            data === "job-add" ||
                            data === "job-edit" ||
                            data === "job-delete" ||
                            data === "job-detail" ||
                            data === "job-assign-employee" ||
                            data === "job-task-list"
                        )
                          ? navigate("/JobDetailPage", {
                              state: {
                                id: props.id,
                                title: props.name,
                                activeTabKey: "1",
                                pageFrom: "requirement",
                              },
                            })
                          : setOpenPMModal({
                              open: true,
                              permission: "Requirement Page",
                            });
                      } else if (props.pageFrom === "recuriter") {
                        props.permissions &&
                        props.permissions.find(
                          (data) =>
                            data === "recruitment-list" ||
                            data === "recruitment-delete" ||
                            // data === "recruitment-add" ||
                            data === "recruitment-detail" ||
                            data === "recruitment-candidate-pool" ||
                            data === "recruitment-add-candidate" ||
                            data === "recruitment-candidate-detail" ||
                            data === "recruitment-candidate-status update" ||
                            data === "recruitment-candidate-mail-list" ||
                            data === "recruitment-candidate-mail-add" ||
                            data === "recruitment-candidate-timeline" ||
                            data === "recruitment-candidate-task-list" ||
                            data === "recruitment-candidate-download-resume" ||
                            data ===
                              "recruitment-candidate-download-recording" ||
                            data === "recruitment-candidate-call" ||
                            data === "recruitment-candidate-interview-list" ||
                            data === "recruitment-candidate-offerLetter" ||
                            data === "recruitment-delete-candidate"
                          // data === "recruitment-candidate-jobs"
                        )
                          ? navigate("/recruitment/recruitmentJobdetails", {
                              state: {
                                id: props.id,
                                activeTabKey: "addCandidate",
                                recuritmentJobDetailsName: props.name,
                              },
                            })
                          : setOpenPMModal({
                              open: true,
                              permission: "Candidate Details Page",
                            });
                      }
                    } else if (x === 4) {
                      let checkPermission =
                        props.permissions &&
                        props.permissions.find(
                          (data) =>
                            data === "candidate-list" ||
                            data === "candidate-detail" ||
                            data === "candidate-edit" ||
                            data === "candidate-mail-add" ||
                            data === "candidate-call" ||
                            data === "candidate-timeline" ||
                            data === "candidate-download-resume" ||
                            data === "candidate-download-recording" ||
                            data === "candidate-mail-list" ||
                            data === "candidate-add" ||
                            data === "candidate-delete" ||
                            data === "candidate-interview-list" ||
                            data === "candidate-export" ||
                            data === "candidate-task-list" ||
                            data === "candidate-jobs"
                        );
                      if (checkPermission) {
                        if (props.pageFrom === "recuriter") {
                          navigate("/candidatesDetailsPage", {
                            state: {
                              pageFrom: "recuriter",
                              tab: "addCandidate",
                              id: props.id,
                              activeTabKey: "addCandidate",
                              title: props.name,
                            },
                          });
                        } else if (props.pageFrom === "requirement") {
                          navigate("/candidatesDetailsPage", {
                            state: {
                              pageFrom: "requirement",
                              id: props.id,
                              activeTabKey: "1",
                              title: props.name,
                            },
                          });
                        } else {
                          navigate("/candidatesDetailsPage");
                        }
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Candidate Details Page",
                        });
                      }
                    }
                  }}
                >
                  {array[index]}
                </p>
              </Breadcrumb.Item>
            );
          })}
      </Breadcrumb>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
          props.setIsHavepermissionsHandler(false);
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BreadcrumbForCandidate);
