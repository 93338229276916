import { Col, Form, Input, Modal, Row, Button } from "antd";
import { useForm } from "antd/lib/form/Form";
import { CloseIcon } from "../../../IconsComp/Icons";
import commonCssStyle from "../../../UI/CommonCss.module.css";
function DepartmentEditModal(props) {
  function onSubmit(data) {
    const val = {
      ...data,
      id: props.onDepartmentByIdData.id,
    };
    props.onSubmitData(val);
    form.resetFields();
  }
  const [form] = useForm();
  return (
    <Modal
      open={props.onEditModal}
      closable={false}
      footer={false}
      title={
        <Row justify="space-between">
          <Col>
            <h3
              className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.popUpHeadingText}`}
            >
              Edit department
            </h3>
          </Col>
          <Col>
            <span
              style={{ float: "right" }}
              onClick={() => {
                props.onCloseEditModal();
              }}
              className={`${commonCssStyle.coursorPointer} `}
            >
              <CloseIcon color={"var(--body-text-color)"} />
            </span>
          </Col>
        </Row>
      }
    >
      <Form
        initialValues={{
          departmentName:
            props.onDepartmentByIdData &&
            props.onDepartmentByIdData.departmentName,
        }}
        form={form}
        layout="vertical"
        onFinish={(val) => onSubmit(val)}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please enter department tag",
            },
          ]}
          label="Department tag"
          name="departmentName"
        >
          <Input placeholder="Edit department tag" />
        </Form.Item>
        <Row style={{ gap: "10px" }} justify="end" align="middle">
          <Col>
            <Button
              type="default"
              className={commonCssStyle.buttonSettingsSecondary}
              onClick={() => {
                props.onCloseEditModal();
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              htmlType="button"
              className={commonCssStyle.buttonSettingsPrimary}
              onClick={() => {
                form.submit();
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export default DepartmentEditModal;
