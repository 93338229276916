import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Switch,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { MinusOutlined } from "@ant-design/icons";

import {
  CloseIcon,
  MinusIcons,
  PlusForButtonIcon,
} from "../../../IconsComp/Icons";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import InOffice from "./InOffice";
import Telephonic from "./Telephonic";
import {
  postInterViewAction,
  putInterViewAction,
} from "../../../../Reusable/Store/Action/RecruitmentAction";
import { getCandidateInterviewAction } from "../../../../Reusable/Store/Action/CandidatesAction";
import { getTasksFilterAction } from "../../../../Reusable/Store/Action/TaskAndCalendarAction";
function RescheduleInterview(props) {
  const [form] = useForm();
  const [startTime, setStartTime] = useState("");
  const [valueData, setValueData] = useState("web");
  const [attachedJd, setAttachedJd] = useState(false);
  const [email, setEmail] = useState(false);

  useEffect(() => {
    if (props.onInterviewDetails) {
      setValueData(props.onInterviewDetails.interviewType);
    }
  }, []);

  const [removeInterviewer, setRemoveInterviewer] = useState(false);
  const timeArray = [
    "8:00 AM",
    "8:30 AM",
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
    "5:30 PM",
    "6:00 PM",
    "6:30 PM",
    "7:00 PM",
    "7:30 PM",
    "8:00 PM",
    "8:30 PM",
    "9:00 PM",
    "9:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
    "12:00 AM",
    "12:30 AM",
    "1:00 AM",
    "1:30 AM",
    "2:00 AM",
    "2:30 AM",
    "3:00 AM",
    "3:30 AM",
    "4:00 AM",
    "4:30 AM",
    "5:00 AM",
    "5:30 AM",
    "6:00 AM",
    "6:30 AM",
    "7:00 AM",
    "7:30 AM",
  ];
  const dateFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";
  let disableData = true;

  const onChange = (e) => {
    //when we switch Mode of Interview to office  this api is calling
    setValueData(e.target.value);
  };
  return (
    <>
      <Drawer
        className="modalModified"
        open={props.rsOpen}
        onCancel={props.onRsOpen}
        closable={false}
        width={690}
        title={
          <Row justify="space-between">
            <Col>
              <p className={commonCssStyle.popUpHeadingText}>
                Reschedule Interview
              </p>
            </Col>

            <Col>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  props.onRsOpen();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)"} />
              </div>
            </Col>
          </Row>
        }
        footer={false}
      >
        <Form
          onFinish={(val) => {
            const data = {
              ...val,
              candidateId:
                props.onInterviewDetails.candidateId &&
                props.onInterviewDetails.candidateId,
              jobId: props.onInterviewDetails.jobId,
              interviewId: props.onInterviewId,
              attachJD: attachedJd ? "1" : "0",
              interviewDate: moment(val.interviewDate).format("YYYY-MM-DD"),
              sendMail: email,
            };
            props.postInterViewHandler(data, (success) => {
              if (success) {
                if (props.pageFrom && props.pageFrom === "taskAndCalendar") {
                  let interviewFilter = JSON.parse(
                    localStorage.getItem("interviewFilterData")
                  );
                  if (interviewFilter) {
                    props.getTaskFilterHandler({
                      current_page: 1,
                      type: "interview",
                      ...props.filterData,
                      ...interviewFilter,
                    });
                  } else {
                    props.getTaskFilterHandler({
                      current_page: 1,
                      type: "interview",
                      ...props.filterData,
                    });
                  }
                } else {
                  props.getInterviewHandler(
                    props.onInterviewDetails.candidateId
                  );
                }
                form.resetFields();
                props.onRsOpen();
              }
            });
          }}
          initialValues={
            props.onInterviewDetails &&
            props.onInterviewDetails && {
              interviewType:
                props.onInterviewDetails.interviewType &&
                props.onInterviewDetails.interviewType,
              candidateId:
                props.onInterviewDetails.candidateName &&
                props.onInterviewDetails.candidateName,
              // candidateId:
              //   props.onInterviewDetails.candidateId &&
              //   props.onInterviewDetails.candidateId,
              jobId:
                props.onInterviewDetails.jobName &&
                props.onInterviewDetails.jobName,
              interviewStartTime:
                props.onInterviewDetails.interviewStartTime &&
                props.onInterviewDetails.interviewStartTime,
              interviewEndTime:
                props.onInterviewDetails.interviewEndTime &&
                props.onInterviewDetails.interviewEndTime,
              interviewDate: moment(
                props.onInterviewDetails.interviewDate &&
                  props.onInterviewDetails.interviewDate
              ),
              meetingLink:
                props.onInterviewDetails.interviewTypeDetails &&
                props.onInterviewDetails.interviewTypeDetails,
              contactName:
                props.onInterviewDetails.interviewTypeDetails &&
                props.onInterviewDetails.interviewTypeDetails.contactName &&
                props.onInterviewDetails.interviewTypeDetails.contactName,
              phone:
                props.onInterviewDetails.interviewTypeDetails &&
                props.onInterviewDetails.interviewTypeDetails.phone &&
                props.onInterviewDetails.interviewTypeDetails.phone,
              instruction:
                props.onInterviewDetails.instruction &&
                props.onInterviewDetails.instruction,
              duration:
                props.onInterviewDetails.duration &&
                props.onInterviewDetails.duration,
              pointOfContact:
                props.onInterviewDetails.interviewTypeDetails &&
                props.onInterviewDetails.interviewTypeDetails.pointOfContact &&
                props.onInterviewDetails.interviewTypeDetails.pointOfContact,
              officeAddress:
                props.onInterviewDetails.interviewTypeDetails &&
                props.onInterviewDetails.interviewTypeDetails.officeAddress &&
                props.onInterviewDetails.interviewTypeDetails.officeAddress,
              interviewerDetails:
                props.onInterviewDetails.interviewers &&
                props.onInterviewDetails.interviewers.map((data) => {
                  return {
                    name: data.name,
                    designation: data.designation,
                    email: data.email,
                  };
                }),
              jobQuestions:
                props.onInterviewDetails.jobQuestions &&
                props.onInterviewDetails.jobQuestions.map((data) => {
                  return data;
                }),
            }
          }
          form={form}
          layout="vertical"
        >
          <Row>
            <Col style={{ padding: "0px 20px 80px 20px" }} xs={24} md={24}>
              {props.pageFrom && props.pageFrom === "taskAndCalendar" && (
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please select candidate",
                    },
                  ]}
                  label="Candidate"
                  name="candidateId"
                >
                  <Input
                    style={{
                      cursor: "not-allowed",
                    }}
                    readOnly={true}
                  />
                </Form.Item>
              )}
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please select job",
                  },
                ]}
                name="jobId"
                label="Job"
              >
                <Input
                  style={{
                    cursor: "not-allowed",
                  }}
                  readOnly={true}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please select interview date",
                  },
                ]}
                name="interviewDate"
                label="Interview Date"
              >
                <DatePicker
                  format={dateFormate}
                  className={commonCssStyle.datePickerBgColor}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Row style={{ marginTop: "20px" }} justify="space-between">
                <Col md={7}>
                  <Form.Item
                    label="Start Time"
                    name="interviewStartTime"
                    rules={[
                      {
                        required: true,
                        message: "Please select interview start time",
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      onChange={(val, data) => {
                        form.setFieldsValue({
                          interviewEndTime: val,
                          duration: 0 + " hours",
                        });
                        setStartTime(val);
                      }}
                      placeholder="Select Start Time"
                    >
                      {timeArray.map((data, index) => {
                        return (
                          <Select.Option key={index} value={data}>
                            {data}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={7}>
                  <Form.Item label="End Time" name="interviewEndTime">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      onChange={(val, data) => {
                        let interviewTimeDate =
                          form.getFieldsValue("interviewEndTime");
                        if (
                          interviewTimeDate.interviewStartTime &&
                          interviewTimeDate.interviewEndTime
                        ) {
                          var startTime = moment(
                            interviewTimeDate.interviewStartTime,
                            "HH:mm a"
                          );
                          var endTime = moment(
                            interviewTimeDate.interviewEndTime,
                            "HH:mm a"
                          );
                          // calculate total duration
                          let duration = moment.duration(
                            endTime.diff(startTime)
                          );
                          // duration in hours
                          let hours = parseInt(duration.asHours());
                          // duration in minutes
                          let minutes = parseInt(duration.asMinutes()) % 60;
                          form.setFieldsValue({
                            duration:
                              hours +
                              " " +
                              "hours" +
                              " " +
                              minutes +
                              " " +
                              "minutes",
                          });
                        }
                      }}
                      placeholder="Select End Time"
                    >
                      {timeArray.map((data, index) => {
                        if (startTime === data) {
                          disableData = false;
                        }
                        return (
                          <Select.Option
                            disabled={disableData}
                            key={index}
                            value={data}
                          >
                            {data}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={7}>
                  <Form.Item label="Duration" name="duration">
                    <Input readOnly placeholder="Duration" />
                  </Form.Item>
                </Col>
                <Col md={24}>
                  <Form.List
                    /* name="interview" */
                    name="interviewerDetails"
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, key) => (
                          <>
                            <Row align="middle" justify="space-between">
                              <Interview
                                interviewersHandler={
                                  props.onTasksData &&
                                  props.onTasksData.interviewers &&
                                  props.onTasksData.interviewers
                                }
                                onRemoveInterviewer={removeInterviewer}
                                onModalName={
                                  props.onModalName && props.onModalName
                                }
                                field={field}
                                form={form}
                              />

                              {/* {key !== 0 && ( */}
                              <Col>
                                <Button
                                  type="primary"
                                  size="small"
                                  shape="circle"
                                  style={{
                                    marginTop: "3px",
                                    marginRight: "7px",
                                    float: "right",
                                    backgroundColor: "#F83A3A",
                                  }}
                                >
                                  <MinusOutlined
                                    color={"var(--theme)"}
                                    onClick={() => {
                                      setRemoveInterviewer((val) => !val);

                                      remove(key);
                                    }}
                                  />
                                </Button>
                              </Col>
                              {/* )} */}
                            </Row>
                          </>
                        ))}
                        <Col>
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                              add();
                            }}
                            shape="circle"
                          >
                            <div style={{ transform: "translateY(2px)" }}>
                              <PlusForButtonIcon
                                height={"19px"}
                                width={"19px"}
                                color={"var(--theme)"}
                              />
                            </div>
                          </Button>
                          <p
                            style={{ marginTop: "-27px", marginLeft: "40px" }}
                            className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.addLocation}`}
                          >
                            Add Interviewer
                          </p>
                        </Col>
                      </>
                    )}
                  </Form.List>
                  <Form.Item
                    className={commonCssStyle.sectionEndGap}
                    style={{ marginBottom: "0px" }}
                    label="Mode of Interview"
                    name="interviewType"
                    rules={[
                      {
                        required: true,
                        message: "Please select mode of interview",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={onChange}
                      value={valueData}
                      className="radioBtnText"
                    >
                      <Radio value="web">Virtual</Radio>
                      <Radio value="office">In-Office</Radio>
                      <Radio value="telephonic">Telephonic</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col md={24}>
                  <Row style={{ gap: "16px" }} align="middle">
                    <Col>
                      <Form.Item name="attachJD">
                        <Switch
                          checked={attachedJd}
                          onChange={(val) => {
                            setAttachedJd(val);
                          }}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <p className={`${commonCssStyle.formInputLabel}`}>
                        Attach Job Description
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col md={24}>
                  {valueData === "web" ? (
                    <Form.Item
                      label="Meeting Link"
                      name="meetingLink"
                      rules={[
                        {
                          required: false,
                          message: "Please enter meeting link",
                        },
                      ]}
                    >
                      <Input placeholder="Add Meeting Link" />
                    </Form.Item>
                  ) : valueData === "office" ? (
                    <InOffice />
                  ) : (
                    <Telephonic />
                  )}
                </Col>
                <Col md={24}>
                  <Row className={commonCssStyle.sectionEndGap}>
                    <Col md={24}>
                      <Form.List /* name="assesment" */ name="jobQuestions">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map((field, key) => (
                              <Row>
                                <Col md={24}>
                                  <Assesment
                                    onQuestionData={props.assesmentQuestionData}
                                    field={field}
                                  />
                                </Col>

                                <Col md={24}>
                                  <p
                                    style={{
                                      marginTop: "2px",
                                      float: "right",
                                      color: "#F83A3A",
                                    }}
                                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.removeLocation}`}
                                  >
                                    {/* Remove Assesment Question */}
                                    Remove Interview Feedback
                                  </p>
                                  <Button
                                    type="primary"
                                    size="small"
                                    shape="circle"
                                    style={{
                                      marginTop: "3px",
                                      marginRight: "7px",
                                      float: "right",
                                      backgroundColor: "#F83A3A",
                                    }}
                                  >
                                    <div
                                      style={{
                                        transform: "translateY(-4px)",
                                      }}
                                      onClick={() => remove(key)}
                                    >
                                      <MinusIcons color={"var(--theme)"} />
                                    </div>
                                  </Button>
                                </Col>
                              </Row>
                            ))}

                            <Button
                              size="small"
                              type="primary"
                              onClick={() => add()}
                              shape="circle"
                            >
                              <div style={{ transform: "translateY(2px)" }}>
                                <PlusForButtonIcon
                                  height={"19px"}
                                  width={"19px"}
                                  color={"var(--theme)"}
                                />
                              </div>
                            </Button>
                            <p
                              style={{ marginTop: "-27px", marginLeft: "40px" }}
                              className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.addLocation}`}
                            >
                              Add Interview Feedback
                            </p>
                          </>
                        )}
                      </Form.List>
                      <Form.Item
                        className={commonCssStyle.sectionEndGap}
                        label="Instruction"
                        name="instruction"
                      >
                        <Input.TextArea placeholder="Type instruction here..." />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              md={24}
              className={commonCssStyle.footerShadow}
              style={{ width: "690px" }}
            >
              <Row justify="end" style={{ gap: "25px" }}>
                <Col>
                  <Button
                    className={commonCssStyle.buttonSettingsSecondary}
                    type="default"
                    onClick={() => {
                      props.onRsOpen();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Row gutter={[16, 0]}>
                    <Col>
                      <Button
                        className={commonCssStyle.buttonSettingsSecondary}
                        onClick={() => {
                          // setReschedule(true);
                          setEmail(false);
                          form.submit();
                        }}
                      >
                        Save
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        style={{ width: "auto" }}
                        className={commonCssStyle.buttonSettingsPrimary}
                        type="primary"
                        htmlType="button"
                        onClick={() => {
                          setEmail(true);
                          // setNotify(true);
                          form.submit();
                        }}
                      >
                        Send and Save
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    settindsData: state.settings.settings,
    assesmentQuestionData:
      state.interviewAssessmentQuestions.interviewAssessmentQuestions,
    filterData: state.taskAndCalendar.filterData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    putInterViewHandler: (data, callback) => {
      dispatch(putInterViewAction(data, callback));
    },
    postInterViewHandler: (data, callback) => {
      dispatch(postInterViewAction(data, callback));
    },
    getInterviewHandler: (id) => {
      dispatch(getCandidateInterviewAction(id));
    },
    getTaskFilterHandler: (data) => {
      dispatch(getTasksFilterAction(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RescheduleInterview);
function Assesment({ field, onQuestionData }) {
  const [assessmentQuestion, setAssessmentQuestion] = useState("");
  {
    /* Add Assesment Questions */
  }
  return (
    <>
      <Form.Item
        {...field}
        rules={[
          {
            required: true,
            message: "Select assessment question",
          },
        ]}
        // label="Assesment Question"
        label="Interview Feedback"
      >
        {assessmentQuestion === "others" ? (
          <Input.TextArea placeholder="Add Interview Feedback" />
        ) : (
          <Select
            getPopupContainer={(trigger) => trigger.parentElement}
            onChange={(val, data) => {
              if (data.id) {
                setAssessmentQuestion(data.id);
              }
            }}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {onQuestionData &&
              onQuestionData.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.question}>
                    {data.question}
                  </Select.Option>
                );
              })}
            <Select.Option id="others" value="">
              Others
            </Select.Option>
          </Select>
        )}
      </Form.Item>
    </>
  );
}
function Interview({ field, form, onModalName, onRemoveInterviewer }) {
  const [validationForInterview, setValidationForInterview] = useState(true);
  useEffect(() => {
    if (onModalName === "edit") {
      form.getFieldValue("interviewerDetails").map((x) => {
        if (
          (x && x.name && x.name.length !== 0) ||
          (x && x.designation && x.designation.length !== 0) ||
          (x && x.email && x.email.length !== 0)
        ) {
          setValidationForInterview(false);
        } else {
          setValidationForInterview(true);
        }
      });
      // setValidationForInterview(false);
    }
  }, [onRemoveInterviewer]);

  useEffect(() => {
    if (onModalName !== "edit") {
      form.getFieldValue("interviewerDetails").map((x) => {
        if (
          (x && x.name && x.name.length !== 0) ||
          (x && x.designation && x.designation.length !== 0) ||
          (x && x.email && x.email.length !== 0)
        ) {
          setValidationForInterview(false);
        } else {
          setValidationForInterview(true);
        }
      });
      // setValidationForInterview(false);
    }
  }, [onRemoveInterviewer]);

  return (
    <>
      <Col md={7}>
        <Form.Item
          required={false}
          rules={[
            {
              required: validationForInterview,

              message: "Please enter interviewer name",
            },
          ]}
          name={[field.name, "name"]}
          label="Interviewer Name"
        >
          <Input
            onChange={(e) => {
              if (onModalName === "edit") {
                form.getFieldValue("interviewerDetails").map((x) => {
                  if (x) {
                    if (!x.name && !x.designation && !x.email) {
                      setValidationForInterview(true);
                    } else {
                      setValidationForInterview(false);
                    }
                  }
                });
              } else {
                if (e.target.value.length > 0) {
                  setValidationForInterview(false);
                } else {
                  setValidationForInterview(true);
                }
              }
            }}
            placeholder="Enter Interviewer Name"
          />
        </Form.Item>
      </Col>

      <Col md={7}>
        <Form.Item
          required={false}
          rules={[
            {
              required: validationForInterview,

              message: "Please enter designation",
            },
          ]}
          name={[field.name, "designation"]}
          label="Interviewer Designation"
        >
          <Input
            onChange={(e) => {
              if (onModalName === "edit") {
                form.getFieldValue("interviewerDetails").map((x) => {
                  if (x) {
                    if (!x.name && !x.designation && !x.email) {
                      setValidationForInterview(true);
                    } else {
                      setValidationForInterview(false);
                    }
                  }
                });
              } else {
                if (e.target.value.length > 0) {
                  setValidationForInterview(false);
                } else {
                  setValidationForInterview(true);
                }
              }
            }}
            placeholder="Enter Interviewer Designation"
          />
        </Form.Item>
      </Col>

      <Col md={7}>
        <Form.Item
          required={false}
          name={[field.name, "email"]}
          label="Interview Mail Id"
          rules={[
            {
              type: "email",
              message: "Enter valid email",
            },
            {
              required: validationForInterview,

              message: "Please enter email",
            },
          ]}
        >
          <Input
            onChange={(e) => {
              if (onModalName === "edit") {
                form.getFieldValue("interviewerDetails").map((x) => {
                  if (x) {
                    if (!x.name && !x.designation && !x.email) {
                      setValidationForInterview(true);
                    } else {
                      setValidationForInterview(false);
                    }
                  }
                });
              } else {
                if (e.target.value.length > 0) {
                  setValidationForInterview(false);
                } else {
                  setValidationForInterview(true);
                }
              }
            }}
            placeholder="Enter Interviewer Mail Id"
          />
        </Form.Item>
      </Col>
    </>
  );
}
