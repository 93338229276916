import { Button, Col, Form, Input, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import Modal from "antd/lib/modal/Modal";
import { CloseIcon } from "../../IconsComp/Icons";
import commonCssStyles from "../../UI/CommonCss.module.css";
function EmployeeBankModal(props) {
  const [form] = useForm();
  return (
    <Modal
    closable={false}
      className="modalModifiedForTemplete"
      footer={false}
      // onCancel={props.bankDetailsClose}
      open={props.bankDetailsOpen}
      extra={
        <div
          onClick={() => props.bankDetailsClose()}
          className={`${commonCssStyles.coursorPointer} `}
        >
          {" "}
          <CloseIcon color={"var(--icons-color)"} />
        </div>
      }
      title={
        <Row >
          <Col md={20}><p
          // style={{ fontSize: "20px", margin: "0px" }}
          className={`${commonCssStyles.popUpHeadingText}`}
        >
          Edit Bank Details
        </p></Col>
        <Col md={4}>
        <div
        style={{float:"right"}}
        onClick={() => {
          props.bankDetailsClose();
        }}
        className={`${commonCssStyles.coursorPointer} `}
        >
          <CloseIcon   color={"var(--body-text-color)" || "var(--theme)"} />
        </div></Col>
        </Row>
      }
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={
          props.employee && {
            bankName: props.employee.bankName,
            bankBranchName: props.employee.bankBranchName,
            AccountName: props.employee.AccountName,
            AccountNumber: props.employee.AccountNumber,
            ifsc: props.employee.ifsc,
          }
        }
        onFinish={props.onSubmit}
      >
        <Row style={{ padding: "10px 20px" }}>
          <Col md={24}>
            <Row>
              <Col md={24}>
                <Form.Item
                  name="bankName"
                  label="Bank"
                  rules={[
                    {
                      required: false,
                      message: "Bank  is required",
                    },
                    // {
                    //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                    //   message:
                    //     "It allows only alphabets,no whitespace at the beginning and end",
                    // },
                  ]}
                >
                  <Input placeholder="Enter bank" />
                </Form.Item>

                <Form.Item
                  name="AccountNumber"
                  label="Account Number"
                  rules={[
                    {
                      required: false,
                      message: "Account number is required",
                    },
                    // {
                    //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9\s]+$/,
                    //   message:
                    //     "It allows only alphanumerics,no whitespace at the beginning and end",
                    // },
                  ]}
                >
                  <Input placeholder="Enter account number" />
                </Form.Item>

                <Form.Item
                  name="AccountName"
                  label="Account in the name of"
                  rules={[
                    {
                      required: false,
                      message: "Account name is required",
                    },
                    // {
                    //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9\s]+$/,
                    //   message:
                    //     "It allows only alphanumerics,no whitespace at the beginning and end",
                    // },
                  ]}
                >
                  <Input placeholder="Enter account name" />
                </Form.Item>

                <Form.Item
                  name="ifsc"
                  label="IFSC Code"
                  rules={[
                    {
                      required: false,
                      message: "Ifsc is Required",
                    },
                    // {
                    //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Z0-9\s]+$/,
                    //   message: "Invalid IFSC",
                    // },

                    // {
                    //   min: 21,
                    //   message: "IFSC is Should be in 11-digit alpha-numeric format",
                    // },
                  ]}
                >
                  <Input
                    maxLength={11}
                    autoComplete="off"
                    className="ant-input mi_input"
                    type="text"
                    placeholder="Enter ifsc number"
                  />
                </Form.Item>
                <Form.Item
                  name="bankBranchName"
                  label="Branch Name"
                  rules={[
                    {
                      required: false,
                      message: "Branch name  is required",
                    },
                    // {
                    //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                    //   message:
                    //     "It allows only Alphabets,No whitespace at the beginning and end",
                    // },
                  ]}
                >
                  <Input placeholder="Enter branch name" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          justify="end"
          style={{ gap: "17px" }}
          className={commonCssStyles.footerShadowForTemplete}
        >
          <Col>
            <Button
              className={
                commonCssStyles.footerButtonAddCandidateDrawerSecondary
              }
              onClick={() => {
                props.bankDetailsClose();
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                form.submit();
              }}
              htmlType="button"
              className={commonCssStyles.footerButtonAddCandidateDrawerPrimary}
              type="primary"
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export default EmployeeBankModal;
