import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  emailNotificationTypes: [],
  emailNotificationType: null,
};
export const masterEmailNotificationTypesSlice = createSlice({
  name: "emailNotificationTypes",
  initialState,
  reducers: {
    getEmailNotificationTypesSlice(state, action) {
      state.emailNotificationTypes = action.payload.result;
    },
    getEmailNotificationTypeSlice(state, action) {
      state.emailNotificationType = action.payload.result;
    },
    postEmailNotificationTypesSlice(state, action) {
      state.emailNotificationTypes = [
        ...state.emailNotificationTypes,
        action.payload.result,
      ];
    },
    putEmailNotificationTypesSlice(state, action) {
      state.emailNotificationTypes = state.emailNotificationTypes.map(
        (data) => {
          if (data.id === action.payload.result.id) {
            return action.payload.result;
          } else {
            return data;
          }
        }
      );
    },
    deleteEmailNotificationTypesSlice(state, action) {
      state.emailNotificationTypes = state.emailNotificationTypes.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});
export const {
  getEmailNotificationTypesSlice,
  getEmailNotificationTypeSlice,
  postEmailNotificationTypesSlice,
  putEmailNotificationTypesSlice,
  deleteEmailNotificationTypesSlice,
} = masterEmailNotificationTypesSlice.actions;
export default masterEmailNotificationTypesSlice.reducer;
