import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import { putInterViewAction } from "../../../Reusable/Store/Action/RecruitmentAction";
import {
  getTasksFilterAction,
  putTaskAndCalendarAction,
} from "../../../Reusable/Store/Action/TaskAndCalendarAction";
import {
  CalendarBlankIcon,
  CaretBlackDownIcon,
  CloseIcon,
} from "../../IconsComp/Icons";
import commonStyles from "../../UI/CommonCss.module.css";
import PermissionMessage from "../../UI/PermissionMessage";
function RegularTaskDrawer(props) {
  const dateFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";
  const timeFormate =
    props.settindsData &&
    props.settindsData.timeFormat &&
    props.settindsData.timeFormat === "12hours"
      ? "hh:mm a"
      : "HH:mm a";
  const timeArray = [
    "8:00 AM",
    "8:30 AM",
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
    "5:30 PM",
    "6:00 PM",
    "6:30 PM",
    "7:00 PM",
    "7:30 PM",
    "8:00 PM",
    "8:30 PM",
    "9:00 PM",
    "9:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
    "12:00 AM",
    "12:30 AM",
    "1:00 AM",
    "1:30 AM",
    "2:00 AM",
    "2:30 AM",
    "3:00 AM",
    "3:30 AM",
    "4:00 AM",
    "4:30 AM",
    "5:00 AM",
    "5:30 AM",
    "6:00 AM",
    "6:30 AM",
    "7:00 AM",
    "7:30 AM",
  ];

  const [menuKey, setMenuKey] = useState("1");
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });

  const menu2 = (
    <Menu
      style={{ width: "214px", margin: "11px 0px" }}
      selectable
      items={[
        {
          key: "1",
          label: (
            <p
              onClick={() => {
                setMenuKey("1");
                props.getCheckPermissionHandler("Task-edit", (success) => {
                  if (success) {
                    props.putTaskHandler(
                      {
                        id: props.onTaskData.id,
                        status: "pending",
                      },
                      (success) => {
                        if (success) {
                          if (props.onViewType && props.onViewType === "list") {
                            props.onOk();
                          } else {
                            let taskFilterDataFromLocal = JSON.parse(
                              localStorage.getItem("taskFilterData")
                            );
                            if (taskFilterDataFromLocal) {
                              props.getTaskFilterHandler({
                                type: "task",
                                viewType: "calendar",
                                ...props.filterData,
                                ...taskFilterDataFromLocal,
                              });
                            } else {
                              props.getTaskFilterHandler({
                                type: "task",
                                viewType: "calendar",
                                ...props.filterData,
                              });
                            }
                            props.onCloseDrawer();
                          }
                        }
                      }
                    );
                  } else {
                    setOpenPMModal({
                      open: true,
                      permission: "Task Details",
                    });
                  }
                });
              }}
              style={{
                color:
                  menuKey === "1"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonStyles.marginBottomRemove} ${commonStyles.darkText}`}
            >
              Pending
            </p>
          ),
        },
        {
          key: "2",
          label: (
            <p
              onClick={() => {
                setMenuKey("2");
                props.getCheckPermissionHandler("Task-edit", (success) => {
                  if (success) {
                    props.putTaskHandler(
                      {
                        id: props.onTaskData.id,
                        status: "completed",
                      },
                      (success) => {
                        if (success) {
                          if (props.onViewType && props.onViewType === "list") {
                            props.onOk();
                          } else {
                            let taskFilterDataFromLocal = JSON.parse(
                              localStorage.getItem("taskFilterData")
                            );
                            if (taskFilterDataFromLocal) {
                              props.getTaskFilterHandler({
                                type: "task",
                                viewType: "calendar",
                                ...props.filterData,
                                ...taskFilterDataFromLocal,
                              });
                            } else {
                              props.getTaskFilterHandler({
                                type: "task",
                                viewType: "calendar",
                                ...props.filterData,
                              });
                            }
                            props.onCloseDrawer();
                          }
                        }
                      }
                    );
                  } else {
                    setOpenPMModal({
                      open: true,
                      permission: "Task Details",
                    });
                  }
                });
              }}
              style={{
                color:
                  menuKey === "2"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonStyles.marginBottomRemove}  ${commonStyles.darkText}`}
            >
              Completed
            </p>
          ),
        },
      ]}
    />
  );

  const [form] = useForm();
  const [candidateName, setCandidateName] = useState({});
  const [clientName, setClientName] = useState({});
  const [jobName, setJobName] = useState({});
  const [isPublic, setIsPublic] = useState(null);

  useEffect(() => {
    // if (props.onModalName && props.onModalName === "display") {
    //   let data1 =
    //     props.onCandidateData &&
    //     props.onCandidateData.find(
    //       (data) => data.id === props.onTaskData.candidateId
    //     );
    //   // console.log(props.onTaskData,jobName,candidateName,assignedToName,assignedByName);
    //   setCandidateName(data1);
    //   let data4 =
    //     props.onCompanyData &&
    //     props.onCompanyData.find(
    //       (data) => data.id === props.onTaskData.clientId
    //     );

    //   setClientName(data4);
    //   let data5 =
    //     props.onJobData &&
    //     props.onJobData.find((data) => data.id === props.onTaskData.jobId);

    //   setJobName(data5);

    //   // setIsPublic(
    //   //   props.onCandidateData &&
    //   //     props.onCandidateData.find((data) => data === data.isPublic)
    //   // );
    // }
    if (props.onModalName && props.onModalName === "edit") {
      setIsPublic(props.onTaskData && props.onTaskData.isPublic);
    }
  }, []);

  function onSubmit(val) {
    const data = {
      ...val,
      isPublic: val.isPublic ? "1" : "0",
      startDate: moment(val.startDate).format("YYYY-MM-DD"),
      dueDate: moment(val.dueDate).format("YYYY-MM-DD"),
    };

    if (props.onModalName === "add") {
      props.onSumbitData(data, (success) => {
        if (success) {
          form.resetFields();
          props.onCloseDrawer();
        }
      });
    } else if (props.onModalName === "edit") {
      props.onSumbitData({ id: props.onTaskData.id, ...data }, (success) => {
        if (success) {
          form.resetFields();
          // props.onCloseDrawer();
        }
      });
    } else if (props.onModalName === "addFromCalendar") {
      props.onSumbitData(data);
    }
  }
  return (
    <>
      <Drawer
        className="modalModified"
        closable={false}
        open={props.onOpen}
        extra={
          <span
            onClick={() => props.onCloseDrawer()}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)"} />
          </span>
        }
        title={
          <>
            <h3
              className={commonStyles.modalHeading}
              style={{ fontSize: "20px", margin: "0px" }}
            >
              {props.onTaskData && props.onModalName === "edit"
                ? "Edit task"
                : props.onModalName === "add" ||
                  props.onModalName === "addFromCalendar"
                ? "Add new task"
                : "Details"}
            </h3>
          </>
        }
        width={503}
        height={48}
      >
        <Row>
          <Col md={24} style={{ padding: "0px 20px 150px 20px" }}>
            {props.onModalName && props.onModalName !== "display" ? (
              <Form
                scrollToFirstError={{
                  behavior: "smooth",
                  block: "center",
                  inline: "center",
                }}
                initialValues={
                  props.onTaskData && props.onModalName === "edit"
                    ? {
                        title: props.onTaskData.title,
                        //taskType: props.onTaskData.taskType,
                        candidateId: props.onTaskData.candidateId,
                        clientId: props.onTaskData.clientId,
                        jobId: props.onTaskData.jobId,
                        //employeeName: props.onTaskData.employeeName,
                        description: props.onTaskData.description,
                        //comments: props.onTaskData.comments,
                        startDate: moment(props.onTaskData.startDate),
                        dueDate: moment(props.onTaskData.dueDate),
                        assignedTo: props.onTaskData.assignedTo,
                        assignedBy: props.onTaskData.assignedBy,
                        status: props.onTaskData.status,
                        isPublic: props.onTaskData.isPublic,
                        startTime: props.onTaskData.startTime,
                        endTime: props.onTaskData.endTime,
                      }
                    : props.onModalName === "addFromCalendar"
                    ? {
                        startDate: moment(props.onSelectedData.start),
                        dueDate: moment(props.onSelectedData.end),
                        startTime: moment(props.onSelectedData.start).format(
                          timeFormate
                        ),
                        assignedBy: props.asignById.id,
                        endTime: moment(props.onSelectedData.end)
                          .add(30, "minutes")
                          .format(timeFormate),
                      }
                    : {
                        // startTime: moment().format(timeFormate),
                        // endTime: moment()
                        //   .add(30, "minutes")
                        //   .format(timeFormate),
                        startTime: "8:00 AM",
                        endTime: "9:00AM",
                        assignedBy: props.asignById.id,
                      }
                }
                onFinish={(val) => {
                  onSubmit(val);
                }}
                layout="vertical"
                form={form}
              >
                <Form.Item
                  label="Task Name"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter task name",
                    },
                  ]}
                >
                  <Input placeholder="Enter task name" />
                </Form.Item>
                {/* <Form.Item
                label="Task Related to"
                name="taskType"
                rules={[
                  {
                    required: true,
                    message: "Please select task related to",
                  },
                ]}
              >
                <Select
                 
                  placeholder="Select task related to"
                >
                  <Select.Option key="1" value="company">
                    Company
                  </Select.Option>
                  <Select.Option key="2" value="candidate">
                    Candidate
                  </Select.Option>
                  <Select.Option key="3" value="job">
                    Job
                  </Select.Option>
                  <Select.Option key="4" value="employee">
                    Employee
                  </Select.Option>
                </Select>
              </Form.Item> */}

                <Form.Item
                  label="Company Name"
                  name="clientId"
                  rules={[
                    {
                      required: false,
                      message: "Please select company name",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select company name"
                  >
                    {props.onCompanyData &&
                      props.onCompanyData.map((data, index) => {
                        return (
                          <Select.Option key={index} value={data.id}>
                            {data.clientName}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Job Name"
                  name="jobId"
                  rules={[
                    {
                      required: false,
                      message: "Please select job name",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select job name"
                  >
                    {props.onJobData &&
                      props.onJobData.map((data, index) => {
                        return (
                          <Select.Option key={index} value={data.id}>
                            {data.title}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Candidate Name"
                  name="candidateId"
                  rules={[
                    {
                      required: false,
                      message: "Please enter candidate name",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Enter candidate name"
                  >
                    {props.onCandidateData &&
                      props.onCandidateData.map((data, index) => {
                        return (
                          <Select.Option key={index} value={data.id}>
                            {data.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                {/* <Form.Item
                label="Employee Name"
                name="employeeName"
                rules={[
                  {
                    required: false,
                    message: "Please select employee name",
                  },
                ]}
              >
                <Input placeholder="Select employee name" />
              </Form.Item> */}

                <Form.Item
                  label="Task Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter task description",
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Enter task description" />
                </Form.Item>
                {/* <Form.Item
                label="Comments"
                name="comments"
                rules={[
                  {
                    required: true,
                    message: "Please enter comments",
                  },
                ]}
              >
                <Input.TextArea placeholder="Enter comments" />
              </Form.Item> */}
                <Row justify="space-between">
                  <Col md={11}>
                    <Form.Item
                      label="Start Date"
                      name="startDate"
                      rules={[
                        {
                          required: true,
                          message: "Please select start date",
                        },
                      ]}
                    >
                      <DatePicker
                        getPopupContainer={(trigger) => trigger.parentElement}
                        placeholder="Select start Date"
                        format={dateFormate}
                        style={{ width: "100%" }}
                        className={`${commonStyles.datePickerBgColor} `}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={11}>
                    <Form.Item
                      name="dueDate"
                      label="End Date"
                      rules={[
                        {
                          required: true,
                          message: "Please select end date",
                        },
                      ]}
                    >
                      <DatePicker
                        getPopupContainer={(trigger) => trigger.parentElement}
                        placeholder="Select end Date"
                        format={dateFormate}
                        style={{ width: "100%" }}
                        className={`${commonStyles.datePickerBgColor} `}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col md={11}>
                    <Form.Item label="Start time" name="startTime">
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        placeholder="Select Start Time"
                      >
                        {timeArray.map((data, index) => {
                          return (
                            <Select.Option key={index} value={data}>
                              {data}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={11}>
                    <Form.Item label="End time" name="endTime">
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        placeholder="Select Start Time"
                      >
                        {timeArray.map((data, index) => {
                          return (
                            <Select.Option key={index} value={data}>
                              {data}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label="Assign by"
                  name="assignedBy"
                  rules={[
                    {
                      required: false,
                      message: "Please select assign by",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    placeholder="Select assign by"
                  >
                    {props.employeesData &&
                      props.employeesData.map((data, index) => {
                        return (
                          <Select.Option
                            key={index}
                            value={data.id}
                          >{`${data.firstName} ${data.lastName}`}</Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                {/* {isPublic !== 1 && ( */}
                <Form.Item
                  label="Assign to"
                  name="assignedTo"
                  rules={[
                    {
                      required: false,
                      message: "Please select assign to",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    placeholder="Select assign to"
                  >
                    {props.employeesData &&
                      props.employeesData.map((data, index) => {
                        return (
                          <Select.Option
                            key={index}
                            value={data.id}
                          >{`${data.firstName} ${data.lastName}`}</Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                {/* )} */}

                <Row align="middle" style={{ gap: "17px" }}>
                  <Col>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please select privacy",
                        },
                      ]}
                      name="isPublic"
                      label="Task Privacy"
                    >
                      {/* <Switch /> */}
                      <Radio.Group
                        onChange={(val) => {
                          setIsPublic(val.target.value);
                        }}
                        className="radioBtnText"
                      >
                        <Radio style={{ paddingRight: "20px" }} value={1}>
                          <p className={`${commonStyles.formInputLabel}`}>
                            Public
                          </p>
                        </Radio>
                        <Radio style={{ paddingRight: "20px" }} value={0}>
                          <p className={`${commonStyles.formInputLabel}`}>
                            Private
                          </p>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            ) : (
              <>
                <Row>
                  <Col md={24}>
                    <p className={commonStyles.tableHeadingsColor}>
                      {props.onTaskData && props.onTaskData.title}
                    </p>
                  </Col>
                  <Col md={24}>
                    <Row style={{ gap: "10px" }} align="middle">
                      <Col>
                        <CalendarBlankIcon color={"var(--primaryColor)"} />
                      </Col>
                      <Col>
                        <p
                          className={`${commonStyles.marginBottomRemove} ${commonStyles.tableHeadingsColor}`}
                        >{`${
                          props.onTaskData &&
                          moment(props.onTaskData.startDate).format(dateFormate)
                        } to ${
                          props.onTaskData &&
                          moment(props.onTaskData.dueDate).format(dateFormate)
                        }`}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col style={{ margin: "10px 0px" }} md={24}>
                    {props.onTaskData &&
                    props.onTaskData.status === "pending" ? (
                      <Tag
                        style={{
                          backgroundColor: "#FFF0DB",
                          color: "#ff9a02",
                          width: "85px",
                          textAlign: "center",
                          padding: "6px 0px 5px 0px",
                          fontSize: "14px",
                        }}
                      >
                        Pending
                      </Tag>
                    ) : (
                      <Tag
                        style={{
                          backgroundColor: "#CCFAE4",
                          color: "#0E8553",
                          width: "85px",
                          textAlign: "center",
                          padding: "6px 0px 5px 0px",
                          fontSize: "14px",
                        }}
                      >
                        Completed
                      </Tag>
                    )}
                  </Col>

                  <Col md={24}>
                    <p className={` ${commonStyles.tableHeadingsColor}`}>
                      Description
                    </p>
                    <p className={commonStyles.tableData}>
                      {props.onTaskData && props.onTaskData.description ? (
                        props.onTaskData.description
                      ) : (
                        <p className={commonStyles.tableData}>NA</p>
                      )}
                    </p>
                  </Col>

                  <Col md={24}>
                    <p className={` ${commonStyles.tableHeadingsColor}`}>
                      Assigned to Name
                    </p>
                    <p className={commonStyles.tableData}>
                      {props.onTaskData && props.onTaskData.assignedToName ? (
                        props.onTaskData.assignedToName
                      ) : (
                        <p className={commonStyles.tableData}>NA</p>
                      )}
                    </p>
                  </Col>
                  <Col md={24}>
                    <p className={` ${commonStyles.tableHeadingsColor}`}>
                      Assigned by Name
                    </p>
                    <p className={commonStyles.tableData}>
                      {props.onTaskData && props.onTaskData.assignedByName ? (
                        props.onTaskData.assignedByName
                      ) : (
                        <p className={commonStyles.tableData}>NA</p>
                      )}
                    </p>
                  </Col>
                  <Col md={24}>
                    <p className={` ${commonStyles.tableHeadingsColor}`}>
                      Candidate Name
                    </p>
                    {/* <p className={commonStyles.tableData}>
                      {candidateName && candidateName.name ? (
                        candidateName.name
                      ) : (
                        <p className={commonStyles.tableData}>NA</p>
                      )}
                    </p> */}
                    {props.onTaskData && props.onTaskData.candidateName ? (
                      <p className={commonStyles.tableData}>
                        {props.onTaskData.candidateName}
                      </p>
                    ) : (
                      <p className={commonStyles.tableData}>NA</p>
                    )}
                  </Col>
                  <Col md={24}>
                    <p className={` ${commonStyles.tableHeadingsColor}`}>
                      Job Name
                    </p>
                    {/* <p className={commonStyles.tableData}>
                      {jobName && jobName.title ? (
                        jobName.title
                      ) : (
                        <p className={commonStyles.tableData}>NA</p>
                      )}
                    </p> */}
                    {props.onTaskData && props.onTaskData.jobName ? (
                      <p className={commonStyles.tableData}>
                        {props.onTaskData.jobName}
                      </p>
                    ) : (
                      <p className={commonStyles.tableData}>NA</p>
                    )}
                  </Col>
                  <Col md={24}>
                    <p className={` ${commonStyles.tableHeadingsColor}`}>
                      Company by Name
                    </p>
                    {/* <p>
                      {clientName && clientName.clientName ? (
                        clientName.clientName
                      ) : (
                        <p className={commonStyles.tableData}>NA</p>
                      )}
                    </p> */}
                    {props.onTaskData && props.onTaskData.clientName ? (
                      <p className={commonStyles.tableData}>
                        {props.onTaskData.clientName}
                      </p>
                    ) : (
                      <p className={commonStyles.tableData}>NA</p>
                    )}
                  </Col>
                  <Col md={24}>
                    <p className={` ${commonStyles.tableHeadingsColor}`}>
                      Status
                    </p>
                    <p className={commonStyles.tableData}>
                      {props.onTaskData && props.onTaskData.status ? (
                        props.onTaskData.status
                      ) : (
                        <p className={commonStyles.tableData}>NA</p>
                      )}
                    </p>
                  </Col>
                  <Col md={24}>
                    <p className={` ${commonStyles.tableHeadingsColor}`}>
                      Public or Private
                    </p>
                    <p className={commonStyles.tableData}>
                      {props.onTaskData &&
                      props.onTaskData.isPublic &&
                      props.onTaskData.isPublic
                        ? "Public"
                        : "Private"}
                    </p>
                  </Col>
                </Row>
              </>
            )}
          </Col>
          <Col
            md={24}
            className={commonStyles.footerShadow}
            style={{ width: "503px" }}
          >
            <Row justify="end" style={{ gap: "25px" }}>
              {props.permissions &&
              // props.onProfile &&
              props.onModalName &&
              props.onModalName === "display" &&
              props.permissions.find(
                (data) => data === "Task-status-update"
              ) ? (
                <Col>
                  <Button className={commonStyles.intaddButton} type="primary">
                    <Row align="middle" justify="space-around">
                      <Col>
                        <p className={commonStyles.marginBottomRemove}>
                          Task Status
                        </p>
                      </Col>
                      <Col>
                        <Dropdown placement="top" overlay={menu2}>
                          <Typography.Link>
                            <Space
                              className={commonStyles.tableHeadingTextData}
                            >
                              <CaretBlackDownIcon color={"white"} />
                            </Space>
                          </Typography.Link>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Button>
                </Col>
              ) : props.permissions &&
                props.onProfile &&
                // props.onProfile.isAdmin &&
                props.onModalName &&
                props.onModalName === "display" &&
                // props.onProfile.isAdmin == 1 &&
                props.permissions.find(
                  (data) => data === "Task-status-update"
                ) ? (
                <Col>
                  <Button className={commonStyles.intaddButton} type="primary">
                    <Row align="middle" justify="space-around">
                      <Col>
                        <p className={commonStyles.marginBottomRemove}>
                          Task Status
                        </p>
                      </Col>
                      <Col>
                        <Dropdown placement="top" overlay={menu2}>
                          <Typography.Link>
                            <Space
                              className={commonStyles.tableHeadingTextData}
                            >
                              <CaretBlackDownIcon color={"white"} />
                            </Space>
                          </Typography.Link>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Button>
                </Col>
              ) : (
                ""
              )}
              <Col>
                <Button
                  className={commonStyles.buttonSettingsSecondary}
                  type="default"
                  onClick={() => {
                    props.onCloseDrawer();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              {props.onModalName && props.onModalName !== "display" && (
                <Col>
                  <Button
                    onClick={() => {
                      form.submit();
                    }}
                    style={{ width: "auto" }}
                    htmlType="button"
                    className={commonStyles.buttonSettingsPrimary}
                    type="primary"
                  >
                    {props.onTaskData && props.onModalName === "edit"
                      ? "Save"
                      : "Create new task"}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Drawer>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    settindsData: state.settings.settings,
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editInterviewStatusHandler: (data, callback) => {
      dispatch(putInterViewAction(data, callback));
    },
    putTaskHandler: (data, callback) => {
      dispatch(putTaskAndCalendarAction(data, callback));
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
    getTaskFilterHandler: (data) => {
      dispatch(getTasksFilterAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegularTaskDrawer);
