import React, { useEffect, useState } from "react";
import { Tabs, Row, Col, Card } from "antd";

import Organization from "./Organization";
import BillingInvoice from "./BillingInvoice";
import TabSystem from "./TabSystem";
import DataImport from "./DataImport";
import Roles from "./Roles";
import Application from "./Application/Application";
import {
  getSettingsAction,
  getSettingsSubscriptionAction,
  postSettingsAction,
  getSettingsWorklocationAction,
  deleteSettingsWorkLocationAction,
  postTestCredentialsAction,
} from "../../../Reusable/Store/Action/SettingsAction";
import { getCountriesAction } from "../../../Reusable/Store/Action/Master/MasterCountryAction";
import { connect } from "react-redux";
import commonCssStyle from "../../UI/CommonCss.module.css";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import { checkPermissionReducer } from "../../../Reusable/Store/Slice/AuthPermissionsSlice";
import PermissionMessage from "../../UI/PermissionMessage";
function Settings({
  getSettingsAction,
  getSettingsSubscriptionAction,
  postSettingsAction,
  settings,
  settingsSubscription,
  countriesData,
  getCountriesHandler,
  getWorkLocationHandler,
  settingsWorklocationData,
  deleteWorkLocationActionHandler,
  getCountryHandler,
  permissions,
  getCheckPermissionHandler,
  testCredentialsHandler,
}) {
  const [key, setKey] = useState("1");
  const [countries, setCountries] = useState([]);
  const [acitveKey, setActiveKey] = useState("1");
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [permCity, setPermCity] = useState([]);
  const [permState, setPermStates] = useState([]);
  const [hoverTextColor, setHoverTestColor] = useState(false);
  const [hoverKey, setHoverKey] = useState("1");
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  useEffect(() => {
    const element = document.getElementById("page-top");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [key]);
  useEffect(() => {
    getSettingsAction();
    getSettingsSubscriptionAction();

    getWorkLocationHandler();
    getCountryHandler({ type: "country" }, (data, type) => {
      setCountries(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const deleteWorkLocationHandler = (data) => {
    deleteWorkLocationActionHandler(data, (success) => {
      if (success) {
        getWorkLocationHandler();
      }
    });
  };
  if (window.location.hash === "#/settings") {
    localStorage.removeItem("clientCompany");
    localStorage.removeItem("employee");
    localStorage.removeItem("requirementFilterData");
    localStorage.removeItem("recuritmentFilterData");
    localStorage.removeItem("taskFilterData");
    localStorage.removeItem("interviewFilterData");
    localStorage.removeItem("candidateDetailFromRequirement");
    localStorage.removeItem("candidateListForPool");
    localStorage.removeItem("recuritmentCandidateFilter");
    localStorage.removeItem("reportsCompanyDetailFilterData");
    localStorage.removeItem("reportsjobdetailfilterdata");
    localStorage.removeItem("reportsjobcandidatefilterdata");
    localStorage.removeItem("recuritmentReportFilter");
    localStorage.removeItem("fromWhichPage");
    localStorage.removeItem("employeeReportsFilter");
  }
  const dataArray = [
    permissions &&
      permissions.find((data) => data === "settings-system") && {
        key: "1",
        label: (
          <p
            onMouseEnter={() => {
              setHoverTestColor(true);
              setHoverKey("1");
            }}
            onMouseLeave={() => {
              setHoverTestColor(false);
              setHoverKey("");
            }}
            className={commonCssStyle.tabsText}
            style={{
              margin: "0px",
              color:
                key === "1"
                  ? "var(--menu-text-color)"
                  : "var(--body-text-color)",
            }}
          >
            System
          </p>
        ),
        children: (
          <>
            <Card
              size="large"
              style={{
                borderRadius: "7px",
                backgroundColor: "var(--background-color)",
              }}
            >
              {settings ? (
                <TabSystem
                  settings={settings}
                  postSettings={postSettingsAction}
                  testCredentialsHandler={testCredentialsHandler}
                ></TabSystem>
              ) : (
                ""
              )}
            </Card>
          </>
        ),
      },
    permissions &&
      permissions.find((data) => data === "settings-organisations") && {
        key: "2",
        label: (
          <p
            onMouseEnter={() => {
              setHoverTestColor(true);
              setHoverKey("2");
            }}
            onMouseLeave={() => {
              setHoverTestColor(false);
            }}
            className={commonCssStyle.tabsText}
            style={{
              margin: "0px",
              color:
                key === "2"
                  ? "var(--menu-text-color)"
                  : "var(--body-text-color)",
            }}
          >
            Organization
          </p>
        ),
        children: (
          <>
            <Card
              style={{
                borderRadius: "7px",
                backgroundColor: "var(--background-color)",
              }}
              size="large"
            >
              {settings ? (
                <Organization
                  onCoutriesData={(data) => {
                    getCountryHandler(
                      { id: data.id, type: data.type },
                      (data, type) => {
                        setTimeout(() => {
                          setStates(data);
                          setCity([]);
                        }, 300);
                      }
                    );
                  }}
                  onPermeCoutriesData={(data) => {
                    getCountryHandler(
                      { id: data.id, type: data.type },
                      (data, type) => {
                        setTimeout(() => {
                          setPermStates(data);
                          setCity([]);
                        }, 300);
                      }
                    );
                  }}
                  onCityData={(data) => {
                    getCountryHandler(
                      { id: data.id, type: data.type },
                      (data, type) => {
                        setTimeout(() => {
                          setCity(data);
                        }, 300);
                      }
                    );
                  }}
                  onPermCityData={(data) => {
                    getCountryHandler(
                      { id: data.id, type: data.type },
                      (data, type) => {
                        setTimeout(() => {
                          setPermCity(data);
                        }, 300);
                      }
                    );
                  }}
                  onCity={city}
                  onStatesData={states}
                  deleteWorkLocation={deleteWorkLocationHandler}
                  onSettingsWorklocation={settingsWorklocationData}
                  // countriesData={countriesData}
                  onPermCity={permCity}
                  onPermState={permState}
                  countriesData={countries}
                  settings={settings}
                  postSettings={postSettingsAction}
                  // onCoutriesData={(data) => {
                  //   props.getCountryHandler(
                  //     { id: data.id, type: data.type },
                  //     (data, type) => {
                  //       setTimeout(() => {
                  //         setStates(data);
                  //       }, 300);
                  //     }
                  //   );
                  // }}

                  // onCityData={(data) => {
                  //   props.getCountryHandler(
                  //     { id: data.id, type: data.type },
                  //     (data, type) => {
                  //       setTimeout(() => {
                  //         setCity(data);
                  //       }, 300);
                  //     }
                  //   );
                  // }}
                ></Organization>
              ) : (
                ""
              )}
            </Card>
          </>
        ),
      },
    permissions &&
      permissions.find((data) => data === "settings-billing") && {
        key: "3",
        label: (
          <p
            onMouseEnter={() => {
              setHoverTestColor(true);
            }}
            onMouseLeave={() => {
              setHoverTestColor(false);
            }}
            className={commonCssStyle.tabsText}
            style={{
              margin: "0px",
              color:
                key === "3"
                  ? "var(--menu-text-color)"
                  : "var(--body-text-color)",
            }}
          >
            Billing and invoice
          </p>
        ),
        children: (
          <>
            <Card
              size="large"
              style={{
                borderRadius: "7px",
                backgroundColor: "var(--background-color)",
              }}
            >
              <BillingInvoice
                settingsCurrency={settings && settings.currency}
                dateFormat={settings && settings.dateFormat}
                settingsSubscription={settingsSubscription}
              ></BillingInvoice>
            </Card>
          </>
        ),
      },
    permissions &&
      permissions.find((data) => data === "settings-roles-list") && {
        key: "4",
        label: (
          <p
            onMouseEnter={() => {
              setHoverTestColor(true);
            }}
            onMouseLeave={() => {
              setHoverTestColor(false);
            }}
            className={commonCssStyle.tabsText}
            style={{
              margin: "0px",
              color:
                key === "4"
                  ? "var(--menu-text-color)"
                  : "var(--body-text-color)",
            }}
          >
            Roles
          </p>
        ),
        children: (
          <>
            <Card
              style={{
                borderRadius: "7px",
                backgroundColor: "var(--background-color)",
              }}
              size="large"
            >
              <Roles></Roles>
            </Card>
          </>
        ),
      },
    {
      key: "5",
      label: (
        <p
          onMouseEnter={() => {
            setHoverTestColor(true);
          }}
          onMouseLeave={() => {
            setHoverTestColor(false);
          }}
          className={`a ${commonCssStyle.tabsText}`}
          style={{
            margin: "0px",
            color:
              key === "5" ? "var(--menu-text-color)" : "var(--body-text-color)",
          }}
        >
          Application
        </p>
      ),
      children: (
        <>
          <Card
            size="large"
            style={{ backgroundColor: "var(--background-color)" }}
          >
            <Application
              settings={settings}
              postSettings={postSettingsAction}
            />
          </Card>
        </>
      ),
    },
    permissions &&
      permissions.find((data) => data === "settings-import") && {
        key: "6",
        label: (
          <p
            onMouseEnter={() => {
              setHoverTestColor(true);
            }}
            onMouseLeave={() => {
              setHoverTestColor(false);
            }}
            className={commonCssStyle.tabsText}
            style={{
              margin: "0px",
              color:
                key === "6"
                  ? "var(--menu-text-color)"
                  : "var(--body-text-color)",
            }}
          >
            Data Import
          </p>
        ),
        children: (
          <>
            <Card
              size="large"
              style={{
                borderRadius: "7px",
                backgroundColor: "var(--background-color)",
              }}
            >
              <DataImport />
            </Card>
          </>
        ),
      },
  ];

  return (
    <>
      <Row>
        <Col sm={24}>
          <Tabs
            id="page-top"
            onChange={(key) => {
              setKey(key);
            }}
            onTabClick={(val) => {
              if (val === "1") {
                getCheckPermissionHandler("settings-system", (success) => {
                  if (success) {
                    setActiveKey("1");
                  } else {
                    setOpenPMModal({
                      open: true,
                      permission: "System",
                    });
                  }
                });
              } else if (val === "2") {
                getCheckPermissionHandler(
                  "settings-organisations",
                  (success) => {
                    if (success) {
                      setActiveKey("2");
                    } else {
                      setOpenPMModal({
                        open: true,
                        permission: "Organization",
                      });
                    }
                  }
                );
              } else if (val === "3") {
                getCheckPermissionHandler("settings-billing", (success) => {
                  if (success) {
                    setActiveKey("3");
                  } else {
                    setOpenPMModal({
                      open: true,
                      permission: "Billing",
                    });
                  }
                });
              } else if (val === "4") {
                getCheckPermissionHandler("settings-roles-list", (success) => {
                  if (success) {
                    setActiveKey("4");
                  } else {
                    setOpenPMModal({
                      open: true,
                      permission: "Roles",
                    });
                  }
                });
              } else if (val === "5") {
                setActiveKey("5");
              } else if (val === "6") {
                getCheckPermissionHandler("settings-import", (success) => {
                  if (success) {
                    setActiveKey("6");
                  } else {
                    setOpenPMModal({
                      open: true,
                      permission: "Import",
                    });
                  }
                });
              }
            }}
            className="leftTabsSettingsStyle fixedStyle"
            style={{ left: "0px", borderRadius: "7px" }}
            // defaultActiveKey="1"
            activeKey={acitveKey}
            tabPosition="left"
            items={dataArray}
          />
        </Col>
      </Row>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    settingsSubscription: state.settings.settingsSubscription,
    countriesData: state.country.countries,
    StateData: state.country.state,
    cityData: state.country.city,
    settingsWorklocationData: state.settings.settingsWorklocation,
    permissions: state.authpermissions.permissions,
    ishavepermissions: state.authpermissions.ishavepermissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSettingsAction: () => {
      dispatch(getSettingsAction());
    },
    getSettingsSubscriptionAction: () => {
      dispatch(getSettingsSubscriptionAction());
    },
    postSettingsAction: (val) => {
      dispatch(postSettingsAction(val));
    },

    getCountryHandler: (data, callback) => {
      dispatch(getCountriesAction(data, callback));
    },
    getWorkLocationHandler: () => {
      dispatch(getSettingsWorklocationAction());
    },
    deleteWorkLocationActionHandler: (data, callback) => {
      dispatch(deleteSettingsWorkLocationAction(data, callback));
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
    setIsHavepermissionsHandler: (data) => {
      dispatch(checkPermissionReducer(data));
    },
    testCredentialsHandler: (data, callback) => {
      dispatch(postTestCredentialsAction(data, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
