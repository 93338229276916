import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Switch,
  DatePicker,
  Checkbox,
  Tag,
  Image,
  message,
  InputNumber,
  Divider,
} from "antd";
import React, { useEffect, useRef, useTransition } from "react";
import pdf from "../../../../../Assets/Image/pdf.png";
import word from "../../../../../Assets/Image/word.png";
import commonStyles from "../../../../UI/CommonCss.module.css";
import {
  getCandidatesEmailAndPhoneCheckAction,
  postCandidatesAction,
} from "../../../../../Reusable/Store/Action/CandidatesAction";
import PhoneInput from "react-phone-number-input";
import {
  UploadIcon,
  CloseIcon,
  PlusForButtonIcon,
  MinusIcons,
} from "../../../../IconsComp/Icons";
import "antd-country-phone-input/dist/index.css";
import "flagpack/dist/flagpack.css";
import { connect } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { Option } from "antd/lib/mentions";
import { getEmployeesAction } from "../../../../../Reusable/Store/Action/EmployeesAction";
import { getCountriesAction } from "../../../../../Reusable/Store/Action/Master/MasterCountryAction";
import { getTagsAction } from "../../../../../Reusable/Store/Action/Master/MasterTagsAction";
import moment from "moment";
import { getCandidatesAction } from "../../../../../Reusable/Store/Action/CandidatesAction";
import { getLeadSourcesAction } from "../../../../../Reusable/Store/Action/Master/MasterLeadSourceAction";
import { getStatusAction } from "../../../../../Reusable/Store/Action/Master/MasterStatusAction";
import { type } from "@testing-library/user-event/dist/type";
import { useLocation, useNavigate } from "react-router";
import AadTagsForSkills from "../../../../UI/AadTagsForSkills";
import ResumeParser from "./ResumeParser";
import {
  fileTypeDataSlice,
  getCandidateResumeParsingSlice,
  getCandidateResumeSlice,
} from "../../../../../Reusable/Store/Slice/CandidatesSlice";
import axios from "axios";
const tagRenderForSkills = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      className="tagsStyle"
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

const Addcandidatepage = (props) => {
  const handleChangeForSkills = (value) => {};
  const location = useLocation();
  const [specialAbilitiesData, setSpecialAbilitiesData] = useState(false);

  let btnref = useRef(null);
  // const [url, setUrl] = useState("");
  const [phoneData, setPhoneData] = useState(null);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [statePermanents, setStatePermanents] = useState([]);
  const [city, setCity] = useState([]);
  const [cityPermanent, setCityPermanent] = useState([]);
  const [cityStatus, setCityStatus] = useState(false);
  const [permanentCityStatus, setPermanentCityStatus] = useState(false);
  const [isPersuing, setIsPersuing] = useState(false);
  const [candidateEmailCheck, setCandidateEmailCheck] = useState(false);
  const [candidatePhoneCheck, setCandidatePhoneCheck] = useState(false);
  const [newTagsSkills, setTagsNewSkills] = useState([]);
  const [checkWhatsAppPhoneNumber, setCheckWhatsAppPhoneNumber] =
    useState(false);
  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  // const onChangeCountry = (value) => {
  //   setState(
  //     props.countriesData &&
  //       props.countriesData.find((x) => x.name === value).state
  //   );
  //   setTimeout(() => {
  //     if (state || state.length !== 0) {
  //       form.setFieldsValue({
  //         city: initvalues.city,
  //         state: initvalues.state,
  //       });
  //       setCity([]);
  //     }
  //   }, 300);
  // };

  const onChangeCountry = (val, data) => {
    props.getCountriesHandler({ id: data.id, type: "state" }, (data, type) => {
      setStates(data);
    });
    form.setFieldsValue({
      city: initvalues.city,
      state: initvalues.state,
    });
    setCity([]);
  };

  const onChangeCountryPermanent = (val, data) => {
    props.getCountriesHandler({ id: data.id, type: "state" }, (data, type) => {
      setStatePermanents(data);
    });
    form.setFieldsValue({
      permanentcity: initvalues.permanentcity,
      permanentstate: initvalues.permanentstate,
    });
    setCityPermanent([]);
  };
  // const onChangeCountryPermanent = (value) => {
  //   setStatePermanent(
  //     props.countriesData &&
  //       props.countriesData.find((x) => x.name === value).state
  //   );
  //   setTimeout(() => {
  //     if (statePermanent || statePermanent.length !== 0) {
  //       form.setFieldsValue({
  //         permanentcity: initvalues.permanentcity,
  //         permanentstate: initvalues.permanentstate,
  //       });
  //       setCityPermanent([]);
  //     }
  //   }, 300);
  // };
  // const onChangeState = (value) => {
  //   form.setFieldsValue({
  //     city: initvalues.city,
  //   });
  //   let cityData = state && state.find((x) => x.stateName === value).city;
  //   setCity(cityData);
  //   if (cityData.length === 0 || cityData[0] === "") {
  //     setCityStatus(true);
  //   } else {
  //     setCityStatus(false);
  //   }
  // };

  const onChangeState = (value, data) => {
    props.getCountriesHandler({ id: data.id, type: "city" }, (data, type) => {
      setCity(data);
    });
    form.setFieldsValue({
      city: initvalues.city,
    });
  };

  const onChangeStatePermanent = (value, data) => {
    props.getCountriesHandler({ id: data.id, type: "city" }, (data, type) => {
      setCityPermanent(data);
    });
    form.setFieldsValue({
      permanentcity: initvalues.permanentcity,
    });
  };

  // const onChangeStatePermanent = (value) => {
  //   form.setFieldsValue({
  //     permanentcity: initvalues.permanentcity,
  //   });
  //   let cityPermanentData =
  //     statePermanent && statePermanent.find((x) => x.stateName === value).city;
  //   setCityPermanent(cityPermanentData);

  //   if (cityPermanentData.length === 0 || cityPermanentData[0] === "") {
  //     setPermanentCityStatus(true);
  //   } else {
  //     setPermanentCityStatus(false);
  //   }
  // };

  const [checked, setChecked] = React.useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [checkedCurrentAddress, setCheckedCurrentAddress] = useState(false);
  const [checkedCurrentEducation, setCheckedCurrentEducation] = useState(false);

  const [checkedSpecialAbilities, setCheckedSpecialAbilities] = useState(false);

  const [checkedEmailToCandidate, setCheckedEmailToCandidate] = useState(false);
  const [filesData, setFilesData] = useState("");
  const [fileType, setFileType] = useState("");
  const [jobIdData, setJobId] = useState({ jobId: "", send: false });
  const [whatsAppChecked, setWhatsappChecked] = useState(false);
  const [fileRequired, setFileRequired] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [jobField, setJobField] = useState(false);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFilesData(e.dataTransfer.files[0]);
      setFileType(e.dataTransfer.files[0].type);

      if (e.dataTransfer.files[0].type === "application/pdf") {
        let reader = new FileReader();
        reader.readAsDataURL(e.dataTransfer.files[0]);
        reader.onloadend = (e) => {
          // setUrl(e.target.result);
          props.getCandidateResumeHandler(e.currentTarget.result);
        };
        resumeParsingHandler(e.dataTransfer.files[0]);
      }
    }
  };

  const isPersuingHandler = () => {
    setIsPersuing(false);
    setTimeout(() => {
      if (
        form.getFieldValue("educationInfo") &&
        form
          .getFieldValue("educationInfo")
          .find((data) => data && data.isPersuing === true)
      ) {
        setIsPersuing(true);
      }
    }, 300);
  };

  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      if (
        (location.state &&
          location.state.from &&
          location.state.from === "fromRequirement") ||
        (location.state &&
          location.state.from &&
          location.state.from === "fromRecuritment")
      ) {
        form.setFieldsValue({
          title: location.state.title,
          jobCode: location.state.jobCode,
        });
        setJobId({ jobId: location.state.id, send: true });
        setJobField(true);
      }
      props.getCountriesHandler({ type: "country" }, (data, type) => {
        setCountries(data);
      });
      form.setFieldsValue({
        country: "India",
        permanentcountry: "India",
      });
      props.getCountriesHandler({ id: 101, type: "state" }, (data, type) => {
        setStates(data);
        setStatePermanents(data);
      });
      //id:101 is for India to set default value for country
      props.getTagHandler();
      props.getCandidateDetailsHandler();
      props.getLeadSourcesHandler();
      props.getStatusHandler();
      props.getEmployeesHandler();
    }
    return () => {
      unsubscribed = true;
    };
  }, []);

  function resumeParsingHandler(data) {
    axios
      .post(
        "https://parser.recruitintell.in/get_chunk",
        {
          resume_file: data,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        props.getCandidateResumeParsingHandler(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChange = (info) => {
    setFileRequired(false);
    if (info.target.files[0].size >= 2000000) {
      message.warning("This file should be less than 2 MB", 3);
      return;
    }
    setFilesData(info.target.files[0]);
    setFileType(info.target.files[0].type);
    if (info.target.files[0].type === "application/pdf") {
      if (info.target.files.length > 0) {
        let reader = new FileReader();
        reader.readAsDataURL(info.target.files[0]);
        reader.onloadend = (e) => {
          // setUrl(e.target.result);
          props.getCandidateResumeHandler(e.target.result);
        };
        resumeParsingHandler(info.target.files[0]);
      }
    }
  };

  function navigateToPreviousPage() {
    if (
      location &&
      location.state &&
      location.state.from &&
      location.state.from === "fromRequirement"
    ) {
      navigate("/JobDetailPage", {
        state: {
          // from: "fromRequirement",
          // pageFrom: "requirement",
          jobCode: location?.state?.jobCode,
          title: location?.state?.title,
          id: location?.state?.id,
        },
      });
      props.onClose();
    } else if (
      location &&
      location.state &&
      location.state.from &&
      location.state.from === "fromRecuritment"
    ) {
      navigate("/recruitment/recruitmentJobdetails", {
        state: {
          // from: "fromRecuritment",
          activeTabKey: "addCandidate",
          // pageFrom: "recuriter",
          id: location?.state?.id,
          jobCode: location?.state?.jobCode,
          recuritmentJobDetailsName: location?.state?.title,
        },
      });
      props.onClose();
    } else {
      props.onClose();
    }
  }

  const onFinish = (values) => {
    // const experienceStartDateMonth = values.employeeInfo
    //   ? moment(values.employeeInfo.from).format("MM")
    //   : "";
    // const experienceStartDateYear = values.employeeInfo
    //   ? moment(values.employeeInfo.from).format("YYYY")
    //   : "";
    // const experienceEndtDateMonth = values.employeeInfo
    //   ? moment(values.employeeInfo.to).format("MM")
    //   : "";
    // const experienceEndDateYear = values.employeeInfo
    //   ? moment(values.employeeInfo.to).format("YYYY")
    //   : "";
    // const startMonth = experienceStartDateYear * 12 + experienceStartDateMonth;
    // const endMonth = experienceEndDateYear * 12 + experienceEndtDateMonth;
    // const monthInterval = endMonth - startMonth;
    // const monthsOfExperience = monthInterval % 12;
    // const RelevantExperienceMonths = monthsOfExperience;
    // const totalExperienceMonths = monthsOfExperience;
    const {
      name,
      email,
      phone,
      whatsApp,
      gender,
      dob,
      location,
      country,
      address,
      state,
      city,
      pincode,
      specialAbilityComments,
      leadSource,
      // recruitmentStatus,
      permanentaddress,
      permanentcity,
      permanentcountry,
      permanentlocation,
      permanentpincode,
      permanentstate,
      communicationManagerId,
      currentSalary,
      totalExperienceMonths,
      relavantExperienceMonths,
      portfolio,
    } = values;

    const educationInfo =
      values.educationInfo &&
      values.educationInfo.map((x) => {
        return {
          ...x,
          isPersuing: x.isPersuing ? 1 : 0,
          from:
            x.from === undefined ? undefined : moment(x.from).format("YYYY"),
          to: x.to === undefined ? undefined : moment(x.to).format("YYYY"),
        };
      });
    const employeeInfo =
      values.employeeInfo &&
      values.employeeInfo.map((x) => {
        return {
          ...x,
          isCurrent: x.isCurrent ? 1 : 0,
          from:
            x.from === undefined
              ? undefined
              : moment(x.from).format("YYYY-MM-DD"),
          to:
            x.to === undefined ? undefined : moment(x.to).format("YYYY-MM-DD"),
        };
      });
    const currentSameAsPermanentAdrress = [
      {
        type: "current",
        location: location,
        country: country,
        address: address,
        state: state,
        city: city,
        pincode: pincode,
      },
      {
        type: "permanent",
        location: location,
        country: country,
        address: address,
        state: state,
        city: city,
        pincode: pincode,
      },
    ];
    const permanentAdrressIsDifferent = [
      {
        type: "current",
        location: location,
        country: country,
        address: address,
        state: state,
        city: city,
        pincode: pincode,
      },
      {
        type: "permanent",
        location: permanentlocation,
        country: permanentcountry,
        address: permanentaddress,
        state: permanentstate,
        city: permanentcity,
        pincode: permanentpincode,
      },
    ];
    const tags = values.tag;
    const data = {
      totalExperienceMonths: totalExperienceMonths ? totalExperienceMonths : "",
      relavantExperienceMonths: relavantExperienceMonths
        ? relavantExperienceMonths
        : "",
      educationInfo,
      employeeInfo,
      tags: tags ? tags : null,
      resume: filesData,
      name,
      portfolio,
      email,
      phone,
      currentSalary,
      sendMail: checkedEmailToCandidate ? 1 : 0,
      whatsApp: whatsAppChecked ? phone : whatsApp,
      gender,
      dob:
        dob === undefined || dob === "Invalid date"
          ? undefined
          : moment(dob).format("YYYY-MM-DD"),
      communicationManagerId,
      specialAbility: checkedSpecialAbilities ? 1 : 0,
      specialAbilityComments,
      leadSource,
      addressCheck: checkedCurrentAddress ? 1 : 0,
      // recruitmentStatus,
      addressInfo: checkedCurrentAddress
        ? currentSameAsPermanentAdrress
        : permanentAdrressIsDifferent,
    };
    // props.postAddCandidateHandler(data, (success) => {
    //   if (success) {
    //     setFilesData("");
    //     form.resetFields();
    //     props.onClose();
    //   }
    // });

    if (jobIdData.send) {
      props.postAddCandidateHandler(
        { ...data, jobId: jobIdData.jobId },
        (success) => {
          if (success) {
            setFilesData("");
            props.getCandidateResumeHandler("");

            props.getCandidateResumeParsingHandler({});
            setTagsNewSkills([]);
            form.resetFields();
            navigateToPreviousPage();
          }
        }
      );
    } else {
      props.postAddCandidateHandler(data, (success) => {
        if (success) {
          setFilesData("");
          props.getCandidateResumeHandler("");

          form.resetFields();
          props.getCandidateResumeParsingHandler({});
          navigateToPreviousPage();
        }
      });
    }
  };
  useEffect(() => {
    //to check email
    if (props.resumeParsing && props.resumeParsing.email) {
      props.getCandidatesEmailAndPhoneCheckHandler(
        {
          type: "email",
          value: props.resumeParsing.email,
        },
        (success) => {
          if (success) {
            setCandidateEmailCheck(false);
          } else {
            setCandidateEmailCheck(true);
          }
        }
      );
    }
    //to check phone number
    if (props.resumeParsing && props.resumeParsing.mobile_number) {
      props.getCandidatesEmailAndPhoneCheckHandler(
        {
          type: "phone",
          value: props.resumeParsing.mobile_number,
        },
        (success) => {
          if (success) {
            setCandidatePhoneCheck(false);
          } else {
            setCandidatePhoneCheck(true);
          }
        }
      );
    }
    form.setFieldsValue({
      name:
        props.resumeParsing && props.resumeParsing.name
          ? props.resumeParsing.name
          : "",
      email:
        props.resumeParsing && props.resumeParsing.email
          ? props.resumeParsing.email
          : "",
      phone:
        props.resumeParsing && props.resumeParsing.mobile_number
          ? props.resumeParsing.mobile_number
          : "",
      employeeInfo:
        props.resumeParsing && props.resumeParsing.company_names
          ? props.resumeParsing.company_names.map((x) => {
              return {
                companyName: x,
              };
            })
          : [],
      employeeInfo:
        props.resumeParsing && props.resumeParsing.designation
          ? props.resumeParsing.designation.map((x) => {
              return {
                designation: x,
              };
            })
          : [],
      educationInfo:
        props.resumeParsing && props.resumeParsing.college_name
          ? props.resumeParsing.college_name.map((x) => {
              return {
                institute: x,
              };
            })
          : [],
      educationInfo:
        props.resumeParsing && props.resumeParsing.degree
          ? props.resumeParsing.degree.map((x) => {
              return {
                qualification: x,
              };
            })
          : [],
      totalExperienceMonths:
        props.resumeParsing && props.resumeParsing.total_experience
          ? props.resumeParsing.total_experience
          : "",
      tag:
        props.resumeParsing && props.resumeParsing.skills
          ? props.resumeParsing.skills
          : [],
    });
    if (props?.resumeParsing?.skills) {
      setTagsNewSkills(props.resumeParsing.skills);
    } else {
    }
  }, [props.resumeParsing]);

  const initvalues = {
    whatsApp: "",
    permanentlocation: "",
    permanentaddress: "",
    permanentcountry: "",
    permanentstate: "",
    permanentcity: "",
    permanentpincode: "",
    specialAbilityComments: "",
    referenceNumber: "",
    referenceEmail: "",
    referenceName: "",
    summary: "",
    totalExperienceMonths: "",
    to: undefined,
    from: undefined,
    designation: "",
    companyName: "",
    city: "",
    state: "",
    permanentstate: "",
    permanentcity: "",
    educationInfo: [],
    employeeInfo: [],
    portfolio: [],
  };

  return (
    <>
      <Drawer
        className={"modalModified candidateModalModified"}
        closable={false}
        extra={
          <div
            onClick={() => {
              props.getCandidateResumeHandler("");
              props.getCandidateResumeParsingHandler({});

              if (
                location.state &&
                location.state.from &&
                location.state.from === "fromRequirement"
              ) {
                navigate("/JobDetailPage", {
                  state: {
                    // from: "fromRequirement",
                    // pageFrom: "requirement",
                    jobCode: location?.state?.jobCode,
                    title: location?.state?.title,
                    id: location?.state?.id,
                  },
                });
                props.onClose();
              } else if (
                location.state &&
                location.state.from &&
                location.state.from === "fromRecuritment"
              ) {
                navigate("/recruitment/recruitmentJobdetails", {
                  state: {
                    // from: "fromRecuritment",
                    activeTabKey: "addCandidate",
                    // pageFrom: "recuriter",
                    id: location?.state?.id,
                    jobCode: location?.state?.jobCode,
                    recuritmentJobDetailsName: location?.state?.title,
                  },
                });
                props.onClose();
              } else {
                props.onClose();
              }
              // props.onClose();
            }}
            className={`${commonStyles.coursorPointer}`}
          >
            <CloseIcon color={"var(--body-text-color)"} />
          </div>
        }
        footer={false}
        style={{
          paddingBottom: "0px",
        }}
        title={
          <p
            style={{ fontSize: "20px", margin: "0px" }}
            className={`${commonStyles.modalHeading}`}
          >
            Add Candidate
          </p>
        }
        width={726}
        height={48}
        // onClose={props.onClose}
        open={props.onOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        {/* <Row>
          <Col md={11}>
            <ResumeParser />
          </Col>
          <Col md={11}> */}
        <Form
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          onDragEnter={handleDrag}
          initialValues={initvalues}
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{ marginBottom: "80px" }}
        >
          <Row justify="space-between" className={commonStyles.modalRowStyling}>
            <Col xs={11} md={11}>
              <ResumeParser />
            </Col>
            <Col flex={1} xs={11} md={11}>
              <Row className={commonStyles.modalRowStyling}>
                <Col flex={1}>
                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        name="resume"
                        label={
                          <p
                            className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                          >
                            Resume
                          </p>
                        }
                        style={{ borderRadius: "1px" }}
                      >
                        {filesData || dragActive ? (
                          <div
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                            style={{
                              backgroundColor: "var(--secondaryColor)",
                              height: "155px",
                              borderRadius: "6px",
                            }}
                          >
                            <Image
                              onClick={(e) => {
                                e.preventDefault();
                                btnref.click();
                                return false;
                              }}
                              preview={false}
                              src={fileType === "application/pdf" ? pdf : word}
                              style={{
                                height: "118px",
                                width: "100px",
                                borderRadius: "6px",
                                position: "relative",
                                display: "block",
                                margin: "16px auto 21px 21px",
                              }}
                            />
                            <span
                              onClick={() => {
                                props.getCandidateResumeHandler("");
                                props.getCandidateResumeParsingHandler({});
                                setCandidateEmailCheck(false);
                                setCandidatePhoneCheck(false);
                                setCheckWhatsAppPhoneNumber(false);
                                setFilesData("");
                                setFileRequired(true);
                              }}
                              style={{
                                position: "absolute",
                                paddingTop: "5px",
                                paddingLeft: "1px",
                                cursor: "pointer",
                              }}
                            >
                              <CloseIcon color={"var(--headings-color)"} />
                            </span>
                          </div>
                        ) : (
                          <>
                            <input
                              hidden
                              accept="application/pdf,.docx"
                              type="file"
                              name="resume"
                              onChange={handleChange}
                              ref={(input) => {
                                btnref = input;
                              }}
                            />
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                btnref.click();
                                return false;
                              }}
                              style={{
                                textAlign: "center",
                                height: "155px",
                                borderRadius: "6px",
                                backgroundColor: "var(--input-color)",
                                borderRadius: "5px",
                              }}
                            >
                              <Row
                                justify="center"
                                style={{ paddingTop: "31px" }}
                              >
                                <Col>
                                  <UploadIcon
                                    color={"var(--body-text-color)"}
                                  />
                                </Col>
                              </Row>
                              <div>
                                <p
                                  style={{
                                    fontWeight: 400,
                                    textAlign: "center",
                                    marginTop: "10px",
                                    fontFamily: "Helvetica,ProductSans",
                                    fontStyle: "normal",
                                    fontSize: "16px",
                                    lineHeight: "19px",
                                    color: "var(--body-text-color)",
                                  }}
                                >
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Upload
                                  resume &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                  or &nbsp;&nbsp;&nbsp; Drag and drop resume
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  {jobField && (
                    <>
                      <Row>
                        <Col flex={1}>
                          <Form.Item
                            name="title"
                            label="Job Title"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your job title",
                              },
                              // {
                              //   pattern: /^[A-Z a-z]+$/,
                              //   message: "Enter only alphabets",
                              // },
                            ]}
                          >
                            <Input readOnly placeholder="Enter job title" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col flex={1}>
                          <Form.Item
                            name="jobCode"
                            label="Job Code"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your job code",
                              },
                              // {
                              //   pattern: /^[A-Z a-z]+$/,
                              //   message: "Enter only alphabets",
                              // },
                            ]}
                          >
                            <Input readOnly placeholder="Enter job code" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        name="name"
                        label="Full Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your full name",
                          },
                          // {
                          //   pattern: /^[A-Z a-z]+$/,
                          //   message: "Enter only alphabets",
                          // },
                        ]}
                      >
                        <Input placeholder="Enter full name" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your email",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email",
                          },
                        ]}
                        onBlur={(val) => {
                          if (val.target.value.length > 0) {
                            props.getCandidatesEmailAndPhoneCheckHandler(
                              {
                                type: "email",
                                value: val.target.value,
                              },
                              (success) => {
                                if (success) {
                                  setCandidateEmailCheck(false);
                                } else {
                                  setCandidateEmailCheck(true);
                                }
                              }
                            );
                          } else {
                            setCandidateEmailCheck(false);
                          }
                        }}
                      >
                        <Input placeholder="Enter email address" />
                      </Form.Item>
                    </Col>
                  </Row>
                  {candidateEmailCheck ? (
                    <p style={{ color: "#F83A3A" }}>
                      Candidate with same email already exists
                    </p>
                  ) : (
                    <></>
                  )}

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        label="Gender"
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: "Please select gender",
                          },
                        ]}
                      >
                        <Select
                          getPopupContainer={(trigger) => trigger.parentElement}
                          placeholder="Select gender"
                        >
                          <Select.Option value="Male">Male</Select.Option>
                          <Select.Option value="Female">Female</Select.Option>
                          <Select.Option value="Other">Other</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        label="DOB"
                        name="dob"
                        rules={[
                          {
                            required: false,
                            message: "Please enter date of birth",
                          },
                        ]}
                      >
                        <DatePicker
                          getPopupContainer={(trigger) => trigger.parentElement}
                          disabledDate={(current) =>
                            current.isAfter(moment().subtract(16, "year"))
                          }
                          defaultPickerValue={moment().subtract(16, "year")}
                          placeholder="Enter date of birth"
                          format={dataFormate}
                          className={commonStyles.datePickerBgColor}
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="modelcol" md={24}>
                      <Form.Item
                        className={`phoneInputStroke`}
                        name="phone"
                        label="Contact Number"
                        rules={[
                          {
                            required: true,
                            message: "Please enter contact number",
                          },
                        ]}
                        onBlur={(val) => {
                          if (val.target.value.length > 0) {
                            props.getCandidatesEmailAndPhoneCheckHandler(
                              {
                                type: "phone",
                                value: val.target.value.replace(/\s+/g, ""),
                              },
                              (success) => {
                                if (success) {
                                  setCandidatePhoneCheck(false);
                                } else {
                                  setCandidatePhoneCheck(true);
                                }
                              }
                            );
                          } else {
                            setCandidatePhoneCheck(false);
                          }
                        }}
                      >
                        <PhoneInput
                          onChange={(value) => {
                            setPhoneData(value);
                          }}
                          placeholder="Enter contact number"
                          className={`ant-input ant-cust-inputs`}
                          defaultCountry="IN"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {candidatePhoneCheck ? (
                    <p style={{ color: "#F83A3A" }}>
                      Candidate with same phone number already exists
                    </p>
                  ) : (
                    <></>
                  )}
                  <Row style={{ gap: "10px" }} align="middle">
                    <Col>
                      <Form.Item
                        name="whatsAppCheck"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        <Checkbox
                          className="intellcheckbox"
                          onChange={(val) => {
                            setWhatsappChecked(val.target.checked);

                            form.setFieldsValue({
                              whatsApp: initvalues.whatsApp,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <p
                        className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                      >
                        Whatsapp Number is same as Contact Number
                      </p>
                    </Col>
                  </Row>

                  {!whatsAppChecked && (
                    <Row>
                      <Col className="modelcol" md={24}>
                        <Form.Item
                          className={`phoneInputStroke`}
                          rules={[
                            {
                              required: true,
                              message: "Please enter whatsapp number",
                            },
                          ]}
                          name="whatsApp"
                          label="Whatsapp Number"
                          onBlur={(val) => {
                            if (val.target.value.length > 0) {
                              props.getCandidatesEmailAndPhoneCheckHandler(
                                {
                                  type: "phone",
                                  value: val.target.value.replace(/\s+/g, ""),
                                },
                                (success) => {
                                  if (success) {
                                    setCheckWhatsAppPhoneNumber(false);
                                  } else {
                                    setCheckWhatsAppPhoneNumber(true);
                                  }
                                }
                              );
                            } else {
                              setCheckWhatsAppPhoneNumber(false);
                            }
                          }}
                        >
                          <PhoneInput
                            placeholder="Enter whatsapp number"
                            className="ant-input ant-cust-inputs"
                            defaultCountry="IN"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {checkWhatsAppPhoneNumber ? (
                    <p style={{ color: "#F83A3A" }}>
                     Candidate with same phone number already exists
                    </p>
                  ) : (
                    <></>
                  )}

                  <Row className={commonStyles.sectionEndGap}>
                    <Col>
                      <h1 className={commonStyles.HeadingFormDesign}>
                        Current Address
                      </h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        name="location"
                        label="Apartment, flat/house number"
                      >
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter apartment, flat/house number"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item name="address" label="Address">
                        <Input placeholder="Enter address" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        label="Country"
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: "Please select country",
                          },
                        ]}
                      >
                        <Select
                          getPopupContainer={(trigger) => trigger.parentElement}
                          showSearch
                          onChange={onChangeCountry}
                          placeholder="Select country"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {countries &&
                            countries.length > 0 &&
                            countries.map((data, index) => {
                              return (
                                <Select.Option
                                  key={index}
                                  value={data.name}
                                  id={data.id}
                                >
                                  {data.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        label="State"
                        name="state"
                        rules={[
                          {
                            required: true,
                            message: "Please select state",
                          },
                        ]}
                      >
                        {states && states.length === 0 ? (
                          <Input placeholder="Enter state" />
                        ) : (
                          <Select
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            onChange={onChangeState}
                            showSearch
                            placeholder="Select state"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {states &&
                              states.map((data, index) => {
                                return (
                                  <Select.Option
                                    key={index}
                                    value={data.name}
                                    id={data.id}
                                  >
                                    {data.name}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        label="City"
                        name="city"
                        rules={[
                          {
                            required: true,
                            message: "Please select city",
                          },
                        ]}
                      >
                        {city.length === 0 ? (
                          <Input placeholder="Enter city" />
                        ) : (
                          <Select
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            showSearch
                            placeholder="Select city"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {city.map((data, index) => {
                              return (
                                <Select.Option value={data.name} key={index}>
                                  {data.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item label="Pincode" name="pincode">
                        <Input placeholder="Enter pincode" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ gap: "10px" }} align="middle">
                    <Col>
                      <Form.Item style={{ margin: "0px", padding: "0px" }}>
                        <Switch
                          onChange={(val) => {
                            setCheckedCurrentAddress(val);
                            form.setFieldsValue({
                              permanentlocation: initvalues.permanentlocation,
                              permanentaddress: initvalues.permanentaddress,
                              permanentcountry: initvalues.permanentcountry,
                              permanentstate: initvalues.permanentstate,
                              permanentcity: initvalues.permanentcity,
                              permanentpincode: initvalues.permanentpincode,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <p
                        className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                      >
                        Current Address is same as Permanent Address
                      </p>
                    </Col>
                  </Row>

                  {checkedCurrentAddress ? (
                    <></>
                  ) : (
                    <>
                      <Row className={commonStyles.sectionEndGap}>
                        <Col>
                          <h1 className={commonStyles.HeadingFormDesign}>
                            Permanent Address
                          </h1>
                        </Col>
                      </Row>
                      <Row>
                        <Col flex={1}>
                          <Form.Item
                            name="permanentlocation"
                            label="Apartment, flat/house number"
                          >
                            <Input
                              style={{ width: "100%" }}
                              placeholder="Enter apartment, flat/house number"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col flex={1}>
                          <Form.Item name="permanentaddress" label="Address">
                            <Input placeholder="Enter permanent address" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col flex={1}>
                          <Form.Item
                            label="Country"
                            name="permanentcountry"
                            rules={[
                              {
                                required: true,
                                message: "Please select country",
                              },
                            ]}
                          >
                            <Select
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                              showSearch
                              onChange={onChangeCountryPermanent}
                              placeholder="Select country"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {countries &&
                                countries.length > 0 &&
                                countries.map((data, index) => {
                                  return (
                                    <Select.Option
                                      key={index}
                                      value={data.name}
                                      id={data.id}
                                    >
                                      {data.name}
                                    </Select.Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col flex={1}>
                          <Form.Item
                            label="State"
                            name="permanentstate"
                            rules={[
                              {
                                required: true,
                                message: "Please select state",
                              },
                            ]}
                          >
                            {statePermanents && statePermanents.length === 0 ? (
                              <Input placeholder="Enter state" />
                            ) : (
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentElement
                                }
                                onChange={onChangeStatePermanent}
                                showSearch
                                placeholder="Select state"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {statePermanents &&
                                  statePermanents.map((data, index) => {
                                    return (
                                      <Select.Option
                                        key={index}
                                        value={data.name}
                                        id={data.id}
                                      >
                                        {data.name}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col flex={1}>
                          <Form.Item
                            label="City"
                            name="permanentcity"
                            rules={[
                              {
                                required: true,
                                message: "Please select city",
                              },
                            ]}
                          >
                            {cityPermanent.length === 0 ? (
                              <Input placeholder="Enter permanent city" />
                            ) : (
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentElement
                                }
                                showSearch
                                placeholder="Select city"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {cityPermanent &&
                                  cityPermanent.map((data, index) => {
                                    return (
                                      <Select.Option
                                        value={data.name}
                                        key={index}
                                      >
                                        {data.name}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col flex={1}>
                          <Form.Item label="Pincode" name="permanentpincode">
                            <Input placeholder="Enter pincode" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className={commonStyles.sectionEndGap}>
                    <Col>
                      <div className="ant-col ant-form-item-label">
                        <label /* className="ant-form-item-required" */>
                          Portfolio/ Work sample
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "20px" }}>
                    <Col className="modelcol" md={24}>
                      <Form.List name="portfolio">
                        {(fields, { add, remove }) => (
                          <>
                            {/* {fields.length > 0 && (
                              <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item-required">
                                  Portfolio / Work sample
                                </label>
                              </div>
                            )} */}
                            {fields.map((field, key) => (
                              <div>
                                <Row>
                                  <Col flex={1}>
                                    <AddLink
                                      field={field}
                                      dataFormate={dataFormate}
                                    />
                                  </Col>

                                  <Col md={24} style={{ marginBottom: "10px" }}>
                                    <p
                                      style={{
                                        marginTop: "0px",
                                        marginBottom: "8px",
                                        float: "right",
                                        color: "#F83A3A",
                                      }}
                                      className={`${commonStyles.marginBottomRemove} ${commonStyles.removeLocation}`}
                                    >
                                      Remove Link
                                    </p>
                                    <Button
                                      className={commonStyles.removeLocationbtn}
                                      type="primary"
                                      size="small"
                                      shape="circle"
                                      style={{
                                        marginTop: "3px",
                                        marginRight: "7px",
                                        float: "right",
                                        backgroundColor: "#F83A3A",
                                        transform: "translateY(-4px)",
                                      }}
                                    >
                                      <div
                                        style={{
                                          transform: "translateY(-4px)",
                                        }}
                                        onClick={() => remove(key)}
                                      >
                                        <MinusIcons color={"var(--theme)"} />
                                      </div>
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                            <Button
                              size="small"
                              onClick={() => add()}
                              type="primary"
                              shape="circle"
                              style={
                                {
                                  // transform: "transformY(-20px)",
                                }
                              }
                            >
                              <div style={{ transform: "translateY(2px)" }}>
                                <PlusForButtonIcon
                                  height={"19px"}
                                  width={"19px"}
                                  color={"var(--theme)"}
                                />
                              </div>
                            </Button>{" "}
                            <p
                              style={{
                                marginTop: "-27px",
                                marginLeft: "40px",
                              }}
                              className={`${commonStyles.marginBottomRemove} ${commonStyles.addLocation}`}
                            >
                              Add Link
                            </p>
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>

                  <Row className={commonStyles.sectionEndGap}>
                    <Col className="modelcol" md={24}>
                      <Form.List name="educationInfo">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map((field, key) => (
                              <div>
                                <Row>
                                  <Col flex={1}>
                                    <AddEducation
                                      onIsPersuing={isPersuingHandler}
                                      field={field}
                                      dataFormate={dataFormate}
                                      form={form}
                                    />
                                  </Col>

                                  <Col md={24}>
                                    <p
                                      style={{
                                        marginTop: "2px",
                                        float: "right",
                                        color: "F83A3A",
                                      }}
                                      className={`${commonStyles.marginBottomRemove} ${commonStyles.removeLocation}`}
                                    >
                                      {" "}
                                      Remove Education
                                    </p>
                                    <Button
                                      className={commonStyles.removeLocationbtn}
                                      size="small"
                                      type="primary"
                                      shape="circle"
                                      style={{
                                        marginTop: "3px",
                                        marginRight: "7px",
                                        float: "right",
                                        backgroundColor: "#F83A3A",
                                      }}
                                    >
                                      <div
                                        style={{
                                          transform: "translateY(-4px)",
                                        }}
                                        onClick={() => {
                                          remove(key);
                                          if (key === 0) {
                                            setCheckedCurrentEducation(false);
                                          }
                                        }}
                                      >
                                        <MinusIcons color={"var(--theme)"} />
                                      </div>
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            ))}

                            <>
                              <Button
                                size="small"
                                onClick={() => {
                                  add();
                                }}
                                type="primary"
                                shape="circle"
                              >
                                <div style={{ transform: "translateY(2px)" }}>
                                  <PlusForButtonIcon
                                    height={"19px"}
                                    width={"19px"}
                                    color={"var(--theme)"}
                                  />
                                </div>
                              </Button>{" "}
                              <p
                                className={`${commonStyles.marginBottomRemove} ${commonStyles.addLocation}`}
                                style={{
                                  marginTop: "-27px",
                                  marginLeft: "40px",
                                }}
                              >
                                {" "}
                                Add Education
                              </p>
                            </>
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>

                  {!isPersuing && (
                    <Row className={commonStyles.sectionEndGap}>
                      <Col className="modelcol" md={24}>
                        <Form.List name="employeeInfo">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map((field, key) => (
                                <div>
                                  <Row>
                                    <Col md={24}>
                                      <AddEmployee
                                        form={form}
                                        dataFormate={dataFormate}
                                        field={field}
                                      />
                                    </Col>

                                    <Col md={24}>
                                      <p
                                        style={{
                                          marginTop: "2px",
                                          float: "right",
                                          color: "#F83A3A",
                                        }}
                                        className={`${commonStyles.marginBottomRemove} ${commonStyles.removeLocation}`}
                                      >
                                        {" "}
                                        Remove Employer
                                      </p>
                                      <Button
                                        className={
                                          commonStyles.removeLocationbtn
                                        }
                                        size="small"
                                        type="primary"
                                        shape="circle"
                                        style={{
                                          marginTop: "3px",
                                          marginRight: "7px",
                                          float: "right",
                                          backgroundColor: "red",
                                        }}
                                      >
                                        <div
                                          style={{
                                            transform: "translateY(-4px)",
                                          }}
                                          onClick={() => {
                                            remove(key);
                                          }}
                                        >
                                          <MinusIcons color={"var(--theme)"} />
                                        </div>
                                      </Button>{" "}
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                              <>
                                <Button
                                  size="small"
                                  onClick={() => {
                                    add();
                                  }}
                                  type="primary"
                                  shape="circle"
                                >
                                  <div style={{ transform: "translateY(2px)" }}>
                                    <PlusForButtonIcon
                                      height={"19px"}
                                      width={"19px"}
                                      color={"var(--theme)"}
                                    />
                                  </div>{" "}
                                </Button>{" "}
                                <p
                                  style={{
                                    marginTop: "-27px",
                                    marginLeft: "40px",
                                  }}
                                  className={`${commonStyles.marginBottomRemove} ${commonStyles.addLocation}`}
                                >
                                  Add Employer
                                </p>
                              </>
                            </>
                          )}
                        </Form.List>
                      </Col>
                    </Row>
                  )}
                  <Row className={commonStyles.sectionEndGap}>
                    <Col md={24}>
                      <Form.Item
                        label="Total Experience in Years"
                        name={"totalExperienceMonths"}
                        rules={[
                          {
                            required: false,
                            message: "Please enter total experience",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Enter total experience"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <Form.Item
                        label="Relevant Experience in Years"
                        name={"relavantExperienceMonths"}
                        rules={[
                          {
                            required: false,
                            message: "Please enter relevant experience",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Enter relevant experience"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "0px" }}>
                    <Col flex={1}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please enter current salary",
                          },
                        ]}
                        label="Current Salary (CTC in Lakhs)"
                        name={"currentSalary"}
                      >
                        <Input
                          type="number"
                          placeholder="Enter current salary"
                        />
                        {/* <Select placeholder="Select current salary">
                          <Select.Option value="2-2.5LPA">
                            2-2.5LPA
                          </Select.Option>

                          <Select.Option value="2.6-3LPA">
                            2.6-3LPA
                          </Select.Option>

                          <Select.Option value="3.1-3.5LPA">
                            3.1-3.5LPA
                          </Select.Option>

                          <Select.Option value="3.6-4LPA">
                            3.6-4LPA
                          </Select.Option>

                          <Select.Option value="4.1-4.5LPA">
                            4.1-4.5LPA
                          </Select.Option>
                        </Select> */}
                      </Form.Item>
                    </Col>
                  </Row>
                  {props?.onProfileData?.roleType !== "External Recruiter" ? (
                    <Row>
                      <Col flex={1}>
                        <Form.Item
                          label="Source"
                          name="leadSource"
                          rules={[
                            {
                              required: true,
                              message: "Please select source",
                            },
                          ]}
                        >
                          <Select
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            showSearch
                            placeholder="Select source"
                          >
                            {props.sourceOfTheLeadData.map((data) => {
                              return (
                                <Select.Option
                                  key={data.id}
                                  value={data.source}
                                >
                                  {data.source}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}

                  {/* <Row>
                    <Col flex={1}>
                      <Form.Item
                        label="Current Stage"
                        name="recruitmentStatus"
                        rules={[
                          {
                            required: false,
                            message: "Please select current stage",
                          },
                        ]}
                      >
                        <Select showSearch placeholder="Select stage">
                          {props.currentStageData &&
                            props.currentStageData.map((data) => {
                              if (data.status) {
                                return data.status.map((x) => {
                                  return (
                                    <Select.Option
                                      value={data.statusType + "/" + x}
                                      key={data.statusType + "/" + x}
                                    >
                                      {data.statusType + "/" + x}
                                    </Select.Option>
                                  );
                                });
                              } else {
                                return (
                                  <Select.Option
                                    value={data.statusType}
                                    key={data.statusType}
                                  >
                                    {data.statusType}
                                  </Select.Option>
                                );
                              }
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row> */}
                  <Row style={{ gap: "10px" }} align="middle">
                    <Col>
                      <Form.Item style={{ margin: "0px", padding: "0px" }}>
                        <Switch
                          onChange={(val) => {
                            setSpecialAbilitiesData(val);
                            setCheckedSpecialAbilities(val);
                            form.setFieldsValue({
                              specialAbilityComments:
                                initvalues.specialAbilityComments,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <p
                        className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                      >
                        Special Abilities
                      </p>
                    </Col>
                  </Row>

                  {checkedSpecialAbilities && (
                    <Form.Item
                      name="specialAbilityComments"
                      label="Special Ability Comments"
                      rules={[
                        {
                          required: false,
                          message: "Please enter special ability comments",
                        },
                      ]}
                    >
                      <TextArea
                        placeholder="Describe your special abilities"
                        rows={3}
                      />
                    </Form.Item>
                  )}

                  <Row style={{ marginTop: "10px" }}>
                    <Col flex={1}>
                      <Form.Item
                        label="Key Skills/Tag or Add New key Skills/Tags"
                        name="tag"
                        rules={[
                          {
                            required: true,
                            message: "Select skill tag",
                          },
                        ]}
                      >
                        <Select
                          getPopupContainer={(trigger) => trigger.parentElement}
                          className={`TagsStyle `}
                          mode="tags"
                          allowClear
                          style={{
                            width: "100%",
                          }}
                          showSearch
                          maxTagCount={0}
                          onChange={(val) => {
                            setTagsNewSkills(val);
                          }}
                          tokenSeparators={[","]}
                          // dropdownRender={(menu) => (
                          //   <>
                          //     {menu}
                          //     <AadTagsForSkills
                          //       // candidateTag={(val) => {
                          //       //   //new tag
                          //       //   console.log(val);
                          //       //   form.setFieldsValue({
                          //       //     tag: val.map((x) => {
                          //       //       return x;
                          //       //     }),
                          //       //   });
                          //       //   // form.setFieldsValue({
                          //       //   //   tag: val.map((x) => {
                          //       //   //     return x;
                          //       //   //   }),
                          //       //   // });
                          //       // }}
                          //       onType={"candidate"}
                          //     />
                          //   </>
                          // )}
                          placeholder="Please select skill tag or add new key skills/tags"
                        >
                          {props.TagsData &&
                            props.TagsData.filter(
                              (data) => data.type === "candidate"
                            ).map((data) => {
                              return (
                                <Option key={data.id} value={data.tag}>
                                  {data.tag}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginBottom: "30px",
                      border: "0.1px solid var(--body-text-color)",
                      padding: "10px",
                      borderRadius: "7px",
                    }}
                  >
                    <Col xs={24}>
                      <p style={{ color: "var(--body-text-color)" }}>
                        Key Skills/Tag Preview
                      </p>
                      {newTagsSkills &&
                        newTagsSkills.length > 0 &&
                        newTagsSkills.map((tagsData, tagIndex) => {
                          return (
                            <Tag
                              onClose={() => {
                                form.setFieldsValue({
                                  tag: form
                                    .getFieldValue("tag")
                                    .filter((val) => val !== tagsData),
                                });
                                if (
                                  form.getFieldValue("tag") &&
                                  form.getFieldValue("tag").length === 0
                                ) {
                                  setCheckedEmailToCandidate([]);
                                }
                              }}
                              className="keySkillsTags"
                              closable
                              key={tagIndex}
                            >
                              {tagsData}
                            </Tag>
                          );
                        })}
                    </Col>
                  </Row>
                  <Row style={{ gap: "10px" }} align="middle">
                    <Col>
                      <Form.Item
                        style={{
                          margin: "8px 0px 0px 0px",
                          padding: "0px",
                        }}
                      >
                        <Switch
                          checked={checkedEmailToCandidate}
                          onChange={(val) => {
                            setCheckedEmailToCandidate(val);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <p
                        className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                      >
                        Send email to the candidate
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col xs={24} md={24} className={commonStyles.footerShadow}>
            <Row justify="end" style={{ gap: "17px", width: "98vw" }}>
              {/* width: "50vw"  width: "690px" */}
              <Col>
                <Button
                  onClick={() => {
                    form.resetFields();
                    setTagsNewSkills([]);
                    props.getCandidateResumeHandler("");

                    props.getCandidateResumeParsingHandler({});
                    if (
                      location.state &&
                      location.state.from &&
                      location.state.from === "fromRequirement"
                    ) {
                      navigate("/JobDetailPage", {
                        state: {
                          from: "fromRequirement",
                          // pageFrom: "requirement",
                          jobCode: location?.state?.jobCode,
                          title: location?.state?.title,
                          id: location?.state?.id,
                        },
                      });
                      props.onClose();
                    } else if (
                      location.state &&
                      location.state.from &&
                      location.state.from === "fromRecuritment"
                    ) {
                      navigate("/recruitment/recruitmentJobdetails", {
                        state: {
                          from: "fromRecuritment",
                          activeTabKey: "addCandidate",
                          // pageFrom: "recuriter",
                          id: location?.state?.id,
                          jobCode: location?.state?.jobCode,
                          recuritmentJobDetailsName: location?.state?.title,
                        },
                      });
                      props.onClose();
                    } else {
                      props.onClose();
                    }
                  }}
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                  className={
                    commonStyles.footerButtonAddCandidateDrawerSecondary
                  }
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button
                  onClick={() => {
                    form.submit();
                  }}
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                  htmlType="button"
                  className={commonStyles.footerButtonAddCandidateDrawerPrimary}
                  type="primary"
                >
                  Add Candidate
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
        {/* </Col>
        </Row> */}
      </Drawer>
    </>
  );
};

function AddLink({ field, dataFormate }) {
  return (
    <>
      <Row>
        <Col md={24}>
          <Form.Item
            // name={[field.name, "link1"]}
            {...field}
            rules={[
              {
                required: false,
                message: "Please enter portfolio / work sample",
              },
            ]}
          >
            <Input placeholder="Paste the link here" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

function AddEducation({ field, dataFormate, form, onIsPersuing }) {
  return (
    <Row>
      <Col md={24}>
        <Form.Item
          label="Qualification"
          name={[field.name, "qualification"]}
          rules={[
            {
              required: true,
              message: "Please enter qualification",
            },
          ]}
        >
          <Input placeholder="Enter your degree" />
        </Form.Item>
      </Col>
      <Col md={24}>
        <Form.Item
          label="Qualification Type"
          name={[field.name, "qualificationType"]}
          rules={[
            {
              required: true,
              message: "Please enter qualification type",
            },
          ]}
        >
          {/* <Input placeholder="select qualification type" /> */}
          <Select
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder="Select qualification type"
          >
            <Select.Option value="Secondary">Secondary</Select.Option>
            <Select.Option value="Higher Secondary">
              Higher Secondary
            </Select.Option>
            <Select.Option value="Under-Graduate">Under Graduate</Select.Option>
            <Select.Option value="Graduate">Graduate</Select.Option>
            <Select.Option value="Post-Graduate">Post Graduate</Select.Option>
            <Select.Option value="Doctoral studies">
              Doctoral Studies
            </Select.Option>
            <Select.Option value="Diploma">Diploma</Select.Option>
            <Select.Option value="Distance Education">
              Distance Education
            </Select.Option>
            <Select.Option value="Correspondence Education">
              Correspondence Education
            </Select.Option>
            <Select.Option value="Vocational Education & Training">
              Vocational Education & Training
            </Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col md={24}>
        <Form.Item
          label="Institute/ School Name"
          name={[field.name, "institute"]}
          rules={[
            {
              required: false,
              message: "Please enter institute/ school name",
            },
          ]}
        >
          <Input placeholder="Enter your institute/ school name" />
        </Form.Item>
      </Col>
      <Col md={24}>
        <Form.Item
          label="Major"
          name={[field.name, "major"]}
          rules={[
            {
              required: false,
              message: "Please enter major",
            },
          ]}
        >
          <Input placeholder="Enter your subject name" />
        </Form.Item>
      </Col>
      <Col md={11}>
        <Form.Item
          label="CGPA"
          name={[field.name, "cgpa"]}
          rules={[
            {
              required: false,
              message: "Please enter cgpa",
            },
          ]}
        >
          <Input placeholder="Enter cgpa" type="number" />
        </Form.Item>
      </Col>
      <Col md={11} push={2}>
        <Form.Item
          label="Percentage"
          name={[field.name, "percentage"]}
          rules={[
            {
              required: false,
              message: "Please enter percentage",
            },
          ]}
        >
          <Input placeholder="Enter percentage" type="number" />
        </Form.Item>
      </Col>

      <Col md={24}>
        <Row justify="space-between">
          <Col md={11}>
            <Form.Item
              label="Start Date"
              name={[field.name, "from"]}
              rules={[
                {
                  required: false,
                  message: "Please select start date",
                },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue("educationInfo")[field.name].to ||
                      getFieldValue("educationInfo")[field.name].to > value
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "From date must be less than To Date"
                    );
                  },
                }),
              ]}
            >
              <DatePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                picker="year"
                placeholder="From"
                className={commonStyles.datePickerBgColor}
                style={{
                  width: "100%",
                }}
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(1, "day"))
                }
              />
            </Form.Item>
          </Col>

          <Col md={11}>
            <Form.Item
              label="End Date"
              name={[field.name, "to"]}
              rules={[
                {
                  required: false,
                  message: "Please select end date",
                },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue("educationInfo")[field.name].from ||
                      getFieldValue("educationInfo")[field.name].from < value
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "To date must be greater than From Date"
                    );
                  },
                }),
              ]}
            >
              <DatePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                picker="year"
                placeholder="To"
                className={commonStyles.datePickerBgColor}
                style={{
                  width: "100%",
                }}
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(1, "day"))
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ gap: "10px" }} align="middle">
          <Col>
            <Form.Item
              valuePropName="checked"
              name={[field.name, "isPersuing"]}
              style={{ margin: "0px", padding: "0px" }}
              rules={[
                {
                  required: false,
                  message: "This field is required",
                },
              ]}
            >
              <Switch
                onChange={(val) => {
                  onIsPersuing();
                  if (val) {
                    form.setFieldsValue({
                      ...form.getFieldsValue(),
                      educationInfo: form
                        .getFieldValue("educationInfo")
                        .map((x, i) => {
                          if (i === field.name)
                            return { ...x, isPersuing: true };
                          else return { ...x, isPersuing: false };
                        }),
                      employeeInfo: [],
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <p
              className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
            >
              Current Education
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function AddEmployee({ field, dataFormate, form }) {
  return (
    <Row>
      <Col style={{ marginTop: "10px" }} md={24}>
        <Form.Item
          label="Company"
          name={[field.name, "companyName"]}
          rules={[
            {
              required: true,
              message: "Please enter company",
            },
          ]}
        >
          <Input placeholder="Enter your company" />
        </Form.Item>
      </Col>
      <Col>
        <Row style={{ gap: "10px" }} align="middle">
          <Col>
            <Form.Item
              valuePropName="checked"
              name={[field.name, "isCurrent"]}
              style={{ margin: "0px", padding: "0px" }}
              rules={[
                {
                  required: false,
                  message: "This field is required",
                },
              ]}
            >
              <Switch
                onChange={(val) => {
                  if (val) {
                    form.setFieldsValue({
                      ...form.getFieldsValue(),
                      employeeInfo: form
                        .getFieldValue("employeeInfo")
                        .map((x, i) => {
                          if (i === field.name) {
                            return {
                              ...x,
                              isCurrent: true,
                              to: "",
                            };
                          } else {
                            return { ...x, isCurrent: false };
                          }
                        }),
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <p
              className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
            >
              Current Company
            </p>
          </Col>
        </Row>
      </Col>
      <Col style={{ marginTop: "20px" }} md={24}>
        <Form.Item
          label="Designation"
          name={[field.name, "designation"]}
          rules={[
            {
              required: true,
              message: "Please enter designation",
            },
          ]}
        >
          <Input placeholder="Enter your designation" />
        </Form.Item>
      </Col>
      <Col md={24}>
        <Row style={{ gap: "50px" }}>
          <Col md={11}>
            <Form.Item
              label="Start date"
              name={[field.name, "from"]}
              rules={[
                {
                  required: false,
                  message: "Please enter start date",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue("employeeInfo")[field.name].to ||
                      getFieldValue("employeeInfo")[field.name].to > value
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "From date must be less than To Date"
                    );
                  },
                }),
              ]}
            >
              <DatePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder="Select date"
                format={dataFormate}
                className={commonStyles.datePickerBgColor}
                style={{
                  width: "100%",
                }}
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(1, "day"))
                }
              />
            </Form.Item>
          </Col>
          <Col md={11}>
            <Form.Item
              label="End date"
              name={[field.name, "to"]}
              rules={[
                {
                  required: false,
                  message: "Please enter end date",
                },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue("employeeInfo")[field.name].from ||
                      getFieldValue("employeeInfo")[field.name].from < value
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "To date must be greater than From Date"
                    );
                  },
                }),
              ]}
            >
              <DatePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder="Select date"
                format={dataFormate}
                className={commonStyles.datePickerBgColor}
                style={{
                  width: "100%",
                }}
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(1, "day"))
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col md={24}>
        <Form.Item
          label="Summary"
          name={[field.name, "summary"]}
          rules={[
            {
              required: false,
              message: "Please enter summary",
            },
          ]}
        >
          <Input.TextArea placeholder="Enter summary" />
        </Form.Item>
      </Col>
      <Col md={24}>
        <Form.Item
          label="Reference Contact Person name"
          name={[field.name, "referenceName"]}
          rules={[
            {
              required: false,
              message: "Please enter reference name",
            },
          ]}
        >
          <Input placeholder="Enter reference contact person name" />
        </Form.Item>
      </Col>
      <Col md={24}>
        <Form.Item
          label="Reference Contact Person Email"
          name={[field.name, "referenceEmail"]}
          rules={[
            {
              required: false,
              message: "Please enter reference email",
            },
            {
              type: "email",
              message: "The input is not valid E-mail!.",
            },
          ]}
        >
          <Input placeholder="Enter reference contact person email" />
        </Form.Item>
      </Col>
      <Col md={24}>
        <Form.Item
          name={[field.name, "referenceNumber"]}
          rules={[
            {
              required: false,
              message: "Please enter reference number",
            },
          ]}
          label="Reference contact person number"
        >
          <PhoneInput
            className="ant-input ant-cust-inputs"
            defaultCountry="IN"
            placeholder="Enter reference contact person number"
          />
        </Form.Item>
      </Col>

      {/* <Row style={{ gap: "10px" }} align="middle">
        <Col>
          <Form.Item
            valuePropName="checked"
            name={[field.name, "isCurrent"]}
            style={{ margin: "0px", padding: "0px" }}
            rules={[
              {
                required: false,
                message: "This field is required",
              },
            ]}
          >
            <Switch
              onChange={(val) => {
                if (val) {
                  form.setFieldsValue({
                    ...form.getFieldsValue(),
                    employeeInfo: form
                      .getFieldValue("employeeInfo")
                      .map((x, i) => {
                        if (i === field.name) {
                          return { ...x, isCurrent: true };
                        } else {
                          return { ...x, isCurrent: false };
                        }
                      }),
                  });
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col>
          <p
            className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
          >
            Current Company
          </p>
        </Col>
      </Row> */}
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    candidates: state.candidates.candidates,
    countriesData: state.country.countries,
    StateData: state.country.state,
    cityData: state.country.city,
    TagsData: state.tags.tags,
    candidatesDetails: state.candidates.candidates,
    sourceOfTheLeadData: state.leadSource.leadSources,
    currentStageData: state.status.status,
    communicationManager: state.employee.employees,
    settindsData: state.settings.settings,
    resumeParsing: state.candidates.resumeParsing,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postAddCandidateHandler: (val, callback) => {
      dispatch(postCandidatesAction(val, callback));
    },
    getLeadSourcesHandler: () => {
      dispatch(getLeadSourcesAction());
    },
    getCountriesHandler: (data, callback) => {
      dispatch(getCountriesAction(data, callback));
    },
    getTagHandler: () => {
      dispatch(getTagsAction());
    },
    getCandidateDetailsHandler: () => {
      dispatch(getCandidatesAction());
    },
    getStatusHandler: () => {
      dispatch(getStatusAction());
    },
    getEmployeesHandler: () => {
      dispatch(getEmployeesAction());
    },
    getCandidateResumeParsingHandler: (data) => {
      dispatch(getCandidateResumeParsingSlice(data));
    },
    getCandidateResumeHandler: (data) => {
      dispatch(getCandidateResumeSlice(data));
    },
    getCandidatesEmailAndPhoneCheckHandler: (data, callback) => {
      dispatch(getCandidatesEmailAndPhoneCheckAction(data, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Addcandidatepage);
