import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  Rate,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { putInterViewAction } from "../../../Reusable/Store/Action/RecruitmentAction";
import {
  getTaskAndCalendarAction,
  getTasksFilterAction,
  postCompletedInterviewPerformanceAction,
} from "../../../Reusable/Store/Action/TaskAndCalendarAction";
import {
  CalendarBlankIcon,
  CaretBlackDownIcon,
  ClockIcon,
  CloseIcon,
  DesktopTowerIcon,
  HourglassIcon,
} from "../../IconsComp/Icons";
import commonStyles from "../../UI/CommonCss.module.css";
import styles from "../AddCandidatesComp/AddCandidatesComp.module.css";
import InterviewCompleted from "../AddCandidatesComp/CandidatesSummary/InterviewCompleted";
function CalendarInterviewDetails(props) {
  const [form] = useForm();
  const [menuKey, setMenuKey] = useState("1");
  const [icon, setIcon] = useState(false);
  let rattingArray = [];
  const [ratingData, setRatingData] = useState([]);
  useEffect(() => {
    if (
      props.onInterviewDetails &&
      props.onInterviewDetails.performanceRating &&
      props.onInterviewDetails.performanceRating.length > 0
    ) {
      props.onInterviewDetails.performanceRating.map((x, i) => {
        if (
          props.onInterviewDetails.performanceRating.length !==
          rattingArray.length
        ) {
          return rattingArray.push(x.rating);
        }
      });
      setRatingData(rattingArray);
    }
    form.setFieldsValue({
      comment: props.onInterviewDetails ? props.onInterviewDetails.comment : "",
    });
  }, [props.onInterviewDetails]);

  const interviewQuestions = (data) => {
    // props.onAnswers({
    //   ...data,
    //   id: props.onJobQuestions.id,
    // });
    const interviewFeedback = {
      //id: props.onJobQuestions && props.onJobQuestions.id,
      candidateInterviewId:
        props.onInterviewDetails && props.onInterviewDetails.id,
      comment: data.comment ? data.comment : "",
      rating: ratingData,
    };

    props.postInterviewPerformanceRatingHandler(
      interviewFeedback,
      (success) => {
        if (success) {
          if (props.onInterviewDetails) {
            props.getTaskByIdHandler(
              props.onInterviewDetails.id,
              { link: 1 },
              (success) => {}
            );
          }
          props.onClose();
        }
      }
    );
  };
  const menu2 = (
    <Menu
      style={{ width: "214px", margin: "11px 0px" }}
      selectable
      items={[
        {
          key: "1",
          label: (
            <p
              onClick={() => {
                setMenuKey("1");

                props.editInterviewStatusHandler(
                  {
                    id: props.onInterviewDetails.id,
                    // status: "pending",
                    status: "Pending",
                  },
                  (success) => {
                    if (success) {
                      // if (props.typeData === "task") {
                      // props.getTaskFilterHandler({
                      //   type: "interview",
                      //   current_page: 1,
                      // });
                      let interviewFilterDataFromLocal = JSON.parse(
                        localStorage.getItem("interviewFilterData")
                      );
                      if (interviewFilterDataFromLocal) {
                        props.getTaskFilterHandler({
                          type: "interview",
                          viewType: "calendar",
                          ...props.filterData,
                          ...interviewFilterDataFromLocal,
                        });
                      } else {
                        props.getTaskFilterHandler({
                          type: "interview",
                          viewType: "calendar",
                          ...props.filterData,
                        });
                      }
                      props.onClose();
                      // } else {
                      //   props.fetchInterviewData();
                      // }
                      // props.onCloseInterViewDetails();
                    }
                  }
                );
              }}
              style={{
                color:
                  menuKey === "1"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonStyles.marginBottomRemove} ${commonStyles.darkText}`}
            >
              Pending
            </p>
          ),
        },
        {
          key: "2",
          label: (
            <p
              onClick={() => {
                setMenuKey("2");
                props.editInterviewStatusHandler(
                  {
                    id: props.onInterviewDetails.id,
                    // status: "completed",
                    status: "Completed",
                  },
                  (success) => {
                    if (success) {
                      // if (props.typeData === "task") {
                      // props.getTaskFilterHandler({
                      //   type: "interview",
                      //   current_page: 1,
                      // });
                      let interviewFilterDataFromLocal = JSON.parse(
                        localStorage.getItem("interviewFilterData")
                      );
                      if (interviewFilterDataFromLocal) {
                        props.getTaskFilterHandler({
                          type: "interview",
                          viewType: "calendar",
                          ...props.filterData,
                          ...interviewFilterDataFromLocal,
                        });
                      } else {
                        props.getTaskFilterHandler({
                          type: "interview",
                          viewType: "calendar",
                          ...props.filterData,
                        });
                      }
                      props.onClose();
                      // } else {
                      //   props.fetchInterviewData();
                      // }
                    }
                  }
                );
                // props.onCloseInterViewDetails();
              }}
              style={{
                color:
                  menuKey === "2"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonStyles.marginBottomRemove}  ${commonStyles.darkText}`}
            >
              Completed
            </p>
          ),
        },
        {
          key: "3",
          label: (
            <p
              onClick={() => {
                setMenuKey("3");
                props.editInterviewStatusHandler(
                  {
                    id: props.onInterviewDetails.id,
                    status: "Rescheduled by Client",
                  },
                  (success) => {
                    if (success) {
                      // if (props.typeData === "task") {
                      // props.getTaskFilterHandler({
                      //   type: "interview",
                      //   current_page: 1,
                      // });
                      let interviewFilterDataFromLocal = JSON.parse(
                        localStorage.getItem("interviewFilterData")
                      );
                      if (interviewFilterDataFromLocal) {
                        props.getTaskFilterHandler({
                          type: "interview",
                          viewType: "calendar",
                          ...props.filterData,
                          ...interviewFilterDataFromLocal,
                        });
                      } else {
                        props.getTaskFilterHandler({
                          type: "interview",
                          viewType: "calendar",
                          ...props.filterData,
                        });
                      }
                      props.onClose();
                      // } else {
                      //   props.fetchInterviewData();
                      // }
                    }
                  }
                );
                // props.onCloseInterViewDetails();
              }}
              style={{
                color:
                  menuKey === "3"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonStyles.marginBottomRemove}  ${commonStyles.darkText}`}
            >
              Rescheduled by Client
            </p>
          ),
        },
        {
          key: "4",
          label: (
            <p
              onClick={() => {
                setMenuKey("4");
                props.editInterviewStatusHandler(
                  {
                    id: props.onInterviewDetails.id,
                    status: "Candidate No Show",
                  },
                  (success) => {
                    if (success) {
                      // if (props.typeData === "task") {
                      // props.getTaskFilterHandler({
                      //   type: "interview",
                      //   current_page: 1,
                      // });
                      let interviewFilterDataFromLocal = JSON.parse(
                        localStorage.getItem("interviewFilterData")
                      );
                      if (interviewFilterDataFromLocal) {
                        props.getTaskFilterHandler({
                          type: "interview",
                          viewType: "calendar",
                          ...props.filterData,
                          ...interviewFilterDataFromLocal,
                        });
                      } else {
                        props.getTaskFilterHandler({
                          type: "interview",
                          viewType: "calendar",
                          ...props.filterData,
                        });
                      }
                      props.onClose();
                      // } else {
                      //   props.fetchInterviewData();
                      // }
                    }
                  }
                );
                // props.onCloseInterViewDetails();
              }}
              style={{
                color:
                  menuKey === "4"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonStyles.marginBottomRemove}  ${commonStyles.darkText}`}
            >
              Candidate No Show
            </p>
          ),
        },
        {
          key: "5",
          label: (
            <p
              onClick={() => {
                setMenuKey("5");
                props.editInterviewStatusHandler(
                  {
                    id: props.onInterviewDetails.id,
                    status: "Rescheduled by Candidate",
                  },
                  (success) => {
                    if (success) {
                      // if (props.typeData === "task") {
                      // props.getTaskFilterHandler({
                      //   type: "interview",
                      //   current_page: 1,
                      // });
                      let interviewFilterDataFromLocal = JSON.parse(
                        localStorage.getItem("interviewFilterData")
                      );
                      if (interviewFilterDataFromLocal) {
                        props.getTaskFilterHandler({
                          type: "interview",
                          viewType: "calendar",
                          ...props.filterData,
                          ...interviewFilterDataFromLocal,
                        });
                      } else {
                        props.getTaskFilterHandler({
                          type: "interview",
                          viewType: "calendar",
                          ...props.filterData,
                        });
                      }
                      props.onClose();
                      // } else {
                      //   props.fetchInterviewData();
                      // }
                    }
                  }
                );
                // props.onCloseInterViewDetails();
              }}
              style={{
                color:
                  menuKey === "5"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonStyles.marginBottomRemove}  ${commonStyles.darkText}`}
            >
              Rescheduled by Candidate
            </p>
          ),
        },
      ]}
    />
  );

  return (
    <>
      <Drawer
        className={"modalModified"}
        closable={false}
        extra={
          <span
            onClick={() => {
              props.onClose();
            }}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)"} />
          </span>
        }
        footer={
          <Row
            onMouseEnter={() => {
              setIcon(true);
            }}
            onMouseLeave={() => {
              setIcon(false);
            }}
            align="middle"
            justify="space-around"
          >
            {
              <>
                {
                  // props.onProfile &&
                  //   props.onProfile.roleType &&
                  props.permissions &&
                    // props.onProfile.roleType !== "Recruiter" &&
                    props.permissions.find(
                      (data) => data === "interview-status-update"
                    ) && (
                      <Col>
                        <Button
                          className={commonStyles.intaddButton}
                          type="primary"
                        >
                          <Row align="middle" justify="space-around">
                            <Col>
                              <p className={commonStyles.marginBottomRemove}>
                                Interview Status
                              </p>
                            </Col>
                            <Col>
                              <Dropdown placement="top" overlay={menu2}>
                                <Typography.Link>
                                  <Space
                                    className={
                                      commonStyles.tableHeadingTextData
                                    }
                                  >
                                    <CaretBlackDownIcon color={"white"} />
                                  </Space>
                                </Typography.Link>
                              </Dropdown>
                            </Col>
                          </Row>
                        </Button>
                      </Col>
                    )
                }
                {/* <Col>
                  <Button
                    onClick={() => {
                      props.onCloseInterViewDetails();
                    }}
                    className={commonCssStyles.buttonSettingsSecondary}
                  >
                    Cancel
                  </Button>
                </Col> */}
              </>
            }
            {props.onModalName && props.onModalName === "display" ? (
              <>
                {props.onInterviewDetails &&
                props.onInterviewDetails.status === "pending" ? (
                  <>
                    <Col>
                      <Button
                        onClick={() => {
                          // setNotify(true);
                          props.editInterviewStatusHandler(
                            {
                              id: props.onInterviewDetails.id,
                              // notify: notify ? "1" : "0",
                              notify: "1",
                            },
                            (success) => {
                              if (success) {
                                props.onClose();
                                // props.fetchInterviewData();
                                // props.onCloseInterViewDetails();
                              }
                            }
                          );
                        }}
                        style={{ width: "auto" }}
                        className={commonStyles.buttonSettingsPrimary}
                        type="primary"
                      >
                        Notify Candidate
                      </Button>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col>
                  <Button
                    onClick={() => {
                      props.onClose();
                      // props.onCloseInterViewDetails();
                    }}
                    className={commonStyles.buttonSettingsSecondary}
                  >
                    Cancel
                  </Button>
                </Col>
              </>
            ) : (
              <>
                {/* {props.onInterviewDetails.status &&
                props.onInterviewDetails.status === "pending" ? (
                  <>
                    <Col>
                      <Button
                        onClick={() => {
                          // setReschedule(true);
                          props.editInterviewStatusHandler(
                            {
                              id: props.onInterviewDetails.id,
                              // reschedule: reschedule ? "1" : "0",
                              reschedule: "1",
                            },
                            (success) => {
                              if (success) {
                                props.onClose();
                                // props.fetchInterviewData();
                                // props.onCloseInterViewDetails();
                              }
                            }
                          );
                        }}
                        type="default"
                        style={{ width: "auto" }}
                        className={commonStyles.buttonSettingsSecondary}
                      >
                        Reschedule Interview
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => {
                          // setNotify(true);
                          props.editInterviewStatusHandler(
                            {
                              id: props.onInterviewDetails.id,
                              // notify: notify ? "1" : "0",
                              notify: "1",
                            },
                            (success) => {
                              if (success) {
                                props.onClose();
                                // props.fetchInterviewData();
                                // props.onCloseInterViewDetails();
                              }
                            }
                          );
                        }}
                        style={{ width: "auto" }}
                        className={commonStyles.buttonSettingsPrimary}
                        type="primary"
                      >
                        Notify Candidate
                      </Button>
                    </Col>
                  </>
                ) : (
                  ""
                )} */}
              </>
            )}
          </Row>
        }
        style={{
          paddingBottom: "0px",
        }}
        title={
          <p
            style={{ fontSize: "20px", margin: "0px" }}
            className={`${commonStyles.modalHeading}`}
          >
            Interview Details
          </p>
        }
        width={726}
        height={48}
        open={props.onOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Row style={{ margin: "0px 20px 20px 20px" }} align="middle">
          <Col md={24}>
            <Row>
              <Col>
                <p className={styles.candidateSummaryLeftSubCont}>
                  {props.onInterviewDetails &&
                    props.onInterviewDetails.headingText &&
                    props.onInterviewDetails.headingText}
                  {/* Interview with{" "}
          <span>title
            <b className={styles.candidateSummaryLeftSubContBold}>
              Sania Malhotra , UX Designer
            </b>
          </span>
          &nbsp; candidate by&nbsp;
          <span>
            {props.statusHandle &&
              props.statusHandle.interviewers.map((data, index) => {
                return (
                  <b
                    key={index}
                    className={styles.candidateSummaryLeftSubContBold}
                  >
                    {data.name}
                  </b>
                );
              })}
          </span> */}
                </p>
                {props.onInterviewDetails &&
                props.onInterviewDetails.jobName ? (
                  <Col md={24}>
                    <p
                      className={`${commonStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}  `}
                    >
                      {` Job name : ${props.onInterviewDetails.jobName}`}
                    </p>
                  </Col>
                ) : (
                  <></>
                )}
                {props.onInterviewDetails &&
                props.onInterviewDetails.clientName ? (
                  <Col style={{ marginTop: "20px" }} md={24}>
                    <p
                      className={`${commonStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}  `}
                    >
                      {` Client name : ${props.onInterviewDetails.clientName}`}
                    </p>
                  </Col>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Row style={{ gap: "17px", marginTop: "20px" }}>
              <Col>
                <Row align="middle" style={{ gap: "10px" }}>
                  <Col>
                    <CalendarBlankIcon color={"var(--primaryColor)"} />
                  </Col>
                  <Col>
                    <p
                      className={`${commonStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}`}
                    >
                      {/* Wednesday, March 12 2022 */}
                      {moment(
                        props.onInterviewDetails &&
                          props.onInterviewDetails.interviewDate
                      ).format("MMMM DD YYYY")}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row align="middle" style={{ gap: "10px" }}>
                  <Col>
                    <ClockIcon color={"var(--primaryColor)"} />
                  </Col>
                  <Col>
                    <p
                      className={`${commonStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}`}
                    >
                      {`${
                        props.onInterviewDetails &&
                        props.onInterviewDetails.interviewStartTime &&
                        props.onInterviewDetails.interviewStartTime
                      } -
                  ${
                    props.onInterviewDetails &&
                    props.onInterviewDetails.interviewEndTime &&
                    props.onInterviewDetails.interviewEndTime
                  }`}
                    </p>
                  </Col>
                </Row>
                <p style={{ margin: "0px" }}>
                  <span
                    className={styles.candidateSummaryLeftSubCont}
                    style={{ verticalAlign: "text-bottom" }}
                  ></span>
                </p>
              </Col>
              <Col>
                <Row align="middle" style={{ gap: "10px" }}>
                  <Col>
                    <HourglassIcon color={"var(--primaryColor)"} />
                  </Col>
                  <Col>
                    <p
                      className={`${commonStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}`}
                    >
                      {`${
                        props.onInterviewDetails &&
                        props.onInterviewDetails.duration
                      } `}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col md={24}>
                <Row align="middle" style={{ gap: "10px" }}>
                  <Col>
                    <DesktopTowerIcon />
                  </Col>
                  <Col>
                    <p
                      className={`${commonStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}`}
                    >
                      {props.onInterviewDetails &&
                      props.onInterviewDetails.interviewType &&
                      props.onInterviewDetails.interviewType === "web"
                        ? "Virtual"
                        : props.onInterviewDetails.interviewType}
                    </p>
                  </Col>
                  <Col>
                    {(props.onInterviewDetails &&
                      props.onInterviewDetails.status === "pending") ||
                    props.onInterviewDetails.status === "Pending" ? (
                      <Tag
                        style={{
                          backgroundColor: "#FFF0DB",
                          color: "#ff9a02",
                          width: "85px",
                          textAlign: "center",
                          padding: "6px 0px 5px 0px",
                          fontSize: "14px",
                        }}
                      >
                        Pending
                      </Tag>
                    ) : (
                      <Tag
                        style={{
                          backgroundColor: "#CCFAE4",
                          color: "#0E8553",
                          // width: "85px",
                          width: "auto",
                          textAlign: "center",
                          padding: "6px 10px 5px 10px",
                          fontSize: "14px",
                        }}
                      >
                        {props.onInterviewDetails &&
                        props.onInterviewDetails.status === "Completed"
                          ? "Completed"
                          : props.onInterviewDetails.status === "completed"
                          ? "Completed"
                          : props.onInterviewDetails &&
                            props.onInterviewDetails.status ===
                              "Rescheduled by Candidate"
                          ? "Rescheduled by Candidate"
                          : props.onInterviewDetails &&
                            props.onInterviewDetails.status ===
                              "Rescheduled by Client"
                          ? "Rescheduled by Client"
                          : props.onInterviewDetails &&
                            props.onInterviewDetails.status ===
                              "Candidate No Show"
                          ? "Candidate No Show"
                          : "Completed"}
                      </Tag>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {/* <Col style={{ margin: "21px auto auto auto" }} md={24}>
            <Row style={{ gap: "9px" }}>
              <Col>
                {props.onInterviewDetails &&
                props.onInterviewDetails.status &&
                props.onInterviewDetails.status === "completed" ? (
                  <Checkbox
                    defaultChecked
                    onChange={() => {
                      props.editInterviewStatusHandler(
                        {
                          id: props.onInterviewDetails.id,
                          status: "pending",
                        },
                        (success) => {}
                      );
                    }}
                  />
                ) : (
                  <Checkbox
                    onChange={() => {
                      props.editInterviewStatusHandler(
                        {
                          id: props.onInterviewDetails.id,
                          status: "completed",
                        },
                        (success) => {}
                      );
                    }}
                  />
                )}
              </Col>
              <Col>
                <p className={commonStyles.formInputLabel}>
                  Mark task as completed
                </p>
              </Col>
            </Row>
          </Col> */}
          <Col style={{ marginTop: "20px" }} md={24}>
            {props.onInterviewDetails &&
            props.onInterviewDetails.interviewTypeDetails &&
            props.onInterviewDetails.interviewType === "web" ? (
              <Form layout="vertical">
                {props.onInterviewDetails &&
                  props.onInterviewDetails.interviewType === "web" && (
                    <Row justify="space-between" align="middle">
                      <Col md={19}>
                        <Form.Item label="Interview Link">
                          <Input
                            readOnly
                            defaultValue={
                              (props.onInterviewDetails &&
                                props.onInterviewDetails.status ===
                                  "pending") ||
                              props.onInterviewDetails.status === "Pending"
                                ? props.onInterviewDetails &&
                                  props.onInterviewDetails.interviewTypeDetails
                                : props.onInterviewDetails &&
                                  props.onInterviewDetails.interviewTypeDetails
                            }
                            style={{
                              height: "35px",
                              color: "var(--primaryColor)",
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Button
                          onClick={() => {
                            window.open(
                              props.onInterviewDetails &&
                                props.onInterviewDetails.interviewTypeDetails,
                              "_blank"
                            );
                          }}
                          className={commonStyles.buttonSettingsPrimary}
                          style={{ height: "35px", width: "85px" }}
                          type="primary"
                        >
                          Join
                        </Button>
                      </Col>
                    </Row>
                  )}
              </Form>
            ) : props.onInterviewDetails.interviewType === "office" ? (
              <>
                <p className={` ${styles.candidateSummaryLeftSubCont}`}>
                  Office Address :&nbsp;
                  <span>
                    {props.onInterviewDetails.interviewTypeDetails &&
                    props.onInterviewDetails.interviewTypeDetails
                      .officeAddress &&
                    props.onInterviewDetails.interviewTypeDetails.officeAddress
                      ? props.onInterviewDetails.interviewTypeDetails
                          .officeAddress
                      : "NA"}
                  </span>
                </p>
                <p className={` ${styles.candidateSummaryLeftSubCont}`}>
                  Point Of Contact :&nbsp;
                  <span>
                    {props.onInterviewDetails.interviewTypeDetails &&
                    props.onInterviewDetails.interviewTypeDetails
                      .pointOfContact &&
                    props.onInterviewDetails.interviewTypeDetails.pointOfContact
                      ? props.onInterviewDetails.interviewTypeDetails
                          .pointOfContact
                      : "NA"}
                  </span>
                </p>
              </>
            ) : (
              <>
                <p className={` ${styles.candidateSummaryLeftSubCont}`}>
                  Contact Name :&nbsp;
                  <span>
                    {props.onInterviewDetails.interviewTypeDetails &&
                    props.onInterviewDetails.interviewTypeDetails.contactName &&
                    props.onInterviewDetails.interviewTypeDetails.contactName
                      ? props.onInterviewDetails.interviewTypeDetails
                          .contactName
                      : "NA"}
                  </span>
                </p>
                <p className={` ${styles.candidateSummaryLeftSubCont}`}>
                  Phone :&nbsp;
                  <span>
                    {props.onInterviewDetails.interviewTypeDetails &&
                    props.onInterviewDetails.interviewTypeDetails.phone &&
                    props.onInterviewDetails.interviewTypeDetails.phone
                      ? props.onInterviewDetails.interviewTypeDetails.phone
                      : "NA"}
                  </span>
                </p>
              </>
            )}
          </Col>

          {props.onInterviewDetails &&
          props.onInterviewDetails.interviewers &&
          props.onInterviewDetails.interviewers.length > 0 ? (
            <>
              <Col style={{ marginTop: "20px" }} md={24}>
                <p
                  className={styles.drawerFormText}
                  style={{ margin: "0px", paddingBottom: "14px" }}
                >
                  Interview Details
                </p>
                <Row align="middle">
                  <Col md={8}>
                    <p className={commonStyles.formInputLabel}>Name</p>
                  </Col>
                  <Col md={8}>
                    <p className={commonStyles.formInputLabel}>Designation</p>
                  </Col>
                  <Col md={8}>
                    <p className={commonStyles.formInputLabel}>Email</p>
                  </Col>
                </Row>

                {props.onInterviewDetails.interviewers.map((data, index) => {
                  return (
                    <Row key={index} align="middle">
                      <Col md={8}>
                        <p
                          className={styles.interviewSubHeading}
                          style={{ margin: "0px", padding: "5px" }}
                        >
                          {data.name}
                        </p>
                      </Col>

                      <Col md={8}>
                        <p
                          className={styles.interviewSubHeading}
                          style={{ margin: "0px", padding: "5px" }}
                        >
                          {data.designation}
                        </p>
                      </Col>

                      <Col md={8}>
                        <p
                          className={styles.interviewSubHeading}
                          style={{ margin: "0px" }}
                        >
                          {data.email}
                        </p>
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </>
          ) : (
            <></>
          )}
          {props.onInterviewDetails && props.onInterviewDetails.instruction ? (
            <>
              <Col md={24}>
                <p className={styles.drawerFormText}>Instruction</p>
              </Col>
              <Col md={24}>
                <p className={styles.interviewSubHeading}>
                  {props.onInterviewDetails &&
                  props.onInterviewDetails.instruction
                    ? props.onInterviewDetails.instruction
                    : "NA"}
                </p>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
        <Row gutter={[0, 16]} style={{ margin: "0px 20px 80px 20px" }}>
          <Col xs={24} md={24}>
            {(props.onInterviewDetails &&
              props.onInterviewDetails.answers &&
              props.onInterviewDetails.answers.length === 0) ||
            (props.onInterviewDetails.answers === null &&
              props.onInterviewDetails.status === "completed") ? (
              <Col md={24}>
                <p
                  className={`${commonStyles.marginBottomRemove} ${styles.drawerFormText}`}
                >
                  Interview Feedback
                </p>
              </Col>
            ) : (
              ""
            )}
          </Col>
          <Col xs={24} md={24}>
            {/* performanceRating */}
            {props.onInterviewDetails &&
              props.onInterviewDetails.performanceRating &&
              props.onInterviewDetails.performanceRating.map((x, index) => {
                return (
                  <div style={{ marginBottom: "20px" }} key={index}>
                    <p
                      className={`${commonStyles.marginBottomRemove} ${styles.answersText}`}
                    >{`${index + 1}) ${x.question}`}</p>
                    <Rate
                      defaultValue={x.rating}
                      // value={
                      //   ratingData && ratingData.length > 0
                      //     ? ratingData[index]
                      //     : x.rating
                      // }
                      value={ratingData ? ratingData[index] : x.rating}
                      onChange={(data) => {
                        if (ratingData[index] || ratingData[index] === 0) {
                          setRatingData((v) => {
                            const newStateValue = [...v];
                            newStateValue[index] = data;
                            return newStateValue;
                          });
                        } else {
                          setRatingData((rate) => [...rate, data]);
                        }
                        // const val = {
                        //   id: props.onInterviewDetails && props.onInterviewDetails.id,
                        //   rating: data,
                        // };
                        // console.log(val);
                        // props.onRating(val);
                      }}
                      style={{ color: "var(--primaryColor)" }}
                    />
                  </div>
                );
              })}
          </Col>
          <Col style={{ marginTop: "20px" }} xs={24} md={24}>
            <Form
              form={form}
              onFinish={(val) => {
                interviewQuestions(val);
              }}
              // initialValues={
              //   props.onInterviewDetails &&
              //   props.onInterviewDetails.comment && {
              //     comment: props.onInterviewDetails.comment,
              //   }
              // }
              layout="vertical"
            >
              <Form.Item label="Comment/Notes" name={"comment"}>
                <Input.TextArea />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Button
                  htmlType="button"
                  onClick={() => {
                    form.submit();
                  }}
                  style={{ width: "auto" }}
                  type="default"
                  className={commonStyles.buttonSettingsSecondary}
                >
                  Save Feedback
                </Button>
              </div>
            </Form>
          </Col>
          <Col>
            <p
              className={`${commonStyles.marginBottomRemove}  ${styles.drawerFormText}`}
            >
              Overall Rating
            </p>
            <Rate
              disabled={true}
              style={{ color: "var(--primaryColor)" }}
              value={
                props.onInterviewDetails && props.onInterviewDetails.rating
              }
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editInterviewStatusHandler: (data, callback) => {
      dispatch(putInterViewAction(data, callback));
    },
    getTaskFilterHandler: (data) => {
      dispatch(getTasksFilterAction(data));
    },
    postInterviewPerformanceRatingHandler: (data, callback) => {
      dispatch(postCompletedInterviewPerformanceAction(data, callback));
    },
    getTaskByIdHandler: (id, link, callback) => {
      dispatch(getTaskAndCalendarAction(id, link, callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarInterviewDetails);
