import { Button, Col, Row } from "antd";
import Modal from "antd/lib/modal/Modal";
import { CloseIcon } from "../IconsComp/Icons";
import commonCssStyle from "../UI/CommonCss.module.css";
function PermissionMessage(props) {
  return (
    <Modal
      title={
        <Row justify="space-between">
          <Col>
            <p
              className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.popUpHeadingText}`}
            >
              Permission
            </p>
          </Col>
          <Col>
            <span
              style={{ float: "right" }}
              onClick={() => {
                props.onClosePMModal();
              }}
              className={`${commonCssStyle.coursorPointer} `}
            >
              <CloseIcon color={"var(--body-text-color)"} />
            </span>
          </Col>
        </Row>
      }
      closable={false}
      footer={
        <Row justify="end" align="middle">
          <Col>
            <Button
              type="default"
              className={commonCssStyle.buttonSettingsSecondary}
              style={{ height: "48px", fontSize: "16px", width: "181px" }}
              onClick={() => {
                props.onClosePMModal();
              }}
            >
              Close
            </Button>
          </Col>
        </Row>
      }
      open={props.onOpenPMModal.open}
    >
      <Row>
        <Col>
          <p style={{ color: "var(--body-text-color)" }}>
            You don't have Permission for {props.onOpenPMModal.permission}
          </p>
        </Col>
      </Row>
    </Modal>
  );
}
export default PermissionMessage;
