import {
  Drawer,
  Form,
  Input,
  Button,
  Tag,
  Select,
  DatePicker,
  Row,
  Col,
} from "antd";
import styles from "./Employee.module.css";
import filter from "../../../Assets/Image/filter.png";
import { CloseIcon, FilterOutlinedIcon } from "../../IconsComp/Icons";
import commonStyles from "../../UI/CommonCss.module.css";
import { useState } from "react";
import moment from "moment";
import ResetButton from "../../UI/ResetButton";
import { useEffect } from "react";

const { Option } = Select;
const tagRenderForMobileNum = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

const EmployeeFilter = (props) => {
  const [form] = Form.useForm();

  const [location, setLocation] = useState(false);

  const [filterDatas, setFilterDatas] = useState({
    reportingManager: "",
    employmentType: "",
    role: "",
    department: "",
  });

  // const fd = localStorage.getItem("employee");
  // console.log(obj,"obj")

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("employee"));
    if (obj) {
      form.setFieldsValue({
        status: obj.status,
        department: obj.department,
        employmentType: obj.employmentType,
        location: obj.location,
        reportingManager: obj.reportingManager,
        role: obj.role,
        joiningDate: obj.joiningDate ? moment(obj.joiningDate) : "",
      });
    }
  }, []);

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const onFinish = (values) => {
    const datas = {
      ...values,
      joiningDate: values.joiningDate
        ? moment(values.joiningDate).format("YYYY-MM-DD")
        : "",
    };
    props.employeeFilterHandleer(datas);
    props.onCloseFilter();
  };
  return (
    <Drawer
      className="modalModified"
      extra={
        <div
          onClick={() => props.onCloseFilter()}
          className={`${commonStyles.coursorPointer} `}
        >
          {" "}
          <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
        </div>
      }
      width="506"
      height={48}
      closeIcon={<img alt="example" src={filter} />}
      title={
        <>
          <Row>
            <Col>
              <FilterOutlinedIcon color={"var(--primaryColor)"} />
            </Col>
            <Col>
              <p
                style={{ margin: "0px 0px 0px 5px" }}
                className={commonStyles.filterHeadingName}
              >
                Filter
              </p>
            </Col>
          </Row>{" "}
        </>
      }
      footer={false}
      closable={false}
      open={props.onOpenFilter}
    >
      <Form form={form} onFinish={onFinish} layout="vertical" hideRequiredMark>
        <Row style={{ padding: "0px 30px 80px 30px" }}>
          <Col md={24}>
            <Form.Item name="status" label="Employee Status">
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                allowClear
                placeholder="Select employee status"
                // className={"candidateSelectHeight"}
              >
                <Select.Option key={"active"} value={"active"}>
                  Active
                </Select.Option>
                <Select.Option key={"inactive"} value={"inactive"}>
                  Inactive
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="employmentType" label="Employee Type">
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                showSearch
                allowClear
                style={{
                  width: "100%",
                }}
                notFoundContent={null}
                className={`${commonStyles.border} `} /* candidateSelectHeight */
                placeholder="Select employment type"
                tagRender={tagRenderForMobileNum}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onKeyUp={(val) => {
                  setFilterDatas({
                    employmentType: val.target.value,
                  });
                }}
              >
                {filterDatas.employmentType &&
                  filterDatas.employmentType !== " " &&
                  filterDatas.employmentType.length > 0 &&
                  props.employmentType &&
                  props.employmentType.map((data, index) => {
                    return (
                      <Option key={index} value={data.jobType}>
                        {data.jobType}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item name="role" label="Employee Role">
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                allowClear
                showSearch
                notFoundContent={null}
                className={`${commonStyles.border} `} /* candidateSelectHeight */
                placeholder="Enter employee role"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onKeyUp={(val) => {
                  setFilterDatas({
                    role: val.target.value,
                  });
                }}
              >
                {filterDatas.role &&
                  filterDatas.role !== " " &&
                  filterDatas.role.length > 0 &&
                  props.roleTypeData &&
                  props.roleTypeData.map((data, index) => {
                    return (
                      data.isDefault !== 1 && (
                        <Option key={index} value={data.name}>
                          {data.name}
                        </Option>
                      )
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item name="department" label="Department">
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                mode="multiple"
                showSearch
                allowClear
                style={{
                  width: "100%",
                }}
                className={`${commonStyles.border} `} /* candidateSelectHeight */
                placeholder="Select employment type"
                notFoundContent={null}
                tagRender={tagRenderForMobileNum}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onKeyUp={(val) => {
                  setFilterDatas({
                    department: val.target.value,
                  });
                }}
              >
                {filterDatas.department &&
                  filterDatas.department !== " " &&
                  filterDatas.department.length > 0 &&
                  props.departments &&
                  props.departments.map((data, index) => {
                    return (
                      <Option key={index} value={data.departmentName}>
                        {data.departmentName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item name="location" label="Location">
              <Input
                onChange={() => {
                  if (!location) {
                    setLocation(true);
                  }
                }}
                // className={"CandidateInputHeight"}
                placeholder="Enter location"
              />
            </Form.Item>

            {location && (
              <ResetButton
                onClearData={() => {
                  form.setFieldsValue({
                    location: "",
                  });
                  setLocation(false);
                }}
              />
            )}

            <Form.Item name="joiningDate" label="Joining Date">
              <DatePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                locale={"en"}
                style={{ width: "100%" }}
                className={`${commonStyles.datePickerBgColor} `} /* CandidateInputHeight */
                format={dataFormate}
              ></DatePicker>
            </Form.Item>

            <Form.Item name="reportingManager" label="Reporting Manager">
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                showSearch
                allowClear
                style={{
                  width: "100%",
                }}
                notFoundContent={null}
                className={`${commonStyles.border} `} /* candidateSelectHeight */
                placeholder="Select reporting manager"
                tagRender={tagRenderForMobileNum}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onKeyUp={(val) => {
                  setFilterDatas({
                    reportingManager: val.target.value,
                  });
                }}
              >
                {filterDatas.reportingManager &&
                  filterDatas.reportingManager !== " " &&
                  filterDatas.reportingManager.length > 0 &&
                  props.employees &&
                  props.employees.map((data, index) => {
                    return (
                      <Option
                        key={index}
                        value={data.firstName + " " + data.lastName}
                      >
                        {data.firstName + " " + data.lastName}
                      </Option>
                    );
                  })}
                {/* {props.employees &&
                  props.employees.map((data, index) => {
                    return (
                      <Option key={index} value={data.id}>
                        {data.firstName + " " + data.lastName}
                      </Option>
                    );
                  })} */}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Col className={commonStyles.footerShadow} style={{ width: "503px" }}>
          <Row justify="end" style={{ gap: "25px" }}>
            <Col>
              <Button
                className={commonStyles.buttonSettingsSecondary}
                type="default"
                onClick={() => {
                  localStorage.removeItem("employee")
                  localStorage.removeItem("employeeSort")
                  props.employeeFilterHandleer({});
                  setLocation(false);
                  form.resetFields();
                  setFilterDatas({
                    reportingManager: "",
                    employmentType: "",
                    role: "",
                    department: "",
                  });
                  props.onCloseFilter();
                }}
              >
                Reset Filter
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  form.submit();
                }}
                htmlType="button"
                className={commonStyles.buttonSettingsPrimary}
                type="primary"
              >
                Apply Filter
              </Button>
            </Col>
          </Row>
        </Col>
      </Form>
    </Drawer>
  );
};
export default EmployeeFilter;
