import {
  getInterviewDetailsSlice,
  getInterviewSlice,
  postInterviewSlice,
  recruitment_FilterSlice,
  getCandidateAssignedToRecruitmentByIdSlice,
  putInterviewSlice,
  getImportCandidatesSlice,
  recruitment_All_Candidates_FilterSlice,
  getRecruitmentsCandidatesJobStatusSlice,
  getRecruitmentAllStatusSlice,
  getRecruitmentCallLogSlice,
  recruitment_Suggested_Candidates_FilterSlice,
  getInterviewFeedbackSlice,
} from "../Slice/RecruitmentSlice";

import {
  RECRUITMENT_FILTER_URL,
  INTERVIEW_URL,
  RECRUITMENT_PROCESS_FROM_POOL_URL,
  RECRUITMENT_POST_IMPORT_URL,
  CANDIDATE_LIST__IMPORT_DATA_URL,
  CANDIDATE_IMPORT_FORMAT_URL,
  RECRUITMENT_DELETE_IMPORT_DATA_URL,
  RECRUITMENT_CANDIDATE_ALL_FILTER_DATA_URL,
  RECRUITMENT_CANDIDATE_JOB_STATUS_URL,
  RECRUITMENT_ALL_STATUS,
  RECRUITMENT_CALL_LOGS_URL,
  IMPORT_SETTINGS_COMPANY_DATA_URL,
  RECRUITMENT_EXPORT_URL,
  RECRUITMENT_CANDIDATE_SUGGESTION,
  RECRUITMENT_INTERVIEW_FEEDBACK_URL,
} from "../apipath";
import AxiosApi from "../api";
import { message } from "antd";

const api = new AxiosApi();

export const getRecruitmentsFilterAction = (data) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_FILTER_URL + data.current_page,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(recruitment_FilterSlice(data));
          } else {
            dispatch(recruitment_FilterSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

export const postInterViewAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      INTERVIEW_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            dispatch(putInterviewSlice(data));
            message.success(data.message, 3);
          } else {
            dispatch(recruitment_FilterSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const getInterViewAction = (id, callback) => {
  return (dispatch) => {
    api.invoke(INTERVIEW_URL + "/" + id, "get", (data, success, statusCode) => {
      if (success) {
        callback(false);
        if (statusCode === 200) {
          callback(true);
          dispatch(getInterviewDetailsSlice(data));
        } else {
          dispatch(recruitment_FilterSlice({ result: [] }));
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};

export const putInterViewAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      INTERVIEW_URL + "/" + val.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            dispatch(getInterViewAction(val.id));
            // dispatch(getInterviewDetailsSlice(data));
            message.success(data.message, 3);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

//for assigning candidate

export const postAssignedEmployeeAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_PROCESS_FROM_POOL_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            dispatch(getRecruitmentsFilterAction({ current_page: 1 }));
            message.success(data.message, 3);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

export const postAssignedEmployeeSuggestionAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_PROCESS_FROM_POOL_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            // dispatch(getRecruitmentsSuggestedCandidatesFilterAction({ jobId: data.jobId }));
            message.success(data.message, 3);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

//fetch candidate assigned to recruitment

export const getCandidateAssignedToRecruitmentByIdAction = (id) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_PROCESS_FROM_POOL_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200)
            dispatch(getCandidateAssignedToRecruitmentByIdSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//for posting the data

export const postRecruitmentImportCanidateAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_POST_IMPORT_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            dispatch(getImportCandidatesAction());
            message.success(data.message, 3);
          } else {
            dispatch(recruitment_FilterSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

//new
export const getImportCandidatesAction = () => {
  return (dispatch) => {
    api.invoke(
      CANDIDATE_LIST__IMPORT_DATA_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            // window.open(data.result)
            dispatch(getImportCandidatesSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      }
      // data
    );
  };
};

//download excel sheet

export const getCandidatesExcelFormatAction = () => {
  return (dispatch) => {
    api.invoke(
      CANDIDATE_IMPORT_FORMAT_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            window.open(data.link);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      }
    );
  };
};

//to delete a particular candidate in recruitment

export const getRecruitmentDeleteImportCandidateData = (id) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_DELETE_IMPORT_DATA_URL + "/" + "candidate" + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getImportCandidatesAction());
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//to delete all candidates
export const getRecruitmentDeleteAllImportedCandidatesData = () => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_DELETE_IMPORT_DATA_URL + "/" + "candidate",
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getImportCandidatesAction());
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//get filter data by id candidates data

export const getRecruitmentsAllCandidatesFilterAction = (val) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_CANDIDATE_ALL_FILTER_DATA_URL +
        "/" +
        val.id.id +
        "?page=" +
        val.current_page,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(recruitment_All_Candidates_FilterSlice(data));
          } else {
            dispatch(recruitment_All_Candidates_FilterSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

//fetch the job status by id

export const getRecruitmentsCandidatesJobStatusAction = (id) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_CANDIDATE_JOB_STATUS_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getRecruitmentsCandidatesJobStatusSlice(data));
          } else {
            // dispatch(recruitment_FilterSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      id
    );
  };
};

//fetch all status

export const getRecruitmentAllStatusAction = () => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_ALL_STATUS,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getRecruitmentAllStatusSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      }
      // data
    );
  };
};

//put candidate action
export const putRecruitmentCandidateStatusAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_PROCESS_FROM_POOL_URL + "/" + val.id,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            dispatch(getRecruitmentAllStatusAction());
            message.success(data.message, 3);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

//delete candidates from pool
export const deleteCandidatesFroPoolAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_PROCESS_FROM_POOL_URL + "/" + val.id,
      "delete",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(
              getRecruitmentsAllCandidatesFilterAction({
                id: val.filterId,
                current_page: 1,
              })
            );
            callback(true);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//Recruitment call history

export const getRecruitmentCallLogsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_CALL_LOGS_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getRecruitmentCallLogSlice(data));
          else {
            message.destroy();
            // message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//for saving the candidate data

export const postSettingsCandidateImportAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      IMPORT_SETTINGS_COMPANY_DATA_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getRecruitmentsFilterAction({ current_page: 1 }));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

//for recruitment export
export const exportRecruitmentDetaisAction = (data) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_EXPORT_URL + "/" + data.jobId,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            window.open(data.result);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      },
      data
    );
  };
};

//recruitment candidate suggestion

export const getRecruitmentsSuggestedCandidatesFilterAction = (data) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_CANDIDATE_SUGGESTION,
      // + data.current_page
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(recruitment_Suggested_Candidates_FilterSlice(data));
          } else {
            dispatch(
              recruitment_Suggested_Candidates_FilterSlice({ result: [] })
            );
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

//recruitment details interview feedback

export const getInterviewFeedbackction = (id) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_INTERVIEW_FEEDBACK_URL +
        "/" +
        id.JobId +
        "/" +
        id.candidateId,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getInterviewFeedbackSlice(data));
          } else {
            // dispatch(getInterviewFeedbackSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
