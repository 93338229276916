import { Button, Col, Form, Input, Modal, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import styles from "../../Screens/AddCandidatesComp/AddCandidatesComp.module.css";
import commonCssStyles from "../../UI/CommonCss.module.css";
function ResetPasswordModal(props) {
  const [form] = useForm();
  function passwordResetHandle(data) {
    const val = {
      password: data.ConfirmPassword,
      email: props.profile.email,
    };
    props.passwordResetActionHandle(val, (success) => {
      if (success) {
        props.onCloseModal();
        localStorage.removeItem("token");
        window.location.href = "/";
      }
    });
  }
  return (
    <>
      <Modal
        open={props.onOpenModal}
        // onCancel={props.onCloseModal}
        width={780}
        title={
          <>
            <h3 className={styles.summaryInfoMainText}>Reset Password</h3>
          </>
        }
        footer={false}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(val) => passwordResetHandle(val)}
        >
          <Row gutter={19}>
            <Col md={24}>
              <Form.Item
                name="Password"
                label="Enter Password"
                rules={[
                  {
                    required: true,
                    message: "Password Required",
                  },
                  // {
                  //   pattern:
                  //     /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[-\\#\\@\\$\\.\\%\\&\\*\\!])(?=.*[a-zA-Z]).{8,16}$/,
                  //   message:
                  //     "Password must contain at least one lowercase letter, uppercase letter, number, and special character",
                  // },
                ]}
              >
                <Input.Password placeholder="Enter Password" />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                name="ConfirmPassword"
                label="Confirm Password"
                dependencies={["Password"]}
                rules={[
                  {
                    required: true,
                    message: "Confirm Password Required",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("Password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two password that you entered does not match"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Row
                justify="end"
                style={{ marginTop: "20px", position: "relative", gap: "10px" }}
              >
                <Col>
                  <Button
                    className={commonCssStyles.buttonSettingsSecondary}
                    onClick={() => {
                      props.onCloseModal();
                    }}
                    style={{
                      width: "150px",
                      height: "48px",
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    className={commonCssStyles.buttonSettingsPrimary}
                    htmlType="button"
                    onClick={() => {
                      form.submit();
                    }}
                    style={{
                      width: "150px",
                      height: "48px",
                    }}
                    type="primary"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
export default ResetPasswordModal;
