import {
  Col,
  Drawer,
  Form,
  Image,
  Row,
  message,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Switch,
  Button,
  Card,
  Tag,
} from "antd";
import moment from "moment";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import commonStyles from "../../../UI/CommonCss.module.css";
import {
  BackButtonIcon,
  CloseIcon,
  MinusIcons,
  PlusForButtonIcon,
  UploadIcon,
} from "../../../IconsComp/Icons";
import pdf from "../../../../Assets/Image/pdf.png";
import word from "../../../../Assets/Image/word.png";
import PhoneInput from "react-phone-number-input";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import { connect } from "react-redux";
import { useEffect } from "react";
import { getCountriesAction } from "../../../../Reusable/Store/Action/Master/MasterCountryAction";
import {
  getCountriesExternalAction,
  postExternalCandidatesAction,
  getVerifyCandidatesEmailAction,
  getExternalJobDetailAction,
} from "../../../../Reusable/Store/Action/PublicRequirementAction";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

const tagRenderForSkills = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

function ExternalJobApplyPage(props) {
  const [form] = Form.useForm();
  const [dragActive, setDragActive] = useState(false);
  const [filesData, setFilesData] = useState();
  const [checkedEmailToCandidate, setCheckedEmailToCandidate] = useState(false);
  const [whatsAppChecked, setWhatsappChecked] = useState(false);
  const [checkedSpecialAbilities, setCheckedSpecialAbilities] = useState(false);
  const [checkedCurrentAddress, setCheckedCurrentAddress] = useState(false);
  const [fileType, setFileType] = useState("");
  const [fileRequired, setFileRequired] = useState(false);
  const [phoneData, setPhoneData] = useState(null);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [statePermanents, setStatePermanents] = useState([]);
  const [cityPermanent, setCityPermanent] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isPersuing, setIsPersuing] = useState(false);
  const [checkedCurrentEducation, setCheckedCurrentEducation] = useState(false);
  const [specialAbilitiesData, setSpecialAbilitiesData] = useState(false);
  const [quickApply, setQuickApply] = useState(false);

  const [candidateEmailCheck, setCandidateEmailCheck] = useState(false);
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(false);
  const [backButtonIcon, setBackButtonIcon] = useState(false);

  const navigate = useNavigate();

  let btnref = useRef(null);

  const location1 = useLocation();

  useEffect(() => {
    if (
      location1 &&
      location1.state &&
      location1.state.commingFrom &&
      location1.state.commingFrom === "list page"
    ) {
      setBackButtonIcon(true);
    } else {
      setBackButtonIcon(false);
    }
  }, [props.openModal]);

  useEffect(() => {
    props.getCountriesHandler({ type: "country" }, (data, type) => {
      setCountries(data);
    });
    form.setFieldsValue({
      country: "India",
      permanentcountry: "India",
    });
    props.getCountriesHandler({ id: 101, type: "state" }, (data, type) => {
      setStates(data);
      setStatePermanents(data);
    });
    //101 India
  }, []);

  useEffect(() => {
    props.getPublicJobDetailsHandler(
      location1 &&
        location1.state &&
        location1.state.trackingCode &&
        location1.state.trackingCode
    );
  }, []);

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const onChangeCountry = (val, data) => {
    props.getCountriesHandler({ id: data.id, type: "state" }, (data, type) => {
      setStates(data);
    });
    form.setFieldsValue({
      city: initvalues.city,
      state: initvalues.state,
    });
    setCity([]);
  };

  const onChangeCountryPermanent = (val, data) => {
    props.getCountriesHandler({ id: data.id, type: "state" }, (data, type) => {
      setStatePermanents(data);
    });
    form.setFieldsValue({
      permanentcity: initvalues.permanentcity,
      permanentstate: initvalues.permanentstate,
    });
    setCityPermanent([]);
  };

  const onChangeState = (value, data) => {
    props.getCountriesHandler({ id: data.id, type: "city" }, (data, type) => {
      setCity(data);
    });
    form.setFieldsValue({
      city: initvalues.city,
    });
  };

  const onChangeStatePermanent = (value, data) => {
    props.getCountriesHandler({ id: data.id, type: "city" }, (data, type) => {
      setCityPermanent(data);
    });
    form.setFieldsValue({
      permanentcity: initvalues.permanentcity,
    });
  };

  const isPersuingHandler = () => {
    setIsPersuing(false);
    setTimeout(() => {
      if (
        form.getFieldValue("educationInfo") &&
        form
          .getFieldValue("educationInfo")
          .find((data) => data && data.isPersuing === true)
      ) {
        setIsPersuing(true);
      }
    }, 300);
  };

  const initvalues = {
    whatsApp: "",
    permanentlocation: "",
    permanentaddress: "",
    permanentcountry: "",
    permanentstate: "",
    permanentcity: "",
    permanentpincode: "",
    specialAbilityComments: "",
    referenceNumber: "",
    referenceEmail: "",
    referenceName: "",
    summary: "",
    totalExperienceMonths: "",
    to: undefined,
    from: undefined,
    designation: "",
    companyName: "",
    city: "",
    state: "",
    permanentstate: "",
    permanentcity: "",
    educationInfo: [],
    employeeInfo: [],
    portfolio: [],
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleChange = (info) => {
    setFileRequired(false);
    if (info.target.files[0].size >= 2000000) {
      message.warning("This file should be less than 2 MB", 3);
      return;
    }
    setFilesData(info.target.files[0]);
    setFileType(info.target.files[0].type);
  };
  const onFinish = (values) => {
    const {
      name,
      email,
      phone,
      whatsApp,
      gender,
      dob,
      location,
      country,
      address,
      state,
      city,
      pincode,
      specialAbilityComments,
      leadSource,
      // recruitmentStatus,
      permanentaddress,
      permanentcity,
      permanentcountry,
      permanentlocation,
      permanentpincode,
      permanentstate,
      // communicationManagerId,
      currentSalary,
      totalExperienceMonths,
      relavantExperienceMonths,
      portfolio,
    } = values;

    const educationInfo =
      values.educationInfo &&
      values.educationInfo.map((x) => {
        return {
          ...x,
          isPersuing: x.isPersuing ? 1 : 0,
          from:
            x.from === undefined ? undefined : moment(x.from).format("YYYY"),
          to: x.to === undefined ? undefined : moment(x.to).format("YYYY"),
        };
      });
    const employeeInfo =
      values.employeeInfo &&
      values.employeeInfo.map((x) => {
        return {
          ...x,
          isCurrent: x.isCurrent ? 1 : 0,
          from:
            x.from === undefined
              ? undefined
              : moment(x.from).format("YYYY-MM-DD"),
          to:
            x.to === undefined ? undefined : moment(x.to).format("YYYY-MM-DD"),
        };
      });
    const currentSameAsPermanentAdrress = [
      {
        type: "current",
        location: location,
        country: country,
        address: address,
        state: state,
        city: city,
        pincode: pincode,
      },
      {
        type: "permanent",
        location: location,
        country: country,
        address: address,
        state: state,
        city: city,
        pincode: pincode,
      },
    ];
    const permanentAdrressIsDifferent = [
      {
        type: "current",
        location: location,
        country: country,
        address: address,
        state: state,
        city: city,
        pincode: pincode,
      },
      {
        type: "permanent",
        location: permanentlocation,
        country: permanentcountry,
        address: permanentaddress,
        state: permanentstate,
        city: permanentcity,
        pincode: permanentpincode,
      },
    ];
    const tags = values.tags;
    const data = {
      totalExperienceMonths: totalExperienceMonths ? totalExperienceMonths : "",
      relavantExperienceMonths: relavantExperienceMonths
        ? relavantExperienceMonths
        : "",
      educationInfo,
      employeeInfo,
      tags: tags ? tags : null,
      resume: filesData,
      name,
      portfolio,
      email,
      phone,
      currentSalary,
      // sendMail: checkedEmailToCandidate ? 1 : 0,
      whatsApp: whatsAppChecked ? phone : whatsApp,
      gender,
      dob:
        dob === undefined || dob === "Invalid date"
          ? undefined
          : moment(dob).format("YYYY-MM-DD"),
      // communicationManagerId,
      specialAbility: checkedSpecialAbilities ? 1 : 0,
      specialAbilityComments,
      leadSource,
      addressCheck: checkedCurrentAddress ? 1 : 0,
      // recruitmentStatus,
      addressInfo: checkedCurrentAddress
        ? currentSameAsPermanentAdrress
        : permanentAdrressIsDifferent,
      trackingCode: location1.state.trackingCode,
    };
    props.postAddCandidateHandler(data, (success) => {
      if (success) {
        navigate(`/jobs/jobdetail/${location1.state.trackingCode}`, {
          state: {
            showSuccessMessage: "true",
            hideAppluBtn: "true",
            commingFrom: location1.state.commingFrom,
            BackButtonIcons: location1.state.BackButtonIcons,
          },
        });
        setFilesData("");
        form.resetFields();
        props.closeModal();
        props.hideApplyBtn();
      }
    });
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFilesData(e.dataTransfer.files[0]);
      setFileType(e.dataTransfer.files[0].type);
    }
  };

  return (
    <>
      {/* //demo  */}
      <Row>
        <Col xs={24}>
      <Row justify="center" style={{ paddingTop: "30px" }}>
        <Col xs={23} sm={23} md={23} lg={12} 
        >
          {/* <div
            className={`${commonStyles.externApply}`}
           
          > */}
            <Row  style={{
              height: "60px",
              width: "auto",
              backgroundColor: "#ffffff",
              boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.15)",
              // boxShadow: "5px 10px red",
              marginBottom:"1px",
            }}
            >
              <Col>
                <p style={{ margin: "10px auto 0px 20px" }}>
                  {!backButtonIcon ? (
                    <>
                      <Link
                        to={`/jobs/jobdetail/${location1.state.trackingCode}`}
                        state={{
                          commingFrom: location1.state.commingFrom,
                          BackButtonIcons: location1.state.BackButtonIcons,
                        }}
                      >
                        <BackButtonIcon color={"var(--icons-color)"} />
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        to={`/jobs/jobdetail/${location1.state.trackingCode}`}
                        state={{
                          commingFrom: location1.state.commingFrom,
                          BackButtonIcons: location1.state.BackButtonIcons,
                        }}
                      >
                        <BackButtonIcon color={"var(--icons-color)"} />
                      </Link>
                    </>
                  )}
                </p>
              </Col>
              <Col>
                <h1 style={{ margin: "4px auto 10px 20px" }}>Apply</h1>
              </Col>
            </Row>
          {/* </div> */}
        </Col>
      </Row>
      </Col>
      <Col xs={24}>
      <Row style={{ marginBottom:"30px"}} justify="center">
        <Col xs={23} sm={23} md={23} lg={12}>
          <div
            style={{
              overflow: "scroll",
              height: "90vh",
             
              backgroundColor: "#ffffff",
            }}
          >
            <Form
              onDragEnter={handleDrag}
              initialValues={initvalues}
              form={form}
              layout="vertical"
              onFinish={onFinish}
              style={{ marginBottom: "100px" }}
              scrollToFirstError={{
                behavior: "smooth",
                block: "center",
                inline: "center",
              }}
            >
              <Row style={{ 
                marginTop: "20px",
                 marginLeft: "20px" }}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <p
                    style={{
                      fontSize: "14px",
                      // margin: "40px 0px 10px 0px",
                      fontFamily: "Helvetica,ProductSans",
                    }}
                  >
                    <b>
                      (we will not be sending any marketing/promotional
                      messages/notifications)
                    </b>
                  </p>
                </Col>
              </Row>
              <Row
                style={{ gap: "10px", margin: "0px 20px 10px 20px" }}
                align="middle"
              >
                <Col>
                  <p
                    className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                  >
                    Apply Quickly
                  </p>
                </Col>
                <Col>
                  <Form.Item style={{ margin: "0px", padding: "0px" }}>
                    <Switch
                      onChange={(val) => {
                        // setSpecialAbilitiesData(val);
                        setQuickApply(val);
                        // form.setFieldsValue({
                        //   specialAbilityComments:
                        //     initvalues.specialAbilityComments,
                        // });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {!quickApply ? (
                <>
                  <Row className={commonStyles.modalRowStyling}>
                    <Col flex={1}>
                      <Row>
                        <Col flex={1}>
                          <Form.Item
                            name="resume"
                            label={
                              <p
                                className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                              >
                                Resume
                              </p>
                            }
                            style={{ borderRadius: "1px" }}
                          >
                            {filesData || dragActive ? (
                              <div
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                                style={{
                                  backgroundColor: "var(--secondaryColor)",
                                  height: "155px",
                                  borderRadius: "6px",
                                }}
                              >
                                <Image
                                  onClick={(e) => {
                                    e.preventDefault();
                                    btnref.click();
                                    return false;
                                  }}
                                  preview={false}
                                  src={
                                    fileType === "application/pdf" ? pdf : word
                                  }
                                  style={{
                                    height: "118px",
                                    width: "100px",
                                    borderRadius: "6px",
                                    position: "relative",
                                    display: "block",
                                    margin: "16px auto 21px 21px",
                                  }}
                                />
                                <span
                                  onClick={() => {
                                    setFilesData("");
                                    setFileRequired(true);
                                  }}
                                  style={{
                                    position: "absolute",
                                    paddingTop: "5px",
                                    paddingLeft: "1px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <CloseIcon color={"var(--headings-color)"} />
                                </span>
                              </div>
                            ) : (
                              <>
                                <input
                                  hidden
                                  accept="application/pdf,.docx"
                                  type="file"
                                  name="resume"
                                  onChange={handleChange}
                                  ref={(input) => {
                                    btnref = input;
                                  }}
                                />
                                <div
                                  onClick={(e) => {
                                    e.preventDefault();
                                    btnref.click();
                                    return false;
                                  }}
                                  style={{
                                    textAlign: "center",
                                    height: "155px",
                                    borderRadius: "6px",
                                    backgroundColor: "var(--input-color)",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <Row
                                    justify="center"
                                    style={{ paddingTop: "31px" }}
                                  >
                                    <Col>
                                      <UploadIcon
                                        color={"var(--body-text-color)"}
                                      />
                                    </Col>
                                  </Row>
                                  <div>
                                    <p
                                      style={{
                                        fontWeight: 400,
                                        textAlign: "center",
                                        marginTop: "10px",
                                        fontFamily: "Helvetica,ProductSans",
                                        fontStyle: "normal",
                                        fontSize: "16px",
                                        lineHeight: "19px",
                                        color: "var(--body-text-color)",
                                      }}
                                    >
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      Upload resume &nbsp;&nbsp;&nbsp;
                                      &nbsp;&nbsp;&nbsp; or &nbsp;&nbsp;&nbsp;
                                      Drag and drop resume
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </Form.Item>

                          <Form.Item
                            name="name"
                            label="Full Name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your full name",
                              },
                              // {
                              //   pattern: /^[A-Z a-z]+$/,
                              //   message: "Enter only alphabets",
                              // },
                            ]}
                          >
                            <Input placeholder="Enter full name" />
                          </Form.Item>

                          <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your email",
                              },
                              {
                                type: "email",
                                message: "Please enter a valid email",
                              },
                            ]}
                            onBlur={(val) => {
                              if (val.target.value.length > 0) {
                                const dataCheck = {
                                  trackingCode: location1.state.trackingCode,
                                  type: "email",
                                  Values: val.target.value,
                                };
                                props.checkEmailHandler(
                                  dataCheck,
                                  (success) => {
                                    if (success) {
                                      setCandidateEmailCheck(false);
                                    } else {
                                      setCandidateEmailCheck(true);
                                    }
                                  }
                                );
                              }
                            }}
                          >
                            <Input placeholder="Enter email address" />
                          </Form.Item>
                          {candidateEmailCheck && (
                            <p style={{ color: "#F83A3A" }}>
                              (Candidate already been assigned to this job)
                            </p>
                          )}

                          <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[
                              {
                                required: true,
                                message: "Please select gender",
                              },
                            ]}
                          >
                            <Select placeholder="Select gender">
                              <Select.Option value="Male">Male</Select.Option>
                              <Select.Option value="Female">
                                Female
                              </Select.Option>
                              <Select.Option value="Other">Other</Select.Option>
                            </Select>
                          </Form.Item>

                          <Form.Item
                            label="DOB"
                            name="dob"
                            rules={[
                              {
                                required: false,
                                message: "Please enter date of birth",
                              },
                            ]}
                          >
                            <DatePicker
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                              disabledDate={(current) =>
                                current.isAfter(moment().subtract(16, "year"))
                              }
                              defaultPickerValue={moment().subtract(16, "year")}
                              placeholder="Enter date of birth"
                              format={dataFormate}
                              className={commonStyles.datePickerBgColor}
                              style={{
                                width: "100%",
                              }}
                            />
                          </Form.Item>

                          <Form.Item
                            className={`phoneInputStroke`}
                            name="phone"
                            label="Contact Number"
                            rules={[
                              {
                                required: true,
                                message: "Please enter contact number",
                              },
                            ]}
                            onBlur={(vals) => {
                              if (vals.target.value.length > 0) {
                                const dataChecks = {
                                  trackingCode: location1.state.trackingCode,
                                  type: "phone",
                                  Values: vals.target.value.replace(/\s+/g, ""),
                                };
                                props.checkEmailHandler(
                                  dataChecks,
                                  (success) => {
                                    if (success) {
                                      setCheckPhoneNumber(false);
                                    } else {
                                      setCheckPhoneNumber(true);
                                    }
                                  }
                                );
                              }
                            }}
                          >
                            <PhoneInput
                              onChange={(value) => {
                                setPhoneData(value);
                              }}
                              placeholder="Enter contact number"
                              className={`ant-input ant-cust-inputs`}
                              defaultCountry="IN"
                            />
                          </Form.Item>
                          {checkPhoneNumber && (
                            <p style={{ color: "#F83A3A" }}>
                              (Candidate already been assigned to this job)
                            </p>
                          )}
                          <Row style={{ gap: "5px" }} align="middle">
                            <Col xs={3} sm={3} md={3} lg={3} xl={2} xxl={2}>
                              <Form.Item
                                name="whatsAppCheck"
                                style={{ margin: "0px", padding: "0px" }}
                              >
                                <Checkbox
                                  className="intellcheckbox"
                                  onChange={(val) => {
                                    setWhatsappChecked(val.target.checked);

                                    form.setFieldsValue({
                                      whatsApp: initvalues.whatsApp,
                                    });
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={18}
                              sm={18}
                              md={18}
                              lg={18}
                              xl={18}
                              xxl={18}
                            >
                              <p
                                className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                              >
                                Whatsapp Number is same as Contact Number
                              </p>
                            </Col>
                          </Row>

                          {!whatsAppChecked && (
                            <Row>
                              <Col className="modelcol" flex={1}>
                                <Form.Item
                                  className={`phoneInputStroke`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter whatsapp number",
                                    },
                                  ]}
                                  name="whatsApp"
                                  label="Whatsapp Number"
                                >
                                  <PhoneInput
                                    placeholder="Enter whatsapp number"
                                    className="ant-input ant-cust-inputs"
                                    defaultCountry="IN"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          )}

                          <Row className={commonStyles.sectionEndGap}>
                            <Col>
                              <h1 className={commonStyles.HeadingFormDesign}>
                                Current Address
                              </h1>
                            </Col>
                          </Row>

                          <Form.Item
                            name="location"
                            label="Apartment, flat/house number"
                          >
                            <Input
                              style={{ width: "100%" }}
                              placeholder="Enter apartment, flat/house number"
                            />
                          </Form.Item>

                          <Form.Item name="address" label="Address">
                            <Input placeholder="Enter address" />
                          </Form.Item>

                          <Form.Item
                            label="Country"
                            name="country"
                            rules={[
                              {
                                required: true,
                                message: "Please select country",
                              },
                            ]}
                          >
                            <Select
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                              showSearch
                              onChange={onChangeCountry}
                              placeholder="Select country"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {countries &&
                                countries.length > 0 &&
                                countries.map((data, index) => {
                                  return (
                                    <Select.Option
                                      key={index}
                                      value={data.name}
                                      id={data.id}
                                    >
                                      {data.name}
                                    </Select.Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            label="State"
                            name="state"
                            rules={[
                              {
                                required: true,
                                message: "Please select state",
                              },
                            ]}
                          >
                            {states && states.length === 0 ? (
                              <Input placeholder="Enter state" />
                            ) : (
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentElement
                                }
                                onChange={onChangeState}
                                showSearch
                                placeholder="Select state"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {states &&
                                  states.map((data, index) => {
                                    return (
                                      <Select.Option
                                        key={index}
                                        value={data.name}
                                        id={data.id}
                                      >
                                        {data.name}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            )}
                          </Form.Item>

                          <Form.Item
                            label="City"
                            name="city"
                            rules={[
                              {
                                required: true,
                                message: "Please select city",
                              },
                            ]}
                          >
                            {city.length === 0 ? (
                              <Input placeholder="Enter city" />
                            ) : (
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentElement
                                }
                                showSearch
                                placeholder="Select city"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {city &&
                                  city.map((data, index) => {
                                    return (
                                      <Select.Option
                                        value={data.name}
                                        key={index}
                                      >
                                        {data.name}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            )}
                          </Form.Item>

                          <Form.Item label="Pincode" name="pincode">
                            <Input placeholder="Enter pincode" />
                          </Form.Item>

                          <Row style={{ gap: "5px" }} align="middle">
                            <Col xs={5} sm={4} md={2} lg={2} xl={2} xxl={2}>
                              <Form.Item
                                style={{ margin: "0px", padding: "0px" }}
                              >
                                <Switch
                                  onChange={(val) => {
                                    setCheckedCurrentAddress(val);
                                    form.setFieldsValue({
                                      permanentlocation:
                                        initvalues.permanentlocation,
                                      permanentaddress:
                                        initvalues.permanentaddress,
                                      permanentcountry:
                                        initvalues.permanentcountry,
                                      permanentstate: initvalues.permanentstate,
                                      permanentcity: initvalues.permanentcity,
                                      permanentpincode:
                                        initvalues.permanentpincode,
                                    });
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={17}
                              sm={18}
                              md={18}
                              lg={18}
                              xl={18}
                              xxl={18}
                            >
                              <p
                                className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                              >
                                Current Address is same as Permanent Address
                              </p>
                            </Col>
                          </Row>

                          {checkedCurrentAddress ? (
                            <></>
                          ) : (
                            <>
                              <Row className={commonStyles.sectionEndGap}>
                                <Col>
                                  <h1
                                    className={commonStyles.HeadingFormDesign}
                                  >
                                    Permanent Address
                                  </h1>
                                </Col>
                              </Row>

                              <Form.Item
                                name="permanentlocation"
                                label="Apartment, flat/house number"
                              >
                                <Input
                                  style={{ width: "100%" }}
                                  placeholder="Enter apartment, flat/house number"
                                />
                              </Form.Item>

                              <Form.Item
                                name="permanentaddress"
                                label="Address"
                              >
                                <Input placeholder="Enter permanent address" />
                              </Form.Item>

                              <Form.Item
                                label="Country"
                                name="permanentcountry"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select country",
                                  },
                                ]}
                              >
                                <Select
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                  showSearch
                                  onChange={onChangeCountryPermanent}
                                  placeholder="Select country"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {countries &&
                                    countries.length > 0 &&
                                    countries.map((data, index) => {
                                      return (
                                        <Select.Option
                                          key={index}
                                          value={data.name}
                                          id={data.id}
                                        >
                                          {data.name}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>

                              <Form.Item
                                label="State"
                                name="permanentstate"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select state",
                                  },
                                ]}
                              >
                                {statePermanents &&
                                statePermanents.length === 0 ? (
                                  <Input placeholder="Enter state" />
                                ) : (
                                  <Select
                                    getPopupContainer={(trigger) =>
                                      trigger.parentElement
                                    }
                                    onChange={onChangeStatePermanent}
                                    showSearch
                                    placeholder="Select state"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {statePermanents &&
                                      statePermanents.map((data, index) => {
                                        return (
                                          <Select.Option
                                            key={index}
                                            value={data.name}
                                            id={data.id}
                                          >
                                            {data.name}
                                          </Select.Option>
                                        );
                                      })}
                                  </Select>
                                )}
                              </Form.Item>

                              <Form.Item
                                label="City"
                                name="permanentcity"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select city",
                                  },
                                ]}
                              >
                                {cityPermanent.length === 0 ? (
                                  <Input placeholder="Enter permanent city" />
                                ) : (
                                  <Select
                                    getPopupContainer={(trigger) =>
                                      trigger.parentElement
                                    }
                                    showSearch
                                    placeholder="Select city"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {cityPermanent &&
                                      cityPermanent.map((data, index) => {
                                        return (
                                          <Select.Option
                                            value={data.name}
                                            key={index}
                                          >
                                            {data.name}
                                          </Select.Option>
                                        );
                                      })}
                                  </Select>
                                )}
                              </Form.Item>

                              <Form.Item
                                label="Pincode"
                                name="permanentpincode"
                              >
                                <Input placeholder="Enter pincode" />
                              </Form.Item>
                            </>
                          )}
                          <Row className={commonStyles.sectionEndGap}>
                            <Col>
                              <div className="ant-col ant-form-item-label">
                                <label /* className="ant-form-item-required" */>
                                  Portfolio/ Work sample
                                </label>
                              </div>
                            </Col>
                          </Row>
                          <Row style={{ marginBottom: "20px" }}>
                            <Col
                              className="modelcol"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                            >
                              <Form.List name="portfolio">
                                {(fields, { add, remove }) => (
                                  <>
                                    {/* {fields.length > 0 && (
                              <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item-required">
                                  Portfolio / Work sample
                                </label>
                              </div>
                            )} */}
                                    {fields.map((field, key) => (
                                      <div>
                                        <Row>
                                          <Col xs={24} sm={24} md={24} lg={24}>
                                            <AddLink
                                              field={field}
                                              dataFormate={dataFormate}
                                            />
                                          </Col>

                                          <Col
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            style={{ marginBottom: "10px" }}
                                          >
                                            <p
                                              style={{
                                                marginTop: "0px",
                                                marginBottom: "8px",
                                                float: "right",
                                                color: "#F83A3A",
                                              }}
                                              className={`${commonStyles.marginBottomRemove} ${commonStyles.removeLocation}`}
                                            >
                                              Remove Link
                                            </p>
                                            <Button
                                              className={
                                                commonStyles.removeLocationbtn
                                              }
                                              type="primary"
                                              size="small"
                                              shape="circle"
                                              style={{
                                                marginTop: "3px",
                                                marginRight: "7px",
                                                float: "right",
                                                backgroundColor: "#F83A3A",
                                                transform: "translateY(-4px)",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  transform: "translateY(-4px)",
                                                }}
                                                onClick={() => remove(key)}
                                              >
                                                <MinusIcons
                                                  color={"var(--theme)"}
                                                />
                                              </div>
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    ))}
                                    <Button
                                      size="small"
                                      onClick={() => add()}
                                      type="primary"
                                      shape="circle"
                                      style={
                                        {
                                          // transform: "transformY(-20px)",
                                        }
                                      }
                                    >
                                      <div
                                        style={{ transform: "translateY(2px)" }}
                                      >
                                        <PlusForButtonIcon
                                          height={"19px"}
                                          width={"19px"}
                                          color={"var(--theme)"}
                                        />
                                      </div>
                                    </Button>{" "}
                                    <p
                                      style={{
                                        marginTop: "-27px",
                                        marginLeft: "40px",
                                      }}
                                      className={`${commonStyles.marginBottomRemove} ${commonStyles.addLocation}`}
                                    >
                                      Add Link
                                    </p>
                                  </>
                                )}
                              </Form.List>
                            </Col>
                          </Row>

                          <Row className={commonStyles.sectionEndGap}>
                            <Col className="modelcol" md={24}>
                              <Form.List name="educationInfo">
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map((field, key) => (
                                      <div>
                                        <Row>
                                          <Col flex={1}>
                                            <AddEducation
                                              onIsPersuing={isPersuingHandler}
                                              field={field}
                                              dataFormate={dataFormate}
                                              form={form}
                                            />
                                          </Col>

                                          <Col
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            style={{ marginBottom: "5px" }}
                                          >
                                            <p
                                              style={{
                                                marginTop: "2px",
                                                float: "right",
                                                color: "F83A3A",
                                              }}
                                              className={`${commonStyles.marginBottomRemove} ${commonStyles.removeLocation}`}
                                            >
                                              {" "}
                                              Remove Education
                                            </p>
                                            <Button
                                              className={
                                                commonStyles.removeLocationbtn
                                              }
                                              size="small"
                                              type="primary"
                                              shape="circle"
                                              style={{
                                                marginTop: "3px",
                                                marginRight: "7px",
                                                float: "right",
                                                backgroundColor: "#F83A3A",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  transform: "translateY(-4px)",
                                                }}
                                                onClick={() => {
                                                  remove(key);
                                                  if (key === 0) {
                                                    setCheckedCurrentEducation(
                                                      false
                                                    );
                                                  }
                                                }}
                                              >
                                                <MinusIcons
                                                  color={"var(--theme)"}
                                                />
                                              </div>
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    ))}

                                    <>
                                      <Button
                                        size="small"
                                        onClick={() => {
                                          add();
                                        }}
                                        type="primary"
                                        shape="circle"
                                      >
                                        <div
                                          style={{
                                            transform: "translateY(2px)",
                                          }}
                                        >
                                          <PlusForButtonIcon
                                            height={"19px"}
                                            width={"19px"}
                                            color={"var(--theme)"}
                                          />
                                        </div>
                                      </Button>{" "}
                                      <p
                                        className={`${commonStyles.marginBottomRemove} ${commonStyles.addLocation}`}
                                        style={{
                                          marginTop: "-27px",
                                          marginLeft: "40px",
                                        }}
                                      >
                                        {" "}
                                        Add Education
                                      </p>
                                    </>
                                  </>
                                )}
                              </Form.List>
                            </Col>
                          </Row>

                          {!isPersuing && (
                            <Row className={commonStyles.sectionEndGap}>
                              <Col className="modelcol" md={24}>
                                <Form.List name="employeeInfo">
                                  {(fields, { add, remove }) => (
                                    <>
                                      {fields.map((field, key) => (
                                        <div>
                                          <Row>
                                            <Col md={24}>
                                              <AddEmployee
                                                form={form}
                                                dataFormate={dataFormate}
                                                field={field}
                                              />
                                            </Col>

                                            <Col
                                              xs={24}
                                              sm={24}
                                              md={24}
                                              lg={24}
                                              style={{ marginBottom: "5px" }}
                                            >
                                              <p
                                                style={{
                                                  marginTop: "2px",
                                                  float: "right",
                                                  color: "#F83A3A",
                                                }}
                                                className={`${commonStyles.marginBottomRemove} ${commonStyles.removeLocation}`}
                                              >
                                                {" "}
                                                Remove Employer
                                              </p>
                                              <Button
                                                className={
                                                  commonStyles.removeLocationbtn
                                                }
                                                size="small"
                                                type="primary"
                                                shape="circle"
                                                style={{
                                                  marginTop: "3px",
                                                  marginRight: "7px",
                                                  float: "right",
                                                  backgroundColor: "red",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    transform:
                                                      "translateY(-4px)",
                                                  }}
                                                  onClick={() => {
                                                    remove(key);
                                                  }}
                                                >
                                                  <MinusIcons
                                                    color={"var(--theme)"}
                                                  />
                                                </div>
                                              </Button>{" "}
                                            </Col>
                                          </Row>
                                        </div>
                                      ))}
                                      <>
                                        <Button
                                          size="small"
                                          onClick={() => {
                                            add();
                                          }}
                                          type="primary"
                                          shape="circle"
                                        >
                                          <div
                                            style={{
                                              transform: "translateY(2px)",
                                            }}
                                          >
                                            <PlusForButtonIcon
                                              height={"19px"}
                                              width={"19px"}
                                              color={"var(--theme)"}
                                            />
                                          </div>{" "}
                                        </Button>{" "}
                                        <p
                                          style={{
                                            marginTop: "-27px",
                                            marginLeft: "40px",
                                          }}
                                          className={`${commonStyles.marginBottomRemove} ${commonStyles.addLocation}`}
                                        >
                                          Add Employer
                                        </p>
                                      </>
                                    </>
                                  )}
                                </Form.List>
                              </Col>
                            </Row>
                          )}
                          <Row className={commonStyles.sectionEndGap}>
                            <Col flex={1}>
                              <Form.Item
                                label="Total Experience in Years"
                                name={"totalExperienceMonths"}
                                rules={[
                                  {
                                    required: false,
                                    message: "Please enter total experience",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  placeholder="Enter total experience"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col flex={1}>
                              <Form.Item
                                label="Relevant Experience in Years"
                                name={"relavantExperienceMonths"}
                                rules={[
                                  {
                                    required: false,
                                    message: "Please enter relevant experience",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  placeholder="Enter relevant experience"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "0px" }}>
                            <Col flex={1}>
                              <Form.Item
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter current salary",
                                  },
                                ]}
                                label="Current Salary (CTC in Lakhs)"
                                name={"currentSalary"}
                              >
                                <Input
                                  type="number"
                                  placeholder="Enter current salary"
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row style={{ gap: "10px" }} align="middle">
                            <Col>
                              <Form.Item
                                style={{ margin: "0px", padding: "0px" }}
                              >
                                <Switch
                                  onChange={(val) => {
                                    setSpecialAbilitiesData(val);
                                    setCheckedSpecialAbilities(val);
                                    form.setFieldsValue({
                                      specialAbilityComments:
                                        initvalues.specialAbilityComments,
                                    });
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col>
                              <p
                                className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                              >
                                Special Abilities
                              </p>
                            </Col>
                          </Row>

                          {checkedSpecialAbilities && (
                            <Form.Item
                              name="specialAbilityComments"
                              label="Special Ability Comments"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Please enter special ability comments",
                                },
                              ]}
                            >
                              <TextArea
                                placeholder="Describe your special abilities"
                                rows={3}
                              />
                            </Form.Item>
                          )}

                          <Row>
                            <Col flex={1}>
                              <Form.Item
                                label="Key Skills/Tag"
                                name="tags"
                                rules={[
                                  {
                                    required: true,
                                    message: "Select skill tag",
                                  },
                                ]}
                              >
                                <Select
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                  // className={"candidateSelectHeight"}
                                  mode="multiple"
                                  allowClear
                                  style={{
                                    width: "100%",
                                  }}
                                  //   onKeyUp={(val) => {
                                  //     setFilterDatas({ skills: val.target.value });
                                  //   }}
                                  notFoundContent={null}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  placeholder="Select skills"
                                  tagRender={tagRenderForSkills}
                                >
                                  {
                                    //   filterDatas.skills &&
                                    //     filterDatas.skills !== " " &&
                                    //     filterDatas.skills.length > 0 &&
                                    props.publicRequirement_jobDeatail &&
                                      props.publicRequirement_jobDeatail
                                        .skills &&
                                      props.publicRequirement_jobDeatail.skills.map(
                                        (data, index) => {
                                          return (
                                            <Option key={index} value={data}>
                                              {data}
                                            </Option>
                                          );
                                        }
                                      )
                                  }
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>

                          {/* button */}
                          <Row justify="end" style={{ gap: "5px" }}>
                            <Col>
                              <Button
                                onClick={() => {
                                  setCandidateEmailCheck(false);
                                  form.resetFields();
                                  navigate(
                                    `/jobs/jobdetail/${location1.state.trackingCode}`,
                                    {
                                      state: {
                                        commingFrom:
                                          location1.state.commingFrom,
                                        BackButtonIcons:
                                          location1.state.BackButtonIcons,
                                      },
                                    }
                                  );
                                }}
                                style={{
                                  width: "150px",
                                  height: "48px",
                                }}
                                className={
                                  commonStyles.footerButtonAddCandidateDrawerSecondary
                                }
                              >
                                Cancel
                              </Button>
                            </Col>
                            {!candidateEmailCheck && (
                              <Col>
                                <Button
                                  onClick={() => {
                                    form.submit();
                                  }}
                                  style={{
                                    width: "150px",
                                    height: "48px",
                                  }}
                                  htmlType="button"
                                  className={
                                    commonStyles.footerButtonAddCandidateDrawerPrimary
                                  }
                                  type="primary"
                                >
                                  Apply
                                </Button>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              ) : (
                <Row style={{ margin: "0px 20px 10px 20px" }}>
                  <Col flex={1}>
                    <Row>
                      <Col flex={1}>
                        <Form.Item
                          name="resume"
                          label={
                            <p
                              className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                            >
                              Resume
                            </p>
                          }
                          style={{ borderRadius: "1px" }}
                        >
                          {filesData || dragActive ? (
                            <div
                              onDragEnter={handleDrag}
                              onDragLeave={handleDrag}
                              onDragOver={handleDrag}
                              onDrop={handleDrop}
                              style={{
                                backgroundColor: "var(--secondaryColor)",
                                height: "155px",
                                borderRadius: "6px",
                              }}
                            >
                              <Image
                                onClick={(e) => {
                                  e.preventDefault();
                                  btnref.click();
                                  return false;
                                }}
                                preview={false}
                                src={
                                  fileType === "application/pdf" ? pdf : word
                                }
                                style={{
                                  height: "118px",
                                  width: "100px",
                                  borderRadius: "6px",
                                  position: "relative",
                                  display: "block",
                                  margin: "16px auto 21px 21px",
                                }}
                              />
                              <span
                                onClick={() => {
                                  setFilesData("");
                                  setFileRequired(true);
                                }}
                                style={{
                                  position: "absolute",
                                  paddingTop: "5px",
                                  paddingLeft: "1px",
                                  cursor: "pointer",
                                }}
                              >
                                <CloseIcon color={"var(--headings-color)"} />
                              </span>
                            </div>
                          ) : (
                            <>
                              <input
                                hidden
                                accept="application/pdf,.docx"
                                type="file"
                                name="resume"
                                onChange={handleChange}
                                ref={(input) => {
                                  btnref = input;
                                }}
                              />
                              <div
                                onClick={(e) => {
                                  e.preventDefault();
                                  btnref.click();
                                  return false;
                                }}
                                style={{
                                  textAlign: "center",
                                  height: "155px",
                                  borderRadius: "6px",
                                  backgroundColor: "var(--input-color)",
                                  borderRadius: "5px",
                                }}
                              >
                                <Row
                                  justify="center"
                                  style={{ paddingTop: "31px" }}
                                >
                                  <Col>
                                    <UploadIcon
                                      color={"var(--body-text-color)"}
                                    />
                                  </Col>
                                </Row>
                                <div>
                                  <p
                                    style={{
                                      fontWeight: 400,
                                      textAlign: "center",
                                      marginTop: "10px",
                                      fontFamily: "Helvetica,ProductSans",
                                      fontStyle: "normal",
                                      fontSize: "16px",
                                      lineHeight: "19px",
                                      color: "var(--body-text-color)",
                                    }}
                                  >
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Upload
                                    resume &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                    or &nbsp;&nbsp;&nbsp; Drag and drop resume
                                  </p>
                                </div>
                              </div>
                            </>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col flex={1}>
                        <Form.Item
                          name="name"
                          label="Full Name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your full name",
                            },
                            // {
                            //   pattern: /^[A-Z a-z]+$/,
                            //   message: "Enter only alphabets",
                            // },
                          ]}
                        >
                          <Input placeholder="Enter full name" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col flex={1}>
                        <Form.Item
                          name="email"
                          label="Email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your email",
                            },
                            {
                              type: "email",
                              message: "Please enter a valid email",
                            },
                          ]}
                          onBlur={(val) => {
                            if (val.target.value.length > 0) {
                              const dataCheck = {
                                trackingCode: location1.state.trackingCode,
                                type: "email",
                                Values: val.target.value,
                              };
                              props.checkEmailHandler(dataCheck, (success) => {
                                if (success) {
                                  setCandidateEmailCheck(false);
                                } else {
                                  setCandidateEmailCheck(true);
                                }
                              });
                            }
                          }}
                        >
                          <Input placeholder="Enter email address" />
                        </Form.Item>
                        {candidateEmailCheck && (
                          <p style={{ color: "#F83A3A" }}>
                            (Candidate already been assigned to this job)
                          </p>
                        )}
                      </Col>
                    </Row>

                  

                    <Row>
                      <Col className="modelcol" flex={1}>
                        <Form.Item
                          className={`phoneInputStroke`}
                          name="phone"
                          label="Contact Number"
                          rules={[
                            {
                              required: true,
                              message: "Please enter contact number",
                            },
                          ]}
                          onBlur={(vals) => {
                            if (vals.target.value.length > 0) {
                              const dataChecks = {
                                trackingCode: location1.state.trackingCode,
                                type: "phone",
                                Values: vals.target.value.replace(/\s+/g, ""),
                              };
                              props.checkEmailHandler(dataChecks, (success) => {
                                if (success) {
                                  setCheckPhoneNumber(false);
                                } else {
                                  setCheckPhoneNumber(true);
                                }
                              });
                            }
                          }}
                        >
                          <PhoneInput
                            onChange={(value) => {
                              setPhoneData(value);
                            }}
                            placeholder="Enter contact number"
                            className={`ant-input ant-cust-inputs`}
                            defaultCountry="IN"
                          />
                        </Form.Item>
                        {checkPhoneNumber && (
                          <p style={{ color: "#F83A3A" }}>
                            (Candidate already been assigned to this job)
                          </p>
                        )}
                      </Col>
                    </Row>

                    <Row style={{ gap: "5px" }} align="middle">
                      <Col xs={3} sm={3} md={3} lg={3} xl={2} xxl={2}>
                        <Form.Item
                          name="whatsAppCheck"
                          style={{ margin: "0px", padding: "0px" }}
                        >
                          <Checkbox
                            className="intellcheckbox"
                            onChange={(val) => {
                              setWhatsappChecked(val.target.checked);

                              form.setFieldsValue({
                                whatsApp: initvalues.whatsApp,
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={18} sm={18} md={18} lg={18} xl={18} xxl={18}>
                        <p
                          className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                        >
                          Whatsapp Number is same as Contact Number
                        </p>
                      </Col>
                    </Row>

                    {!whatsAppChecked && (
                      <Row>
                        <Col className="modelcol" md={24}>
                          <Form.Item
                            className={`phoneInputStroke`}
                            rules={[
                              {
                                required: true,
                                message: "Please enter whatsapp number",
                              },
                            ]}
                            name="whatsApp"
                            label="Whatsapp Number"
                          >
                            <PhoneInput
                              placeholder="Enter whatsapp number"
                              className="ant-input ant-cust-inputs"
                              defaultCountry="IN"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}

                  

                    <Row style={{ marginTop: "0px" }}>
                      <Col flex={1}>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please enter current salary",
                            },
                          ]}
                          label="Current Salary (CTC in Lakhs)"
                          name={"currentSalary"}
                        >
                          <Input
                            type="number"
                            placeholder="Enter current salary"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col flex={1}>
                        <Form.Item
                          label="Key Skills/Tag"
                          name="tags"
                          rules={[
                            {
                              required: true,
                              message: "Select skill tag",
                            },
                          ]}
                        >
                          <Select
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            // className={"candidateSelectHeight"}
                            mode="multiple"
                            allowClear
                            style={{
                              width: "100%",
                            }}
                            //   onKeyUp={(val) => {
                            //     setFilterDatas({ skills: val.target.value });
                            //   }}
                            notFoundContent={null}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Select skills"
                            tagRender={tagRenderForSkills}
                          >
                            {
                              //   filterDatas.skills &&
                              //     filterDatas.skills !== " " &&
                              //     filterDatas.skills.length > 0 &&
                              props.publicRequirement_jobDeatail &&
                                props.publicRequirement_jobDeatail.skills &&
                                props.publicRequirement_jobDeatail.skills.map(
                                  (data, index) => {
                                    return (
                                      <Option key={index} value={data}>
                                        {data}
                                      </Option>
                                    );
                                  }
                                )
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row justify="end" style={{ gap: "5px" }}>
                      <Col>
                        <Button
                          onClick={() => {
                            setCandidateEmailCheck(false);
                            form.resetFields();
                            navigate(
                              `/jobs/jobdetail/${location1.state.trackingCode}`,
                              {
                                state: {
                                  commingFrom: location1.state.commingFrom,
                                  BackButtonIcons:
                                    location1.state.BackButtonIcons,
                                },
                              }
                            );
                          }}
                          style={{
                            width: "150px",
                            height: "48px",
                          }}
                          className={
                            commonStyles.footerButtonAddCandidateDrawerSecondary
                          }
                        >
                          Cancel
                        </Button>
                      </Col>
                      {!candidateEmailCheck && (
                        <Col>
                          <Button
                            onClick={() => {
                              form.submit();
                            }}
                            style={{
                              width: "150px",
                              height: "48px",
                            }}
                            htmlType="button"
                            className={
                              commonStyles.footerButtonAddCandidateDrawerPrimary
                            }
                            type="primary"
                          >
                            Apply
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        </Col>
      </Row>
      </Col>
      </Row>
      {/* end of demo  */}
    </>
  );
}

function AddLink({ field, dataFormate }) {
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            // name={[field.name, "link1"]}
            {...field}
            rules={[
              {
                required: false,
                message: "Please enter portfolio / work sample",
              },
            ]}
          >
            <Input placeholder="Paste the link here" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

function AddEducation({ field, dataFormate, form, onIsPersuing }) {
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Form.Item
          label="Qualification"
          name={[field.name, "qualification"]}
          rules={[
            {
              required: true,
              message: "Please enter qualification",
            },
          ]}
        >
          <Input placeholder="Enter your degree" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Form.Item
          label="Qualification Type"
          name={[field.name, "qualificationType"]}
          rules={[
            {
              required: true,
              message: "Please enter qualification type",
            },
          ]}
        >
          <Select placeholder="Select qualification type">
            <Select.Option value="Secondary">Secondary</Select.Option>
            <Select.Option value="Higher Secondary">
              Higher Secondary
            </Select.Option>
            <Select.Option value="Under-Graduate">Under Graduate</Select.Option>
            <Select.Option value="Graduate">Graduate</Select.Option>
            <Select.Option value="Post-Graduate">Post Graduate</Select.Option>
            <Select.Option value="Doctoral studies">
              Doctoral Studies
            </Select.Option>
            <Select.Option value="Diploma">Diploma</Select.Option>
            <Select.Option value="Distance Education">
              Distance Education
            </Select.Option>
            <Select.Option value="Correspondence Education">
              Correspondence Education
            </Select.Option>
            <Select.Option value="Vocational Education & Training">
              Vocational Education & Training
            </Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Form.Item
          label="Institute/ School Name"
          name={[field.name, "institute"]}
          rules={[
            {
              required: false,
              message: "Please enter institute/ school name",
            },
          ]}
        >
          <Input placeholder="Enter your institute/ school name" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Form.Item
          label="Major"
          name={[field.name, "major"]}
          rules={[
            {
              required: false,
              message: "Please enter major",
            },
          ]}
        >
          <Input placeholder="Enter your subject name" />
        </Form.Item>
      </Col>

      <Col flex={1}>
        <Row justify="space-between">
          <Col xs={24} sm={24} md={11} lg={11}>
            <Form.Item
              label="CGPA"
              name={[field.name, "cgpa"]}
              rules={[
                {
                  required: false,
                  message: "Please enter cgpa",
                },
              ]}
            >
              <Input placeholder="Enter cgpa" type="number" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={11} lg={11}>
            <Form.Item
              label="Percentage"
              name={[field.name, "percentage"]}
              rules={[
                {
                  required: false,
                  message: "Please enter percentage",
                },
              ]}
            >
              <Input placeholder="Enter percentage" type="number" />
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col xs={24} sm={24} md={24} lg={24}>
        <Row justify="space-between">
          <Col xs={24} sm={24} md={11} lg={11}>
            <Form.Item
              label="Start Date"
              name={[field.name, "from"]}
              rules={[
                {
                  required: false,
                  message: "Please select start date",
                },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue("educationInfo")[field.name].to ||
                      getFieldValue("educationInfo")[field.name].to > value
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "From date must be less than To Date"
                    );
                  },
                }),
              ]}
            >
              <DatePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                picker="year"
                placeholder="Select date"
                className={commonStyles.datePickerBgColor}
                style={{
                  width: "100%",
                }}
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(1, "day"))
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={11} lg={11}>
            <Form.Item
              label="End Date"
              name={[field.name, "to"]}
              rules={[
                {
                  required: false,
                  message: "Please select end date",
                },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue("educationInfo")[field.name].from ||
                      getFieldValue("educationInfo")[field.name].from < value
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "To date must be greater than From Date"
                    );
                  },
                }),
              ]}
            >
              <DatePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                picker="year"
                placeholder="Select date"
                className={commonStyles.datePickerBgColor}
                style={{
                  width: "100%",
                }}
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(1, "day"))
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ gap: "10px" }} align="middle">
          <Col>
            <Form.Item
              valuePropName="checked"
              name={[field.name, "isPersuing"]}
              style={{ margin: "0px", padding: "0px" }}
              rules={[
                {
                  required: false,
                  message: "This field is required",
                },
              ]}
            >
              <Switch
                onChange={(val) => {
                  onIsPersuing();
                  if (val) {
                    form.setFieldsValue({
                      ...form.getFieldsValue(),
                      educationInfo: form
                        .getFieldValue("educationInfo")
                        .map((x, i) => {
                          if (i === field.name)
                            return { ...x, isPersuing: true };
                          else return { ...x, isPersuing: false };
                        }),
                      employeeInfo: [],
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <p
              className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
            >
              Current Education
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function AddEmployee({ field, dataFormate, form }) {
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Form.Item
          label="Company"
          name={[field.name, "companyName"]}
          rules={[
            {
              required: true,
              message: "Please enter company",
            },
          ]}
        >
          <Input placeholder="Enter your company" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Row style={{ gap: "10px" }} align="middle">
          <Col>
            <Form.Item
              valuePropName="checked"
              name={[field.name, "isCurrent"]}
              style={{ margin: "0px", padding: "0px" }}
              rules={[
                {
                  required: false,
                  message: "This field is required",
                },
              ]}
            >
              <Switch
                onChange={(val) => {
                  if (val) {
                    form.setFieldsValue({
                      ...form.getFieldsValue(),
                      employeeInfo: form
                        .getFieldValue("employeeInfo")
                        .map((x, i) => {
                          if (i === field.name) {
                            return { ...x, isCurrent: true, to: "" };
                          } else {
                            return { ...x, isCurrent: false };
                          }
                        }),
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <p
              className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
            >
              Current Company
            </p>
          </Col>
        </Row>
      </Col>
      <Col style={{ marginTop: "20px" }} xs={24} sm={24} md={24} lg={24}>
        <Form.Item
          label="Designation"
          name={[field.name, "designation"]}
          rules={[
            {
              required: true,
              message: "Please enter designation",
            },
          ]}
        >
          <Input placeholder="Enter your designation" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Row justify="space-between">
          <Col xs={24} sm={24} md={11} lg={11}>
            <Form.Item
              label="Start date"
              name={[field.name, "from"]}
              rules={[
                {
                  required: false,
                  message: "Please enter start date",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue("employeeInfo")[field.name].to ||
                      getFieldValue("employeeInfo")[field.name].to > value
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "From date must be less than To Date"
                    );
                  },
                }),
              ]}
            >
              <DatePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder="Select date"
                format={dataFormate}
                className={commonStyles.datePickerBgColor}
                style={{
                  width: "100%",
                }}
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(1, "day"))
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={11} lg={11}>
            <Form.Item
              label="End date"
              name={[field.name, "to"]}
              rules={[
                {
                  required: false,
                  message: "Please enter end date",
                },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue("employeeInfo")[field.name].from ||
                      getFieldValue("employeeInfo")[field.name].from < value
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "To date must be greater than From Date"
                    );
                  },
                }),
              ]}
            >
              <DatePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder="Select date"
                format={dataFormate}
                className={commonStyles.datePickerBgColor}
                style={{
                  width: "100%",
                }}
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(1, "day"))
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col xs={24} sm={24} md={24} lg={24}>
        <Form.Item
          label="Summary"
          name={[field.name, "summary"]}
          rules={[
            {
              required: false,
              message: "Please enter summary",
            },
          ]}
        >
          <Input.TextArea placeholder="Enter summary" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Form.Item
          label="Reference Contact Person name"
          name={[field.name, "referenceName"]}
          rules={[
            {
              required: false,
              message: "Please enter reference name",
            },
          ]}
        >
          <Input placeholder="Enter reference contact person name" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Form.Item
          label="Reference Contact Person Email"
          name={[field.name, "referenceEmail"]}
          rules={[
            {
              required: false,
              message: "Please enter reference email",
            },
            {
              type: "email",
              message: "The input is not valid E-mail!.",
            },
          ]}
        >
          <Input placeholder="Enter reference contact person email" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Form.Item
          name={[field.name, "referenceNumber"]}
          rules={[
            {
              required: false,
              message: "Please enter reference number",
            },
          ]}
          label="Reference contact person number"
        >
          <PhoneInput
            className="ant-input ant-cust-inputs"
            defaultCountry="IN"
            placeholder="Enter reference contact person number"
          />
        </Form.Item>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    countriesData: state.country.countries,
    StateData: state.country.state,
    cityData: state.country.city,
    publicRequirement_jobDeatail:
      state.publicRequirement.publicRequirement_jobDeatail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCountriesHandler: (data, callback) => {
      dispatch(getCountriesExternalAction(data, callback));
    },
    postAddCandidateHandler: (data, callback) => {
      dispatch(postExternalCandidatesAction(data, callback));
    },
    checkEmailHandler: (val, callback) => {
      dispatch(getVerifyCandidatesEmailAction(val, callback));
    },
    getPublicJobDetailsHandler(id) {
      dispatch(getExternalJobDetailAction(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalJobApplyPage);
