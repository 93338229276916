import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  permissions: [],
  ishavepermissions: false,
};
export const AuthPermissionsSlice = createSlice({
  name: "authpermissions",
  initialState,
  reducers: {
    permissionsReducer: (state, action) => {
      state.permissions = action.payload.result;
    },
    checkPermissionReducer: (state, action) => {
      state.ishavepermissions = action.payload;
    },
  },
});
export const { checkPermissionReducer, permissionsReducer } =
  AuthPermissionsSlice.actions;
export default AuthPermissionsSlice.reducer;
