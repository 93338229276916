import { Breadcrumb } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import commonCssStyle from "../../UI/CommonCss.module.css";
import PermissionMessage from "../../UI/PermissionMessage";
function BreadcrumbForTaskAndInterview(props) {
  const navigate = useNavigate();
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  const array =
    props.taskStatus && props.taskStatus
      ? [
          "Home",
          !props.calendarViewStatus && !props.calendarViewStatus
            ? "Task List view"
            : "Task Calendar view",
        ]
      : [
          "Home",
          !props.calendarViewStatus && !props.calendarViewStatus
            ? "Interview List view"
            : "Interview Calendar view",
        ];
  return (
    <>
      <Breadcrumb>
        {props.slide &&
          props.slide.map((data, index) => {
            return (
              <Breadcrumb.Item key={index}>
                <p
                  className={` ${commonCssStyle.breadcrumbText} ${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove}`}
                  onClick={() => {
                    if (data === 1) {
                      props.getCheckPermissionHandler(
                        "dashboard",
                        (success) => {
                          if (success) {
                            navigate("/dashboard");
                          } else {
                            setOpenPMModal({
                              open: true,
                              permission: "Dashboard",
                            });
                          }
                        }
                      );
                    }
                  }}
                >
                  {array[index]}
                </p>
              </Breadcrumb.Item>
            );
          })}
      </Breadcrumb>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
          props.setIsHavepermissionsHandler(false);
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BreadcrumbForTaskAndInterview);
