import {
  Drawer,
  Form,
  Input,
  Button,
  Tag,
  Select,
  InputNumber,
  Switch,
  Row,
  Col,
  DatePicker,
} from "antd";

import { CloseIcon, FilterOutlinedIcon } from "../../../IconsComp/Icons";
import commonStyles from "../../../UI/CommonCss.module.css";
import styles from "../../../Screens/ClientCompany/ClientCompany.module.css";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
  getCandidatesAction,
  getCandidatesFilterAction,
} from "../../../../Reusable/Store/Action/CandidatesAction";
import { getReportsCandidatesAction } from "../../../../Reusable/Store/Action/ReportsAction";
import moment from "moment";
import {
  getTagAction,
  getTagsAction,
} from "../../../../Reusable/Store/Action/Master/MasterTagsAction";
import ResetButton from "../../../UI/ResetButton";
import { getCandidatesSummaryReportsAction } from "../../../../Reusable/Store/Action/ReportsAction";
import { getRecruitmentAllStatusAction } from "../../../../Reusable/Store/Action/RecruitmentAction";
import { getRequirementsAction } from "../../../../Reusable/Store/Action/RequirementAction";

const { Option } = Select;

const tagRenderForMobileNum = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

const tagRenderForSkills = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

const tagRender = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

function ReportsJobCandidatesFilter(props) {
  const [filterDatas, setFilterDatas] = useState({
    name: "",
    email: "",
    phone: "",
    skills: "",
    jobId: "",
  });

  const [restBtnLocation, setRestBtnLocation] = useState(false);
  const [restBtnExperience, setRestBtnExperience] = useState(false);
  const [restBtnSalary, setRestBtnSalary] = useState(false);
  const [restBtnAge, setRestBtnAge] = useState(false);
  const [restBtnTotalExp, setRestBtnTotalExp] = useState(false);
  const [restBtnRelevantExp, setRestBtnRelevantExp] = useState(false);

  const dateFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const [form] = Form.useForm();

  const resetFilter = () => {
    form.resetFields();
    form.setFieldsValue({
      jobId: "",
    });
    setFilterDatas({
      jobId: "",
    });
    const data = {
      current_page: 1,
    };
    props.onCloseFilter();
    props.candidateReportFilter(data);
  };

  const [checkedSpecialAbilities, setCheckedSpecialAbilities] = useState(false);

  useEffect(() => {
    props.getCandidateDetailsListHandler({});
    props.getTagHandler();
    props.getReportsCandidateSummaryHandler();
    props.getRecruitmentAllStatusHandler();
    props.getRequirementAction();
    setFilterDatas({ jobId: props.initialValuesJobId });
  }, [props.initialValuesJobId]);

useEffect(()=>{
  const obj = JSON.parse(localStorage.getItem("reportsjobcandidatefilterdata"));
  if(obj){
    form.setFieldsValue({
      appliedDate:obj.appliedDate?moment(obj.appliedDate):"",
      dateFrom:moment(obj.dateFrom),
      dateTo:moment(obj.dateTo),
      jobId:obj.jobId,
      location:obj.location,
      name:obj.name == "" ? undefined : obj.name,
      relevalntExperience:obj.relevalntExperience,
      skills:obj.skills == "" ? undefined : obj.skills,
      stage:obj.stage,
      totalExperience:obj.totalExperience,
    })
    setCheckedSpecialAbilities(obj.specialAbility)
  }

},[])


  const onFinish = (values) => {
    const data = {
      name: values.name && values.name.length > 0 ? values.name : "",
      skills: values.skills && values.skills.length > 0 ? values.skills : "",
      location: values.location ? values.location : "",
      appliedDate: values.appliedDate
        ? moment(values.appliedDate).format("YYYY-MM-DD")
        : "",
      specialAbility: checkedSpecialAbilities ? "1" : "",
      stage: values.stage ? values.stage : "",
      totalExperience: values.totalExperience ? values.totalExperience : "",
      relevalntExperience: values.relevalntExperience
        ? values.relevalntExperience
        : "",
      jobId: values.jobId ? values.jobId : "",
      dateTo: props.toDate,
      dateFrom: props.fromDate,
    };

    if (
      data.email &&
      data.phone &&
      data.skills &&
      data.location &&
      // data.tags &&
      data.experience &&
      data.appliedDate &&
      data.salary &&
      data.specialAbility &&
      data.leadSource &&
      data.jobStatus &&
      data.jobId === ""
    ) {
      props.reportsJobFilterHandleer({
        current_page: 1,
        dateFrom: "1970-01-01",
        dateTo: "3000-01-01",
      });
      props.onCloseFilter();
    } else {
      props.reportsJobFilterHandleer({
        current_page: 1,
        ...data,
      });
      props.onCloseFilter();
    }
  };

  return (
    <>
      <Drawer
        className="modalModified"
        closable={false}
        extra={
          <div
            onClick={() => props.onCloseFilter()}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
          </div>
        }
        width={503}
        height={48}
        title={
          <>
            <Row>
              <Col>
                <FilterOutlinedIcon color={"var(--primaryColor)"} />
              </Col>
              <Col>
                <p
                  style={{ margin: "0px 0px 0px 5px" }}
                  className={commonStyles.filterHeadingName}
                >
                  Filter
                </p>
              </Col>
            </Row>{" "}
          </>
        }
        // onClose={props.onCloseFilter}
        open={props.onOpenFilter}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          initialValues={
            props.initialValuesJobId && {
              jobId: props.initialValuesJobId,
              // jobId: filterDatas.jobId,
            }
          }
        >
          <Row style={{ padding: "0px 20px 80px 20px" }}>
            <Col md={24}>
              <Form.Item name="stage" label="Hiring Status">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  allowClear
                  className={"candidateSelectHeight"}
                  style={{ width: "100%" }}
                  placeholder="Select hiring status"
                >
                  {props.get_all_status &&
                    props.get_all_status.map((data, index) => {
                      return (
                        <Select.OptGroup
                          key={index}
                          label={<b>{data.statusType}</b>}
                        >
                          {data.status &&
                            data.status.map((i, j) => {
                              return (
                                <Select.Option value={i}>{i}</Select.Option>
                              );
                            })}
                        </Select.OptGroup>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Name" name="name">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  onKeyUp={(val) => {
                    setFilterDatas({
                      name: val.target.value,
                    });
                  }}
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // className={"candidateSelectHeight"}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select name"
                  tagRender={tagRenderForMobileNum}
                >
                  {filterDatas.name &&
                    filterDatas.name !== " " &&
                    filterDatas.name.length > 0 &&
                    props.candidates &&
                    props.candidates.map((data) => {
                      return (
                        <Select.Option key={data.id} value={data.name}>
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Job Name" name="jobId">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  showSearch
                  allowClear
                  onKeyUp={(val) => {
                    setFilterDatas({
                      jobId: val.target.value,
                    });
                  }}
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // className={"candidateSelectHeight"}
                  // mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select job name"
                  // tagRender={tagRenderForMobileNum}
                >
                  {filterDatas.jobId &&
                    filterDatas.jobId.length > 0 &&
                    filterDatas.jobId !== " " &&
                    props.requirements &&
                    props.requirements.map((data, index) => {
                      return (
                        <Select.Option key={index} value={data.id}>
                          {data.title}
                        </Select.Option>
                      );
                    })}
                  {/* {
                    props.requirements &&
                    props.requirements.map((data,index) => {
                      return (
                        <Select.Option key={index} value={data.id}>
                          {data.title}
                        </Select.Option>
                      );
                    })} */}
                </Select>
              </Form.Item>

              <Form.Item label="Total Experience" name="totalExperience">
                <Input
                  type="number"
                  onChange={() => {
                    if (!restBtnTotalExp) {
                      setRestBtnTotalExp(true);
                    }
                  }}
                  className={"CandidateInputHeight"}
                  placeholder="Enter total experience"
                />
              </Form.Item>
              {restBtnTotalExp && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      totalExperience: "",
                    });
                    setRestBtnTotalExp(false);
                  }}
                />
              )}

              <Form.Item
                label="Relevalnt Experience"
                name="relevalntExperience"
              >
                <Input
                  type="number"
                  onChange={() => {
                    if (!restBtnRelevantExp) {
                      setRestBtnRelevantExp(true);
                    }
                  }}
                  className={"CandidateInputHeight"}
                  placeholder="Enter relevalnt experience"
                />
              </Form.Item>
              {restBtnRelevantExp && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      relevalntExperience: "",
                    });
                    setRestBtnRelevantExp(false);
                  }}
                />
              )}

              <Form.Item label="Key Skills/Tag" name="skills">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  // className={"candidateSelectHeight"}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  onKeyUp={(val) => {
                    setFilterDatas({ skills: val.target.value });
                  }}
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select skills"
                  tagRender={tagRenderForSkills}
                >
                  {filterDatas.skills &&
                    filterDatas.skills !== " " &&
                    filterDatas.skills.length > 0 &&
                    props.TagsData &&
                    props.TagsData.filter(
                      (data) => data.type === "candidate"
                    ).map((data) => {
                      return (
                        <Option key={data.id} value={data.tag}>
                          {data.tag}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Location" name="location">
                <Input
                  onChange={() => {
                    if (!restBtnLocation) {
                      setRestBtnLocation(true);
                    }
                  }}
                  // className={"CandidateInputHeight"}
                  placeholder="Enter location"
                />
              </Form.Item>

              {restBtnLocation && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      location: "",
                    });
                    setRestBtnLocation(false);
                  }}
                />
              )}

              {restBtnExperience && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      experience: "",
                    });
                    setRestBtnExperience(false);
                  }}
                />
              )}

              <Form.Item label="Applied Date" name="appliedDate">
                <DatePicker
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placeholder="Select date"
                  format={dateFormate}
                  style={{ width: "100%" }}
                  className={`${commonStyles.datePickerBgColor} `}
                />
              </Form.Item>

              <Row align="middle" style={{ gap: "17px" }}>
                <Col>
                  <p className={`${commonStyles.formInputLabel} `}>
                    Special abilities
                  </p>
                </Col>
                <Col>
                  <Form.Item name="specialAbility">
                    <Switch
                      onChange={(val) => {
                        setCheckedSpecialAbilities(val);
                      }}
                      checked={checkedSpecialAbilities} 
                    />
                  </Form.Item>
                </Col>
              </Row>

              {restBtnAge && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      age: "",
                    });
                    setRestBtnAge(false);
                  }}
                />
              )}
            </Col>
          </Row>

          <Col className={commonStyles.footerShadow} style={{ width: "503px" }}>
            <Row justify="end" style={{ gap: "25px" }}>
              <Col>
                <Button
                  className={commonStyles.buttonSettingsSecondary}
                  type="default"
                  onClick={() => {
                    localStorage.removeItem("reportsjobcandidatefilterdata");
                    localStorage.removeItem("reportsjobcandidatefilterdataSort");
                    props.reportsJobFilterHandleer({
                      current_page: 1,
                      dateFrom: "1970-01-01",
                      dateTo: "3000-01-01",
                    });
                    setRestBtnLocation(false);
                    setRestBtnExperience(false);
                    setRestBtnSalary(false);
                    setRestBtnAge(false);
                    setFilterDatas({
                      email: "",
                      phone: "",
                      skills: "",
                      jobId: "",
                    });
                    props.onRestFilter();
                    resetFilter();
                  }}
                >
                  Reset Filter
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ReportsCandidateSummary: state.reports.reportsCandidateSummary,
    candidates: state.candidates.candidates,
    candidatesFilterData: state.candidates.candidatesFilter,
    TagsData: state.tags.tags,
    get_all_status: state.recruitment.get_all_status,
    requirements: state.requirements.requirements,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRecruitmentAllStatusHandler: () => {
      dispatch(getRecruitmentAllStatusAction());
    },
    getCandidateDetailsListHandler: () => {
      dispatch(getCandidatesAction());
    },
    getCandidateFilterHandler: (val) => {
      dispatch(getCandidatesFilterAction(val));
    },
    getTagHandler: () => {
      dispatch(getTagsAction());
    },
    getReportsCandidateSummaryHandler: () => {
      dispatch(getCandidatesSummaryReportsAction());
    },
    getRequirementAction: () => {
      dispatch(getRequirementsAction());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsJobCandidatesFilter);
