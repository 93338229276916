import {
  DatePicker,
  Drawer,
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  Radio,
  Switch,
  Menu,
  Dropdown,
  Typography,
  Space,
} from "antd";
import React, { useState } from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import styles from "../../AddCandidatesComp/AddCandidatesComp.module.css";
import InOffice from "./InOffice";
import Telephonic from "./Telephonic";
import {
  CaretBlackDownIcon,
  CloseIcon,
  MinusIcons,
  PlusForButtonIcon,
} from "../../../IconsComp/Icons";
import commonCssStyles from "../../../UI/CommonCss.module.css";
import { useForm } from "antd/lib/form/Form";

import { getCountriesAction } from "../../../../Reusable/Store/Action/Master/MasterCountryAction";
import { connect } from "react-redux";
import {
  postInterViewAction,
  putInterViewAction,
} from "../../../../Reusable/Store/Action/RecruitmentAction";
import moment from "moment";
import { getInterviewAssissmentQuestionsAction } from "../../../../Reusable/Store/Action/Master/MasterInterviewAssessmentQuestionsAction";
import { useEffect } from "react";
import {
  getCandidatesJobAction,
  getTasksFilterAction,
} from "../../../../Reusable/Store/Action/TaskAndCalendarAction";
import { getCandidatesJobSlice } from "../../../../Reusable/Store/Slice/TaskAndCalendarSlice";
import { useCallback } from "react";

function ScheduleInterviewDrawer(props) {
  const [countryData, setCountryData] = useState([]);
  const [attachedJd, setAttachedJd] = useState(false);
  // const [value, setValue] = useState("web");
  const [form] = useForm();
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [email, setEmail] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [valueData, setValueData] = useState("web");
  const [menuKey, setMenuKey] = useState("1");
  const [reschedule, setReschedule] = useState(false);
  const [notify, setNotify] = useState(false);
  const [removeInterviewer, setRemoveInterviewer] = useState(false);

  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      props.getAssessmentQuestionHander(); //used to call assessment api
      if (props.onModalName === "addFromCalendar") {
        //used to calculate End Time and Duration based on start time
        var startTime = moment(props.onSelectedData.start, "HH:mm a");
        var endTime = moment(props.onSelectedData.end, "HH:mm a");
        // calculate total duration
        let duration = moment.duration(endTime.diff(startTime));
        // duration in hours
        let hours = parseInt(duration.asHours());
        // duration in minutes
        let minutes = parseInt(duration.asMinutes()) % 60;
        form.setFieldsValue({
          duration: hours + " " + "hours" + " " + minutes + " " + "minutes",
        }); //setting duration based on start time and end time
        setStartTime(props.onSelectedData.start && props.onSelectedData.start);
      }
      if (props.onModalName === "edit") {
        //if the schedule interview is related to edit we need to set the data here
        // if (
        //   props.onTasksData &&
        //   props.onTasksData.attachJD &&
        //   props.onTasksData.attachJD
        // ) {
        //   //job attached to Jd
        //   setAttachedJd(props.onTasksData.attachJD === "1" ? true : false);
        // }
        //setting Mode of Interview example web or office or Telephonic
        setValueData(
          props.onTasksData &&
            props.onTasksData.interviewType &&
            props.onTasksData.interviewType
        );
        // if (
        //   props.onTasksData &&
        //   props.onTasksData.interviewType &&
        //   props.onTasksData.interviewType === "office" &&
        //   props.onTasksData.interviewTypeDetails &&
        //   props.onTasksData.interviewTypeDetails.country
        // ) {
        //   // if the ode of interview is office we are calling country here and setting data
        //   props.getCountryHandler({ type: "country" }, (data, type) => {
        //     setCountryData(data);
        //     if (data) {
        //       let _country =
        //         data &&
        //         data.find((x) => {
        //           return (
        //             x.name === props.onTasksData.interviewTypeDetails.country
        //           );
        //         });
        //       if (_country) {
        //         props.getCountryHandler(
        //           { id: _country.id, type: "state" },
        //           (data, type) => {
        //             setStates(data);
        //             let _state =
        //               data &&
        //               data.find((d) => {
        //                 return (
        //                   d.name ===
        //                   props.onTasksData.interviewTypeDetails.state
        //                 );
        //               });
        //             if (_state) {
        //               props.getCountryHandler(
        //                 { id: _state.id, type: "city" },
        //                 (data, type) => {
        //                   setCity(data);
        //                 }
        //               );
        //             }
        //           }
        //         );
        //       }
        //     }
        //   });
        // }
      }
    }
    return () => {
      unsubscribed = true;
    };
  }, []);
  useEffect(() => {
    if (props.pendinInterviewData === "yes") {
      //if schedule interview is edit in candidate details page we are setting Mode of Interview value here
      setValueData(
        props.onTasksData &&
          props.onTasksData.interviewType &&
          props.onTasksData.interviewType
      );
    }
  }, [
    props.onTasksData &&
      props.onTasksData.interviewType &&
      props.onTasksData.interviewType,
  ]);
  //this menu is used to change the interview status
  const menu2 = (
    <Menu
      style={{ width: "214px", margin: "11px 0px" }}
      selectable
      items={[
        {
          key: "1",
          label: (
            <p
              onClick={() => {
                setMenuKey("1");

                props.putInterViewHandler(
                  {
                    id: props.onTasksData.id,
                    // status: "pending",
                    status: "Pending",
                  },
                  (success) => {
                    if (success) {
                      if (
                        props.onInterviewName &&
                        props.onInterviewName === "task"
                      ) {
                        //after setting status, we are calling filter api in task and calander
                        props.getTaskFilterHandler({
                          current_page: 1,
                          type: props.taskStatusData ? "task" : "interview",
                          ...props.filterData,
                        });
                      } else {
                        //after setting status, we are calling this api to update interview list in candidate details page
                        props.fetchInterviewData();
                      }
                    }
                  }
                );
                props.onCloseSchedileInterview();
              }}
              style={{
                color:
                  menuKey === "1"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.darkText}`}
            >
              Pending
            </p>
          ),
        },
        {
          key: "2",
          label: (
            <p
              onClick={() => {
                setMenuKey("2");
                props.putInterViewHandler(
                  {
                    id: props.onTasksData.id,
                    // status: "completed",
                    status: "Completed",
                  },
                  (success) => {
                    if (success) {
                      if (
                        props.onInterviewName &&
                        props.onInterviewName === "task"
                      ) {
                        //after setting status, we are calling filter api in task and calander
                        props.getTaskFilterHandler({
                          current_page: 1,
                          type: props.taskStatusData ? "task" : "interview",
                          ...props.filterData,
                        });
                      } else {
                        //after setting status, we are calling this api to update interview list in candidate details page
                        props.fetchInterviewData();
                      }
                    }
                  }
                );
                props.onCloseSchedileInterview();
              }}
              style={{
                color:
                  menuKey === "2"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonCssStyles.marginBottomRemove}  ${commonCssStyles.darkText}`}
            >
              Completed
            </p>
          ),
        },
        {
          key: "3",
          label: (
            <p
              onClick={() => {
                setMenuKey("3");
                props.putInterViewHandler(
                  {
                    id: props.onTasksData.id,
                    status: "Reschedule by Client",
                  },
                  (success) => {
                    if (success) {
                      if (
                        props.onInterviewName &&
                        props.onInterviewName === "task"
                      ) {
                        //after setting status, we are calling filter api in task and calander
                        props.getTaskFilterHandler({
                          current_page: 1,
                          type: props.taskStatusData ? "task" : "interview",
                          ...props.filterData,
                        });
                      } else {
                        //after setting status, we are calling this api to update interview list in candidate details page
                        props.fetchInterviewData();
                      }
                    }
                  }
                );
                props.onCloseSchedileInterview();
              }}
              style={{
                color:
                  menuKey === "3"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonCssStyles.marginBottomRemove}  ${commonCssStyles.darkText}`}
            >
              Rescheduled by Client
            </p>
          ),
        },
        {
          key: "4",
          label: (
            <p
              onClick={() => {
                setMenuKey("4");
                props.putInterViewHandler(
                  {
                    id: props.onTasksData.id,
                    status: "Candidate No Show",
                  },
                  (success) => {
                    if (success) {
                      if (
                        props.onInterviewName &&
                        props.onInterviewName === "task"
                      ) {
                        //after setting status, we are calling filter api in task and calander
                        props.getTaskFilterHandler({
                          current_page: 1,
                          type: props.taskStatusData ? "task" : "interview",
                          ...props.filterData,
                        });
                      } else {
                        //after setting status, we are calling this api to update interview list in candidate details page
                        props.fetchInterviewData();
                      }
                    }
                  }
                );
                props.onCloseSchedileInterview();
              }}
              style={{
                color:
                  menuKey === "4"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonCssStyles.marginBottomRemove}  ${commonCssStyles.darkText}`}
            >
              Candidate No Show
            </p>
          ),
        },
        {
          key: "5",
          label: (
            <p
              onClick={() => {
                setMenuKey("5");
                props.putInterViewHandler(
                  {
                    id: props.onTasksData.id,
                    status: "Rescheduled by Candidate",
                  },
                  (success) => {
                    if (success) {
                      if (
                        props.onInterviewName &&
                        props.onInterviewName === "task"
                      ) {
                        //after setting status, we are calling filter api in task and calander
                        props.getTaskFilterHandler({
                          current_page: 1,
                          type: props.taskStatusData ? "task" : "interview",
                          ...props.filterData,
                        });
                      } else {
                        //after setting status, we are calling this api to update interview list in candidate details page
                        props.fetchInterviewData();
                      }
                    }
                  }
                );
                props.onCloseSchedileInterview();
              }}
              style={{
                color:
                  menuKey === "4"
                    ? "var(--primaryColor)"
                    : "var(--body-text-color)",
              }}
              className={`${commonCssStyles.marginBottomRemove}  ${commonCssStyles.darkText}`}
            >
              Rescheduled by Candidate
            </p>
          ),
        },
      ]}
    />
  );
  const dateFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";
  const timeFormate =
    props.settindsData &&
    props.settindsData.timeFormat &&
    props.settindsData.timeFormat === "12hours"
      ? "hh:mm a"
      : "HH:mm a";
  const timeArray = [
    "8:00 AM",
    "8:30 AM",
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
    "5:30 PM",
    "6:00 PM",
    "6:30 PM",
    "7:00 PM",
    "7:30 PM",
    "8:00 PM",
    "8:30 PM",
    "9:00 PM",
    "9:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
    "12:00 AM",
    "12:30 AM",
    "1:00 AM",
    "1:30 AM",
    "2:00 AM",
    "2:30 AM",
    "3:00 AM",
    "3:30 AM",
    "4:00 AM",
    "4:30 AM",
    "5:00 AM",
    "5:30 AM",
    "6:00 AM",
    "6:30 AM",
    "7:00 AM",
    "7:30 AM",
  ];

  const initvalues = {
    state: "",
    city: "",
  };
  const onChange = (e) => {
    //when we switch Mode of Interview to office  this api is calling
    setValueData(e.target.value);
    // if (e.target.value === "office") {
    // props.getCountryHandler({ type: "country" }, (data, type) => {
    //   setCountryData(data);
    // });
    // }
  };
  const onSubmit = (val) => {
    if (props.onModalName !== "edit") {
      //when you are sending post for schedule interview data will enter inside here
      if (
        props.onInterviewName === "candidateTable" ||
        props.onInterviewName === "candidateDetails"
      ) {
        //if your are submitting data from candidate table or candidate details page the submited data is sending from this api
        const data = {
          ...val,
          interviewDate: moment(val.interviewDate).format("YYYY/MM/DD"),
          // type: "general",
          type: "recruitment",
          sendMail: email ? "1" : "0",
          attachJD: attachedJd ? "1" : "0",
          candidateId: props.onCandidateName.id,
        };

        props.postInterViewHandler(data, (success) => {
          if (success) {
            form.resetFields();
            if (props.onInterviewName === "candidateDetails") {
              props.onFetchInterviewData();
            }
            props.onCloseSchedileInterview();
          }
        });
      } else if (props.onInterviewName === "task") {
        //if your are submitting data from task and calendar page the submited data is sending from this api
        const data = {
          ...val,
          sendMail: email ? "1" : "0",
          interviewDate: moment(val.interviewDate).format("YYYY/MM/DD"),
          // type: "general",
          type: "recruitment",
          attachJD: attachedJd ? "1" : "0",
        };
        props.postInterViewHandler(data, (success) => {
          if (success) {
            form.resetFields();
            // props.onCloseSchedileInterview();
            props.onSubmit();
          }
        });
      } else if (props.onInterviewName === "recruitment") {
        //if your are submitting data from recuritment page the submited data is sending from this api
        const data = {
          ...val,
          interviewDate: moment(val.interviewDate).format("YYYY/MM/DD"),
          type: "recruitment",
          sendMail: email ? "1" : "0",
          candidateId: props.onCandidateName.id,
          jobId: props.onCandidateName.jobId,
          attachJD: attachedJd ? "1" : "0",
        };
        props.postInterViewHandler(data, (success) => {
          if (success) {
            form.resetFields();
            props.onCloseSchedileInterview();
          }
        });
      }
    } else {
      //when you are sending put for schedule interview data will enter here
      const data = {
        ...val,
        notify: notify ? "1" : "0",
        reschedule: reschedule ? "1" : "0",
        id: props.onTasksData && props.onTasksData.id,
        sendMail: email ? "1" : "0",
        interviewDate: moment(val.interviewDate).format("YYYY/MM/DD"),
        attachJD: attachedJd ? "1" : "0",
      };
      props.putInterViewHandler(data, (success) => {
        if (success) {
          form.resetFields();
          if (props.onInterviewName === "task") {
            //if schedule interview edit is from task and calendar page the drawer will close function is here
            props.onSubmit();
          } else {
            //if schedule interview edit, not from task and calendar page the drawer will close function is here
            props.fetchInterviewData();
            props.onCloseSchedileInterview();
          }
        }
      });
    }
  };
  let disableData = true;
  return (
    <Drawer
      closable={false}
      extra={
        <div
          onClick={() => {
            if (props.onInterviewName === "task") {
              props.getCandidatesJobHandler();
            }
            props.onCloseSchedileInterview();
          }}
          className={commonCssStyles.coursorPointer}
        >
          <CloseIcon color={"var(--body-text-color)"} />
        </div>
      }
      title={
        <>
          <h3 className={styles.summaryInfoMainText}>
            {props.onModalName === "edit"
              ? "Edit Schedule Interview"
              : "Schedule Interview"}
          </h3>
        </>
      }
      footer={
        <div className={styles.footerContainer}>
          {props.onModalName === "edit" ? (
            <>
              {/* {props.onProfile &&
              props.onProfile.roleType &&
              props.onProfile.roleType !== "Recruiter" ? ( */}
              {props.permissions.find(
                (data) => data === "interview-status-update"
              ) && (
                <Button className={commonCssStyles.intaddButton} type="primary">
                  <Row align="middle" justify="space-around">
                    <Col>
                      <p className={commonCssStyles.marginBottomRemove}>
                        Interview Status
                      </p>
                    </Col>
                    <Col>
                      <Dropdown placement="top" overlay={menu2}>
                        <Typography.Link>
                          <Space
                            className={commonCssStyles.tableHeadingTextData}
                          >
                            <CaretBlackDownIcon color={"white"} />
                          </Space>
                        </Typography.Link>
                      </Dropdown>
                    </Col>
                  </Row>
                </Button>
              )}
              {/* ) : (
                ""
              )} */}
              <Button
                className={commonCssStyles.buttonSettingsSecondary}
                onClick={() => {
                  // setReschedule(true); //reschedule 1
                  form.submit();
                }}
              >
                {/* Reschedule */}
                Save
              </Button>
              {/* <Button
                className={commonCssStyles.buttonSettingsPrimary}
                type="primary"
                htmlType="button"
                onClick={() => {
                  setNotify(true); //notify 1
                  form.submit();
                }}
              >
                Notify
                Save and Send
              </Button> */}
            </>
          ) : (
            <>
              <Button
                className={commonCssStyles.buttonSettingsSecondary}
                onClick={() => {
                  // setReschedule(true);
                  setEmail(false);
                  form.submit();
                }}
              >
                Save
              </Button>
              <Button
                style={{ width: "auto" }}
                className={commonCssStyles.buttonSettingsPrimary}
                type="primary"
                htmlType="button"
                onClick={() => {
                  setEmail(true);
                  // setNotify(true);
                  form.submit();
                }}
              >
                Send and Save
              </Button>
            </>
          )}
        </div>
      }
      width={690}
      onClose={props.onCloseSchedileInterview}
      open={props.onOpenSchedileInterview}
    >
      <Form
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
        initialValues={
          props.onTasksData && props.onModalName === "edit"
            ? {
                interviewDate: moment(
                  props.onTasksData.interviewDate &&
                    props.onTasksData.interviewDate
                ),
                jobId: props.onTasksData.jobId && props.onTasksData.jobId,
                candidateId:
                  props.onTasksData.candidateId &&
                  props.onTasksData.candidateId,
                // stage: props.onTasksData.stage && props.onTasksData.stage,
                interviewType:
                  props.onTasksData.interviewType &&
                  props.onTasksData.interviewType,
                duration:
                  props.onTasksData.duration && props.onTasksData.duration,
                interviewEndTime:
                  props.onTasksData.interviewEndTime &&
                  props.onTasksData.interviewEndTime,
                interviewStartTime:
                  props.onTasksData.interviewStartTime &&
                  props.onTasksData.interviewStartTime,
                interviewerDetails:
                  props.onTasksData.interviewers &&
                  props.onTasksData.interviewers.map((data) => {
                    return {
                      name: data.name,
                      designation: data.designation,
                      email: data.email,
                    };
                  }),
                contactName:
                  props.onTasksData.interviewTypeDetails &&
                  props.onTasksData.interviewTypeDetails.contactName &&
                  props.onTasksData.interviewTypeDetails.contactName,
                phone:
                  props.onTasksData.interviewTypeDetails &&
                  props.onTasksData.interviewTypeDetails.phone &&
                  props.onTasksData.interviewTypeDetails.phone,
                instruction:
                  props.onTasksData.instruction &&
                  props.onTasksData.instruction,
                pointOfContact:
                  props.onTasksData.interviewTypeDetails &&
                  props.onTasksData.interviewTypeDetails.pointOfContact &&
                  props.onTasksData.interviewTypeDetails.pointOfContact,
                officeAddress:
                  props.onTasksData.interviewTypeDetails &&
                  props.onTasksData.interviewTypeDetails.officeAddress &&
                  props.onTasksData.interviewTypeDetails.officeAddress,
                // country:
                //   props.onTasksData.interviewTypeDetails &&
                //   props.onTasksData.interviewTypeDetails.country,
                // state:
                //   props.onTasksData.interviewTypeDetails &&
                //   props.onTasksData.interviewTypeDetails.state &&
                //   props.onTasksData.interviewTypeDetails.state,
                // city:
                // props.onTasksData.interviewTypeDetails &&
                // props.onTasksData.interviewTypeDetails.city &&
                // props.onTasksData.interviewTypeDetails.city,
                // address:
                //   props.onTasksData.interviewTypeDetails &&
                //   props.onTasksData.interviewTypeDetails.address &&
                //   props.onTasksData.interviewTypeDetails.address,
                // phone: props.onTasksData.phone && props.onTasksData.phone,

                meetingLink:
                  props.onTasksData.interviewTypeDetails &&
                  props.onTasksData.interviewTypeDetails,
                jobQuestions:
                  props.onTasksData.jobQuestions &&
                  props.onTasksData.jobQuestions.map((data) => {
                    return data;
                  }),
              }
            : props.onModalName === "addFromCalendar"
            ? {
                interviewStartTime: moment(
                  props.onSelectedData.start && props.onSelectedData.start
                ).format("h:mm a"),
                interviewEndTime: moment(
                  props.onSelectedData.end && props.onSelectedData.end
                ).format("h:mm a"),
              }
            : props.onInterviewName && props.onInterviewName !== "recruitment"
            ? {
                jobId: props.jobName,
              }
            : props.onInterviewName && props.onInterviewName === "recruitment"
            ? {
                interviewDate: "",
                jobId: "",
                candidateId: "",
                // stage: props.onTasksData.stage && props.onTasksData.stage,
                interviewType: "",
                duration: "",
                interviewEndTime: "",
                interviewStartTime: "",
                interviewerDetails: "",
                contactName: "",
                phone: "",
                instruction: "",
                // country: "",
                // state: "",
                // city: "",
                // address: "",
                // phone: "",
                officeAddress: "",
                pointOfContact: "",

                meetingLink: "",
                jobQuestions: "",
              }
            : {
                interviewType: "web",
                initvalues,
                interviewStartTime: "8:00 AM",
                interviewEndTime: "9:00 AM",
                duration: 1 + " " + "hours" + " " + 0 + " " + "minutes",
                //interviewerDetails: [{ name: "", designation: "", email: "" }],
                // candidateId: props.onCandidateName.id,
              }
        }
        form={form}
        onFinish={(val) => onSubmit(val)}
        layout="vertical"
      >
        {props.onInterviewName === "task" && (
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select candidate",
              },
            ]}
            label="Candidate"
            name="candidateId"
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              onChange={(val, d) => {
                props.getCandidateRelatedJobsHandler(val);
              }}
              disabled={props.onModalName === "edit" ? true : false}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {/* onCandidateData   candidatesJob */}
              {props.onCandidateData &&
                props.onCandidateData.map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.id}>
                      {data.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        )}
        {props.onInterviewName &&
        props.onInterviewName !== "recruitment" &&
        props.onModalName === "edit" ? (
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select job",
              },
            ]}
            label="Job"
            name="jobId"
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              defaultValue={props.jobName && props.jobName}
              // value={props.jobName && props.jobName}
              disabled={
                props.onModalName === "edit" || (props.jobName && props.jobName)
                  ? true
                  : false
              }
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {props.onJobData &&
                props.onJobData.map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.id}>
                      {data.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        ) : (
          <>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please select job",
                },
              ]}
              label="Job"
              name="jobId"
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                defaultValue={props.jobName && props.jobName}
                // value={props.jobName && props.jobName}
                disabled={
                  props.onModalName === "edit" ||
                  (props.jobName && props.jobName)
                    ? true
                    : false
                }
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {props.candidatesJob &&
                  props.candidatesJob.map((data, index) => {
                    return (
                      <Select.Option key={index} value={data.id}>
                        {data.title}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </>
        )}
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please select interview date",
            },
          ]}
          label="Interview Date"
          name="interviewDate"
        >
          <DatePicker
            getPopupContainer={(trigger) => trigger.parentElement}
            disabled={props.onModalName === "edit" ? true : false}
            format={dateFormate}
            className={commonCssStyles.datePickerBgColor}
            style={{ width: "100%" }}
            placeholder="Select date"
            disabledDate={(current) =>
              current.isBefore(moment().subtract(1, "day"))
            }
          />
        </Form.Item>

        <Row style={{ marginTop: "20px" }} justify="space-between">
          <Col md={7}>
            <Form.Item
              label="Start Time"
              name="interviewStartTime"
              rules={[
                {
                  required: true,
                  message: "Please select interview start time",
                },
              ]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                disabled={props.onModalName === "edit" ? true : false}
                onChange={(val, data) => {
                  form.setFieldsValue({
                    interviewEndTime: val,
                    duration: 0 + " hours",
                  });
                  setStartTime(val);
                }}
                placeholder="Select Start Time"
              >
                {timeArray.map((data, index) => {
                  return (
                    <Select.Option key={index} value={data}>
                      {data}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col md={7}>
            <Form.Item label="End Time" name="interviewEndTime">
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                disabled={props.onModalName === "edit" ? true : false}
                onChange={(val, data) => {
                  setEndTime(val);

                  let interviewTimeDate =
                    form.getFieldsValue("interviewEndTime");
                  if (
                    interviewTimeDate.interviewStartTime &&
                    interviewTimeDate.interviewEndTime
                  ) {
                    var startTime = moment(
                      interviewTimeDate.interviewStartTime,
                      "HH:mm a"
                    );
                    var endTime = moment(
                      interviewTimeDate.interviewEndTime,
                      "HH:mm a"
                    );
                    // calculate total duration
                    let duration = moment.duration(endTime.diff(startTime));
                    // duration in hours
                    let hours = parseInt(duration.asHours());
                    // duration in minutes
                    let minutes = parseInt(duration.asMinutes()) % 60;
                    form.setFieldsValue({
                      duration:
                        hours + " " + "hours" + " " + minutes + " " + "minutes",
                    });
                  }
                }}
                placeholder="Select End Time"
              >
                {timeArray.map((data, index) => {
                  if (startTime === data) {
                    disableData = false;
                  }
                  return (
                    <Select.Option
                      disabled={disableData}
                      key={index}
                      value={data}
                    >
                      {data}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col md={7}>
            <Form.Item label="Duration" name="duration">
              <Input readOnly placeholder="Duration" />
            </Form.Item>
          </Col>
        </Row>

        <Row className={commonCssStyles.sectionEndGap}>
          <Col md={24}>
            <Form.List
              /* name="interview" */
              name="interviewerDetails"
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, key) => (
                    <>
                      <Row align="middle" justify="space-between">
                        <Interview
                          interviewersHandler={
                            props.onTasksData &&
                            props.onTasksData.interviewers &&
                            props.onTasksData.interviewers
                          }
                          onRemoveInterviewer={removeInterviewer}
                          onModalName={props.onModalName && props.onModalName}
                          field={field}
                          form={form}
                        />

                        {/* {key !== 0 && ( */}
                        <Col>
                          <Button
                            type="primary"
                            size="small"
                            shape="circle"
                            style={{
                              marginTop: "3px",
                              marginRight: "7px",
                              float: "right",
                              backgroundColor: "#F83A3A",
                            }}
                          >
                            <MinusOutlined
                              color={"var(--theme)"}
                              onClick={() => {
                                setRemoveInterviewer((val) => !val);

                                remove(key);
                              }}
                            />
                          </Button>
                        </Col>
                        {/* )} */}
                      </Row>
                    </>
                  ))}
                  <Col>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        add();
                      }}
                      shape="circle"
                    >
                      <div style={{ transform: "translateY(2px)" }}>
                        <PlusForButtonIcon
                          height={"19px"}
                          width={"19px"}
                          color={"var(--theme)"}
                        />
                      </div>
                    </Button>
                    <p
                      style={{ marginTop: "-27px", marginLeft: "40px" }}
                      className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.addLocation}`}
                    >
                      Add Interviewer
                    </p>
                  </Col>
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        {/* <Form.Item
          className={commonCssStyles.sectionEndGap}
          label="Stage"
          name="stage"
          rules={[
            {
              required: true,
              message: "Please select stage",
            },
          ]}
        >
          <Select placeholder="Select Stage">
            <Select.Option value="Technical">Technical</Select.Option>
            <Select.Option value="Screening">Screening</Select.Option>
          </Select>
        </Form.Item> */}

        <Form.Item
          className={commonCssStyles.sectionEndGap}
          style={{ marginBottom: "0px" }}
          label="Mode of Interview"
          name="interviewType"
          rules={[
            {
              required: true,
              message: "Please select mode of interview",
            },
          ]}
        >
          <Radio.Group
            onChange={onChange}
            value={valueData}
            className="radioBtnText"
          >
            <Radio value="web">Virtual</Radio>
            <Radio value="office">In-Office</Radio>
            <Radio value="telephonic">Telephonic</Radio>
          </Radio.Group>
        </Form.Item>

        <Row style={{ gap: "16px" }} align="middle">
          <Col>
            <Form.Item name="attachJD">
              <Switch
                checked={attachedJd}
                onChange={(val) => {
                  setAttachedJd(val);
                }}
                size="small"
              />
            </Form.Item>
          </Col>
          <Col>
            <p className={`${commonCssStyles.formInputLabel}`}>
              Attach Job Description
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            {valueData === "web" ? (
              <Form.Item
                label="Meeting Link"
                name="meetingLink"
                rules={[
                  {
                    required: false,
                    message: "Please enter meeting link",
                  },
                ]}
              >
                <Input placeholder="Add Meeting Link" />
              </Form.Item>
            ) : valueData === "office" ? (
              <InOffice
              // stateData={(data) => {
              //   props.getCountryHandler(
              //     { id: data.id, type: "state" },
              //     (data, type) => {
              //       setStates(data);
              //       form.setFieldsValue({
              //         city: initvalues.city,
              //         state: initvalues.state,
              //       });
              //       setCity([]);
              //     }
              //   );
              // }}
              // cityData={(data) => {
              //   props.getCountryHandler(
              //     { id: data.id, type: "city" },
              //     (data, type) => {
              //       setCity(data);
              //       form.setFieldsValue({
              //         city: initvalues.city,
              //       });
              //     }
              //   );
              // }}
              // onCountriesData={countryData}
              // onStateData={states}
              // onCityData={city}
              />
            ) : (
              <Telephonic />
            )}
          </Col>
        </Row>

        {/* {props.onModalName !== "edit" && ( */}
        <>
          <Row className={commonCssStyles.sectionEndGap}>
            <Col md={24}>
              <Form.List /* name="assesment" */ name="jobQuestions">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, key) => (
                      <Row>
                        <Col md={24}>
                          <Assesment
                            onQuestionData={props.assesmentQuestionData}
                            field={field}
                          />
                        </Col>

                        <Col md={24}>
                          <p
                            style={{
                              marginTop: "2px",
                              float: "right",
                              color: "#F83A3A",
                            }}
                            className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.removeLocation}`}
                          >
                            {/* Remove Assesment Question */}
                            Remove Interview Feedback
                          </p>
                          <Button
                            type="primary"
                            size="small"
                            shape="circle"
                            style={{
                              marginTop: "3px",
                              marginRight: "7px",
                              float: "right",
                              backgroundColor: "#F83A3A",
                            }}
                          >
                            <div
                              style={{
                                transform: "translateY(-4px)",
                              }}
                              onClick={() => remove(key)}
                            >
                              <MinusIcons color={"var(--theme)"} />
                            </div>
                          </Button>
                        </Col>
                      </Row>
                    ))}

                    <Button
                      size="small"
                      type="primary"
                      onClick={() => add()}
                      shape="circle"
                    >
                      <div style={{ transform: "translateY(2px)" }}>
                        <PlusForButtonIcon
                          height={"19px"}
                          width={"19px"}
                          color={"var(--theme)"}
                        />
                      </div>
                    </Button>
                    <p
                      style={{ marginTop: "-27px", marginLeft: "40px" }}
                      className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.addLocation}`}
                    >
                      Add Interview Feedback
                    </p>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </>
        {/* )} */}

        <Form.Item
          className={commonCssStyles.sectionEndGap}
          label="Instruction"
          name="instruction"
        >
          <Input.TextArea placeholder="Type instruction here..." />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

const mapStateToProps = (state) => {
  return {
    assesmentQuestionData:
      state.interviewAssessmentQuestions.interviewAssessmentQuestions,
    settindsData: state.settings.settings,
    filterData: state.taskAndCalendar.filterData,
    taskStatusData: state.taskAndCalendar.taskStatusData,
    permissions: state.authpermissions.permissions,
    candidatesJob: state.taskAndCalendar.candidatesJob, // use this for interview from task and calendar
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAssessmentQuestionHander: (data) => {
      dispatch(getInterviewAssissmentQuestionsAction());
    },
    getCountryHandler: (data, callback) => {
      dispatch(getCountriesAction(data, callback));
    },
    postInterViewHandler: (data, callback) => {
      dispatch(postInterViewAction(data, callback));
    },
    putInterViewHandler: (data, callback) => {
      dispatch(putInterViewAction(data, callback));
    },
    getTaskFilterHandler: (data) => {
      dispatch(getTasksFilterAction(data));
    },

    getCandidateRelatedJobsHandler: (val) => {
      dispatch(getCandidatesJobAction(val));
    },
    getCandidatesJobHandler: () => {
      dispatch(getCandidatesJobSlice({ result: [] }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleInterviewDrawer);
function Assesment({ field, onQuestionData }) {
  const [assessmentQuestion, setAssessmentQuestion] = useState("");
  {
    /* Add Assesment Questions */
  }
  return (
    <>
      <Form.Item
        {...field}
        rules={[
          {
            required: true,
            message: "Select assessment question",
          },
        ]}
        // label="Assesment Question"
        label="Interview Feedback"
      >
        {assessmentQuestion === "others" ? (
          <Input.TextArea placeholder="Add Interview Feedback" />
        ) : (
          <Select
            getPopupContainer={(trigger) => trigger.parentElement}
            onChange={(val, data) => {
              if (data.id) {
                setAssessmentQuestion(data.id);
              }
            }}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {onQuestionData &&
              onQuestionData.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.question}>
                    {data.question}
                  </Select.Option>
                );
              })}
            <Select.Option id="others" value="">
              Others
            </Select.Option>
          </Select>
        )}
      </Form.Item>
    </>
  );
}
function Interview({ field, form, onModalName, onRemoveInterviewer }) {
  const [validationForInterview, setValidationForInterview] = useState(true);
  useEffect(() => {
    if (onModalName === "edit") {
      form.getFieldValue("interviewerDetails").map((x) => {
        if (
          (x && x.name && x.name.length !== 0) ||
          (x && x.designation && x.designation.length !== 0) ||
          (x && x.email && x.email.length !== 0)
        ) {
          setValidationForInterview(false);
        } else {
          setValidationForInterview(true);
        }
      });
      // setValidationForInterview(false);
    }
  }, [onRemoveInterviewer]);

  useEffect(() => {
    if (onModalName !== "edit") {
      form.getFieldValue("interviewerDetails").map((x) => {
        if (
          (x && x.name && x.name.length !== 0) ||
          (x && x.designation && x.designation.length !== 0) ||
          (x && x.email && x.email.length !== 0)
        ) {
          setValidationForInterview(false);
        } else {
          setValidationForInterview(true);
        }
      });
      // setValidationForInterview(false);
    }
  }, [onRemoveInterviewer]);

  return (
    <>
      <Col md={7}>
        <Form.Item
          required={false}
          rules={[
            {
              required: validationForInterview,

              message: "Please enter interviewer name",
            },
          ]}
          name={[field.name, "name"]}
          label="Interviewer Name"
        >
          <Input
            onChange={(e) => {
              if (onModalName === "edit") {
                form.getFieldValue("interviewerDetails").map((x) => {
                  if (x) {
                    if (!x.name && !x.designation && !x.email) {
                      setValidationForInterview(true);
                    } else {
                      setValidationForInterview(false);
                    }
                  }
                });
              } else {
                if (e.target.value.length > 0) {
                  setValidationForInterview(false);
                } else {
                  setValidationForInterview(true);
                }
              }
            }}
            placeholder="Enter Interviewer Name"
          />
        </Form.Item>
      </Col>

      <Col md={7}>
        <Form.Item
          required={false}
          rules={[
            {
              required: validationForInterview,

              message: "Please enter designation",
            },
          ]}
          name={[field.name, "designation"]}
          label="Interviewer Designation"
        >
          <Input
            onChange={(e) => {
              if (onModalName === "edit") {
                form.getFieldValue("interviewerDetails").map((x) => {
                  if (x) {
                    if (!x.name && !x.designation && !x.email) {
                      setValidationForInterview(true);
                    } else {
                      setValidationForInterview(false);
                    }
                  }
                });
              } else {
                if (e.target.value.length > 0) {
                  setValidationForInterview(false);
                } else {
                  setValidationForInterview(true);
                }
              }
            }}
            placeholder="Enter Interviewer Designation"
          />
        </Form.Item>
      </Col>

      <Col md={7}>
        <Form.Item
          required={false}
          name={[field.name, "email"]}
          label="Interview Mail Id"
          rules={[
            {
              type: "email",
              message: "Enter valid email",
            },
            {
              required: validationForInterview,

              message: "Please enter email",
            },
          ]}
        >
          <Input
            onChange={(e) => {
              if (onModalName === "edit") {
                form.getFieldValue("interviewerDetails").map((x) => {
                  if (x) {
                    if (!x.name && !x.designation && !x.email) {
                      setValidationForInterview(true);
                    } else {
                      setValidationForInterview(false);
                    }
                  }
                });
              } else {
                if (e.target.value.length > 0) {
                  setValidationForInterview(false);
                } else {
                  setValidationForInterview(true);
                }
              }
            }}
            placeholder="Enter Interviewer Mail Id"
          />
        </Form.Item>
      </Col>
    </>
  );
}
