import "antd/dist/antd.variable.min.css";
import React, { useEffect, useState } from "react";
import "./App.less";
import Dashboard from "./Components/Screens/Dashboard/ContentPage/Dashboard";
import Profile from "./Components/Screens/ProfileComp/Profile";
import { HashRouter, Route, Routes } from "react-router-dom";
import ConfirmPassword from "./Components/Screens/Login/ConfirmPassword";
import ForgotPassword from "./Components/Screens/Login/ForgotPassword";
import Settings from "./Components/Screens/Settings/Settings";
import Login from "./Components/Screens/Login/Login";
import { ConfigProvider, Layout, Skeleton, notification, Button } from "antd";
import SideBar from "./Components/UI/SideBarComp/SideBar";
import Headers from "./Components/UI/headersComp/Headers";
import CandidateTabs from "./Components/Screens/AddCandidatesComp/CandidatesSummary/CandidateTabs";
import Employee from "./Components/Screens/Employees/Employee";
import EmployeeSummary from "./Components/Screens/Employees/EmployeeSummary";
import { connect } from "react-redux";
import ClientCompanyTable from "./Components/Screens/ClientCompany/ClientCompanyTable";
import ClientCompanyTabs from "./Components/Screens/ClientCompany/ClientCompanyDetails/ClientCompanyTabs";
import CandidateDetailsTable from "./Components/Screens/AddCandidatesComp/candidateDetailsTable/CandidateDetailsTable";
import RequirementTable from "./Components/Screens/Requirement/RequirementTable";
import RequirementJobDetailPage from "./Components/Screens/Requirement/RequirementJobDetailPage";
import moment from "moment";
import {
  logoutWhenPermissionChangedAction,
  profileAction,
  refreshAction,
} from "./Reusable/Store/Action/AuthenticationAction";
import { getSettingsAction } from "./Reusable/Store/Action/SettingsAction";
import pageNotFound from "../src/Assets/Image/pageNotFound.png";
import RecruitmentTable from "./Components/Screens/Recruitment/RecruitmentTable";
import RecruitmentJobDetailPage from "./Components/Screens/Recruitment/RecruitmentJobDetailPage";
import CandidateForPool from "./Components/Screens/Recruitment/CandidateForPool";
import ImportCandidate from "./Components/Screens/Recruitment/ImportCandidate";
import TaskCalendarTabs from "./Components/Screens/TaskCalendar/TaskCalendarTabs";
import TaskDetails from "./Components/Screens/TaskCalendar/TaskDetails";
import ErrorPage from "./Components/UI/ErrorPage";
import RecruitmentExternalJobDetailPage from "./Components/Screens/Recruitment/RecruitmentJobApplyOutside/RecruitmentExternalJobDetailPage";
import { permissionsAction } from "./Reusable/Store/Action/AuthPermissionsAction";
import AppNotification from "./Components/Screens/Dashboard/AppNotification";
import ExternalJobsListingPage from "./Components/Screens/Recruitment/RecruitmentJobApplyOutside/ExternalJobsListingPage";
import { messaging, onMessageListener } from "./firebase";
import GlobalSearchList from "./Components/UI/headersComp/GlobalSearchList";
import { getToken } from "firebase/messaging";
import { putNotificationStoreTokenAction } from "./Reusable/Store/Action/NotificationAction";
import ReportsCandidateSummary from "./Components/Screens/Reports/CandidateReports/ReportsCandidateSummary";
import ReportsCompanyDetail from "./Components/Screens/Reports/CompanyReports/ReportsCompanyDetail";
import CandidateReportsDetailTable from "./Components/Screens/Reports/CandidateReports/CandidateReportsDetailTable";
import Charts from "./Components/Screens/Reports/Charts/Charts";
import Piechart from "./Components/Screens/Reports/Charts/Piechart";
import Barchart from "./Components/Screens/Reports/Charts/Barchart";
import ReportsJobDetails from "./Components/Screens/Reports/CompanyReports/ReportsJobDetails";
import ReportsJobCandidates from "./Components/Screens/Reports/CompanyReports/ReportsJobCandidates";
import { getNotificationsAction } from "./Reusable/Store/Action/NotificationAction";
import ExternalJobApplyPage from "./Components/Screens/Recruitment/RecruitmentJobApplyOutside/ExternalJobApplyPage";
import RecuritmentReport from "./Components/Screens/Reports/RecuritmentReports/RecuritmentReport";
import SuggestedCandidates from "./Components/Screens/Recruitment/SuggestedCandidates";
import RecuritmentJob from "./Components/Screens/Reports/RecuritmentReports/RecuritmentJob";
import axios from "axios";
import { LOGOUT_URL } from "./Reusable/Store/apipath";
import { logoutReducer } from "./Reusable/Store/Slice/AuthenticationSlice";
import DemoDetail from "./Components/Screens/Recruitment/RecruitmentJobApplyOutside/DemoDetail";
const { Content } = Layout;
function App({
  token,
  refreshAction,
  getSettingsAction,
  settings,
  permissions,
  permissionsHandler,
  putNotificationStoreTokenHandler,
  profileAction,
  profile,
  getNotificationsHandler,
  logoutReducer,
  logoutWhenPermissionChangedAction,
  localStorageHandler,
}) {
  let [email, setEmail] = useState(null);
  let [code, setCode] = useState(null);
  const [notificationMessage, setNotification] = useState({});

  // const [show, setShow] = useState(false);

  const setState = () => {
    setCode(null);
    setEmail(null);
  };
  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // Generate Token
      // vapidKey:
      //   "BMcfH2m42qmxmM86ATB6Tp1NZg7eew7BYduPKv316Nn4ky23_Hf8jbdjOLmuk3vyWa_ItXAa9mS2YaQFmU7F7RU",
      // {
      //   vapidKey:
      //     "BGoqVKvVNancr0x-MaPkd1E1KBSSfg2vQQQO0qxZK6A58foc4l2Oil6vmjEjxN2cL62SPShFkGI8IjC9dZw8hIw",
      // }
      const token = await getToken(messaging, {
        vapidKey:
          "BMZpv31iOPV6H3U0zgo3_dHS7uFi5p5mDXVDgbIVzvGgsnOVLHD1vPQ0hwyglWFBmkM0TSlK_Kbal3-bRXsmQ9c",
      });
      // const token = await getToken(messaging, {
      //   vapidKey:
      //     "BGoqVKvVNancr0x-MaPkd1E1KBSSfg2vQQQO0qxZK6A58foc4l2Oil6vmjEjxN2cL62SPShFkGI8IjC9dZw8hIw",
      // });
      if (token) putNotificationStoreTokenHandler({ device_key: token });

      // console.log("Token Gen", token);

      // Send this token  to server ( db)
    } else if (permission === "denied") {
      alert("You denied for the notification");
    }
  }
  onMessageListener()
    .then((payload) => {
      // setNotification(payload);
      // openNotification();
      if (payload.notification.body === "Permission Changed") {
        logoutWhenPermissionChangedAction();
      } else {
        notification.open({
          message: payload.notification.title,
          description: payload.notification.body,
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
        getNotificationsHandler();
      }
    })
    .catch((err) => console.log("failed: ", err));

  // function openNotification() {}

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = settings && settings.logo && settings.logo;
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--theme",
      settings && settings.theme === "dark" ? "#1E1E1E" : "#fff"
    );
    if (settings && settings.theme === "dark") {
      document.documentElement.style.setProperty(
        "--box-shadow",
        "0px 0px 0px rgba(0, 0, 0, 0)"
      );

      document.documentElement.style.setProperty(
        "--dr-border-color",
        "#676767"
      );
      document.documentElement.style.setProperty("--switch-bg", "#d9d9d994");
      document.documentElement.style.setProperty("--progress-card", "#292929");
      document.documentElement.style.setProperty("--card-color", "#292929");
      document.documentElement.style.setProperty(
        "--table-heading-color",
        "#ffff"
      );
      document.documentElement.style.setProperty(
        "--active-menu-color",
        settings && settings.primaryColor ? settings.primaryColor : "#2F7BF5"
      );
      document.documentElement.style.setProperty(
        "--select-bg-card-color",
        "#292929"
      );
      document.documentElement.style.setProperty(
        "--secondary-card-color",
        "#292929"
      );
      document.documentElement.style.setProperty("--Joyride-Clr", "#ffffff");

      document.documentElement.style.setProperty(
        "--dropdown-menu-bg-color",
        "#1E1E1E"
      );

      document.documentElement.style.setProperty(
        "--drawer-bg-color",
        "#1E1E1E"
      );
      document.documentElement.style.setProperty("--card-text-color", "#ffff");
      document.documentElement.style.setProperty("--inverse-tabs-ink", "#ffff");

      document.documentElement.style.setProperty(
        "--main-heading-text-color",
        "#ffff"
      );

      document.documentElement.style.setProperty("--error-text-color", "#ffff");
      document.documentElement.style.setProperty(
        "--table-text-color",
        "#7C7C7C"
      );

      document.documentElement.style.setProperty(
        "--placeholder-color",
        "#ffff"
      );
      document.documentElement.style.setProperty("--input-text", "#ffff");

      document.documentElement.style.setProperty("--tabs-ink", "#ffff");
      document.documentElement.style.setProperty("--input-focus", "#ffff");
      document.documentElement.style.setProperty(
        "--application-btn",
        "#1E1E1E"
      );
      document.documentElement.style.setProperty(
        "--application-btn-text",
        "#ffff"
      );

      document.documentElement.style.setProperty("--headings-color", "#fff");
      document.documentElement.style.setProperty("--upload-text-color", "#fff");
      document.documentElement.style.setProperty(
        "--nofile-text-color",
        "#403E3E"
      );

      document.documentElement.style.setProperty(
        "--nofile-button-color",
        "#1E1E1E"
      );
      document.documentElement.style.setProperty("--icons-color", "#fff");

      document.documentElement.style.setProperty("--input-color", "#292929");
      document.documentElement.style.setProperty("--change-color", "#fff");
      document.documentElement.style.setProperty("--menu-tabs-color", "#fff");
      document.documentElement.style.setProperty("--menu-text-color", "#ffff");
      document.documentElement.style.setProperty("--subHeadings-color", "#fff");
      document.documentElement.style.setProperty("--body-text-color", "#fff");
      document.documentElement.style.setProperty("--menu-tab-color", "#ffff");
      document.documentElement.style.setProperty(
        "--primary-button-color",
        "#ffff"
      );
      document.documentElement.style.setProperty(
        "--primary-hover-button-color",
        "#1E1E1E"
      );
      document.documentElement.style.setProperty(
        "--secondary-button-color",
        "#ffff"
      );
      document.documentElement.style.setProperty(
        "--secondary-hover-button-color",
        "#1E1E1E"
      );
      document.documentElement.style.setProperty(
        "--inverse-secondaryColor",
        "#1E1E1E"
      );
      document.documentElement.style.setProperty(
        "--inverse-body-text-color",
        "#403E3E"
      );
      document.documentElement.style.setProperty(
        "--inverse-heading-text-color",
        "#403E3E"
      );
      document.documentElement.style.setProperty(
        "--background-color",
        "#121212"
      );
      document.documentElement.style.setProperty(
        "--heading-bg-color",
        "#121212"
      );
      document.documentElement.style.setProperty(
        "--sidemenu-bg-color",
        "#1E1E1E"
      );
      document.documentElement.style.setProperty(
        "--primaryColor",
        settings && settings.primaryColor ? settings.primaryColor : "#2F7BF5"
      );
      document.documentElement.style.setProperty("--hovering-color", "#292929");
      document.documentElement.style.setProperty(
        "--table-hovering-color",
        "#292929"
      );
      document.documentElement.style.setProperty(
        "--dropdown-menu-text-color",
        "#ffff"
      );
      document.documentElement.style.setProperty(
        "--dropdown-bg-color",
        "#1E1E1E"
      );
      document.documentElement.style.setProperty(
        "--unactive-tab-color",
        "#7c7c7c"
      );
      document.documentElement.style.setProperty(
        "--notification--bg",
        "#5b5b5b"
      );
    } else {
      document.documentElement.style.setProperty(
        "--notification--bg",
        "#f6f8fc"
      );
      document.documentElement.style.setProperty(
        "--dr-border-color",
        "transparent"
      );
      document.documentElement.style.setProperty("--switch-bg", "#bfbfbf");
      document.documentElement.style.setProperty("--progress-card", "#F6F8FC");
      document.documentElement.style.setProperty(
        "--box-shadow",
        "0px -3px 17px rgba(0, 0, 0, 0.15)"
      );
      document.documentElement.style.setProperty("--Joyride-Clr", "#ffffff");

      document.documentElement.style.setProperty(
        "--active-menu-color",
        settings && settings.hoveringColor ? settings.hoveringColor : "#90BAFD"
      );
      document.documentElement.style.setProperty(
        "--table-heading-color",
        settings && settings.subheadingColor
          ? settings.subheadingColor
          : "#030A16"
      );
      document.documentElement.style.setProperty(
        "--select-bg-card-color",
        "#ffff"
      );
      document.documentElement.style.setProperty(
        "--secondary-card-color",
        settings && settings.secondaryColor
          ? settings.secondaryColor
          : "#E3EBFF"
      );
      document.documentElement.style.setProperty(
        "--dropdown-menu-text-color",
        settings && settings.primaryColor ? settings.primaryColor : "#2F7BF5"
      );
      document.documentElement.style.setProperty(
        "--table-hovering-color",
        "#fafafa"
      );
      document.documentElement.style.setProperty(
        "--dropdown-menu-bg-color",
        "#ffff"
      );
      document.documentElement.style.setProperty(
        "--dropdown-bg-color",
        settings && settings.secondaryColor
          ? settings.secondaryColor
          : "#E3EBFF"
      );
      document.documentElement.style.setProperty(
        "--hovering-color",
        settings && settings.hoveringColor ? settings.hoveringColor : "#90BAFD"
      );
      document.documentElement.style.setProperty(
        "--sidemenu-bg-color",
        settings && settings.primaryColor ? settings.primaryColor : "#2F7BF5"
      );
      document.documentElement.style.setProperty("--heading-bg-color", "#ffff");
      document.documentElement.style.setProperty(
        "--inverse-heading-text-color",
        settings && settings.headingColor ? settings.headingColor : "#030A16"
      );
      document.documentElement.style.setProperty(
        "--inverse-body-text-color",
        settings && settings.bodyTextColor ? settings.bodyTextColor : "#403E3E"
      );
      document.documentElement.style.setProperty("--drawer-bg-color", "#ffff");
      document.documentElement.style.setProperty(
        "--inverse-secondaryColor",
        settings && settings.secondaryColor
          ? settings.secondaryColor
          : "#E3EBFF"
      );

      document.documentElement.style.setProperty(
        "--inverse-tabs-ink",
        settings && settings.bodyTextColor ? settings.bodyTextColor : "#403E3E"
      );

      document.documentElement.style.setProperty(
        "--card-text-color",
        settings && settings.bodyTextColor ? settings.bodyTextColor : "#403E3E"
      );
      document.documentElement.style.setProperty("--card-color", "#ffff");

      document.documentElement.style.setProperty(
        "--error-text-color",
        "#f83a3a"
      );
      document.documentElement.style.setProperty(
        "--main-heading-text-color",
        settings && settings.headingColor ? settings.headingColor : "#030A16"
      );
      document.documentElement.style.setProperty(
        "--table-text-color",
        settings && settings.bodyTextColor ? settings.bodyTextColor : "#403E3E"
      );
      document.documentElement.style.setProperty(
        "--placeholder-color",
        settings && settings.bodyTextColor ? settings.bodyTextColor : "#403E3E"
      );
      document.documentElement.style.setProperty(
        "--input-text",
        settings && settings.bodyTextColor ? settings.bodyTextColor : "#403E3E"
      );
      document.documentElement.style.setProperty(
        "--input-focus",
        settings && settings.primaryColor ? settings.primaryColor : "#2F7BF5"
      );
      document.documentElement.style.setProperty(
        "--tabs-ink",
        settings && settings.primaryColor ? settings.primaryColor : "#2F7BF5"
      );
      document.documentElement.style.setProperty(
        "--inverse-tabs-ink",
        settings && settings.primaryColor ? settings.primaryColor : "#2F7BF5"
      );
      document.documentElement.style.setProperty(
        "--application-btn-text",
        settings && settings.primaryColor ? settings.primaryColor : "#2F7BF5"
      );
      document.documentElement.style.setProperty("--application-btn", "#ffff");
      document.documentElement.style.setProperty(
        "--nofile-button-color",
        settings && settings.primaryButtonColor
          ? settings.primaryButtonColor
          : "#2F7BF5"
      );
      document.documentElement.style.setProperty(
        "--nofile-text-color",
        settings && settings.bodyTextColor ? settings.bodyTextColor : "#403E3E"
      );
      document.documentElement.style.setProperty(
        "--upload-text-color",
        settings && settings.bodyTextColor ? settings.bodyTextColor : "#403E3E"
      );
      document.documentElement.style.setProperty("--icons-color", "#7C7C7C");

      document.documentElement.style.setProperty(
        "--input-color",
        settings && settings.secondaryColor
          ? settings.secondaryColor
          : "#E3EBFF"
      );
      document.documentElement.style.setProperty("--change-color", "#0c6620");
      document.documentElement.style.setProperty(
        "--headings-color",
        settings && settings.headingColor ? settings.headingColor : "#030A16"
      );
      document.documentElement.style.setProperty(
        "--menu-tabs-color",
        settings && settings.secondaryColor
          ? settings.secondaryColor
          : "#E3EBFF"
      );
      document.documentElement.style.setProperty(
        "--menu-text-color",
        settings && settings.secondaryColor ? settings.MenuTabColor : "#2F7BF5"
      );
      document.documentElement.style.setProperty(
        "--subHeadings-color",
        settings && settings.subheadingColor
          ? settings.subheadingColor
          : "#030A16"
      );
      document.documentElement.style.setProperty(
        "--body-text-color",
        settings && settings.bodyTextColor ? settings.bodyTextColor : "#403E3E"
      );
      document.documentElement.style.setProperty(
        "--unactive-tab-color",
        settings && settings.bodyTextColor ? settings.bodyTextColor : "#403E3E"
      );
      document.documentElement.style.setProperty(
        "--primary-button-color",
        settings && settings.primaryButtonColor
          ? settings.primaryButtonColor
          : "#2F7BF5"
      );
      document.documentElement.style.setProperty(
        "--secondary-button-color",
        settings && settings.secondaryButtonColor
          ? settings.secondaryButtonColor
          : "#2F7BF5"
      );
      document.documentElement.style.setProperty(
        "--secondary-hover-button-color",
        "#ffff"
      );
      document.documentElement.style.setProperty(
        "--primary-hover-button-color",
        "#ffff"
      );
      document.documentElement.style.setProperty(
        "--menu-tab-color",
        settings && settings.MenuTabColor ? settings.MenuTabColor : "#2F7BF5"
      );
      document.documentElement.style.setProperty(
        "--background-color",
        settings && settings.backgroundColor
          ? settings.backgroundColor
          : "#F6F8FC"
      );
      document.documentElement.style.setProperty(
        "--primaryColor",
        settings && settings.primaryColor ? settings.primaryColor : "#2F7BF5"
      );
    }

    document.documentElement.style.setProperty(
      "--secondaryColor",
      settings && settings.secondaryColor ? settings.secondaryColor : "#E3EBFF"
    );

    // document.documentElement.style.setProperty(
    //   "--menu-tab-color",
    //   settings && settings.MenuTabColor ? settings.MenuTabColor : "#2F7BF5"
    // );
  }, [settings]);
  useEffect(() => {
    if (token) {
      getSettingsAction();
      permissionsHandler();
      requestPermission();
      profileAction();
    }
    if (window.location.href.split("?")[1]) {
      let par = window.location.href.split("?")[1].split("&");
      setCode(par[0].split("=")[1]);
      setEmail(par[1].split("=")[1]);
    }

    // var min = moment(new Date(localStorage.getItem("date"))).diff(
    //   moment(),
    //   "minutes"
    // );
    // if (
    //   parseInt(-1 * min) >
    //   parseInt(localStorage.getItem("expires_in")) - 10
    // ) {
    //   refreshAction();
    // }
  }, []);

  return (
    <>
      {/*  window.location.hash.slice(0,6)!=="#/jobs" */}
      <HashRouter>
        <React.Fragment>
          {window.location.hash.includes("/jobs") ? (
            <div
              style={{
                backgroundColor: "#F0F2F5",
              }}
            >
              <Routes>
                <Route
                  path="/jobs/jobdetail/:id"
                  element={<RecruitmentExternalJobDetailPage />}
                />

                <Route path="/jobs/:id" element={<ExternalJobsListingPage />} />

                <Route path="/jobs/apply" element={<ExternalJobApplyPage />} />
              </Routes>
            </div>
          ) : (
            <ConfigProvider>
              {token ? (
                settings ? (
                  <Layout>
                    <SideBar onProfile={profile} settings={settings} />
                    <Layout style={{ height: "100vh" }}>
                      <Headers />
                      <Content
                        style={{
                          padding: "30px",
                          maxHeight: "100vh",
                          overflowY: "scroll",
                          backgroundColor: "var(--background-color)",
                        }}
                      >
                        <Routes>
                          <Route path="*" element={<ErrorPage onPNF={1} />} />
                          {/* {permissions &&
                            permissions.find(
                              (data) => data === "dashboard"
                            ) && (
                              <Route
                                path="/"
                                element={<Dashboard onProfile={profile} />}
                              />
                            )} */}
                          {profile?.roleType !== "External Recruiter"
                            ? permissions &&
                              permissions.find(
                                (data) => data === "dashboard"
                              ) && (
                                <Route
                                  path="/"
                                  element={<Dashboard onProfile={profile} />}
                                />
                              )
                            : permissions &&
                              permissions.find(
                                (data) =>
                                  data === "recruitment-list" ||
                                  data === "recruitment-delete" ||
                                  data === "recruitment-detail" ||
                                  data === "recruitment-candidate-pool" ||
                                  data === "recruitment-add-candidate" ||
                                  data === "recruitment-candidate-detail" ||
                                  data ===
                                    "recruitment-candidate-status update" ||
                                  data === "recruitment-candidate-mail-list" ||
                                  data === "recruitment-candidate-mail-add" ||
                                  data === "recruitment-candidate-timeline" ||
                                  data === "recruitment-candidate-task-list" ||
                                  data ===
                                    "recruitment-candidate-download-resume" ||
                                  data ===
                                    "recruitment-candidate-download-recording" ||
                                  data === "recruitment-candidate-call" ||
                                  data ===
                                    "recruitment-candidate-interview-list" ||
                                  data ===
                                    "recruitment-candidate-offerLetter" ||
                                  data === "recruitment-delete-candidate"
                                // data === "recruitment-candidate-jobs"
                              ) && (
                                <Route
                                  path="/"
                                  element={<RecruitmentTable />}
                                />
                              )}

                          {permissions &&
                            permissions.find(
                              (data) => data === "dashboard"
                            ) && (
                              <Route
                                path="/dashboard"
                                element={<Dashboard onProfile={profile} />}
                              />
                            )}

                          {permissions &&
                            permissions.find(
                              (data) =>
                                data === "candidate-list" ||
                                data === "candidate-detail" ||
                                data === "candidate-edit" ||
                                data === "candidate-mail-add" ||
                                data === "candidate-call" ||
                                data === "candidate-timeline" ||
                                data === "candidate-download-resume" ||
                                data === "candidate-download-recording" ||
                                data === "candidate-mail-list" ||
                                data === "candidate-add" ||
                                data === "candidate-delete" ||
                                data === "candidate-interview-list" ||
                                data === "candidate-export" ||
                                data === "candidate-task-list" ||
                                data === "candidate-jobs"
                            ) && (
                              <Route
                                path="/candidatesDetailsPage"
                                element={
                                  <CandidateDetailsTable
                                    onProfileData={profile}
                                  />
                                }
                              />
                            )}
                          <Route
                            path="/Notification"
                            element={<AppNotification />}
                          />
                          <Route
                            path="/globalsearchlist"
                            element={<GlobalSearchList />}
                          />
                          <Route
                            path="/profile"
                            element={<Profile tokenData={token} />}
                          />
                          {permissions &&
                            permissions.find(
                              (data) =>
                                data === "settings-system" ||
                                data === "settings-organisations" ||
                                data === "settings-billing" ||
                                data === "settings-roles-list" ||
                                data === "settings-roles-add" ||
                                data === "settings-roles-edit" ||
                                data === "settings-roles-delete" ||
                                data === "settings-application-emailTemplate" ||
                                data === "settings-application-leadSource" ||
                                data ===
                                  "settings-application-candidateReason" ||
                                data === "settings-application-jobListing" ||
                                data ===
                                  "settings-application-jobDescription" ||
                                data === "settings-application-sharingMEdium" ||
                                data === "settings-application-department" ||
                                data === "settings-application-jobPrefix" ||
                                data === "settings-import" ||
                                data === "settings-application-tags"
                            ) && (
                              <Route path="/settings" element={<Settings />} />
                            )}
                          {permissions &&
                            permissions.find(
                              (data) =>
                                data === "employees-list" ||
                                data === "employees-add" ||
                                data === "employees-edit" ||
                                data === "employees-delete" ||
                                data === "employees-detail" ||
                                data === "employees-assign-role" ||
                                data === "employees-assign-permission" ||
                                data === "employees-timeline" ||
                                data === "employees-task-list" ||
                                data === "employee-download-recording" ||
                                data === "employee-call"
                            ) && (
                              <Route path="/employee" element={<Employee />} />
                            )}
                          {permissions &&
                            permissions.find(
                              (data) =>
                                data === "employees-list" ||
                                data === "employees-add" ||
                                data === "employees-edit" ||
                                data === "employees-delete" ||
                                data === "employees-detail" ||
                                data === "employees-assign-role" ||
                                data === "employees-assign-permission" ||
                                data === "employees-timeline" ||
                                data === "employees-task-list" ||
                                data === "employee-download-recording" ||
                                data === "employee-call"
                            ) && (
                              <Route
                                path="/employee/summary"
                                element={<EmployeeSummary tokenData={token} />}
                              />
                            )}
                          {permissions &&
                            permissions.find(
                              (data) =>
                                data === "candidate-list" ||
                                data === "candidate-detail" ||
                                data === "candidate-edit" ||
                                data === "candidate-mail-add" ||
                                data === "candidate-call" ||
                                data === "candidate-timeline" ||
                                data === "candidate-download-resume" ||
                                data === "candidate-download-recording" ||
                                data === "candidate-mail-list" ||
                                data === "candidate-add" ||
                                data === "candidate-delete" ||
                                data === "candidate-interview-list" ||
                                data === "candidate-export" ||
                                data === "candidate-task-list" ||
                                data === "candidate-jobs"
                            ) && (
                              <Route
                                path="/candidatesDetailsPage/summary"
                                element={
                                  <CandidateTabs
                                    onProfileData={profile}
                                    tokenData={token}
                                  />
                                }
                              />
                            )}
                          {permissions &&
                            permissions.find(
                              (data) =>
                                data === "client-list" ||
                                data === "client-delete" ||
                                data === "client-add" ||
                                data === "client-detail" ||
                                data === "client-edit" ||
                                data === "client-job" ||
                                data === "client-contact-info" ||
                                // data === "client-contact-person-info" ||
                                data === "client-billing-detail"
                            ) && (
                              <Route
                                path="/clientCompany"
                                element={<ClientCompanyTable />}
                              />
                            )}
                          {permissions &&
                            permissions.find(
                              (data) =>
                                data === "client-list" ||
                                data === "client-delete" ||
                                data === "client-add" ||
                                data === "client-detail" ||
                                data === "client-edit" ||
                                data === "client-job" ||
                                data === "client-contact-info" ||
                                // data === "client-contact-person-info" ||
                                data === "client-billing-detail"
                            ) && (
                              <Route
                                path="/clientCompany/details"
                                element={<ClientCompanyTabs />}
                              />
                            )}
                          {permissions &&
                            permissions.find(
                              (data) =>
                                data === "job-list" ||
                                data === "job-add" ||
                                data === "job-edit" ||
                                data === "job-delete" ||
                                data === "job-detail" ||
                                data === "job-assign-employee" ||
                                data === "job-task-list"
                            ) && (
                              <Route
                                path="/requirement"
                                element={<RequirementTable />}
                              />
                            )}
                          {permissions && //
                            permissions.find(
                              (data) =>
                                data === "job-list" ||
                                data === "job-add" ||
                                data === "job-edit" ||
                                data === "job-delete" ||
                                data === "job-detail" ||
                                data === "job-assign-employee" ||
                                data === "job-task-list"
                            ) && (
                              <Route
                                path="/clientCompany/details/requirement"
                                element={<RequirementTable />}
                              />
                            )}
                          {permissions &&
                            permissions.find(
                              (data) =>
                                data === "job-list" ||
                                data === "job-add" ||
                                data === "job-edit" ||
                                data === "job-delete" ||
                                data === "job-detail" ||
                                data === "job-assign-employee" ||
                                data === "job-task-list"
                            ) && (
                              <Route
                                path="/JobDetailPage"
                                element={<RequirementJobDetailPage />}
                              />
                            )}
                          {permissions &&
                            permissions.find(
                              (data) =>
                                data === "Task-add" ||
                                data === "Task-edit" ||
                                data === "Task-delete" ||
                                data === "Task-detail" ||
                                data === "Task-list-view" ||
                                data === "Task-calendar-view" ||
                                data === "interview-add" ||
                                data === "interview-edit" ||
                                data === "interview-detail" ||
                                data === "interview-delete" ||
                                data === "interview-status-update" ||
                                data === "Task-status-update"
                            ) && (
                              <Route
                                path="/taskCalendar"
                                element={
                                  <TaskCalendarTabs
                                    tokenData={token}
                                    onProfile={profile}
                                  />
                                }
                              />
                            )}
                          {permissions &&
                            permissions.find(
                              (data) =>
                                data === "Task-add" ||
                                data === "Task-edit" ||
                                data === "Task-delete" ||
                                data === "Task-detail" ||
                                data === "Task-list-view" ||
                                data === "Task-calendar-view" ||
                                data === "interview-add" ||
                                data === "interview-edit" ||
                                data === "interview-detail" ||
                                data === "interview-delete" ||
                                data === "interview-status-update" ||
                                data === "Task-status-update"
                            ) && (
                              <Route
                                path="/taskCalendar/interview"
                                element={<TaskDetails />}
                              />
                            )}

                          {permissions &&
                            permissions.find(
                              (data) =>
                                data === "recruitment-list" ||
                                data === "recruitment-delete" ||
                                data === "recruitment-detail" ||
                                data === "recruitment-candidate-pool" ||
                                data === "recruitment-add-candidate" ||
                                data === "recruitment-candidate-detail" ||
                                data ===
                                  "recruitment-candidate-status update" ||
                                data === "recruitment-candidate-mail-list" ||
                                data === "recruitment-candidate-mail-add" ||
                                data === "recruitment-candidate-timeline" ||
                                data === "recruitment-candidate-task-list" ||
                                data ===
                                  "recruitment-candidate-download-resume" ||
                                data ===
                                  "recruitment-candidate-download-recording" ||
                                data === "recruitment-candidate-call" ||
                                data ===
                                  "recruitment-candidate-interview-list" ||
                                data === "recruitment-candidate-offerLetter" ||
                                data === "recruitment-delete-candidate"
                              // data === "recruitment-candidate-jobs"
                            ) && (
                              <Route
                                path="/recruitment"
                                element={<RecruitmentTable />}
                              />
                            )}
                          {permissions &&
                            permissions.find(
                              (data) =>
                                data === "recruitment-list" ||
                                data === "recruitment-delete" ||
                                data === "recruitment-detail" ||
                                data === "recruitment-candidate-pool" ||
                                data === "recruitment-add-candidate" ||
                                data === "recruitment-candidate-detail" ||
                                data ===
                                  "recruitment-candidate-status update" ||
                                data === "recruitment-candidate-mail-list" ||
                                data === "recruitment-candidate-mail-add" ||
                                data === "recruitment-candidate-timeline" ||
                                data === "recruitment-candidate-task-list" ||
                                data ===
                                  "recruitment-candidate-download-resume" ||
                                data ===
                                  "recruitment-candidate-download-recording" ||
                                data === "recruitment-candidate-call" ||
                                data ===
                                  "recruitment-candidate-interview-list" ||
                                data === "recruitment-candidate-offerLetter" ||
                                data === "recruitment-delete-candidate"
                              // data === "recruitment-candidate-jobs"
                            ) && (
                              <Route
                                path="/recruitment/recruitmentJobdetails"
                                element={
                                  <RecruitmentJobDetailPage profile={profile} />
                                }
                              />
                            )}
                          {permissions &&
                            permissions.find(
                              (data) => data === "recruitment-candidate-pool"
                            ) && (
                              <Route
                                path="/candidateForPool"
                                element={<CandidateForPool />}
                              />
                            )}
                          <Route
                            path="/candidates/suggested"
                            element={<SuggestedCandidates />}
                          />
                          {permissions &&
                            permissions.find(
                              (data) => data === "recruitment-add-candidate"
                            ) && (
                              <Route
                                path="/importCandidate"
                                element={<ImportCandidate />}
                              />
                            )}

                          {permissions &&
                            permissions.find((data) => data === "report") && (
                              <>
                                <Route
                                  path="/reports/candidatesummary"
                                  element={<ReportsCandidateSummary />}
                                />
                                <Route
                                  path="/reports/candidatedetailtable"
                                  element={<CandidateReportsDetailTable />}
                                />
                                <Route
                                  path="/reports/companydetailtable"
                                  element={<ReportsCompanyDetail />}
                                />
                                <Route
                                  path="/reports/jobdetailtable"
                                  element={<ReportsJobDetails />}
                                />
                                <Route
                                  path="/reports/job/candidates"
                                  element={<ReportsJobCandidates />}
                                />
                                <Route
                                  path="/reports/employee"
                                  element={<RecuritmentReport />}
                                />
                                <Route
                                  path="/reports/employee/jobs"
                                  element={<RecuritmentJob />}
                                />
                                <Route path="/charts" element={<Charts />} />
                                <Route
                                  path="/piecharts"
                                  element={<Piechart />}
                                />
                                <Route
                                  path="/barcharts"
                                  element={<Barchart />}
                                />
                              </>
                            )}
                        </Routes>
                      </Content>
                    </Layout>
                  </Layout>
                ) : (
                  <Skeleton />
                )
              ) : (
                <Layout style={{ minHeight: "100vh" }}>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        email && code ? (
                          <ConfirmPassword
                            setState={setState}
                            code={code}
                            email={email}
                          />
                        ) : (
                          <Login />
                        )
                      }
                    />
                    {/* Display outside  */}

                    {/* end of outside job display  */}

                    {/* demo DemoDetail */}

                    <Route path="/demo/:id" element={<DemoDetail />} />

                    {/* end of demo  */}

                    <Route path="*" element={<ErrorPage onPNF={0} />} />

                    <Route
                      path="/forgotpassword"
                      element={<ForgotPassword />}
                    />
                  </Routes>
                </Layout>
              )}
            </ConfigProvider>
          )}
        </React.Fragment>
      </HashRouter>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.authentication.accessToken
      ? state.authentication.accessToken
      : localStorage.getItem("token"),
    settings: state.settings.settings,
    permissions: state.authpermissions.permissions,
    profile: state.authentication.profileData,
    localStorageHandler: state.authentication.localStorageHandler,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    refreshAction: () => {
      dispatch(refreshAction());
    },
    profileAction: () => {
      dispatch(profileAction());
    },
    permissionsHandler: () => {
      dispatch(permissionsAction());
    },
    getSettingsAction: () => {
      dispatch(getSettingsAction());
    },
    putNotificationStoreTokenHandler: (data) => {
      dispatch(putNotificationStoreTokenAction(data));
    },
    getNotificationsHandler: () => {
      dispatch(getNotificationsAction());
    },
    logoutReducer: () => {
      dispatch(logoutReducer());
    },
    logoutWhenPermissionChangedAction: () => {
      dispatch(logoutWhenPermissionChangedAction());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
