import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Tag,
} from "antd";
import { Option } from "antd/lib/mentions";
import React, { useEffect } from "react";
import { CloseIcon, FilterOutlinedIcon } from "../../IconsComp/Icons";
import commonStyles from "../../UI/CommonCss.module.css";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import { useState } from "react";
import ResetButton from "../../UI/ResetButton";

function RecruitmentCandidateFilter(props) {
  const [form] = Form.useForm();
  const [uniqueName, setUniqueName] = useState([]);
  const [uniqueTeamMember, setUniqueTeamMember] = useState([]);
  const [uniqueEmail, setUniqueEmail] = useState([]);
  const [uniquePhone, setUniquePhone] = useState([]);
  useEffect(() => {
    if (props.candidates) {
      let nameUn = new Set(props.candidates.map((data) => data.name));
      let teamMemberUn = new Set(
        props.candidates.map((data) => data.teamMember)
      );
      let emailUn = new Set(props.candidates.map((data) => data.email));
      let phoneUn = new Set(props.candidates.map((data) => data.phone));
      setUniqueName([...nameUn]);
      setUniqueTeamMember([...teamMemberUn]);
      setUniqueEmail([...emailUn]);
      setUniquePhone([...phoneUn]);
    }
  }, [props.candidates]);

  useEffect(() => {
    let filterData = localStorage.getItem("recuritmentCandidateFilter");
    if (filterData) {
      let data = JSON.parse(filterData);
      console.log(data);
      form.setFieldsValue({
        stage: data.stage,
        name: data.name,
        teamMember: data.teamMember,
        email: data.email,
        phone: data.phone,
        tags: data.tags,
        location: data.location,
        experience: data.experience,
        specialAbility: data.specialAbility,
      });
      setCheckedSpecialAbilities(data.specialAbility == "1" ? true : false);
    }
  }, []);

  const [checkedSpecialAbilities, setCheckedSpecialAbilities] = useState(false);
  const [location, setLocation] = useState(false);
  const [experience, setExperience] = useState(false);
  const [filterDatas, setFilterDatas] = useState({
    name: "",
    teamMember: "",
    email: "",
    phone: "",
    tags: "",
  });

  const tagRenderForMobileNum = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="var(--primaryColor)"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          padding: "5px 17px 5px 23px",
        }}
      >
        {label}
      </Tag>
    );
  };

  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="var(--primaryColor)"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          padding: "5px 17px 5px 23px",
        }}
      >
        {label}
      </Tag>
    );
  };

  const onFinish = (values) => {
    const data = {
      ...values,
      email: values.email && values.email.length > 0 ? values.email : "",
      phone: values.phone ? values.phone : "",
      skills: values.skills && values.skills.length > 0 ? values.skills : "",
      location: values.location ? values.location : "",
      tags: values.tags && values.tags.length > 0 ? values.tags : "",
      experience: values.experience ? values.experience : "",
      specialAbility: checkedSpecialAbilities ? "1" : "",
      name: values.name ? values.name : "",
      applyfilter: "clear selected",
    };
    props.recruitmentFilterHandler(data);
    props.onCloseFilter();
  };

  return (
    <>
      <Drawer
        className={`modalModified `}
        open={props.openFilter}
        width={500}
        height={48}
        closable={false}
        extra={
          <div
            onClick={() => props.onCloseFilter()}
            className={commonStyles.coursorPointer}
          >
            <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
          </div>
        }
        title={
          <>
            <Row>
              <Col>
                <FilterOutlinedIcon color={"var(--primaryColor)"} />
              </Col>
              <Col>
                <p
                  style={{ fontSize: "20px", margin: "0px 0px 0px 5px" }}
                  className={styles.txtBlueClr}
                >
                  Filter
                </p>
              </Col>
            </Row>
          </>
        }
        footer={false}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row
            className={styles.modalRowStyling}
            style={{ marginBottom: "80px" }}
          >
            <Col flex={1}>
              <Row>
                <Col flex={1}>
                  <Form.Item name="stage" label="Hiring Status">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      allowClear
                      // className={"candidateSelectHeight"}
                      style={{ width: "100%" }}
                      placeholder="Select hiring status"
                    >
                      {props.get_all_status &&
                        props.get_all_status.map((data, index) => {
                          return (
                            <Select.OptGroup
                              key={index}
                              label={<b>{data.statusType}</b>}
                            >
                              {data.status &&
                                data.status.map((i, j) => {
                                  return (
                                    <Select.Option value={i}>{i}</Select.Option>
                                  );
                                })}
                            </Select.OptGroup>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item label="Name" name="name">
                    <Select
                      // className={"candidateSelectHeight"}
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select name"
                      notFoundContent={null}
                      tagRender={tagRenderForMobileNum}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onKeyUp={(val) => {
                        setFilterDatas({
                          name: val.target.value,
                        });
                      }}
                    >
                      {filterDatas.name &&
                        filterDatas.name !== " " &&
                        filterDatas.name.length > 0 &&
                        uniqueName &&
                        uniqueName.map((data, index) => {
                          return (
                            <Select.Option key={index} value={data}>
                              {data}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item label="Reporting Manager" name="teamMember">
                    <Select
                      showSearch
                      // className={"candidateSelectHeight"}
                      mode="multiple"
                      allowClear
                      notFoundContent={null}
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select team member"
                      tagRender={tagRenderForMobileNum}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onKeyUp={(val) => {
                        setFilterDatas({
                          teamMember: val.target.value,
                        });
                      }}
                    >
                      {/* {filterDatas.teamMember &&
                        filterDatas.teamMember !== " " &&
                        filterDatas.teamMember.length > 0 &&
                        props.candidates &&
                        props.candidates.map((data, index) => {
                          return (
                            <Select.Option key={index} value={data.teamMember}>
                              {data.teamMember}
                            </Select.Option>
                          );
                        })} */}
                      {/* {filterDatas.teamMember &&
                        filterDatas.teamMember !== " " &&
                        filterDatas.teamMember.length > 0 &&
                        uniqueTeamMember &&
                        uniqueTeamMember.map((data, index) => {
                          return (
                            <Select.Option key={index} value={data}>
                              {data}
                            </Select.Option>
                          );
                        })} */}
                      {filterDatas.teamMember &&
                        filterDatas.teamMember !== " " &&
                        filterDatas.teamMember.length > 0 &&
                        props.employees &&
                        props.employees.map((data, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={data.firstName + " " + data.lastName}
                            >
                              {`${data.firstName} ${data.lastName}`}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item label="Email" name="email">
                    <Select
                      showSearch
                      // className={"candidateSelectHeight"}
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      notFoundContent={null}
                      placeholder="Select email"
                      tagRender={tagRenderForMobileNum}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onKeyUp={(val) => {
                        setFilterDatas({
                          email: val.target.value,
                        });
                      }}
                    >
                      {filterDatas.email &&
                        filterDatas.email !== " " &&
                        filterDatas.email.length > 0 &&
                        uniqueEmail &&
                        uniqueEmail.map((data, index) => {
                          return (
                            <Select.Option key={index} value={data}>
                              {data}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item label="Mobile Number" name="phone">
                    <Select
                      showSearch
                      // className={"candidateSelectHeight"}
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      notFoundContent={null}
                      placeholder="Select mobile number"
                      tagRender={tagRenderForMobileNum}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onKeyUp={(val) => {
                        setFilterDatas({
                          phone: val.target.value,
                        });
                      }}
                    >
                      {filterDatas.phone &&
                        filterDatas.phone !== " " &&
                        filterDatas.phone.length > 0 &&
                        uniquePhone &&
                        uniquePhone.map((data, index) => {
                          return (
                            <Select.Option key={index} value={data}>
                              {data}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item label="Key Skills/Tag" name="tags">
                    <Select
                      showSearch
                      notFoundContent={null}
                      // className={"candidateSelectHeight"}
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select skills"
                      tagRender={tagRender}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onKeyUp={(val) => {
                        setFilterDatas({
                          tags: val.target.value,
                        });
                      }}
                    >
                      {filterDatas.tags &&
                        filterDatas.tags !== " " &&
                        filterDatas.tags.length > 0 &&
                        props.TagsData &&
                        props.TagsData.filter(
                          (data) => data.type === "candidate"
                        ).map((data) => {
                          return (
                            <Option key={data.id} value={data.tag}>
                              {data.tag}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row>
                <Col flex={1}>
                  <Form.Item label="Team Member" name="teamMember">
                    <Select
                      // className={"candidateSelectHeight"}
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select team member"
                      tagRender={tagRenderForMobileNum}
                    >
                      {props.candidates &&
                        props.candidates.map((data) => {
                          return (
                            <Select.Option
                              key={data.id}
                              value={data.teamMember}
                            >
                              {data.teamMember}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row> */}

              <Row>
                <Col flex={1}>
                  <Form.Item label="Location" name="location">
                    <Input
                      onChange={() => {
                        if (!location) {
                          setLocation(true);
                        }
                      }}
                      // className={"CandidateInputHeight"}
                      placeholder="Enter location"
                    />
                  </Form.Item>
                  {location && (
                    <ResetButton
                      onClearData={() => {
                        form.setFieldsValue({
                          location: "",
                        });
                        setLocation(false);
                      }}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item label="Experience" name="experience">
                    <Input
                      onChange={() => {
                        if (!experience) {
                          setExperience(true);
                        }
                      }}
                      // className={"CandidateInputHeight"}
                      placeholder="Enter experience"
                    />
                  </Form.Item>
                  {experience && (
                    <ResetButton
                      onClearData={() => {
                        form.setFieldsValue({
                          experience: "",
                        });
                        setExperience(false);
                      }}
                    />
                  )}
                </Col>
              </Row>

              <Row align="middle" style={{ gap: "17px" }}>
                <Col>
                  <p className={`${commonStyles.formInputLabel} `}>
                    Special abilities
                  </p>
                </Col>
                <Col>
                  <Form.Item name="specialAbility">
                    <Switch
                      onChange={(val) => {
                        setCheckedSpecialAbilities(val);
                      }}
                      checked={checkedSpecialAbilities}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col className={commonStyles.footerShadow} style={{ width: "503px" }}>
            <Row justify="end" style={{ gap: "25px" }}>
              <Col>
                <Button
                  className={commonStyles.buttonSettingsSecondary}
                  type="default"
                  onClick={() => {
                    setLocation(false);
                    setExperience(false);
                    form.resetFields();
                    setFilterDatas({
                      name: "",
                      teamMember: "",
                      email: "",
                      phone: "",
                      tags: "",
                    });
                    props.onCloseFilter();
                    setCheckedSpecialAbilities(false);
                    localStorage.removeItem("recuritmentCandidateFilter");
                    localStorage.removeItem("fromWhichPage");
                    localStorage.removeItem("recuritmentCandidateSort");
                    props.recruitmentFilterHandler({ current_page: 1 });
                  }}
                >
                  Reset Filter
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    let recuritmentCandidateFilter = JSON.parse(
                      localStorage.getItem("recuritmentCandidateFilter")
                    );
                    localStorage.setItem(
                      "recuritmentCandidateFilter",
                      JSON.stringify({
                        ...form.getFieldsValue(),
                        ...recuritmentCandidateFilter,
                      })
                    );
                    localStorage.setItem(
                      "fromWhichPage",
                      JSON.stringify({
                        fromRecuritment: "fromRecuritment",
                      })
                    );
                    form.submit();
                  }}
                  htmlType="button"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
}

export default RecruitmentCandidateFilter;
