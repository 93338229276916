import { Card, Select, Table } from "antd";
import React from "react";
import styles from "../../AddCandidatesComp/AddCandidatesComp.module.css";
import commonCssStyles from "../../../UI/CommonCss.module.css";

const columns = [
  {
    title: (
      <p
        className={`${commonCssStyles.tableHeadingsColor} ${commonCssStyles.marginBottomRemove}`}
      >
        Task Name
      </p>
    ),
    dataIndex: "taskName",
    render: (data) => {
      return (
        <p
          className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.tableHeadingTextData}`}
        >
          {data ? data : ""}
        </p>
      );
    },
  },
  {
    title: (
      <p
        className={`${commonCssStyles.tableHeadingsColor} ${commonCssStyles.marginBottomRemove}`}
      >
        Assigned by
      </p>
    ),
    dataIndex: "assignedBy",
    render: (data) => {
      return (
        <p
          className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.tableHeadingTextData}`}
        >
          {data ? data : ""}
        </p>
      );
    },
  },
  {
    title: (
      <p
        className={`${commonCssStyles.tableHeadingsColor} ${commonCssStyles.marginBottomRemove}`}
      >
        Start Date
      </p>
    ),
    dataIndex: "startDate",
    render: (data) => {
      return (
        <p
          className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.tableHeadingTextData}`}
        >
          {data ? data : ""}
        </p>
      );
    },
  },
  {
    title: (
      <p
        className={`${commonCssStyles.tableHeadingsColor} ${commonCssStyles.marginBottomRemove}`}
      >
        Due Date
      </p>
    ),
    dataIndex: "dueDate",
    render: (data) => {
      return (
        <p
          className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.tableHeadingTextData}`}
        >
          {data ? data : ""}
        </p>
      );
    },
  },
  {
    title: (
      <p
        className={`${commonCssStyles.tableHeadingsColor} ${commonCssStyles.marginBottomRemove}`}
      >
        Status
      </p>
    ),
    dataIndex: "status",
    render: (text) =>
      text === "Active" ? (
        <Select
          size="small"
          className="intellselecttask"
          defaultValue={"Pending"}
          style={{ color: "#FF9A02", width: "120px" }}
        >
          <Select.Option value="1">Pending</Select.Option>
          <Select.Option value="2">Completed</Select.Option>
        </Select>
      ) : (
        <Select
          size="small"
          className="intellselecttask"
          defaultValue={"Completed"}
          style={{ color: "#0E8553", width: "120px" }}
        >
          <Select.Option value="1">Pending</Select.Option>
          <Select.Option value="2">Completed</Select.Option>
        </Select>
      ),
  },
];
const data = [
  {
    key: "1",
    taskName: "Assignment",
    assignedBy: "Anupam Singhania ",
    startDate: "20/19/2022",
    dueDate: "24/19/2022",
    status: "Active",
  },
  {
    key: "2",
    taskName: "Assignment",
    assignedBy: "Anupam Singhania ",
    startDate: "20/19/2022",
    dueDate: "24/19/2022",
    status: "Completed",
  },
];
function ClientCompanyTask() {
  return (
    <>
      <Card style={{ paddingBottom: "40vh" }}>
        <Table
          scroll={{ x: "auto" }}
          style={{ marginTop: "-12px" }}
          className="taskStyle"
          pagination={false}
          columns={columns}
          dataSource={data}
        />
      </Card>
    </>
  );
}
export default ClientCompanyTask;
