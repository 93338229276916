import { Col, Modal, Row, Table } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import { CaretRightOutlined } from "@ant-design/icons";
import { useState } from "react";
import { CloseIcon } from "../../../IconsComp/Icons";

function CandidateCall(props) {
  const [openRecordingModal, setOpenRecordingModal] = useState(false);

  const [callRecordStore, setCallRecordStore] = useState("");
  const closeRecordingModal = () => {
    setOpenRecordingModal(false);
  };

  const Colss = [
    {
      title: "Recordings",
      dataIndex: "",
      render: (text) => {
        return (
          <>
            <audio
              controls
              controlsList="nodownload"
              style={{ height: "40px" }}
            >
              <source src={`${text}`} />
            </audio>
          </>
        );
      },
    },
  ];

  const recordingColumns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Recordings
        </p>
      ),
      width: "50%",
      dataIndex: "link",
      render: (data, render, i) => {
        return (
          <>
            {render.link &&
              render.link.map((x, i) => {
                return (
                  <>
                    <audio
                      controls
                      controlsList="nodownload"
                      style={{ height: "40px" }}
                    >
                      <source src={`${x}`} />
                    </audio>
                  </>
                );
              })}
          </>
        );
      },
      key: "link",
    },
  ];

  const employeeRecordingColumns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Recordings
        </p>
      ),
      width: "50%",
      dataIndex: "link",
      render: (data, render, i) => {
        return (
          <>
            {render.link.length > 0
              ? render.link.map((x, i) => {
                  return (
                    <>
                      <audio
                        controls
                        controlsList="nodownload"
                        style={{ height: "40px" }}
                      >
                        <source src={`${x}`} />
                      </audio>
                    </>
                  );
                })
              : ""}
          </>
        );
      },
      key: "link",
    },
  ];

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const columns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Employee Name
        </p>
      ),
      dataIndex: "employeeName",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Number
        </p>
      ),
      dataIndex: "phone",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Duration
        </p>
      ),
      dataIndex: "duration",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Date & Time
        </p>
      ),
      dataIndex: "logTime",
      render: (text, render) => {
        return (
          <>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <p
                      className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
                    >
                      {text ? moment(text).format(dataFormate) : "N/A"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p
                      className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
                    >
                      {text
                        ? moment(text).format(
                            text === "24hours" ? "HH:mm" : "hh:mm a"
                          )
                        : "N/A"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Recorded Calls
        </p>
      ),
      dataIndex: "recordingLink",
      render: (text, render) => (
        <>
          {render.linkCount > 0 ? (
            <Row align="middle">
              <Col>
                <CaretRightOutlined
                  style={{ fontSize: "40px", color: "var(--icons-color)" }}
                />
              </Col>
              <Col
                className={`${commonCssStyle.coursorPointer}`}
                onClick={() => {
                  setOpenRecordingModal(true);
                  setCallRecordStore(render.link);
                }}
              >
                <p
                  className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
                >
                  {render.linkCount ? "(" + render.linkCount + ")" : ""}
                </p>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  //colums for employee
  const EmployeeTablecolumns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Candidate Name
        </p>
      ),
      dataIndex: "candidateName",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Number
        </p>
      ),
      dataIndex: "phone",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Duration
        </p>
      ),
      dataIndex: "duration",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Date & Time
        </p>
      ),
      dataIndex: "logTime",
      render: (text, render) => {
        return (
          <>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <p
                      className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
                    >
                      {text ? moment(text).format(dataFormate) : "N/A"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p
                      className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
                    >
                      {text
                        ? moment(text).format(
                            text === "24hours" ? "HH:mm" : "hh:mm a"
                          )
                        : "N/A"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        );
      },
    },
    // props.permissions &&
    //   props.permissions.find(
    //     (data) => data === "candidate-download-recording"
    //   ) &&
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Recorded Calls
        </p>
      ),
      dataIndex: "recordingLink",
      render: (text, render) => (
        <>
          {render.linkCount > 0 ? (
            <Row align="middle">
              <Col>
                <CaretRightOutlined
                  style={{ fontSize: "40px", color: "var(--icons-color)" }}
                />
              </Col>
              <Col
                className={`${commonCssStyle.coursorPointer}`}
                onClick={() => {
                  setOpenRecordingModal(true);
                  setCallRecordStore(render.link);
                }}
              >
                <p
                  className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
                >
                  {render.linkCount ? "(" + render.linkCount + ")" : ""}
                </p>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];
  return (
    <>
      {props.employeeCalls ? (
        <Table
          className="importCandidateTable"
          columns={EmployeeTablecolumns}
          dataSource={props.employeeCalls}
          pagination={{
            showSizeChanger: false,
          }}
        />
      ) : props.candidateCalls.length > 0 ? (
        <Table
          className="importCandidateTable"
          columns={columns}
          dataSource={props.candidateCalls}
          pagination={{
            showSizeChanger: false,
          }}
        />
      ) : props.RecruitmentCalls.length > 0 ? (
        <Table
          className="importCandidateTable"
          columns={columns}
          dataSource={props.RecruitmentCalls}
          pagination={{
            showSizeChanger: false,
          }}
        />
      ) : (
        <Table
         className="importCandidateTable"
          columns={columns} 
          pagination={{
            showSizeChanger: false,
          }}
          />
      )}

      <Modal
        open={openRecordingModal}
        onCancel={closeRecordingModal}
        footer={false}
        closable={false}
        width={550}
        title={
          <Row>
            <Col md={20}>
              <p
                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.popUpHeadingText} ${commonCssStyle.textBreak}`}
              >
                Recorded Calls
              </p>
            </Col>
            <Col md={4}>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  setOpenRecordingModal(false);
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
      >
        <Table
          style={{ marginTop: "-22px" }}
          className="importCandidateTable"
          dataSource={callRecordStore && callRecordStore}
          columns={Colss}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    permissions: state.authpermissions.permissions,
    settindsData: state.settings.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateCall);
