import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardCards: false,
};
export const LoadingIconSlice = createSlice({
  name: "loadingIcon",
  initialState,
  reducers: {
    getLoadingDashboardCard(state, action) {
      state.dashboardCards = action.payload;
    },
  },
});

export const { getLoadingDashboardCard } = LoadingIconSlice.actions;
export default LoadingIconSlice.reducer;
