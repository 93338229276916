import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import {
  CaretBlackDownIcon,
  Filedownload,
  FilterOutlinedIcon,
  PlusIcon,
  TrashIcon,
} from "../../../IconsComp/Icons";
import { Link } from "react-router-dom";
import {
  getReportsCompanyAction,
  exportReportsCompanyDetaisAction,
} from "../../../../Reusable/Store/Action/ReportsAction";
import { connect } from "react-redux";
import moment from "moment";
import ReportsCompanyDetailFilter from "./ReportsCompanyDetailFilter";
import { useForm } from "antd/lib/form/Form";

function ReportsCompanyDetail(props) {
  const [form] = useForm();
  const [cfilter, setCFilter] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  const [fromDate, setFromDate] = useState("1970-01-01");
  const [toDate, setToDate] = useState("3000-01-01");

  const [menuKey, setMenuKey] = useState("1");
  useEffect(() => {
    const obj = JSON.parse(
      localStorage.getItem("reportsCompanyDetailFilterData")
    );
    if (obj) {
      props.getCompanyReportsHandler(obj);
    } else {
      props.getCompanyReportsHandler({ current_page: 1 });
    }
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys, ...newSelectedRowKeys]);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const clientFilterHandleer = (values) => {
    setCFilter(values);
    const data = {
      ...values,
      current_page: 1,
      type: "list",
    };
    props.getCompanyReportsHandler(data);
    localStorage.setItem(
      "reportsCompanyDetailFilterData",
      JSON.stringify(data)
    );
  };
  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const fetchRecords = (page) => {
    let fdatacomprep = JSON.parse(localStorage.getItem("reportsCompanyDetailFilterData"));
    
    if(fdatacomprep){
      const val = {
        ...cfilter,
        ...fdatacomprep,
        ...props.candidatesFilterData,
        current_page: page,
      };

      localStorage.setItem("reportsCompanyDetailFilterData",JSON.stringify({...fdatacomprep,current_page:page}))
      props.getCompanyReportsHandler(val);
    }else{
      const val = {
        ...cfilter,
        ...props.candidatesFilterData,
        current_page: page,
      };
      localStorage.setItem("reportsCompanyDetailFilterData",JSON.stringify({current_page:page}))
      props.getCompanyReportsHandler(val);
    }
    
   
  };

  function showFilterHandler() {
    setOpenFilter(true);
  }
  const closeFilterHandler = () => {
    setOpenFilter(false);
  };

  const menu2 = (
    <Menu
      style={{ width: "151px", margin: "0px 45px" }}
      selectable
      defaultSelectedKeys={["1"]}
      items={[
        {
          key: "3",
          label: (
            <p
              style={{ marginTop: "7px" }}
              className={`${commonCssStyle.externalJobTextrole}`}
              onClick={() => {
                const data = {
                  current_page: 1,
                  ...cfilter,
                  dateFrom: fromDate,
                  dateTo: toDate,
                  type: "export",
                  exportType: "excel",
                };
                props.exportCompanyDetailsReportHandler(data);
              }}
            >
              Export to Excel
            </p>
          ),
        },
        {
          key: "4",
          label: (
            <p
              style={{ marginTop: "7px" }}
              className={`${commonCssStyle.externalJobTextrole}`}
              onClick={() => {
                const data = {
                  current_page: 1,
                  ...cfilter,
                  dateFrom: fromDate,
                  dateTo: toDate,
                  type: "export",
                  exportType: "pdf",
                };
                props.exportCompanyDetailsReportHandler(data);
              }}
            >
              Export to PDF
            </p>
          ),
        },
      ]}
    />
  );

  const columns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Company Name
        </p>
      ),
      dataIndex: "clientName",
      // width: "17%",
      render: (text, record) => (
        <Link
          to={"/reports/jobdetailtable"}
          // state={{ id: record.id, Companyname: text }}
          state={{ id: text, Companyname: text }}
        >
          <p
            style={{ cursor: "pointer" }}
            className={commonCssStyle.tableDataLinkText}
          >
            {text}
          </p>
        </Link>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Contact Person
        </p>
      ),
      dataIndex: "contactPerson",
      // width: "17%",
      // render: (data) => {
      //   props.permissions &&
      //   props.permissions.find(
      //     (data) => data === "client-contact-person-info"
      //   ) ? (
      //     <p
      //       className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
      //     >
      //       {data && data}
      //     </p>
      //   ) : (
      //     <p
      //       className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.infoTextColorBold}`}
      //     >
      //       *************
      //     </p>
      //   );
      // },
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Contact Person Email & Number
        </p>
      ),
      dataIndex: "contactPersonEmail",
      // width: "23%",
      render: (text, record) => (
        <>
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
            style={{ marginBottom: "9px" }}
          >
            {record.contactPersonEmail}
          </p>
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {record.contactPersonPhone}
          </p>
        </>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          No of Hires
        </p>
      ),
      dataIndex: "hireCount",
      // width: "11%",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
            style={{ marginLeft: "19px" }}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Active Jobs
        </p>
      ),
      dataIndex: "activeJobs",
      // width: "11%",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
            style={{ marginLeft: "19px" }}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Company Status
        </p>
      ),
      // align: "center",
      dataIndex: "status",
      // width: "20%",
      render: (text, record) => (
        <>
          <Row
            align="middle"
            // wrap={false}
            // style={{ gap: "24px", float: "right" }}
          >
            <Col>
              <Tag
                className={
                  text === "active"
                    ? commonCssStyle.activeTag
                    : commonCssStyle.inactiveTag
                }
              >
                {text}
              </Tag>
            </Col>
          </Row>
        </>
      ),
    },
  ];
  const handleProvinceChange = (value,d) => {
    localStorage.setItem("ReportsCompanyReport1Sort",JSON.stringify(d.value))
    let reportsCompanyDetailFilter = JSON.parse(
      localStorage.getItem("reportsCompanyDetailFilterData")
    );
    let sort = value.split("_");

    if (reportsCompanyDetailFilter) {
      props.getCompanyReportsHandler({
        current_page: 1,
        ...cfilter,
        ...reportsCompanyDetailFilter,
        sortBy: sort[0],
        sortOrder: sort[1],
        type: "list",
      });
      setCFilter({
        current_page: 1,
        ...cfilter,
        ...reportsCompanyDetailFilter,
        sortBy: sort[0],
        sortOrder: sort[1],
        type: "list",
      });
      localStorage.setItem("reportsCompanyDetailFilterData",JSON.stringify({...reportsCompanyDetailFilter, sortBy: sort[0],
        sortOrder: sort[1]}))
    } else {
      props.getCompanyReportsHandler({
        current_page: 1,
        ...cfilter,
        sortBy: sort[0],
        sortOrder: sort[1],
        current_page: 1,
        type: "list",
      });
      setCFilter({
        current_page: 1,
        ...cfilter,
        sortBy: sort[0],
        sortOrder: sort[1],
        type: "list",
      });
      localStorage.setItem("reportsCompanyDetailFilterData",JSON.stringify({current_page: 1,
        ...cfilter, sortBy: sort[0],
        sortOrder: sort[1]}))
    }
  };

  if (window.location.hash === "#/reports/companydetailtable") {
    localStorage.removeItem("clientCompany");
    localStorage.removeItem("employee");
    localStorage.removeItem("requirementFilterData");
    localStorage.removeItem("recuritmentFilterData");
    localStorage.removeItem("taskFilterData");
    localStorage.removeItem("interviewFilterData");
    localStorage.removeItem("candidateDetailFromRequirement");
    localStorage.removeItem("candidateListForPool");
    localStorage.removeItem("recuritmentCandidateFilter");
    localStorage.removeItem("fromWhichPage");
    localStorage.removeItem("candidateDetailFromRecruitment");
    localStorage.removeItem("recuritmentReportFilter");
    localStorage.removeItem("employeeReportsFilter");

    localStorage.removeItem("ClientCompanySort");
localStorage.removeItem("employeeSort");
localStorage.removeItem("reportsjobcandidatefilterdataSort");
localStorage.removeItem("ReportsJobDetailFilterOneSort");
localStorage.removeItem("reportRecruitmentEmployeeSort");

localStorage.removeItem("candidateSort");
localStorage.removeItem("interivewSort");
localStorage.removeItem("taskSort");
localStorage.removeItem("recuritmentSort");
localStorage.removeItem("requirementSort");
  }

  return (
    <>
      <Breadcrumb className={commonCssStyle.breadcrumbBottomMargin}>
        <Breadcrumb.Item>
          <Link to={"/"}>
            <p
              className={`${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove} ${commonCssStyle.breadcrumbText}`}
            >
              Home
            </p>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/reports/companydetailtable"}>
            <p
              className={`${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove} ${commonCssStyle.breadcrumbText}`}
            >
              Company
            </p>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col md={24}>
          <Row align="middle" justify="space-between">
            <Col>
              <Row>
                <Col /* xs={24} sm={24} md={24} */>
                  <h1
                    style={{ marginRight: "31px" }}
                    className={`${commonCssStyle.mainHeadingText} ${commonCssStyle.inttitle}`}
                  >
                    Company Report &#40;{" "}
                    {props.companyReport && props.companyReport.total
                      ? props.companyReport.total
                      : "0"}{" "}
                    &#41;
                  </h1>
                </Col>

                <Col /* xs={24} sm={24} md={24} */>
                  <Button
                    onClick={showFilterHandler}
                    className={`${commonCssStyle.filterButton}`}
                    id="candidates-table-filter-btn"
                  >
                    <Row style={{ gap: "11px" }}>
                      <Col>
                        <div style={{ transform: "translateY(2px)" }}>
                          <FilterOutlinedIcon color={"var(--primaryColor)"} />
                        </div>
                      </Col>
                      <Col>
                        <p
                          className={`${commonCssStyle.filterAndSortTextStyle} ${commonCssStyle.marginBottomRemove}`}
                        >
                          Filter
                        </p>
                      </Col>
                    </Row>
                  </Button>
                </Col>

                <Col /* xs={24} sm={24} md={24} */>
                  <Button
                    style={{
                      height: "40px",
                      marginRight: "20px",
                    }}
                    className={` ${commonCssStyle.sortBtn}`}
                    id="candidates-table-sortby-btn"
                  >
                    <Row justify="space-between">
                      <Col>
                        <p style={{ transform: "translateY(2px)" }}>Sort By</p>
                      </Col>
                      <Col>
                        <Select
                          value={JSON.parse(localStorage.getItem("ReportsCompanyReport1Sort"))
                          ?
                          JSON.parse(localStorage.getItem("ReportsCompanyReport1Sort")):"Select"
                        }
                          getPopupContainer={(trigger) => trigger.parentElement}
                          style={{
                            width: "100%",
                            // transform: "translateY(3px)",
                          }}
                          onChange={handleProvinceChange}
                          bordered={false}
                          placeholder={
                            <p
                              style={{
                                margin: "0px",
                                color: "var(--primaryColor)",
                              }}
                            >
                              Select
                            </p>
                          }
                          size="small"
                          className={`${commonCssStyle.filterDrop}  selectInput sortBySelectStyle selectTag selectTag1`}
                        >
                          <Select.Option
                            value="clientName_ASC"
                            key="0"
                            style={{ padding: "10px" }}
                          >
                            Client Name Ascending
                          </Select.Option>
                          <Select.Option
                            value="clientName_DESC"
                            key="1"
                            style={{ padding: "10px" }}
                          >
                            Client Name Descending
                          </Select.Option>
                          <Select.Option
                            value="lastUpdated_ASC"
                            key="2"
                            style={{ padding: "10px" }}
                          >
                            Last Updated Ascending
                          </Select.Option>
                          <Select.Option
                            value="lastUpdated_DESC"
                            key="3"
                            style={{ padding: "10px" }}
                          >
                            Last Updated Descending
                          </Select.Option>
                        </Select>
                      </Col>
                    </Row>
                  </Button>
                </Col>
              </Row>
            </Col>
            {/* demo  */}
            <Col>
              <Form form={form}>
                <Row justify="space-between">
                  <Col md={11}>
                    <Form.Item className="candidateReportForm" name="from">
                      <DatePicker
                        getPopupContainer={(trigger) => trigger.parentElement}
                        name="from"
                        format={dataFormate}
                        placeholder="from"
                        style={{ width: "115px", height: "40px" }}
                        className="ReportsFrom"
                        suffixIcon={null}
                        onChange={(val) => {
                          if (val !== null) {
                            props.getCompanyReportsHandler({
                              ...cfilter,
                              current_page: 1,
                              type: "list",
                              dateFrom: moment(val).format("YYYY-MM-DD"),
                              dateTo: toDate,
                            });
                            setFromDate(moment(val).format("YYYY-MM-DD"));
                          } else {
                            props.getCompanyReportsHandler({
                              ...cfilter,
                              type: "list",
                              current_page: 1,
                              dateFrom: "1970-01-01",
                              dateTo: toDate,
                            });
                            setFromDate("1970-01-01");
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={11}>
                    <Form.Item className="candidateReportForm" name="to">
                      <DatePicker
                        getPopupContainer={(trigger) => trigger.parentElement}
                        onChange={(val) => {
                          if (val !== null) {
                            props.getCompanyReportsHandler({
                              ...cfilter,
                              current_page: 1,
                              type: "list",
                              dateFrom: fromDate,
                              dateTo: moment(val).format("YYYY-MM-DD"),
                            });
                            setToDate(moment(val).format("YYYY-MM-DD"));
                          } else {
                            props.getCompanyReportsHandler({
                              ...cfilter,
                              type: "list",
                              current_page: 1,
                              dateTo: "3000-01-01",
                              dateFrom: fromDate,
                            });
                            setToDate("3000-01-01");
                          }
                        }}
                        format={dataFormate}
                        placeholder="to"
                        style={{ width: "115px", height: "40px" }}
                        className="ReportsFrom"
                        suffixIcon={null}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>

            {/* demo end  */}

            <Col xs={24} sm={24} md={24} lg={4}>
              <Row justify="end">
                <Dropdown placement="bottom" overlay={menu2}>
                  <Typography.Link>
                    <Space className={commonCssStyle.tableHeadingTextData}>
                      <Button
                        style={{ width: "151px" }}
                        className={commonCssStyle.intaddButton}
                        type="primary"
                      >
                        <Row align="middle" justify="space-around">
                          <Col>
                            <PlusIcon color={"white"} />
                          </Col>
                          <Col>Export</Col>
                          <Col>
                            <CaretBlackDownIcon color={"white"} />
                          </Col>
                        </Row>
                      </Button>
                    </Space>
                  </Typography.Link>
                </Dropdown>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={24} style={{ marginTop: "40px" }}>
          <Table
            className="ReportsTablescroll"
            scroll={{ x: "auto" }}
            pagination={{
              size: "small",
              current: props.companyReport && props.companyReport.current_page,
              pageSize: props.companyReport && props.companyReport.per_page,
              total: props.companyReport && props.companyReport.total,
              showSizeChanger: false,
              onChange: (props) => {
                fetchRecords(props);
              },
            }}
            columns={columns}
            dataSource={
              props.companyReport &&
              props.companyReport.data &&
              props.companyReport.data
            }
            rowKey="key"
          />
        </Col>
      </Row>
      <ReportsCompanyDetailFilter
        onOpenFilter={openFilter}
        onCloseFilter={closeFilterHandler}
        clientFilterHandleer={clientFilterHandleer}
        fromDate={fromDate}
        toDate={toDate}
        onResetFilter={() => {
          form.resetFields();
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    companyReport: state.reports.reportsCompany,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyReportsHandler: (val, callback) => {
      dispatch(getReportsCompanyAction(val, callback));
    },
    exportCompanyDetailsReportHandler: (val) => {
      dispatch(exportReportsCompanyDetaisAction(val));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsCompanyDetail);
