import { Form, Input, Modal, Button, Row, Col } from "antd";
import styles from "../ProfileComp/Profile.module.css";
import commonCssStyle from "../../UI/CommonCss.module.css";
import { useForm } from "antd/lib/form/Form";
import { CloseIcon } from "../../IconsComp/Icons";

function NameModal(props) {
  const [form] = useForm();
  return (
    <>
      <Modal
        className="modalModifiedForTemplete"
        footer={false}
        title={
          <Row>
            <Col md={20}>
              <h3 className={commonCssStyle.popUpHeadingText}>Edit Name</h3>
            </Col>
            <Col md={4}>
              {" "}
              <div
                style={{ float: "right" }}
                onClick={() => {
                  props.onCloseName();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
        open={props.onOpenName}
        closable={false}
        // onCancel={props.onCloseName}
      >
        <Form
          form={form}
          initialValues={{
            firstName: props.profile.firstName,
            lastName: props.profile.lastName,
          }}
          layout="vertical"
          onFinish={(val) =>
            props.restData(val, (success) => {
              if (success) props.onCloseName();
            })
          }
        >
          <Row>
            <Col md={24}>
              <Row style={{ padding: "0px 15px" }}>
                <Col md={24}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      className={styles.popUpInputField}
                      placeholder="Enter First Name"
                    />
                  </Form.Item>
                </Col>
                <Col md={24}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      className={styles.popUpInputField}
                      placeholder="Enter Last Name"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              md={24}
              className={commonCssStyle.footerShadowForTemplete}
            >
              <Row
                justify="end"
                style={{
                  position: "relative",
                  gap: "10px",
                }}
              >
                <Col>
                  <Button
                    className={commonCssStyle.buttonSettingsSecondary}
                    onClick={() => {
                      props.onCloseName();
                    }}
                    style={{
                      width: "150px",
                      height: "48px",
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    className={commonCssStyle.buttonSettingsPrimary}
                    htmlType="button"
                    onClick={() => {
                      form.submit();
                    }}
                    style={{
                      width: "150px",
                      height: "48px",
                    }}
                    type="primary"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
export default NameModal;
