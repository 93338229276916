import { Col, Row, Timeline, Card, Avatar, Empty, Form, DatePicker, Button } from "antd";
import styles from "./Employee.module.css";
import commonCssStyle from "../../UI/CommonCss.module.css";
import { ClipBoardIcon, UserPlusIcon } from "../../IconsComp/Icons";
import { connect } from "react-redux";
import { getEmployeesTimelineAction,postEmployeeTimelineFilterAction } from "../../../Reusable/Store/Action/EmployeesAction";
import { useLocation } from "react-router";
import { useEffect } from "react";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import style from "../Dashboard/ContentPage/ContentPage.module.css"


const EmployeeTimeline = (props) => {

  console.log(props.employee_timeline.kpi)


  const [cardBackgroundColor1, setCardBackgroundColor1] =
  useState("var(--theme)");
  const [cardBackgroundColor2, setCardBackgroundColor2] =
  useState("var(--theme)");
const [cardBackgroundColor3, setCardBackgroundColor3] =
  useState("var(--theme)");
const [cardBackgroundColor4, setCardBackgroundColor4] =
  useState("var(--theme)");
  const [cardBackgroundColor5, setCardBackgroundColor5] =
  useState("var(--theme)");

  let { settings } = props;
  const location = useLocation();
  useEffect(() => {
    props.getEmployeesTimelineHandler(location.state.id);
  }, [location]);

  const [form] = useForm();
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));

  const dataFormate =
  props.settindsData && props.settindsData.dateFormat
    ? props.settindsData.dateFormat
    : "DD/MM/YYYY";

    const onFinish=(val)=>{
      const data = {
        dateFrom: val.dateFrom
          ? moment(val.dateFrom).format("YYYY-MM-DD")
          : "1970-01-01",
        dateTo: val.dateTo ? moment(val.dateTo).format("YYYY-MM-DD") : "3000-01-01",
        type:"employee",
        id:location.state.id,
      };
    props.postEmpTimelineFilterHandler(data,(success)=>{
      if(success){
      }
    
    });
    }

  return (
    <Row>
       <Col sm={24} >
          <Card>
            <Form
              form={form}
              onFinish={onFinish}
            >
              <Row style={{ gap: "20px", padding: "20px 15px 0px 20px" }} justify="end">
                <Col>
                  <Form.Item name="dateFrom" style={{border:"0.5px solid var(--dr-border-color)",borderRadius:"9px"}}>
                    <DatePicker
                     suffixIcon={null}
                     className="ReportsFrom"
                      format={dataFormate}
                      placeholder="from"
                      style={{ width: "115px", height: "40px" }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="dateTo" style={{border:"0.5px solid var(--dr-border-color)",borderRadius:"9px"}}>
                    <DatePicker
                     suffixIcon={null}
                     className="ReportsFrom"
                      format={dataFormate}
                      placeholder="to"
                      style={{ width: "115px", height: "40px" }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  {" "}
                  <Button
                    className={commonCssStyle.buttonSettingsPrimary}
                    style={{
                      width: "100px",
                      height: "40px",
                      marginTop: "3px",
                    }}
                    type="primary"
                    size="small"
                    htmlType="button"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Filter
                  </Button>
                </Col>
                <Col>
                  {" "}
                  <Button
                    className={commonCssStyle.buttonSettingsSecondary}
                    style={{
                      width: "100px",
                      height: "40px",
                      marginTop: "3px",
                    }}
                    type="default"
                    size="small"
                    onClick={() => {
                      form.resetFields();
                      const data = {
                        dateFrom: moment().format("YYYY-MM-DD"),
                        dateTo: moment().format("YYYY-MM-DD"),
                        type:"employee",
                        id:location.state.id,
                      };
                      props.postEmpTimelineFilterHandler(data,(success)=>{
                        if(success){
                        }
                      
                      });
                    }}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>

        

{/* new design  */}
<Col md={24}>
          <Card>
<div className={style.contentContainer1} id="Dashboard-jobSummary">
        <div>
          <Row justify="space-evenly" align="middle">
            <Col md={2}>
              <p
                className={style.jobSummaryContent1}
                style={{ color: "var(--primaryColor)" }}
              >
                {props.employee_timeline&&
                      props.employee_timeline.kpi&&
                      props.employee_timeline.kpi.allJobs&&
                      props.employee_timeline.kpi.allJobs}
              </p>
              <p className={style.jobSummaryContent2}>Jobs</p>
            </Col>
            <Col md={1}>
              <div
                className={style.jobSummaryContentBar1}
                style={{
                  backgroundColor: "var(--secondary-card-color)",
                }}
              ></div>
            </Col>
            <Col md={5}>
              <p
                className={style.jobSummaryContent1}
                style={{ color: "var(--primaryColor)" }}
              >
                {props.employee_timeline&&
                      props.employee_timeline.kpi&&
                      props.employee_timeline.kpi.candidatesCount&&
                      props.employee_timeline.kpi.candidatesCount}
              </p>
              <p  className={style.jobSummaryContent2}>Candidate Added</p>
            </Col>
            <Col md={1}>
              <div
                className={style.jobSummaryContentBar1}
                style={{
                  backgroundColor: "var(--secondary-card-color)",
                }}
              ></div>
            </Col>
            <Col md={3}>
              <p
                className={style.jobSummaryContent1}
                style={{ color: "var(--primaryColor)" }}
              >
                {props.employee_timeline&&
                      props.employee_timeline.kpi&&
                      props.employee_timeline.kpi.interviewedCount&&
                      props.employee_timeline.kpi.interviewedCount}
              </p>
              <p className={style.jobSummaryContent2}>Interview</p>
            </Col>
            <Col md={1}>
              <div
                className={style.jobSummaryContentBar1}
                style={{
                  backgroundColor: "var(--secondary-card-color)",
                }}
              ></div>
            </Col>
            <Col md={3}>
              <p
                className={style.jobSummaryContent1}
                style={{ color: "var(--primaryColor)" }}
              >
                {props.employee_timeline&&
                      props.employee_timeline.kpi&&
                      props.employee_timeline.kpi.callsCount&&
                      props.employee_timeline.kpi.callsCount}
              </p>
              <p className={style.jobSummaryContent2}>Total Calls</p>
            </Col> 
            <Col md={1}>
              <div
                className={style.jobSummaryContentBar1}
                style={{
                  backgroundColor: "var(--secondary-card-color)",
                }}
              ></div>
            </Col>
            <Col md={4}>
              <p
                className={style.jobSummaryContent1}
                style={{ color: "var(--primaryColor)" }}
              >
                {props.employee_timeline&&
                      props.employee_timeline.kpi&&
                      props.employee_timeline.kpi.activitiesCount&&
                      props.employee_timeline.kpi.activitiesCount}
              </p>
              <p className={style.jobSummaryContent2}>Total Activities</p>
            </Col>
          </Row>
        </div>
      </div>
</Card>
</Col>
{/* end of new design  */}


      <Col sm={24}>
        <Card style={{ paddingTop: "30px" }}>
          <Timeline
            className="employeeTimeLineStyle"
            mode="left"
            style={{ width: "60%" }}
          > 
          {props.employee_timeline&&props.employee_timeline.result&&props.employee_timeline.result.length>0?props.employee_timeline.result.map(x=>{
             return <Timeline.Item
             dot={
               <Avatar
                 size={50}
                 className={commonCssStyle.avtarBgClr}
                 style={{
                   fontSize: "16px",
                 }}
               >
                 {x.action === "profile" ? <div style={{ transform: "translate(4%,8%)" }}>
                   <UserPlusIcon color={"var(--primaryColor)"} />
                 </div> : <div style={{ transform: "translate(4%,8%)" }}>
                   <ClipBoardIcon color={"var(--primaryColor)"} />
                 </div>}

               </Avatar>
             }
             label={
               <>
                 <Row style={{minHeight:'100px'}}>
                   <Col>
                     <p
                       style={{ margin: "0px", paddingBottom: "8px" }}
                       className={commonCssStyle.timelineText1}
                     >
                       {x.addedAt && moment(x.addedAt).format(
                         settings && settings.dateFormat ? settings.dateFormat : "DD-MM-YYYY"
                       )}
                     </p>
                     <p
                       style={{ margin: "0px" }}
                       className={commonCssStyle.timelineText2}
                     >
                        {moment(x.addedAt).format( settings && settings.timeFormat
                                  && settings.timeFormat==="24hours"?"HH:mm"
                                   : 'hh:mm A')}
                     </p>
                   </Col>
                 </Row>
               </>
             }
           >
            {x.timelineText&&
             <span className={commonCssStyle.timelineTextNormal} dangerouslySetInnerHTML={{__html:x.timelineText}}>
             </span>
          }

             {/* <b className={commonCssStyle.timelineTextBold}>Sania Malhotra,</b>{" "}
       <span className={commonCssStyle.timelineTextNormal}>
         added to the
       </span>{" "}
       <b className={commonCssStyle.timelineTextBold}>UX Designer</b>{" "}
       <span className={commonCssStyle.timelineTextNormal}>
         job, location
       </span>
       <b className={commonCssStyle.timelineTextBold}>
         Bengaluru by Anupam Singhania,
       </b>{" "}
       <span className={commonCssStyle.timelineTextNormal}>
         {" "}
         HR Department
       </span> */}
           </Timeline.Item>
          }):<Empty></Empty>}
           
            {/* <Timeline.Item
              dot={
                <Avatar
                  size={50}
                  className={commonCssStyle.avtarBgClr}
                  style={{ fontSize: "16px" }}
                >
                  <div style={{ transform: "translate(4%,8%)" }}>
                    <ClipBoardIcon color={"var(--primaryColor)"} />
                  </div>
                </Avatar>
              }
              label={
                <>
                  <Row>
                    <Col>
                      <p
                        style={{ margin: "0px", paddingBottom: "8px" }}
                        className={commonCssStyle.timelineText1}
                      >
                        Sept 24rd, 2022
                      </p>

                      <p
                        style={{ margin: "0px" }}
                        className={commonCssStyle.timelineText2}
                      >
                        15:10 PM
                      </p>
                    </Col>
                  </Row>
                </>
              }
            >
              <b className={commonCssStyle.timelineTextBold}>Sania Malhotra,</b>{" "}
              <span className={commonCssStyle.timelineTextNormal}>
                cuurent stage has been changed to{" "}
              </span>
              <b className={commonCssStyle.timelineTextBold}>HR Review</b>
            </Timeline.Item> */}
          </Timeline>
        </Card>
      </Col>
    </Row>
  );
};
const mapStateToProps = (state) => {
  return {
    employee_timeline: state.employee.employee_timeline,
    settings: state.settings.settings,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeesTimelineHandler: (id) => {
      dispatch(getEmployeesTimelineAction(id));
    },
    postEmpTimelineFilterHandler:(val,callback)=>{
      dispatch(postEmployeeTimelineFilterAction(val,callback))
    }

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTimeline);
