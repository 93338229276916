import { Button, Card, Col, Form, Input, Row, Layout } from "antd";
import Lock from "../../../Assets/Image/Lock.png";
import logo from "../../../Assets/Image/recruitintelli.png";
import mailbox from "../../../Assets/Image/mailbox.png";
import React from "react";
import { Link } from "react-router-dom";
// import style from '../../../Components/Screens/Login/login.module.css';
import style from "../../../Components/Screens/Login/login.module.css";
import { loginAction } from "../../../Reusable/Store/Action/AuthenticationAction";
import { connect } from "react-redux";
import { HeartFilled } from "@ant-design/icons";

const { Content } = Layout;

function Login({ loginAction }) {
  return (
    <Layout>
      <Content>
        <Row style={style.row}>
          <Col md={10} sm={0} xs={0} className={style.col}>
            <div className={style.card}>
              <img className={style.logo} alt="example" src={logo} />
              <p className={style.pmadewith}>
                Made with <HeartFilled style={{ color: "#F83A3A" }} /> in india
              </p>
              <p className={style.pintell}>Powered by Recruitintell </p>
            </div>
          </Col>
          <Col md={14} sm={24} xs={24} className={style.formcol}>
            <Card className={style.formCard}>
              <Form onFinish={(val) => loginAction(val)}>
                <p className={style.p1}>Sign In</p>
                <Row>
                  <Col md={2} xs={2}>
                    <img
                      alt="example"
                      className={style.inputIcons}
                      src={mailbox}
                    />
                  </Col>
                  <Col xs={1}></Col>
                  <Col md={21} xs={21}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Email is Required",
                        },
                        {
                          pattern:
                            /^([a-zA-Z0-9\s.]+)@([a-zA-Z0-9]+)\.([a-zA-Z]{2,5})$/,
                          message: "Invalid Email",
                        },
                      ]}
                    >
                      <Input
                        className={style.input}
                        name="email"
                        type="email"
                        placeholder="Email*"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={2} xs={2}>
                    <img
                      alt="example"
                      className={style.inputIcons}
                      src={Lock}
                    />
                  </Col>
                  <Col xs={1}></Col>
                  <Col md={21} xs={21}>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        className={`passwordTextColor ${style.input}`}
                        name="password"
                        type="password"
                        placeholder="Password*"
                      />
                    </Form.Item>
                    <Link to={"/forgotpassword"}>
                      <p className={style.p2}>Forgot Password?</p>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <Button htmlType="submit" className={style.submit}>
                      Sign In
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginAction: (val) => {
      dispatch(loginAction(val));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
