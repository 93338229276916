import { Row, Col, Rate, Form, Button, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import styles from "../../AddCandidatesComp/AddCandidatesComp.module.css";
import commonCssStyles from "../../../UI/CommonCss.module.css";

function InterviewCompleted(props) {
  const [form] = useForm();
  const [textFieldStatus, setTextFieldStatus] = useState(true);
  let rattingArray = [];
  const [ratingData, setRatingData] = useState([]);
  useEffect(() => {
    if (
      props.onJobQuestions &&
      props.onJobQuestions.performanceRating &&
      props.onJobQuestions.performanceRating.length > 0
    ) {
      props.onJobQuestions.performanceRating.map((x, i) => {
        if (
          props.onJobQuestions.performanceRating.length !== rattingArray.length
        ) {
          return rattingArray.push(x.rating);
        }
      });
      setRatingData(rattingArray);
    }
    form.setFieldsValue({
      comment: props.onJobQuestions ? props.onJobQuestions.comment : "",
    });
  }, [props.onJobQuestions]);
  // useEffect(() => {
  //   let formAnswers =
  //     props.onJobQuestions &&
  //     !props.onJobQuestions.answers &&
  //     props.onJobQuestions.jobQuestions &&
  //     props.onJobQuestions.jobQuestions.map(() => {
  //       return "";
  //     });
  //   // : props.onJobQuestions.answers.map((data) => {
  //   //     return data;
  //   //   });
  //   form.setFieldsValue({
  //     answers: formAnswers,
  //   });
  // }, [
  //   form,
  //   props.onJobQuestions,
  //   props.onJobQuestions &&
  //     props.onJobQuestions.jobQuestions &&
  //     props.onJobQuestions.answers,
  // ]);

  const interviewQuestions = (data) => {
    // props.onAnswers({
    //   ...data,
    //   id: props.onJobQuestions.id,
    // });
    const interviewFeedback = {
      //id: props.onJobQuestions && props.onJobQuestions.id,
      candidateInterviewId: props.onJobQuestions && props.onJobQuestions.id,
      comment: data.comment ? data.comment : "",
      rating: ratingData,
    };

    props.onAnswers(interviewFeedback);
  };
  return (
    <>
      <Row gutter={[0, 16]} style={{ marginTop: "20px" }}>
        <Col xs={24} md={24}>
          {(props.onJobQuestions &&
            props.onJobQuestions.answers &&
            props.onJobQuestions.answers.length === 0) ||
          (props.onJobQuestions.answers === null &&
            props.onJobQuestions.status === "completed") ? (
            <Col md={24}>
              <p
                className={`${commonCssStyles.marginBottomRemove} ${styles.drawerFormText}`}
              >
                Interview Feedback
              </p>
            </Col>
          ) : (
            ""
          )}
        </Col>
        <Col xs={24} md={24}>
          {/* performanceRating */}
          {props.onJobQuestions &&
            props.onJobQuestions.performanceRating &&
            props.onJobQuestions.performanceRating.map((x, index) => {
              return (
                <div style={{ marginBottom: "20px" }} key={index}>
                  <p
                    className={`${commonCssStyles.marginBottomRemove} ${styles.answersText}`}
                  >{`${index + 1}) ${x.question}`}</p>
                  <Rate
                    defaultValue={x.rating}
                    // value={
                    //   ratingData && ratingData.length > 0
                    //     ? ratingData[index]
                    //     : x.rating
                    // }
                    value={ratingData ? ratingData[index] : x.rating}
                    onChange={(data) => {
                      if (ratingData[index] || ratingData[index] === 0) {
                        setRatingData((v) => {
                          const newStateValue = [...v];
                          newStateValue[index] = data;
                          return newStateValue;
                        });
                      } else {
                        setRatingData((rate) => [...rate, data]);
                      }
                      // const val = {
                      //   id: props.onJobQuestions && props.onJobQuestions.id,
                      //   rating: data,
                      // };
                      // console.log(val);
                      // props.onRating(val);
                    }}
                    style={{ color: "var(--primaryColor)" }}
                  />
                </div>
              );
            })}
        </Col>
        <Col style={{ marginTop: "20px" }} xs={24} md={24}>
          <Form
            form={form}
            onFinish={(val) => {
              interviewQuestions(val);
            }}
            // initialValues={
            //   props.onJobQuestions &&
            //   props.onJobQuestions.comment && {
            //     comment: props.onJobQuestions.comment,
            //   }
            // }
            layout="vertical"
          >
            <Form.Item label="Comment/Notes" name={"comment"}>
              <Input.TextArea />
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Button
                htmlType="button"
                onClick={() => {
                  form.submit();
                }}
                style={{ width: "auto" }}
                type="default"
                className={commonCssStyles.buttonSettingsSecondary}
              >
                Save Feedback
              </Button>
            </div>
          </Form>
        </Col>
        <Col>
          <p
            className={`${commonCssStyles.marginBottomRemove}  ${styles.drawerFormText}`}
          >
            Overall Rating
          </p>
          <Rate
            disabled={true}
            style={{ color: "var(--primaryColor)" }}
            value={props.onJobQuestions && props.onJobQuestions.rating}
          />
        </Col>
      </Row>

      {/* <Form
          className="interviewPendingForm"
          hideRequiredMark
          initialValues={
            props.onJobQuestions &&
            !props.onJobQuestions.answers &&
            props.onJobQuestions.jobQuestions && {
              answers: props.onJobQuestions.jobQuestions.map(() => {
                return "";
              }),
            }
            // : props.onJobQuestions.answers &&
            //   props.onJobQuestions.answers.length > 0 && {
            //     answers: props.onJobQuestions.answers.map((data) => {
            //       return data;
            //     }),
            //   }
          }
          layout="vertical"
          form={form}
          onFinish={(val) => interviewQuestions(val)}
        >
          {(props.onJobQuestions &&
            props.onJobQuestions.answers &&
            props.onJobQuestions.answers.length === 0) ||
          (props.onJobQuestions.answers === null &&
            props.onJobQuestions.status === "completed") ? (
            <Row>
              <Col md={24}>
                <Form.List name="answers">
                  {(fields) =>
                    fields.map((field, key) => (
                      <Answer
                        textFieldStatus={textFieldStatus}
                        key={field.key}
                        onJobQuestions={
                          props.onJobQuestions &&
                          props.onJobQuestions.jobQuestions &&
                          props.onJobQuestions.jobQuestions[field.key]
                        }
                        field={field}
                      />
                    ))
                  }
                </Form.List>
              </Col>
            </Row>
          ) : (
            <>
              {props.onJobQuestions &&
                props.onJobQuestions.jobQuestions &&
                props.onJobQuestions.jobQuestions.map((data, index) => {
                  return (
                    <Row>
                      <Col md={1}>
                        <b className={styles.bulletingPoints}>{index + 1}</b>
                      </Col>
                      <Col md={23}>
                        <p className={styles.interviewSubHeading}>{data}</p>
                        <b className={styles.answersText}>Answer</b>
                        <p className={styles.interviewSubHeading}>
                          {props.onJobQuestions &&
                            props.onJobQuestions.answers &&
                            props.onJobQuestions.answers[index]}
                        </p>
                      </Col>
                    </Row>
                  );
                })}
              <Row style={{ marginTop: "30px" }}>
                <Col md={24}>
                  <h3
                    className={styles.drawerFormText}
                    style={{ marginBottom: "10px" }}
                  >
                    Rating
                  </h3>
                  <Rate
                    value={props.onJobQuestions && props.onJobQuestions.rating}
                    onChange={(data) => {
                      const val = {
                        id: props.onJobQuestions && props.onJobQuestions.id,
                        rating: data,
                      };
                      props.onRating(val);
                    }}
                    style={{ color: "var(--primaryColor)" }}
                  />
                </Col>
              </Row>
            </>
          )}
          {
            (props.onJobQuestions &&
              props.onJobQuestions.answers &&
              props.onJobQuestions.answers.length === 0) ||
              (props.onJobQuestions.answers === null &&
                props.onJobQuestions.status === "completed" &&
                // <Form.Item
                //   style={{
                //     display: "flex",
                //     justifyContent: "center",
                //     alignContent: "center",
                //   }}
                // >
                (props.onJobQuestions &&
                props.onJobQuestions.jobQuestions &&
                props.onJobQuestions.jobQuestions.length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Button
                      htmlType="button"
                      onClick={() => {
                        form.submit();
                      }}
                      type="default"
                      className={commonCssStyles.buttonSettingsSecondary}
                    >
                      Save Answers
                    </Button>
                  </div>
                ) : (
                  ""
                )))
            // </Form.Item>
          }
        </Form> */}
      {/* </Row> */}
      {/* {props.onJobQuestions &&
            props.onJobQuestions.jobQuestions &&
            props.onJobQuestions.jobQuestions.map((data, index) => {
              return (
                <Row>
                  <Col md={1}>
                    <b className={styles.bulletingPoints}>{index + 1}</b>
                  </Col>
                  <Col md={23}>
                    <p className={styles.interviewSubHeading}>{data}</p>
                    <b className={styles.answersText}>Answer</b>
                    <p className={styles.interviewSubHeading}>
                      {props.onJobQuestions &&
                        props.onJobQuestions.answers &&
                        props.onJobQuestions.answers[index]}
                    </p>
                  </Col>
                </Row>
              );
            })}

          <Row>
            <Col>
              <h3
                className={styles.drawerFormText}
                style={{ marginBottom: "10px" }}
              >
                Comment
              </h3>
              <p
                className={styles.interviewSubHeading}
                style={{ margin: "0px" }}
              >
                {props.onJobQuestions && props.onJobQuestions.comment
                  ? props.onJobQuestions.comment
                  : "No Comments"}
              </p>
            </Col>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col>
              <h3
                className={styles.drawerFormText}
                style={{ marginBottom: "10px" }}
              >
                Rating
              </h3>
              <Rate
                value={props.onJobQuestions && props.onJobQuestions.rating}
                onChange={(data) => {
                  const val = {
                    id: props.onJobQuestions && props.onJobQuestions.id,
                    rating: data,
                  };
                  props.onRating(val);
                }}
                style={{ color: "var(--primaryColor)" }}
              />
            </Col>
          </Row>
        </Col>
      </Row> */}
    </>
  );
}
export default InterviewCompleted;
function Answer({ onJobQuestions, field, textFieldStatus }) {
  return (
    <Row>
      <Col md={24}>
        <Form.Item
          {...field}
          rules={[
            {
              required: true,
              message: "Required",
            },
          ]}
          label={
            <>
              <p style={{ margin: "0px" }} className={styles.bulletingPoints}>
                {field.key + 1 + " )"}
              </p>
              &nbsp;
              <p
                style={{ margin: "0px" }}
                className={styles.interviewSubHeading}
              >
                {`${onJobQuestions}`}
              </p>
            </>
          }
        >
          <Input.TextArea
            // readOnly={textFieldStatus}
            style={{ marginTop: "10px" }}
            placeholder="Type assesment answer"
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
