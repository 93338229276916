import React from "react";
import { Button, Card, Col, Form, Input, Row, Layout } from "antd";

import logo from "../../../Assets/Image/recruitintelli.png";
import mailbox from "../../../Assets/Image/mailbox.png";
import style from "../../../Components/Screens/Login/login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { sendResetLinkhAction } from "../../../Reusable/Store/Action/AuthenticationAction";
import { connect } from "react-redux";
import { HeartFilled } from "@ant-design/icons";

const { Content } = Layout;
function ForgotPassword({ sendResetLinkhAction, isverify }) {
  let navigate = useNavigate();
  return (
    <Layout>
      <Content>
        <Row>
          <Col md={10} sm={0} xs={0} className={style.col}>
            <Card className={style.card}>
              <img className={style.logo} alt="example" src={logo} />

              <p className={style.pmadewith}>
                Made with <HeartFilled style={{ color: "#F83A3A" }} /> in india
              </p>
              <p className={style.pintell}>Powered by Recruitintell </p>
            </Card>
          </Col>
          <Col md={14} sm={24} xs={24} className={style.formcol}>
            <Card className={style.formCard}>
              <Form
                onFinish={(val) =>
                  sendResetLinkhAction({ ...val, type: "web" }, navigate)
                }
              >
                <p className={style.p1}>Forgot Password </p>
                <p className={style.fp}>
                  Enter the registered email, we will send the reset link for
                  resetting password into your email along with instructions
                </p>

                <Row>
                  <Col md={2} xs={2}>
                    <img
                      alt="example"
                      className={style.inputIcons}
                      src={mailbox}
                    />
                  </Col>
                  <Col xs={1}></Col>
                  <Col md={21} xs={21}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Email is Required",
                        },
                        {
                          pattern:
                            /^([a-zA-Z0-9\s.]+)@([a-zA-Z0-9]+)\.([a-zA-Z]{2,5})$/,
                          message: "Invalid Email",
                        },
                      ]}
                    >
                      <Input
                        className={style.input}
                        name="email"
                        type="email"
                        placeholder="Email*"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    {/* <Link to={"/"}> */}
                    {/* <p className={style.link}>     Didn’t get the link ?</p>
                      <p className={style.linkP}>Send again </p></Link> */}
                    <Button htmlType="submit" className={style.submit}>
                      Send reset link
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendResetLinkhAction: (val, navigate) => {
      dispatch(sendResetLinkhAction(val, navigate));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
