import styles from "../Application/Application.module.css";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import AddTemplateModal from "./AddTemplateModal";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Modal,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  BackButtonIcon,
  TrashIcon,
  EditIconBlueClr,
  PlusIcon,
  ThreeDotsIcon,
  CloseIcon,
} from "../../../IconsComp/Icons";
import { connect } from "react-redux";
import moment from "moment";

import {
  getEmailTemplatesAction,
  deleteEmailTemplateAction,
  getEmailTemplateAction,
  putEmailTemplatesAction,
} from "../../../../Reusable/Store/Action/Master/MasterEmailTemplateAction";
import { getEmailNotificationTypesAction } from "../../../../Reusable/Store/Action/Master/MasterEmailNotificationTypesAction";

function EmailTemplates(props, { settings }) {
  const [modalName, setModalName] = useState("");

  const [deleteClr, setDeleteClr] = useState(false);
  const [deleteETModal, setDeleteETModal] = useState(false);
  const [deleteETId, setDeleteETId] = useState("");

  useEffect(() => {
    props.getEmailTemplatesHandler();
    props.getEmailNotificationHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [addTemplateModal, setAddTemplateModal] = useState(false);

  function setAddTemplateModalOpenHandler() {
    setAddTemplateModal(true);
    setModalName("add");
  }
  function setCancelTemplateModalOpenHandler() {
    setAddTemplateModal(false);
  }

  function setOkTemplateModalOpenHandler() {
    setAddTemplateModal(false);
  }

  function setOkEditModalOpenHandler(id) {
    setAddTemplateModal(false);
    props.getEmailTemplateByIdHanler(id);
  }

  function confirmDeleteHandler() {
    setDeleteETModal(false);
    props.deleteEmailTemplateHandler(deleteETId);
    setDeleteETId("");
  }
  function closeDeleteHandler() {
    setDeleteETModal(false);
  }

  const columns = [
    {
      title: (
        <>
          <p
            style={{ margin: "0px" }}
            className={commonCssStyle.tableHeadingTextForEmailTemplate}
          >
            Name
          </p>
        </>
      ),
      dataIndex: "title",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableEmailDescriptionTextData}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <>
          <p
            style={{ margin: "0px" }}
            className={commonCssStyle.tableHeadingTextForEmailTemplate}
          >
            Subject
          </p>
        </>
      ),
      dataIndex: "subject",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableEmailDescriptionTextData}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <>
          <p
            style={{ margin: "0px" }}
            className={commonCssStyle.tableHeadingTextForEmailTemplate}
          >
            Created Date
          </p>
        </>
      ),
      dataIndex: "createdAt",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableEmailDescriptionTextData}`}
          >
            {data
              ? moment(data).format(
                  settings && settings.dateFormat
                    ? settings.dateFormat
                    : "DD/MM/YYYY"
                )
              : ""}
          </p>
        );
      },
    },
    {
      title: (
        <>
          <p
            style={{ margin: "0px" }}
            className={commonCssStyle.tableHeadingTextForEmailTemplate}
          >
            Status
          </p>
        </>
      ),
      dataIndex: "placedOrNot",
      render: (text, render) => {
        return (
          <>
            <Row align="middle" style={{ gap: "10px" }}>
              <Col>
                <Switch
                  onChange={(val) => {
                    props.putEmailTemplateHandler(
                      {
                        ...render,
                        status: val ? 1 : 0,
                      },
                      (success) => {}
                    );
                  }}
                  defaultChecked={render.status === 1}
                />
              </Col>
              <Col>
                <p
                  style={{ margin: "0px" }}
                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableEmailDescriptionTextData}`}
                >
                  {render.status === 1 ? "Enable" : "Disable"}
                </p>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      dataIndex: "resumeLink",
      title: (
        <>
          <p
            style={{ margin: "0px" }}
            className={commonCssStyle.tableHeadingTextForEmailTemplate}
          >
            Action
          </p>
        </>
      ),
      render: (text, render) => (
        <Dropdown
          overlay={
            <Menu
              className="emailMenu"
              style={{
                width: "193px",
                height: "100px",
                borderRadius: "6px",
                padding: "16px,0,0,16px",
              }}
              items={[
                {
                  key: "1",
                  label: (
                    <Row
                      style={{ gap: "10px" }}
                      align="middle"
                      onClick={() => {
                        props.getEmailTemplateByIdHanler(
                          render.id,
                          (success) => {
                            if (success) {
                              setAddTemplateModal(true);
                              setModalName("edit");
                            }
                          }
                        );
                      }}
                    >
                      <Col>
                        <EditIconBlueClr color={"var(--primaryColor)"} />
                      </Col>
                      <Col>
                        <h3
                          style={{
                            margin: "0px",
                            color: "var(--primaryColor)",
                            padding: "15px 0px 12px 12px",
                          }}
                        >
                          Edit
                        </h3>
                      </Col>
                    </Row>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <Row
                      onClick={() => {
                        setDeleteETModal(true);
                        setDeleteETId(render.id);
                      }}
                      onMouseEnter={() => {
                        setDeleteClr(true);
                      }}
                      onMouseLeave={() => {
                        setDeleteClr(false);
                      }}
                      style={{ gap: "10px" }}
                      align="middle"
                    >
                      <Col>
                        <TrashIcon
                          color={deleteClr ? "red" : "var(--primaryColor)"}
                        />
                      </Col>
                      <Col>
                        <h3
                          style={{
                            margin: "0px",
                            color: deleteClr
                              ? "#F83A3A"
                              : "var(--primaryColor)",
                            padding: "15px 0px 12px 12px",
                          }}
                        >
                          Delete
                        </h3>
                      </Col>
                    </Row>
                  ),
                },
              ]}
            />
          }
          placement="bottomRight"
        >
          <a href onClick={(e) => e.preventDefault()}>
            <Space>
              <ThreeDotsIcon color={"var(--table-text-color)"} />
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <Card size="large" style={{ backgroundColor: "var(--background-color)" }}>
        <Card className={styles.EmailTemplatesCard}>
          <Row justify="space-between">
            <Col>
              <Row align="middle">
                <Col
                  style={{ cursor: "pointer", paddingLeft: "5px" }}
                  className={styles.EmailTempBackBtn}
                  onClick={() => {
                    props.backToApp();
                  }}
                >
                  <BackButtonIcon color={"var(--icons-color)"} />
                </Col>
                <Col>
                  <h4 className={commonCssStyle.ApplicationTemplatesName}>
                    Email templates
                  </h4>
                </Col>
              </Row>
            </Col>

            <Col className={styles.EmailAddTemplateBtn}>
              <Button
                onClick={setAddTemplateModalOpenHandler}
                type="primary"
                style={{ width: "200px" }}
                className={`${commonCssStyle.intaddButton} ${styles.EmailTemplatesAddBtn}`}
              >
                <Row align="middle" justify="space-around">
                  <Col style={{ marginTop: "2px" }}>
                    <PlusIcon color={"white"} />
                  </Col>
                  <Col className={styles.EmailAddTemplateName}>
                    Add Template
                  </Col>
                </Row>
              </Button>
            </Col>
          </Row>
        </Card>
      </Card>
      <Card>
        <Table
          scroll={{ x: "auto" }}
          pagination={false}
          rowKey="key"
          columns={columns}
          dataSource={props.emailTemplates}
        />
      </Card>

      <Modal
        closable={false}
        title={
          <Row style={{ gap: "387px" }}>
            <Col>
              {" "}
              <p
                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.popUpHeadingText}`}
              >
                Delete
              </p>
            </Col>
            <Col>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  closeDeleteHandler();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
        footer={
          <>
            <Row style={{ gap: "10px" }} justify="end" align="middle">
              <Col>
                <Button
                  className={commonCssStyle.buttonSettingsSecondary}
                  onClick={() => {
                    closeDeleteHandler();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  className={commonCssStyle.buttonSettingsPrimary}
                  type="primary"
                  onClick={() => {
                    confirmDeleteHandler();
                  }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </>
        }
        open={deleteETModal}
        onOk={confirmDeleteHandler}
        // onCancel={closeDeleteHandler}
      >
        <Row>
          <Col>
            <p
              className={`${commonCssStyle.deleteConfiTextColor} ${commonCssStyle.deleteConfiTextColor}`}
            >
              Are you sure you want to delete
            </p>
          </Col>
        </Row>
      </Modal>
      {addTemplateModal && modalName === "add" ? (
        <AddTemplateModal
          onOpen={addTemplateModal}
          onCancel={setCancelTemplateModalOpenHandler}
          onOk={setOkTemplateModalOpenHandler}
          emailNotificationData={props.emailNotificationTypes}
          modalNameHandler={modalName}
        />
      ) : (
        ""
      )}
      {addTemplateModal &&
      modalName === "edit" &&
      props.editEmailTemplateData ? (
        <AddTemplateModal
          onOpen={addTemplateModal}
          onCancel={setCancelTemplateModalOpenHandler}
          onOk={setOkEditModalOpenHandler}
          emailNotificationData={props.emailNotificationTypes}
          modalNameHandler={modalName}
          propsETIdHandler={props.editEmailTemplateData}
        />
      ) : (
        ""
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    emailTemplates: state.emailTemplates.emailTemplates,
    editEmailTemplateData: state.emailTemplates.emailTemplate,
    emailNotificationTypes: state.emailNotificationTypes.emailNotificationTypes,
    settings: state.settings.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEmailNotificationHandler: () => {
      dispatch(getEmailNotificationTypesAction());
    },
    getEmailTemplatesHandler: () => {
      dispatch(getEmailTemplatesAction());
    },
    deleteEmailTemplateHandler: (id) => {
      dispatch(deleteEmailTemplateAction(id));
    },
    getEmailTemplateByIdHanler: (id, callback) => {
      dispatch(getEmailTemplateAction(id, callback));
    },
    putEmailTemplateHandler: (data, callback) => {
      dispatch(putEmailTemplatesAction(data, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates);
