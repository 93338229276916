import styles from "../../../Components/Screens/Dashboard/ContentPage/ContentPage.module.css";
import commonCssStyles from "../../../Components/UI/CommonCss.module.css";
import {
  Avatar,
  Layout,
  Input,
  Badge,
  Modal,
  Row,
  Col,
  Carousel,
  Button,
  Affix,
  Form,
} from "antd";
import {
  BellICon,
  BrieftCaseIcon,
  BuildingsIcon,
  CalendarIcon,
  CloseIcon,
  DownArrowIcon,
  ProfileIcon,
  SearchIcon,
  SettingIcon,
  UserFocusIcon,
  UserFocusIcon1,
  UserIcon,
} from "../../IconsComp/Icons";
import { notification } from "antd";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  getNotificationsAction,
  putNotificationAction,
  getNotificationMarkZeroAction,
} from "../../../Reusable/Store/Action/NotificationAction";
import Notification from "../../Screens/Dashboard/AppNotification";
import moment from "moment";
import {
  QuestionCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Dashboard from "../../Screens/Dashboard/ContentPage/Dashboard";
import NotificationCard from "./NotificationCard";
import { getGlobalSearchAction } from "../../../Reusable/Store/Action/GlobalSearchAction";
import { getGSDataStatusSlice } from "../../../Reusable/Store/Slice/GlobalSearchSlice";
import { useForm } from "antd/lib/form/Form";
const { Header } = Layout;
function Headers(props) {
  const [form] = useForm();
  function onSearch(val) {}
  const ref = useRef();
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  useEffect(() => {
    // if(props.loginCount&&props.loginCount==="842"){
    //   setHelpModalOpen(true);
    // }
    props.getNotificationsHandler();
    props.getGlobalSearchHandler({ val: "candidate" });
  }, []);

  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [hideNotification, setHideNotification] = useState(false);
  const [term, setTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");

  const [searchModalOpen, setSearchModalOpen] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);

  // function showDropdownHandler() {
  //   setShowDropdown((val) => !val);
  // }

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const contentStyle = {
    margin: 0,
    height: "450px",
    lineHeight: "160px",
    textAlign: "center",
  };

  const onChange = (currentSlide) => {};

  useEffect(() => {
    if (debouncedTerm !== "" && debouncedTerm.length > 2) {
      setSearchModalOpen(true);
      props.getGlobalSearchHandler({ val: debouncedTerm });
    } else {
      setDebouncedTerm("");
      setSearchModalOpen(false);
      // <Link to={"/"} />;
    }
  }, [debouncedTerm]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showDropdown && ref.current && !ref.current.contains(e.target)) {
        // props.getGSDataStatusHandler(false);
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showDropdown]);

  function globalSearch(event) {
    const timer = setTimeout(() => {
      setDebouncedTerm(event.target.value);
    }, 1000);
    return () => clearTimeout(timer);
  }

  function resetSearchHandler() {
    setShowDropdown(false);
    form.resetFields();
  }

  return (
    <>
      <Header
        style={{
          width: "100%",
          backgroundColor: "var(--heading-bg-color)",
          boxShadow: "var(--box-shadow)",
          padding: "0px 10px 0px 30px",
          zIndex: 1,
        }}
      >
        <Row wrap={false} justify="space-between">
          <Col xs={9} md={10} flex={1}>
            <Form form={form}>
              <Form.Item className="searchBarClass" name="search">
                <Input.Search
                  id="Search-anything-bar"
                  placeholder="Search"
                  onSearch={onSearch}
                  onClick={() => {
                    setShowDropdown(true);
                    // setShowDropdown((val) => !val);
                  }}
                  onChange={globalSearch}
                  allowClear={true}
                  className={`searchBarPlaceHoderClr ${styles.inputField}`}
                  prefix={<SearchIcon color={"var(--primaryColor)"} />}
                  // style={{
                  // borderRadius: 50,
                  // width: "486px",
                  // height: "42px",
                  // fontSize: "20px",
                  // }}
                />
              </Form.Item>
            </Form>
          </Col>
          {/* showDropdown */}

          {debouncedTerm.length > 2 && showDropdown && (
            <>
              {props.gsDataStatus ? (
                <div
                  ref={ref}
                  style={{
                    background: "var(--theme)",
                    position: "absolute",
                    // width: "486px",
                    width: "30%",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                    boxSizing: "border-box",
                    top: "60px",
                    borderRadius: "8px",
                  }}
                >
                  {props.globalSearchdata &&
                    props.globalSearchdata.slice(0, 5).map((data, index) => {
                      return (
                        <>
                          <Row
                            align="middle"
                            style={{
                              marginBottom: "15px",
                              paddingRight: "7px",
                            }}
                            key={index}
                            justify="space-between"
                          >
                            <Col md={18}>
                              <Row align="middle" justify="space-between">
                                <Col md={4}>
                                  <span style={{ padding: "0px 0px 0px 19px" }}>
                                    <Avatar
                                      size={50}
                                      icon={
                                        <span
                                          style={{
                                            display: "block",
                                            width: "50%",
                                            position: "relative",
                                            transform: "translate(11px,6px)",
                                          }}
                                        >
                                          {data.type === "task" ? (
                                            <CalendarIcon
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : data.type === "interview" ? (
                                            <CalendarIcon
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : data.type === "recruitment" ? (
                                            <UserFocusIcon1
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : data.type === "candidate" ? (
                                            <UserIcon
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : data.type === "requirement" ? (
                                            <BrieftCaseIcon
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : data.type === "company" ? (
                                            <BuildingsIcon
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : data.type === "employee" ? (
                                            <UserIcon
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : data.type === "settings" ? (
                                            <SettingIcon
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : (
                                            <BellICon
                                              color={"var(--primaryColor)"}
                                            />
                                          )}
                                        </span>
                                      }
                                    />
                                  </span>
                                </Col>

                                {data.type === "candidate" ? (
                                  <Col
                                    md={16}
                                    onClick={() => {
                                      setDebouncedTerm("");
                                      resetSearchHandler();
                                    }}
                                  >
                                    <Link
                                      to={"/candidatesDetailsPage/summary"}
                                      state={{
                                        id: data.id,
                                        from: "candidateList",
                                      }}
                                    >
                                      <p
                                        className={`${commonCssStyles.textEllipsis} ${commonCssStyles.headingText2} ${commonCssStyles.marginBottomRemove}`}
                                        style={{
                                          textAlign: "left",
                                          padding: "0px 10px 0px 10px",
                                        }}
                                      >
                                        {data.title}
                                      </p>
                                    </Link>
                                  </Col>
                                ) : data.type === "requirement" ? (
                                  <Col
                                    md={16}
                                    onClick={() => {
                                      setDebouncedTerm("");
                                    }}
                                  >
                                    <Link
                                      to={"/JobDetailPage"}
                                      state={{ id: data.id }}
                                    >
                                      <p
                                        className={`${commonCssStyles.headingText2} ${commonCssStyles.marginBottomRemove}`}
                                        style={{
                                          textAlign: "left",
                                          padding: "0px 10px 0px 10px",
                                        }}
                                      >
                                        {data.title}
                                      </p>
                                    </Link>
                                  </Col>
                                ) : data.type === "company" ? (
                                  <Col
                                    md={16}
                                    onClick={() => {
                                      setDebouncedTerm("");
                                    }}
                                  >
                                    <Link
                                      to={"/clientCompany/details"}
                                      state={data.id}
                                    >
                                      <p
                                        className={`${commonCssStyles.headingText2} ${commonCssStyles.marginBottomRemove}`}
                                        style={{
                                          textAlign: "left",
                                          padding: "0px 10px 0px 10px",
                                        }}
                                      >
                                        {data.title}
                                      </p>
                                    </Link>
                                  </Col>
                                ) : data.type === "employee" ? (
                                  <Col
                                    md={16}
                                    onClick={() => {
                                      setDebouncedTerm("");
                                    }}
                                  >
                                    <Link
                                      to={"employee/summary"}
                                      state={{ id: data.id }}
                                    >
                                      <p
                                        className={`${commonCssStyles.headingText2} ${commonCssStyles.marginBottomRemove}`}
                                        style={{
                                          textAlign: "left",
                                          padding: "0px 10px 0px 10px",
                                        }}
                                      >
                                        {data.title}
                                      </p>
                                    </Link>
                                  </Col>
                                ) : data.type === "task" ? (
                                  <Col
                                    md={16}
                                    onClick={() => {
                                      setDebouncedTerm("");
                                    }}
                                  >
                                    <Link
                                      to={"/taskCalendar"}
                                      state={{ id: data.id, type: "task" }}
                                    >
                                      <p
                                        className={`${commonCssStyles.headingText2} ${commonCssStyles.marginBottomRemove}`}
                                        style={{
                                          textAlign: "left",
                                          padding: "0px 10px 0px 10px",
                                        }}
                                      >
                                        {data.title}
                                      </p>
                                    </Link>
                                  </Col>
                                ) : data.type === "interview" ? (
                                  <Col
                                    md={16}
                                    onClick={() => {
                                      setDebouncedTerm("");
                                    }}
                                  >
                                    <Link
                                      to={"/taskCalendar"}
                                      state={{ id: data.id, type: "interview" }}
                                    >
                                      <p
                                        className={`${commonCssStyles.headingText2} ${commonCssStyles.marginBottomRemove}`}
                                        style={{
                                          textAlign: "left",
                                          padding: "0px 10px 0px 10px",
                                        }}
                                      >
                                        {data.title}
                                      </p>
                                    </Link>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </Col>

                            <Col md={5}>
                              <p
                                className={`${commonCssStyles.textEllipsis} ${commonCssStyles.marginBottomRemove} ${commonCssStyles.tableHeadingTextData}`}
                                style={{
                                  textAlign: "right",
                                  padding: "0px 19px 0px 19px",
                                }}
                              >
                                {data.type}
                              </p>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                  <Row>
                    <Col push={1}>
                      {props.globalSearchdata &&
                      props.globalSearchdata.length > 5 ? (
                        <Link
                          to={"/globalsearchlist"}
                          state={props.globalSearchdata}
                        >
                          <p
                            onClick={() => {
                              setDebouncedTerm("");
                            }}
                            // style={{ margin:"0px 0px"}}
                          >
                            see all
                          </p>
                        </Link>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
              ) : (
                <div
                  ref={ref}
                  style={{
                    background: "var(--theme)",
                    position: "absolute",
                    width: "30%",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                    boxSizing: "border-box",
                    top: "60px",
                    borderRadius: "8px",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      margin: "0px",
                      fontWeight: 700,
                      color: "var(--body-text-color)",
                    }}
                  >
                    No Data Found
                  </p>
                </div>
              )}
            </>
          )}
          <Col xs={14} md={13}>
            <Row
              // justify="end"
              style={{ flexDirection: "row-reverse" }}
            >
              <Col>
                <Link to={"/profile"}>
                  <Avatar
                    size={{ sm: 40, lg: 50 }}
                    className={styles.avtarForLogAndNotf}
                    id="headers-profile-icon"
                    icon={
                      <div
                        className={`${commonCssStyles.toCenterAvtarIconDiv}`}
                      >
                        <span
                          className={`${commonCssStyles.toCenterAvtarIconSpan}`}
                        >
                          <ProfileIcon color={"var(--primaryColor)"} />
                        </span>
                      </div>
                    }
                  />
                </Link>
              </Col>
              {/* <input
          hidden
          ref={(input) => {
            btnref = input;
          }}
          onClick={() => {
            // setOpenNotificationModal(true);
            setOpenNotificationModal((val) => !val);
          }}
        /> */}
              <Col>
                <Badge
                  count={
                    props.Notifications &&
                    props.Notifications.notificationCount &&
                    props.Notifications.notificationCount
                  }
                  showZero
                  overflowCount={99}
                >
                  <Avatar
                    size={{ sm: 40, lg: 50 }}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   btnref.click();
                    //   return false;
                    // }}
                    onClick={() => {
                      // setOpenNotificationModal(true);
                      setOpenNotificationModal((val) => !val);
                    }}
                    className={`${styles.avtarBellForLogAndNotg} ${commonCssStyles.coursorPointer}`}
                    id="headers-notification-icon"
                    icon={
                      <div
                        className={`${commonCssStyles.toCenterAvtarIconDiv}`}
                      >
                        <span
                          className={`${commonCssStyles.toCenterAvtarIconSpan}`}
                        >
                          <BellICon color={"var(--primaryColor)"} />
                        </span>
                      </div>
                    }
                  />
                </Badge>
              </Col>
              {/* {openNotificationModal && (
            <Row className={`${commonCssStyles.notificationDropdown}`}>
            <Col md={24}>
                <Row
                  style={{
                    padding: "5px 5px 0px 18px",
                    margin: "0px",
                    boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.15)",
                  position: "fixed",
                  width: "100%",
                  }}
                  className={commonCssStyles.coursorPointer}
                >
                  <Col md={21}>
                  <p className={commonCssStyles.modalHeading}>Notifications</p>
                  </Col>
                  <Col
                    md={3}
                    onClick={() => {
                      setOpenNotificationModal(false);
                      props.getNotificationMarkZeroHandler((success) => {
                        if (success) {
                          props.getNotificationsHandler();
                        }
                      });
                    }}
                    className={commonCssStyles.coursorPointer}
                  >
                    <CloseIcon color={"var(--body-text-color)"} />
                  </Col>
                </Row>
              </Col>
            <Col md={24} style={{ transform: "translateY(37px)" }}>
                <Row style={{ padding: "0px 10px 0px 10px" }}>
                  {props.Notifications && props.Notifications.new.length > 0 ? (
                    <>
                      <Col md={20}>
                        <p className={`${commonCssStyles.timelineTextBold}`}>
                          See All
                        </p>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                  {props.Notifications && props.Notifications.new.length > 0 ? (
                    <>
                      <Col
                        md={4}
                        onClick={() => {
                          setOpenNotificationModal(false);
                          props.getNotificationMarkZeroHandler((success) => {
                            if (success) {
                              props.getNotificationsHandler();
                            }
                          });
                        }}
                      >
                        <Link to="/Notification" state={props.Notifications}>
                          <p className={`${commonCssStyles.timelineTextBold}`}>
                            See All
                          </p>
                        </Link>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md={20}></Col>
                      <Col
                        md={4}
                        onClick={() => {
                          setOpenNotificationModal(false);
                          props.getNotificationMarkZeroHandler((success) => {
                            if (success) {
                              props.getNotificationsHandler();
                            }
                          });
                        }}
                      >
                        <Link to="/Notification" state={props.Notifications}>
                          <p className={`${commonCssStyles.timelineTextBold}`}>
                            See All
                          </p>
                        </Link>
                      </Col>
                    </>
                  )}
                  <Col>
                    {props.Notifications &&
                      props.Notifications.new &&
                    props.Notifications.new.map((data, index) => {
                        return (
                          <>
                            <Row
                             key={index} 
                            style={{
                              margin: "7px auto 7px auto",
                              padding: "6px 0px",
                            }}
                            className={
                              data.isRead === 1
                                ? commonCssStyles.notificationBg1
                                : commonCssStyles.notificationBg
                            }
                             >
                              <Col md={4}>
                                <Avatar
                                  className={`${styles.avtarForLogAndNotification} ${commonCssStyles.coursorPointer}`}
                                  id="headers-notification-icon"
                                  icon={
                                    <div
                                      style={{
                                        display: "block",
                                        width: "50%",
                                        position: "relative",
                                        transform: "translate(8px,8px)",
                                      }}
                                    >
                                    {data.iconType === "task" ? (
                                      <CalendarIcon
                                        color={"var(--primaryColor)"}
                                      />
                                    ) : data.iconType === "task" ? (
                                      <Dashboard
                                        color={"var(--primaryColor)"}
                                      />
                                    ) : data.iconType === "recruitment" ? (
                                      <UserFocusIcon1
                                        color={"var(--primaryColor)"}
                                      />
                                    ) : data.iconType === "Candidate" ? (
                                      <UserIcon color={"var(--primaryColor)"} />
                                    ) : data.iconType === "requirement" ? (
                                      <BrieftCaseIcon
                                        color={"var(--primaryColor)"}
                                      />
                                    ) : data.iconType === "company" ? (
                                      <BuildingsIcon
                                        color={"var(--primaryColor)"}
                                      />
                                    ) : data.iconType === "Employee" ? (
                                      <UserIcon color={"var(--primaryColor)"} />
                                    ) : data.iconType === "settings" ? (
                                      <SettingIcon
                                        color={"var(--primaryColor)"}
                                      />
                                    ) : (
                                      <BellICon color={"var(--primaryColor)"} />
                                    )}
                                    </div>
                                  }
                                />
                              </Col>
                              <Col md={20}>
                                <p
                                style={{ lineHeight: "21px" }}
                                  className={`${commonCssStyles.marginBottomRemove} `}
                                dangerouslySetInnerHTML={{
                                  __html: data.notification,
                                }}
                              ></p>
                              <p
                                style={{ lineHeight: "19px" }}
                                className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.notificationSubText}`}
                                >
                                {moment().diff(
                                  moment(data.createdAt),
                                  "minutes"
                                ) <= 60
                                  ? moment().diff(data.createdAt, "minutes") +
                                    " minutes ago"
                                  : moment().diff(
                                      moment(data.createdAt),
                                      "hour"
                                    ) <= 12
                                  ? moment().diff(data.createdAt, "hour") +
                                    " hour ago"
                                  : moment().diff(
                                      moment(data.createdAt),
                                      "days"
                                    ) <= 10
                                  ? moment().diff(data.createdAt, "days") +
                                    " days ago"
                                  : moment.isDate.format(dataFormate)}
                                </p>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                </Col>
              </Row>

              <Row style={{ padding: " 0px 10px 0px 10px" }}>
                {props.Notifications &&
                props.Notifications.earlier.length > 0 ? (
                  <>
                    <Col md={24}>
                      <p className={`${commonCssStyles.timelineTextBold}`}>
                        Earlier
                      </p>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col md={24}>
                  {props.Notifications &&
                    props.Notifications.earlier &&
                    props.Notifications.earlier.map((data, index) => {
                      return (
                        <Row
                          style={{
                            margin: "7px auto 7px auto",
                            padding: "6px 0px",
                          }}
                          key={index}
                          className={
                            data.isRead === 1
                              ? commonCssStyles.notificationBg1
                              : commonCssStyles.notificationBg
                          }
                        >
                          <Col md={4}>
                            <Avatar
                              className={`${styles.avtarForLogAndNotification} ${commonCssStyles.coursorPointer}`}
                              icon={
                                <div
                                  style={{
                                    display: "block",
                                    width: "50%",
                                    position: "relative",
                                    transform: "translate(8px,8px)",
                                  }}
                                >
                                  {data.iconType === "task" ? (
                                    <CalendarIcon
                                      color={"var(--primaryColor)"}
                                    />
                                  ) : data.iconType === "task" ? (
                                    <Dashboard color={"var(--primaryColor)"} />
                                  ) : data.iconType === "recruitment" ? (
                                    <UserFocusIcon1
                                      color={"var(--primaryColor)"}
                                    />
                                  ) : data.iconType === "Candidate" ? (
                                    <UserIcon color={"var(--primaryColor)"} />
                                  ) : data.iconType === "requirement" ? (
                                    <BrieftCaseIcon
                                      color={"var(--primaryColor)"}
                                    />
                                  ) : data.iconType === "company" ? (
                                    <BuildingsIcon
                                      color={"var(--primaryColor)"}
                                    />
                                  ) : data.iconType === "Employee" ? (
                                    <UserIcon color={"var(--primaryColor)"} />
                                  ) : data.iconType === "settings" ? (
                                    <SettingIcon
                                      color={"var(--primaryColor)"}
                                    />
                                  ) : (
                                    <BellICon color={"var(--primaryColor)"} />
                                  )}
                                </div>
                              }
                            />
                          </Col>
                          <Col md={20}>
                            <p
                              style={{ lineHeight: "21px" }}
                              // style={{marginTop:"-7%"}}
                              className={`${commonCssStyles.marginBottomRemove}`}
                              dangerouslySetInnerHTML={{
                                __html: data.notification,
                              }}
                            ></p>
                                <p
                              style={{ lineHeight: "19px" }}
                              className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.notificationSubText}`}
                              // style={{marginTop:"-20%"}}
                            >
                                    {moment().diff(
                                      moment(data.createdAt),
                                      "minutes"
                                    ) <= 60
                                ? moment().diff(data.createdAt, "minutes") +
                                  " minutes ago"
                                : moment().diff(
                                    moment(data.createdAt),
                                    "hour"
                                  ) <= 12
                                ? moment().diff(data.createdAt, "hour") +
                                  " hour ago"
                                : moment().diff(
                                    moment(data.createdAt),
                                    "days"
                                  ) <= 10
                                ? moment().diff(data.createdAt, "days") +
                                  " days ago"
                                : moment.isDate.format(dataFormate)}
                            </p>
                          </Col>
                        </Row>
                      );
                    })}
                </Col>
              </Row>
            </Col>
          </Row>
        )} */}
              {openNotificationModal && (
                <NotificationCard
                  onClearData={() => {
                    setOpenNotificationModal(false);
                    props.getNotificationMarkZeroHandler((success) => {
                      if (success) {
                        props.getNotificationsHandler();
                      }
                    });
                  }}
                >
                  <Col md={24}>
                    <Row style={{ padding: "0px 10px 0px 10px" }}>
                      {props.Notifications &&
                      props.Notifications.new.length > 0 ? (
                        <>
                          <Col md={20}>
                            <p
                              className={`${commonCssStyles.timelineTextBold}`}
                            >
                              New
                            </p>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                      {props.Notifications &&
                      props.Notifications.new.length > 0 ? (
                        <>
                          <Col
                            md={4}
                            onClick={() => {
                              setOpenNotificationModal(false);
                              props.getNotificationMarkZeroHandler(
                                (success) => {
                                  if (success) {
                                    props.getNotificationsHandler();
                                  }
                                }
                              );
                            }}
                          >
                            <Link
                              to="/Notification"
                              state={props.Notifications}
                            >
                              <p
                                className={`${commonCssStyles.timelineTextBold}`}
                              >
                                See All
                              </p>
                            </Link>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col md={20}></Col>
                          <Col
                            md={4}
                            onClick={() => {
                              setOpenNotificationModal(false);
                              props.getNotificationMarkZeroHandler(
                                (success) => {
                                  if (success) {
                                    props.getNotificationsHandler();
                                  }
                                }
                              );
                            }}
                          >
                            <Link
                              to="/Notification"
                              state={props.Notifications}
                            >
                              <p
                                className={`${commonCssStyles.timelineTextBold}`}
                              >
                                See All
                              </p>
                            </Link>
                          </Col>
                        </>
                      )}
                      <Col>
                        {props.Notifications &&
                          props.Notifications.new &&
                          props.Notifications.new.map((data, index) => {
                            return (
                              <>
                                <Row
                                  onClick={() => {
                                    if (data.isRead == 0) {
                                      props.putNotificationAction(
                                        {
                                          isRead: "1",
                                          id: data.id,
                                        },
                                        (success) => {
                                          if (success) {
                                            props.getNotificationsHandler();
                                          }
                                        }
                                      );
                                    }
                                  }}
                                  key={index}
                                  style={{
                                    margin: "7px auto 7px auto",
                                    padding: "6px 0px",
                                  }}
                                  className={`${
                                    data.isRead == 1
                                      ? commonCssStyles.notificationBg1
                                      : commonCssStyles.notificationBg
                                  } ${
                                    data.isRead == 0 &&
                                    commonCssStyles.coursorPointer
                                  }`}
                                >
                                  <Col md={4}>
                                    <Avatar
                                      className={`${styles.avtarForLogAndNotification} ${commonCssStyles.coursorPointer}`}
                                      id="headers-notification-icon"
                                      icon={
                                        <div
                                          style={{
                                            display: "block",
                                            width: "50%",
                                            position: "relative",
                                            transform: "translate(8px,8px)",
                                          }}
                                        >
                                          {data.iconType === "task" ? (
                                            <CalendarIcon
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : data.iconType === "task" ? (
                                            <Dashboard
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : data.iconType ===
                                            "recruitment" ? (
                                            <UserFocusIcon1
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : data.iconType === "Candidate" ? (
                                            <UserIcon
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : data.iconType ===
                                            "requirement" ? (
                                            <BrieftCaseIcon
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : data.iconType === "company" ? (
                                            <BuildingsIcon
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : data.iconType === "Employee" ? (
                                            <UserIcon
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : data.iconType === "settings" ? (
                                            <SettingIcon
                                              color={"var(--primaryColor)"}
                                            />
                                          ) : (
                                            <BellICon
                                              color={"var(--primaryColor)"}
                                            />
                                          )}
                                        </div>
                                      }
                                    />
                                  </Col>
                                  <Col md={20}>
                                    <p
                                      style={{
                                        lineHeight: "21px",
                                        color: "var(--body-text-color)",
                                      }}
                                      className={`${commonCssStyles.marginBottomRemove} `}
                                      dangerouslySetInnerHTML={{
                                        __html: data.notification,
                                      }}
                                    ></p>
                                    <p
                                      style={{ lineHeight: "19px" }}
                                      className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.notificationSubText}`}
                                    >
                                      {moment().diff(
                                        moment(data.createdAt),
                                        "minutes"
                                      ) <= 60
                                        ? moment().diff(
                                            data.createdAt,
                                            "minutes"
                                          ) + " minutes ago"
                                        : moment().diff(
                                            moment(data.createdAt),
                                            "hour"
                                          ) <= 12
                                        ? moment().diff(
                                            data.createdAt,
                                            "hour"
                                          ) + " hour ago"
                                        : moment().diff(
                                            moment(data.createdAt),
                                            "days"
                                          ) <= 10
                                        ? moment().diff(
                                            data.createdAt,
                                            "days"
                                          ) + " days ago"
                                        : moment(data.createdAt).format(
                                            dataFormate
                                          )}
                                    </p>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                      </Col>
                    </Row>

                    <Row style={{ padding: " 0px 10px 0px 10px" }}>
                      {props.Notifications &&
                      props.Notifications.earlier.length > 0 ? (
                        <>
                          <Col md={24}>
                            <p
                              className={`${commonCssStyles.timelineTextBold}`}
                            >
                              Earlier
                            </p>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                      <Col md={24}>
                        {props.Notifications &&
                          props.Notifications.earlier &&
                          props.Notifications.earlier.map((data, index) => {
                            return (
                              <Row
                                onClick={() => {
                                  if (data.isRead == 0) {
                                    props.putNotificationAction(
                                      {
                                        isRead: "1",
                                        id: data.id,
                                      },
                                      (success) => {
                                        if (success) {
                                          props.getNotificationsHandler();
                                        }
                                      }
                                    );
                                  }
                                }}
                                style={{
                                  margin: "7px auto 7px auto",
                                  padding: "6px 0px",
                                }}
                                key={index}
                                className={`${
                                  data.isRead == 1
                                    ? commonCssStyles.notificationBg1
                                    : commonCssStyles.notificationBg
                                }  ${
                                  data.isRead == 0 &&
                                  commonCssStyles.coursorPointer
                                } `}
                              >
                                <Col md={4}>
                                  <Avatar
                                    className={`${styles.avtarForLogAndNotification} ${commonCssStyles.coursorPointer}`}
                                    icon={
                                      <div
                                        style={{
                                          display: "block",
                                          width: "50%",
                                          position: "relative",
                                          transform: "translate(8px,8px)",
                                        }}
                                      >
                                        {data.iconType === "task" ? (
                                          <CalendarIcon
                                            color={"var(--primaryColor)"}
                                          />
                                        ) : data.iconType === "task" ? (
                                          <Dashboard
                                            color={"var(--primaryColor)"}
                                          />
                                        ) : data.iconType === "recruitment" ? (
                                          <UserFocusIcon1
                                            color={"var(--primaryColor)"}
                                          />
                                        ) : data.iconType === "Candidate" ? (
                                          <UserIcon
                                            color={"var(--primaryColor)"}
                                          />
                                        ) : data.iconType === "requirement" ? (
                                          <BrieftCaseIcon
                                            color={"var(--primaryColor)"}
                                          />
                                        ) : data.iconType === "company" ? (
                                          <BuildingsIcon
                                            color={"var(--primaryColor)"}
                                          />
                                        ) : data.iconType === "Employee" ? (
                                          <UserIcon
                                            color={"var(--primaryColor)"}
                                          />
                                        ) : data.iconType === "settings" ? (
                                          <SettingIcon
                                            color={"var(--primaryColor)"}
                                          />
                                        ) : (
                                          <BellICon
                                            color={"var(--primaryColor)"}
                                          />
                                        )}
                                      </div>
                                    }
                                  />
                                </Col>
                                <Col md={20}>
                                  <p
                                    style={{
                                      lineHeight: "21px",
                                      color: "var(--body-text-color)",
                                    }}
                                    // style={{marginTop:"-7%"}}
                                    className={`${commonCssStyles.marginBottomRemove}`}
                                    dangerouslySetInnerHTML={{
                                      __html: data.notification,
                                    }}
                                  ></p>
                                  <p
                                    style={{ lineHeight: "19px" }}
                                    className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.notificationSubText}`}
                                    // style={{marginTop:"-20%"}}
                                  >
                                    {moment().diff(
                                      moment(data.createdAt),
                                      "minutes"
                                    ) <= 60
                                      ? moment().diff(
                                          data.createdAt,
                                          "minutes"
                                        ) + " minutes ago"
                                      : moment().diff(
                                          moment(data.createdAt),
                                          "hour"
                                        ) <= 12
                                      ? moment().diff(data.createdAt, "hour") +
                                        " hour ago"
                                      : moment().diff(
                                          moment(data.createdAt),
                                          "days"
                                        ) <= 10
                                      ? moment().diff(data.createdAt, "days") +
                                        " days ago"
                                      : moment(data.createdAt).format(
                                          dataFormate
                                        )}
                                  </p>
                                </Col>
                              </Row>
                            );
                          })}
                      </Col>
                    </Row>
                  </Col>
                </NotificationCard>
              )}

              {/* help / */}
              <Col>
                <Avatar
                  size={{ sm: 40, lg: 50 }}
                  onClick={() => {
                    setHelpModalOpen(true);
                  }}
                  className={`${styles.avtarForLogAndNotf} ${commonCssStyles.coursorPointer}`}
                  id="headers-help-icon"
                  icon={
                    <div className={`${commonCssStyles.toCenterAvtarIconDiv}`}>
                      <span
                        className={`${commonCssStyles.toCenterAvtarIconSpan}`}
                      >
                        <QuestionCircleOutlined
                          style={{
                            color: "var(--primaryColor)",
                            fontSize: "25px",
                          }}
                        />
                      </span>
                    </div>
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* end of help  */}
      </Header>
      <Modal
        className="modalHelp"
        open={helpModalOpen}
        title={
          <Row justify="space-between">
            <Col>
              <p
                className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.popUpHeadingText}`}
              >
                Help
              </p>
            </Col>

            <Col>
              <span
                style={{ float: "right" }}
                onClick={() => {
                  setHelpModalOpen(false);
                }}
                className={`${commonCssStyles.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)"} />
              </span>
            </Col>
          </Row>
        }
        closable={false}
        bodyStyle={{ height: 500 }}
        width={1000}
        footer={false}
      >
        <Carousel
          arrows
          afterChange={onChange}
          nextArrow={
            <LeftOutlined
              style={{ color: "var(--primaryColor)", fontSize: "25px" }}
            />
          }
          prevArrow={
            <RightOutlined
              style={{ color: "var(--primaryColor)", fontSize: "25px" }}
            />
          }
          ref={ref}
        >
          <div>
            <h3 style={contentStyle}>
              <Avatar style={{ color: "var(--headings-color)" }}>1</Avatar>
              <p>sample text</p>
            </h3>
          </div>
          <div>
            <h3 style={contentStyle}>
              <Avatar style={{ color: "var(--headings-color)" }}>2</Avatar>
              <p>sample text</p>
            </h3>
          </div>
          <div>
            <h3 style={contentStyle}>
              <Avatar style={{ color: "var(--headings-color)" }}>3</Avatar>
              <p>sample text</p>
            </h3>
          </div>
          <div>
            <h3 style={contentStyle}>
              <Avatar style={{ color: "var(--headings-color)" }}>4</Avatar>
              <p>sample text</p>
            </h3>
          </div>
        </Carousel>
        <div>
          <p
            className={commonCssStyles.coursorPointer}
            onClick={() => {
              ref.current.prev();
            }}
            style={{ position: "absolute", top: "50%", fontSize: "25px" }}
          >
            <LeftOutlined />
          </p>
          <p
            className={commonCssStyles.coursorPointer}
            onClick={() => {
              ref.current.next();
            }}
            style={{
              position: "absolute",
              right: 10,
              top: "48%",
              fontSize: "25px",
            }}
          >
            <RightOutlined />
          </p>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    settindsData: state.settings.settings,
    Notifications: state.notification.Notifications,
    globalSearchdata: state.globalSearch.globalSearch,
    loginCount: state.authentication.accessToken
      ? state.authentication.accessToken
      : localStorage.getItem("loginCount"),
    gsDataStatus: state.globalSearch.gsDataStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationsHandler: () => {
      dispatch(getNotificationsAction());
    },
    getNotificationMarkZeroHandler: () => {
      dispatch(getNotificationMarkZeroAction());
    },
    //gloal search
    getGlobalSearchHandler: (data) => {
      dispatch(getGlobalSearchAction(data));
    },
    putNotificationAction: (data, callback) => {
      dispatch(putNotificationAction(data, callback));
    },
    getGSDataStatusHandler: (data) => {
      dispatch(getGSDataStatusSlice(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Headers);
