import { Button, Card, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { CaretRightIcon } from "../../../IconsComp/Icons";
import styles from "../Application/Application.module.css";
import JobDescription from "./JobDescription";
import CandidateSelectionAndRejection from "./CandidateSelectionAndRejection";
import EmailTemplates from "./EmailTemplates";
import JobListingPage from "./JobListingPage";
import SourceOfTheLead from "./SourceOfTheLead";
import JobLinkForSharingMedium from "./JobLinkForSharingMedium";
import ApplicationTags from "./ApplicationTags";
import commonStyles from "../../../UI/CommonCss.module.css";
import { useForm } from "antd/lib/form/Form";
import Department from "./Department";
import { connect } from "react-redux";
import { checkPermissionAction } from "../../../../Reusable/Store/Action/AuthPermissionsAction";
import PermissionMessage from "../../../UI/PermissionMessage";

const Application = ({
  settings,
  postSettings,
  permissions,
  ishavepermissions,

  getCheckPermissionHandler,
}) => {
  const [data, setData] = useState("var(--theme)", "2px solid red");
  const [key, setKey] = useState(0);
  const [form] = useForm();
  function backToAppHandler() {
    setKey(0);
  }
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });

  const applicationRouter = () => {
    if (key === 0) {
      return (
        <>
          {permissions &&
            permissions.find(
              (data) => data === "settings-application-emailTemplate"
            ) && (
              <Button
                style={{ backgroundColor: data }}
                onMouseEnter={() => {
                  setData("var(--theme)");
                }}
                onClick={() => {
                  getCheckPermissionHandler(
                    "settings-application-emailTemplate",
                    (success) => {
                      if (success) {
                        setKey(1);
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Email Template",
                        });
                      }
                    }
                  );
                }}
                className={styles.mainButtonForApplication}
              >
                <Row justify="space-between" align="middle">
                  <Col xs={22} md={22}>
                    <p className={styles.mainButtonForApplicationName}>
                      Email templates
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    className={styles.mainButtonForApplicationIcon}
                  >
                    <CaretRightIcon color={"var(--application-btn-text)"} />
                  </Col>
                </Row>
              </Button>
            )}

          {permissions &&
            permissions.find(
              (data) => data === "settings-application-tags"
            ) && (
              <Button
                style={{ backgroundColor: data }}
                className={styles.mainButtonForApplication}
                onClick={() => {
                  getCheckPermissionHandler(
                    "settings-application-tags",
                    (success) => {
                      if (success) {
                        setKey(2);
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Tags",
                        });
                      }
                    }
                  );
                }}
              >
                <Row justify="space-between" align="middle">
                  <Col xs={22} md={22}>
                    <p className={styles.mainButtonForApplicationName}>Tags</p>
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    className={styles.mainButtonForApplicationIcon}
                  >
                    <CaretRightIcon color={"var(--application-btn-text)"} />
                  </Col>
                </Row>
              </Button>
            )}

          {permissions &&
            permissions.find(
              (data) => data === "settings-application-leadSource"
            ) && (
              <Button
                style={{ backgroundColor: data }}
                className={styles.mainButtonForApplication}
                onClick={() => {
                  getCheckPermissionHandler(
                    "settings-application-leadSource",
                    (success) => {
                      if (success) {
                        setKey(3);
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Lead Source",
                        });
                      }
                    }
                  );
                }}
              >
                <Row justify="space-between" align="middle">
                  <Col xs={22} md={22}>
                    <p className={styles.mainButtonForApplicationName}>
                      Source Of the Lead
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    className={styles.mainButtonForApplicationIcon}
                  >
                    <CaretRightIcon color={"var(--application-btn-text)"} />
                  </Col>
                </Row>
              </Button>
            )}

          {permissions &&
            permissions.find(
              (data) => data === "settings-application-candidateReason"
            ) && (
              <Button
                style={{ backgroundColor: data }}
                className={styles.mainButtonForApplication}
                onClick={() => {
                  getCheckPermissionHandler(
                    "settings-application-candidateReason",
                    (success) => {
                      if (success) {
                        setKey(4);
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Candidate Reason",
                        });
                      }
                    }
                  );
                }}
              >
                <Row justify="space-between" align="middle">
                  <Col xs={22} md={22}>
                    <p className={styles.mainButtonForApplicationName}>
                      Candidate Selection and Rejection Reasons
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    className={styles.mainButtonForApplicationIcon}
                  >
                    <CaretRightIcon color={"var(--application-btn-text)"} />
                  </Col>
                </Row>
              </Button>
            )}

          {permissions &&
            permissions.find(
              (data) => data === "settings-application-jobListing"
            ) && (
              <Button
                style={{ backgroundColor: data }}
                className={styles.mainButtonForApplication}
                onClick={() => {
                  getCheckPermissionHandler(
                    "settings-application-jobListing",
                    (success) => {
                      if (success) {
                        setKey(5);
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Job Listing",
                        });
                      }
                    }
                  );
                }}
              >
                <Row justify="space-between" align="middle" wrap={false}>
                  <Col xs={22} md={22}>
                    <p className={styles.mainButtonForApplicationName}>
                      Job Listing and Individual job detail Page Permissions
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    className={styles.mainButtonForApplicationIcon}
                  >
                    <CaretRightIcon color={"var(--application-btn-text)"} />
                  </Col>
                </Row>
              </Button>
            )}

          {permissions &&
            permissions.find(
              (data) => data === "settings-application-jobDescription"
            ) && (
              <Button
                style={{ backgroundColor: data }}
                className={styles.mainButtonForApplication}
                onClick={() => {
                  getCheckPermissionHandler(
                    "settings-application-jobDescription",
                    (success) => {
                      if (success) {
                        setKey(6);
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Job Description",
                        });
                      }
                    }
                  );
                }}
              >
                <Row justify="space-between" align="middle">
                  <Col xs={22} md={22}>
                    <p className={styles.mainButtonForApplicationName}>
                      Job Description
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    className={styles.mainButtonForApplicationIcon}
                  >
                    <CaretRightIcon color={"var(--application-btn-text)"} />
                  </Col>
                </Row>
              </Button>
            )}

          {permissions &&
            permissions.find(
              (data) => data === "settings-application-sharingMEdium"
            ) && (
              <Button
                style={{ backgroundColor: data }}
                className={styles.mainButtonForApplication}
                onClick={() => {
                  getCheckPermissionHandler(
                    "settings-application-sharingMEdium",
                    (success) => {
                      if (success) {
                        setKey(7);
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Sharing Medium",
                        });
                      }
                    }
                  );
                }}
              >
                <Row justify="space-between" align="middle" wrap={false}>
                  <Col xs={22} md={22}>
                    <p className={styles.mainButtonForApplicationName}>
                      Job link for sharing mediums
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    className={styles.mainButtonForApplicationIcon}
                  >
                    <CaretRightIcon color={"var(--application-btn-text)"} />
                  </Col>
                </Row>
              </Button>
            )}
          {permissions &&
            permissions.find(
              (data) => data === "settings-application-department"
            ) && (
              <Button
                style={{ backgroundColor: data }}
                className={styles.mainButtonForApplication}
                onClick={() => {
                  getCheckPermissionHandler(
                    "settings-application-department",
                    (success) => {
                      if (success) {
                        setKey(8);
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Department",
                        });
                      }
                    }
                  );
                }}
              >
                <Row justify="space-between" align="middle" wrap={false}>
                  <Col xs={22} md={22}>
                    <p className={styles.mainButtonForApplicationName}>
                      Department
                    </p>
                  </Col>
                  <Col
                    xs={2}
                    md={2}
                    className={styles.mainButtonForApplicationIcon}
                  >
                    <CaretRightIcon color={"var(--application-btn-text)"} />
                  </Col>
                </Row>
              </Button>
            )}
          {settings &&
          permissions &&
          permissions.find(
            (data) => data === "settings-application-jobPrefix"
          ) ? (
            <Form
              form={form}
              layout="vertical"
              initialValues={{
                jobPrefix: settings.jobPrefix,
              }}
              onFinish={(val) => {
                postSettings({ ...val });
              }}
              requiredMark={false}
            >
              <Card
                style={{ overflow: "hidden" }}
                className={styles.CardForJobPrefixCode}
              >
                <Row>
                  <Col span={22}>
                    <Form.Item
                      name="jobPrefix"
                      rules={[
                        {
                          required: true,
                          message: "JobPrefix Required",
                        },
                      ]}
                      label={
                        <p
                          className={styles.cardPrefixLabelName}
                          style={{ color: "var(--headings-color)" }}
                        >
                          Job Prefix Code
                        </p>
                      }
                    >
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Enter Job Prefix Code"
                        className={styles.CardForJobInputField}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Row>
                <Col
                  style={{
                    position: "absolute",
                    right: "0px",
                    margin: "10px 0px",
                  }}
                >
                  <Button
                    onClick={() => {
                      form.submit();
                    }}
                    htmlType="button"
                    type="primary"
                    className={commonStyles.buttonSettingsPrimary}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : (
            ""
          )}
          <PermissionMessage
            onOpenPMModal={openPMModal}
            onClosePMModal={() => {
              setOpenPMModal({ open: false, permission: "" });
            }}
          />
        </>
      );
    } else if (key === 1) {
      return <EmailTemplates backToApp={backToAppHandler} />;
    } else if (key === 2) {
      return <ApplicationTags /*Tags*/ backToApp={backToAppHandler} />;
    } else if (key === 3) {
      return <SourceOfTheLead backToApp={backToAppHandler} />;
    } else if (key === 4) {
      return <CandidateSelectionAndRejection backToApp={backToAppHandler} />;
    } else if (key === 5) {
      return <JobListingPage backToApp={backToAppHandler} />;
    } else if (key === 6) {
      return <JobDescription backToApp={backToAppHandler} />;
    } else if (key === 7) {
      return <JobLinkForSharingMedium backToApp={backToAppHandler} />;
    } else if (key === 8) {
      return <Department backToApp={backToAppHandler} />;
    }
  };
  return applicationRouter();
};

const mapStateToProps = (state) => {
  return {
    permissions: state.authpermissions.permissions,
    ishavepermissions: state.authpermissions.ishavepermissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Application);
