import {
  Col,
  Row,
  Timeline,
  Card,
  Avatar,
  Empty,
  Form,
  DatePicker,
  Button,
} from "antd";
import { ClipBoardIcon, UserPlusIcon } from "../../../IconsComp/Icons";
import styles from "../../AddCandidatesComp/AddCandidatesComp.module.css";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import { getCandidateTimeLineSlice } from "../../../../Reusable/Store/Slice/CandidatesSlice";
import { connect } from "react-redux";
import {
  getCandidatesTimelineAction,
  postCandidateTimelineFilterAction,
} from "../../../../Reusable/Store/Action/CandidatesAction";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";

function TimeLine(props) {
  let { settings } = props;
  const location = useLocation();

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  useEffect(() => {
    props.getCandidatesTimelineHandler(location.state.id);
  }, [location]);

  const [form] = useForm();
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));

  const onFinish = (val) => {
    const data = {
      dateFrom: val.dateFrom
        ? moment(val.dateFrom).format("YYYY-MM-DD")
        : "1970-01-01",
      dateTo: val.dateTo ? moment(val.dateTo).format("YYYY-MM-DD") : "3000-01-01",
      type: "candidate",
      id: location.state.id,
    };
    props.postTimelineFilterHandler(data, (success) => {
      if (success) {
      }
    });
  };

  return (
    <>
      <Row style={{ marginTop: "-12px" }}>
        <Col sm={24}>
          <Card>
            <Form form={form} onFinish={onFinish}>
              <Row
                style={{ gap: "20px", padding: "20px 15px 0px 20px" }}
                justify="end"
              >
                <Col>
                  <Form.Item
                    name="dateFrom"
                    style={{
                      border: "0.5px solid var(--dr-border-color)",
                      borderRadius: "9px",
                    }}
                  >
                    <DatePicker
                      format={dataFormate}
                      suffixIcon={null}
                      className="ReportsFrom"
                      placeholder="from"
                      style={{ width: "115px", height: "40px" }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="dateTo"
                    style={{
                      border: "0.5px solid var(--dr-border-color)",
                      borderRadius: "9px",
                    }}
                  >
                    <DatePicker
                      className="ReportsFrom"
                      suffixIcon={null}
                      format={dataFormate}
                      placeholder="to"
                      style={{ width: "115px", height: "40px" }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  {" "}
                  <Button
                    className={commonCssStyle.buttonSettingsPrimary}
                    style={{
                      width: "100px",
                      height: "40px",
                      marginTop: "3px",
                    }}
                    type="primary"
                    size="small"
                    htmlType="button"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Filter
                  </Button>
                </Col>
                <Col>
                  {" "}
                  <Button
                    className={commonCssStyle.buttonSettingsSecondary}
                    style={{
                      width: "100px",
                      height: "40px",
                      marginTop: "3px",
                    }}
                    type="default"
                    size="small"
                    onClick={() => {
                      form.resetFields();
                      const data = {
                        dateFrom: moment().format("YYYY-MM-DD"),
                        dateTo: moment().format("YYYY-MM-DD"),
                        type: "candidate",
                        id: location.state.id,
                      };
                      props.postTimelineFilterHandler(data, (success) => {
                        if (success) {
                        }
                      });
                    }}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col sm={24}>
          <Card style={{ paddingTop: "30px" }}>
            <Timeline
              className="timeLineStyle"
              mode="left"
              style={{ width: "60%" }}
            >
              {props.candidates_TimeLine &&
              props.candidates_TimeLine.length > 0 ? (
                props.candidates_TimeLine.map((x) => {
                  return (
                    <Timeline.Item
                      dot={
                        <Avatar
                          size={50}
                          className={commonCssStyle.avtarBgClr}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          {x.action === "profile" ? (
                            <div style={{ transform: "translate(4%,8%)" }}>
                              <UserPlusIcon color={"var(--primaryColor)"} />
                            </div>
                          ) : (
                            <div style={{ transform: "translate(4%,8%)" }}>
                              <ClipBoardIcon color={"var(--primaryColor)"} />
                            </div>
                          )}
                        </Avatar>
                      }
                      label={
                        <>
                          <Row style={{ minHeight: "100px" }}>
                            <Col>
                              <p
                                style={{ margin: "0px", paddingBottom: "8px" }}
                                className={commonCssStyle.timelineText1}
                              >
                                {x.addedAt &&
                                  moment(x.addedAt).format(
                                    settings && settings.dateFormat
                                      ? settings.dateFormat
                                      : "DD-MM-YYYY"
                                  )}
                              </p>
                              <p
                                style={{ margin: "0px" }}
                                className={commonCssStyle.timelineText2}
                              >
                                {moment(x.addedAt).format(
                                  settings &&
                                    settings.timeFormat &&
                                    settings.timeFormat === "24hours"
                                    ? "HH:mm"
                                    : "hh:mm a"
                                )}
                              </p>
                            </Col>
                          </Row>
                        </>
                      }
                    >
                      {x.timelineText && (
                        <span
                          className={commonCssStyle.timelineTextNormal}
                          dangerouslySetInnerHTML={{ __html: x.timelineText }}
                        ></span>
                      )}
                      {/* <b className={commonCssStyle.timelineTextBold}>Sania Malhotra,</b>{" "}
            <span className={commonCssStyle.timelineTextNormal}>
              added to the
            </span>{" "}
            <b className={commonCssStyle.timelineTextBold}>UX Designer</b>{" "}
            <span className={commonCssStyle.timelineTextNormal}>
              job, location
            </span>
            <b className={commonCssStyle.timelineTextBold}>
              Bengaluru by Anupam Singhania,
            </b>{" "}
            <span className={commonCssStyle.timelineTextNormal}>
              {" "}
              HR Department
            </span> */}
                    </Timeline.Item>
                  );
                })
              ) : (
                <Empty></Empty>
              )}

              {/* <Timeline.Item
              dot={
                <Avatar
                  size={50}
                  className={commonCssStyle.avtarBgClr}
                  style={{ fontSize: "16px" }}
                >
                  <div style={{ transform: "translate(4%,8%)" }}>
                    <ClipBoardIcon color={"var(--primaryColor)"} />
                  </div>
                </Avatar>
              }
              label={
                <>
                  <Row>
                    <Col>
                      <p
                        style={{ margin: "0px", paddingBottom: "8px" }}
                        className={commonCssStyle.timelineText1}
                      >
                        Sept 24rd, 2022
                      </p>

                      <p
                        style={{ margin: "0px" }}
                        className={commonCssStyle.timelineText2}
                      >
                        15:10 PM
                      </p>
                    </Col>
                  </Row>
                </>
              }
            >
              <b className={commonCssStyle.timelineTextBold}>Sania Malhotra,</b>{" "}
              <span className={commonCssStyle.timelineTextNormal}>
                cuurent stage has been changed to{" "}
              </span>
              <b className={commonCssStyle.timelineTextBold}>HR Review</b>
            </Timeline.Item> */}
            </Timeline>
          </Card>
        </Col>
      </Row>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    candidates_TimeLine: state.candidates.candidates_TimeLine,
    settings: state.settings.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCandidatesTimelineHandler: (id) => {
      dispatch(getCandidatesTimelineAction(id));
    },
    postTimelineFilterHandler: (val, callback) => {
      dispatch(postCandidateTimelineFilterAction(val, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeLine);
