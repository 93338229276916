import { Button, Card, Col, Form, Row, Select, Input, Modal, Tag } from "antd";
import React, { useEffect, useState, useRef } from "react";
import styles from "../Application/Application.module.css";
import { useForm } from "antd/lib/form/Form";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import {
  BackButtonIcon,
  CloseIcon,
  TagEditIcon,
} from "../../../IconsComp/Icons";
import { connect } from "react-redux";
import {
  getDepartmentsAction,
  postDepartmentsAction,
  deleteDepartmentAction,
  putDepartmentsAction,
  getDepartmentAction,
} from "../../../../Reusable/Store/Action/Master/MasterDepartmentsAction";
import DepartmentEditModal from "./DepartmentEditModal";
function Department(props) {
  const [form] = useForm();
  const [tags, setTags] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [modal, setModal] = useState(false);
  const [editId, setEditId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    props.getDepartmentHandler();
  }, []);
  const handleClose = () => {
    setModal(false);
  };
  const onDepartmentChange = (event) => {
    setTags(event.target.value);
  };
  const handleOpen = (removedTag) => {
    setDeleteId(removedTag);
    setModal(true);
  };
  const addDepartmentHandler = (e) => {
    e.preventDefault();
    if (tags.trim().length === 0) {
      return;
    }

    const val = {
      departmentName: tags.trim(),
    };
    props.postDepartmentHandler(val, (success) => {
      if (success) {
        setTags("");
        props.getDepartmentHandler();
      }
    });
  };

  const forMap = (tag) => {
    if (tag === undefined) {
      return;
    }
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        closable
        icon={
          <span
            onClick={() => {
              // setEditId(tag.id);
              props.getDepartmentByIdHandler(tag.id, (success) => {
                if (success) {
                  setEditModal(true);
                }
              });
            }}
            className={commonCssStyle.coursorPointer}
          >
            <TagEditIcon
              height={"19"}
              width={"34"}
              color={"var(--primaryColor)"}
            />
          </span>
        }
        onClose={(e) => {
          e.preventDefault();
          handleOpen(tag.id);
        }}
      >
        {tag.departmentName}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagChild = props.departmentData && props.departmentData.map(forMap);

  return (
    <>
      <Card style={{ overflow: "hidden" }}>
        <Card style={{ overflow: "hidden" }} className={styles.TagsCard1}>
          <Row align="middle" wrap={false}>
            <Col
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.backToApp();
              }}
              className={styles.SourceofTheLeadBackBtn}
            >
              <BackButtonIcon color={"var(--icons-color)"} />
            </Col>
            <Col>
              <h1 className={commonCssStyle.applicationCardName}>Department</h1>
            </Col>
          </Row>
        </Card>
        <Card
          style={{
            minHeight: "70vh",

            padding: "0px 24px",
          }}
          className={`${commonCssStyle.overflow} ${styles.TagCard2}`}
        >
          <Form onFinish={(val) => {}} layout="vertical">
            <Row>
              <Col md={24} className="modelcol">
                <Form.Item
                  label={
                    <h1
                      style={{ paddingTop: "24px" }}
                      className={commonCssStyle.darkText}
                    >
                      Department
                    </h1>
                  }
                  name="departmentName"
                >
                  <Input
                    className="tagsInput"
                    placeholder="Please Enter Deparment Tag"
                    ref={inputRef}
                    value={tags}
                    onChange={onDepartmentChange}
                    onPressEnter={addDepartmentHandler}
                  />

                  <div
                    style={{
                      marginBottom: 16,
                    }}
                  >
                    <p>{tagChild}</p>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Card>
      <Modal
        open={modal}
        closable={false}
        title={
          <Row justify="space-between">
            <Col>
              <h3
                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.popUpHeadingText}`}
              >
                Delete department
              </h3>
            </Col>
            <Col>
              <span
                style={{ float: "right" }}
                onClick={() => {
                  handleClose();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)"} />
              </span>
            </Col>
          </Row>
        }
        footer={
          <>
            <Row style={{ gap: "10px" }} justify="end" align="middle">
              <Col>
                <Button
                  type="default"
                  className={commonCssStyle.buttonSettingsSecondary}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className={commonCssStyle.buttonSettingsPrimary}
                  onClick={() => {
                    props.deleteDepartmentHandler(deleteId, (success) => {
                      handleClose();
                    });
                  }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Row>
          <Col>
            <p
              className={`${commonCssStyle.deleteConfiTextColor} ${commonCssStyle.deleteConfiTextColor}`}
            >
              Are you sure you want to delete
            </p>
          </Col>
        </Row>
      </Modal>
      {/* edit modal start */}
      {editModal && props.departmentByIdData && (
        <DepartmentEditModal
          onEditModal={editModal}
          onSubmitData={(val) => {
            props.putDepartmentHandler(val, (success) => {
              if (success) {
                setEditModal(false);
              }
            });
          }}
          onDepartmentByIdData={
            props.departmentByIdData && props.departmentByIdData
          }
          onCloseEditModal={() => {
            setEditModal(false);
          }}
        />
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    departmentData: state.departments.departments,
    departmentByIdData: state.departments.department,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDepartmentHandler: () => {
      dispatch(getDepartmentsAction());
    },
    postDepartmentHandler: (data, callback) => {
      dispatch(postDepartmentsAction(data, callback));
    },
    deleteDepartmentHandler: (id, callback) => {
      dispatch(deleteDepartmentAction(id, callback));
    },
    putDepartmentHandler: (data, callback) => {
      dispatch(putDepartmentsAction(data, callback));
    },
    getDepartmentByIdHandler: (id, callback) => {
      dispatch(getDepartmentAction(id, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Department);
