import { useEffect, useState } from "react";
import {
  Table,
  Tag,
  Dropdown,
  Menu,
  Row,
  Col,
  Button,
  Select,
  Switch,
  Modal,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import {
  ThreeDotsIcon,
  FilterOutlinedIcon,
  EditIcon,
  TrashIcon,
  PlusIcon,
  CloseIcon,
} from "../../IconsComp/Icons";
import { Link, useNavigate } from "react-router-dom";
import ClientCompanyDrawer from "./ClientCompanyDrawer";
import ClientCompanyFilter from "./ClientCompanyFilter";
import commonCssStyle from "../../UI/CommonCss.module.css";
import { connect } from "react-redux";
import {
  client_FilterAction,
  deleteClientAction,
  getClientAction,
  getClientsAction,
  postClientAction,
  putClientAction,
} from "../../../Reusable/Store/Action/ClientAction";
import { getCountriesAction } from "../../../Reusable/Store/Action/Master/MasterCountryAction";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import PermissionMessage from "../../UI/PermissionMessage";
import BreadcrumbForClient from "./BreadcrumbForClient";

function ClientCompanyTable(props) {
  const [open, setOpen] = useState(false);
  const [openType, setOpenType] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [deleteBtnClr, setDeleteBtnClr] = useState("var(--headings-color)");
  const [cfilter, setCFilter] = useState({});
  const [deletId, setDeleteId] = useState("");
  const [openDeleteModal, SetOpenDeleteModal] = useState(false);
  const [linkState, setLinkState] = useState(true);
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  // const [clientStatus, setClientStatus] = useState({
  //   clientContact: false,
  // });
  const navigate = useNavigate();
  function confirmDeleteHandler() {
    SetOpenDeleteModal(false);
    props.deleteclientDetailsHandler(deletId, (success) => {});
    setDeleteId("");
  }
  function closeDeleteHandler() {
    SetOpenDeleteModal(false);
  }
  useEffect(() => {
    props.getClientsHandler();

    // props.getCountryHandler();
  }, []);

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("clientCompany"));
    if (obj) {
      props.getClientFilterHandler(obj);
    } else {
      props.getClientFilterHandler({ current_page: 1 });
    }
  }, []);

  const showDrawer = (type, id) => {
    setOpen(true);
    setOpenType(type);

    if (type === "edit") {
      props.getClientHandler(id);
    }
  };
  const closeHandler = () => {
    setOpen(false);
  };

  if (window.location.hash === "#/clientCompany") {
    localStorage.removeItem("candidateDetail");
    localStorage.removeItem("employee");
    localStorage.removeItem("requirementFilterData");
    localStorage.removeItem("recuritmentFilterData");
    localStorage.removeItem("taskFilterData");
    localStorage.removeItem("interviewFilterData");
    localStorage.removeItem("candidateDetailFromRequirement");
    localStorage.removeItem("candidateListForPool");
    localStorage.removeItem("recuritmentCandidateFilter");
    localStorage.removeItem("reportsCompanyDetailFilterData");
    localStorage.removeItem("reportsjobdetailfilterdata");
    localStorage.removeItem("reportsjobcandidatefilterdata");
    localStorage.removeItem("fromWhichPage");
    localStorage.removeItem("candidateDetailFromRecruitment");
    localStorage.removeItem("recuritmentReportFilter");
    localStorage.removeItem("employeeReportsFilter");
    
    localStorage.removeItem("employeeSort");
    localStorage.removeItem("ReportsCompanyReport1Sort");
    localStorage.removeItem("reportsjobcandidatefilterdataSort");
    localStorage.removeItem("ReportsJobDetailFilterOneSort");
    localStorage.removeItem("reportRecruitmentEmployeeSort");

    localStorage.removeItem("candidateSort");
    localStorage.removeItem("interivewSort");
    localStorage.removeItem("taskSort");
    localStorage.removeItem("recuritmentSort");
    localStorage.removeItem("requirementSort");
  }

  function showFilterHandler() {
    setOpenFilter(true);
  }
  const closeFilterHandler = () => {
    setOpenFilter(false);
  };

  const columns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Company Name
        </p>
      ),
      dataIndex: "clientName",
      // width: "17%",
      render: (text, record) =>
        props.permissions &&
        props.permissions.find((data) => data === "client-detail") &&
        record.status === "active" ? (
          <p
            onClick={() => {
              props.getCheckPermissionHandler("client-detail", (success) => {
                if (success) {
                  navigate("/clientCompany/details", {
                    state: record.id,
                  });
                } else {
                  setOpenPMModal({
                    open: true,
                    permission: "Client Details",
                  });
                }
              });
            }}
            style={{ cursor: "pointer" }}
            className={`${commonCssStyle.marginBottomRemove}  ${commonCssStyle.tableDataLinkText}`}
          >
            {text}
          </p>
        ) : (
          <p
            className={`${commonCssStyle.marginBottomRemove} `} /* ${commonCssStyle.tableDataLinkText} */
            state={record.id}
          >
            {text}
          </p>
        ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Contact Person
        </p>
      ),
      dataIndex: "contactPerson",
      // width: "17%",
      // render: (data) => {
      //   props.permissions &&
      //   props.permissions.find(
      //     (data) => data === "client-contact-person-info"
      //   ) ? (
      //     <p
      //       className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
      //     >
      //       {data && data}
      //     </p>
      //   ) : (
      //     <p
      //       className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.infoTextColorBold}`}
      //     >
      //       *************
      //     </p>
      //   );
      // },
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Contact Person Email & Number
        </p>
      ),
      dataIndex: "contactPersonEmail",
      // width: "23%",
      render: (text, record) =>
        props.permissions &&
        props.permissions.find((data) => data === "client-contact-info") ? (
          <>
            <p
              className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
              style={{ marginBottom: "9px" }}
            >
              {record.contactPersonEmail}
            </p>
            <p
              className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
            >
              {record.contactPersonPhone}
            </p>
          </>
        ) : (
          <p
            className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.infoTextColorBold}`}
            style={{ marginLeft: "1px" }}
          >
            *************
          </p>
        ),
      // ) : (
      //   <>
      //     <p
      //       onClick={() => {
      //         props.getCheckPermissionHandler(
      //           "client-contact-info",
      //           (success) => {
      //             if (success) {
      //               setClientStatus({
      //                 clientContact: true,
      //               });
      //             } else {
      //               setClientStatus({
      //                 clientContact: false,
      //               });
      //               setOpenPMModal({
      //                 open: true,
      //                 permission: "Client Contact Info",
      //               });
      //             }
      //           }
      //         );
      //       }}
      //       className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.coursorPointer}`}
      //     >
      //       Show
      //     </p>
      //   </>
      // ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          No of Hires
        </p>
      ),
      dataIndex: "hireCount",
      // width: "11%",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
            style={{ marginLeft: "19px" }}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Active Jobs
        </p>
      ),
      dataIndex: "activeJobs",
      // width: "11%",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
            style={{ marginLeft: "19px" }}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Company Status
        </p>
      ),
      align: "center",
      dataIndex: "status",
      // width: "20%",
      render: (text, record) => (
        <>
          <Row
            align="middle"
            wrap={false}
            style={{ gap: "24px", float: "right" }}
          >
            <Col>
              <Tag
                className={
                  text === "active"
                    ? commonCssStyle.activeTag
                    : commonCssStyle.inactiveTag
                }
              >
                {text}
              </Tag>
            </Col>
            {props.permissions &&
              props.permissions.find((data) => data === "client-edit") && (
                <Col>
                  <Switch
                    checked={text === "active" ? true : false}
                    onChange={() => {
                      props.getCheckPermissionHandler(
                        "client-edit",
                        (success) => {
                          if (success) {
                            props.putclientTemplateHandler(
                              {
                                id: record.id,
                                status:
                                  text === "active" ? "inactive" : "active",
                                current_page: props.clientsData.current_page
                                  ? props.clientsData.current_page
                                  : 1,
                              },
                              "grid",
                              (success) => {}
                            );
                          } else {
                            setOpenPMModal({
                              open: true,
                              permission: "Client Edit",
                            });
                          }
                        }
                      );
                    }}
                  />
                </Col>
              )}
            {props.permissions &&
              props.permissions.find((data) => data === "client-delete") && (
                <Col>
                  <Dropdown
                    placement="bottomRight"
                    overlay={
                      <Menu
                        style={{ width: "180px" }}
                        items={[
                          // {
                          //   key: "1",
                          //   label: (
                          //     <Row
                          //       onClick={() => {
                          //         showDrawer("edit", record.id);
                          //       }}
                          //       onMouseEnter={() => {
                          //         setIconClr("#2F7BF5");
                          //       }}
                          //       onMouseLeave={() => {
                          //         setIconClr("var(--body-text-color)");
                          //       }}
                          //     >
                          //       <Col style={{ padding: "5px 5px 0px 0px" }}>
                          //         <EditIcon color={iconClr} />
                          //       </Col>
                          //       <Col style={{ padding: "5px 5px 0px 5px" }}>
                          //         <p
                          //           style={{ marginBottom: "0px", color: iconClr }}
                          //         >
                          //           Edit
                          //         </p>
                          //       </Col>
                          //     </Row>
                          //   ),
                          // },
                          {
                            key: "2",
                            label: (
                              <Row
                                onClick={() => {
                                  props.getCheckPermissionHandler(
                                    "client-delete",
                                    (success) => {
                                      if (success) {
                                        SetOpenDeleteModal(true);
                                        setDeleteId(record.id);
                                      } else {
                                        setOpenPMModal({
                                          open: true,
                                          permission: "Client Delete",
                                        });
                                      }
                                    }
                                  );
                                }}
                                onMouseEnter={() => {
                                  setDeleteBtnClr("red");
                                }}
                                onMouseLeave={() => {
                                  setDeleteBtnClr("var(--headings-color)");
                                }}
                              >
                                <Col style={{ padding: "5px 5px 0px 0px" }}>
                                  <TrashIcon color={deleteBtnClr} />
                                </Col>
                                <Col
                                  style={{
                                    padding: "5px 5px 0px 5px",
                                    color: deleteBtnClr,
                                  }}
                                >
                                  <p>Delete</p>
                                </Col>
                              </Row>
                            ),
                          },
                        ]}
                      />
                    }
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      <ThreeDotsIcon color={"var(--table-text-color)"} />
                    </Link>
                  </Dropdown>
                </Col>
              )}
          </Row>
        </>
      ),
    },
  ];

  const fetchRecords = (page) => {
    const FdataComp = JSON.parse(localStorage.getItem("clientCompany"));
    if (FdataComp) {
      const val = {
        ...cfilter,
        ...FdataComp,
        ...props.clientsData,
        current_page: page,
      };
      localStorage.setItem(
        "clientCompany",
        JSON.stringify({ ...FdataComp, current_page: page })
      );
      props.getClientFilterHandler(val);
    } else {
      const val = {
        ...cfilter,
        ...props.clientsData,
        current_page: page,
      };
      localStorage.setItem(
        "clientCompany",
        JSON.stringify({ current_page: page })
      );
      props.getClientFilterHandler(val);
    }
  };

  const handleProvinceChange = (value, d) => {
    localStorage.setItem("ClientCompanySort", JSON.stringify(d.value));
    let clientCompanyFilter = JSON.parse(localStorage.getItem("clientCompany"));
    let sort = value.split("_");
    if (clientCompanyFilter) {
      props.getClientFilterHandler({
        current_page: 1,
        ...cfilter,
        ...clientCompanyFilter,
        sortBy: sort[0],
        sortOrder: sort[1],
      });
      setCFilter({
        current_page: 1,
        ...cfilter,
        ...clientCompanyFilter,
        sortBy: sort[0],
        sortOrder: sort[1],
      });
      localStorage.setItem(
        "clientCompany",
        JSON.stringify({
          current_page: 1,
          ...clientCompanyFilter,
          sortBy: sort[0],
          sortOrder: sort[1],
        })
      );
    } else {
      props.getClientFilterHandler({
        current_page: 1,
        ...cfilter,
        sortBy: sort[0],
        sortOrder: sort[1],
      });
      setCFilter({
        current_page: 1,
        ...cfilter,
        sortBy: sort[0],
        sortOrder: sort[1],
      });
      localStorage.setItem(
        "clientCompany",
        JSON.stringify({current_page: 1, ...cfilter, sortBy: sort[0], sortOrder: sort[1] })
      );
    }
  };
  const clientFilterHandleer = (val) => {
    let clientCompanyFilter = JSON.parse(localStorage.getItem("clientCompany"));
    const data = {
      ...clientCompanyFilter,
      ...val,
      current_page: 1,
    };

    setCFilter(data);
    props.getClientFilterHandler(data);
    localStorage.setItem("clientCompany", JSON.stringify(data));
  };
  const onSubmit = (val) => {
    const data = {
      ...val,
      current_page: 1,
    };
    if (openType === "add")
      props.postclientTemplateHandler(data, (success) => {
        if (success) {
          setOpen(false);
        }
      });
    else
      props.putclientTemplateHandler(
        { id: props.client.id, data },
        "grid",
        (success) => {
          if (success) setOpen(false);
        }
      );
  };
  return (
    <>
      <Row>
        <Col md={24} className={commonCssStyle.breadcrumbBottomMargin}>
          <BreadcrumbForClient slide={[1, 2]} />
        </Col>
        <Col md={24}>
          <ClientCompanyFilter
            clientFilterHandleer={clientFilterHandleer}
            clients={props.clients}
            onOpenFilter={openFilter}
            onCloseFilter={closeFilterHandler}
          />
          {openType === "add" && (
            <ClientCompanyDrawer
              clientData={props.clientsData}
              onSubmit={onSubmit}
              countriesData={props.countriesData}
              onOpen={open}
              onClose={closeHandler}
            />
          )}
          {openType === "edit" && props.client && (
            <ClientCompanyDrawer
              onSubmit={onSubmit}
              client={props.client}
              countriesData={props.countriesData}
              onOpen={open}
              onClose={closeHandler}
            />
          )}

          <Row align="middle" justify="space-between">
            <Col>
              <h1
                style={{ marginRight: "51px" }}
                className={`${commonCssStyle.inttitle} ${commonCssStyle.mainHeadingText}`}
              >
                Companies &#40;{" "}
                {props.clientsData && props.clientsData.total
                  ? props.clientsData.total
                  : "0"}{" "}
                &#41;
              </h1>

              <Button
                onClick={showFilterHandler}
                className={`${commonCssStyle.filterButton}`}
              >
                <Row style={{ gap: "11px" }}>
                  <Col>
                    <div style={{ transform: "translateY(2px)" }}>
                      <FilterOutlinedIcon color={"var(--primaryColor)"} />
                    </div>
                  </Col>
                  <Col>
                    <p
                      className={`${commonCssStyle.filterAndSortTextStyle} ${commonCssStyle.marginBottomRemove}`}
                    >
                      Filter
                    </p>
                  </Col>
                </Row>
              </Button>

              <Button
                style={{
                  height: "40px",
                }}
                className={`${commonCssStyle.sortBtn}`}
              >
                <Row align="middle" justify="space-between">
                  <Col>
                    <p
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      Sort by
                    </p>
                  </Col>
                  <Col>
                    <Select
                      value={
                        JSON.parse(localStorage.getItem("ClientCompanySort"))
                          ? JSON.parse(
                              localStorage.getItem("ClientCompanySort")
                            )
                          : "Select"
                      }
                      style={{ width: "100%" }}
                      bordered={false}
                      onChange={handleProvinceChange}
                      placeholder={
                        <p
                          style={{
                            margin: "0px",
                            color: "var(--primaryColor)",
                          }}
                        >
                          Select
                        </p>
                      }
                      size="small"
                      className={`${commonCssStyle.filterDrop} selectInput sortBySelectStyle selectTag selectTag1`}
                    >
                      <Select.Option
                        value="clientName_ASC"
                        key="0"
                        style={{ padding: "10px" }}
                      >
                        Client Name Ascending
                      </Select.Option>
                      <Select.Option
                        value="clientName_DESC"
                        key="1"
                        style={{ padding: "10px" }}
                      >
                        Client Name Descending
                      </Select.Option>
                      {/* <Select.Option
                        value="lastUpdated_ASC"
                        key="2"
                        style={{ padding: "10px" }}
                      >
                        Last Updated Ascending
                      </Select.Option>
                      <Select.Option
                        value="lastUpdated_DESC"
                        key="3"
                        style={{ padding: "10px" }}
                      >
                        Last Updated Descending
                      </Select.Option> */}
                    </Select>
                  </Col>
                </Row>
              </Button>
            </Col>

            {props.permissions &&
              props.permissions.find((data) => data === "client-add") && (
                <Col>
                  <Button
                    type="primary"
                    style={{ boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.15)" }}
                    className={commonCssStyle.intaddButton}
                    onClick={() => {
                      props.getCheckPermissionHandler(
                        "client-add",
                        (success) => {
                          if (success) {
                            showDrawer("add", null);
                          } else {
                            setOpenPMModal({
                              open: true,
                              permission: "Client Edit",
                            });
                          }
                        }
                      );
                    }}
                  >
                    <Row align="middle" style={{ gap: "11px" }}>
                      <Col>
                        <PlusIcon color={"white"} />
                      </Col>
                      <Col>
                        <p className={`${commonCssStyle.marginBottomRemove} `}>
                          Add new Company
                        </p>
                      </Col>
                    </Row>
                  </Button>
                </Col>
              )}
          </Row>

          <Col style={{ marginTop: "20px" }} xs={24}>
            <Table
              scroll={{ x: "auto" }}
              pagination={{
                size: "small",
                current:
                  props.clientsData && props.clientsData.current_page
                    ? props.clientsData.current_page
                    : 1,
                pageSize: props.clientsData && props.clientsData.per_page,
                total: props.clientsData && props.clientsData.total,
                showSizeChanger: false,
                onChange: (props) => {
                  fetchRecords(props);
                },
              }}
              rowKey="key"
              columns={columns}
              dataSource={props.clientsData.data}
            />
          </Col>
        </Col>
      </Row>
      {/* modal start */}
      <Modal
        closable={false}
        title={
          <Row justify="space-between">
            <Col>
              <p className={commonCssStyle.popUpHeadingText}>Delete Company</p>
            </Col>

            <Col>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  closeDeleteHandler();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)"} />
              </div>
            </Col>
          </Row>
        }
        footer={
          <>
            <Row style={{ gap: "10px" }} justify="end" align="middle">
              <Col>
                <Button
                  className={commonCssStyle.buttonSettingsSecondary}
                  type="default"
                  style={{ height: "48px", fontSize: "16px", width: "181px" }}
                  onClick={() => {
                    closeDeleteHandler();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  className={commonCssStyle.buttonSettingsPrimary}
                  type="primary"
                  // className={styles.btnPrimary}
                  style={{ height: "48px", fontSize: "16px", width: "181px" }}
                  onClick={() => {
                    confirmDeleteHandler();
                  }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </>
        }
        open={openDeleteModal}
        onOk={confirmDeleteHandler}
      >
        <Row>
          <Col>
            <p className={commonCssStyle.tableHeadingTextData}>
              Are you sure you want to delete
            </p>
          </Col>
        </Row>
      </Modal>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    clients: state.client.clients,
    clientsData: state.client.client_filter,
    client: state.client.client,
    // countriesData: state.country.countries,
    settindsData: state.settings.settings,
    permissions: state.authpermissions.permissions,
    ishavepermissions: state.authpermissions.ishavepermissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getClientsHandler: () => {
      dispatch(getClientsAction());
    },
    getClientHandler: (id) => {
      dispatch(getClientAction(id));
    },
    deleteclientDetailsHandler: (id, callback) => {
      dispatch(deleteClientAction(id, callback));
    },
    putclientTemplateHandler: (data, type, callback) => {
      dispatch(putClientAction(data, type, callback));
    },
    postclientTemplateHandler: (data, callback) => {
      dispatch(postClientAction(data, callback));
    },
    getClientFilterHandler: (val, callback) => {
      dispatch(client_FilterAction(val, callback));
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },

    // getCountryHandler: () => {
    //   dispatch(getCountriesAction());
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientCompanyTable);
