import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { getClientsAction } from "../../../../Reusable/Store/Action/ClientAction";
import { getTagsAction } from "../../../../Reusable/Store/Action/Master/MasterTagsAction";
import {
  exportRecuritmentJobAction,
  getRecuritmentJobAction,
  recuritmentJobKPIAction,
} from "../../../../Reusable/Store/Action/ReportsAction";
import {
  CaretBlackDownIcon,
  FilterOutlinedIcon,
  PlusIcon,
} from "../../../IconsComp/Icons";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import BreadcrumbForRandR from "./BreadcrumbForRandR";
import RecuritmentJobFilter from "./RecuritmentJobFilter";

function RecuritmentJob(props) {
  const [form] = useForm();
  const location = useLocation();
  const [mouseHoverEffect, setMouseHoverEffect] = useState("0");
  const [cfilter, setCFilter] = useState({});
  const [rRFilter, setRRFilter] = useState(false);
  const [fromDate, setFromDate] = useState("1970-01-01");
  const [toDate, setToDate] = useState("3000-01-01");
  const dateFormate =
    props.settings && props.settings.dateFormat
      ? props.settings.dateFormat
      : "DD/MM/YYYY";
  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      let employeeReportsFilterHandler = localStorage.getItem(
        "employeeReportsFilter"
      );
      let employeeReportsFilterHandler1 = JSON.parse(
        employeeReportsFilterHandler
      );
      if (employeeReportsFilterHandler1) {
        props.getRecuritmentJobHandler({
          type: "list",
          userId: location.state.id,
          current_page: 1,
          ...employeeReportsFilterHandler1,
        });
      } else {
        props.getRecuritmentJobHandler({
          type: "list",
          userId: location.state.id,
          current_page: 1,
        });
      }
      props.getRecuritmentJobKPIHandler({ userId: location.state.id });

      // props.getTagHandler();
      props.getClientsHandler();
    }
    return () => {
      unsubscribed = true;
    };
  }, []);

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const columns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Title
        </p>
      ),

      dataIndex: "title",
      key: "title",
      render: (data, record) => {
        return (
          <p
            style={{
              color: "var(--primaryColor)",
              fontWeight: 600,
            }} /* ${commonCssStyle.coursorPointer} */
            className={`${commonCssStyle.tableData}  ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Client Name
        </p>
      ),

      dataIndex: "clientName",
      key: "clientName",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Role
        </p>
      ),

      dataIndex: "role",
      key: "role",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Job Code
        </p>
      ),

      dataIndex: "jobCode",
      key: "jobCode",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Department
        </p>
      ),

      dataIndex: "department",
      key: "department",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Billing Value
        </p>
      ),

      dataIndex: "billingValue",
      key: "billingValue",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? "Estimited - " + data : "NA"}
            <br />
            <br />
            {record.revenueGenerated
              ? "Actual - " + record.revenueGenerated
              : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Job Type
        </p>
      ),

      dataIndex: "jobType",
      key: "jobType",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Public Listing
        </p>
      ),

      dataIndex: "makePublic",
      key: "makePublic",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data == "1" ? "YES" : "NO"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Position Required
        </p>
      ),

      dataIndex: "positionRequired",
      key: "positionRequired",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Active
        </p>
      ),
      dataIndex: "activeCount",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Screening
        </p>
      ),
      dataIndex: "screeningCount",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Interviewing
        </p>
      ),
      dataIndex: "interviewedCount",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Offered
        </p>
      ),
      dataIndex: "offeredCount",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Hired
        </p>
      ),
      dataIndex: "hiredCount",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Declined
        </p>
      ),
      dataIndex: "declinedCount",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Last Updated Date
        </p>
      ),

      dataIndex: "lastUpdatedDate",
      key: "lastUpdatedDate",
      render: (data, record) => {
        return (
          <p
            style={{ whiteSpace: "nowrap" }}
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? moment(data).format(dateFormate) : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Start Date
        </p>
      ),
      dataIndex: "startDate",
      render: (data) => (
        <p
          style={{ whiteSpace: "nowrap" }}
          className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
        >
          {data && data !== "NA" ? moment(data).format(dataFormate) : "NA"}
        </p>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          End Date
        </p>
      ),
      dataIndex: "endDate",
      render: (data) => (
        <p
          style={{ whiteSpace: "nowrap" }}
          className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
        >
          {data && data !== "NA" ? moment(data).format(dataFormate) : "NA"}
        </p>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Time Spent
        </p>
      ),
      dataIndex: "timeSpent",
      render: (data) => {
        return (
          <p
            style={{ whiteSpace: "nowrap" }}
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Status
        </p>
      ),

      dataIndex: "status",
      key: "status",
      render: (data, record) => (
        <Row>
          <Col>
            <Tag
              style={{
                backgroundColor: data === "open" ? "#D2EFCD" : "#FFD9D9",
                color: data === "open" ? "#044123" : "#F83A3A",
                padding: "5px 20px",
                lineHeight: "21px",
                fontSize: "14px",
                fontWeight: "500",
                fontStyle: "normal",
                fontFamily: "Helvetica,ProductSans",
              }}
            >
              {data && data.charAt(0).toUpperCase() + data.slice(1)}
            </Tag>
          </Col>
        </Row>
      ),
    },
  ];
  const fetchRecords = (page) => {
    const val = {
      type: "list",
      recruiter: location.state.id,
      current_page: page,
    };
    props.getRecuritmentJobHandler(val);
  };

  const menu2 = (
    <Menu
      style={{ width: "151px", margin: "0px 45px" }}
      selectable
      defaultSelectedKeys={["1"]}
      items={[
        {
          key: "3",
          label: (
            <p
              className={`${commonCssStyle.externalJobTextrole}`}
              style={{ marginTop: "7px" }}
              onClick={() => {
                //props.exportRecuritmentJobHandler
                props.exportRecuritmentJobHandler({
                  ...cfilter,
                  type: "export",
                  dateFrom: fromDate,
                  dateTo: toDate,
                  exportType: "excel",
                  userId: location.state.id,
                  // userId: location.state.userId,
                });
              }}
            >
              Export to Excel
            </p>
          ),
        },
        {
          key: "4",
          label: (
            <p
              style={{ marginTop: "7px" }}
              className={`${commonCssStyle.externalJobTextrole}`}
              onClick={() => {
                props.exportRecuritmentJobHandler({
                  ...cfilter,
                  dateFrom: fromDate,
                  dateTo: toDate,
                  type: "export",
                  exportType: "pdf",
                  userId: location.state.id,
                  // userId: location.state.userId,
                });
              }}
            >
              Export to PDF
            </p>
          ),
        },
      ]}
    />
  );

  function handleProvinceChange(e) {
    let sort = e.split("_");
    props.getRecuritmentJobHandler({
      type: "list",
      userId: location.state.id,
      current_page: 1,
      sortBy: sort[0],
      sortOrder: sort[1],
    });
    setCFilter({
      type: "list",
      userId: location.state.id,
      current_page: 1,
      sortBy: sort[0],
      sortOrder: sort[1],
    });
  }

  return (
    <>
      <Row>
        <Col md={24} className={commonCssStyle.breadcrumbBottomMargin}>
          <BreadcrumbForRandR
            page={1}
            name={location.state && location.state.name && location.state.name}
          />
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <Row
            justify="end"
            style={{ margin: "0px 20px 20px 0px" }}
            gutter={[16, 16]}
          >
            <Col>
              <DatePicker
                onChange={(val) => {
                  props.getRecuritmentJobKPIHandler({
                    userId: location.state.id,
                    // userId: location.state.userId,
                    dateFrom:
                      val !== null
                        ? moment(val).format("YYYY-MM-DD")
                        : fromDate,
                    dateTo: toDate,
                  });
                }}
                format={dateFormate}
                placeholder="From"
                style={{ width: "115px", height: "40px" }}
                className="ReportsFrom"
                suffixIcon={null}
              />
            </Col>
            <Col>
              <DatePicker
                onChange={(val) => {
                  props.getRecuritmentJobKPIHandler({
                    dateFrom: fromDate,
                    userId: location.state.id,
                    // userId: location.state.userId,
                    dateTo:
                      val !== null ? moment(val).format("YYYY-MM-DD") : toDate,
                  });
                }}
                format={dateFormate}
                placeholder="To"
                style={{ width: "115px", height: "40px" }}
                className="ReportsFrom"
                suffixIcon={null}
              />
            </Col>
          </Row>

          <Row
            style={{ marginTop: "20px" }}
            gutter={[0, 16]}
            align="middle"
            justify="space-evenly"
          >
            <Col>
              <Card
                onMouseEnter={() => {
                  setMouseHoverEffect("7");
                }}
                onMouseLeave={() => {
                  setMouseHoverEffect("0");
                }}
                style={{
                  border:
                    mouseHoverEffect === "7"
                      ? "3px solid #2f7bf5"
                      : "3px solid  var(--theme)",
                  margin: "0px",
                }}
                className={commonCssStyle.cards}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={commonCssStyle.recuritmentReportCardTitle}
                    >
                      {props.recuritmentJobKPI &&
                        props.recuritmentJobKPI.shortlistedCount &&
                        props.recuritmentJobKPI.shortlistedCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={commonCssStyle.recuritmentReportCardSubTitle}>
                      Shortlisted
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                onMouseEnter={() => {
                  setMouseHoverEffect("4");
                }}
                onMouseLeave={() => {
                  setMouseHoverEffect("0");
                }}
                style={{
                  border:
                    mouseHoverEffect === "4"
                      ? "3px solid #2f7bf5"
                      : "3px solid  var(--theme)",
                  margin: "0px",
                }}
                className={commonCssStyle.cards}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={commonCssStyle.recuritmentReportCardTitle}
                    >
                      {props.recuritmentJobKPI &&
                        props.recuritmentJobKPI.interviewedCount &&
                        props.recuritmentJobKPI.interviewedCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={commonCssStyle.recuritmentReportCardSubTitle}>
                      Interviewed
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                onMouseEnter={() => {
                  setMouseHoverEffect("5");
                }}
                onMouseLeave={() => {
                  setMouseHoverEffect("0");
                }}
                style={{
                  border:
                    mouseHoverEffect === "5"
                      ? "3px solid #2f7bf5"
                      : "3px solid  var(--theme)",
                  margin: "0px",
                }}
                className={commonCssStyle.cards}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={commonCssStyle.recuritmentReportCardTitle}
                    >
                      {props.recuritmentJobKPI &&
                        props.recuritmentJobKPI.offeredCount &&
                        props.recuritmentJobKPI.offeredCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={commonCssStyle.recuritmentReportCardSubTitle}>
                      Offered
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                onMouseEnter={() => {
                  setMouseHoverEffect("10");
                }}
                onMouseLeave={() => {
                  setMouseHoverEffect("0");
                }}
                style={{
                  border:
                    mouseHoverEffect === "10"
                      ? "3px solid #2f7bf5"
                      : "3px solid  var(--theme)",
                  margin: "0px",
                }}
                className={commonCssStyle.cards}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={commonCssStyle.recuritmentReportCardTitle}
                    >
                      {props.recuritmentJobKPI &&
                        props.recuritmentJobKPI.hiredCount &&
                        props.recuritmentJobKPI.hiredCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={commonCssStyle.recuritmentReportCardSubTitle}>
                      Hired
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                onMouseEnter={() => {
                  setMouseHoverEffect("3");
                }}
                onMouseLeave={() => {
                  setMouseHoverEffect("0");
                }}
                style={{
                  border:
                    mouseHoverEffect === "3"
                      ? "3px solid #2f7bf5"
                      : "3px solid  var(--theme)",
                  margin: "0px",
                }}
                className={commonCssStyle.cards}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={commonCssStyle.recuritmentReportCardTitle}
                    >
                      {props.recuritmentJobKPI &&
                        props.recuritmentJobKPI.declinedCount &&
                        props.recuritmentJobKPI.declinedCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={commonCssStyle.recuritmentReportCardSubTitle}>
                      Declined
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row
            style={{ marginTop: "20px" }}
            gutter={[0, 16]}
            align="middle"
            justify="space-evenly"
          >
            <Col>
              <Card
                onMouseEnter={() => {
                  setMouseHoverEffect("9");
                }}
                onMouseLeave={() => {
                  setMouseHoverEffect("0");
                }}
                className={commonCssStyle.cards}
                style={{
                  border:
                    mouseHoverEffect === "9"
                      ? "3px solid #2f7bf5"
                      : "3px solid  var(--theme)",
                  margin: "0px",
                }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={commonCssStyle.recuritmentReportCardTitle}
                    >
                      {props.recuritmentJobKPI &&
                        props.recuritmentJobKPI.totalJobs &&
                        props.recuritmentJobKPI.totalJobs}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={commonCssStyle.recuritmentReportCardSubTitle}>
                      Total Jobs
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                onMouseEnter={() => {
                  setMouseHoverEffect("6");
                }}
                onMouseLeave={() => {
                  setMouseHoverEffect("0");
                }}
                style={{
                  border:
                    mouseHoverEffect === "6"
                      ? "3px solid #2f7bf5"
                      : "3px solid  var(--theme)",
                  margin: "0px",
                }}
                className={commonCssStyle.cards}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={commonCssStyle.recuritmentReportCardTitle}
                    >
                      {props.recuritmentJobKPI &&
                        props.recuritmentJobKPI.openJobs &&
                        props.recuritmentJobKPI.openJobs}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={commonCssStyle.recuritmentReportCardSubTitle}>
                      Open Jobs
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col>
              <Card
                onMouseEnter={() => {
                  setMouseHoverEffect("2");
                }}
                onMouseLeave={() => {
                  setMouseHoverEffect("0");
                }}
                style={{
                  border:
                    mouseHoverEffect === "2"
                      ? "3px solid #2f7bf5"
                      : "3px solid  var(--theme)",
                  margin: "0px",
                }}
                className={commonCssStyle.cards}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={commonCssStyle.recuritmentReportCardTitle}
                    >
                      {props.recuritmentJobKPI &&
                        props.recuritmentJobKPI.closedJobs &&
                        props.recuritmentJobKPI.closedJobs}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={commonCssStyle.recuritmentReportCardSubTitle}>
                      Closed Jobs
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                onMouseEnter={() => {
                  setMouseHoverEffect("1");
                }}
                onMouseLeave={() => {
                  setMouseHoverEffect("0");
                }}
                className={commonCssStyle.cards}
                style={{
                  border:
                    mouseHoverEffect === "1"
                      ? "3px solid #2f7bf5"
                      : "3px solid  var(--theme)",
                  margin: "0px",
                }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={commonCssStyle.recuritmentReportCardTitle}
                    >
                      {props.recuritmentJobKPI &&
                        props.recuritmentJobKPI.estimated &&
                        props.recuritmentJobKPI.estimated}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={commonCssStyle.recuritmentReportCardSubTitle}>
                      Billing Value
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                onMouseEnter={() => {
                  setMouseHoverEffect("8");
                }}
                onMouseLeave={() => {
                  setMouseHoverEffect("0");
                }}
                style={{
                  border:
                    mouseHoverEffect === "8"
                      ? "3px solid #2f7bf5"
                      : "3px solid  var(--theme)",
                  margin: "0px",
                }}
                className={commonCssStyle.cards}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={commonCssStyle.recuritmentReportCardTitle}
                    >
                      {props.recuritmentJobKPI &&
                        props.recuritmentJobKPI.actual &&
                        props.recuritmentJobKPI.actual}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={commonCssStyle.recuritmentReportCardSubTitle}>
                      Actual
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} md={24}>
          <Row
            align="middle"
            justify="space-between"
            gutter={[0, 16]}
            style={{ marginTop: "20px" }}
          >
            <Col xs={11} md={15}>
              <Row gutter={[16, 16]}>
                <Col>
                  <h1
                    style={{ marginRight: "10px" }}
                    className={`${commonCssStyle.mainHeadingText} ${commonCssStyle.inttitle}`}
                  >
                    Jobs&nbsp;&#40;
                    {props.recuritmentJob && props.recuritmentJob.total
                      ? props.recuritmentJob.total
                      : "0"}
                    &#41;
                  </h1>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      setRRFilter(true);
                    }}
                    className={`${commonCssStyle.filterButton}`}
                  >
                    <Row>
                      <Col>
                        <div style={{ transform: "translateY(2px)" }}>
                          <FilterOutlinedIcon color={"var(--primaryColor)"} />
                        </div>
                      </Col>
                      <Col>
                        <p
                          className={`${commonCssStyle.filterAndSortTextStyle} ${commonCssStyle.marginBottomRemove}`}
                        >
                          Filter
                        </p>
                      </Col>
                    </Row>
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{
                      height: "40px",
                    }}
                    className={` ${commonCssStyle.sortBtn}`}
                  >
                    <Row justify="space-between">
                      <Col>
                        <p style={{ transform: "translateY(2px)" }}>Sort By</p>
                      </Col>
                      <Col>
                        <Select
                          style={{
                            width: "100%",
                            transform: "translateY(3px)",
                          }}
                          bordered={false}
                          placeholder={
                            <p
                              style={{
                                margin: "0px",
                                color: "var(--primaryColor)",
                              }}
                            >
                              Select
                            </p>
                          }
                          onChange={handleProvinceChange}
                          size="small"
                          className={`${commonCssStyle.filterDrop}  selectInput sortBySelectStyle selectTag selectTag1`}
                        >
                          <Select.Option key="1" value="title_ASC">
                            Title Ascending
                          </Select.Option>
                          <Select.Option key="2" value="title_DESC">
                            Title Descending
                          </Select.Option>
                          <Select.Option key="3" value="lastUpdated_ASC">
                            Last Updated Ascending
                          </Select.Option>
                          <Select.Option key="4" value="lastUpdated_DESC">
                            Last Updated Descending
                          </Select.Option>
                          <Select.Option key="5" value="createdDate_ASC">
                            Created Date Ascending
                          </Select.Option>
                          <Select.Option key="6" value="createdDate_DESC">
                            Created Date Descending
                          </Select.Option>
                          <Select.Option key="7" value="department_ASC">
                            Department Ascending
                          </Select.Option>
                          <Select.Option key="8" value="department_DESC">
                            Department Descending
                          </Select.Option>
                          <Select.Option key="9" value="jobType_ASC">
                            Job Type Ascending
                          </Select.Option>
                          <Select.Option key="10" value="jobType_DESC">
                            Job Type Descending
                          </Select.Option>
                          <Select.Option key="11" value="role_ASC">
                            Role Ascending
                          </Select.Option>
                          <Select.Option key="12" value="role_DESC">
                            Role Descending
                          </Select.Option>
                          <Select.Option key="13" value="clientComapny_ASC">
                            Client Comapny Ascending
                          </Select.Option>
                          <Select.Option key="14" value="clientComapny_DESC">
                            Client Comapny Descending
                          </Select.Option>
                        </Select>
                      </Col>
                    </Row>
                  </Button>
                </Col>

                <Col>
                  <Form form={form}>
                    <Row gutter={[16, 0]}>
                      <Col>
                        <Form.Item name="dateFrom" className="employeeJobForm">
                          <DatePicker
                            onChange={(val) => {
                              props.getRecuritmentJobHandler({
                                type: "list",
                                userId: location.state.id,
                                current_page: 1,
                                dateFrom:
                                  val !== null
                                    ? moment(val).format("YYYY-MM-DD")
                                    : fromDate,
                                dateTo: toDate,
                              });

                              // setFromDate(dateF);
                              setCFilter({
                                type: "list",
                                userId: location.state.id,
                                current_page: 1,
                                dateFrom:
                                  val !== null
                                    ? moment(val).format("YYYY-MM-DD")
                                    : fromDate,
                                dateTo: toDate,
                              });
                            }}
                            format={dateFormate}
                            placeholder="From"
                            style={{ width: "115px", height: "40px" }}
                            className="ReportsFrom"
                            suffixIcon={null}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item name="dateTo" className="employeeJobForm">
                          <DatePicker
                            onChange={(val) => {
                              props.getRecuritmentJobHandler({
                                type: "list",
                                userId: location.state.id,
                                current_page: 1,
                                dateTo:
                                  val !== null
                                    ? moment(val).format("YYYY-MM-DD")
                                    : toDate,
                                dateFrom: fromDate,
                              });
                              setCFilter({
                                type: "list",
                                userId: location.state.id,
                                current_page: 1,
                                dateTo:
                                  val !== null
                                    ? moment(val).format("YYYY-MM-DD")
                                    : toDate,
                                dateFrom: fromDate,
                              });
                            }}
                            format={dateFormate}
                            placeholder="To"
                            style={{ width: "115px", height: "40px" }}
                            className="ReportsFrom"
                            suffixIcon={null}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col xs={11} md={7}>
              <Row justify="end">
                <Col>
                  <Dropdown placement="bottom" overlay={menu2}>
                    <Typography.Link>
                      <Space className={commonCssStyle.tableHeadingTextData}>
                        <Button
                          style={{ width: "151px" }}
                          className={commonCssStyle.intaddButton}
                          type="primary"
                        >
                          <Row align="middle" justify="space-around">
                            <Col>
                              <PlusIcon color={"white"} />
                            </Col>
                            <Col>Export</Col>
                            <Col>
                              <CaretBlackDownIcon color={"white"} />
                            </Col>
                          </Row>
                        </Button>
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col md={24}>
              <Table
                pagination={{
                  size: "small",
                  current:
                    props.recuritmentJob && props.recuritmentJob.current_page,
                  pageSize:
                    props.recuritmentJob && props.recuritmentJob.per_page,
                  total: props.recuritmentJob && props.recuritmentJob.total,
                  showSizeChanger: false,
                  onChange: (props) => {
                    fetchRecords(props);
                  },
                }}
                columns={columns}
                // scroll={{
                //   x: "auto",
                // }}
                scroll={{ x: "100vw" }}
                dataSource={props.recuritmentJob.data}
                // className="recuritmentReportTable"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {/* filter */}
      <RecuritmentJobFilter
        // onTagsData={props.tagsData && props.tagsData}
        onClients={props.clients && props.clients}
        onOpenFilter={rRFilter}
        onFromDate={fromDate}
        onToDate={toDate}
        onFandTReset={() => {
          form.resetFields();
        }}
        onSubmit={(val) => {
          setCFilter({ ...val, current_page: 1, type: "list" });
          props.getRecuritmentJobHandler({
            ...val,
            type: "list",
            userId: location.state.id,
            current_page: 1,
          });
        }}
        onCloseFilter={() => {
          setRRFilter(false);
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    recuritmentJobKPI: state.reports.recuritmentJobKPI,
    recuritmentJob: state.reports.recuritmentJob,
    // tagsData: state.tags.tags,
    settings: state.settings.settings,
    clients: state.client.clients,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRecuritmentJobKPIHandler: (data) => {
      dispatch(recuritmentJobKPIAction(data));
    },
    getRecuritmentJobHandler: (data) => {
      dispatch(getRecuritmentJobAction(data));
    },
    getClientsHandler: () => {
      dispatch(getClientsAction());
    },
    exportRecuritmentJobHandler: (data) => {
      dispatch(exportRecuritmentJobAction(data));
    },
    // getTagHandler: () => {
    //   dispatch(getTagsAction());
    // },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RecuritmentJob);
