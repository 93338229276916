import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

// var firebaseConfig = {
//   apiKey: "AIzaSyCltUxqkFZSoG9Q025Ta2KzNUIh7zEwbVU",
//   authDomain: "recruitintell-4764d.firebaseapp.com",
//   databaseURL: "https://project-id.firebaseio.com",
//   projectId: "recruitintell-4764d",
//   storageBucket: "recruitintell-4764d.appspot.com",
//   messagingSenderId: "758663503006",
//   appId: "1:758663503006:web:1730e64e1968a4a7ca2f8f",
// };
const firebaseConfig = {
  apiKey: "AIzaSyCcZfG87q6eNAib3C8VNclzXe3U2MyG13U",
  authDomain: "recruitintell-e0d42.firebaseapp.com",
  projectId: "recruitintell-e0d42",
  storageBucket: "recruitintell-e0d42.appspot.com",
  messagingSenderId: "991172120396",
  appId: "1:991172120396:web:f93e36211ae823812849e9",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

// const firebaseConfig = {
//   apiKey: "AIzaSyB9b7gB0SfNUC55-CN1gjVdKC2-B-xnOZg",
//   authDomain: "cusstomdata.firebaseapp.com",
//   databaseURL: "https://cusstomdata.firebaseio.com",
//   projectId: "cusstomdata",
//   storageBucket: "cusstomdata.appspot.com",
//   messagingSenderId: "89959501692",
//   appId: "1:89959501692:web:c5fd9be2ad9ad6cd69def9",
// };
