// import styles from "../AddCandidatesComp.module.css";
// import style from "../../../Screens/Settings/Application/Application.module.css";
import commonCssStyle from "../../UI/CommonCss.module.css";
import { useEffect, useState } from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  Menu,
  Dropdown,
  Select,
  Input,
  Checkbox,
} from "antd";
import { getRecruitmentsAllCandidatesFilterAction } from "../../../Reusable/Store/Action/RecruitmentAction";
import {
  ThreeDotsIcon,
  Filedownload,
  CalendarCheckIcon,
  FilterOutlinedIcon,
  TrashIcon,
  LinkBreak,
  SearchIcon,
  BrieftCaseIcon,
} from "../../IconsComp/Icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Steps from "../../UI/Steps";
import { Option } from "antd/lib/mentions";
import RecruitmentCandidateFilter from "./RecruitmentCandidateFilter";
import ScheduleInterviewDrawer from "../AddCandidatesComp/CandidatesSummary/ScheduleInterviewDrawer";
import {
  getRecruitmentsFilterAction,
  getRecruitmentAllStatusAction,
  deleteCandidatesFroPoolAction,
  exportRecruitmentDetaisAction,
} from "../../../Reusable/Store/Action/RecruitmentAction";
import { getClientsAction } from "../../../Reusable/Store/Action/ClientAction";
import { getRequirementsAction } from "../../../Reusable/Store/Action/RequirementAction";
import { getEmployeesAction } from "../../../Reusable/Store/Action/EmployeesAction";
import { getTagsAction } from "../../../Reusable/Store/Action/Master/MasterTagsAction";
import { getCandidatesAction } from "../../../Reusable/Store/Action/CandidatesAction";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import PermissionMessage from "../../UI/PermissionMessage";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import BreadcrumbForRecruitment from "./BreadcrumbForRecruitment";
import moment from "moment";

function RecruitmentCandidates(props) {
  const location = useLocation();
  const fetchRecords = (page) => {
    let recuritmentCandidateFilterLocal = JSON.parse(
      localStorage.getItem("recuritmentCandidateFilter")
    );

    if (recuritmentCandidateFilterLocal) {
      const val = {
        ...props.candidatesFilterData,
        ...recuritmentCandidateFilterLocal,
        current_page: page,
        id: props.idData,
      };
      localStorage.setItem(
        "candidateListForPool",
        JSON.stringify({
          ...recuritmentCandidateFilterLocal,
          current_page: page,
        })
      );
      props.getCandidatesFilterAllDataHandler(val);
    } else {
      const val = {
        ...props.candidatesFilterData,
        current_page: page,
        id: props.idData,
      };
      localStorage.setItem(
        "candidateListForPool",
        JSON.stringify({
          current_page: page,
        })
      );

      props.getCandidatesFilterAllDataHandler(val);
    }

    // console.log(val);
  };

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      let recuritmentCandidateFilter = localStorage.getItem(
        "recuritmentCandidateFilter"
      );

      if (recuritmentCandidateFilter) {
        let recuritmentCandidateFilter1 = JSON.parse(
          recuritmentCandidateFilter
        );
        props.getCandidatesFilterAllDataHandler({
          id: props.idData,
          current_page: 1,
          ...recuritmentCandidateFilter1,
        });
      } else {
        props.getCandidatesFilterAllDataHandler({
          id: props.idData,
          current_page: 1,
        });
      }

      props.getClientsHandler();
      props.getTagHandler();
      props.getRequirementAction();
      props.getEmployeesAction();
      props.getCandidateDetailsHandler({});
      props.getTagHandler();
      props.getRecruitmentAllStatusHandler();
    }
    return () => {
      unsubscribed = true;
    };
  }, []);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [deletId, setDeleteId] = useState("");
  const [openDeleteModal, SetOpenDeleteModal] = useState(false);
  const [openJobModal, SetOpenJobModal] = useState(false);
  const [hoverKey, setHoverKey] = useState("0");
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  const [opeRecruitmentCandidateFilter, setOpenRecruitmentCandidateFilter] =
    useState(false);
  const [interviewName, setInterviewName] = useState("");
  const [editIconHover, setEditIconHver] = useState(false);
  const [scheduleDrawer, setScheduleDrawer] = useState(false);
  const [candidateId, setCandidateId] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [cfilter, setCFilter] = useState({});

  // function confirmDeleteHandler() {
  //   SetOpenDeleteModal(false);

  //   setDeleteId("");
  // }
  function closeDrawerHandler() {
    setScheduleDrawer(false);
  }

  const recruitmentFilterHandler = (val) => {
    if (val.applyfilter === "clear selected") {
      setSelectedRowKeys([]);
    }

    const data = {
      id: props.idData,
      ...val,
      current_page: 1,
    };
    setCFilter(val);
    props.getCandidatesFilterAllDataHandler(data);
  };

  const data = [
    {
      key: "1",
      jobcode: "DEV23",
      jobtitle: "UX Designer1",
      companyname: "Devusoft",
      positionclosed: "3/10",
      shortlisted: "6",
      interviewed: "6",
      number: "one",
      offered: "6",
    },
    {
      key: "3",
      jobcode: "ENG12",
      jobtitle: "Graphic Designer",
      companyname: "Engima",
      positionclosed: "3/10",
      shortlisted: "6",
      interviewed: "6",
      number: "two",
      offered: "6",
    },
    {
      key: "4",
      jobcode: "ENG12",
      jobtitle: "Graphic Designer",
      companyname: "Engima",
      positionclosed: "3/10",
      shortlisted: "6",
      interviewed: "6",
      number: "three",
      offered: "6",
    },
    {
      key: "5",
      jobcode: "ENG12",
      jobtitle: "Graphic Designer",
      companyname: "Engima",
      positionclosed: "3/10",
      shortlisted: "6",
      interviewed: "6",
      number: "one",
      offered: "6",
    },
  ];

  function confirmDeleteHandler() {
    props.deleteCandidateFromPool(
      { filterId: props.idData, id: deletId },
      (success) => {
        if (success) {
          SetOpenDeleteModal(false);
        }
      }
    );
    setDeleteId("");
  }

  const handleProvinceChange = (e) => {
    let recuritmentCandidateFilterLocal = JSON.parse(
      localStorage.getItem("recuritmentCandidateFilter")
    );
    localStorage.setItem("recuritmentCandidateSort", e);
    if (recuritmentCandidateFilterLocal) {
      localStorage.setItem(
        "recuritmentCandidateFilter",
        JSON.stringify({
          ...recuritmentCandidateFilterLocal,
          id: props.idData,
          sortBy: "name",
          current_page: 1,
          sortOrder: "ASC",
        })
      );
      if (e === "Name_ASC") {
        props.getCandidatesFilterAllDataHandler({
          // ...cfilter,
          ...recuritmentCandidateFilterLocal,
          id: props.idData,
          sortBy: "name",
          current_page: 1,
          sortOrder: "ASC",
        });
      } else if (e === "Name_DESC") {
        localStorage.setItem(
          "recuritmentCandidateFilter",
          JSON.stringify({
            ...recuritmentCandidateFilterLocal,
            id: props.idData,
            sortBy: "name",
            current_page: 1,
            sortOrder: "DESC",
          })
        );
        props.getCandidatesFilterAllDataHandler({
          // ...cfilter,
          ...recuritmentCandidateFilterLocal,
          id: props.idData,
          sortBy: "name",
          current_page: 1,
          sortOrder: "DESC",
        });
      } else if (e === "Applied_ASC") {
        localStorage.setItem(
          "recuritmentCandidateFilter",
          JSON.stringify({
            ...recuritmentCandidateFilterLocal,
            id: props.idData,
            sortBy: "appliedDate",
            current_page: 1,
            sortOrder: "ASC",
          })
        );
        props.getCandidatesFilterAllDataHandler({
          // ...cfilter,
          ...recuritmentCandidateFilterLocal,
          id: props.idData,
          sortBy: "appliedDate",
          current_page: 1,
          sortOrder: "ASC",
        });
      } else if (e === "Applied_DESC") {
        localStorage.setItem(
          "recuritmentCandidateFilter",
          JSON.stringify({
            ...recuritmentCandidateFilterLocal,
            id: props.idData,
            sortBy: "appliedDate",
            current_page: 1,
            sortOrder: "DESC",
          })
        );
        props.getCandidatesFilterAllDataHandler({
          // ...cfilter,
          ...recuritmentCandidateFilterLocal,
          id: props.idData,
          sortBy: "appliedDate",
          current_page: 1,
          sortOrder: "DESC",
        });
      } else if (e === "Last_ASC") {
        localStorage.setItem(
          "recuritmentCandidateFilter",
          JSON.stringify({
            ...recuritmentCandidateFilterLocal,
            id: props.idData,
            sortBy: "lastUpdated",
            current_page: 1,
            sortOrder: "ASC",
          })
        );
        props.getCandidatesFilterAllDataHandler({
          // ...cfilter,
          ...recuritmentCandidateFilterLocal,
          id: props.idData,
          sortBy: "lastUpdated",
          current_page: 1,
          sortOrder: "ASC",
        });
      } else if (e === "Last_DESC") {
        localStorage.setItem(
          "recuritmentCandidateFilter",
          JSON.stringify({
            ...recuritmentCandidateFilterLocal,
            id: props.idData,
            sortBy: "lastUpdated",
            current_page: 1,
            sortOrder: "DESC",
          })
        );
        props.getCandidatesFilterAllDataHandler({
          // ...cfilter,
          ...recuritmentCandidateFilterLocal,
          id: props.idData,
          sortBy: "lastUpdated",
          current_page: 1,
          sortOrder: "DESC",
        });
      }
    } else {
      if (e === "Name_ASC") {
        localStorage.setItem(
          "recuritmentCandidateFilter",
          JSON.stringify({
            id: props.idData,
            sortBy: "name",
            current_page: 1,
            sortOrder: "ASC",
          })
        );
        props.getCandidatesFilterAllDataHandler({
          // ...cfilter,
          id: props.idData,
          sortBy: "name",
          current_page: 1,
          sortOrder: "ASC",
        });
      } else if (e === "Name_DESC") {
        localStorage.setItem(
          "recuritmentCandidateFilter",
          JSON.stringify({
            id: props.idData,
            sortBy: "name",
            current_page: 1,
            sortOrder: "DESC",
          })
        );
        props.getCandidatesFilterAllDataHandler({
          // ...cfilter,
          id: props.idData,
          sortBy: "name",
          current_page: 1,
          sortOrder: "DESC",
        });
      } else if (e === "Applied_ASC") {
        localStorage.setItem(
          "recuritmentCandidateFilter",
          JSON.stringify({
            id: props.idData,
            sortBy: "appliedDate",
            current_page: 1,
            sortOrder: "ASC",
          })
        );
        props.getCandidatesFilterAllDataHandler({
          // ...cfilter,
          id: props.idData,
          sortBy: "appliedDate",
          current_page: 1,
          sortOrder: "ASC",
        });
      } else if (e === "Applied_DESC") {
        localStorage.setItem(
          "recuritmentCandidateFilter",
          JSON.stringify({
            id: props.idData,
            sortBy: "appliedDate",
            current_page: 1,
            sortOrder: "DESC",
          })
        );
        props.getCandidatesFilterAllDataHandler({
          // ...cfilter,
          id: props.idData,
          sortBy: "appliedDate",
          current_page: 1,
          sortOrder: "DESC",
        });
      } else if (e === "Last_ASC") {
        localStorage.setItem(
          "recuritmentCandidateFilter",
          JSON.stringify({
            id: props.idData,
            sortBy: "lastUpdated",
            current_page: 1,
            sortOrder: "ASC",
          })
        );
        props.getCandidatesFilterAllDataHandler({
          // ...cfilter,
          id: props.idData,
          sortBy: "lastUpdated",
          current_page: 1,
          sortOrder: "ASC",
        });
      } else if (e === "Last_DESC") {
        localStorage.setItem(
          "recuritmentCandidateFilter",
          JSON.stringify({
            id: props.idData,
            sortBy: "lastUpdated",
            current_page: 1,
            sortOrder: "DESC",
          })
        );
        props.getCandidatesFilterAllDataHandler({
          // ...cfilter,
          id: props.idData,
          sortBy: "lastUpdated",
          current_page: 1,
          sortOrder: "DESC",
        });
      }
    }
  };

  function closeDeleteHandler() {
    SetOpenDeleteModal(false);
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const closeHandler = () => {
    setOpen(false);
  };

  function showFilterHandler() {
    setOpenFilter(true);
  }

  function OpenRecruitmentCanidateFilter() {
    setOpenRecruitmentCandidateFilter(true);
  }

  function CloseFilterHandler() {
    setOpenRecruitmentCandidateFilter(false);
  }

  const closeFilterHandler = () => {
    setOpenFilter(false);
  };
  const editJob = () => {
    SetOpenJobModal(true);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys, ...newSelectedRowKeys]);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      dataIndex: "id",
      render: (text, render) => (
        <>
          <Checkbox
            checked={selectedRowKeys.find((x) => x === text)}
            onChange={(val) => {
              if (val.target.checked) {
                setSelectedRowKeys([...selectedRowKeys, text]);
              } else {
                let index = selectedRowKeys.indexOf(text);
                if (index != -1) {
                  setSelectedRowKeys(
                    selectedRowKeys.filter((data) => data !== text)
                  );
                }
              }
            }}
          />
        </>
      ),
    },
    {
      title: (
        <p style={{ textAlign: "center" }} className={styles.LabelName}>
          Name
        </p>
      ),
      dataIndex: "candidateName",
      render: (text, render) =>
        props.permissions &&
        props.permissions.find(
          (data) => data === "recruitment-candidate-detail"
        ) ? (
          // <p
          //   state={{
          //     id: render.candidateId,
          //     from: "recruitmentList",
          //     backIconid: render.id,
          //     jobDetailsPageId: location.state,
          //   }}
          //   to={"/candidatesDetailsPage/summary"}
          // >
          <p
            onClick={() => {
              props.getCheckPermissionHandler(
                "recruitment-candidate-detail",
                (success) => {
                  if (success) {
                    navigate("/candidatesDetailsPage/summary", {
                      state: {
                        id: render.candidateId,
                        from: "recruitmentList",
                        backIconid: render.id,
                        jobDetailsPageId: location.state,
                        recuritmentJobDetailsName:
                          location.state.recuritmentJobDetailsName,
                      },
                    });
                  } else {
                    setOpenPMModal({
                      open: true,
                      permission: "Recruitment Candidate Details",
                    });
                  }
                }
              );
            }}
            style={{
              color: "var(--primaryColor)",
              fontFamily: "Helvetica,ProductSans",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "17px",
              margin: "0px",
              cursor: "pointer",
            }}
          >
            {text ? text : "NA"}
          </p>
        ) : (
          // </p>
          <p
            className={commonCssStyle.marginBottomRemove}
            style={{ textAlign: "center", color: "#2F7BF5" }}
          >
            {text ? text : "NA"}
          </p>
        ),
    },
    {
      title: (
        <p style={{ textAlign: "center" }} className={styles.LabelName}>
          Reporting Manager
        </p>
      ),
      dataIndex: "teamMember",
      render: (text, data) => {
        return (
          <p
            style={{ textAlign: "center" }}
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {text ? text : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ textAlign: "center" }} className={styles.LabelName}>
          Resume Link
        </p>
      ),
      dataIndex: "resume",
      render: (text, render) => (
        <>
          <Row style={{ justifyContent: "center" }}>
            {text && (
              <span
                className={commonCssStyle.coursorPointer}
                onClick={() => {
                  window.open(render.resume, "_blank");
                }}
              >
                <Filedownload color={"var(--primaryColor)"} />
              </span>
            )}
          </Row>
        </>
      ),
    },
    {
      title: (
        <p style={{ textAlign: "center" }} className={styles.LabelName}>
          Email and Number
        </p>
      ),
      dataIndex: "candidatePhone",
      render: (text, data) => {
        return (
          <>
            <p
              style={{ textAlign: "center" }}
              className={`${commonCssStyle.tableData}`}
            >
              {data.email}
            </p>
            <p
              style={{ textAlign: "center" }}
              className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
            >
              {data.candidatePhone}
            </p>
          </>
        );
      },
    },
    {
      title: (
        <p style={{ textAlign: "center" }} className={styles.LabelName}>
          Applied Date
        </p>
      ),
      dataIndex: "appliedDate",
      render: (text) => {
        return (
          <p
            style={{ textAlign: "center" }}
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {text ? moment(text).format(dataFormate) : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ textAlign: "center" }} className={styles.LabelName}>
          Last Updated
        </p>
      ),
      dataIndex: "lastUpdatedDate",
      render: (text) => {
        return (
          <p
            style={{ textAlign: "center" }}
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {text ? moment(text).format(dataFormate) : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ textAlign: "center" }} className={styles.LabelName}>
          Active Applied Job Count
        </p>
      ),
      dataIndex: "activeJobCount",
      render: (text) => {
        return (
          <p
            style={{ textAlign: "center" }}
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {text && text}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ textAlign: "center" }} className={styles.LabelName}>
          Offer Count
        </p>
      ),
      dataIndex: "offerJobCount",
      render: (text) => (
        <p
          style={{ textAlign: "center" }}
          className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
        >
          {text && text}
        </p>
      ),
    },
    {
      title: (
        <p style={{ textAlign: "center" }} className={styles.LabelName}>
          Offer Declined
        </p>
      ),
      dataIndex: "offerDeclinedJobCount",
      render: (text) => (
        <p
          style={{ textAlign: "center" }}
          className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
        >
          {text && text}
        </p>
      ),
    },

    {
      title: <p className={styles.LabelName}>Current Stage</p>,
      dataIndex: "currentStageNumber",
      render: (text, render) => {
        return (
          <>
            {/* <Steps number={text} /> */}
            <Row>
              <Col>
                <Row>
                  <Col>
                    <p className={`${commonCssStyle.tableData}`}>
                      {render.currentStageType}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className={`${commonCssStyle.tableData}`}>
                      {render.currentStage}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "",

      render: (text, render) => {
        return (
          <>
            <Dropdown
              overlay={
                <Menu
                  style={{ /*  paddingBottom: "10px", */ width: "220px" }}
                  items={[
                    props.permissions &&
                      props.permissions.find(
                        (data) => data === "interview-add"
                      ) && {
                        key: "1",
                        label: (
                          <>
                            <Row
                              align="middle"
                              onClick={() => {
                                setCandidateId({
                                  id: render.candidateId,
                                  name: render.name,
                                  jobId: render.jobId,
                                });
                                setInterviewName("recruitment");
                                setScheduleDrawer(true);
                              }}
                              onMouseEnter={() => {
                                setHoverKey("1");
                              }}
                              onMouseLeave={() => {
                                setHoverKey("0");
                              }}
                            >
                              <Col
                                style={{
                                  padding: "5px 5px 0px 0px",
                                  transform: "translateY(3px)",
                                }}
                              >
                                <CalendarCheckIcon
                                  color={
                                    hoverKey === "1"
                                      ? "var(--primaryColor) "
                                      : "var(--body-text-color)"
                                  }
                                />
                              </Col>
                              <Col style={{ padding: "5px 5px 0px 5px" }}>
                                <p
                                  style={{
                                    margin: "0px",
                                    color:
                                      hoverKey === "1"
                                        ? "var(--primaryColor) "
                                        : "var(--body-text-color)",
                                  }}
                                >
                                  Schedule Interview
                                </p>
                              </Col>
                            </Row>
                          </>
                        ),
                      },
                    props.permissions &&
                      props.permissions.find(
                        (data) => data === "recruitment-delete-candidate"
                      ) && {
                        key: "2",
                        label: (
                          <>
                            <Row
                              align="middle"
                              onMouseEnter={() => {
                                setHoverKey("2");
                              }}
                              onMouseLeave={() => {
                                setHoverKey("0");
                              }}
                              onClick={() => {
                                SetOpenDeleteModal(true);
                                setDeleteId(render.id);
                              }}
                            >
                              <Col
                                style={{
                                  padding: "5px 5px 0px 0px",
                                  transform: "translateY(3px)",
                                }}
                              >
                                {/* <TrashIcon
                                color={
                                  hoverKey === "2"
                                    ? "#F83A3A"
                                    : "var(--body-text-color)"
                                }
                              /> */}
                                <LinkBreak
                                  color={
                                    hoverKey === "2"
                                      ? "#F83A3A"
                                      : "var(--body-text-color)"
                                  }
                                />
                              </Col>
                              <Col style={{ padding: "5px 5px 0px 5px" }}>
                                <p
                                  style={{
                                    margin: "0px",
                                    color:
                                      hoverKey === "2"
                                        ? "#F83A3A"
                                        : "var(--body-text-color)",
                                  }}
                                >
                                  Unlink
                                </p>
                              </Col>
                            </Row>
                          </>
                        ),
                      },
                    // props.permissions &&
                    //   props.permissions.find(
                    //     (data) => data === "job-detail"
                    //   ) && {
                    //     key: "3",
                    //     label: (
                    //       <Row
                    //         style={{ gap: "10px" }}
                    //         align="middle"
                    //         onMouseEnter={() => {
                    //           setHoverKey("3");
                    //         }}
                    //         onMouseLeave={() => {
                    //           setHoverKey("0");
                    //         }}
                    //         onClick={() => {
                    //           props.getCheckPermissionHandler(
                    //             "job-detail",
                    //             (success) => {
                    //               if (success) {
                    //                 navigate("/JobDetailPage", {
                    //                   state: {
                    //                     id: render.jobId,
                    //                     activeTabKey: "1",
                    //                   },
                    //                 });
                    //               } else {
                    //                 setOpenPMModal({
                    //                   open: true,
                    //                   permission: "Job Details",
                    //                 });
                    //               }
                    //             }
                    //           );
                    //         }}
                    //       >
                    //         <Col>
                    //           <BrieftCaseIcon
                    //             color={
                    //               hoverKey === "3"
                    //                 ? "var(--primaryColor) "
                    //                 : "var(--body-text-color)"
                    //             }
                    //           />
                    //         </Col>
                    //         <Col>
                    //           <p
                    //             style={{
                    //               margin: "0px",
                    //               color:
                    //                 hoverKey === "3"
                    //                   ? "var(--primaryColor) "
                    //                   : "var(--body-text-color)",
                    //             }}
                    //           >
                    //             Job Detail
                    //           </p>
                    //         </Col>
                    //       </Row>
                    //     ),
                    //   },
                  ]}
                />
              }
            >
              <span
                className={commonCssStyle.coursorPointer}
                onClick={(e) => e.preventDefault()}
              >
                <ThreeDotsIcon color={"var(--icons-color)"} />
              </span>
            </Dropdown>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col md={24}>
          {/* {open&&  <RequirementDrawer onOpen={open} onClose={closeHandler} />} */}

          <Row justify="end" align="middle">
            <Col style={{ marginBottom: "20px" }}>
              <Button
                style={{ marginTop: "1%" }}
                onClick={OpenRecruitmentCanidateFilter}
                className={`${commonCssStyle.filterButton}`}
              >
                <Row style={{ gap: "11px" }}>
                  <Col>
                    <span style={{ transform: "translateY(2px)" }}>
                      <FilterOutlinedIcon color={"var(--primaryColor)"} />
                    </span>
                  </Col>
                  <Col>
                    <p
                      className={`${commonCssStyle.filterAndSortTextStyle} ${commonCssStyle.marginBottomRemove}`}
                    >
                      Filter
                    </p>
                  </Col>
                </Row>
              </Button>

              <Button
                style={{
                  height: "40px",
                }}
                className={` ${commonCssStyle.sortBtn}`}
              >
                <Row justify="space-between">
                  <Col>
                    <p style={{ transform: "translateY(2px)" }}>Sort By</p>
                  </Col>
                  <Col>
                    <Select
                      style={{ width: "100%", transform: "translateY(3px)" }}
                      onChange={handleProvinceChange}
                      bordered={false}
                      placeholder={
                        <p
                          style={{
                            margin: "0px",
                            color: "var(--primaryColor)",
                          }}
                        >
                          Select
                        </p>
                      }
                      value={
                        localStorage.getItem("recuritmentCandidateSort")
                          ? localStorage.getItem("recuritmentCandidateSort")
                          : "Select"
                      }
                      size="small"
                      className={`${commonCssStyle.filterDrop}  selectInput sortBySelectStyle selectTag selectTag1`}
                    >
                      <Select.Option key="1" value="Name_ASC">
                        Name Ascending
                      </Select.Option>
                      <Select.Option key="2" value="Name_DESC">
                        Name Descending
                      </Select.Option>
                      <Select.Option key="3" value="Applied_ASC">
                        Applied Date Ascending
                      </Select.Option>
                      <Select.Option key="4" value="Applied_DESC">
                        Applied Date Descending
                      </Select.Option>
                      <Select.Option key="5" value="Last_ASC">
                        Last Updated Ascending
                      </Select.Option>
                      <Select.Option key="6" value="Last_DESC">
                        Last Updated Descending
                      </Select.Option>
                    </Select>
                  </Col>
                </Row>
              </Button>

              <Button
                type="primary"
                className={commonCssStyle.dataImportBtnCandidaterecruit}
                onClick={() => {
                  props.exportExternalCandidateDetails({
                    ...cfilter,
                    selectedId: selectedRowKeys,
                    jobId: location.state.id,
                  });
                }}
              >
                Export to Excel 
              </Button>
            </Col>

            <Col xs={24}>
              <Table
                scroll={{ x: "auto" }}
                pagination={{
                  size: "small",
                  current:
                    props.import_candidates_Filter_Data &&
                    props.import_candidates_Filter_Data.current_page
                      ? props.import_candidates_Filter_Data.current_page
                      : 1,
                  pageSize:
                    props.import_candidates_Filter_Data &&
                    props.import_candidates_Filter_Data.per_page,
                  total:
                    props.import_candidates_Filter_Data &&
                    props.import_candidates_Filter_Data.total,
                  showSizeChanger: false,
                  onChange: (props) => {
                    fetchRecords(props);
                  },
                }}
                rowKey="key"
                columns={columns}
                dataSource={
                  props.import_candidates_Filter_Data &&
                  props.import_candidates_Filter_Data.data &&
                  props.import_candidates_Filter_Data.data
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        title={
          <p className={commonCssStyle.popUpHeadingText}>Unlink Candidate</p>
        }
        footer={
          <>
            <Row style={{ gap: "10px" }} justify="end" align="middle">
              <Col>
                <Button
                  type="default"
                  style={{ height: "48px", fontSize: "16px", width: "151px" }}
                  onClick={() => {
                    closeDeleteHandler();
                  }}
                  className={commonCssStyle.buttonSettingsSecondary}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  style={{ height: "48px", fontSize: "16px", width: "151px" }}
                  type="primary"
                  className={commonCssStyle.buttonSettingsPrimary}
                  onClick={() => {
                    confirmDeleteHandler();
                  }}
                >
                  Unlink
                </Button>
              </Col>
            </Row>
          </>
        }
        open={openDeleteModal}
        onCancel={closeDeleteHandler}
        onOk={confirmDeleteHandler}
      >
        <Row>
          <Col>
            <p className={commonCssStyle.tableHeadingTextData}>
              Are you sure you want to Unlink Candidate
            </p>
          </Col>
        </Row>
      </Modal>
      <RecruitmentCandidateFilter
        openFilter={opeRecruitmentCandidateFilter}
        onCloseFilter={CloseFilterHandler}
        employees={props.employees}
        clients={props.clients}
        requirements={props.requirements}
        tagsData={props.tagsData}
        recruitmentFilterHandler={recruitmentFilterHandler}
        candidates={props.candidates}
        TagsData={props.TagsData}
        get_all_status={props.get_all_status}
      />

      <ScheduleInterviewDrawer
        onCandidateName={candidateId} //candidate id
        onInterviewName={interviewName} //specifing from schedule drawer from which page the data is comming like example recruitment, it's noting but modal name
        onOpenSchedileInterview={scheduleDrawer} // used to open the drawer
        onCloseSchedileInterview={closeDrawerHandler} // used to close the drawer
      />
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    // candidates: state.candidates.candidates,
    // candidatesFilterData: state.candidates.candidatesFilter,
    import_candidates_Filter_Data:
      state.recruitment.import_candidates_Filter_Data,
    clients: state.client.clients,
    tagsData: state.tags.tags,
    requirements: state.requirements.requirements,
    employees: state.employee.employees,
    candidates: state.candidates.candidates,
    TagsData: state.tags.tags,
    get_all_status: state.recruitment.get_all_status,
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCandidatesFilterAllDataHandler: (id) => {
      dispatch(getRecruitmentsAllCandidatesFilterAction(id));
    },
    getClientsHandler: () => {
      dispatch(getClientsAction());
    },
    getTagHandler: () => {
      dispatch(getTagsAction());
    },
    getRequirementAction: () => {
      dispatch(getRequirementsAction());
    },
    getEmployeesAction: () => {
      dispatch(getEmployeesAction());
    },
    getCandidateDetailsHandler: () => {
      dispatch(getCandidatesAction());
    },
    getTagHandler: () => {
      dispatch(getTagsAction());
    },
    getRecruitmentAllStatusHandler: () => {
      dispatch(getRecruitmentAllStatusAction());
    },
    deleteCandidateFromPool: (id, callback) => {
      dispatch(deleteCandidatesFroPoolAction(id, callback));
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
    exportExternalCandidateDetails: (val, callback) => {
      dispatch(exportRecruitmentDetaisAction(val));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecruitmentCandidates);
