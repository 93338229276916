import { Avatar, Card, Col, Modal, Row, Button } from "antd";
import styles from "./Employee.module.css";
import style from "../AddCandidatesComp/AddCandidatesComp.module.css";
import Bank from "../../../Assets/Image/Bank.png";
import Vector from "../../../Assets/Image/Vector.png";

import {
  BankIcon,
  BrieftCaseIcon,
  CloseIcon,
  Credentials,
  DownLoadIcon,
  EditBorderIcon,
  EditIcon,
  EnvelopeIcon,
  FileAddIcon,
  FilePlus,
  MapPinIcon,
  PhoneIcon,
  TrashIcon,
  UserIcon,
  WhatsappIcon,
} from "../../IconsComp/Icons";
import commonCssStyle from "../../UI/CommonCss.module.css";
import { useEffect, useState } from "react";
import EmployeesPersonalDetails from "./EmployeesPersonalDetails";
import EmployeeDetailsDrawer from "./EmployeeDetailsDrawer";
import EmployeeBankModal from "./EmployeeBankModal";
import EmployeeCredentialsModal from "./EmployeeCredentialsModal";
import pdf from "../../../Assets/Image/PdfImage.png";
import word from "../../../Assets/Image/word.png";
import { PlusOutlined, LoginOutlined } from "@ant-design/icons";
import UploadEmployeeDocumentsModal from "./UploadEmployeeDocumentsModal";
import moment from "moment";
import { getCountriesAction } from "../../../Reusable/Store/Action/Master/MasterCountryAction";
import { connect } from "react-redux";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import PermissionMessage from "../../UI/PermissionMessage";

const EmployeeDetails = (props) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [permanentStates, setPermanentStates] = useState([]);
  const [permanentCity, setPermanentCity] = useState([]);
  useEffect(() => {
    props.getCountryHandler({ type: "country" }, (data, type) => {
      setCountries(data);
    });
  }, []);
  const [hoverRedClr, setHoverRedClr] = useState("var(--primaryColor)");
  const [experienceModalId, setExperienceModalId] = useState("");
  const [deleteModalName, setDeleteModalName] = useState("");
  const [isModalDocumentOpen, setIsModalDocumentOpen] = useState(false);
  const [deleteUploadId, setDeleteUploadId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [personalDrawOpen, setPersonalDrawOpen] = useState(false);
  const [employeeDrawOpen, setEmployeeDrawOpen] = useState(false);
  const [bankDetailsOpen, setBankDetailsOpen] = useState(false);
  const [cradentialsOpen, setCradentialsOpen] = useState(false);
  const [deleteTag, setDeleteTag] = useState("");
  const dateFormat = props.settindsData
    ? props.settindsData.dateFormat
    : "DD/MM/YYYY";
  const [employeeDetails, setEmployeeDetails] = useState(false);
  const personalEditData = (data) => {};
  const personalDetailsCloseHandler = () => {
    setPersonalDrawOpen(false);
  };
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });

  const handleDelete = () => {
    props.employeeDocumentDeleteHandler(deleteUploadId);
    setDeleteTag("");
    setDeleteId("");
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setDeleteTag("");
    setIsModalOpen(false);
  };

  const employeeEditData = (data) => {};
  const employeeDetailsCloseHandler = () => {
    setEmployeeDrawOpen(false);
  };

  const bankEditData = (data) => {};
  const bankDetailsCloseHandler = () => {
    setBankDetailsOpen(false);
  };

  const cradentialsCloseHandler = () => {
    setCradentialsOpen(false);
  };

  const showDocumentModal = () => {
    setIsModalDocumentOpen(true);
  };
  const documentHandleCancel = () => {
    setIsModalDocumentOpen(false);
  };

  const uploadModalCloseHandler = () => {
    setEmployeeDetails(false);
  };

  const uploadModalOkHandler = () => {
    setEmployeeDetails(false);
  };

  // const documentHandleOk = (val) => {
  //   props.postDocumentHandler(val, (success) => {
  //     if (success) {
  //       setIsModalDocumentOpen(false);
  //       props.getCandidateHandler(location.state);
  //     }
  //   });
  // };

  return (
    <>
      <Row>
        <Col md={24}>
          <Card
            style={{
              padding: "20px",
              marginBottom: "30px",
            }}
          >
            <Row>
              <Col sm={24}>
                <Row align="middle" justify="space-between">
                  <Col md={23}>
                    <h1 className={commonCssStyle.summaryInfoMainText}>
                      <Avatar
                        size={50}
                        style={{ paddingTop: "5px", marginRight: "12px" }}
                      >
                        <UserIcon color={"var(--primaryColor)"} />
                      </Avatar>
                      Personal Details
                    </h1>
                  </Col>
                  {props.employee &&
                    props.employee.isPrimary === 0 &&
                    props.authPermissions &&
                    props.authPermissions.find(
                      (data) => data === "employees-edit"
                    ) && (
                      <Col
                        onClick={() => {
                          props.getCheckPermissionHandler(
                            "employees-edit",
                            (success) => {
                              if (success) {
                                setPersonalDrawOpen(true);
                              } else {
                                setOpenPMModal({
                                  open: true,
                                  permission: "Employees Edit",
                                });
                              }
                            }
                          );
                        }}
                        className={commonCssStyle.coursorPointer}
                        md={1}
                      >
                        <EditBorderIcon color={"var(--primaryColor)"} />
                      </Col>
                    )}
                </Row>
              </Col>
              <Col style={{ marginTop: "19px" }} sm={24}>
                <Row
                  gutter={[0, 16]}
                  justify="space-between"
                  align="middle"
                  style={{ marginTop: "15px" }}
                >
                  <Col /* md={8} xs={24} */>
                    <Row align="top" style={{ gap: "8px" }}>
                      <Col>
                        <div style={{ transform: "translateY(-2px)" }}>
                          <EnvelopeIcon color={"var(--body-text-color)"} />
                        </div>
                      </Col>
                      <Col>
                        <p
                          className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                          style={{ marginBottom: "7px" }}
                        >
                          Email
                        </p>
                        <p
                          className={`${commonCssStyle.infoTextColorBold} ${commonCssStyle.marginBottomRemove}  `}
                          style={{ marginLeft: "2px" }}
                        >
                          {props.employee && props.employee.email}{" "}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col /* md={8} xs={24} */>
                    <Row style={{ gap: "8px" }} align="top">
                      <Col>
                        <div style={{ transform: "translateY(-2px)" }}>
                          <PhoneIcon color={"var(--body-text-color)"} />
                        </div>
                      </Col>
                      <Col>
                        <p
                          className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                          style={{ marginBottom: "7px" }}
                        >
                          Contact Number
                        </p>
                        <p
                          className={`${commonCssStyle.infoTextColorBold}  ${commonCssStyle.marginBottomRemove}  `}
                          style={{ marginLeft: "2px" }}
                        >
                          {props.employee && props.employee.phone}{" "}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col /* md={8} xs={24} */>
                    <Row align="top" style={{ gap: "8px" }}>
                      <Col>
                        <div style={{ transform: "translateY(-2px)" }}>
                          <WhatsappIcon color={"var(--body-text-color)"} />
                        </div>
                      </Col>
                      <Col>
                        <p
                          className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                          style={{ marginBottom: "7px" }}
                        >
                          Whatsapp Number
                        </p>
                        <p
                          className={`${commonCssStyle.infoTextColorBold}   ${commonCssStyle.marginBottomRemove}  `}
                          style={{ marginLeft: "2px" }}
                        >
                          {props.employee && props.employee.whatsApp
                            ? props.employee.whatsApp
                            : "NY"}{" "}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: "19px" }}>
                  <Col md={24} xs={24} style={{ marginTop: "25px" }}>
                    <Row align="top" style={{ gap: "8px" }}>
                      <Col>
                        <div style={{ transform: "translateY(-2px)" }}>
                          <MapPinIcon color={"var(--body-text-color)"} />
                        </div>
                      </Col>
                      <Col>
                        <p
                          className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                          style={{ marginBottom: "7px" }}
                        >
                          Current Address
                        </p>
                      </Col>
                      <Col md={12}>
                        <p
                          className={`${commonCssStyle.infoTextColorBold}    ${commonCssStyle.marginBottomRemove}`}
                          style={{ marginLeft: "2px" }}
                        >
                          {props.employee &&
                            props.employee.addressInfo &&
                            props.employee.addressInfo.map((data, index) => {
                              return (
                                data.type === "current" && (
                                  <span
                                    className={`${commonCssStyle.infoTextColorBold}`}
                                    key={index}
                                  >{`${
                                    data.location ? data.location : "Location"
                                  }, ${
                                    data.country ? data.country : "Country"
                                  }, ${data.state ? data.state : "State"}, ${
                                    data.city ? data.city : "City"
                                  }, ${
                                    data.pincode ? data.pincode : "Pin code"
                                  }`}</span>
                                )
                              );
                            })}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: "19px" }}>
                  <Col md={24} xs={24} style={{ marginTop: "25px" }}>
                    <Row align="top" style={{ gap: "8px" }}>
                      <Col>
                        <div style={{ transform: "translateY(-2px)" }}>
                          <MapPinIcon color={"var(--body-text-color)"} />
                        </div>
                      </Col>
                      <Col>
                        <p
                          className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                          style={{ marginBottom: "7px" }}
                        >
                          Permanent Address
                        </p>
                      </Col>
                      <Col md={12}>
                        <p
                          className={`${commonCssStyle.infoTextColorBold}    ${commonCssStyle.marginBottomRemove}`}
                          style={{ marginLeft: "2px" }}
                        >
                          {props.employee &&
                            props.employee.addressInfo &&
                            props.employee.addressInfo.map((data, index) => {
                              return (
                                data.type === "permanent" && (
                                  <span
                                    className={`${commonCssStyle.infoTextColorBold} ${styles.personalDetails}`}
                                    key={index}
                                  >{`${
                                    data.location ? data.location : "Location"
                                  }, ${
                                    data.country ? data.country : "Country"
                                  }, ${data.state ? data.state : "State"}, ${
                                    data.city ? data.city : "City"
                                  }, ${
                                    data.pincode ? data.pincode : "Pin code"
                                  }`}</span>
                                )
                              );
                            })}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row style={{ marginTop: "19px" }}>
                  <Col md={24} xs={24} style={{ marginTop: "25px" }}>
                    <Row align="top" style={{ gap: "8px" }}>
                      <Col>
                        <div style={{ transform: "translateY(-2px)" }}>
                          {/* <MapPinIcon color={"var(--body-text-color)"} /> */}
                          <LoginOutlined
                            height={100}
                            pointerType="pen"
                            style={{
                              color: "var(--body-text-color)",
                              fontSize: "22px",
                            }}
                          />
                        </div>
                      </Col>
                      <Col>
                        <p
                          className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                          style={{ marginBottom: "7px" }}
                        >
                          Last Login :-
                        </p>
                      </Col>
                      <Col md={12}>
                        <p
                          className={`${commonCssStyle.infoTextColorBold}    ${commonCssStyle.marginBottomRemove}`}
                          style={{ marginLeft: "2px" }}
                        >
                          {props.employee &&
                          props.employee.lastLogin &&
                          props.employee.lastLogin
                            ? moment(props.employee.lastLogin).format(
                                dateFormat
                              )
                            : "N/A"}
                          {" at "}
                          {props.employee &&
                          props.employee.lastLogin &&
                          props.employee.lastLogin
                            ? moment(props.employee.lastLogin).format(
                                props.settindsData &&
                                  props.settindsData.timeFormat &&
                                  props.settindsData.timeFormat === "24hours"
                                  ? "HH:mm"
                                  : "hh:mm a"
                              )
                            : "N/A"}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={24}>
          <Card style={{ padding: "20px", marginBottom: "30px" }}>
            <Row>
              <Col sm={24}>
                <Row align="middle">
                  <Col md={23}>
                    <h1 className={commonCssStyle.summaryInfoMainText}>
                      <Avatar
                        size={50}
                        style={{ paddingTop: "5px", marginRight: "12px" }}
                      >
                        <BrieftCaseIcon color={"var(--primaryColor)"} />
                      </Avatar>{" "}
                      Employement Details
                    </h1>
                  </Col>
                  {props.employee &&
                    props.employee.isPrimary === 0 &&
                    props.authPermissions &&
                    props.authPermissions.find(
                      (data) => data === "employees-edit"
                    ) && (
                      <Col
                        onClick={() => {
                          props.getCheckPermissionHandler(
                            "employees-edit",
                            (success) => {
                              if (success) {
                                setEmployeeDrawOpen(true);
                              } else {
                                setOpenPMModal({
                                  open: true,
                                  permission: "Employees Edit",
                                });
                              }
                            }
                          );
                        }}
                        className={commonCssStyle.coursorPointer}
                        md={1}
                      >
                        <EditBorderIcon color={"var(--primaryColor)"} />
                      </Col>
                    )}
                </Row>
              </Col>
              <Col style={{ marginTop: "19px" }} sm={24}>
                <Row style={{ marginTop: "15px" }}>
                  <Col md={8} xs={24}>
                    <p
                      className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                      style={{ marginBottom: "7px" }}
                    >
                      Employee ID{" "}
                    </p>
                    <p
                      className={`${commonCssStyle.infoTextColorBold}   ${commonCssStyle.marginBottomRemove}  `}
                      style={{ marginLeft: "1px" }}
                    >
                      {props.employee && props.employee.empId
                        ? props.employee.empId
                        : "NY"}{" "}
                    </p>
                  </Col>
                  <Col md={8} xs={24}>
                    <p
                      className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                      style={{ marginBottom: "7px" }}
                    >
                      {" "}
                      Employee Status
                    </p>
                    <p
                      className={`${commonCssStyle.infoTextColorBold}   ${commonCssStyle.marginBottomRemove}  `}
                      style={{ marginLeft: "2px" }}
                    >
                      {props.employee && props.employee.status
                        ? props.employee.status
                        : "NY"}{" "}
                    </p>
                  </Col>
                  <Col md={8} xs={24}>
                    <p
                      className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                      style={{ marginBottom: "7px" }}
                    >
                      Joining Date{" "}
                    </p>
                    <p
                      className={`${commonCssStyle.infoTextColorBold}   ${commonCssStyle.marginBottomRemove}  `}
                      style={{ marginLeft: "2px" }}
                    >
                      {props.employee && props.employee.joiningDate
                        ? moment(props.employee.joiningDate).format(dateFormat)
                        : "NY"}{" "}
                    </p>
                  </Col>
                  <Col md={8} xs={24} style={{ marginTop: "25px" }}>
                    <p
                      className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                      style={{ marginBottom: "7px" }}
                    >
                      Employment Type{" "}
                    </p>
                    <p
                      className={`${commonCssStyle.infoTextColorBold}   ${commonCssStyle.marginBottomRemove}  `}
                      style={{ marginLeft: "2px" }}
                    >
                      {props.employee && props.employee.employmentType
                        ? props.employee.employmentType
                        : "NY"}{" "}
                    </p>
                  </Col>
                  <Col md={8} xs={24} style={{ marginTop: "25px" }}>
                    <p
                      className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                      style={{ marginBottom: "7px" }}
                    >
                      Department{" "}
                    </p>
                    <p
                      className={`${commonCssStyle.infoTextColorBold}   ${commonCssStyle.marginBottomRemove}  `}
                      style={{ marginLeft: "2px" }}
                    >
                      {props.employee && props.employee.department
                        ? props.employee.department
                        : "NY"}{" "}
                    </p>
                  </Col>
                  <Col md={8} xs={24} style={{ marginTop: "25px" }}>
                    <p
                      className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                      style={{ marginBottom: "7px" }}
                    >
                      Work Location{" "}
                    </p>
                    <p
                      className={`${commonCssStyle.infoTextColorBold}   ${commonCssStyle.marginBottomRemove}  `}
                      style={{ marginLeft: "2px" }}
                    >
                      {props.employee &&
                        props.employee.workLocation &&
                        props.employee.workLocation.locationName +
                          ", " +
                          props.employee.workLocation.address +
                          ", " +
                          props.employee.workLocation.city +
                          ", " +
                          props.employee.workLocation.state +
                          ", " +
                          props.employee.workLocation.country +
                          ", " +
                          props.employee.workLocation.pincode}
                    </p>
                  </Col>
                  <Col md={8} xs={24} style={{ marginTop: "25px" }}>
                    <p
                      className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                      style={{ marginBottom: "7px" }}
                    >
                      Per day cost
                    </p>
                    <p
                      className={`${commonCssStyle.infoTextColorBold}   ${commonCssStyle.marginBottomRemove}  `}
                      style={{ marginLeft: "2px" }}
                    >
                      {props.employee && props.employee.perDayCost
                        ? props.settings &&
                          props.settings.currency &&
                          props.settings.currency +
                            " " +
                            props.employee.perDayCost
                        : "NA"}
                    </p>
                  </Col>
                  <Col md={8} xs={24} style={{ marginTop: "25px" }}>
                    <p
                      className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                      style={{ marginBottom: "7px" }}
                    >
                      Salary (CTC in Lakhs)
                    </p>
                    <p
                      className={`${commonCssStyle.infoTextColorBold}   ${commonCssStyle.marginBottomRemove}  `}
                      style={{ marginLeft: "2px" }}
                    >
                      {props.employee && props.employee.monthlySalary
                        ? props.settings &&
                          props.settings.currency &&
                          props.settings.currency +
                            " " +
                            props.employee.monthlySalary
                        : "NA"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={24}>
          <Card style={{ padding: "20px", marginBottom: "30px" }}>
            <Row>
              <Col sm={24}>
                <Row align="middle">
                  <Col md={23}>
                    <h1 className={commonCssStyle.summaryInfoMainText}>
                      <Avatar
                        size={50}
                        style={{ paddingTop: "5px", marginRight: "12px" }}
                      >
                        <img
                          alt="example"
                          className={styles.inputIconsB}
                          src={Bank}
                        />
                      </Avatar>
                      Bank Details
                    </h1>
                  </Col>
                  {props.employee &&
                    props.employee.isPrimary === 0 &&
                    props.authPermissions &&
                    props.authPermissions.find(
                      (data) => data === "employees-edit"
                    ) && (
                      <Col
                        onClick={() => {
                          props.getCheckPermissionHandler(
                            "employees-edit",
                            (success) => {
                              if (success) {
                                setBankDetailsOpen(true);
                              } else {
                                setOpenPMModal({
                                  open: true,
                                  permission: "Employees Edit",
                                });
                              }
                            }
                          );
                        }}
                        className={commonCssStyle.coursorPointer}
                        md={1}
                      >
                        <EditBorderIcon color={"var(--primaryColor)"} />
                      </Col>
                    )}
                </Row>
              </Col>
              <Col style={{ marginTop: "19px" }} sm={24}>
                <Row style={{ marginTop: "15px" }}>
                  <Col md={6} xs={24}>
                    <p
                      className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                      style={{ marginBottom: "7px" }}
                    >
                      Bank{" "}
                    </p>
                    <p
                      className={`${commonCssStyle.infoTextColorBold}   ${commonCssStyle.marginBottomRemove}  `}
                      style={{ marginLeft: "2px" }}
                    >
                      {props.employee && props.employee.bankName
                        ? props.employee.bankName
                        : "NY"}{" "}
                    </p>
                  </Col>
                  <Col md={6} xs={24}>
                    <p
                      className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                      style={{ marginBottom: "7px" }}
                    >
                      Account Number{" "}
                    </p>
                    <p
                      className={`${commonCssStyle.infoTextColorBold}   ${commonCssStyle.marginBottomRemove}  `}
                      style={{ marginLeft: "2px" }}
                    >
                      {props.employee && props.employee.AccountNumber
                        ? props.employee.AccountNumber
                        : "NY"}{" "}
                    </p>
                  </Col>
                  <Col md={6} xs={24}>
                    <p
                      className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                      style={{ marginBottom: "7px" }}
                    >
                      IFSC Code{" "}
                    </p>
                    <p
                      className={`${commonCssStyle.infoTextColorBold}   ${commonCssStyle.marginBottomRemove}  `}
                      style={{ marginLeft: "2px" }}
                    >
                      {props.employee && props.employee.ifsc
                        ? props.employee.ifsc
                        : "NY"}{" "}
                    </p>
                  </Col>
                  <Col md={6} xs={24}>
                    <p
                      className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                      style={{ marginBottom: "7px" }}
                    >
                      Branch Name{" "}
                    </p>
                    <p
                      className={`${commonCssStyle.infoTextColorBold}   ${commonCssStyle.marginBottomRemove}  `}
                      style={{ marginLeft: "2px" }}
                    >
                      {props.employee && props.employee.bankBranchName
                        ? props.employee.bankBranchName
                        : "NY"}{" "}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={24}>
          <Card
            style={{
              padding: "20px",
              paddingBottom: "40px",
              marginBottom: "30px",
            }}
          >
            <Row>
              <Col sm={24}>
                <Row align="middle">
                  <Col md={23}>
                    <h1 className={commonCssStyle.summaryInfoMainText}>
                      <Avatar
                        size={50}
                        style={{ paddingTop: "5px", marginRight: "12px" }}
                      >
                        <img
                          alt="example"
                          className={styles.inputIconsB}
                          src={Vector}
                        />
                      </Avatar>{" "}
                      Credentials
                    </h1>
                  </Col>
                  {props.employee &&
                    props.employee.isPrimary === 0 &&
                    props.authPermissions &&
                    props.authPermissions.find(
                      (data) => data === "employees-edit"
                    ) && (
                      <Col
                        onClick={() => {
                          props.getCheckPermissionHandler(
                            "employees-edit",
                            (success) => {
                              if (success) {
                                setCradentialsOpen(true);
                              } else {
                                setOpenPMModal({
                                  open: true,
                                  permission: "Employees Edit",
                                });
                              }
                            }
                          );
                        }}
                        className={commonCssStyle.coursorPointer}
                        md={1}
                      >
                        <EditBorderIcon color={"var(--primaryColor)"} />
                      </Col>
                    )}
                </Row>
              </Col>
              <Col style={{ marginTop: "19px" }} sm={24}>
                <Row style={{ marginTop: "15px" }}>
                  <Col md={8} xs={24}>
                    <p
                      className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                      style={{ marginBottom: "7px" }}
                    >
                      User Name{" "}
                    </p>
                    <p
                      className={`${commonCssStyle.infoTextColorBold}   ${commonCssStyle.marginBottomRemove}  `}
                      style={{ marginLeft: "2px" }}
                    >
                      {props.employee && props.employee.username
                        ? props.employee.username
                        : "NY"}{" "}
                    </p>
                  </Col>
                  <Col md={8} xs={24}>
                    <p
                      className={`${commonCssStyle.infoTextColor} ${commonCssStyle.marginBottomRemove}`}
                      style={{ marginBottom: "7px" }}
                    >
                      Password{" "}
                    </p>
                    <p
                      className={`${commonCssStyle.infoTextColorBold}   ${commonCssStyle.marginBottomRemove}  `}
                      style={{ marginLeft: "2px" }}
                    >
                      ********
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>

        {/* upload employee Details */}
        <Col xs={24} md={24} style={{}}>
          <Card
            style={{
              marginBottom: "30px",
              paddingBottom: "40px",
              minHeight: "234px",
            }}
            title={
              <>
                <Row align="middle" justify="space-between">
                  <Col>
                    <h3 className={commonCssStyle.summaryInfoMainText}>
                      <Avatar
                        className={commonCssStyle.avtarBgClr}
                        size={50}
                        style={{ paddingTop: "5px", marginRight: "12px" }}
                      >
                        <FilePlus color={"var(--primaryColor)"} />
                      </Avatar>
                      Document
                    </h3>
                  </Col>

                  {props.authPermissions &&
                    props.authPermissions.find(
                      (data) => data === "employees-add"
                    ) && (
                      <Col>
                        <div
                          onClick={() => {
                            props.getCheckPermissionHandler(
                              "candidate-download-resume",
                              (success) => {
                                if (success) {
                                  setEmployeeDetails(true);
                                } else {
                                  setOpenPMModal({
                                    open: true,
                                    permission: "Employee Add",
                                  });
                                }
                              }
                            );
                          }}
                        >
                          <p
                            style={{
                              margin: "0px",
                              color: "var(--primaryColor)",
                              cursor: "pointer",
                              fontFamily: "Helvetica,Product Sans Medium",
                              fontStyle: "noral",
                              fontSize: "16px",
                              lineHeight: "19px",
                            }}
                          >
                            <PlusOutlined
                              style={{ color: "var(--primaryColor)" }}
                            />{" "}
                            Add Document
                          </p>
                        </div>
                      </Col>
                    )}
                </Row>
              </>
            }
          >
            <Row className={style.paddingForInfo}>
              {props.employee &&
              props.employee.uploads &&
              props.employee.uploads.length === 0 ? (
                <Col
                  className={`${style.addIconsAlig} ${style.paddingForInfo}`}
                >
                  <FileAddIcon color={"var(--icons-color)"} />
                  <p
                    style={{ textAlign: "center" }}
                    className={style.infoTextForExperience}
                  >
                    No Document have been uploaded yet
                  </p>
                </Col>
              ) : (
                props.employee &&
                props.employee.uploads &&
                props.employee.uploads.map((data) => {
                  return (
                    <Col
                      key={data.id}
                      style={{ width: "100%", padding: "0px 20px" }}
                    >
                      <Row
                        justify="space-between"
                        style={{
                          backgroundColor: "var(--secondary-card-color)",
                          padding: "10px 5px",
                          margin: "31px 0px 0px 0px",
                          borderRadius: "5px",
                        }}
                      >
                        <Col>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            {data.documentName &&
                            data.documentName.split(".").pop() === "pdf" ? (
                              <img src={pdf} alt="pdf" />
                            ) : (
                              <img
                                style={{ width: "31px" }}
                                src={word}
                                alt="word"
                              />
                            )}
                            <p
                              style={{
                                margin: "0px",
                                color: "var(--body-text-color)",
                              }}
                            >
                              {data.documentName && data.documentName}
                            </p>
                          </div>
                        </Col>

                        <Col>
                          <Row align="middle" style={{ gap: "10px" }}>
                            <Col className={commonCssStyle.coursorPointer}>
                              <span
                                onClick={() => {
                                  window.open(data.uploadPath, "_blank");
                                }}
                              >
                                <DownLoadIcon color={"var(--primaryColor)"} />
                              </span>
                            </Col>
                            {props.employee &&
                              props.employee.isPrimary === 0 && (
                                <Col className={commonCssStyle.coursorPointer}>
                                  <span
                                    onMouseEnter={() => {
                                      setHoverRedClr("#F83A3A");
                                      setExperienceModalId(data.id);
                                    }}
                                    onMouseLeave={() => {
                                      setHoverRedClr("var(--primaryColor)");
                                    }}
                                    onClick={() => {
                                      setDeleteModalName("documentDelete");
                                      setDeleteUploadId(data.id);
                                      setIsModalOpen(true);
                                    }}
                                  >
                                    <TrashIcon
                                      color={
                                        experienceModalId === data.id
                                          ? hoverRedClr
                                          : "var(--primaryColor)"
                                      }
                                    />
                                  </span>
                                </Col>
                              )}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  );
                })
              )}
            </Row>
          </Card>
        </Col>

        {/* end of upload employee details  */}
      </Row>
      {personalDrawOpen && (
        <EmployeesPersonalDetails
          onCoutriesData={(data) => {
            props.getCountryHandler(
              { id: data.id, type: data.type },
              (data, type) => {
                setTimeout(() => {
                  setStates(data);
                }, 300);
              }
            );
          }}
          onCoutriesPermanentData={(data) => {
            props.getCountryHandler(
              { id: data.id, type: data.type },
              (data, type) => {
                setTimeout(() => {
                  setPermanentStates(data);
                }, 300);
              }
            );
          }}
          onCityData={(data) => {
            props.getCountryHandler(
              { id: data.id, type: data.type },
              (data, type) => {
                setTimeout(() => {
                  setCity(data);
                }, 300);
              }
            );
          }}
          onPermanentDataCityData={(data) => {
            props.getCountryHandler(
              { id: data.id, type: data.type },
              (data, type) => {
                setTimeout(() => {
                  setPermanentCity(data);
                }, 300);
              }
            );
          }}
          onPermanentCity={permanentCity}
          onPermanentStates={permanentStates}
          onCity={city}
          onStatesData={states}
          countriesData={countries}
          personalDetailsClose={personalDetailsCloseHandler}
          personalDetailsOpen={personalDrawOpen}
          employee={props.employee}
          // countriesData={props.countriesData}
          settindsData={props.settindsData}
          onSubmit={(val) => {
            props.putEmployeesAction(
              { ...val, id: props.employee.userId },
              "details",
              (success) => {
                if (success) personalDetailsCloseHandler();
              }
            );
          }}
        />
      )}
      {employeeDrawOpen && (
        <EmployeeDetailsDrawer
          employeeDetailsClose={employeeDetailsCloseHandler}
          employeeDetailsOpen={employeeDrawOpen}
          employee={props.employee}
          employees={props.employees}
          employmentType={props.jobTypes}
          roleTypeData={props.roleTypeData}
          departments={props.departments}
          workLocation={props.workLocation}
          settindsData={props.settindsData}
          onSubmit={(val) => {
            props.putEmployeesAction(
              { ...val, id: props.employee.userId },
              "details",
              (success) => {
                if (success) employeeDetailsCloseHandler();
              }
            );
          }}
        />
      )}
      {bankDetailsOpen && (
        <EmployeeBankModal
          bankDetailsClose={bankDetailsCloseHandler}
          bankDetailsOpen={bankDetailsOpen}
          employee={props.employee}
          onSubmit={(val) => {
            props.putEmployeesAction(
              { ...val, id: props.employee.userId },
              "details",
              (success) => {
                if (success) bankDetailsCloseHandler();
              }
            );
          }}
        />
      )}
      {cradentialsOpen && (
        <EmployeeCredentialsModal
          credentialsDetailsClose={cradentialsCloseHandler}
          credentialsDetailsOpen={cradentialsOpen}
          employee={props.employee}
          onSubmit={(val) => {
            props.putEmployeesAction(
              { ...val, id: props.employee.userId },
              "details",
              (success) => {
                if (success) cradentialsCloseHandler();
              }
            );
          }}
        />
      )}
      {employeeDetails && (
        <UploadEmployeeDocumentsModal
          uploadModalOpen={employeeDetails}
          uploadModalClose={uploadModalCloseHandler}
          employee={props.employee}
          onCancelHandle={documentHandleCancel}
          onOkHandle={(val) => {
            props.postEmployeeDocuments(val, "details", (success) => {
              if (success) {
                uploadModalCloseHandler();
                // props.getEmployeeuplodHandler();
              }
            });
          }}
        />
      )}

      <Modal
        closable={false}
        style={{
          width: "40%",
          height: "189px",
        }}
        title={
          <Row style={{ gap: "288px" }}>
            <Col>
              {" "}
              <h3 className={commonCssStyle.popUpHeadingText}>
                Delete Document
              </h3>
            </Col>
            <Col>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  handleCancel();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
        footer={
          <div>
            <Button
              className={commonCssStyle.buttonSettingsSecondary}
              type="default"
              style={{
                width: "177px",
                height: "45px",
              }}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              className={commonCssStyle.buttonSettingsPrimary}
              onClick={() => {
                handleDelete();
              }}
              type="primary"
              style={{
                width: "177px",
                height: "45px",
              }}
            >
              Delete
            </Button>
          </div>
        }
        open={isModalOpen}
        onOk={handleDelete}
        // onCancel={handleCancel}
      >
        <p
          className={`${commonCssStyle.deleteConfiTextColor} ${commonCssStyle.marginBottomRemove}`}
        >
          Are you sure you want to delete this Document
        </p>
      </Modal>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    countriesData: state.country.countries,
    StateData: state.country.state,
    cityData: state.country.city,
    settings: state.settings.settings,
    authPermissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCountryHandler: (data, callback) => {
      dispatch(getCountriesAction(data, callback));
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetails);
