import { Button, Row, Col } from "antd";
import pageNotFound from "../../Assets/Image/pageNotFound.png";
import { useNavigate } from "react-router-dom";
function ErrorPage(props) {
  const navigate = useNavigate();
  return (
    <>
      <img
        style={{
          display: "block",
          //   margin: "auto 30%",
          margin: props.onPNF === 1 ? "0px auto" : "auto 30%",
          position: "relative",
          height: "100%",
        }}
        src={pageNotFound}
      />
      <p
        style={{
          textAlign: "center",
          color: "var(--body-text-color)",
          fontWeight: 400,
          fontSize: "22px",
        }}
      >
        Oop! Sorry the content is not availabel
      </p>

      <Row justify="center">
        <Col>
          <Button
            style={{
              padding: "11px auto",
            }}
            onClick={() => {
              navigate("/");
            }}
            type="primary"
          >
            Back to Dashboard
          </Button>
        </Col>
      </Row>
    </>
  );
}
export default ErrorPage;
