import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Row,
  Tabs,
  Tag,
  Divider,
  Select,
  Form,
  Input,
} from "antd";
import {
  BackButtonIcon,
  BrieftCaseIcon,
  CopyIcon,
  EditBorderIcon,
  GridFour,
  MinusIcons,
  PlusForButtonIcon,
} from "../../IconsComp/Icons";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import commonCssStyle from "../../UI/CommonCss.module.css";
import { Option } from "antd/lib/mentions";
import CopyToClipboard from "react-copy-to-clipboard";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import { connect } from "react-redux";
import BreadcrumbForRecruitment from "./BreadcrumbForRecruitment";

const tagRenderForSkills = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      className="tagsStyle"
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

function SourcingCandidate(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [copyValues, setCopyValues] = useState("");
  const [copied, setCopied] = useState(false);
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  const [mouseKey, setMouseKey] = useState(-1);

  const clearCopied = () => {
    setTimeout(() => {
      setMouseKey(-1);
    }, 2000);
  };

  const onFinish = (val) => {
    props.sharingMediumData(val);
    form.resetFields();
  };

  function addCandidateManually() {
    if (props.recruitmentIdData) {
      props.getCheckPermissionHandler("candidate-add", (success) => {
        if (success) {
          localStorage.setItem(
            "fromWhichPage",
            JSON.stringify({
              fromRecuritment: "fromRecuritment",
            })
          );
          navigate("/candidatesDetailsPage", {
            state: {
              from: "fromRecuritment",
              tab: "addCandidate",
              pageFrom: "recuriter",
              id: props.recruitmentIdData.id,
              jobCode: props.recruitmentIdData.jobCode,
              title: props.recruitmentIdData.title,
            },
          });
        } else {
          setOpenPMModal({
            open: true,
            permission: "Candidate Add",
          });
        }
      });
    }
  }

  return (
    <>
      <Card>
        <Row className={styles.jobDetailCard}>
          <Col md={23}>
            <p className={`${commonCssStyle.headingSourcing}`}>
              Sourcing candidates from various mediums:
            </p>
          </Col>
        </Row>

        {props.permissions &&
          props.permissions.find(
            (data) => data === "recruitment-add-candidate"
          ) && (
            <Row className={commonCssStyle.rowSourcing}>
              <Card className={commonCssStyle.cardSourcing}>
                <Col className={commonCssStyle.buttonForSourcing}>
                  {/* <Link to="/candidateForPool" state={location.state}> */}
                  <Button
                    onClick={() => {
                      navigate("/candidates/suggested", {
                        state: {
                          id: location.state.id,
                          activeTabKey: location.state.activeTabKey,
                          name: props.name,
                          onPathname: props.onPathname,
                        },
                      });
                    }}
                    type="primary"
                    shape="circle"
                    size="small"
                  >
                    <div style={{ transform: "translateY(2px)" }}>
                      <PlusForButtonIcon
                        height={"19px"}
                        width={"19px"}
                        color={"var(--theme)"}
                      />
                    </div>
                  </Button>
                  {/* </Link> */}
                  {/* <Link to="/candidateForPool" state={location.state}> */}
                  <p
                    onClick={() => {
                      if (props.onPathname === "/JobDetailPage") {
                        navigate("/candidates/suggested", {
                          state: {
                            id: location.state.id,
                            activeTabKey: location.state.activeTabKey,
                            name: props.name,
                            onPathname: props.onPathname,
                          },
                        });
                      } else {
                        navigate("/candidates/suggested", {
                          state: {
                            id: location.state.id,
                            activeTabKey: location.state.activeTabKey,
                            name: props.name,
                            // onPathname: props.onPathname,
                          },
                        });
                      }
                    }}
                    style={{ marginTop: "-27px", marginLeft: "40px" }}
                    className={`${commonCssStyle.coursorPointer} ${commonCssStyle.linksSourcing}`}
                  >
                    <span className={commonCssStyle.linkForSourcing}>
                      Candidates suggestion to live job
                    </span>
                  </p>
                  {/* </Link> */}
                </Col>
              </Card>
            </Row>
          )}
        {props.permissions &&
          props.permissions.find((data) => data === "candidate-add") && (
            <Row className={commonCssStyle.rowSourcing}>
              <Card className={commonCssStyle.cardSourcing}>
                <Row align="middle" style={{ gap: "30px" }}>
                  <Col>
                    <Button
                      onClick={() => {
                        // navigate
                        addCandidateManually();
                      }}
                      type="primary"
                      shape="circle"
                      size="small"
                    >
                      <span style={{ transform: "translateY(2px)" }}>
                        <PlusForButtonIcon
                          height={"19px"}
                          width={"19px"}
                          color={"var(--theme)"}
                        />
                      </span>
                    </Button>
                  </Col>
                  <Col>
                    <p
                      onClick={() => {
                        // navigate
                        addCandidateManually();
                      }}
                      className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.coursorPointer} ${commonCssStyle.linksSourcing}`}
                    >
                      Add Candidate Manually
                    </p>
                  </Col>
                </Row>
              </Card>
            </Row>
          )}
        <Row className={commonCssStyle.rowSourcing}>
          <Card className={commonCssStyle.cardSourcing}>
            <p
              className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.sourcingTitle}`}
            >
              Job Links
            </p>
            {props.recruitmentIdData &&
            props.recruitmentIdData.trackingLink.length > 0
              ? props.recruitmentIdData.trackingLink.map((data, index) => {
                  return (
                    <Row key={data.index} style={{ flexDirection: "column" }}>
                      <Col>
                        <span
                          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                        >
                          Medium:&nbsp;
                        </span>
                        <span
                          className={`${commonCssStyle.tableData} ${commonCssStyle.marginTop}`}
                        >
                          {data.sharingMedium}
                        </span>
                      </Col>
                      <Col style={{ marginTop: "3px" }}>
                        <Row>
                          <Col>
                            <span
                              className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                            >
                              Link:&nbsp;
                            </span>
                            <span
                              className={`${commonCssStyle.tableData} ${commonCssStyle.marginTop}`}
                              value={copyValues}
                              // setCopyValues={data.trackingLink}
                            >
                              {/* <Link
                                href={data.trackingLink && data.trackingLink}
                                target="_blank"
                              >
                                {data.trackingLink.substring(0, 20) + "..."}
                              </Link> */}
                              <a
                                href={data.trackingLink && data.trackingLink}
                                target="_blank"
                              >
                                {data.trackingLink.substring(0, 20) + "..."}
                              </a>
                            </span>
                          </Col>
                          <Col
                            // push={1}
                            style={{ marginLeft: "1%" }}
                            className={commonCssStyle.coursorPointer}
                          >
                            <CopyToClipboard
                              text={data.trackingLink}
                              onCopy={() => setCopied(true)}
                            >
                              {/* <span
                                onClick={() => {
                                  setCopyValues(data.trackingLink);
                                }}
                              >
                                <CopyIcon />
                              </span> */}
                              <Button
                                style={{
                                  width: "auto",
                                  fontSize: "14px",
                                  height: "auto",
                                }}
                                type="primary"
                                className={commonCssStyle.buttonSettingsPrimary}
                                onClick={() => {
                                  setCopyValues(data.trackingLink);
                                  setMouseKey(index);
                                  clearCopied();
                                }}
                              >
                                {mouseKey == index ? "Copied" : "Copy"}
                              </Button>
                            </CopyToClipboard>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })
              : ""}
          </Card>
        </Row>

        {props.permissions &&
          props.permissions.find(
            (data) => data === "recruitment-add-candidate"
          ) &&
          props?.profile?.roleType !== "External Recruiter" && (
            <Row className={commonCssStyle.rowSourcing}>
              <Card className={commonCssStyle.cardSourcing}>
                <p className={commonCssStyle.sourcingTitle}>Job Links</p>
                <Form
                  form={form}
                  initialValues={{
                    addLink: [{ link1: "" }],
                  }}
                  onFinish={onFinish}
                >
                  <Row className={commonCssStyle.sectionEndGap}>
                    <Col>
                      <div className="ant-col ant-form-item-label">
                        <p
                          className={
                            commonCssStyle.sourcingTitle
                          } /* className="ant-form-item-required" */
                        >
                          Sharing medium
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Form.List name="sharingMedium">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, key) => (
                          <div>
                            <Row>
                              <Col md={24}>
                                <AddLink MediumLink={props} field={field} />
                              </Col>
                              <Col md={24}>
                                <Row
                                  justify="end"
                                  style={{ marginRight: "21%" }}
                                >
                                  <Col style={{ marginBottom: "3px" }}>
                                    <p
                                      style={{
                                        marginTop: "0px",
                                        marginBottom: "8px",
                                        float: "right",
                                        color: "#F83A3A",
                                      }}
                                      className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.removeLocation}`}
                                    >
                                      Remove Link
                                    </p>
                                    <Button
                                      type="primary"
                                      size="small"
                                      shape="circle"
                                      className={
                                        commonCssStyle.removeLocationbtn
                                      }
                                      style={{
                                        marginTop: "3px",
                                        marginRight: "7px",
                                        float: "right",
                                        backgroundColor: "#F83A3A",
                                      }}
                                    >
                                      <div
                                        style={{
                                          transform: "translateY(-4px)",
                                        }}
                                        onClick={() => remove(key)}
                                      >
                                        <MinusIcons color={"var(--theme)"} />
                                      </div>
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        ))}
                        <Button
                          onClick={() => {
                            props.getCheckPermissionHandler(
                              "recruitment-add-candidate",
                              (success) => {
                                if (success) {
                                  add();
                                } else {
                                  setOpenPMModal({
                                    open: true,
                                    permission: "Recruitment Add Candidate",
                                  });
                                }
                              }
                            );
                          }}
                          type="primary"
                          shape="circle"
                          size="small"
                        >
                          <div style={{ transform: "translateY(2px)" }}>
                            <PlusForButtonIcon
                              height={"19px"}
                              width={"19px"}
                              color={"var(--theme)"}
                            />
                          </div>
                        </Button>
                        <p
                          style={{ marginTop: "-27px", marginLeft: "40px" }}
                          className={commonCssStyle.linksSourcing}
                        >
                          <span className={commonCssStyle.linkForSourcing}>
                            Add Link
                          </span>
                        </p>
                        <Col style={{ marginTop: "45px" }}>
                          <Button
                            style={{ height: "43px", width: "146px" }}
                            className={commonCssStyle.ButtonForNormalPrimary}
                            type="primary"
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                      </>
                    )}
                  </Form.List>
                </Form>

                <Col className={commonCssStyle.buttonForSourcing}></Col>
              </Card>
            </Row>
          )}
        {props.permissions &&
          props.permissions.find(
            (data) => data === "recruitment-add-candidate"
          ) && (
            <Row className={commonCssStyle.rowSourcing}>
              <Card className={commonCssStyle.cardSourcing}>
                <Col className={commonCssStyle.buttonForSourcing}>
                  {/* <Link to="/candidateForPool" state={location.state}> */}
                  <Button
                    onClick={() => {
                      navigate("/candidateForPool", {
                        state: {
                          id: location.state.id,
                          activeTabKey: location.state.activeTabKey,
                          name: props.name,
                          onPathname: props.onPathname,
                        },
                      });
                    }}
                    type="primary"
                    shape="circle"
                    size="small"
                  >
                    <div style={{ transform: "translateY(2px)" }}>
                      <PlusForButtonIcon
                        height={"19px"}
                        width={"19px"}
                        color={"var(--theme)"}
                      />
                    </div>
                  </Button>
                  {/* </Link> */}
                  {/* <Link to="/candidateForPool" state={location.state}> */}
                  <p
                    onClick={() => {
                      if (props.onPathname === "/JobDetailPage") {
                        navigate("/candidateForPool", {
                          state: {
                            id: location.state.id,
                            activeTabKey: location.state.activeTabKey,
                            name: props.name,
                            onPathname: props.onPathname,
                          },
                        });
                      } else {
                        navigate("/candidateForPool", {
                          state: {
                            id: location.state.id,
                            activeTabKey: location.state.activeTabKey,
                            name: props.name,
                            // onPathname: props.onPathname,
                          },
                        });
                      }
                    }}
                    style={{ marginTop: "-27px", marginLeft: "40px" }}
                    className={`${commonCssStyle.coursorPointer} ${commonCssStyle.linksSourcing}`}
                  >
                    <span className={commonCssStyle.linkForSourcing}>
                      Add Candidate from job pool
                    </span>
                  </p>
                  {/* </Link> */}
                </Col>
              </Card>
            </Row>
          )}

        <Row className={commonCssStyle.rowSourcing}>
          <Card className={commonCssStyle.cardSourcing}>
            <Col className={commonCssStyle.buttonForSourcing}>
              {/* <Link to="/importCandidate" state={props.idData}> */}
              <Button
                onClick={() => {
                  navigate("/importCandidate", {
                    state: {
                      id: props.idData.id,
                      activeTabKey: props.idData.activeTabKey,
                      name: props.name,
                      onPathname: props.onPathname,
                    },
                  });
                }}
                type="primary"
                shape="circle"
                size="small"
              >
                <div style={{ transform: "translateY(2px)" }}>
                  <PlusForButtonIcon
                    height={"19px"}
                    width={"19px"}
                    color={"var(--theme)"}
                  />
                </div>
              </Button>
              {/* </Link> */}
              {/* <Link to="/importCandidate" state={props.idData}> */}
              <p
                onClick={() => {
                  navigate("/importCandidate", {
                    state: {
                      id: props.idData.id,
                      activeTabKey: props.idData.activeTabKey,
                      name: props.name,
                      onPathname: props.onPathname,
                    },
                  });
                }}
                style={{ marginTop: "-27px", marginLeft: "40px" }}
                className={`${commonCssStyle.coursorPointer} ${commonCssStyle.linksSourcing}`}
              >
                <span className={commonCssStyle.linkForSourcing}>
                  Import candidates
                </span>
              </p>
              {/* </Link> */}
            </Col>
          </Card>
        </Row>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SourcingCandidate);

function AddLink({ field, MediumLink }) {
  return (
    <Row>
      <Col md={19}>
        <Form.Item
          // name={[field.name, "medium"]}
          // rules={[
          //   {
          //     required: true,
          //     message: "Please enter portfolio/work sample",
          //   },
          // ]}
          {...field}
        >
          <Select
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder="Select sharing medium"
          >
            {MediumLink.sharingMediumsData &&
              MediumLink.sharingMediumsData.map((data, index) => {
                return (
                  <Option key={index} value={data.medium}>
                    {data.medium}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
      </Col>
      {/* <Col md={19}>
        <Form.Item
          name={[field.name, "trackingLink"]}
          rules={[
            {
              required: false,
              message: "Please enter link",
            },
          ]}
        >
          <Input placeholder="Paste the link here" />
        </Form.Item>
      </Col> */}
    </Row>
  );
}
