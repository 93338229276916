import { Card, Radio, Table } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { checkPermissionAction } from "../../../../Reusable/Store/Action/AuthPermissionsAction";
import commonStyleCss from "../../../UI/CommonCss.module.css";
import PermissionMessage from "../../../UI/PermissionMessage";

const ClientCompanyJob = (props) => {
  const navigate = useNavigate();
  const [selectedData, setSeledData] = useState("all");
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  useEffect(() => {
    props.clientTempleteData(selectedData);
  }, []);

  const columns = [
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Job Title
        </p>
      ),
      dataIndex: "title",
      render: (data, render) => {
        return props.permissions &&
          props.permissions.find((data) => data === "job-detail") ? (
          <p
            onClick={() => {
              props.getCheckPermissionHandler("job-detail", (success) => {
                if (success) {
                  navigate("/recruitment/recruitmentJobdetails", {
                    state: {
                      id: render.id,
                      activeTabKey: "3",
                      recuritmentJobDetailsName: data,
                      from: "clientCompany",
                    },
                  });
                } else {
                  setOpenPMModal({
                    open: true,
                    permission: "Job Details",
                  });
                }
              });
            }}
            style={{ color: "var(--primaryColor)" }}
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove} ${commonStyleCss.coursorPointer}`}
          >
            {data ? data : ""}
          </p>
        ) : (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove} ${commonStyleCss.coursorPointer}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Job Department
        </p>
      ),
      dataIndex: "department",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Position Required
        </p>
      ),
      dataIndex: "positionRequired",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Job Revenue
        </p>
      ),
      dataIndex: "revenueGenerated",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {props.settindsData.currency && props.settindsData.currency}{" "}
            {data ? data : "0"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Active
        </p>
      ),
      dataIndex: "activeCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Screening
        </p>
      ),
      dataIndex: "screeningCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Interviewing
        </p>
      ),
      dataIndex: "interviewedCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Offered
        </p>
      ),
      dataIndex: "offeredCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Hired
        </p>
      ),
      dataIndex: "hiredCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Declined
        </p>
      ),
      dataIndex: "declinedCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
  ];

  return (
    <>
      <Card>
        <div>
          <Card
            style={{ borderBottom: "1px solid #E0E0E0", marginTop: "-12px" }}
          >
            <Radio.Group
              className="radioBtnText"
              style={{ padding: "15px" }}
              onChange={({ target: { value } }) => {
                setSeledData(value);
                props.clientTempleteData(value);
              }}
              value={selectedData}
            >
              <Radio value="all" className={commonStyleCss.textNormal}>
                All Jobs
              </Radio>
              <Radio value="live" className={commonStyleCss.textNormal}>
                Live Jobs
              </Radio>
              <Radio value="close" className={commonStyleCss.textNormal}>
                Closed Jobs
              </Radio>
            </Radio.Group>
          </Card>

          <Table
            scroll={{ x: "auto" }}
            pagination={{
              size: "small",
            }}
            style={{ margin: "0px 5px" }}
            size="small"
            className="JobTable"
            columns={columns}
            dataSource={
              props.clientJobDataHandler && props.clientJobDataHandler
            }
          />
        </div>
      </Card>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientCompanyJob);
