import {
  Avatar,
  Card,
  Col,
  Row,
  Tag,
  Input,
  Modal,
  Button,
  Form,
  Select,
  Space,
} from "antd";
import {
  BrieftCaseAddIcon,
  BrieftCaseIcon,
  CloseIcon,
  DownLoadIcon,
  EditBorderIcon,
  EditIcon,
  FileAddIcon,
  FilePlus,
  GroupIcon,
  Student,
  Tags,
  TagsIcon,
  TrashIcon,
  UserIcon,
} from "../../../IconsComp/Icons";

import { connect } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import pdf from "../../../../Assets/Image/PdfImage.png";
import word from "../../../../Assets/Image/word.png";
import { useState, useRef, useEffect, useCallback } from "react";
import styles from "../../AddCandidatesComp/AddCandidatesComp.module.css";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import UploadModal from "./UploadModal";
import PersonalDetailsModal from "./PersonalDetailsModal";
import ExperienceModal from "./ExperienceModal";
import EducationModal from "./EducationModal";
import moment from "moment";
import { useLocation, Link } from "react-router-dom";

import {
  getCandidateAction,
  deleteCandidateExperiencesAction,
  deleteCandidateEducationsAction,
  putCandidatesAction,
  deleteCandidateuploadsAction,
  postCandidateuploadsAction,
} from "../../../../Reusable/Store/Action/CandidatesAction";
import { getSettingsWorklocationAction } from "../../../../Reusable/Store/Action/SettingsAction";
import { useForm } from "antd/lib/form/Form";
import { getCountriesAction } from "../../../../Reusable/Store/Action/Master/MasterCountryAction";
import { getTagsAction } from "../../../../Reusable/Store/Action/Master/MasterTagsAction";
import commonCssStyles from "../../../UI/CommonCss.module.css";
import { checkPermissionAction } from "../../../../Reusable/Store/Action/AuthPermissionsAction";

import PermissionMessage from "../../../UI/PermissionMessage";
import AadTagsForSkills from "../../../UI/AadTagsForSkills";

function SummaryDetails(props) {
  const location = useLocation();
  const [form] = useForm();
  const [backSpace, setBackSpace] = useState(false);
  const [tags, setTags] = useState(["UX", "Design"]);
  const [storeTags, setStoreTags] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDocumentOpen, setIsModalDocumentOpen] = useState(false);
  const [isModalDocumentPerDetOpen, setIsModalPerDetOpen] = useState(false);
  const [experienceModalStatus, setExperienceModalStatus] = useState(false);
  const [educationStatus, setEducationStatus] = useState(false);
  const [modalName, setModalName] = useState("");
  const [modalData, setModalData] = useState({});
  const [hoverRedClr, setHoverRedClr] = useState("var(--primaryColor)");
  const [experienceModalId, setExperienceModalId] = useState("");
  const [deleteModalName, setDeleteModalName] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [educationData, setEducationData] = useState({});
  const [tagModal, setTagModal] = useState(false);
  const [deleteTag, setDeleteTag] = useState("");
  const [deleteUploadId, setDeleteUploadId] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [newSkillsTag, setNewSkillsTag] = useState([]);
  // const [newTags, setNewTags] = useState([]);
  const [permanentStates, setPermanentStates] = useState([]);
  const [permanentCity, setPermanentCity] = useState([]);
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  // const handleSearch = (x) => {
  //   console.log(x);
  // };
  // const handleFocus = (x) => {
  //   console.log(x);
  // };

  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      // if (location.state.from == "recruitmentList") {
      //   // props.getCandidateHandler(location.state.id);
      //   props.getRecruitmentCandidateHandler(location.state.backIconid);
      // }
      if (location.state.from != "recruitmentList") {
        props.getCandidateHandler(location.state.id);
      }
      props.getCountryHandler({ type: "country" }, (data, type) => {
        setCountries(data);
      });
      props.getTagHandler();
    }
    return () => {
      unsubscribed = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";
  const timeFormate =
    props.settindsData &&
    props.settindsData.timeFormat &&
    props.settindsData.timeFormat === "24hours"
      ? "HH:mm"
      : props.settindsData.timeFormat === "12hours"
      ? "hh:mm a"
      : "HH:mm";

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showPersonalDetailsModal = () => {
    setIsModalPerDetOpen(true);
  };
  const handleDelete = () => {
    if (deleteModalName === "addExperience") {
      props.deleteCandidateExperiencesHandler(deleteId);
    } else if (deleteModalName === "addEducation") {
      props.deleteCandidateEducationsHandler(deleteId);
    } else if (deleteModalName === "tagsDelete") {
      let tags = props.candidateData.tags.filter((data) => data !== deleteTag);
      if (tags.length === 0) {
        tags = null;
      }
      let tagsDatas = {
        id: props.candidateData.id,
        _method: "PUT",
        tags,
      };
      props.putCandidateHandler(tagsDatas, "details", (success) => {
        if (success) {
          cancelTagModalHandler();
        }
      });
    } else if (deleteModalName === "documentDelete") {
      props.deleteUploadHandler(deleteUploadId, (success) => {
        if (success) {
          if (location.state.from === "recruitmentList") {
            props.onRecruitmentCandidateData(
              props.onRecruitmentCandidate.candidateJobId
            );
          }
          // else if(location.state.from==="candidateList"){
          //   props.getCandidateHandler(location.state.id);
          // }
          setDeleteUploadId("");
        }
      });
    }
    setDeleteTag("");
    setDeleteId("");
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setDeleteTag("");
    setIsModalOpen(false);
  };
  const showDocumentModal = () => {
    setIsModalDocumentOpen(true);
  };
  const documentHandle = () => {
    handleClose(storeTags);
    setIsModalDocumentOpen(false);
  };
  const documentHandleCancel = () => {
    setIsModalDocumentOpen(false);
  };
  const documentHandleOk = (val) => {
    // props.postDocumentHandler(val, (success) => {
    //   if (success) {
    //     setIsModalDocumentOpen(false);
    //     props.getCandidateHandler(location.state);
    //   }
    // });
  };
  const personalDetailsCancel = () => {
    setIsModalPerDetOpen(false);
  };
  const handleClose = (removedTag) => {
    // tags delete
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  const tagsData = (data) => {
    let tagsDatas;
    if (props.candidateData.tags) {
      tagsDatas = {
        id: props.candidateData.id,
        tags: [...props.candidateData.tags, ...data.tags],
        _method: "PUT",
      };
    } else {
      tagsDatas = {
        id: props.candidateData.id,
        tags: data.tags,
        _method: "PUT",
      };
    }
    props.putCandidateHandler(tagsDatas, "details", (success) => {
      if (success) {
        form.resetFields();
        setNewSkillsTag([]);
        cancelTagModalHandler();
      }
    });
  };

  const experienceModalCancel = () => {
    setExperienceModalStatus(false);

    setModalData({});
    setModalName("");
  };

  const experienceModalOk = () => {
    setExperienceModalStatus(false);
    props.getCandidateHandler(location.state.id);
  };

  const educationModalCancel = () => {
    setEducationStatus(false);
  };
  const educationModalOk = () => {
    setEducationStatus(false);
    props.getCandidateHandler(location.state.id);
  };

  const cancelTagModalHandler = () => {
    setTagModal(false);
  };
  const okTagModalHandler = () => {
    setTagModal(false);
  };

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding:
            location.state.from === "recruitmentList"
              ? "10px 23px 10px 23px"
              : "10px 17px 10px 23px",
        }}
        closable={location.state.from === "recruitmentList" ? false : true}
        onClose={(e) => {
          e.preventDefault();
          props.getCheckPermissionHandler("candidate-delete", (success) => {
            if (success) {
              setDeleteModalName("tagsDelete");
              showModal();
              setDeleteTag(tag);
            } else {
              setOpenPMModal({
                open: true,
                permission: "Candidate Delete",
              });
            }
          });
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
          padding: "10px 0px",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagChild =
    location.state.from === "candidateList"
      ? props.candidateData &&
        props.candidateData.tags &&
        props.candidateData.tags.map(forMap)
      : props.onRecruitmentCandidate &&
        props.onRecruitmentCandidate.tags &&
        props.onRecruitmentCandidate.tags.map(forMap);

  return (
    <>
      <Row>
        <Col xs={24} md={24}>
          <Card
            style={{
              marginBottom: "30px",
            }}
            title={
              <>
                <h3 className={commonCssStyles.summaryInfoMainText}>
                  <Avatar
                    className={commonCssStyle.avtarBgClr}
                    size={50}
                    style={{ paddingTop: "5px", marginRight: "12px" }}
                  >
                    <BrieftCaseIcon color={"var(--primaryColor)"} />
                  </Avatar>
                  Job Details
                </h3>
              </>
            }
          >
            {location.state &&
            location.state.from &&
            props.candidateData &&
            props.candidateData.recruiter &&
            location.state.from === "candidateList" ? (
              <Row
                gutter={[0, 16]}
                className={` ${styles.paddingForInfo}`}
                justify="space-between"
              >
                <Col md={24}>
                  <p
                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                  >
                    <span>Total Experience in Years : </span>
                    {location.state.from === "candidateList"
                      ? props.candidateData &&
                        props.candidateData.totalExperienceMonths &&
                        props.candidateData.totalExperienceMonths
                      : ""}
                  </p>
                </Col>
                <Col md={24}>
                  <p
                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                  >
                    <span>Relevant Experience in Years : </span>
                    {location?.state?.from === "candidateList"
                      ? props.candidateData &&
                        props.candidateData.relavantExperienceMonths &&
                        props.candidateData.relavantExperienceMonths
                      : ""}
                  </p>
                </Col>
                <Col md={24}>
                  <p
                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                  >
                    <span>Current Salary (CTC in Lakhs) : </span>
                    {location.state.from === "candidateList"
                      ? props.candidateData &&
                        props.candidateData.currentSalary &&
                        props.candidateData.currentSalary
                      : ""}
                  </p>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            {location?.state?.from === "recruitmentList" &&
            props.onRecruitmentCandidate &&
            props.onRecruitmentCandidate.addressInfo ? (
              <Row
                gutter={[0, 16]}
                className={` ${styles.paddingForInfo}`}
                justify="space-between"
              >
                <Col md={24}>
                  <p
                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                  >
                    <span>Total Experience in Years : </span>
                    {location?.state?.from === "recruitmentList"
                      ? props.onRecruitmentCandidate &&
                        props.onRecruitmentCandidate.totalExperienceMonths &&
                        props.onRecruitmentCandidate.totalExperienceMonths
                      : ""}
                  </p>
                </Col>
                <Col md={24}>
                  <p
                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                  >
                    <span>Relevant Experience in Years : </span>
                    {location?.state?.from === "recruitmentList"
                      ? props.onRecruitmentCandidate &&
                        props.onRecruitmentCandidate.relavantExperienceMonths &&
                        props.onRecruitmentCandidate.relavantExperienceMonths
                      : ""}
                  </p>
                </Col>
                <Col md={24}>
                  <p
                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                  >
                    <span>Current Salary (CTC in Lakhs) : </span>
                    {location.state.from === "recruitmentList"
                      ? props.onRecruitmentCandidate &&
                        props.onRecruitmentCandidate.currentSalary &&
                        props.onRecruitmentCandidate.currentSalary
                      : ""}
                  </p>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {location?.state?.from === "recruitmentList" ? (
              props.onRecruitmentCandidate &&
              props.onRecruitmentCandidate.jobSummary &&
              props.onRecruitmentCandidate.jobSummary.length > 0 ? (
                <Row
                  gutter={[0, 16]}
                  className={` ${styles.paddingForInfo}`} /* ${commonCssStyle.overflow} */
                >
                  <Col md={24}>
                    <Row gutter={[24, 16]} justify="space-between">
                      <Col md={6}>
                        <p
                          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                        >
                          Title
                        </p>
                      </Col>
                      <Col md={6}>
                        <p
                          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                        >
                          Company
                        </p>
                      </Col>
                      <Col md={6}>
                        <p
                          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                        >
                          Job Code
                        </p>
                      </Col>
                      <Col md={6}>
                        <p
                          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                        >
                          Current Status
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={24}>
                    {props.onRecruitmentCandidate &&
                      props.onRecruitmentCandidate.jobSummary &&
                      props.onRecruitmentCandidate.jobSummary.map(
                        (data, index) => {
                          return (
                            <Row
                              gutter={[24, 16]}
                              justify="space-between"
                              key={index}
                              style={{ marginTop: "10px" }}
                            >
                              <Col md={6}>
                                <p
                                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.infoTextColor} ${commonCssStyle.textBreak}`}
                                >
                                  {data.title}
                                </p>
                              </Col>
                              <Col md={6}>
                                <p
                                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.infoTextColor} ${commonCssStyle.textBreak}`}
                                >
                                  {data.company}
                                </p>
                              </Col>
                              <Col md={6}>
                                <p
                                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.infoTextColor} ${commonCssStyle.textBreak}`}
                                >
                                  {data.jobCode}
                                </p>
                              </Col>
                              <Col md={6}>
                                <p
                                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.infoTextColor} ${commonCssStyle.textBreak}`}
                                >
                                  {data.currentStatus}
                                </p>
                              </Col>
                            </Row>
                          );
                        }
                      )}
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col
                    className={`${styles.paddingForInfo} ${styles.addIconsAlig} ${commonCssStyle.tableData}`}
                    md={24}
                  >
                    <BrieftCaseAddIcon color={"var(--icons-color)"} />

                    <p
                      style={{ textAlign: "center" }}
                      className={styles.infoTextForExperience}
                    >
                      No Job have been added yet
                    </p>
                  </Col>
                </Row>
              )
            ) : location?.state?.from === "candidateList" &&
              props.candidateData &&
              props.candidateData.jobSummary &&
              props.candidateData.jobSummary.length > 0 ? (
              <Row
                gutter={[0, 16]}
                className={` ${styles.paddingForInfo}`} /* ${commonCssStyle.overflow} */
              >
                <Col md={24}>
                  <Row gutter={[24, 16]} justify="space-between">
                    <Col md={6}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                      >
                        Title
                      </p>
                    </Col>
                    <Col md={6}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                      >
                        Company
                      </p>
                    </Col>
                    <Col md={6}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                      >
                        Job Code
                      </p>
                    </Col>
                    <Col md={6}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                      >
                        Current Status
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col md={24}>
                  {props.candidateData &&
                    props.candidateData.jobSummary &&
                    props.candidateData.jobSummary.map((data, index) => {
                      return (
                        <Row
                          gutter={[24, 16]}
                          justify="space-between"
                          key={index}
                          style={{ marginTop: "10px" }}
                        >
                          <Col md={6}>
                            <p
                              className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.infoTextColor} ${commonCssStyle.textBreak}`}
                            >
                              {data.title}
                            </p>
                          </Col>
                          <Col md={6}>
                            <p
                              className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.infoTextColor} ${commonCssStyle.textBreak}`}
                            >
                              {data.company}
                            </p>
                          </Col>
                          <Col md={6}>
                            <p
                              className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.infoTextColor} ${commonCssStyle.textBreak}`}
                            >
                              {data.jobCode}
                            </p>
                          </Col>
                          <Col md={6}>
                            <p
                              className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.infoTextColor} ${commonCssStyle.textBreak}`}
                            >
                              {data.currentStatus}
                            </p>
                          </Col>
                        </Row>
                      );
                    })}
                </Col>
              </Row>
            ) : (
              <Row>
                <Col
                  className={`${styles.paddingForInfo} ${styles.addIconsAlig} ${commonCssStyle.tableData}`}
                  md={24}
                >
                  <BrieftCaseAddIcon color={"var(--icons-color)"} />

                  <p
                    style={{ textAlign: "center" }}
                    className={styles.infoTextForExperience}
                  >
                    No Job have been added yet
                  </p>
                </Col>
              </Row>
            )}
          </Card>
        </Col>
        <Col xs={24} md={24}>
          <Card
            style={{
              marginBottom: "30px",
              // minHeight: "184px",
            }}
            title={
              <>
                <h3 className={commonCssStyles.summaryInfoMainText}>
                  <Avatar
                    className={commonCssStyle.avtarBgClr}
                    size={50}
                    style={{ paddingTop: "5px", marginRight: "12px" }}
                  >
                    <UserIcon color={"var(--primaryColor)"} />
                  </Avatar>
                  Personal Details
                </h3>
              </>
            }
            extra={
              location.state.from === "candidateList" &&
              props.permissions &&
              props.permissions.find((data) => data === "candidate-edit") &&
              props.candidateData &&
              props.candidateData.status &&
              props.candidateData.status === "active" ? (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.getCheckPermissionHandler(
                      "candidate-edit",
                      (success) => {
                        if (success) {
                          showPersonalDetailsModal();
                        } else {
                          setOpenPMModal({
                            open: true,
                            permission: "Candidate Edit",
                          });
                        }
                      }
                    );
                  }}
                >
                  <EditBorderIcon color={"var(--primaryColor)"} />
                </span>
              ) : (
                ""
              )
            }
          >
            <Row
              justify="space-between"
              gutter={[0, 16]}
              className={styles.paddingForInfo}
            >
              <Col /* sm={11} md={4} */>
                <p className={commonCssStyle.infoTextColor}>
                  <span>Gender : </span>
                  <span
                    className={`${commonCssStyle.infoTextColorBold}`} /*  ${styles.personalDetails} */
                  >
                    {location.state.from === "candidateList"
                      ? props.candidateData && props.candidateData.gender
                        ? props.candidateData.gender
                        : "NA"
                      : location.state.from === "recruitmentList" &&
                        props.onRecruitmentCandidate &&
                        props.onRecruitmentCandidate.gender
                      ? props.onRecruitmentCandidate.gender
                      : "NA"}
                  </span>
                </p>
              </Col>
              <Col /* sm={11} md={4} */>
                <p className={commonCssStyle.infoTextColor}>
                  <span>DOB : </span>
                  <span className={`${commonCssStyle.infoTextColorBold}`}>
                    {location.state.from === "candidateList"
                      ? props.candidateData && props.candidateData.dob
                        ? moment(props.candidateData.dob).format(dataFormate)
                        : "NA"
                      : location.state.from === "recruitmentList" &&
                        props.onRecruitmentCandidate &&
                        props.onRecruitmentCandidate.dob &&
                        moment(props.onRecruitmentCandidate.dob).format(
                          dataFormate
                        )}
                  </span>
                </p>
              </Col>
              <Col /* sm={24} md={13} */>
                <p
                  className={`${commonCssStyle.textBreak} ${commonCssStyle.infoTextColor}`}
                >
                  <span>Current Address : </span>
                  {location.state.from === "candidateList"
                    ? props.candidateData &&
                      props.candidateData.addressInfo &&
                      props.candidateData.addressInfo.map((data, index) => {
                        return (
                          data.type === "current" && (
                            <span
                              className={`${commonCssStyle.infoTextColorBold}`}
                              key={index}
                            >{`${data.location ? data.location : "Location"}, ${
                              data.country ? data.country : "Country"
                            }, ${data.state ? data.state : "State"}, ${
                              data.city ? data.city : "City"
                            }, ${
                              data.pincode ? data.pincode : "Pin code"
                            }`}</span>
                          )
                        );
                      })
                    : location.state.from === "recruitmentList" &&
                      props.onRecruitmentCandidate &&
                      props.onRecruitmentCandidate.addressInfo &&
                      props.onRecruitmentCandidate.addressInfo.map(
                        (data, index) => {
                          return (
                            data.type === "current" && (
                              <span
                                className={`${commonCssStyle.infoTextColorBold} ${styles.personalDetails}`}
                                key={index}
                              >{`${
                                data.location ? data.location : "Location"
                              }, ${data.country ? data.country : "Country"}, ${
                                data.state ? data.state : "State"
                              }, ${data.city ? data.city : "City"}, ${
                                data.pincode ? data.pincode : "Pin code"
                              }`}</span>
                            )
                          );
                        }
                      )}
                </p>

                <p
                  style={{ marginTop: "10px" }}
                  className={`${commonCssStyle.textBreak} ${commonCssStyle.infoTextColor}`}
                >
                  <span>Permanent Address :</span>
                  {location.state.from === "candidateList"
                    ? props.candidateData &&
                      props.candidateData.addressInfo &&
                      props.candidateData.addressInfo.map((data) => {
                        return (
                          data.type === "permanent" && (
                            <span
                              className={`${commonCssStyle.infoTextColorBold} ${styles.personalDetails}`}
                              key={data.id}
                            >{`${data.location ? data.location : "Location"}, ${
                              data.country ? data.country : "Country"
                            }, ${data.state ? data.state : "State"}, ${
                              data.city ? data.city : "City"
                            }, ${
                              data.pincode ? data.pincode : "Pin code"
                            }`}</span>
                          )
                        );
                      })
                    : location.state.from === "recruitmentList" &&
                      props.onRecruitmentCandidate &&
                      props.onRecruitmentCandidate.addressInfo &&
                      props.onRecruitmentCandidate.addressInfo.map((data) => {
                        return (
                          data.type === "permanent" && (
                            <span
                              className={`${commonCssStyle.infoTextColorBold} ${styles.personalDetails}`}
                              key={data.id}
                            >{`${data.location ? data.location : "Location"}, ${
                              data.country ? data.country : "Country"
                            }, ${data.state ? data.state : "State"}, ${
                              data.city ? data.city : "City"
                            }, ${
                              data.pincode ? data.pincode : "Pin code"
                            }`}</span>
                          )
                        );
                      })}
                </p>
              </Col>
              <Col xs={12} md={24}>
                <Row>
                  <Col>
                    <p className={commonCssStyle.infoTextColor}>
                      <span>Created Date : </span>
                      {location.state.from === "candidateList"
                        ? moment(
                            props.candidateData &&
                              props.candidateData.createdAt &&
                              props.candidateData.createdAt
                          ).format(dataFormate)
                        : moment(
                            props.onRecruitmentCandidate &&
                              props.onRecruitmentCandidate.createdAt &&
                              props.onRecruitmentCandidate.createdAt
                          ).format(dataFormate)}
                    </p>
                  </Col>
                  <Col xs={24} md={24}>
                    <p
                      style={{ marginTop: "10px" }}
                      className={commonCssStyle.infoTextColor}
                    >
                      <span>Created Time : </span>
                      {location.state.from === "candidateList"
                        ? moment(
                            props.candidateData &&
                              props.candidateData.createdAt &&
                              props.candidateData.createdAt
                          ).format(timeFormate)
                        : moment(
                            props.onRecruitmentCandidate &&
                              props.onRecruitmentCandidate.createdAt &&
                              props.onRecruitmentCandidate.createdAt
                          ).format(timeFormate)}
                    </p>
                  </Col>

                  {location.state &&
                  location.state.from &&
                  props.candidateData &&
                  props.candidateData.recruiter &&
                  location.state.from === "candidateList" ? (
                    <Col style={{ marginTop: "15px" }} xs={12} md={24}>
                      <Row>
                        <Col>
                          <p className={commonCssStyle.infoTextColor}>
                            <span>Recruiter : </span>
                            {location.state.from === "candidateList"
                              ? props.candidateData.recruiter
                              : ""}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </Col>
              {/* <Col xs={12} md={12}>
                <p className={commonCssStyle.infoTextColor}>
                  <span>Created By : </span>

                  {props.candidateData &&
                  props.candidateData.createdBy &&
                  props.onTokenId === props.candidateData.id
                    ? // <p className={commonCssStyle.infoTextColor}>
                      props.onProfileData &&
                      props.onProfileData.firstName +
                        " " +
                        props.onProfileData.lastName
                    : // </p>
                      props.candidatesData &&
                      props.candidateData &&
                      props.candidatesData.find((data) => {
                        if (data.id === props.candidateData.createdBy) {
                          return (
                            // <p className={commonCssStyle.infoTextColor}>
                            data.name
                            // </p>
                          );
                        }
                      })}
                </p>
              </Col> */}
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={24}>
          <Card
            style={{
              marginBottom: "30px",
            }}
            title={
              <>
                <Row align="middle" justify="space-between">
                  <Col>
                    <h3 className={styles.summaryInfoMainText}>
                      <Avatar
                        className={commonCssStyle.avtarBgClr}
                        size={50}
                        style={{ paddingTop: "5px", marginRight: "12px" }}
                      >
                        <Tags color={"var(--primaryColor)"} />
                      </Avatar>
                      Key Skills/Tag
                    </h3>
                  </Col>

                  {location.state.from === "candidateList" &&
                  props.permissions.find((data) => data === "candidate-add") &&
                  props.candidateData &&
                  props.candidateData.status &&
                  props.candidateData.status === "active" ? (
                    <Col>
                      <p
                        onClick={() => {
                          props.getCheckPermissionHandler(
                            "candidate-add",
                            (success) => {
                              if (success) {
                                setTagModal(true);
                              } else {
                                setOpenPMModal({
                                  open: true,
                                  permission: "candidate Add",
                                });
                              }
                            }
                          );
                        }}
                        style={{
                          margin: "0px",
                          color: "var(--primaryColor)",
                          cursor: "pointer",
                          fontFamily: "Helvetica,Product Sans Medium",
                          fontStyle: "noral",
                          fontSize: "16px",
                          lineHeight: "19px",
                        }}
                      >
                        <PlusOutlined
                          style={{ color: "var(--primaryColor)" }}
                        />
                        Add Key Skills/Tags
                      </p>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </>
            }
            extra={
              <>
                <EditIcon />
              </>
            }
          >
            {location.state.from === "candidateList" &&
            props.candidateData &&
            props.candidateData.tags &&
            props.candidateData.tags === null ? (
              <Row>
                <Col
                  className={`${styles.paddingForInfo} ${styles.addIconsAlig} ${commonCssStyle.tableData}`}
                  md={24}
                >
                  <TagsIcon color={"var(--icons-color)"} />
                  <p
                    style={{ textAlign: "center" }}
                    className={styles.infoTextForExperience}
                  >
                    No Tags have been uploaded yet
                  </p>
                </Col>
              </Row>
            ) : location.state.from === "recruitmentList" &&
              props.onRecruitmentCandidate &&
              props.onRecruitmentCandidate.tags &&
              props.onRecruitmentCandidate.tags === null ? (
              <Row>
                <Col
                  className={`${styles.paddingForInfo} ${styles.addIconsAlig} ${commonCssStyle.tableData}`}
                  md={24}
                >
                  <TagsIcon color={"var(--icons-color)"} />
                  <p
                    style={{ textAlign: "center" }}
                    className={styles.infoTextForExperience}
                  >
                    No Tags have been uploaded yet
                  </p>
                </Col>
              </Row>
            ) : (
              <div className={styles.paddingForInfo}>
                <div
                  style={{
                    marginBottom: 16,
                  }}
                >
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    {tagChild}
                  </p>
                </div>
              </div>
            )}
          </Card>
        </Col>
        <Col xs={24} md={24}>
          <Card
            style={{
              marginBottom: "30px",
            }}
            title={
              <>
                <Row align="middle" justify="space-between">
                  <Col>
                    <h3 className={styles.summaryInfoMainText}>
                      <Avatar
                        className={commonCssStyle.avtarBgClr}
                        size={50}
                        style={{ paddingTop: "5px", marginRight: "12px" }}
                      >
                        <BrieftCaseIcon color={"var(--primaryColor)"} />
                      </Avatar>
                      Experience
                    </h3>
                  </Col>

                  {location.state.from === "candidateList" &&
                  props.permissions &&
                  props.permissions.find((data) => data === "candidate-add") &&
                  props.candidateData &&
                  props.candidateData.status &&
                  props.candidateData.status === "active" ? (
                    <Col
                      onClick={() => {
                        props.getCheckPermissionHandler(
                          "candidate-add",
                          (success) => {
                            if (success) {
                              setExperienceModalStatus(true);
                              setModalName("add");
                            } else {
                              setOpenPMModal({
                                open: true,
                                permission: "Candidate Add",
                              });
                            }
                          }
                        );
                      }}
                    >
                      <p
                        style={{
                          margin: "0px",
                          color: "var(--primaryColor)",
                          cursor: "pointer",
                          fontFamily: "Helvetica,Product Sans Medium",
                          fontStyle: "noral",
                          fontSize: "16px",
                          lineHeight: "19px",
                        }}
                      >
                        <PlusOutlined
                          style={{ color: "var(--primaryColor)" }}
                        />
                        Add Experience
                      </p>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </>
            }
          >
            {location.state.from === "candidateList" &&
            props.candidateData &&
            props.candidateData.employeeInfo &&
            props.candidateData.employeeInfo.length > 0 ? (
              props.candidateData.employeeInfo.map((data, index) => {
                return (
                  <Row
                    style={{ paddingTop: "27px" }}
                    key={data.id}
                    className={styles.paddingForInfo}
                  >
                    <Col md={24}>
                      <Row align="middle" justify="space-between">
                        <Col>
                          <p
                            className={` ${commonCssStyle.tableHeadingsColor}`}
                          >
                            {data.designation}
                          </p>
                        </Col>
                        <Col>
                          <Row style={{ gap: "10px" }}>
                            {props.permissions &&
                            props.permissions.find(
                              (data) => data === "candidate-edit"
                            ) &&
                            props.candidateData &&
                            props.candidateData.status &&
                            props.candidateData.status === "active" ? (
                              <Col
                                className={commonCssStyle.coursorPointer}
                                onClick={() => {
                                  props.getCheckPermissionHandler(
                                    "candidate-edit",
                                    (success) => {
                                      if (success) {
                                        setExperienceModalStatus(true);
                                        setModalName("edit");
                                        setModalData(data);
                                      } else {
                                        setOpenPMModal({
                                          open: true,
                                          permission: "Candidate Edit",
                                        });
                                      }
                                    }
                                  );
                                }}
                              >
                                <EditBorderIcon color={"var(--primaryColor)"} />
                              </Col>
                            ) : (
                              <></>
                            )}
                            {props.permissions &&
                            props.permissions.find(
                              (data) => data === "candidate-delete"
                            ) &&
                            props.candidateData &&
                            props.candidateData.status &&
                            props.candidateData.status === "active" ? (
                              <Col
                                className={commonCssStyle.coursorPointer}
                                onClick={() => {
                                  props.getCheckPermissionHandler(
                                    "candidate-delete",
                                    (success) => {
                                      if (success) {
                                        setDeleteModalName("addExperience");
                                        setDeleteId(data.id);
                                        showModal();
                                      } else {
                                        setOpenPMModal({
                                          open: true,
                                          permission: "Candidate Delete",
                                        });
                                      }
                                    }
                                  );
                                }}
                                onMouseEnter={() => {
                                  setHoverRedClr("#F83A3A");
                                  setExperienceModalId(data.id);
                                }}
                                onMouseLeave={() => {
                                  setHoverRedClr("var(--primaryColor)");
                                }}
                              >
                                <TrashIcon
                                  color={
                                    experienceModalId === data.id
                                      ? hoverRedClr
                                      : "var(--primaryColor)"
                                  }
                                />
                              </Col>
                            ) : (
                              <></>
                            )}
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <p className={` ${commonCssStyle.tableData}`}>
                            {data.companyName}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className={` ${commonCssStyle.tableData}`}>
                            {`${
                              data.from === null || data.from === "Invalid date"
                                ? "N/A"
                                : moment(data.from).format("MMMM YYYY")
                            } to ${
                              data.to === null || data.to === "Invalid date"
                                ? "N/A"
                                : moment(data.to).format("MMMM YYYY")
                            }`}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className={` ${commonCssStyle.tableData}`}>
                            {data.summary}
                          </p>
                        </Col>
                      </Row>
                      {data.isCurrent === 1 && (
                        <Row>
                          <Col>
                            <p
                              style={{ color: "var(--primaryColor)" }}
                              className={` ${commonCssStyle.tableData}`}
                            >
                              Current company
                            </p>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                );
              })
            ) : location.state.from === "recruitmentList" &&
              props.onRecruitmentCandidate &&
              props.onRecruitmentCandidate.employeeInfo &&
              props.onRecruitmentCandidate.employeeInfo.length > 0 ? (
              props.onRecruitmentCandidate.employeeInfo.map((data, index) => {
                return (
                  <Row
                    style={{ paddingTop: "27px" }}
                    key={data.id}
                    className={styles.paddingForInfo}
                  >
                    <Col md={24}>
                      <Row align="middle" justify="space-between">
                        <Col>
                          <p
                            className={` ${commonCssStyle.tableHeadingsColor}`}
                          >
                            {data.designation}
                          </p>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <p className={` ${commonCssStyle.tableData}`}>
                            {data.companyName}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className={` ${commonCssStyle.tableData}`}>
                            {`${moment(data.from).format(
                              "MMMM YYYY"
                            )} to ${moment(data.to).format("MMMM YYYY")}`}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className={` ${commonCssStyle.tableData}`}>
                            {data.summary}
                          </p>
                        </Col>
                      </Row>
                      {data.isCurrent === 1 && (
                        <Row>
                          <Col>
                            <p
                              style={{ color: "var(--primaryColor)" }}
                              className={` ${commonCssStyle.tableData}`}
                            >
                              Current company
                            </p>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                );
              })
            ) : (
              <Row>
                <Col
                  className={`${styles.paddingForInfo} ${styles.addIconsAlig} ${commonCssStyle.tableData}`}
                  md={24}
                >
                  <BrieftCaseAddIcon color={"var(--icons-color)"} />

                  <p
                    style={{ textAlign: "center" }}
                    className={styles.infoTextForExperience}
                  >
                    No Experience have been uploaded yet
                  </p>
                </Col>
              </Row>
            )}
          </Card>
        </Col>
        <Col xs={24} md={24}>
          <Card
            style={{
              marginBottom: "30px",
            }}
            title={
              <>
                <Row align="middle" justify="space-between">
                  <Col>
                    <h3 className={styles.summaryInfoMainText}>
                      <Avatar
                        className={commonCssStyle.avtarBgClr}
                        size={50}
                        style={{ paddingTop: "5px", marginRight: "12px" }}
                      >
                        <Student color={"var(--primaryColor)"} />
                      </Avatar>
                      Education
                    </h3>
                  </Col>

                  {location.state.from === "candidateList" &&
                  props.permissions.find((data) => data === "candidate-add") &&
                  props.candidateData &&
                  props.candidateData.status &&
                  props.candidateData.status === "active" ? (
                    <Col
                      onClick={() => {
                        props.getCheckPermissionHandler(
                          "candidate-add",
                          (success) => {
                            if (success) {
                              setEducationStatus(true);
                              setModalName("add");
                            } else {
                              setOpenPMModal({
                                open: true,
                                permission: "candidate Add",
                              });
                            }
                          }
                        );
                      }}
                    >
                      <p
                        style={{
                          margin: "0px",
                          color: "var(--primaryColor)",
                          cursor: "pointer",
                          fontFamily: "Helvetica,Product Sans Medium",
                          fontStyle: "noral",
                          fontSize: "16px",
                          lineHeight: "19px",
                        }}
                      >
                        <PlusOutlined
                          style={{ color: "var(--primaryColor)" }}
                        />
                        Add Education
                      </p>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </>
            }
          >
            {location.state.from === "candidateList" &&
            props.candidateData &&
            props.candidateData.educationInfo &&
            props.candidateData.educationInfo.length > 0 ? (
              props.candidateData.educationInfo.map((data) => {
                return (
                  <Row
                    style={{ paddingTop: "27px" }}
                    key={data.id}
                    className={styles.paddingForInfo}
                  >
                    <Col md={24}>
                      <Row align="middle" justify="space-between">
                        <Col>
                          <p
                            className={` ${commonCssStyle.tableHeadingsColor}`}
                          >
                            {`${data.qualification}${
                              data.major !== null ? ", " + data.major : " "
                            }
                            `}
                          </p>
                        </Col>

                        <Col>
                          <Row style={{ gap: "10px" }}>
                            {props.permissions &&
                            props.permissions.find(
                              (data) => data === "candidate-edit"
                            ) &&
                            props.candidateData &&
                            props.candidateData.status &&
                            props.candidateData.status === "active" ? (
                              <Col
                                className={commonCssStyle.coursorPointer}
                                onClick={() => {
                                  props.getCheckPermissionHandler(
                                    "candidate-edit",
                                    (success) => {
                                      if (success) {
                                        setModalName("edit");
                                        setEducationStatus(true);
                                        setEducationData(data);
                                      } else {
                                        setOpenPMModal({
                                          open: true,
                                          permission: "Candidate Edit",
                                        });
                                      }
                                    }
                                  );
                                }}
                              >
                                <EditBorderIcon color={"var(--primaryColor)"} />
                              </Col>
                            ) : (
                              <></>
                            )}
                            {props.permissions &&
                            props.permissions.find(
                              (data) => data === "candidate-delete"
                            ) &&
                            props.candidateData &&
                            props.candidateData.status &&
                            props.candidateData.status === "active" ? (
                              <Col
                                onClick={() => {
                                  props.getCheckPermissionHandler(
                                    "candidate-delete",
                                    (success) => {
                                      if (success) {
                                        setDeleteModalName("addEducation");
                                        setDeleteId(data.id);
                                        showModal();
                                      } else {
                                        setOpenPMModal({
                                          open: true,
                                          permission: "Candidate Delete",
                                        });
                                      }
                                    }
                                  );
                                }}
                                className={commonCssStyle.coursorPointer}
                                onMouseEnter={() => {
                                  setHoverRedClr("#F83A3A");
                                  setExperienceModalId(data.id);
                                }}
                                onMouseLeave={() => {
                                  setHoverRedClr("var(--primaryColor)");
                                }}
                              >
                                <TrashIcon
                                  color={
                                    experienceModalId === data.id
                                      ? hoverRedClr
                                      : "var(--primaryColor)"
                                  }
                                />
                              </Col>
                            ) : (
                              <></>
                            )}
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className={`${commonCssStyle.tableData}`}>
                            {data.institute}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className={` ${commonCssStyle.tableData}`}>
                            {`${
                              data.from === null || data.from === "Invalid date"
                                ? "N/A"
                                : moment(data.from).format("YYYY")
                            } to ${
                              data.to === null || data.to === "Invalid date"
                                ? "N/A"
                                : moment(data.to).format("YYYY")
                            }`}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {data.cgpa !== null && (
                            <p className={` ${commonCssStyle.tableData}`}>
                              {`CGPA : ${data.cgpa}`}
                            </p>
                          )}
                          {data.percentage !== null && (
                            <p className={` ${commonCssStyle.tableData}`}>
                              {`Percentage : ${data.percentage}%`}
                            </p>
                          )}
                        </Col>
                      </Row>
                      {data.isPersuing === 1 && (
                        <Row>
                          <Col>
                            <p
                              style={{ color: "var(--primaryColor)" }}
                              className={` ${commonCssStyle.tableData}`}
                            >
                              Persuing
                            </p>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                );
              }) /* recruitmentList */
            ) : location.state.from === "recruitmentList" &&
              props.onRecruitmentCandidate &&
              props.onRecruitmentCandidate.educationInfo &&
              props.onRecruitmentCandidate.educationInfo.length > 0 ? (
              props.onRecruitmentCandidate.educationInfo.map((data) => {
                return (
                  <Row
                    style={{ paddingTop: "27px" }}
                    key={data.id}
                    className={styles.paddingForInfo}
                  >
                    <Col md={24}>
                      <Row align="middle" justify="space-between">
                        <Col>
                          <p
                            className={` ${commonCssStyle.tableHeadingsColor}`}
                          >
                            {`${data.qualification}, ${data.major}`}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className={`${commonCssStyle.tableData}`}>
                            {data.institute}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className={` ${commonCssStyle.tableData}`}>
                            {`${moment(data.from).format(
                              "MMMM YYYY"
                            )} to ${moment(data.to).format("MMMM YYYY")}`}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className={` ${commonCssStyle.tableData}`}>
                            {`Grade : ${data.grade}`}
                          </p>
                        </Col>
                      </Row>
                      {data.isPersuing === 1 && (
                        <Row>
                          <Col>
                            <p
                              style={{ color: "var(--primaryColor)" }}
                              className={` ${commonCssStyle.tableData}`}
                            >
                              Persuing
                            </p>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                );
              })
            ) : (
              <Row>
                <Col
                  className={`${styles.paddingForInfo} ${styles.addIconsAlig} ${commonCssStyle.tableData}`}
                  md={24}
                >
                  <div style={{ transform: "translateY(-20px)" }}>
                    <GroupIcon color={"var(--icons-color)"} />
                  </div>

                  <p
                    style={{ textAlign: "center" }}
                    className={styles.infoTextForExperience}
                  >
                    No Education have been uploaded yet
                  </p>
                </Col>
              </Row>
            )}
          </Card>
        </Col>
        <Col xs={24} md={24}>
          <Card
            style={{
              marginBottom: "30px",

              minHeight: "234px",
            }}
            title={
              <>
                <Row align="middle" justify="space-between">
                  <Col>
                    <h3 className={styles.summaryInfoMainText}>
                      <Avatar
                        className={commonCssStyle.avtarBgClr}
                        size={50}
                        style={{ paddingTop: "5px", marginRight: "12px" }}
                      >
                        <FilePlus color={"var(--primaryColor)"} />
                      </Avatar>
                      Document
                    </h3>
                  </Col>

                  {props.permissions &&
                  props.permissions.find((data) => data === "candidate-add") &&
                  props.candidateData &&
                  props.candidateData.status &&
                  props.candidateData.status === "active" ? (
                    <Col>
                      <div
                        onClick={() => {
                          props.getCheckPermissionHandler(
                            "candidate-add",
                            (success) => {
                              if (success) {
                                showDocumentModal();
                              } else {
                                setOpenPMModal({
                                  open: true,
                                  permission: "Candidate Add",
                                });
                              }
                            }
                          );
                        }}
                      >
                        <p
                          style={{
                            margin: "0px",
                            color: "var(--primaryColor)",
                            cursor: "pointer",
                            fontFamily: "Helvetica,Product Sans Medium",
                            fontStyle: "noral",
                            fontSize: "16px",
                            lineHeight: "19px",
                          }}
                        >
                          <PlusOutlined
                            style={{ color: "var(--primaryColor)" }}
                          />
                          Add Document
                        </p>
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </>
            }
          >
            <Row className={styles.paddingForInfo}>
              {location.state.from === "candidateList" &&
              props.candidateData &&
              props.candidateData.uploads &&
              props.candidateData.uploads.length === 0 ? (
                <Col
                  className={`${styles.addIconsAlig} ${styles.paddingForInfo}`}
                >
                  <FileAddIcon color={"var(--icons-color)"} />
                  <p
                    style={{
                      textAlign: "center",
                      color: "var(--body-text-color)",
                      fontFamily: "Helvetica,Product Sans",
                      fontSize: "16px",
                    }}
                    className={styles.infoTextForExperience}
                  >
                    No Document have been uploaded yet
                  </p>
                </Col>
              ) : (
                location.state.from === "candidateList" &&
                props.candidateData &&
                props.candidateData.uploads &&
                props.candidateData.uploads.map((data) => {
                  return (
                    <Col key={data.id} style={{ width: "100%" }}>
                      <Row
                        justify="space-between"
                        style={{
                          backgroundColor: "var(--secondary-card-color)",
                          padding: "10px 5px",
                          margin: "31px 0px 0px 0px",
                          borderRadius: "5px",
                        }}
                      >
                        <Col>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // gap: "20px",
                            }}
                          >
                            {data.documentName &&
                            data.documentName.split(".").pop() === "pdf" ? (
                              <img src={pdf} alt="pdf" />
                            ) : (
                              <img
                                style={{ width: "31px" }}
                                src={word}
                                alt="word"
                              />
                            )}
                            <p
                              style={{
                                margin: "0px 0px 0px 20px",
                                color: "var(--body-text-color)",
                              }}
                            >
                              {data.documentName && data.documentName}
                            </p>
                          </div>
                        </Col>

                        <Col>
                          <Row align="middle" style={{ gap: "10px" }}>
                            {props.permissions &&
                            props.candidateData &&
                            props.permissions.find(
                              (data) => data === "candidate-download-resume"
                            ) &&
                            props.candidateData.status &&
                            props.candidateData.status === "active" ? (
                              <Col className={commonCssStyle.coursorPointer}>
                                <span
                                  onClick={() => {
                                    props.getCheckPermissionHandler(
                                      "candidate-download-resume",
                                      (success) => {
                                        if (success) {
                                          window.open(
                                            data.uploadPath,
                                            "_blank"
                                          );
                                        } else {
                                          setOpenPMModal({
                                            open: true,
                                            permission: "Candidate Edit",
                                          });
                                        }
                                      }
                                    );
                                  }}
                                >
                                  <DownLoadIcon color={"var(--primaryColor)"} />
                                </span>
                              </Col>
                            ) : (
                              <></>
                            )}
                            {props.permissions &&
                            props.candidateData &&
                            props.permissions.find(
                              (data) => data === "candidate-delete"
                            ) &&
                            props.candidateData.status &&
                            props.candidateData.status === "active" ? (
                              <Col className={commonCssStyle.coursorPointer}>
                                <span
                                  onMouseEnter={() => {
                                    setHoverRedClr("#F83A3A");
                                    setExperienceModalId(data.id);
                                  }}
                                  onMouseLeave={() => {
                                    setHoverRedClr("var(--primaryColor)");
                                  }}
                                  onClick={() => {
                                    props.getCheckPermissionHandler(
                                      "candidate-delete",
                                      (success) => {
                                        if (success) {
                                          setDeleteModalName("documentDelete");
                                          setDeleteUploadId(data.id);
                                          setIsModalOpen(true);
                                        } else {
                                          setOpenPMModal({
                                            open: true,
                                            permission: "Candidate Delete",
                                          });
                                        }
                                      }
                                    );
                                  }}
                                >
                                  <TrashIcon
                                    color={
                                      experienceModalId === data.id
                                        ? hoverRedClr
                                        : "var(--primaryColor)"
                                    }
                                  />
                                </span>
                              </Col>
                            ) : (
                              <></>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  );
                })
              )}
              {location.state.from === "recruitmentList" &&
              props.onRecruitmentCandidate &&
              props.onRecruitmentCandidate.uploads &&
              props.onRecruitmentCandidate.uploads.length === 0 ? (
                <Col
                  className={`${styles.addIconsAlig} ${styles.paddingForInfo}`}
                >
                  <FileAddIcon color={"var(--icons-color)"} />
                  <p
                    style={{
                      textAlign: "center",
                      color: "var(--body-text-color)",
                    }}
                    className={styles.infoTextForExperience}
                  >
                    No Document have been uploaded yet
                  </p>
                </Col>
              ) : (
                location.state.from === "recruitmentList" &&
                props.onRecruitmentCandidate &&
                props.onRecruitmentCandidate.uploads &&
                props.onRecruitmentCandidate.uploads.map((data) => {
                  return (
                    <Col key={data.id} style={{ width: "100%" }}>
                      <Row
                        justify="space-between"
                        style={{
                          backgroundColor: "var(--secondary-card-color)",
                          padding: "10px 5px",
                          margin: "31px 0px 0px 0px",
                          borderRadius: "5px",
                        }}
                      >
                        <Col>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            {data.documentName &&
                            data.documentName.split(".").pop() === "pdf" ? (
                              <img src={pdf} alt="pdf" />
                            ) : (
                              <img
                                style={{ width: "31px" }}
                                src={word}
                                alt="word"
                              />
                            )}
                            <p
                              style={{
                                margin: "0px",
                                color: "var(--body-text-color)",
                              }}
                            >
                              {data.documentName && data.documentName}
                            </p>
                          </div>
                        </Col>

                        <Col>
                          <Row align="middle" style={{ gap: "10px" }}>
                            <Col className={commonCssStyle.coursorPointer}>
                              <span
                                onClick={() => {
                                  window.open(data.uploadPath, "_blank");
                                }}
                              >
                                <DownLoadIcon color={"var(--primaryColor)"} />
                              </span>
                            </Col>

                            <Col className={commonCssStyle.coursorPointer}>
                              <span
                                onMouseEnter={() => {
                                  setHoverRedClr("#F83A3A");
                                  setExperienceModalId(data.id);
                                }}
                                onMouseLeave={() => {
                                  setHoverRedClr("var(--primaryColor)");
                                }}
                                onClick={() => {
                                  setDeleteModalName("documentDelete");
                                  setDeleteUploadId(data.id);
                                  setIsModalOpen(true);
                                }}
                              >
                                <TrashIcon
                                  color={
                                    experienceModalId === data.id
                                      ? hoverRedClr
                                      : "var(--primaryColor)"
                                  }
                                />
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  );
                })
              )}
            </Row>
          </Card>
        </Col>
      </Row>
      {/* modal start */}
      {location.state.from === "candidateList" &&
        props.candidateData &&
        isModalDocumentOpen && (
          <UploadModal
            page={"candidateList"}
            data={props.candidateData}
            modalDocumentOpen={isModalDocumentOpen}
            onCancelHandle={documentHandleCancel}
            onDocumentHandle={documentHandle}
            onOkHandle={(val) => {
              props.postDocumentHandler(val, (success) => {
                if (success) {
                  documentHandleCancel();
                  props.getCandidateHandler(location.state.id);
                }
              });
            }}
          />
        )}

      {location.state.from === "recruitmentList" &&
        props.onRecruitmentCandidate &&
        isModalDocumentOpen && (
          <UploadModal
            page={"recruitmentList"}
            data={props.onRecruitmentCandidate}
            modalDocumentOpen={isModalDocumentOpen}
            onCancelHandle={documentHandleCancel}
            onDocumentHandle={documentHandle}
            onOkHandle={(val) => {
              props.postDocumentHandler(val, (success) => {
                if (success) {
                  documentHandleCancel();
                  props.onRecruitmentCandidateData(
                    props.onRecruitmentCandidate.candidateJobId
                  );
                }
              });
            }}
          />
        )}

      {props.candidateData && isModalDocumentPerDetOpen && (
        <PersonalDetailsModal
          data={props.candidateData}
          onCoutriesData={(data) => {
            props.getCountryHandler(
              { id: data.id, type: data.type },
              (data, type) => {
                setTimeout(() => {
                  setStates(data);
                  setCity([]);
                }, 300);
              }
            );
          }}
          onCoutriesPermanentData={(data) => {
            props.getCountryHandler(
              { id: data.id, type: data.type },
              (data, type) => {
                setTimeout(() => {
                  setPermanentStates(data);
                  setPermanentCity([]);
                }, 300);
              }
            );
          }}
          onCityData={(data) => {
            props.getCountryHandler(
              { id: data.id, type: data.type },
              (data, type) => {
                setTimeout(() => {
                  setCity(data);
                }, 300);
              }
            );
          }}
          onPermanentDataCityData={(data) => {
            props.getCountryHandler(
              { id: data.id, type: data.type },
              (data, type) => {
                setTimeout(() => {
                  setPermanentCity(data);
                }, 300);
              }
            );
          }}
          onPermanentCity={permanentCity}
          onPermanentStates={permanentStates}
          onCity={city}
          onStatesData={states}
          countriesData={countries}
          onClosePersonalDetails={personalDetailsCancel}
          onOpenPersonalDetails={isModalDocumentPerDetOpen}
          settindsData={props.settindsData}
        />
      )}
      {experienceModalStatus && modalName === "add" ? (
        <ExperienceModal
          data={{ candidateId: props.candidateData.id }}
          onModalName={modalName}
          onOpenExperienceModal={experienceModalStatus}
          onCloseExperienceModal={experienceModalCancel}
          onOkExperienceModal={experienceModalOk}
          settindsData={props.settindsData}
        />
      ) : (
        ""
      )}
      {experienceModalStatus && modalName === "edit" ? (
        <ExperienceModal
          data={modalData}
          onModalName={modalName}
          onOpenExperienceModal={experienceModalStatus}
          onCloseExperienceModal={experienceModalCancel}
          onOkExperienceModal={experienceModalOk}
          settindsData={props.settindsData}
        />
      ) : (
        ""
      )}
      {educationStatus && modalName === "add" ? (
        <EducationModal
          onModalName={modalName}
          data={{ candidateId: props.candidateData.id }}
          onCloseEducationModal={educationModalCancel}
          onOkEducationModal={educationModalOk}
          onOpenEducationModal={educationStatus}
          settindsData={props.settindsData}
        />
      ) : (
        ""
      )}
      {educationStatus && modalName === "edit" ? (
        <EducationModal
          onModalName={modalName}
          data={educationData}
          onCloseEducationModal={educationModalCancel}
          onOkEducationModal={educationModalOk}
          onOpenEducationModal={educationStatus}
          settindsData={props.settindsData}
        />
      ) : (
        ""
      )}

      {/*  */}
      <Modal
        closable={false}
        style={{
          width: "40%",
          height: "189px",
        }}
        title={
          <Row /* style={{gap:"285px"}} */>
            <Col md={20}>
              <h3 className={commonCssStyle.popUpHeadingText}>
                {deleteModalName === "tagsDelete" && "Delete Tags"}
                {deleteModalName === "addExperience" && "Delete Experience"}
                {deleteModalName === "addEducation" && "Delete Education"}
                {deleteModalName === "documentDelete" && "Delete Document"}
              </h3>
            </Col>
            <Col md={4}>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  handleCancel();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
        footer={
          <>
            <Button
              className={commonCssStyle.buttonSettingsSecondary}
              type="default"
              style={{
                width: "177px",
                height: "45px",
              }}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              className={commonCssStyle.buttonSettingsPrimary}
              onClick={() => {
                handleDelete();
              }}
              type="primary"
              style={{
                width: "177px",
                height: "45px",
              }}
            >
              Delete
            </Button>
          </>
        }
        open={isModalOpen}
        onOk={handleDelete}
      >
        <p
          className={`${commonCssStyle.deleteConfiTextColor} ${commonCssStyle.marginBottomRemove}`}
        >
          {deleteModalName === "tagsDelete" &&
            "Are you sure you want to delete this Tag"}
          {deleteModalName === "addExperience" &&
            "Are you sure you want to delete this Experience"}
          {deleteModalName === "addEducation" &&
            "Are you sure you want to delete this Education"}
          {deleteModalName === "documentDelete" &&
            "Are you sure you want to delete this Document"}
        </p>
      </Modal>
      {/* tag modal */}
      <Modal
        style={{
          width: "40%",
          height: "189px",
        }}
        title={
          <Row justify="space-between">
            <Col>
              <h3 className={commonCssStyle.popUpHeadingText}>
                Add Key Skills/Tags
              </h3>
            </Col>
            <Col>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  cancelTagModalHandler();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)"} />
              </div>
            </Col>
          </Row>
        }
        footer={false}
        closable={false}
        open={tagModal}
        // onCancel={cancelTagModalHandler}
        onOk={okTagModalHandler}
      >
        <Row style={{ marginBottom: "30px" }}>
          <Col md={24}>
            {newSkillsTag &&
              newSkillsTag.map((x, index) => {
                return (
                  <Tag
                    className="keySkillsTags"
                    onClose={() => {
                      form.setFieldsValue({
                        tags: form
                          .getFieldValue("tags")
                          .filter((val) => val !== x),
                      });
                      if (
                        form.getFieldValue("tags") &&
                        form.getFieldValue("tags").length === 0
                      ) {
                        setNewSkillsTag([]);
                      }
                    }}
                    closable
                    key={index}
                  >
                    {x}
                  </Tag>
                );
              })}
          </Col>
        </Row>
        <Form
          form={form}
          name="tags"
          onFinish={(val) => {
            tagsData(val);
          }}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select key skills/tags ",
              },
            ]}
            name="tags"
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              // autoAdjustOverflow={true}
              placeholder="Select key skills/tags or add new key skills/tags"
              className={`TagsStyle`}
              maxTagCount={0}
              // align={{ offset: [0, 4] }}
              mode="tags"
              allowClear
              // style={{
              //   width: "100%",
              // }}
              onChange={(val) => {
                setNewSkillsTag(val);
              }}
              showSearch
              tokenSeparators={[","]}
            >
              {props.tagsData &&
                props.tagsData
                  .filter(
                    (data) =>
                      data.type === "candidate" &&
                      props.candidateData &&
                      (!props.candidateData.tags ||
                        !props.candidateData.tags.includes(data.tag))
                  )
                  .map((data) => {
                    return (
                      <Select.Option key={data.id} value={data.tag}>
                        {data.tag}
                      </Select.Option>
                    );
                  })}
            </Select>
          </Form.Item>

          <Row justify="end" style={{ gap: "17px" }}>
            <Col>
              <Button
                style={{
                  width: "177px",
                  height: "45px",
                }}
                className={commonCssStyles.buttonSettingsSecondary}
                onClick={() => {
                  cancelTagModalHandler();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                className={commonCssStyle.buttonSettingsPrimary}
                htmlType="submit"
                type="primary"
                style={{
                  width: "177px",
                  height: "45px",
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tagsData: state.tags.tags,
    candidateData: state.candidates.candidate,
    candidatesData: state.candidates.candidates,
    countriesData: state.country.countries,
    settindsData: state.settings.settings,
    StateData: state.country.state,
    cityData: state.country.city,
    permissions: state.authpermissions.permissions,
    RecruitmentCandidate: state.candidates.RecruitmentCandidate,
    ishavepermissions: state.authpermissions.ishavepermissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCandidateHandler: (id) => {
      dispatch(getCandidateAction(id));
    },
    deleteCandidateExperiencesHandler: (id) => {
      dispatch(deleteCandidateExperiencesAction(id));
    },
    deleteCandidateEducationsHandler: (id) => {
      dispatch(deleteCandidateEducationsAction(id));
    },
    deleteUploadHandler: (id, callback) => {
      dispatch(deleteCandidateuploadsAction(id, callback));
    },
    putCandidateHandler: (data, type, callback) => {
      dispatch(putCandidatesAction(data, type, callback));
    },
    getCountryHandler: (data, callback) => {
      dispatch(getCountriesAction(data, callback));
    },
    getTagHandler: () => {
      dispatch(getTagsAction());
    },
    postDocumentHandler: (data, callback) => {
      dispatch(postCandidateuploadsAction(data, callback));
    },
    getSettingsWorkLocationHandler: () => {
      dispatch(getSettingsWorklocationAction());
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryDetails);
