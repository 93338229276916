import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  candidateSettlementTerms: [],
  candidateSettlementTerm: null,
};
export const masterCandidateSettlementTermSlice = createSlice({
  name: "candidateSettlementTerm",
  initialState,
  reducers: {
    getCandidateSettlementTermsSlice(state, action) {
      state.candidateSettlementTerms = action.payload.result;
    },
    getCandidateSettlementTermSlice(state, action) {
      state.candidateSettlementTerm = action.payload.result;
    },
    postCandidateSettlementTermsSlice(state, action) {
      state.candidateSettlementTerms = [...state, action.payload.result];
    },
    putCandidateSettlementTermSlice(state, action) {
      state.candidateSettlementTerms = state.candidateSettlementTerms.map(
        (data) => {
          if (data.id === action.payload.result) {
            return action.payload.result;
          } else {
            return data;
          }
        }
      );
    },
    deleteCandidateSettlementTermsSlice(state, action) {
      state.candidateSettlementTerms = state.candidateSettlementTerms.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});
export const {
  getCandidateSettlementTermsSlice,
  getCandidateSettlementTermSlice,
  postCandidateSettlementTermsSlice,
  putCandidateSettlementTermSlice,
  deleteCandidateSettlementTermsSlice,
} = masterCandidateSettlementTermSlice.actions;
export default masterCandidateSettlementTermSlice.reducer;
