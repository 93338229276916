import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employees: [],
  employee: null,
  employee_filter: [],
  employee_timeline: [],
  employee_call_logs: [],
  roles_permission: [],
  employee_jobs:[],
};

export const EmployeesSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    getEmployeesSlice(state, action) {
      state.employees = action.payload.result;
    },
    getRolesAndPermissionSlice(state, action) {
      state.roles_permission = action.payload.result;
    },
    getEmployeesTimelineSlice(state, action) {
      // state.employee_timeline = action.payload.result;
      state.employee_timeline = action.payload;
    },
    employee_filterSlice(state, action) {
      state.employee_filter = action.payload.result;
    },
    getEmployeeSlice(state, action) {
      state.employee = action.payload.result;
    },
    postEmployeesSlice(state, action) {
      state.employee_filter = [action.payload.result, ...state.employee_filter];
    },
    postEmployee_permissionSlice(state, action) {},
    postemployee_roleSlice(state, action) {},
    putEmployeesSlice(state, action) {
      state.employee = action.payload.result;
    },
    deleteEmployeesSlice(state, action) {
      state.employee_filter = state.employee_filter.filter(
        (data) => data.id !== action.payload
      );
    },
    //for candidate uploads
    postEmployeeDocumentsSlice(state, action) {
      state.employee = {
        ...state.employee,
        uploads: [action.payload.result, ...state.employee.uploads],
      };
    },

    deleteEmployeeuploadsSlice(state, action) {
      state.candidate = {
        ...state.candidate,
        uploads: state.candidate.uploads.filter(
          (data) => data.id !== action.payload
        ),
      };
    },

    //employee call logs
    getEmployeesCallLogsSlice(state, action) {
      state.employee_call_logs = action.payload.result;
    },

    //get jobs assigned to the employees
    getEmployeesAssignToJobSlice(state, action) {
      state.employee_jobs = action.payload.result;
    },
  },
});
export const {
  getEmployeesSlice,
  getEmployeeSlice,
  postEmployeesSlice,
  getEmployeesTimelineSlice,
  deleteEmployeesSlice,
  postEmployee_permissionSlice,
  postemployee_roleSlice,
  putEmployeesSlice,
  employee_filterSlice,
  postEmployeeDocumentsSlice,
  deleteEmployeeuploadsSlice,
  getRolesAndPermissionSlice,
  getEmployeesCallLogsSlice,
  getEmployeesAssignToJobSlice,
} = EmployeesSlice.actions;
export default EmployeesSlice.reducer;
