import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  settings: null,
  settingsSubscription: null,
  settingsWorklocation: [],
  // activeKey: "",
};
export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    getSettingsSlice(state, action) {
      state.settings = action.payload.result;
    },
    postSettingsSlice(state, action) {
      state.settings = { ...state.settings, ...action.payload };
    },
    getSettingsSubscriptionSlice(state, action) {
      state.settingsSubscription = action.payload.result;
    },
    getSettingsWorklocationSlice(state, action) {
      state.settingsWorklocation = action.payload.result;
    },
    deleteSettingsWorkLocationSlice(state, action) {
      state.settingsWorklocation = state.settingsWorklocation.filter(
        (data) => data.id !== action.payload
      );
    },
    activeMenuKeySlice(state, action) {
      // localStorage.setItem("activeMenu", action.payload);
      state.activeKey = action.payload;
    },
  },
});

export const {
  getSettingsSlice,
  postSettingsSlice,
  getSettingsSubscriptionSlice,
  getSettingsWorklocationSlice,
  deleteSettingsWorkLocationSlice,
  activeMenuKeySlice,
} = settingsSlice.actions;
export default settingsSlice.reducer;
