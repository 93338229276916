import { Button } from "antd";
import commonCssStyle from "../UI/CommonCss.module.css";
function ResetButton(props) {
  return (
    <Button
      className={commonCssStyle.buttonSettingsPrimary}
      style={{
        marginBottom: "20px",
        width: "auto",
        height: "auto",
        padding: "5px 5px",
      }}
      type="primary"
      size="small"
      onClick={() => {
        props.onClearData();
      }}
    >
      Clear Data
    </Button>
  );
}
export default ResetButton;
