import {
  getLeadSourcesSlice,
  postLeadSourcesSlice,
  getLeadSourceSlice,
  putLeadSourcesSlice,
  deleteLeadSourcesSlice,
} from "../../Slice/Master/MasterLeadSourceSlice";
import { LEAD_SOURCE } from "../../masterpath";
import { message } from "antd";
import AxiosApi from "../../api";
const api = new AxiosApi();
export const getLeadSourcesAction = () => {
  return (dispatch) => {
    api.invoke(LEAD_SOURCE, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getLeadSourcesSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const postLeadSourcesAction = (data) => {
  return (dispatch) => {
    api.invoke(
      LEAD_SOURCE,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(postLeadSourcesSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const getLeadSourceAction = (id) => {
  return (dispatch) => {
    api.invoke(LEAD_SOURCE + "/" + id, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getLeadSourceSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const putLeadSourcesAction = (data) => {
  return (dispatch) => {
    api.invoke(
      LEAD_SOURCE + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(putLeadSourcesSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteLeadSourcesAction = (id) => {
  return (dispatch) => {
    api.invoke(
      LEAD_SOURCE + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(deleteLeadSourcesSlice(id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
