import {
  getStatusSlice,
  getStatusIdSlice,
  postStatusSlice,
  putStatusSlice,
  deleteStatusSlice,
} from "../../Slice/Master/MasterStatusSlice";
import { STATUS_URL } from "../../masterpath";
import { message } from "antd";
import AxiosApi from "../../api";
const api = new AxiosApi();
export const getStatusAction = () => {
  return (dispatch) => {
    api.invoke(STATUS_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getStatusSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getStatusIdAction = (id) => {
  return (dispatch) => {
    api.invoke(STATUS_URL + "/" + id, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getStatusIdSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const postStatusAction = (data) => {
  return (dispatch) => {
    api.invoke(
      STATUS_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(postStatusSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const putStatusAction = (data) => {
  return (dispatch) => {
    api.invoke(
      STATUS_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(putStatusSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteStatusAction = (id) => {
  return (dispatch) => {
    api.invoke(STATUS_URL + "/" + id, "delete", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) {
          message.destroy();
          message.success(data.message, 3);
          dispatch(deleteStatusSlice(id));
        } else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
