import {
  getSettingsSlice,
  postSettingsSlice,
  getSettingsSubscriptionSlice,
  getSettingsWorklocationSlice,
  deleteSettingsWorkLocationSlice,
} from "../Slice/SettingsSlice";
import {
  SETTINGS_URL,
  SETTINGS_SUBSCRIPTION_DETAILS_URL,
  SETTINGS_WORK_LOCATION_URL,
  WORK_LOCATION_REMOVE_IN_ORGANIZATION_URL,
  TEST_CREDENTIALS_URL
} from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";
const api = new AxiosApi();
export const getSettingsAction = () => {
  return (dispatch) => {
    api.invoke(SETTINGS_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getSettingsSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getSettingsSubscriptionAction = () => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_SUBSCRIPTION_DETAILS_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getSettingsSubscriptionSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const getSettingsWorklocationAction = () => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_WORK_LOCATION_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getSettingsWorklocationSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postSettingsAction = (val) => {
  return (dispatch) => {
    api.invoke(
      SETTINGS_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(postSettingsSlice(val));
            dispatch(getSettingsAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};
export const deleteSettingsWorkLocationAction = (id, callback) => {
  return (dispatch) => {
    api.invoke(
      WORK_LOCATION_REMOVE_IN_ORGANIZATION_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);

            dispatch(deleteSettingsWorkLocationSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};


//test credentials

export const postTestCredentialsAction = (val,callback) => {
  return (dispatch) => {
    api.invoke(
      TEST_CREDENTIALS_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {          
            message.destroy();
            message.success(data.message, 3);
            dispatch(getSettingsAction());
            callback(true);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};