import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { BackButtonIcon, TrashIcon,CloseIcon } from "../../../IconsComp/Icons";
import styles from "../Application/Application.module.css";
import { connect } from "react-redux";
import {
  getCandidateReasonsAction,
  postCandidateReasonsAction,
  deleteCandidateReasonsAction,
  putCandidateReasonsAction,
} from "../../../../Reusable/Store/Action/Master/MasterCandidateReasonAction";

import commonStyles from "../../../UI/CommonCss.module.css";
import { useForm } from "antd/lib/form/Form";

function CandidateSelectionAndRejection(props) {
  const [deleteIconStyle, setDeleteIconStyle] = useState(false);
  const [form1] = useForm();
  const [form2] = useForm();
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [tabNumber, setTabNumber] = useState("");
  function onChange(data) {
    setTabNumber(data);
  }

  useEffect(() => {
    props.getCandidateReasonsHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function selectionDataHandler(data) {
    const val = {
      type: "selection",
      reason: data.type,
      status: 1,
    };

    form1.resetFields();

    props.postCandidateReasonsHandler(val);
  }
  function rejectionDataHandler(data) {
    const val = {
      type: "rejection",
      reason: data.type,
      status: 1,
    };
    form2.resetFields();

    props.postCandidateReasonsHandler(val);
  }

  function okHandler() {
    props.deleteCandidateReasonsHandler(deleteId);
    setDeleteModal(false);
    setDeleteId("");
  }
  function cancelHandler() {
    setDeleteId("");
    setDeleteModal(false);
  }

  const itemsData = [
    {
      label: "Candidate Selection Reasons",
      key: "1",
      children: (
        <>
          <Card className={styles.CandidateCard1}>
            <List
              size="small"
              bordered={false}
              dataSource={props.candidateSelectionDatas}
              renderItem={(items) => {
                return (
                  items.type === "selection" && (
                    <List.Item>
                      <Checkbox
                        onChange={(val) => {
                          props.putCandidateReasonHandler({
                            id: items.id,
                            status: val.target.checked ? 1 : 0,
                          });
                        }}
                        checked={items.status === 1}
                        value={items.reason}
                        className={`intellcheckbox ${styles.CandidateSelectCardText}`}
                      >
                        {items.reason}
                      </Checkbox>

                      <span
                        style={{ cursor: "pointer" }}
                        onMouseEnter={() => {
                          setId(items.id);
                          setDeleteIconStyle(true);
                        }}
                        onMouseLeave={() => {
                          setId("");
                          setDeleteIconStyle(false);
                        }}
                        onClick={() => {
                          setDeleteId(items.id);
                          setDeleteModal(true);
                        }}
                      >
                        <TrashIcon
                          color={
                            deleteIconStyle && items.id === id
                              ? "red"
                              : "var(--headings-color)"
                          }
                        />
                      </span>
                    </List.Item>
                  )
                );
              }}
            />
            <Form
              form={form1}
              name="selectionForm"
              onFinish={(val) => {
                selectionDataHandler(val);
              }}
              layout="vertical"
              hideRequiredMark
              className="checkboxwww"
              style={{ padding: "0px 20px 20px 20px" }}
            >
              <Form.Item
                style={{ marginTop: "38px" }}
                name="type"
                label={<p className={styles.CandidateOther}>Add Reasons</p>}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Type other reasons"
                  style={{ resize: "none" }}
                />
              </Form.Item>
              <Row
                style={{ marginTop: "10px", gap: "25px" }}
                align="middle"
                justify="end"
              >
                <Col>
                  <Button
                    className={commonStyles.buttonSettingsSecondary}
                    type="default"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    className={commonStyles.buttonSettingsPrimary}
                    htmlType="submit"
                    type="primary"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </>
      ),
    },
    {
      label: "Candidate Rejection Reasons",
      key: "2",
      children: (
        <>
          <Card className={styles.CandidateCard1}>
            <List
              size="small"
              bordered={false}
              dataSource={props.candidateSelectionDatas}
              renderItem={(items) => {
                return (
                  items.type === "rejection" && (
                    <List.Item>
                      <Checkbox
                        onChange={(val) => {
                          props.putCandidateReasonHandler({
                            id: items.id,
                            status: val.target.checked ? 1 : 0,
                          });
                        }}
                        checked={items.status === 1}
                        className={`intellcheckbox ${styles.CandidateSelectCardText}`}
                      >
                        {items.reason}
                      </Checkbox>
                      <span
                        style={{ cursor: "pointer" }}
                        onMouseEnter={() => {
                          setId(items.id);
                          setDeleteIconStyle(true);
                        }}
                        onMouseLeave={() => {
                          setId("");
                          setDeleteIconStyle(false);
                        }}
                        onClick={() => {
                          setDeleteId(items.id);
                          setDeleteModal(true);
                        }}
                      >
                        <TrashIcon
                          color={
                            deleteIconStyle && items.id === id
                              ? "red"
                              : "var(--headings-color)"
                          }
                        />
                      </span>
                    </List.Item>
                  )
                );
              }}
            />
            <Form
              form={form2}
              name="rejectionForm"
              onFinish={(val) => {
                rejectionDataHandler(val);
              }}
              layout="vertical"
              hideRequiredMark
              className="checkboxwww"
              style={{ padding: "0px 20px 20px 20px" }}
            >
              <Form.Item
                style={{ marginTop: "38px" }}
                name="type"
                label={<p className={styles.CandidateOther}>Add Reasons</p>}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Type other reasons"
                  style={{ resize: "none" }}
                />
              </Form.Item>
              <Row
                style={{ marginTop: "10px", gap: "25px" }}
                align="middle"
                justify="end"
              >
                <Col>
                  <Button
                    className={commonStyles.buttonSettingsSecondary}
                    type="default"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    className={commonStyles.buttonSettingsPrimary}
                    htmlType="submit"
                    type="primary"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </>
      ),
    },
  ];
  return (
    <>
      <Card style={{ overflow: "hidden" }}>
        <Card style={{ overflow: "hidden" }} className={styles.TagsCard1}>
          <Row align="middle" wrap={false}>
            <Col
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.backToApp();
              }}
              className={styles.SourceofTheLeadBackBtn}
            >
              <BackButtonIcon color={"var(--icons-color)"} />
            </Col>
            <Col>
              <h1 className={commonStyles.applicationCardName}>
                Candidate Selection and Rejection
              </h1>
            </Col>
          </Row>
        </Card>

        <Tabs
          className="summarytabForCandidateSel"
          defaultActiveKey="1"
          onChange={onChange}
          items={itemsData}
        />
      </Card>
      <Modal
                  closable={false}
        title={
         <Row style={{gap:"203px"}}>
          <Col> <p
            className={`${commonStyles.marginBottomRemove} ${commonStyles.popUpHeadingText}`}
          >
            {tabNumber === "1"
              ? "Delete Candidate Selection"
              : "Delete Candidate Rejection"}
          </p></Col>
          <Col> <div
        style={{float:"right"}}
        onClick={() => {
          cancelHandler();
        }}
        className={`${commonStyles.coursorPointer} `}
        >
          <CloseIcon   color={"var(--body-text-color)" || "var(--theme)"} />
        </div></Col>
         </Row>
        }
        footer={
          <Row style={{ gap: "10px" }} justify="end" align="middle">
            <Col>
              <Button
                type="default"
                className={commonStyles.buttonSettingsSecondary}
                onClick={() => {
                  cancelHandler();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                className={commonStyles.buttonSettingsPrimary}
                onClick={() => {
                  okHandler();
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        }
        open={deleteModal}
        // onCancel={cancelHandler}
      >
        <Row>
          <Col>
            <p
              className={`${commonStyles.deleteConfiTextColor} ${commonStyles.deleteConfiTextColor}`}
            >
              Are you sure you want to delete
            </p>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    candidateSelectionDatas: state.candidateReasonSlice.candidateReasons,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCandidateReasonsHandler: () => {
      dispatch(getCandidateReasonsAction());
    },
    postCandidateReasonsHandler: (data) => {
      dispatch(postCandidateReasonsAction(data));
    },
    deleteCandidateReasonsHandler: (id) => {
      dispatch(deleteCandidateReasonsAction(id));
    },
    putCandidateReasonHandler: (data) => {
      dispatch(putCandidateReasonsAction(data));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateSelectionAndRejection);
