import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Tabs, Tag, Divider, Select, Spin } from "antd";
import {
  BackButtonIcon,
  BrieftCaseIcon,
  EditBorderIcon,
  GridFour,
  NotifyClientIcon,
  UsersIcon,
} from "../../IconsComp/Icons";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import commonCssStyle from "../../UI/CommonCss.module.css";
import SourcingCandidate from "../Recruitment/SourcingCandidate";
import { FilterOutlinedIcon } from "../../IconsComp/Icons";
import EmployeeTask from "../Employees/EmployeeTask";
import RecruitmentJobInfo from "./RecruitmentJobInfo";
import RecruitmentCandidates from "./RecruitmentCandidates";
import JobInfo from "../Requirement/JobInfo";
import { connect } from "react-redux";
import { getRequirementAction } from "../../../Reusable/Store/Action/RequirementAction";
import { getSharingMediumsAction } from "../../../Reusable/Store/Action/Master/MasterSharingMediumAction";
import { putRequirementsAction } from "../../../Reusable/Store/Action/RequirementAction";
import PermissionMessage from "../../UI/PermissionMessage";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import BreadcrumbForRecruitment from "./BreadcrumbForRecruitment";

function RecruitmentJobDetailPage(props) {
  const [openFilter, setOpenFilter] = useState(false);
  const [key, setKey] = useState("1");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteTag, setDeleteTag] = useState("");
  const [modaltype, setModaltype] = useState("");
  const [acitveKey, setActiveKey] = useState("1");
  // const [modalaName,setModalName]=useState("");
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      props.getRequirementHandler(
        location.state && location.state.id && location.state.id
      );
      if (
        location.state &&
        location.state.activeTabKey &&
        location.state.activeTabKey === "candidatePool"
      ) {
        setActiveKey("3");
        setKey("3");
      } else if (
        location.state &&
        location.state.activeTabKey &&
        location.state.activeTabKey === "addCandidate"
      ) {
        setActiveKey("2");
        setKey("2");
      }
      props.getSharingMediumsActionHandler();
    }
    return () => {
      unsubscribed = true;
    };
  }, []);
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  function showFilterHandler() {
    setOpenFilter(true);
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const sharingMediumData = (val) => {
    const data = { ...val, _method: "PUT", id: location.state.id };
    props.putRequirementHandler(data, "details", (success) => {
      if (success) {
      }
    });
  };

  const handleProvinceChange = (e) => {
    if (e === "Name_ASC") {
      props.getCandidateFilterHandler({
        // ...cfilter,
        sortBy: "name",
        current_page: 1,
        sortOrder: "ASC",
      });
    } else if (e === "Name_DESC") {
      props.getCandidateFilterHandler({
        // ...cfilter,
        sortBy: "name",
        current_page: 1,
        sortOrder: "DESC",
      });
    } else if (e === "Applied_ASC") {
      props.getCandidateFilterHandler({
        // ...cfilter,
        sortBy: "appliedDate",
        current_page: 1,
        sortOrder: "ASC",
      });
    } else if (e === "Applied_DESC") {
      props.getCandidateFilterHandler({
        // ...cfilter,
        sortBy: "appliedDate",
        current_page: 1,
        sortOrder: "DESC",
      });
    } else if (e === "Last_ASC") {
      props.getCandidateFilterHandler({
        // ...cfilter,
        sortBy: "lastUpdated",
        current_page: 1,
        sortOrder: "ASC",
      });
    } else if (e === "Last_DESC") {
      props.getCandidateFilterHandler({
        // ...cfilter,
        sortBy: "lastUpdated",
        current_page: 1,
        sortOrder: "DESC",
      });
    }
  };

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding: "10px 23px 10px 23px",
        }}
        // closable
        // onClose={(e) => {
        //   e.preventDefault();
        //   showModal();
        //   setDeleteTag(tag);
        //   setModaltype("mandatorySkills");
        // }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
          padding: "10px 0px",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagChild =
    props.recruitmentIdData &&
    props.recruitmentIdData.mandatorySkills &&
    props.recruitmentIdData.mandatorySkills.map(forMap);

  const itemsData = [
    {
      label: (
        <Link to="/recruitment">
          <BackButtonIcon color={"var(--body-text-color)"} />
        </Link>
      ),
      key: "0",
    },
    props.permissions &&
      props.permissions.find((data) => data === "recruitment-detail") && {
        label: (
          <>
            <Row align="middle">
              <Col>
                <BrieftCaseIcon
                  color={
                    key === "1"
                      ? "var(--menu-text-color)"
                      : "var(--body-text-color)"
                  }
                />
              </Col>
              <Col push={1}>
                <p
                  className={commonCssStyle.tabsText}
                  style={{
                    margin: "0px",
                    color:
                      key === "1"
                        ? "var(--menu-text-color)"
                        : "var(--body-text-color)",
                  }}
                >
                  Job Detail
                </p>
              </Col>
            </Row>
          </>
        ),
        key: "1",
        children: (
          <>
            <Row gutter={[40, 16]}>
              <Col md={6}>
                {/* <RecruitmentJobInfo /> */}
                <JobInfo recruitmentIdData={props.recruitmentIdData} />
              </Col>

              <Col md={18}>
                <Card className={styles.jobDetailsCardHeader}>
                  <Row className={styles.jobDetailCard}>
                    <Col md={23}>
                      <p
                        className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.textBreak}`}
                      >
                        Job Title
                      </p>
                      <p
                        className={`${commonCssStyle.textBreak} ${styles.txtBlueClrRequirement}`}
                      >
                        {props.recruitmentIdData && props.recruitmentIdData
                          ? props.recruitmentIdData.title
                          : "NA"}
                      </p>
                    </Col>
                    {/* <Col md={1}>
                    <EditBorderIcon color={"var(--primaryColor)"} />
                  </Col> */}
                  </Row>
                  <Divider type="horizontal" className={styles.seperator} />
                  <Row className={styles.jobDetailCardMinHeight}>
                    <Col>
                      <p
                        className={`${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor} `}
                      >
                        Job Type
                      </p>
                      {props.recruitmentIdData &&
                        props.recruitmentIdData &&
                        props.recruitmentIdData.jobType && (
                          <p
                            className={`${commonCssStyle.textBreak} ${commonCssStyle.jobDetailParagraph}`}
                          >
                            {props.recruitmentIdData && props.recruitmentIdData
                              ? props.recruitmentIdData.jobType
                              : "NA"}
                          </p>
                        )}
                    </Col>
                  </Row>
                  <Row className={styles.jobDetailCardMinHeight}>
                    <Col>
                      <Row>
                        <Col>
                          <h2
                            className={`${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
                          >
                            Mandatory skills
                          </h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p
                            style={{
                              marginTop: "-20px",
                            }}
                          >
                            {tagChild}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row
                    className={styles.jobDetailCard1}
                    style={{ gap: "25px" }}
                  >
                    <Col>
                      <h2
                        className={`${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor}`}
                      >
                        Min Experience (in years)
                      </h2>
                      <p
                        className={`${commonCssStyle.textBreak} ${commonCssStyle.jobDetailParagraph}`}
                      >
                        {props.recruitmentIdData && props.recruitmentIdData
                          ? props.recruitmentIdData.experienceToMonth
                          : "NA"}
                      </p>
                    </Col>
                    <Col>
                      <h2
                        className={`${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor}`}
                      >
                        Max Experience (in years)
                      </h2>
                      <p
                        className={`${commonCssStyle.textBreak} ${commonCssStyle.jobDetailParagraph}`}
                      >
                        {props.recruitmentIdData && props.recruitmentIdData
                          ? props.recruitmentIdData.experienceFromMonth
                          : "NA"}
                      </p>
                    </Col>
                  </Row>

                  <Row className={styles.jobDetailCard1}>
                    <Col>
                      <h2
                        className={`${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor}`}
                      >
                        Billing Value
                      </h2>
                      <p
                        className={`${commonCssStyle.textBreak} ${commonCssStyle.jobDetailParagraph}`}
                      >
                        {props.settindsData &&
                          props.settindsData.currency &&
                          props.settindsData.currency}{" "}
                        {props.recruitmentIdData && props.recruitmentIdData
                          ? props.recruitmentIdData.billingValue
                          : "NA"}
                      </p>
                    </Col>
                  </Row>

                  {/* <Row className={styles.jobDetailCard}>
                  <Col>
                    <h2 className={`${commonCssStyle.tableHeadingsColor}`}>
                      Standard commission
                    </h2>
                    <p className={commonCssStyle.jobDetailParagraph}>Fixed</p>
                  </Col>
                </Row> */}

                  <Row className={styles.jobDetailCard1}>
                    <Col>
                      <h2
                        className={`${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor}`}
                      >
                        Settlement Terms
                      </h2>
                      <p
                        className={`${commonCssStyle.textBreak} ${commonCssStyle.jobDetailParagraph}`}
                      >
                        {props.recruitmentIdData && props.recruitmentIdData
                          ? props.recruitmentIdData.settlementTerms
                          : "NA"}
                      </p>
                    </Col>
                  </Row>
                  <Divider type="horizontal" className={styles.seperator} />
                  <Row className={styles.jobDetailCard}>
                    <Col>
                      <p
                        className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.textBreak}`}
                      >
                        Company
                      </p>
                      <p
                        className={`${commonCssStyle.textBreak} ${commonCssStyle.jobDetailParagraph}`}
                      >
                        {props.recruitmentIdData && props.recruitmentIdData
                          ? props.recruitmentIdData.company
                          : "NA"}
                      </p>
                    </Col>
                  </Row>
                  <Row className={styles.jobDetailCard}>
                    <Col>
                      <p
                        className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.textBreak}`}
                      >
                        Job Description
                      </p>
                      {props.recruitmentIdData &&
                        props.recruitmentIdData &&
                        props.recruitmentIdData.jobDescription && (
                          <p
                            className={`${commonCssStyle.textBreak} ${commonCssStyle.jdScroll} ${commonCssStyle.jobDetailParagraph}`}
                            dangerouslySetInnerHTML={{
                              __html: props.recruitmentIdData.jobDescription,
                            }}
                          ></p>
                        )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {props.recruitmentIdData &&
                      props.recruitmentIdData.jobDescriptionURL ? (
                        <div style={{ margin: "20px" }}>
                          <Button
                            onClick={() => {
                              if (
                                props.recruitmentIdData &&
                                props.recruitmentIdData.jobDescriptionURL
                              ) {
                                window.open(
                                  props.recruitmentIdData.jobDescriptionURL,
                                  "_blank"
                                );
                              }
                            }}
                            type="primary"
                            // style={{ color: "var(--primaryColor)" }}
                            // className={`${commonCssStyle.coursorPointer} ${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
                          >
                            Download JD
                          </Button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </>
        ),
      },
    props.permissions &&
      props.permissions.find(
        (data) => data === "recruitment-add-candidate"
      ) && {
        label: (
          <>
            <Row align="middle">
              <Col>
                <UsersIcon
                  color={
                    key === "2"
                      ? "var(--menu-text-color)"
                      : "var(--body-text-color)"
                  }
                />
              </Col>
              <Col push={2}>
                <p
                  className={commonCssStyle.tabsText}
                  style={{
                    margin: "0px",
                    color:
                      key === "2"
                        ? "var(--menu-text-color)"
                        : "var(--body-text-color)",
                  }}
                >
                  Add Candidates
                </p>
              </Col>
            </Row>
          </>
        ),
        key: "2",
        children: (
          <>
            <SourcingCandidate
              profile={props.profile}
              name={props.recruitmentIdData}
              idData={location.state}
              sharingMediumData={sharingMediumData}
              recruitmentIdData={props.recruitmentIdData}
              sharingMediumsData={props.sharingMediumsData}
            />
          </>
        ),
      },
    props.permissions &&
      props.permissions.find(
        (data) => data === "recruitment-candidate-pool"
      ) && {
        label: (
          <>
            <Row align="middle">
              <Col>
                <UsersIcon
                  color={
                    key === "3"
                      ? "var(--menu-text-color)"
                      : "var(--body-text-color)"
                  }
                />
              </Col>
              <Col push={2}>
                <p
                  className={commonCssStyle.tabsText}
                  style={{
                    margin: "0px",
                    color:
                      key === "3"
                        ? "var(--menu-text-color)"
                        : "var(--body-text-color)",
                  }}
                >
                  Candidates Pool
                </p>
              </Col>
              {/* <Col push={15}>
            <Button
                onClick={showFilterHandler}
                className={`${commonCssStyle.filterButton}`}
              >
                <Row style={{ gap: "11px" }}>
                  <Col>
                    <div style={{ transform: "translateY(2px)" }}>
                      <FilterOutlinedIcon color={"var(--primaryColor)"} />
                    </div>
                  </Col>
                  <Col>
                    <p
                      className={`${commonCssStyle.filterAndSortTextStyle} ${commonCssStyle.marginBottomRemove}`}
                    >
                      Filter
                    </p>
                  </Col>
                </Row>
              </Button>
            </Col>
            <Col push={15}>
            <Button
                style={{
                  height: "40px",
                }}
                className={` ${commonCssStyle.sortBtn}`}
              >
                <Row justify="space-between">
                  <Col>
                    <p style={{ transform: "translateY(2px)" }}>Sort By</p>
                  </Col>
                  <Col>
                    <Select
                      style={{ width: "100%", transform: "translateY(3px)" }}
                      onChange={handleProvinceChange}
                      bordered={false}
                      placeholder={
                        <p
                          style={{
                            margin: "0px",
                            color: "var(--primaryColor)",
                          }}
                        >
                          Select
                        </p>
                      }
                      size="small"
                      className={`${commonCssStyle.filterDrop}  selectInput sortBySelectStyle selectTag selectTag1`}
                    >
                      <Select.Option key="1" value="Name_ASC">
                        Name_Ascending
                      </Select.Option>
                      <Select.Option key="2" value="Name_DESC">
                        Name_Descending
                      </Select.Option>
                      <Select.Option key="3" value="Applied_ASC">
                        Applied Date_Ascending
                      </Select.Option>
                      <Select.Option key="4" value="Applied_DESC">
                        Applied Date_Descending
                      </Select.Option>
                      <Select.Option key="5" value="Last_ASC">
                        Last Updated_Ascending
                      </Select.Option>
                      <Select.Option key="6" value="Last_DESC">
                        Last Updated_Descending
                      </Select.Option>
                    </Select>
                  </Col>
                </Row>
              </Button>
            </Col> */}
            </Row>
          </>
        ),
        key: "3",
        children: (
          <>
            <Row>
              <Col md={24}>
                <RecruitmentCandidates idData={location.state} />
              </Col>
            </Row>
          </>
        ),
      },
    // {
    //   key: "4",
    //   label: (
    //     <Row align="middle">
    //       <Col>
    //         <NotifyClientIcon
    //           color={
    //             key === "4"
    //               ? "var(--menu-text-color)"
    //               : "var(--body-text-color)"
    //           }
    //         />
    //       </Col>
    //       <Col push={2}>
    //         <p
    //           className={commonCssStyle.tabsText}
    //           style={{
    //             margin: "0px",
    //             color:
    //               key === "4"
    //                 ? "var(--menu-text-color)"
    //                 : "var(--body-text-color)",
    //           }}
    //         >
    //           Notify Client
    //         </p>
    //       </Col>
    //     </Row>
    //   ),
    // },
  ];

  return (
    <>
      <Row className={commonCssStyle.breadcrumbBottomMargin}>
        <Col md={24}>
          <BreadcrumbForRecruitment
            slide={[1, 2, 3]}
            recruitmentId={location.state}
            name={
              location.state &&
              location.state.recuritmentJobDetailsName &&
              location.state.recuritmentJobDetailsName
            }
          />
        </Col>
      </Row>

      <Tabs
        onTabClick={(val) => {
          if (val == "1") {
            setActiveKey("1");
          } else if (val == "2") {
            props.getCheckPermissionHandler(
              "recruitment-add-candidate",
              (success) => {
                if (success) {
                  setActiveKey("2");
                } else {
                  setOpenPMModal({
                    open: true,
                    permission: "Recruitment Add Candidate",
                  });
                }
              }
            );
          } else if (val == "3") {
            props.getCheckPermissionHandler(
              "recruitment-candidate-pool",
              (success) => {
                if (success) {
                  setActiveKey("3");
                } else {
                  setOpenPMModal({
                    open: true,
                    permission: "Recruitment Candidate Pool",
                  });
                }
              }
            );
          } else if (val === "4") {
            setActiveKey("4");
          }
        }}
        style={{ width: "100%" }}
        className="taskCalendarTabs"
        activeKey={acitveKey}
        defaultActiveKey={
          location.state &&
          location.state.activeTabKey &&
          location.state.activeTabKey
        }
        onChange={(key) => setKey(key)}
        addIcon={
          <Button
            type="primary"
            style={{
              margin: "11px 20px 11px 0px",
              width: "214px",
              height: "46px",
              boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.15)",
              borderRadius: "6px",
            }}
          >
            <Row align="middle" justify="space-around">
              <Col>
                <PlusOutlined />
              </Col>
              <Col>
                <p
                  style={{
                    margin: "0px",
                    fontWeight: 400,
                    fontSize: "16px",
                  }}
                >
                  Add Interviews
                </p>
              </Col>
            </Row>
          </Button>
        }
        items={itemsData}
      />
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
          props.setIsHavepermissionsHandler(false);
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    recruitmentIdData: state.requirements.requirement,
    sharingMediumsData: state.sharingMedium.sharingMediums,
    settindsData: state.settings.settings,
    permissions: state.authpermissions.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRequirementHandler: (id) => {
      dispatch(getRequirementAction(id));
    },
    getSharingMediumsActionHandler: () => {
      dispatch(getSharingMediumsAction());
    },
    putRequirementHandler: (data, type, callback) => {
      dispatch(putRequirementsAction(data, type, callback));
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecruitmentJobDetailPage);
