import styles from "../AddCandidatesComp.module.css";
import style from "../../../Screens/Settings/Application/Application.module.css";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import { useState } from "react";
import {
  Table,
  Tag,
  Dropdown,
  Menu,
  Row,
  Col,
  Button,
  Select,
  Modal,
  Checkbox,
  Form,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  ThreeDotsIcon,
  Filedownload,
  FilterOutlinedIcon,
  NewIcon,
  CalendarCheckIcon,
  DisableUserIcon,
  TrashIcon,
  UserIcon,
  CaretBlackDownIcon,
  CloseIcon,
} from "../../../IconsComp/Icons";
import commonStyleCss from "../../../UI/CommonCss.module.css";
import AddCandidateDrawer from "./drawerComp/AddCandidateDrawer";
import Filter from "./Filter";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  getCandidatesAction,
  deleteCandidatesAction,
  putCandidatesAction,
  getCandidatesFilterAction,
  exportCandidatesDetaisAction,
} from "../../../../Reusable/Store/Action/CandidatesAction";
import { getSettingsWorklocationAction } from "../../../../Reusable/Store/Action/SettingsAction";
import { useEffect } from "react";
import moment from "moment";
import ScheduleInterviewDrawer from "../CandidatesSummary/ScheduleInterviewDrawer";
import { getRequirementsAction } from "../../../../Reusable/Store/Action/RequirementAction";
import { checkPermissionAction } from "../../../../Reusable/Store/Action/AuthPermissionsAction";
import PermissionMessage from "../../../UI/PermissionMessage";
import BreadcrumbForCandidate from "./BreadcrumbForCandidate";
// import ResumeParser from "./drawerComp/ResumeParser";
import { getCandidatesJobAction } from "../../../../Reusable/Store/Action/TaskAndCalendarAction";
// import LoadingIcon from "../../../UI/LoadingIcon";
import { activeMenuKeySlice } from "../../../../Reusable/Store/Slice/SettingsSlice";

function CandidateDetailsTable(props) {
  // useEffect(() => {
  //   props.getSettingsWorkLocationHandler();
  // });
  useEffect(() => {
    if (location && location.state && location.state.pageFrom === "recuriter") {
      props.activeMenuKeyHandle("/recruitment");
    } else if (
      location &&
      location.state &&
      location.state.pageFrom === "requirement"
    ) {
      props.activeMenuKeyHandle("/requirement");
    } else if (
      location &&
      location.state &&
      location.state.pageFrom === "candidate"
    ) {
      props.activeMenuKeyHandle("/candidatesDetailsPage");
    }
  }, []);
  const dateFormate =
    props.settings && props.settings.dateFormat
      ? props.settings.dateFormat
      : "DD/MM/YYYY";
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });

  const fetchRecords = (page) => {
    let candidateFilterDataLocal = JSON.parse(
      localStorage.getItem("candidateDetail")
    );

    if (candidateFilterDataLocal) {
      const val = {
        // ...cfilter,
        // ...props.candidatesFilterData,
        ...candidateFilterDataLocal,
        current_page: page,
      };
      localStorage.setItem(
        "candidateDetail",
        JSON.stringify({ ...candidateFilterDataLocal, current_page: page })
      );
      props.getCandidateFilterHandler(val);
    } else {
      const val = {
        ...cfilter,
        ...props.candidatesFilterData,
        current_page: page,
      };
      localStorage.setItem(
        "candidateDetail",
        JSON.stringify({ current_page: page })
      );
      props.getCandidateFilterHandler(val);
    }
  };
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [deleteBtnClr, setDeleteBtnClr] = useState("var(--headings-color)");
  const [deletId, setDeleteId] = useState("");
  const [openDeleteModal, SetOpenDeleteModal] = useState(false);
  const [hoverKey, setHoverKey] = useState("0");
  const [cfilter, setCFilter] = useState({});
  // const [iconClr, setIconClr] = useState("var(--subHeadings-color)");
  const [scheduleDrawer, setScheduleDrawer] = useState(false);
  const [candidateId, setCandidateId] = useState({});
  const [interviewName, setInterviewName] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  if (window.location.hash === "#/candidatesDetailsPage") {
    localStorage.removeItem("clientCompany");
    localStorage.removeItem("employee");
    // localStorage.removeItem("requirementFilterData");
    // localStorage.removeItem("recuritmentFilterData");
    localStorage.removeItem("taskFilterData");
    localStorage.removeItem("interviewFilterData");
    // localStorage.removeItem("candidateDetailFromRequirement");
    localStorage.removeItem("candidateListForPool");

    // localStorage.removeItem("recuritmentCandidateFilter");
    localStorage.removeItem("reportsCompanyDetailFilterData");
    localStorage.removeItem("reportsjobdetailfilterdata");
    localStorage.removeItem("reportsjobcandidatefilterdata");
    localStorage.removeItem("recuritmentReportFilter");
    localStorage.removeItem("employeeReportsFilter");
    localStorage.removeItem("interivewSort");
    localStorage.removeItem("taskSort");
    localStorage.removeItem("recuritmentSort");
    localStorage.removeItem("recuritmentCandidateSort");
    localStorage.removeItem("requirementSort");
    localStorage.removeItem("ClientCompanySort");
    localStorage.removeItem("employeeSort");
    localStorage.removeItem("ReportsCompanyReport1Sort");
    localStorage.removeItem("reportsjobcandidatefilterdataSort");
    localStorage.removeItem("ReportsJobDetailFilterOneSort");
    localStorage.removeItem("reportRecruitmentEmployeeSort");
    // let check = JSON.parse(localStorage.getItem("fromWhichPage"));
    if (
      location &&
      location.state &&
      location.state.from === "fromRecuritment"
    ) {
    } else if (
      location &&
      location.state &&
      location.state.from === "fromRequirement"
    ) {
    } else {
      // localStorage.removeItem("fromWhichPage");
      // localStorage.removeItem("candidateDetailFromRecruitment");
      // localStorage.removeItem("candidateDetailFromRequirement");
      localStorage.removeItem("requirementFilterData");
      localStorage.removeItem("recuritmentFilterData");
    }
  }
  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      if (
        (location.state &&
          location.state.from &&
          location.state.from === "fromRequirement") ||
        (location.state &&
          location.state.from &&
          location.state.from === "fromRecuritment")
      ) {
        setOpen(true);
      }
      const data = {
        current_page: 1,
      };
      // props.getCandidateFilterHandler(data);
      props.getJobNameHandler();
    }
    return () => {
      unsubscribed = true;
    };
  }, []);

  useEffect(() => {
    if (
      (location.state &&
        location.state.from &&
        location.state.from === "fromRecuritment") ||
      (location.state &&
        location.state.from &&
        location.state.from === "fromRequirement")
    ) {
      return;
    }
    const obj = JSON.parse(localStorage.getItem("candidateDetail"));
    // const fromWhichPage = JSON.parse(localStorage.getItem("fromWhichPage"));
    // if (fromWhichPage && fromWhichPage.candidateDetail === "candidateDetail") {
    if (obj) {
      props.getCandidateFilterHandler(obj);
    } else {
      props.getCandidateFilterHandler({ current_page: 1 });
    }
    // } else {
    //   props.getCandidateFilterHandler({ current_page: 1 });
    // }
  }, []);

  function confirmDeleteHandler() {
    SetOpenDeleteModal(false);

    props.deleteCandidateDetailsHandler(deletId, (success) => {
      if (success) {
        // let candidateDetailFilter = JSON.parse(
        //   localStorage.getItem("candidateDetail")
        // );
        // if (candidateDetailFilter) {
        //   props.getCandidateFilterHandler({
        //     ...cfilter,
        //     sortBy: "lastUpdated",
        //     sortOrder: "DESC",
        //     current_page: 1,
        //     ...candidateDetailFilter,
        //   });
        // } else {
        //   props.getCandidateFilterHandler({
        //     ...cfilter,
        //     sortBy: "lastUpdated",
        //     sortOrder: "DESC",
        //     current_page: 1,
        //   });
        // }
      }
    });
    setDeleteId("");
  }
  function closeDrawerHandler() {
    setScheduleDrawer(false);
  }
  function closeDeleteHandler() {
    SetOpenDeleteModal(false);
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const closeHandler = () => {
    setOpen(false);
  };

  function showFilterHandler() {
    setOpenFilter(true);
  }
  const closeFilterHandler = () => {
    setOpenFilter(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys, ...newSelectedRowKeys]);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      dataIndex: "id",
      render: (text, render) => (
        // (location &&
        //   location.state &&
        //   location.state.from &&
        //   location.state.from === "fromRequirement") ||
        // (location &&
        //   location.state &&
        //   location.state.from &&
        //   location?.state?.from === "fromRecuritment") ? (
        //   <></>
        // ) : (
        <>
          <Checkbox
            checked={selectedRowKeys.find((x) => x === text)}
            onChange={(val) => {
              if (val.target.checked) {
                setSelectedRowKeys([...selectedRowKeys, text]);
              } else {
                let index = selectedRowKeys.indexOf(text);
                if (index != -1) {
                  setSelectedRowKeys(
                    selectedRowKeys.filter((data) => data !== text)
                  );
                }
              }
            }}
          />
        </>
      ),
      // ),
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Name
        </p>
      ),
      dataIndex: "name",
      render: (text, render) => (
        <>
          {/* &&
          render.status === "active" */}
          {props.permissions &&
          props.permissions.find((data) => data === "candidate-detail") ? (
            <Row
              style={{ gap: "2px" }}
              onClick={() => {
                props.getCheckPermissionHandler(
                  "candidate-detail",
                  (success) => {
                    if (success) {
                      // if (
                      //   location.state &&
                      //   location.state.pageFrom &&
                      //   location.state.pageFrom === "recuriter"
                      // ) {
                      //   navigate("/candidatesDetailsPage/summary", {
                      //     state: {
                      //       id: render.id,
                      //       from: "candidateList",
                      //       pageFrom: "recuriter",
                      //       title: location.state.title,
                      //       idForRecuritment: location.state.id,
                      //     },
                      //   });
                      // } else if (
                      //   location.state &&
                      //   location.state.pageFrom &&
                      //   location.state.pageFrom === "requirement"
                      // ) {
                      //   navigate("/candidatesDetailsPage/summary", {
                      //     state: {
                      //       id: render.id,
                      //       from: "candidateList",
                      //       pageFrom: "requirement",
                      //       title: location.state.title,
                      //       idForRequirement: location.state.id,
                      //     },
                      //   });
                      // } else {
                      navigate("/candidatesDetailsPage/summary", {
                        state: { id: render.id, from: "candidateList" },
                      });
                      // }
                    } else {
                      setOpenPMModal({
                        open: true,
                        permission: "Candidate Details",
                      });
                    }
                  }
                );
              }}
              // to={"/candidatesDetailsPage/summary"}
              // state={{ id: render.id, from: "candidateList" }}
            >
              <Col>
                <p
                  style={{
                    color: "var(--primaryColor)",
                    fontFamily: "Helvetica,ProductSans",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "17px",
                    margin: "0px",
                    cursor: "pointer",
                  }}
                >
                  {text}
                </p>
              </Col>
              <Col className={commonCssStyle.newIcon}>
                {moment().diff(moment(render.createdAt), "days") <= 15 ? (
                  <NewIcon />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          ) : (
            <Row style={{ gap: "2px" }}>
              <Col>
                <p
                  style={{
                    color: "var(--table-text-color)",
                    fontFamily: "Helvetica,ProductSans",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "17px",
                    margin: "0px",
                  }}
                >
                  {text}
                </p>
              </Col>
              <Col className={commonCssStyle.newIcon}>
                {moment().diff(moment(render.createdAt), "days") <= 15 ? (
                  <NewIcon />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          )}
        </>
      ),
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Contact Number
        </p>
      ),
      dataIndex: "phone",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Recruiter
        </p>
      ),
      dataIndex: "teamMember",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Source
        </p>
      ),
      dataIndex: "leadSource",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },

    // {
    //   title: (
    //     <p
    //       className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
    //     >
    //       Resume link
    //     </p>
    //   ),
    //   dataIndex: "resume",

    //   render: (text, render) => (
    //     <Row justify="space-between">
    //       <Col md={6}>
    //         {text &&
    //         props.permissions &&
    //         props.permissions.find(
    //           (data) => data === "candidate-download-resume"
    //         ) ? (
    //           <span
    //             className={commonCssStyle.coursorPointer}
    //             onClick={() => {
    //               props.getCheckPermissionHandler(
    //                 "candidate-download-resume",
    //                 (success) => {
    //                   if (success) {
    //                     window.open(render.resume, "_blank");
    //                   } else {
    //                     setOpenPMModal({
    //                       open: true,
    //                       permission: "Candidate Download Resume",
    //                     });
    //                   }
    //                 }
    //               );
    //             }}
    //           >
    //             <Filedownload color={"var(--primaryColor)"} />
    //           </span>
    //         ) : (
    //           text && <Filedownload color={"var(--primaryColor)"} />
    //         )}
    //       </Col>
    //     </Row>
    //   ),
    // },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Job Assigned
        </p>
      ),
      dataIndex: "jobAssignedCount",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonStyleCss.marginBottomRemove}`} /* ${commonCssStyle.coursorPointer} */
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Applied Date
        </p>
      ),
      dataIndex: "createdAt",
      render: (data) => {
        return (
          <p
            style={{ whiteSpace: "nowrap" }}
            className={`${commonCssStyle.tableData} ${commonStyleCss.marginBottomRemove}`} /* ${commonCssStyle.coursorPointer} */
          >
            {data ? moment(data).format(dateFormate) : "NA"}
          </p>
        );
      },
    },
    {
      title: <></>,
      dataIndex: "status",
      render: (text, render) => (
        <Row justify="space-between">
          <Col md={6} id="candidateEditDelete">
            <Dropdown
              placement="bottomRight"
              overlay={
                <Menu
                  style={{ paddingBottom: "10px" }}
                  items={[
                    props.permissions &&
                      props.permissions.find(
                        (data) => data === "interview-add"
                      ) && {
                        key: "1",
                        label:
                          render.status === "active" ? (
                            <Row
                              align="middle"
                              onClick={() => {
                                props.getCheckPermissionHandler(
                                  "interview-add",
                                  (success) => {
                                    if (success) {
                                      setCandidateId({
                                        id: render.id,
                                        name: render.name,
                                      });
                                      props.getCandidateRelatedJobsHandler(
                                        render.id
                                      );
                                      setInterviewName("candidateTable");
                                      setScheduleDrawer(true);
                                    } else {
                                      setOpenPMModal({
                                        open: true,
                                        permission: "Interview Add",
                                      });
                                    }
                                  }
                                );
                              }}
                              onMouseEnter={() => {
                                setHoverKey("1");
                              }}
                              onMouseLeave={() => {
                                setHoverKey("0");
                              }}
                            >
                              <Col
                                style={{
                                  padding: "5px 5px 0px 0px",
                                  transform: "translateY(3px)",
                                }}
                              >
                                <CalendarCheckIcon
                                  color={
                                    hoverKey === "1"
                                      ? "var(--primaryColor)"
                                      : "var(--body-text-color)"
                                  }
                                />
                              </Col>
                              <Col style={{ padding: "5px 5px 0px 5px" }}>
                                <p
                                  style={{
                                    margin: "0px",
                                    color:
                                      hoverKey === "1"
                                        ? "var(--primaryColor)"
                                        : "var(--body-text-color)",
                                  }}
                                >
                                  Schedule Interview
                                </p>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          ),
                      },
                    props.permissions &&
                      props.permissions.find(
                        (data) => data === "candidate-delete"
                      ) && {
                        key: "3",
                        label: (
                          <Row
                            onClick={() => {
                              props.getCheckPermissionHandler(
                                "candidate-delete",
                                (success) => {
                                  if (success) {
                                    SetOpenDeleteModal(true);
                                    setDeleteId(render.id);
                                  } else {
                                    setOpenPMModal({
                                      open: true,
                                      permission: "Candidate Delete",
                                    });
                                  }
                                }
                              );
                            }}
                            onMouseEnter={() => {
                              setDeleteBtnClr("#F83A3A");
                            }}
                            onMouseLeave={() => {
                              setDeleteBtnClr("var(--headings-color)");
                            }}
                          >
                            <Col
                              style={{
                                padding: "5px 5px 0px 0px",
                                transform: "translateY(3px)",
                              }}
                            >
                              <TrashIcon color={deleteBtnClr} />
                            </Col>
                            <Col
                              style={{
                                padding: "10px 5px 0px 5px",
                                color: deleteBtnClr,
                              }}
                            >
                              <p
                                style={{
                                  margin: "0px",
                                  color: deleteBtnClr,
                                }}
                              >
                                Delete
                              </p>
                            </Col>
                          </Row>
                        ),
                      },
                  ]}
                />
              }
            >
              <Link onClick={(e) => e.preventDefault()}>
                <ThreeDotsIcon color={"var(--table-text-color)"} />
              </Link>
            </Dropdown>
          </Col>
        </Row>
      ),
    },
  ];

  const handleProvinceChange = (e) => {
    localStorage.setItem("candidateSort", e);
    let candidateDetailFilter = JSON.parse(
      localStorage.getItem("candidateDetail")
    );

    if (candidateDetailFilter) {
      if (e === "Name_ASC") {
        localStorage.setItem(
          "candidateDetail",
          JSON.stringify({
            current_page: 1,
            ...candidateDetailFilter,
            sortBy: "name",
            sortOrder: "ASC",
          })
        );
        props.getCandidateFilterHandler({
          current_page: 1,
          ...cfilter,
          ...candidateDetailFilter,
          sortBy: "name",
          sortOrder: "ASC",
        });

        /* filter */
        setCFilter({
          current_page: 1,
          ...cfilter,
          ...candidateDetailFilter,
          sortBy: "name",
          sortOrder: "ASC",
        });
      } else if (e === "Name_DESC") {
        localStorage.setItem(
          "candidateDetail",
          JSON.stringify({
            current_page: 1,
            ...candidateDetailFilter,
            sortBy: "name",
            sortOrder: "DESC",
          })
        );
        props.getCandidateFilterHandler({
          current_page: 1,
          ...cfilter,
          ...candidateDetailFilter,
          sortBy: "name",
          sortOrder: "DESC",
        });

        /* filter */
        setCFilter({
          current_page: 1,
          ...cfilter,
          ...candidateDetailFilter,
          sortBy: "name",
          sortOrder: "DESC",
        });
      } else if (e === "Applied_ASC") {
        props.getCandidateFilterHandler({
          current_page: 1,
          ...cfilter,
          ...candidateDetailFilter,
          sortBy: "appliedDate",
          sortOrder: "ASC",
        });

        /* filter */
        setCFilter({
          current_page: 1,
          ...cfilter,
          ...candidateDetailFilter,
          sortBy: "appliedDate",
          sortOrder: "ASC",
        });
        localStorage.setItem(
          "candidateDetail",
          JSON.stringify({
            current_page: 1,
            ...candidateDetailFilter,
            sortBy: "appliedDate",
            sortOrder: "ASC",
          })
        );
      } else if (e === "Applied_DESC") {
        props.getCandidateFilterHandler({
          current_page: 1,
          ...cfilter,
          ...candidateDetailFilter,
          sortBy: "appliedDate",
          sortOrder: "DESC",
        });

        /* filter */
        setCFilter({
          current_page: 1,
          ...cfilter,
          ...candidateDetailFilter,
          sortBy: "appliedDate",
          sortOrder: "DESC",
        });
        localStorage.setItem(
          "candidateDetail",
          JSON.stringify({
            current_page: 1,
            ...candidateDetailFilter,
            sortBy: "appliedDate",
            sortOrder: "DESC",
          })
        );
      }
    } else {
      if (e === "Name_ASC") {
        props.getCandidateFilterHandler({
          current_page: 1,
          ...cfilter,
          sortBy: "name",
          sortOrder: "ASC",
        });
        /* filter */
        setCFilter({
          current_page: 1,
          ...cfilter,
          sortBy: "name",
          sortOrder: "ASC",
        });

        localStorage.setItem(
          "candidateDetail",
          JSON.stringify({
            sortBy: "name",
            current_page: 1,
            sortOrder: "ASC",
          })
        );
      } else if (e === "Name_DESC") {
        props.getCandidateFilterHandler({
          current_page: 1,
          ...cfilter,
          sortBy: "name",
          sortOrder: "DESC",
        });

        /* filter */
        setCFilter({
          current_page: 1,
          ...cfilter,
          sortBy: "name",
          sortOrder: "DESC",
        });
        localStorage.setItem(
          "candidateDetail",
          JSON.stringify({
            current_page: 1,
            sortBy: "name",
            sortOrder: "DESC",
          })
        );
      } else if (e === "Applied_ASC") {
        props.getCandidateFilterHandler({
          current_page: 1,
          ...cfilter,
          sortBy: "appliedDate",
          sortOrder: "ASC",
        });

        /* filter */
        setCFilter({
          current_page: 1,
          ...cfilter,
          sortBy: "appliedDate",
          sortOrder: "ASC",
        });
        localStorage.setItem(
          "candidateDetail",
          JSON.stringify({
            current_page: 1,
            sortBy: "appliedDate",
            sortOrder: "ASC",
          })
        );
      } else if (e === "Applied_DESC") {
        props.getCandidateFilterHandler({
          current_page: 1,
          ...cfilter,
          sortBy: "appliedDate",
          sortOrder: "DESC",
        });

        /* filter */
        setCFilter({
          current_page: 1,
          ...cfilter,
          sortBy: "appliedDate",
          sortOrder: "DESC",
        });
        localStorage.setItem(
          "candidateDetail",
          JSON.stringify({
            sortBy: "appliedDate",
            current_page: 1,
            sortOrder: "DESC",
          })
        );
      }
    }
    // else if (e === "Last_ASC") {
    //   props.getCandidateFilterHandler({
    //     ...cfilter,
    //     sortBy: "lastUpdated",
    //     current_page: 1,
    //     sortOrder: "ASC",
    //   });
    //   /* filter */
    //   setCFilter({
    //     ...cfilter,
    //     sortBy: "lastUpdated",
    //     current_page: 1,
    //     sortOrder: "ASC",
    //   });
    // } else if (e === "Last_DESC") {
    //   props.getCandidateFilterHandler({
    //     ...cfilter,
    //     sortBy: "lastUpdated",
    //     current_page: 1,
    //     sortOrder: "DESC",
    //   });
    //   /* filter */
    //   setCFilter({
    //     ...cfilter,
    //     sortBy: "lastUpdated",
    //     current_page: 1,
    //     sortOrder: "DESC",
    //   });
    // }
  };
  const candidateFilterHandleer = (val) => {
    // let fromWhichPage = localStorage.getItem("fromWhichPage");
    // let fromWhichPage1 = JSON.parse(fromWhichPage);
    // if (
    //   (fromWhichPage1 &&
    //     fromWhichPage1.fromRequirement !== "fromRequirement" &&
    //     fromWhichPage1 &&
    //     fromWhichPage1.fromRecuritment !== "fromRecuritment") ||
    //   !fromWhichPage1
    // ) {
    //   localStorage.setItem(
    //     "fromWhichPage",
    //     JSON.stringify({
    //       candidateDetail: "candidateDetail",
    //     })
    //   );
    // }
    // setCFilter(val);
    // if (
    //   location.state &&
    //   location.state.from &&
    //   location.state.from === "fromRequirement"
    // ) {
    //   localStorage.setItem(
    //     "candidateDetailFromRequirement",
    //     JSON.stringify({
    //       ...val,
    //     })
    //   );
    // } else if (
    //   location.state &&
    //   location.state.from &&
    //   location.state.from === "fromRecuritment"
    // ) {
    //   localStorage.setItem(
    //     "candidateDetailFromRecruitment",
    //     JSON.stringify({
    //       ...val,
    //     })
    //   );
    // } else {
    // }
    let candidateFilterDataLocal = JSON.parse(
      localStorage.getItem("candidateDetail")
    );
    localStorage.setItem(
      "candidateDetail",
      JSON.stringify({
        ...val,
        ...candidateFilterDataLocal,
      })
    );
    props.getCandidateFilterHandler(val, (success) => {
      if (success) {
        closeFilterHandler();
      }
    });
setCFilter({...val})
  };
  return (
    <>
      <Row>
        <Col md={24} className={commonCssStyle.breadcrumbBottomMargin}>
          {location &&
          location.state &&
          location.state.pageFrom === "recuriter" ? (
            <BreadcrumbForCandidate
              pageFrom={"recuriter"}
              name={location.state.title}
              id={location.state.id}
              slide={[1, 2, 3, 4]}
            />
          ) : location &&
            location.state &&
            location.state.pageFrom === "requirement" ? (
            <BreadcrumbForCandidate
              pageFrom={"requirement"}
              name={location.state.title}
              id={location.state.id}
              slide={[1, 2, 3, 4]}
            />
          ) : (
            <BreadcrumbForCandidate pageFrom={"candidate"} slide={[1, 2]} />
          )}
        </Col>
        <Col md={24}>
          {open && (
            <AddCandidateDrawer
              onProfileData={props?.onProfileData}
              onOpen={open}
              onClose={closeHandler}
            />
          )}

          <Filter
            candidateFilterHandleer={candidateFilterHandleer}
            onOpenFilter={openFilter}
            candidateFilterData={props.candidatesFilterData}
            onCloseFilter={closeFilterHandler}
            settindsData={props.settindsData}
          />
          {/* {open && <ResumeParser  />} */}

          <Row wrap={false} align="middle" justify="space-between">
            <Col>
              <Row gutter={[16, 16]}>
                <Col>
                  <h1
                    style={{ marginRight: "10px" }}
                    className={`${commonCssStyle.mainHeadingText} ${commonCssStyle.inttitle}`}
                  >
                    Candidates &#40;{" "}
                    {props.candidatesFilterData &&
                    props.candidatesFilterData.total
                      ? props.candidatesFilterData.total
                      : "0"}{" "}
                    &#41;
                  </h1>
                </Col>
                <Col>
                  <Button
                    onClick={showFilterHandler}
                    className={`${commonStyleCss.filterButton}`}
                    id="candidates-table-filter-btn"
                  >
                    <Row style={{ gap: "11px" }}>
                      <Col>
                        <div style={{ transform: "translateY(2px)" }}>
                          <FilterOutlinedIcon color={"var(--primaryColor)"} />
                        </div>
                      </Col>
                      <Col>
                        <p
                          className={`${commonCssStyle.filterAndSortTextStyle} ${commonCssStyle.marginBottomRemove}`}
                        >
                          Filter
                        </p>
                      </Col>
                    </Row>
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{
                      height: "40px",
                    }}
                    className={` ${commonStyleCss.sortBtn}`}
                    id="candidates-table-sortby-btn"
                  >
                    <Row justify="space-between">
                      <Col>
                        <p style={{ transform: "translateY(2px)" }}>Sort By</p>
                      </Col>
                      <Col>
                        <Select
                          style={{
                            width: "100%",
                            transform: "translateY(3px)",
                          }}
                          onChange={handleProvinceChange}
                          bordered={false}
                          placeholder={
                            <p
                              style={{
                                margin: "0px",
                                color: "var(--primaryColor)",
                              }}
                            >
                              Select
                            </p>
                          }
                          value={
                            localStorage.getItem("candidateSort")
                              ? localStorage.getItem("candidateSort")
                              : "Select"
                          }
                          size="small"
                          className={`${commonStyleCss.filterDrop}  selectInput sortBySelectStyle selectTag selectTag1`}
                        >
                          <Select.Option key="1" value="Name_ASC">
                            Name Ascending
                          </Select.Option>
                          <Select.Option key="2" value="Name_DESC">
                            Name Descending
                          </Select.Option>
                          <Select.Option key="3" value="Applied_ASC">
                            Applied Date Ascending
                          </Select.Option>
                          <Select.Option key="4" value="Applied_DESC">
                            Applied Date Descending
                          </Select.Option>
                          {/* <Select.Option key="5" value="Last_ASC">
                        Last Updated Ascending
                      </Select.Option>
                      <Select.Option key="6" value="Last_DESC">
                        Last Updated Descending
                      </Select.Option> */}
                        </Select>
                      </Col>
                    </Row>
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row justify="end" /* wrap={false} */ style={{ gap: "5px" }}>
                {props.permissions &&
                  props.permissions.find(
                    (data) => data === "candidate-export"
                  ) && (
                    <Col>
                      <Button
                        id="data-export-button"
                        type="primary"
                        className={commonCssStyle.dataExportBtnCandidate}
                        onClick={() => {
                          props.getCheckPermissionHandler(
                            "candidate-export",
                            (success) => {
                              if (success) {
                                props.exportCandidateDetails({
                                  ...cfilter,
                                  selectedId: selectedRowKeys,
                                });
                              } else {
                                setOpenPMModal({
                                  open: true,
                                  permission: "Candidate Export",
                                });
                              }
                            }
                          );
                        }}
                      >
                        Export to Excel
                      </Button>
                    </Col>
                  )}

                {props.permissions &&
                  props.permissions.find(
                    (data) => data === "candidate-add"
                  ) && (
                    <Col>
                      <Button
                        type="primary"
                        className={commonCssStyle.intaddButton}
                        onClick={() => {
                          props.getCheckPermissionHandler(
                            "candidate-add",
                            (success) => {
                              if (success) {
                                showDrawer();
                              } else {
                                setOpenPMModal({
                                  open: true,
                                  permission: "Candidate Add",
                                });
                              }
                            }
                          );

                          // if (!props.ishavepermissions) {
                          //   showDrawer();
                          // } else {
                          //   setOpenPMModal({
                          //     open: true,
                          //     permission: "Candidate Add",
                          //   });
                          // }
                        }}
                        icon={<PlusOutlined />}
                        id="candidates-table-AddCandidate-btn"
                      >
                        Add new Candidate
                      </Button>
                    </Col>
                  )}
              </Row>
            </Col>
          </Row>

          <Col style={{ marginTop: "20px" }} xs={24}>
            <Table
              scroll={{ x: "80vw" }}
              pagination={{
                size: "small",
                current:
                  props.candidatesFilterData &&
                  props.candidatesFilterData.current_page,
                pageSize:
                  props.candidatesFilterData &&
                  props.candidatesFilterData.per_page,
                total:
                  props.candidatesFilterData &&
                  props.candidatesFilterData.total,
                showSizeChanger: false,
                onChange: (props) => {
                  fetchRecords(props);
                },
              }}
              rowKey="key"
              columns={columns}
              dataSource={props.candidatesFilterData.data}
            />
          </Col>
        </Col>
      </Row>

      <Modal
        closable={false}
        title={
          <Row style={{ gap: "289px" }}>
            <Col>
              <p className={commonCssStyle.popUpHeadingText}>
                Delete Candidate
              </p>
            </Col>
            <Col>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  closeDeleteHandler();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
        footer={
          <>
            <Row style={{ gap: "10px" }} justify="end" align="middle">
              <Col>
                <Button
                  type="default"
                  className={commonCssStyle.buttonSettingsSecondary}
                  style={{ height: "48px", fontSize: "16px", width: "181px" }}
                  onClick={() => {
                    closeDeleteHandler();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className={commonCssStyle.buttonSettingsPrimary}
                  onClick={() => {
                    confirmDeleteHandler();
                  }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </>
        }
        open={openDeleteModal}
        // onCancel={closeDeleteHandler}
        onOk={confirmDeleteHandler}
      >
        <Row>
          <Col>
            <p className={commonCssStyle.tableHeadingTextData}>
              Are you sure you want to delete
            </p>
          </Col>
        </Row>
      </Modal>
      {/* if you are adding schedule interview data from candidate table page this component is used and this many props you need to send */}
      <ScheduleInterviewDrawer
        onInterviewName={interviewName} //specifing from schedule drawer from which page the data is comming like example candidateTable, it's noting but modal name
        //onCandidateData={props.candidates}
        onCandidateName={candidateId} //sending id of selected candidate
        onJobData={props.jobsData} //all jobs data we are sending
        onOpenSchedileInterview={scheduleDrawer} // used to open the drawer
        onCloseSchedileInterview={closeDrawerHandler} // used to close the drawer
      />

      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    candidates: state.candidates.candidates,
    candidatesFilterData: state.candidates.candidatesFilter,
    settindsData: state.settings.settings,
    jobsData: state.requirements.requirements,
    permissions: state.authpermissions.permissions,
    settings: state.settings.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCandidateDetailsHandler: () => {
      dispatch(getCandidatesAction());
    },
    deleteCandidateDetailsHandler: (id, callback) => {
      dispatch(deleteCandidatesAction(id, callback));
    },
    putCandidateTemplateHandler: (data, type, callback) => {
      dispatch(putCandidatesAction(data, type, callback));
    },
    getCandidateFilterHandler: (val, callback) => {
      dispatch(getCandidatesFilterAction(val, callback));
    },
    getSettingsWorkLocationHandler: () => {
      dispatch(getSettingsWorklocationAction());
    },
    exportCandidateDetails: (val, callback) => {
      dispatch(exportCandidatesDetaisAction(val));
    },
    getJobNameHandler: () => {
      dispatch(getRequirementsAction());
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
    getCandidateRelatedJobsHandler: (val) => {
      dispatch(getCandidatesJobAction(val));
    },
    activeMenuKeyHandle: (data) => {
      dispatch(activeMenuKeySlice(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateDetailsTable);
