import axios from "axios";
import moment from "moment";
import { message } from "antd";
import { REFRESH_URL } from "./apipath";
import { refreshReducer, refreshTokenSlice } from "./Slice/AuthenticationSlice";

// import { useDispatch } from "react-redux";

// import { refreshAction } from "../Store/Action/AuthenticationAction";
// import { refreshReducer } from "./Slice/AuthenticationSlice";
let apiURL = process.env.REACT_APP_API_PATH;

let axios_instance = axios.create({
  baseURL: apiURL,
  timeout: 65000,
});
// axios_instance.interceptors.response.use((response) => {
//   return response;
// });

axios_instance.interceptors.response.use(
  (config) => {
    let token = localStorage.getItem("token");
    let exp = localStorage.getItem("expires_in");
    let date = localStorage.getItem("date");

    if (token && date) {
      var min = moment().diff(moment(new Date(date)), "minutes");
      if (exp && min > parseInt(exp) - 30) {
        axios({
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          url: apiURL + REFRESH_URL,
          data: {
            token,
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(response.status);
            } else {
              setTimeout(() => {
                localStorage.setItem(
                  "token",
                  response.data.api_key.original.token
                );
                localStorage.setItem(
                  "expires_in",
                  response.data.api_key.original.expires_in
                );
                // localStorage.setItem("activeMenu", "/dashboard");
                localStorage.setItem("date", new Date());
                refreshTokenSlice(response.data);
                window.location.reload();
              }, 200);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

class IntellRecutAPI {
  invoke = (path, method, callback, data, params) => {
    let config = {
      method: method,
      url: path,
    };
    if (data !== undefined) {
      config["data"] = data;
    }
    if (params !== undefined) {
      config["params"] = params;
    }
    if (path !== "login") {
      let contentType = "application/json";

      const token = localStorage.getItem("token");

      if (
        path === "profile/profile_update" ||
        (path === "settings" && method === "post") ||
        (path === "candidates/uploads" && method === "post") ||
        (data && path === "candidates/" + data.id && method === "post") ||
        (path === "candidates" && method === "post") ||
        (data && path === "client/" + data.id && method === "post") ||
        (path === "client" && method === "post") ||
        (data && path === "employees/" + data.id && method === "post") ||
        (path === "employees" && method === "post") ||
        (path === "employee_documents" && method === "post") ||
        (path === "job_filter" && method === "post") ||
        (path === "jobs" && method === "post") ||
        (data && path === "jobs/" + data.id && method === "post") ||
        (path === "import/candidate" && method === "post") ||
        (data && path === "recruitment/" + data.id && method === "post") ||
        (path === "import/job" && method === "post") ||
        (path === "import/company" && method === "post") ||
        (path === "/public_jobs/apply" && method === "post")
      ) {
        contentType = "multipart/form-data";
      }
      config["headers"] = {
        Authorization: "Bearer " + token,
        "Content-Type": contentType,
        TimeZone: new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1],
        IpAddress: "",
        "Access-Control-Allow-Origin": "*",
      };
    } else {
      config["headers"] = { "Access-Control-Allow-Origin": "*" };
    }

    axios_instance
      .request(config)
      .then((res) => {
        callback(res.data, true, res.status);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
          message
            .loading("Session expired. Redirecting you to login page.")
            .then(() => window.location.reload());
        } else if (
          err.response &&
          err.response.status === 403 &&
          path !== "login"
        ) {
          window.location.href = "/";
          message.destroy();
          message
            .loading("Session expired. Redirecting you to login page.", 1)
            .then(() => window.location.reload());
        } else if (err.response && err.response.status === 500) {
          callback(err.response.data, true, err.response.status);
        } else {
          callback(err.response.data, true, err.response.status);
        }
      });
  };
}

export default IntellRecutAPI;
