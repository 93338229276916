import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  emailTemplates: [],
  emailTemplate: null,
};
export const masterEmailTemplatedSlice = createSlice({
  name: "emailTemplates",
  initialState,
  reducers: {
    getEmailTemplatesSlice(state, action) {
      state.emailTemplates = action.payload.result;
    },
    getEmailTemplateSlice(state, action) {
      state.emailTemplate = action.payload.result;
    },
    postEmailTemplateSlice(state, action) {
      state.emailTemplates = [...state.emailTemplates, action.payload.result];
    },
    putEmailTemplatesSlice(state, action) {
      state.emailTemplates = state.emailTemplates.map((data) => {
        if (data.id === action.payload.result.id) {
          return action.payload.result;
        } else {
          return data;
        }
      });
    },
    deleteEmailTemplateSlice(state, action) {
      state.emailTemplates = state.emailTemplates.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});
export const {
  getEmailTemplatesSlice,
  getEmailTemplateSlice,
  postEmailTemplateSlice,
  putEmailTemplatesSlice,
  deleteEmailTemplateSlice,
} = masterEmailTemplatedSlice.actions;
export default masterEmailTemplatedSlice.reducer;
