import { Form, Select, Drawer, Row, Col, Button, DatePicker } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  filterStatusSlice,
  getTaskAndInterviewFilterData,
  getTaskStatusVarSlice,
  loaderSlice,
} from "../../../Reusable/Store/Slice/TaskAndCalendarSlice";
import { CloseIcon, FilterOutlinedIcon } from "../../IconsComp/Icons";
import commonStyles from "../../UI/CommonCss.module.css";

function TaskFilter(props) {
  const [form] = useForm();
  const [dateValue, setDateValue] = useState("");
  useEffect(() => {
    // if (!props.taskStatus) {
    //   let localFilterDataForInterview = localStorage.getItem(
    //     "interviewFilterData"
    //   );
    //   if (props.filterStatusVar && !localFilterDataForInterview) {
    //     form.resetFields();
    //   }
    // } else {
    //   let localFilterDataForTask = localStorage.getItem("taskFilterData");

    //   if (props.filterStatusVar && !localFilterDataForTask) {
    //     form.resetFields();
    //   }
    // }
    if (props.filterStatusVar) {
      form.resetFields();
    }
  }, [props.filterStatusVar]);
  useEffect(() => {
    form.resetFields();
    if (!props.taskStatus) {
      let localFilterDataForInterview = localStorage.getItem(
        "interviewFilterData"
      );
      if (localFilterDataForInterview) {
        let data = JSON.parse(localFilterDataForInterview);
        form.setFieldsValue({
          interviewDate: data.interviewDate ? moment(data.interviewDate) : "",
          date: data.date,
          clientId: data.clientId,
          jobId: data.jobId,
          candidateId: data.candidateId,
          status: data.status,
          interviewType: data.interviewType,
        });
      }
    } else if (props.taskStatus) {
      let localFilterDataForTask = localStorage.getItem("taskFilterData");
      if (localFilterDataForTask) {
        let data = JSON.parse(localFilterDataForTask);
        form.setFieldsValue({
          assignedBy: data.assignedBy,
          assignedTo: data.assignedTo,
          status: data.status,
          candidateId: data.candidateId,
          clientId: data.clientId,
          jobId: data.jobId,
          date: data.date,
          startDate: data.startDate ? moment(data.startDate) : "",
          dueDate: data.dueDate ? moment(data.dueDate) : "",
          taskType: data.taskType,
        });
      }
    }
  }, [props.taskStatus]);
  const dateFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const [filterDatas, setFilterDatas] = useState({
    clientId: "",
    jobId: "",
    candidateId: "",
    assignedTo: "",
    assignedBy: "",
  });
  const onSubmit = (val) => {
    if (dateValue === "custom") {
      let dateFrom = moment(val.customDate[0]).format("YYYY/MM/DD");
      let dateTo = moment(val.customDate[1]).format("YYYY/MM/DD");
      delete val.customDate;
      props.getFilterStatusHandler(true); // used for set filter status using this we can display data in calander conditionaly
      props.onFilterData({
        ...val,
        current_page: 1,
        dateFrom,
        dateTo,
        startDate: val.startDate && moment(val.startDate).format("YYYY/MM/DD"),
        dueDate: val.dueDate && moment(val.dueDate).format("YYYY/MM/DD"),
        interviewDate:
          val.interviewDate && moment(val.interviewDate).format("YYYY/MM/DD"),
        type: props.taskStatus ? "task" : "interview",
      });
      props.getTaskAndInterviewFilterData({
        ...val,
        current_page: 1,
        dateFrom,
        dateTo,
        startDate: val.startDate && moment(val.startDate).format("YYYY/MM/DD"),
        dueDate: val.dueDate && moment(val.dueDate).format("YYYY/MM/DD"),
        interviewDate:
          val.interviewDate && moment(val.interviewDate).format("YYYY/MM/DD"),
        type: props.taskStatus ? "task" : "interview",
      });
      props.onFilterClose();
    } else {
      props.getFilterStatusHandler(true); // used for set filter status using this we can display data in calander conditionaly
      props.onFilterData({
        ...val,
        type: props.taskStatus ? "task" : "interview",
        startDate: val.startDate && moment(val.startDate).format("YYYY/MM/DD"),
        dueDate: val.dueDate && moment(val.dueDate).format("YYYY/MM/DD"),
        interviewDate:
          val.interviewDate && moment(val.interviewDate).format("YYYY/MM/DD"),
        current_page: 1,
      });
      props.getTaskAndInterviewFilterData({
        ...val,
        type: props.taskStatus ? "task" : "interview",
        startDate: val.startDate && moment(val.startDate).format("YYYY/MM/DD"),
        dueDate: val.dueDate && moment(val.dueDate).format("YYYY/MM/DD"),
        interviewDate:
          val.interviewDate && moment(val.interviewDate).format("YYYY/MM/DD"),
        current_page: 1,
      });
      props.onFilterClose();
    }
    props.getTaskStatusVarHandler(false);
  };

  return (
    <>
      <Drawer
        open={props.onFilterOpen}
        className="modalModified"
        closable={false}
        extra={
          <span
            onClick={() => {
              props.getTaskStatusVarHandler(false);
              props.onFilterClose();
            }}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)"} />
          </span>
        }
        title={
          <>
            <Row>
              <Col>
                <FilterOutlinedIcon color={"var(--primaryColor)"} />
              </Col>
              <Col>
                <p
                  style={{ fontSize: "20px", margin: "0px 0px 0px 5px" }}
                  className={commonStyles.filterHeadingName}
                >
                  Filter
                </p>
              </Col>
            </Row>
          </>
        }
        width={503}
        height={48}
      >
        <Row>
          <Col md={24} style={{ padding: "0px 20px 150px 20px" }}>
            <Form
              onFinish={(val) => onSubmit(val)}
              layout="vertical"
              form={form}
            >
              {/* <Row align="middle" style={{ gap: "17px", marginBottom: "20px" }}>
                <Col>
                  <Radio.Group
                    onChange={(val) => {
                      setTaskStatus(val.target.value);
                      props.onTaskStatus(val.target.value);
                    }}
                    value={props.taskStatus}
                    className="radioBtnText"
                  >
                    <Radio value={true}>Regular Task</Radio>
                    <Radio value={false}>Interview</Radio>
                  </Radio.Group>
                </Col>
              </Row> */}

              {!props.taskStatus && (
                <Form.Item label="Interview Date" name="interviewDate">
                  <DatePicker
                    getPopupContainer={(trigger) => trigger.parentElement}
                    format={dateFormate}
                    style={{
                      width: "100%",
                      backgroundColor: "var(--input-color)",
                    }}
                  />
                </Form.Item>
              )}

              <Form.Item label="Created Date" name="date">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  onChange={(value) => {
                    setDateValue(value);
                  }}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option value="today">Today</Select.Option>
                  <Select.Option value="thisWeek">This Week</Select.Option>
                  <Select.Option value="previousWeek">
                    Previous Week
                  </Select.Option>
                  <Select.Option value="thisMonth">This Month</Select.Option>
                  <Select.Option value="custom">Custom</Select.Option>
                </Select>
              </Form.Item>

              {dateValue && dateValue === "custom" && (
                <Form.Item label="Custom Date" name="customDate">
                  <DatePicker.RangePicker
                    style={{
                      width: "100%",
                      backgroundColor: "var(--input-color)",
                    }}
                  />
                </Form.Item>
              )}

              <Form.Item label="Company" name="clientId">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  allowClear
                  notFoundContent={null}
                  showSearch
                  onKeyUp={(val) => {
                    setFilterDatas({
                      clientId: val.target.value,
                    });
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {filterDatas.clientId &&
                    filterDatas.clientId !== " " &&
                    filterDatas.clientId.length > 0 &&
                    props.onCompanyData &&
                    props.onCompanyData.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          /* value={data.id} */ value={data.clientName}
                        >
                          {data.clientName}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Job ID" name="jobId">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  allowClear
                  notFoundContent={null}
                  onKeyUp={(val) => {
                    setFilterDatas({
                      jobId: val.target.value,
                    });
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {filterDatas.jobId &&
                    filterDatas.jobId !== " " &&
                    filterDatas.jobId.length > 0 &&
                    props.onJobData &&
                    props.onJobData.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          /* value={data.id} */ value={data.title}
                        >
                          {data.title}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Candidate" name="candidateId">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  allowClear
                  notFoundContent={null}
                  onKeyUp={(val) => {
                    setFilterDatas({
                      candidateId: val.target.value,
                    });
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {filterDatas.candidateId &&
                    filterDatas.candidateId !== " " &&
                    filterDatas.candidateId.length > 0 &&
                    props.onCandidateData &&
                    props.onCandidateData.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          /* value={data.id} */ value={data.name}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              {props.taskStatus && (
                <Form.Item label="Assign to" name="assignedTo">
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    allowClear
                    notFoundContent={null}
                    placeholder="Select assign to"
                    showSearch
                    onKeyUp={(val) => {
                      setFilterDatas({
                        assignedTo: val.target.value,
                      });
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {filterDatas.assignedTo &&
                      filterDatas.assignedTo !== " " &&
                      filterDatas.assignedTo.length > 0 &&
                      props.employeesData &&
                      props.employeesData &&
                      props.employeesData.map((data, index) => {
                        return (
                          <Select.Option
                            key={index}
                            // value={data.id}
                            value={`${data.firstName} ${data.lastName}`}
                          >{`${data.firstName} ${data.lastName}`}</Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              )}
              {props.taskStatus && (
                <Form.Item label="Assign by" name="assignedBy">
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    allowClear
                    notFoundContent={null}
                    onKeyUp={(val) => {
                      setFilterDatas({
                        assignedBy: val.target.value,
                      });
                    }}
                    placeholder="Select assign by"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {filterDatas.assignedBy &&
                      filterDatas.assignedBy !== " " &&
                      filterDatas.assignedBy.length > 0 &&
                      props.employeesData &&
                      props.employeesData.map((data, index) => {
                        return (
                          <Select.Option
                            key={index}
                            // value={data.id}
                            value={`${data.firstName} ${data.lastName}`}
                          >{`${data.firstName} ${data.lastName}`}</Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              )}

              <Form.Item label="Status" name="status">
                {props.taskStatus ? (
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    allowClear
                  >
                    <Select.Option key="1" value="pending">
                      Pending
                    </Select.Option>
                    <Select.Option key="2" value="completed">
                      Completed
                    </Select.Option>
                  </Select>
                ) : (
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    allowClear
                  >
                    <Select.Option key="1" value="pending">
                      Pending
                    </Select.Option>
                    <Select.Option key="2" value="completed">
                      Completed
                    </Select.Option>
                    {/* <Select.Option key="3" value="Rescheduled by Candidate">
                      Rescheduled by Candidate
                    </Select.Option>
                    <Select.Option key="4" value="Rescheduled by Client">
                      Rescheduled by Client
                    </Select.Option> */}
                    <Select.Option key="4" value="Candidate No Show">
                      Candidate No Show
                    </Select.Option>
                    <Select.Option key="5" value="reschedule-candidate-pending">
                      {/* Reschedule Candidate-Pending */}
                      Rescheduled by Candidate Reschedule Pending
                    </Select.Option>
                    <Select.Option
                      key="6"
                      value="reschedule-candidate-completed"
                    >
                      {/* Reschedule Candidate-Completed */}
                      Rescheduled by Candidate Reschedule Completed
                    </Select.Option>
                    <Select.Option key="7" value="reschedule-client-pending">
                      {/* Rescheduled Client-Pending */}
                      Rescheduled by Client Reschedule Pending
                    </Select.Option>
                    <Select.Option key="8" value="reschedule-client-completed">
                      {/* Reschedule Client-Completed */}
                      Rescheduled by Client Reschedule Completed
                    </Select.Option>
                  </Select>
                )}
              </Form.Item>

              {!props.taskStatus && (
                <Form.Item label="Interview Type" name="interviewType">
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    allowClear
                  >
                    <Select.Option key="1" value="web">
                      Virtual
                    </Select.Option>
                    <Select.Option key="2" value="office">
                      In-Office
                    </Select.Option>
                    <Select.Option key="3" value="telephonic">
                      Telephonic
                    </Select.Option>
                  </Select>
                </Form.Item>
              )}

              {/* {!props.taskStatus && (
                <Form.Item label="Reschedule Type" name="rescheduleType">
                  <Select allowClear>
                    <Select.Option key="1" value="pending">
                      Pending
                    </Select.Option>
                    <Select.Option key="2" value="completed">
                      Completed
                    </Select.Option>
                  </Select>
                </Form.Item>
              )} */}

              {props.taskStatus && (
                <Row justify="space-between">
                  <Col md={11}>
                    <Form.Item name="startDate" label="Start Date">
                      <DatePicker
                        placeholder="Select date"
                        format={dateFormate}
                        style={{ width: "100%" }}
                        className={`${commonStyles.datePickerBgColor} `}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={11}>
                    <Form.Item name="dueDate" label="End Date">
                      <DatePicker
                        placeholder="Select date"
                        format={dateFormate}
                        style={{ width: "100%" }}
                        className={`${commonStyles.datePickerBgColor} `}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              {props.taskStatus && (
                <Form.Item label="Task Name" name="taskType">
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    allowClear
                    placeholder="Select Task Name"
                  >
                    <Select.Option value="candidate" key="1">
                      Candidate
                    </Select.Option>
                    <Select.Option value="job" key="2">
                      Job
                    </Select.Option>
                    <Select.Option value="company" key="3">
                      Company
                    </Select.Option>
                  </Select>
                </Form.Item>
              )}
            </Form>
          </Col>
          <Col
            md={24}
            className={commonStyles.footerShadow}
            style={{ width: "503px" }}
          >
            <Row justify="end" style={{ gap: "17px" }}>
              <Col>
                <Button
                  className={commonStyles.buttonSettingsSecondary}
                  type="default"
                  onClick={() => {
                    props.loaderHandler(false);
                    props.onFilterData({
                      type: "interview",
                      current_page: 1,
                    });
                    // setTaskStatus(true);
                    props.onTaskStatus(false);
                    props.getFilterStatusHandler(false); // used for set filter status using this we can display data in calander conditionaly
                    // props.getFilterStatusHandler(true); // used for set filter status using this we can display data in calander conditionaly
                    props.onCalendarViewStatus();
                    props.getTaskStatusVarHandler(false);
                    props.onFilterClose();
                    setDateValue("");
                    form.resetFields();
                    // if (props.taskStatus) {
                    localStorage.removeItem("taskFilterData");
                    localStorage.removeItem("interivewSort");
                    localStorage.removeItem("taskSort");
                    // } else {
                    localStorage.removeItem("interviewFilterData");
                    // }
                  }}
                >
                  Reset Filter
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    if (props.taskStatus) {
                      let taskFilterData = JSON.parse(
                        localStorage.getItem("taskFilterData")
                      );
                      localStorage.setItem(
                        "taskFilterData",
                        JSON.stringify({
                          current_page: 1,
                          ...taskFilterData,
                          ...form.getFieldsValue(),
                        })
                      );
                    } else {
                      let interviewFilterData = JSON.parse(
                        localStorage.getItem("interviewFilterData")
                      );
                      localStorage.setItem(
                        "interviewFilterData",
                        JSON.stringify({
                          current_page: 1,
                          ...interviewFilterData,
                          ...form.getFieldsValue(),
                        })
                      );
                    }
                    form.submit();
                  }}
                  htmlType="button"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    filterStatus: state.taskAndCalendar.filterStatus,
    settindsData: state.settings.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getFilterStatusHandler: (data) => {
      dispatch(filterStatusSlice(data));
    },
    getTaskAndInterviewFilterData: (data) => {
      dispatch(getTaskAndInterviewFilterData(data));
    },
    getTaskStatusVarHandler: (data) => {
      dispatch(getTaskStatusVarSlice(data));
    },
    loaderHandler: (data) => {
      dispatch(loaderSlice(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskFilter);
