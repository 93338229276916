import { Breadcrumb } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import commonCssStyle from "../../UI/CommonCss.module.css";
import PermissionMessage from "../../UI/PermissionMessage";
function BreadcrumbForItoC(props) {
  const navigate = useNavigate();
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <p
            onClick={() => {
              props.getCheckPermissionHandler("dashboard", (success) => {
                if (success) {
                  navigate("/dashboard");
                } else {
                  setOpenPMModal({
                    open: true,
                    permission: "Dashboard",
                  });
                }
              });
            }}
            className={` ${commonCssStyle.breadcrumbText} ${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove}`}
          >
            Home
          </p>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <p
            onClick={() => {
              props.getCheckPermissionHandler("interview-detail", (success) => {
                if (success) {
                  if (props.name) {
                    navigate("/taskCalendar", {
                      state: {
                        from: "candidateDetails",
                        id: props.name.id,
                        interviewDetals: props.name,
                      },
                    });
                  }
                } else {
                  setOpenPMModal({
                    open: true,
                    permission: "Interview Details",
                  });
                }
              });
            }}
            className={` ${commonCssStyle.breadcrumbText} ${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove}`}
          >
            {props.name && props.name.title
              ? `Interview List view (${props.name.title})`
              : "Interview List view"}
          </p>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <p
            className={` ${commonCssStyle.breadcrumbText} ${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove}`}
          >
            Candidate Details
          </p>
        </Breadcrumb.Item>
      </Breadcrumb>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
          props.setIsHavepermissionsHandler(false);
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbForItoC);
