import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
} from "antd";
import React, { useEffect } from "react";
import styles from "../../../Screens/ClientCompany/ClientCompany.module.css";
import commonStyles from "../../../UI/CommonCss.module.css";
import pdf from "../../../../Assets/Image/pdf.png";
import word from "../../../../Assets/Image/word.png";

//flag import

import "antd-country-phone-input/dist/index.css";
import "flagpack/dist/flagpack.css";

import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import {
  CurrencyInr,
  UploadIcon,
  CloseIcon,
  DownLoadIcon,
} from "../../../IconsComp/Icons";
import { useRef } from "react";
import moment from "moment";
import { connect } from "react-redux";
//flag import end

const EditBillingAddressDrawer = (props) => {
  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [frist, setFrist] = useState(true);

  const [form] = Form.useForm();
  const [filesData, setFilesData] = useState(null);
  // const [fileName,setFileName]= useState(props.client.agreement);
  const [uploadFile, setUploadFile] = useState();
  let btnref = useRef(null);
  const [fileType, setFileType] = useState("");
  const initvalues = {
    billingCity: "",
    billingState: "",
  };
  const onChangeCountry = (value, data, type) => {
    props.onCoutriesData({ id: data.id, type: "state" });

    if (type !== 1) {
      form.setFieldsValue({
        billingCity: initvalues.billingCity,
        billingState: initvalues.billingState,
      });
    }
  };
  const onChangeState = (value, data, type) => {
    if (type !== 1) {
      form.setFieldsValue({
        billingCity: initvalues.billingCity,
      });
    }
    props.onCityData({ id: data.id, type: "city" });
  };
  useEffect(() => {
    setTimeout(() => {
      if (props.client && props.client.billingCountry) {
        let _country = props.countriesData.find((data) => {
          return data.name === props.client.billingCountry;
        });
        onChangeCountry(_country && _country, _country, 1);
      }
    }, 200);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (props.client && props.client.billingState && frist) {
        let _stateData =
          props.onStatesData &&
          props.onStatesData.find((x) => {
            return x.name === props.client.billingState;
          });

        onChangeState(_stateData, _stateData, 1);
        setFrist(false);
      }
    }, 300);
  }, [props.onStatesData]);
  const handleChange = (info) => {
    if (!(info.target.files[0].size <= 5000000)) {
      message.warning("This file should be less than 5 MB", 3);
      return;
    }
    setFilesData(info.target.files[0]);
    setFileType(info.target.files[0].type);
    setUploadFile(info.target.files[0].name);
  };
  const onFinish = (val) => {
    var data = {
      ...val,
      id: props.client.id,

      agreement: filesData,
      validityStartDate: moment(val.validityStartDate).format("YYYY-MM-DD"),
      validityEndDate: moment(val.validityEndDate).format("YYYY-MM-DD"),
    };
    if (!data.agreement) delete data.agreement;

    props.onSubmit(data);
  };
  return (
    <>
      <Drawer
        className={"modalModified"}
        closable={false}
        extra={
          <div
            onClick={() => {
              form.resetFields();
              props.onCloseBillingAddressModal();
            }}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
          </div>
        }
        footer={false}
        style={{
          paddingBottom: "0px",
        }}
        title={
          <p
            style={{ fontSize: "20px", margin: "0px" }}
            className={`${commonStyles.modalHeading}`}
          >
            {!props.client ? "Add Company" : "Edit Billing Details"}
          </p>
        }
        width={726}
        height={48}
        open={props.onOpenBillingAddressModal}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          form={form}
          initialValues={
            props.client && {
              gstin: props.client.gstin,
              pan: props.client.pan,
              billingAddress: props.client.billingAddress,
              billingCity: props.client.billingCity,
              billingState: props.client.billingState,
              billingCountry: props.client.billingCountry,
              billingPincode: props.client.billingPincode,
              agreement: props.client.agreement,
              validityStartDate:
                props.client.validityStartDate &&
                moment(props.client.validityStartDate),
              validityEndDate:
                props.client.validityEndDate &&
                moment(props.client.validityEndDate),
              feesType: props.client.feesType,
              fees: props.client.fees,
              paymentTerms: props.client.paymentTerms,
              replacementClause: props.client.replacementClause,
            }
          }
          layout="vertical"
          onFinish={onFinish}
        >
          <Row
            className={styles.modalRowStyling}
            style={{ padding: "0px 20px 80px 20px" }}
          >
            <Col flex={1}>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="GST"
                    name="gstin"
                    rules={[
                      {
                        required: false,
                        message: "Please enter gst number",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Z0-9\s]+$/,
                      //   message: "Invalid GSTN",
                      // },

                      // {
                      //   min: 15,
                      //   message:
                      //     "GSTN number Should be in 15-digit alpha-numeric format",
                      // },
                      // {
                      //   pattern: /.*\S.*/,
                      //   message: "White space are not allowed",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter gst number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="PAN"
                    name="pan"
                    rules={[
                      {
                        required: false,
                        message: "Please enter pan number",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Z0-9\s]+$/,
                      //   message: "Invalid PAN",
                      // },

                      // {
                      //   min: 10,
                      //   message:
                      //     "PAN number Should be in 10-digit alpha-numeric format",
                      // },
                      // {
                      //   pattern: /.*\S.*/,
                      //   message: "White space are not allowed",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter pan number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Billing Address"
                    name="billingAddress"
                    rules={[
                      {
                        required: true,
                        message: "Please enter billing address ",
                      },
                    ]}
                  >
                    <TextArea rows={4} placeholder="Enter billing address" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Country"
                    name="billingCountry"
                    rules={[
                      {
                        required: true,
                        message: "Please select country",
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      showSearch
                      onChange={onChangeCountry}
                      placeholder="Select country"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={
                        props.countriesData &&
                        props.countriesData.map((item) => ({
                          value: item.name,
                          id: item.id,
                          label: item.name,
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="State"
                    name="billingState"
                    rules={[
                      {
                        required: true,
                        message: "Please select state",
                      },
                    ]}
                  >
                    {props.onStatesData && props.onStatesData.length === 0 ? (
                      <Input placeholder="Enter state" />
                    ) : (
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        onChange={onChangeState}
                        showSearch
                        placeholder="Select state"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {props.onStatesData &&
                          props.onStatesData.map((data, index) => {
                            return (
                              <Select.Option
                                key={index}
                                value={data.name}
                                id={data.id}
                              >
                                {data.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="City"
                    name="billingCity"
                    rules={[
                      {
                        required: true,
                        message: "City Required",
                      },
                    ]}
                  >
                    {props.onCity && props.onCity.length === 0 ? (
                      <Input placeholder="Enter city" />
                    ) : (
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        showSearch
                        placeholder="Please select city"
                      >
                        {props.onCity &&
                          props.onCity.map((data, index) => {
                            return (
                              <Select.Option value={data.name} key={index}>
                                {data.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="ZIP Code"
                    name="billingPincode"
                    rules={[
                      {
                        required: true,
                        message: "Please enter zip code",
                      },
                      // {
                      //   pattern: /^\d{6}?$/,
                      //   message: "Invalid Pincode",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter zip code" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col
            style={{ width: "726px" }}
            xs={24}
            md={24}
            className={commonStyles.footerShadow}
          >
            <Row justify="end" style={{ gap: "19px" }}>
              <Col>
                <Button
                  onClick={() => {
                    props.onCloseBillingAddressModal();
                    form.resetFields();
                  }}
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                  className={commonStyles.buttonSettingsSecondary}
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button
                  onClick={() => {
                    form.submit();
                  }}
                  htmlType="button"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  {!props.client ? "Add Company" : "Save"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    settindsData: state.settings.settings,
  };
};

export default connect(mapStateToProps)(EditBillingAddressDrawer);
