import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Tag,
} from "antd";
import { useLocation } from "react-router";
import {
  CloseIcon,
  MinusIcons,
  PlusForButtonIcon,
} from "../../IconsComp/Icons";
import commonCssStyles from "../../UI/CommonCss.module.css";
import ReactQuill from "react-quill";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AadTagsForSkills from "../../UI/AadTagsForSkills";
import UploadJDEdit from "../Recruitment/UploadJDEdit";
import word from "../../../Assets/Image/word.png";
import pdf from "../../../Assets/Image/pdf.png";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import { getTagsAction } from "../../../Reusable/Store/Action/Master/MasterTagsAction";
import { connect } from "react-redux";

function JobInfoEditDrawer(props) {
  const [value, setValue] = useState("");
  const [commercials, setCommercials] = useState([]);
  const [jDFileData, setJDFileData] = useState(null);
  const [optionalSkillsData, setOptionalSkillsData] = useState([]);
  const [optionalSkillsTags, setOptionalSkillsTags] = useState([]);
  const [hiringValue, setHiringValue] = useState("external");
  const [newSkillsTag, setNewSkillsTag] = useState([]);

  const location = useLocation();
  const { Option } = Select;

  const modules = {
    toolbar: [
      ["bold"],
      ["italic"],
      ["underline"],
      [{ list: "bullet" }],
      [{ list: "ordered" }],
      [{ align: [] }],
      ["link"],
      // ["image"],
      // ["video"],
      ["code-block"],
    ],
  };
  const [form] = Form.useForm();
  const onFinish = (val) => {
    if (props.modalNameHandler === "jobLocation") {
      const data = {
        ...val,
        id: location.state.id,
        optionalSkills:
          optionalSkillsData && optionalSkillsData.length > 0
            ? optionalSkillsData
            : [],
      };
      props.onSubmit(data);
    } else {
      const data = {
        ...val,
        id: location.state.id,
        jdFile: jDFileData ? jDFileData[0] : null,
        isJDremoved: jDFileData ? 0 : 1,
        optionalSkills:
          val.optionalSkills && val.optionalSkills.length > 0
            ? val.optionalSkills
            : [],
      };

      props.onSubmit(data);
    }
  };

  const onHiringValueChange = (e) => {
    setHiringValue(e.target.value);
  };

  const onChangeCompany = (data) => {
    let companydata = props.clients && props.clients.find((x) => x.id == data);
    form.setFieldsValue({
      standardCommission: "",
      settlementTerms: companydata.paymentTerms,
    });
    setCommercials(companydata.fees);
  };

  const onChangeJobDesc = (val) => {
    form.setFieldsValue({
      jobDescription: val,
    });
  };

useEffect(()=>{
  setHiringValue(props.requiremtIdData&&props.requiremtIdData.hiringType)
},[])


  useEffect(() => {
    setOptionalSkillsData(
      props.requiremtIdData && props.requiremtIdData.optionalSkills
        ? props.requiremtIdData.optionalSkills
        : []
    );
  }, [props.requiremtIdData]);

  useEffect(() => {
    if (props.requiremtIdData) {
      if (props.requiremtIdData.mandatorySkills) {
        setNewSkillsTag(props.requiremtIdData.mandatorySkills);
      }
      if (props.requiremtIdData.optionalSkills) {
        setOptionalSkillsTags(props.requiremtIdData.optionalSkills);
      }
      form.setFieldsValue({
        locationDetails: props.requiremtIdData.locationDetails,
        areaDetails: props.requiremtIdData.areaDetails,
        workMode: props.requiremtIdData.workMode,
        experienceFromMonth: props.requiremtIdData.experienceFromMonth,
        department: props.requiremtIdData.department,
        workDuration: props.requiremtIdData.workDuration,
        maxSalary: props.requiremtIdData.maxSalary,
        minSalary: props.requiremtIdData.minSalary,
        currency:
          props.requiremtIdData.currency +
          "-" +
          props.requiremtIdData.currencyText,
        jobCode: props.requiremtIdData.jobCode,
        priority: props.requiremtIdData.priority,
        title: props.requiremtIdData.title,
        jobDescription: props.requiremtIdData.jobDescription,
        experienceToMonth: props.requiremtIdData.experienceToMonth,
        settlementTerms: props.requiremtIdData.settlementTerms,
        standardCommission: props.requiremtIdData.standardCommission,
        mandatorySkills:
          props.requiremtIdData.mandatorySkills &&
          props.requiremtIdData.mandatorySkills,
        optionalSkills: props.requiremtIdData.optionalSkills,
        salaryMode: props.requiremtIdData.salaryMode,
        replacementClause: props.requiremtIdData.replacementClause,
        clientId: props.requiremtIdData.clientId,
        billingValue: props.requiremtIdData.billingValue,
        jobType: props.requiremtIdData.jobType,
        makePublic: props.requiremtIdData.makePublic,
        // hiringType: props.requiremtIdData.hiringType,
      });
    }
  }, [props.requiremtIdData]);

  useEffect(() => {
    props.getTagHandler();
  }, []);

  return (
    <Drawer
      closable={false}
      className={"modalModified"}
      extra={
        <div
          onClick={() => {
            props.onCloseJobInfo();
          }}
          className={commonCssStyles.coursorPointer}
        >
          <CloseIcon color={"var(--icons-color)"} />
        </div>
      }
      footer={false}
      width={726}
      title={
        <p
          style={{ fontSize: "20px", margin: "0px" }}
          className={`${commonCssStyles.modalHeading}`}
        >
          Edit Job Info
        </p>
      }
      open={props.openJobInfoDrawer}
    >
      <Form
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
        onFinish={onFinish}
        layout="vertical"
        form={form}
        initialValues={
          props.requiremtIdData && {
            locationDetails: props.requiremtIdData.locationDetails,
            areaDetails: props.requiremtIdData.areaDetails,
            workMode: props.requiremtIdData.workMode,
            experienceFromMonth: props.requiremtIdData.experienceFromMonth,
            department: props.requiremtIdData.department,
            workDuration: props.requiremtIdData.workDuration,
            maxSalary: props.requiremtIdData.maxSalary,
            minSalary: props.requiremtIdData.minSalary,
            currency:
              props.requiremtIdData.currency +
              "-" +
              props.requiremtIdData.currencyText,
            jobCode: props.requiremtIdData.jobCode,
            priority: props.requiremtIdData.priority,
            title: props.requiremtIdData.title,
            jobDescription: props.requiremtIdData.jobDescription,
            experienceToMonth: props.requiremtIdData.experienceToMonth,
            settlementTerms: props.requiremtIdData.settlementTerms,
            standardCommission: props.requiremtIdData.standardCommission,
            mandatorySkills:
              props.requiremtIdData.mandatorySkills &&
              props.requiremtIdData.mandatorySkills,
            optionalSkills: props.requiremtIdData.optionalSkills,
            salaryMode: props.requiremtIdData.salaryMode,
            replacementClause: props.requiremtIdData.replacementClause,
            clientId: props.requiremtIdData.clientId,
            billingValue: props.requiremtIdData.billingValue,
            jobType: props.requiremtIdData.jobType,
            makePublic: props.requiremtIdData.makePublic,
            hiringType: props.requiremtIdData.hiringType,
            role: props.requiremtIdData.role,
            jobStatus: props.requiremtIdData.jobStatus,
          }
        }
      >
        <Row>
          <Col style={{ padding: "0px 20px 80px 20px" }} md={24}>
            {props.modalNameHandler === "jobLocation" ? (
              <>
                <Form.Item
                  name="locationDetails"
                  label="Job Location"
                  rules={[
                    {
                      required: true,
                      message: "Please enter job location",
                    },
                  ]}
                >
                  <Input placeholder="Enter job location" />
                </Form.Item>
                <Form.Item
                  name="jobStatus"
                  label="Job Status"
                  rules={[
                    {
                      required: false,
                      message: "Please select job status",
                    },
                  ]}
                >
                  <Select>
                    <Select.Option value="published">Published</Select.Option>
                    <Select.Option value="draft">Draft</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="areaDetails"
                  label="Area Details"
                  rules={[
                    {
                      required: false,
                      message: "Please enter area",
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Enter area" />
                </Form.Item>
                <Form.Item
                  label="Work Mode"
                  name="workMode"
                  rules={[
                    {
                      required: true,
                      message: "Please select work mode",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    placeholder="Select work mode"
                  >
                    <Select.Option value="Remote">Remote</Select.Option>
                    <Select.Option value="Office">Office</Select.Option>
                    <Select.Option value="Hybrid">Hybrid</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Department"
                  name="department"
                  rules={[
                    {
                      required: true,
                      message: "Please select department",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select department"
                  >
                    {props.departments &&
                      props.departments.map((data, index) => {
                        return (
                          <Select.Option
                            key={index}
                            value={data.departmentName}
                          >
                            {data.departmentName}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item name="workDuration" label="Duration of work">
                  <Input placeholder="Enter duration of work" />
                </Form.Item>

                <Row justify="space-between">
                  <Col span={11}>
                    <Form.Item
                      label="Min Salary (CTC in Lakhs)"
                      name="minSalary"
                      rules={[
                        {
                          required: true,
                          message: "Please enter max salary",
                        },
                      ]}
                    >
                      <Input placeholder="Enter min salary" />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      label="Max Salary (CTC in Lakhs)"
                      name="maxSalary"
                      rules={[
                        {
                          required: true,
                          message: "Please enter min salary",
                        },
                      ]}
                    >
                      <Input placeholder="Enter max salary" />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  name="currency"
                  label="Currency"
                  rules={[
                    {
                      required: true,
                      message: "Please select currency",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    placeholder="Select currency"
                  >
                    <Select.Option value="₹-(Rupee)">
                      ₹ Indian Rupee
                    </Select.Option>
                    <Select.Option value="$-(Dollar)">$ Dollar</Select.Option>
                    <Select.Option value="₩-(Korea Won)">
                      ₩ Korea Won
                    </Select.Option>
                    <Select.Option value="£-(Pound)"> £ Pound</Select.Option>
                    <Select.Option value="¥-(Yen)">¥ Yen</Select.Option>
                    <Select.Option value="₦-(Nigeria Naira)">
                      ₦ Nigeria Naira
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="jobCode"
                  label="Job Code"
                  rules={[
                    {
                      required: true,
                      message: "Please enter job code",
                    },
                  ]}
                >
                  <Row style={{ gap: "22px" }}>
                    <Col md={5}>
                      <Input
                        readOnly
                        defaultValue={
                          props.settindsData && props.settindsData.jobPrefix
                        }
                      />
                    </Col>
                    <Col md={18}>
                      <Input
                        readOnly
                        defaultValue={
                          props.requiremtIdData &&
                          props.requiremtIdData.jobCode &&
                          props.requiremtIdData.jobCode.split("-")[1]
                            ? props.requiremtIdData.jobCode.split("-")[1]
                            : props.requiremtIdData.jobCode
                          // props.requiremtIdData.jobCode.slice(
                          //   props.settindsData &&
                          //     props.settindsData.jobPrefix &&
                          //     props.settindsData.jobPrefix.length + 1
                          // )
                        }
                        placeholder="Enter job code"
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                    label="Designation"
                    name="role"
                    rules={[
                      {
                        required: true,
                        message: "Please select job role",
                      },
                    ]}
                  >
                    <Input placeholder="Enter job role" />
                  </Form.Item>
                <Form.Item
                  name="priority"
                  label="Job Priority"
                  // rules={[
                  //   {
                  //     required: false,
                  //     message: "Please enter job priority",
                  //   },
                  // ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    placeholder="Select job priority"
                  >
                    <Select.Option value="trending">Trending</Select.Option>
                    <Select.Option value="hot">Hot</Select.Option>
                    <Select.Option value="premium">Premium</Select.Option>
                    <Select.Option value="featured">Featured</Select.Option>
                    <Select.Option value="">None</Select.Option>
                  </Select>
                </Form.Item>

                <Row
                  style={{ marginBottom: "45px" }}
                  className={commonCssStyles.sectionEndGap}
                >
                  <Col className="sharingMedium" md={24}>
                    <Form.List name="sharingMedium">
                      {(fields, { add, remove }) => (
                        <>
                          {/* {fields.length > 0 && (
                              <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item-required">
                                  Portfolio / Work sample
                                </label>
                              </div>
                            )} */}
                          {fields.map((field, key) => (
                            <div>
                              <Row>
                                <Col flex={1}>
                                  <AddLink
                                    field={field}
                                    MediumLink={props}
                                    // dataFormate={dataFormate}
                                  />
                                </Col>

                                <Col md={24} style={{ marginBottom: "10px" }}>
                                  <p
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "8px",
                                      float: "right",
                                      color: "#F83A3A",
                                    }}
                                    className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.removeLocation}`}
                                  >
                                    Remove Link
                                  </p>
                                  <Button
                                    className={
                                      commonCssStyles.removeLocationbtn
                                    }
                                    type="primary"
                                    size="small"
                                    shape="circle"
                                    style={{
                                      marginTop: "3px",
                                      marginRight: "7px",
                                      float: "right",
                                      backgroundColor: "#F83A3A",
                                      transform: "translateY(-4px)",
                                    }}
                                  >
                                    <div
                                      style={{
                                        transform: "translateY(-4px)",
                                      }}
                                      onClick={() => remove(key)}
                                    >
                                      <MinusIcons color={"var(--theme)"} />
                                    </div>
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          ))}
                          <Button
                            size="small"
                            onClick={() => add()}
                            type="primary"
                            shape="circle"
                            style={
                              {
                                // transform: "transformY(-20px)",
                              }
                            }
                          >
                            <div style={{ transform: "translateY(2px)" }}>
                              <PlusForButtonIcon
                                height={"19px"}
                                width={"19px"}
                                color={"var(--theme)"}
                              />
                            </div>
                          </Button>{" "}
                          <p
                            style={{
                              marginTop: "-27px",
                              marginLeft: "40px",
                            }}
                            className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.addLocation}`}
                          >
                            {" "}
                            Add Link
                          </p>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>

                {/* <Form.Item
                //  name="priority"
                  label="Job Link"
                  rules={[
                    {
                      required: false,
                      message: "Please enter job link",
                    },
                  ]}
                >
                  <Input placeholder="Enter job link" />
                </Form.Item> */}
              </>
            ) : (
              <>
                <Form.Item
                  label="Job Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter job title",
                    },
                  ]}
                >
                  <Input placeholder="Enter job title" />
                </Form.Item>
                <Form.Item
                  label="Company"
                  name="clientId"
                  rules={[
                    {
                      required: true,
                      message: "Please select company",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    // mode="multiple"
                    onChange={onChangeCompany}
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    className={`${commonCssStyles.border}`}
                    placeholder="Select company"
                    // tagRender={tagRenderForMobileNum}
                  >
                    {props.clients &&
                      props.clients.map((data, index) => {
                        return (
                          <Option key={index} value={data.id}>
                            {data.clientName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Row>
                  <Col flex={1}>
                    <Form.Item
                      label="Job Description"
                      // name="JobDescription1"
                      rules={[
                        {
                          required: true,
                          message: "Please select job description",
                        },
                      ]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        onChange={onChangeJobDesc}
                        // mode="multiple"
                        allowClear
                        style={{
                          width: "100%",
                        }}
                        className={`${commonCssStyles.border}`}
                        placeholder="Select job description"
                        // tagRender={tagRenderForMobileNum}
                      >
                        {props.jobDescriptionData &&
                          props.jobDescriptionData.find(
                            (x) => x.status == "1"
                          ) &&
                          props.jobDescriptionData.map((data, index) => {
                            return (
                              <Option key={index} value={data.jobDescription}>
                                {data.department}
                              </Option>
                            );
                          })}
                        <Option value="">Other</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label="Job Description"
                  name="jobDescription"
                  rules={[
                    {
                      required: true,
                      message: "Please enter job description",
                    },
                  ]}
                >
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    value={value}
                    onChange={setValue}
                  />
                </Form.Item>

                <Row style={{ marginBottom: "20px" }}>
                  <Col flex={1}>
                    <UploadJDEdit
                      word={word}
                      pdf={pdf}
                      filesData={(val) => {
                        setJDFileData(val);
                      }}
                      data={
                        props.requiremtIdData &&
                        props.requiremtIdData.jobDescriptionPath &&
                        props.requiremtIdData.jobDescriptionPath
                      }
                    />
                  </Col>
                </Row>

                {/* <Form.Item
                  label="Skills"
                  rules={[
                    {
                      required: false,
                      message: "Please select skills",
                    },
                  ]}
                >
                  <Select placeholder="Select skills">
                    <Select.Option value="tag">Tag</Select.Option>
                  </Select>
                </Form.Item> */}

                <Row justify="space-between">
                  <Col span={11}>
                    <Form.Item
                      label="Min Experience (in years)"
                      name="experienceToMonth"
                      rules={[
                        {
                          required: true,
                          message: "Please enter min experience",
                        },
                      ]}
                    >
                      <Input placeholder="Enter min experience" />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      label="Max Experience (in years)"
                      name="experienceFromMonth"
                      rules={[
                        {
                          required: true,
                          message: "Please enter max experience",
                        },
                      ]}
                    >
                      <Input placeholder="Enter max experience" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginBottom: "30px",
                    border: "0.1px solid var(--body-text-color)",
                    padding: "10px",
                    borderRadius: "7px",
                  }}
                >
                  <Col>
                    <p style={{ color: "var(--body-text-color)" }}>
                      Mandatory Skills Or Add New Skills Preview
                    </p>
                    {newSkillsTag &&
                      newSkillsTag.length > 0 &&
                      newSkillsTag.map((tagData, tagsIndex) => {
                        return (
                          <Tag
                            className="keySkillsTags"
                            closable
                            onClose={() => {
                              form.setFieldsValue({
                                mandatorySkills: form
                                  .getFieldValue("mandatorySkills")
                                  .filter((val) => val !== tagData),
                              });
                              if (
                                form.getFieldValue("mandatorySkills") &&
                                form.getFieldValue("mandatorySkills").length ===
                                  0
                              ) {
                                setNewSkillsTag([]);
                              }
                            }}
                            key={tagsIndex}
                          >
                            {tagData}
                          </Tag>
                        );
                      })}
                  </Col>
                </Row>
                <Row>
                  <Col flex={1}>
                    <Form.Item
                      label="Mandatory Skills"
                      name="mandatorySkills"
                      rules={[
                        {
                          required: true,
                          message: "Please select mandatory skills",
                        },
                      ]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        className={`TagsStyle `}
                        mode="tags"
                        allowClear
                        style={{
                          width: "100%",
                        }}
                        // dropdownRender={(menu) => (
                        //   <>
                        //     {menu}
                        //     <AadTagsForSkills onType={"skill"} />
                        //   </>
                        // )}
                        maxTagCount={0}
                        onChange={(val) => {
                          setNewSkillsTag(val);
                        }}
                        showSearch
                        tokenSeparators={[","]}
                        placeholder="Select mandatory skills or add new skills"
                      >
                        {props.tagsData &&
                          props.tagsData
                            .filter((data) => data.type === "skill")
                            .map((data) => {
                              return (
                                <Select.Option key={data.id} value={data.tag}>
                                  {data.tag}
                                </Select.Option>
                              );
                            })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginBottom: "30px",
                    border: "0.1px solid var(--body-text-color)",
                    padding: "10px",
                    borderRadius: "7px",
                  }}
                >
                  <Col>
                    <p style={{ color: "var(--body-text-color)" }}>
                      Optional Skills Or Add New Skills Preview
                    </p>
                    {optionalSkillsTags &&
                      optionalSkillsTags.length > 0 &&
                      optionalSkillsTags.map((tagData, tagsIndex) => {
                        return (
                          <Tag
                            className="keySkillsTags"
                            closable
                            onClose={() => {
                              form.setFieldsValue({
                                optionalSkills: form
                                  .getFieldValue("optionalSkills")
                                  .filter((val) => val !== tagData),
                              });
                              if (
                                form.getFieldValue("optionalSkills") &&
                                form.getFieldValue("optionalSkills").length ===
                                  0
                              ) {
                                setOptionalSkillsTags([]);
                              }
                            }}
                            key={tagsIndex}
                          >
                            {tagData}
                          </Tag>
                        );
                      })}
                  </Col>
                </Row>
                <Row>
                  <Col flex={1}>
                    <Form.Item
                      label="Optional Skills"
                      name="optionalSkills"
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        className={`TagsStyle `}
                        mode="tags"
                        allowClear
                        style={{
                          width: "100%",
                        }}
                        onChange={(val) => {
                          setOptionalSkillsTags(val);
                        }}
                        // dropdownRender={(menu) => (
                        //   <>
                        //     {menu}
                        //     <AadTagsForSkills onType={"skill"} />
                        //   </>
                        // )}
                        showSearch
                        maxTagCount={0}
                        tokenSeparators={[","]}
                        placeholder="Select optional skills or add new skills"
                      >
                        {props.tagsData &&
                          props.tagsData
                            .filter((data) => data.type === "skill")
                            .map((data) => {
                              return (
                                <Select.Option key={data.id} value={data.tag}>
                                  {data.tag}
                                </Select.Option>
                              );
                            })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    <Form.Item
                      label="Salary Mode"
                      name="salaryMode"
                      rules={[
                        {
                          required: false,
                          message: "Please select salary mode",
                        },
                      ]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        placeholder="Select salary mode"
                      >
                        <Select.Option value="monthly">Monthly</Select.Option>
                        <Select.Option value="one time">One time</Select.Option>
                        <Select.Option value="per two week">
                          Per two week
                        </Select.Option>
                        <Select.Option value="per week">Per week</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    <Form.Item label="Settlement Terms" name="settlementTerms">
                      <Input placeholder="Enter settlement terms" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    <Form.Item label="Billing Value" name="billingValue">
                      <Input placeholder="Enter billing value" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    <Form.Item
                      label="Replacement Clause"
                      name="replacementClause"
                    >
                      <Input placeholder="Enter replacement clause" />
                    </Form.Item>
                  </Col>
                </Row>

                
                <Row>
                  <Col flex={1}>
                    <Form.Item
                      label="Agreed commercials"
                      name="standardCommission"
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        placeholder="Select agreed commercials"
                      >
                        {commercials &&
                          commercials.map((data, index) => {
                            return (
                              <Select.Option value={data} key={index}>
                                {data}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    <Form.Item
                      label="Job Type"
                      name="jobType"
                      rules={[
                        {
                          required: true,
                          message: "Please select job type",
                        },
                      ]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        placeholder="Select job type"
                      >
                        <Select.Option value="part time">
                          Part time
                        </Select.Option>
                        <Select.Option value="full time">
                          Full time
                        </Select.Option>
                        <Select.Option value="Internship">
                          Internship
                        </Select.Option>
                        <Select.Option value="Contract">Contract</Select.Option>
                        <Select.Option value="Temporary">
                          Temporary
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

               

                <Row >
                  <Col>
                    <p></p>
                    <Form.Item
                      name="makePublic"
                      rules={[
                        {
                          required: true,
                          message: "required",
                        },
                      ]}
                      label={<p className={styles.addJobLabel}>Privacy</p>}
                    >
                      <Radio.Group>
                        <Radio value={1}>Public</Radio>
                        <Radio value={0}>Private</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                 
                </Row>

                <Row  style={{ marginBottom: "40px" }}>
                <Col md={24}>
                    <Form.Item
                      label="Internal/External Hiring"
                      name="hiringType"
                      rules={[
                        {
                          required: true,
                          message: "Please select internal or external",
                        },
                      ]}
                    >
                      <Radio.Group
                        onChange={onHiringValueChange}
                        defaultValue={hiringValue}
                        // value={privacyvalue}
                        className="radioBtnText"
                      >
                        <Radio value="internal">Internal</Radio>
                        <Radio value="external">External</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

              </>
            )}
          </Col>
          <Col
            style={{ width: "726px" }}
            className={commonCssStyles.footerShadow}
          >
            <Row style={{ gap: "17px" }} justify="end">
              <Col>
                <Button
                  onClick={() => {
                    props.onCloseJobInfo();
                  }}
                  type="default"
                  className={commonCssStyles.buttonSettingsSecondary}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    form.submit();
                  }}
                  htmlType="button"
                  type="primary"
                  className={commonCssStyles.buttonSettingsPrimary}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

function AddLink({ field, dataFormate, MediumLink }) {
  return (
    <>
      <Row style={{ gap: "20px" }}>
        <Col md={24}>
          <Form.Item
            {...field}
            rules={[
              {
                required: true,
                message: "Please Select sharing medium",
              },
            ]}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              placeholder="Select sharing medium"
            >
              {MediumLink.sharingMediumsData &&
                MediumLink.sharingMediumsData.map((data, index) => {
                  return (
                    <Option key={index} value={data.medium}>
                      {data.medium}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col md={19}>
          <Form.Item
            name={[field.name, "trackingLink"]}
            rules={[
              {
                required: false,
                message: "Please enter link",
              },
            ]}
            value={copyValues}
            onChange={(val)=>{setCopyValues(val.target.value)}}
          >
            <Input placeholder="Paste the link here" />
          </Form.Item>
        </Col>
        <Col md={4}>
         <CopyToClipboard  text={copyValues} onCopy={()=>setCopied(true)}>
         <Button type="primary" className={commonCssStyles.copyLinkButton} >
            Copy Link
          </Button>
         </CopyToClipboard>
        </Col> */}
      </Row>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tagsData: state.tags.tags,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTagHandler: () => {
      dispatch(getTagsAction());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(JobInfoEditDrawer);
