import { Avatar, Col, Row } from "antd";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import commonCssStyles from "../../../Components/UI/CommonCss.module.css";
import {
  BackButtonIcon,
  BellICon,
  BrieftCaseIcon,
  BuildingsIcon,
  CalendarIcon,
  SettingIcon,
  UserFocusIcon1,
  UserIcon,
} from "../../IconsComp/Icons";
import Dashboard from "../../Screens/Dashboard/ContentPage/Dashboard";

function GlobalSearchList() {
  const location = useLocation();
 
  return (
    <>
      <div className={`${commonCssStyles.notificationFullDetails}`}>
        <Row style={{padding:"20px",position:"fixed",zIndex:"10",backgroundColor:"var(--theme)",width:"35rem"}}>
          <Col md={24}>
          <Link to={"/dashboard"}>
            <BackButtonIcon color={"var(--body-text-color)"} />
            </Link>
          </Col>
        </Row>
        {location &&
          location.state &&
          location.state.map((data, index) => {
            return (
              <>
                <Row
                  key={index}
                  justify="space-between"
                  style={{ marginBottom: "24px" }}
                  align="middle"
                >
                  <Col md={19}>
                    <Row align="middle">
                      <Col md={4} >
                        <span style={{ padding: "0px 0px 0px 19px" }}>
                          <Avatar
                            size={50}
                            icon={
                              <div
                                style={{
                                  display: "block",
                                  width: "50%",
                                  position: "relative",
                                  transform: "translate(11px,6px)",
                                }}
                              >
                                {data.type === "task" ? (
                                  <CalendarIcon color={"var(--primaryColor)"} />
                                ) : data.type === "task" ? (
                                  <Dashboard color={"var(--primaryColor)"} />
                                ) : data.type === "recruitment" ? (
                                  <UserFocusIcon1
                                    color={"var(--primaryColor)"}
                                  />
                                ) : data.type === "candidate" ? (
                                  <UserIcon color={"var(--primaryColor)"} />
                                ) : data.type === "requirement" ? (
                                  <BrieftCaseIcon
                                    color={"var(--primaryColor)"}
                                  />
                                ) : data.type === "company" ? (
                                  <BuildingsIcon
                                    color={"var(--primaryColor)"}
                                  />
                                ) : data.type === "employee" ? (
                                  <UserIcon color={"var(--primaryColor)"} />
                                ) : data.type === "settings" ? (
                                  <SettingIcon color={"var(--primaryColor)"} />
                                ) : (
                                  <BellICon color={"var(--primaryColor)"} />
                                )}
                              </div>
                            }
                          />
                        </span>
                      </Col>
                      {data.type === "candidate" ? (
                      
                          <Col
                            md={20} /* onClick={()=>{setDebouncedTerm("");}} */
                          >
                              <Link
                          to={"/candidatesDetailsPage/summary"}
                          state={{ id: data.id, from: "candidateList" }}
                        >
                            <p
                              className={`${commonCssStyles.headingText2} ${commonCssStyles.marginBottomRemove}`}
                              style={{
                                textAlign: "left",
                                padding: "0px 10px 0px 10px",
                              }}
                            >
                              {data.title}
                            </p>
                            </Link>
                          </Col>
                      ) : data.type === "requirement" ? (
                          <Col
                            md={20} /* onClick={()=>{setDebouncedTerm("");}} */
                          >                        <Link to={"/JobDetailPage"} state={{ id: data.id }}>

                            <p
                              className={`${commonCssStyles.headingText2} ${commonCssStyles.marginBottomRemove}`}
                              style={{
                                textAlign: "left",
                                padding: "0px 10px 0px 10px",
                              }}
                            >
                              {data.title}
                            </p>
                            </Link>

                          </Col>
                      ) : data.type === "company" ? (
                          <Col
                            md={20} /* onClick={()=>{setDebouncedTerm("");}} */
                          >  
                          <Link to={"/clientCompany/details"} state={data.id}>
                            <p
                              className={`${commonCssStyles.headingText2} ${commonCssStyles.marginBottomRemove}`}
                              style={{
                                textAlign: "left",
                                padding: "0px 10px 0px 10px",
                              }}
                            >
                              {data.title}
                            </p>
                            </Link>

                          </Col>
                      ) : data.type === "employee" ? (
                        
                          <Col
                            md={20} /* onClick={()=>{setDebouncedTerm("");}} */
                          >
                            <Link to={"/employee/summary"} state={data.id}>
                            <p
                              className={`${commonCssStyles.headingText2} ${commonCssStyles.marginBottomRemove}`}
                              style={{
                                textAlign: "left",
                                padding: "0px 10px 0px 10px",
                              }}
                            >
                              {data.title}
                            </p>
                            </Link>
                          </Col>
                      ) : data.type === "task" ? (
                        
                          <Col
                            md={20} /* onClick={()=>{setDebouncedTerm("");}} */
                          >
                            <Link
                          to={"/taskCalendar"}
                          state={{ id: data.id, type: "task" }}
                        >
                            <p
                              className={`${commonCssStyles.headingText2} ${commonCssStyles.marginBottomRemove}`}
                              style={{
                                textAlign: "left",
                                padding: "0px 10px 0px 10px",
                              }}
                            >
                              {data.title}
                            </p></Link>
                          </Col>
                      ) : data.type === "interview" ? (
                        <Col
                            md={20}
                          >
                        <Link
                          to={"/taskCalendar"}
                          state={{ id: data.id, type:"interview" }}
                        >
                            <p
                              className={`${commonCssStyles.headingText2} ${commonCssStyles.marginBottomRemove} ${commonCssStyles.textBreak}`}
                              style={{
                                textAlign: "left",
                                padding: "0px 10px 0px 10px",
                              }}
                            >
                              {data.title}
                            </p>
                        </Link>
                          </Col>
                      ):""}
                    </Row>
                  </Col>

                  <Col md={5}>
                    <p
                      className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.tableHeadingTextData}`}
                      style={{
                        textAlign: "right",

                        padding: "0px 19px 0px 19px",
                      }}
                    >
                      {data.type}
                    </p>
                  </Col>
                </Row>
              </>
            );
          })}
      </div>
    </>
  );
}

export default GlobalSearchList;
