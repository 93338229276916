import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  message,
  Modal,
  Progress,
  Row,
  Switch,
  Table,
  Tabs,
} from "antd";

import styles from "../../AddCandidatesComp/AddCandidatesComp.module.css";
import {
  BackButtonIcon,
  CaretBlackDownIcon,
  CloseIcon,
  DownloadIcon,
  EditBorderIcon,
  EnvelopeForSummaryIcon,
  EyeIcon,
  OfferLetterIcon,
  PhoneForSummaryIcon,
  RightMarkIcon,
  WhatsappIcon,
} from "../../../IconsComp/Icons";
import Interview from "./Interview";
import SummaryDetails from "./SummaryDetails";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Emails from "./Emails";
import TimeLine from "./TimeLine";
import Task from "./Task";
import commonCssStyles from "../../../UI/CommonCss.module.css";
import Status from "./Status";
import Steps from "../../../UI/Steps";
import EmployeeTask from "../../Employees/EmployeeTask";
import { useEffect, useRef, useState } from "react";
import {
  getRequirementAction,
  getRequirementsAction,
} from "../../../../Reusable/Store/Action/RequirementAction";
import {
  getCandidateInterviewAction,
  getRecruitmentCandidateAction,
  getCandidatesCallLogsAction,
  putCandidatesAction,
  getCandidatesInsightAction,
} from "../../../../Reusable/Store/Action/CandidatesAction";
import { getInterViewAction } from "../../../../Reusable/Store/Action/RecruitmentAction";
import {
  getRecruitmentsCandidatesJobStatusAction,
  getRecruitmentAllStatusAction,
  putRecruitmentCandidateStatusAction,
  getRecruitmentCallLogsAction,
  getInterviewFeedbackction,
} from "../../../../Reusable/Store/Action/RecruitmentAction";
import word from "../../../../Assets/Image/word.png";
import jwt_decode from "jwt-decode";
import pdf from "../../../../Assets/Image/pdf.png";
import excel from "../../../../Assets/Image/excel.png";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { getTaskAndCalendarAction } from "../../../../Reusable/Store/Action/TaskAndCalendarAction";
import CandidateCall from "./CandidateCall";
import style from "../../AddCandidatesComp/AddCandidatesComp.module.css";
import { checkPermissionAction } from "../../../../Reusable/Store/Action/AuthPermissionsAction";
import PermissionMessage from "../../../UI/PermissionMessage";
import BreadcrumbForRecruitment from "../../Recruitment/BreadcrumbForRecruitment";
import BreadcrumbForCandidate from "../candidateDetailsTable/BreadcrumbForCandidate";
import BreadcrumbForItoC from "../../TaskCalendar/BreadcrumbForItoC";
import CandidateAssgnToJobs from "./CandidateAssgnToJobs";
import InterviewFeedback from "./InterviewFeedback";
import CandidateFeedback from "./CandidateFeedback";
import CandidateInsight from "./CandidateInsight";
import { activeMenuKeySlice } from "../../../../Reusable/Store/Slice/SettingsSlice";

function CandidateTabs(props) {
  const [draftModalOpen, setDraftModalOpen] = useState(false);
  let btnref = useRef(null);
  const [candidateInsightNotFound, setCandidateInsightNotFound] =
    useState(false);

  const [filesData, setFilesData] = useState("");
  const [form] = useForm();
  const [formT] = useForm();

  const navigate = useNavigate();
  const [notify, setNotify] = useState(false);
  const [progress, setProgress] = useState(0);
  const [internalHiring, setInternaliring] = useState(false);
  const [externalHring, setExternalHiring] = useState(false);
  const [fileResult, setFileResult] = useState(false);
  const [filesDisp, setFilesDisp] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [openDrawerPending, setOpenDrawerPending] = useState(false);

  const location = useLocation();
  const [fetchId, setFetchId] = useState({});
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });

  const [openrecruter, setOpenrecruiter] = useState(false);
  const [tentativePopup, setTetativePopup] = useState(false);
  useEffect(() => {
    if (
      (location && location.state && location.state.pageFrom === "recuriter") ||
      (location && location.state && location.state.from === "recruitmentList")
    ) {
      props.activeMenuKeyHandle("/recruitment");
    } else if (
      location &&
      location.state &&
      location.state.pageFrom === "requirement"
    ) {
      props.activeMenuKeyHandle("/requirement");
    } else if (
      location &&
      location.state &&
      location.state.pageFrom === "candidate"
    ) {
      props.activeMenuKeyHandle("/candidatesDetailsPage");
    } else if (
      location &&
      location.state &&
      location.state.comingFrom === "interviewList"
    ) {
      props.activeMenuKeyHandle("/taskCalendar");
    }
  }, []);
  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      let decoded = jwt_decode(props.tokenData);
      setFetchId(decoded);
      props.getJobNameHandler();
      props.getInterviewHandler(location.state.id && location.state.id);
      props.getRecruitmentAllStatusHandler();
      if (location.state.from !== "candidateList") {
        props.getCandidatejobStatusHandler(
          location.state.backIconid && location.state.backIconid
        );
      }
      if (location.state.from === "recruitmentList") {
        // props.getCandidateHandler(location.state.id);
        props.getRecruitmentCandidateHandler(location.state.backIconid);
        // props.getRecruitmentCallLogsAction(location.state.backIconid);
        props.getCandidatesCallLogsAction({ id: location.state.id });
      } else if (location.state.from === "candidateList") {
        props.getCandidatesCallLogsAction({
          id: location.state.id,
        });
      }
      if (
        props.recruitment_job_status &&
        props.recruitment_job_status.offer_letter &&
        props.recruitment_job_status.offer_letter.hiringType == "external"
      ) {
        setExternalHiring(true);
      } else {
        setInternaliring(true);
      }
    }
    return () => {
      unsubscribed = true;
    };
  }, [location]);

  const columns = [
    {
      title: (
        <p
          className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.tableHeadingsColor}`}
        >
          Name
        </p>
      ),
      width: "40%",
      dataIndex: "name",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyles.tableData} ${commonCssStyles.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
      key: "name",
    },
    {
      title: (
        <p
          className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.tableHeadingsColor}`}
        >
          Email
        </p>
      ),
      width: "50%",
      dataIndex: "email",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyles.tableData} ${commonCssStyles.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
      key: "email",
    },
    {
      title: (
        <p
          className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.tableHeadingsColor}`}
        >
          Phone
        </p>
      ),
      dataIndex: "phone",
      width: "40%",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyles.tableData} ${commonCssStyles.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
      key: "phone",
    },
  ];

  const dataFormate =
    props.settings && props.settings.dateFormat
      ? props.settings.dateFormat
      : "DD/MM/YYYY";

  function TentativeJoiningDate(valu) {
    const data1 = {
      stage:
        props.recruitment_job_status &&
        props.recruitment_job_status.currentStage,
      id: location.state.backIconid,
      joiningDate:
        valu.joiningDate !== null
          ? moment(valu.joiningDate).format("YYYY-MM-DD")
          : "Invalid date",
      ctc: valu.ctc ? valu.ctc : "0",
      _method: "PUT",
    };
    props.putRecruitmentJobStatusHandler(data1, (success) => {
      if (success) {
        if (location.state.backIconid) {
          props.getCandidatejobStatusHandler(location.state.backIconid);
        }
        closeTentativePopup();
      }
    });
  }

  function closeTentativePopup() {
    setTetativePopup(false);
  }

  const DraftData = (draftData) => {
    const data = {
      ...draftData,
      stage: "Offered",
      id: location.state.backIconid,
      notify: notify ? 1 : 0,
      // hiringType: internalHiring ? "internal" : "external",
      // joiningDate: draftData.joiningDate!==null
      //   ? moment(draftData.joiningDate).format("YYYY/MM/DD")
      //   : "Invalid date",
      joiningDate:
        draftData.joiningDate === null || draftData.joiningDate === undefined
          ? "Invalid date"
          : moment(draftData.joiningDate).format("YYYY/MM/DD"),
      attachOfferletter: filesData,
      _method: "PUT",
    };
    props.putRecruitmentJobStatusHandler(data, (success) => {
      if (success) {
        props.getCandidatejobStatusHandler(
          location.state.backIconid && location.state.backIconid
        );
        closeModal();
        form.resetFields();
      }
    });
  };

  const handleChange = (info) => {
    setFileResult(false);

    if (!(info.target.files[0].size <= 2000000)) {
      message.warning("This file should be less than 2 MB", 3);
      setProgress(0);
      return;
    }
    setFilesData(info.target.files[0]);
    setFilesDisp(true);
    setProgress(50);
    setTimeout(() => {
      setProgress(100);
    }, 300);
  };

  const closeModal = () => {
    setDraftModalOpen(false);
  };

  const itemsArray = [
    {
      label: "Summary",
      key: "1",
      children: (
        <SummaryDetails
          onTokenId={fetchId}
          onRecruitmentCandidateData={(value) => {
            props.getRecruitmentCandidateHandler(value);
          }}
          onRecruitmentCandidate={props.RecruitmentCandidate}
        />
      ),
    },
    location.state.from === "recruitmentList" && {
      label: "Status",
      key: "6",
      children: (
        <Status
          onDraftModal={draftModalOpen}
          recruitment_job_status={props.recruitment_job_status}
          get_all_status={props.get_all_status}
          candidateId={location.state.backIconid}
          RecruitmentCandidate={props.RecruitmentCandidate}
        />
      ),
    },
    location.state.from === "candidateList" &&
      props.permissions &&
      props.permissions.find((data) => data === "candidate-interview-list") && {
        label: "Interview",
        key: "2",
        children: (
          <Interview
            onInterviewDetailsData={(data) => {
              props.getInterviewDetailsHandler(data, (success) => {});
            }}
            getTaskInterviewData={(data) => {
              props.getTaskByIdHandler(data, { link: 1 }, (success) => {
                if (success) {
                  setOpenDrawerPending(true);
                }
              });
            }}
            onFetchInterviewData={() => {
              props.getInterviewHandler(location.state.id);
            }}
            onOpenDrawerPending1={openDrawerPending}
            onOpenDrawerPending2={() => {
              setOpenDrawerPending(false);
            }}
            RecruitmentCandidate={props.RecruitmentCandidate}
            onTasksData={props.task}
            onInterviewDetails={props.interviewDetailsData}
            onCandidateData={props.candidateData}
            onSettingsData={props.settings}
            onCandidateInterview={props.candidateInterviewData}
            onJobData={props.jobsData}
            onCandidateName={{ id: location.state.id }}
          />
        ),
      },
    location.state.from === "recruitmentList" &&
      props.permissions &&
      props.permissions.find(
        (data) => data === "recruitment-candidate-interview-list"
      ) && {
        label: "Interview",
        key: "2",
        children: (
          <Interview
            onInterviewDetailsData={(data) => {
              props.getInterviewDetailsHandler(data, (success) => {});
            }}
            getTaskInterviewData={(data) => {
              props.getTaskByIdHandler(data, { link: 1 }, (success) => {
                if (success) {
                  setOpenDrawerPending(true);
                }
              });
            }}
            onFetchInterviewData={() => {
              props.getInterviewHandler(location.state.id);
            }}
            onOpenDrawerPending1={openDrawerPending}
            onOpenDrawerPending2={() => {
              setOpenDrawerPending(false);
            }}
            RecruitmentCandidate={props.RecruitmentCandidate}
            onTasksData={props.task}
            onInterviewDetails={props.interviewDetailsData}
            onCandidateData={props.candidateData}
            onSettingsData={props.settings}
            onCandidateInterview={props.candidateInterviewData}
            onJobData={props.jobsData}
            onCandidateName={{ id: location.state.id }}
          />
        ),
      },
    location.state.from === "candidateList" &&
      props.permissions &&
      props.permissions.find((data) => data === "candidate-mail-list") && {
        label: "Emails",
        key: "3",
        children: (
          <Emails
            onCandidateData={props.candidateData}
            RecruitmentCandidate={props.RecruitmentCandidate}
          />
        ),
      },
    location.state.from === "recruitmentList" &&
      props.permissions &&
      props.permissions.find(
        (data) => data === "recruitment-candidate-mail-list"
      ) && {
        label: "Emails",
        key: "3",
        children: (
          <Emails
            onCandidateData={props.candidateData}
            RecruitmentCandidate={props.RecruitmentCandidate}
          />
        ),
      },
    location.state.from === "candidateList" &&
      props.permissions &&
      props.permissions.find((data) => data === "candidate-call") && {
        label: "Calls",
        key: "4",
        children: (
          <CandidateCall
            candidateCalls={props.candidateCalls}
            RecruitmentCalls={props.recruitment_call_logs}
          />
        ),
      },
    location.state.from === "recruitmentList" &&
      props.permissions &&
      props.permissions.find(
        (data) => data === "recruitment-candidate-call"
      ) && {
        label: "Calls",
        key: "4",
        children: (
          <CandidateCall
            candidateCalls={props.candidateCalls}
            RecruitmentCalls={props.recruitment_call_logs}
          />
        ),
      },
    location.state.from === "candidateList" &&
      props.permissions &&
      props.permissions.find((data) => data === "candidate-timeline") && {
        label: "Timeline",
        key: "5",
        children: <TimeLine />,
      },
    location.state.from === "recruitmentList" &&
      props.permissions &&
      props.permissions.find(
        (data) => data === "recruitment-candidate-timeline"
      ) && {
        label: "Timeline",
        key: "5",
        children: <TimeLine />,
      },
    location.state.from === "candidateList" &&
      props.permissions &&
      props.permissions.find((data) => data === "candidate-task-list") && {
        label: "Task",
        key: "7",
        children: <EmployeeTask type="candidate" />,
      },
    location.state.from === "recruitmentList" &&
      props.permissions &&
      props.permissions.find(
        (data) => data === "recruitment-candidate-task-list"
      ) && {
        label: "Task",
        key: "7",
        children: <EmployeeTask type="candidate" />,
      },

    //job candidate
    location.state.from === "candidateList" &&
      props.permissions &&
      props.permissions.find((data) => data === "candidate-jobs") &&
      props.candidateData &&
      props.candidateData.status &&
      props.candidateData.status === "active" && {
        label: "Job",
        key: "8",
        children: <CandidateAssgnToJobs />,
      },
    // location.state.from === "recruitmentList" &&
    //   props.permissions &&
    //   props.permissions.find((data) => data === "recruitment-candidate-jobs") &&
    //   props.candidateData &&
    //   props.candidateData.status &&
    //   props.candidateData.status === "active" && {
    //     label: "Job",
    //     key: "8",
    //     children: <CandidateAssgnToJobs />,
    //   },
    location.state.from === "recruitmentList" &&
      props?.onProfileData?.roleType !== "External Recruiter" && {
        label: "Interview Feedback",
        key: "9",
        children: (
          <InterviewFeedback
            getInterviewFeedbackction={props.getInterviewFeedbackction}
          />
        ),
      },
    // location.state.from === "recruitmentList" && {
    //   label: "Candidate Feedback",
    //   key: "10",
    //   children: <CandidateFeedback />,
    // },
    location.state.from === "candidateList" &&
      props?.onProfileData?.roleType !== "External Recruiter" && {
        label: "Candidate Insight",
        key: "10",
        children: (
          <CandidateInsight
            onCandidateInsightNotFound={candidateInsightNotFound}
          />
        ),
      },

    location.state.from === "recruitmentList" &&
      props?.onProfileData?.roleType !== "External Recruiter" && {
        label: "Candidate Insight",
        key: "10",
        children: (
          <CandidateInsight
            onCandidateInsightNotFound={candidateInsightNotFound}
          />
        ),
      },
  ];

  return (
    <>
      {location.state.from &&
      location.state.from === "candidateList" &&
      location.state.comingFrom &&
      location.state.comingFrom === "interviewList" ? (
        <Row className={commonCssStyles.breadcrumbBottomMargin}>
          <Col md={24}>
            <BreadcrumbForItoC
              name={
                location.state.interviewDetals && location.state.interviewDetals
              }
            />
          </Col>
        </Row>
      ) : (
        location.state.from === "candidateList" && (
          <Row>
            <Col className={commonCssStyles.breadcrumbBottomMargin} md={24}>
              {location.state &&
              location.state.pageFrom &&
              location.state.pageFrom === "recuriter" ? (
                <BreadcrumbForCandidate
                  pageFrom={"recuriter"}
                  name={location.state.title}
                  id={location.state.idForRecuritment}
                  slide={[1, 2, 3, 4, 5]}
                />
              ) : location.state &&
                location.state.from === "candidateList" &&
                location.state.pageFrom &&
                location.state.pageFrom === "requirement" ? (
                <BreadcrumbForCandidate
                  pageFrom={"requirement"}
                  name={location.state.title}
                  id={location.state.idForRequirement}
                  slide={[1, 2, 3, 4, 5]}
                />
              ) : (
                <BreadcrumbForCandidate
                  pageFrom={"candidate"}
                  slide={[1, 2, 5]}
                />
              )}
            </Col>
          </Row>
        )
      )}
      {location.state.from === "recruitmentList" && (
        <Row className={commonCssStyles.breadcrumbBottomMargin}>
          <Col md={24}>
            <BreadcrumbForRecruitment
              candidateId={location.state.id}
              recruitmentId={location.state.jobDetailsPageId}
              name={location.state.recuritmentJobDetailsName}
              slide={[1, 2, 3, 4]}
            />
          </Col>
        </Row>
      )}

      <Row gutter={[0, 16]}>
        <Col xs={24} md={8}>
          <Card style={{ marginRight: "30px" }}>
            <div
              style={{
                textAlign: "center",
                paddingTop: "20px",

                margin: "15px 20px",
              }}
            >
              <Row>
                <Col xs={1} md={1}>
                  {location.state.from === "recruitmentList" ? (
                    <Link
                      to="/recruitment/recruitmentJobdetails"
                      state={{
                        id: location.state.jobDetailsPageId.id,
                        activeTabKey: "candidatePool",
                        recuritmentJobDetailsName:
                          location.state.recuritmentJobDetailsName,
                      }}
                    >
                      <BackButtonIcon color={"var(--icons-color)"} />

                      {/* location.state.id */}
                    </Link>
                  ) : location.state.from === "candidateList" &&
                    location.state.comingFrom &&
                    location.state.comingFrom === "interviewList" ? (
                    <span
                      onClick={() => {
                        props.getCheckPermissionHandler(
                          "interview-detail",
                          (success) => {
                            if (success) {
                              navigate("/taskCalendar", {
                                state: {
                                  from: "candidateDetails",
                                  id:
                                    location.state.interviewDetals &&
                                    location.state.interviewDetals.id &&
                                    location.state.interviewDetals.id,
                                  interviewDetals:
                                    location.state.interviewDetals,
                                },
                              });
                            } else {
                              setOpenPMModal({
                                open: true,
                                permission: "Interview Details",
                              });
                            }
                          }
                        );
                      }}
                      className={commonCssStyles.coursorPointer}
                    >
                      <BackButtonIcon color={"var(--icons-color)"} />
                    </span>
                  ) : location.state.from === "candidateList" &&
                    location.state.pageFrom &&
                    location.state.pageFrom === "recuriter" ? (
                    <Link
                      to="/candidatesDetailsPage"
                      state={{
                        pageFrom: "recuriter",
                        tab: "addCandidate",
                        id: location.state.idForRecuritment,
                        activeTabKey: "addCandidate",
                        title: location.state.title,
                      }}
                    >
                      <BackButtonIcon color={"var(--icons-color)"} />
                    </Link>
                  ) : location.state.from === "candidateList" &&
                    location.state.pageFrom &&
                    location.state.pageFrom === "requirement" ? (
                    <Link
                      to="/candidatesDetailsPage"
                      state={{
                        from: "fromRequirement",
                        pageFrom: "requirement",
                        title: location.state.title,
                        id: location.state.idForRequirement,
                        activeKey: "1",
                      }}
                    >
                      <BackButtonIcon color={"var(--icons-color)"} />
                    </Link>
                  ) : (
                    <Link to="/candidatesDetailsPage">
                      <BackButtonIcon color={"var(--icons-color)"} />
                    </Link>
                  )}
                </Col>
                <Col xs={23} md={23}>
                  <p
                    className={`${commonCssStyles.informationPersonName} ${commonCssStyles.textBreak}`}
                  >
                    {location.state.from === "candidateList" &&
                      props.candidateData &&
                      props.candidateData.name}
                    {location.state.from === "recruitmentList" &&
                      props.RecruitmentCandidate &&
                      props.RecruitmentCandidate.name}
                  </p>
                </Col>
              </Row>
            </div>
            {location.state.from === "recruitmentList" && (
              <Row
                style={{
                  margin: "15px 20px",
                  padding: "20px",
                  background: "var(--secondary-card-color)",
                  borderRadius: "6px",
                  overflow: "hidden",
                }}
              >
                <Col xs={24} md={24}>
                  <Row>
                    <Col md={24}>
                      <p
                        style={{ fontSize: "20px", paddingBottom: "19px" }}
                        className={`${commonCssStyles.textBreak} ${commonCssStyles.summaryInfoMainText}`}
                      >
                        Current Stage
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24} style={{ marginBottom: "10px" }}>
                      {/* <Steps
                      number={
                        props.recruitment_job_status &&
                        props.recruitment_job_status.candidate_job_status &&
                        props.recruitment_job_status.candidate_job_status[0] &&
                        props.recruitment_job_status.candidate_job_status[0]
                          .stageOrder &&
                        props.recruitment_job_status.candidate_job_status[0]
                          .stageOrder
                      }
                    /> */}
                      <p
                        className={`${style.statusSubText} ${commonCssStyles.marginBottomRemove}`}
                      >
                        {props.recruitment_job_status &&
                          props.recruitment_job_status.candidate_job_status &&
                          props.recruitment_job_status
                            .candidate_job_status[0] &&
                          props.recruitment_job_status.candidate_job_status[0]
                            .stageType &&
                          props.recruitment_job_status.candidate_job_status[0]
                            .stageType}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ gap: "5px" }}>
                    <Col>
                      <p
                        className={`${commonCssStyles.textBreak}`}
                        style={{
                          color:
                            props.recruitment_job_status.currentStage ===
                            "Absconded"
                              ? "red"
                              : props.recruitment_job_status.currentStage ===
                                "Quit"
                              ? "red"
                              : props.recruitment_job_status.currentStage ===
                                "Blacklisted"
                              ? "red"
                              : props.recruitment_job_status.currentStage ===
                                "Deleted"
                              ? "red"
                              : props.recruitment_job_status.currentStage ===
                                "Candidate Declined/Backout"
                              ? "red"
                              : props.recruitment_job_status.currentStage ===
                                "Rejected"
                              ? "red"
                              : props.recruitment_job_status.currentStage ===
                                "Not in Consideration"
                              ? "red"
                              : "var(--primaryColor)",
                        }}
                      >
                        {props.recruitment_job_status.currentStage}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            {/* for Tentative joining date */}
            {location.state &&
            location.state.from &&
            location.state.from === "recruitmentList" &&
            props?.onProfileData?.roleType !== "External Recruiter" ? (
              (props.recruitment_job_status &&
                props.recruitment_job_status.offer_letter &&
                props.recruitment_job_status.currentStage &&
                props.recruitment_job_status.currentStage === "Offer Sent") ||
              props.recruitment_job_status.currentStage == "Offered" ||
              props.recruitment_job_status.currentStage == "Offer Accepted" ||
              props.recruitment_job_status.currentStage == "Hired" ? (
                <Row
                  align="middle"
                  style={{
                    margin: "15px 20px",
                    padding: "20px 2px",
                    background: "var(--secondary-card-color)",
                    borderRadius: "6px",
                  }}
                >
                  <Col md={24}>
                    <Row>
                      <Col xs={11} md={24} lg={11}>
                        <p
                          className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}`}
                          style={{
                            color: "var(--body-text-color)",
                            textAlign: "center",
                          }}
                        >
                          Tentative Joining Date
                        </p>
                      </Col>
                      <Col xs={11} md={19} lg={10}>
                        <p
                          className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}
                     ${commonCssStyles.coursorPointer}
                     `}
                          style={{
                            color: "var(--body-text-color)",
                            textAlign: "center",
                          }}
                        >
                          {props.recruitment_job_status &&
                          props.recruitment_job_status.offer_letter &&
                          props.recruitment_job_status.offer_letter
                            .joiningDate &&
                          props.recruitment_job_status.offer_letter.joiningDate
                            .length > 1
                            ? moment(
                                props.recruitment_job_status &&
                                  props.recruitment_job_status.offer_letter &&
                                  props.recruitment_job_status.offer_letter
                                    .joiningDate &&
                                  props.recruitment_job_status.offer_letter
                                    .joiningDate
                              ).format(dataFormate)
                            : "N/A"}
                        </p>
                      </Col>
                      <Col
                        md={1}
                        lg={1}
                        onClick={() => {
                          setTetativePopup(true);
                        }}
                        className={`${commonCssStyles.coursorPointer}`}
                      >
                        <EditBorderIcon color={"var(--primaryColor)"} />
                      </Col>
                    </Row>
                  </Col>

                  <Col md={24}>
                    <Row>
                      <Col xs={11} md={24} lg={11}>
                        <p
                          className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}`}
                          style={{
                            color: "var(--body-text-color)",
                            textAlign: "center",
                          }}
                        >
                          CTC (in Lakhs)
                        </p>
                      </Col>
                      <Col xs={11} md={19} lg={10}>
                        <p
                          className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}
                     ${commonCssStyles.coursorPointer}
                     `}
                          style={{
                            color: "var(--body-text-color)",
                            textAlign: "center",
                          }}
                        >
                          {props.recruitment_job_status &&
                          props.recruitment_job_status.offer_letter &&
                          props.recruitment_job_status.offer_letter.ctc != "0"
                            ? props.recruitment_job_status.offer_letter.ctc
                            : "N/A"}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {/* end of Tentative joining date  */}

            <Row
              style={{
                margin: "15px 20px",
                padding: "20px",
                background: "var(--secondary-card-color)",
                borderRadius: "6px",
                overflow: "hidden",
              }}
            >
              <Col xs={24} md={24}>
                <Row>
                  <Col md={24}>
                    <p
                      style={{ fontSize: "20px", paddingBottom: "19px" }}
                      className={commonCssStyles.summaryInfoMainText}
                    >
                      Contact Information
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24}>
                    <Row>
                      <Col>
                        <EnvelopeForSummaryIcon color={"var(--icons-color)"} />
                      </Col>
                      <Col>
                        <p
                          style={{ paddingLeft: "10px" }}
                          className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.textBreak} ${commonCssStyles.detailsInfoTextColor}`}
                        >
                          {location.state.from === "candidateList" &&
                            props.candidateData &&
                            props.candidateData.email}
                          {location.state.from === "recruitmentList" &&
                            props.RecruitmentCandidate &&
                            props.RecruitmentCandidate.email}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <PhoneForSummaryIcon color={"var(--icons-color)"} />
                      </Col>
                      <Col>
                        <p
                          style={{ paddingLeft: "10px" }}
                          className={`${commonCssStyles.textBreak} ${commonCssStyles.marginBottomRemove} ${commonCssStyles.detailsInfoTextColor}`}
                        >
                          {location.state.from === "candidateList" &&
                            props.candidateData &&
                            props.candidateData.phone}
                          {location.state.from === "recruitmentList" &&
                            props.RecruitmentCandidate &&
                            props.RecruitmentCandidate.phone}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <WhatsappIcon color={"var(--icons-color)"} />
                      </Col>
                      <Col>
                        <p
                          style={{ paddingLeft: "10px" }}
                          className={`${commonCssStyles.textBreak} ${commonCssStyles.marginBottomRemove} ${commonCssStyles.detailsInfoTextColor}`}
                        >
                          {location.state.from === "candidateList" &&
                            props.candidateData &&
                            props.candidateData.whatsApp}
                          {location.state.from === "recruitmentList" &&
                            props.RecruitmentCandidate &&
                            props.RecruitmentCandidate.whatsApp}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {props.permissions &&
              props.permissions.find((x) => x === "candidate-edit") &&
              location.state &&
              location.state.from === "candidateList" && (
                <Row
                  align="middle"
                  style={{
                    margin: "15px 20px",
                    padding: "20px",
                    background: "var(--secondary-card-color)",
                    borderRadius: "6px",
                  }}
                >
                  <Col xs={24}>
                    <Row justify="space-between">
                      <Col xs={11}>
                        <p
                          className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftMainCont}`}
                          style={{
                            color: "var(--primaryColor)",
                            textAlign: "left",
                          }}
                        >
                          Status
                        </p>
                      </Col>
                      <Col xs={11}>
                        <Row justify="space-around">
                          <Col>
                            <p
                              style={{ color: "var(--body-text-color)" }}
                              className={`${commonCssStyles.marginBottomRemove}`}
                            >
                              {props.candidateData &&
                              props.candidateData.status &&
                              props.candidateData.status === "active"
                                ? "Active"
                                : "Inactive"}
                            </p>
                          </Col>

                          <Col>
                            {props.candidateData &&
                            props.candidateData.status &&
                            props.candidateData.status === "active" ? (
                              <Switch
                                checked={true}
                                onChange={() => {
                                  props.getCheckPermissionHandler(
                                    "candidate-edit",
                                    (success) => {
                                      if (success) {
                                        props.putCandidateTemplateHandler(
                                          {
                                            id: props.candidateData.id,
                                            _method: "PUT",
                                            status: "inactive",
                                          },
                                          "",
                                          (success) => {}
                                        );
                                      } else {
                                        setOpenPMModal({
                                          open: true,
                                          permission: "Candidate Edit",
                                        });
                                      }
                                    }
                                  );
                                }}
                                size="small"
                              />
                            ) : (
                              <Switch
                                onChange={() => {
                                  props.getCheckPermissionHandler(
                                    "candidate-edit",
                                    (success) => {
                                      if (success) {
                                        props.putCandidateTemplateHandler(
                                          {
                                            id: props.candidateData.id,

                                            _method: "PUT",
                                            status: "active",
                                          },
                                          "",
                                          (success) => {}
                                        );
                                      } else {
                                        setOpenPMModal({
                                          open: true,
                                          permission: "Candidate Edit",
                                        });
                                      }
                                    }
                                  );
                                }}
                                size="small"
                              />
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            <Row
              align="middle"
              style={{
                margin: "15px 20px",
                padding: "20px 10px",
                background: "var(--secondary-card-color)",
                borderRadius: "6px",
              }}
            >
              <Col xs={11} md={11}>
                <p
                  className={styles.candidateSummaryLeftMainCont}
                  style={{ color: "var(--primaryColor)" }}
                >
                  {location.state.from === "candidateList" &&
                    props.candidateData &&
                    props.candidateData.shortlistedJobCount}
                  {location.state.from === "recruitmentList" &&
                    props.RecruitmentCandidate &&
                    props.RecruitmentCandidate.appiledJobCount}
                </p>
                <p
                  className={`${commonCssStyles.textBreak} ${styles.candidateSummaryLeftSubCont}`}
                  style={{
                    color: "var(--body-text-color)",
                    textAlign: "center",
                  }}
                >
                  Applied job count
                </p>
              </Col>
              <Col xs={1} md={1}>
                <Divider
                  type="vertical"
                  style={{
                    background: "#FFFFFF",
                    width: "5px",
                    height: "68px",
                    borderRadius: "4px",
                  }}
                />
              </Col>
              <Col xs={11} md={11}>
                <p
                  className={`${commonCssStyles.textBreak} ${styles.candidateSummaryLeftMainCont}`}
                  style={{ color: "var(--primaryColor)" }}
                >
                  {location.state.from === "candidateList" &&
                    props.candidateData &&
                    props.candidateData.activeJobCount}
                  {location.state.from === "recruitmentList" &&
                    props.RecruitmentCandidate &&
                    props.RecruitmentCandidate.activeJobCount}
                </p>
                <p
                  className={`${commonCssStyles.textBreak} ${styles.candidateSummaryLeftSubCont}`}
                  style={{
                    color: "var(--body-text-color)",
                    textAlign: "center",
                  }}
                >
                  Active job count
                </p>
              </Col>
            </Row>

            {/* for showing interview attendance ratio and interview performance ratio  */}

            <Row
              align="middle"
              style={{
                margin: "15px 20px",
                padding: "15px 0px",
                background: "var(--secondary-card-color)",
                borderRadius: "6px",
              }}
            >
              <Col xs={11} md={11} lg={10}>
                <p
                  className={styles.candidateSummaryLeftMainCont}
                  style={{ color: "var(--primaryColor)" }}
                >
                  {location.state.from === "candidateList" &&
                    props.candidateData &&
                    props.candidateData.interviewAttendanceRatio}
                  {location.state.from === "recruitmentList" &&
                    props.RecruitmentCandidate &&
                    props.RecruitmentCandidate.interviewAttendanceRatio}
                </p>
                <p
                  className={`${commonCssStyles.textBreak} ${styles.candidateSummaryLeftSubCont}`}
                  style={{
                    color: "var(--body-text-color)",
                    textAlign: "center",
                  }}
                >
                  Interview Attendance
                </p>
              </Col>
              <Col xs={1} md={1} lg={1}>
                <Divider
                  type="vertical"
                  style={{
                    background: "#FFFFFF",
                    width: "5px",
                    height: "68px",
                    borderRadius: "4px",
                  }}
                />
              </Col>
              <Col xs={11} md={11} lg={11}>
                <p
                  className={`${commonCssStyles.textBreak} ${styles.candidateSummaryLeftMainCont}`}
                  style={{ color: "var(--primaryColor)" }}
                >
                  {location.state.from === "candidateList" &&
                    props.candidateData &&
                    props.candidateData.interviewPerformance}
                  {location.state.from === "recruitmentList" &&
                    props.RecruitmentCandidate &&
                    props.RecruitmentCandidate.interviewPerformance}
                </p>
                <p
                  className={`${commonCssStyles.textBreak} ${styles.candidateSummaryLeftSubCont}`}
                  style={{
                    color: "var(--body-text-color)",
                    textAlign: "center",
                  }}
                >
                  Interview Performance
                </p>
              </Col>
            </Row>

            {/* end of interview performance ratio  */}

            {/* for showing hiring status  */}

            {location.state.from === "recruitmentList" && (
              <Row
                align="middle"
                style={{
                  margin: "15px 20px",
                  padding: "20px 10px",
                  background: "var(--secondary-card-color)",
                  borderRadius: "6px",
                }}
              >
                <Col xs={11} md={24} lg={7}>
                  <p
                    className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}`}
                    style={{
                      color: "var(--body-text-color)",
                      textAlign: "center",
                    }}
                  >
                    Recruiter
                  </p>
                </Col>
                <Col xs={11} md={24} lg={15}>
                  {props.RecruitmentCandidate &&
                  props.RecruitmentCandidate.recruiters &&
                  props.RecruitmentCandidate.recruiters.length > 1 ? (
                    <>
                      <Row align="middle">
                        <Col md={15}>
                          <p
                            className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}
                        `}
                            style={{
                              color: "var(--body-text-color)",
                              textAlign: "center",
                            }}
                          >
                            {props.RecruitmentCandidate &&
                              props.RecruitmentCandidate.recruiters &&
                              props.RecruitmentCandidate.recruiters[0].name}
                            {/* &nbsp; */}
                          </p>
                        </Col>
                        <Col md={2}>
                          <p
                            className={`                       
                        ${commonCssStyles.coursorPointer}
                        ${styles.candidateSummaryLeftSubCont}
                        `}
                            style={{ marginTop: "20px" }}
                            onClick={() => {
                              setOpenrecruiter(true);
                            }}
                          >
                            (+1)
                          </p>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <p
                      className={`${commonCssStyles.marginBottomRemove} ${styles.candidateSummaryLeftSubCont}
                     ${commonCssStyles.coursorPointer}
                     `}
                      style={{
                        color: "var(--body-text-color)",
                        textAlign: "center",
                      }}
                    >
                      {props.RecruitmentCandidate &&
                      props.RecruitmentCandidate.recruiters &&
                      props.RecruitmentCandidate.recruiters.length == "1"
                        ? props.RecruitmentCandidate.recruiters[0].name
                        : "NA"}
                    </p>
                  )}
                </Col>
              </Row>
            )}

            {/* end of showing hiring status  */}

            {location.state.from === "recruitmentList" &&
              props.permissions &&
              props.permissions.find(
                (data) => data === "recruitment-candidate-offerLetter"
              ) && (
                <Row
                  justify="space-between"
                  style={{
                    margin: "15px 20px",
                    padding: "20px 10px",
                  }}
                >
                  <Col xs={24} md={24}>
                    {props.recruitment_job_status &&
                      props.recruitment_job_status.currentStage ==
                        "Offer Sent" && (
                        <Button
                          className={commonCssStyles.buttonSettingsPrimary}
                          onClick={() => {
                            props.getCheckPermissionHandler(
                              "recruitment-candidate-offerLetter",
                              (success) => {
                                if (success) {
                                  setDraftModalOpen(true);
                                  setDraftModalOpen(true);
                                } else {
                                  setOpenPMModal({
                                    open: true,
                                    permission: "Offer Letter",
                                  });
                                }
                              }
                            );
                          }}
                          style={{ width: "100%", height: "64px" }}
                          type="primary"
                        >
                          <Row align="middle" justify="space-between">
                            <Col>
                              <p style={{ margin: "0px" }}>Offer letter</p>
                            </Col>
                            <Col>
                              <EyeIcon color={"var(--theme)"} />
                            </Col>
                          </Row>
                        </Button>
                      )}

                    {props.recruitment_job_status &&
                      props.recruitment_job_status.currentStage ==
                        "Offered" && (
                        <Button
                          className={commonCssStyles.buttonSettingsPrimary}
                          style={{ width: "100%", height: "64px" }}
                          type="primary"
                        >
                          <Row align="middle" justify="space-between">
                            <Col>
                              <p style={{ margin: "0px" }}>Offered</p>
                            </Col>

                            <Col>
                              <RightMarkIcon color={"var(--theme)"} />
                            </Col>
                          </Row>
                        </Button>
                      )}
                  </Col>
                </Row>
              )}

            {/* for draft  */}

            {/* end o draft  */}

            <Row
              justify="space-between"
              style={{
                margin: "15px 20px",
                padding: "20px 10px",
              }}
            >
              <Col xs={24} md={24}>
                {props.candidateData &&
                  props.candidateData.resume &&
                  props.permissions &&
                  props.permissions.find(
                    (data) => data === "candidate-download-resume"
                  ) && (
                    <Button
                      className={commonCssStyles.buttonSettingsPrimary}
                      onClick={() => {
                        props.getCheckPermissionHandler(
                          "candidate-download-resume",
                          (success) => {
                            if (success) {
                              window.open(
                                props.candidateData &&
                                  props.candidateData.resume,
                                "_blank"
                              );
                            } else {
                              setOpenPMModal({
                                open: true,
                                permission: "Candidate Download Resume",
                              });
                            }
                          }
                        );
                      }}
                      style={{ width: "100%", height: "64px" }}
                      type="primary"
                    >
                      <Row align="middle" justify="space-between">
                        <Col>
                          <p
                            className={`${commonCssStyles.textBreak}`}
                            style={{ margin: "0px" }}
                          >
                            Download Resume
                          </p>
                        </Col>

                        <Col>
                          <DownloadIcon color={"var(--theme)"} />
                        </Col>
                      </Row>
                    </Button>
                  )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col
          md={16}
          style={{ overflowY: "scroll", height: "100vh" }}
          className="tabsScroll"
        >
          <Tabs
            onTabClick={(val) => {
              if (val === "1") {
                props.getCheckPermissionHandler(
                  "candidate-detail",
                  (success) => {
                    if (success) {
                      setActiveKey("1");
                    } else {
                      setOpenPMModal({
                        open: true,
                        permission: "Candidate Details",
                      });
                    }
                  }
                );
              } else if (val === "2") {
                props.getCheckPermissionHandler(
                  location.state.from === "recruitmentList"
                    ? "recruitment-candidate-interview-list"
                    : "candidate-interview-list",
                  (success) => {
                    if (success) {
                      setActiveKey("2");
                    } else {
                      setOpenPMModal({
                        open: true,
                        permission:
                          location.state.from === "recruitmentList"
                            ? "Recruitment Candidate Interview List"
                            : "Candidate Interview List",
                      });
                    }
                  }
                );
              } else if (val === "3") {
                props.getCheckPermissionHandler(
                  location.state.from === "recruitmentList"
                    ? "recruitment-candidate-mail-list"
                    : "candidate-mail-list",
                  (success) => {
                    if (success) {
                      setActiveKey("3");
                    } else {
                      setOpenPMModal({
                        open: true,
                        permission:
                          location.state.from === "recruitmentList"
                            ? "Recruitment Candidate Mail List"
                            : "Candidate Mail List",
                      });
                    }
                  }
                );
              } else if (val === "4") {
                props.getCheckPermissionHandler(
                  location.state.from === "recruitmentList"
                    ? "recruitment-candidate-call"
                    : "candidate-call",
                  (success) => {
                    if (success) {
                      setActiveKey("4");
                    } else {
                      setOpenPMModal({
                        open: true,
                        permission:
                          location.state.from === "recruitmentList"
                            ? "Recruitment Candidate Call"
                            : "Candidate Call",
                      });
                    }
                  }
                );
              } else if (val === "5") {
                props.getCheckPermissionHandler(
                  location.state.from === "recruitmentList"
                    ? "recruitment-candidate-timeline"
                    : "candidate-timeline",
                  (success) => {
                    if (success) {
                      setActiveKey("5");
                    } else {
                      setOpenPMModal({
                        open: true,
                        permission:
                          location.state.from === "recruitmentList"
                            ? "Recuritment Candidate Time Line"
                            : "Candidate Timeline",
                      });
                    }
                  }
                );
              } else if (val === "6") {
                setActiveKey("6");
              } else if (val === "7") {
                props.getCheckPermissionHandler(
                  location.state.from === "recruitmentList"
                    ? "recruitment-candidate-task-list"
                    : "candidate-task-list",
                  (success) => {
                    if (success) {
                      setActiveKey("7");
                    } else {
                      setOpenPMModal({
                        open: true,
                        permission:
                          location.state.from === "recruitmentList"
                            ? "Recruitment Candidate Task List"
                            : "Candidate Task List",
                      });
                    }
                  }
                );
              } else if (val === "8") {
                if (location.state.from !== "recruitmentList") {
                  props.getCheckPermissionHandler(
                    "candidate-jobs",
                    (success) => {
                      if (success) {
                        setActiveKey("8");
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Candidate Job",
                        });
                      }
                    }
                  );
                }
              } else if (val === "9") {
                setActiveKey("9");
                if (location.state.from === "recruitmentList") {
                  const dataa = {
                    JobId:
                      location.state &&
                      location.state.jobDetailsPageId &&
                      location.state.jobDetailsPageId.id &&
                      location.state.jobDetailsPageId.id,
                    candidateId:
                      location.state && location.state.id && location.state.id,
                  };
                  props.getInterviewFeedBackdataHandler(dataa);
                }
              } else if (val === "10") {
                setActiveKey("10");
                if (
                  location.state.from === "candidateList" ||
                  location.state.from === "recruitmentList"
                ) {
                  props.getCandidatesInsightHandler(
                    location.state.id,
                    (success) => {
                      if (success) {
                        setCandidateInsightNotFound(true);
                      } else {
                        setCandidateInsightNotFound(false);
                      }
                    }
                  );
                }
              }
            }}
            className="summarytab summaryTabsFixed"
            // defaultActiveKey="1"
            activeKey={activeKey}
            style={{
              height: 220,
            }}
            items={itemsArray}
          />
        </Col>
      </Row>
      <Modal
        className="modalModifiedForTemplete"
        width={706}
        title={
          <Row>
            <Col md={20}>
              <p className={` ${styles.summaryInfoMainText}`}>Record</p>
            </Col>
            <Col md={4}>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  closeModal();
                }}
                className={`${commonCssStyles.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)"} />
              </div>
            </Col>
          </Row>
        }
        open={draftModalOpen}
        closable={false}
        onCancel={closeModal}
        footer={false}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={DraftData}
          initialValues={{
            // props.recruitment_job_status.offer_letter
            candidateName:
              props &&
              props.recruitment_job_status &&
              props.recruitment_job_status.offer_letter &&
              props.recruitment_job_status.offer_letter.candidateName &&
              props.recruitment_job_status.offer_letter.candidateName,
            designation:
              props &&
              props.recruitment_job_status &&
              props.recruitment_job_status.offer_letter &&
              props.recruitment_job_status.offer_letter.candidateName &&
              props.recruitment_job_status.offer_letter.designation,
            ctc:
              props &&
              props.recruitment_job_status &&
              props.recruitment_job_status.offer_letter &&
              props.recruitment_job_status.offer_letter.candidateName &&
              props.recruitment_job_status.offer_letter.ctc,
            joiningDate:
              (props.recruitment_job_status &&
                props.recruitment_job_status.offer_letter &&
                props.recruitment_job_status.offer_letter.candidateName &&
                props.recruitment_job_status.offer_letter.joiningDate ===
                  null) ||
              (props.recruitment_job_status &&
                props.recruitment_job_status.offer_letter &&
                props.recruitment_job_status.offer_letter.candidateName &&
                props.recruitment_job_status.offer_letter.joiningDate ===
                  undefined) ||
              (props.recruitment_job_status &&
                props.recruitment_job_status.offer_letter &&
                props.recruitment_job_status.offer_letter.candidateName &&
                props.recruitment_job_status.offer_letter.joiningDate == "")
                ? undefined
                : moment(
                    props.recruitment_job_status &&
                      props.recruitment_job_status.offer_letter &&
                      props.recruitment_job_status.offer_letter.candidateName &&
                      props.recruitment_job_status.offer_letter.joiningDate
                  ),
          }}
        >
          <Row style={{ padding: " 0px 20px 20px 20px" }}>
            <Col md={24}>
              <Form.Item label="Candidate Name" name="candidateName">
                <Input placeholder="Enter Candidate Name" />
              </Form.Item>
              <Form.Item label="Designation" name="designation">
                <Input placeholder="Enter Designation" />
              </Form.Item>
              <Form.Item label="CTC (in Lakhs)" name="ctc">
                <Input type="number" placeholder="Enter CTC" />
              </Form.Item>
              <Form.Item
                label="Tentative Joining Date"
                name="joiningDate"
                rules={[
                  {
                    required: false,
                    message: "Please select joining date",
                  },
                ]}
              >
                <DatePicker
                  placeholder="Select Tentative Joining  date"
                  format={"DD/MM/YYYY"}
                  style={{ width: "100%" }}
                  className={`${commonCssStyles.datePickerBgColor} CandidateInputHeight`}
                />
              </Form.Item>

              {/* for attcah offer letter  */}

              {props.recruitmentIdData &&
                props.recruitmentIdData.hiringType &&
                props.recruitmentIdData.hiringType == "internal" && (
                  <Row>
                    <Col>
                      {!filesData &&
                      props.recruitment_job_status.offer_letter &&
                      props.recruitment_job_status.offer_letter
                        .attachOfferletter === "" ? (
                        <></>
                      ) : !filesData && filesDisp ? (
                        <></>
                      ) : (
                        <div
                          style={{
                            margin: "22px",
                            display: "flex",
                            alignItems: "center",
                            padding: "14px 8px 17px 8px",
                            backgroundColor: "var(--progress-card)",
                            borderRadius: "8px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Image
                              onClick={(e) => {
                                e.preventDefault();
                                btnref.click();
                                return false;
                              }}
                              preview={false}
                              src={
                                !filesData
                                  ? props.recruitment_job_status.offer_letter &&
                                    props.recruitment_job_status.offer_letter
                                      .attachOfferletter &&
                                    props.recruitment_job_status.offer_letter.attachOfferletter.split(
                                      "."
                                    )[1] === "pdf"
                                    ? pdf
                                    : word
                                  : filesData.type === "application/pdf"
                                  ? pdf
                                  : word
                              }
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "6px",
                                position: "relative",
                              }}
                            />
                          </div>
                          {!filesData && (
                            <div style={{ margin: "0px 10px" }}>
                              <p
                                className={commonCssStyles.marginBottomRemove}
                                style={{ color: "var(--body-text-color)" }}
                              >
                                {props.recruitment_job_status.offer_letter &&
                                  props.recruitment_job_status.offer_letter
                                    .attachOfferletter &&
                                  props.recruitment_job_status.offer_letter.attachOfferletter.substring(
                                    0,
                                    30
                                  ) + "..."}
                              </p>
                            </div>
                          )}
                          {filesDisp && (
                            <div
                              style={{
                                width: "100%",
                                margin: "0px 10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.formInputLabel}`}
                                >
                                  {filesData.name}
                                </p>
                                <p
                                  className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.formInputLabel}`}
                                >
                                  {progress === "0"
                                    ? "0%"
                                    : progress === "50"
                                    ? "50%"
                                    : "100%"}
                                </p>
                              </div>
                              <Progress percent={progress} size="small" />
                            </div>
                          )}
                          <div>
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                setFilesData("");
                                setFilesDisp(true);

                                // btnref.click();
                                // return false;
                              }}
                              style={{
                                position: "relative",
                                paddingTop: "5px",
                                paddingLeft: "1px",
                                cursor: "pointer",
                              }}
                            >
                              <CloseIcon color={"var(--headings-color)"} />
                            </span>
                          </div>
                        </div>
                      )}

                      <input
                        accept="application/pdf,.docx"
                        hidden
                        type="file"
                        name={["fileUpload"]}
                        multiple
                        onChange={handleChange}
                        ref={(input) => {
                          btnref = input;
                        }}
                        onClick={(event) => {
                          event.currentTarget.value = null;
                        }}
                      />
                      {!filesData && (
                        <div
                          className={`dataImportUploadStyle`}
                          style={{
                            borderRadius: "0px",
                            width: "100%",
                          }}
                        >
                          <Row>
                            <Col>
                              <Row>
                                <Col>
                                  <p
                                    onClick={(e) => {
                                      e.preventDefault();
                                      btnref.click();
                                      return false;
                                    }}
                                    style={{ textAlign: "center" }}
                                    className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.formInputLabel}`}
                                  >
                                    <Row style={{ gap: "4px" }}>
                                      <Col>
                                        <OfferLetterIcon />
                                      </Col>
                                      <Col> Attach offer letter</Col>
                                    </Row>
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                  </Row>
                )}

              {/* end off attach offer letter  */}

              {props.recruitmentIdData &&
                props.recruitmentIdData.hiringType &&
                props.recruitmentIdData.hiringType == "internal" && (
                  <Row align="middle" style={{ gap: "17px" }}>
                    <Col>
                      <p className={`${commonCssStyles.formInputLabel} `}>
                        Notify Candidate
                      </p>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Switch
                          onChange={(val) => {
                            setNotify(val);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
            </Col>
          </Row>
          <Row
            className={commonCssStyles.footerShadowForTemplete}
            style={{ gap: "17px" }}
            justify="end"
          >
            <Col>
              <Button
                className={commonCssStyles.buttonSettingsSecondary}
                style={{
                  width: "177px",
                  height: "45px",
                }}
                onClick={() => {
                  if (filesDisp) {
                    setFilesDisp(false);
                  }
                  closeModal();
                }}
                type="default"
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                className={commonCssStyles.buttonSettingsPrimary}
                style={{
                  width: "177px",
                  height: "45px",
                }}
                htmlType="submit"
                type="primary"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
          props.setIsHavepermissionsHandler(false);
        }}
      />

      {/* for displaying recruiter  */}
      <Modal
        open={openrecruter}
        footer={false}
        closable={false}
        width={550}
        title={
          <Row>
            <Col md={20}>
              <p
                className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.popUpHeadingText} ${commonCssStyles.textBreak}`}
              >
                Recruiter Assigned Details
              </p>
            </Col>
            <Col md={4}>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  setOpenrecruiter(false);
                }}
                className={`${commonCssStyles.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
      >
        <Table
          style={{ marginTop: "-20px" }}
          scroll={{ x: "auto" }}
          className="AssignRecruiterTable"
          dataSource={props.RecruitmentCandidate.recruiters}
          columns={columns}
          pagination={false}
        />
      </Modal>
      {/* end of displaying recruiter  */}

      {/* tentative popup  */}

      <Modal
        open={tentativePopup}
        closable={false}
        footer={false}
        onCancel={closeTentativePopup}
        className="modalModifiedForTemplete"
        width={650}
        title={
          <Row>
            <Col md={20}>
              <p className={` ${styles.summaryInfoMainText}`}>
                Tentative Joining Date
              </p>
            </Col>
            <Col md={4}>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  closeTentativePopup();
                  formT.resetFields();
                }}
                className={`${commonCssStyles.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
      >
        <Row style={{ padding: " 0px 20px 20px 20px" }}>
          <Col sm={24} md={24} lg={24}>
            <Form
              form={formT}
              layout="vertical"
              initialValues={{
                joiningDate:
                  props.recruitment_job_status &&
                  props.recruitment_job_status.offer_letter &&
                  props.recruitment_job_status.offer_letter.joiningDate &&
                  moment(props.recruitment_job_status.offer_letter.joiningDate),
                ctc:
                  props.recruitment_job_status &&
                  props.recruitment_job_status.offer_letter &&
                  props.recruitment_job_status.offer_letter.ctc &&
                  props.recruitment_job_status.offer_letter.ctc,
              }}
              // initialValues={
              //   {
              //     joiningDate:"2022-12-4"
              //   }
              // }
              onFinish={TentativeJoiningDate}
            >
              <Form.Item
                label="Tentative Joining Date"
                name="joiningDate"
                rules={[
                  {
                    required: false,
                    message: "Please select joining date",
                  },
                ]}
              >
                <DatePicker
                  placeholder="Select Tentative Joining  date"
                  format={dataFormate}
                  style={{ width: "100%" }}
                  className={`${commonCssStyles.datePickerBgColor} CandidateInputHeight`}
                />
              </Form.Item>
              <Form.Item label="CTC (in Lakhs)" name="ctc">
                <Input type="number" placeholder="Enter CTC" />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row
          className={commonCssStyles.footerShadowForTemplete}
          style={{ gap: "17px" }}
          justify="end"
        >
          <Col>
            <Button
              className={commonCssStyles.buttonSettingsSecondary}
              style={{
                width: "130px",
                height: "45px",
              }}
              onClick={() => {
                closeTentativePopup();
                formT.resetFields();
              }}
              type="default"
            >
              Cancel
            </Button>
          </Col>
          {/* <Col>
            <Button
              className={commonCssStyle.buttonSettingsPrimary}
              style={{
                width: "177px",
                height: "45px",
              }}
              htmlType="submit"
              // type="primary"
            >
              Save Draft
            </Button>
          </Col> */}
          <Col>
            <Button
              onClick={() => {
                formT.submit();
              }}
              className={commonCssStyles.buttonSettingsPrimary}
              style={{
                width: "130px",
                height: "45px",
              }}
              htmlType="submit"
              type="primary"
            >
              Save
            </Button>
          </Col>
        </Row>
      </Modal>

      {/* end of tentative popup  */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    candidateData: state.candidates.candidate,
    jobsData: state.requirements.requirements,
    candidateInterviewData: state.candidates.candidateInterview,
    settings: state.settings.settings,
    interviewDetailsData: state.recruitment.interview_details,
    recruitment_job_status: state.recruitment.recruitment_job_status,
    get_all_status: state.recruitment.get_all_status,
    RecruitmentCandidate: state.candidates.RecruitmentCandidate,
    task: state.taskAndCalendar.taskFilterData,
    candidateCalls: state.candidates.candidateCalls,
    recruitment_call_logs: state.recruitment.recruitment_call_logs,
    permissions: state.authpermissions.permissions,

    //get interview feedback
    getInterviewFeedbackction: state.recruitment.getInterviewFeedback,

    //for getting job internal or external details
    recruitmentIdData: state.requirements.requirement,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getJobNameHandler: () => {
      dispatch(getRequirementsAction());
    },
    getInterviewHandler: (id) => {
      dispatch(getCandidateInterviewAction(id));
    },
    getInterviewDetailsHandler: (id, callback) => {
      dispatch(getInterViewAction(id, callback));
    },
    getCandidatejobStatusHandler: (id) => {
      dispatch(getRecruitmentsCandidatesJobStatusAction(id));
    },
    getRecruitmentAllStatusHandler: () => {
      dispatch(getRecruitmentAllStatusAction());
    },
    putRecruitmentJobStatusHandler: (val, callback) => {
      dispatch(putRecruitmentCandidateStatusAction(val, callback));
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
    // getCandidateJobStatus:(id)=>{
    //   dispatch()
    // }
    //recruitment
    getTaskByIdHandler: (id, link, callback) => {
      dispatch(getTaskAndCalendarAction(id, link, callback));
    },
    getRecruitmentCandidateHandler: (id) => {
      dispatch(getRecruitmentCandidateAction(id));
    },

    //canidates call logs
    getCandidatesCallLogsAction: (id) => {
      dispatch(getCandidatesCallLogsAction(id.id));
    },

    //for recruitment call logs
    getRecruitmentCallLogsAction: (id) => {
      dispatch(getRecruitmentCallLogsAction(id));
    },

    //for interview feedback form data
    getInterviewFeedBackdataHandler: (data) => {
      dispatch(getInterviewFeedbackction(data));
    },
    // candidate status cahange
    putCandidateTemplateHandler: (data, type, callback) => {
      dispatch(putCandidatesAction(data, type, callback));
    },

    //for getting job external or internal
    getRequirementHandler: (id) => {
      dispatch(getRequirementAction(id));
    },

    //for candidate insight
    getCandidatesInsightHandler: (id, callback) => {
      dispatch(getCandidatesInsightAction(id, callback));
    },
    activeMenuKeyHandle: (data) => {
      dispatch(activeMenuKeySlice(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateTabs);
