import {
  getPermissionSlice,getPermissionsSlice,postPermissionSlice
} from "../../Slice/Master/MasterpermissionSlice";
import {  } from "../../masterpath";
import { message } from "antd";
import AxiosApi from "../../api";
import { PERMISSIONS_URL } from "../../apipath";
const api = new AxiosApi();
export const getPermissionsAction = () => {
  return (dispatch) => {
    api.invoke(PERMISSIONS_URL, "get", (data, success, stateCode) => {
      if (success) {
        if (stateCode === 200) {
          dispatch(getPermissionsSlice(data));
        } else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const postPermission = (data,callback) => {
  return (dispatch) => {
    api.invoke(
      PERMISSIONS_URL,
      "post",
      (data, success, stateCode) => {
        callback(false);
        if (success) {
          if (stateCode === 200) {
            callback(true);

            message.destroy();
            message.success(data.message, 3);
            dispatch(postPermissionSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const getPermissionAction = (id) => {
  return (dispatch) => {
    api.invoke(PERMISSIONS_URL + "/" + id, "get", (data, success, stateCode) => {
      if (success) {
        if (stateCode === 200) {
          dispatch(getPermissionSlice(data));
        } else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};

