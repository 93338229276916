import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  interviewAssessmentQuestions: [],
  interviewAssessmentQuestion: null,
};
export const masterinterviewAssessmentQuestionSlice = createSlice({
  name: "interviewAssessmentQuestions",
  initialState,
  reducers: {
    getInterviewAssissmentQuestionsSlice(state, action) {
      state.interviewAssessmentQuestions = action.payload.result;
    },
    getInterviewAssissmentQuestionSlice(state, action) {
      state.interviewAssessmentQuestion = action.payload.result;
    },
    postInterviewAssissmentQuestionsSlice(state, action) {
      state.interviewAssessmentQuestions = [
        ...state.interviewAssessmentQuestions,
        action.payload.result,
      ];
    },
    putInterviewAssissmentQuestionsSlice(state, action) {
      state.interviewAssessmentQuestions =
        state.interviewAssessmentQuestions.map((data) => {
          if (data.id === action.payload.result.id) {
            return action.payload.result;
          } else {
            return data;
          }
        });
    },
    deleteInterviewAssissmentQuestionsSlice(state, action) {
      state.interviewAssessmentQuestions =
        state.interviewAssessmentQuestions.filter(
          (data) => data.id !== action.payload
        );
    },
  },
});
export const {
  getInterviewAssissmentQuestionsSlice,
  getInterviewAssissmentQuestionSlice,
  postInterviewAssissmentQuestionsSlice,
  putInterviewAssissmentQuestionsSlice,
  deleteInterviewAssissmentQuestionsSlice,
} = masterinterviewAssessmentQuestionSlice.actions;
export default masterinterviewAssessmentQuestionSlice.reducer;
