import {
  checkPermissionReducer,
  permissionsReducer,
} from "../Slice/AuthPermissionsSlice";
import { CHECK_PERMISSION_URL, AUTH_PERMISSIONS_URL } from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";

const api = new AxiosApi();

export const permissionsAction = () => {
  return (dispatch) => {
    api.invoke(AUTH_PERMISSIONS_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) {
          dispatch(permissionsReducer(data));
        } else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};

export const checkPermissionAction = (id, callback) => {
  return (dispatch) => {
    api.invoke(
      CHECK_PERMISSION_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            // dispatch(checkPermissionReducer(true));
            callback(true);
          } else {
            callback(false);
            // dispatch(checkPermissionReducer(false));
            // message.destroy();
            // message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
