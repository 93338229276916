import { Breadcrumb } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import commonCssStyle from "../../UI/CommonCss.module.css";
import PermissionMessage from "../../UI/PermissionMessage";
function BreadcrumbForRequirement(props) {
  const navigate = useNavigate();
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  const array = [
    "Home",
    "Requirement",
    `Job Details (${props.name && props.name.title && props.name.title})`,
  ];
  return (
    <>
      <Breadcrumb>
        {props.slide.map((data, index) => {
          return (
            <Breadcrumb.Item key={index}>
              <p
                className={` ${commonCssStyle.breadcrumbText} ${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove}`}
                onClick={() => {
                  if (data === 1) {
                    props.getCheckPermissionHandler("dashboard", (success) => {
                      if (success) {
                        navigate("/dashboard");
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Dashboard",
                        });
                      }
                    });
                  } else if (data === 2) {
                    props.permissions &&
                    props.permissions.find(
                      (data) =>
                        data === "job-list" ||
                        data === "job-add" ||
                        data === "job-edit" ||
                        data === "job-delete" ||
                        data === "job-detail" ||
                        data === "job-assign-employee" ||
                        data === "job-task-list"
                    )
                      ? navigate("/requirement")
                      : setOpenPMModal({
                          open: true,
                          permission: "Job",
                        });
                  } else if (data === 3) {
                    props.getCheckPermissionHandler("job-detail", (success) => {
                      if (success) {
                        navigate("/JobDetailPage", {
                          state: { id: props.name.id, activeTabKey: "1" },
                        });
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Job Detail Page",
                        });
                      }
                    });
                  }
                }}
              >
                {array[index]}
              </p>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
          props.setIsHavepermissionsHandler(false);
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BreadcrumbForRequirement);
