import { Button, Col, Form, Input, Row, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { CloseIcon } from "../../IconsComp/Icons";
import commonCssStyles from "../../UI/CommonCss.module.css";
function EmployeeCredentialsModal(props) {
  const [form] = useForm();
  return (
    <Modal
    closable={false}

      className="modalModifiedForTemplete"
      footer={false}
      open={props.credentialsDetailsOpen}
      title={
       <Row style={{gap:"298px"}}>
        <Col> <p
          style={{ fontSize: "20px", margin: "0px" }}
          className={`${commonCssStyles.popUpHeadingText}`}
        >
          Edit Credentials
        </p></Col>
        <Col> <div
        style={{float:"right"}}
        onClick={() => {
          props.credentialsDetailsClose();
        }}
        className={`${commonCssStyles.coursorPointer} `}
        >
          <CloseIcon   color={"var(--body-text-color)" || "var(--theme)"} />
        </div></Col>
       </Row>
      }
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={
          props.employee && {
            password: props.employee.password,
            username: props.employee.username,
          }
        }
        onFinish={props.onSubmit}
      >
        <Row style={{ padding: "10px 20px" }}>
          <Col md={24}>
            <Row>
              <Col md={24}>
                <Form.Item
                  name="username"
                  label="User Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter username",
                    },
                  ]}
                >
                  <Input placeholder="Enter username" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    { required: true, message: "Please enter password" },
                    // {
                    //   pattern:
                    //     /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[!\\@#$%^&._"'()+]){1,})(?!.*\s).{8,}$/,
                    //   message:
                    //     "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character!",
                    // },
                  ]}
                >
                  <Input.Password
                    style={{ backgroundColor: "var(--input-color)" }}
                    placeholder="Enter password"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          justify="end"
          style={{ gap: "17px" }}
          className={commonCssStyles.footerShadowForTemplete}
        >
          <Col>
            <Button
              className={
                commonCssStyles.footerButtonAddCandidateDrawerSecondary
              }
              onClick={() => {
                props.credentialsDetailsClose();
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                form.submit();
              }}
              htmlType="button"
              className={commonCssStyles.footerButtonAddCandidateDrawerPrimary}
              type="primary"
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export default EmployeeCredentialsModal;
