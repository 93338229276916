import { Card, Col, Row } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import styles from "../AddCandidatesComp.module.css";
import commonCss from "../../../UI/CommonCss.module.css";
function CandidateInsight(props) {
  const [hoverKey, setHoverKey] = useState("0");
  return (
    <>
      {props.onCandidateInsightNotFound && props.onCandidateInsightNotFound ? (
        <Card>
          <Row justify="space-evenly" style={{ margin: "20px" }}>
            <Col>
              <Card
                className={styles.cards}
                onMouseEnter={() => {
                  setHoverKey("1");
                }}
                onMouseLeave={() => {
                  setHoverKey("0");
                }}
                style={{
                  border:
                    hoverKey === "1"
                      ? `3px solid var(--primaryColor)`
                      : "3px solid var(--theme)",
                }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.candidateInsight &&
                        props.candidateInsight.companyCount &&
                        props.candidateInsight.companyCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={`${styles.candidateSummaryCardSubTitle}`}>
                      Companies
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                className={styles.cards}
                onMouseEnter={() => {
                  setHoverKey("2");
                }}
                onMouseLeave={() => {
                  setHoverKey("0");
                }}
                style={{
                  border:
                    hoverKey === "2"
                      ? `3px solid var(--primaryColor)`
                      : "3px solid var(--theme)",
                }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.candidateInsight &&
                        props.candidateInsight.jobsCount &&
                        props.candidateInsight.jobsCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>
                      Jobs Assigned
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                className={styles.cards}
                onMouseEnter={() => {
                  setHoverKey("8");
                }}
                onMouseLeave={() => {
                  setHoverKey("0");
                }}
                style={{
                  border:
                    hoverKey === "8"
                      ? `3px solid var(--primaryColor)`
                      : "3px solid var(--theme)",
                }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.candidateInsight &&
                        props.candidateInsight.screeningCount &&
                        props.candidateInsight.screeningCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>
                      Screening
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row justify="space-evenly" style={{ margin: "20px" }}>
          <Col>
              <Card
                className={styles.cards}
                onMouseEnter={() => {
                  setHoverKey("9");
                }}
                onMouseLeave={() => {
                  setHoverKey("0");
                }}
                style={{
                  border:
                    hoverKey === "9"
                      ? `3px solid var(--primaryColor)`
                      : "3px solid var(--theme)",
                }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.candidateInsight &&
                        props.candidateInsight.interviewingCount &&
                        props.candidateInsight.interviewingCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>
                      Interview
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                className={styles.cards}
                onMouseEnter={() => {
                  setHoverKey("5");
                }}
                onMouseLeave={() => {
                  setHoverKey("0");
                }}
                style={{
                  border:
                    hoverKey === "5"
                      ? `3px solid var(--primaryColor)`
                      : "3px solid var(--theme)",
                }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.candidateInsight &&
                        props.candidateInsight.interviewAttendanceRatio &&
                        props.candidateInsight.interviewAttendanceRatio}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>
                      Interview Attendance Ratio
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                className={styles.cards}
                onMouseEnter={() => {
                  setHoverKey("6");
                }}
                onMouseLeave={() => {
                  setHoverKey("0");
                }}
                style={{
                  border:
                    hoverKey === "6"
                      ? `3px solid var(--primaryColor)`
                      : "3px solid var(--theme)",
                }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.candidateInsight &&
                        props.candidateInsight.interviewOverAllRating &&
                        props.candidateInsight.interviewOverAllRating}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>
                       Interview Rating
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row justify="space-evenly" style={{ margin: "20px" }}>
          <Col>
              <Card
                className={styles.cards}
                onMouseEnter={() => {
                  setHoverKey("7");
                }}
                onMouseLeave={() => {
                  setHoverKey("0");
                }}
                style={{
                  border:
                    hoverKey === "7"
                      ? `3px solid var(--primaryColor)`
                      : "3px solid var(--theme)",
                }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.candidateInsight &&
                        props.candidateInsight.AppliedCount &&
                        props.candidateInsight.AppliedCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>
                    Active External Job Application
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                className={styles.cards}
                onMouseEnter={() => {
                  setHoverKey("10");
                }}
                onMouseLeave={() => {
                  setHoverKey("0");
                }}
                style={{
                  border:
                    hoverKey === "10"
                      ? `3px solid var(--primaryColor)`
                      : "3px solid var(--theme)",
                }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.candidateInsight &&
                        props.candidateInsight.OfferedCount &&
                        props.candidateInsight.OfferedCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>Offered</p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                className={styles.cards}
                onMouseEnter={() => {
                  setHoverKey("3");
                }}
                onMouseLeave={() => {
                  setHoverKey("0");
                }}
                style={{
                  border:
                    hoverKey === "3"
                      ? `3px solid var(--primaryColor)`
                      : "3px solid var(--theme)",
                }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.candidateInsight &&
                        props.candidateInsight.offerAcceptedCount &&
                        props.candidateInsight.offerAcceptedCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>
                    Offer Accepted
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>         
           
           
          
          </Row>
          <Row justify="space-evenly" style={{ margin: "20px" }}>
          <Col>
              <Card
                className={styles.cards}
                onMouseEnter={() => {
                  setHoverKey("12");
                }}
                onMouseLeave={() => {
                  setHoverKey("0");
                }}
                style={{
                  border:
                    hoverKey === "12"
                      ? `3px solid var(--primaryColor)`
                      : "3px solid var(--theme)",
                }}
              >
                <Row>
                  <Col md={24}>
                    <h3
                      style={{ color: "#2f7bf5" }}
                      className={styles.candidateSummaryCardTitle}
                    >
                      {props.candidateInsight &&
                        props.candidateInsight.DeclinedCount &&
                        props.candidateInsight.DeclinedCount}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={24}>
                    <p className={styles.candidateSummaryCardSubTitle}>
                    Offer Declined
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Card>
      ) : (
        <Card>
          <Row style={{ margin: "20px" }}>
            <Col xs={24}>
              <p
                style={{ color: "var(--body-text-color)", textAlign: "center" }}
              >
                No data
              </p>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    candidateInsight: state.candidates.candidateInsight,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(CandidateInsight);
