import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  status: [],
  statusId: null,
};
export const masterStatusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    getStatusSlice(state, action) {
      state.status = action.payload.result.sort((a,b)=>a.sortOrder-b.sortOrder);
    },
    getStatusIdSlice(state, action) {
      state.statusId = action.payload.result;
    },
    postStatusSlice(state, action) {
      state.status = [...state.status, action.payload.result];
    },
    putStatusSlice(state, action) {
      state.status = state.status.map((data) => {
        if (data.id === action.payload.result.id) {
          return action.payload.result;
        } else {
          return data;
        }
      });
    },
    deleteStatusSlice(state, action) {
      state.status = state.status.filter((data) => data.id !== action.payload);
    },
  },
});
export const {
  getStatusSlice,
  getStatusIdSlice,
  postStatusSlice,
  putStatusSlice,
  deleteStatusSlice,
} = masterStatusSlice.actions;
export default masterStatusSlice.reducer;
