import { Col, Row, Select, Table } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import {
  getTaskByTypeAction,
  putTaskAndCalendarAction,
} from "../../../Reusable/Store/Action/TaskAndCalendarAction";
import { putTaskAndCalendarSlice } from "../../../Reusable/Store/Slice/TaskAndCalendarSlice";
import commonCssStyle from "../../UI/CommonCss.module.css";
const EmployeeTask = (props) => {
  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const location = useLocation();
  useEffect(() => {
    props.getTaskByTypeHandler(
      // props.type === "candidate" ? location.state.id : location.state.id,
      props.type === "company" ? location.state : location.state.id,
      props.type
    );
  }, []);
  const columns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Task Name
        </p>
      ),
      dataIndex: "title",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "Null"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Assigned by
        </p>
      ),
      dataIndex: "assignedByName",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "Null"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Start Date
        </p>
      ),
      dataIndex: "startDate",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? moment(data).format(dataFormate) : "Null"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Due Date
        </p>
      ),
      dataIndex: "dueDate",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? moment(data).format(dataFormate) : "Null"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Status
        </p>
      ),
      dataIndex: "status",

      width: 150,
      render: (text, record) =>
        text !== "completed" ? (
          <Select
            size="small"
            className="intellselecttask"
            // defaultValue={text}
            value={text}
            onChange={(val) => {
              props.putTaskAndCalendarHandler(
                { id: record.id, status: val },
                (success) => {
                  if (success) {
                    props.getTaskByTypeHandler(
                      props.type === "company"
                        ? location.state
                        : location.state.id,
                      props.type
                    );
                  }
                }
              );
            }}
            style={{ color: "#FF9A02", width: "120px" }}
          >
            <Select.Option value="pending">Pending</Select.Option>
            <Select.Option value="on hold">On hold</Select.Option>
            <Select.Option value="in progress">In Progress</Select.Option>
            <Select.Option value="completed">Completed</Select.Option>
          </Select>
        ) : (
          <Select
            size="small"
            className="intellselecttask"
            // defaultValue={text}
            value={text}
            style={{ color: "#0E8553", width: "120px" }}
            onChange={(val) => {
              props.putTaskAndCalendarHandler(
                { id: record.id, status: val },
                (success) => {
                  if (success) {
                    props.getTaskByTypeHandler(
                      props.type === "company"
                        ? location.state
                        : location.state.id,
                      props.type
                    );
                  }
                }
              );
            }}
          >
            <Select.Option value="pending">Pending</Select.Option>
            <Select.Option value="on hold">On hold</Select.Option>
            <Select.Option value="in progress">In Progress</Select.Option>
            <Select.Option value="completed">Completed</Select.Option>
            <Select.Option value="deleted">Deleted</Select.Option>
          </Select>
        ),
    },
  ];

  return (
    <Row>
      <Col sm={24}>
        <Table
          scroll={{ x: "auto" }}
          pagination={false}
          rowKey="key"
          columns={columns}
          dataSource={props.taskbytyps}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    taskbytyps: state.taskAndCalendar.taskbytyps,
    settindsData: state.settings.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTaskByTypeHandler: (typeId, type) => {
      dispatch(getTaskByTypeAction(typeId, type));
    },
    putTaskAndCalendarHandler: (val, callback) => {
      dispatch(putTaskAndCalendarAction(val, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTask);
