import { Drawer, Form, Input, Button, Tag, Select, Row, Col } from "antd";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { CloseIcon, FilterOutlinedIcon } from "../../IconsComp/Icons";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import AadTagsForSkills from "../../UI/AadTagsForSkills";
import commonStyles from "../../UI/CommonCss.module.css";

const { Option } = Select;

const tagRenderForMobileNum = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

const tagRender = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

function RecruitmentFilter(props) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [uniqueValueData, setUniqueValueData] = useState([]);
  const [uniqueJob, setUniqueJob] = useState([]);
  const [companyName, setCompanyName] = useState("");
  // const [recuritmentFilterDatas, setRecuritmentFilterData] = useState({});
  useEffect(() => {
    if (props.filterData && props.filterData.data) {
      let uniqueValue = new Set(
        props.filterData &&
          props.filterData.data.map((data) => data.billingValue)
      );
      setUniqueValueData([...uniqueValue]);
    }
    if (props.requirements && props.requirements) {
      let jobUnique = new Set(props.requirements.map((data) => data.title));
      setUniqueJob([...jobUnique]);
    }
  }, [props.requirements && props.filterData]);

  useEffect(() => {
    let filterData = localStorage.getItem("recuritmentFilterData");
    if (filterData) {
      let data = JSON.parse(filterData);

      form.setFieldsValue({
        company: data.company,
        billingValue: data.billingValue,
        jobCode: data.jobCode,
        skills: data.skills,
        jobTitle: data.jobTitle,
        specialTag: data.specialTag,
      });
      // setRecuritmentFilterData({
      //   companyName: data.companyName,
      // });
    }
  }, []);

  const [filterDatas, setFilterDatas] = useState({
    company: "",
    recruiter: "",
    billingValue: "",
    jobCode: "",
    skills: "",
    jobTitle: "",
  });
  const handleChangeForMobile = () => {};

  const onFinish = (values) => {
    // const data = {
    //   ...values,
    //   publishedJobs:publisedJob?1:"",
    //   draftJobs:draftJob?1:""
    // }
    props.recruitmentFilterHandler(values);
    props.onCloseFilter();
  };

  return (
    <>
      <Drawer
        className={`modalModified `}
        closable={false}
        extra={
          <div
            onClick={() => props.onCloseFilter()}
            className={commonStyles.coursorPointer}
          >
            <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
          </div>
        }
        width={500}
        height={48}
        title={
          <>
            <Row>
              <Col>
                <FilterOutlinedIcon color={"var(--primaryColor)"} />
              </Col>
              <Col>
                <p
                  style={{ fontSize: "20px", margin: "0px 0px 0px 5px" }}
                  className={styles.txtBlueClr}
                >
                  Filter
                </p>
              </Col>
            </Row>{" "}
          </>
        }
        footer={false}
        // onClose={props.onCloseFilter}
        open={props.onOpenFilter}
      >
        <Form
          initialValues={{}}
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <Row
            className={styles.modalRowStyling}
            style={{ marginBottom: "80px" }}
          >
            <Col flex={1}>
              {/* <Row>
                <Col flex={1}>
                  <Form.Item label="Employee Assigned" name="recruiter">
                    <Select
                      notFoundContent={null}
                      // mode="multiple"
                      showSearch
                      allowClear
                      onKeyUp={(val) => {
                        setFilterDatas({
                          recruiter: val.target.value,
                        });
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      style={{
                        width: "100%",
                      }}
                      className={`${commonStyles.border} candidateSelectHeight`}
                      placeholder="Select employee assigned"
                      tagRender={tagRenderForMobileNum}
                    >
                      {filterDatas.recruiter &&
                        filterDatas.recruiter !== " " &&
                        filterDatas.recruiter.length > 0 &&
                        props.employees &&
                        props.employees &&
                        props.employees.map((data, index) => {
                          return (
                            <Option key={index} value={data.id}>
                              {data.firstName + " " + data.lastName}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row> */}

              <Row>
                <Col flex={1}>
                  <Form.Item label="Company" name="company">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      notFoundContent={null}
                      // mode="multiple"
                      // defaultValue={recuritmentFilterDatas.companyName}
                      allowClear
                      showSearch
                      style={{
                        width: "100%",
                      }}
                      onKeyUp={(val) => {
                        setFilterDatas({
                          company: val.target.value,
                        });
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      // onChange={(val, d) => {
                      //   setCompanyName(d.children);
                      // }}
                      className={`${commonStyles.border} candidateSelectHeight`}
                      placeholder="Select company"
                      tagRender={tagRenderForMobileNum}
                    >
                      {filterDatas.company &&
                        filterDatas.company !== " " &&
                        filterDatas.company.length > 0 &&
                        props.clients &&
                        props.clients &&
                        props.clients.map((data, index) => {
                          return (
                            <Option key={index} value={data.clientName}>
                              {data.clientName}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item label="Billing" name="billingValue">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      notFoundContent={null}
                      showSearch
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onKeyUp={(val) => {
                        setFilterDatas({
                          billingValue: val.target.value,
                        });
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      className={`${commonStyles.border} candidateSelectHeight`}
                      placeholder="Select billing Value"
                      tagRender={tagRenderForMobileNum}
                    >
                      {filterDatas.billingValue &&
                        filterDatas.billingValue !== " " &&
                        filterDatas.billingValue.length > 0 &&
                        uniqueValueData &&
                        uniqueValueData.map((data, index) => {
                          return (
                            <Select.Option key={index} value={data}>
                              {data}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row>
                <Col flex={1}>
                  <Form.Item label="Billing Value" name="billingValue">
                    <Select
                      // mode="multiple"
                      notFoundContent={null}
                      showSearch
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onKeyUp={(val) => {
                        setFilterDatas({
                          billingValue: val.target.value,
                        });
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      className={`${commonStyles.border} candidateSelectHeight`}
                      placeholder="Select billing Value"
                      tagRender={tagRenderForMobileNum}
                    >
                      {filterDatas.billingValue &&
                        filterDatas.billingValue !== " " &&
                        filterDatas.billingValue.length > 0 &&
                        props.requirements &&
                        props.requirements &&
                        props.requirements.map((data, index) => {
                          return (
                            <Option key={index} value={data.billingValue}>
                              {data.billingValue}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row> */}

              <Row>
                <Col flex={1}>
                  <Form.Item label="Job Code" name="jobCode">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      // mode="multiple"
                      notFoundContent={null}
                      showSearch
                      onKeyUp={(val) => {
                        setFilterDatas({
                          jobCode: val.target.value,
                        });
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      className={`${commonStyles.border} candidateSelectHeight`}
                      placeholder="Select job Code"
                      tagRender={tagRenderForMobileNum}
                    >
                      {filterDatas.jobCode &&
                        filterDatas.jobCode !== " " &&
                        filterDatas.jobCode.length > 0 &&
                        props.requirements &&
                        props.requirements &&
                        props.requirements.map((data, index) => {
                          return (
                            <Option key={index} value={data.jobCode}>
                              {data.jobCode}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item label="Skills" name="skills">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      notFoundContent={null}
                      showSearch
                      onKeyUp={(val) => {
                        setFilterDatas({
                          skills: val.target.value,
                        });
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Select skills"
                      tagRender={tagRender}
                      className={`${commonStyles.border} candidateSelectHeight`}
                    >
                      {filterDatas.skills &&
                        filterDatas.skills !== " " &&
                        filterDatas.skills.length > 0 &&
                        props.tagsData &&
                        props.tagsData
                          .filter((data) => data.type === "skill")
                          .map((data) => {
                            return (
                              <Select.Option key={data.id} value={data.tag}>
                                {data.tag}
                              </Select.Option>
                            );
                          })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item label="Job Title" name="jobTitle">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      // mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      notFoundContent={null}
                      showSearch
                      onKeyUp={(val) => {
                        setFilterDatas({
                          jobTitle: val.target.value,
                        });
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      className={`${commonStyles.border} candidateSelectHeight`}
                      placeholder="Select job title"
                      tagRender={tagRenderForMobileNum}
                    >
                      {filterDatas.jobTitle &&
                        filterDatas.jobTitle !== " " &&
                        filterDatas.jobTitle.length > 0 &&
                        uniqueJob &&
                        uniqueJob.map((data, index) => {
                          return (
                            <Option key={index} value={data}>
                              {data}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Special Job Tag (Featured/Trending/Hot/Premium)"
                    name="specialTag"
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      allowClear
                      placeholder="Select job priority"
                      className={`${commonStyles.border} candidateSelectHeight`}
                    >
                      <Select.Option value="trending">Trending</Select.Option>
                      <Select.Option value="hot">Hot</Select.Option>
                      <Select.Option value="premium">Premium</Select.Option>
                      <Select.Option value="featured">Featured</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col className={commonStyles.footerShadow} style={{ width: "503px" }}>
            <Row justify="end" style={{ gap: "25px" }}>
              <Col>
                <Button
                  className={commonStyles.buttonSettingsSecondary}
                  type="default"
                  onClick={() => {
                    form.resetFields();
                    setFilterDatas({
                      company: "",
                      recruiter: "",
                      billingValue: "",
                      jobCode: "",
                      skills: "",
                      jobTitle: "",
                    });
                    localStorage.removeItem("recuritmentFilterData");
                    localStorage.removeItem("fromWhichPage");
                    localStorage.removeItem("recuritmentSort");
                    props.recruitmentFilterHandler({
                      current_page: props.filterData.current_page,
                    });
                    props.onCloseFilter();
                  }}
                >
                  Reset Filter
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    form.submit();
                    localStorage.setItem(
                      "recuritmentFilterData",
                      JSON.stringify({
                        ...form.getFieldsValue(),
                      })
                    );
                    localStorage.setItem(
                      "fromWhichPage",
                      JSON.stringify({
                        fromRecuritment: "fromRecuritment",
                      })
                    );
                  }}
                  htmlType="button"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
}

export default RecruitmentFilter;
