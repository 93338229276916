import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requirements: [],
  requirement: null,
  requirement_filter: [],
  requirement_hiring:[],
  requirementJobCode:null,
  import_Job_Data:[],
};

export const RequirementsSlice = createSlice({
  name: "requirements",
  initialState,
  reducers: {
    getRequirementsSlice(state, action) {
      state.requirements = action.payload.result;
    },
    postRequirementsSlice(state, action) {
      // state.requirements = [...state.requirements, action.payload.result];
      state.requirement_filter.result = [
        action.payload.result,
        ...state.requirement_filter.result,
      ];
    },
    getRequirementSlice(state, action) {
      state.requirement = action.payload.result;
    },
    putRequirementsSlice(state, action) {
      state.requirements = state.requirements.map((data) => {
        if (data.id === action.payload.result.id) {
          return action.payload.result;
        } else {
          return data;
        }
      });
    },
    deleteRequirementsSlice(state, action) {
      state.requirements = state.requirement.filter(
        (data) => data.id !== action.payload
      );
    },

    //filter
    getRequirementsFilterSlice(state, action) {
      state.requirement_filter = action.payload.result;
    },


    getHiringTeamByIdSlice(state, action) {
      state.requirement_hiring = action.payload.result;
    },
    getJobCodeSlice(state, action) {
      state.requirementJobCode = action.payload.result;
    },

    getImportJobSlice(state, action) {
      state.import_Job_Data = action.payload.result;
    },

  },
  // postRequirementsSlice(state, action) {
  //   state.requirements = [...state.requirements, action.payload.result];
  // },
  // getRequirementSlice(state, action) {
  //   state.requirement = action.payload.result;
  // },
  // putRequirementsSlice(state, action) {
  //   state.requirements = state.requirements.map((data) => {
  //     if (data.id === action.payload.result.id) {
  //       return action.payload.result;
  //     } else {
  //       return data;
  //     }
  //   });
  // },
  // deleteRequirementsSlice(state, action) {
  //   state.requirements = state.requirement.filter(
  //     (data) => data.id !== action.payload
  //   );
  // },

  // //filter
  // getRequirementsFilterSlice(state, action) {
  //   state.candidatesFilter = action.payload.result;
  // },

  //for job code
 
});



export const {
  getRequirementSlice,
  getRequirementsSlice,
  postRequirementsSlice,
  putRequirementsSlice,
  deleteRequirementsSlice,
  getRequirementsFilterSlice,
  getHiringTeamByIdSlice,
  getJobCodeSlice,
  getImportJobSlice,
} = RequirementsSlice.actions;

export default RequirementsSlice.reducer;
