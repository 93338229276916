import {
  getPublicRequirementSlice,
  getExternalJobDetailSlice,
} from "../Slice/PublicRequirementSlice";
import {
  EXTERNAL_JOB_APPLY_JOBS_LIST_API,
  EXTERNAL_JOB_DETAILS_API,
  GET_COUNTRY_URL,
  POST_EXTERNAL_CANDIDATES_URL,
  VERIFY_EXTERNAL_CANDIDATES_URL,
} from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";

const api = new AxiosApi();

export const getExternalJobsListAction = (val) => {
  return (dispatch) => {
    api.invoke(
      EXTERNAL_JOB_APPLY_JOBS_LIST_API +
        "/" +
        val.id +
        "?page=" +
        val.current_page,
      "post",
      (val, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getPublicRequirementSlice(val));
          } else {
            dispatch(getPublicRequirementSlice({ result: [] }));
            message.destroy();
            message.error(val.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const getExternalJobDetailAction = (id) => {
  return (dispatch) => {
    api.invoke(
      EXTERNAL_JOB_DETAILS_API + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getExternalJobDetailSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const getCountriesExternalAction = (values, callback) => {
  return (dispatch) => {
    api.invoke(
      GET_COUNTRY_URL + "/" + values.type + "/" + values.id,
      "get",
      (data, success, statusCode) => {
        callback([], undefined);
        if (success) {
          if (statusCode === 200) {
            callback(data.result, values.type);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const postExternalCandidatesAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      POST_EXTERNAL_CANDIDATES_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            // dispatch(getCandidatesFilterAction({ current_page: 1 }));
            message.destroy();
            message.success(data.message, 3);
            // dispatch(postCandidatesSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

//external candidates email verification

export const getVerifyCandidatesEmailAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      VERIFY_EXTERNAL_CANDIDATES_URL + "/"+ val.trackingCode + "/"+val.type+ "/" + val.Values,
      "get",
      (val, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
          } else {
            message.destroy();
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};
