import {
  getJobListingPermissionsSlice,
  postJobListingPermissionsSlice,
  getJobListingPermissionSlice,
  putJobListingPermissionsSlice,
  deleteJobListingPermissionsSlice,
} from "../../Slice/Master/MasterJobListingPermissionSlice";
import { JOB_LISTING_PERMISSION_URL } from "../../masterpath";
import { message } from "antd";
import AxiosApi from "../../api";
const api = new AxiosApi();

export const getJobListingPermissionsAction = () => {
  return (dispatch) => {
    api.invoke(
      JOB_LISTING_PERMISSION_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getJobListingPermissionsSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const getJobListingPermissionAction = (id) => {
  return (dispatch) => {
    api.invoke(
      JOB_LISTING_PERMISSION_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getJobListingPermissionSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postJobListingPermissionsAction = (data) => {
  return (dispatch) => {
    api.invoke(
      JOB_LISTING_PERMISSION_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(postJobListingPermissionsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const putJobListingPermissionsAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      JOB_LISTING_PERMISSION_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(putJobListingPermissionsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteJobListingPermissionsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      JOB_LISTING_PERMISSION_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(deleteJobListingPermissionsSlice(id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
