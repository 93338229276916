import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  candidates: [],
  candidate: null,
  candidateAddresses: [],
  candidateExperiences: [],
  candidateExperience: null,
  candidatesFilter: [],
  candidatesEmail: [],
  candidates_TimeLine: [],
  candidateInterview: [],
  RecruitmentCandidate: [],
  candidateCalls: [],
  resumeParsing: {},
  resumeUrl: "",
  candidateInsight: null,
};

export const CandidatesSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {
    getCandidatesSlice(state, action) {
      state.candidates = action.payload.result;
    },
    getCandidateTimeLineSlice(state, action) {
      state.candidates_TimeLine = action.payload.result;
    },
    postCandidatesSlice(state, action) {
      state.candidates = [...state.candidates, action.payload.result];
    },
    getCandidateSlice(state, action) {
      state.candidate = action.payload.result;
    },
    getCandidateEmailSlice(state, action) {
      state.candidatesEmail = action.payload.result;
    },
    postCandidateEmailSlice(state, action) {
      state.candidatesEmail = [action.payload.result, ...state.candidatesEmail];
    },
    putCandidatesSlice(state, action) {
      state.candidates = state.candidates.map((data) => {
        if (data.id === action.payload.result.id) {
          return action.payload.result;
        } else {
          return data;
        }
      });
    },
    deleteCandidatesSlice(state, action) {
      state.candidates = state.candidates.filter(
        (data) => data.id !== action.payload
      );
    },

    //candidate Address
    getCandidateAddressesSlice(state, action) {
      state.candidateAddresses = action.payload.result;
    },
    postCandidateAddressesSlice(state, action) {
      state.candidate = {
        ...state.candidate,
        addressInfo: [action.payload.result, ...state.candidate.addressInfo],
      };
    },

    putCandidateAddressesSlice(state, action) {
      state.candidate = {
        ...state.candidate,
        addressInfo: state.candidate.addressInfo.map((data) => {
          if (data.id === action.payload.result.id) {
            return action.payload.result;
          } else {
            return data;
          }
        }),
      };
    },
    deleteCandidateAddressesSlice(state, action) {
      state.candidate = {
        ...state.candidate,
        addressInfo: state.candidate.addressInfo.filter(
          (data) => data.id !== action.payload
        ),
      };
    },

    //education
    // getCandidateEducationsSlice(state, action) {
    //   state.candidateEducations = action.payload.result;
    // },
    postCandidateEducationsSlice(state, action) {
      state.candidate = {
        ...state.candidate,
        educationInfo: [
          action.payload.result,
          ...state.candidate.educationInfo,
        ],
      };
    },
    getCandidateEducationSlice(state, action) {
      state.candidateEducation = action.payload.result;
    },
    putCandidateEducationsSlice(state, action) {
      state.candidate = {
        ...state.candidate,
        educationInfo: state.candidate.educationInfo.map((data) => {
          if (data.id === action.payload.result.id) {
            return action.payload.result;
          } else {
            return data;
          }
        }),
      };
    },
    deleteCandidateEducationsSlice(state, action) {
      state.candidate = {
        ...state.candidate,
        educationInfo: state.candidate.educationInfo.filter(
          (data) => data.id !== action.payload
        ),
      };
    },

    //experiences
    getCandidateExperiencesSlice(state, action) {
      state.candidateExperiences = action.payload.result;
    },
    postCandidateExperiencesSlice(state, action) {
      state.candidate = {
        ...state.candidate,
        employeeInfo: [action.payload.result, ...state.candidate.employeeInfo],
      };
    },
    getCandidateExperienceSlice(state, action) {
      state.candidateExperience = action.payload.result;
    },
    putCandidateExperiencesSlice(state, action) {
      state.candidate = {
        ...state.candidate,
        employeeInfo: state.candidate.employeeInfo.map((data) => {
          if (data.id === action.payload.result.id) {
            return action.payload.result;
          } else {
            return data;
          }
        }),
      };
    },
    deleteCandidateExperiencesSlice(state, action) {
      state.candidate = {
        ...state.candidate,
        employeeInfo: state.candidate.employeeInfo.filter(
          (data) => data.id !== action.payload
        ),
      };
    },

    //uploads

    postCandidateuploadsSlice(state, action) {
      state.candidate = {
        ...state.candidate,
        uploads: [action.payload.result, ...state.candidate.uploads],
      };
    },

    deleteCandidateuploadsSlice(state, action) {
      state.candidate = {
        ...state.candidate,
        uploads: state.candidate.uploads.filter(
          (data) => data.id !== action.payload
        ),
      };
    },

    //filter
    getCandidatesFilterSlice(state, action) {
      state.candidatesFilter = action.payload.result;
    },

    //interview
    getCandidateInterviewSlice(state, action) {
      state.candidateInterview = action.payload.result;
    },

    //recruitment slice
    getRecruitmentCandidateSlice(state, action) {
      state.RecruitmentCandidate = action.payload.result;
    },

    //Candidate call slice
    getCandidateCallSlice(state, action) {
      state.candidateCalls = action.payload.result;
    },

    //resume parsing in candidate
    getCandidateResumeParsingSlice(state, action) {
      state.resumeParsing = action.payload.data;
      // state.resumeParsing = action.payload;
    },
    //resume url for candidate
    getCandidateResumeSlice(state, action) {
      state.resumeUrl = action.payload;
    },

    //candidate insight
    getCandidatesInsightSlice(state, action) {
      state.candidateInsight = action.payload.res;
    },
  },
});

export const {
  getCandidateSlice,
  getCandidatesSlice,
  postCandidatesSlice,
  putCandidatesSlice,
  deleteCandidatesSlice,
  getCandidateTimeLineSlice,
  getCandidateEmailSlice,
  postCandidateEmailSlice,
  getCandidateAddressesSlice,
  getCandidateAddressSlice,
  postCandidateAddressesSlice,
  putCandidateAddressesSlice,
  deleteCandidateAddressesSlice,
  getCandidatesInsightSlice,
  getCandidateEducationsSlice,
  getCandidateEducationSlice,
  postCandidateEducationsSlice,
  putCandidateEducationsSlice,
  deleteCandidateEducationsSlice,

  getCandidateExperiencesSlice,
  getCandidateExperienceSlice,
  postCandidateExperiencesSlice,
  putCandidateExperiencesSlice,
  deleteCandidateExperiencesSlice,

  getCandidateuploadsSlice,
  getCandidateuploadSlice,
  postCandidateuploadsSlice,
  putCandidateuploadsSlice,
  deleteCandidateuploadsSlice,

  getCandidatesFilterSlice,

  getCandidateInterviewSlice,

  getCandidateCallSlice,

  getRecruitmentCandidateSlice,
  getCandidateResumeParsingSlice,

  getCandidateResumeSlice,
} = CandidatesSlice.actions;
export default CandidatesSlice.reducer;
