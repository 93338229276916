import { Card, Row, Col, Button, List } from "antd";
import { useEffect, useState } from "react";
import { PaperPlane } from "../../../IconsComp/Icons";
import styles from "../../AddCandidatesComp/AddCandidatesComp.module.css";
import EmailModal from "./EmailModal";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import {
  getCandidateEmailAction,
  postCandidateEmailAction,
} from "../../../../Reusable/Store/Action/CandidatesAction";
import { connect } from "react-redux";
import moment from "moment";
import { useLocation } from "react-router";
import { checkPermissionAction } from "../../../../Reusable/Store/Action/AuthPermissionsAction";
import PermissionMessage from "../../../UI/PermissionMessage";
function Emails(props) {
  const location = useLocation();
  useEffect(() => {
    props.getCandidateEmailHandler(location.state.id);
  }, []);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [ViewEmailModalOpen, setViewEmailModalOpen] = useState(false);
  const [modalNames, setModalName] = useState("");
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  function okHandler() {
    setEmailModalOpen(false);
  }
  function cancelHandler() {
    setEmailModalOpen(false);
  }

  return (
    <>
      <Row>
        <Col xs={24} md={24}>
          <Card
            style={{
              width: "100%",
              marginTop: "-12px",
              marginBottom: "30px",
              minHeight: "70px",
              padding: "26px",
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
            }}
          >
            <Row justify="space-between" align="middle">
              <Col>
                <p
                  style={{ margin: "0px" }}
                  className={styles.summaryInfoMainText}
                >
                  Mails List
                </p>
              </Col>
              {location.state.from === "recruitmentList" &&
              props.onCandidateData &&
              props.permissions &&
              props.permissions.find(
                (data) => data === "recruitment-candidate-mail-add"
              ) &&
              props.onCandidateData.status &&
              props.onCandidateData.status === "active" ? (
                <Col>
                  <Button
                    className={commonCssStyle.buttonSettingsPrimary}
                    type="primary"
                    style={{ height: "45px", width: "149px" }}
                    onClick={() => {
                      // if (location.state.from === "recruitmentList") {
                      props.getCheckPermissionHandler(
                        "recruitment-candidate-mail-add",
                        (success) => {
                          if (success) {
                            setModalName("add");
                            setEmailModalOpen(true);
                          } else {
                            setOpenPMModal({
                              open: true,
                              permission: "Recruitment Mail Add",
                            });
                          }
                        }
                      );
                      // } else {
                      //   props.getCheckPermissionHandler(
                      //     "candidate-mail-add",
                      //     (success) => {
                      //       if (success) {
                      //         setModalName("add");
                      //         setEmailModalOpen(true);
                      //       } else {
                      //         setOpenPMModal({
                      //           open: true,
                      //           permission: "Candidate Mail Add",
                      //         });
                      //       }
                      //     }
                      //   );
                      // }
                    }}
                  >
                    <Row justify="space-around" align="middle">
                      <Col>
                        <div style={{ transform: "translateY(2px)" }}>
                          <PaperPlane color={"var(--theme)"} />
                        </div>
                      </Col>
                      <Col>
                        <p style={{ margin: "0px" }}> Send Email</p>
                      </Col>
                    </Row>
                  </Button>
                </Col>
              ) : (
                <></>
              )}
              {location.state.from === "candidateList" &&
              props.onCandidateData &&
              props.permissions &&
              props.permissions.find((data) => data === "candidate-mail-add") &&
              props.onCandidateData.status &&
              props.onCandidateData.status === "active" ? (
                <Col>
                  <Button
                    className={commonCssStyle.buttonSettingsPrimary}
                    type="primary"
                    style={{ height: "45px", width: "149px" }}
                    onClick={() => {
                      // if (location.state.from === "recruitmentList") {
                      //   props.getCheckPermissionHandler(
                      //     "recruitment-candidate-mail-add",
                      //     (success) => {
                      //       if (success) {
                      //         setModalName("add");
                      //         setEmailModalOpen(true);
                      //       } else {
                      //         setOpenPMModal({
                      //           open: true,
                      //           permission: "Recruitment Mail Add",
                      //         });
                      //       }
                      //     }
                      //   );
                      // } else {
                      props.getCheckPermissionHandler(
                        "candidate-mail-add",
                        (success) => {
                          if (success) {
                            setModalName("add");
                            setEmailModalOpen(true);
                          } else {
                            setOpenPMModal({
                              open: true,
                              permission: "Candidate Mail Add",
                            });
                          }
                        }
                      );
                      // }
                    }}
                  >
                    <Row justify="space-around" align="middle">
                      <Col>
                        <div style={{ transform: "translateY(2px)" }}>
                          <PaperPlane color={"var(--theme)"} />
                        </div>
                      </Col>
                      <Col>
                        <p style={{ margin: "0px" }}> Send Email</p>
                      </Col>
                    </Row>
                  </Button>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={24}>
          <Card
            style={{
              width: "100%",
              marginTop: "-19px",
              marginBottom: "30px",

              padding: "26px 0px",
            }}
          >
            <List
              bordered={false}
              size="large"
              pagination={{
                position: "bottom",
                // align,
                size: "small",
                pageSize: "25",
                showSizeChanger: false,
                hideOnSinglePage: true,
              }}
              dataSource={props.candidatesEmail}
              renderItem={(item, index) => (
                <>
                  <List.Item
                    grid={{
                      gutter: 16,
                      column: 3,
                    }}
                    key={index}
                  >
                    <p className={styles.drawerFormText}>{item.sentByName}</p>
                    <p
                      onClick={() => {
                        setModalName("edit");
                        setViewEmailModalOpen(item);
                        setEmailModalOpen(true);
                      }}
                      className={`${styles.interviewSubHeading} ${commonCssStyle.coursorPointer}`}
                      dangerouslySetInnerHTML={{ __html: item.subject }}
                    ></p>
                    <p className={styles.drawerFormText}>
                      {moment().diff(moment(item.sentOn), "days") <= 1
                        ? moment(item.sentOn).format("hh:mm:ss")
                        : moment().diff(moment(item.sentOn), "days") > 1 &&
                          moment(item.sentOn).diff(moment(), "year") < 1
                        ? moment(item.sentOn).format("DD/MM")
                        : moment(item.sentOn).format("DD/MM/YYYY")}
                    </p>
                  </List.Item>
                </>
              )}
            />
          </Card>
        </Col>
      </Row>
      {emailModalOpen && modalNames === "add" && (
        <EmailModal
          modalNames={modalNames}
          onCandidateName={
            props.onCandidateData &&
            props.onCandidateData.name &&
            props.onCandidateData.name
          }
          onRecruitmentCandidateName={
            props.RecruitmentCandidate &&
            props.RecruitmentCandidate.name &&
            props.RecruitmentCandidate.name
          }
          isModalOpen={emailModalOpen}
          handleOk={okHandler}
          handleCancel={cancelHandler}
          id={location.state.id}
          postCandidateEmailHandler={(val) => {
            props.postCandidateEmailHandler(val, (success) => {
              if (success) {
                props.getCandidateEmailHandler(val.candidateId);
                cancelHandler();
              }
            });
          }}
        />
      )}
      {emailModalOpen && modalNames === "edit" && (
        <EmailModal
          modalNames={modalNames}
          ViewEmailModalOpen={ViewEmailModalOpen}
          isModalOpen={emailModalOpen}
          handleOk={okHandler}
          handleCancel={cancelHandler}
          id={location.state.id}
          postCandidateEmailHandler={props.postCandidateEmailHandler}
        />
      )}
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    candidatesEmail: state.candidates.candidatesEmail,
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCandidateEmailHandler: (id) => {
      dispatch(getCandidateEmailAction(id));
    },
    postCandidateEmailHandler: (data, callback) => {
      dispatch(postCandidateEmailAction(data, callback));
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Emails);
