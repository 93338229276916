import { Button, Image } from "antd";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import commonCssStyle from "../../../../UI/CommonCss.module.css";
import axios from "axios";
import { getResumeParsingAction } from "../../../../../Reusable/Store/Action/CandidatesAction";
import {
  getCandidateResumeParsingSlice,
  getCandidateResumeSlice,
  // getCandidateResumeSlice,
} from "../../../../../Reusable/Store/Slice/CandidatesSlice";
import { UploadIcon } from "../../../../IconsComp/Icons";
import { SelectOutlined } from "@ant-design/icons";
function ResumeParser(props) {
  // const [url, setUrl] = useState("");

  // let btnref = useRef(null);
  // const onChange = (e) => {
  //   const files = e.target.files;
  //   // files.length > 0 && setUrl(URL.createObjectURL(files[0]));
  //   if (files.length > 0) {
  //     let selectedFiles = e.target.files[0];
  //     if (e.target.files.length > 0) {
  //       let reader = new FileReader();
  //       reader.readAsDataURL(selectedFiles);
  //       reader.onloadend = (e) => {
  //         // setUrl(e.target.result);
  //         props.getCandidateResumeHandler(e.target.result);
  //       };
  //       axios
  //         .post(
  //           "https://parser.recruitintell.in/get_chunk",
  //           {
  //             resume_file: files[0],
  //           },
  //           {
  //             headers: {
  //               "Content-Type": "multipart/form-data",
  //             },
  //           }
  //         )
  //         .then(function (response) {
  //           props.getCandidateResumeParsingHandler(response);
  //         })
  //         .catch(function (error) {
  //           console.log(error);
  //         });
  //     }
  //   }
  //   // let data = {
  //   //   name: "RAM KUMAR",
  //   //   email: "shri.babuji@shriresume.com",
  //   //   mobile_number: "0000000000",
  //   //   skills: [
  //   //     "Marketing",
  //   //     "English",
  //   //     "Sales",
  //   //     "Excel",
  //   //     "Administration",
  //   //     "Engineering",
  //   //     "Word",
  //   //   ],
  //   //   college_name: null,
  //   //   degree: [
  //   //     "Bachelor, Technology \nDeccan College of Engineering & Technology",
  //   //   ],
  //   //   designation: ["Team Leader", "Sales Manager", "Sales Assistant"],
  //   //   company_names: [
  //   //     "About Me",
  //   //     "Serena Global Pvt Ltd",
  //   //     "Tamcoj Technologies",
  //   //     "Solvent Solutions Pvt Ltd",
  //   //     "Pimpri",
  //   //   ],
  //   //   total_experience: 4.58,
  //   //   no_of_pages: 3,
  //   // };
  //   // props.getCandidateResumeParsingHandler(data);

  //   // let filesData = URL.createObjectURL(files[0]);
  //   // console.log(filesData);
  //   // props.getResumeParsingHandler({ resume_file: files[0] });
  // };

  return (
    <div>
      <div style={{ height: "80vh", width: "50%", position: "fixed" }}>
        {/* <input
          ref={(input) => {
            btnref = input;
          }}
          type="file"
          accept=".pdf"
          hidden
          onChange={onChange}
        /> */}
        {/* <Button
          onClick={(e) => {
            e.preventDefault();
            btnref.click();
            return false;
          }}
          style={{ marginBottom: "20px" }}
          type="primary"
          className={commonCssStyle.buttonSettingsPrimary}
        >
          Choose File
        </Button> */}
        {props.resumeUrl ? (
          <div
            style={{
              border: "1px solid rgba(0, 0, 0, 0.3)",
              height: "100%",
            }}
          >
            <iframe
              // src={url}
              loading="lazy"
              src={props.resumeUrl}
              frameBorder="0"
              height="100%"
              width="100%"
            />
            {/* <object height="100%" width="100%" src={props.resumeUrl}>
              <embed src={props.resumeUrl}></embed>
            </object> */}
            {/* <object
              data={props.resumeUrl}
              width="400"
              height="300"
              type="text/html"
            /> */}
            {/* <Viewer fileUrl={url} /> */}
          </div>
        ) : (
          <div
            style={{
              alignItems: "center",
              border: "2px dashed var(--primaryColor)",
              display: "flex",
              borderRadius: "8px",
              fontSize: "32px",
              height: "100%",
              justifyContent: "center",
              width: "100%",
              color: "var(--primaryColor)",
            }}
          >
            Preview area
          </div>
          // <div
          //   // onClick={(e) => {
          //   //   e.preventDefault();
          //   //   btnref.click();
          //   //   return false;
          //   // }}
          //   style={{
          //     textAlign: "center",
          //     padding: "20px",
          //     height: "80vh",
          //     borderRadius: "7px",
          //     backgroundColor: "var(--input-color)",
          //     borderRadius: "5px",
          //     paddingBottom: "29px",
          //     cursor: "pointer",
          //   }}
          // >
          //   <div
          //     style={{
          //       display: "block",
          //       position: "relative",
          //       // margin: "50% auto",
          //       height: "100%",
          //       transform: "translate(0,50%)",
          //     }}
          //   >
          //     <SelectOutlined
          //       style={{ fontSize: "30px", color: "var(--icons-color)" }}
          //     />
          //     {/* <UploadIcon color={"var(--icons-color)"} /> */}
          //     <p
          //       style={{
          //         fontWeight: 400,
          //         textAlign: "center",
          //         color: "var(--upload-text-color)",
          //       }}
          //     >
          //       {/* Select Resume for auto file the fields */}
          //     </p>
          //   </div>
          // </div>
        )}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    resumeParsing: state.candidates.resumeParsing,
    resumeUrl: state.candidates.resumeUrl,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getResumeParsingHandler: (data) => {
    //   dispatch(getResumeParsingAction(data));
    // },
    getCandidateResumeHandler: (data) => {
      dispatch(getCandidateResumeSlice(data));
    },
    getCandidateResumeParsingHandler: (data) => {
      dispatch(getCandidateResumeParsingSlice(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResumeParser);
