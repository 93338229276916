import {
  getReportsCandidateSummarySlice,
  getReportsCandidateSlice,
  getReportsCompanySlice,
  getReportsJobSlice,
  getReportsJobCandidatesSlice,
  getRecuritmentReportSlice,
  getRecuritmentJobKPISlice,
  getRecuritmentJobSlice,
  getEmployeeHitRatioSlice,
  getReportsRequirementSlice,
  getReportsRequirementTimeGraphSlice
} from "../Slice/ReportsSlice";
import { message } from "antd";
import AxiosApi from "../api";
import {
  GET_REPORTS_CANDIDATES_SUMMARY,
  GET_REPORT_CANDIDATES_DETAILS,
  GET_REPORT_COMPANY_DETAILS,
  GET_REPORT_JOB_DETAILS,
  GET_REPORT_JOB_CANDIDATES_DETAILS,
  REPORTS_CANDIDATE_EXPORT_URL,
  REPORTS_COMPANY_EXPORT_URL,
  REPORTS_JOB_EXPORT_URL,
  REPORTS_JOB_CANDIDATE_EXPORT_URL,
  GET_RECURITMENT_URL,
  GET_RECURITMENT_KPI_URL,
  GET_REPORT_RECURITMENT_JOB_URL,
  EXPORT_REPORT_RECURITMENT_JOB_URL,
  GET_Export_RECURITMENT_URL,
  REPORTS_EMPLOYEE_HIT_RATIO_GRAPH_URL,
  REPORTS_REQUIREMENT_GRAPH,
  REPORTS_REQUIREMENT_TIME_GRAPH_URL
} from "../../Store/apipath";

const api = new AxiosApi();

//for reports summary
export const getCandidatesSummaryReportsAction = () => {
  return (dispatch) => {
    api.invoke(
      GET_REPORTS_CANDIDATES_SUMMARY,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getReportsCandidateSummarySlice(data));
          } else {
            dispatch(getReportsCandidateSummarySlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//for get candidates
export const getReportsCandidatesAction = (val) => {
  return (dispatch) => {
    api.invoke(
      GET_REPORT_CANDIDATES_DETAILS + "?page=" + val.current_page,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getReportsCandidateSlice(data));
          else {
            dispatch(getReportsCandidateSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

//for get client company
export const getReportsCompanyAction = (val) => {
  return (dispatch) => {
    api.invoke(
      GET_REPORT_COMPANY_DETAILS + "?page=" + val.current_page,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getReportsCompanySlice(data));
          else {
            dispatch(getReportsCompanySlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

//for get reports job
export const getReportsJobAction = (val) => {
  return (dispatch) => {
    api.invoke(
      GET_REPORT_JOB_DETAILS + "?page=" + val.current_page,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getReportsJobSlice(data));
          else {
            dispatch(getReportsJobSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

//for get reports job candidates
export const getReportsJobCandidatesAction = (val) => {
  return (dispatch) => {
    api.invoke(
      GET_REPORT_JOB_CANDIDATES_DETAILS + "?page=" + val.current_page,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getReportsJobCandidatesSlice(data));
          else {
            dispatch(getReportsJobCandidatesSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

//export actions
export const exportReportsCandidatesDetaisAction = (data) => {
  return (dispatch) => {
    api.invoke(
      REPORTS_CANDIDATE_EXPORT_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            window.open(data.result);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      },
      data
    );
  };
};

export const exportReportsCompanyDetaisAction = (data) => {
  return (dispatch) => {
    api.invoke(
      REPORTS_COMPANY_EXPORT_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            window.open(data.result);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      },
      data
    );
  };
};

export const exportReportsJobDetaisAction = (data) => {
  return (dispatch) => {
    api.invoke(
      REPORTS_JOB_EXPORT_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            window.open(data.result);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      },
      data
    );
  };
};

export const exportReportsJobCandidatesDetaisAction = (data) => {
  return (dispatch) => {
    api.invoke(
      REPORTS_JOB_CANDIDATE_EXPORT_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            window.open(data.result);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      },
      data
    );
  };
};

//end of export actions

//recuritment report
export const recuritmentReportAction = (val) => {
  return (dispatch) => {
    api.invoke(
      GET_RECURITMENT_URL + val.current_page,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getRecuritmentReportSlice(data));
          } else {
            dispatch(getRecuritmentReportSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      },
      val
    );
  };
};

//export employee(recruitment report)
//export actions
export const exportEmployeeDetaisAction = (data) => {
  return (dispatch) => {
    api.invoke(
      GET_Export_RECURITMENT_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            window.open(data.result);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      },
      data
    );
  };
};

//recuritment report
export const recuritmentJobKPIAction = (val) => {
  return (dispatch) => {
    api.invoke(
      GET_RECURITMENT_KPI_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getRecuritmentJobKPISlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      },
      val
    );
  };
};
//recuritment report
export const getRecuritmentJobAction = (val) => {
  return (dispatch) => {
    api.invoke(
      GET_REPORT_RECURITMENT_JOB_URL + val.current_page,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getRecuritmentJobSlice(data));
          } else {
            dispatch(getRecuritmentJobSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      },
      val
    );
  };
};
//export actions
export const exportRecuritmentJobAction = (val) => {
  return (dispatch) => {
    api.invoke(
      EXPORT_REPORT_RECURITMENT_JOB_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            window.open(data.result);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      },
      val
    );
  };
};



//graphs (employee graph)

export const getEmployeeHitRatioAction = (val) => {
  return (dispatch) => {
    api.invoke(
      REPORTS_EMPLOYEE_HIT_RATIO_GRAPH_URL + "/"+val.dateFrom +"/"+val.dateTo,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getEmployeeHitRatioSlice(data));
          } else {
            // dispatch(getEmployeeHitRatioSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const getReportsRequirementGraphAction = (val) => {
  return (dispatch) => {
    api.invoke(
      REPORTS_REQUIREMENT_GRAPH + "/"+val.dateFrom +"/"+val.dateTo,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getReportsRequirementSlice(data));
          } else {
            // dispatch(getReportsRequirementSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const getReportsRequirementTimeGraphAction = (val) => {
  return (dispatch) => {
    api.invoke(
      REPORTS_REQUIREMENT_TIME_GRAPH_URL + "/"+val.dateFrom +"/"+val.dateTo,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getReportsRequirementTimeGraphSlice(data));
          } else {
            // dispatch(getReportsRequirementTimeGraphSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};