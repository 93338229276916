import { Card, Col, Row } from "antd";
import { CloseIcon } from "../../IconsComp/Icons";
import commonCssStyles from "../../UI/CommonCss.module.css";
function NotificationCard(props) {
  return (
    <Card className={`${commonCssStyles.notificationCard}`}>
      <Row style={{ zIndex: 100 }}>
        <Col>
          <Card
            style={{
              padding: "18px 10px",
              margin: "0px",
              boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.15)",
              position: "fixed",
              width: "100%",
            }}
          >
            <Row justify="space-between">
              <Col>
                <p
                  className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.modalHeading}`}
                >
                  Notifications
                </p>
              </Col>
              <Col className={commonCssStyles.coursorPointer}>
                <span
                  onClick={() => {
                    props.onClearData();
                  }}
                >
                  <CloseIcon color={"var(--body-text-color)"} />
                </span>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row
        className={commonCssStyles.notificationDropdown}
        style={{ transform: "translateY(80px)" }}
      >
        {props.children}
      </Row>
    </Card>
  );
}
export default NotificationCard;
