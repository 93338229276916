import { Button, Col, Divider, Row } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getTaskAndCalendarAction } from "../../../Reusable/Store/Action/TaskAndCalendarAction";
import { LinkSimpleIcon } from "../../IconsComp/Icons";
import commonCssStyles from "../../UI/CommonCss.module.css";
import InterviewDetails from "../AddCandidatesComp/CandidatesSummary/InterviewDetails";
import CalendarInterviewDetails from "./CalendarInterviewDetails";
import RegularTaskDrawer from "./RegularTaskDrawer";
function CalendarToolTip(props) {
  const [interval, setIntervalData] = useState(false);
  const [intervalDrawer, setIntervalDrawer] = useState(false);
  const [regularTaskDrawer, setRegularTaskDrawer] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const closeDrawer = () => {
    setRegularTaskDrawer(false);
  };
  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      props.getTaskByIdHandler(
        props.data.id,
        { link: props.onViewName ? 0 : 1 },
        (success) => {
          if (success) {
          }
        }
      );
    }
    return () => {
      unsubscribed = true;
    };
  }, []);
  const dataFormate =
    props.settindsData && props.settindsData.dataFormate === "MM-DD-YYYY"
      ? "MMMM DD YYYY"
      : "DD MMMM, YYYY";
  return (
    <>
      <div style={{ padding: "20px 24px 20px 24px" }}>
        <p
          style={{ marginBottom: "12px" }}
          className={`${commonCssStyles.calendarTitleText} ${commonCssStyles.marginBottomRemove}`}
        >
          {props.data &&
          props.data.title &&
          props.data.title.split("(")[1] === "+0)"
            ? props.data.title.split("(")[0]
            : props.data.title}
        </p>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{ marginBottom: "12px" }}
            className={`${commonCssStyles.calendarSubTitleText} ${commonCssStyles.marginBottomRemove}`}
          >
            {props.data &&
              props.data.startDate &&
              moment(props.data.startDate).format(dataFormate)}
          </p>
          <p
            style={{ marginBottom: "12px" }}
            className={`${commonCssStyles.calendarSubTitleText} ${commonCssStyles.marginBottomRemove}`}
          >
            {props.data &&
              props.data.startDate &&
              moment(props.data.startDate).format("dddd")}
          </p>
          {props.data &&
            props.data.interviewStartTime &&
            props.data.interviewEndTime &&
            props.data.interviewEndTime && (
              <p
                style={{ marginBottom: "12px" }}
                className={`${commonCssStyles.calendarSubTitleText} ${commonCssStyles.marginBottomRemove}`}
              >
                <span>{props.data.interviewStartTime}</span>
                <span> to </span>
                <span>{props.data.interviewEndTime}</span>
              </p>
            )}
        </div>
        {props.data &&
        props.data.interviewType &&
        props.data.interviewType === "web" ? (
          props.data.interviewTypeDetails &&
          props.data.interviewTypeDetails && (
            <Row align="middle" style={{ gap: "10px", marginBottom: "27px" }}>
              <Col>
                <span>
                  <LinkSimpleIcon color={"var(--primaryColor)"} />
                </span>
              </Col>
              <Col>
                <p
                  className={`${commonCssStyles.calendarLinkText} ${commonCssStyles.marginBottomRemove}`}
                >
                  {props.data.interviewTypeDetails}
                </p>
              </Col>
            </Row>
          )
        ) : (
          <></>
        )}

        <Row style={{ gap: "8px" }}>
          {props.data &&
          props.data.interviewType &&
          props.data.interviewType === "web" ? (
            props.data.interviewTypeDetails &&
            props.data.interviewTypeDetails && (
              <Col>
                <Button
                  onClick={() => {
                    window.open(props.data.interviewTypeDetails, "_blank");
                  }}
                  style={{ height: "37px" }}
                  type="primary"
                  className={commonCssStyles.buttonSettingsPrimary}
                >
                  Join
                </Button>
              </Col>
            )
          ) : (
            <></>
          )}
          {props.onViewName &&
            props.permissions &&
            props.permissions.find((data) => data === "Task-detail") && (
              <Col>
                <Button
                  style={{ height: "37px" }}
                  type="default"
                  onClick={() => {
                    props.onMouseLeaveDelay();
                    if (props.onViewName) {
                      setRegularTaskDrawer(true);
                    } else {
                      setIntervalDrawer(true);
                    }
                  }}
                  className={commonCssStyles.buttonSettingsSecondary}
                >
                  Details
                </Button>
              </Col>
            )}
          {!props.onViewName &&
            props.permissions &&
            props.permissions.find((data) => data === "interview-detail") && (
              <Col>
                <Button
                  style={{ height: "37px" }}
                  type="default"
                  onClick={() => {
                    props.onMouseLeaveDelay();
                    if (props.onViewName) {
                      setRegularTaskDrawer(true);
                    } else {
                      setIntervalDrawer(true);
                    }
                  }}
                  className={commonCssStyles.buttonSettingsSecondary}
                >
                  Details
                </Button>
              </Col>
            )}
        </Row>
      </div>
      {/* <div style={{ height: "2px", backgroundColor: "#E0E0E0" }}></div>
      <div style={{ padding: "20px 24px 20px 24px" }}>
        <p
          className={`${commonCssStyles.calendarTitleText} ${commonCssStyles.marginBottomRemove}`}
        >
          Organiser
        </p>
      </div> */}
      {intervalDrawer && (
        <CalendarInterviewDetails
          onInterviewDetails={props.tasksData}
          onOpen={intervalDrawer}
          onClose={() => {
            setIntervalDrawer(false);
          }}
        />
      )}
      {regularTaskDrawer && (
        <RegularTaskDrawer
          onTaskData={props.tasksData}
          onModalName={"display"}
          onCloseDrawer={closeDrawer}
          onOpen={regularTaskDrawer}
          employeesData={props.employeesData}
          onJobData={props.onJobsData}
          onCandidateData={props.onCandidateData}
          onCompanyData={props.onCompanyData}
        />
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    settindsData: state.settings.settings,
    tasksData: state.taskAndCalendar.taskFilterData,
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTaskByIdHandler: (id, link, callback) => {
      dispatch(getTaskAndCalendarAction(id, link, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarToolTip);
