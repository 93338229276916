import {
  Button,
  Col,
  Form,
  Input,
  Card,
  Row,
  Select,
  Image,
  message,
} from "antd";
import {
  CloseIcon,
  EditIcon,
  MinusIcons,
  PlusForButtonIcon,
  PlusIcon,
} from "../../IconsComp/Icons";
import { UploadIcon } from "../../IconsComp/Icons";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import React, { useEffect, useRef } from "react";
import InputMask from "react-input-mask";
import style from "../../../Components/Screens/Settings/Setting.module.css";
import commonStyles from "../../UI/CommonCss.module.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
function Organization(props) {
  let btnref = useRef(null);
  let btnref1 = useRef(null);
  const [form] = useForm();

  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [stateData, setStateData] = useState("");
  const [countryData, setCountryData] = useState("");
  const [cityData, setCityData] = useState("");

  const [statePermanentData, setStatePermanentData] = useState("");
  const initvalues = {
    billingCity: "",
    billingState: "",
  };

  const onChangeCountry = (value, data, type) => {
    if (data) {
      props.onCoutriesData({ id: data.id, type: "state" });
    }

    if (type !== 1) {
      form.setFieldsValue({
        billingCity: initvalues.billingCity,
        billingState: initvalues.billingState,
      });
    }
  };

  const onChangeState = (value, data, type) => {
    if (type !== 1) {
      form.setFieldsValue({
        billingCity: initvalues.billingCity,
      });
    }
    if (data) {
      props.onCityData({ id: data.id, type: "city" });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      let _country =
        props.countriesData &&
        props.countriesData.find((data) => {
          return data.name === settings.billingCountry;
        });
      onChangeCountry(_country && _country, _country, 1);
    }, 200);
  }, []);
  const [first, setFirst] = useState(true);
  // useEffect(() => {
  //   if (first) {
  //     setTimeout(() => {
  //       let _state =
  //         props.onStatesData &&
  //         props.onStatesData.find((data) => {
  //           return data.name === settings.billingState;
  //         });
  //       if (_state) {
  //         onChangeState(_state && _state, _state, 1);
  //       }
  //       setFirst(false);
  //     }, 300);
  //   }
  // }, [props.onStatesData]);
  useEffect(() => {
    setTimeout(() => {
      if (first) {
        let _state =
          props.onStatesData &&
          props.onStatesData.find((data) => {
            return data.name === settings.billingState;
          });
        // if (_state) {
        onChangeState(_state && _state, _state, 1);
        // }
      }
      setFirst(false);
    }, 300);
  }, [props.onStatesData]);

  const handleChange = (info) => {
    if (info.target.files[0].size <= 2000000) {
      props.postSettings({ logo: info.target.files[0] });
    } else {
      message.warning("This file should be less than 2 MB", 3);
    }
  };

  const handleChange1 = (info) => {
    if (info.target.files[0].size <= 2000000) {
      props.postSettings({ sharingLogo: info.target.files[0] });
    } else {
      message.warning("This file should be less than 2 MB", 3);
    }
  };

  let { settings } = props;

  const statePermanentDataHandler = (data) => {
    setStatePermanentData(data);
  };

  return (
    <Card style={{ padding: "20px" }} className={`${commonStyles.coverBoady}`}>
      <Form
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
        layout="vertical"
        onFinish={(val) => {
          props.postSettings({
            ...val,
          });
        }}
        form={form}
        initialValues={{
          initvalues,
          name: settings.name,
          tagline: settings.tagline,
          billingLocation: settings.billingLocation,
          billingAddress: settings.billingAddress,
          billingCity: settings.billingCity,
          billingCountry: settings.billingCountry,
          billingPincode: settings.billingPincode,
          billingState: settings.billingState,
          cin: settings.cin,
          tan: settings.tan,
          pan: settings.pan,
          gst: settings.gst,
          workLocation:
            props.onSettingsWorklocation &&
            props.onSettingsWorklocation.map((x) => {
              return {
                id: x.id,
                locationName: x.locationName,
                address: x.address,
                city: x.city,
                state: x.state,
                country: x.country,
                pincode: x.pincode,
              };
            }),
          department: settings.department,
          primaryPhone: settings.contactPersonPhone,
        }}
      >
        <Row>
          <Col md={24} hidden style={{marginBottom:"20px"}}>
            <input
              hidden
              type="file"
              name="fileUpload"
              onChange={handleChange}
              ref={(input) => {
                btnref = input;
              }}
              onClick={(event) => {
                event.currentTarget.value = null;
              }}
            />
          </Col>
          <Col className="modelcol" md={24} style={{marginBottom:"20px"}}>
            <Form.Item
              label={
                <p
                  style={{
                    margin: "0px",
                    color: "var(--body-text-color)",
                    fontSize: "20px",
                    fontWeight: 500,
                  }}
                >
                  Logo
                </p>
              }
              className={style.label1}
              style={{ borderRadius: "1px", textAlign: "center" }}
            >
              {settings.logo ? (
                <>
                                <div  style={{border:"2px dashed var(--primaryColor)",padding:"25px"}}>
                  <Image
                    onClick={(e) => {
                      e.preventDefault();
                      btnref.click();
                      return false;
                    }}
                    preview={false}
                    src={settings.logo}
                    style={{
                      margin: "-12px",
                      width: "150px",
                      // borderRadius: "50%",
                    }}
                  />

                  <span
                    onClick={() => {
                      props.postSettings({ logo: null });
                    }}
                    style={{
                      position: "relative",
                      top: "0px",
                      left: "20px",
                      cursor: "pointer",
                    }}
                  >
                    <CloseIcon color={"var(--icons-color)"} />
                  </span>
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      btnref.click();
                      return false;
                    }}
                    style={{
                      position: "relative",
                      left: "0px",
                      cursor: "pointer",
                      top: "30px",
                    }}
                  >
                    <EditIcon color={"var(--icons-color)"} />
                  </span>

                  </div>
                </>
              ) : (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    btnref.click();
                    return false;
                  }}
                  style={{
                    textAlign: "center",

                    padding: "20px",
                    height: "110px",
                    borderRadius: "7px",
                    backgroundColor: "var(--input-color)",
                    borderRadius: "5px",
                    paddingBottom: "29px",
                  }}
                >
                  <UploadIcon color={"var(--icons-color)"} />
                  <div>
                    <p
                      style={{
                        fontWeight: 400,
                        textAlign: "center",
                        color: "var(--upload-text-color)",
                      }}
                    >
                      Upload Logo or Drag logo here
                    </p>
                  </div>
                </div>
              )}
            </Form.Item>
          </Col>

{/* social media logo  */}

<Col md={24} hidden  >
            <input
              hidden
              type="file"
              name="fileUpload"
              onChange={handleChange1}
              ref={(input) => {
                btnref1 = input;
              }}
              onClick={(event) => {
                event.currentTarget.value = null;
              }}
            />
          </Col>
          <Col className="modelcol" md={24}  >
            <Form.Item
              label={
                <p
                  style={{
                    margin: "0px 0px 20px 0px",
                    color: "var(--body-text-color)",
                    fontSize: "20px",
                    fontWeight: 500,
                   marginTop:"20px"
                  }}
                >
                  Social Share Logo
                </p>
              }
              className={style.label1}
              style={{ borderRadius: "1px", textAlign: "center" }}
            >
              {settings.sharingLogo ? (
                <>
                <div  style={{border:"2px dashed var(--primaryColor)",padding:"25px"}}>
                  <Image
                    onClick={(e) => {
                      e.preventDefault();
                      btnref1.click();
                      return false;
                    }}
                    preview={false}
                    src={settings.sharingLogo}
                    style={{
                      margin: "-12px",
                      width: "150px",
                      // borderRadius: "50%",
                    }}
                  />

                  <span
                    onClick={() => {
                      props.postSettings({ sharingLogo: null });
                    }}
                    style={{
                      position: "relative",
                      top: "0px",
                      left: "20px",
                      cursor: "pointer",
                    }}
                  >
                    <CloseIcon color={"var(--icons-color)"} />
                  </span>
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      btnref1.click();
                      return false;
                    }}
                    style={{
                      position: "relative",
                      left: "0px",
                      cursor: "pointer",
                      top: "30px",
                    }}
                  >
                    <EditIcon color={"var(--icons-color)"} />
                  </span>

                  </div>
                </>
              ) : (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    btnref1.click();
                    return false;
                  }}
                  style={{
                    textAlign: "center",

                    padding: "20px",
                    height: "110px",
                    borderRadius: "7px",
                    backgroundColor: "var(--input-color)",
                    borderRadius: "5px",
                    paddingBottom: "29px",
                  }}
                >
                  <UploadIcon color={"var(--icons-color)"} />
                  <div>
                    <p
                      style={{
                        fontWeight: 400,
                        textAlign: "center",
                        color: "var(--upload-text-color)",
                      }}
                    >
                      Upload Logo or Drag logo here
                    </p>
                  </div>
                </div>
              )}
            </Form.Item>
          </Col>

{/* end of social media logo  */}


          <Col className="modelcol" style={{ marginTop: "20px" }} md={24}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter organization title",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                //   message:
                //     "It allows only Alphabets, No whitespace at the beginning and end",
                // },
              ]}
              label="Title"
              className={style.label}
            >
              <Input
                className="orgInptCss"
                placeholder=" Enter organization title"
              />
            </Form.Item>
          </Col>

          <Col className="modelcol" md={24}>
            <Form.Item
              name="tagline"
              label="TagLine "
              className={style.label}
              rules={[
                {
                  required: true,
                  message: "Please enter tagline",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                //   message:
                //     "It allows only Alphabets,No whitespace at the beginning and end",
                // },
              ]}
            >
              <Input className="orgInptCss" placeholder="Enter tagline" />
            </Form.Item>
          </Col>
          <Col className="modelcol" md={24}>
            <Form.Item
              className={style.label}
              name="billingLocation"
              rules={[
                {
                  required: true,
                  message: "Please enter office location",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9\s_,/,#,-]+$/,
                //   message:
                //     "It allows only Alphanumerics _,/,#,-,No whitespace at the beginning and end",
                // },
              ]}
              label="Office Location"
            >
              <Input
                className="orgInptCss"
                placeholder="Enter office location "
              />
            </Form.Item>
          </Col>
          <Col className="modelcol" md={24}>
            <Form.Item
              className={style.label}
              name="billingAddress"
              rules={[
                {
                  required: true,
                  message: "Please enter address",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9\s_,/,#,-]+$/,
                //   message:
                //     "It allows only Alphanumerics _,/,#,-,No whitespace at the beginning and end",
                // },
              ]}
              label="Address"
            >
              <Input className="orgInptCss" placeholder="Enter address " />
            </Form.Item>
          </Col>
          <Col className="modelcol" md={24}>
            <Form.Item
              className={style.label}
              name="billingCountry"
              rules={[
                {
                  required: true,
                  message: "Please select country",
                },
              ]}
              label="Country"
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                className="orgSelectCss"
                showSearch
                onChange={onChangeCountry}
                placeholder="Select Country"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={
                  props.countriesData &&
                  props.countriesData.map((item) => ({
                    value: item.name,
                    label: item.name,
                    id: item.id,
                  }))
                }
              />
            </Form.Item>
          </Col>

          <Col className="modelcol" md={24}>
            <Form.Item
              className={style.label}
              name="billingState"
              rules={[
                {
                  required: true,
                  message: "Please select state",
                },
              ]}
              label="State"
            >
              {/* {props.onStatesData && props.onStatesData.length === 0 ? (
                <Input className="orgInptCss" placeholder="Enter state" />
              ) : ( */}
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                className="orgSelectCss"
                onChange={onChangeState}
                showSearch
                placeholder="Select State"
              >
                {props.onStatesData &&
                  props.onStatesData.map((data, index) => {
                    return (
                      <Select.Option key={index} value={data.name} id={data.id}>
                        {data.name}
                      </Select.Option>
                    );
                  })}
              </Select>
              {/* )} */}
            </Form.Item>
          </Col>

          <Col className="modelcol" md={24}>
            <Form.Item
              className={style.label}
              name="billingCity"
              rules={[
                {
                  required: true,
                  message: "Please select city",
                },
              ]}
              label="City"
            >
              {props.onCity && props.onCity.length === 0 ? (
                <Input className="orgInptCss" placeholder="Enter city" />
              ) : (
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  className="orgSelectCss"
                  showSearch
                  placeholder="Select City"
                >
                  {props.onCity &&
                    props.onCity.map((data, index) => {
                      return (
                        <Select.Option value={data.name} key={index}>
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col className="modelcol" md={24}>
            <Form.Item
              className={style.label}
              name="billingPincode"
              label="Pincode"
              rules={[
                {
                  required: true,
                  message: "Please enter pincode",
                },
                // {
                //   pattern: /^\d{6}?$/,
                //   message: "Invalid Pincode",
                // },
              ]}
            >
              <InputMask
                mask="999999"
                autoComplete="off"
                className="ant-input mi_input  orgInptCss"
                type="text"
                placeholder="Pincode number"
              />
            </Form.Item>
          </Col>
          <Col className="modelcol" md={24}>
            <Form.Item
              className={style.label}
              name="cin"
              label="CIN"
              rules={[
                {
                  required: false,
                  message: "Cin is required",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Z0-9\s]+$/,
                //   message: "Invalid CIN",
                // },

                // {
                //   min: 21,
                //   message: "CIN is Should be in 21-digit alpha-numeric format",
                // },
              ]}
            >
              <Input
                maxLength={21}
                autoComplete="off"
                className="ant-input mi_input orgInptCss"
                type="text"
                placeholder="Enter cin number"
              />
            </Form.Item>
          </Col>
          <Col className="modelcol" md={24}>
            <Form.Item
              className={style.label}
              name="tan"
              label="TAN"
              rules={[
                {
                  required: false,
                  message: "Please enter Tan",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Z0-9\s]+$/,
                //   message: "Invalid TAN",
                // },
                // {
                //   min: 10,
                //   message: "TAN is Should be in 10-digit alpha-numeric format",
                // },
              ]}
            >
              <Input
                maxLength={10}
                autoComplete="off"
                className="ant-input mi_input orgInptCss"
                type="text"
                placeholder="Enter tan number"
              />
            </Form.Item>
          </Col>
          <Col className="modelcol" md={24}>
            <Form.Item
              className={style.label}
              name="pan"
              label="PAN"
              rules={[
                {
                  required: false,
                  message: "Please enter PAN",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Z0-9\s]+$/,
                //   message: "Invalid PAN",
                // },

                // {
                //   min: 10,
                //   message: "PAN is Should be in 10-digit alpha-numeric format",
                // },
              ]}
            >
              <Input
                maxLength={10}
                autoComplete="off"
                className="ant-input mi_input orgInptCss"
                type="text"
                placeholder="Enter pan number"
              />
            </Form.Item>
          </Col>
          <Col className="modelcol" md={24}>
            <Form.Item
              className={style.label}
              name="gst"
              label="GSTN"
              rules={[
                {
                  required: false,
                  message: "Please enter GSTN",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Z0-9\s]+$/,
                //   message: "Invalid GSTN",
                // },

                // {
                //   min: 15,
                //   message: "GSTN is Should be in 15-digit alpha-numeric format",
                // },
              ]}
            >
              <Input
                maxLength={15}
                autoComplete="off"
                className="ant-input mi_input orgInptCss"
                type="text"
                placeholder="Enter gstn number "
              />
            </Form.Item>
          </Col>
          <Col className={`${commonStyles.sectionEndGap} modelcol`} md={24}>
            <Form.List name="workLocation">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, key) => (
                    <>
                      <Address
                        field={field}
                        key={key}
                        onCityData={props.onCityData}
                        workLocation={
                          props.onSettingsWorklocation &&
                          props.onSettingsWorklocation[key]
                        }
                        stateData={(data) => {
                          setStateData(data);
                        }}
                        onPermCityHandler={props.onPermCity}
                        onPermStateHandler={props.onPermState}
                        statePermanentData={statePermanentDataHandler}
                        // onCity={props.onCity}
                        // onStatesData={props.onStatesData}
                        form={form}
                        onPermCityData={props.onPermCityData}
                        // countriesData={props.countriesData}
                        onCoutriesData={props.countriesData}
                        onPermeCoutriesData={props.onPermeCoutriesData}
                      ></Address>
                      <Row>
                        <Col md={24}>
                          <p
                            className={`${commonStyles.marginBottomRemove} ${commonStyles.removeLocation}`}
                            style={{
                              margin: "5px 0px",
                              float: "right",
                            }}
                          >
                            Remove Location
                          </p>
                          <Button
                            className={commonStyles.removeLocationbtn}
                            size="small"
                            type="primary"
                            shape="circle"
                            style={{
                              marginTop: "6px",
                              marginRight: "7px",
                              float: "right",
                            }}
                            onClick={() => {
                              form
                                .getFieldValue("workLocation")
                                .map((data, index) => {
                                  if (index === key) {
                                    props.deleteWorkLocation(data.id);
                                    remove(key);
                                  }
                                });
                            }}
                          >
                            <div style={{ transform: "translateY(-4px)" }}>
                              <MinusIcons color={"var(--theme)"} />
                            </div>
                          </Button>{" "}
                        </Col>
                      </Row>
                    </>
                  ))}
                  <Button
                    onClick={() => {
                      add();
                    }}
                    style={{ backgroundColor: "var(--primaryColor)" }}
                    size="small"
                    type="primary"
                    shape="circle"
                  >
                    <div style={{ transform: "translateY(2px)" }}>
                      <PlusForButtonIcon
                        height={"19px"}
                        width={"19px"}
                        color={"var(--theme)"}
                      />
                    </div>
                  </Button>
                  <p
                    className={`${commonStyles.marginBottomRemove} ${commonStyles.addLocation}`}
                    style={{ marginTop: "-25px", marginLeft: "40px" }}
                  >
                    Add More Locations
                  </p>
                </>
              )}
            </Form.List>
          </Col>

          <Col className={`${commonStyles.sectionEndGap} modelcol`} md={24}>
            <Form.Item
              name="primaryPhone"
              label="Primary Contact Number"
              className={style.label}
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
              ]}
            >
              <PhoneInput
                placeholder="Enter contact number"
                international
                className={`ant-input ant-cust-inputs antPhoneHeight`}
                defaultCountry="IN"
              />
            </Form.Item>
          </Col>

          <Col className="modelcol" md={24}>
            <Form.Item
              name="department"
              rules={[
                {
                  required: true,
                  message: "Please enter department",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                //   message:
                //     "It allows only Alphanumerics ,No whitespace at the beginning and end",
                // },
              ]}
              label="Department"
              className={style.label}
            >
              {/* <Select>
                <Select.Option>IT</Select.Option>
              </Select> */}
              <Input className="orgInptCss" placeholder="Enter department" />
            </Form.Item>
          </Col>
        </Row>
        <div className={style.btndiv}>
          <Button
            className={commonStyles.buttonSettingsSecondary}
            type="default"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
            htmlType="button"
            className={commonStyles.buttonSettingsPrimary}
            style={{ marginLeft: "17px" }}
          >
            Save
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export default Organization;
function Address({
  field,

  workLocation,
  form,
  onPermCityHandler,
  onPermStateHandler,
  onPermCityData,
  onPermeCoutriesData,
  onCityData,
  onCoutriesData,
}) {
  const [first, setFirst] = useState(true);
  const onChangeCountry = (value, data, type) => {
    if (data) {
      onPermeCoutriesData({ id: data.id, type: "state" });
    }

    if (type !== 1) {
      form.setFieldsValue({
        workLocation: form.getFieldValue("workLocation").map((data, index) => {
          if (index === field.name) {
            return {
              ...data,
              city: null,
              state: null,
            };
          } else {
            return data;
          }
        }),
      });
    }
  };
  const onChangeState = (value, data, type) => {
    if (data) {
      onPermCityData({ id: data.id, type: "city" });
    }
    if (type !== 1) {
      form.setFieldsValue({
        workLocation: form.getFieldValue("workLocation").map((data, index) => {
          if (index === field.name) {
            return {
              ...data,
              city: null,
            };
          } else {
            return data;
          }
        }),
      });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (workLocation && workLocation.country) {
        let _country =
          onCoutriesData &&
          onCoutriesData.find((data) => {
            return data.name === workLocation.country;
          });
        setTimeout(() => {
          onChangeCountry(_country, _country, 1);
        }, 100);
      }
    }, 200);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (workLocation && workLocation.country && first) {
        let _stateData =
          onPermStateHandler &&
          onPermStateHandler.find((data) => {
            return data.name === workLocation.state;
          });
        // if (_stateData) {
        onChangeState(_stateData, _stateData, 1);
        // }
      }
      setFirst(false);
    }, 800);
  }, [onPermStateHandler]);
  return (
    <div>
      <Row>
        <Col className="modelcol" md={24}>
          <Form.Item
            className={style.label}
            name={[field.name, "locationName"]}
            rules={[
              {
                required: true,
                message: "Please enter office location",
              },
              // {
              //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9\s_,/,#,-]+$/,
              //   message:
              //     "It allows only Alphanumerics _,/,#,-,No whitespace at the beginning and end",
              // },
            ]}
            label="Office Location"
          >
            <Input
              className="orgInptCss"
              placeholder="Enter office location "
            />
          </Form.Item>
        </Col>
        <Col className="modelcol" md={24}>
          <Form.Item
            className={style.label}
            name={[field.name, "address"]}
            rules={[
              {
                required: true,
                message: "Please enter address is",
              },
              // {
              //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9\s_,/,#,-]+$/,
              //   message:
              //     "It allows only Alphanumerics _,/,#,-,No whitespace at the beginning and end",
              // },
            ]}
            label="Address"
          >
            <Input className="orgInptCss" placeholder="Enter address " />
          </Form.Item>
        </Col>

        <Col className="modelcol" md={24}>
          <Form.Item
            className={style.label}
            name={[field.name, "country"]}
            rules={[
              {
                required: true,
                message: "Please select country",
              },
            ]}
            label="Country"
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className="orgSelectCss"
              showSearch
              placeholder="Select country"
              onChange={onChangeCountry}
            >
              {onCoutriesData &&
                onCoutriesData.map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.name} id={data.id}>
                      {data.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col className="modelcol" md={24}>
          <Form.Item
            className={style.label}
            name={[field.name, "state"]}
            rules={[
              {
                required: true,
                message: "Please select state",
              },
            ]}
            label="State"
          >
            {onPermStateHandler && onPermStateHandler.length === 0 ? (
              <Input className="orgInptCss" placeholder="Enter state" />
            ) : (
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                className="orgSelectCss"
                showSearch
                onChange={onChangeState}
                placeholder="Select state"
              >
                {onPermStateHandler &&
                  onPermStateHandler.map((data, index) => {
                    return (
                      <Select.Option key={index} value={data.name} id={data.id}>
                        {data.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col className="modelcol" md={24}>
          <Form.Item
            className={style.label}
            name={[field.name, "city"]}
            rules={[
              {
                required: true,
                message: "Please select city",
              },
            ]}
            label="City"
          >
            {onPermCityHandler && onPermCityHandler.length === 0 ? (
              <Input className="orgInptCss" placeholder="Enter city" />
            ) : (
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                className="orgSelectCss"
                showSearch
                placeholder="Select city"
              >
                {onPermCityHandler &&
                  onPermCityHandler.map((data, index) => {
                    return (
                      <Select.Option value={data.name} key={index}>
                        {data.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col className="modelcol" md={24}>
          <Form.Item
            className={style.label}
            name={[field.name, "pincode"]}
            label="Pincode"
            rules={[
              {
                required: true,
                message: "Please enter pincode",
              },
              // {
              //   pattern: /^\d{6}?$/,
              //   message: "Invalid Pincode",
              // },
            ]}
          >
            <InputMask
              mask="999999"
              autoComplete="off"
              className="ant-input mi_input  orgInptCss"
              type="text"
              placeholder="Pincode number"
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
