import { Button, Card, Col, Form, Row, Modal, Input } from "antd";
import React, { useEffect, useState, useRef } from "react";
import styles from "../Application/Application.module.css";
import { Tabs, Tag } from "antd";
import { BackButtonIcon, CloseIcon } from "../../../IconsComp/Icons";
import { connect } from "react-redux";
import {
  getTagsAction,
  postTagsAction,
  deleteTagsAction,
} from "../../../../Reusable/Store/Action/Master/MasterTagsAction";
import commonCssStyle from "../../../UI/CommonCss.module.css";
function ApplicationTags(props) {
  const [tabState, setTabState] = useState("1");
  const [deleteId, setDeleteId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [company, setCompany] = useState("");
  const [candidate, setCandidate] = useState("");
  const [requirement, setRequirement] = useState("");
  const [skills, setSkills] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    props.getTagHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onChange = (key) => {
    setTabState(key);
  };
  /* modal pop up */
  const handleClose = (removedTag) => {
    setDeleteId(removedTag);
    setOpenModal(true);
  };
  function closeDeleteHandler() {
    setOpenModal(false);
  }
  function confirmDeleteHandler(id) {
    props.deleteTagHandler(id);
    setOpenModal(false);
  }
  /* on change */
  const onCompanyChange = (event) => {
    setCompany(event.target.value);
  };
  const onCandidateChange = (event) => {
    setCandidate(event.target.value);
  };
  const onRequirementChange = (event) => {
    setRequirement(event.target.value);
  };

  const onSkillsChange = (event) => {
    setSkills(event.target.value);
  };
  /*  */
  /* add company item */
  const addCompanyItem = (e) => {
    e.preventDefault();
    if (company.trim().length === 0) {
      return;
    }

    companyHandleConfirm(company.trim());
    setCompany("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  /* add candidate item */
  const addCandidateItem = (e) => {
    e.preventDefault();
    if (candidate.trim().length === 0) {
      return;
    }
    candidateHandleConfirm(candidate.trim());
    setCandidate("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  /* add requirement item */
  const addRequirementItem = (e) => {
    e.preventDefault();
    if (requirement.trim().length === 0) {
      return;
    }
    requirementHandleConfirm(requirement.trim());
    setRequirement("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  /*  tags start */
  /* Skills*/
  const addSkillsItem = (e) => {
    e.preventDefault();
    if (skills.trim().length === 0) {
      return;
    }
    skillsHandleConfirm(skills.trim());
    setSkills("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  /*End of skills*/
  /* confirm start */
  /* company confirm */
  const companyHandleConfirm = (data) => {
    const val = {
      type: "company",
      tag: data,
    };
    props.postTagHandler(val);
  };
  /* candidate conifrm */
  const candidateHandleConfirm = (data) => {
    const val = {
      type: "candidate",
      tag: data,
    };
    props.postTagHandler(val);
  };
  /* requirement confirm */
  const requirementHandleConfirm = (data) => {
    const val = {
      type: "requirement",
      tag: data,
    };
    props.postTagHandler(val);
  };
  /*Skills confirm*/
  const skillsHandleConfirm = (data) => {
    const val = {
      type: "skill",
      tag: data,
    };
    props.postTagHandler(val);
  };
  /* confirm ends */
  const forMap = (data) => {
    if (data === undefined) {
      return;
    } else if (data.type === "company" && tabState === "1") {
      const tagElem = (
        <Tag
          className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
          closable
          onClose={(e) => {
            e.preventDefault();
            handleClose(data.id);
          }}
        >
          {data.tag}
        </Tag>
      );

      return (
        <span
          key={data.id}
          style={{
            display: "inline-block",
          }}
        >
          {tagElem}
        </span>
      );
    } else if (data.type === "candidate" && tabState === "2") {
      const candidateTagElem = (
        <Tag
          className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
          closable
          onClose={(e) => {
            e.preventDefault();
            handleClose(data.id);
          }}
        >
          {data.tag}
        </Tag>
      );
      return (
        <span
          key={data.id}
          style={{
            display: "inline-block",
          }}
        >
          {candidateTagElem}
        </span>
      );
    } else if (data.type === "requirement" && tabState === "3") {
      const requirementTagElem = (
        <Tag
          className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
          closable
          onClose={(e) => {
            e.preventDefault();
            handleClose(data.id);
          }}
        >
          {data.tag}
        </Tag>
      );
      return (
        <span
          key={data.id}
          style={{
            display: "inline-block",
          }}
        >
          {requirementTagElem}
        </span>
      );
    } else if (data.type === "skill" && tabState === "4") {
      const skillTagElem = (
        <Tag
          className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
          closable
          onClose={(e) => {
            e.preventDefault();
            handleClose(data.id);
          }}
        >
          {data.tag}
        </Tag>
      );
      return (
        <span
          key={data.id}
          style={{
            display: "inline-block",
          }}
        >
          {skillTagElem}
        </span>
      );
    }
  };
  const tagChild = props.tagsData && props.tagsData.map(forMap);

  /* tags ends */

  const itemsData = [
    {
      label: <p className={styles.TabsTableHeading}>Company Tags</p>,
      key: "1",
      children: (
        <>
          <Card className={`${commonCssStyle.overflow} ${styles.TagCard2}`}>
            <Form>
              <Row>
                <Col style={{ marginTop: "10px" }} className="modelcol" md={24}>
                  <Form.Item
                    className={styles.tagsSelectTagsInput}
                    name="company"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      className="tagsInput"
                      placeholder="Enter Company Tag"
                      ref={inputRef}
                      value={company}
                      onChange={onCompanyChange}
                      onPressEnter={addCompanyItem}
                    />

                    <div
                      style={{
                        marginBottom: 16,
                      }}
                    >
                      <p className={styles.tagchilddata}>{tagChild}</p>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </>
      ),
    },
    {
      label: <p className={styles.TabsTableHeading}>Candidate Tags</p>,
      key: "2",
      children: (
        <>
          <Card className={`${commonCssStyle.overflow} ${styles.TagCard2}`}>
            <Form>
              <Row>
                <Col style={{ marginTop: "10px" }} className="modelcol" md={24}>
                  <Form.Item
                    className={styles.tagsSelectTagsInput}
                    name="candidate"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      className="tagsInput"
                      placeholder="Enter Candidate Tag"
                      ref={inputRef}
                      value={candidate}
                      onChange={onCandidateChange}
                      onPressEnter={addCandidateItem}
                    />
                    <div
                      style={{
                        marginBottom: 16,
                      }}
                    >
                      <p className={styles.tagchilddata}>{tagChild}</p>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </>
      ),
    },
    {
      label: <p className={styles.TabsTableHeading}>Requirement Tags</p>,
      key: "3",
      children: (
        <>
          <Card className={`${commonCssStyle.overflow} ${styles.TagCard2}`}>
            <Form>
              <Row>
                <Col style={{ marginTop: "10px" }} className="modelcol" md={24}>
                  <Form.Item
                    className={styles.tagsSelectTagsInput}
                    name="requirement"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      className="tagsInput"
                      placeholder="Enter Requirement Tag"
                      ref={inputRef}
                      value={requirement}
                      onChange={onRequirementChange}
                      onPressEnter={addRequirementItem}
                    />

                    <div
                      style={{
                        marginBottom: 16,
                      }}
                    >
                      <p className={styles.tagchilddata}>{tagChild}</p>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </>
      ),
    },
    {
      label: <p className={styles.TabsTableHeading}>Skills</p>,
      key: "4",
      children: (
        <>
          <Card className={`${commonCssStyle.overflow} ${styles.TagCard2}`}>
            <Form>
              <Row>
                <Col style={{ marginTop: "10px" }} className="modelcol" md={24}>
                  <Form.Item
                    className={styles.tagsSelectTagsInput}
                    name="requirement"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      className="tagsInput"
                      placeholder="Enter Skill Tag"
                      ref={inputRef}
                      value={skills}
                      onChange={onSkillsChange}
                      onPressEnter={addSkillsItem}
                    />

                    <div
                      style={{
                        marginBottom: 16,
                      }}
                    >
                      <p className={styles.tagchilddata}>{tagChild}</p>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </>
      ),
    },
  ];

  /*  */
  return (
    <>
      <Card style={{ overflow: "hidden" }} className={styles.TagsCard1}>
        <Row align="middle">
          <Col
            style={{ cursor: "pointer" }}
            className={`${styles.TagTemplatesBackBtnIcon} ${styles.TagTemplatesBackBtn}`}
            onClick={() => {
              props.backToApp();
            }}
          >
            <BackButtonIcon color={"var(--icons-color)"} />
          </Col>
          <Col>
            <h1 className={commonCssStyle.applicationCardName}>Tags</h1>
          </Col>
        </Row>
      </Card>
      <Card style={{ overflow: "hidden" }}>
        <Tabs
          className="tagsTab"
          defaultActiveKey="1"
          onChange={onChange}
          items={itemsData}
        />
      </Card>
      <Modal
        closable={false}
        open={openModal}
        // onCancel={closeDeleteHandler}
        title={
          <Row >
            <Col md={20}>
              <h3
                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.popUpHeadingText}`}
              >
                Delete Tags
              </h3>
            </Col>
            <Col md={4}>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  closeDeleteHandler();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)"} />
              </div>
            </Col>
          </Row>
        }
        footer={
          <>
            <Row style={{ gap: "10px" }} justify="end" align="middle">
              <Col>
                <Button
                  className={commonCssStyle.buttonSettingsSecondary}
                  onClick={() => {
                    closeDeleteHandler();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className={commonCssStyle.buttonSettingsPrimary}
                  onClick={() => {
                    confirmDeleteHandler(deleteId);
                  }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Row>
          <Col>
            <p
              className={`${commonCssStyle.deleteConfiTextColor} ${commonCssStyle.deleteConfiTextColor}`}
            >
              Are you sure you want to delete
            </p>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tagsData: state.tags.tags,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTagHandler: () => {
      dispatch(getTagsAction());
    },
    postTagHandler: (data) => {
      dispatch(postTagsAction(data));
    },
    deleteTagHandler: (id) => {
      dispatch(deleteTagsAction(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationTags);
