import {
  getRequirementsSlice,
  postRequirementsSlice,
  getRequirementSlice,
  putRequirementsSlice,
  deleteRequirementsSlice,
  getRequirementsFilterSlice,
  getHiringTeamByIdSlice,
  getJobCodeSlice,
  getImportJobSlice,
} from "../Slice/RequirementSlice";

import {
  REQUIREMENT_URL,
  REQUIREMENT_FILTER_URL,
  REQUIREMENT_JOB_HIRING_TEAM,
  JOB_CODE_URL,
  REQUIREMENT_JOB_POST_IMPORT_URL,
  JOB_IMPORT_FORMAT_URL,
  JOB_LIST__IMPORT_DATA_URL,
  RECRUITMENT_DELETE_IMPORT_DATA_URL,
  REQUIREMENT_UNLINK_CANDIDATE_JOS_URL,
  REQUIREMENT_ADD_CANDIDATE_JOS_URL,
} from "../apipath";
import AxiosApi from "../api";
import { message } from "antd";

const api = new AxiosApi();

export const getRequirementsAction = () => {
  return (dispatch) => {
    api.invoke(REQUIREMENT_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getRequirementsSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("something went wrong", 3);
      }
    });
  };
};

export const getRequirementAction = (id) => {
  return (dispatch) => {
    api.invoke(
      REQUIREMENT_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getRequirementSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const postRequirementsAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      REQUIREMENT_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);

            message.destroy();
            message.success(data.message, 3);
            dispatch(getRequirementsFilterAction({ current_page: 1 }));
            dispatch(postRequirementsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

export const putRequirementsAction = (val, type, callback) => {
  return (dispatch) => {
    api.invoke(
      REQUIREMENT_URL + "/" + val.id,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            if (type === "grid") {
              dispatch(
                getRequirementsFilterAction({ current_page: val.current_page })
              );
            } else {
              dispatch(getRequirementAction(val.id));
            }
            message.destroy();
            message.success(data.message, 3);
            /* here */
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};
// export const getRequirementsFilterAction = (data) => {
//   return (dispatch) => {
//     api.invoke(
//       REQUIREMENT_FILTER_URL+data.current_page,
//       "post",
//       (data, success, statusCode) => {
//         if (success) {
//           if (statusCode === 200) {
//             dispatch(getRequirementsFilterSlice(data));
//           } else {
//             dispatch(getRequirementsFilterSlice({ result: [] }));
//             message.destroy();
//             message.error(data.message, 3);
//           }
export const deleteRequirementsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      REQUIREMENT_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(getRequirementsFilterAction({ current_page: 1 }));
            dispatch(deleteRequirementsSlice(id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const getRequirementsFilterAction = (data) => {
  return (dispatch) => {
    api.invoke(
      REQUIREMENT_FILTER_URL + data.current_page,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getRequirementsFilterSlice(data));
          } else {
            dispatch(getRequirementsFilterSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

//for sending Assigned Employee Data

export const postAssignedEmployeeAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      REQUIREMENT_JOB_HIRING_TEAM,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getRequirementsFilterAction({ current_page: 1 }));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

//get hiring team by id

export const getHiringTeamByIdAction = (id) => {
  return (dispatch) => {
    api.invoke(
      REQUIREMENT_JOB_HIRING_TEAM + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getHiringTeamByIdSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//for getting jobcode

export const getJobCodeAction = () => {
  return (dispatch) => {
    api.invoke(JOB_CODE_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getJobCodeSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("something went wrong", 3);
      }
    });
  };
};

//post import data

export const postImportJobAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      REQUIREMENT_JOB_POST_IMPORT_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            dispatch(getImportJobAction());
            message.success(data.message, 3);
          } else {
            // dispatch(recruitment_FilterSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
//end of posting excel sheet

//for storing the imported value
export const getImportJobAction = () => {
  return (dispatch) => {
    api.invoke(
      JOB_LIST__IMPORT_DATA_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            // window.open(data.result)
            dispatch(getImportJobSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      }
      // data
    );
  };
};

//end of storing imported value

//download job excel sheet
export const getJobExcelFormatAction = () => {
  return (dispatch) => {
    api.invoke(JOB_IMPORT_FORMAT_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) {
          window.open(data.link);
        } else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("something went wrong", 3);
      }
    });
  };
};

//end of job excel

//for delete all job data
export const getJobDeleteAllImportedJobData = () => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_DELETE_IMPORT_DATA_URL + "/" + "job",
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getImportJobAction());
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
//end

//for deleting a particular/single job

export const getCompanyDeleteImportJobData = (id) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_DELETE_IMPORT_DATA_URL + "/" + "job" + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getImportJobAction());
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//add candidates to the job

export const postAddCandidatesToJobAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      REQUIREMENT_ADD_CANDIDATE_JOS_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getRequirementsFilterAction({ current_page: data.current_page }));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

//unlinks jobs from candidate
export const postUnlinkJobFrCandidateAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      REQUIREMENT_UNLINK_CANDIDATE_JOS_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);

            message.destroy();
            message.success(data.message, 3);
            // dispatch(getRequirementsFilterAction({ current_page: 1 }));
            // dispatch(postRequirementsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
