import { Button, Card, Col, Row, Tabs } from "antd";
import PdfImage from "../../../../Assets/Image/PdfImage.png";

import styles from "../ClientCompany.module.css";
import {
  BackButtonIcon,
  DownloadOutlinedIcon,
  EnvelopeForSummaryIcon,
  MapPinIcon,
  PhoneForSummaryIcon,
  PlusIcon,
} from "../../../IconsComp/Icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ClientCompanyTask from "./ClientCompanyTask";
import ClientCompanyJob from "./ClientCompanyJob";
import ClientCompanyDetails from "./ClientCompanyDetails";
import { getClientsJobsAction } from "../../../../Reusable/Store/Action/ClientAction";
import { connect } from "react-redux";
import {
  getClientAction,
  putClientAction,
} from "../../../../Reusable/Store/Action/ClientAction";
import { useEffect, useState } from "react";
import commonCssStyles from "../../../UI/CommonCss.module.css";
import { getCountriesAction } from "../../../../Reusable/Store/Action/Master/MasterCountryAction";
import word from "../../../../Assets/Image/word.png";
import pdf from "../../../../Assets/Image/pdf.png";
import moment from "moment";
import EmployeeTask from "../../Employees/EmployeeTask";
import PermissionMessage from "../../../UI/PermissionMessage";
import { checkPermissionAction } from "../../../../Reusable/Store/Action/AuthPermissionsAction";
import { checkPermissionReducer } from "../../../../Reusable/Store/Slice/AuthPermissionsSlice";
import BreadcrumbForClient from "../BreadcrumbForClient";

function ClientCompanyTabs(props) {
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [acitveKey, setActiveKey] = useState("1");

  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const location = useLocation();
  useEffect(() => {
    props.getClientHandler(location.state);
    props.getCountryHandler({ type: "country" }, (data, type) => {
      setCountries(data);
    });
  }, [location]);

  const clientCompanyDataHandler = (data) => {
    const val = {
      id: location.state,
      type: data,
    };
    props.getClientsJobsActionHandler(val);
  };

  const itemsArray = [
    props.permissions &&
      props.permissions.find((data) => data === "client-detail") && {
        label: "Details",
        key: "1",
        children: (
          <ClientCompanyDetails
            countriesData={props.countriesData}
            client={props.client}
            putclientTemplateHandler={props.putclientTemplateHandler}
          />
        ),
      },
    props.permissions &&
      props.permissions.find((data) => data === "client-job") && {
        label: "Jobs",
        key: "2",
        children: (
          <ClientCompanyJob
            clientJobDataHandler={props.clientJobData}
            clientTempleteData={clientCompanyDataHandler}
            settindsData={props.settindsData}
          />
        ),
      },
    props.permissions &&
      props.permissions.find((data) => data === "client-task-list") && {
        label: "Task",
        key: "3",
        children: <EmployeeTask type="company" />,
      },
  ];

  return (
    <>
      <Row>
        <Col
          md={
            props.permissions &&
            props.permissions.find((data) => data === "job-add")
              ? 12
              : 24
          }
          className={commonCssStyles.breadcrumbBottomMargin}
        >
          <BreadcrumbForClient slide={[1, 2, 3]} />
        </Col>
        {props.permissions &&
          props.permissions.find((data) => data === "job-add") && (
            <Col md={12}>
              <Button
                onClick={() => {
                  props.getCheckPermissionHandler("job-add", (success) => {
                    if (success) {
                      // navigate("/requirement", {
                      //   state: { requiredDrawerOpen: true },
                      // });
                      navigate("/clientCompany/details/requirement", {
                        state: location.state,
                      });
                    } else {
                      setOpenPMModal({
                        open: true,
                        permission: "Requirement Add",
                      });
                    }
                  });
                }}
                type="primary"
                className={commonCssStyles.intaddButton}
                style={{
                  boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.15)",
                  marginBottom: "17px",
                }}
              >
                <Row align="middle" style={{ gap: "11px" }}>
                  <Col>
                    <PlusIcon color={"white"} />
                  </Col>
                  <Col>
                    <p className={`${commonCssStyles.marginBottomRemove} `}>
                      Add new Job
                    </p>
                  </Col>
                </Row>
              </Button>
            </Col>
          )}
      </Row>
      <Row gutter={[0, 16]}>
        <Col xs={24} md={8}>
          <Card style={{ marginRight: "30px" }}>
            <div
              style={{
                textAlign: "center",
                paddingTop: "20px",

                margin: "15px 20px",
              }}
            >
              <Row>
                <Col
                  className={commonCssStyles.coursorPointer}
                  onClick={() => {
                    navigate("/clientCompany");
                  }}
                  xs={1}
                  md={1}
                >
                  {/* <Link to="/clientCompany"> */}
                  <BackButtonIcon color={"var(--body-text-color)"} />
                  {/* </Link> */}
                </Col>
                <Col xs={23} md={23}>
                  <p className={`${commonCssStyles.informationPersonName}`}>
                    {props.client && props.client.clientName}
                  </p>
                </Col>
              </Row>
            </div>
            <Row
              style={{
                margin: "15px 20px",
                padding: "20px",
                background: "var(--secondary-card-color)",
                borderRadius: "6px",
                overflow: "hidden",
              }}
            >
              <Col xs={24} md={24}>
                <Row>
                  <Col md={24}>
                    <p
                      style={{ fontSize: "20px", paddingBottom: "19px" }}
                      className={commonCssStyles.summaryInfoMainText}
                    >
                      Contact Information
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24}>
                    <Row>
                      <Col>
                        <EnvelopeForSummaryIcon
                          color={"var(--body-text-color)"}
                        />
                      </Col>
                      {props.permissions &&
                      props.permissions.find(
                        (data) => data === "client-contact-info"
                      ) ? (
                        <Col>
                          <p
                            style={{ paddingLeft: "10px", marginTop: "2px" }}
                            className={commonCssStyles.infoTextColor}
                          >
                            {props.client && props.client.clientEmail}
                          </p>
                        </Col>
                      ) : (
                        <p
                          className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.infoTextColorBold}`}
                        >
                          &nbsp;&nbsp;&nbsp;*************
                        </p>
                      )}
                    </Row>
                    <Row>
                      <Col>
                        <PhoneForSummaryIcon color={"var(--body-text-color)"} />
                      </Col>
                      {props.permissions &&
                      props.permissions.find(
                        (data) => data === "client-contact-info"
                      ) ? (
                        <Col>
                          <p
                            style={{ paddingLeft: "10px", marginTop: "3px" }}
                            className={commonCssStyles.infoTextColor}
                          >
                            {props.client && props.client.clientPhone}
                          </p>
                        </Col>
                      ) : (
                        <p
                          className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.infoTextColorBold}`}
                        >
                          &nbsp;&nbsp;&nbsp;*************
                        </p>
                      )}
                    </Row>

                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <MapPinIcon color={"var(--body-text-color)"} />
                          </Col>
                          {props.permissions &&
                          props.permissions.find(
                            (data) => data === "client-contact-info"
                          ) ? (
                            <>
                              <Col>
                                <p
                                  style={{
                                    paddingLeft: "10px",
                                    margin: "1px 0px 0px 0px",
                                    color: "var(--body-text-color)",
                                  }}
                                >
                                  {props.client &&
                                    props.client.billingAddress +
                                      ", " +
                                      props.client.billingCity +
                                      ","}
                                </p>
                              </Col>
                              <Col style={{ paddingLeft: "2px" }}>
                                <p
                                  style={{
                                    paddingLeft: "1px",
                                    margin: "0px",
                                    color: "var(--body-text-color)",
                                  }}
                                >
                                  {props.client &&
                                    props.client.billingState +
                                      ", " +
                                      props.client.billingCountry +
                                      ", " +
                                      props.client.billingPincode}
                                </p>
                              </Col>
                            </>
                          ) : (
                            <p
                              className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.infoTextColorBold}`}
                            >
                              &nbsp;&nbsp;&nbsp;*************
                            </p>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row
              justify="space-between"
              style={{
                margin: "15px 20px",
                padding: "20px 10px",
              }}
            >
              <Col xs={24} md={24}>
                {props.client && props.client.agreement && (
                  <Button
                    className={commonCssStyles.buttonSettingsPrimary}
                    style={{ width: "100%", height: "84px" }}
                    type="primary"
                  >
                    <Row align="middle" justify="space-between">
                      <Col>
                        <Row justify="space-between">
                          <Col style={{ marginTop: "5px" }}>
                            <img
                              src={
                                props.client &&
                                props.client.agreement &&
                                props.client.agreement.split(".")[
                                  props.client.agreement.split(".").length - 1
                                ] == "pdf"
                                  ? pdf
                                  : word /* pdf : word */
                              }
                              style={{ height: "46px" }}
                            />
                          </Col>
                          <Col>
                            <p
                              className={`${commonCssStyles.marginBottomRemove} ${styles.expirytxt}`}
                            >
                              Service Agreement
                            </p>
                            <p
                              style={{ marginLeft: "15px" }}
                              className={`${commonCssStyles.marginBottomRemove} ${styles.expirytxt}`}
                            >
                              Expiry Date
                              {props.client &&
                                props.client.validityEndDate &&
                                moment(props.client.validityEndDate).format(
                                  dataFormate
                                )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        onClick={() => {
                          window.open(props.client && props.client.agreement);
                        }}
                      >
                        <DownloadOutlinedIcon color={"var(--theme)"} />
                      </Col>
                    </Row>
                  </Button>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col
          md={16}
          style={{ overflowY: "scroll", height: "100vh" }}
          className="tabsScroll"
        >
          <Tabs
            onTabClick={(val) => {
              if (val === "1") {
                props.getCheckPermissionHandler("client-detail", (success) => {
                  if (success) {
                    setActiveKey("1");
                  } else {
                    setOpenPMModal({
                      open: true,
                      permission: "Client Details",
                    });
                  }
                });
              } else if (val === "2") {
                props.getCheckPermissionHandler("client-job", (success) => {
                  if (success) {
                    setActiveKey("2");
                  } else {
                    setOpenPMModal({
                      open: true,
                      permission: "Client Job",
                    });
                  }
                });
              } else if (val === "3") {
                props.getCheckPermissionHandler(
                  "client-task-list",
                  (success) => {
                    if (success) {
                      setActiveKey("3");
                    } else {
                      setOpenPMModal({
                        open: true,
                        permission: "Client Task List",
                      });
                    }
                  }
                );
              }
            }}
            className="summarytab"
            activeKey={acitveKey}
            defaultActiveKey="1"
            style={{
              height: 220,
            }}
            items={itemsArray}
          />
        </Col>
      </Row>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
          props.setIsHavepermissionsHandler(false);
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    client: state.client.client,
    clientJobData: state.client.client_jobs,
    countriesData: state.country.countries,
    settindsData: state.settings.settings,
    StateData: state.country.state,
    cityData: state.country.city,
    permissions: state.authpermissions.permissions,
    ishavepermissions: state.authpermissions.ishavepermissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getClientHandler: (id) => {
      dispatch(getClientAction(id));
    },

    putclientTemplateHandler: (data, type, callback) => {
      dispatch(putClientAction(data, type, callback));
    },
    getCountryHandler: (data, callback) => {
      dispatch(getCountriesAction(data, callback));
    },
    getClientsJobsActionHandler: (data) => {
      dispatch(getClientsJobsAction(data));
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
    setIsHavepermissionsHandler: (data) => {
      dispatch(checkPermissionReducer(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientCompanyTabs);
