import {
  client_FilterSlice,
  deleteClientSlice,
  getClientSlice,
  getClientsSlice,
  postClientSlice,
  putClientSlice,
  getClientsJobsSlice,
  getImportClientCompanySlice,
} from "../Slice/ClientSlice";

import {
  CLIENT_URL,
  CLIENT_FILTER_URL,
  CLIENT_JOBS_ID_URL,
  CLIENT_COMPANY_POST_IMPORT_URL,
  COMPANY_LIST__IMPORT_DATA_URL,
  COMPANY_IMPORT_FORMAT_URL,
  RECRUITMENT_DELETE_IMPORT_DATA_URL,
} from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";

const api = new AxiosApi();

export const getClientsAction = () => {
  return (dispatch) => {
    api.invoke(CLIENT_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) {
          dispatch(getClientsSlice(data));
        } else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};

export const getClientAction = (id) => {
  return (dispatch) => {
    api.invoke(CLIENT_URL + "/" + id, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getClientSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const postClientAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      CLIENT_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);

            message.destroy();
            message.success(data.message, 3);
            dispatch(getClientsAction());
            dispatch(client_FilterAction({ current_page: 1 }));

            dispatch(postClientSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

export const deleteClientAction = (id) => {
  return (dispatch) => {
    api.invoke(CLIENT_URL + "/" + id, "delete", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) {
          message.destroy();
          message.success(data.message, 3);
          dispatch(getClientsAction());
          let clientCompanyFilterLocal = JSON.parse(
            localStorage.getItem("clientCompany")
          );
          if (clientCompanyFilterLocal) {
            dispatch(client_FilterAction(clientCompanyFilterLocal));
          } else {
            dispatch(client_FilterAction({ current_page: 1 }));
          }
          dispatch(deleteClientSlice(id));
        } else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const putClientAction = (val, type, callback) => {
  return (dispatch) => {
    api.invoke(
      CLIENT_URL + "/" + val.id,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            if (type === "grid") {
              let clientCompanyFilterLocal = JSON.parse(
                localStorage.getItem("clientCompany")
              );
              if (clientCompanyFilterLocal) {
                dispatch(client_FilterAction(clientCompanyFilterLocal));
              } else {
                dispatch(
                  client_FilterAction({ current_page: val.current_page })
                );
              }
              dispatch(getClientsAction({}));
            } else dispatch(getClientAction(val.id));

            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(putClientSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      { ...val, _method: "PUT" }
    );
  };
};
export const client_FilterAction = (data) => {
  return (dispatch) => {
    api.invoke(
      CLIENT_FILTER_URL + data.current_page,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(client_FilterSlice(data));
          } else {
            dispatch(client_FilterSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const getClientsJobsAction = (data) => {
  return (dispatch) => {
    api.invoke(
      CLIENT_JOBS_ID_URL + "/" + data.id + "/" + data.type,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getClientsJobsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
            dispatch(getClientsJobsSlice([]));
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
// export const getClientsJobsAction = (data) => {
//   return (dispatch) => {
//     api.invoke(
//       CLIENT_JOBS_ID_URL + "/" + data.id + "/" + data.type,
//       "get",
//       (data, success, statusCode) => {
//         if (success) {
//           if (statusCode === 200) {
//             dispatch(getClientsJobsSlice(data));
//           } else {
//             message.destroy();
//             message.error(data.message, 3);
//           }
//         } else {
//           message.destroy();
//           message.error("Something went wrong", 3);
//         }
//       }
//     );
//   };
// };

//for posting the client action
export const postClientCompanyImportAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      CLIENT_COMPANY_POST_IMPORT_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            dispatch(getImportClientCompanyAction());
            message.success(data.message, 3);
          } else {
            // dispatch(recruitment_FilterSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

//for fecthing the values of imported clients

export const getImportClientCompanyAction = () => {
  return (dispatch) => {
    api.invoke(
      COMPANY_LIST__IMPORT_DATA_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            // window.open(data.result)
            dispatch(getImportClientCompanySlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      }
      // data
    );
  };
};

//for downloading excel sheet
export const getCompanyExcelFormatAction = () => {
  return (dispatch) => {
    api.invoke(
      COMPANY_IMPORT_FORMAT_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            window.open(data.link);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      }
    );
  };
};
//end

//for delete all company data
export const getCompanyDeleteAllImportedCompanyData = () => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_DELETE_IMPORT_DATA_URL + "/" + "company",
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getImportClientCompanyAction());
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
//end

//For deleting a particular/single company

export const getCompanyDeleteImportCandidateData = (id) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_DELETE_IMPORT_DATA_URL + "/" + "company" + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getImportClientCompanyAction());
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
