import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  publicRequirement: [],
  publicRequirement_jobDeatail: [],
};

export const publicRequirementSlice = createSlice({
  name: "publicRequirement",
  initialState,
  reducers: {
    getPublicRequirementSlice(state, action) {
      state.publicRequirement = action.payload.result;
    },
    getExternalJobDetailSlice(state, action) {
      state.publicRequirement_jobDeatail = action.payload.result;
    },
  },
});

export const { getPublicRequirementSlice, getExternalJobDetailSlice } =
  publicRequirementSlice.actions;
export default publicRequirementSlice.reducer;
