import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reportsCandidateSummary: [],
  reportsCandidates: [],
  reportsCompany: [],
  reportsjob: [],
  reports_job_candidates: [],
  recuritmentReport: [],
  recuritmentJobKPI: null,
  recuritmentJob: [],
  employee_hit_ratio_graph:[],
  requirement_reports_graph:[],
  requirement_reports_time_graph:[],

};

export const ReportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    //for reports summary
    getReportsCandidateSummarySlice(state, action) {
      state.reportsCandidateSummary = action.payload.result;
    },
    //for get candidates
    getReportsCandidateSlice(state, action) {
      state.reportsCandidates = action.payload.result;
    },
    //for get company
    getReportsCompanySlice(state, action) {
      state.reportsCompany = action.payload.result;
    },
    //for get job
    getReportsJobSlice(state, action) {
      state.reportsjob = action.payload.result;
    },
    //for get job candidates
    getReportsJobCandidatesSlice(state, action) {
      state.reports_job_candidates = action.payload.result;
    },
    //for get recuritment
    getRecuritmentReportSlice(state, action) {
      state.recuritmentReport = action.payload.result;
    },
    //for recuritment job kpi
    getRecuritmentJobKPISlice(state, action) {
      state.recuritmentJobKPI = action.payload.data;
    },
    //for recuritment job
    getRecuritmentJobSlice(state, action) {
      state.recuritmentJob = action.payload.result;
    },
    getEmployeeHitRatioSlice(state, action) {
      state.employee_hit_ratio_graph = action.payload.data;
    },
    getReportsRequirementSlice(state, action) {
      state.requirement_reports_graph = action.payload.data;
    },
    getReportsRequirementTimeGraphSlice(state, action) {
      state.requirement_reports_time_graph = action.payload.data;
      
    },
  },
});

export const {
  getReportsCandidateSummarySlice,
  getReportsCandidateSlice,
  getReportsCompanySlice,
  getRecuritmentJobSlice,
  getReportsJobSlice,
  getRecuritmentJobKPISlice,
  getReportsJobCandidatesSlice,
  getRecuritmentReportSlice,
  getEmployeeHitRatioSlice,
  getReportsRequirementSlice,
  getReportsRequirementTimeGraphSlice
} = ReportsSlice.actions;
export default ReportsSlice.reducer;
