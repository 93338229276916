import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  DatePicker,
  Switch,
  Select,
  Drawer,
} from "antd";
import moment from "moment";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import { connect } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import {
  postCandidateEducationsAction,
  putCandidateEducationsAction,
} from "../../../../Reusable/Store/Action/CandidatesAction";
import { CloseIcon } from "../../../IconsComp/Icons";
import { useState } from "react";
function EducationModal(props) {
  const [form] = useForm();
  const [gradeType, setGradeType] = useState("");

  const dateFormat = props.settindsData && props.settindsData.dateFormat;
  const educationHandler = (data) => {
    const val = {
      candidateId: props.data.candidateId,
      major: data.major,
      cgpa: data.cgpa,
      percentage: data.percentage,
      institute: data.institute,
      isPersuing: data.isPersuing ? 1 : 0,
      qualification: data.qualification,
      qualificationType: data.qualificationType,
      to:
        data.to === null || data.to === undefined
          ? "Invalid date"
          : moment(data.to).format("YYYY"),
      from:
        data.from === null || data.from === undefined
          ? "Invalid date"
          : moment(data.from).format("YYYY"),
    };
    const putData = {
      id: props.data.id,
      major: data.major,
      cgpa: data.cgpa,
      percentage: data.percentage,
      institute: data.institute,
      isPersuing: data.isPersuing ? true : false,
      qualification: data.qualification,
      qualificationType: data.qualificationType,
      to:
        data.to === null || data.to === undefined
          ? "Invalid date"
          : moment(data.to).format("YYYY"),
      from:
        data.from === null || data.from === undefined
          ? "Invalid date"
          : moment(data.from).format("YYYY"),
    };
    if (props.onModalName === "add") {
      props.postEducationHandler(val, (success) => {
        if (success) {
          form.resetFields();
          props.onOkEducationModal();
        }
      });
    } else {
      props.putEducationHandler(putData, (success) => {
        if (success) {
          form.resetFields();
          props.onOkEducationModal();
        }
      });
    }
  };
  const onChange = (date, dateString) => {};
  return (
    <Drawer
      closable={false}
      width={500}
      className={"modalModified"}
      // onClose={props.onCloseEducationModal}
      open={props.onOpenEducationModal}
      title={
        <p className={commonCssStyle.popUpHeadingText}>
          {props.onModalName === "edit" ? "Edit Education" : "Add Education"}
        </p>
      }
      extra={
        <div
          onClick={() => props.onCloseEducationModal()}
          className={`${commonCssStyle.coursorPointer} `}
        >
          <CloseIcon color={"var(--body-text-color)"} />
        </div>
      }
      footer={false}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(val) => {
          educationHandler(val);
        }}
        initialValues={
          props.onModalName === "edit" && {
            major: props.data.major,
            cgpa: props.data.cgpa,
            percentage: props.data.percentage,
            institute: props.data.institute,
            isPersuing: props.data.isPersuing,
            qualification: props.data.qualification,
            qualificationType: props.data.qualificationType,
            from:
              props.data.from === null || props.data.from === "Invalid date"
                ? undefined
                : moment(props.data.from),
            to:
              props.data.to === null || props.data.to === "Invalid date"
                ? undefined
                : moment(props.data.to),
          }
        }
      >
        <Row style={{ marginBottom: "80px", padding: "0px 20px" }}>
          <Col md={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter qualification",
                },
              ]}
              label="Qualification"
              name="qualification"
            >
              <Input placeholder="Enter qualification" />
            </Form.Item>
            <Form.Item
              label="Qualification Type"
              name="qualificationType"
              rules={[
                {
                  required: true,
                  message: "Please enter qualification type",
                },
              ]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder="Select qualification type"
              >
                <Select.Option value="Secondary">Secondary</Select.Option>
                <Select.Option value="Higher Secondary">
                  Higher Secondary
                </Select.Option>
                <Select.Option value="Under-Graduate">
                  Under Graduate
                </Select.Option>
                <Select.Option value="Graduate">Graduate</Select.Option>
                <Select.Option value="Post-Graduate">
                  Post Graduate
                </Select.Option>
                <Select.Option value="Doctoral studies">
                  Doctoral Studies
                </Select.Option>
                <Select.Option value="Diploma">Diploma</Select.Option>
                <Select.Option value="Distance Education">
                  Distance Education
                </Select.Option>
                <Select.Option value="Correspondence Education">
                  Correspondence Education
                </Select.Option>
                <Select.Option value="Vocational Education & Training">
                  Vocational Education & Training
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: false,
                  message: "Please enter institute",
                },
              ]}
              label="Institute/ School Name"
              name="institute"
            >
              <Input placeholder="Enter institue" />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: false,
                  message: "Please enter major",
                },
              ]}
              label="Major"
              name="major"
            >
              <Input placeholder="Enter major" />
            </Form.Item>

            <Row justify="space-between">
              <Col md={11}>
                <Form.Item
                  label="CGPA"
                  name="cgpa"
                  rules={[
                    {
                      required: false,
                      message: "Please enter cgpa",
                    },
                  ]}
                >
                  <Input placeholder="Enter cgpa" type="number" />
                </Form.Item>
              </Col>
              <Col md={11}>
                <Form.Item
                  label="Percentage"
                  name="percentage"
                  rules={[
                    {
                      required: false,
                      message: "Please enter percentage",
                    },
                  ]}
                >
                  <Input placeholder="Enter percentage" type="number" />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ gap: "30px" }}>
              <Col md={11}>
                <Form.Item
                  label="Start Date"
                  rules={[
                    {
                      required: false,
                      message: "Please enter from",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          !getFieldValue("to") ||
                          getFieldValue("to") > value
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Start Date must be less than To Date"
                        );
                      },
                    }),
                  ]}
                  name="from"
                  dependencies={["to"]}
                >
                  <DatePicker
                    getPopupContainer={(trigger) => trigger.parentElement}
                    defaultValue={undefined}
                    picker="year"
                    className={commonCssStyle.datePickerBgColor}
                    style={{
                      width: "100%",
                    }}
                    // format={dateFormat}
                    placeholder="From"
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
              <Col md={11}>
                <Form.Item
                  label="End Date"
                  rules={[
                    {
                      required: false,
                      message: "Please enter to",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          !getFieldValue("from") ||
                          getFieldValue("from") < value
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "End Date must be greater than Start Date"
                        );
                      },
                    }),
                  ]}
                  name="to"
                  dependencies={["from"]}
                >
                  <DatePicker
                    getPopupContainer={(trigger) => trigger.parentElement}
                    picker="year"
                    defaultValue={undefined}
                    className={commonCssStyle.datePickerBgColor}
                    style={{
                      width: "100%",
                    }}
                    // format={dateFormat}
                    placeholder="To"
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Persuing" name="isPersuing">
              {props.data.isPersuing ? <Switch defaultChecked /> : <Switch />}
            </Form.Item>
          </Col>
        </Row>

        <Row
          className={commonCssStyle.footerShadow}
          justify="end"
          align="middle"
          style={{ gap: "10px", width: "500px" }}
        >
          <Col>
            <Button
              className={commonCssStyle.buttonSettingsSecondary}
              onClick={() => {
                props.onCloseEducationModal();
              }}
              type="default"
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                form.submit();
              }}
              htmlType="button"
              className={commonCssStyle.buttonSettingsPrimary}
              type="primary"
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

const mapStateToProps = (state) => {
  return {
    dataFormate: state.settings.settings,
    settindsData: state.settings.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postEducationHandler: (data, callback) => {
      dispatch(postCandidateEducationsAction(data, callback));
    },
    putEducationHandler: (data, callback) => {
      dispatch(putCandidateEducationsAction(data, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EducationModal);
