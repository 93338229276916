import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  jobTypes: [],
  jobType: null,
};
export const masterJobTypesSlice = createSlice({
  name: "jobtype",
  initialState,
  reducers: {
    getJobTypesSlice(state, action) {
      state.jobTypes = action.payload.result;
    },
    getJobTypeSlice(state, action) {
      state.jobType =  action.payload.result;

    },
    postJobTypeSlice(state, action) {
      state.jobTypes=[
        ...state.jobTypes,
        action.payload.result,
    ];
    },
    putJobTypesSlice(state, action) {
      state.jobTypes=state.jobTypes.map((data)=>{
        if(data.id===action.payload.result.id){
            return action.payload.result;
        }else{
            return data;
        }
    });
    },
    deleteJobTypeSlice(state, action) {
      state.jobTypes=state.jobTypes.filter(
        (data)=>data.id!==action.payload
    );
    },
  },
});
export const {
  getJobTypesSlice,
  getJobTypeSlice,
  postJobTypeSlice,
  putJobTypesSlice,
  deleteJobTypeSlice,
} = masterJobTypesSlice.actions;
export default masterJobTypesSlice.reducer;
