import {
  getEmployeesSlice,
  getEmployeeSlice,
  postEmployeesSlice,
  deleteEmployeesSlice,
  employee_filterSlice,
  postEmployee_permissionSlice,
  postemployee_roleSlice,
  putEmployeesSlice,
  postEmployeeDocumentsSlice,
  deleteEmployeeuploadsSlice,
  getEmployeesTimelineSlice,
  getEmployeesCallLogsSlice,
  getRolesAndPermissionSlice,
  getEmployeesAssignToJobSlice,
} from "../Slice/EmployeesSlice";
import {
  EMPLOYEES_URL,
  EMPLOYEE_FILTER_URL,
  EMPLOYEE_PERMISSION_URL,
  EMPLOYEE_ROLE_URL,
  EMPLOYEE_DOCUMENTS_URL,
  EMPLOYEE_TIMELINE_URL,
  EMPLOYEE_CALL_LOGS_URL,
  EMPLOYEE_JOBS_URL,
  CANDIDATE_TIMELINE_FILTER_URL,
} from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";

const api = new AxiosApi();

export const getEmployeesAction = () => {
  return (dispatch) => {
    api.invoke(EMPLOYEES_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getEmployeesSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};

export const getEmployeeAction = (id) => {
  return (dispatch) => {
    api.invoke(EMPLOYEES_URL + "/" + id, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getEmployeeSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const postEmployeesAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      EMPLOYEES_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);

            message.destroy();
            message.success(data.message, 3);
            dispatch(employee_filterAction({ current_page: 1 }));
            dispatch(getEmployeesAction());
            dispatch(postEmployeesSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

export const deleteEmployeesAction = (id) => {
  return (dispatch) => {
    api.invoke(
      EMPLOYEES_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            let employeeFilterLocal = JSON.parse(
              localStorage.getItem("employee")
            );
            if (employeeFilterLocal) {
              dispatch(employee_filterAction(employeeFilterLocal));
            } else {
              dispatch(employee_filterAction({ current_page: 1 }));
            }
            dispatch(getEmployeesAction());

            dispatch(deleteEmployeesSlice(id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const employee_filterAction = (data) => {
  return (dispatch) => {
    api.invoke(
      EMPLOYEE_FILTER_URL + data.current_page,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(employee_filterSlice(data));
          } else {
            dispatch(employee_filterSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const putEmployeesAction = (value, type, callback) => {
  return (dispatch) => {
    api.invoke(
      EMPLOYEES_URL + "/" + value.id,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            if (type === "grid") {
              let employeeFilterLocal = JSON.parse(
                localStorage.getItem("employee")
              );
              if (employeeFilterLocal) {
                dispatch(employee_filterAction(employeeFilterLocal));
              } else {
                dispatch(
                  employee_filterAction({ current_page: value.current_page })
                );
              }
              dispatch(getEmployeesAction());
            } else dispatch(getEmployeeAction(value.id));

            message.destroy();
            message.success(data.message, 3);
            dispatch(putEmployeesSlice(value));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      { ...value, _method: "PUT" }
    );
  };
};
export const postEmployee_permissionAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      EMPLOYEE_PERMISSION_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);

            message.destroy();
            message.success(data.message, 3);
            dispatch(getEmployeeAction(val.employeeId));
            dispatch(postEmployee_permissionSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};
export const getEmployee_permissionAction = (val) => {
  return (dispatch) => {
    api.invoke(
      EMPLOYEE_PERMISSION_URL + "/" + val,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getRolesAndPermissionSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postemployee_roleAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      EMPLOYEE_ROLE_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);

            message.destroy();
            message.success(data.message, 3);
            dispatch(getEmployeeAction(val.employeeId));
            dispatch(postemployee_roleSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const postEmployeeDocumentsAction = (value, type, callback) => {
  return (dispatch) => {
    api.invoke(
      EMPLOYEE_DOCUMENTS_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getEmployeeAction(value.userId));
            // dispatch(postEmployeeDocumentsSlice(value));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      value
    );
  };
};

// export const getEmployeeuploadAction = (id) => {
//   return (dispatch) => {
//     api.invoke(
//       EMPLOYEE_DOCUMENTS_URL + "/" + id,
//       "get",
//       (data, success, statusCode) => {
//         if (success) {
//           if (statusCode === 200) dispatch(getCandidateuploadSlice(data));
//           else {
//             message.destroy();
//             message.error(data.message, 3);
//           }
//         } else {
//           message.destroy();
//           message.error("Something went wrong", 3);
//         }
//       }
//     );
//   };
// };

export const deleteEmployeeuploadsAction = (id, callback) => {
  return (dispatch) => {
    api.invoke(
      EMPLOYEE_DOCUMENTS_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);

            dispatch(deleteEmployeeuploadsSlice(id));
            dispatch(getEmployeesAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const getEmployeesTimelineAction = (id) => {
  return (dispatch) => {
    api.invoke(
      EMPLOYEE_TIMELINE_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getEmployeesTimelineSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//employee timeline filter
export const postEmployeeTimelineFilterAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATE_TIMELINE_FILTER_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getEmployeesTimelineSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

//for employee call history

export const getEmployeeCallLogsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      EMPLOYEE_CALL_LOGS_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getEmployeesCallLogsSlice(data));
          else {
            dispatch(getEmployeesCallLogsSlice({ result: [] }));
            message.destroy();
            // message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//to get employee jobs
export const getEmployeesAssignToJobAction = (val) => {
  return (dispatch) => {
    api.invoke(
      EMPLOYEE_JOBS_URL + "/" + val.id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getEmployeesAssignToJobSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
