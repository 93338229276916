import {
  getGlobalSearchSlice,
  getGSDataStatusSlice,
} from "../Slice/GlobalSearchSlice";

import { GET_GLOBAL_SEARCH_URL } from "../apipath";

import { message } from "antd";
import AxiosApi from "../api";

const api = new AxiosApi();

export const getGlobalSearchAction = (data) => {
  return (dispatch) => {
    api.invoke(
      GET_GLOBAL_SEARCH_URL + "/" + data.val,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getGlobalSearchSlice(data));
            dispatch(getGSDataStatusSlice(true));
          } else {
            dispatch(getGSDataStatusSlice(false));
            // message.destroy();
            // message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
