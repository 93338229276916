import {
  getCandidatesSlice,
  postCandidatesSlice,
  getCandidateSlice,
  putCandidatesSlice,
  deleteCandidatesSlice,
  getCandidateAddressesSlice,
  postCandidateAddressesSlice,
  getCandidateAddressSlice,
  putCandidateAddressesSlice,
  deleteCandidateAddressesSlice,
  getCandidateEducationsSlice,
  postCandidateEducationsSlice,
  getCandidateEducationSlice,
  putCandidateEducationsSlice,
  deleteCandidateEducationsSlice,
  getCandidateExperiencesSlice,
  postCandidateExperiencesSlice,
  getCandidateExperienceSlice,
  putCandidateExperiencesSlice,
  deleteCandidateExperiencesSlice,
  getCandidateuploadsSlice,
  postCandidateuploadsSlice,
  getCandidateEmailSlice,
  postCandidateEmailSlice,
  getCandidateuploadSlice,
  putCandidateuploadsSlice,
  deleteCandidateuploadsSlice,
  getCandidatesFilterSlice,
  getCandidateTimeLineSlice,
  getCandidateInterviewSlice,
  getImportCandidatesSlice,
  getRecruitmentCandidateSlice,
  getCandidateCallSlice,
  getCandidateResumeParsingSlice,
  getCandidateEmailAndPhoneCheckSlice,
  getCandidatesInsightSlice,
} from "../Slice/CandidatesSlice";
import {
  CANDIDATES_URL,
  CANDIDATES_ADDRESS_URL,
  CANDIDATES_EDUCATION_URL,
  CANDIDATES_EXPERIENCE_URL,
  CANDIDATES_UPLOADS_URL,
  CANDIDATES_FILTER_URL,
  CANDIDATES_TIMELINE_URL,
  CANDIDATE_EXPORT_URL,
  CANDIDATE_EMAIL_URL,
  CANDIDATE_INTERVIEW_URL,
  RECRUITMENT_CANDIDATES_URL,
  CANDIDATE_CALL_LOGS_URL,
  CANDIDATE_TIMELINE_FILTER_URL,
  CANDIDATE_RESUME_PARSING_URL,
  CANDIDATE_EMAIL_OR_PHONE_CHECK,
  CANDIDATE_INSIGHT_URL,
} from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";

const api = new AxiosApi();

//Candidates
export const getCandidatesAction = () => {
  return (dispatch) => {
    api.invoke(CANDIDATES_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getCandidatesSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};

export const getCandidateAction = (id) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getCandidateSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const getCandidateEmailAction = (id) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATE_EMAIL_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getCandidateEmailSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postCandidateEmailAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATE_EMAIL_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getCandidateEmailAction(data.candidateId));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

export const postCandidatesAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            dispatch(getCandidatesFilterAction({ current_page: 1 }));
            message.destroy();
            message.success(data.message, 3);
            dispatch(postCandidatesSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

export const putCandidatesAction = (input, type, callback) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_URL + "/" + input.id,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);

            if (type === "grid") {
              dispatch(
                getCandidatesFilterAction({ current_page: input.current_page })
              );
            } else {
              dispatch(getCandidateAction(input.id));
            }
            dispatch(putCandidatesSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      input
    );
  };
};

export const deleteCandidatesAction = (id) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            let candidateDetailFilterLocal = JSON.parse(
              localStorage.getItem("candidateDetail")
            );
            if (candidateDetailFilterLocal) {
              dispatch(getCandidatesFilterAction(candidateDetailFilterLocal));
            } else {
              dispatch(getCandidatesFilterAction({ current_page: 1 }));
            }
            dispatch(deleteCandidatesSlice(id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//Candidates Address

export const getCandidateAddressesAction = () => {
  return (dispatch) => {
    api.invoke(CANDIDATES_ADDRESS_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getCandidateAddressesSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getCandidateAddressAction = (id) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_ADDRESS_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getCandidateAddressSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postCandidateAddressesAction = (data) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_ADDRESS_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(postCandidateAddressesSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

export const putCandidateAddressesAction = (data) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_ADDRESS_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(putCandidateAddressesSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteCandidateAddressesAction = (id) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_ADDRESS_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(deleteCandidateAddressesSlice(id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//Education

export const getCandidateEducationsAction = () => {
  return (dispatch) => {
    api.invoke(CANDIDATES_EDUCATION_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getCandidateEducationsSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getCandidateEducationAction = (id) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_EDUCATION_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getCandidateEducationSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postCandidateEducationsAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_EDUCATION_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(postCandidateEducationsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

export const putCandidateEducationsAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_EDUCATION_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(putCandidateEducationsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteCandidateEducationsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_EDUCATION_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(deleteCandidateEducationsSlice(id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//Experience

export const getCandidateExperiencesAction = () => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_EXPERIENCE_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getCandidateExperiencesSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const getCandidateExperienceAction = (id) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_EXPERIENCE_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getCandidateExperienceSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postCandidateExperiencesAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_EXPERIENCE_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(postCandidateExperiencesSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

export const putCandidateExperiencesAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_EXPERIENCE_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(putCandidateExperiencesSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteCandidateExperiencesAction = (id) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_EXPERIENCE_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(deleteCandidateExperiencesSlice(id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//Uploads

export const getCandidateuploadsAction = () => {
  return (dispatch) => {
    api.invoke(CANDIDATES_UPLOADS_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getCandidateuploadsSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getCandidateuploadAction = (id) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_UPLOADS_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getCandidateuploadSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postCandidateuploadsAction = (input, callback) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_UPLOADS_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(postCandidateuploadsSlice(data));
            // dispatch(getCandidateAction(input.id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      input
    );
  };
};

export const putCandidateuploadsAction = (data) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_UPLOADS_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(putCandidateuploadsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteCandidateuploadsAction = (id, callback) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_UPLOADS_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);

            dispatch(deleteCandidateuploadsSlice(id));
            dispatch(getCandidatesAction());
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

export const getCandidatesFilterAction = (data) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_FILTER_URL + data.current_page,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getCandidatesFilterSlice(data));
          } else {
            dispatch(getCandidatesFilterSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

export const getCandidatesTimelineAction = (id) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATES_TIMELINE_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getCandidateTimeLineSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//timeline filter handler
export const postCandidateTimelineFilterAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATE_TIMELINE_FILTER_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(getCandidateTimeLineSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

export const exportCandidatesDetaisAction = (data) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATE_EXPORT_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            window.open(data.result);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      },
      data
    );
  };
};
export const getCandidateInterviewAction = (id) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATE_INTERVIEW_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getCandidateInterviewSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("something went wrong", 3);
        }
      }
    );
  };
};

//for recruitment candidate slice

export const getRecruitmentCandidateAction = (id) => {
  return (dispatch) => {
    api.invoke(
      RECRUITMENT_CANDIDATES_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getRecruitmentCandidateSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//for canidate call history

export const getCandidatesCallLogsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATE_CALL_LOGS_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getCandidateCallSlice(data));
          else {
            dispatch(getCandidateCallSlice({ result: [] }));
            message.destroy();
            // message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
//for candidate email and phone check
export const getCandidatesEmailAndPhoneCheckAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATE_EMAIL_OR_PHONE_CHECK + "/" + data.type + "/" + data.value,
      "get",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//for candidate insight
export const getCandidatesInsightAction = (id, callback) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATE_INSIGHT_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            dispatch(getCandidatesInsightSlice(data));
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//for resume parsing
// export const getResumeParsingAction = (val) => {
//   return (dispatch) => {
//     api.invoke(
//       CANDIDATE_RESUME_PARSING_URL,
//       "post",
//       (data, success, statusCode) => {
//         if (success) {
//           if (statusCode === 200) {
//             dispatch(getCandidateResumeParsingSlice(data));
//           }
//         } else {
//           message.destroy();
//           message.error("Something went wrong", 3);
//         }
//       },
//       val
//     );
//   };
// };
