import { Card, Col, Row, Switch, Tabs, List } from "antd";
import React, { useEffect } from "react";
import styles from "../Application/Application.module.css";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import { BackButtonIcon } from "../../../IconsComp/Icons";
import { connect } from "react-redux";
import {
  getJobListingPermissionsAction,
  putJobListingPermissionsAction,
} from "../../../../Reusable/Store/Action/Master/MasterJobListingPermissionAction";

function JobListingPage(props) {
  useEffect(() => {
    props.getJobListingsHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onChange = (key) => {};
  const itemsData = [
    {
      label: (
        <p className={styles.TabsTableHeading}>Job Listing Page Permission</p>
      ),
      key: "1",
      children: (
        <>
          <Card>
            <List
              size="small"
              bordered={false}
              dataSource={props.jobListingDatas}
              renderItem={(item) => {
                return (
                  item.accessType === "listing" && (
                    <List.Item
                      grid={{
                        gutter: 16,
                        column: 2,
                      }}
                    >
                      <Row align="middle">
                        <Col>
                          <p
                            style={{
                              margin: "8px 10px",
                              textOverflow: "ellipsis",
                            }}
                            className={commonCssStyle.tableHeadingTextData}
                          >
                            {item.field}
                          </p>
                        </Col>
                      </Row>

                      <Row align="middle">
                        {item.status === 1 ? (
                          <>
                            <Row
                              style={{ gap: "10px" }}
                              justify="space-between"
                            >
                              <Col>
                                <Switch
                                  onChange={(val) => {
                                    const data = {
                                      id: item.id,
                                      accessType: item.accessType,
                                      status: val,
                                    };
                                    props.putJobListingHandler(
                                      data,
                                      (success) => {
                                        if (success) {
                                          props.getJobListingsHandler();
                                        }
                                      }
                                    );
                                  }}
                                  defaultChecked
                                />
                              </Col>
                              <Col>
                                <p
                                  style={{
                                    marginBottom: "0px",
                                    marginRight: "39px",
                                  }}
                                  className={
                                    commonCssStyle.tableHeadingTextData
                                  }
                                >
                                  Enable
                                </p>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <>
                            <Row
                              style={{ gap: "10px" }}
                              justify="space-between"
                            >
                              <Col>
                                <Switch
                                  onChange={(val) => {
                                    const data = {
                                      id: item.id,
                                      accessType: item.accessType,
                                      status: val,
                                    };
                                    props.putJobListingHandler(
                                      data,
                                      (success) => {
                                        if (success) {
                                          props.getJobListingsHandler();
                                        }
                                      }
                                    );
                                  }}
                                />
                              </Col>
                              <Col>
                                <p
                                  style={{
                                    marginBottom: "0px",
                                    marginRight: "39px",
                                  }}
                                  className={
                                    commonCssStyle.tableHeadingTextData
                                  }
                                >
                                  Disable
                                </p>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Row>
                    </List.Item>
                  )
                );
              }}
            />
          </Card>
        </>
      ),
    },
    {
      label: (
        <p className={styles.TabsTableHeading}>
          Individual Job Detail Page Permission
        </p>
      ),
      key: "2",
      children: (
        <>
          <Card>
            <List
              size="small"
              bordered={false}
              dataSource={props.jobListingDatas}
              renderItem={(item) => {
                return (
                  item.accessType === "detail" && (
                    <List.Item
                      grid={{
                        gutter: 16,
                        column: 2,
                      }}
                    >
                      <Row align="middle">
                        <Col>
                          <p
                            style={{
                              margin: "8px 10px",
                              textOverflow: "ellipsis",
                            }}
                            className={commonCssStyle.tableHeadingTextData}
                          >
                            {item.field}
                          </p>
                        </Col>
                      </Row>
                      <Row align="middle">
                        <Col>
                          {item.status === 1 ? (
                            <>
                              <Row
                                style={{ gap: "10px" }}
                                justify="space-between"
                                align="middle"
                              >
                                <Col>
                                  <Switch
                                    onChange={(val) => {
                                      const data = {
                                        id: item.id,
                                        accessType: item.accessType,
                                        status: val,
                                      };
                                      props.putJobListingHandler(
                                        data,
                                        (success) => {
                                          if (success) {
                                            props.getJobListingsHandler();
                                          }
                                        }
                                      );
                                    }}
                                    defaultChecked
                                  />
                                </Col>
                                <Col>
                                  <p
                                    style={{
                                      marginBottom: "0px",
                                      marginRight: "39px",
                                    }}
                                    className={
                                      commonCssStyle.tableHeadingTextData
                                    }
                                  >
                                    Enable
                                  </p>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <>
                              <Row
                                style={{ gap: "10px" }}
                                justify="space-between"
                              >
                                <Col>
                                  <Switch
                                    onChange={(val) => {
                                      const data = {
                                        id: item.id,
                                        accessType: item.accessType,
                                        status: val,
                                      };
                                      props.putJobListingHandler(
                                        data,
                                        (success) => {
                                          if (success) {
                                            props.getJobListingsHandler();
                                          }
                                        }
                                      );
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <p
                                    style={{
                                      marginBottom: "0px",
                                      marginRight: "39px",
                                    }}
                                    className={
                                      commonCssStyle.tableHeadingTextData
                                    }
                                  >
                                    Disable
                                  </p>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Col>
                      </Row>
                    </List.Item>
                  )
                );
              }}
            />
          </Card>
        </>
      ),
    },
  ];
  return (
    <>
      <Card style={{ overflow: "hidden", paddingBottom: "70px" }}>
        <Card style={{ overflow: "hidden" }} className={styles.TagsCard1}>
          <Row align="middle" wrap={false}>
            <Col
              onClick={() => {
                props.backToApp();
              }}
              style={{ cursor: "pointer" }}
              className={`${styles.TagTemplatesBackBtnIcon} ${styles.TagTemplatesBackBtn}`}
            >
              <BackButtonIcon color={"var(--icons-color)"} />
            </Col>
            <Col>
              <h1 className={commonCssStyle.applicationCardName}>
                Job Listing Page
              </h1>
            </Col>
          </Row>
        </Card>

        <Tabs
          className="summarytabForJobListing"
          defaultActiveKey="1"
          onChange={onChange}
          items={itemsData}
        />
      </Card>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    jobListingDatas: state.jobListingPermission.jobListingPermissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getJobListingsHandler: () => {
      dispatch(getJobListingPermissionsAction());
    },
    putJobListingHandler: (data, callback) => {
      // const data = {
      //   id,
      //   accessType,
      //   status,
      // };
      dispatch(putJobListingPermissionsAction(data, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(JobListingPage);
