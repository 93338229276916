import { createSlice } from "@reduxjs/toolkit";

const initialState={
    Notifications:[],
    notification:null,
}

export const NotificationSlice = createSlice({
    name:"notification",
    initialState,
    reducers:{
        getNotificationSlice(state,action){
            state.Notifications=action.payload.result;
        },
        putNotificationSlice(state, action) {
            state.notification = action.payload.result;
          },
    }
})

export const {
    getNotificationSlice,
    putNotificationSlice,
}=NotificationSlice.actions;
export default NotificationSlice.reducer;