import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Radio,
  Row,
  Select,
  Tag,
} from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CloseIcon, FilterOutlinedIcon } from "../../../IconsComp/Icons";
import commonStyles from "../../../UI/CommonCss.module.css";

const tagRenderForMobileNum = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

function RecruitmentReportFilter(props) {
  const [form] = Form.useForm();

  const [filterDatas, setFilterDatas] = useState({
    jobId: "",
    empId: "",
    name: "",
    phone: "",
    email: "",
    joiningDate: "",
    role: "",
  });

  const onFinish = (val) => {
    const data = {
      ...val,
      joiningDate: val.joiningDate
        ? moment(val.joiningDate).format("YYYY-MM-DD")
        : "",
      type: "list",
      dateFrom: props.fromDate,
      dateTo: props.toDate,
    };
    localStorage.setItem("recuritmentReportFilter", JSON.stringify(data));
    props.onSubmitFltr(data, (success) => {
      if (success) {
        props.onClose();
      }
    });
    props.onClose();
  };

  useEffect(() => {
    let recuritmentReportFilterData = localStorage.getItem(
      "recuritmentReportFilter"
    );
    let recuritmentReportFilterData1 = JSON.parse(recuritmentReportFilterData);
    if (recuritmentReportFilterData1) {
      form.setFieldsValue({
        dateFrom: recuritmentReportFilterData1.dateFrom
          ? moment(recuritmentReportFilterData1.dateFrom)
          : "",
        dateTo: recuritmentReportFilterData1.dateTo
          ? moment(recuritmentReportFilterData1.dateTo)
          : "",
        email: recuritmentReportFilterData1.email,
        empId: recuritmentReportFilterData1.empId,
        jobId: recuritmentReportFilterData1.jobId,
        joiningDate: recuritmentReportFilterData1.joiningDate
          ? moment(recuritmentReportFilterData1.joiningDate)
          : "",
        name: recuritmentReportFilterData1.name,
        phone: recuritmentReportFilterData1.phone,
        role: recuritmentReportFilterData1.role,
        type: recuritmentReportFilterData1.type,
      });
    }
  }, []);

  const resetFilter = () => {
    form.resetFields();
    props.onSubmitFltr({
      current_page: 1,
      dateFrom: "1970-01-01",
      dateTo: "3000-01-01",
      type: "list",
    });
    props.onClose();
  };

  return (
    <>
      <Drawer
        className="modalModified"
        closable={false}
        extra={
          <div
            onClick={() => props.onClose()}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
          </div>
        }
        width={503}
        height={48}
        title={
          <>
            <Row>
              <Col>
                <FilterOutlinedIcon color={"var(--primaryColor)"} />
              </Col>
              <Col>
                <p
                  style={{ margin: "0px 0px 0px 5px" }}
                  className={commonStyles.filterHeadingName}
                >
                  Filter
                </p>
              </Col>
            </Row>
          </>
        }
        open={props.onOpen}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row style={{ padding: "0px 20px 80px 20px" }}>
            <Col md={24}>
              <Form.Item name="jobId" label="Job Title">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  // mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  className={`${commonStyles.border} candidateSelectHeight`}
                  placeholder="Select title"
                  tagRender={tagRenderForMobileNum}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={null}
                  showSearch
                  onKeyUp={(val) => {
                    setFilterDatas({
                      jobId: val.target.value,
                    });
                  }}
                >
                  {/* {filterDatas.title &&
                        filterDatas.title !== " " &&
                        filterDatas.title.length > 0 &&
                        props.requirements &&
                        props.requirements &&
                        props.requirements.map((data, index) => {
                          return (
                            <Option key={index} value={data.title}>
                              {data.title}
                            </Option>
                          );
                        })} */}
                  {filterDatas.jobId &&
                    filterDatas.jobId !== " " &&
                    filterDatas.jobId.length > 0 &&
                    props.requirements &&
                    props.requirements.map((data, index) => {
                      return (
                        <Option
                          key={index}
                          /* value={data.id} */ value={data.title}
                        >
                          {data.title}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item name="empId" label="Employee Code">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  // mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  className={`${commonStyles.border} candidateSelectHeight`}
                  placeholder="Select employee code"
                  tagRender={tagRenderForMobileNum}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={null}
                  showSearch
                  onKeyUp={(val) => {
                    setFilterDatas({
                      empId: val.target.value,
                    });
                  }}
                >
                  {/* {filterDatas.title &&
                        filterDatas.title !== " " &&
                        filterDatas.title.length > 0 &&
                        props.requirements &&
                        props.requirements &&
                        props.requirements.map((data, index) => {
                          return (
                            <Option key={index} value={data.title}>
                              {data.title}
                            </Option>
                          );
                        })} */}
                  {filterDatas.empId &&
                    filterDatas.empId !== " " &&
                    filterDatas.empId.length > 0 &&
                    props.employees &&
                    props.employees.map((data, index) => {
                      return (
                        <Option
                          key={index}
                          /* value={data.empId} */ value={data.empId}
                        >
                          {data.empId}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item name="name" label="Name">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  className={`${commonStyles.border} candidateSelectHeight`}
                  placeholder="Select name"
                  tagRender={tagRenderForMobileNum}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={null}
                  showSearch
                  onKeyUp={(val) => {
                    setFilterDatas({
                      name: val.target.value,
                    });
                  }}
                >
                  {/* {filterDatas.title &&
                        filterDatas.title !== " " &&
                        filterDatas.title.length > 0 &&
                        props.requirements &&
                        props.requirements &&
                        props.requirements.map((data, index) => {
                          return (
                            <Option key={index} value={data.title}>
                              {data.title}
                            </Option>
                          );
                        })} */}
                  {filterDatas.name &&
                    filterDatas.name !== " " &&
                    filterDatas.name.length > 0 &&
                    props.employees &&
                    props.employees.map((data, index) => {
                      return (
                        <Option
                          key={index}
                          value={data.firstName + " " + data.lastName}
                        >
                          {data.firstName + " " + data.lastName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item name="phone" label="Phone">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  className={`${commonStyles.border} candidateSelectHeight`}
                  placeholder="Select number"
                  tagRender={tagRenderForMobileNum}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={null}
                  showSearch
                  onKeyUp={(val) => {
                    setFilterDatas({
                      phone: val.target.value,
                    });
                  }}
                >
                  {/* {filterDatas.title &&
                        filterDatas.title !== " " &&
                        filterDatas.title.length > 0 &&
                        props.requirements &&
                        props.requirements &&
                        props.requirements.map((data, index) => {
                          return (
                            <Option key={index} value={data.title}>
                              {data.title}
                            </Option>
                          );
                        })} */}
                  {filterDatas.phone &&
                    filterDatas.phone !== " " &&
                    filterDatas.phone.length > 0 &&
                    props.employees &&
                    props.employees.map((data, index) => {
                      return (
                        <Option key={index} value={data.phone}>
                          {data.phone}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item name="email" label="Email">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  className={`${commonStyles.border} candidateSelectHeight`}
                  placeholder="Select email"
                  tagRender={tagRenderForMobileNum}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={null}
                  showSearch
                  onKeyUp={(val) => {
                    setFilterDatas({
                      email: val.target.value,
                    });
                  }}
                >
                  {/* {filterDatas.title &&
                        filterDatas.title !== " " &&
                        filterDatas.title.length > 0 &&
                        props.requirements &&
                        props.requirements &&
                        props.requirements.map((data, index) => {
                          return (
                            <Option key={index} value={data.title}>
                              {data.title}
                            </Option>
                          );
                        })} */}
                  {filterDatas.email &&
                    filterDatas.email !== " " &&
                    filterDatas.email.length > 0 &&
                    props.employees &&
                    props.employees.map((data, index) => {
                      return (
                        <Option key={index} value={data.email}>
                          {data.email}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Joining Date" name="joiningDate">
                <DatePicker
                  placeholder="Select date"
                  // format={dateFormate}
                  style={{ width: "100%" }}
                  className={`${commonStyles.datePickerBgColor} `} /* CandidateInputHeight */
                />
              </Form.Item>

              <Form.Item name="role" label="Employee Role">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  allowClear
                  showSearch
                  notFoundContent={null}
                  className={`${commonStyles.border} `} /* candidateSelectHeight */
                  placeholder="Select employee role"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onKeyUp={(val) => {
                    setFilterDatas({
                      role: val.target.value,
                    });
                  }}
                >
                  {filterDatas.role &&
                    filterDatas.role !== " " &&
                    filterDatas.role.length > 0 &&
                    props.roleTypeData &&
                    props.roleTypeData.map((data, index) => {
                      return (
                        data.isDefault !== 1 && (
                          <Option key={index} value={data.name}>
                            {data.name}
                          </Option>
                        )
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Col className={commonStyles.footerShadow} style={{ width: "503px" }}>
            <Row justify="end" style={{ gap: "25px" }}>
              <Col>
                <Button
                  className={commonStyles.buttonSettingsSecondary}
                  type="default"
                  onClick={() => {
                    localStorage.removeItem("recuritmentReportFilter");
                    localStorage.removeItem("reportRecruitmentEmployeeSort");
                    resetFilter();
                    props.resetDateFields();
                    setFilterDatas({
                      name: "",
                      email: "",
                      phone: "",
                      skills: "",
                    });
                    props.onClose();
                    localStorage.removeItem("recuritmentReportFilter");
                  }}
                >
                  Reset Filter
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
}

export default RecruitmentReportFilter;
