import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Switch,
  DatePicker,
  Checkbox,
  Image,
  message,
} from "antd";
import React, { useEffect, useRef } from "react";
import commonStyles from "../../../UI/CommonCss.module.css";
import PhoneInput from "react-phone-number-input";
import pdf from "../../../../Assets/Image/pdf.png";
import word from "../../../../Assets/Image/word.png";
import {
  UploadIcon,
  CloseIcon,
  PlusForButtonIcon,
  MinusIcons,
  EditIcon,
} from "../../../IconsComp/Icons";
import "antd-country-phone-input/dist/index.css";
import "flagpack/dist/flagpack.css";
import { connect } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { getEmployeesAction } from "../../../../Reusable/Store/Action/EmployeesAction";
import { getTagsAction } from "../../../../Reusable/Store/Action/Master/MasterTagsAction";
import { getStatusAction } from "../../../../Reusable/Store/Action/Master/MasterStatusAction";
import moment from "moment";
import {
  getCandidatesAction,
  putCandidatesAction,
} from "../../../../Reusable/Store/Action/CandidatesAction";
import { getLeadSourcesAction } from "../../../../Reusable/Store/Action/Master/MasterLeadSourceAction";
import { useForm } from "antd/lib/form/Form";

const PersonalDetailsModal = (props) => {
  let btnref = useRef(null);
  const [form] = useForm();
  const dateFormat =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const [dragActive, setDragActive] = useState(false);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    setFileRequired(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFilesData(e.dataTransfer.files[0]);
      setFileType(e.dataTransfer.files[0].type);
    }
  };

  const onChangeCountry = (value, data, type) => {
    props.onCoutriesData({ id: data.id, type: "state" });

    if (type !== 1) {
      form.setFieldsValue({
        city: initvalues.city,
        state: initvalues.state,
      });
    }
  };

  const onChangeCountryPermanent = (value, data, type) => {
    props.onCoutriesPermanentData({ id: data.id, type: "state" });

    if (type !== 1) {
      form.setFieldsValue({
        permanentcity: initvalues.permanentcity,
        permanentstate: initvalues.permanentstate,
      });
    }
  };

  const onChangeState = (value, data, type) => {
    if (type !== 1) {
      form.setFieldsValue({
        city: initvalues.city,
      });
    }
    if (data) {
      props.onCityData({ id: data.id, type: "city" });
    }
  };

  const onChangeStatePermanent = (value, data, type) => {
    if (type !== 1) {
      form.setFieldsValue({
        permanentcity: initvalues.permanentcity,
      });
    }
    if (data) {
      props.onPermanentDataCityData({ id: data.id, type: "city" });
    }
  };

  const [checkedCurrentAddress, setCheckedCurrentAddress] = useState(false);

  const [checkedSpecialAbilities, setCheckedSpecialAbilities] = useState(false);
  const [filesData, setFilesData] = useState("");
  const [whatsAppChecked, setWhatsappChecked] = useState(false);

  const [fileType, setFileType] = useState("");
  const [fileRequired, setFileRequired] = useState(true);
  const [frist, setFrist] = useState(true);
  const [fristForPer, setFristForPer] = useState(true);
  useEffect(() => {
    props.getTagHandler();
    props.getCandidateDetailsHandler();
    props.getLeadSourcesHandler();
    props.getStatusHandler();
    props.getEmployeesHandler();
    setTimeout(() => {
      if (props.candidate.addressCheck === 1) {
        setCheckedCurrentAddress(true);
      }

      if (props.candidate.specialAbility === 1) {
        setCheckedSpecialAbilities(true);
      }
      if (props.candidate.phone === props.candidate.whatsApp) {
        setWhatsappChecked(true);
      }

      // setStateData(stateFind); //state data

      let country =
        props.candidate.addressInfo.find((data) => data.type === "current") &&
        props.candidate.addressInfo.find((data) => data.type === "current")
          .country;
      // let state =
      //   props.candidate.addressInfo.find((data) => data.type === "current") &&
      //   props.candidate.addressInfo.find((data) => data.type === "current")
      //     .state;

      if (country) {
        let _country =
          props.countriesData &&
          props.countriesData.find((data) => {
            return data.name === country;
          });
        onChangeCountry(_country && _country.id, _country, 1);
      }
      // if (state) {
      //   let _state =
      //     props.onStatesData &&
      //     props.onStatesData.find((data) => {
      //       return data.name === state;
      //     });

      //   onChangeState(_state && _state.id, _state, 1);
      // }

      country =
        props.candidate.addressInfo.find((data) => data.type === "permanent") &&
        props.candidate.addressInfo.find((data) => data.type === "permanent")
          .country;

      // state =
      //   props.candidate.addressInfo.find((data) => data.type === "permanent") &&
      //   props.candidate.addressInfo.find((data) => data.type === "permanent")
      //     .state;

      if (country) {
        let _country =
          props.countriesData &&
          props.countriesData.find((data) => {
            return data.name === country;
          });
        onChangeCountryPermanent(_country && _country.id, _country, 1);
      }
      // if (state) {
      //   let _state =
      //     props.onPermanentStates &&
      //     props.onPermanentStates.find((data) => {
      //       return data.name === state;
      //     });
      //   onChangeStatePermanent(_state && _state.id, _state, 1);
      // }

      // if (country) {
      //   // onChangeCountryPermanent(country, 1);
      //   if (state) {
      //     let cityPermanentData =
      //       props.onStatesData &&
      //       props.onStatesData.find((data) => {
      //         return data.name === state;
      //       });
      //     setStatePermanent(cityPermanentData);
      //     let cityData =
      //       props.onCity &&
      //       props.onCity.find((data) => {
      //         return data.name === city;
      //       });
      //     setCityPermanent(cityData);
      //   }
      // }
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (fristForPer) {
        let state =
          props.candidate.addressInfo.find(
            (data) => data.type === "permanent"
          ) &&
          props.candidate.addressInfo.find((data) => data.type === "permanent")
            .state;
        if (state) {
          let _state =
            props.onPermanentStates &&
            props.onPermanentStates.find((data) => {
              return data.name === state;
            });
          onChangeStatePermanent(_state && _state.id, _state, 1);
          setFristForPer(false);
        }
      }
    }, 200);
  }, [props.onPermanentStates]);
  useEffect(() => {
    setTimeout(() => {
      if (frist) {
        let state =
          props.candidate.addressInfo.find((data) => data.type === "current") &&
          props.candidate.addressInfo.find((data) => data.type === "current")
            .state;
        if (state) {
          let _state =
            props.onStatesData &&
            props.onStatesData.find((data) => {
              return data.name === state;
            });

          onChangeState(_state && _state.id, _state, 1);
          setFrist(false);
        }
      }
    }, 200);
  }, [props.onStatesData]);

  const handleChange = (info) => {
    setFileRequired(false);
    if (!(info.target.files[0].size <= 2000000)) {
      message.warning("This file should be less than 2 MB", 3);
      return;
    }
    setFilesData(info.target.files[0]);
    setFileType(info.target.files[0].type);
  };

  const onFinishHandler = (values) => {
    // const experienceStartDateMonth = values.employeeInfo
    //   ? moment(values.employeeInfo[0].from).format("MM")
    //   : "";
    // const experienceStartDateYear = values.employeeInfo
    //   ? moment(values.employeeInfo[0].from).format("YYYY")
    //   : "";
    // const experienceEndtDateMonth = values.employeeInfo
    //   ? moment(values.employeeInfo[0].to).format("MM")
    //   : "";
    // const experienceEndDateYear = values.employeeInfo
    //   ? moment(values.employeeInfo[0].to).format("YYYY")
    //   : "";
    // const startMonth = experienceStartDateYear * 12 + experienceStartDateMonth;
    // const endMonth = experienceEndDateYear * 12 + experienceEndtDateMonth;
    // const monthInterval = endMonth - startMonth;
    // const monthsOfExperience = monthInterval % 12;
    // const totalExperienceMonths = monthsOfExperience;
    const {
      name,
      email,
      phone,
      whatsApp,
      gender,
      dob,
      location,
      country,
      address,
      state,
      city,
      pincode,
      specialAbilityComments,
      leadSource,
      recruitmentStatus,
      permanentaddress,
      permanentcity,
      permanentcountry,
      permanentlocation,
      permanentpincode,
      portfolio,
      permanentstate,
      communicationManagerId,
      relavantExperienceMonths,
      totalExperienceMonths,
      currentSalary,
    } = values;

    const currentSameAsPermanentAdrress = [
      {
        type: "current",
        location: location,
        country: country,
        address: address,
        state: state,
        city: city,
        pincode: pincode,
      },
      {
        type: "permanent",
        location: location,
        country: country,
        address: address,
        state: state,
        city: city,
        pincode: pincode,
      },
    ];
    const permanentAdrressIsDifferent = [
      {
        type: "current",
        location: location,
        country: country,
        address: address,
        state: state,
        city: city,
        pincode: pincode,
      },
      {
        type: "permanent",
        location: permanentlocation,
        country: permanentcountry,
        address: permanentaddress,
        state: permanentstate,
        city: permanentcity,
        pincode: permanentpincode,
      },
    ];

    const data = {
      id: props.data.id,
      relavantExperienceMonths,
      totalExperienceMonths,
      resume: filesData ? filesData : null,
      name,
      email,
      phone,
      portfolio,
      whatsApp: whatsAppChecked ? phone : whatsApp,
      gender,
      dob: moment(dob).format("YYYY-MM-DD"),
      communicationManagerId,
      specialAbility: checkedSpecialAbilities ? 1 : 0,
      specialAbilityComments,
      leadSource,
      currentSalary,
      addressCheck: checkedCurrentAddress ? 1 : 0,
      recruitmentStatus,
      _method: "PUT",
      addressInfo: checkedCurrentAddress
        ? currentSameAsPermanentAdrress
        : permanentAdrressIsDifferent,
    };

    if (!data.resume) delete data.resume;
    props.putCandidateHandler(data, "details", (success) => {
      if (success) {
        form.resetFields();
        props.onClosePersonalDetails();
      }
    });
  };
  const initvalues = {
    whatsApp: "",
    permanentlocation: "",
    permanentaddress: "",
    permanentcountry: "",
    permanentstate: "",
    permanentcity: "",
    permanentpincode: "",
    specialAbilityComments: "",
    city: "",
    state: "",
    permanentstate: "",
    permanentcity: "",
  };
  return (
    <>
      <Drawer
        className={"modalModified"}
        closable={false}
        extra={
          <div
            onClick={() => props.onClosePersonalDetails()}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--icons-color)"} />
          </div>
        }
        footer={false}
        style={{
          paddingBottom: "0px",
        }}
        title={
          <p
            style={{ fontSize: "20px", margin: "0px" }}
            className={`${commonStyles.modalHeading}`}
          >
            Edit Personal Details
          </p>
        }
        width={726}
        height={48}
        // onClose={props.onClosePersonalDetails}
        open={props.onOpenPersonalDetails}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          onDragEnter={handleDrag}
          form={form}
          layout="vertical"
          onFinish={(val) => onFinishHandler(val)}
          initialValues={
            props.candidate && {
              initvalues,
              recruitmentStatus: props.candidate.recruitmentStatus,
              specialAbility: props.candidate.specialAbility,
              name: props.candidate.name,
              email: props.candidate.email,
              currentSalary: props.candidate.currentSalary,
              gender: props.candidate.gender,
              totalExperienceMonths: props.candidate.totalExperienceMonths,
              relavantExperienceMonths:
                props.candidate.relavantExperienceMonths,
              // dob: moment(props.candidate.dob),
              dob:
                props.candidate.dob === null ||
                props.candidate.dob === "Invalid date"
                  ? undefined
                  : moment(props.candidate.dob),
              // portfolio:
              //   props.candidate.portfolio &&
              //   props.candidate.portfolio.map((data) => {
              //     return { link1: data.link1 };
              //   }),
              portfolio: props.candidate.portfolio && props.candidate.portfolio,
              phone: props.candidate.phone,
              specialAbilityComments: props.candidate.specialAbilityComments,
              leadSource: props.candidate.leadSource,
              whatsApp: props.candidate.whatsApp,
              communicationManagerId: props.candidate.communicationManagerId
                ? props.candidate.communicationManagerId
                : "",
              permanentcity:
                props.candidate.addressInfo.find(
                  (data) => data.type === "permanent"
                ) &&
                props.candidate.addressInfo.find(
                  (data) => data.type === "permanent"
                ).city,

              country:
                props.candidate.addressInfo.find(
                  (data) => data.type === "current"
                ) &&
                props.candidate.addressInfo.find(
                  (data) => data.type === "current"
                ).country,
              permanentcountry:
                props.candidate.addressInfo.find(
                  (data) => data.type === "permanent"
                ) &&
                props.candidate.addressInfo.find(
                  (data) => data.type === "permanent"
                ).country,
              city:
                props.candidate.addressInfo.find(
                  (data) => data.type === "current"
                ) &&
                props.candidate.addressInfo.find(
                  (data) => data.type === "current"
                ).city,

              state:
                props.candidate.addressInfo.find(
                  (data) => data.type === "current"
                ) &&
                props.candidate.addressInfo.find(
                  (data) => data.type === "current"
                ).state,
              permanentstate:
                props.candidate.addressInfo.find(
                  (data) => data.type === "permanent"
                ) &&
                props.candidate.addressInfo.find(
                  (data) => data.type === "permanent"
                ).state,
              location:
                props.candidate.addressInfo.find(
                  (data) => data.type === "current"
                ) &&
                props.candidate.addressInfo.find(
                  (data) => data.type === "current"
                ).location,
              pincode:
                props.candidate.addressInfo.find(
                  (data) => data.type === "current"
                ) &&
                props.candidate.addressInfo.find(
                  (data) => data.type === "current"
                ).pincode,
              permanentpincode:
                props.candidate.addressInfo.find(
                  (data) => data.type === "permanent"
                ) &&
                props.candidate.addressInfo.find(
                  (data) => data.type === "permanent"
                ).permanentpincode,
              address:
                props.candidate.addressInfo.find(
                  (data) => data.type === "current"
                ) &&
                props.candidate.addressInfo.find(
                  (data) => data.type === "current"
                ).address,

              permanentaddress:
                props.candidate.addressInfo.find(
                  (data) => data.type === "permanent"
                ) &&
                props.candidate.addressInfo.find(
                  (data) => data.type === "permanent"
                ).address,
              permanentlocation:
                props.candidate.addressInfo.find(
                  (data) => data.type === "permanent"
                ) &&
                props.candidate.addressInfo.find(
                  (data) => data.type === "permanent"
                ).location,
            }
          }
          style={{ marginBottom: "80px" }}
        >
          <Row className={commonStyles.modalRowStyling}>
            <Col flex={1}>
              <Row>
                <Col flex={1}>
                  <Row>
                    <Col flex={1}>
                      <input
                        hidden
                        accept="application/pdf,.docx"
                        type="file"
                        name="resume"
                        onChange={handleChange}
                        ref={(input) => {
                          btnref = input;
                        }}
                      />
                      <Form.Item
                        name="resume"
                        label={
                          <p
                            className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                          >
                            Resume
                          </p>
                        }
                        style={{ borderRadius: "1px" }}
                      >
                        {(props.candidate && props.candidate.resume) ||
                        !fileRequired ||
                        dragActive ? (
                          <>
                            <div
                              onDragEnter={handleDrag}
                              onDragLeave={handleDrag}
                              onDragOver={handleDrag}
                              onDrop={handleDrop}
                              onClick={(e) => {
                                e.preventDefault();
                                btnref.click();
                                return false;
                              }}
                              style={{
                                backgroundColor: "var(--input-color)",
                                height: "155px",
                                borderRadius: "6px",
                              }}
                            >
                              {!fileType ? (
                                <Image
                                  preview={false}
                                  src={
                                    props.candidate &&
                                    props.candidate.resume &&
                                    props.candidate.resume.split(".")[
                                      props.candidate.resume.split(".").length -
                                        1
                                    ] == "pdf"
                                      ? pdf
                                      : word
                                  }
                                  style={{
                                    height: "118px",
                                    width: "100px",
                                    borderRadius: "6px",
                                    position: "relative",
                                    display: "block",
                                    margin: "16px auto 21px 21px",
                                  }}
                                />
                              ) : (
                                <Image
                                  preview={false}
                                  src={
                                    fileType === "application/pdf" ? pdf : word
                                  }
                                  style={{
                                    height: "118px",
                                    width: "100px",
                                    borderRadius: "6px",
                                    position: "relative",
                                    display: "block",
                                    margin: "16px auto 21px 21px",
                                  }}
                                />
                              )}
                              <span
                                onClick={() => {
                                  setFilesData("");
                                  setFileRequired(true);
                                }}
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  paddingLeft: "10px",
                                  cursor: "pointer",
                                  color: "var(--body-text-color)",
                                }}
                              >
                                <EditIcon color={"var(--icons-color)"} />{" "}
                                Reupload resume
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                btnref.click();
                                return false;
                              }}
                              style={{
                                textAlign: "center",
                                height: "155px",
                                backgroundColor: "var(--input-color)",
                                borderRadius: "5px",
                              }}
                            >
                              <Row
                                justify="center"
                                style={{ paddingTop: "31px" }}
                              >
                                <Col>
                                  <UploadIcon
                                    color={"var(--body-text-color)"}
                                  />
                                </Col>
                              </Row>
                              <div>
                                <p
                                  style={{
                                    fontWeight: 400,
                                    textAlign: "center",
                                    marginTop: "10px",
                                    fontFamily: "Helvetica,ProductSans",
                                    fontStyle: "normal",
                                    fontSize: "16px",
                                    lineHeight: "19px",
                                    color: "var(--body-text-color)",
                                  }}
                                >
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Upload
                                  Resume &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                  or &nbsp;&nbsp;&nbsp; Drag and drop resume
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        name="name"
                        type=""
                        label="Full Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter full name",
                          },
                          // {
                          //   pattern: /^[A-Z a-z]+$/,
                          //   message: "Enter only alphabets",
                          // },
                        ]}
                      >
                        <Input placeholder="Enter full name" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your email",
                          },
                          {
                            type: "email",
                            message: "The input is not valid E-mail!.",
                          },
                        ]}
                      >
                        <Input
                          disabled={true}
                          placeholder="Enter email address"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        label="Gender"
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: "Please select gender",
                          },
                        ]}
                      >
                        <Select
                          getPopupContainer={(trigger) => trigger.parentElement}
                          placeholder="Select gender"
                        >
                          <Select.Option value="Male">Male</Select.Option>
                          <Select.Option value="Female">Female</Select.Option>
                          <Select.Option value="Other">Other</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        label="DOB"
                        name="dob"
                        rules={[
                          {
                            required: false,
                            message: "Please enter date of birth",
                          },
                        ]}
                      >
                        <DatePicker
                          getPopupContainer={(trigger) => trigger.parentElement}
                          disabledDate={(current) =>
                            current.isAfter(moment().subtract(1, "day"))
                          }
                          defaultPickerValue={moment().subtract(16, "year")}
                          format={dateFormat}
                          className={commonStyles.datePickerBgColor}
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="modelcol" md={24}>
                      <Form.Item
                        name="phone"
                        label="Contact Number"
                        rules={[
                          {
                            required: true,
                            message: "Please enter contact number",
                          },
                        ]}
                      >
                        <PhoneInput
                          placeholder="Enter contact number"
                          className="ant-input ant-cust-inputs"
                          defaultCountry="IN"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ gap: "10px" }} align="middle">
                    <Col>
                      <Form.Item
                        name="whatsApp"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        <Checkbox
                          checked={whatsAppChecked}
                          className="intellcheckbox"
                          onChange={(val) => {
                            setWhatsappChecked(val.target.checked);
                            form.setFieldsValue({
                              whatsApp: initvalues.whatsApp,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <p
                        className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                      >
                        Whatsapp Number is same as Contact Number
                      </p>
                    </Col>
                  </Row>
                  {!whatsAppChecked && (
                    <Row>
                      <Col className="modelcol" md={24}>
                        <Form.Item
                          name="whatsApp"
                          label="Whatsapp Number"
                          rules={[
                            {
                              required: false,
                              message: "Please enter whatsapp number",
                            },
                          ]}
                        >
                          <PhoneInput
                            placeholder="Enter whatsapp number"
                            className="ant-input ant-cust-inputs"
                            defaultCountry="IN"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Row className={commonStyles.sectionEndGap}>
                    <Col>
                      <h1 className={commonStyles.HeadingFormDesign}>
                        Current Address
                      </h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        name="location"
                        label="Apartment, flat/house number"
                      >
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter apartment, flat/house number"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item name="address" label="Address">
                        <Input placeholder="Enter address" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        label="Country"
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: "Please select your country",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          getPopupContainer={(trigger) => trigger.parentElement}
                          onChange={onChangeCountry}
                          placeholder="Select country"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={
                            props.countriesData &&
                            props.countriesData.map((item) => ({
                              value: item.name,
                              label: item.name,
                              id: item.id,
                            }))
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        label="State"
                        name="state"
                        rules={[
                          {
                            required: true,
                            message: "Please select your state",
                          },
                        ]}
                      >
                        {props.onStatesData &&
                        props.onStatesData.length === 0 ? (
                          <Input placeholder="Enter state" />
                        ) : (
                          <Select
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            onChange={onChangeState}
                            showSearch
                            placeholder="Select state"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={
                              props.onStatesData &&
                              props.onStatesData.map((item) => ({
                                value: item.name,
                                id: item.id,
                                label: item.name,
                              }))
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        label="City"
                        name="city"
                        rules={[
                          {
                            required: true,
                            message: "Please select your city",
                          },
                        ]}
                      >
                        {props.onCity && props.onCity.length === 0 ? (
                          <Input placeholder="Enter city" />
                        ) : (
                          <Select
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            showSearch
                            placeholder="Select city"
                          >
                            {props.onCity &&
                              props.onCity.map((data, index) => {
                                return (
                                  <Select.Option value={data.name} key={index}>
                                    {data.name}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item label="Pincode" name="pincode">
                        <Input placeholder="Enter pincode" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ gap: "10px" }} align="middle">
                    <Col>
                      <Form.Item style={{ margin: "0px", padding: "0px" }}>
                        <Switch
                          checked={checkedCurrentAddress}
                          onChange={(val) => {
                            setCheckedCurrentAddress(val);
                            form.setFieldsValue({
                              permanentlocation: initvalues.permanentlocation,
                              permanentaddress: initvalues.permanentaddress,
                              permanentcountry: initvalues.permanentcountry,
                              permanentstate: initvalues.permanentstate,
                              permanentcity: initvalues.permanentcity,
                              permanentpincode: initvalues.permanentpincode,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <p
                        className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove} `}
                      >
                        Current Address is same as Permanent Address
                      </p>
                    </Col>
                  </Row>

                  {!checkedCurrentAddress && (
                    <>
                      <Row className={commonStyles.sectionEndGap}>
                        <Col>
                          <h1 className={commonStyles.HeadingFormDesign}>
                            Permanent Address
                          </h1>
                        </Col>
                      </Row>
                      <Row>
                        <Col flex={1}>
                          <Form.Item
                            name="permanentlocation"
                            label="Apartment, flat/house number"
                          >
                            <Input
                              style={{ width: "100%" }}
                              placeholder="Enter apartment, flat/house number"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col flex={1}>
                          <Form.Item name="permanentaddress" label="Address">
                            <Input placeholder="Enter permanent address" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col flex={1}>
                          <Form.Item
                            label="Country"
                            name="permanentcountry"
                            rules={[
                              {
                                required: true,
                                message: "Please select your country",
                              },
                            ]}
                          >
                            <Select
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                              showSearch
                              onChange={onChangeCountryPermanent}
                              placeholder="Select country"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={
                                props.countriesData &&
                                props.countriesData.map((item, index) => ({
                                  value: item.name,
                                  label: item.name,
                                  key: index,
                                  id: item.id,
                                }))
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col flex={1}>
                          <Form.Item
                            label="State"
                            name="permanentstate"
                            rules={[
                              {
                                required: true,
                                message: "Please select your state",
                              },
                            ]}
                          >
                            {props.onPermanentStates &&
                            props.onPermanentStates.length === 0 ? (
                              <Input placeholder="Enter state" />
                            ) : (
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentElement
                                }
                                onChange={onChangeStatePermanent}
                                showSearch
                                placeholder="Select state"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {props.onPermanentStates &&
                                  props.onPermanentStates.map((data, index) => {
                                    return (
                                      <Select.Option
                                        key={index}
                                        value={data.name}
                                        id={data.id}
                                      >
                                        {data.name}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col flex={1}>
                          <Form.Item
                            label="City"
                            name="permanentcity"
                            rules={[
                              {
                                required: true,
                                message: "Please select your city",
                              },
                            ]}
                          >
                            {props.onPermanentCity &&
                            props.onPermanentCity.length === 0 ? (
                              <Input placeholder="Enter permanent city" />
                            ) : (
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentElement
                                }
                                showSearch
                                placeholder="Select city"
                              >
                                {props.onPermanentCity &&
                                  props.onPermanentCity.map((data, index) => {
                                    return (
                                      <Select.Option
                                        value={data.name}
                                        key={index}
                                      >
                                        {data.name}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col flex={1}>
                          <Form.Item label="Pincode" name="permanentpincode">
                            <Input placeholder="Enter pincode" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className={commonStyles.sectionEndGap}>
                    <Col>
                      <div className="ant-col ant-form-item-label">
                        <label /* className="ant-form-item-required" */>
                          Portfolio / Work sample
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "20px" }}>
                    <Col className="modelcol" md={24}>
                      <Form.List name="portfolio">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map((field, key) => (
                              <div>
                                <Row>
                                  <Col flex={1}>
                                    <AddLink field={field}></AddLink>
                                  </Col>

                                  <Col md={24} style={{ marginBottom: "10px" }}>
                                    <p
                                      style={{
                                        marginTop: "0px",
                                        marginBottom: "8px",
                                        float: "right",
                                        color: "#F83A3A",
                                      }}
                                      className={`${commonStyles.marginBottomRemove} ${commonStyles.removeLocation}`}
                                    >
                                      Remove Link
                                    </p>
                                    <Button
                                      type="primary"
                                      size="small"
                                      shape="circle"
                                      className={commonStyles.removeLocationbtn}
                                      style={{
                                        marginTop: "3px",
                                        marginRight: "7px",
                                        float: "right",
                                        backgroundColor: "#F83A3A",
                                      }}
                                    >
                                      <div
                                        style={{
                                          transform: "translateY(-4px)",
                                        }}
                                        onClick={() => remove(key)}
                                      >
                                        <MinusIcons color={"var(--theme)"} />
                                      </div>
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                            <Button
                              size="small"
                              onClick={() => add()}
                              type="primary"
                              shape="circle"
                            >
                              <div style={{ transform: "translateY(2px)" }}>
                                <PlusForButtonIcon
                                  height={"19px"}
                                  width={"19px"}
                                  color={"var(--theme)"}
                                />
                              </div>
                            </Button>{" "}
                            <p
                              style={{ marginTop: "-27px", marginLeft: "40px" }}
                              className={`${commonStyles.marginBottomRemove} ${commonStyles.addLocation}`}
                            >
                              {" "}
                              Add Link
                            </p>
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                  <Row className={commonStyles.sectionEndGap}>
                    <Col md={24}>
                      <Form.Item
                        label="Total Experience in Years"
                        name={"totalExperienceMonths"}
                        rules={[
                          {
                            required: false,
                            message: "Please enter total experience",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Enter total experience"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <Form.Item
                        label="Relevant Experience in Years"
                        name={"relavantExperienceMonths"}
                        rules={[
                          {
                            required: false,
                            message: "Please enter relevant experience",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Enter relevant experience"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please enter current salary",
                          },
                        ]}
                        label="Current Salary (CTC in Lakhs)"
                        name={"currentSalary"}
                      >
                        <Input
                          type="number"
                          placeholder="Enter current salary"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        label="Source"
                        name="leadSource"
                        rules={[
                          {
                            required: true,
                            message: "Please select source",
                          },
                        ]}
                      >
                        <Select
                          getPopupContainer={(trigger) => trigger.parentElement}
                          placeholder="Select source"
                        >
                          {props.sourceOfTheLeadData.map((data) => {
                            return (
                              <Select.Option key={data.id} value={data.source}>
                                {data.source}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        label="Current Stage"
                        name="recruitmentStatus"
                        rules={[
                          {
                            required: false,
                            message: "Please select current stage",
                          },
                        ]}
                      >
                        <Select
                          getPopupContainer={(trigger) => trigger.parentElement}
                          placeholder="Select stage"
                        >
                          {props.currentStageData &&
                            props.currentStageData.map((data) => {
                              if (data.status) {
                                return data.status.map((x) => {
                                  return (
                                    <Select.Option
                                      value={data.statusType + "/" + x}
                                      key={data.statusType + "/" + x}
                                    >
                                      {data.statusType + "/" + x}
                                    </Select.Option>
                                  );
                                });
                              } else {
                                return (
                                  <Select.Option
                                    value={data.statusType}
                                    key={data.statusType}
                                  >
                                    {data.statusType}
                                  </Select.Option>
                                );
                              }
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="specialAbility"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <Row style={{ gap: "10px" }}>
                      <Col>
                        {props.candidate.specialAbility ? (
                          <Switch
                            defaultChecked
                            onChange={(val) => {
                              setCheckedSpecialAbilities(val);
                            }}
                          />
                        ) : (
                          <Switch
                            onChange={(val) => {
                              setCheckedSpecialAbilities(val);
                              form.setFieldsValue({
                                specialAbilityComments:
                                  initvalues.specialAbilityComments,
                              });
                            }}
                          />
                        )}
                      </Col>
                      <Col>
                        <p className={`${commonStyles.formInputLabel}`}>
                          Special Abilities
                        </p>
                      </Col>
                    </Row>
                  </Form.Item>

                  {checkedSpecialAbilities ? (
                    <Form.Item
                      name="specialAbilityComments"
                      label="Special Ability Comments"
                      rules={[
                        {
                          required: false,
                          message: "Please enter special ability comments",
                        },
                      ]}
                    >
                      <TextArea
                        placeholder="Describe your special abilities"
                        rows={3}
                      />
                    </Form.Item>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Col xs={24} md={24} className={commonStyles.footerShadow}>
            <Row justify="end" style={{ gap: "17px", width: "690px" }}>
              <Col>
                <Button
                  onClick={() => {
                    form.resetFields();
                    props.onClosePersonalDetails();
                  }}
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                  className={
                    commonStyles.footerButtonAddCandidateDrawerSecondary
                  }
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button
                  onClick={() => {
                    form.submit();
                  }}
                  htmlType="button"
                  className={commonStyles.footerButtonAddCandidateDrawerPrimary}
                  type="primary"
                >
                  Save Candidate
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
};
function AddLink({ field }) {
  return (
    <Row>
      <Col flex={1}>
        <Form.Item
          // name={[field.name, "link1"]}
          {...field}
          rules={[
            {
              required: true,
              message: "Please enter portfolio/work sample",
            },
          ]}
        >
          <Input placeholder="Paste the link here" />
        </Form.Item>
      </Col>
    </Row>
  );
}
const mapStateToProps = (state) => {
  return {
    settingsData: state.settings.settings,
    candidate: state.candidates.candidate,
    TagsData: state.tags.tags,
    sourceOfTheLeadData: state.leadSource.leadSources,
    currentStageData: state.status.status,
    communicationManager: state.employee.employees,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    putCandidateHandler: (val, type, callback) => {
      dispatch(putCandidatesAction(val, type, callback));
    },
    getLeadSourcesHandler: () => {
      dispatch(getLeadSourcesAction());
    },

    getTagHandler: () => {
      dispatch(getTagsAction());
    },
    getCandidateDetailsHandler: () => {
      dispatch(getCandidatesAction());
    },
    getStatusHandler: () => {
      dispatch(getStatusAction());
    },

    getEmployeesHandler: () => {
      dispatch(getEmployeesAction());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalDetailsModal);
