import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Checkbox,
  DatePicker,
  Image,
  message,
  InputNumber,
} from "antd";
import React, { useEffect } from "react";
import { CloseIcon, EditIcon } from "../../IconsComp/Icons";
import "antd-country-phone-input/dist/index.css";
import "flagpack/dist/flagpack.css";
import commonStyles from "../../UI/CommonCss.module.css";
import PhoneInput from "react-phone-number-input";

import { UploadIcon } from "../../IconsComp/Icons";
import { useState } from "react";
import { useRef } from "react";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";

const EmployeesPersonalDetails = (props) => {
  let btnref = useRef(null);
  const [form] = useForm();

  const [fileDataForImage, setFileDataForImage] = useState("");
  const [whatsAppChecked, setWhatsappChecked] = useState(false);
  const [checkedCurrentAddress, setCheckedCurrentAddress] = useState(false);
  const [state, setState] = useState([]);
  const [statePermanent, setStatePermanent] = useState([]);
  const [city, setCity] = useState([]);
  const [cityPermanent, setCityPermanent] = useState([]);
  const [first, setFirst] = useState(true);
  const [firstForPer, setFirstForPer] = useState(true);
  const [fileType, setFileType] = useState("");
  const [isExternal, setIsExternal] = useState(true);

  const onChangeCountry = (value, data, type) => {
    if (data) {
      props.onCoutriesData({ id: data.id, type: "state" });
    }

    if (type !== 1) {
      form.setFieldsValue({
        city: initvalues.city,
        state: initvalues.state,
      });
    }
  };
  const onChangeCountryPermanent = (value, data, type) => {
    if (data) {
      props.onCoutriesPermanentData({ id: data.id, type: "state" });
    }

    if (type !== 1) {
      form.setFieldsValue({
        permanentstate: initvalues.permanentstate,
        permanentcity: initvalues.permanentcity,
      });
    }
  };
  const onChangeState = (value, data, type) => {
    if (type !== 1) {
      form.setFieldsValue({
        city: initvalues.city,
      });
    }
    props.onCityData({ id: data.id, type: "city" });
  };
  const onChangeStatePermanent = (value, data, type) => {
    if (type !== 1) {
      form.setFieldsValue({
        permanentcity: initvalues.permanentcity,
      });
    }
    props.onPermanentDataCityData({ id: data.id, type: "city" });
  };

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const handleChange = (info) => {
    if (info.target.files[0].size <= 2000000) {
      setFileDataForImage(info.target.files[0]);
    } else {
      message.warning("This file should be less than 2 MB", 3);
    }
    setFileType(info.target.files[0]);
  };
  useEffect(() => {
    if (props?.employee?.isExternal == 1) {
      setIsExternal(false);
    }
    setTimeout(() => {
      let currentAddress =
        props.employee &&
        props.employee.addressInfo &&
        props.employee.addressInfo.find((data) => data.type === "current");
      let permanentAddress =
        props.employee &&
        props.employee.addressInfo &&
        props.employee.addressInfo.find((data) => data.type === "permanent");
      if (
        currentAddress.location === permanentAddress.location &&
        currentAddress.state === permanentAddress.state &&
        currentAddress.city === permanentAddress.city &&
        currentAddress.address === permanentAddress.address &&
        currentAddress.pincode === permanentAddress.pincode
      ) {
        setCheckedCurrentAddress(true);
      }

      if (props.employee.phone === props.employee.whatsApp) {
        setWhatsappChecked(true);
      }
      let country =
        props.employee.addressInfo.find((data) => data.type === "current") &&
        props.employee.addressInfo.find((data) => data.type === "current")
          .country;

      if (country) {
        let _country =
          props.countriesData &&
          props.countriesData.find((data) => {
            return data.name === country;
          });
        onChangeCountry(_country && _country.id, _country, 1);
      }

      country =
        props.employee.addressInfo.find((data) => data.type === "permanent") &&
        props.employee.addressInfo.find((data) => data.type === "permanent")
          .country;

      if (country) {
        let _country =
          props.countriesData &&
          props.countriesData.find((data) => {
            return data.name === country;
          });
        onChangeCountry(_country && _country.id, _country, 1);
      }

      // if (country) {
      //   // onChangeCountryPermanent(country, 1);
      //   if (state) {
      //     let cityPermanentData =
      //       props.onStatesData &&
      //       props.onStatesData.find((data) => {
      //         return data.name === state;
      //       });

      //     //onChangeStatePermanent(cityPermanentData, 1);
      //   }
      // }
    }, 100);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (first) {
        let state =
          props.employee.addressInfo.find((data) => data.type === "current") &&
          props.employee.addressInfo.find((data) => data.type === "current")
            .state;
        if (state) {
          let _state =
            props.onStatesData &&
            props.onStatesData.find((data) => {
              return data.name === state;
            });

          onChangeState(_state && _state.id, _state, 1);
        }
        setFirst(false);
      }
    }, 300);
  }, [props.onStatesData]);

  useEffect(() => {
    setTimeout(() => {
      if (firstForPer) {
        let state =
          props.employee.addressInfo.find(
            (data) => data.type === "permanent"
          ) &&
          props.employee.addressInfo.find((data) => data.type === "permanent")
            .state;
        if (state) {
          let _state =
            props.onPermanentStates &&
            props.onPermanentStates.find((data) => {
              return data.name === state;
            });

          onChangeState(_state && _state.id, _state, 1);
        }
        setFirstForPer(false);
      }
    }, 300);
  }, [props.onPermanentStates]);

  const onSubmit = (val) => {
    const currentSameAsPermanentAdrress = [
      {
        type: "current",
        location: val.location,
        country: val.country,
        address: val.address,
        state: val.state,
        city: val.city,
        pincode: val.pincode,
      },
      {
        type: "permanent",
        location: val.location,
        country: val.country,
        address: val.address,
        state: val.state,
        city: val.city,
        pincode: val.pincode,
      },
    ];
    const permanentAdrressIsDifferent = [
      {
        type: "current",
        location: val.location,
        country: val.country,
        address: val.address,
        state: val.state,
        city: val.city,
        pincode: val.pincode,
      },
      {
        type: "permanent",
        location: val.permanentlocation,
        country: val.permanentcountry,
        address: val.permanentaddress,
        state: val.permanentstate,
        city: val.permanentcity,
        pincode: val.permanentpincode,
      },
    ];

    let data = {
      ...val,
      addressInfo: checkedCurrentAddress
        ? currentSameAsPermanentAdrress
        : permanentAdrressIsDifferent,
      addressCheck: checkedCurrentAddress ? 1 : 0,
      dob: moment(val.dob).format("YYYY-MM-DD"),
      profileImage: fileDataForImage,
      whatsAppCheck: whatsAppChecked ? 1 : 0,
      whatsApp: whatsAppChecked ? val.phone : val.whatsApp,
    };

    props.onSubmit(data);
  };

  const initvalues = {
    whatsApp: "",
    city: "",
    state: "",
    permanentstate: "",
    permanentcity: "",
  };
  return (
    <Drawer
      className={"modalModified"}
      extra={
        <div
          onClick={() => props.personalDetailsClose()}
          className={`${commonStyles.coursorPointer} `}
        >
          {" "}
          <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
        </div>
      }
      footer={false}
      style={{
        paddingBottom: "0px",
      }}
      // className={styles.addcandidatetop}
      title={
        <p
          style={{ fontSize: "20px", margin: "0px" }}
          className={commonStyles.modalHeading}
        >
          Edit Personal Details
        </p>
      }
      height={48}
      width={806}
      closable={false}
      open={props.personalDetailsOpen}
    >
      <Form
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
        form={form}
        initialValues={
          props.employee && {
            initvalues,
            firstName: props.employee.firstName,
            lastName: props.employee.lastName,
            email: props.employee.email,
            phone: props.employee.phone,
            whatsApp: props.employee.whatsApp,
            gender: props.employee.gender,
            dob: props.employee.dob && moment(props.employee.dob),
            permanentcity:
              props.employee.addressInfo.find(
                (data) => data.type === "permanent"
              ) &&
              props.employee.addressInfo.find(
                (data) => data.type === "permanent"
              ).city,

            country:
              props.employee.addressInfo.find(
                (data) => data.type === "current"
              ) &&
              props.employee.addressInfo.find((data) => data.type === "current")
                .country,
            permanentcountry:
              props.employee.addressInfo.find(
                (data) => data.type === "permanent"
              ) &&
              props.employee.addressInfo.find(
                (data) => data.type === "permanent"
              ).country,
            city:
              props.employee.addressInfo.find(
                (data) => data.type === "current"
              ) &&
              props.employee.addressInfo.find((data) => data.type === "current")
                .city,

            state:
              props.employee.addressInfo.find(
                (data) => data.type === "current"
              ) &&
              props.employee.addressInfo.find((data) => data.type === "current")
                .state,
            permanentstate:
              props.employee.addressInfo.find(
                (data) => data.type === "permanent"
              ) &&
              props.employee.addressInfo.find(
                (data) => data.type === "permanent"
              ).state,
            location:
              props.employee.addressInfo.find(
                (data) => data.type === "current"
              ) &&
              props.employee.addressInfo.find((data) => data.type === "current")
                .location,
            pincode:
              props.employee.addressInfo.find(
                (data) => data.type === "current"
              ) &&
              props.employee.addressInfo.find((data) => data.type === "current")
                .pincode,
            permanentpincode:
              props.employee.addressInfo.find(
                (data) => data.type === "permanent"
              ) &&
              props.employee.addressInfo.find(
                (data) => data.type === "permanent"
              ).pincode,
            address:
              props.employee.addressInfo.find(
                (data) => data.type === "current"
              ) &&
              props.employee.addressInfo.find((data) => data.type === "current")
                .address,

            permanentaddress:
              props.employee.addressInfo.find(
                (data) => data.type === "permanent"
              ) &&
              props.employee.addressInfo.find(
                (data) => data.type === "permanent"
              ).address,
            permanentlocation:
              props.employee.addressInfo.find(
                (data) => data.type === "permanent"
              ) &&
              props.employee.addressInfo.find(
                (data) => data.type === "permanent"
              ).location,
          }
        }
        layout="vertical"
        onFinish={onSubmit}
      >
        <Row
          className={commonStyles.modalRowStyling}
          style={{ marginBottom: "85px" }}
        >
          <Col md={24}>
            <input
              hidden
              accept="image/*"
              type="file"
              name="fileUpload"
              onChange={handleChange}
              ref={(input) => {
                btnref = input;
              }}
            />
            <Form.Item name="agreement" label="Display Picture">
              {fileDataForImage ||
              (props.employee && props.employee.profileImage) ? (
                <>
                  <div
                    style={{
                      backgroundColor: "var(--input-color)",
                      height: "155px",
                      borderRadius: "6px",
                    }}
                  >
                    {/* <Image
                    onClick={(e) => {
                      e.preventDefault();
                      btnref.click();
                      return false;
                    }}
                    preview={false}
                    src={fileDataForImage||(props.employee&&props.employee.profileImage)}
                    style={{
                      height: "118px",
                      width: "100px",
                      borderRadius: "6px",
                      position: "relative",
                      display: "block",
                      margin: "16px auto 21px 21px",
                    }}
                  /> */}
                    {!fileType ? (
                      <Image
                        preview={false}
                        src={
                          /* fileDataForImage|| */ props.employee &&
                          props.employee.profileImage
                        }
                        style={{
                          height: "118px",
                          width: "100px",
                          borderRadius: "6px",
                          position: "relative",
                          display: "block",
                          margin: "16px auto 21px 21px",
                        }}
                      />
                    ) : (
                      <Image
                        preview={false}
                        src={URL.createObjectURL(fileType)}
                        style={{
                          height: "118px",
                          width: "100px",
                          borderRadius: "6px",
                          position: "relative",
                          display: "block",
                          margin: "16px auto 21px 21px",
                        }}
                      />
                    )}
                    {/* <span
                      onClick={() => {
                        setFileDataForImage("");
                        setFileType("");
                      }}
                      style={{
                        position: "absolute",
                        top: "11px",
                        paddingLeft: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <CloseIcon color={"var(--body-text-color)"} />
                    </span> */}
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        btnref.click();
                        return false;
                      }}
                      style={{
                        position: "absolute",
                        top: "20px",
                        paddingLeft: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <EditIcon color={"var(--body-text-color)"} />
                    </span>
                  </div>
                </>
              ) : (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    btnref.click();
                    return false;
                  }}
                  style={{
                    textAlign: "center",

                    padding: "20px",
                    height: "110px",
                    backgroundColor: "var(--input-color)",
                    borderRadius: "5px",
                    paddingBottom: "29px",
                  }}
                >
                  <UploadIcon color={"var(--body-text-color)"} />
                  <div>
                    <p
                      style={{
                        fontWeight: 400,
                        textAlign: "center",
                        color: "var(--upload-text-color)",
                      }}
                    >
                      Upload Logo or Drag logo here
                    </p>
                  </div>
                </div>
              )}
            </Form.Item>

            <Form.Item
              style={{ marginTop: "20px" }}
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please enter first name",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                //   message:
                //     "It allows only alphabets, no whitespace at the beginning and end",
                // },
              ]}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>

            <Form.Item
              style={{ marginTop: "20px" }}
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please enter last name",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                //   message:
                //     "It allows only alphabets, no whitespace at the beginning and end",
                // },
              ]}
            >
              <Input placeholder="Enter last name" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Official Email Address"
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
                {
                  type: "email",
                  message: "The input is not valid E-mail!.",
                },
              ]}
            >
              <Input placeholder="Enter email address" />
            </Form.Item>

            <Form.Item
              className={`phoneInputStroke`}
              name="phone"
              label="Contact Number"
              rules={[
                {
                  required: true,
                  message: "Please enter contact number",
                },
              ]}
            >
              <PhoneInput
                placeholder="Enter contact number"
                className={`ant-input ant-cust-inputs`}
                defaultCountry="IN"
              />
            </Form.Item>
            <Row style={{ gap: "10px" }} align="middle">
              <Col>
                <Form.Item
                  name="whatsAppCheck"
                  style={{ margin: "0px", padding: "0px" }}
                >
                  <Checkbox
                    checked={whatsAppChecked}
                    className="intellcheckbox"
                    onChange={(val) => {
                      setWhatsappChecked(val.target.checked);
                      form.setFieldsValue({ whatsApp: initvalues.whatsApp });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <p
                  className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                >
                  Whatsapp Number is same as Contact Number
                </p>
              </Col>
            </Row>

            {!whatsAppChecked && (
              <Row>
                <Col className="modelcol" md={24}>
                  <Form.Item
                    className={`phoneInputStroke`}
                    rules={[
                      {
                        required: true,
                        message: "Please enter whatsapp number",
                      },
                    ]}
                    name="whatsApp"
                    label="Whatsapp Number"
                  >
                    <PhoneInput
                      placeholder="Enter whatsapp number"
                      className="ant-input ant-cust-inputs"
                      defaultCountry="IN"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Form.Item
              className={commonStyles.sectionEndGap}
              label="Gender"
              name="gender"
              rules={[
                {
                  required: isExternal,
                  message: "Please select gender",
                },
              ]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder="Select gender"
              >
                <Select.Option value="Male">Male</Select.Option>
                <Select.Option value="Female">Female</Select.Option>
                <Select.Option value="Other">Other</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="DOB"
              name="dob"
              rules={[
                {
                  required: false,
                  message: "Please select of birth",
                },
              ]}
            >
              <DatePicker
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(16, "year"))
                }
                getPopupContainer={(trigger) => trigger.parentElement}
                defaultPickerValue={moment().subtract(16, "year")}
                placeholder="Select date of birth"
                format={dataFormate}
                style={{
                  width: "100%",
                  backgroundColor: "var(--input-color)",
                }}
              />
            </Form.Item>
            <Row className={commonStyles.sectionEndGap}>
              <Col>
                <h1 className={commonStyles.HeadingFormDesign}>
                  Current Address
                </h1>
              </Col>
            </Row>
            <Row>
              <Col flex={1}>
                <Form.Item
                  name="location"
                  label="Apartment, flat/house number"
                  rules={[
                    {
                      required: isExternal,
                      message: "Please enter apartment, flat/house number",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "100%" }}
                    placeholder="Enter apartment, flat/house number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col flex={1}>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[
                    {
                      required: isExternal,
                      message: "Please enter address",
                    },
                  ]}
                >
                  <Input placeholder="Enter address" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col flex={1}>
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[
                    {
                      required: isExternal,
                      message: "Please select country",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    showSearch
                    onChange={onChangeCountry}
                    placeholder="Select country"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={
                      props.countriesData &&
                      props.countriesData.map((item) => ({
                        value: item.name,
                        id: item.id,
                        label: item.name,
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col flex={1}>
                <Form.Item
                  label="State"
                  name="state"
                  rules={[
                    {
                      required: isExternal,
                      message: "Please select state",
                    },
                  ]}
                >
                  {props.onStatesData && props.onStatesData.length === 0 ? (
                    <Input placeholder="Enter state" />
                  ) : (
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      onChange={onChangeState}
                      showSearch
                      placeholder="Select state"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={
                        props.onStatesData &&
                        props.onStatesData.map((item) => ({
                          value: item.name,
                          id: item.id,
                          label: item.name,
                        }))
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col flex={1}>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[
                    {
                      required: isExternal,
                      message: "Please select your city",
                    },
                  ]}
                >
                  {props.onCity && props.onCity.length === 0 ? (
                    <Input placeholder="Enter city" />
                  ) : (
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      showSearch
                      placeholder="Select city"
                    >
                      {props.onCity &&
                        props.onCity.map((data, index) => {
                          return (
                            <Select.Option value={data.name} key={index}>
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col flex={1}>
                <Form.Item
                  label="Pincode"
                  name="pincode"
                  rules={[
                    {
                      required: isExternal,
                      message: "Please enter pincode",
                    },
                    // {
                    //   pattern: /^[0-9]+$/,
                    //   message: "Enter only number",
                    // },
                  ]}
                >
                  <Input placeholder="Enter pincode" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="addressCheck"
              style={{ margin: "0px", padding: "0px" }}
            >
              <Row style={{ gap: "10px" }}>
                <Col>
                  <Switch
                    checked={checkedCurrentAddress}
                    onChange={(val) => {
                      setCheckedCurrentAddress(val);
                      form.setFieldsValue({
                        permanentlocation: initvalues.permanentlocation,
                        permanentaddress: initvalues.permanentaddress,
                        permanentcountry: initvalues.permanentcountry,
                        permanentstate: initvalues.permanentstate,
                        permanentcity: initvalues.permanentcity,
                        permanentpincode: initvalues.permanentpincode,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <p className={`${commonStyles.formInputLabel}`}>
                    Current address is same as permanant address
                  </p>
                </Col>
              </Row>
            </Form.Item>

            {!checkedCurrentAddress && (
              <>
                <Row className={commonStyles.sectionEndGap}>
                  <Col>
                    <h1 className={commonStyles.HeadingFormDesign}>
                      Permanent Address
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col flex={1}>
                    <Form.Item
                      name="permanentlocation"
                      label="Apartment, flat/house number"
                      rules={[
                        {
                          required: isExternal,
                          message: "Please enter apartment, flat/house number",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Enter apartment, flat/house number"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    <Form.Item
                      name="permanentaddress"
                      label="Address"
                      rules={[
                        {
                          required: isExternal,
                          message: "Please enter permanent address",
                        },
                      ]}
                    >
                      <Input placeholder="Enter permanent address" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    <Form.Item
                      label="Country"
                      name="permanentcountry"
                      rules={[
                        {
                          required: isExternal,
                          message: "Please select country",
                        },
                      ]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        showSearch
                        onChange={onChangeCountryPermanent}
                        placeholder="Select country"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={
                          props.countriesData &&
                          props.countriesData.map((item) => ({
                            value: item.name,
                            id: item.id,
                            label: item.name,
                          }))
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    <Form.Item
                      label="State"
                      name="permanentstate"
                      rules={[
                        {
                          required: isExternal,
                          message: "Please select state",
                        },
                      ]}
                    >
                      {props.onPermanentStates &&
                      props.onPermanentStates.length === 0 ? (
                        <Input placeholder="Enter state" />
                      ) : (
                        <Select
                          getPopupContainer={(trigger) => trigger.parentElement}
                          onChange={onChangeStatePermanent}
                          showSearch
                          placeholder="Select state"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={
                            props.onPermanentStates &&
                            props.onPermanentStates.map((item, index) => ({
                              value: item.name,

                              label: item.name,
                              id: item.id,
                            }))
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    <Form.Item
                      label="City"
                      name="permanentcity"
                      rules={[
                        {
                          required: isExternal,
                          message: "Please select city",
                        },
                      ]}
                    >
                      {props.onPermanentCity &&
                      props.onPermanentCity.length === 0 ? (
                        <Input placeholder="Enter city" />
                      ) : (
                        <Select
                          getPopupContainer={(trigger) => trigger.parentElement}
                          showSearch
                          placeholder="Select city"
                        >
                          {props.onPermanentCity &&
                            props.onPermanentCity.map((data, index) => {
                              return (
                                <Select.Option value={data.name} key={index}>
                                  {data.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    <Form.Item
                      label="Pincode"
                      name="permanentpincode"
                      rules={[
                        {
                          required: true,
                          message: "Please enter pincode",
                        },
                        // {
                        //   pattern: /^[0-9]+$/,
                        //   message: "Enter only number",
                        // },
                      ]}
                    >
                      <Input placeholder="Enter pincode" />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>

        <Col xs={24} md={24} className={commonStyles.footerShadow}>
          <Row justify="end" style={{ gap: "17px", width: "770px" }}>
            <Col>
              <Button
                onClick={props.personalDetailsClose}
                style={{
                  width: "150px",
                  height: "48px",
                }}
                className={commonStyles.footerButtonAddCandidateDrawerSecondary}
              >
                Cancel
              </Button>
            </Col>

            <Col>
              <Button
                onClick={() => {
                  form.submit();
                }}
                htmlType="button"
                className={commonStyles.footerButtonAddCandidateDrawerPrimary}
                type="primary"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Form>
    </Drawer>
  );
};

export default EmployeesPersonalDetails;
