import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Switch,
  Input,
  Button,
  Row,
  Col,
  Select,
  Form,
  Image,
  message,
  Modal,
} from "antd";
import style from "../../../Components/Screens/Settings/Setting.module.css";
import {
  CameraIcon,
  CloseIcon,
  EditIcon,
  MoonIcon,
  PaperPlane,
  PushNIcon,
  SunIcon,
} from "../../IconsComp/Icons";
import { Colorpicker } from "antd-colorpicker";
import { UploadIcon } from "../../../../src/Components/IconsComp/Icons.js";
import Sun from "../../../Assets/Image/Sun.png";
import Moon from "../../../Assets/Image/Moon.png";
import whatsapp from "../../../Assets/Image/whatsapp.png";
import email from "../../../Assets/Image/email.png";
import { useForm } from "antd/lib/form/Form";
import commonStyles from "../../UI/CommonCss.module.css";
import CopyToClipboard from "react-copy-to-clipboard";
import styles from "./Application/Application.module.css";
import ReactQuill from "react-quill";
import PhoneInput from "react-phone-number-input";

function TabSystem(props) {
  let btnref = useRef(null);
  const [value, setValue] = useState("");
  const [copied, setCopied] = useState(false);
  const [copyValues, setCopyValues] = useState(false);

  const [emailTest, setEmailTest] = useState(false);
  const [whatsappTest, setWhatsappTest] = useState(false);

  const [onchangeEmail, setOnchangeEmail] = useState(false);
  const [onchangeWhatsapp, setOnchangeWhatsapp] = useState(false);
  const [mouseKey,setMouseKey]=useState(false);


const clearCopied = () =>{
setTimeout(() => {
  setMouseKey(false)
}, 3000);
}



  useEffect(() => {
    if (settings.isEmailTested == "0") {
      setOnchangeEmail(true);
    } else {
      setOnchangeEmail(false);
    }
  }, []);

  useEffect(() => {
    if (settings.isWhatsAppTested == "0") {
      setOnchangeWhatsapp(true);
    } else {
      setOnchangeWhatsapp(false);
    }
  }, []);

  const closeEmailModal = () => {
    setEmailTest(false);
  };

  const closeWhatsapp = () => {
    setWhatsappTest(false);
  };

  const testEmail = (val) => {
    const data = {
      ...val,
      isEmailTested: "1",
      type: "mail",
    };
    props.testCredentialsHandler(data, (success) => {
      if (success) {
        form1.resetFields();
        setEmailTest(false);
        setOnchangeEmail(false);
      }
    });
  };

  const testWhatsapp = (value) => {
    const data1 = {
      ...value,
      isWhatsAppTested: "1",
      type: "whatsapp",
    };
    props.testCredentialsHandler(data1, (success) => {
      if (success) {
        form2.resetFields();
        setWhatsappTest(false);
        setOnchangeWhatsapp(false);
      }
    });
  };

  const onCommonChange = (val) => {
    if (val.target.value) {
      setOnchangeEmail(true);
    }
  };

  const onCommonChangeWhtsapp = (val) => {
    if (val.target.value) {
      setOnchangeWhatsapp(true);
    }
  };

  const modules = {
    toolbar: [
      ["bold"],
      ["italic"],
      ["underline"],
      [{ list: "bullet" }],
      [{ list: "ordered" }],
      [{ align: [] }],
      ["link"],
      // ["image"],
      // ["video"],
      ["code-block"],
    ],
  };

  const [color, setColor] = useState({
    primaryColor: "",
    secondaryColor: "",
    primaryButtonColor: "",
    secondaryButtonColor: "",
    backgroundColor: "",
    headingColor: "",
    subheadingColor: "",
    bodyTextColor: "",
    MenuTabColor: "",
    hoveringColor: "",
  });
  const [form] = useForm();

  const [form1] = useForm();
  const [form2] = useForm();

  let { settings } = props;
  useEffect(() => {
    setColor({
      primaryColor: settings.primaryColor ? settings.primaryColor : "#2F7BF5",
      secondaryColor: settings.secondaryColor
        ? settings.secondaryColor
        : "#E3EBFF",
      primaryButtonColor: settings.primaryButtonColor
        ? settings.primaryButtonColor
        : "#2F7BF5",
      secondaryButtonColor: settings.secondaryButtonColor
        ? settings.secondaryButtonColor
        : "#2F7BF5",
      backgroundColor: settings.backgroundColor
        ? settings.backgroundColor
        : "#F6F8FC",
      headingColor: settings.headingColor ? settings.headingColor : "#030A16",
      subheadingColor: settings.subheadingColor
        ? settings.subheadingColor
        : "#030A16",
      bodyTextColor: settings.bodyTextColor
        ? settings.bodyTextColor
        : "#403E3E",
      MenuTabColor: settings.MenuTabColor ? settings.MenuTabColor : "#2F7BF5",
      hoveringColor: settings.hoveringColor
        ? settings.hoveringColor
        : "#90BAFD",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (info) => {
    if (info.target.files[0].size <= 5000000) {
      props.postSettings({ backgroundImage: info.target.files[0] });
    } else {
      message.warning("This file should be less than 5 MB", 3);
    }
  };
  return (
    <>
      <Card style={{ padding: "20px", minHeight: "287px" }}>
        <Form layout="vertical">
          <Row>
            <Col className="modelcol" md={24}>
              <Form.Item
                name="backgroundimage"
                rules={[
                  {
                    message: "Background image is Required",
                  },
                ]}
                label={
                  <p
                    style={{ paddingBottom: "22px" }}
                    className={`${commonStyles.marginBottomRemove} ${commonStyles.headingSettingsText}`}
                  >
                    Background image
                  </p>
                }
                className={style.label1}
                style={{ borderRadius: "1px", textAlign: "center" }}
              >
                <div hidden>
                  <input
                    type="file"
                    name="fileUpload"
                    onChange={handleChange}
                    ref={(input) => {
                      btnref = input;
                    }}
                    onClick={(event) => {
                      event.currentTarget.value = null;
                    }}
                  />
                </div>
                {settings.backgroundImage ? (
                  <div
                    style={{
                      border: "2px dashed var(--primaryColor)",
                      borderRadius: "6px",
                      height: "180px",
                    }}
                  >
                    <Image
                      onClick={(e) => {
                        e.preventDefault();
                        btnref.click();
                        return false;
                      }}
                      alt={<CameraIcon />}
                      style={{ width: "100%", height: "150px" }}
                      preview={false}
                      src={settings.backgroundImage}
                    />
                    <span
                      onClick={(e) => {
                        props.postSettings({ backgroundImage: null });

                        return false;
                      }}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <CloseIcon color={"var(--icons-color)"} />
                    </span>
                    <span
                      style={{
                        position: "absolute",
                        top: "60px",
                        right: "10px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        btnref.click();
                        return false;
                      }}
                    >
                      <EditIcon color={"var(--icons-color)"} />
                    </span>
                  </div>
                ) : (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      btnref.click();
                      return false;
                    }}
                    style={{
                      textAlign: "center",
                      backgroundColor: "var(--input-color)",
                      padding: "20px",
                      height: "180px",

                      border: "2px dashed  #0074ff",
                      borderRadius: "5px",
                    }}
                    className={commonStyles.coverBoady}
                  >
                    <div style={{ marginTop: "27px" }}>
                      <UploadIcon color={"var(--icons-color)"} />
                    </div>

                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      <p
                        className={`${commonStyles.marginBottomRemove} ${commonStyles.lightTextForUpload}`}
                      >
                        Upload image or Drag image here ( maximum 5mb ){" "}
                        <b className={`${commonStyles.subHeadingTextNormal}`}>
                          724*180
                        </b>
                      </p>
                    </div>
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card
        style={{
          padding: "22px",
          marginTop: "30px",
        }}
        className={commonStyles.coverBoady}
      >
        <p
          style={{ paddingBottom: "18px" }}
          className={commonStyles.headingSettingsText}
        >
          Color scheme
        </p>
        <Form
          initialValues={{
            primaryColor: settings.primaryColor
              ? settings.primaryColor
              : "#2F7BF5",
            secondaryColor: settings.secondaryColor
              ? settings.secondaryColor
              : "#E3EBFF",
            primaryButtonColor: settings.primaryButtonColor
              ? settings.primaryButtonColor
              : "#2F7BF5",
            secondaryButtonColor: settings.secondaryButtonColor
              ? settings.secondaryButtonColor
              : "#2F7BF5",
            backgroundColor: settings.backgroundColor
              ? settings.backgroundColor
              : "#F6F8FC",
            headingColor: settings.headingColor
              ? settings.headingColor
              : "#030A16",
            subheadingColor: settings.subheadingColor
              ? settings.subheadingColor
              : "#030A16",
            bodyTextColor: settings.bodyTextColor
              ? settings.bodyTextColor
              : "#403E3E",
            MenuTabColor: settings.MenuTabColor
              ? settings.MenuTabColor
              : "#2F7BF5",
            hoveringColor: settings.hoveringColor
              ? settings.hoveringColor
              : "#90BAFD",
          }}
          onFinish={(val) => {
            props.postSettings({
              primaryColor: val.primaryColor.hex || val.primaryColor,
              secondaryColor: val.secondaryColor.hex || val.secondaryColor,
              primaryButtonColor:
                val.primaryButtonColor.hex || val.primaryButtonColor,
              secondaryButtonColor:
                val.secondaryButtonColor.hex || val.secondaryButtonColor,
              backgroundColor: val.backgroundColor.hex || val.backgroundColor,
              headingColor: val.headingColor.hex || val.headingColor,
              subheadingColor: val.subheadingColor.hex || val.subheadingColor,
              bodyTextColor: val.bodyTextColor.hex || val.bodyTextColor,
              MenuTabColor: val.MenuTabColor.hex || val.MenuTabColor,
              hoveringColor: val.hoveringColor.hex || val.hoveringColor,
            });
            // window.location.reload();
          }}
        >
          <Row>
            <Col md={8}>
              <p className={style.colorp}>Primary Color </p>
            </Col>
            <Col md={8}>
              {settings.primaryColor ? (
                <p className={style.changcolrp}>Change color</p>
              ) : (
                <p className={style.choosecolrp}>Choose color</p>
              )}
            </Col>

            <Col md={8} style={{ color: color && color.primaryColor }}>
              {color && color.primaryColor}
              <Form.Item name="primaryColor">
                <Colorpicker
                  popup
                  onChange={(clr) => {
                    setColor({ ...color, primaryColor: clr.hex });
                  }}
                  blockStyles={{
                    width: "158px",
                    border: "1px solid lightgray",
                    height: "42px",
                    borderRadius: "4px",
                    boxShadow: "0px",
                    display: "inline-flex",
                  }}
                ></Colorpicker>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <p className={style.colorp}>Secondary Color </p>
            </Col>
            <Col md={8}>
              {settings.secondaryColor ? (
                <p className={style.changcolrp}>Change color</p>
              ) : (
                <p className={style.choosecolrp}>Choose color</p>
              )}
            </Col>
            <Col md={8} style={{ color: color && color.secondaryColor }}>
              {color && color.secondaryColor}
              <Form.Item name="secondaryColor">
                <Colorpicker
                  popup
                  onChange={(clr) => {
                    setColor({ ...color, secondaryColor: clr.hex });
                  }}
                  blockStyles={{
                    width: "158px",
                    border: "1px solid lightgray",
                    height: "42px",
                    borderRadius: "4px",
                    boxShadow: "0px",
                    display: "inline-flex",
                  }}
                ></Colorpicker>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <p className={style.colorp}> Primary Button Color </p>
            </Col>
            <Col md={8}>
              {settings.primaryButtonColor ? (
                <p className={style.changcolrp}>Change color</p>
              ) : (
                <p className={style.choosecolrp}>Choose color</p>
              )}{" "}
            </Col>
            <Col md={8} style={{ color: color && color.primaryButtonColor }}>
              {color && color.primaryButtonColor}

              <Form.Item name="primaryButtonColor">
                <Colorpicker
                  popup
                  onChange={(clr) => {
                    setColor({ ...color, primaryButtonColor: clr.hex });
                  }}
                  blockStyles={{
                    width: "158px",
                    height: "42px",
                    border: "1px solid lightgray",
                    borderRadius: "4px",
                    boxShadow: "0px",
                    display: "inline-flex",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <p className={style.colorp}>Secondary Button Color </p>
            </Col>
            <Col md={8}>
              {settings.secondaryButtonColor ? (
                <p className={style.changcolrp}>Change color</p>
              ) : (
                <p className={style.choosecolrp}>Choose color</p>
              )}{" "}
            </Col>
            <Col md={8} style={{ color: color && color.secondaryButtonColor }}>
              {color && color.secondaryButtonColor}

              <Form.Item name={`secondaryButtonColor`}>
                <Colorpicker
                  popup
                  onChange={(clr) => {
                    setColor({ ...color, secondaryButtonColor: clr.hex });
                  }}
                  blockStyles={{
                    width: "158px",
                    height: "42px",
                    border: "1px solid lightgray",
                    borderRadius: "4px",
                    boxShadow: "0px",
                    display: "inline-flex",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <p className={style.colorp}>Background Color </p>
            </Col>
            <Col md={8}>
              {settings.backgroundColor ? (
                <p className={style.changcolrp}>Change color</p>
              ) : (
                <p className={style.choosecolrp}>Choose color</p>
              )}{" "}
            </Col>
            <Col md={8} style={{ color: color && color.backgroundColor }}>
              {color && color.backgroundColor}

              <Form.Item name={`backgroundColor`}>
                <Colorpicker
                  popup
                  onChange={(clr) => {
                    setColor({ ...color, backgroundColor: clr.hex });
                  }}
                  blockStyles={{
                    width: "158px",
                    height: "42px",
                    border: "1px solid lightgray",
                    borderRadius: "4px",
                    boxShadow: "0px",
                    display: "inline-flex",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <p className={style.colorp}> Headings Color </p>
            </Col>
            <Col md={8}>
              {settings.headingColor ? (
                <p className={style.changcolrp}>Change color</p>
              ) : (
                <p className={style.choosecolrp}>Choose color</p>
              )}{" "}
            </Col>
            <Col md={8} style={{ color: color && color.headingColor }}>
              {color && color.headingColor} Text
              <Form.Item name={`headingColor`}>
                <Colorpicker
                  popup
                  onChange={(clr) => {
                    setColor({ ...color, headingColor: clr.hex });
                  }}
                  blockStyles={{
                    width: "158px",
                    height: "42px",
                    border: "1px solid lightgray",
                    borderRadius: "4px",
                    boxShadow: "0px",
                    display: "inline-flex",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <p className={style.colorp}>SubHeadings Color </p>
            </Col>
            <Col md={8}>
              {settings.subheadingColor ? (
                <p className={style.changcolrp}>Change color</p>
              ) : (
                <p className={style.choosecolrp}>Choose color</p>
              )}{" "}
            </Col>
            <Col md={8} style={{ color: color && color.subheadingColor }}>
              {color && color.subheadingColor} Text
              <Form.Item name={`subheadingColor`}>
                <Colorpicker
                  popup
                  onChange={(clr) => {
                    setColor({ ...color, subheadingColor: clr.hex });
                  }}
                  blockStyles={{
                    width: "158px",
                    height: "42px",
                    border: "1px solid lightgray",
                    borderRadius: "4px",
                    boxShadow: "0px",
                    display: "inline-flex",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <p className={style.colorp}>Body text Color </p>
            </Col>
            <Col md={8}>
              {settings.bodyTextColor ? (
                <p className={style.changcolrp}>Change color</p>
              ) : (
                <p className={style.choosecolrp}>Choose color</p>
              )}{" "}
            </Col>
            <Col md={8} style={{ color: color && color.bodyTextColor }}>
              {color && color.bodyTextColor} Text
              <Form.Item name={`bodyTextColor`}>
                <Colorpicker
                  popup
                  onChange={(clr) => {
                    setColor({ ...color, bodyTextColor: clr.hex });
                  }}
                  blockStyles={{
                    width: "158px",
                    height: "42px",
                    border: "1px solid lightgray",
                    borderRadius: "4px",
                    boxShadow: "0px",
                    display: "inline-flex",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <p className={style.colorp}>Menu tab Color </p>
            </Col>
            <Col md={8}>
              {settings.MenuTabColor ? (
                <p className={style.changcolrp}>Change color</p>
              ) : (
                <p className={style.choosecolrp}>Choose color</p>
              )}{" "}
            </Col>
            <Col md={8} style={{ color: color && color.MenuTabColor }}>
              {color && color.MenuTabColor} Text
              <Form.Item name={`MenuTabColor`}>
                <Colorpicker
                  popup
                  onChange={(clr) => {
                    setColor({ ...color, MenuTabColor: clr.hex });
                  }}
                  blockStyles={{
                    width: "158px",
                    height: "42px",
                    border: "1px solid lightgray",
                    borderRadius: "4px",
                    boxShadow: "0px",
                    display: "inline-flex",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <p className={style.colorp}>Hovering color </p>
            </Col>
            <Col md={8}>
              {settings.hoveringColor ? (
                <p className={style.changcolrp}>Change color</p>
              ) : (
                <p className={style.choosecolrp}>Choose color</p>
              )}{" "}
            </Col>
            <Col md={8} style={{ color: color && color.hoveringColor }}>
              {color && color.hoveringColor} Text
              <Form.Item name={`hoveringColor`}>
                <Colorpicker
                  popup
                  onChange={(clr) => {
                    setColor({ ...color, hoveringColor: clr.hex });
                  }}
                  blockStyles={{
                    width: "158px",
                    height: "42px",
                    border: "1px solid lightgray",
                    borderRadius: "4px",
                    boxShadow: "0px",
                    display: "inline-flex",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" gutter={[0, 16]}>
            <Col>
              <Button
                type="default"
                onClick={() => {
                  props.postSettings({
                    primaryColor: "#2F7BF5",
                    secondaryColor: "#E3EBFF",
                    primaryButtonColor: "#2F7BF5",
                    secondaryButtonColor: "#2F7BF5",
                    backgroundColor: "#F6F8FC",
                    headingColor: "#030A16",
                    subheadingColor: "#030A16",
                    bodyTextColor: "#403E3E",
                    MenuTabColor: "#2F7BF5",
                    hoveringColor: "#90BAFD",
                  });
                  window.location.reload();
                  form.resetFields();
                }}
                className={commonStyles.buttonSettingsSecondary}
              >
                Reset color
              </Button>
            </Col>
            <Col>
              <Button
                htmlType="submit"
                type="primary"
                className={commonStyles.buttonSettingsPrimary}
                style={{ marginLeft: "17px" }}
              >
                Save
              </Button>
            </Col>
          </Row>
          {/* <div style={{ textAlign: "center" }}>
          </div> */}
        </Form>
      </Card>
      <div
        style={{
          marginTop: "30px",
          backgroundColor: "var(--background-color)",
        }}
      >
        <Row gutter={[16, 16]} justify="space-between">
          <Col /* style={{ paddingRight: "10px" }} */ md={24} lg={11}>
            <Card style={{ padding: "20px" }}>
              <Form
                layout="vertical"
                initialValues={{ dateFormat: settings.dateFormat }}
              >
                <Form.Item
                  className={style.label}
                  name="dateFormat"
                  rules={[
                    {
                      message: "Date format is Required",
                    },
                  ]}
                  label={
                    <p
                      style={{ marginBottom: "20px" }}
                      className={`${commonStyles.marginBottomRemove} ${style.formateInSettingsStyle}`}
                    >
                      Date format
                    </p>
                  }
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    className="settingDropDownInp"
                    placeholder="Select Date format"
                    onChange={(val) => {
                      props.postSettings({ dateFormat: val });
                    }}
                  >
                    <Select.Option value="MM-DD-YYYY">
                      Month first (MM-DD-YY)
                    </Select.Option>
                    <Select.Option value="DD-MM-YYYY">
                      Date first (DD-MM-YY)
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col
            md={24}
            lg={11} /* style={{ paddingLeft: "10px" }} */
            /* style={{ marginTop: "20px" }} */
          >
            <Card style={{ padding: "20px" }}>
              <Form
                layout="vertical"
                initialValues={{ timeFormat: settings.timeFormat }}
              >
                <Form.Item
                  className={style.label}
                  name="timeFormat"
                  rules={[
                    {
                      //  required: true,
                      message: "Time format is Required",
                    },
                  ]}
                  label={
                    <p
                      style={{ marginBottom: "20px" }}
                      className={`${commonStyles.marginBottomRemove} ${style.formateInSettingsStyle}`}
                    >
                      Time format
                    </p>
                  }
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    className="settingDropDownInp"
                    style={{ color: "var(--body-text-color)" }}
                    placeholder="Select time format"
                    onChange={(val) => {
                      props.postSettings({ timeFormat: val });
                    }}
                  >
                    <Select.Option value="24hours">
                      24 Hours (HH:MM:SS)
                    </Select.Option>
                    <Select.Option value="12hours">
                      12 Hours (HH:MM:SS AM/PM)
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: "30px" }}>
        <Row gutter={[16, 16]} justify="space-between">
          <Col md={24} lg={11} /* style={{ paddingRight: "10px" }} */>
            <Card style={{ padding: "20px" }}>
              <Form
                layout="vertical"
                initialValues={{ currency: settings.currency }}
              >
                <Form.Item
                  className={style.label}
                  name="currency"
                  rules={[
                    {
                      //  required: true,
                      message: "Currency Required",
                    },
                  ]}
                  label={
                    <p
                      style={{ marginBottom: "20px" }}
                      className={`${commonStyles.marginBottomRemove} ${style.formateInSettingsStyle}`}
                    >
                      Currency
                    </p>
                  }
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    className="settingDropDownInp"
                    placeholder="Currency format"
                    onChange={(val) => {
                      props.postSettings({ currency: val });
                    }}
                  >
                    <Select.Option value="₹">₹ Indian Rupee</Select.Option>
                    <Select.Option value="$">$ Dollar</Select.Option>
                    <Select.Option value="₩">₩ Korea Won </Select.Option>
                    <Select.Option value="£"> £ Pound</Select.Option>
                    <Select.Option value="¥">¥ Yen</Select.Option>
                    <Select.Option value="₦">₦ Nigeria Naira</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col md={24} lg={11} /* style={{ marginTop: "20px" }} */>
            <Card style={{ padding: "20px", height: "100%" }}>
              <p
                style={{ marginBottom: "29px" }}
                className={`${commonStyles.marginBottomRemove} ${style.formateInSettingsStyle}`}
              >
                Change Theme
              </p>

              <Row style={{ marginTop: "50px" }} align="middle">
                <Col md={24}>
                  <Row justify="space-between">
                    <Col>
                      <Row style={{ gap: "10px" }}>
                        {/* <Col>
                          {settings && settings.theme === "light" ? (
                            <SunIcon color={"var(--icons-color)"} />
                          ) : (
                            <MoonIcon color={"var(--icons-color)"} />
                          )}
                        </Col> */}
                        <Col>
                          <p
                            style={{
                              color: "var(--body-text-color)",
                              fontSize: "16px",
                            }}
                            className={`${commonStyles.marginBottomRemove}`}
                          >
                            {settings && settings.theme === "light"
                              ? "Light"
                              : "Dark"}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="end" align="middle" style={{ gap: "10px" }}>
                        <Col>
                          <span className={style.darkAndLightThemStatus}>
                            <SunIcon
                              color={
                                settings.theme === "light"
                                  ? "var(--primaryColor)"
                                  : "var(--icons-color)"
                              }
                            />
                          </span>
                        </Col>

                        <Col>
                          {!settings.theme || settings.theme === "light" ? (
                            <Switch
                              onChange={(val) => {
                                const data = {
                                  theme: "dark",
                                };
                                props.postSettings({ ...data });
                              }}
                            />
                          ) : (
                            <Switch
                              checked={true}
                              onChange={(val) => {
                                const data = {
                                  theme: "light",
                                };
                                props.postSettings({ ...data });
                              }}
                            />
                          )}
                        </Col>
                        <Col>
                          <span>
                            <MoonIcon
                              color={
                                settings.theme === "dark"
                                  ? "var(--primaryColor)"
                                  : "var(--icons-color)"
                              }
                            />
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>

      {/* <div > */}
      <Row style={{ marginTop: "30px" }}>
        <Col md={24}>
          <Card
            style={{
              padding: "20px",
            }} /* className={commonStyles.coverBoady} */
          >
            <Form
              layout="vertical"
              initialValues={{
                externalPathName:
                  window.location.host + "/#/jobs/" + settings.tenantId,
              }}
              // initialValues={{ externalPathName: window.location.host+"/#/externaljobs/"+"853546c3-6ef4-4a70-bf05-8f9a547cc4c4"}}
            >
              <Row align="middle" justify="space-between">
                <Col lg={19}>
                  <Form.Item
                    name="externalPathName"
                    label="External Job Link"
                    value={
                      window.location.host + "/#/jobs/" + settings.tenantId
                    }
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col lg={4} /* style={{ marginTop: "3%" }} */>
                  <CopyToClipboard
                    text={window.location.host + "/#/jobs/" + settings.tenantId}
                    onCopy={() => setCopied(true)}

                  >
                    <Button
                      type="primary"
                      className={`${commonStyles.copyLinkButton}`}
                      onClick={()=>{
                        setMouseKey(true);
                        clearCopied()
                      }}
                    >
                   {mouseKey?"Copied":"Copy Link"}
                    </Button>
                  </CopyToClipboard>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
      {/* </div> */}

      <Card style={{ padding: "20px", marginTop: "20px" }}>
        <Form
          onFinish={(val) => {
            const dataEmail = {
              isEmailEnabled:
                onchangeEmail && val.isEmailTested == "false"
                  ? "0"
                  : val.isEmailEnabled === true
                  ? "1"
                  : "0",
              isEmailTested: onchangeEmail ? "0" : settings.isEmailTested,
            };
            const dataWhatsapp = {
              isWhatsAppEnabled:
                onchangeWhatsapp && val.isWhatsAppTested == "false"
                  ? "0"
                  : val.isWhatsAppEnabled === true
                  ? "1"
                  : "0",
              isWhatsAppTested: onchangeWhatsapp
                ? "0"
                : settings.isWhatsAppTested,
            };
            props.postSettings({
              ...val,
              ...dataEmail,
              ...dataWhatsapp,
              isNotificationEnabled: val.isNotificationEnabled ? "1" : "0",
              // isWhatsAppEnabled: val.isWhatsAppEnabled ? "1" : "0",
            });
          }}
          layout="vertical"
          initialValues={
            settings
              ? {
                  isEmailEnabled:
                    settings.isEmailEnabled === "1" ? true : false,
                  emailUsername: settings.emailUsername,
                  emailKey: settings.emailKey,
                  emailSecret: settings.emailSecret,
                  isWhatsAppEnabled:
                    settings.isWhatsAppEnabled === "1" ? true : false,
                    isNotificationEnabled:
                    settings.isNotificationEnabled === "1" ? true : false,
                  whatsAppPhoneNumber: settings.whatsAppPhoneNumber,
                  whatsAppKey: settings.whatsAppKey,
                  whatsAppURL: settings.whatsAppURL,
                  emailMailer: settings.emailMailer,
                  emailHost: settings.emailHost,
                  emailPassword: settings.emailPassword,
                  emailPort: settings.emailPort,
                  emailEncryption: settings.emailEncryption,
                }
              : {}
          }
        >
          <div className={style.taskSummaryContentContainer1}>
            <p
              style={{ marginBottom: "20px" }}
              className={style.formateInSettingsStyle}
            >
              Integrations
            </p>
          </div>
          <div className={style.taskSummaryContentContainer1}>
            <p className={style.emailAndWhatsApp}>
              <img alt="example" className={style.icons} src={email} />
              Email{" "}
              <span className={style.emailAndWhatsappSubText}>
                ( Lorem ipsum dolor sit amet, consectetur adipiscing elit )
              </span>
            </p>

            {settings.isEmailTested == "1" && !onchangeEmail ? (
              <p style={{ marginRight: "5px" }}>
                <Form.Item name="isEmailEnabled">
                  <Switch
                    defaultChecked={
                      settings.isEmailEnabled === "1" ? true : false
                    }
                    style={{ float: "right", transform: "translateY(20px)" }}
                  />
                </Form.Item>
              </p>
            ) : (
              ""
            )}
            {settings.isEmailTested == "1" && !onchangeEmail ? (
              <span className={style.disableAndEnableBtnStatus}>
                {settings && settings.isEmailEnabled === "1" && true
                  ? "Enable"
                  : "Disable"}
              </span>
            ) : (
              ""
            )}
          </div>
          <Row>
            <Col md={24}>
              <Form.Item
                className={style.label}
                name="emailUsername"
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                  {
                    type: "email",
                    message: "The input is not valid E-mail!.",
                  },
                ]}
                label="Email"
                onChange={onCommonChange}
              >
                <Input className="inputCss" placeholder="Enter email " />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item
                className={style.label}
                name="emailKey"
                rules={[
                  {
                    required: false,
                    message: "Email key is required",
                  },
                ]}
                label="Email Key"
              >
                <Input className="inputCss" placeholder="Enter email key " />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item
                className={style.label}
                name="emailSecret"
                rules={[
                  {
                    required: false,
                    message: "Email secret is required",
                  },
                ]}
                label="Email Secret"
                onChange={onCommonChange}
              >
                <Input className="inputCss" placeholder="Enter email secret " />
              </Form.Item>
            </Col>
          </Row>
          {/* settings new fields   */}
          <Row>
            <Col md={24}>
              <Form.Item
                className={style.label}
                name="emailMailer"
                rules={[
                  {
                    required: false,
                    message: "Mailer is required",
                  },
                ]}
                label="Mailer"
                onChange={onCommonChange}
              >
                <Input className="inputCss" placeholder="Enter mailer" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item
                className={style.label}
                name="emailHost"
                rules={[
                  {
                    required: false,
                    message: "Host is required",
                  },
                ]}
                label="Host"
                onChange={onCommonChange}
              >
                <Input className="inputCss" placeholder="Enter host" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item
                className={style.label}
                name="emailPort"
                rules={[
                  {
                    required: false,
                    message: "Port is required",
                  },
                ]}
                label="Port"
                onChange={onCommonChange}
              >
                <Input className="inputCss" placeholder="Enter port" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item
                className={style.label}
                name="emailEncryption"
                rules={[
                  {
                    required: false,
                    message: "Email encryption is required",
                  },
                ]}
                label="Email Encryption"
                onChange={onCommonChange}
              >
                <Input
                  className="inputCss"
                  placeholder="Enter email encryption"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item
                className={style.label}
                name="emailPassword"
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                ]}
                label="Password"
                onChange={onCommonChange}
              >
                <Input
                  type="password"
                  className="inputCss"
                  placeholder="Enter password"
                />
              </Form.Item>
            </Col>
          </Row>
          {settings.isEmailTested == "0" || onchangeEmail ? (
            <Button
              className={commonStyles.buttonSettingsPrimary}
              style={{ marginBottom: "30px", width: "170px" }}
              type="primary"
              onClick={() => {
                setEmailTest(true);
              }}
            >
              Send Test Email
            </Button>
          ) : (
            ""
          )}
          {/* end of settings new fields  */}
          <div className={style.taskSummaryContentContainer1}>
            <p className={style.emailAndWhatsApp}>
              <img alt="example" className={style.icons} src={whatsapp} />
              Whatsapp{" "}
              <span className={style.emailAndWhatsappSubText}>
                ( Lorem ipsum dolor sit amet, consectetur adipiscing elit)
              </span>
            </p>
            {settings.isWhatsAppTested == "1" && !onchangeWhatsapp ? (
              <p style={{ marginRight: "5px" }}>
                <Form.Item name="isWhatsAppEnabled">
                  <Switch
                    defaultChecked={
                      settings.isWhatsAppEnabled === "1" ? true : false
                    }
                    style={{ float: "right", transform: "translateY(20px)" }}
                  />
                </Form.Item>
              </p>
            ) : (
              ""
            )}
            {settings.isWhatsAppTested == "1" && !onchangeWhatsapp ? (
              <span className={style.disableAndEnableBtnStatus}>
                {settings && settings.isWhatsAppEnabled === "1" && true
                  ? "Enable"
                  : "Disable"}
              </span>
            ) : (
              ""
            )}
          </div>

          <Row>
            <Col md={24}>
              <Form.Item
                className={style.label}
                name="whatsAppKey"
                rules={[
                  {
                    required: true,
                    message: "Key is required",
                  },
                ]}
                label="Key"
                onChange={onCommonChangeWhtsapp}
              >
                <Input className="inputCss" placeholder="Enter key" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item
                className={style.label}
                name="whatsAppURL"
                rules={[
                  {
                    required: true,
                    message: "Whatsapp url is required",
                  },
                ]}
                label="Whatsapp URL"
                onChange={onCommonChangeWhtsapp}
              >
                <Input className="inputCss" placeholder="Enter whatsapp url " />
              </Form.Item>
            </Col>
          </Row>

          {settings.isWhatsAppTested == "0" || onchangeWhatsapp ? (
            <Button
              className={commonStyles.buttonSettingsPrimary}
              style={{ marginBottom: "30px", width: "200px" }}
              type="primary"
              onClick={() => {
                setWhatsappTest(true);
              }}
            >
              Send Test Message
            </Button>
          ) : (
            ""
          )}



{/* push notification   */}

<div className={style.taskSummaryContentContainer1}>



            <p className={style.emailAndWhatsApp}>

              

              

              <Row>
                <Col><PushNIcon color={"var(--primaryColor)"}/></Col>
                <Col style={{marginTop:"4px",marginLeft:"8px"}}>Push Notification</Col>
              </Row>
            </p>
              <p style={{ marginRight: "5px" }}>
                <Form.Item name="isNotificationEnabled">
                  <Switch
                    defaultChecked={
                      settings.isNotificationEnabled === "1" ? true : false
                    }
                    style={{ float: "right", transform: "translateY(20px)" }}
                  />
                </Form.Item>
              </p>
          
          
              <span className={style.disableAndEnableBtnStatus}>
                {settings && settings.isNotificationEnabled === "1" && true
                  ? "Enable"
                  : "Disable"}
              </span>
        
          </div>


{/* end of push notification   */}





          <Row justify="center">
            <Col>
              <Button
                htmlType="submit"
                className={commonStyles.buttonSettingsPrimary}
                type="primary"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>

      <Modal
        closable={false}
        width={840}
        open={emailTest}
        onCancel={closeEmailModal}
        className={`${styles.modalStyle} emailModal modalModifiedEmail`}
        footer={false}
        title={
          <Row>
            <Col md={20}>
              {" "}
              <p
                className={`${commonStyles.marginBottomRemove} ${commonStyles.popUpHeadingText}`}
              >
                Email Test
              </p>
            </Col>
            <Col md={4}>
              <div
                style={{ float: "right" }}
                onClick={closeEmailModal}
                className={`${commonStyles.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
      >
        <Form
          form={form1}
          layout="vertical"
          hideRequiredMark
          onFinish={(val) => testEmail(val)}
          // initialValues={{
          //   title: props.propsETIdHandler && props.propsETIdHandler.title,
          //   notificationType:
          //     props.propsETIdHandler && props.propsETIdHandler.notificationType,
          //   subject: props.propsETIdHandler && props.propsETIdHandler.subject,
          //   cc: props.propsETIdHandler && props.propsETIdHandler.cc,
          //   content: props.propsETIdHandler && props.propsETIdHandler.content,
          // }}
        >
          <Row className={styles.drawerRowStyling}>
            <Col flex={1}>
              <Row>
                <Col className="modelcol" md={24}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter email",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email",
                      },
                    ]}
                    label="Send To"
                  >
                    <Input className="emailTempInptCss" placeholder="" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="modelcol" md={24}>
                  <Form.Item
                    name="subject"
                    rules={[
                      {
                        required: true,
                        message: "Please select subject",
                      },
                    ]}
                    label="Subject"
                  >
                    <Input className="emailTempInptCss" placeholder="" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please select content",
                      },
                    ]}
                    name="content"
                  >
                    <ReactQuill
                      className="emialTemplete"
                      theme="snow"
                      modules={modules}
                      value={value}
                      onChange={setValue}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col xs={24} md={24} className={commonStyles.footerShadowForTemplete}>
            <Row
              align="middle"
              justify="end"
              style={{
                position: "relative",
                right: "10px",
                gap: "10px",
              }}
            >
              <Col>
                <Button
                  onClick={() => {
                    // props.onOk();
                    form1.submit();
                  }}
                  className={commonStyles.buttonSettingsPrimary}
                  htmlType="button"
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                  type="primary"
                >
                  <Row align="middle" justify="space-around">
                    <Col style={{ transform: "translateY(3px)" }}>
                      <PaperPlane color={"var(--theme)"} />
                    </Col>
                    <Col className={styles.paperBtnNameTemp}>Send</Col>
                  </Row>
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Modal>

      <Modal
        closable={false}
        width={840}
        open={whatsappTest}
        onCancel={closeWhatsapp}
        className={`${styles.modalStyle} emailModal modalModifiedEmail`}
        footer={false}
        title={
          <Row>
            <Col md={20}>
              {" "}
              <p
                className={`${commonStyles.marginBottomRemove} ${commonStyles.popUpHeadingText}`}
              >
                Whatsapp Test
              </p>
            </Col>
            <Col md={4}>
              <div
                style={{ float: "right" }}
                onClick={closeWhatsapp}
                className={`${commonStyles.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
      >
        <Form
          form={form2}
          layout="vertical"
          hideRequiredMark
          onFinish={(val) => testWhatsapp(val)}
          // initialValues={{
          //   title: props.propsETIdHandler && props.propsETIdHandler.title,
          //   notificationType:
          //     props.propsETIdHandler && props.propsETIdHandler.notificationType,
          //   subject: props.propsETIdHandler && props.propsETIdHandler.subject,
          //   cc: props.propsETIdHandler && props.propsETIdHandler.cc,
          //   content: props.propsETIdHandler && props.propsETIdHandler.content,
          // }}
        >
          <Row className={styles.drawerRowStyling}>
            <Col flex={1}>
              <Row>
                <Col className="modelcol" md={24}>
                  <Form.Item
                    className={`phoneInputStroke`}
                    name="phone"
                    label="Contact Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter phone number",
                      },
                    ]}
                  >
                    <PhoneInput
                      placeholder="Enter phone number"
                      className={`ant-input ant-cust-inputs`}
                      defaultCountry="IN"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="modelcol" md={24}>
                  <Form.Item
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: "Please enter message",
                      },
                    ]}
                    label="Message"
                  >
                    <Input className="emailTempInptCss" placeholder="message" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col xs={24} md={24} className={commonStyles.footerShadowForTemplete}>
            <Row
              align="middle"
              justify="end"
              style={{
                position: "relative",
                right: "10px",
                gap: "10px",
              }}
            >
              <Col>
                <Button
                  onClick={() => {
                    form2.submit();
                  }}
                  className={commonStyles.buttonSettingsPrimary}
                  htmlType="button"
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                  type="primary"
                >
                  <Row align="middle" justify="space-around">
                    <Col style={{ transform: "translateY(3px)" }}>
                      <PaperPlane color={"var(--theme)"} />
                    </Col>
                    <Col className={styles.paperBtnNameTemp}>Send</Col>
                  </Row>
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Modal>
    </>
  );
}

export default TabSystem;
