import React from "react";
import { Table, Card } from "antd";

const columns = [
  {
    title: "Company ID",
    dataIndex: "companyID",
  },
  {
    title: "Company Name",
    dataIndex: "companyName",
  },

  {
    title: "Department",
    dataIndex: "department",
  },
  {
    title: "Role",
    dataIndex: "role",
  },
  {
    title: "Job Type",
    dataIndex: "jobType",
    align: "center",
  },
];
const data = [
  {
    key: "1",

    companyID: "DV123423",
    companyName: " Google",

    department: "Design",
    role: "Design",
    jobType: "Full Time",
  },
  {
    key: "2",

    companyID: "DV123423",
    companyName: " Google",
    department: "Design",
    role: "Design",
    jobType: "Full Time",
  },
  {
    key: "3",
    companyID: "DV123423",
    companyName: " Google",
    department: "Design",
    role: "Design",
    jobType: "Full Time",
  },
  {
    key: "4",

    companyID: "DV123423",
    companyName: " Google",
    department: "Design",
    role: "Design",
    jobType: "Full Time",
  },
  {
    key: "5",

    companyID: "DV123423",
    companyName: " Google",
    department: "Design",
    role: "Design",
    jobType: "Full Time",
  },
];

function DatatImportFile() {
  return (
    <Card style={{ padding: "20px", marginTop: "30px" }}>
      <Table
        scroll={{ x: "auto" }}
        className="dataImportTableStyle"
        columns={columns}
        dataSource={data}
        title={() => "Import  Company List"}
      />
    </Card>
  );
}

export default DatatImportFile;
