import { Avatar, Col, Row } from "antd";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import commonCssStyles from "../../../Components/UI/CommonCss.module.css";
import {
  BackButtonIcon,
  BellICon,
  BrieftCaseIcon,
  BuildingsIcon,
  CalendarIcon,
  CloseIcon,
  SettingIcon,
  UserFocusIcon,
  UserFocusIcon1,
  UserIcon,
} from "../../IconsComp/Icons";
import styles from "../../../Components/Screens/Dashboard/ContentPage/ContentPage.module.css";
import moment from "moment";
import { connect } from "react-redux";
import Dashboard from "./ContentPage/Dashboard";

function AppNotification(props) {
  const location = useLocation();

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  return (
    <>
      <div className={`${commonCssStyles.notificationFullDetails}`}>
        <Row>
          <Col md={24}>
            <Row
              style={{
                padding: "20px 5px 5px 18px",
                margin: "0px",
                boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Col
                md={3}
                className={`${commonCssStyles.coursorPointer} ${commonCssStyles.marginBottomRemove}`}
                style={{ marginTop: "-6px" }}
              >
                <Link to="/dashboard">
                  <BackButtonIcon color={"var(--body-text-color)"} />
                </Link>
              </Col>
              <Col md={21}>
                <h3
                  className={`${commonCssStyles.modalHeading} ${commonCssStyles.marginBottomRemove}`}
                >
                  Notifications
                </h3>
              </Col>
            </Row>
          </Col>
          <Col md={24} style={{ overflowY: "scroll" }}>
            <Row style={{ padding: "0px 10px 0px 10px" }}>
              {location.state &&
              location.state.new &&
              location.state.new.length > 0 ? (
                <>
                  <Col md={24}>
                    <p className={`${commonCssStyles.timelineTextBold}`}>New</p>
                  </Col>
                </>
              ) : (
                ""
              )}
              <Col md={24}>
                {location.state &&
                  location.state.new &&
                  location.state.new.map((data, index) => {
                    return (
                      <>
                        <Row
                          key={index}
                          style={{ margin: "4px" }}
                          className={
                            data.isRead === 1
                              ? commonCssStyles.notificationBg1
                              : commonCssStyles.notificationBg
                          }
                        >
                          <Col md={4}>
                            <Avatar
                              className={`${styles.avtarForLogAndNotification} ${commonCssStyles.coursorPointer}`}
                              id="headers-notification-icon"
                              icon={
                                <div
                                  style={{
                                    display: "block",
                                    width: "50%",
                                    position: "relative",
                                    transform: "translate(8px,8px)",
                                  }}
                                >
                                  {data.iconType === "task" ? (
                                    <CalendarIcon
                                      color={"var(--primaryColor)"}
                                    />
                                  ) : data.iconType === "task" ? (
                                    <Dashboard color={"var(--primaryColor)"} />
                                  ) : data.iconType === "recruitment" ? (
                                    <UserFocusIcon1
                                      color={"var(--primaryColor)"}
                                    />
                                  ) : data.iconType === "Candidate" ? (
                                    <UserIcon color={"var(--primaryColor)"} />
                                  ) : data.iconType === "requirement" ? (
                                    <BrieftCaseIcon
                                      color={"var(--primaryColor)"}
                                    />
                                  ) : data.iconType === "company" ? (
                                    <BuildingsIcon
                                      color={"var(--primaryColor)"}
                                    />
                                  ) : data.iconType === "Employee" ? (
                                    <UserIcon color={"var(--primaryColor)"} />
                                  ) : data.iconType === "settings" ? (
                                    <SettingIcon
                                      color={"var(--primaryColor)"}
                                    />
                                  ) : (
                                    <BellICon color={"var(--primaryColor)"} />
                                  )}
                                </div>
                              }
                            />
                          </Col>
                          <Col md={20}>
                            <p
                            
                              style={{ lineHeight: "21px",color:"var(--body-text-color)" }}
                              className={`${commonCssStyles.marginBottomRemove} `}
                              dangerouslySetInnerHTML={{
                                __html: data.notification,
                              }}
                            ></p>
                            <p
                              style={{ lineHeight: "19px" }}
                              className={` ${commonCssStyles.notificationSubText}`}
                            >
                              {moment().diff(
                                moment(data.createdAt),
                                "minutes"
                              ) <= 60
                                ? moment().diff(data.createdAt, "minutes") +
                                  " minutes ago"
                                : moment().diff(
                                    moment(data.createdAt),
                                    "hour"
                                  ) <= 12
                                ? moment().diff(data.createdAt, "hour") +
                                  " hour ago"
                                : moment().diff(
                                    moment(data.createdAt),
                                    "days"
                                  ) <= 10
                                ? moment().diff(data.createdAt, "days") +
                                  " days ago"
                                : moment(data.createdAt).format(dataFormate)}
                            </p>
                          </Col>
                        </Row>
                      </>
                    );
                  })}
              </Col>
            </Row>

            <Row style={{ padding: " 0px 10px 0px 10px" }}>
              {location.state &&
              location.state.earlier &&
              location.state.earlier.length > 0 ? (
                <>
                  <Col md={24}>
                    <p
                      className={`${commonCssStyles.timelineTextBold}`}
                      style={{ marginTop: "10px" }}
                    >
                      Earlier
                    </p>
                  </Col>
                </>
              ) : (
                ""
              )}
              <Col md={24}>
                {location.state &&
                  location.state.earlier &&
                  location.state.earlier.map((data, index) => {
                    return (
                      <>
                        <Row
                          key={index}
                          style={{ margin: "4px" }}
                          className={
                            data.isRead === 1
                              ? commonCssStyles.notificationBg1
                              : commonCssStyles.notificationBg
                          }
                        >
                          <Col md={4}>
                            <Avatar
                              className={`${styles.avtarForLogAndNotification} ${commonCssStyles.coursorPointer}`}
                              id="headers-notification-icon"
                              icon={
                                <div
                                  style={{
                                    display: "block",
                                    width: "50%",
                                    position: "relative",
                                    transform: "translate(8px,8px)",
                                  }}
                                >
                                  {data.iconType === "task" ? (
                                    <CalendarIcon
                                      color={"var(--primaryColor)"}
                                    />
                                  ) : data.iconType === "task" ? (
                                    <Dashboard color={"var(--primaryColor)"} />
                                  ) : data.iconType === "recruitment" ? (
                                    <UserFocusIcon1
                                      color={"var(--primaryColor)"}
                                    />
                                  ) : data.iconType === "Candidate" ? (
                                    <UserIcon color={"var(--primaryColor)"} />
                                  ) : data.iconType === "requirement" ? (
                                    <BrieftCaseIcon
                                      color={"var(--primaryColor)"}
                                    />
                                  ) : data.iconType === "company" ? (
                                    <BuildingsIcon
                                      color={"var(--primaryColor)"}
                                    />
                                  ) : data.iconType === "Employee" ? (
                                    <UserIcon color={"var(--primaryColor)"} />
                                  ) : data.iconType === "settings" ? (
                                    <SettingIcon
                                      color={"var(--primaryColor)"}
                                    />
                                  ) : (
                                    <BellICon color={"var(--primaryColor)"} />
                                  )}
                                </div>
                              }
                            />
                          </Col>
                          <Col md={20}>
                            <p

                              style={{ lineHeight: "21px",color:"var(--body-text-color)" }}
                              className={`${commonCssStyles.marginBottomRemove} `}
                              dangerouslySetInnerHTML={{
                                __html: data.notification,
                              }}
                            ></p>
                            <p
                              style={{ lineHeight: "19px" }}
                              className={`${commonCssStyles.notificationSubText}`}
                            >
                              {moment().diff(
                                moment(data.createdAt),
                                "minutes"
                              ) <= 60
                                ? moment().diff(data.createdAt, "minutes") +
                                  " minutes ago"
                                : moment().diff(
                                    moment(data.createdAt),
                                    "hour"
                                  ) <= 12
                                ? moment().diff(data.createdAt, "hour") +
                                  " hour ago"
                                : moment().diff(
                                    moment(data.createdAt),
                                    "days"
                                  ) <= 10
                                ? moment().diff(data.createdAt, "days") +
                                  " days ago"
                                : moment(data.createdAt).format(dataFormate)}
                            </p>
                          </Col>
                        </Row>
                      </>
                    );
                  })}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    settindsData: state.settings.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(AppNotification);
