import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  sharingMediums: [],
  sharingMedium: null,
};
export const masterSharingMediumSlice = createSlice({
  name: "sharingMedium",
  initialState,
  reducers: {
    getSharingMediumsSlice(state, action) {
      state.sharingMediums = action.payload.result;
    },
    getSharingMediumSlice(state, action) {
      state.sharingMedium = action.payload.result;
    },
    postSharingMediumsSlice(state, action) {
      state.sharingMediums = [...state.sharingMediums, action.payload.result];
    },
    putSharingMediumsSlice(state, action) {
      state.sharingMediums = state.sharingMediums.map((data) => {
        if (data.id === action.payload.result.id) {
          return action.payload.result;
        } else {
          return data;
        }
      });
    },
    deleteSharingMediumsSlice(state, action) {
      state.sharingMediums = state.sharingMediums.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});
export const {
  getSharingMediumsSlice,
  getSharingMediumSlice,
  postSharingMediumsSlice,
  putSharingMediumsSlice,
  deleteSharingMediumsSlice,
} = masterSharingMediumSlice.actions;
export default masterSharingMediumSlice.reducer;
