import { Drawer, Form, Input, Button, Tag, Select, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CloseIcon, FilterOutlinedIcon } from "../../IconsComp/Icons";
import commonStyles from "../../UI/CommonCss.module.css";
import ResetButton from "../../UI/ResetButton";
const { Option } = Select;
const tagRenderForMobileNum = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

function ClientCompanyFilter(props) {
  const [form] = Form.useForm();
  const [uniqueRoles, setUniqueRoles] = useState([]);
  const [uniquePersonPhone, setUniquePersonPhone] = useState([]);
  const [uniqueContactPerson, setUniqueContactPerson] = useState([]);

  useEffect(() => {
    if (props.clients && props.clients) {
      let rolesUn = new Set(
        props.clients.map((data) => data.contactPersonDesignation)
      );
      let personPhoneUn = new Set(
        props.clients.map((data) => data.contactPersonPhone)
      );
      let contactPersonUn = new Set(
        props.clients.map((data) => data.contactPerson)
      );
      setUniqueRoles([...rolesUn]);
      setUniquePersonPhone([...personPhoneUn]);
      setUniqueContactPerson([...contactPersonUn]);
    }
  }, [props]);

  const [location, setLocation] = useState(false);
  const [filterDatas, setFilterDatas] = useState({
    clientName: "",
    clientEmail: "",
    contactPersonDesignation: "",
    contactPersonPhone: "",
    contactPerson: "",
  });
  const resetFilter = () => {
    form.resetFields();
  };

  const onFinish = (values) => {
    props.clientFilterHandleer(values);
    props.onCloseFilter();
  };

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("clientCompany"));

    if (obj) {
      form.setFieldsValue({
        clientName: obj.clientName,
        clientEmail: obj.clientEmail,
        contactPerson: obj.contactPerson,
        contactPersonPhone: obj.contactPersonPhone,
        jobStatus: obj.jobStatus,
        location: obj.location,
        status: obj.status,
      });
    }
  }, []);

  return (
    <>
      <Drawer
        className={"modalModified"}
        closable={false}
        extra={
          <div
            onClick={() => props.onCloseFilter()}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
          </div>
        }
        width={500}
        height={48}
        title={
          <>
            <Row>
              <Col>
                <FilterOutlinedIcon color={"var(--primaryColor)"} />
              </Col>
              <Col>
                <p
                  style={{ fontSize: "20px", margin: "0px 0px 0px 5px" }}
                  className={commonStyles.filterHeadingName}
                >
                  Filter
                </p>
              </Col>
            </Row>
          </>
        }
        footer={false}
        // onClose={props.onCloseFilter}
        open={props.onOpenFilter}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row style={{ padding: "0px 20px 80px 20px" }}>
            <Col md={24}>
              <Form.Item name="clientName" label="Company Name">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  onKeyUp={(val) => {
                    setFilterDatas({ clientName: val.target.value });
                  }}
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  className={`${commonStyles.border} `} /* candidateSelectHeight */
                  placeholder="Select company name"
                  tagRender={tagRenderForMobileNum}
                >
                  {filterDatas.clientName &&
                    filterDatas.clientName.length > 0 &&
                    filterDatas.clientName !== " " &&
                    props.clients &&
                    props.clients.map((data, index) => {
                      return (
                        <Option key={index} value={data.clientName}>
                          {data.clientName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              {props.permissions &&
                props.permissions.find(
                  (data) => data === "client-contact-info"
                ) && (
                  <Form.Item name="clientEmail" label="Email Id">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onKeyUp={(val) => {
                        setFilterDatas({
                          clientEmail: val.target.value,
                        });
                      }}
                      notFoundContent={null}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      className={`${commonStyles.border} `} /* candidateSelectHeight */
                      placeholder="Select email id"
                      tagRender={tagRenderForMobileNum}
                    >
                      {filterDatas.clientEmail &&
                        filterDatas.clientEmail !== " " &&
                        filterDatas.clientEmail.length > 0 &&
                        props.clients &&
                        props.clients.map((data, index) => {
                          return (
                            <Option key={index} value={data.clientEmail}>
                              {data.clientEmail}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                )}

              {/* <Form.Item name="contactPersonDesignation" label="Role">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  onKeyUp={(val) => {
                    setFilterDatas({
                      contactPersonDesignation: val.target.value,
                    });
                  }}
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  className={`${commonStyles.border} `}
                  placeholder="Select role"
                  tagRender={tagRenderForMobileNum}
                >
                  {filterDatas.contactPersonDesignation &&
                    filterDatas.contactPersonDesignation !== " " &&
                    filterDatas.contactPersonDesignation.length > 0 &&
                    uniqueRoles &&
                    uniqueRoles.map((data, index) => {
                      return (
                        <Option key={index} value={data}>
                          {data}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item> */}

              <Form.Item name="jobStatus" label="Job Status">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  allowClear
                  placeholder="Select job status"
                  className={`${commonStyles.border} `} /* candidateSelectHeight */
                >
                  <Select.Option key={"active"} value={"active"}>
                    Active
                  </Select.Option>
                  <Select.Option key={"inactive"} value={"inactive"}>
                    Inactive
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="contactPersonPhone"
                label="Contact Person Number"
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  onKeyUp={(val) => {
                    setFilterDatas({
                      contactPersonPhone: val.target.value,
                    });
                  }}
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  className={`${commonStyles.border} `} /* candidateSelectHeight */
                  placeholder="Select contact person number"
                  tagRender={tagRenderForMobileNum}
                >
                  {filterDatas.contactPersonPhone &&
                    filterDatas.contactPersonPhone !== " " &&
                    filterDatas.contactPersonPhone.length > 0 &&
                    uniquePersonPhone &&
                    uniquePersonPhone.map((data, index) => {
                      return (
                        <Option key={index} value={data}>
                          {data}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item name="contactPerson" label="Contact Person Name">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  onKeyUp={(val) => {
                    setFilterDatas({
                      contactPerson: val.target.value,
                    });
                  }}
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  className={`${commonStyles.border} `} /* candidateSelectHeight */
                  placeholder="Select contact person name"
                  tagRender={tagRenderForMobileNum}
                >
                  {filterDatas.contactPerson &&
                    filterDatas.contactPerson !== " " &&
                    filterDatas.contactPerson.length > 0 &&
                    uniqueContactPerson &&
                    uniqueContactPerson.map((data, index) => {
                      return (
                        <Option key={index} value={data}>
                          {data}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item name="status" label="Company Status">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  allowClear
                  placeholder="Select status"
                  className={`${commonStyles.border} `} /* candidateSelectHeight */
                >
                  <Select.Option key={"active"} value={"active"}>
                    Active
                  </Select.Option>
                  <Select.Option key={"inactive"} value={"inactive"}>
                    Inactive
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="location" label="Location">
                <Input
                  onChange={() => {
                    if (!location) {
                      setLocation(true);
                    }
                  }}
                  placeholder="Enter location"
                  // className={"CandidateInputHeight"}
                />
              </Form.Item>
              {location && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      location: "",
                    });
                    setLocation(false);
                  }}
                />
              )}
            </Col>
          </Row>

          <Col className={commonStyles.footerShadow} style={{ width: "503px" }}>
            <Row justify="end" style={{ gap: "25px" }}>
              <Col>
                <Button
                  onClick={() => {
                    localStorage.removeItem("clientCompany");
                    localStorage.removeItem("ClientCompanySort");
                    props.clientFilterHandleer({});
                    setLocation(false);
                    resetFilter();
                    setFilterDatas({
                      clientName: "",
                      clientEmail: "",
                      contactPersonDesignation: "",
                      contactPersonPhone: "",
                      contactPerson: "",
                    });
                    props.onCloseFilter();
                  }}
                  className={commonStyles.buttonSettingsSecondary}
                  type="default"
                >
                  Reset Filter
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    form.submit();
                  }}
                  htmlType="button"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientCompanyFilter);
