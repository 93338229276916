import {
  getEmailTemplatesSlice,
  getEmailTemplateSlice,
  postEmailTemplateSlice,
  putEmailTemplatesSlice,
  deleteEmailTemplateSlice,
} from "../../Slice/Master/MasterEmailTemplateSlice";
import { EMAIL_TEMPLATE_URL } from "../../masterpath";
import { message } from "antd";
import AxiosApi from "../../api";
const api = new AxiosApi();
export const getEmailTemplatesAction = () => {
  return (dispatch) => {
    api.invoke(EMAIL_TEMPLATE_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getEmailTemplatesSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getEmailTemplateAction = (id, callback) => {
  return (dispatch) => {
    api.invoke(
      EMAIL_TEMPLATE_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            dispatch(getEmailTemplateSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postEmailTemplateAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      EMAIL_TEMPLATE_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);

        if (success) {
          if (statusCode === 200) {
            callback(true);

            message.destroy();
            message.success(data.message, 3);
            dispatch(postEmailTemplateSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const putEmailTemplatesAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      EMAIL_TEMPLATE_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(putEmailTemplatesSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteEmailTemplateAction = (id) => {
  return (dispatch) => {
    api.invoke(
      EMAIL_TEMPLATE_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(deleteEmailTemplateSlice(id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
