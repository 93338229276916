import {
  Table,
  Tag,
  Dropdown,
  Menu,
  Space,
  Row,
  Col,
  Button,
  Select,
  Image,
  Modal,
} from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Employee.module.css";
import commonCssStyle from "../../UI/CommonCss.module.css";
import {
  CalendarCheckIcon,
  CloseIcon,
  DisableUserIcon,
  EditIcon,
  FilterOutlinedIcon,
  PlusIcon,
  ThreeDotsIcon,
  TrashIcon,
  UserIcon,
} from "../../IconsComp/Icons";
import EmployeeFilter from "./EmployeeFilter";
import AddEmployee from "./AddEmployee";
import { connect } from "react-redux";
import { getJobTypesAction } from "../../../Reusable/Store/Action/Master/MasterJobTypesAction";
import {
  deleteEmployeesAction,
  employee_filterAction,
  getEmployeeAction,
  getEmployeesAction,
  postEmployeesAction,
  putEmployeesAction,
} from "../../../Reusable/Store/Action/EmployeesAction";
import { getSettingsWorklocationAction } from "../../../Reusable/Store/Action/SettingsAction";
import { getCountriesAction } from "../../../Reusable/Store/Action/Master/MasterCountryAction";
import { useEffect } from "react";
import { getDepartmentsAction } from "../../../Reusable/Store/Action/Master/MasterDepartmentsAction";
import {
  getAllRoleTypesAction,
  getRoleTypesAction,
} from "../../../Reusable/Store/Action/Master/MasterRoleTypeAction";
import moment from "moment";
import { getRolesAction } from "../../../Reusable/Store/Action/Master/MasterRolesAction";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import PermissionMessage from "../../UI/PermissionMessage";
import BreadcrumbForEmployees from "./BreadcrumbForEmployees";

const Employee = (props) => {
  const navigate = useNavigate();
  const [iconClrHover, setIconsClrHover] = useState(false);
  const [editIconHover, setEditIconHver] = useState(false);
  const [disableIconHover, DisableEditIconHver] = useState(false);
  const [deleteIconHover, DeleteEditIconHver] = useState(false);
  const [cfilter, setCFilter] = useState({});
  const [iconClr, setIconClr] = useState("var(--subHeadings-color)");
  const [deletId, setDeleteId] = useState("");
  const [countries, setCountries] = useState([]);
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });

  if (window.location.hash === "#/employee") {
    localStorage.removeItem("candidateDetail");
    localStorage.removeItem("clientCompany");
    localStorage.removeItem("requirementFilterData");
    localStorage.removeItem("recuritmentFilterData");
    localStorage.removeItem("taskFilterData");
    localStorage.removeItem("interviewFilterData");
    localStorage.removeItem("candidateDetailFromRequirement");
    localStorage.removeItem("candidateListForPool");
    localStorage.removeItem("recuritmentCandidateFilter");
    localStorage.removeItem("reportsCompanyDetailFilterData");
    localStorage.removeItem("reportsjobdetailfilterdata");
    localStorage.removeItem("reportsjobcandidatefilterdata");
    localStorage.removeItem("fromWhichPage");
    localStorage.removeItem("candidateDetailFromRecruitment");
    localStorage.removeItem("recuritmentReportFilter");
    localStorage.removeItem("employeeReportsFilter");

    localStorage.removeItem("ClientCompanySort");
localStorage.removeItem("ReportsCompanyReport1Sort");
localStorage.removeItem("reportsjobcandidatefilterdataSort");
localStorage.removeItem("ReportsJobDetailFilterOneSort");
localStorage.removeItem("reportRecruitmentEmployeeSort");

localStorage.removeItem("candidateSort");
localStorage.removeItem("interivewSort");
localStorage.removeItem("taskSort");
localStorage.removeItem("recuritmentSort");
localStorage.removeItem("requirementSort");
  }

  const [open, setOpen] = useState(false);
  const [openType, setOpenType] = useState(false);

  const [openFilter, setOpenFilter] = useState(false);

  const [openDeleteModal, SetOpenDeleteModal] = useState(false);

  function confirmDeleteHandler() {
    SetOpenDeleteModal(false);
    props.deleteEmployeesAction(deletId, (success) => {});
    setDeleteId("");
  }

  function closeDeleteHandler() {
    SetOpenDeleteModal(false);
  }

  const showDrawer = (type, id) => {
    setOpen(true);

    setOpenType(type);
    if (type === "edit") {
      props.getEmployeeAction(id);
    }
  };
  const showFilterHandler = () => {
    setOpenFilter(true);
  };
  const closeFilterHandler = () => {
    setOpenFilter(false);
  };
  const closeHandler = () => {
    setOpen(false);
  };
  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const columns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Name
        </p>
      ),

      render: (text, record) => (
        <>
          <Row align="middle" style={{ gap: "17px" }} wrap={false}>
            <Col>
              <Image
                preview={false}
                width={40}
                height={40}
                style={{ borderRadius: "20px" }}
                src={record.profileImage}
              />
            </Col>
            {props.permissions &&
            props.permissions.find((data) => data === "employees-detail") &&
            record.status === "active" ? (
              <Col>
                <p
                  onClick={() => {
                    props.getCheckPermissionHandler(
                      "employees-detail",
                      (success) => {
                        if (success) {
                          navigate("/employee/summary", {
                            state: { id: record.id, allow: record.isPrimary },
                          });
                        } else {
                          setOpenPMModal({
                            open: true,
                            permission: "Client Details",
                          });
                        }
                      }
                    );
                  }}
                  style={{
                    color: "var(--primaryColor)",
                    fontFamily: "Helvetica,ProductSans",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "17px",
                    margin: "0px",
                    cursor: "pointer",
                  }}
                >
                  {/* <Link
                    state={record.id}
                    to={"/employee/summary"}
                   
                  > */}
                  {record.firstName + " " + record.lastName}
                  {/* </Link> */}
                </p>
              </Col>
            ) : (
              <Col>
                <p
                  className={` ${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
                >
                  {record.firstName + " " + record.lastName}
                </p>
              </Col>
            )}
          </Row>
        </>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Official Email & Number
        </p>
      ),
      dataIndex: "emailAddress",
      // align: "center",
      render: (text, record) => (
        <>
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
            style={{ marginBottom: "9px" }}
          >
            {record.email}
          </p>
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {record.phone}
          </p>
        </>
      ),
    },
    // {
    //   title: (
    //     <p
    //       className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
    //     >
    //       Job Title
    //     </p>
    //   ),
    //   dataIndex: "designation",
    //   // align: "center",
    //   render: (data) => {
    //     return (
    //       <p
    //         className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
    //       >
    //         {data ? data : ""}
    //       </p>
    //     );
    //   },
    // },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Reporting Manager
        </p>
      ),
      dataIndex: "reportingManagerName",
      // align: "center",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Team
        </p>
      ),
      dataIndex: "team",
      // align: "center",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Type
        </p>
      ),
      dataIndex: "isExternal",
      // align: "center",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? "External" : "Internal"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Joining Date
        </p>
      ),
      dataIndex: "joiningDate",
      // align: "center",
      render: (data) => {
        return (
          <p
          style={{whiteSpace:"nowrap"}}
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? moment(data).format(dataFormate) : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Status
        </p>
      ),
      dataIndex: "status",
      // align: "center",
      render: (text) =>
        text === "active" ? (
          <>
            <Tag className={styles.activeTagsStyleForEmployee}>{text}</Tag>
          </>
        ) : (
          <Tag className={styles.inActiveTagsStyleForEmployee}>{text}</Tag>
        ),
    },
    {
      render: (record, data) => (
        <>
          {data.isPrimary === 0 &&
            props.permissions &&
            props.permissions.find((data) => data === "employees-edit") && (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Dropdown
                  overlay={
                    <Menu
                      style={{ paddingBottom: "10px", width: "220px" }}
                      items={[
                        // props.permissions &&
                        //   props.permissions.find(
                        //     (data) => data === "employees-assign-role"
                        //   ) &&
                        // {
                        //   key: "1",
                        //   label: (
                        //     <>
                        //       {record.status === "active" ? (
                        //         <Row
                        //           align="middle"
                        //           onMouseEnter={() => {
                        //             setIconsClrHover(true);
                        //           }}
                        //           onMouseLeave={() => {
                        //             setIconsClrHover(false);
                        //           }}
                        //         >
                        //           <Col
                        //             style={{
                        //               padding: "5px 5px 0px 0px",
                        //               transform: "translateY(3px)",
                        //             }}
                        //           >
                        //             <CalendarCheckIcon
                        //               color={
                        //                 !iconClrHover
                        //                   ? "var(--body-text-color)"
                        //                   : "var(--primaryColor)"
                        //               }
                        //             />
                        //           </Col>
                        //           <Col style={{ padding: "5px 5px 0px 5px" }}>
                        //             <p
                        //               style={{
                        //                 margin: "0px",
                        //                 color: !iconClrHover
                        //                   ? "var(--body-text-color)"
                        //                   : "var(--primaryColor)",
                        //               }}
                        //               className={
                        //                 commonCssStyle.textClrForMenu
                        //               }
                        //             >
                        //               Assign
                        //             </p>
                        //           </Col>
                        //         </Row>
                        //       ) : (
                        //         ""
                        //       )}
                        //     </>
                        //   ),
                        // },
                        props.permissions &&
                          props.permissions.find(
                            (data) => data === "employees-edit"
                          ) && {
                            key: "3",
                            label: (
                              <>
                                <Row
                                  onMouseEnter={() => {
                                    setEditIconHver(true);
                                  }}
                                  onMouseLeave={() => {
                                    setEditIconHver(false);
                                  }}
                                  onClick={() => {
                                    props.getCheckPermissionHandler(
                                      "employees-edit",
                                      (success) => {
                                        if (success) {
                                          props.putEmployeesAction(
                                            {
                                              id: record.id,
                                              status:
                                                record.status === "active"
                                                  ? "inactive"
                                                  : "active",
                                              current_page:
                                                props.employee_filter &&
                                                props.employee_filter
                                                  .current_page
                                                  ? props.employee_filter
                                                      .current_page
                                                  : 1,
                                            },
                                            "grid",
                                            (success) => {}
                                          );
                                        } else {
                                          setOpenPMModal({
                                            open: true,
                                            permission: "Employees Edit",
                                          });
                                        }
                                      }
                                    );
                                  }}
                                  align="middle"
                                >
                                  {record.status &&
                                  record.status === "active" ? (
                                    <>
                                      <Col
                                        style={{
                                          padding: "5px 5px 0px 0px",

                                          transform: "translateY(3px)",
                                        }}
                                      >
                                        <DisableUserIcon
                                          color={
                                            !editIconHover
                                              ? "var(--body-text-color)"
                                              : "var(--primaryColor)"
                                          }
                                        />
                                      </Col>

                                      <Col
                                        style={{ padding: "11px 5px 0px 5px" }}
                                      >
                                        <p
                                          style={{
                                            margin: "0px",
                                            color: !editIconHover
                                              ? "var(--body-text-color)"
                                              : "var(--primaryColor)",
                                          }}
                                        >
                                          Disable
                                        </p>
                                      </Col>
                                    </>
                                  ) : (
                                    <>
                                      <Col
                                        style={{
                                          padding: "5px 5px 0px 0px",

                                          transform: "translateY(3px)",
                                        }}
                                      >
                                        <UserIcon color={iconClr} />
                                      </Col>

                                      <Col
                                        style={{ padding: "11px 5px 0px 5px" }}
                                      >
                                        <p
                                          style={{
                                            margin: "0px",
                                            color: iconClr,
                                          }}
                                        >
                                          Enable
                                        </p>
                                      </Col>
                                    </>
                                  )}
                                </Row>
                              </>
                            ),
                          },
                        props.permissions &&
                          props.permissions.find(
                            (data) => data === "employees-delete"
                          ) && {
                            key: "4",
                            label: (
                              <>
                                <Row
                                  onClick={() => {
                                    props.getCheckPermissionHandler(
                                      "employees-delete",
                                      (success) => {
                                        if (success) {
                                          SetOpenDeleteModal(true);
                                          setDeleteId(record.id);
                                        } else {
                                          setOpenPMModal({
                                            open: true,
                                            permission: "Employee Delete",
                                          });
                                        }
                                      }
                                    );

                                    // props.deleteEmployeesAction(record.id);
                                  }}
                                  align="middle"
                                  onMouseEnter={() => {
                                    DeleteEditIconHver("#F83A3A");
                                  }}
                                  onMouseLeave={() => {
                                    DeleteEditIconHver(false);
                                  }}
                                >
                                  <Col
                                    style={{
                                      padding: "5px 5px 0px 0px",
                                      transform: "translateY(3px)",
                                    }}
                                  >
                                    <TrashIcon
                                      color={
                                        !deleteIconHover
                                          ? "var(--body-text-color)"
                                          : "#F83A3A"
                                      }
                                    />
                                  </Col>
                                  <Col style={{ padding: "5px 5px 0px 5px" }}>
                                    <p
                                      style={{
                                        margin: "0px",
                                        color: !deleteIconHover
                                          ? "var(--body-text-color)"
                                          : "#F83A3A",
                                      }}
                                      className={commonCssStyle.textClrForMenu}
                                    >
                                      Delete
                                    </p>
                                  </Col>
                                </Row>
                              </>
                            ),
                          },
                      ]}
                    />
                  }
                >
                  <a href onClick={(e) => e.preventDefault()}>
                    <Space>
                      <ThreeDotsIcon color={"var(--table-text-color)"} />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            )}
        </>
      ),
    },
  ];

  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      props.getEmployeesAction();
      props.getJobTypesAction();
      props.getCountryHandler({ type: "country" }, (data, type) => {
        setCountries(data);
      });
      props.getDepartmentsAction();
      props.getAllRoleTypesAction();
      props.getRolesTypeHandler();
      props.getSettingsWorkLocationHandler();
    }
    return () => {
      unsubscribed = true;
    };
  }, []);

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("employee"));
    if (obj) {
      props.employee_filterAction(obj);
    } else {
      props.employee_filterAction({ current_page: 1 });
    }
  }, []);

  const handleProvinceChange = (value,d) => {
    localStorage.setItem("employeeSort",JSON.stringify(d.value));
    let employeeFilter = JSON.parse(localStorage.getItem("employee"));
    let sort = value.split("_");
    if (employeeFilter) {
      props.employee_filterAction({
        current_page: 1,
        ...cfilter,
        sortBy: sort[0],
        sortOrder: sort[1],
        ...employeeFilter,
      });
      setCFilter({
        current_page: 1,
        ...cfilter,
        sortBy: sort[0],
        sortOrder: sort[1],
        ...employeeFilter,
      });
      localStorage.setItem("employee",JSON.stringify({...employeeFilter,sortBy: sort[0],
        sortOrder: sort[1], }))
    } else {
      props.employee_filterAction({
        current_page: 1,
        ...cfilter,
        sortBy: sort[0],
        sortOrder: sort[1],
      });
      setCFilter({
        current_page: 1,
        ...cfilter,
        sortBy: sort[0],
        sortOrder: sort[1],
      });
      localStorage.setItem("employee",JSON.stringify({current_page: 1,
        ...cfilter,sortBy: sort[0],
        sortOrder: sort[1], }))
    }
  };
  const employeeFilterHandleer = (val) => {
    let employeeFilter = JSON.parse(localStorage.getItem("employee"));
    const data = {
      current_page: 1,
      ...employeeFilter,
      ...val,
    };
    setCFilter(data);
    props.employee_filterAction(data);
    localStorage.setItem("employee", JSON.stringify(data));
  };
  const fetchRecords = (page) => {
    const fDataEmployee = JSON.parse(localStorage.getItem("employee"));
    if (fDataEmployee) {
      const val = {
        ...cfilter,
        ...fDataEmployee,
        ...props.clientsData,
        current_page: page,
      };
      localStorage.setItem(
        "employee",
        JSON.stringify({ ...fDataEmployee, current_page: page })
      );
      props.employee_filterAction(val);
    } else {
      const val = {
        ...cfilter,
        ...props.clientsData,
        current_page: page,
      };
      localStorage.setItem("employee", JSON.stringify({ current_page: page }));
      props.employee_filterAction(val);
    }
  };
  return (
    <>
      <Row>
        <Col className={commonCssStyle.breadcrumbBottomMargin} md={24}>
          <BreadcrumbForEmployees slide={[1, 2]} />
        </Col>
        {open && openType === "add" && (
          <AddEmployee
            employees={props.employees}
            employmentType={props.jobTypes}
            roleTypeData={props.roleTypeData}
            departments={props.departments}
            countriesData={props.countriesData}
            workLocation={props.settingsWorkLocation}
            settindsData={props.settindsData}
            onSubmit={(val) => {
              props.postEmployeesAction(val, (success) => {
                if (success) closeHandler();
              });
            }}
            onOpen={open}
            onClose={closeHandler}
          />
        )}
        {open && openType === "edit" && props.employee && (
          <AddEmployee
            employees={props.employees}
            employmentType={props.jobTypes}
            roleTypeData={props.roleTypeData}
            departments={props.departments}
            countriesData={props.countriesData}
            workLocation={props.settingsWorkLocation}
            settindsData={props.settindsData}
            employee={props.employee}
            onSubmit={(val) => {
              props.putEmployeesAction(val, (success) => {
                if (success) closeHandler();
              });
            }}
            onOpen={open}
            onClose={closeHandler}
          />
        )}
        <EmployeeFilter
          employees={props.employees}
          employmentType={props.jobTypes}
          roleTypeData={props.roleTypeData}
          departments={props.departments}
          employeeFilterHandleer={employeeFilterHandleer}
          onOpenFilter={openFilter}
          onCloseFilter={closeFilterHandler}
          settindsData={props.settindsData}
        />

        <Col xs={24} style={{ marginBottom: "20px" }}>
          <h1
            style={{ marginRight: "48px" }}
            className={`${commonCssStyle.mainHeadingText} ${commonCssStyle.inttitle}`}
          >
            Employees ({" "}
            {props.employee_filter && props.employee_filter.total
              ? props.employee_filter.total
              : "0"}{" "}
            )
          </h1>
          <Button
            onClick={showFilterHandler}
            className={commonCssStyle.filterButton}
          >
            <Row style={{ gap: "11px" }}>
              <Col>
                <div style={{ transform: "translateY(2px)" }}>
                  <FilterOutlinedIcon color={"var(--primaryColor)"} />
                </div>
              </Col>
              <Col>
                <p
                  className={`${commonCssStyle.filterAndSortTextStyle} ${commonCssStyle.marginBottomRemove}`}
                >
                  Filter
                </p>
              </Col>
            </Row>
          </Button>

          <Button
            style={{
              height: "40px",
            }}
            className={`${commonCssStyle.sortBtn}`}
          >
            <Row align="middle" justify="space-between">
              <Col>
                <p
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Sort by
                </p>
              </Col>
              <Col>
                <Select
                value={JSON.parse(localStorage.getItem("employeeSort"))?
                JSON.parse(localStorage.getItem("employeeSort")):"Select"}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  style={{ width: "100%" }}
                  bordered={false}
                  onChange={handleProvinceChange}
                  placeholder={
                    <p
                      style={{
                        margin: "0px",
                        color: "var(--primaryColor)",
                      }}
                    >
                      Select
                    </p>
                  }
                  size="small"
                  className={`${commonCssStyle.filterDrop} selectInput sortBySelectStyle selectTag selectTag1`}
                >
                  <Select.Option
                    key="1"
                    value="firstName_ASC"
                    style={{ padding: "10px" }}
                  >
                    Name Ascending
                  </Select.Option>
                  <Select.Option
                    key="2"
                    value="firstName_DESC"
                    style={{ padding: "10px" }}
                  >
                    Name Descending
                  </Select.Option>
                  {/* <Select.Option
                    key="3"
                    value="lastName_ASC"
                    style={{ padding: "10px" }}
                  >
                    Last Name Ascending
                  </Select.Option>
                  <Select.Option
                    key="4"
                    value="lastName_DESC"
                    style={{ padding: "10px" }}
                  >
                    Last Name Descending
                  </Select.Option> */}
                  <Select.Option
                    key="5"
                    value="employeeId_ASC"
                    style={{ padding: "10px" }}
                  >
                    Employee Id Ascending
                  </Select.Option>
                  <Select.Option
                    key="6"
                    value="employeeId_DESC"
                    style={{ padding: "10px" }}
                  >
                    Employee Id Descending
                  </Select.Option>
                  <Select.Option
                    key="5"
                    value="joiningDate_ASC"
                    style={{ padding: "10px" }}
                  >
                    Joining Date Ascending
                  </Select.Option>
                  <Select.Option
                    key="6"
                    value="joiningDate_DESC"
                    style={{ padding: "10px" }}
                  >
                    Joining Date Descending
                  </Select.Option>
                  {/* <Select.Option
                    key="5"
                    value="employmentType_ASC"
                    style={{ padding: "10px" }}
                  >
                    Employment Type Ascending
                  </Select.Option>
                  <Select.Option
                    key="6"
                    value="employmentType_DESC"
                    style={{ padding: "10px" }}
                  >
                    Employment Type Descending
                  </Select.Option> */}
                </Select>
              </Col>
            </Row>
          </Button>

          {props.permissions &&
            props.permissions.find((data) => data === "employees-add") && (
              <Button
                type="primary"
                style={{ boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.15)" }}
                onClick={() => {
                  props.getCheckPermissionHandler(
                    "employees-add",
                    (success) => {
                      if (success) {
                        showDrawer("add", null);
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Employees Add",
                        });
                      }
                    }
                  );
                }}
                className={commonCssStyle.intaddButton}
              >
                <Row align="middle" style={{ gap: "11px" }}>
                  <Col>
                    <PlusIcon color={"white"} />
                  </Col>
                  <Col>
                    <p className={`${commonCssStyle.marginBottomRemove} `}>
                      Add new Employee
                    </p>
                  </Col>
                </Row>
              </Button>
            )}
        </Col>
        <Col xs={24}>
          <Table
            scroll={{ x: "auto" }}
            pagination={{
              size: "small",
              current:
                props.employee_filter && props.employee_filter.current_page
                  ? props.employee_filter.current_page
                  : 1,
              pageSize: props.employee_filter && props.employee_filter.per_page,
              total: props.employee_filter && props.employee_filter.total,
              showSizeChanger: false,
              onChange: (props) => {
                fetchRecords(props);
              },
            }}
            rowKey="key"
            columns={columns}
            dataSource={props.employee_filter.data}
          />
        </Col>
      </Row>

      <Modal
        closable={false}
        title={
          <Row>
            <Col md={20}>
              {" "}
              <p className={commonCssStyle.popUpHeadingText}>Delete Employee</p>
            </Col>
            <Col md={4}>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  closeDeleteHandler();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)"} />
              </div>
            </Col>
          </Row>
        }
        footer={
          <>
            <Row style={{ gap: "10px" }} justify="end" align="middle">
              <Col>
                <Button
                  type="default"
                  style={{ height: "48px", fontSize: "16px", width: "181px" }}
                  className={commonCssStyle.buttonSettingsSecondary}
                  onClick={() => {
                    closeDeleteHandler();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  style={{ height: "48px", fontSize: "16px", width: "181px" }}
                  className={commonCssStyle.buttonSettingsPrimary}
                  onClick={() => {
                    confirmDeleteHandler();
                  }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </>
        }
        open={openDeleteModal}
        onOk={confirmDeleteHandler}
      >
        <Row>
          <Col>
            <p className={commonCssStyle.tableHeadingTextData}>
              Are you sure you want to delete
            </p>
          </Col>
        </Row>
      </Modal>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    employees: state.employee.employees,
    employee_filter: state.employee.employee_filter,
    employee: state.employee.employee,
    countriesData: state.country.countries,
    jobTypes: state.jobtype.jobTypes,
    departments: state.departments.departments,
    // roleTypeData: state.roleTypes.roleTypes,
    roleTypeData: state.roles.roles,
    settingsWorkLocation: state.settings.settingsWorklocation,
    settindsData: state.settings.settings,
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeesAction: () => {
      dispatch(getEmployeesAction());
    },
    getEmployeeAction: (id) => {
      dispatch(getEmployeeAction(id));
    },
    getJobTypesAction: () => {
      dispatch(getJobTypesAction());
    },
    employee_filterAction: (val) => {
      dispatch(employee_filterAction(val));
    },
    deleteEmployeesAction: (id) => {
      dispatch(deleteEmployeesAction(id));
    },
    putEmployeesAction: (data, type, callback) => {
      dispatch(putEmployeesAction(data, type, callback));
    },
    postEmployeesAction: (data, callback) => {
      dispatch(postEmployeesAction(data, callback));
    },
    getCountryHandler: (data, callback) => {
      dispatch(getCountriesAction(data, callback));
    },
    getDepartmentsAction: () => {
      dispatch(getDepartmentsAction());
    },
    getAllRoleTypesAction: () => {
      dispatch(getAllRoleTypesAction());
    },
    // getRolesTypeHandler: () => {
    //   dispatch(getRoleTypesAction());
    // },
    getRolesTypeHandler: () => {
      dispatch(getRolesAction());
    },
    getSettingsWorkLocationHandler: () => {
      dispatch(getSettingsWorklocationAction());
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Employee);
