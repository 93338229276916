// import styles from "../SideBarComp/SideBar.module.css";
import styles from "../../UI/SideBarComp/SideBar.module.css";
import { useEffect, useState } from "react";
import { Col, Layout, Menu, Row, Tooltip } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Joyride from "react-joyride";
import {
  DashboardIcon,
  CalendarIcon,
  BuildingsIcon,
  UsersIcon,
  BrieftCaseIcon,
  UserFocusIcon,
  UserIcon,
  ReportIcon,
  SettingIcon,
} from "../../IconsComp/Icons";
import { StepsTour } from "../../..";
import { connect } from "react-redux";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import { activeMenuKeySlice } from "../../../Reusable/Store/Slice/SettingsSlice";
const { Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

function SideBar(props) {
  const location = useLocation();
  const navigate = useNavigate();
  // let activeKey = localStorage.getItem("activeMenu");
  // const [selectedKey, setSelectedKey] = useState(
  //   location.pathname !== "/" ? location.pathname : "/dashboard"
  // );
  const [selectedKey, setSelectedKey] = useState(null);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (
      props?.onProfile?.roleType === "External Recruiter" &&
      location.pathname === "/"
    ) {
      props.activeMenuKeyHandle("/recruitment");
    }
  }, [props.onProfile]);

  useEffect(() => {
    // if (activeKey) {

    if (props.activeKey !== location.pathname) {
      if (
        (location.pathname === "/" &&
          props?.onProfile?.roleType !== "External Recruiter") ||
        (location.pathname === "/dashboard" &&
          props?.onProfile?.roleType !== "External Recruiter")
      ) {
        props.activeMenuKeyHandle("/dashboard");
      } else if (
        location.pathname === "/candidatesDetailsPage/summary" ||
        location.pathname === "/candidatesDetailsPage"
      ) {
        props.activeMenuKeyHandle("/candidatesDetailsPage");
      } else if (
        location.pathname === "/recruitment" ||
        location.pathname === "/recruitment/recruitmentJobdetails" ||
        location.pathname === "/candidates/suggested" ||
        location.pathname === "/candidateForPool" ||
        location.pathname === "/importCandidate"
      ) {
        props.activeMenuKeyHandle("/recruitment");
      } else if (location.pathname === "/taskCalendar") {
        props.activeMenuKeyHandle("/taskCalendar");
      } else if (
        location.pathname === "/JobDetailPage" ||
        location.pathname === "/requirement"
      ) {
        props.activeMenuKeyHandle("/requirement");
      } else if (
        location.pathname === "/clientCompany" ||
        location.pathname === "/clientCompany/details"
      ) {
        props.activeMenuKeyHandle("/clientCompany");
      } else if (
        location.pathname === "/employee" ||
        location.pathname === "/employee/summary"
      ) {
        props.activeMenuKeyHandle("/employee");
      } else if (location.pathname === "/settings") {
        props.activeMenuKeyHandle("/settings");
      } else if (
        location.pathname === "/reports/companydetailtable" ||
        location.pathname === "/reports/jobdetailtable" ||
        location.pathname === "/reports/job/candidates"
      ) {
        props.activeMenuKeyHandle("/reports/companydetailtable");
      } else if (location.pathname === "/reports/candidatesummary") {
        props.activeMenuKeyHandle("/reports/candidatesummary");
      } else if (location.pathname === "/reports/candidatedetailtable") {
        props.activeMenuKeyHandle("/reports/candidatedetailtable");
      } else if (
        location.pathname === "/reports/employee" ||
        location.pathname === "/reports/employee/jobs"
      ) {
        props.activeMenuKeyHandle("/reports/employee");
      } else if (location.pathname === "/profile") {
        props.activeMenuKeyHandle("");
      }
    }
    // }
  }, [location.pathname]);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = props.settings && props.settings.logo && props.settings.logo;
  }, []);

  const items = [
    props.permissions &&
      props.permissions.find((data) => data === "dashboard") &&
      getItem(
        "Dashboard",
        "/dashboard",

        <Link
          to="/dashboard"
          className={styles.menuIcon}
          id="Dashboard-sidebar-icon"
        >
          <DashboardIcon color={"white"} />
        </Link>
      ),

    props.permissions &&
      props.permissions.find(
        (data) =>
          data === "recruitment-list" ||
          data === "recruitment-delete" ||
          // data === "recruitment-add" ||
          data === "recruitment-detail" ||
          data === "recruitment-candidate-pool" ||
          data === "recruitment-add-candidate" ||
          data === "recruitment-candidate-detail" ||
          data === "recruitment-candidate-status update" ||
          data === "recruitment-candidate-mail-list" ||
          data === "recruitment-candidate-mail-add" ||
          data === "recruitment-candidate-timeline" ||
          data === "recruitment-candidate-task-list" ||
          data === "recruitment-candidate-download-resume" ||
          data === "recruitment-candidate-download-recording" ||
          data === "recruitment-candidate-call" ||
          data === "recruitment-candidate-interview-list" ||
          data === "recruitment-candidate-offerLetter" ||
          data === "recruitment-delete-candidate"
        // data === "recruitment-candidate-jobs"
      ) &&
      getItem(
        "Recruitment",
        "/recruitment",

        <Link
          to="/recruitment"
          className={styles.menuIcon}
          id="Recruitment-sidebar-icon"
        >
          <UserFocusIcon color={"white"} />
        </Link>
      ),
    props.permissions &&
      props.permissions.find(
        (data) =>
          data === "Task-add" ||
          data === "Task-edit" ||
          data === "Task-delete" ||
          data === "Task-list-view" ||
          data === "Task-calendar-view" ||
          data === "interview-add" ||
          data === "interview-edit" ||
          data === "interview-detail" ||
          data === "interview-delete" ||
          data === "interview-status-update" ||
          data === "Task-status-update" ||
          data === "Task-detail"
      ) &&
      getItem(
        "Task & Calendar",
        "/taskCalendar",

        <Link
          to="/taskCalendar"
          className={styles.menuIcon}
          id="Task-sidebar-icon"
        >
          <CalendarIcon color={"white"} />
        </Link>
      ),
    props.permissions &&
      props.permissions.find(
        (data) =>
          data === "candidate-list" ||
          data === "candidate-detail" ||
          data === "candidate-edit" ||
          data === "candidate-mail-add" ||
          data === "candidate-call" ||
          data === "candidate-timeline" ||
          data === "candidate-download-resume" ||
          data === "candidate-download-recording" ||
          data === "candidate-mail-list" ||
          data === "candidate-add" ||
          data === "candidate-delete" ||
          data === "candidate-interview-list" ||
          data === "candidate-export" ||
          data === "candidate-task-list" ||
          data === "candidate-jobs"
      ) &&
      getItem(
        "Candidate",
        "/candidatesDetailsPage",

        <Link
          to="/candidatesDetailsPage"
          className={styles.menuIcon}
          id="Candidate-sidebar-icon"
        >
          <UsersIcon color={"white"} />
        </Link>
      ),
    props.permissions &&
      props.permissions.find(
        (data) =>
          data === "job-list" ||
          data === "job-add" ||
          data === "job-edit" ||
          data === "job-delete" ||
          data === "job-detail" ||
          data === "job-assign-employee" ||
          data === "job-task-list"
      ) &&
      getItem(
        "Requirement",
        "/requirement",

        <Link
          to="/requirement"
          className={styles.menuIcon}
          id="Requirement-sidebar-icon"
        >
          <BrieftCaseIcon color={"white"} />
        </Link>
      ),
    //client

    props.permissions &&
      props.permissions.find(
        (data) =>
          data === "client-list" ||
          data === "client-delete" ||
          data === "client-add" ||
          data === "client-detail" ||
          data === "client-edit" ||
          data === "client-job" ||
          data === "client-contact-info" ||
          // data === "client-contact-person-info" ||
          data === "client-billing-detail"
      ) &&
      getItem(
        "Client company",
        "/clientCompany",

        <Link
          to="/clientCompany"
          className={styles.menuIcon}
          id="Client-sidebar-icon"
        >
          <BuildingsIcon color={"white"} />
        </Link>
      ),
    props.permissions &&
      props.permissions.find(
        (data) =>
          data === "employees-list" ||
          data === "employees-add" ||
          data === "employees-edit" ||
          data === "employees-delete" ||
          data === "employees-detail" ||
          data === "employees-assign-role" ||
          data === "employees-assign-permission" ||
          data === "employees-timeline" ||
          data === "employees-task-list" ||
          data === "employee-download-recording" ||
          data === "employee-call"
      ) &&
      getItem(
        "Employee",
        "/employee",

        <Link
          to={"/employee"}
          className={styles.menuIcon}
          id="Employee-sidebar-icon"
        >
          <UserIcon color={"white"} />
        </Link>
      ),
    props.permissions &&
      props.permissions.find((data) => data === "report") &&
      getItem(
        "Reports",
        "/reports",

        <Link to={"/reports/candidatesummary"} className={styles.menuIcon}>
          <ReportIcon color={"white"} />
        </Link>,
        collapsed
          ? [
              getItem(
                "Candidate Summary",
                "/reports/candidatesummary",

                <Link
                  to={"/reports/candidatesummary"}
                  className={styles.menuIcon}
                />
              ),
              getItem(
                "Candidate Detail",
                "/reports/candidatedetailtable",

                <Link
                  to={"/reports/candidatedetailtable"}
                  state={{ from: "", leadSource: "" }}
                  className={styles.menuIcon}
                />
              ),
              getItem(
                "Company Detail",
                "/reports/companydetailtable",

                <Link
                  to={"/reports/companydetailtable"}
                  className={styles.menuIcon}
                />
              ),
              getItem(
                "Employee",
                "/reports/employee",
                <Link to={"/reports/employee"} className={styles.menuIcon} />
              ),
            ]
          : [
              getItem(
                "Candidate Summary",
                "/reports/candidatesummary",
                <Link
                  to={"/reports/candidatesummary"}
                  className={styles.menuIcon}
                >
                  <UsersIcon color={"white"} />
                </Link>
              ),
              getItem(
                "Candidate Detail",
                "/reports/candidatedetailtable",
                <Link
                  to={"/reports/candidatedetailtable"}
                  state={{ from: "", leadSource: "" }}
                  className={styles.menuIcon}
                >
                  <UsersIcon color={"white"} />
                </Link>
              ),
              getItem(
                "Company Detail",
                "/reports/companydetailtable",
                <Link
                  to={"/reports/companydetailtable"}
                  className={styles.menuIcon}
                >
                  <BuildingsIcon color={"white"} />
                </Link>
              ),
              getItem(
                "Employee",
                "/reports/employee",
                <Link to={"/reports/employee"} className={styles.menuIcon}>
                  <UserIcon color={"white"} />
                </Link>
              ),
            ]
      ),

    props.permissions &&
      props.permissions.find(
        (data) =>
          data === "settings-system" ||
          data === "settings-organisations" ||
          data === "settings-billing" ||
          data === "settings-roles-list" ||
          data === "settings-roles-add" ||
          data === "settings-roles-edit" ||
          data === "settings-roles-delete" ||
          data === "settings-application-emailTemplate" ||
          data === "settings-application-leadSource" ||
          data === "settings-application-candidateReason" ||
          data === "settings-application-jobListing" ||
          data === "settings-application-jobDescription" ||
          data === "settings-application-sharingMEdium" ||
          data === "settings-application-department" ||
          data === "settings-application-jobPrefix" ||
          data === "settings-import" ||
          data === "settings-application-tags"
      ) &&
      getItem(
        "Settings",
        "/settings",

        <Link
          to={"/settings"}
          className={styles.menuIcon}
          id="Settings-sidebar-icon"
        >
          <SettingIcon color={"white"} />
        </Link>
      ),
  ];
  function handleClick(e) {
    props.activeMenuKeyHandle(e.key);
  }

  return (
    <>
      {/* <Joyride
        isFixed
        continuous
        // hideCloseButton
        // scrollToFirstStep
        showProgress
        showSkipButton
        // stepIndex={joyRide.stepIndex}
        // steps={joyRide}
        steps={StepsTour}
        // steps={stepTourRide}
        callback={(val) => {
          if (
            val.type === "step:before" &&
            val.step.target === "#Recruitment-sidebar-icon"
          ) {
            setSelectedKey("/recruitment");
            navigate("/recruitment");
          } else if (
            val.type === "step:before" &&
            val.step.target === "#Task-sidebar-icon"
          ) {
            setSelectedKey("/taskCalendar");
            navigate("/taskCalendar");
          } else if (
            val.type === "step:before" &&
            val.step.target === "#Candidate-sidebar-icon"
          ) {
            setSelectedKey("/candidatesDetailsPage");
            navigate("/candidatesDetailsPage");
          } else if (
            val.type === "step:before" &&
            val.step.target === "#Requirement-sidebar-icon"
          ) {
            setSelectedKey("/requirement");
            navigate("/requirement");
          } else if (
            val.type === "step:before" &&
            val.step.target === "#Client-sidebar-icon"
          ) {
            setSelectedKey("/clientCompany");
            navigate("/clientCompany");
          } else if (
            val.type === "step:before" &&
            val.step.target === "#Employee-sidebar-icon"
          ) {
            setSelectedKey("/employee");
            navigate("/employee");
          } else if (
            val.type === "step:before" &&
            val.step.target === "#Settings-sidebar-icon"
          ) {
            setSelectedKey("/settings");
            navigate("/settings");
          }
        }}
        // callback={handleCallback}
        styles={{
          options: {
            arrowColor: "rgba(0,0,0)",
            // backgroundColor: 'var(--secondaryColor)',
            overlayColor: "rgba(0,0,0,0)",
            backgroundColor: "rgba(0,0,0,0.8)",
            primaryColor: "var(--primaryColor)",
            textColor: "var(--Joyride-Clr)",
            // width: 300,
            zIndex: 1000,
          },
        }}
      /> */}
      <Sider
        className="sideBar"
        collapsible
        onCollapse={(value) => setCollapsed(value)}
        collapsedWidth={145}
        width={275}
        // collapsed={collapsed}
        style={{
          height: "100vh",
          // overflowY: "scroll",
        }}
      >
        <div
          className={`${styles.logo} ${
            collapsed ? styles.logosOpen : styles.logosClose
          }`}
        >
          {props.settings && (
            <img className={`${styles.logoImage}`} src={props.settings.logo} />
          )}
        </div>
        <Menu
          // defaultSelectedKeys={[
          //   location.pathname !== "/" ? location.pathname : "/dashboard",
          // ]}
          onClick={handleClick}
          selectedKeys={[props.activeKey]}
          mode="inline"
          style={{
            // height: "1024px",

            padding: "0px 15px",
            fontSize: "18px",
            backgroundColor: "var(--sidemenu-bg-color)",
          }}
          items={items}
        />
      </Sider>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    permissions: state.authpermissions.permissions,
    ishavepermissions: state.authpermissions.ishavepermissions,
    activeKey: state.settings.activeKey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCheckPermissionHandler: (id) => {
      dispatch(checkPermissionAction(id));
    },
    activeMenuKeyHandle: (data) => {
      dispatch(activeMenuKeySlice(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
