import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countries: [],
  state:[],
  city:[]
};
export const masterCountrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    getCountriesSlice(state, action) {
      state.countries = action.payload.result;
    },
    getStateSlice(state, action) {
      state.state = action.payload.result;
    },
    getCitySlice(state, action) {
      state.city = action.payload.result;
    },
  },
});

export const { getCountriesSlice,getStateSlice,getCitySlice } = masterCountrySlice.actions;
export default masterCountrySlice.reducer;
