import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  TreeSelect,
  Button,
  Col,
  Row,
  Select,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import styles from "../ProfileComp/Profile.module.css";
import moment from "moment";
import commonCssStyle from "../../UI/CommonCss.module.css";
import { useForm } from "antd/lib/form/Form";
import { CloseIcon } from "../../IconsComp/Icons";
function PersonalInformationModal(props) {
  const [form] = useForm();
  const dataFormate =
    props.settings && props.settings.dateFormat
      ? props.settings.dateFormat
      : "DD/MM/YYYY";
  return (
    <Modal
      className="modalModifiedForTemplete"
      footer={false}
      title={
        <Row>
          <Col md={20}>
            <h3 className={commonCssStyle.popUpHeadingText}>
              Edit Personal Information
            </h3>
          </Col>
          <Col md={4}>
            <div
              style={{ float: "right" }}
              onClick={() => {
                props.onClosePINfo();
              }}
              className={`${commonCssStyle.coursorPointer} `}
            >
              <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
            </div>
          </Col>
        </Row>
      }
      open={props.onOpenPINfo}
      // onCancel={props.onClosePINfo}
      closable={false}
    >
      <Form
        form={form}
        initialValues={{
          dob: props.profile.dob && moment(props.profile.dob),
          gender: props.profile.gender,
          phone: props.profile.phone,
          address: props.profile.address,
        }}
        onFinish={(val) =>
          props.updatedProfileInfo(val, (success) => {
            if (success) props.onClosePINfo();
          })
        }
        layout="vertical"
      >
        <Row>
          <Col style={{ padding: "0px 30px 0px 30px" }}>
            <Row>
              <Col md={24}>
                <Form.Item name="dob" label="Date Of Birth">
                  <DatePicker
                    format={dataFormate}
                    disabledDate={(current) =>
                      current.isAfter(moment().isBefore(1, "day"))
                    }
                    className={`${commonCssStyle.datePickerBgColor} ${styles.popUpInputField}`}
                  />
                </Form.Item>
              </Col>

              <Col md={24}>
                <Form.Item name="gender" label="Gender">
                  <Select placeholder="Enter gender">
                    <Select.Option value="Male">Male</Select.Option>
                    <Select.Option value="Female">Female</Select.Option>
                    <Select.Option value="Other">Others</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={24}>
                <Form.Item name="phone" label="Phone Number">
                  <InputNumber
                    className={styles.popUpInputField}
                    placeholder="Enter phone number"
                  />
                </Form.Item>
              </Col>

              <Col md={24}>
                <Form.Item name="address" label="Address">
                  <Input.TextArea
                    className={styles.popUpInputField}
                    placeholder="Enter address"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col
            xs={24}
            md={24}
            className={commonCssStyle.footerShadowForTemplete}
          >
            <Row
              justify="end"
              style={{
                position: "relative",
                gap: "10px",
              }}
            >
              <Col>
                <Button
                  onClick={() => {
                    props.onClosePINfo();
                  }}
                  className={commonCssStyle.buttonSettingsSecondary}
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    form.submit();
                  }}
                  htmlType="button"
                  className={commonCssStyle.buttonSettingsPrimary}
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                  type="primary"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export default PersonalInformationModal;
