import {
  getJobTypesSlice,
  getJobTypeSlice,
  postJobTypeSlice,
  putJobTypesSlice,
  deleteJobTypeSlice,
} from "../../Slice/Master/MasterJobTypesSlice";
import { JOB_TYPES_URL } from "../../masterpath";
import { message } from "antd";
import AxiosApi from "../../api";
const api = new AxiosApi();
export const getJobTypesAction = () => {
  return (dispatch) => {
    api.invoke(JOB_TYPES_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getJobTypesSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getJobTypeAction = (id) => {
  return (dispatch) => {
    api.invoke(JOB_TYPES_URL + "/" + id, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getJobTypeSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const postJobTypeAction = (data) => {
  return (dispatch) => {
    api.invoke(
      JOB_TYPES_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {message.destroy();
            message.success(data.message, 3);dispatch(postJobTypeSlice(data));
           } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const putJobTypesAction = (data) => {
  return (dispatch) => {
    api.invoke(
      JOB_TYPES_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200){message.destroy();
            message.success(data.message, 3); dispatch(putJobTypesSlice(data));
           } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteJobTypeAction = (id) => {
  return (dispatch) => {
    api.invoke(
      JOB_TYPES_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200){message.destroy();
            message.success(data.message, 3); dispatch(deleteJobTypeSlice(id));}
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
