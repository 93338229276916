import {
  Button,
  Col,
  Dropdown,
  Menu,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import moment from "moment";
import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import { getInterViewAction } from "../../../Reusable/Store/Action/RecruitmentAction";
import {
  CloseIcon,
  EditIcon,
  ThreeDotsIcon,
  TrashIcon,
} from "../../IconsComp/Icons";
import commonCssStyle from "../../UI/CommonCss.module.css";
import PermissionMessage from "../../UI/PermissionMessage";
import RescheduleInterview from "../AddCandidatesComp/CandidatesSummary/RescheduleInterview";
import TaskAndInterviewStatus from "./TaskAndInterviewStatus";
function TaskListView(props) {
  const dateFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD-MM-YYYY";
  const navigate = useNavigate();
  const [iconClr, setIconClr] = useState("0");
  const [deleteBtnClr, setDeleteBtnClr] = useState("var(--headings-color)");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeletId] = useState("");
  const [rsOpen, setRsOpen] = useState(false);
  const [interviewId, setInterviewId] = useState("");

  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  let statusData = [
    {
      value: "pending",
      title: "Pending",
    },
    {
      value: "completed",
      title: "Completed",
    },
  ];

  let interviewStatusData = [
    {
      value: "Pending",
      title: "Pending",
    },
    {
      value: "Completed",
      title: "Completed",
    },
    {
      value: "Rescheduled by Candidate",
      title: "Rescheduled by Candidate",
    },
    {
      value: "Rescheduled by Client",
      title: "Rescheduled by Client",
    },
    {
      value: "Candidate No Show",
      title: "Candidate No Show",
    },
  ];

  const fetchRecords = (page) => {
    if (props.taskStatus) {
      let taskFilterDataLocal = JSON.parse(
        localStorage.getItem("taskFilterData")
      );
      if (taskFilterDataLocal) {
        const val = {
          ...taskFilterDataLocal,
          current_page: page,
        };
        localStorage.setItem(
          "taskFilterData",
          JSON.stringify({ ...taskFilterDataLocal, current_page: page })
        );
        props.pagnationData(val);
      } else {
        const val = {
          current_page: page,
        };
        localStorage.setItem(
          "taskFilterData",
          JSON.stringify({ current_page: page })
        );
        props.pagnationData(val);
      }
    } else if (!props.taskStatus) {
      let interviewFilterDataLocal = JSON.parse(
        localStorage.getItem("interviewFilterData")
      );
      if (interviewFilterDataLocal) {
        const val = {
          ...interviewFilterDataLocal,
          current_page: page,
        };
        localStorage.setItem(
          "interviewFilterData",
          JSON.stringify({ ...interviewFilterDataLocal, current_page: page })
        );
        props.pagnationData(val);
      } else {
        const val = {
          current_page: page,
        };
        localStorage.setItem(
          "interviewFilterData",
          JSON.stringify({ current_page: page })
        );
        props.pagnationData(val);
      }
    }
  };
  function handleDelete() {
    props.onDeleteData(deleteId);
    setIsModalOpen(false);
  }

  const columns = [
    {
      title: (
        <p
          className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
        >
          Task Name
        </p>
      ),
      dataIndex: "title",
      key: "1",
      width: "20%",
      render: (text, render) => (
        <>
          {props.permissions &&
          props.taskStatus &&
          props.permissions.find((data) => data === "Task-detail") ? (
            <p
              style={{
                color: "var(--primaryColor)",
                fontFamily: "Helvetica,ProductSans",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "17px",
                margin: "0px",
                cursor: "pointer",
              }}
              // to={"/taskCalendar/interview"}
              onClick={() => {
                props.getCheckPermissionHandler("Task-detail", (success) => {
                  if (success) {
                    // navigate({ state: { statusDatas: render.status } });
                    props.renderData(render);
                    props.displayModal(render.id);
                  } else {
                    setOpenPMModal({
                      open: true,
                      permission: "Task Details",
                    });
                  }
                });
              }}
            >
              {text && text.split("(")[1] === "+0)" ? text.split("(")[0] : text}
            </p>
          ) : props.permissions &&
            !props.taskStatus &&
            props.permissions.find((data) => data === "interview-detail") ? (
            <p
              style={{
                color: "var(--primaryColor)",
                fontFamily: "Helvetica,ProductSans",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "17px",
                margin: "0px",
                cursor: "pointer",
              }}
              // to={"/taskCalendar/interview"}
              onClick={() => {
                props.getCheckPermissionHandler(
                  "interview-detail",
                  (success) => {
                    if (success) {
                      // navigate({ state: { statusDatas: render.status } });
                      props.renderData(render);
                      props.displayModal(render.id);
                    } else {
                      setOpenPMModal({
                        open: true,
                        permission: "Interview Details",
                      });
                    }
                  }
                );
              }}
            >
              {text && text.split("(")[1] === "+0)" ? text.split("(")[0] : text}
            </p>
          ) : (
            <p
              className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableDataLinkText}`}
            >
              {text && text.split("(")[1] === "+0)" ? text.split("(")[0] : text}
            </p>
          )}

          {/* {props.interviewStartTime ||
            (render.interviewEndTime && (
              <p
                className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
              >
                {`${render.interviewStartTime && render.interviewStartTime} - ${
                  render.interviewEndTime && render.interviewEndTime
                }`}
              </p>
            ))} */}
        </>
      ),
    },
    !props.taskStatus
      ? {
          title: (
            <p
              className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
            >
              Time
            </p>
          ),
          width: "22%",
          render: (text, render) =>
            props.interviewStartTime ||
            (render.interviewEndTime && (
              <p
              style={{whiteSpace:"nowrap"}}
                className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
              >
                {`${render.interviewStartTime && render.interviewStartTime} - ${
                  render.interviewEndTime && render.interviewEndTime
                }`}
              </p>
            )),
        }
      : {},
    {
      title: (
        <p
          className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
        >
          Description
        </p>
      ),
      // width: "30%",
      dataIndex: "description",
      key: "2",
      render: (data) => {
        return (
          <>
            {data && data.length > 200 ? (
              <>
                <p
                  className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
                >
                  {data.substring(0, 200)}
                  <span>...</span>
                </p>
              </>
            ) : (
              <p
                className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
              >
                {data}
              </p>
            )}
          </>
        );
      },
    },

    {
      title: props.taskStatus ? (
        <p
          className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
        >
          Assign to
        </p>
      ) : (
        <p
          className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
        >
          Client
        </p>
      ),
      dataIndex: props.taskStatus ? "assignedToName" : "client",
      key: "3",

      render: (data, render) => {
        return props.taskStatus ? (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {/* {render.isPublic === 0
              ? data && data.split("(")[1] === "+0)"
                ? data.split("(")[0]
                : data
              : ""} */}
            {data && data.split("(")[1] === "+0)" ? data.split("(")[0] : data}
          </p>
        ) : (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
        >
          Assigned by
        </p>
      ),
      dataIndex: "assignedByName",
      key: "4",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NY"}
          </p>
        );
      },
    },
    {
      title:
        props.taskStatus && props.taskStatus ? (
          <p
            className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
          >
            Start date
          </p>
        ) : (
          <p
            className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
          >
            Date
          </p>
        ),
      dataIndex: "startDate",
      key: "5",
      width: "18%",
      render: (data) => {
        return (
          <p
          style={{whiteSpace:"nowrap"}}
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? moment(data).format(dateFormate) : "NY"}
          </p>
        );
      },
    },
    props.taskStatus && props.taskStatus
      ? {
          title: (
            <p
              className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
            >
              End date
            </p>
          ),
          dataIndex: props.taskStatus ? "dueDate" : "endDate",
          width: "18%",
          key: "6",
          render: (data, record) => {
            return (
              <p
              style={{whiteSpace:"nowrap"}}
                className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
              >
                {moment(data).format(dateFormate)}
              </p>
            );
          },
        }
      : {},
    !props.taskStatus
      ? {
          title: (
            <p
              className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
            >
              Team Member
            </p>
          ),
          dataIndex: "teamMember",
          key: "7",
          render: (data, record) => {
            return (
              <p
                className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
              >
                {data}
              </p>
            );
          },
        }
      : {},
    !props.taskStatus
      ? {
          title: (
            <p
              className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
            >
              Role
            </p>
          ),
          dataIndex: "role",
          key: "8",
          render: (data, record) => {
            return (
              <p
                className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
              >
                {data}
              </p>
            );
          },
        }
      : {},
    {
      title: (
        <p
          className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove} `}
        >
          Status
        </p>
      ),
      key: "9",
      dataIndex: "status",
      render: (text, render) => (
        <>
          {
            // props.onProfile &&
            // props.onProfile.roleType &&
            props.taskStatus &&
            props.permissions &&
            // props.onProfile.roleType !== "Recruiter" &&
            props.permissions.find((data) => data === "Task-status-update") ? (
              <TaskAndInterviewStatus
                text={text}
                statusData={statusData}
                onStatusChange={(val) => {
                  props.getCheckPermissionHandler(
                    "Task-status-update",
                    (success) => {
                      if (success) {
                        props.statusData({
                          id: render.id,
                          status: val,
                          current_page:
                            props.taskData && props.taskData.current_page
                              ? props.taskData.current_page
                              : 1,
                        });
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Task Status Update",
                        });
                      }
                    }
                  );
                }}
              />
            ) : // props.onProfile &&
            // props.onProfile.roleType &&
            !props.taskStatus &&
              props.permissions &&
              // props.onProfile.roleType !== "Recruiter" &&
              props.permissions.find(
                (data) => data === "interview-status-update"
              ) ? (
              <TaskAndInterviewStatus
                text={text}
                statusData={interviewStatusData}
                onStatusChange={(val) => {
                  props.getCheckPermissionHandler(
                    "interview-status-update",
                    (success) => {
                      if (success) {
                        props.statusData({
                          id: render.id,
                          status: val,
                          current_page:
                            props.taskData && props.taskData.current_page
                              ? props.taskData.current_page
                              : 1,
                        });
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Interview Status Update",
                        });
                      }
                    }
                  );
                }}
              />
            ) : (
              <p
                className={`${commonCssStyle.marginBottomRemove}`}
                style={{
                  color:
                    text === "pending" || text === "Pending"
                      ? "#FF9A02"
                      : "#044123",
                }}
              >
                {text.charAt(0).toUpperCase() + text.slice(1)}
              </p>
            )
          }
        </>
      ),
    },
    {
      key: "action",
      render: (_, record) => (
        <>
          {props.onProfile &&
          props.onProfile.roleType &&
          props.onProfile.roleType !== "Recruiter" ? (
            <Dropdown
              placement="bottomRight"
              overlay={
                <Menu
                  style={{ width: "180px" }}
                  items={
                    props.taskStatus
                      ? [
                          props.permissions &&
                            props.permissions.find(
                              (data) => data === "Task-edit"
                            ) && {
                              key: "1",
                              label: (
                                <Row
                                  onClick={() => {
                                    props.getCheckPermissionHandler(
                                      "Task-edit",
                                      (success) => {
                                        if (success) {
                                          props.editData({
                                            id: record.id,
                                            modal: "edit",
                                          });
                                        } else {
                                          setOpenPMModal({
                                            open: true,
                                            permission: "Task Edit",
                                          });
                                        }
                                      }
                                    );
                                  }}
                                  onMouseEnter={() => {
                                    setIconClr("#2F7BF5");
                                  }}
                                  onMouseLeave={() => {
                                    setIconClr("var(--headings-color)");
                                  }}
                                >
                                  <Col style={{ padding: "5px 5px 0px 0px" }}>
                                    <EditIcon color={iconClr} />
                                  </Col>
                                  <Col style={{ padding: "5px 5px 0px 5px" }}>
                                    <p
                                      style={{
                                        marginBottom: "0px",
                                        color: iconClr,
                                      }}
                                    >
                                      Edit
                                    </p>
                                  </Col>
                                </Row>
                              ),
                            },

                          props.permissions &&
                            props.permissions.find(
                              (data) => data === "Task-delete"
                            ) && {
                              key: "2",
                              label: (
                                <Row
                                  onClick={() => {
                                    props.getCheckPermissionHandler(
                                      "Task-delete",
                                      (success) => {
                                        if (success) {
                                          setIsModalOpen(true);
                                          setDeletId(record.id);
                                        } else {
                                          setOpenPMModal({
                                            open: true,
                                            permission: "Task Delete",
                                          });
                                        }
                                      }
                                    );
                                  }}
                                  onMouseEnter={() => {
                                    setDeleteBtnClr("#ff4d4f");
                                  }}
                                  onMouseLeave={() => {
                                    setDeleteBtnClr("var(--headings-color)");
                                  }}
                                >
                                  <Col style={{ padding: "5px 5px 0px 0px" }}>
                                    <TrashIcon color={deleteBtnClr} />
                                  </Col>
                                  <Col
                                    style={{
                                      padding: "5px 5px 0px 5px",
                                      color: deleteBtnClr,
                                    }}
                                  >
                                    <p
                                      className={
                                        commonCssStyle.marginBottomRemove
                                      }
                                    >
                                      Delete
                                    </p>
                                  </Col>
                                </Row>
                              ),
                            },
                        ]
                      : [
                          props.permissions &&
                            props.permissions.find(
                              (data) => data === "interview-edit"
                            ) && {
                              key: "1",
                              label: (
                                <Row
                                  onClick={() => {
                                    props.getCheckPermissionHandler(
                                      "interview-edit",
                                      (success) => {
                                        if (success) {
                                          props.editData({
                                            id: record.id,
                                            modal: "edit",
                                          });
                                        } else {
                                          setOpenPMModal({
                                            open: true,
                                            permission: "Interview Edit",
                                          });
                                        }
                                      }
                                    );
                                  }}
                                  onMouseEnter={() => {
                                    setIconClr("1");
                                  }}
                                  onMouseLeave={() => {
                                    setIconClr("0");
                                  }}
                                >
                                  <Col style={{ padding: "5px 5px 0px 0px" }}>
                                    <EditIcon
                                      color={
                                        iconClr == 1
                                          ? "#2F7BF5"
                                          : "var(--headings-color)"
                                      }
                                    />
                                  </Col>
                                  <Col style={{ padding: "5px 5px 0px 5px" }}>
                                    <p
                                      style={{
                                        marginBottom: "0px",
                                        color:
                                          iconClr == 1
                                            ? "#2F7BF5"
                                            : "var(--headings-color)",
                                      }}
                                    >
                                      Edit
                                    </p>
                                  </Col>
                                </Row>
                              ),
                            },
                          record.rescheduleRequest == 1 && {
                            key: "3",
                            label: (
                              <Row
                                onClick={() => {
                                  setInterviewId(record.id);
                                  props.getInterviewDetailsHandler(
                                    record.id,
                                    (success) => {
                                      if (success) {
                                        setRsOpen(true);
                                      }
                                    }
                                  );
                                }}
                                onMouseEnter={() => {
                                  setIconClr("3");
                                }}
                                onMouseLeave={() => {
                                  setIconClr("0");
                                }}
                              >
                                <Col style={{ padding: "5px 5px 0px 0px" }}>
                                  <EditIcon
                                    color={
                                      iconClr == 3
                                        ? "#2F7BF5"
                                        : "var(--headings-color)"
                                    }
                                  />
                                </Col>
                                <Col style={{ padding: "5px 5px 0px 5px" }}>
                                  <p
                                    style={{
                                      marginBottom: "0px",
                                      color:
                                        iconClr == 3
                                          ? "#2F7BF5"
                                          : "var(--headings-color)",
                                    }}
                                  >
                                    Reschedule
                                  </p>
                                </Col>
                              </Row>
                            ),
                          },
                          props.permissions &&
                            props.permissions.find(
                              (data) => data === "interview-delete"
                            ) && {
                              key: "2",
                              label: (
                                <Row
                                  onClick={() => {
                                    props.getCheckPermissionHandler(
                                      "interview-delete",
                                      (success) => {
                                        if (success) {
                                          setIsModalOpen(true);
                                          setDeletId(record.id);
                                        } else {
                                          setOpenPMModal({
                                            open: true,
                                            permission: "Interview Edit",
                                          });
                                        }
                                      }
                                    );
                                  }}
                                  onMouseEnter={() => {
                                    setDeleteBtnClr("#ff4d4f");
                                  }}
                                  onMouseLeave={() => {
                                    setDeleteBtnClr("var(--headings-color)");
                                  }}
                                >
                                  <Col style={{ padding: "5px 5px 0px 0px" }}>
                                    <TrashIcon color={deleteBtnClr} />
                                  </Col>
                                  <Col
                                    style={{
                                      padding: "5px 5px 0px 5px",
                                      color: deleteBtnClr,
                                    }}
                                  >
                                    <p
                                      className={
                                        commonCssStyle.marginBottomRemove
                                      }
                                    >
                                      Delete
                                    </p>
                                  </Col>
                                </Row>
                              ),
                            },
                        ]
                  }
                />
              }
            >
              <span className={commonCssStyle.coursorPointer}>
                <Space size="middle">
                  <ThreeDotsIcon color={"var(--table-text-color)"} />
                </Space>
              </span>
            </Dropdown>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        scroll={{ x: "auto" }}
        columns={columns}
        dataSource={
          props.taskData && props.taskData.data && props.taskData.data
        }
        // showSizeChanger={false}
        pagination={{
          size: "small",
          current: props.taskData && props.taskData.current_page,
          pageSize: props.taskData && props.taskData.per_page,
          total: props.taskData && props.taskData.total,
          showSizeChanger: false,
          onChange: (props) => {
            fetchRecords(props);
          },
        }}
      />
      {/* modal start */}
      <Modal
        closable={false}
        style={{
          width: "40%",
          height: "189px",
        }}
        title={
          <Row /* style={{gap:"285px"}} */>
            <Col md={20}>
              <h3 className={commonCssStyle.popUpHeadingText}>
                {props.taskStatus ? "Delete Task" : "Delete Interview"}
              </h3>
            </Col>
            <Col md={4}>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  setIsModalOpen(false);
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--icons-color)"} />
              </div>
            </Col>
          </Row>
        }
        footer={
          <>
            <Button
              className={commonCssStyle.buttonSettingsSecondary}
              type="default"
              style={{
                width: "177px",
                height: "45px",
              }}
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className={commonCssStyle.buttonSettingsPrimary}
              onClick={() => {
                handleDelete();
              }}
              type="primary"
              style={{
                width: "177px",
                height: "45px",
              }}
            >
              Delete
            </Button>
          </>
        }
        open={isModalOpen}
      >
        <p
          className={`${commonCssStyle.deleteConfiTextColor} ${commonCssStyle.marginBottomRemove}`}
        >
          Are you sure you want to delete this{" "}
          {props.taskStatus ? "Task" : "Interview"}
        </p>
      </Modal>
      {rsOpen && (
        <RescheduleInterview
          onInterviewId={interviewId}
          pageFrom={"taskAndCalendar"}
          rsOpen={rsOpen}
          onInterviewDetails={props.interviewDetailsData}
          candidateJobs={props.candidatesJob}
          onRsOpen={() => {
            setRsOpen(false);
          }}
        />
      )}
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    interviewDetailsData: state.recruitment.interview_details,
    permissions: state.authpermissions.permissions,
    candidatesJob: state.taskAndCalendar.candidatesJob,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
    getInterviewDetailsHandler: (id, callback) => {
      dispatch(getInterViewAction(id, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskListView);
/* text === "pending" ? (
            <p
              className={`${commonCssStyle.marginBottomRemove}`}
              style={{ color: text === "pending" ? "#FF9A02" : text === "compeleted" ? "#044123" : text === "Rescheduled by Candidate" ? "#044123" : text === "Rescheduled by Client" ? "#044123" ? text === "Candidate No Show" ? "#044123" : "#044123" }}
            >
              {text.charAt(0).toUpperCase() + text.slice(1)}
            </p>
          ) : text === "compeleted" ? (
            <p
              className={`${commonCssStyle.marginBottomRemove}`}
              style={{ color: "#044123" }}
            >
              {text.charAt(0).toUpperCase() + text.slice(1)}
            </p>
          ) : text === "Rescheduled by Candidate" ? (
            <p
              className={`${commonCssStyle.marginBottomRemove}`}
              style={{ color: "#044123" }}
            >
              {text.charAt(0).toUpperCase() + text.slice(1)}
            </p>
          ) : text === "Rescheduled by Client" ? (
            <p
              className={`${commonCssStyle.marginBottomRemove}`}
              style={{ color: "#044123" }}
            >
              {text.charAt(0).toUpperCase() + text.slice(1)}
            </p>
          ) : (
            text === "Candidate No Show" && (
              <p
                className={`${commonCssStyle.marginBottomRemove}`}
                style={{ color: "#044123" }}
              >
                {text.charAt(0).toUpperCase() + text.slice(1)}
              </p>
            ) */
