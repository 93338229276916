import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import commonStyleCss from "../../../UI/CommonCss.module.css";
import styles from "../../../Screens/ClientCompany/ClientCompany.module.css";
import {
  CaretBlackDownIcon,
  Featured,
  Filedownload,
  FilterOutlinedIcon,
  Hot,
  NewIcon,
  PlusIcon,
  Premium,
  TrashIcon,
  Trending,
} from "../../../IconsComp/Icons";
import { Link, useLocation } from "react-router-dom";
import {
  getReportsJobCandidatesAction,
  exportReportsJobCandidatesDetaisAction,
} from "../../../../Reusable/Store/Action/ReportsAction";
import { connect } from "react-redux";
import moment from "moment";
import ReportsCompanyDetailFilter from "./ReportsCompanyDetailFilter";
import ReportsJobDetailFilter from "./ReportsJobDetailFilter";
import ReportsJobCandidatesFilter from "./ReportsJobCandidatesFilter";
import { useForm } from "antd/lib/form/Form";

function ReportsJobCandidates(props) {
  const [cfilter, setCFilter] = useState({});
  const [form] = useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  const [fromDate, setFromDate] = useState("1970-01-01");
  const [toDate, setToDate] = useState("3000-01-01");

  const location = useLocation();
  const [menuKey, setMenuKey] = useState("1");
  useEffect(() => {
    // props.getJobCandidatesReportsHandler({
    //   current_page: 1,
    //   jobId:
    //     location &&
    //     location.state &&
    //     location.state.jobId &&
    //     location.state.jobId,
    // });
    setCFilter({
      current_page: 1,
      jobId:
        location &&
        location.state &&
        location.state.jobId &&
        location.state.jobId,
    });
  }, [location]);

  

  useEffect(()=>{
    const obj = JSON.parse(localStorage.getItem("reportsjobcandidatefilterdata"));
    if(obj){
      props.getJobCandidatesReportsHandler(obj);
    }else{
      props.getJobCandidatesReportsHandler({ 
          current_page: 1,
          jobId:
            location &&
            location.state &&
            location.state.jobId &&
            location.state.jobId,});
   localStorage.setItem("reportsjobcandidatefilterdata",JSON.stringify({
              current_page: 1,
              jobId:
              location &&
            location.state &&
            location.state.jobId &&
            location.state.jobId,
            })); 
    }
  },[])

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys, ...newSelectedRowKeys]);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const fetchRecords = (page) => {

    let repjbcanLocal = JSON.parse(localStorage.getItem("reportsjobcandidatefilterdata"));
if(repjbcanLocal){
  const val = {
    ...cfilter,
    ...repjbcanLocal,
    ...props.candidatesFilterData,
    current_page: page,
  };
  localStorage.setItem("reportsjobcandidatefilterdata",JSON.stringify({...repjbcanLocal,current_page:page}))
  props.getJobCandidatesReportsHandler(val);
}else{
  const val = {
    ...cfilter,
    ...props.candidatesFilterData,
    current_page: page,
  };
  localStorage.setItem("reportsjobcandidatefilterdata",JSON.stringify({current_page:page}))
  props.getJobCandidatesReportsHandler(val);
}
   
  };

  function showFilterHandler() {
    setOpenFilter(true);
  }
  const closeFilterHandler = () => {
    setOpenFilter(false);
  };

  const reportsJobFilterHandleer = (val) => {
    setCFilter(val);
    const data = {
      ...val,
      current_page: 1,
      type: "list",
    }
    props.getJobCandidatesReportsHandler(data);
    localStorage.setItem("reportsjobcandidatefilterdata",JSON.stringify(data))

  };

  const menu2 = (
    <Menu
      style={{ width: "151px", margin: "0px 45px" }}
      selectable
      defaultSelectedKeys={["1"]}
      items={[
        {
          key: "3",
          label: (
            <p
              style={{ marginTop: "7px" }}
              className={`${commonStyleCss.externalJobTextrole}`}
              onClick={() => {
                const data = {
                  current_page: 1,
                  ...cfilter,
                  dateFrom: fromDate,
                  dateTo: toDate,
                  type: "export",
                  exportType: "excel",
                };
                props.expectJobCandidatesReportsHandler(data);
              }}
            >
              Export to Excel
            </p>
          ),
        },
        {
          key: "4",
          label: (
            <p
              style={{ marginTop: "7px" }}
              className={`${commonStyleCss.externalJobTextrole}`}
              onClick={() => {
                const data = {
                  current_page: 1,
                  ...cfilter,
                  dateFrom: fromDate,
                  dateTo: toDate,
                  type: "export",
                  exportType: "pdf",
                };
                props.expectJobCandidatesReportsHandler(data);
              }}
            >
              Export to PDF
            </p>
          ),
        },
      ]}
    />
  );

  const columns = [
    {
      title: (
        <p
          // style={{ textAlign: "center" }}
          className={styles.LabelName}
        >
          Name
        </p>
      ),
      dataIndex: "candidateName",
      render: (text, render) => (
        <p
          style={{
            color: "var(--primaryColor)",
            fontFamily: "Helvetica,ProductSans",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "17px",
            margin: "0px",
            // cursor: "pointer",
          }}
        >
          {text ? text : "NA"}
        </p>
      ),
    },
    {
      title: (
        <p style={{ textAlign: "center" }} className={styles.LabelName}>
          Recruiter
        </p>
      ),
      dataIndex: "teamMember",
      render: (text, data) => {
        return (
          <p
            style={{ textAlign: "center" }}
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {text ? text : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ textAlign: "center" }} className={styles.LabelName}>
          Resume Link
        </p>
      ),
      dataIndex: "resume",
      render: (text, render) => (
        <>
          <Row style={{ justifyContent: "center" }}>
            {text && (
              <span
                className={commonStyleCss.coursorPointer}
                onClick={() => {
                  window.open(render.resume, "_blank");
                }}
              >
                <Filedownload color={"var(--primaryColor)"} />
              </span>
            )}
          </Row>
        </>
      ),
    },
    {
      title: (
        <p style={{ textAlign: "center" }} className={styles.LabelName}>
          Active Applied Job Count
        </p>
      ),
      dataIndex: "activeJobCount",
      render: (text) => {
        return (
          <p
            style={{ textAlign: "center" }}
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {text ? text : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ textAlign: "center" }} className={styles.LabelName}>
          Offer Count
        </p>
      ),
      dataIndex: "offerJobCount",
      render: (text) => (
        <p
          style={{ textAlign: "center" }}
          className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
        >
          {text ? text : "NA"}
        </p>
      ),
    },

    {
      title: <p className={styles.LabelName}>Current Stage</p>,
      dataIndex: "currentStageNumber",
      render: (text, render) => {
        return (
          <>
            {/* <Steps number={text} /> */}
            <Row>
              <Col>
                <Row>
                  <Col>
                    <p className={`${commonStyleCss.tableData}`}>
                      {render.currentStageType}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className={`${commonStyleCss.tableData}`}>
                      {render.currentStage}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        );
      },
    },

    {
      title: (
        <p style={{ textAlign: "center" }} className={styles.LabelName}>
          Tentative Joining Date
        </p>
      ),
      dataIndex: "joiningDate",
      render: (text, data) => {
        return (
          <p
            style={{ textAlign: "center" }}
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {(text && text == undefined) ||
            text == null ||
            text == "" ||
            text == "Invalid date"
              ? "N/A"
              : moment(text).format(dataFormate)}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ textAlign: "center" }} className={styles.LabelName}>
          CTC Offered (in lakhs)
        </p>
      ),
      dataIndex: "ctcOffered",
      render: (text, data) => {
        return (
          <p
            style={{ textAlign: "center" }}
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {text ? text : "NA"}
          </p>
        );
      },
    },
  ];
  const handleProvinceChange = (value,d) => {
    localStorage.setItem("reportsjobcandidatefilterdataSort",JSON.stringify(d.value))
    let repjbcanSrtLocal = JSON.parse(localStorage.getItem("reportsjobcandidatefilterdata"));
    let sort = value.split("_");

    if(repjbcanSrtLocal){
      props.getJobCandidatesReportsHandler({
        ...cfilter,
        ...repjbcanSrtLocal,
        type: "list",
        sortBy: sort[0],
        sortOrder: sort[1],
        current_page: 1,
      });
      setCFilter({
        ...cfilter,
        ...repjbcanSrtLocal,
        type: "list",
        sortBy: sort[0],
        sortOrder: sort[1],
      });
      localStorage.setItem("reportsjobcandidatefilterdata",JSON.stringify({...repjbcanSrtLocal, sortBy: sort[0],
        sortOrder: sort[1]}))
    }else{
      props.getJobCandidatesReportsHandler({
        ...cfilter,
        type: "list",
        sortBy: sort[0],
        sortOrder: sort[1],
        current_page: 1,
      });
      setCFilter({
        ...cfilter,
        type: "list",
        sortBy: sort[0],
        sortOrder: sort[1],
      });

      localStorage.setItem("reportsjobcandidatefilterdata",JSON.stringify({ sortBy: sort[0],
        sortOrder: sort[1]}))
    }


  };

  return (
    <>
      <Breadcrumb className={commonStyleCss.breadcrumbBottomMargin}>
        <Breadcrumb.Item>
          <Link to={"/"}>
            <p
             onClick={()=>{
              localStorage.removeItem("reportsjobcandidatefilterdata");
              localStorage.removeItem("reportsjobcandidatefilterdataSort");
            }}
              className={`${commonStyleCss.coursorPointer} ${commonStyleCss.marginBottomRemove} ${commonStyleCss.breadcrumbText}`}
            >
              Home
            </p>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to={"/reports/companydetailtable"}
            state={{ Companyname: location.state.Companyname }}
          >
            <p
             onClick={()=>{
              localStorage.removeItem("reportsjobcandidatefilterdata");
              localStorage.removeItem("reportsjobcandidatefilterdataSort");
            }}
              className={`${commonStyleCss.coursorPointer} ${commonStyleCss.marginBottomRemove} ${commonStyleCss.breadcrumbText}`}
            >
              Company &nbsp;(&nbsp;{location.state.Companyname}&nbsp;)
            </p>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to={"/reports/jobdetailtable"}
            state={{
              id: location.state.backButtonId,
              Companyname: location.state.Companyname,
              jobName: location.state.jobName,
            }}
          >
            <p
            onClick={()=>{
              localStorage.removeItem("reportsjobcandidatefilterdata");
            }}
              className={`${commonStyleCss.coursorPointer} ${commonStyleCss.marginBottomRemove} ${commonStyleCss.breadcrumbText}`}
            >
              Job &nbsp;(&nbsp;{location.state.jobName}&nbsp;)
            </p>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/reports/job/candidates"} state={location.state}>
            <p
              className={`${commonStyleCss.coursorPointer} ${commonStyleCss.marginBottomRemove} ${commonStyleCss.breadcrumbText}`}
            >
              Candidates
            </p>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col md={24}>
          <Row align="middle" justify="space-between">
            <Col>
              <Row>
                <Col /* xs={24} sm={24} md={24} lg={6} */>
                  <h1
                    style={{ marginRight: "31px" }}
                    className={`${commonStyleCss.mainHeadingText} ${commonStyleCss.inttitle}`}
                  >
                    Candidates Report &#40;{" "}
                    {props.jobCandidatesReport &&
                    props.jobCandidatesReport.total
                      ? props.jobCandidatesReport.total
                      : "0"}{" "}
                    &#41;
                  </h1>
                </Col>

                <Col /* xs={24} sm={24} md={24} lg={2} */>
                  <Button
                    onClick={showFilterHandler}
                    className={`${commonStyleCss.filterButton}`}
                    id="candidates-table-filter-btn"
                  >
                    <Row style={{ gap: "11px" }}>
                      <Col>
                        <div style={{ transform: "translateY(2px)" }}>
                          <FilterOutlinedIcon color={"var(--primaryColor)"} />
                        </div>
                      </Col>
                      <Col>
                        <p
                          className={`${commonStyleCss.filterAndSortTextStyle} ${commonStyleCss.marginBottomRemove}`}
                        >
                          Filter
                        </p>
                      </Col>
                    </Row>
                  </Button>
                </Col>

                <Col /* xs={24} sm={24} md={24} lg={6} */>
                  <Button
                    style={{
                      height: "40px",
                      marginRight: "20px",
                    }}
                    className={` ${commonStyleCss.sortBtn}`}
                    id="candidates-table-sortby-btn"
                  >
                    <Row justify="space-between">
                      <Col>
                        <p style={{ transform: "translateY(2px)" }}>Sort By</p>
                      </Col>
                      <Col>
                        <Select
  value={JSON.parse(localStorage.getItem("reportsjobcandidatefilterdataSort"))
  ?
  JSON.parse(localStorage.getItem("reportsjobcandidatefilterdataSort")):"Select"
}

                          getPopupContainer={(trigger) => trigger.parentElement}
                          style={{
                            width: "100%",
                            // transform: "translateY(3px)",
                          }}
                          onChange={handleProvinceChange}
                          bordered={false}
                          placeholder={
                            <p
                              style={{
                                margin: "0px",
                                color: "var(--primaryColor)",
                              }}
                            >
                              Select
                            </p>
                          }
                          size="small"
                          className={`${commonStyleCss.filterDrop}  selectInput sortBySelectStyle selectTag selectTag1`}
                        >
                          <Select.Option
                            value="clientName_ASC"
                            key="0"
                            style={{ padding: "10px" }}
                          >
                            Client Name Ascending
                          </Select.Option>
                          <Select.Option
                            value="clientName_DESC"
                            key="1"
                            style={{ padding: "10px" }}
                          >
                            Client Name Descending
                          </Select.Option>
                          <Select.Option
                            value="lastUpdated_ASC"
                            key="2"
                            style={{ padding: "10px" }}
                          >
                            Last Updated Ascending
                          </Select.Option>
                          <Select.Option
                            value="lastUpdated_DESC"
                            key="3"
                            style={{ padding: "10px" }}
                          >
                            Last Updated Descending
                          </Select.Option>
                        </Select>
                      </Col>
                    </Row>
                  </Button>
                </Col>

                <Col /* xs={24} sm={24} md={24} lg={3} */>
                  <Form form={form}>
                    <Row justify="space-between">
                      <Col md={11}>
                        <Form.Item className="candidateReportForm" name="from">
                          <DatePicker
                            name="from"
                            format={dataFormate}
                            placeholder="from"
                            style={{ width: "115px", height: "40px" }}
                            className="ReportsFrom"
                            suffixIcon={null}
                            onChange={(val) => {
                              if (val !== null) {
                                props.getJobCandidatesReportsHandler({
                                  ...cfilter,
                                  current_page: 1,
                                  type: "list",
                                  dateFrom: moment(val).format("YYYY-MM-DD"),
                                  dateTo: toDate,
                                });
                                setFromDate(moment(val).format("YYYY-MM-DD"));
                              } else {
                                props.getJobCandidatesReportsHandler({
                                  ...cfilter,
                                  type: "list",
                                  current_page: 1,
                                  dateFrom: "1970-01-01",
                                  dateTo: toDate,
                                });
                                setFromDate("1970-01-01");
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={11}>
                        <Form.Item className="candidateReportForm" name="to">
                          <DatePicker
                            onChange={(val) => {
                              if (val !== null) {
                                props.getJobCandidatesReportsHandler({
                                  ...cfilter,
                                  current_page: 1,
                                  type: "list",
                                  dateFrom: fromDate,
                                  dateTo: moment(val).format("YYYY-MM-DD"),
                                });
                                setToDate(moment(val).format("YYYY-MM-DD"));
                              } else {
                                props.getJobCandidatesReportsHandler({
                                  ...cfilter,
                                  type: "list",
                                  current_page: 1,
                                  dateTo: "3000-01-01",
                                  dateFrom: fromDate,
                                });
                                setToDate("3000-01-01");
                              }
                            }}
                            format={dataFormate}
                            placeholder="to"
                            style={{ width: "115px", height: "40px" }}
                            className="ReportsFrom"
                            suffixIcon={null}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col /* xs={24} sm={24} md={24} lg={4} */>
              <Row>
                <Dropdown placement="bottom" overlay={menu2}>
                  <Typography.Link>
                    <Space className={commonStyleCss.tableHeadingTextData}>
                      <Button
                        style={{ width: "151px" }}
                        className={commonStyleCss.intaddButton}
                        type="primary"
                      >
                        <Row align="middle" justify="space-around">
                          <Col>
                            <PlusIcon color={"white"} />
                          </Col>
                          <Col>Export</Col>
                          <Col>
                            <CaretBlackDownIcon color={"white"} />
                          </Col>
                        </Row>
                      </Button>
                    </Space>
                  </Typography.Link>
                </Dropdown>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={24} style={{ marginTop: "40px" }}>
          <Table
            className="ReportsTablescroll"
            scroll={{ x: "auto" }}
            pagination={{
              size: "small",
              current:
                props.jobCandidatesReport &&
                props.jobCandidatesReport.current_page,
              pageSize:
                props.jobCandidatesReport && props.jobCandidatesReport.per_page,
              total:
                props.jobCandidatesReport && props.jobCandidatesReport.total,
              showSizeChanger: false,
              onChange: (props) => {
                fetchRecords(props);
              },
            }}
            columns={columns}
            dataSource={
              props.jobCandidatesReport &&
              props.jobCandidatesReport.data &&
              props.jobCandidatesReport.data
            }
          />
        </Col>
      </Row>
      <ReportsJobCandidatesFilter
        onOpenFilter={openFilter}
        onCloseFilter={closeFilterHandler}
        reportsJobFilterHandleer={reportsJobFilterHandleer}
        initialValuesJobId={
          location &&
          location.state &&
          location.state.jobId &&
          location.state.jobId
        }
        fromDate={fromDate}
        toDate={toDate}
        onRestFilter={() => {
          form.resetFields();
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    settindsData: state.settings.settings,
    jobCandidatesReport: state.reports.reports_job_candidates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobCandidatesReportsHandler: (val, callback) => {
      dispatch(getReportsJobCandidatesAction(val, callback));
    },
    expectJobCandidatesReportsHandler: (val) => {
      dispatch(exportReportsJobCandidatesDetaisAction(val));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsJobCandidates);
