import { getDashboardSlice } from "../Slice/DashboardSlice";
import { message } from "antd";
import AxiosApi from "../api";
import { DASHBOARD_GET_API } from "../../Store/apipath";

const api = new AxiosApi();

export const getDashboardAction = () => {
  return (dispatch) => {
    api.invoke(DASHBOARD_GET_API, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) {
          dispatch(getDashboardSlice(data));
        } else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
