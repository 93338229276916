import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  globalSearch: [],
  gsDataStatus: false,
};

export const globalSearchSlice = createSlice({
  name: "globalSearch",
  initialState,
  reducers: {
    getGlobalSearchSlice(state, action) {
      state.globalSearch = action.payload.result;
    },
    getGSDataStatusSlice(state, action) {
      state.gsDataStatus = action.payload;
    },
  },
});

export const { getGlobalSearchSlice, getGSDataStatusSlice } =
  globalSearchSlice.actions;
export default globalSearchSlice.reducer;
