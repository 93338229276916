import {
  getNotificationSlice,
  putNotificationSlice,
} from "../Slice/NotificationSlice";
import {
  NOTIFICATION_GET_API,
  NOTIFICATION_PUT_API,
  NOTIFICATION_MARK_READ,
  NOTIFICATION_STORE_TOKEN,
} from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";

const api = new AxiosApi();

export const getNotificationsAction = () => {
  return (dispatch) => {
    api.invoke(NOTIFICATION_GET_API, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) {
          dispatch(getNotificationSlice(data));
        } else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};

export const putNotificationAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      // NOTIFICATION_MARK_READ + "/" + data.id,
      NOTIFICATION_GET_API + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(putNotificationSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

export const getNotificationMarkZeroAction = () => {
  return (dispatch) => {
    api.invoke(NOTIFICATION_MARK_READ, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) {
          // dispatch(getNotificationSlice(data));
          dispatch(getNotificationsAction());
        } else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};

export const putNotificationStoreTokenAction = (data) => {
  return (dispatch) => {
    api.invoke(
      NOTIFICATION_STORE_TOKEN,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
