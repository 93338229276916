import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  roles: [],
  role: null,
};

export const masterRolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    getRolesSlice(state, action) {
      state.roles = action.payload.result;
    },
    getRoleSlice(state, action) {
      state.role = action.payload.result;
    },
    postRolesSlice(state, action) {
      state.roles = [...state.roles, action.payload.result];
    },
    putRolesSlice(state, action) {
      state.roles = state.roles.map((data) => {
        if (data.id === action.payload.result.id) {
          return action.payload.result;
        } else {
          return data;
        }
      });
    },
    deleteRolesSlice(state, action) {
      state.roles = state.roles.filter((data) => data.id !== action.payload);
    },
  },
});
export const {
  getRolesSlice,
  getRoleSlice,
  postRolesSlice,
  putRolesSlice,
  deleteRolesSlice,
} = masterRolesSlice.actions;
export default masterRolesSlice.reducer;
