import { Button, Card, Checkbox, Col, Form, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { profileAction } from "../../../Reusable/Store/Action/AuthenticationAction";
import {
  getEmployee_permissionAction,
  postEmployee_permissionAction,
} from "../../../Reusable/Store/Action/EmployeesAction";
import commonCssStyle from "../../UI/CommonCss.module.css";
function EmployeeRandP(props) {
  const [form] = useForm();
  const [randPAll, setRandPAll] = useState([]);
  const [randP, setRandP] = useState([]);
  const [randP1, setRandP1] = useState([]);
  const [randP2, setRandP2] = useState([]);
  const [randP3, setRandP3] = useState([]);
  const [randP4, setRandP4] = useState([]);
  const [randP5, setRandP5] = useState([]);
  const [randP6, setRandP6] = useState([]);
  const [randP7, setRandP7] = useState([]);
  const [randP8, setRandP8] = useState([]);
  const [randP9, setRandP9] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  // const [totalLength, setTotalLength] = useState(0);

  // useEffect(() => {
  //   let unsubscribed = false;
  //   if (!unsubscribed) {
  //     if (totalLength === 87) {
  //       setCheckedAll(true);
  //     } else {
  //       setCheckedAll(false);
  //     }
  //   }
  //   return () => {
  //     unsubscribed = true;
  //   };
  // }, [totalLength]);

  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      if (props.employee && props.employee.permissions) {
        if (props.employee.permissions.length === 87) {
          setCheckedAll(true);
        }
        //dashboard
        let dashboard = props.employee.permissions.filter(
          (data) => data === "dashboard"
        );
        setRandP(dashboard);
        //candidate
        let candidate = props.employee.permissions.filter(
          (data) =>
            data === "candidate-list" ||
            data === "candidate-add" ||
            data === "candidate-edit" ||
            data === "candidate-delete" ||
            data === "candidate-detail" ||
            data === "candidate-mail-list" ||
            data === "candidate-mail-add" ||
            data === "candidate-timeline" ||
            data === "candidate-task-list" ||
            data === "candidate-download-resume" ||
            data === "candidate-download-recording" ||
            data === "candidate-export" ||
            data === "candidate-call" ||
            data === "candidate-interview-list"
        );
        setRandP1(candidate);
        //recuritment
        let recuritment = props.employee.permissions.filter(
          (data) =>
            data === "recruitment-list" ||
            data === "recruitment-detail" ||
            data === "recruitment-add-candidate" ||
            data === "recruitment-candidate-pool" ||
            data === "recruitment-candidate-detail" ||
            data === "recruitment-candidate-status update" ||
            data === "recruitment-candidate-mail-list" ||
            data === "recruitment-candidate-mail-add" ||
            data === "recruitment-candidate-timeline" ||
            data === "recruitment-candidate-task-list" ||
            data === "recruitment-candidate-download-resume" ||
            data === "recruitment-candidate-download-recording" ||
            data === "recruitment-candidate-call" ||
            data === "recruitment-candidate-interview-list" ||
            data === "recruitment-candidate-offerLetter" ||
            data === "recruitment-delete-candidate"
          // data === "recruitment-candidate-jobs"
        );
        setRandP2(recuritment);
        //requiremtn
        let requirement = props.employee.permissions.filter(
          (data) =>
            data === "job-list" ||
            data === "job-add" ||
            data === "job-delete" ||
            data === "job-assign-employee" ||
            data === "job-task-list" ||
            data === "job-edit" ||
            data === "job-detail"
        );
        setRandP3(requirement);
        //company
        let company = props.employee.permissions.filter(
          (data) =>
            data === "client-list" ||
            data === "client-add" ||
            data === "client-edit" ||
            data === "client-delete" ||
            data === "client-detail" ||
            data === "client-job" ||
            data === "client-task-list" ||
            data === "client-contact-info" ||
            data === "client-billing-detail"
        );
        setRandP4(company);
        //employee
        let employee = props.employee.permissions.filter(
          (data) =>
            data === "employees-list" ||
            data === "employees-add" ||
            data === "employees-edit" ||
            data === "employees-delete" ||
            data === "employees-detail" ||
            data === "employees-assign-role" ||
            data === "employees-assign-permission" ||
            data === "employees-timeline" ||
            data === "employees-task-list" ||
            data === "employee-download-recording" ||
            data === "employee-call" ||
            data === "employee-job-list"
        );
        setRandP5(employee);
        // task
        let task = props.employee.permissions.filter(
          (data) =>
            data === "Task-add" ||
            data === "Task-edit" ||
            data === "Task-delete" ||
            data === "Task-detail" ||
            data === "Task-list-view" ||
            data === "Task-calendar-view" ||
            data === "Task-status-update"
        );
        setRandP6(task);
        //settings
        let settings = props.employee.permissions.filter(
          (data) =>
            data === "settings-system" ||
            data === "settings-organisations" ||
            data === "settings-billing" ||
            data === "settings-roles-list" ||
            data === "settings-roles-add" ||
            data === "settings-roles-edit" ||
            data === "settings-roles-delete" ||
            data === "settings-application-emailTemplate" ||
            data === "settings-application-leadSource" ||
            data === "settings-application-candidateReason" ||
            data === "settings-application-jobListing" ||
            data === "settings-application-jobDescription" ||
            data === "settings-application-sharingMEdium" ||
            data === "settings-application-department" ||
            data === "settings-application-jobPrefix" ||
            data === "settings-import" ||
            data === "settings-application-tags"
        );
        setRandP7(settings);
        //interview
        let interview = props.employee.permissions.filter(
          (data) =>
            data === "interview-add" ||
            data === "interview-edit" ||
            data === "interview-detail" ||
            data === "interview-delete" ||
            data === "interview-status-update"
        );
        setRandP8(interview);
        //report
        let report = props.employee.permissions.filter(
          (data) => data === "report"
        );
        setRandP9(report);
      }
    }
    return () => {
      unsubscribed = true;
    };
  }, [props.employee]);

  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      props.getEmployeePermissionHandler(props.id);
    }
    return () => {
      unsubscribed = true;
    };
  }, []);

  //dashboard
  const dashboardArray = [{ label: "Dashboard", value: "dashboard" }];
  // candidate
  const candidateArray = [
    {
      label: "List",
      value: "candidate-list",
    },
    {
      label: "Add",
      value: "candidate-add",
    },
    {
      label: "Edit",
      value: "candidate-edit",
    },
    {
      label: "Delete",
      value: "candidate-delete",
    },
    {
      label: "Detail",
      value: "candidate-detail",
    },
    {
      label: "Mail List",
      value: "candidate-mail-list",
    },
    {
      label: "Mail Add",
      value: "candidate-mail-add",
    },
    {
      label: "Timeline",
      value: "candidate-timeline",
    },
    {
      label: "Task List",
      value: "candidate-task-list",
    },
    {
      label: "Download Resume",
      value: "candidate-download-resume",
    },
    {
      label: "Download Recording",
      value: "candidate-download-recording",
    },
    {
      label: "Export",
      value: "candidate-export",
    },
    {
      label: "Call",
      value: "candidate-call",
    },
    {
      label: "Interview List",
      value: "candidate-interview-list",
    },
  ];
  //recruitment
  const recruitmentArray = [
    {
      label: "List",
      value: "recruitment-list",
    },
    {
      label: "Details",
      value: "recruitment-detail",
    },
    // {
    //   label: "Add",
    //   value: "recruitment-add",
    // },
    // {
    //   label: "Delete",
    //   value: "recruitment-delete",
    // },
    {
      label: "Add Candidate",
      value: "recruitment-add-candidate",
    },
    {
      label: "Candidate Pool",
      value: "recruitment-candidate-pool",
    },
    {
      label: "Candidate Details",
      value: "recruitment-candidate-detail",
    },
    {
      label: "Candidate Status Update",
      value: "recruitment-candidate-status update",
    },
    {
      label: "Candidate Mail List",
      value: "recruitment-candidate-mail-list",
    },
    {
      label: "Candidate Mail Add",
      value: "recruitment-candidate-mail-add",
    },
    {
      label: "Candidate Timeline",
      value: "recruitment-candidate-timeline",
    },
    {
      label: "Candidate Task List",
      value: "recruitment-candidate-task-list",
    },
    {
      label: "Candidate Download Resume",
      value: "recruitment-candidate-download-resume",
    },
    {
      label: "Candidate Download Recording",
      value: "recruitment-candidate-download-recording",
    },
    {
      label: "Candidate Call",
      value: "recruitment-candidate-call",
    },
    {
      label: "Candidate Interview List",
      value: "recruitment-candidate-interview-list",
    },
    {
      label: "Candidate Offer Letter",
      value: "recruitment-candidate-offerLetter",
    },
    {
      label: "Delete Candidate",
      value: "recruitment-delete-candidate",
    },
  ];

  //requirement
  const requirementArray = [
    { label: "Job List", value: "job-list" },
    { label: "Job Add", value: "job-add" },
    { label: "Job Edit", value: "job-edit" },
    { label: "Job Delete", value: "job-delete" },
    { label: "Job Details", value: "job-detail" },
    { label: "Job Assign Employee", value: "job-assign-employee" },
    { label: "Job Task List", value: "job-task-list" },
  ];
  //company
  const companyArray = [
    { label: "Client List", value: "client-list" },
    { label: "Client Add", value: "client-add" },
    { label: "Client Edit", value: "client-edit" },
    { label: "Client Delete", value: "client-delete" },
    { label: "Client Details", value: "client-detail" },
    { label: "Client Job", value: "client-job" },
    { label: "Client Task List", value: "client-task-list" },
    { label: "Client Contact Info", value: "client-contact-info" },
    // {
    //   label: "Client Contact Person Info",
    //   value: "client-contact-person-info",
    // },
    { label: "Client Billing Detail", value: "client-billing-detail" },
  ];
  //employees
  const employeesArray = [
    { label: "Employees List", value: "employees-list" },
    { label: "Employees Add", value: "employees-add" },
    { label: "Employees Edit", value: "employees-edit" },
    { label: "Employees Delete", value: "employees-delete" },
    { label: "Employees Details", value: "employees-detail" },
    { label: "Employees Assign Role", value: "employees-assign-role" },
    {
      label: "Employees Assign Permission",
      value: "employees-assign-permission",
    },
    { label: "Employees Timeline", value: "employees-timeline" },
    { label: "Employees Task List", value: "employees-task-list" },
    {
      label: "Employees Download Recording",
      value: "employee-download-recording",
    },
    { label: "Employees Call", value: "employee-call" },
    { label: "Employees job", value: "employee-job-list" },
  ];
  //task
  const taskArray = [
    { label: "Task Add", value: "Task-add" },
    { label: "Task Edit", value: "Task-edit" },
    { label: "Task Delete", value: "Task-delete" },
    { label: "Task Detail", value: "Task-detail" },
    { label: "Task List View", value: "Task-list-view" },
    { label: "Task Calendar View", value: "Task-calendar-view" },
    { label: "Task Status Update", value: "Task-status-update" },
  ];
  //settings
  const settingsArray = [
    { label: "Settings System", value: "settings-system" },
    { label: "Settings Organisation", value: "settings-organisations" },
    { label: "Settings Billing", value: "settings-billing" },
    { label: "Settings Roles List", value: "settings-roles-list" },
    { label: "Settings Roles Add", value: "settings-roles-add" },
    { label: "Settings Roles Edit", value: "settings-roles-edit" },
    { label: "Settings Roles Delete", value: "settings-roles-delete" },
    {
      label: "Settings Application Emial Template",
      value: "settings-application-emailTemplate",
    },
    {
      label: "Settings Application Lead Source",
      value: "settings-application-leadSource",
    },
    {
      label: "Settings Application Candidate Reason",
      value: "settings-application-candidateReason",
    },
    {
      label: "Settings Application Job Listing",
      value: "settings-application-jobListing",
    },
    {
      label: "Settings Application Job Description",
      value: "settings-application-jobDescription",
    },
    {
      label: "Settings Application Sharing Medium",
      value: "settings-application-sharingMEdium",
    },
    {
      label: "Settings Application Department",
      value: "settings-application-department",
    },
    {
      label: "Settings Application Job Prefix",
      value: "settings-application-jobPrefix",
    },
    { label: "Settings Import", value: "settings-import" },
    { label: "Settings Application Tags", value: "settings-application-tags" },
  ];
  //interview
  const interviewArray = [
    { label: "Interview Add", value: "interview-add" },
    { label: "Interview Edit", value: "interview-edit" },
    { label: "Interview Details", value: "interview-detail" },
    { label: "Interview Delete", value: "interview-delete" },
    { label: "Interview Status", value: "interview-status-update" },
    // { label: "Interview Calendar View", value: "interview-calendar-view" },
  ];
  const reportArray = [{ label: "Report", value: "report" }];
  // console.log(
  //   dashboardArray.length +
  //     candidateArray.length +
  //     recruitmentArray.length +
  //     requirementArray.length +
  //     companyArray.length +
  //     employeesArray.length +
  //     taskArray.length +
  //     settingsArray.length +
  //     interviewArray.length +
  //     reportArray.length
  // );
  function checkedData(val) {
    if (val.target.checked) {
      setRandP((data) => [...data, val.target.value]);
    } else {
      let index = randP.indexOf(val.target.value);
      if (index != -1) {
        setRandP(randP.filter((data) => data !== val.target.value));
      }
    }
  }
  function checkedData1(val) {
    if (val.target.checked) {
      setRandP1((data) => [...data, val.target.value]);
    } else {
      let index = randP1.indexOf(val.target.value);
      if (index != -1) {
        setRandP1(randP1.filter((data) => data !== val.target.value));
      }
    }
  }
  function checkedData2(val) {
    if (val.target.checked) {
      setRandP2((data) => [...data, val.target.value]);
    } else {
      let index = randP2.indexOf(val.target.value);
      if (index != -1) {
        setRandP2(randP2.filter((data) => data !== val.target.value));
      }
    }
  }
  function checkedData3(val) {
    if (val.target.checked) {
      setRandP3((data) => [...data, val.target.value]);
    } else {
      let index = randP3.indexOf(val.target.value);
      if (index != -1) {
        setRandP3(randP3.filter((data) => data !== val.target.value));
      }
    }
  }
  function checkedData4(val) {
    if (val.target.checked) {
      setRandP4((data) => [...data, val.target.value]);
    } else {
      let index = randP4.indexOf(val.target.value);
      if (index != -1) {
        setRandP4(randP4.filter((data) => data !== val.target.value));
      }
    }
  }
  function checkedData5(val) {
    if (val.target.checked) {
      setRandP5((data) => [...data, val.target.value]);
    } else {
      let index = randP5.indexOf(val.target.value);
      if (index != -1) {
        setRandP5(randP5.filter((data) => data !== val.target.value));
      }
    }
  }
  function checkedData6(val) {
    if (val.target.checked) {
      setRandP6((data) => [...data, val.target.value]);
    } else {
      let index = randP6.indexOf(val.target.value);
      if (index != -1) {
        setRandP6(randP6.filter((data) => data !== val.target.value));
      }
    }
  }
  function checkedData7(val) {
    if (val.target.checked) {
      setRandP7((data) => [...data, val.target.value]);
    } else {
      let index = randP7.indexOf(val.target.value);
      if (index != -1) {
        setRandP7(randP7.filter((data) => data !== val.target.value));
      }
    }
  }
  function checkedData8(val) {
    if (val.target.checked) {
      setRandP8((data) => [...data, val.target.value]);
    } else {
      let index = randP8.indexOf(val.target.value);
      if (index != -1) {
        setRandP8(randP8.filter((data) => data !== val.target.value));
      }
    }
  }
  function checkedData9(val) {
    if (val.target.checked) {
      setRandP9((data) => [...data, val.target.value]);
    } else {
      let index = randP9.indexOf(val.target.value);
      if (index != -1) {
        setRandP9(randP9.filter((data) => data !== val.target.value));
      }
    }
  }
  function checkedDataAll() {
    // if (val.target.checked) {
    //   setRandPAll((data) => [...data, val.target.value]);
    // } else {
    //   let index = randPAll.indexOf(val.target.value);
    //   if (index != -1) {
    //     setRandPAll(randPAll.filter((data) => data !== val.target.value));
    //   }
    // }
    setRandP(
      dashboardArray.map((x) => {
        return x.value;
      })
    );
    setRandP1(
      candidateArray.map((x) => {
        return x.value;
      })
    );
    setRandP2(
      recruitmentArray.map((x) => {
        return x.value;
      })
    );
    setRandP3(
      requirementArray.map((x) => {
        return x.value;
      })
    );
    setRandP4(
      companyArray.map((x) => {
        return x.value;
      })
    );
    setRandP5(
      employeesArray.map((x) => {
        return x.value;
      })
    );
    setRandP6(
      taskArray.map((x) => {
        return x.value;
      })
    );
    setRandP7(
      settingsArray.map((x) => {
        return x.value;
      })
    );
    setRandP8(
      interviewArray.map((x) => {
        return x.value;
      })
    );
    setRandP9(
      reportArray.map((x) => {
        return x.value;
      })
    );
  }

  return (
    <>
      <Row>
        {props.authPermissions &&
          props.authPermissions.find(
            (data) => data === "employees-assign-role"
          ) && (
            <Col sm={24}>
              <Card style={{ padding: "20px", marginBottom: "30px" }}>
                <Form
                  form={form}
                  initialValues={{
                    role:
                      props.employee &&
                      props.employee.role &&
                      props.employee.role,
                  }}
                  onFinish={(val) => {
                    props.postemployee_roleAction(
                      {
                        employeeId: props.employee.userId,
                        role: val.role,
                      },
                      (success) => {
                        if (success) {
                          // form.resetFields();
                          props.getEmployeePermissionHandler(props.id);
                        }
                      }
                    );
                  }}
                  layout="vertical"
                >
                  <Form.Item
                    label="Role"
                    name="role"
                    rules={[{ required: true, message: "Roles is Required" }]}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      showSearch
                      allowClear
                    >
                      {props.roles &&
                        props.roles.map((x, index) => {
                          return (
                            <Select.Option key={index} value={x.name}>
                              {x.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Row justify="end">
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className={commonCssStyle.buttonSettingsPrimary}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          )}
      </Row>
      {props.authPermissions &&
        props.authPermissions.find(
          (data) => data === "employees-assign-permission"
        ) && (
          <>
            <Row justify="space-between">
              <Col>
                <h1 className={commonCssStyle.headingText}>
                  Roles and Permission
                </h1>
              </Col>

              <Col>
                <Button
                  className={commonCssStyle.buttonSettingsPrimary}
                  htmlType="button"
                  type="primary"
                  onClick={() => {
                    let data = [
                      ...randP,
                      ...randP1,
                      ...randP2,
                      ...randP3,
                      ...randP4,
                      ...randP5,
                      ...randP6,
                      ...randP7,
                      ...randP8,
                      ...randP9,
                    ];

                    // console.log(data);
                    props.postEmployeePermissionHandler(
                      {
                        employeeId: props.id,
                        permission: data,
                      },
                      (success) => {}
                    );
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col md={24}>
                <Checkbox
                  checked={
                    randP.length +
                      randP1.length +
                      randP2.length +
                      randP3.length +
                      randP4.length +
                      randP5.length +
                      randP6.length +
                      randP7.length +
                      randP8.length +
                      randP9.length ===
                    89
                  }
                  onChange={(val) => {
                    if (val.target.checked) {
                      checkedDataAll();
                      setCheckedAll(true);
                    } else {
                      setRandP([]);
                      setRandP1([]);
                      setRandP2([]);
                      setRandP3([]);
                      setRandP4([]);
                      setRandP5([]);
                      setRandP6([]);
                      setRandP7([]);
                      setRandP8([]);
                      setRandP9([]);
                      setCheckedAll(false);
                    }
                  }}
                >
                  <p
                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.coursorPointer} ${commonCssStyle.rolesAndPermission}`}
                  >
                    Select All
                  </p>
                </Checkbox>
              </Col>
            </Row>
            <Row gutter={[0, 24]}>
              <Col md={24}>
                <Card style={{ padding: "30px" }}>
                  <Row gutter={[0, 16]} justify="space-between">
                    <Col md={24} lg={4}>
                      <Checkbox
                        checked={randP.length === dashboardArray.length}
                        onChange={(val) => {
                          if (val.target.checked) {
                            setRandP(
                              dashboardArray.map((x) => {
                                return x.value;
                              })
                            );
                          } else {
                            setRandP([]);
                          }
                        }}
                      >
                        <p
                          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.coursorPointer} ${commonCssStyle.rolesAndPermission}`}
                        >
                          Dashboard
                        </p>
                      </Checkbox>
                    </Col>
                    {dashboardArray &&
                      dashboardArray.map((data, index) => {
                        return (
                          <Col key={index} md={24} lg={19}>
                            <Checkbox
                              checked={randP.find((x) => x === data.value)}
                              value={data.value}
                              onChange={(val) => {
                                checkedData(val);
                              }}
                            >
                              <p
                                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                              >
                                {data.label}
                              </p>
                            </Checkbox>
                          </Col>
                        );
                      })}
                  </Row>
                </Card>
              </Col>
              <Col md={24}>
                <Card style={{ padding: "30px" }}>
                  <Row gutter={[0, 16]} justify="space-between">
                    <Col md={24} lg={4}>
                      <Checkbox
                        checked={randP1.length === candidateArray.length}
                        onChange={(val) => {
                          if (val.target.checked) {
                            setRandP1(
                              candidateArray.map((x) => {
                                return x.value;
                              })
                            );
                          } else {
                            setRandP1([]);
                          }
                        }}
                      >
                        <p
                          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                        >
                          Candidate
                        </p>
                      </Checkbox>
                    </Col>
                    <Col md={24} lg={19}>
                      <Row gutter={[16, 16]}>
                        {candidateArray &&
                          candidateArray.map((data, index) => {
                            return (
                              <Col key={index}>
                                <Checkbox
                                  checked={randP1.find((x) => x === data.value)}
                                  onChange={(val) => {
                                    checkedData1(val);
                                  }}
                                  value={data.value}
                                >
                                  <p
                                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                                  >
                                    {data.label}
                                  </p>
                                </Checkbox>
                              </Col>
                            );
                          })}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24}>
                <Card style={{ padding: "30px" }}>
                  <Row gutter={[0, 16]} justify="space-between">
                    <Col md={24} lg={4}>
                      <Checkbox
                        checked={randP2.length === recruitmentArray.length}
                        onChange={(val) => {
                          if (val.target.checked) {
                            setRandP2(
                              recruitmentArray.map((x) => {
                                return x.value;
                              })
                            );
                          } else {
                            setRandP2([]);
                          }
                        }}
                      >
                        <p
                          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                        >
                          Recruitment
                        </p>
                      </Checkbox>
                    </Col>
                    <Col md={24} lg={19}>
                      <Row gutter={[16, 16]}>
                        {recruitmentArray &&
                          recruitmentArray.map((data, index) => {
                            return (
                              <Col key={index}>
                                <Checkbox
                                  checked={randP2.find((x) => x === data.value)}
                                  onChange={(val) => {
                                    checkedData2(val);
                                  }}
                                  value={data.value}
                                >
                                  <p
                                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                                  >
                                    {data.label}
                                  </p>
                                </Checkbox>
                              </Col>
                            );
                          })}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24}>
                <Card style={{ padding: "30px" }}>
                  <Row gutter={[0, 16]} justify="space-between">
                    <Col md={24} lg={4}>
                      <Checkbox
                        checked={randP3.length === requirementArray.length}
                        onChange={(val) => {
                          if (val.target.checked) {
                            setRandP3(
                              requirementArray.map((x) => {
                                return x.value;
                              })
                            );
                          } else {
                            setRandP3([]);
                          }
                        }}
                      >
                        <p
                          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                        >
                          Requirement
                        </p>
                      </Checkbox>
                    </Col>
                    <Col md={24} lg={19}>
                      <Row gutter={[16, 16]}>
                        {requirementArray &&
                          requirementArray.map((data, index) => {
                            return (
                              <Col key={index}>
                                <Checkbox
                                  checked={randP3.find((x) => x === data.value)}
                                  onChange={(val) => {
                                    checkedData3(val);
                                  }}
                                  value={data.value}
                                >
                                  <p
                                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                                  >
                                    {data.label}
                                  </p>
                                </Checkbox>
                              </Col>
                            );
                          })}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24}>
                <Card style={{ padding: "30px" }}>
                  <Row gutter={[0, 16]} justify="space-between">
                    <Col md={24} lg={4}>
                      <Checkbox
                        checked={randP4.length === companyArray.length}
                        onChange={(val) => {
                          if (val.target.checked) {
                            setRandP4(
                              companyArray.map((x) => {
                                return x.value;
                              })
                            );
                          } else {
                            setRandP4([]);
                          }
                        }}
                      >
                        <p
                          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                        >
                          Comapny
                        </p>
                      </Checkbox>
                    </Col>
                    <Col md={24} lg={19}>
                      <Row gutter={[16, 16]}>
                        {companyArray &&
                          companyArray.map((data, index) => {
                            return (
                              <Col key={index}>
                                <Checkbox
                                  checked={randP4.find((x) => x === data.value)}
                                  onChange={(val) => {
                                    checkedData4(val);
                                  }}
                                  value={data.value}
                                >
                                  <p
                                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                                  >
                                    {data.label}
                                  </p>
                                </Checkbox>
                              </Col>
                            );
                          })}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24}>
                <Card style={{ padding: "30px" }}>
                  <Row gutter={[0, 16]} justify="space-between">
                    <Col md={24} lg={4}>
                      <Checkbox
                        checked={randP5.length === employeesArray.length}
                        onChange={(val) => {
                          if (val.target.checked) {
                            setRandP5(
                              employeesArray.map((x) => {
                                return x.value;
                              })
                            );
                          } else {
                            setRandP5([]);
                          }
                        }}
                      >
                        <p
                          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                        >
                          Employee
                        </p>
                      </Checkbox>
                    </Col>
                    <Col md={24} lg={19}>
                      <Row gutter={[16, 16]}>
                        {employeesArray &&
                          employeesArray.map((data, index) => {
                            return (
                              <Col key={index}>
                                <Checkbox
                                  checked={randP5.find((x) => x === data.value)}
                                  onChange={(val) => {
                                    checkedData5(val);
                                  }}
                                  value={data.value}
                                >
                                  <p
                                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                                  >
                                    {data.label}
                                  </p>
                                </Checkbox>
                              </Col>
                            );
                          })}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24}>
                <Card style={{ padding: "30px" }}>
                  <Row gutter={[0, 16]} justify="space-between">
                    <Col md={24} lg={4}>
                      <Checkbox
                        checked={randP6.length === taskArray.length}
                        onChange={(val) => {
                          if (val.target.checked) {
                            setRandP6(
                              taskArray.map((x) => {
                                return x.value;
                              })
                            );
                          } else {
                            setRandP6([]);
                          }
                        }}
                      >
                        <p
                          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                        >
                          Task
                        </p>
                      </Checkbox>
                    </Col>
                    <Col md={24} lg={19}>
                      <Row gutter={[16, 16]}>
                        {taskArray &&
                          taskArray.map((data, index) => {
                            return (
                              <Col key={index}>
                                <Checkbox
                                  checked={randP6.find((x) => x === data.value)}
                                  onChange={(val) => {
                                    checkedData6(val);
                                  }}
                                  value={data.value}
                                >
                                  <p
                                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                                  >
                                    {data.label}
                                  </p>
                                </Checkbox>
                              </Col>
                            );
                          })}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24}>
                <Card style={{ padding: "30px" }}>
                  <Row gutter={[0, 16]} justify="space-between">
                    <Col md={24} lg={4}>
                      <Checkbox
                        checked={randP7.length === settingsArray.length}
                        onChange={(val) => {
                          if (val.target.checked) {
                            setRandP7(
                              settingsArray.map((x) => {
                                return x.value;
                              })
                            );
                          } else {
                            setRandP7([]);
                          }
                        }}
                      >
                        <p
                          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                        >
                          Settings
                        </p>
                      </Checkbox>
                    </Col>
                    <Col md={24} lg={19}>
                      <Row gutter={[16, 16]}>
                        {settingsArray &&
                          settingsArray.map((data, index) => {
                            return (
                              <Col key={index}>
                                <Checkbox
                                  checked={randP7.find((x) => x === data.value)}
                                  onChange={(val) => {
                                    checkedData7(val);
                                  }}
                                  value={data.value}
                                >
                                  <p
                                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                                  >
                                    {data.label}
                                  </p>
                                </Checkbox>
                              </Col>
                            );
                          })}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24}>
                <Card style={{ padding: "30px" }}>
                  <Row gutter={[0, 16]} justify="space-between">
                    <Col md={24} lg={4}>
                      <Checkbox
                        checked={randP8.length === interviewArray.length}
                        onChange={(val) => {
                          if (val.target.checked) {
                            setRandP8(
                              interviewArray.map((x) => {
                                return x.value;
                              })
                            );
                          } else {
                            setRandP8([]);
                          }
                        }}
                      >
                        <p
                          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                        >
                          Interview
                        </p>
                      </Checkbox>
                    </Col>
                    <Col md={24} lg={19}>
                      <Row gutter={[16, 16]}>
                        {interviewArray &&
                          interviewArray.map((data, index) => {
                            return (
                              <Col key={index}>
                                <Checkbox
                                  checked={randP8.find((x) => x === data.value)}
                                  onChange={(val) => {
                                    checkedData8(val);
                                  }}
                                  value={data.value}
                                >
                                  <p
                                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                                  >
                                    {data.label}
                                  </p>
                                </Checkbox>
                              </Col>
                            );
                          })}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24}>
                <Card style={{ padding: "30px" }}>
                  <Row gutter={[0, 16]} justify="space-between">
                    <Col md={24} lg={4}>
                      <Checkbox
                        checked={randP9.length === reportArray.length}
                        onChange={(val) => {
                          if (val.target.checked) {
                            setRandP9(
                              reportArray.map((x) => {
                                return x.value;
                              })
                            );
                          } else {
                            setRandP9([]);
                          }
                        }}
                      >
                        <p
                          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                        >
                          Report
                        </p>
                      </Checkbox>
                    </Col>
                    <Col md={24} lg={19}>
                      <Row gutter={[16, 16]}>
                        {reportArray &&
                          reportArray.map((data, index) => {
                            return (
                              <Col key={index}>
                                <Checkbox
                                  checked={randP9.find((x) => x === data.value)}
                                  onChange={(val) => {
                                    checkedData9(val);
                                  }}
                                  value={data.value}
                                >
                                  <p
                                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                                  >
                                    {data.label}
                                  </p>
                                </Checkbox>
                              </Col>
                            );
                          })}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }} justify="end">
              <Col>
                <Button
                  className={commonCssStyle.buttonSettingsPrimary}
                  htmlType="button"
                  type="primary"
                  onClick={() => {
                    let data = [
                      ...randP,
                      ...randP1,
                      ...randP2,
                      ...randP3,
                      ...randP4,
                      ...randP5,
                      ...randP6,
                      ...randP7,
                      ...randP8,
                      ...randP9,
                    ];

                    // console.log(data);
                    props.postEmployeePermissionHandler(
                      {
                        employeeId: props.id,
                        permission: data,
                      },
                      (success) => {}
                    );
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </>
        )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    authPermissions: state.authpermissions.permissions,
    profile: state.authentication.profileData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeePermissionHandler: (id) => {
      dispatch(getEmployee_permissionAction(id));
    },
    postEmployeePermissionHandler: (data, callback) => {
      dispatch(postEmployee_permissionAction(data, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeRandP);
