import { Form, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
function EmployeRPSelect(props) {
  const [form] = useForm();
  // dashboard
  const dashboardArray = [{ label: "Dashboard", value: "dashboard" }];
  // candidate
  const candidateArray = [
    {
      label: "Candidate List",
      value: "candidate-list",
    },
    {
      label: "Candidate Add",
      value: "candidate-add",
    },
    {
      label: "Candidate Edit",
      value: "candidate-edit",
    },
    {
      label: "Candidate Delete",
      value: "candidate-delete",
    },
    {
      label: "Candidate Detail",
      value: "candidate-detail",
    },
    {
      label: "Candidate Mail List",
      value: "candidate-mail-list",
    },
    {
      label: "Candidate Mail Add",
      value: "candidate-mail-add",
    },
    {
      label: "Candidate Timeline",
      value: "candidate-timeline",
    },
    {
      label: "Candidate Task List",
      value: "candidate-task-list",
    },
    {
      label: "Candidate Download Resume",
      value: "candidate-download-resume",
    },
    {
      label: "Candidate Download Recording",
      value: "candidate-download-recording",
    },
    {
      label: "Candidate Export",
      value: "candidate-export",
    },
    {
      label: "Candidate Call",
      value: "candidate-call",
    },
    {
      label: "Candidate Interview List",
      value: "candidate-interview-list",
    },
    {
      label: "Candidate Job",
      value: "candidate-jobs",
    },
  ];
  //recruitment
  const recruitmentArray = [
    {
      label: "Recruitment List",
      value: "recruitment-list",
    },
    {
      label: "Recruitment Details",
      value: "recruitment-detail",
    },
    // {
    //   label: "Recruitment Add",
    //   value: "recruitment-add",
    // },
    // {
    //   label: "Recruitment Delete",
    //   value: "recruitment-delete",
    // },
    {
      label: "Recruitment Add Candidate",
      value: "recruitment-add-candidate",
    },
    {
      label: "Recruitment Candidate Pool",
      value: "recruitment-candidate-pool",
    },
    {
      label: "Recruitment Candidate Details",
      value: "recruitment-candidate-detail",
    },
    {
      label: "Recruitment Candidate Status Update",
      value: "recruitment-candidate-status update",
    },
    {
      label: "Recruitment Candidate Mail List",
      value: "recruitment-candidate-mail-list",
    },
    {
      label: "Recruitment Candidate Mail Add",
      value: "recruitment-candidate-mail-add",
    },
    {
      label: "Recruitment Candidate Timeline",
      value: "recruitment-candidate-timeline",
    },
    {
      label: "Recruitment Candidate Task List",
      value: "recruitment-candidate-task-list",
    },
    {
      label: "Recruitment Candidate Download Resume",
      value: "recruitment-candidate-download-resume",
    },
    {
      label: "Recruitment Candidate Download Recording",
      value: "recruitment-candidate-download-recording",
    },
    {
      label: "Recruitment Candidate Call",
      value: "recruitment-candidate-call",
    },
    {
      label: "Recruitment Candidate Interview List",
      value: "recruitment-candidate-interview-list",
    },
    {
      label: "Recruitment Candidate Offer Letter",
      value: "recruitment-candidate-offerLetter",
    },
    {
      label: "Recruitment Delete Candidate",
      value: "recruitment-delete-candidate",
    },
    // {
    //   label: "Recruitment Candidate Jobs",
    //   value: "recruitment-candidate-jobs",
    // },
  ];
  //requirement
  const requirementArray = [
    { label: "Job List", value: "job-list" },
    { label: "Job Add", value: "job-add" },
    { label: "Job Edit", value: "job-edit" },
    { label: "Job Delete", value: "job-delete" },
    { label: "Job Details", value: "job-detail" },
    { label: "Job Assign Employee", value: "job-assign-employee" },
    { label: "Job Task List", value: "job-task-list" },
  ];
  //company
  const companyArray = [
    { label: "Client List", value: "client-list" },
    { label: "Client Add", value: "client-add" },
    { label: "Client Edit", value: "client-edit" },
    { label: "Client Delete", value: "client-delete" },
    { label: "Client Details", value: "client-detail" },
    { label: "Client Job", value: "client-job" },
    { label: "Client Task List", value: "client-task-list" },
    { label: "Client Contact Info", value: "client-contact-info" },
    // {
    //   label: "Client Contact Person Info",
    //   value: "client-contact-person-info",
    // },
    { label: "Client Billing Detail", value: "client-billing-detail" },
  ];
  //employees
  const employeesArray = [
    { label: "Employees List", value: "employees-list" },
    { label: "Employees Add", value: "employees-add" },
    { label: "Employees Edit", value: "employees-edit" },
    { label: "Employees Delete", value: "employees-delete" },
    { label: "Employees Details", value: "employees-detail" },
    { label: "Employees Assign Role", value: "employees-assign-role" },
    {
      label: "Employees Assign Permission",
      value: "employees-assign-permission",
    },
    { label: "Employees Timeline", value: "employees-timeline" },
    { label: "Employees Task List", value: "employees-task-list" },
    {
      label: "Employees Download Recording",
      value: "employee-download-recording",
    },
    { label: "Employees Call", value: "employee-call" },
    { label: "Employees job", value: "employee-job-list" },
  ];
  //task
  const taskArray = [
    { label: "Task Add", value: "Task-add" },
    { label: "Task Edit", value: "Task-edit" },
    { label: "Task Delete", value: "Task-delete" },
    { label: "Task Detail", value: "Task-detail" },
    { label: "Task List View", value: "Task-list-view" },
    { label: "Task Calendar View", value: "Task-calendar-view" },
  ];
  //settings
  const settingsArray = [
    { label: "Settings System", value: "settings-system" },
    { label: "Settings Organisation", value: "settings-organisations" },
    { label: "Settings Billing", value: "settings-billing" },
    { label: "Settings Roles List", value: "settings-roles-list" },
    { label: "Settings Roles Add", value: "settings-roles-add" },
    { label: "Settings Roles Edit", value: "settings-roles-edit" },
    { label: "Settings Roles Delete", value: "settings-roles-delete" },
    {
      label: "Settings Application Emial Template",
      value: "settings-application-emailTemplate",
    },
    {
      label: "Settings Application Lead Source",
      value: "settings-application-leadSource",
    },
    {
      label: "Settings Application Candidate Reason",
      value: "settings-application-candidateReason",
    },
    {
      label: "Settings Application Job Listing",
      value: "settings-application-jobListing",
    },
    {
      label: "Settings Application Job Description",
      value: "settings-application-jobDescription",
    },
    {
      label: "Settings Application Sharing Medium",
      value: "settings-application-sharingMEdium",
    },
    {
      label: "Settings Application Department",
      value: "settings-application-department",
    },
    {
      label: "Settings Application Job Prefix",
      value: "settings-application-jobPrefix",
    },
    { label: "Settings Import", value: "settings-import" },
    { label: "Settings Application Tags", value: "settings-application-tags" },
  ];
  //interview
  const interviewArray = [
    { label: "Interview Add", value: "interview-add" },
    { label: "Interview Edit", value: "interview-edit" },
    { label: "Interview Details", value: "interview-detail" },
    { label: "Interview Delete", value: "interview-delete" },
    // { label: "Interview Calendar View", value: "interview-calendar-view" },
  ];

  const reportArray = [{ label: "Report", value: "report" }];

  return (
    <>
      <Form form={form}>
        {props.name === "dashboard" ? (
          <Form.Item name="dashboard">
            <Select
              onChange={(val) => {
                props.tagsData([...props.tags, val]);
                form.resetFields();
              }}
              placeholder="Select Roles and Permission"
              style={{ width: "100%" }}
            >
              {dashboardArray
                .filter((data) => !props.tags.includes(data.value))
                .map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.value}>
                      {data.label}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        ) : props.name === "candidate" ? (
          <Form.Item name="candidate">
            <Select
              onChange={(val) => {
                props.tagsData([...props.tags, val]);
                form.resetFields();
              }}
              placeholder="Select Roles and Permission"
              style={{ width: "100%" }}
            >
              {candidateArray
                .filter((data) => !props.tags.includes(data.value))
                .map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.value}>
                      {data.label}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        ) : props.name === "recruitment" ? (
          <Form.Item name="recuritment">
            <Select
              onChange={(val) => {
                props.tagsData([...props.tags, val]);
                form.resetFields();
              }}
              placeholder="Select Roles and Permission"
              style={{ width: "100%" }}
            >
              {recruitmentArray
                .filter((data) => !props.tags.includes(data.value))
                .map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.value}>
                      {data.label}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        ) : props.name === "requirement" ? (
          <Form.Item name="requirement">
            <Select
              onChange={(val) => {
                props.tagsData([...props.tags, val]);
                form.resetFields();
              }}
              placeholder="Select Roles and Permission"
              style={{ width: "100%" }}
            >
              {requirementArray
                .filter((data) => !props.tags.includes(data.value))
                .map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.value}>
                      {data.label}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        ) : props.name === "company" ? (
          <Form.Item name="company">
            <Select
              onChange={(val) => {
                props.tagsData([...props.tags, val]);
                form.resetFields();
              }}
              placeholder="Select Roles and Permission"
              style={{ width: "100%" }}
            >
              {companyArray
                .filter((data) => !props.tags.includes(data.value))
                .map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.value}>
                      {data.label}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        ) : props.name === "task" ? (
          <Form.Item name="task">
            <Select
              onChange={(val) => {
                props.tagsData([...props.tags, val]);
                form.resetFields();
              }}
              placeholder="Select Roles and Permission"
              style={{ width: "100%" }}
            >
              {taskArray
                .filter((data) => !props.tags.includes(data.value))
                .map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.value}>
                      {data.label}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        ) : props.name === "employees" ? (
          <Form.Item name="employees">
            <Select
              onChange={(val) => {
                props.tagsData([...props.tags, val]);
                form.resetFields();
              }}
              placeholder="Select Roles and Permission"
              style={{ width: "100%" }}
            >
              {employeesArray
                .filter((data) => !props.tags.includes(data.value))
                .map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.value}>
                      {data.label}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        ) : props.name === "settings" ? (
          <Form.Item name="settings">
            <Select
              onChange={(val) => {
                props.tagsData([...props.tags, val]);
                form.resetFields();
              }}
              placeholder="Select Roles and Permission"
              style={{ width: "100%" }}
            >
              {settingsArray
                .filter((data) => !props.tags.includes(data.value))
                .map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.value}>
                      {data.label}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        ) : props.name === "interview" ? (
          <Form.Item name="interview">
            <Select
              onChange={(val) => {
                props.tagsData([...props.tags, val]);
                form.resetFields();
              }}
              placeholder="Select Roles and Permission"
              style={{ width: "100%" }}
            >
              {interviewArray
                .filter((data) => !props.tags.includes(data.value))
                .map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.value}>
                      {data.label}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        ) : (
          props.name === "report" && (
            <Form.Item name="report">
              <Select
                onChange={(val) => {
                  props.tagsData([...props.tags, val]);
                  form.resetFields();
                }}
                placeholder="Select Roles and Permission"
                style={{ width: "100%" }}
              >
                {reportArray
                  .filter((data) => !props.tags.includes(data.value))
                  .map((data, index) => {
                    return (
                      <Select.Option key={index} value={data.value}>
                        {data.label}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          )
        )}
      </Form>
    </>
  );
}
export default EmployeRPSelect;
