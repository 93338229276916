import { Button, Col, Drawer, Form, Input, Row } from "antd";
import React from "react";
import styles from "../../../Screens/ClientCompany/ClientCompany.module.css";
import commonStyles from "../../../UI/CommonCss.module.css";

//flag import

import "antd-country-phone-input/dist/index.css";
import "flagpack/dist/flagpack.css";

import { CloseIcon } from "../../../IconsComp/Icons";
import PhoneInput from "react-phone-number-input";
//flag import end

const EditClientCompanyDetailsDrawer = (props) => {
  const [form] = Form.useForm();

  const onFinish = (val) => {
    var data = {
      ...val,
      id: props.client.id,
    };
    if (!data.agreement) delete data.agreement;
    props.onSubmit(data);
  };
  return (
    <>
      <Drawer
        className={"modalModified"}
        closable={false}
        extra={
          <div
            onClick={() => {
              form.resetFields();
              props.onCloseClientCompany();
            }}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
          </div>
        }
        footer={false}
        style={{
          paddingBottom: "0px",
        }}
        title={
          <p
            style={{ fontSize: "20px", margin: "0px" }}
            className={`${commonStyles.modalHeading}`}
          >
            {!props.client ? "Add Company" : "Edit Company Details"}
          </p>
        }
        width={726}
        height={48}
        open={props.onOpenClientCompany}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          form={form}
          initialValues={
            props.client && {
              clientName: props.client.clientName,
              clientEmail: props.client.clientEmail,
              clientPhone: props.client.clientPhone,

              employeesCount: props.client.employeesCount,
              cin: props.client.cin,
              tan: props.client.tan,
            }
          }
          layout="vertical"
          onFinish={onFinish}
        >
          <Row
            className={styles.modalRowStyling}
            style={{ padding: "0px 20px 80px 20px" }}
          >
            <Col flex={1}>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Company Name"
                    name="clientName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter company name",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                      //   message:
                      //     "It allows only alphabets, no whitespace at the beginning and end",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter company name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Official Email Address"
                    name="clientEmail"
                    rules={[
                      {
                        required: true,
                        message: "Official email address required",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email",
                      },
                    ]}
                  >
                    <Input placeholder="Enter official email" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    name="clientPhone"
                    label="Official Contact Number"
                    type="number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter phone number",
                      },
                    ]}
                  >
                    <PhoneInput
                      placeholder="Enter contact number"
                      className="ant-input ant-cust-inputs"
                      defaultCountry="IN"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Number of Employee"
                    name="employeesCount"
                    rules={[
                      {
                        required: true,
                        message: "Please enter number of employees",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="Enter number of employees"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="TAN"
                    name="tan"
                    rules={[
                      {
                        required: false,
                        message: "Please enter tan number",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Z0-9\s]+$/,
                      //   message: "Invalid TAN",
                      // },
                      // {
                      //   min: 10,
                      //   message: "TAN number Should be in 10-digit alpha-numeric format",
                      // },
                      // {
                      //   pattern:/.*\S.*/,
                      //   message:"White space are not allowed"
                      // }
                    ]}
                  >
                    <Input placeholder="Enter tan number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="CIN"
                    name="cin"
                    rules={[
                      {
                        required: false,
                        message: "Please enter cin number",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Z0-9\s]+$/,
                      //   message: "Invalid CIN",
                      // },
                      // {
                      //   min: 21,
                      //   message: "CIN number Should be in 21-digit alpha-numeric format",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter cin number" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Col
            style={{ width: "726px" }}
            xs={24}
            md={24}
            className={commonStyles.footerShadow}
          >
            <Row justify="end" style={{ gap: "19px" }}>
              <Col>
                <Button
                  onClick={() => {
                    props.onCloseClientCompany();
                    form.resetFields();
                  }}
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                  className={commonStyles.buttonSettingsSecondary}
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button
                  onClick={() => {
                    form.submit();
                  }}
                  htmlType="button"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  {!props.client ? "Add Company" : "Save"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
};

export default EditClientCompanyDetailsDrawer;
