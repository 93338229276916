import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  leadSources: [],
  leadSource: null,
};
export const masterLeadSourceSlice = createSlice({
  name: "leadSource",
  initialState,
  reducers: {
    getLeadSourcesSlice(state, action) {
      state.leadSources = action.payload.result;
    },
    postLeadSourcesSlice(state, action) {
      state.leadSources = [...state.leadSources, action.payload.result];
    },
    getLeadSourceSlice(state, action) {
      state.leadSource = action.payload.result;
    },
    putLeadSourcesSlice(state, action) {
      state.leadSources = state.leadSources.map((data) => {
        if (data.id === action.payload.result.id) {
          return action.payload.result;
        } else {
          return data;
        }
      });
    },
    deleteLeadSourcesSlice(state, action) {
      state.leadSources = state.leadSources.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const {
  getLeadSourcesSlice,
  postLeadSourcesSlice,
  getLeadSourceSlice,
  putLeadSourcesSlice,
  deleteLeadSourcesSlice,
} = masterLeadSourceSlice.actions;
export default masterLeadSourceSlice.reducer;
