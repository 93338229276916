//For Email, Tags, leadSource,roles, candidate reason,job listing permission, job description, sharing medium,candidate settlement terms,department, email notification types, email placeholder, interview assessment questions, job types,status type,country,status url's
export const EMAIL_TEMPLATE_URL = "email_templates";
export const TAGS_URL = "tags";
export const LEAD_SOURCE = "leadsource";
export const CANDIDATE_REASON_URL = "candidate_reason";
export const JOB_LISTING_PERMISSION_URL = "job_listing";
export const JOB_DESCRIPTION_URL = "job_descriptions";
export const SHARING_MEDIUM_URL = "sharing_medium";
export const CANDIDATE_SETTLEMENT_TERMS_URL = "candidate_settlement_terms";
export const DEPARTMENT_URL = "department";
export const EMAIL_NOTIFICATION_TYPES_URL = "email_notification_types";
export const EMAIL_PLACEHOLDER_URL = "email_placeholder";
export const INTERVIEW_ASSESSMENT_QUESTIONS_URL =
  "interview_assesment_questions";
export const JOB_TYPES_URL = "job_types";
export const STATUS_TYPE_URL = "status_type";
export const STATUS_URL = "all_status";
export const ROLES_URL = "roles";
export const ROLES_TYPES_URL = "role_type";
export const ALL_ROLES_URL = "all_roles";
export const COUNTRY_URL = "loation_list";
