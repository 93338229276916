import {
  Radio,
  Card,
  Button,
  Upload,
  Form,
  Row,
  Col,
  Table,
  Modal,
  Image,
  Progress,
  message,
} from "antd";
import React, { useState } from "react";
import style from "../../../Components/Screens/Settings/Setting.module.css";
import DatatImportFile from "./DatatImportFile";
import commonStyles from "../../UI/CommonCss.module.css";
import { useEffect } from "react";
import { connect } from "react-redux";
import {
  getImportCandidatesAction,
  getRecruitmentDeleteImportCandidateData,
  getCandidatesExcelFormatAction,
  postRecruitmentImportCanidateAction,
  getRecruitmentDeleteAllImportedCandidatesData,
  postSettingsCandidateImportAction,
} from "../../../Reusable/Store/Action/RecruitmentAction";
import { CloseIcon } from "../../IconsComp/Icons";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import { useRef } from "react";
import excel from "../../../Assets/Image/excel.png";
import word from "../../../Assets/Image/word.png";
import pdf from "../../../Assets/Image/pdf.png";
import {
  postImportJobAction,
  getJobExcelFormatAction,
  getImportJobAction,
  getJobDeleteAllImportedJobData,
  getCompanyDeleteImportJobData,
} from "../../../Reusable/Store/Action/RequirementAction";
import {
  getImportClientCompanyAction,
  postClientCompanyImportAction,
  getCompanyExcelFormatAction,
  getCompanyDeleteAllImportedCompanyData,
  getCompanyDeleteImportCandidateData,
} from "../../../Reusable/Store/Action/ClientAction";

function DataImport(props) {
  let btnref = useRef(null);
  const [value, setValue] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSelected, setDataSelected] = useState([]);
  const [deleteAllModal, setDeleteAllModal] = useState(false);
  const [openDeleteModal, SetOpenDeleteModal] = useState(false);
  const [deletId, setDeleteId] = useState("");
  const [filesData, setFilesData] = useState("");
  const [progress, setProgress] = useState(0);
  const [fileResult, setFileResult] = useState(false);

  //for client import
  const [filesResultCompany, setFilesResultCompany] = useState("");
  const [progressCompany, setProgressCompany] = useState(0);
  const [filesDataCompany, setFilesDataCompany] = useState("");
  const [deleteAllModalCompany, setDeleteAllModalCompany] = useState(false);
  const [selectedRowKeysCompany, setSelectedRowKeysCompany] = useState([]);
  const [deletIdCompany, setDeleteIdCompany] = useState("");
  const [openDeleteModalCompany, SetOpenDeleteModalCompany] = useState(false);

  const handleChangeCompany = (info) => {
    setFilesResultCompany(false);

    if (!(info.target.files[0].size <= 2000000)) {
      message.warning("This file should be less than 2 MB", 3);
      setProgressCompany(0);
      return;
    }
    setFilesDataCompany(info.target.files[0]);
    setProgressCompany(50);
    setTimeout(() => {
      setProgressCompany(100);
    }, 300);
  };

  const onSelectChangeCompany = (newSelectedRowKeys) => {
    setSelectedRowKeysCompany(newSelectedRowKeys);
  };

  const rowSelectionCompany = {
    selectedRowKeys: selectedRowKeysCompany,
    onChange: onSelectChangeCompany,
  };

  const SaveDataCompany = () => {
    const val = {
      type: "company",
      ids: selectedRowKeysCompany,
    };

    props.postSelectedCandidatesFromPoolHandler(val, (success) => {
      if(success){
        props.getImportCompanyHandler();
      }
    });
  };

  const ImportCompanyData = () => {
    const Importdata = {
      importFile: filesDataCompany,
    };

    props.postImportCompanyDataHandler(Importdata, (success) => {
      if (success) {
        props.getImportCompanyHandler();
        setFilesDataCompany("");
      }
    });
  };

  const downloadCompanyExcelFormat = () => {
    props.getCompanyExcelFormatHandler();
  };

  const deleteAllImportedCompany = () => {
    props.deleteAllImportedCompanyDataHandler();
    setDeleteAllModalCompany(false);
  };

  const CancelAllCompanyDelete = () => {
    setDeleteAllModalCompany(false);
  };
  //end of client import

  //job import
  const [filesResultJob, setFilesResultJob] = useState("");
  const [progressJob, setProgressJob] = useState(0);
  const [filesDataJob, setFilesDataJob] = useState("");
  const [deleteAllModalJob, setDeleteAllModalJob] = useState(false);
  const [selectedRowKeysJob, setSelectedRowKeysJob] = useState([]);
  const [deletIdJob, setDeleteIdJob] = useState("");
  const [openDeleteModalJob, SetOpenDeleteModalJob] = useState(false);

  const handleChangeJob = (info) => {
    setFilesResultJob(false);

    if (!(info.target.files[0].size <= 2000000)) {
      message.warning("This file should be less than 2 MB", 3);
      setProgressJob(0);
      return;
    }
    setFilesDataJob(info.target.files[0]);
    setProgressJob(50);
    setTimeout(() => {
      setProgressJob(100);
    }, 300);
  };

  const SaveDataJob = () => {
    const val = {
      type: "job",
      ids: selectedRowKeysJob,
    };
    props.postSelectedCandidatesFromPoolHandler(val, (success) => {
      if(success){
        props.getImportJobHandler();
      }
    });
  };
  const onSelectChangeJob = (newSelectedRowKeys) => {
    setSelectedRowKeysJob(newSelectedRowKeys);
  };
  const rowSelectionJob = {
    selectedRowKeys: selectedRowKeysJob,
    onChange: onSelectChangeJob,
  };
  const ImportJobData = () => {
    const Importdata = {
      importFile: filesDataJob,
    };

    props.postImportJobDataHandler(Importdata, (success) => {
      if (success) {
        // props.getImportJobHandler();
        setFilesDataJob("");
      }
    });
  };

  const downloadJobExcelFormat = () => {
    props.getJobExcelFormat();
  };

  const deleteAllImportedJob = () => {
    props.deleteAllImportedJobDataHandler();
    setDeleteAllModalJob(false);
  };

  const CancelAllJobDelete = () => {
    setDeleteAllModalJob(false);
  };

  //end of job import

  useEffect(() => {
    props.getImportCandidatesHandler();
    props.getImportCompanyHandler();
    props.getImportJobHandler();
  }, []);
  // const columns = [
  //   {
  //     title: <p className={style.dataImportTitle}>Job ID</p>,
  //     dataIndex: "jobID",
  //     render: (data) => {
  //       return (
  //         <p
  //           className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
  //         >
  //           {data ? data : ""}
  //         </p>
  //       );
  //     },
  //   },
  //   {
  //     title: <p className={style.dataImportTitle}>Job Name</p>,
  //     dataIndex: "jobName",
  //     render: (data) => {
  //       return (
  //         <p
  //           className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
  //         >
  //           {data ? data : ""}
  //         </p>
  //       );
  //     },
  //   },

  //   {
  //     title: <p className={style.dataImportTitle}>Company Name</p>,
  //     dataIndex: "companyName",
  //     render: (data) => {
  //       return (
  //         <p
  //           className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
  //         >
  //           {data ? data : ""}
  //         </p>
  //       );
  //     },
  //   },
  //   {
  //     title: <p className={style.dataImportTitle}>Department</p>,
  //     dataIndex: "department",
  //     render: (data) => {
  //       return (
  //         <p
  //           className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
  //         >
  //           {data ? data : ""}
  //         </p>
  //       );
  //     },
  //   },
  //   {
  //     title: <p className={style.dataImportTitle}>Role</p>,
  //     dataIndex: "role",
  //     render: (data) => {
  //       return (
  //         <p
  //           className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
  //         >
  //           {data ? data : ""}
  //         </p>
  //       );
  //     },
  //   },
  //   {
  //     title: <p className={style.dataImportTitle}>Job Type</p>,
  //     dataIndex: "jobType",
  //     render: (data) => {
  //       return (
  //         <p
  //           className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
  //         >
  //           {data ? data : ""}
  //         </p>
  //       );
  //     },
  //   },
  // ];

  const columnsForCandidate = [
    {
      title: <p className={styles.LabelName}>Name</p>,
      dataIndex: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: <p className={styles.LabelName}>Contact Number</p>,
      dataIndex: "phone",
      render: (data) => {
        return (
          <p
            className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Skills</p>,
      dataIndex: "address",
      render: (data) => {
        return (
          <p
            className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Email id</p>,
      dataIndex: "email",
      render: (data) => {
        return (
          <p
            className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Source</p>,
      dataIndex: "leadSource",
      render: (data) => {
        return (
          <p
            className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: "",
      render: (text, render) => (
        <>
          <Row style={{ gap: "15px" }}>
            <Col
              onClick={() => {
                SaveSingleData(render.id);
              }}
            >
              <p
                style={{ color: "var(--primaryColor)" }}
                className={`${commonStyles.marginBottomRemove} ${commonStyles.coursorPointer}`}
              >
                Save
              </p>
            </Col>
            <Col
              onClick={() => {
                SetOpenDeleteModal(true);
                setDeleteId(render.id);
              }}
            >
              <p
                className={`${commonStyles.marginBottomRemove} ${commonStyles.coursorPointer} ${commonStyles.deleteRed}`}
                style={{ color: "#DF4242" }}
              >
                Delete
              </p>
            </Col>
          </Row>
        </>
        // )
      ),
    },
  ];

  const columnsForCompany = [
    {
      title: <p className={styles.LabelName}>Company Name</p>,
      dataIndex: "clientName",
      render: (text) => <a>{text}</a>,
    },
    {
      title: <p className={styles.LabelName}>Company Email & Number</p>,
      dataIndex: "contactPersonEmail",
      render: (data, record) => {
        return (
          <>
            <p
              className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
              style={{ marginBottom: "9px" }}
            >
              {" "}
              {record.clientEmail}
            </p>
            <p
              className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
            >
              {record.clientPhone}
            </p>{" "}
          </>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Contact Person</p>,
      dataIndex: "contactPerson",
      render: (data) => {
        return (
          <p
            className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Contact Person Email & Number</p>,
      dataIndex: "contactPersonEmail",
      render: (data, record) => {
        return (
          <>
            <p
              className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
              style={{ marginBottom: "9px" }}
            >
              {" "}
              {record.contactPersonEmail}
            </p>
            <p
              className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
            >
              {record.contactPersonPhone}
            </p>{" "}
          </>
        );
      },
    },
    {
      title: "",
      render: (text, render) => (
        <>
          <Row style={{ gap: "15px" }}>
            <Col
              onClick={() => {
                SaveSingleDataCompany(render.id);
              }}
            >
              <p
                style={{ color: "var(--primaryColor)" }}
                className={`${commonStyles.marginBottomRemove} ${commonStyles.coursorPointer}`}
              >
                Save
              </p>
            </Col>
            <Col
              onClick={() => {
                SetOpenDeleteModalCompany(true);
                setDeleteIdCompany(render.id);
              }}
            >
              <p
                className={`${commonStyles.marginBottomRemove} ${commonStyles.coursorPointer} ${commonStyles.deleteRed}`}
                style={{ color: "#DF4242" }}
              >
                Delete
              </p>
            </Col>
          </Row>
        </>
        // )
      ),
    },
  ];

  const columnsForJob = [
    {
      title: <p className={styles.LabelName}>Job Name</p>,
      dataIndex: "title",
      render: (data) => {
        return (
          <p
            className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Company Name</p>,
      dataIndex: "company",
      render: (data) => {
        return (
          <p
            className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Department</p>,
      dataIndex: "department",
      render: (data) => {
        return (
          <p
            className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Role</p>,
      dataIndex: "role",
      render: (data) => {
        return (
          <p
            className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Job Type</p>,
      dataIndex: "jobType",
      render: (data) => {
        return (
          <p
            className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: "",
      render: (text, render) => (
        <>
          <Row style={{ gap: "15px" }}>
            <Col
              onClick={() => {
                SaveSingleDataJob(render.id);
              }}
            >
              <p
                style={{ color: "var(--primaryColor)" }}
                className={`${commonStyles.marginBottomRemove} ${commonStyles.coursorPointer}`}
              >
                Save
              </p>
            </Col>
            <Col
              onClick={() => {
                SetOpenDeleteModalJob(true);
                setDeleteIdJob(render.id);
              }}
            >
              <p
                className={`${commonStyles.marginBottomRemove} ${commonStyles.coursorPointer} ${commonStyles.deleteRed}`}
                style={{ color: "#DF4242" }}
              >
                Delete
              </p>
            </Col>
          </Row>
        </>
        // )
      ),
    },
  ];
  const data = [
    {
      key: "1",

      jobID: "DV1234",
      jobName: "UX Designer",
      companyName: "Devusoft",
      department: "Design",
      role: "Design",
      jobType: "Full Time",
    },
    {
      key: "2",

      jobID: "DV1234",
      jobName: "UX Designer",
      companyName: "Devusoft",
      department: "Design",
      role: "Design",
      jobType: "Full Time",
    },
    {
      key: "3",
      jobID: "DV1234",
      jobName: "UX Designer",
      companyName: "Devusoft",
      department: "Design",
      role: "Design",
      jobType: "Full Time",
    },
    {
      key: "4",

      jobID: "DV1234",
      jobName: "UX Designer",
      companyName: "Devusoft",
      department: "Design",
      role: "Design",
      jobType: "Full Time",
    },
    {
      key: "5",

      jobID: "DV1234",
      jobName: "UX Designer",
      companyName: "Devusoft",
      department: "Design",
      role: "Design",
      jobType: "Full Time",
    },
  ];
  const onChangeHandler = (event) => {
    setValue(event.target.value);
  };

  const [fileList, setFileList] = useState([]);
  const propsq = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  //for data import candidate
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setDataSelected(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const SaveData = () => {
    const val = {
      type: "candidate",
      ids: dataSelected,
    };

    props.postSelectedCandidatesFromPoolHandler(val, (success) => {
      if(success){
        props.getImportCandidatesHandler();
      }
    });
  };

  function closeDeleteHandler() {
    SetOpenDeleteModal(false);
  }

  function confirmDeleteHandler() {
    SetOpenDeleteModal(false);
    props.deleteCandidateDetailsHandler(deletId, (success) => {});
    setDeleteId("");
  }

  //single data delete company
  function confirmDeleteHandlerCompany() {
    SetOpenDeleteModalCompany(false);
    props.deleteCompanyDetailsHandler(deletIdCompany, (success) => {});
    setDeleteIdCompany("");
  }

  function closeDeleteHandlerCompany() {
    SetOpenDeleteModalCompany(false);
  }
  //end

  //single data delete jobs
  function confirmDeleteHandlerJob() {
    SetOpenDeleteModalJob(false);
    props.deleteJobDetailsHandler(deletIdJob, (success) => {
      if(success){
        props.getImportJobHandler();
      }
    });
    setDeleteIdJob("");
  }

  function closeDeleteHandlerJob() {
    SetOpenDeleteModalJob(false);
  }
  //end
  const deleteAllImportedCandidates = () => {
    props.deleteAllImportedCandiatesHandler();
    setDeleteAllModal(false);
  };

  const CancelAllDelete = () => {
    setDeleteAllModal(false);
  };

  const SaveSingleData = (data) => {
    const val = {
      type: "candidate",
      ids: [data],
    };
    props.postSelectedCandidatesFromPoolHandler(val, (success) => {
      if(success){
        props.getImportCandidatesHandler();
      }
    });
  };

  const SaveSingleDataCompany = (data) => {
    const val = {
      type: "company",
      ids: [data],
    };
    props.postSelectedCandidatesFromPoolHandler(val, (success) => {
      if(success){
        props.getImportCompanyHandler();
      }
    });
  };

  const SaveSingleDataJob = (data) => {
    const val = {
      type: "job",
      ids: [data],
    };
    props.postSelectedCandidatesFromPoolHandler(val, (success) => {
      if(success){
        props.getImportJobHandler();
      }
    });
  };

  const downloadExcelFormat = () => {
    props.getCandidateExcelFormat();
  };

  const handleChange = (info) => {
    setFileResult(false);

    if (!(info.target.files[0].size <= 2000000)) {
      message.warning("This file should be less than 2 MB", 3);
      setProgress(0);
      return;
    }
    setFilesData(info.target.files[0]);
    setProgress(50);
    setTimeout(() => {
      setProgress(100);
    }, 300);
  };

  const ImportCandidatesData = () => {
    const Importdata = {
      importFile: filesData,
    };

    props.postImportCandidatesDataHandler(Importdata, (success) => {
      if (success) {
        props.getImportCandidatesHandler();
        setFilesData("");
      }
    });
  };
  //end of data import candidate

  //for importing client files

  //end of import candidates files
  const contetnt = () => {
    if (value === 1)
      return (
        <>
          {props.import_Job_Data.length === 0 ? (
            <Card style={{ padding: "22px", borderTop: "1px solid #7c7c7c54" }}>
              <h4
                style={{
                  marginBottom: "29px",
                  fontSize: "16px",
                  color: "var(--headings-color)",
                  fontWeight: 500,
                  fontFamily: "Helvetica,Product Sans Medium",
                }}
              >
                Import Type{" "}
                <span style={{ color: "var(--body-text-color)" }}>
                  (Download Button Lead to Download the Excel Sheet)
                </span>
              </h4>
              <p
                style={{
                  color: "var(--body-text-color)",
                  marginBottom: "20px",
                }}
              >
                Job Order
              </p>

              {filesDataJob && (
                <div
                  style={{
                    margin: "22px",
                    display: "flex",
                    alignItems: "center",
                    padding: "14px 8px 17px 8px",
                    backgroundColor: "var(--progress-card)",
                    borderRadius: "8px",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Image
                      onClick={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      preview={false}
                      src={data.type === "application/pdf" ? pdf : excel}
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "6px",
                        position: "relative",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      margin: "0px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{ color: "var(--headings-color)" }}
                        className={`${commonStyles.marginBottomRemove} ${commonStyles.formInputLabel}`}
                      >
                        {filesDataJob.name}
                      </p>
                      <p
                        className={`${commonStyles.marginBottomRemove} ${commonStyles.formInputLabel}`}
                      >
                        {progressJob == "0"
                          ? "0%"
                          : progressJob == "50"
                          ? "50%"
                          : "100%"}
                      </p>
                    </div>
                    <Progress percent={progressJob} size="small" />
                  </div>
                  <div>
                    <span
                      onClick={() => {
                        setFilesDataJob("");
                      }}
                      style={{
                        position: "relative",
                        paddingTop: "5px",
                        paddingLeft: "1px",
                        cursor: "pointer",
                      }}
                    >
                      <CloseIcon color={"var(--headings-color)"} />
                    </span>
                  </div>
                </div>
              )}

              <input
                accept=".xlsx"
                hidden
                type="file"
                name={["fileUpload"]}
                multiple
                onChange={handleChangeJob}
                ref={(input) => {
                  btnref = input;
                }}
              />
              {!filesDataJob && (
                <div
                  className={`dataImportUploadStyle`}
                  style={{
                    borderRadius: "0px",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      marginTop: "26px",
                      textAlign: "center",
                      color: "var(--body-text-color)",
                    }}
                    className={`${commonStyles.chosenFileTextColor}`}
                  >
                    No File Chosen
                  </p>
                  <Button
                    style={{
                      padding: "12px 0px",
                      display: "block",
                      position: "relative",
                      margin: "0px auto 33px auto",
                    }}
                    type="primary"
                    className={commonStyles.buttonSettingsPrimary}
                    onClick={(e) => {
                      e.preventDefault();
                      btnref.click();
                      return false;
                    }}
                  >
                    Choose File
                  </Button>
                </div>
              )}
              <Row style={{ gap: "15px", marginTop: "16px" }}>
                <Col>
                  <Button
                    className={commonStyles.buttonSettingsPrimary}
                    onClick={ImportJobData}
                    type="primary"
                  >
                    Import
                  </Button>
                </Col>
                <Col>
                  <Button
                    className={commonStyles.buttonSettingsSecondary}
                    type="default"
                    onClick={downloadJobExcelFormat}
                  >
                    Download
                  </Button>
                </Col>
              </Row>
            </Card>
          ) : (
            <Row style={{ borderTop: "1px solid #7c7c7c54" }}>
              <Col md={24}>
                <Table
                  pagination={false}
                  scroll={{ x: "auto" }}
                  rowSelection={rowSelectionJob}
                  rowKey="id"
                  className="importCandidateTable"
                  title={() => (
                    <>
                      <Row
                        align="middle"
                        justify="space-between"
                        style={{ borderBottom: "1px solid #7c7c7c54" }}
                      >
                        <Col>
                          <h3
                            style={{
                              fontSize: "16px",
                              color: "var(--headings-color)",
                              padding: "22px 0px 22px 22px",
                              fontFamily: "Helvetica,ProductSans",
                            }}
                            className={`${commonStyles.marginBottomRemove} `}
                          >
                            Imported Job List
                          </h3>
                        </Col>
                        <Col>
                          <Row
                            align="middle"
                            style={{ gap: "15px", marginRight: "20px" }}
                          >
                            <Col>
                              <Button
                                type="primary"
                                className={commonStyles.buttonSettingsPrimary}
                                onClick={SaveDataJob}
                              >
                                Save
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                onClick={() => {
                                  setSelectedRowKeysJob([]);
                                }}
                                type="default"
                                className={commonStyles.buttonSettingsSecondary}
                              >
                                Clear
                              </Button>
                            </Col>
                            <Col
                              onClick={() => {
                                // deleteAllImportedJob();
                                setDeleteAllModalJob(true);
                              }}
                            >
                              <p
                                className={`${commonStyles.marginBottomRemove} ${commonStyles.coursorPointer} ${commonStyles.deleteRed}`}
                              >
                                Delete All
                              </p>
                            </Col>
                            {/* <Col
                          onClick={() => {
                            //here
                          }}
                        >
                          <p
                            style={{ color: "var(--primaryColor)" }}
                            className={`${commonStyles.marginBottomRemove} ${commonStyles.coursorPointer}`}
                          >
                            Select All
                          </p>
                        </Col> */}
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                  columns={columnsForJob}
                  dataSource={props.import_Job_Data}
                />
                <Modal
                  closable={false}
                  title={
                    <Row style={{ gap: "350px" }}>
                      <Col>
                        <p className={commonStyles.popUpHeadingText}>
                          Delete Job
                        </p>
                      </Col>
                      <Col>
                        <div
                          style={{ float: "right" }}
                          onClick={() => {
                            closeDeleteHandlerJob();
                          }}
                          className={`${commonStyles.coursorPointer} `}
                        >
                          <CloseIcon
                            color={"var(--body-text-color)" || "var(--theme)"}
                          />
                        </div>

                        <Modal
                          closable={false}
                          title={
                            <Row style={{ gap: "238px" }}>
                              <Col>
                                <p className={commonStyles.popUpHeadingText}>
                                  Delete All Job
                                </p>
                              </Col>
                              <Col>
                                <div
                                  style={{ float: "right" }}
                                  onClick={() => {
                                    closeDeleteHandler();
                                  }}
                                  className={`${commonStyles.coursorPointer} `}
                                >
                                  <CloseIcon
                                    color={
                                      "var(--body-text-color)" || "var(--theme)"
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                          }
                          footer={
                            <>
                              <Row
                                style={{ gap: "10px" }}
                                justify="end"
                                align="middle"
                              >
                                <Col>
                                  <Button
                                    type="default"
                                    className={
                                      commonStyles.buttonSettingsSecondary
                                    }
                                    style={{
                                      height: "48px",
                                      fontSize: "16px",
                                      width: "181px",
                                    }}
                                    onClick={() => {
                                      CancelAllJobDelete();
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                                <Col>
                                  <Button
                                    type="primary"
                                    className={
                                      commonStyles.buttonSettingsPrimary
                                    }
                                    onClick={() => {
                                      confirmDeleteHandlerJob();
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          }
                          open={deleteAllModal}
                          onOk={confirmDeleteHandler}
                        >
                          <Row>
                            <Col>
                              <p className={commonStyles.tableHeadingTextData}>
                                Are you sure you want to delete All Jobs
                              </p>
                            </Col>
                          </Row>
                        </Modal>
                      </Col>
                    </Row>
                  }
                  footer={
                    <>
                      <Row style={{ gap: "10px" }} justify="end" align="middle">
                        <Col>
                          <Button
                            type="default"
                            className={commonStyles.buttonSettingsSecondary}
                            style={{
                              height: "48px",
                              fontSize: "16px",
                              width: "181px",
                            }}
                            onClick={() => {
                              closeDeleteHandlerJob();
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            className={commonStyles.buttonSettingsPrimary}
                            onClick={() => {
                              confirmDeleteHandlerJob();
                            }}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </>
                  }
                  open={openDeleteModalJob}
                  onOk={confirmDeleteHandlerJob}
                >
                  <Row>
                    <Col>
                      <p className={commonStyles.tableHeadingTextData}>
                        Are you sure you want to delete
                      </p>
                    </Col>
                  </Row>
                </Modal>

                {/* delete all modal  */}
                <Modal
                  closable={false}
                  title={
                    <Row style={{ gap: "320px" }}>
                      <Col>
                        <p className={commonStyles.popUpHeadingText}>
                          Delete All jobs
                        </p>
                      </Col>
                      <Col>
                        <div
                          style={{ float: "right" }}
                          onClick={() => {
                            CancelAllJobDelete();
                          }}
                          className={`${commonStyles.coursorPointer} `}
                        >
                          <CloseIcon
                            color={"var(--body-text-color)" || "var(--theme)"}
                          />
                        </div>
                      </Col>
                    </Row>
                  }
                  footer={
                    <>
                      <Row style={{ gap: "10px" }} justify="end" align="middle">
                        <Col>
                          <Button
                            type="default"
                            className={commonStyles.buttonSettingsSecondary}
                            style={{
                              height: "50px",
                              fontSize: "16px",
                              width: "146px",
                            }}
                            onClick={() => {
                              CancelAllJobDelete();
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            className={commonStyles.buttonSettingsPrimary}
                            onClick={() => {
                              deleteAllImportedJob();
                            }}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </>
                  }
                  open={deleteAllModalJob}
                  onOk={confirmDeleteHandler}
                >
                  <Row>
                    <Col>
                      <p className={commonStyles.tableHeadingTextData}>
                        Are you sure you want to delete All Jobs
                      </p>
                    </Col>
                  </Row>
                </Modal>
              </Col>
            </Row>
          )}
        </>
      );
    else if (value === 2)
      return (
        <>
          {props.import_Client_Company_List_data.length === 0 ? (
            <Card style={{ padding: "22px", borderTop: "1px solid #7c7c7c54" }}>
              <h4
                style={{
                  marginBottom: "29px",
                  fontSize: "16px",
                  color: "var(--headings-color)",
                  fontWeight: 500,
                  fontFamily: "Helvetica,Product Sans Medium",
                }}
              >
                Import Type{" "}
                <span style={{ color: "var(--body-text-color)" }}>
                  (Download Button Lead to Download the Excel Sheet)
                </span>
              </h4>
              <p
                style={{
                  color: "var(--body-text-color)",
                  marginBottom: "20px",
                }}
              >
                Company
              </p>

              {filesDataCompany && (
                <div
                  style={{
                    margin: "22px",
                    display: "flex",
                    alignItems: "center",
                    padding: "14px 8px 17px 8px",
                    backgroundColor: "var(--progress-card)",
                    borderRadius: "8px",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Image
                      onClick={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      preview={false}
                      src={data.type === "application/pdf" ? pdf : excel}
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "6px",
                        position: "relative",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      margin: "0px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{ color: "var(--headings-color)" }}
                        className={`${commonStyles.marginBottomRemove} ${commonStyles.formInputLabel}`}
                      >
                        {filesDataCompany.name}
                      </p>
                      <p
                        className={`${commonStyles.marginBottomRemove} ${commonStyles.formInputLabel}`}
                      >
                        {progressCompany == "0"
                          ? "0%"
                          : progressCompany == "50"
                          ? "50%"
                          : "100%"}
                      </p>
                    </div>
                    <Progress percent={progressCompany} size="small" />
                  </div>
                  <div>
                    <span
                      onClick={() => {
                        setFilesDataCompany("");
                      }}
                      style={{
                        position: "relative",
                        paddingTop: "5px",
                        paddingLeft: "1px",
                        cursor: "pointer",
                      }}
                    >
                      <CloseIcon color={"var(--headings-color)"} />
                    </span>
                  </div>
                </div>
              )}

              <input
                accept=".xlsx"
                hidden
                type="file"
                name={["fileUpload"]}
                multiple
                onChange={handleChangeCompany}
                ref={(input) => {
                  btnref = input;
                }}
              />
              {!filesDataCompany && (
                <div
                  className={`dataImportUploadStyle`}
                  style={{
                    borderRadius: "0px",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      marginTop: "26px",
                      textAlign: "center",
                      color: "var(--body-text-color)",
                    }}
                    className={`${commonStyles.chosenFileTextColor}`}
                  >
                    No File Chosen
                  </p>
                  <Button
                    style={{
                      padding: "12px 0px",
                      display: "block",
                      position: "relative",
                      margin: "0px auto 33px auto",
                    }}
                    type="primary"
                    className={commonStyles.buttonSettingsPrimary}
                    onClick={(e) => {
                      e.preventDefault();
                      btnref.click();
                      return false;
                    }}
                  >
                    Choose File
                  </Button>
                </div>
              )}
              <Row style={{ gap: "15px", marginTop: "16px" }}>
                <Col>
                  <Button
                    className={commonStyles.buttonSettingsPrimary}
                    onClick={ImportCompanyData}
                    type="primary"
                  >
                    Import
                  </Button>
                </Col>
                <Col>
                  <Button
                    className={commonStyles.buttonSettingsSecondary}
                    type="default"
                    onClick={downloadCompanyExcelFormat}
                  >
                    Download
                  </Button>
                </Col>
              </Row>
            </Card>
          ) : (
            <Row style={{ borderTop: "1px solid #7c7c7c54" }}>
              <Col md={24}>
                <Table
                  pagination={false}
                  scroll={{ x: "auto" }}
                  rowSelection={rowSelectionCompany}
                  rowKey="id"
                  className="importCandidateTable"
                  title={() => (
                    <>
                      <Row
                        align="middle"
                        justify="space-between"
                        style={{ borderBottom: "1px solid #7c7c7c54" }}
                      >
                        <Col>
                          <h3
                            style={{
                              fontSize: "16px",
                              color: "var(--headings-color)",
                              padding: "22px 0px 22px 22px",
                              fontFamily: "Helvetica,ProductSans",
                            }}
                            className={`${commonStyles.marginBottomRemove} `}
                          >
                            Imported Company List
                          </h3>
                        </Col>
                        <Col>
                          <Row
                            align="middle"
                            style={{ gap: "15px", marginRight: "20px" }}
                          >
                            <Col>
                              <Button
                                type="primary"
                                className={commonStyles.buttonSettingsPrimary}
                                onClick={SaveDataCompany}
                              >
                                Save
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                onClick={() => {
                                  setSelectedRowKeysCompany([]);
                                }}
                                type="default"
                                className={commonStyles.buttonSettingsSecondary}
                              >
                                Clear
                              </Button>
                            </Col>
                            <Col
                              onClick={() => {
                                // deleteAllImportedCompany();
                                setDeleteAllModalCompany(true);
                              }}
                            >
                              <p
                                className={`${commonStyles.marginBottomRemove} ${commonStyles.coursorPointer} ${commonStyles.deleteRed}`}
                              >
                                Delete All
                              </p>
                            </Col>
                            {/* <Col
                          onClick={() => {
                            //here
                          }}
                        >
                          <p
                            style={{ color: "var(--primaryColor)" }}
                            className={`${commonStyles.marginBottomRemove} ${commonStyles.coursorPointer}`}
                          >
                            Select All
                          </p>
                        </Col> */}
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                  columns={columnsForCompany}
                  dataSource={props.import_Client_Company_List_data}
                />
                <Modal
                  closable={false}
                  title={
                    <Row style={{ gap: "290px" }}>
                      <Col>
                        <p className={commonStyles.popUpHeadingText}>
                          Delete Company
                        </p>
                      </Col>
                      <Col>
                        <div
                          style={{ float: "right" }}
                          onClick={() => {
                            closeDeleteHandlerCompany();
                          }}
                          className={`${commonStyles.coursorPointer} `}
                        >
                          <CloseIcon
                            color={"var(--body-text-color)" || "var(--theme)"}
                          />
                        </div>
                      </Col>
                    </Row>
                  }
                  footer={
                    <>
                      <Row style={{ gap: "10px" }} justify="end" align="middle">
                        <Col>
                          <Button
                            type="default"
                            className={commonStyles.buttonSettingsSecondary}
                            style={{
                              height: "48px",
                              fontSize: "16px",
                              width: "181px",
                            }}
                            onClick={() => {
                              closeDeleteHandlerCompany();
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            className={commonStyles.buttonSettingsPrimary}
                            onClick={() => {
                              confirmDeleteHandlerCompany();
                            }}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </>
                  }
                  open={openDeleteModalCompany}
                  onOk={confirmDeleteHandler}
                >
                  <Row>
                    <Col>
                      <p className={commonStyles.tableHeadingTextData}>
                        Are you sure you want to delete
                      </p>
                    </Col>
                  </Row>
                </Modal>

                {/* delete all modal  */}
                <Modal
                  closable={false}
                  title={
                    <Row style={{ gap: "238px" }}>
                      <Col>
                        <p className={commonStyles.popUpHeadingText}>
                          Delete All Company
                        </p>
                      </Col>
                      <Col>
                        <div
                          style={{ float: "right" }}
                          onClick={() => {
                            closeDeleteHandler();
                          }}
                          className={`${commonStyles.coursorPointer} `}
                        >
                          <CloseIcon
                            color={"var(--body-text-color)" || "var(--theme)"}
                          />
                        </div>
                      </Col>
                    </Row>
                  }
                  footer={
                    <>
                      <Row style={{ gap: "10px" }} justify="end" align="middle">
                        <Col>
                          <Button
                            type="default"
                            className={commonStyles.buttonSettingsSecondary}
                            style={{
                              height: "48px",
                              fontSize: "16px",
                              width: "181px",
                            }}
                            onClick={() => {
                              CancelAllDelete();
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            className={commonStyles.buttonSettingsPrimary}
                            onClick={() => {
                              deleteAllImportedCandidates();
                            }}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </>
                  }
                  open={deleteAllModal}
                  onOk={confirmDeleteHandler}
                >
                  <Row>
                    <Col>
                      <p className={commonStyles.tableHeadingTextData}>
                        Are you sure you want to delete All Company
                      </p>
                    </Col>
                  </Row>
                </Modal>
                <Modal
                  closable={false}
                  title={
                    <Row style={{ gap: "238px" }}>
                      <Col>
                        <p className={commonStyles.popUpHeadingText}>
                          Delete All Company
                        </p>
                      </Col>
                      <Col>
                        <div
                          style={{ float: "right" }}
                          onClick={() => {
                            CancelAllCompanyDelete();
                          }}
                          className={`${commonStyles.coursorPointer} `}
                        >
                          <CloseIcon
                            color={"var(--body-text-color)" || "var(--theme)"}
                          />
                        </div>
                      </Col>
                    </Row>
                  }
                  footer={
                    <>
                      <Row style={{ gap: "10px" }} justify="end" align="middle">
                        <Col>
                          <Button
                            type="default"
                            className={commonStyles.buttonSettingsSecondary}
                            style={{
                              height: "48px",
                              fontSize: "16px",
                              width: "181px",
                            }}
                            onClick={() => {
                              CancelAllCompanyDelete();
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            className={commonStyles.buttonSettingsPrimary}
                            onClick={() => {
                              deleteAllImportedCompany();
                            }}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </>
                  }
                  open={deleteAllModalCompany}
                  onOk={confirmDeleteHandler}
                >
                  <Row>
                    <Col>
                      <p className={commonStyles.tableHeadingTextData}>
                        Are you sure you want to delete All Company
                      </p>
                    </Col>
                  </Row>
                </Modal>
              </Col>
            </Row>
          )}
        </>
      );
    else
      return (
        <>
          {props.import_candidates_List_data.length === 0 ? (
            <Card style={{ padding: "22px", borderTop: "1px solid #7c7c7c54" }}>
              <h4
                style={{
                  marginBottom: "29px",
                  fontSize: "16px",
                  color: "var(--headings-color)",
                  fontWeight: 500,
                  fontFamily: "Helvetica,Product Sans Medium",
                }}
              >
                Import Type{" "}
                <span style={{ color: "var(--body-text-color)" }}>
                  (Download Button Lead to Download the Excel Sheet)
                </span>
              </h4>
              <p
                style={{
                  color: "var(--body-text-color)",
                  marginBottom: "20px",
                }}
              >
                Candidate
              </p>

              {filesData && (
                <div
                  style={{
                    margin: "22px",
                    display: "flex",
                    alignItems: "center",
                    padding: "14px 8px 17px 8px",
                    backgroundColor: "var(--progress-card)",
                    borderRadius: "8px",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Image
                      onClick={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      preview={false}
                      src={data.type === "application/pdf" ? pdf : excel}
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "6px",
                        position: "relative",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      margin: "0px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{ color: "var(--headings-color)" }}
                        className={`${commonStyles.marginBottomRemove} ${commonStyles.formInputLabel}`}
                      >
                        {filesData.name}
                      </p>
                      <p
                        className={`${commonStyles.marginBottomRemove} ${commonStyles.formInputLabel}`}
                      >
                        {progress == "0"
                          ? "0%"
                          : progress == "50"
                          ? "50%"
                          : "100%"}
                      </p>
                    </div>
                    <Progress percent={progress} size="small" />
                  </div>
                  <div>
                    <span
                      onClick={() => {
                        setFilesData("");
                      }}
                      style={{
                        position: "relative",
                        paddingTop: "5px",
                        paddingLeft: "1px",
                        cursor: "pointer",
                      }}
                    >
                      <CloseIcon color={"var(--headings-color)"} />
                    </span>
                  </div>
                </div>
              )}

              <input
                accept=".xlsx"
                hidden
                type="file"
                name={["fileUpload"]}
                multiple
                onChange={handleChange}
                ref={(input) => {
                  btnref = input;
                }}
              />
              {!filesData && (
                <div
                  className={`dataImportUploadStyle`}
                  style={{
                    borderRadius: "0px",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      marginTop: "26px",
                      textAlign: "center",
                      color: "var(--body-text-color)",
                    }}
                    className={`${commonStyles.chosenFileTextColor}`}
                  >
                    No File Chosen
                  </p>
                  <Button
                    style={{
                      padding: "12px 0px",
                      display: "block",
                      position: "relative",
                      margin: "0px auto 33px auto",
                    }}
                    type="primary"
                    className={commonStyles.buttonSettingsPrimary}
                    onClick={(e) => {
                      e.preventDefault();
                      btnref.click();
                      return false;
                    }}
                  >
                    Choose File
                  </Button>
                </div>
              )}
              <Row style={{ gap: "15px", marginTop: "16px" }}>
                <Col>
                  <Button
                    className={commonStyles.buttonSettingsPrimary}
                    onClick={ImportCandidatesData}
                    type="primary"
                  >
                    Import
                  </Button>
                </Col>
                <Col>
                  <Button
                    className={commonStyles.buttonSettingsSecondary}
                    type="default"
                    onClick={downloadExcelFormat}
                  >
                    Download
                  </Button>
                </Col>
              </Row>
            </Card>
          ) : (
            <Row style={{ borderTop: "1px solid #7c7c7c54" }}>
              <Col md={24}>
                <Table
                  pagination={false}
                  scroll={{ x: "auto" }}
                  rowSelection={rowSelection}
                  rowKey="id"
                  className="importCandidateTable"
                  title={() => (
                    <>
                      <Row
                        align="middle"
                        justify="space-between"
                        style={{ borderBottom: "1px solid #7c7c7c54" }}
                      >
                        <Col>
                          <h3
                            style={{
                              fontSize: "16px",
                              color: "var(--headings-color)",
                              padding: "22px 0px 22px 22px",
                              fontFamily: "Helvetica,ProductSans",
                            }}
                            className={`${commonStyles.marginBottomRemove} `}
                          >
                            Imported Candidate List
                          </h3>
                        </Col>
                        <Col>
                          <Row
                            align="middle"
                            style={{ gap: "15px", marginRight: "20px" }}
                          >
                            <Col>
                              <Button
                                type="primary"
                                className={commonStyles.buttonSettingsPrimary}
                                onClick={SaveData}
                              >
                                Save
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                onClick={() => {
                                  setSelectedRowKeys([]);
                                }}
                                type="default"
                                className={commonStyles.buttonSettingsSecondary}
                              >
                                Clear
                              </Button>
                            </Col>
                            <Col
                              onClick={() => {
                                // deleteAllImportedCandidates();
                                setDeleteAllModal(true);
                              }}
                            >
                              <p
                                className={`${commonStyles.marginBottomRemove} ${commonStyles.coursorPointer} ${commonStyles.deleteRed}`}
                              >
                                Delete All
                              </p>
                            </Col>
                            {/* <Col
                          onClick={() => {
                            //here
                          }}
                        >
                          <p
                            style={{ color: "var(--primaryColor)" }}
                            className={`${commonStyles.marginBottomRemove} ${commonStyles.coursorPointer}`}
                          >
                            Select All
                          </p>
                        </Col> */}
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                  columns={columnsForCandidate}
                  dataSource={props.import_candidates_List_data}
                />
                <Modal
                  closable={false}
                  title={
                    <Row style={{ gap: "289px" }}>
                      <Col>
                        <p className={commonStyles.popUpHeadingText}>
                          Delete Candidate
                        </p>
                      </Col>
                      <Col>
                        <div
                          style={{ float: "right" }}
                          onClick={() => {
                            closeDeleteHandler();
                          }}
                          className={`${commonStyles.coursorPointer} `}
                        >
                          <CloseIcon
                            color={"var(--body-text-color)" || "var(--theme)"}
                          />
                        </div>
                      </Col>
                    </Row>
                  }
                  footer={
                    <>
                      <Row style={{ gap: "10px" }} justify="end" align="middle">
                        <Col>
                          <Button
                            type="default"
                            className={commonStyles.buttonSettingsSecondary}
                            style={{
                              height: "48px",
                              fontSize: "16px",
                              width: "181px",
                            }}
                            onClick={() => {
                              closeDeleteHandler();
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            className={commonStyles.buttonSettingsPrimary}
                            onClick={() => {
                              confirmDeleteHandler();
                            }}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </>
                  }
                  open={openDeleteModal}
                  onOk={confirmDeleteHandler}
                >
                  <Row>
                    <Col>
                      <p className={commonStyles.tableHeadingTextData}>
                        Are you sure you want to delete
                      </p>
                    </Col>
                  </Row>
                </Modal>

                {/* delete all modal  */}
                <Modal
                  closable={false}
                  title={
                    <Row style={{ gap: "238px" }}>
                      <Col>
                        <p className={commonStyles.popUpHeadingText}>
                          Delete All Candidates
                        </p>
                      </Col>
                      <Col>
                        <div
                          style={{ float: "right" }}
                          onClick={() => {
                            closeDeleteHandler();
                          }}
                          className={`${commonStyles.coursorPointer} `}
                        >
                          <CloseIcon
                            color={"var(--body-text-color)" || "var(--theme)"}
                          />
                        </div>
                      </Col>
                    </Row>
                  }
                  footer={
                    <>
                      <Row style={{ gap: "10px" }} justify="end" align="middle">
                        <Col>
                          <Button
                            type="default"
                            className={commonStyles.buttonSettingsSecondary}
                            style={{
                              height: "48px",
                              fontSize: "16px",
                              width: "181px",
                            }}
                            onClick={() => {
                              CancelAllDelete();
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            className={commonStyles.buttonSettingsPrimary}
                            onClick={() => {
                              deleteAllImportedCandidates();
                            }}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </>
                  }
                  open={deleteAllModal}
                  onOk={confirmDeleteHandler}
                >
                  <Row>
                    <Col>
                      <p className={commonStyles.tableHeadingTextData}>
                        Are you sure you want to delete All Candidates
                      </p>
                    </Col>
                  </Row>
                </Modal>
              </Col>
            </Row>
          )}
        </>
      );
  };
  return (
    <>
      <Card style={{ padding: "20px" }}>
        <p
          style={{ marinTop: "30px", color: "var(--headings-color)" }}
          className={`${commonStyles.marginBottomRemove} ${commonStyles.tableHeadingText}`}
        >
          Select Import Type
        </p>
        <Radio.Group
          className="radioBtnText"
          onChange={onChangeHandler}
          value={value}
        >
          <Radio style={{ paddingRight: "20px" }} value={1}>
            Job Order
          </Radio>
          <Radio style={{ padding: "20px" }} value={2}>
            Company
          </Radio>
          <Radio style={{ padding: "20px" }} value={3}>
            Candidate
          </Radio>
        </Radio.Group>
      </Card>
      {contetnt()}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    import_candidates_List_data: state.recruitment.import_candidates_List_data,
    import_Client_Company_List_data:
      state.client.import_Client_Company_List_data,
    import_Job_Data: state.requirements.import_Job_Data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getImportCandidatesHandler: () => {
      dispatch(getImportCandidatesAction());
    },
    //for deleting candidates data
    deleteCandidateDetailsHandler: (id, callback) => {
      dispatch(getRecruitmentDeleteImportCandidateData(id, callback));
    },
    //
    //for downloading candidates excel format
    getCandidateExcelFormat: () => {
      dispatch(getCandidatesExcelFormatAction());
    },
    //
    //for posting import candidates file
    postImportCandidatesDataHandler: (val, callback) => {
      dispatch(postRecruitmentImportCanidateAction(val, callback));
    },
    //
    //for saving the candidate
    postSelectedCandidatesFromPoolHandler: (val, callback) => {
      dispatch(postSettingsCandidateImportAction(val, callback));
    },
    //
    //for delete all candidates data
    deleteAllImportedCandiatesHandler: () => {
      dispatch(getRecruitmentDeleteAllImportedCandidatesData());
    },
    //
    //for company data import
    getImportCompanyHandler: () => {
      dispatch(getImportClientCompanyAction());
    },

    postImportCompanyDataHandler: (val, callback) => {
      dispatch(postClientCompanyImportAction(val, callback));
    },
    //for deleting a single user
    deleteCompanyDetailsHandler: (id, callback) => {
      dispatch(getCompanyDeleteImportCandidateData(id, callback));
    },

    //for downloading company excel format
    getCompanyExcelFormatHandler: () => {
      dispatch(getCompanyExcelFormatAction());
    },

    //for deleting company details
    deleteAllImportedCompanyDataHandler: () => {
      dispatch(getCompanyDeleteAllImportedCompanyData());
    },
    //end

    // end of company import

    //for job import
    postImportJobDataHandler: (val, callback) => {
      dispatch(postImportJobAction(val, callback));
    },
    //
    //for downloading excel format
    getJobExcelFormat: () => {
      dispatch(getJobExcelFormatAction());
    },
    //
    //for getting list of all job
    getImportJobHandler: () => {
      dispatch(getImportJobAction());
    },
    //end of list jobs
    //for deleting job details
    deleteAllImportedJobDataHandler: () => {
      dispatch(getJobDeleteAllImportedJobData());
    },
    //end
    //for deleting single job
    deleteJobDetailsHandler: (id, callback) => {
      dispatch(getCompanyDeleteImportJobData(id, callback));
    },

    //end of job import
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DataImport);
