import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import authentication from "./Slice/AuthenticationSlice";
import emailTemplates from "./Slice/Master/MasterEmailTemplateSlice";
import settings from "./Slice/SettingsSlice";
import leadSource from "./Slice/Master/MasterLeadSourceSlice";
import candidateReasonSlice from "./Slice/Master/MasterCandidateReasonSlice";
import jobListingPermission from "./Slice/Master/MasterJobListingPermissionSlice";
import jobDescription from "./Slice/Master/MasterJobDescriptionSlice";
import sharingMedium from "./Slice/Master/MasterSharingMediumSlice";
import candidateSettlementTerm from "./Slice/Master/MasterCandidateSettlementTermsSlice";
import departments from "./Slice/Master/MasterDepartmentsSlice";
import emailNotificationTypes from "./Slice/Master/MasterEmailNotificationTypesSlice";
import emailPlaceholders from "./Slice/Master/MasterEmailPlaceholderSlice";
import interviewAssessmentQuestions from "./Slice/Master/MasterInterviewAssessmentQuestionsSlice";
import jobtype from "./Slice/Master/MasterJobTypesSlice";
import statusTypes from "./Slice/Master/MasterStatusTypeSlice";
import status from "./Slice/Master/MasterStatusSlice";
import tags from "./Slice/Master/MasterTagsSlice";
import roles from "./Slice/Master/MasterRolesSlice";
import roleTypes from "./Slice/Master/MasterRolesTypeSlice";
import candidates from "./Slice/CandidatesSlice";
import employee from "./Slice/EmployeesSlice";
import uploads from "./Slice/CandidatesSlice";
import upload from "./Slice/CandidatesSlice";
import country from "./Slice/Master/MasterCountrySlice";
import client from "./Slice/ClientSlice";
import authpermissions from "./Slice/AuthPermissionsSlice";
import permission from "./Slice/Master/MasterpermissionSlice";
import requirements from "./Slice/RequirementSlice";
import recruitment from "./Slice/RecruitmentSlice";
import dashboard from "./Slice/DashboardSlice";
import taskAndCalendar from "./Slice/TaskAndCalendarSlice";
import notification from "./Slice/NotificationSlice";
import publicRequirement from "./Slice/PublicRequirementSlice";
import globalSearch from "./Slice/GlobalSearchSlice";
import reports from "./Slice/ReportsSlice";
import loadingIcon from "./Slice/LoadingIconSlice";
const rootReducer = combineReducers({
  authentication,
  emailTemplates,
  tags,
  settings,
  leadSource,
  candidateReasonSlice,
  jobListingPermission,
  jobDescription,
  sharingMedium,
  candidateSettlementTerm,
  departments,
  emailNotificationTypes,
  emailPlaceholders,
  interviewAssessmentQuestions,
  jobtype,
  statusTypes,
  status,
  roles,
  roleTypes,
  candidates,
  employee,
  uploads,
  upload,
  country,
  client,
  authpermissions,
  permission,
  requirements,
  taskAndCalendar,
  recruitment,
  dashboard,
  notification,
  publicRequirement,
  globalSearch,
  reports,
  loadingIcon,
});
const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: true,
});
export default store;
