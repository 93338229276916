import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  statusTypes: [],
  statusType: null,
};
export const masterStatusTypeSlice = createSlice({
  name: "statusTypes",
  initialState,
  reducers: {
    getStatusTypesSlice(state, action) {},
    getStatusTypeSlice(state, action) {},
    postStatusTypesSlice(state, action) {},
    putStatusTypesSlice(state, action) {},
    deleteStatusTypesSlice(state, action) {},
  },
});
export const {
  getStatusTypesSlice,
  getStatusTypeSlice,
  postStatusTypesSlice,
  putStatusTypesSlice,
  deleteStatusTypesSlice,
} = masterStatusTypeSlice.actions;
export default masterStatusTypeSlice.reducer;
