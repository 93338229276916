import styles from "../../AddCandidatesComp/AddCandidatesComp.module.css";
import { Button, Card, Col, Pagination, Row } from "antd";
import React, { useState } from "react";
import { CaretRightIcon } from "../../../IconsComp/Icons";

import ScheduleInterviewDrawer from "./ScheduleInterviewDrawer";
import InterviewDetails from "./InterviewDetails";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import moment from "moment";
import TaskCalendarTabs from "../../TaskCalendar/TaskCalendarTabs";
import InterviewPendingDetails from "./InterviewPendingDetails";
import { useLocation } from "react-router";
import { connect } from "react-redux";
import {
  getInterView2Action,
  getInterViewAction,
} from "../../../../Reusable/Store/Action/RecruitmentAction";
import { checkPermissionAction } from "../../../../Reusable/Store/Action/AuthPermissionsAction";
import PermissionMessage from "../../../UI/PermissionMessage";
import {
  getCandidatesJobAction,
  postCompletedInterviewPerformanceAction,
} from "../../../../Reusable/Store/Action/TaskAndCalendarAction";
import { useEffect } from "react";
import RescheduleInterview from "./RescheduleInterview";

function Interview(props) {
  const location = useLocation();

  const [status, setStatus] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [interviewName, setInterviewName] = useState("");
  const [interviewDetailsDrawer, setInterviewDetailsDrawer] = useState(false);
  const [openDrawerPending, setOpenDrawerPending] = useState(false);
  const [interviewDetails, setInterviewDetails] = useState({});
  const [rsOpen, setRsOpen] = useState(false);
  const [interviewId, setInterviewId] = useState("");

  // const [paginationDataForInterview, setPaginationDataForInterview] = useState({
  //   current: 1, //page
  //   minIndex: (1 - 1) * 20, // (page-1) * pageSize
  //   maxIndex: 1 * 20, //page*pageSize
  // });
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });

  useEffect(() => {
    //this location.state is comming from candidateTab we get object in this {id:"candidatesId",from:"candidateList"}
    props.getCandidateRelatedJobsHandler(
      location && location.state && location.state.id && location.state.id
    );
  }, []);

  function closeDrawerHandler() {
    setOpenDrawer(false);
  }
  function closeInterviewDetailsDrawerHandler() {
    setInterviewDetailsDrawer(false);
  }
  const dateFormate =
    props.onSettingsData && props.onSettingsData.dateFormat
      ? props.onSettingsData.dateFormat
      : "DD/MM/YYYY";

  // const handleChange = (page) => {
  //   setPaginationDataForInterview({
  //     current: page,
  //     minIndex: (page - 1) * 20,
  //     maxIndex: page * 20,
  //   });
  // };
  // index >= paginationDataForInterview.minIndex &&
  //               index < paginationDataForInterview.maxIndex &&

  return (
    <>
      <Row>
        <Col xs={24} md={24}>
          <Card
            style={{
              width: "100%",
              marginTop: "-12px",
              marginBottom: "3px",
              minHeight: "70px",
              padding: "26px",
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
            }}
          >
            <Row justify="space-between" align="middle">
              <Col>
                <p
                  style={{ margin: "0px" }}
                  className={styles.summaryInfoMainText}
                >
                  Interview List
                </p>
              </Col>
              {props.permissions &&
              location.state &&
              location.state.from &&
              props.onCandidateData &&
              props.permissions.find((data) => data === "interview-add") &&
              props.onCandidateData.status &&
              props.onCandidateData.status === "active" &&
              location.state.from === "candidateList" ? (
                <Col>
                  <Button
                    className={commonCssStyle.buttonSettingsPrimary}
                    onClick={() => {
                      props.getCheckPermissionHandler(
                        "interview-add",
                        (success) => {
                          if (success) {
                            setOpenDrawer(true);
                            setInterviewName("candidateDetails");
                          } else {
                            setOpenPMModal({
                              open: true,
                              permission: "Interview Add",
                            });
                          }
                        }
                      );
                    }}
                    type="primary"
                    style={{ height: "45px", width: "auto" }}
                  >
                    Schedule Interview
                  </Button>
                </Col>
              ) : (
                <></>
              )}
              {props.permissions &&
              location.state &&
              location.state.from &&
              props.permissions.find((data) => data === "interview-add") &&
              location.state.from === "recruitmentList" ? (
                <Col>
                  <Button
                    className={commonCssStyle.buttonSettingsPrimary}
                    onClick={() => {
                      props.getCheckPermissionHandler(
                        "interview-add",
                        (success) => {
                          if (success) {
                            setOpenDrawer(true);
                            setInterviewName("candidateDetails");
                          } else {
                            setOpenPMModal({
                              open: true,
                              permission: "Interview Add",
                            });
                          }
                        }
                      );
                    }}
                    type="primary"
                    style={{ height: "45px", width: "auto" }}
                  >
                    Schedule Interview
                  </Button>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={24}>
          {props.onCandidateInterview &&
            props.onCandidateInterview.map((data, index) => {
              return (
                <Card
                  key={index}
                  style={{
                    marginBottom: "16px",
                    padding: "30px",
                  }}
                >
                  <Row align="middle">
                    <Col xs={24} lg={4}>
                      <p className={commonCssStyle.tableHeadingsColor}>
                        {data.interviewer &&
                        data.interviewer.split("(")[1] === "+0)"
                          ? data.interviewer.split("(")[0]
                          : data.interviewer}
                      </p>
                      {/* {data.stage && (
                        <p className={commonCssStyle.tableData}>
                          {`${data.stage} Round`}
                        </p>
                      )} */}
                    </Col>
                    <Col xs={24} lg={5}>
                      <p className={commonCssStyle.tableHeadingsColor}>
                        {data.interviewStartTime} - {data.interviewEndTime}
                      </p>
                      <p className={commonCssStyle.tableData}>
                        {`${data.duration} interview`}
                      </p>
                    </Col>
                    <Col xs={24} lg={3}>
                      <p className={commonCssStyle.tableHeadingsColor}>
                        {moment(data.interviewDate).format(dateFormate)}
                      </p>
                      <p className={commonCssStyle.tableData}>
                        {data.interviewType}
                      </p>
                    </Col>

                    <Col xs={24} lg={5}>
                      {data.status === "pending" ||
                      data.status === "Pending" ? (
                        <p style={{ color: "#FF9A02" }}>{data.status}</p>
                      ) : (
                        <p style={{ color: "#0E8553" }}>{data.status}</p>
                      )}
                    </Col>

                    <Col xs={24} lg={4}>
                      <Row justify="space-between">
                        {data.status === "pending" ||
                        (data.status === "Pending" &&
                          props.permissions &&
                          props.permissions.find(
                            (data) => data === "interview-edit"
                          )) ? (
                          <Col>
                            <p
                              className={`${commonCssStyle.coursorPointer}`}
                              style={{
                                float: "right",
                                color: "var(--primaryColor)",
                              }}
                              onClick={() => {
                                props.getCheckPermissionHandler(
                                  "interview-edit",
                                  (success) => {
                                    if (success) {
                                      if (
                                        data.status === "pending" ||
                                        data.status === "Pending"
                                      ) {
                                        props.getTaskInterviewData(data.id);
                                      } else {
                                        props.onInterviewDetailsData(data.id);
                                        setInterviewDetailsDrawer(true);
                                      }
                                    } else {
                                      setOpenPMModal({
                                        open: true,
                                        permission: "Interview Edit",
                                      });
                                    }
                                  }
                                );
                              }}
                            >
                              Edit
                            </p>
                          </Col>
                        ) : data.rescheduleRequest &&
                          data.rescheduleRequest == 1 ? (
                          <Col xs={24} lg={4}>
                            <>
                              <Button
                                onClick={() => {
                                  setInterviewId(data.id);
                                  props.getInterviewDetailsHandler(
                                    data.id,
                                    (success) => {
                                      if (success) {
                                        //this location.state is comming from candidateTab we get object in this {id:"candidatesId",from:"candidateList"}

                                        props.getCandidateRelatedJobsHandler(
                                          location &&
                                            location.state &&
                                            location.state.id &&
                                            location.state.id
                                        );
                                        setRsOpen(true);
                                      }
                                    }
                                  );
                                }}
                                type="primary"
                                size="small"
                              >
                                Reschedule
                              </Button>
                            </>
                          </Col>
                        ) : (
                          <></>
                        )}
                      </Row>
                    </Col>
                    <Col flex={1}>
                      {props.permissions &&
                        props.permissions.find(
                          (data) => data === "interview-detail"
                        ) && (
                          <span
                            className={commonCssStyle.coursorPointer}
                            style={{
                              float: "right",
                            }}
                            onClick={() => {
                              // if (data.status === "pending") {
                              //   props.getTaskInterviewData(data.id);
                              // } else {
                              //   props.onInterviewDetailsData(data.id);
                              //   setInterviewDetailsDrawer(true);
                              // }
                              props.getCheckPermissionHandler(
                                "interview-detail",
                                (success) => {
                                  if (success) {
                                    if (
                                      data.status === "pending" ||
                                      data.status === "Pending"
                                    ) {
                                      props.getInterviewDetailsHandler(
                                        data.id,
                                        (success) => {
                                          if (success) {
                                            setOpenDrawerPending(true);
                                          }
                                        }
                                      );
                                    } else {
                                      props.onInterviewDetailsData(data.id);
                                      setInterviewDetailsDrawer(true);
                                    }
                                  } else {
                                    setOpenPMModal({
                                      open: true,
                                      permission: "Interview Details",
                                    });
                                  }
                                }
                              );
                            }}
                          >
                            <CaretRightIcon color={"var(--primaryColor)"} />
                          </span>
                        )}
                    </Col>
                  </Row>
                </Card>
              );
            })}
        </Col>
        {/* <Col xs={24} md={24}>
          <Row justify="end">
            <Col>
              <Pagination
                // itemRender={
                //   props.onCandidateInterview && props.onCandidateInterview
                // }
                onChange={handleChange}
                size="small"
                defaultCurrent={1}
                total={
                  props.onCandidateInterview &&
                  props.onCandidateInterview.length - 1
                }
              />
            </Col>
          </Row>
        </Col> */}
      </Row>
      {/* schedule interview drawer for candidate details page start */}
      <ScheduleInterviewDrawer
        candidateJobs={props.candidatesJob}
        onCandidateName={props.onCandidateName}
        onInterviewName={interviewName} //specifing from schedule drawer from which page the data is comming like example candidateDetails, it's noting but modal name
        onCandidateData={props.onCandidateData}
        onJobData={props.onJobData} //all jobs data we are sending
        onOpenSchedileInterview={openDrawer} // used to open the drawer
        onCloseSchedileInterview={closeDrawerHandler} // used to close the drawer
        onFetchInterviewData={() => {
          props.onFetchInterviewData();
        }}
      />
      {/* schedule interview drawer for candidate details page end */}

      {/* schedule interview drawer for recruitmentList details page start */}
      {location.state.from === "recruitmentList" && (
        <ScheduleInterviewDrawer
          page={location.state} //sending data from state like backIconid, id, from, jobDetailsPageId, recuritmentJobDetailsName
          jobName={props.RecruitmentCandidate.jobId} //job id of the recuriter
          onCandidateName={props.onCandidateName} //when we navigate from recuritment->recuritment details->candidate pool tab->candidate details page-> interview tab need to send candidate name so we are using this prop for that
          onInterviewName={interviewName} //specifing from schedule drawer from which page the data is comming like example candidateDetails, it's noting but modal name
          onCandidateData={props.onCandidateData} //all candidates data
          onJobData={props.onJobData} //all jobs data we are sending
          onOpenSchedileInterview={openDrawer} // used to open the drawer
          onCloseSchedileInterview={closeDrawerHandler} // used to close the drawer
          onFetchInterviewData={() => {
            props.onFetchInterviewData(); //to display updated data in interview list in interview tab calling api
          }}
        />
      )}
      {/* schedule interview drawer for recruitmentList details page end */}

      {/*  schedule interview is used in candidate details page in interview tab for edit the interview data start */}
      {props.onOpenDrawerPending1 && (
        <ScheduleInterviewDrawer
          onModalName={"edit"} //sending modal name to find edit or add or display
          pendinInterviewData={"yes"} //sending yes if it is edit in candidate details page in intview tab ,pending interview can be edit
          fetchInterviewData={props.onFetchInterviewData} //to fetch the inteview data to update the new data of interview list in interview tab
          onCandidateName={props.onCandidateName} //sending candidate name
          onInterviewName={interviewName} //specifing from schedule drawer from which page the data is comming like example candidateDetails, it's noting but modal name
          onTasksData={props.onTasksData}
          onJobData={props.onJobData} //sending all job data
          onOpenSchedileInterview={props.onOpenDrawerPending1} // it is used to open the drawer this variable is comming from CandidateTabs.js
          onCloseSchedileInterview={() => {
            props.onOpenDrawerPending2(); // it is used to open the drawer this variable is comming from CandidateTabs.js
          }}
          onProfile={props.onProfile && props.onProfile}
        />
      )}
      {/*  schedule interview is used in candidate details page in interview tab for edit the interview data end */}

      <InterviewDetails
        // fetchData={props.onFetchInterviewData}
        fetchInterviewData={props.onFetchInterviewData} //getting updated data to display in interview list calling api in candidatetabs.js
        onInterviewDetails={props.onInterviewDetails} // getting interview details data like pending
        onCandidateData={props.onCandidateData} // candidate data
        statusHandle={status}
        onCloseInterViewDetails={closeInterviewDetailsDrawerHandler} //used to close drawer
        onOpenInterViewDetails={interviewDetailsDrawer} //used to open drawer
      />

      <InterviewPendingDetails
        fetchInterviewData={props.onFetchInterviewData} //getting updated data to display in interview list calling api in candidatetabs.js
        onOpenPendDetails={openDrawerPending} //used to open drawer
        // onInterviewDetails={interviewDetails}
        onInterviewDetails={props.interviewDetailsData} // getting interview details data like pending
        onProfile={props.onProfile && props.onProfile} // sending profile data to check the role,intertview status to show conditionally it can visable other than recuriter
        onCloseInterViewDetails={() => {
          setOpenDrawerPending(false); //used to close drawer
        }}
        onAnswers={(val) => {
          props.postInterviewPerformanceRatingHandler(val, (success) => {
            if (success) {
              setOpenDrawerPending(false);
            }
          });
        }}
      />

      {rsOpen && (
        <RescheduleInterview
          onInterviewId={interviewId}
          onInterviewDetails={props.onInterviewDetails}
          candidateJobs={props.candidatesJob}
          rsOpen={rsOpen}
          onRsOpen={() => {
            setRsOpen(false);
          }}
        />
      )}
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
          props.setIsHavepermissionsHandler(false);
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    interviewDetailsData: state.recruitment.interview_details,
    permissions: state.authpermissions.permissions,
    onProfile: state.authentication.profileData,
    candidatesJob: state.taskAndCalendar.candidatesJob,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getInterviewDetailsHandler: (id, callback) => {
      dispatch(getInterViewAction(id, callback));
    },

    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },

    getCandidateRelatedJobsHandler: (val) => {
      dispatch(getCandidatesJobAction(val));
    },
    postInterviewPerformanceRatingHandler: (data, callback) => {
      dispatch(postCompletedInterviewPerformanceAction(data, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Interview);
