import { Form, Select } from "antd";

function TaskAndInterviewStatus(props) {
  return (
    <>
      {/* // <Form initialValue={{ status: props.text }}>
    //   <Form.Item name="status"> */}
      <Select
        // getPopupContainer={(trigger) => trigger.parentElement}
        onChange={(val) => {
          props.onStatusChange(val);
        }}
        size="small"
        className={`${
          props.text === "pending"
            ? "taskPendingSelectCss"
            : props.text === "Pending"
            ? "taskPendingSelectCss"
            : props.text === "completed"
            ? "taskCompleteSelectCss"
            : props.text === "Completed"
            ? "taskCompleteSelectCss"
            : props.text === "Rescheduled by Candidate"
            ? "taskCompleteSelectCss"
            : props.text === "Rescheduled by Client"
            ? "taskCompleteSelectCss"
            : props.text === "Candidate No Show"
            ? "taskCompleteSelectCss"
            : "taskHoldSelectCss"
        } tableDropDown`}
        // defaultValue={props.text}
        value={props.text}
        style={{
          width: "123px",
          color:
            props.text === "pending"
              ? "#FF9A02"
              : props.text === "Pending"
              ? "#FF9A02"
              : props.text === "completed"
              ? "#044123"
              : props.text === "Completed"
              ? "#044123"
              : props.text === "Rescheduled by Candidate"
              ? "#044123"
              : props.text === "Rescheduled by Client"
              ? "#044123"
              : props.text === "Candidate No Show"
              ? "#044123"
              : "#ff4d4f",
        }}
      >
        {props.statusData.map((data, index) => {
          return (
            <Select.Option key={index} value={data.value}>
              {data.title}
            </Select.Option>
          );
        })}
      </Select>
      {/* {props.text === "pending" ? (
        <Select
          style={{ width: "123px", color: "#FF9A02" }}
          onChange={(val) => {
            props.onStatusChange(val);
          }}
          size="small"
          className={"taskPendingSelectCss"}
          defaultValue={props.text}
        >
          {props.statusData.map((data, index) => {
            return (
              <Select.Option key={index} value={data.value}>
                {data.title}
              </Select.Option>
            );
          })}
        </Select>
      ) : props.text === "completed" ? (
        <Select
          style={{ width: "123px", color: "#044123" }}
          onChange={(val) => {
            props.onStatusChange(val);
          }}
          size="small"
          className={"taskCompleteSelectCss"}
          defaultValue={props.text}
        >
          {props.statusData.map((data, index) => {
            return (
              <Select.Option key={index} value={data.value}>
                {data.title}
              </Select.Option>
            );
          })}
        </Select>
      ) : props.text === "Rescheduled by Candidate" ? (
        <Select
          style={{ width: "123px", color: "#044123" }}
          onChange={(val) => {
            props.onStatusChange(val);
          }}
          size="small"
          className={"taskCompleteSelectCss"}
          defaultValue={props.text}
        >
          {props.statusData.map((data, index) => {
            return (
              <Select.Option key={index} value={data.value}>
                {data.title}
              </Select.Option>
            );
          })}
        </Select>
      ) : props.text === "Rescheduled by Client" ? (
        <Select
          style={{ width: "123px", color: "#044123" }}
          onChange={(val) => {
            props.onStatusChange(val);
          }}
          size="small"
          className={"taskCompleteSelectCss"}
          defaultValue={props.text}
        >
          {props.statusData.map((data, index) => {
            return (
              <Select.Option key={index} value={data.value}>
                {data.title}
              </Select.Option>
            );
          })}
        </Select>
      ) : (
        props.text === "Candidate No Show" && (
          <Select
            style={{ width: "123px", color: "#044123" }}
            onChange={(val) => {
              props.onStatusChange(val);
            }}
            size="small"
            className={"taskCompleteSelectCss"}
            defaultValue={props.text}
          >
            {props.statusData.map((data, index) => {
              return (
                <Select.Option key={index} value={data.value}>
                  {data.title}
                </Select.Option>
              );
            })}
          </Select>
        )
      )} */}

      {/* //   </Form.Item>
    //  </Form> */}
    </>
  );
}
export default TaskAndInterviewStatus;
