import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  jobDescriptions: [],
  jobDescription: null,
};
export const masterJobDescriptionSlice = createSlice({
  name: "jobDescription",
  initialState,
  reducers: {
    getJobDescriptionsSlice(state, action) {
      state.jobDescriptions = action.payload.result;
    },
    getJobDescriptionSlice(state, action) {
      state.jobDescription = action.payload.result;
    },
    postJobDescriptionsSlice(state, action) {
      state.jobDescriptions = [...state.jobDescriptions, action.payload.result];
    },
    putJobDescriptionsSlice(state, action) {
      state.jobDescriptions = state.jobDescriptions.map((data) => {
        if (data.id === action.payload.result.id) {
          return action.payload.result;
        } else {
          return data;
        }
      });
    },
    deleteJobDescriptionsSlice(state, action) {
      state.jobDescriptions = state.jobDescriptions.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});
export const {
  getJobDescriptionsSlice,
  getJobDescriptionSlice,
  postJobDescriptionsSlice,
  putJobDescriptionsSlice,
  deleteJobDescriptionsSlice,
} = masterJobDescriptionSlice.actions;
export default masterJobDescriptionSlice.reducer;
