import { Button, Col, Form, Input, Row, Select, Tag } from "antd";
import { useRef } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { postTagsAction } from "../../../Reusable/Store/Action/Master/MasterTagsAction";
import { PlusIcon } from "../../IconsComp/Icons";
import commonStyles from "../../UI/CommonCss.module.css";
function ReqAndRecMandatorySkills(props) {
  let index = 0;
  const inputRef = useRef(null);
  const [newTags, setNewTags] = useState([]);
  const [name, setName] = useState("");
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    const val = {
      type: "skill",
      tag: name,
    };
    props.postTagHandler(val);

    setName("");
    // setTimeout(() => {
    //   inputRef.current?.focus();
    // }, 0);
  };
  return (
    <>
      <Row>
        <Col flex={1}>
          <Form.Item
            label="Mandatory Skills Or Add New Skills"
            name="mandatorySkills"
            rules={[
              {
                required: true,
                message: "Please select mandatory skills",
              },
            ]}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className={`TagsStyle `}
              mode="tags"
              showSearch
              maxTagCount={1}
              tokenSeparators={[","]}
              allowClear
              style={{
                width: "100%",
              }}
              onChange={(val) => {
                setNewTags(val);
              }}
              // dropdownRender={(menu) => (
              //   <>
              //     {menu}

              //     <Row justify="space-between" align="middle">
              //       <Col md={16}>
              //         <Input
              //           style={{
              //             //   display: "flex",
              //             //   position: "relative",
              //             //   margin: "10px auto",
              //             width: "98%",
              //             margin: "10px 20px",
              //           }}
              //           className="tagsSelectInput  joblinkSharingMediumSelect"
              //           placeholder="Please enter new skills"
              //           ref={inputRef}
              //           value={name}
              //           onChange={onNameChange}
              //         />
              //       </Col>
              //       <Col md={7}>
              //         <Button
              //           type="text"
              //           style={{
              //             backgroundColor: "transparent",
              //             color: "var(--headings-color)",
              //           }}
              //           onClick={addItem}
              //         >
              //           <Row style={{ gap: "8px" }}>
              //             <Col>
              //               <PlusIcon color={"var(--body-text-color)"} />
              //             </Col>
              //             <Col>
              //               <p
              //                 style={{ textAlign: "right" }}
              //                 className={commonStyles.marginBottomRemove}
              //               >
              //                 Add new skills
              //               </p>
              //             </Col>
              //           </Row>
              //         </Button>
              //       </Col>
              //     </Row>
              //   </>
              // )}
              placeholder="Select mandatory skills or add new skills"
            >
              {props.tagsData &&
                props.tagsData
                  .filter((data) => data.type === "skill")
                  .map((data) => {
                    return (
                      <Select.Option key={data.id} value={data.tag}>
                        {data.tag}
                      </Select.Option>
                    );
                  })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    postTagHandler: (data) => {
      dispatch(postTagsAction(data));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReqAndRecMandatorySkills);
/* {props.pathname === "/requirement" ? ( ) : (
            <Select
              className={`TagsStyle `}
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Select mandatory skills"
            >
              {props.tagsData &&
                props.tagsData
                  .filter((data) => data.type === "skill")
                  .map((data) => {
                    return (
                      <Select.Option key={data.id} value={data.tag}>
                        {data.tag}
                      </Select.Option>
                    );
                  })}
            </Select>
          )} */
