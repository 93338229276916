import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  message,
  Modal,
  Progress,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import Steps from "../../../UI/Steps";
import style from "../../AddCandidatesComp/AddCandidatesComp.module.css";
import React, { useEffect, useRef, useState } from "react";
import {
  CaretBlackDownIcon,
  CloseIcon,
  OfferLetterIcon,
} from "../../../IconsComp/Icons";
import { Option } from "antd/lib/mentions";
import {
  putRecruitmentCandidateStatusAction,
  getRecruitmentAllStatusAction,
  getRecruitmentsCandidatesJobStatusAction,
} from "../../../../Reusable/Store/Action/RecruitmentAction";
import { connect } from "react-redux";
import commonStyles from "../../../UI/CommonCss.module.css";
import styles from "../../AddCandidatesComp/AddCandidatesComp.module.css";
import TextArea from "antd/lib/input/TextArea";
import { useForm } from "antd/lib/form/Form";
import word from "../../../../Assets/Image/word.png";
import pdf from "../../../../Assets/Image/pdf.png";
import excel from "../../../../Assets/Image/excel.png";
import { checkPermissionAction } from "../../../../Reusable/Store/Action/AuthPermissionsAction";
import PermissionMessage from "../../../UI/PermissionMessage";
import { getRequirementAction } from "../../../../Reusable/Store/Action/RequirementAction";
import { useLocation } from "react-router";

function Status(props) {
  // start of dropdown menu
  const [form] = useForm();
  const [form1] = useForm();
  const [draftModalOpen, setDraftModalOpen] = useState(false);
  const [notify, setNotify] = useState(false);
  // const [internalHiring, setInternaliring] = useState(false);
  const [externalHring, setExternalHiring] = useState(true);
  const [hiringStatusModalOpen, setHinringStatusModalOpen] = useState(false);
  const [rejectedModal, setRejectedModal] = useState(false);
  const [filesData, setFilesData] = useState(undefined);
  const [filesData1, setFilesData1] = useState("");
  const [fileResult, setFileResult] = useState(false);
  const [fileResult1, setFileResult1] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progress1, setProgress1] = useState(0);
  const [filesDisp, setFilesDisp] = useState(false);
  const [filesDisp1, setFilesDisp1] = useState(false);

  const [reasonModalName, setReasonModalName] = useState("");
  const [diableData, setDisableData] = useState(
    props.recruitment_job_status && props.recruitment_job_status.currentStage
  );
  const location = useLocation();
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  let btnref = useRef(null);
  let btnref1 = useRef(null);
const [openOfferedModal,setOpenOfferedModal]=useState(false);


const closeOfferModal = () =>{
  setOpenOfferedModal(false);
}


  const closeModal = () => {
    setDraftModalOpen(false);
  };

  const closeHiringStatusModal = () => {
    setHinringStatusModalOpen(false);
  };

  const closeRejectedModal = () => {
    setRejectedModal(false);
  };

  // const

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  useEffect(() => {
    props.getRecruitmentAllStatusHandler();
    // props.getCandidatejobStatusHandler(props.candidateId && props.candidateId);
    props.getRequirementHandler(
      props.RecruitmentCandidate &&
        props.RecruitmentCandidate.jobId &&
        props.RecruitmentCandidate.jobId
    );
  }, []);


  //for sending offer 
  const DraftData1 = (draftData1) => {
    const data = {
      ...draftData1,
      stage: "Offered",
      id: props.candidateId,
      notify: notify ? 1 : 0,
      // hiringType: internalHiring ? "internal" : "external",
      // joiningDate: draftData.joiningDate!==null
      //   ? moment(draftData.joiningDate).format("YYYY/MM/DD")
      //   : "Invalid date",
      joiningDate:
        draftData1.joiningDate === null || draftData1.joiningDate === undefined
          ? "Invalid date"
          : moment(draftData1.joiningDate).format("YYYY/MM/DD"),
      attachOfferletter: filesData1,
      ctc: draftData1.ctc ? draftData1.ctc : "0",
      _method: "PUT",
    };
    props.putRecruitmentJobStatusHandler(data, (success) => {
      if (success) {
        props.getCandidatejobStatusHandler(
          props.candidateId && props.candidateId
        );
        closeOfferModal();
        form.resetFields();
      }
    });
  };



  //end of sending offer


  const JobStatus = (val) => {
    setDisableData(val);
    if (val === "Offer Sent") {
      setDraftModalOpen(true);
    } else if (val === "Offered"){
      setOpenOfferedModal(true);
    } else if (val === "Hired") {
      setHinringStatusModalOpen(true);
    } else if (val === "Rejected") {
      setRejectedModal(true);
      setReasonModalName("Rejected");
    } else if (val === "Deleted") {
      setRejectedModal(true);
      setReasonModalName("Deleted");
    } else if (val === "Blacklisted") {
      setRejectedModal(true);
      setReasonModalName("Blacklisted");
    } else if (val === "Quit") {
      setRejectedModal(true);
      setReasonModalName("Quit");
    } else if (val === "Absconded") {
      setRejectedModal(true);
      setReasonModalName("Absconded");
    } else {
      const data = {
        id: props.candidateId,
        stage: val,
        _method: "PUT",
      };
      props.putRecruitmentJobStatusHandler(data, (success) => {
        if (success) {
          props.getCandidatejobStatusHandler(
            props.candidateId && props.candidateId
          );
          form.resetFields();
          setFilesData(undefined);
        }
      });
    }
  };

  const data = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address: "New York No. 1 Lake Park",
    },
    {
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sidney No. 1 Lake Park",
    },
  ];

  const DraftData = (draftData) => {
    const data = {
      ...draftData,
      stage: "Offer Sent",
      id: props.candidateId,
      notify: notify ? 1 : 0,
      // hiringType: internalHiring ? "internal" : "external",
      // joiningDate: draftData.joiningDate!==null
      //   ? moment(draftData.joiningDate).format("YYYY/MM/DD")
      //   : "Invalid date",
      joiningDate:
        draftData.joiningDate === null || draftData.joiningDate === undefined
          ? "Invalid date"
          : moment(draftData.joiningDate).format("YYYY/MM/DD"),
      attachOfferletter: filesData,
      ctc: draftData.ctc ? draftData.ctc : "0",
      _method: "PUT",
    };
    props.putRecruitmentJobStatusHandler(data, (success) => {
      if (success) {
        props.getCandidatejobStatusHandler(
          props.candidateId && props.candidateId
        );
        closeModal();
        form.resetFields();
      }
    });
  };

  const onFinishHiringStatusData = (valuesHiring) => {
    const dataHiring = {
      ...valuesHiring,
      stage: "Hired",
      id: props.candidateId,
      joiningDate:
        valuesHiring.joiningDate !== null
          ? moment(valuesHiring.joiningDate).format("YYYY/MM/DD")
          : "Invalid date",
      _method: "PUT",
    };
    props.putRecruitmentJobStatusHandler(dataHiring, (success) => {
      if (success) {
        props.getCandidatejobStatusHandler(
          props.candidateId && props.candidateId
        );
        closeHiringStatusModal();
        form.resetFields();
      }
    });
    closeHiringStatusModal();
  };

  const onFinishRejectedStatusData = (val) => {
    if (reasonModalName === "Rejected") {
      const dataOthers = {
        ...val,
        stage: "Rejected",
        id: props.candidateId,
        _method: "PUT",
      };
      props.putRecruitmentJobStatusHandler(dataOthers, (success) => {
        if (success) {
          props.getCandidatejobStatusHandler(
            props.candidateId && props.candidateId
          );
          closeRejectedModal();
          form.resetFields();
        }
      });
    }
    if (reasonModalName === "Deleted") {
      const dataOthers = {
        ...val,
        stage: "Deleted",
        id: props.candidateId,
        _method: "PUT",
      };
      props.putRecruitmentJobStatusHandler(dataOthers, (success) => {
        if (success) {
          props.getCandidatejobStatusHandler(
            props.candidateId && props.candidateId
          );
          closeRejectedModal();
          form.resetFields();
        }
      });
    }
    if (reasonModalName === "Blacklisted") {
      const dataOthers = {
        ...val,
        stage: "Blacklisted",
        id: props.candidateId,
        _method: "PUT",
      };
      props.putRecruitmentJobStatusHandler(dataOthers, (success) => {
        if (success) {
          props.getCandidatejobStatusHandler(
            props.candidateId && props.candidateId
          );
          closeRejectedModal();
          form.resetFields();
        }
      });
    }
    if (reasonModalName === "Quit") {
      const dataOthers = {
        ...val,
        stage: "Quit",
        id: props.candidateId,
        _method: "PUT",
      };
      props.putRecruitmentJobStatusHandler(dataOthers, (success) => {
        if (success) {
          props.getCandidatejobStatusHandler(
            props.candidateId && props.candidateId
          );
          closeRejectedModal();
          form.resetFields();
        }
      });
    }
    if (reasonModalName === "Absconded") {
      const dataOthers = {
        ...val,
        stage: "Absconded",
        id: props.candidateId,
        _method: "PUT",
      };
      props.getCheckPermissionHandler(
        "recruitment-candidate-status update",
        (success) => {
          if (success) {
            props.putRecruitmentJobStatusHandler(dataOthers, (success) => {
              if (success) {
                props.getCandidatejobStatusHandler(
                  props.candidateId && props.candidateId
                );
                closeRejectedModal();
                form.resetFields();
              }
            });
          } else {
            setOpenPMModal({
              open: true,
              permission: "Recruitment Candidate Status Update",
            });
          }
        }
      );
    }
  };

  const handleChange = (info) => {
    setFileResult(false);

    if (!(info.target.files[0].size <= 2000000)) {
      message.warning("This file should be less than 2 MB", 3);
      setProgress(0);
      return;
    }
    setFilesData(info.target.files[0]);
    setProgress(50);
    setTimeout(() => {
      setProgress(100);
    }, 300);
  };

  const handleChange1 = (info1) => {
    setFileResult1(false);

    if (!(info1.target.files[0].size <= 2000000)) {
      message.warning("This file should be less than 2 MB", 3);
      setProgress1(0);
      return;
    }
    setFilesData1(info1.target.files[0]);
    setFilesDisp1(true);
    setProgress1(50);
    setTimeout(() => {
      setProgress1(100);
    }, 300);
  };

  const menu2 = (
    <Menu
      style={{ width: "214px", margin: "11px 45px" }}
      selectable
      defaultSelectedKeys={["1"]}
      items={[
        props.get_all_status &&
          props.get_all_status.map((data, index) => {
            return {
              key: index,
              label: <>{data.statusType}</>,
            };
          }),
        // {
        //   key: "1",
        //   label: (
        //     <p
        //       onClick={() => {
        //         // setModalName("add");
        //         // setMenuKey("1");
        //         // setDrawer(true);
        //       }}
        //       // style={{
        //       //   color:
        //       //     menuKey === "1"
        //       //       ? "var(--primaryColor)"
        //       //       : "var(--body-text-color)",
        //       // }}
        //       className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.darkText}`}
        //     >
        //       Regular Task
        //     </p>
        //   ),
        // },
        // {
        //   key: "2",
        //   label: (
        //     <p
        //       onClick={() => {
        //         // setMenuKey("2");
        //         // setInterviewName("task");
        //         // setScheduleDrawer(true);
        //       }}
        //       // style={{
        //       //   color:
        //       //     menuKey === "2"
        //       //       ? "var(--primaryColor)"
        //       //       : "var(--body-text-color)",
        //       // }}
        //       className={`${commonCssStyle.marginBottomRemove}  ${commonCssStyle.darkText}`}
        //     >
        //       Interview
        //     </p>
        //   ),
        // },
      ]}
    />
  );

  // end of dropdown menu
  let disableoption = false;
  return (
    <>
      <div>
        <Card style={{ padding: "22px" }}>
          <Row justify="end">
            <Col>
              {props.permissions &&
                props.permissions.find(
                  (data) => data === "recruitment-candidate-status update"
                ) && (
                  <Row>
                    <Col md={24}>
                      <Form>
                        <Form.Item name="status">
                          <Select
                            getPopupContainer={(trigger) => trigger.parentElement}
                            className="SelectColorChange"
                            onChange={JobStatus}
                            style={{ width: "300px" }}
                            placeholder={
                              <p
                                className={`${commonCssStyle.marginBottomRemove}`}
                                style={{ color: "var(--theme)" }}
                              >
                                Stage
                              </p>
                            }
                          >
                            {props.get_all_status &&
                              props.get_all_status.map((data, index) => {
                                return (
                                  <Select.OptGroup
                                    key={index}
                                    value={data.stageType}
                                    label={<b>{data.statusType}</b>}
                                  >
                                    {data.status &&
                                      data.status.map((i, j) => {
                                        if (
                                          diableData === i ||
                                          diableData === ""
                                        ) {
                                          disableoption = true;
                                        }
                                        return (
                                          <Select.Option
                                          style={{color:i=="Quit"?"red"
                                          :i=="Absconded"?"red"
                                          :i=="Blacklisted"?"red"
                                          :i=="Deleted"?"red"
                                          :i=="Candidate Declined/Backout"?"red"
                                          :i=="Rejected"?"red"
                                          :i=="Not in Consideration"?"red"
                                          :"black"}}
                                            value={i}
                                            disabled={!disableoption}
                                          >
                                            {i}
                                          </Select.Option>
                                        );
                                      })}
                                  </Select.OptGroup>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                )}
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              {props.recruitment_job_status &&
              props.recruitment_job_status.candidate_job_status &&
              props.recruitment_job_status.candidate_job_status.length > 0
                ? props.recruitment_job_status.candidate_job_status.map(
                    (data, index) => {
                      return (
                        <>
                          <Row>
                            <Col md={24}>
                              <Row>
                                {index && index !== "0" ? (
                                  <Col>
                                    <h3
                                      className={`${style.statusText} ${commonCssStyle.marginBottomRemove}`}
                                      style={{ marginTop: "27px" }}
                                    >
                                      Previous Stage
                                    </h3>
                                    {data.stage === "Rejected" ? (
                                      <p
                                        style={{ color: "#f83a3a" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    )
                                   : data.stage === "Not in Consideration" ? (
                                      <p
                                        style={{ color: "#f83a3a" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) 
                                    : data.stage === "Absconded" ? (
                                      <p
                                        style={{ color: "#f83a3a" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : data.stage === "Quit" ? (
                                      <p
                                        style={{ color: "#f83a3a" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : data.stage === "Blacklisted" ? (
                                      <p
                                        style={{ color: "#f83a3a" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : data.stage === "Deleted" ? (
                                      <p
                                        style={{ color: "#f83a3a" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : data.stage ===
                                      "Candidate Declined/Backout" ? (
                                      <p
                                        style={{ color: "#f83a3a" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : data.stage === "Hired" ? (
                                      <p
                                        style={{ color: "#0e8553" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : data.stage === "joined" ? (
                                      <p
                                        style={{ color: "#0e8553" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : data.stage === "Onboarded" ? (
                                      <p
                                        style={{ color: "#0e8553" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : (
                                      <p
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    )}
                                  </Col>
                                ) : (
                                  <Col>
                                    <h3
                                      className={`${style.statusText} ${commonCssStyle.marginBottomRemove}`}
                                      style={{ marginTop: "4px" }}
                                    >
                                      Current Stage
                                    </h3>
                                    {data.stage === "Rejected" ? (
                                      <p
                                        style={{ color: "#f83a3a" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : 
                                    data.stage === "Not in Consideration" ? (
                                      <p
                                        style={{ color: "#f83a3a" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) :
                                    data.stage === "Absconded" ? (
                                      <p
                                        style={{ color: "#f83a3a" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : data.stage === "Quit" ? (
                                      <p
                                        style={{ color: "#f83a3a" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : data.stage === "Blacklisted" ? (
                                      <p
                                        style={{ color: "#f83a3a" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : data.stage === "Deleted" ? (
                                      <p
                                        style={{ color: "#f83a3a" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : data.stage ===
                                      "Candidate Declined/Backout" ? (
                                      <p
                                        style={{ color: "#f83a3a" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : data.stage === "Hired" ? (
                                      <p
                                        style={{ color: "#0e8553" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : data.stage === "joined" ? (
                                      <p
                                        style={{ color: "#0e8553" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : data.stage === "Onboarded" ? (
                                      <p
                                        style={{ color: "#0e8553" }}
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    ) : (
                                      <p
                                        className={`${style.statusSubText} ${commonCssStyle.marginBottomRemove}`}
                                      >
                                        {data.stageType} :- {data.stage}
                                      </p>
                                    )}
                                  </Col>
                                )}
                              </Row>
                              {/* <Row>
                                <Col style={{ marginTop: "12px" }}>
                                  <Steps number={data.stageOrder} />
                                </Col>
                              </Row> */}
                              {data.stage === "Hired" && (
                                <Form
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "0px",
                                  }}
                                  initialValues={{
                                    joiningDate: data.joiningDate,
                                    // joiningDate:data.stage+"hi"
                                  }}
                                >
                                  {/* <Form.Item name="joiningDate">
                                  <TextArea readOnly >{data.joiningDate}</TextArea>            
                                </Form.Item> */}
                                  <Row>
                                    <Col
                                      md={24}
                                      style={{
                                        height: "64px",
                                        backgroundColor: "var(--input-color)",
                                        borderRadius: "7px",
                                        padding: "10px",
                                      }}
                                    >
                                      <div style={{ width: "100%" }}>
                                        <p
                                          className={`${commonCssStyle.textInsideTextArea} ${commonCssStyle.marginBottomRemove}`}
                                        >
                                          Joining Date:-{" "}
                                          {(data.joiningDate &&
                                            data.joiningDate === null) ||
                                          data.joiningDate === undefined ||
                                          data.joiningDate === "0000-00-00" ||
                                          data.joiningDate == ""
                                            ? "N/A"
                                            : moment(data.joiningDate).format(
                                                dataFormate
                                              )}
                                        </p>
                                        <p
                                          style={{ marginTop: "4px" }}
                                          className={
                                            commonCssStyle.textInsideTextArea
                                          }
                                        >
                                          Hiring Location:-{" "}
                                          {data.hiringLocation
                                            ? data.hiringLocation
                                            : "N/A"}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                </Form>
                              )}
                              {/* sepeate  */}
                              {data.stage === "Offered" && (
                                <>
                                  {/* <Form
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "-10px",
                                  }}
                                  initialValues={{
                                    joiningDate:
                                    data.joiningDate&&
                                    data.joiningDate===null||
                                    data.joiningDate===undefined||
                                    data.joiningDate==="0000-00-00"||
                                    data.joiningDate==""?"Joining Date:- N/A":
                                      "Joining Date:- " +
                                      moment(data.joiningDate).format(
                                        dataFormate
                                      ),
                                    // joiningDate:data.stage+"hi"
                                  }}
                                >
                                  <Form.Item name="joiningDate">
                                    <TextArea readOnly />
                                  </Form.Item>
                                </Form> */}

                                  <Row
                                    align="middle"
                                    style={{
                                      margin: "15px 0px",
                                      padding: "20px 2px",
                                      background: "var(--secondary-card-color)",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    <Col
                                      style={{
                                        color: "var(--body-text-color)",
                                        textAlign: "center",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      {(data.joiningDate &&
                                        data.joiningDate === null) ||
                                      data.joiningDate === undefined ||
                                      data.joiningDate === "0000-00-00" ||
                                      data.joiningDate == ""
                                        ? "Joining Date:- N/A"
                                        : "Joining Date:- " +
                                          moment(data.joiningDate).format(
                                            dataFormate
                                          )}
                                    </Col>
                                  </Row>
                                </>
                              )}
                              {data.stage === "Rejected" && (
                                <Form
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "-10px",
                                  }}
                                  initialValues={{
                                    rejectionreason: data.comment,
                                  }}
                                >
                                  <Form.Item name="rejectionreason">
                                    <TextArea
                                      readOnly
                                      placeholder="Rejection reason"
                                    />
                                  </Form.Item>
                                </Form>
                              )}
                              {data.stage === "Deleted" && (
                                <Form
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "-10px",
                                  }}
                                  initialValues={{
                                    rejectionreason: data.comment,
                                  }}
                                >
                                  <Form.Item name="rejectionreason">
                                    <TextArea
                                      readOnly
                                      placeholder="Rejection reason"
                                    />
                                  </Form.Item>
                                </Form>
                              )}
                              {data.stage === "Blacklisted" && (
                                <Form
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "-10px",
                                  }}
                                  initialValues={{
                                    rejectionreason: data.comment,
                                  }}
                                >
                                  <Form.Item name="rejectionreason">
                                    <TextArea
                                      readOnly
                                      placeholder="Rejection reason"
                                    />
                                  </Form.Item>
                                </Form>
                              )}
                              {data.stage === "Quit" && (
                                <Form
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "-10px",
                                  }}
                                  initialValues={{
                                    rejectionreason: data.comment,
                                  }}
                                >
                                  <Form.Item name="rejectionreason">
                                    <TextArea
                                      readOnly
                                      placeholder="Rejection reason"
                                    />
                                  </Form.Item>
                                </Form>
                              )}
                              {data.stage === "Absconded" && (
                                <Form
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "-10px",
                                  }}
                                  initialValues={{
                                    rejectionreason: data.comment,
                                  }}
                                >
                                  <Form.Item name="rejectionreason">
                                    <TextArea
                                      readOnly
                                      placeholder="Rejection reason"
                                    />
                                  </Form.Item>
                                </Form>
                              )}
                              <Row style={{ marginTop: "10px" }}>
                                <Col md={24}>
                                  <p
                                    className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
                                  >
                                    {data &&
                                      data.createdAt &&
                                      moment(data.createdAt).format(
                                        dataFormate
                                      )}{" "}
                                    &nbsp; changed to {data.stage}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      );
                    }
                  )
                : "no data"}
            </Col>
          </Row>
        </Card>
        <Modal
          className="modalModifiedForTemplete"
          width={706}
          title={
            <Row>
              <Col md={20}>
                <p className={` ${styles.summaryInfoMainText}`}>Record</p>
              </Col>
              <Col md={4}>
                <div
                  style={{ float: "right" }}
                  onClick={() => {
                    closeModal();
                  }}
                  className={`${commonCssStyle.coursorPointer} `}
                >
                  <CloseIcon
                    color={"var(--body-text-color)" || "var(--theme)"}
                  />
                </div>
              </Col>
            </Row>
          }
          open={draftModalOpen}
          closable={false}
          onCancel={closeModal}
          footer={false}
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={DraftData}
            initialValues={{
              candidateName: props.RecruitmentCandidate.name,
              joiningDate: undefined,
            }}
          >
            <Row style={{ padding: " 0px 20px 20px 20px" }}>
              <Col md={24}>
                <Form.Item label="Candidate Name" name="candidateName">
                  <Input placeholder="Enter Candidate Name" />
                </Form.Item>
                <Form.Item label="Designation" name="designation">
                  <Input placeholder="Enter Designation" />
                </Form.Item>
                <Form.Item label="CTC (in Lakhs)" name="ctc">
                  <Input type="number" placeholder="Enter CTC" />
                </Form.Item>
                <Form.Item
                  label="Tentative Joining Date"
                  name="joiningDate"
                  rules={[
                    {
                      required: false,
                      message: "Please select joining date",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Select Tentative Joining  date"
                    format={"DD/MM/YYYY"}
                    style={{ width: "100%" }}
                    className={`${commonStyles.datePickerBgColor} CandidateInputHeight`}
                  />
                </Form.Item>

                {/* for attcah offer letter  */}
                {props.recruitmentIdData &&
                  props.recruitmentIdData.hiringType &&
                  props.recruitmentIdData.hiringType == "internal" && (
                    <Row>
                      <Col>
                        {filesData && (
                          <div
                            style={{
                              margin: "22px",
                              display: "flex",
                              alignItems: "center",
                              padding: "14px 8px 17px 8px",
                              backgroundColor: "var(--progress-card)",
                              borderRadius: "8px",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <Image
                                onClick={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                preview={false}
                                // src={data.type === "application/pdf" ? pdf : word}
                                src={
                                  filesData.type === "application/pdf"
                                    ? pdf
                                    : word
                                }
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  borderRadius: "6px",
                                  position: "relative",
                                }}
                              />
                            </div>
                            <div
                              style={{
                                width: "100%",
                                margin: "0px 10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.formInputLabel}`}
                                >
                                  {filesData.name}
                                </p>
                                <p
                                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.formInputLabel}`}
                                >
                                  {progress == "0"
                                    ? "0%"
                                    : progress == "50"
                                    ? "50%"
                                    : "100%"}
                                </p>
                              </div>
                              <Progress percent={progress} size="small" />
                            </div>
                            <div>
                              <span
                                onClick={() => {
                                  setFilesData("");
                                }}
                                style={{
                                  position: "relative",
                                  paddingTop: "5px",
                                  paddingLeft: "1px",
                                  cursor: "pointer",
                                }}
                              >
                                <CloseIcon color={"var(--headings-color)"} />
                              </span>
                            </div>
                          </div>
                        )}

                        <input
                          accept="application/pdf,.docx"
                          hidden
                          type="file"
                          name={["fileUpload"]}
                          multiple
                          onChange={handleChange}
                          ref={(input) => {
                            btnref = input;
                          }}
                        />
                        {!filesData && (
                          <div
                            className={`dataImportUploadStyle`}
                            style={{
                              borderRadius: "0px",
                              width: "100%",
                            }}
                          >
                            <Row>
                              <Col>
                                <Row>
                                  <Col>
                                    <p
                                      onClick={(e) => {
                                        e.preventDefault();
                                        btnref.click();
                                        return false;
                                      }}
                                      style={{ textAlign: "center" }}
                                      className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.formInputLabel}`}
                                    >
                                      <Row style={{ gap: "4px" }}>
                                        <Col>
                                          {" "}
                                          <OfferLetterIcon />
                                        </Col>
                                        <Col> Attach offer letter</Col>
                                      </Row>
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Col>
                    </Row>
                  )}

                {/* end off attach offer letter  */}

                {props.recruitmentIdData &&
                  props.recruitmentIdData.hiringType &&
                  props.recruitmentIdData.hiringType == "internal" && (
                    <Row align="middle" style={{ gap: "17px" }}>
                      <Col>
                        <p className={`${commonStyles.formInputLabel} `}>
                          Notify Candidate
                        </p>
                      </Col>
                      <Col>
                        <Form.Item>
                          <Switch
                            onChange={(val) => {
                              setNotify(val);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
              </Col>
            </Row>
            <Row
              className={commonCssStyle.footerShadowForTemplete}
              style={{ gap: "17px" }}
              justify="end"
            >
              <Col>
                <Button
                  className={commonCssStyle.buttonSettingsSecondary}
                  style={{
                    width: "177px",
                    height: "45px",
                  }}
                  onClick={() => {
                    closeModal();
                  }}
                  type="default"
                >
                  Cancel
                </Button>
              </Col>
              {/* <Col>
            <Button
              className={commonCssStyle.buttonSettingsPrimary}
              style={{
                width: "177px",
                height: "45px",
              }}
              htmlType="submit"
              // type="primary"
            >
              Save Draft
            </Button>
          </Col> */}
              <Col>
                <Button
                  className={commonCssStyle.buttonSettingsPrimary}
                  style={{
                    width: "177px",
                    height: "45px",
                  }}
                  htmlType="submit"
                  type="primary"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          className="modalModifiedForTemplete"
          title={
            <Row style={{ gap: "330px" }}>
              <Col>
                <p className={` ${styles.summaryInfoMainText}`}>
                  Hiring Status
                </p>
              </Col>
              <Col>
                <div
                  style={{ float: "right" }}
                  onClick={() => {
                    closeHiringStatusModal();
                  }}
                  className={`${commonCssStyle.coursorPointer} `}
                >
                  <CloseIcon
                    color={"var(--body-text-color)" || "var(--theme)"}
                  />
                </div>
              </Col>
            </Row>
          }
          open={hiringStatusModalOpen}
          footer={false}
          closable={false}
          onCancel={closeHiringStatusModal}
        >
          <Row>
            <Col md={24}>
              <Form
                layout="vertical"
                form={form}
                onFinish={onFinishHiringStatusData}
              >
                <Row>
                  <Col style={{ padding: "20px" }} md={24}>
                    <Form.Item
                      label="Tentative Joining Date"
                      name="joiningDate"
                      rules={[
                        {
                          required: false,
                          message: "Please select joining date",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder="Select Tentative Joining  date"
                        format={"DD/MM/YYYY"}
                        style={{ width: "100%" }}
                        className={`${commonStyles.datePickerBgColor} CandidateInputHeight`}
                      />
                    </Form.Item>
                    <Form.Item label="Location" name="hiringLocation">
                      <Input placeholder="Enter hiring location" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  className={commonCssStyle.footerShadowForTemplete}
                  style={{ gap: "17px" }}
                  justify="end"
                >
                  <Col>
                    <Button
                      className={commonCssStyle.buttonSettingsSecondary}
                      style={{
                        width: "177px",
                        height: "45px",
                      }}
                      onClick={() => {
                        closeHiringStatusModal();
                      }}
                      type="default"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className={commonCssStyle.buttonSettingsPrimary}
                      style={{
                        width: "177px",
                        height: "45px",
                      }}
                      htmlType="submit"
                      type="primary"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Modal>

        {/* rejected modal  */}
        <Modal
          className="modalModifiedForTemplete"
          title={
            <Row>
              <Col md={20}>
                <p className={` ${styles.summaryInfoMainText}`}>
                  {reasonModalName === "Rejected" && "Candidate Declined"}
                  {reasonModalName === "Not in Consideration" &&
                    "Not in Consideration"}
                  {reasonModalName === "Deleted" && "Deleted Status"}
                  {reasonModalName === "Blacklisted" && "Blacklisted Status"}
                  {reasonModalName === "Quit" && "Quit Status"}
                  {reasonModalName === "Absconded" && "Absconded Status"}
                </p>
              </Col>
              <Col md={4}>
                <div
                  style={{ float: "right" }}
                  onClick={() => {
                    closeRejectedModal();
                  }}
                  className={`${commonCssStyle.coursorPointer} `}
                >
                  <CloseIcon
                    color={"var(--body-text-color)" || "var(--theme)"}
                  />
                </div>
              </Col>
            </Row>
          }
          open={rejectedModal}
          footer={false}
          closable={false}
          onCancel={closeRejectedModal}
        >
          <Row>
            <Col md={24}>
              <Form
                layout="vertical"
                form={form}
                onFinish={onFinishRejectedStatusData}
              >
                <Row>
                  <Col style={{ padding: "20px" }} md={24}>
                    {reasonModalName === "Rejected" && (
                      <Form.Item label="Rejection reason" name="comment">
                        <TextArea />
                      </Form.Item>
                    )}

                    {reasonModalName === "Deleted" && (
                      <Form.Item label="Deleted reason" name="comment">
                        <TextArea />
                      </Form.Item>
                    )}

                    {reasonModalName === "Blacklisted" && (
                      <Form.Item label="Blacklisted reason" name="comment">
                        <TextArea />
                      </Form.Item>
                    )}

                    {reasonModalName === "Quit" && (
                      <Form.Item label="Quit reason" name="comment">
                        <TextArea />
                      </Form.Item>
                    )}

                    {reasonModalName === "Absconded" && (
                      <Form.Item label="Absconded reason" name="comment">
                        <TextArea />
                      </Form.Item>
                    )}
                  </Col>
                </Row>

                <Row
                  className={commonCssStyle.footerShadowForTemplete}
                  style={{ gap: "17px" }}
                  justify="end"
                >
                  <Col>
                    <Button
                      className={commonCssStyle.buttonSettingsSecondary}
                      style={{
                        width: "177px",
                        height: "45px",
                      }}
                      onClick={() => {
                        closeRejectedModal();
                      }}
                      type="default"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className={commonCssStyle.buttonSettingsPrimary}
                      style={{
                        width: "177px",
                        height: "45px",
                      }}
                      htmlType="submit"
                      type="primary"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Modal>

        {/* modal for showing draft offer letter for offer sent  */}
                      
        <Modal
        className="modalModifiedForTemplete"
        width={706}
        title={
          <Row>
            <Col md={20}>
              <p className={` ${styles.summaryInfoMainText}`}>Record</p>
            </Col>
            <Col md={4}>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  closeOfferModal();
                  form1.resetFields();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)"} />
              </div>
            </Col>
          </Row>
        }
        open={openOfferedModal}
        closable={false}
        footer={false}
        onCancel={closeOfferModal}
        >
          <Form
          layout="vertical"
          form={form1}
          onFinish={DraftData1}
          initialValues={{
            // props.recruitment_job_status.offer_letter
            candidateName:
              props &&
              props.recruitment_job_status &&
              props.recruitment_job_status.offer_letter &&
              props.recruitment_job_status.offer_letter.candidateName &&
              props.recruitment_job_status.offer_letter.candidateName,
            designation:
              props &&
              props.recruitment_job_status &&
              props.recruitment_job_status.offer_letter &&
              props.recruitment_job_status.offer_letter.candidateName &&
              props.recruitment_job_status.offer_letter.designation,
            ctc:
              props &&
              props.recruitment_job_status &&
              props.recruitment_job_status.offer_letter &&
              props.recruitment_job_status.offer_letter.candidateName &&
              props.recruitment_job_status.offer_letter.ctc,
            joiningDate:
              (props.recruitment_job_status &&
                props.recruitment_job_status.offer_letter &&
                props.recruitment_job_status.offer_letter.candidateName &&
                props.recruitment_job_status.offer_letter.joiningDate ===
                  null) ||
              (props.recruitment_job_status &&
                props.recruitment_job_status.offer_letter &&
                props.recruitment_job_status.offer_letter.candidateName &&
                props.recruitment_job_status.offer_letter.joiningDate ===
                  undefined) ||
              (props.recruitment_job_status &&
                props.recruitment_job_status.offer_letter &&
                props.recruitment_job_status.offer_letter.candidateName &&
                props.recruitment_job_status.offer_letter.joiningDate == "")
                ? undefined
                : moment(
                    props.recruitment_job_status &&
                      props.recruitment_job_status.offer_letter &&
                      props.recruitment_job_status.offer_letter.candidateName &&
                      props.recruitment_job_status.offer_letter.joiningDate
                  ),
          }}
        >
          <Row style={{ padding: " 0px 20px 20px 20px" }}>
            <Col md={24}>
              <Form.Item label="Candidate Name" name="candidateName">
                <Input placeholder="Enter Candidate Name" />
              </Form.Item>
              <Form.Item label="Designation" name="designation">
                <Input placeholder="Enter Designation" />
              </Form.Item>
              <Form.Item label="CTC (in Lakhs)" name="ctc">
                <Input type="number" placeholder="Enter CTC" />
              </Form.Item>
              <Form.Item
                label="Tentative Joining Date"
                name="joiningDate"
                rules={[
                  {
                    required: false,
                    message: "Please select joining date",
                  },
                ]}
              >
                <DatePicker
                  placeholder="Select Tentative Joining  date"
                  format={"DD/MM/YYYY"}
                  style={{ width: "100%" }}
                  className={`${commonCssStyle.datePickerBgColor} CandidateInputHeight`}
                />
              </Form.Item>

              {/* for attcah offer letter  */}

              {props.recruitmentIdData &&
                props.recruitmentIdData.hiringType &&
                props.recruitmentIdData.hiringType == "internal" && (
                  <Row>
                    <Col>
                      {!filesData1 &&
                      props.recruitment_job_status.offer_letter &&
                      props.recruitment_job_status.offer_letter
                        .attachOfferletter === "" ? (
                        <></>
                      ) : !filesData1 && filesDisp1 ? (
                        <></>
                      ) : (
                        <div
                          style={{
                            margin: "22px",
                            display: "flex",
                            alignItems: "center",
                            padding: "14px 8px 17px 8px",
                            backgroundColor: "var(--progress-card)",
                            borderRadius: "8px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Image
                              onClick={(e) => {
                                e.preventDefault();
                                btnref1.click();
                                return false;
                              }}
                              preview={false}
                              src={
                                !filesData1
                                  ? props.recruitment_job_status.offer_letter &&
                                    props.recruitment_job_status.offer_letter
                                      .attachOfferletter &&
                                    props.recruitment_job_status.offer_letter.attachOfferletter.split(
                                      "."
                                    )[1] === "pdf"
                                    ? pdf
                                    : word
                                  : filesData1.type === "application/pdf"
                                  ? pdf
                                  : word
                              }
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "6px",
                                position: "relative",
                              }}
                            />
                          </div>
                          {!filesData1 && (
                            <div style={{ margin: "0px 10px" }}>
                              <p
                                className={commonCssStyle.marginBottomRemove}
                                style={{ color: "var(--body-text-color)" }}
                              >
                                {props.recruitment_job_status.offer_letter &&
                                  props.recruitment_job_status.offer_letter
                                    .attachOfferletter &&
                                  props.recruitment_job_status.offer_letter.attachOfferletter.substring(
                                    0,
                                    30
                                  ) + "..."}
                              </p>
                            </div>
                          )}
                          {filesDisp1 && (
                            <div
                              style={{
                                width: "100%",
                                margin: "0px 10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.formInputLabel}`}
                                >
                                  {filesData1.name}
                                </p>
                                <p
                                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.formInputLabel}`}
                                >
                                  {progress1 === "0"
                                    ? "0%"
                                    : progress1 === "50"
                                    ? "50%"
                                    : "100%"}
                                </p>
                              </div>
                              <Progress percent={progress1} size="small" />
                            </div>
                          )}
                          <div>
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                setFilesData1("");
                                setFilesDisp1(true);

                                // btnref.click();
                                // return false;
                              }}
                              style={{
                                position: "relative",
                                paddingTop: "5px",
                                paddingLeft: "1px",
                                cursor: "pointer",
                              }}
                            >
                              <CloseIcon color={"var(--headings-color)"} />
                            </span>
                          </div>
                        </div>
                      )}

                      <input
                        accept="application/pdf,.docx"
                        hidden
                        type="file"
                        name={["fileUpload"]}
                        multiple
                        onChange={handleChange1}
                        ref={(input) => {
                          btnref1 = input;
                        }}
                        onClick={(event) => {
                          event.currentTarget.value = null;
                        }}
                      />
                      {!filesData1 && (
                        <div
                          className={`dataImportUploadStyle`}
                          style={{
                            borderRadius: "0px",
                            width: "100%",
                          }}
                        >
                          <Row>
                            <Col>
                              <Row>
                                <Col>
                                  <p
                                    onClick={(e) => {
                                      e.preventDefault();
                                      btnref1.click();
                                      return false;
                                    }}
                                    style={{ textAlign: "center" }}
                                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.formInputLabel}`}
                                  >
                                    <Row style={{ gap: "4px" }}>
                                      <Col>
                                        <OfferLetterIcon />
                                      </Col>
                                      <Col> Attach offer letter</Col>
                                    </Row>
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                  </Row>
                )}

              {/* end off attach offer letter  */}

              {props.recruitmentIdData &&
                props.recruitmentIdData.hiringType &&
                props.recruitmentIdData.hiringType == "internal" && (
                  <Row align="middle" style={{ gap: "17px" }}>
                    <Col>
                      <p className={`${commonCssStyle.formInputLabel} `}>
                        Notify Candidate
                      </p>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Switch
                          onChange={(val) => {
                            setNotify(val);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
            </Col>
          </Row>
          <Row
            className={commonCssStyle.footerShadowForTemplete}
            style={{ gap: "17px" }}
            justify="end"
          >
            <Col>
              <Button
                className={commonCssStyle.buttonSettingsSecondary}
                style={{
                  width: "177px",
                  height: "45px",
                }}
                onClick={() => {
                  if (filesDisp1) {
                    setFilesDisp1(false);
                  }
                  closeOfferModal();
                }}
                type="default"
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                className={commonCssStyle.buttonSettingsPrimary}
                style={{
                  width: "177px",
                  height: "45px",
                }}
                htmlType="submit"
                type="primary"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
        </Modal>
   
                      {/* end of draft offer letter  */}
      </div>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    get_all_status: state.recruitment.get_all_status,
    settindsData: state.settings.settings,
    permissions: state.authpermissions.permissions,

    //for getting job internal or external details
    recruitmentIdData: state.requirements.requirement,

    //job status handler
    recruitment_job_status: state.recruitment.recruitment_job_status,

    //demo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    putRecruitmentJobStatusHandler: (val, callback) => {
      dispatch(putRecruitmentCandidateStatusAction(val, callback));
    },
    getRecruitmentAllStatusHandler: () => {
      dispatch(getRecruitmentAllStatusAction());
    },
    getCandidatejobStatusHandler: (id) => {
      dispatch(getRecruitmentsCandidatesJobStatusAction(id));
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },

    //for getting job external or internal
    getRequirementHandler: (id) => {
      dispatch(getRequirementAction(id));
    },

    //for getting job status handler
    getCandidatejobStatusHandler: (id) => {
      dispatch(getRecruitmentsCandidatesJobStatusAction(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Status);
