import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  departments: [],
  department: null,
};
export const masterDepartmentsSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {
    getDepartmentsSlice(state, action) {
      state.departments = action.payload.result;
    },
    getDepartmentSlice(state, action) {
      state.department = action.payload.result;
    },
    postDepartmentsSlice(state, action) {
      state.departments = [...state.departments, action.payload.result];
    },
    putDepartmentsSlice(state, action) {
      state.departments = state.departments.map((data) => {
        if (data.id === action.payload.result.id) {
          return action.payload.result;
        } else {
          return data;
        }
      });
    },
    deleteDepartmentSlice(state, action) {
      state.departments = state.departments.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});
export const {
  getDepartmentsSlice,
  getDepartmentSlice,
  postDepartmentsSlice,
  putDepartmentsSlice,
  deleteDepartmentSlice,
} = masterDepartmentsSlice.actions;
export default masterDepartmentsSlice.reducer;
