import {
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Table,
  Card,
  Tag,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import styles from "./Employee.module.css";
import commonCssStyle from "../../UI/CommonCss.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "antd/lib/form/Form";
import {
  getEmployee_permissionAction,
  postEmployee_permissionAction,
} from "../../../Reusable/Store/Action/EmployeesAction";
import { connect } from "react-redux";
import EmployeRPSelect from "./EmployeRPSelect";
import { CloseIcon } from "../../IconsComp/Icons";
import PermissionMessage from "../../UI/PermissionMessage";

const EmployeeRoleandPermission = (props) => {
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  // dashboard
  const dashboardArray = [{ label: "Dashboard", value: "dashboard" }];
  // candidate
  const candidateArray = [
    {
      label: "Candidate List",
      value: "candidate-list",
    },
    {
      label: "Candidate Add",
      value: "candidate-add",
    },
    {
      label: "Candidate Edit",
      value: "candidate-edit",
    },
    {
      label: "Candidate Delete",
      value: "candidate-delete",
    },
    {
      label: "Candidate Detail",
      value: "candidate-detail",
    },
    {
      label: "Candidate Mail List",
      value: "candidate-mail-list",
    },
    {
      label: "Candidate Mail Add",
      value: "candidate-mail-add",
    },
    {
      label: "Candidate Timeline",
      value: "candidate-timeline",
    },
    {
      label: "Candidate Task List",
      value: "candidate-task-list",
    },
    {
      label: "Candidate Download Resume",
      value: "candidate-download-resume",
    },
    {
      label: "Candidate Download Recording",
      value: "candidate-download-recording",
    },
    {
      label: "Candidate Export",
      value: "candidate-export",
    },
    {
      label: "Candidate Call",
      value: "candidate-call",
    },
    {
      label: "Candidate Interview List",
      value: "candidate-interview-list",
    },
    {
      label: "Candidate Job",
      value: "candidate-jobs",
    },
  ];
  //recruitment
  const recruitmentArray = [
    {
      label: "Recruitment List",
      value: "recruitment-list",
    },
    {
      label: "Recruitment Details",
      value: "recruitment-detail",
    },
    // {
    //   label: "Recruitment Add",
    //   value: "recruitment-add",
    // },
    // {
    //   label: "Recruitment Delete",
    //   value: "recruitment-delete",
    // },
    {
      label: "Recruitment Add Candidate",
      value: "recruitment-add-candidate",
    },
    {
      label: "Recruitment Candidate Pool",
      value: "recruitment-candidate-pool",
    },
    {
      label: "Recruitment Candidate Details",
      value: "recruitment-candidate-detail",
    },
    {
      label: "Recruitment Candidate Status Update",
      value: "recruitment-candidate-status update",
    },
    {
      label: "Recruitment Candidate Mail List",
      value: "recruitment-candidate-mail-list",
    },
    {
      label: "Recruitment Candidate Mail Add",
      value: "recruitment-candidate-mail-add",
    },
    {
      label: "Recruitment Candidate Timeline",
      value: "recruitment-candidate-timeline",
    },
    {
      label: "Recruitment Candidate Task List",
      value: "recruitment-candidate-task-list",
    },
    {
      label: "Recruitment Candidate Download Resume",
      value: "recruitment-candidate-download-resume",
    },
    {
      label: "Recruitment Candidate Download Recording",
      value: "recruitment-candidate-download-recording",
    },
    {
      label: "Recruitment Candidate Call",
      value: "recruitment-candidate-call",
    },
    {
      label: "Recruitment Candidate Interview List",
      value: "recruitment-candidate-interview-list",
    },
    {
      label: "Recruitment Candidate Offer Letter",
      value: "recruitment-candidate-offerLetter",
    },
    {
      label: "Recruitment Delete Candidate",
      value: "recruitment-delete-candidate",
    },
    // {
    //   label: "Recruitment Candidate Jobs",
    //   value: "recruitment-candidate-jobs",
    // },
  ];

  //requirement
  const requirementArray = [
    { label: "Job List", value: "job-list" },
    { label: "Job Add", value: "job-add" },
    { label: "Job Edit", value: "job-edit" },
    { label: "Job Delete", value: "job-delete" },
    { label: "Job Details", value: "job-detail" },
    { label: "Job Assign Employee", value: "job-assign-employee" },
    { label: "Job Task List", value: "job-task-list" },
  ];
  //company
  const companyArray = [
    { label: "Client List", value: "client-list" },
    { label: "Client Add", value: "client-add" },
    { label: "Client Edit", value: "client-edit" },
    { label: "Client Delete", value: "client-delete" },
    { label: "Client Details", value: "client-detail" },
    { label: "Client Job", value: "client-job" },
    { label: "Client Task List", value: "client-task-list" },
    { label: "Client Contact Info", value: "client-contact-info" },
    // {
    //   label: "Client Contact Person Info",
    //   value: "client-contact-person-info",
    // },
    { label: "Client Billing Detail", value: "client-billing-detail" },
  ];
  //employees
  const employeesArray = [
    { label: "Employees List", value: "employees-list" },
    { label: "Employees Add", value: "employees-add" },
    { label: "Employees Edit", value: "employees-edit" },
    { label: "Employees Delete", value: "employees-delete" },
    { label: "Employees Details", value: "employees-detail" },
    { label: "Employees Assign Role", value: "employees-assign-role" },
    {
      label: "Employees Assign Permission",
      value: "employees-assign-permission",
    },
    { label: "Employees Timeline", value: "employees-timeline" },
    { label: "Employees Task List", value: "employees-task-list" },
    {
      label: "Employees Download Recording",
      value: "employee-download-recording",
    },
    { label: "Employees Call", value: "employee-call" },
    { label: "Employees job", value: "employee-job-list" },
  ];
  //task
  const taskArray = [
    { label: "Task Add", value: "Task-add" },
    { label: "Task Edit", value: "Task-edit" },
    { label: "Task Delete", value: "Task-delete" },
    { label: "Task Detail", value: "Task-detail" },
    { label: "Task List View", value: "Task-list-view" },
    { label: "Task Calendar View", value: "Task-calendar-view" },
  ];
  //settings
  const settingsArray = [
    { label: "Settings System", value: "settings-system" },
    { label: "Settings Organisation", value: "settings-organisations" },
    { label: "Settings Billing", value: "settings-billing" },
    { label: "Settings Roles List", value: "settings-roles-list" },
    { label: "Settings Roles Add", value: "settings-roles-add" },
    { label: "Settings Roles Edit", value: "settings-roles-edit" },
    { label: "Settings Roles Delete", value: "settings-roles-delete" },
    {
      label: "Settings Application Emial Template",
      value: "settings-application-emailTemplate",
    },
    {
      label: "Settings Application Lead Source",
      value: "settings-application-leadSource",
    },
    {
      label: "Settings Application Candidate Reason",
      value: "settings-application-candidateReason",
    },
    {
      label: "Settings Application Job Listing",
      value: "settings-application-jobListing",
    },
    {
      label: "Settings Application Job Description",
      value: "settings-application-jobDescription",
    },
    {
      label: "Settings Application Sharing Medium",
      value: "settings-application-sharingMEdium",
    },
    {
      label: "Settings Application Department",
      value: "settings-application-department",
    },
    {
      label: "Settings Application Job Prefix",
      value: "settings-application-jobPrefix",
    },
    { label: "Settings Import", value: "settings-import" },
    { label: "Settings Application Tags", value: "settings-application-tags" },
  ];
  //interview
  const interviewArray = [
    { label: "Interview Add", value: "interview-add" },
    { label: "Interview Edit", value: "interview-edit" },
    { label: "Interview Details", value: "interview-detail" },
    { label: "Interview Delete", value: "interview-delete" },
    // { label: "Interview Calendar View", value: "interview-calendar-view" },
  ];
  const reportArray = [{ label: "Report", value: "report" }];

  // const [subRoles, setSubRoles] = useState([]);
  const [form] = useForm();
  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    data: "",
  });

  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      props.getEmployeePermissionHandler(props.id);
      // if (props.employee.designation) {
      // setSubRoles(
      //   props.roles &&
      //     props.roles.find((x) => x.role === props.employee.designation) &&
      //     props.roles.find((x) => x.role === props.employee.designation)
      //       .subRole
      // );
      // }
    }
    return () => {
      unsubscribed = true;
    };
  }, []);

  //dashboard
  const forDashboardMap = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding: "10px 23px 10px 23px",
        }}
        closable
        onClose={(e) => {
          e.preventDefault();
          setOpenDeleteModal({ open: true, data: tag.value });
          // handleClose(tag);
        }}
      >
        {tag.label}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagDashboard = dashboardArray
    .filter((data) => props.employee.permissions.includes(data.value))
    .map(forDashboardMap);
  //candidate
  const forMap = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding: "10px 23px 10px 23px",
        }}
        closable
        onClose={(e) => {
          e.preventDefault();
          // handleClose(tag);
          setOpenDeleteModal({ open: true, data: tag.value });
        }}
      >
        {tag.label}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagCandidate = candidateArray
    .filter((data) => props.employee.permissions.includes(data.value))
    .map(forMap);
  //recuritment
  const forRecuritmentMap = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding: "10px 23px 10px 23px",
        }}
        closable
        onClose={(e) => {
          e.preventDefault();
          // handleClose(tag);
          setOpenDeleteModal({ open: true, data: tag.value });
        }}
      >
        {tag.label}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagRecruitmentArray = recruitmentArray
    .filter((data) => props.employee.permissions.includes(data.value))
    .map(forRecuritmentMap);
  //requirement
  const forRequirementArrayMap = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding: "10px 23px 10px 23px",
        }}
        closable
        onClose={(e) => {
          e.preventDefault();
          // handleClose(tag);
          setOpenDeleteModal({ open: true, data: tag.value });
        }}
      >
        {tag.label}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagRequirementArray = requirementArray
    .filter((data) => props.employee.permissions.includes(data.value))
    .map(forRequirementArrayMap);
  //company
  const forCompanyArrayMap = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding: "10px 23px 10px 23px",
        }}
        closable
        onClose={(e) => {
          e.preventDefault();
          // handleClose(tag);
          setOpenDeleteModal({ open: true, data: tag.value });
        }}
      >
        {tag.label}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagCompanyArrayArray = companyArray
    .filter((data) => props.employee.permissions.includes(data.value))
    .map(forCompanyArrayMap);
  //employee
  const forEmployeeArrayMap = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding: "10px 23px 10px 23px",
        }}
        closable
        onClose={(e) => {
          e.preventDefault();
          // handleClose(tag);
          setOpenDeleteModal({ open: true, data: tag.value });
        }}
      >
        {tag.label}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagEmployeeArrayArray = employeesArray
    .filter((data) => props.employee.permissions.includes(data.value))
    .map(forEmployeeArrayMap);
  //task
  const forTaskArrayMap = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding: "10px 23px 10px 23px",
        }}
        closable
        onClose={(e) => {
          e.preventDefault();
          // handleClose(tag);
          setOpenDeleteModal({ open: true, data: tag.value });
        }}
      >
        {tag.label}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagTaskArrayArray = taskArray
    .filter((data) => props.employee.permissions.includes(data.value))
    .map(forTaskArrayMap);
  //settings
  const forSettingsArrayMap = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding: "10px 23px 10px 23px",
        }}
        closable
        onClose={(e) => {
          e.preventDefault();
          // handleClose(tag);
          setOpenDeleteModal({ open: true, data: tag.value });
        }}
      >
        {tag.label}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagSettingsArray = settingsArray
    .filter((data) => props.employee.permissions.includes(data.value))
    .map(forSettingsArrayMap);
  //interview
  const forInterviewArrayMap = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding: "10px 23px 10px 23px",
        }}
        closable
        onClose={(e) => {
          e.preventDefault();
          // handleClose(tag);
          setOpenDeleteModal({ open: true, data: tag.value });
        }}
      >
        {tag.label}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagInterviewArray = interviewArray
    .filter((data) => props.employee.permissions.includes(data.value))
    .map(forInterviewArrayMap);
  const forReportArrayMap = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding: "10px 23px 10px 23px",
        }}
        closable
        onClose={(e) => {
          e.preventDefault();
          // handleClose(tag);
          setOpenDeleteModal({ open: true, data: tag.value });
        }}
      >
        {tag.label}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagReportArray = reportArray
    .filter((data) => props.employee.permissions.includes(data.value))
    .map(forReportArrayMap);

  return (
    <>
      <Row>
        {props.authPermissions &&
          props.authPermissions.find(
            (data) => data === "employees-assign-role"
          ) && (
            <Col sm={24}>
              <Card style={{ padding: "20px", marginBottom: "30px" }}>
                {/* for roles and permission one dropdown should come and fields will be prefilled with default) */}

                <Form
                  form={form}
                  initialValues={{
                    role:
                      props.employee &&
                      props.employee.role &&
                      props.employee.role,
                  }}
                  onFinish={(val) => {
                    props.postemployee_roleAction(
                      {
                        employeeId: props.employee.userId,
                        role: val.role,
                      },
                      (success) => {
                        if (success) {
                          form.resetFields();
                        }
                      }
                    );
                  }}
                  layout="vertical"
                  // initialValues={
                  //   props.employee && {
                  //     designation: props.employee.designation,
                  //     role: props.employee.role,
                  //   }
                  // }
                >
                  <Row>
                    <Col sm={24}>
                      <Form.Item
                        label="Role"
                        name="role"
                        rules={[
                          {
                            required: true,
                            message: "Role is Required",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          // onChange={() => {
                          //   props.getCheckPermissionHandler(
                          //     "employees-assign-role",
                          //     (success) => {
                          //       if (success) {
                          //         return;
                          //       } else {
                          //         setOpenPMModal({
                          //           open: true,
                          //           permission: "Assign Role",
                          //         });
                          //       }
                          //     }
                          //   );
                          // }}
                          // onChange={(e) => {
                          //   setSubRoles(
                          //     props.roles &&
                          //       props.allrolestypes.find((x) => x.role === e) &&
                          //       props.allrolestypes.find((x) => x.role === e).subRole
                          //   );
                          // }}
                        >
                          {props.roles &&
                            props.roles.map((x, index) => {
                              return (
                                <Select.Option key={index} value={x.name}>
                                  {x.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col sm={24}>
                      <div className={styles.footerContainer}>
                        {/* <Button
                    className={commonCssStyle.buttonSettingsSecondary}
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    Cancel
                  </Button> */}
                        <Button
                          type="primary"
                          htmlType="submit"
                          className={commonCssStyle.buttonSettingsPrimary}
                        >
                          Save
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          )}
        {props.authPermissions &&
          props.authPermissions.find(
            (data) => data === "employees-assign-permission"
          ) && (
            <>
              <Col md={24}>
                <Card style={{ padding: "30px" }}>
                  <Row align="middle">
                    <Col md={3}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                      >
                        Dashboard
                      </p>
                    </Col>
                    <Col md={20}>
                      <EmployeRPSelect
                        tagsData={(val) => {
                          props.postEmployeePermissionHandler(
                            {
                              employeeId: props.id,
                              permission: val,
                            },
                            (success) => {}
                          );
                        }}
                        tags={props.employee.permissions}
                        name={"dashboard"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        {tagDashboard}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24} className={commonCssStyle.marginTop}>
                <Card style={{ padding: "30px" }}>
                  <Row align="middle">
                    <Col md={3}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                      >
                        Candidate
                      </p>
                    </Col>
                    <Col md={20}>
                      <EmployeRPSelect
                        tagsData={(val) => {
                          props.postEmployeePermissionHandler(
                            {
                              employeeId: props.id,
                              permission: val,
                            },
                            (success) => {}
                          );
                        }}
                        tags={props.employee.permissions}
                        name={"candidate"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        {tagCandidate}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24} className={commonCssStyle.marginTop}>
                <Card style={{ padding: "30px" }}>
                  <Row align="middle">
                    <Col md={3}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                      >
                        Recruitment
                      </p>
                    </Col>
                    <Col md={20}>
                      <EmployeRPSelect
                        tagsData={(val) => {
                          props.postEmployeePermissionHandler(
                            {
                              employeeId: props.id,
                              permission: val,
                            },
                            (success) => {}
                          );
                        }}
                        tags={props.employee.permissions}
                        name={"recruitment"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        {tagRecruitmentArray}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24} className={commonCssStyle.marginTop}>
                <Card style={{ padding: "30px" }}>
                  <Row align="middle">
                    <Col md={3}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                      >
                        Requirement
                      </p>
                    </Col>
                    <Col md={20}>
                      <EmployeRPSelect
                        tagsData={(val) => {
                          props.postEmployeePermissionHandler(
                            {
                              employeeId: props.id,
                              permission: val,
                            },
                            (success) => {}
                          );
                        }}
                        tags={props.employee.permissions}
                        name={"requirement"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        {tagRequirementArray}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24} className={commonCssStyle.marginTop}>
                <Card style={{ padding: "30px" }}>
                  <Row align="middle">
                    <Col md={3}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                      >
                        Client & Company
                      </p>
                    </Col>
                    <Col md={20}>
                      <EmployeRPSelect
                        tagsData={(val) => {
                          props.postEmployeePermissionHandler(
                            {
                              employeeId: props.id,
                              permission: val,
                            },
                            (success) => {}
                          );
                        }}
                        tags={props.employee.permissions}
                        name={"company"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        {tagCompanyArrayArray}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24} className={commonCssStyle.marginTop}>
                <Card style={{ padding: "30px" }}>
                  <Row align="middle">
                    <Col md={3}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                      >
                        Task
                      </p>
                    </Col>
                    <Col md={20}>
                      <EmployeRPSelect
                        tagsData={(val) => {
                          props.postEmployeePermissionHandler(
                            {
                              employeeId: props.id,
                              permission: val,
                            },
                            (success) => {}
                          );
                        }}
                        tags={props.employee.permissions}
                        name={"task"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        {tagTaskArrayArray}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24} className={commonCssStyle.marginTop}>
                <Card style={{ padding: "30px" }}>
                  <Row align="middle">
                    <Col md={3}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                      >
                        Employees
                      </p>
                    </Col>
                    <Col md={20}>
                      <EmployeRPSelect
                        tagsData={(val) => {
                          props.postEmployeePermissionHandler(
                            {
                              employeeId: props.id,
                              permission: val,
                            },
                            (success) => {}
                          );
                        }}
                        tags={props.employee.permissions}
                        name={"employees"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        {tagEmployeeArrayArray}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24} className={commonCssStyle.marginTop}>
                <Card style={{ padding: "30px" }}>
                  <Row align="middle">
                    <Col md={3}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                      >
                        Settings
                      </p>
                    </Col>
                    <Col md={20}>
                      <EmployeRPSelect
                        tagsData={(val) => {
                          props.postEmployeePermissionHandler(
                            {
                              employeeId: props.id,
                              permission: val,
                            },
                            (success) => {}
                          );
                        }}
                        tags={props.employee.permissions}
                        name={"settings"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        {tagSettingsArray}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24} className={commonCssStyle.marginTop}>
                <Card style={{ padding: "30px" }}>
                  <Row align="middle">
                    <Col md={3}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                      >
                        Interview
                      </p>
                    </Col>
                    <Col md={20}>
                      <EmployeRPSelect
                        tagsData={(val) => {
                          props.postEmployeePermissionHandler(
                            {
                              employeeId: props.id,
                              permission: val,
                            },
                            (success) => {}
                          );
                        }}
                        tags={props.employee.permissions}
                        name={"interview"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        {tagInterviewArray}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={24} className={commonCssStyle.marginTop}>
                <Card style={{ padding: "30px" }}>
                  <Row align="middle">
                    <Col md={3}>
                      <p
                        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.rolesAndPermission}`}
                      >
                        Report
                      </p>
                    </Col>
                    <Col md={20}>
                      <EmployeRPSelect
                        tagsData={(val) => {
                          props.postEmployeePermissionHandler(
                            {
                              employeeId: props.id,
                              permission: val,
                            },
                            (success) => {}
                          );
                        }}
                        tags={props.employee.permissions}
                        name={"report"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        {tagReportArray}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </>
          )}
      </Row>
      <Modal
        closable={false}
        title={
          <Row>
            <Col md={20}>
              <p className={commonCssStyle.popUpHeadingText}>
                Delete Roles and Permission
              </p>
            </Col>
            <Col md={4}>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  setOpenDeleteModal({ open: false });
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
        footer={
          <>
            <Row style={{ gap: "10px" }} justify="end" align="middle">
              <Col>
                <Button
                  type="default"
                  className={commonCssStyle.buttonSettingsSecondary}
                  style={{ height: "48px", fontSize: "16px", width: "181px" }}
                  onClick={() => {
                    setOpenDeleteModal({ open: false });
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className={commonCssStyle.buttonSettingsPrimary}
                  onClick={() => {
                    let removeTag = props.employee.permissions.filter(
                      (data) => data !== openDeleteModal.data
                    );
                    props.postEmployeePermissionHandler(
                      { employeeId: props.id, permission: removeTag },
                      (success) => {
                        if (success) {
                          setOpenDeleteModal({ open: false, data: "" });
                        }
                      }
                    );
                  }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </>
        }
        open={openDeleteModal.open}
        // onCancel={closeDeleteHandler}
        onOk={() => {
          setOpenDeleteModal({ open: false });
        }}
      >
        <Row>
          <Col>
            <p className={commonCssStyle.tableHeadingTextData}>
              Are you sure you want to delete
            </p>
          </Col>
        </Row>
      </Modal>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    authPermissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeePermissionHandler: (id) => {
      dispatch(getEmployee_permissionAction(id));
    },
    postEmployeePermissionHandler: (data, callback) => {
      dispatch(postEmployee_permissionAction(data, callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeRoleandPermission);
