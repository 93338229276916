import styles from "../ContentPage/ContentPage.module.css";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import {
  Avatar,
  Menu,
  Col,
  Row,
  Card,
  Dropdown,
  Space,
  Typography,
  Divider,
} from "antd";
import {
  CalendarBlankIcon,
  CaretBlackDownIcon,
  CaretBlackRightIcon,
  ClockIcon,
  FilterOutlinedIcon,
  UserIcon,
} from "../../../IconsComp/Icons";
import { useState } from "react";
import { connect } from "react-redux";
import { getDashboardAction } from "../../../../Reusable/Store/Action/DashboardAction";
import { useEffect } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import InterviewDetails from "../../../Screens/AddCandidatesComp/CandidatesSummary/InterviewDetails";
import RegularTaskDrawer from "../../TaskCalendar/RegularTaskDrawer";
import { getTaskAndCalendarAction } from "../../../../Reusable/Store/Action/TaskAndCalendarAction";
import PermissionMessage from "../../../UI/PermissionMessage";
import { checkPermissionAction } from "../../../../Reusable/Store/Action/AuthPermissionsAction";
import LoadingIcon from "../../../UI/LoadingIcon";

const menu = (
  <Menu
    className="dashBoardDropDown"
    style={{ width: "144px", height: "173px", padding: "16px 0px" }}
    selectable
    defaultSelectedKeys={["1"]}
    items={[
      {
        key: "1",
        label: "Today",
      },
      {
        key: "2",
        label: "This Week",
      },
      {
        key: "3",
        label: "Previous Week",
      },
      {
        key: "4",
        label: "This Month",
      },
      {
        key: "5",
        label: "Custom",
      },
    ]}
  />
);

function ContentPage(props) {
  const navigate = useNavigate();
  // const [colors, setColor] = useState("var(--theme-heading-color)");
  const [cardBackgroundColor1, setCardBackgroundColor1] =
    useState("var(--theme)");
  const [cardBackgroundColor2, setCardBackgroundColor2] =
    useState("var(--theme)");
  const [cardBackgroundColor3, setCardBackgroundColor3] =
    useState("var(--theme)");
  const [cardBackgroundColor4, setCardBackgroundColor4] =
    useState("var(--theme)");
  const [cardBackgroundColor5, setCardBackgroundColor5] =
    useState("var(--theme)");
  const [cardBackgroundColor6, setCardBackgroundColor6] =
    useState("var(--theme)");
  const [cardBackgroundColor7, setCardBackgroundColor7] =
    useState("var(--theme)");
  const [cardBackgroundColor8, setCardBackgroundColor8] =
    useState("var(--theme)");
  const [taskRowBgColor, setTaskRowBgColor] = useState("var(--theme)");
  const [mouseKey, setMouseKey] = useState("");
  useEffect(() => {
    props.getDashboardDataHandler();
  }, []);
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });

  const [openInterviewDetails, setOpenInterviewDetails] = useState(false);
  const [openTaskDetails, setOpenTaskDetails] = useState(false);

  const dataFormate =
    props.settings && props.settings.dateFormat
      ? props.settings.dateFormat
      : "DD/MM/YYYY";
  const timeFormate =
    props.settings &&
    props.settings.timeFormat &&
    props.settings.timeFormat === "24hours"
      ? "HH:mm"
      : props.settings.timeFormat === "12hours"
      ? "hh:mm"
      : "HH:mm";

  function generateGreetings() {
    var currentHour = moment().format("HH");

    if (
      currentHour >= 3 &&
      currentHour < 12 &&
      props.onProfile &&
      props.onProfile.firstName &&
      props.onProfile.lastName
    ) {
      return (
        "Good Morning" +
        " " +
        props.onProfile.firstName +
        " " +
        props.onProfile.lastName
      );
    } else if (
      currentHour >= 12 &&
      currentHour < 15 &&
      props.onProfile &&
      props.onProfile.firstName &&
      props.onProfile.lastName
    ) {
      return (
        "Good Afternoon" +
        " " +
        props.onProfile.firstName +
        " " +
        props.onProfile.lastName
      );
    } else if (
      currentHour >= 15 &&
      currentHour < 20 &&
      props.onProfile &&
      props.onProfile.firstName &&
      props.onProfile.lastName
    ) {
      return (
        "Good Evening" +
        " " +
        props.onProfile.firstName +
        " " +
        props.onProfile.lastName
      );
    } else if (
      currentHour >= 20 &&
      currentHour < 3 &&
      props.onProfile &&
      props.onProfile.firstName &&
      props.onProfile.lastName
    ) {
      return (
        "Good Night" +
        " " +
        props.onProfile.firstName +
        " " +
        props.onProfile.lastName
      );
    } else {
      return (
        props.onProfile &&
        props.onProfile.firstName &&
        props.onProfile.lastName &&
        "Hello" +
          " " +
          props.onProfile.firstName +
          " " +
          props.onProfile.lastName
      );
    }
  }

  if (window.location.hash === "#/" || window.location.hash === "#/dashboard") {
    localStorage.removeItem("clientCompany");
    localStorage.removeItem("employee");
    localStorage.removeItem("requirementFilterData");
    localStorage.removeItem("recuritmentFilterData");
    localStorage.removeItem("taskFilterData");
    localStorage.removeItem("interviewFilterData");
    localStorage.removeItem("candidateDetailFromRequirement");
    localStorage.removeItem("candidateListForPool");
    localStorage.removeItem("recuritmentCandidateFilter");
    localStorage.removeItem("reportsCompanyDetailFilterData");
    localStorage.removeItem("reportsjobdetailfilterdata");
    localStorage.removeItem("reportsjobcandidatefilterdata");
    localStorage.removeItem("recuritmentReportFilter");
    localStorage.removeItem("fromWhichPage");
    localStorage.removeItem("employeeReportsFilter");
    localStorage.removeItem("candidateDetail");
    localStorage.removeItem("ClientCompanySort");
    localStorage.removeItem("employeeSort");
    localStorage.removeItem("ReportsCompanyReport1Sort");
    localStorage.removeItem("reportsjobcandidatefilterdataSort");
    localStorage.removeItem("ReportsJobDetailFilterOneSort");
    localStorage.removeItem("reportRecruitmentEmployeeSort");
    localStorage.removeItem("candidateSort");
    localStorage.removeItem("interivewSort");
    localStorage.removeItem("taskSort");
    localStorage.removeItem("recuritmentSort");
    localStorage.removeItem("requirementSort");
  }

  return (
    <>
      <Row justify="space-between">
        <Col>
          <h1 className={`${commonCssStyle.headingText}`}>Dashboard</h1>
        </Col>
        <Col>
          <p className={`${commonCssStyle.wishesCard}`}>
            {generateGreetings()}
          </p>
        </Col>
        <Col>
          <h2
            style={{
              textAlign: "right",
            }}
            className={`${commonCssStyle.headingText} ${commonCssStyle.marginBottomRemove}`}
          >{`${moment().format(dataFormate)}`}</h2>
          <p
            style={{
              textAlign: "right",
              color: "var(--body-text-color)",
            }}
            className={` ${commonCssStyle.marginBottomRemove}`}
          >{`${moment().format(timeFormate)}`}</p>
        </Col>
      </Row>

      <div>
        <Row>
          {props.permissions &&
            props.permissions.find(
              (data) =>
                data === "Task-add" ||
                data === "Task-edit" ||
                data === "Task-delete" ||
                data === "Task-list-view" ||
                data === "Task-calendar-view" ||
                data === "Task-detail"
            ) && (
              <Col
                style={{ marginRight: "2%", width: "48%" }}
                className={`${styles.contentContainer} Dashboard-myTask-class`}
              >
                <Row>
                  <Col md={20}>
                    <h3
                      id="Dashboard-mytask"
                      className={`${commonCssStyle.subHeadingText}`}
                      style={{
                        marginBottom: "32px",
                        color: "var(--headings-color)",
                      }}
                    >
                      My Task
                    </h3>
                  </Col>
                  {props.permissions &&
                  props.permissions.find((data) => data === "Task-detail") &&
                  props.Dashboard &&
                  props.Dashboard.task &&
                  props.Dashboard.task.length > 0 ? (
                    <Col md={4}>
                      {/* <Link to={"/taskCalendar"} state={{ disp: "taskTable" }}> */}
                      <h3
                        onClick={() => {
                          props.getCheckPermissionHandler(
                            "Task-detail",
                            (success) => {
                              if (success) {
                                navigate("/taskCalendar", {
                                  state: { disp: "taskTable" },
                                });
                              } else {
                                setOpenPMModal({
                                  open: true,
                                  permission: "Task Details",
                                });
                              }
                            }
                          );
                        }}
                        className={`${commonCssStyle.textNormal} ${commonCssStyle.coursorPointer}`}
                      >
                        See More
                      </h3>
                      {/* </Link> */}
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                {props.Dashboard &&
                props.Dashboard.task &&
                props.Dashboard.task.length > 0 ? (
                  props.Dashboard.task.slice(0, 4).map((data) => {
                    return (
                      <>
                        <Row>
                          <Col md={21}>
                            <Row style={{ marginBottom: "18px" }}>
                              <Col>
                                <Avatar
                                  size={40}
                                  className={commonCssStyle.avtarBgClr}
                                  style={{
                                    marginRight: "16px",
                                    paddingTop: "4px",
                                  }}
                                >
                                  <CalendarBlankIcon
                                    color={"var(--primaryColor)"}
                                  />
                                </Avatar>
                              </Col>
                              <Col>
                                <p
                                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.lightText}`}
                                >
                                  <span className={commonCssStyle.darkText}>
                                    {data.title}
                                  </span>
                                </p>
                                <p
                                  className={commonCssStyle.marginBottomRemove}
                                  style={{
                                    fontSize: "12px",
                                    color: "var(--body-text-color)",
                                  }}
                                >
                                  <span>
                                    {data.startDate &&
                                      moment(data.startDate).format(
                                        dataFormate
                                      )}
                                  </span>
                                  &nbsp;to&nbsp;
                                  <span>
                                    {data.dueDate &&
                                      moment(data.dueDate).format(dataFormate)}
                                  </span>
                                </p>
                              </Col>
                            </Row>
                          </Col>

                          <Col md={3}>
                            {/* <Link
                              to={"/taskCalendar"}
                              state={{ id: data.id, type: "task" }}
                            > */}
                            <span
                              className={commonCssStyle.coursorPointer}
                              onClick={() => {
                                // props.getCheckPermissionHandler(
                                //   "Task-detail",
                                //   (success) => {
                                //     if (success) {
                                navigate("/taskCalendar", {
                                  state: { id: data.id, type: "task" },
                                });
                                //   } else {
                                //     setOpenPMModal({
                                //       open: true,
                                //       permission: "Task Details",
                                //     });
                                //   }
                                // }
                                // );
                              }}
                            >
                              <CaretBlackRightIcon
                                color={"var(--icons-color)"}
                              />
                            </span>
                            {/* </Link> */}
                          </Col>
                        </Row>
                      </>
                    );
                  })
                ) : (
                  <p
                    style={{
                      color: "var(--body-text-color)",
                      textAlign: "center",
                      fontSize: "23px",
                    }}
                    className={commonCssStyle.marginBottomRemove}
                  >
                    No Data
                  </p>
                )}
              </Col>
            )}
          {props.permissions &&
            props.permissions.find(
              (data) =>
                data === "interview-add" ||
                data === "interview-edit" ||
                data === "interview-detail" ||
                data === "interview-delete"
            ) && (
              <Col
                style={{ marginLeft: "2%", width: "48%" }}
                className={`${styles.contentContainer} Dashboard-myinterview`}
              >
                <Row>
                  <Col md={20}>
                    <h3
                      id="Dashboard-myinterview"
                      className={`${commonCssStyle.subHeadingText}`}
                      style={{
                        marginBottom: "32px",
                        color: "var(--headings-color)",
                      }}
                    >
                      My Interview
                    </h3>
                  </Col>
                  {props.permissions &&
                  props.permissions.find(
                    (data) => data === "interview-detail"
                  ) &&
                  props.Dashboard &&
                  props.Dashboard.interview &&
                  props.Dashboard.interview.length > 0 ? (
                    <Col md={4}>
                      {/* <Link to={"/taskCalendar"} state={{ disp: "interviewTable" }}> */}
                      <h3
                        onClick={() => {
                          props.getCheckPermissionHandler(
                            "interview-detail",
                            (success) => {
                              if (success) {
                                navigate("/taskCalendar", {
                                  state: { disp: "interviewTable" },
                                });
                              } else {
                                setOpenPMModal({
                                  open: true,
                                  permission: "Interview Details",
                                });
                              }
                            }
                          );
                        }}
                        className={`${commonCssStyle.textNormal} ${commonCssStyle.coursorPointer}`}
                      >
                        See More
                      </h3>
                      {/* </Link> */}
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>

                {props.Dashboard &&
                props.Dashboard.interview &&
                props.Dashboard.interview.length > 0 ? (
                  props.Dashboard.interview.slice(0, 4).map((data, index) => {
                    return (
                      <>
                        <Row>
                          <Col md={21}>
                            <Row style={{ marginBottom: "18px" }}>
                              <Col>
                                <Avatar
                                  size={40}
                                  className={commonCssStyle.avtarBgClr}
                                  style={{
                                    marginRight: "16px",
                                    paddingTop: "4px",
                                  }}
                                >
                                  <CalendarBlankIcon
                                    color={"var(--primaryColor)"}
                                  />
                                </Avatar>
                              </Col>
                              <Col>
                                <p
                                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.lightText}`}
                                >
                                  <span
                                    className={` ${commonCssStyle.darkText}`}
                                  >
                                    {data.title ? data.title : "N/A"}
                                  </span>
                                </p>
                                <p
                                  className={commonCssStyle.marginBottomRemove}
                                  style={{
                                    fontSize: "12px",
                                    color: "var(--body-text-color)",
                                  }}
                                >
                                  <span>
                                    {data &&
                                      data.interviewDate &&
                                      moment(data.interviewDate).format(
                                        dataFormate
                                      )}
                                  </span>
                                  &nbsp;&nbsp;
                                  {/* <span>
                                    {` ${
                                      data &&
                                      data.interviewStartTime &&
                                      moment(data.interviewStartTime).format(
                                        timeFormate
                                      )
                                    }
                                    -
                                    ${
                                      data &&
                                      data.interviewEndTime &&
                                      moment(data.interviewEndTime).format(
                                        timeFormate
                                      )
                                    }`}
                                  </span> */}
                                  <span>
                                    {` ${
                                      data &&
                                      data.interviewStartTime &&
                                      data.interviewStartTime
                                    }
                                    -
                                    ${
                                      data &&
                                      data.interviewEndTime &&
                                      data.interviewEndTime
                                    }`}
                                  </span>
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={3}>
                            {/* <Link
                              to={"/taskCalendar"}${commonCssStyle.textEllipsis}
                              state={{ id: data.id, type: "interview" }}
                            > */}
                            <span
                              className={commonCssStyle.coursorPointer}
                              onClick={() => {
                                // props.getCheckPermissionHandler(
                                //   "interview-detail",
                                //   (success) => {
                                //     if (success) {
                                navigate("/taskCalendar", {
                                  state: {
                                    id: data.id,
                                    data: data,
                                    type: "interview",
                                  },
                                });
                                //     } else {
                                //       setOpenPMModal({
                                //         open: true,
                                //         permission: "Interview Details",
                                //       });
                                //     }
                                //   }
                                // );
                              }}
                            >
                              <CaretBlackRightIcon
                                color={"var(--icons-color)"}
                              />
                            </span>
                            {/* </Link> */}
                          </Col>
                        </Row>
                      </>
                    );
                  })
                ) : (
                  <p
                    style={{
                      color: "var(--body-text-color)",
                      textAlign: "center",
                      fontSize: "23px",
                    }}
                    className={commonCssStyle.marginBottomRemove}
                  >
                    No Data
                  </p>
                )}
              </Col>
            )}
        </Row>
        <Row>
          <Col
            md={24}
            id="Dashboard-myjoinee"
            className={`${styles.contentContainer} `} /* Dashboard-myTask-class */
          >
            <Row justify="space-between">
              <Col>
                <h3
                  className={`${commonCssStyle.subHeadingText}`}
                  style={{
                    marginBottom: "32px",
                    color: "var(--headings-color)",
                  }}
                >
                  My Joinee
                </h3>
              </Col>
              {props.permissions &&
              props.permissions.find((data) => data === "candidate-detail") &&
              props.Dashboard &&
              props.Dashboard.task &&
              props.Dashboard.task.length > 0 ? (
                <Col>
                  <h3
                    onClick={() => {
                      navigate("/candidatesDetailsPage");
                    }}
                    className={`${commonCssStyle.textNormal} ${commonCssStyle.coursorPointer}`}
                  >
                    See More
                  </h3>
                </Col>
              ) : (
                ""
              )}
            </Row>
            {props.Dashboard &&
            props.Dashboard.joinee &&
            props.Dashboard.joinee.length > 0 ? (
              props.Dashboard.joinee.slice(0, 4).map((data) => {
                return (
                  <>
                    <Row justify="space-between">
                      <Col>
                        <Row>
                          <Col>
                            <p className={commonCssStyle.lightText}>
                              <Avatar
                                size={40}
                                className={commonCssStyle.avtarBgClr}
                                style={{
                                  marginRight: "16px",
                                  paddingTop: "4px",
                                }}
                              >
                                <UserIcon color={"var(--primaryColor)"} />
                              </Avatar>
                            </p>
                          </Col>
                          <Col>
                            <span className={commonCssStyle.darkText}>
                              {data.candidateName}
                            </span>
                            <br />
                            <span className={commonCssStyle.darkText}>
                              {data.joiningDate === null ||
                              data.joiningDate === undefined ||
                              data.joiningDate === "0000-00-00"
                                ? "N/A"
                                : moment(data.joiningDate).format(dataFormate)}
                            </span>
                          </Col>
                        </Row>
                      </Col>

                      <Col>
                        <span
                          onClick={() => {
                            navigate("/candidatesDetailsPage/summary", {
                              state: {
                                id: data.candidateId,
                                from: "candidateList",
                              },
                            });
                          }}
                          className={commonCssStyle.coursorPointer}
                        >
                          <CaretBlackRightIcon color={"var(--icons-color)"} />
                        </span>
                      </Col>
                    </Row>
                  </>
                );
              })
            ) : (
              <p
                style={{
                  color: "var(--body-text-color)",
                  textAlign: "center",
                  fontSize: "23px",
                }}
                className={commonCssStyle.marginBottomRemove}
              >
                No Data
              </p>
            )}
          </Col>
        </Row>
      </div>

      <div className={styles.contentContainer} id="Dashboard-candidateSummary">
        <h3
          className={commonCssStyle.subHeadingText}
          style={{ marginBottom: "25px", color: "var(--headings-color)" }}
        >
          Candidate Summary
        </h3>

        <Row justify="space-evenly">
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor1("#2f7bf5");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor1("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor1}`,
              }}
            >
              <Row>
                <Col md={24}>
                  <h3
                    style={{ color: "#2f7bf5" }}
                    className={styles.candidateSummaryCardTitle}
                  >
                    {props.Dashboard &&
                      props.Dashboard.candidateSummary &&
                      props.Dashboard.candidateSummary.activeCount &&
                      props.Dashboard.candidateSummary.activeCount}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <p className={styles.candidateSummaryCardSubTitle}>Total</p>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor7("#0CA2D1");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor7("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor7}`,
              }}
            >
              <Row>
                <Col md={24}>
                  <h3
                    style={{ color: "#0CA2D1" }}
                    className={styles.candidateSummaryCardTitle}
                  >
                    {props.Dashboard &&
                      props.Dashboard.candidateSummary &&
                      props.Dashboard.candidateSummary.appliedCount &&
                      props.Dashboard.candidateSummary.appliedCount}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <p className={styles.candidateSummaryCardSubTitle}>Applied</p>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor2("#0CA2D1");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor2("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor2}`,
              }}
            >
              <Row>
                <Col md={24}>
                  <h3
                    style={{ color: "#0CA2D1" }}
                    className={styles.candidateSummaryCardTitle}
                  >
                    {props.Dashboard &&
                      props.Dashboard.candidateSummary &&
                      props.Dashboard.candidateSummary.screeningCount &&
                      props.Dashboard.candidateSummary.screeningCount}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <p className={styles.candidateSummaryCardSubTitle}>
                    Screening
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor3("#FF9A02");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor3("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor3}`,
              }}
            >
              <Row>
                <Col md={24}>
                  <h3
                    style={{ color: "#FF9A02" }}
                    className={styles.candidateSummaryCardTitle}
                  >
                    {props.Dashboard &&
                      props.Dashboard.candidateSummary &&
                      props.Dashboard.candidateSummary.interviewCount &&
                      props.Dashboard.candidateSummary.interviewCount}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <p className={styles.candidateSummaryCardSubTitle}>
                    Interviewing
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row justify="space-evenly">
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor4("#0E8553");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor4("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor4}`,
              }}
            >
              <Row>
                <Col md={24}>
                  <h3
                    style={{ color: "#0E8553" }}
                    className={styles.candidateSummaryCardTitle}
                  >
                    {props.Dashboard &&
                      props.Dashboard.candidateSummary &&
                      props.Dashboard.candidateSummary.offeredCount &&
                      props.Dashboard.candidateSummary.offeredCount}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <p className={styles.candidateSummaryCardSubTitle}>Offered</p>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor5("#0E8553");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor5("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor5}`,
              }}
            >
              <Row>
                <Col md={24}>
                  <h3
                    style={{ color: "#0E8553" }}
                    className={styles.candidateSummaryCardTitle}
                  >
                    {props.Dashboard &&
                      props.Dashboard.candidateSummary &&
                      props.Dashboard.candidateSummary.hiredCount &&
                      props.Dashboard.candidateSummary.hiredCount}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <p className={styles.candidateSummaryCardSubTitle}>Hired</p>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor6("#FD5858");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor6("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor6}`,
              }}
            >
              <Row>
                <Col md={24}>
                  <h3
                    style={{ color: "#FD5858" }}
                    className={styles.candidateSummaryCardTitle}
                  >
                    {props.Dashboard &&
                      props.Dashboard.candidateSummary &&
                      props.Dashboard.candidateSummary.declinedCount &&
                      props.Dashboard.candidateSummary.declinedCount}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <p className={styles.candidateSummaryCardSubTitle}>
                    Declined
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card
              className={styles.cards}
              onMouseEnter={() => {
                setCardBackgroundColor8("#FD5858");
              }}
              onMouseLeave={() => {
                setCardBackgroundColor8("var(--theme)");
              }}
              style={{
                border: `3px solid ${cardBackgroundColor8}`,
              }}
            >
              <Row>
                <Col md={24}>
                  <h3
                    style={{ color: "#FD5858" }}
                    className={styles.candidateSummaryCardTitle}
                  >
                    {props.Dashboard &&
                      props.Dashboard.candidateSummary &&
                      props.Dashboard.candidateSummary
                        .notInConsiderationCount &&
                      props.Dashboard.candidateSummary.notInConsiderationCount}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <p className={styles.candidateSummaryCardSubTitle}>
                    Not in Consideration
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <div className={styles.contentContainer} id="Dashboard-jobSummary">
        <h3
          style={{ paddingBottom: "24px", color: "var(--headings-color)" }}
          className={commonCssStyle.subHeadingText}
        >
          Job Summary
        </h3>
        <div>
          <Row justify="space-around" align="middle">
            <Col md={7}>
              <p
                className={styles.jobSummaryContent1}
                style={{ color: "var(--primaryColor)" }}
              >
                {props.Dashboard &&
                  props.Dashboard.jobSummary &&
                  props.Dashboard.jobSummary.publishedJobs &&
                  props.Dashboard.jobSummary.publishedJobs}
              </p>
              <p className={styles.jobSummaryContent2}>Published jobs</p>
            </Col>
            <Col md={1}>
              <div
                className={styles.jobSummaryContentBar}
                style={{
                  backgroundColor: "var(--secondary-card-color)",
                }}
              ></div>
            </Col>
            <Col md={7}>
              <p
                className={styles.jobSummaryContent1}
                style={{ color: "var(--primaryColor)" }}
              >
                {props.Dashboard &&
                  props.Dashboard.jobSummary &&
                  props.Dashboard.jobSummary.liveJobs &&
                  props.Dashboard.jobSummary.liveJobs}
              </p>
              <p className={styles.jobSummaryContent2}>Live jobs</p>
            </Col>
            <Col md={1}>
              <div
                className={styles.jobSummaryContentBar}
                style={{
                  backgroundColor: "var(--secondary-card-color)",
                }}
              ></div>
            </Col>
            <Col md={7}>
              <p
                className={styles.jobSummaryContent1}
                style={{ color: "var(--primaryColor)" }}
              >
                {props.Dashboard &&
                  props.Dashboard.jobSummary &&
                  props.Dashboard.jobSummary.closedJobs &&
                  props.Dashboard.jobSummary.closedJobs}
              </p>
              <p className={styles.jobSummaryContent2}>Closed jobs</p>
            </Col>
          </Row>
        </div>
      </div>

      <div>
        <Row>
          {props.permissions &&
            props.permissions.find(
              (data) =>
                data === "Task-add" ||
                data === "Task-edit" ||
                data === "Task-delete" ||
                data === "Task-list-view" ||
                data === "Task-calendar-view" ||
                data === "Task-detail"
            ) && (
              <Col
                style={{ marginRight: "2%", width: "48%" }}
                className={styles.contentContainer}
              >
                <Row>
                  <Col md={20}>
                    <h3
                      id="Dashboard-taskAging"
                      className={`${commonCssStyle.subHeadingText}`}
                      style={{
                        marginBottom: "32px",
                        // padding:"24px",
                        color: "var(--headings-color)",
                      }}
                    >
                      Task Aging
                    </h3>
                  </Col>
                  {props.permissions &&
                  props.permissions.find((data) => data === "Task-detail") &&
                  props.Dashboard &&
                  props.Dashboard.taskAging &&
                  props.Dashboard.taskAging.length > 0 ? (
                    <Col md={4}>
                      {/* <Link to={"/taskCalendar"} state={{ disp: "taskTable" }}> */}
                      <h3
                        onClick={() => {
                          props.getCheckPermissionHandler(
                            "Task-detail",
                            (success) => {
                              if (success) {
                                navigate("/taskCalendar", {
                                  state: { disp: "taskTable" },
                                });
                              } else {
                                setOpenPMModal({
                                  open: true,
                                  permission: "Task Details",
                                });
                              }
                            }
                          );
                        }}
                        className={`${commonCssStyle.textNormal} ${commonCssStyle.coursorPointer}`}
                      >
                        See More
                      </h3>
                      {/* </Link> */}
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>

                {props.Dashboard &&
                props.Dashboard.taskAging &&
                props.Dashboard.taskAging.length > 0 ? (
                  props.Dashboard.taskAging.slice(0, 5).map((data, index) => {
                    return (
                      <>
                        <Row>
                          <Col md={24}>
                            <Row
                              key={index}
                              onMouseEnter={() => {
                                setMouseKey(index);
                                setTaskRowBgColor(
                                  "var(--secondary-card-color)"
                                );
                              }}
                              onMouseLeave={() => {
                                setMouseKey("");
                                setTaskRowBgColor("var(--theme)");
                              }}
                              style={{
                                backgroundColor:
                                  mouseKey === index && taskRowBgColor,
                              }}
                              align="middle"
                            >
                              <Col md={24}>
                                <Row>
                                  <Col md={10}>
                                    {/* <p
                                  style={{
                                    marginLeft: "30px",
                                    marginBottom: "0px",
                                    padding: "10px 0px",
                                  }}
                                  className={styles.subHeadingTextNormal}
                                >
                                  {data.title ? data.title : "N/A"}
                                </p>
                                <p  style={{
                                    marginLeft: "30px",
                                    marginBottom: "0px",
                                    padding: "0px 0px",
                                  }}
                                  className={styles.subHeadingTextNormal}>
                                    {data.aging ? data.aging : "N/A"}
                                </p> */}

                                    <Row>
                                      <Col>
                                        <p
                                          style={{
                                            marginLeft: "30px",
                                            marginBottom: "0px",
                                            padding: "10px 0px",
                                          }}
                                          className={
                                            styles.subHeadingTextNormal
                                          }
                                        >
                                          {data.title ? data.title : "N/A"}
                                        </p>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <p
                                          style={{
                                            marginLeft: "30px",
                                            marginBottom: "0px",
                                            marginTop: "-10px",
                                          }}
                                          className={
                                            styles.subHeadingTextNormal
                                          }
                                        >
                                          {data.aging ? data.aging : "N/A"}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md={8}>
                                    <p
                                      style={{
                                        marginBottom: "0px",
                                        padding: "10px 0px",
                                      }}
                                      className={styles.subHeadingTextNormal}
                                    >
                                      {data.startDate
                                        ? moment(data.startDate).format(
                                            dataFormate
                                          )
                                        : "N/A"}
                                    </p>
                                  </Col>
                                  <Col
                                    md={6}
                                    className={commonCssStyle.coursorPointer}
                                  >
                                    <Link
                                      to={"/taskCalendar"}
                                      state={{ id: data.id, type: "task" }}
                                    >
                                      <p
                                        style={{
                                          color: "var(--primaryColor)",
                                          marginBottom: "0px",
                                          marginRight: "40px",
                                          padding: "10px 0px",
                                          textAlign: "right",
                                          fontFamily: "Helvetica,ProductSans",
                                        }}
                                      >
                                        View
                                      </p>
                                    </Link>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    );
                  })
                ) : (
                  <p
                    style={{
                      color: "var(--body-text-color)",
                      textAlign: "center",
                      fontSize: "23px",
                    }}
                    className={commonCssStyle.marginBottomRemove}
                  >
                    No Data
                  </p>
                )}
              </Col>
            )}
          {props.permissions &&
            props.permissions.find(
              (data) =>
                data === "interview-add" ||
                data === "interview-edit" ||
                data === "interview-detail" ||
                data === "interview-delete"
            ) && (
              <Col
                style={{ marginLeft: "2%", width: "48%" }}
                className={styles.contentContainer}
              >
                <Row>
                  <Col md={20}>
                    <h3
                      id="Dashboard-interviewAging"
                      className={`${commonCssStyle.subHeadingText}`}
                      style={{
                        marginBottom: "32px",
                        color: "var(--headings-color)",
                      }}
                    >
                      Interview Aging
                    </h3>
                  </Col>
                  {props.permissions &&
                  props.permissions.find(
                    (data) => data === "interview-detail"
                  ) &&
                  props.Dashboard &&
                  props.Dashboard.interviewAging &&
                  props.Dashboard.interviewAging.length > 0 ? (
                    <Col md={4}>
                      {/* <Link to={"/taskCalendar"} state={{ disp: "interviewTable" }}> */}
                      <h3
                        onClick={() => {
                          props.getCheckPermissionHandler(
                            "interview-detail",
                            (success) => {
                              if (success) {
                                navigate("/taskCalendar", {
                                  state: { disp: "interviewTable" },
                                });
                              } else {
                                setOpenPMModal({
                                  open: true,
                                  permission: "Interview Details",
                                });
                              }
                            }
                          );
                        }}
                        className={`${commonCssStyle.textNormal} ${commonCssStyle.coursorPointer}`}
                      >
                        See More
                      </h3>
                      {/* </Link> */}
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>

                {props.Dashboard &&
                props.Dashboard.interviewAging &&
                props.Dashboard.interviewAging.length > 0 ? (
                  props.Dashboard.interviewAging
                    .slice(0, 5)
                    .map((data, index) => {
                      return (
                        <>
                          <Row>
                            <Col md={21}>
                              <p className={commonCssStyle.lightText}>
                                <Row>
                                  <Col>
                                    <Avatar
                                      size={40}
                                      className={commonCssStyle.avtarBgClr}
                                      style={{
                                        marginRight: "16px",
                                        paddingTop: "4px",
                                      }}
                                    >
                                      <CalendarBlankIcon
                                        color={"var(--primaryColor)"}
                                      />
                                    </Avatar>
                                  </Col>
                                  <Col style={{ marginTop: "1px" }}>
                                    <Row>
                                      <Col>
                                        <span
                                          className={commonCssStyle.darkText}
                                        >
                                          {data.title ? data.title : "N/A"}
                                        </span>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <span
                                          className={commonCssStyle.darkText}
                                        >
                                          {data.aging ? data.aging : "N/A"}
                                        </span>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </p>
                            </Col>

                            <Col md={3}>
                              <Link
                                to={"/taskCalendar"}
                                state={{
                                  id: data.id,
                                  type: "interview",
                                  data: data,
                                }}
                              >
                                <CaretBlackRightIcon
                                  color={"var(--icons-color)"}
                                />
                              </Link>
                            </Col>
                          </Row>
                        </>
                      );
                    })
                ) : (
                  <p
                    style={{
                      color: "var(--body-text-color)",
                      textAlign: "center",
                      fontSize: "23px",
                    }}
                    className={commonCssStyle.marginBottomRemove}
                  >
                    No Data
                  </p>
                )}
              </Col>
            )}
        </Row>
      </div>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    Dashboard: state.dashboard.Dashboard,
    permissions: state.authpermissions.permissions,
    dashboardCard: state.loadingIcon.dashboardCard,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardDataHandler: () => {
      dispatch(getDashboardAction());
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContentPage);
