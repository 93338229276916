import {
  getRoleTypesSlice,
  getRoleTypeSlice,
  postRoleTypesSlice,
  putRoleTypesSlice,
  deleteRoleTypesSlice,
  getAllRoleTypesSlice,
  getRoleSlice,
} from "../../Slice/Master/MasterRolesTypeSlice";
import { ROLES_TYPES_URL, ALL_ROLES_URL, ROLES_URL } from "../../masterpath";
import { message } from "antd";
import AxiosApi from "../../api";
const api = new AxiosApi();
export const getRolesAction = () => {
  return (dispatch) => {
    api.invoke(ROLES_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getRoleSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getAllRoleTypesAction = () => {
  return (dispatch) => {
    api.invoke(ALL_ROLES_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getAllRoleTypesSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getRoleTypesAction = () => {
  return (dispatch) => {
    api.invoke(ROLES_TYPES_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getRoleTypesSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getRoleTypeAction = (id) => {
  return (dispatch) => {
    api.invoke(
      ROLES_TYPES_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getRoleTypeSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postRoleTypesAction = (data) => {
  return (dispatch) => {
    api.invoke(
      ROLES_TYPES_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(postRoleTypesSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const putRoleTypesAction = (data) => {
  return (dispatch) => {
    api.invoke(
      ROLES_TYPES_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(putRoleTypesSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteRoleTypesAction = (id) => {
  return (dispatch) => {
    api.invoke(
      ROLES_TYPES_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(deleteRoleTypesSlice(id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
