import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  candidateReasons: [],
  candidateReason: null,
};

export const masterCandidateReasonSlice = createSlice({
  name: "candidateReasonSlice",
  initialState,
  reducers: {
    getCandidateReasonsSlice(state, action) {
      state.candidateReasons = action.payload.result
    },
    postCandidateReasonsSlice(state, action) {
      state.candidateReasons = [
        ...state.candidateReasons,
        action.payload.result,
      ];
    },
    getCandidateReasonSlice(state, action) {
      state.candidateReason = action.payload.result;
    },
    putCandidateReasonsSlice(state, action) {
      state.candidateReasons = state.candidateReasons.map((data) => {
        if (data.id === action.payload.result.id) {
          return action.payload.result;
        } else {
          return data;
        }
      });
    },
    deleteCandidateReasonsSlice(state, action) {
      state.candidateReasons = state.candidateReasons.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const {
  getCandidateReasonsSlice,
  getCandidateReasonSlice,
  postCandidateReasonsSlice,
  putCandidateReasonsSlice,
  deleteCandidateReasonsSlice,
} = masterCandidateReasonSlice.actions;
export default masterCandidateReasonSlice.reducer;
