import {
  Col,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
  Image,
  DatePicker,
  Button,
  Checkbox,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import commonStyles from "../../../UI/CommonCss.module.css";
import pdf from "../../../../Assets/Image/pdf.png";
import word from "../../../../Assets/Image/word.png";
import styles from "../../../Screens/ClientCompany/ClientCompany.module.css";
import TextArea from "antd/lib/input/TextArea";
import { getSettingsWorklocationAction } from "../../../../Reusable/Store/Action/SettingsAction";

import {
  CurrencyInr,
  UploadIcon,
  CloseIcon,
  DownLoadIcon,
  MinusIcons,
  PlusForButtonIcon,
} from "../../../IconsComp/Icons";
import { connect } from "react-redux";

function EditAgreedCommercialsDrawer(props) {
  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [form] = Form.useForm();
  const [filesData, setFilesData] = useState(null);
  const [cityStatus, setCityStatus] = useState(false);
  const [fileName, setFileName] = useState(props.client.agreement);
  const [uploadFile, setUploadFile] = useState();
  let btnref = useRef(null);
  const [fileType, setFileType] = useState("");
  const [dateCheck, setDateCheck] = useState(false);
  // const [dragActive, setDragActive] = useState(false);

  // const handleDrag = function (e) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (e.type === "dragenter" || e.type === "dragover") {
  //     setDragActive(true);
  //   } else if (e.type === "dragleave") {
  //     setDragActive(false);
  //   }
  // };
  // const handleDrop = function (e) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setDragActive(false);
  //   if (e.dataTransfer.files && e.dataTransfer.files[0]) {
  //     setFilesData(e.dataTransfer.files[0]);
  //     setFileType(e.dataTransfer.files[0].type);
  //     setUploadFile(e.dataTransfer.files[0].name);
  //   }
  // };
  const onChangeCountry = (value) => {
    setState(
      props.countriesData &&
        props.countriesData.find(
          (x) => x.name.toLowerCase() === value.toLowerCase()
        ).state
    );
  };
  const onChangeState = (value) => {
    let cityData =
      state &&
      state.find((x) => x.stateName.toLowerCase() === value.toLowerCase()).city;
    setCity(cityData);
    if (cityData.length === 0 || cityData[0] === "") {
      setCityStatus(true);
    } else {
      setCityStatus(false);
    }
  };
  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      props.getSettingsWorkLocationHandler();
    }
    return () => {
      unsubscribed = true;
    };
  });
  useEffect(() => {
    setTimeout(() => {
      if (
        props.client &&
        props.client.validityEndDate &&
        props.client.validityStartDate
      ) {
        setDateCheck(true);
      } else {
        setDateCheck(false);
      }
      if (props.client && props.client.billingCountry) {
        onChangeCountry(props.client.billingCountry);
      }
      if (
        props.client &&
        props.client.billingCountry &&
        props.client.billingState
      ) {
        setCity(
          props.countriesData &&
            props.countriesData
              .find(
                (x) =>
                  x.name.toLowerCase() ===
                  props.client.billingCountry.toLowerCase()
              )
              .state.find(
                (x) =>
                  x.stateName.toLowerCase() ===
                  props.client.billingState.toLowerCase()
              ).city
        );
      }
    }, 100);
  }, []);
  const handleChange = (info) => {
    if (!(info.target.files[0].size <= 5000000)) {
      message.warning("This file should be less than 5 MB", 3);
      return;
    }
    setFilesData(info.target.files[0]);
    setFileType(info.target.files[0].type);
    setUploadFile(info.target.files[0].name);
  };
  const onFinish = (val) => {
    var data = {
      ...val,
      id: props.client.id,
      agreement: filesData,
      validityStartDate: moment(val.validityStartDate).format("YYYY-MM-DD"),
      validityEndDate: moment(val.validityEndDate).format("YYYY-MM-DD"),
    };
    if (!data.agreement) delete data.agreement;
    props.onSubmit(data);
  };
  return (
    <>
      <Drawer
        className={"modalModified"}
        closable={false}
        extra={
          <div
            onClick={() => {
              // form.resetFields();
              props.onCloseAgreedCommercials();
            }}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
          </div>
        }
        footer={false}
        style={{
          paddingBottom: "0px",
        }}
        title={
          <p
            style={{ fontSize: "20px", margin: "0px" }}
            className={`${commonStyles.modalHeading}`}
          >
            {!props.client ? "Add Company" : "Edit Agreed Commercials"}
          </p>
        }
        width={726}
        height={48}
        open={props.onOpenAgreedCommercials}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          // onDragEnter={handleDrag}
          form={form}
          initialValues={
            props.client && {
              gstin: props.client.gstin,
              pan: props.client.pan,
              billingAddress: props.client.billingAddress,
              billingCity: props.client.billingCity,
              billingState: props.client.billingState,
              billingCountry: props.client.billingCountry,
              billingPincode: props.client.billingPincode,
              agreement: props.client.agreement,
              validityStartDate:
                props.client.validityStartDate &&
                moment(props.client.validityStartDate),
              validityEndDate:
                props.client.validityEndDate &&
                moment(props.client.validityEndDate),
              feesType: props.client.feesType,
              fees: props.client.fees,
              // fees: props.client.fees&&props.client.fees.map((x)=>{
              //   return{
              //     fees:x.agreedcommercial
              //   }
              // }),
              paymentTerms: props.client.paymentTerms,
              replacementClause: props.client.replacementClause,
            }
          }
          layout="vertical"
          onFinish={onFinish}
        >
          <Row
            className={styles.modalRowStyling}
            style={{ padding: "0px 20px 80px 20px" }}
          >
            <Col flex={1}>
              <Row>
                <Col flex={1}>
                  <input
                    hidden
                    accept="application/pdf,.docx"
                    type="file"
                    name="resume"
                    onChange={handleChange}
                    ref={(input) => {
                      btnref = input;
                    }}
                    onClick={(event) => {
                      event.currentTarget.value = null;
                    }}
                  />
                  <Form.Item name="agreement" label="Service Agreement">
                    {(props.client && props.client.agreement) || filesData ? (
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          btnref.click();
                          return false;
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          backgroundColor: "var(--input-color)",
                          padding: "10px 10px",
                          borderRadius: "6px",
                        }}
                      >
                        {!fileType ? (
                          <div
                            // onDragEnter={handleDrag}
                            // onDragLeave={handleDrag}
                            // onDragOver={handleDrag}
                            // onDrop={handleDrop}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "100px",
                            }}
                          >
                            <Image
                              preview={false}
                              src={
                                props.client &&
                                props.client.agreement &&
                                props.client.agreement.split(".")[
                                  props.client.agreement.split(".").length - 1
                                ] == "pdf"
                                  ? pdf
                                  : word
                              }
                              style={{
                                width: "30px",
                                borderRadius: "6px",
                                position: "relative",
                                display: "block",
                              }}
                            />
                            <p
                              className={commonStyles.marginBottomRemove}
                              style={{ color: "var(--body-text-color)" }}
                            >
                              {props.client &&
                                props.client.agreement &&
                                props.client.agreement.substring(
                                  props.client.agreement.lastIndexOf("/") + 1
                                )}
                            </p>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "100px",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              preview={false}
                              src={fileType === "application/pdf" ? pdf : word}
                              style={{
                                width: "30px",
                                borderRadius: "6px",
                                position: "relative",
                                display: "block",
                              }}
                            />
                            <p
                              className={commonStyles.marginBottomRemove}
                              style={{ color: "var(--body-text-color)" }}
                            >
                              {uploadFile}
                            </p>
                            <div
                              style={{ marginLeft: "45%" }}
                              onClick={() => {
                                setFilesData(null);
                              }}
                              className={`${commonStyles.coursorPointer}`}
                            >
                              <Row justify="space-between">
                                <Col>
                                  <CloseIcon color={"var(--headings-color)"} />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        )}
                        <div
                          onClick={() => {
                            setFilesData(null);
                          }}
                          style={{
                            position: "relative",
                            top: "2px",
                            cursor: "pointer",
                          }}
                        >
                          {props.client.agreement && !filesData && (
                            <Row justify="end">
                              <Col
                                onClick={() => {
                                  setFilesData(null);
                                }}
                              >
                                <CloseIcon color={"var(--headings-color)"} />
                              </Col>
                              <Col
                                style={{ marginLeft: "10px" }}
                                onClick={() =>
                                  window.open(
                                    props.client && props.client.agreement
                                  )
                                }
                              >
                                <DownLoadIcon color={"var(--primaryColor)"} />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        <>
                          <div
                            onDragOver={(e) => {
                              e.preventDefault();
                              btnref.click();
                              return false;
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              btnref.click();
                              return false;
                            }}
                            style={{
                              textAlign: "center",
                              height: "155px",
                              backgroundColor: "var(--input-color)",
                              borderRadius: "5px",
                            }}
                          >
                            <Row
                              justify="center"
                              style={{ paddingTop: "31px" }}
                            >
                              <Col>
                                <UploadIcon color={"var(--body-text-color)"} />
                              </Col>
                            </Row>
                            <div>
                              <p
                                style={{
                                  fontWeight: 400,
                                  textAlign: "center",
                                  marginTop: "10px",
                                  fontFamily: "Helvetica,ProductSans",
                                  fontStyle: "normal",
                                  fontSize: "16px",
                                  lineHeight: "19px",
                                  color: "var(--body-text-color)",
                                }}
                              >
                                Upload Document&nbsp;( &nbsp;maximum file size 5
                                mb)
                              </p>
                            </div>
                          </div>
                        </>
                      </>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row
                style={{ gap: "10px", marginBottom: "20px" }}
                className={commonStyles.breadcrumbBottomMargin}
              >
                <Col>
                  <Checkbox
                    checked={dateCheck}
                    onChange={(val) => {
                      setDateCheck(val.target.checked);
                    }}
                  />
                </Col>
                <Col>
                  <p className={`${commonStyles.marginBottomRemove}`}>
                    Agreement
                  </p>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Row justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        label="Start Date"
                        rules={[
                          {
                            required: dateCheck ? true : false,
                            message: "Please select start date",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                !getFieldValue("validityEndDate") ||
                                getFieldValue("validityEndDate") > value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                "From date must be less than To Date"
                              );
                            },
                          }),
                        ]}
                        name="validityStartDate"
                      >
                        <DatePicker
                          getPopupContainer={(trigger) => trigger.parentElement}
                          className={commonStyles.datePickerBgColor}
                          style={{
                            width: "100%",
                          }}
                          format={dataFormate}
                          placeholder="Start Date"
                        ></DatePicker>
                      </Form.Item>
                    </Col>

                    <Col span={11}>
                      <Form.Item
                        label="End Date"
                        rules={[
                          {
                            required: dateCheck ? true : false,
                            message: "Please select end date",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                !getFieldValue("validityStartDate") ||
                                getFieldValue("validityStartDate") < value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                "To date must be greater than From Date"
                              );
                            },
                          }),
                        ]}
                        name="validityEndDate"
                      >
                        <DatePicker
                          getPopupContainer={(trigger) => trigger.parentElement}
                          className={commonStyles.datePickerBgColor}
                          style={{
                            width: "100%",
                          }}
                          format={dataFormate}
                          placeholder="End Date"
                        ></DatePicker>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Agreed Commercials"
                    name="fees"
                    rules={[
                      {
                        required: true,
                        message: "Please enter agreed commercials",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      prefix={<CurrencyInr />}
                      placeholder="Enter amount"
                    />
                  </Form.Item>
                </Col>
              </Row> */}

              <Row className={commonStyles.sectionEndGap}>
                <Col>
                  <div className="ant-col ant-form-item-label">
                    <label /* className="ant-form-item-required" */>
                      Agreed Commercials in %
                    </label>
                  </div>
                </Col>
              </Row>

              <Row style={{ marginBottom: "20px" }}>
                <Col className="sharingMedium" md={24}>
                  <Form.List name="fees">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, key) => (
                          <div>
                            <Row>
                              <Col flex={1}>
                                <AddLink field={field} />
                              </Col>

                              <Col md={24} style={{ marginBottom: "10px" }}>
                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "8px",
                                    float: "right",
                                    color: "#F83A3A",
                                  }}
                                  className={`${commonStyles.marginBottomRemove} ${commonStyles.removeLocation}`}
                                >
                                  Remove
                                </p>
                                <Button
                                  className={commonStyles.removeLocationbtn}
                                  type="primary"
                                  size="small"
                                  shape="circle"
                                  style={{
                                    marginTop: "3px",
                                    marginRight: "7px",
                                    float: "right",
                                    backgroundColor: "#F83A3A",
                                    transform: "translateY(-4px)",
                                  }}
                                >
                                  <div
                                    style={{
                                      transform: "translateY(-4px)",
                                    }}
                                    onClick={() => remove(key)}
                                  >
                                    <MinusIcons color={"var(--theme)"} />
                                  </div>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        ))}
                        <Button
                          size="small"
                          onClick={() => add()}
                          type="primary"
                          shape="circle"
                          style={
                            {
                              // transform: "transformY(-20px)",
                            }
                          }
                        >
                          <div style={{ transform: "translateY(2px)" }}>
                            <PlusForButtonIcon
                              height={"19px"}
                              width={"19px"}
                              color={"var(--theme)"}
                            />
                          </div>
                        </Button>{" "}
                        <p
                          style={{
                            marginTop: "-27px",
                            marginLeft: "40px",
                          }}
                          className={`${commonStyles.marginBottomRemove} ${commonStyles.addLocation}`}
                        >
                          Add More Commercials
                        </p>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>

              <Row className={commonStyles.sectionEndGap}>
                <Col flex={1}>
                  <Form.Item
                    label="Payment Terms"
                    name="paymentTerms"
                    rules={[
                      {
                        required: false,
                        message: "Please enter payment terms",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                      //   message:
                      //     "It allows only alphabets, no whitespace at the beginning and end",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter payment terms" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Replacement Clause"
                    name="replacementClause"
                    rules={[
                      {
                        required: false,
                        message: "Please enter replacement clause",
                      },
                      // {
                      //   pattern: /.*\S.*/,
                      //   message: "White space are not allowed",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter replacement clause" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col
            style={{ width: "726px" }}
            xs={24}
            md={24}
            className={commonStyles.footerShadow}
          >
            <Row justify="end" style={{ gap: "19px" }}>
              <Col>
                <Button
                  onClick={() => {
                    // form.resetFields();
                    props.onCloseAgreedCommercials();
                  }}
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                  className={commonStyles.buttonSettingsSecondary}
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button
                  onClick={() => {
                    form.submit();
                  }}
                  htmlType="button"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  {!props.client ? "Add Company" : "Save"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
}

function AddLink({ field, dataFormate }) {
  const [addLink, setAddLink] = useState([]);

  return (
    <>
      <Row style={{ gap: "20px" }}>
        <Col md={24}>
          <Form.Item
            {...field}
            rules={[
              {
                required: false,
                message: "Please enter agreed commercials in %",
              },
            ]}
          >
            <Input placeholder="Enter agreed commercials in %" type="number" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    settindsData: state.settings.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettingsWorkLocationHandler: () => {
      dispatch(getSettingsWorklocationAction());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAgreedCommercialsDrawer);
