import { Button, Col, Drawer, Form, Input, Row, Select, Tag } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useEffect, useState } from "react";
import { CloseIcon, FilterOutlinedIcon } from "../../../IconsComp/Icons";
import commonStyles from "../../../UI/CommonCss.module.css";
import ResetButton from "../../../UI/ResetButton";
function RecuritmentJobFilter(props) {
  const [form] = useForm();
  const [recuritmentReportFilter, setRecuritmentReportFilter] = useState({
    company: "",
  });

  const [restBtnTitle, setRestBtnTitle] = useState(false);
  const [restBtnJobCode, setRestBtnJobCode] = useState(false);
  const [restBtnBillingValue, setRestBtnBillingValue] = useState(false);
  const [restBtnSalary, setRestBtnSalary] = useState(false);
  function onSubmitData(values) {
    const data = {
      company: values.company ? values.company : "",
      title: values.title ? values.title : "",
      jobCode: values.jobCode ? values.jobCode : "",
      billingValue: values.billingValue ? values.billingValue : "",
      salary: values.salary ? values.salary : "",
      fromDate: props.onFromDate,
      toDate: props.onToDate,
    };
    localStorage.setItem("employeeReportsFilter", JSON.stringify(data));
    props.onSubmit(data);
    props.onCloseFilter();
  }
  const tagRenderForSkill = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="var(--primaryColor)"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          padding: "5px 17px 5px 23px",
        }}
      >
        {label}
      </Tag>
    );
  };

  useEffect(() => {
    let employeeReportsFilterHandler = localStorage.getItem(
      "employeeReportsFilter"
    );
    let employeeReportsFilterHandler1 = JSON.parse(
      employeeReportsFilterHandler
    );
    if (employeeReportsFilterHandler) {
      form.setFieldsValue({
        company: employeeReportsFilterHandler1.company,
        billingValue: employeeReportsFilterHandler1.billingValue,
        fromDate: employeeReportsFilterHandler1.fromDate
          ? moment(employeeReportsFilterHandler1.fromDate)
          : "",
        jobCode: employeeReportsFilterHandler1.jobCode,
        salary: employeeReportsFilterHandler1.salary,
        title: employeeReportsFilterHandler1.title,
        toDate: employeeReportsFilterHandler1.toDate
          ? moment(employeeReportsFilterHandler1.toDate)
          : "",
      });
    }
  }, []);

  return (
    <Drawer
      className="modalModified"
      closable={false}
      extra={
        <span
          onClick={() => props.onCloseFilter()}
          className={`${commonStyles.coursorPointer} `}
        >
          <CloseIcon color={"var(--body-text-color)"} />
        </span>
      }
      width={503}
      height={48}
      title={
        <>
          <Row>
            <Col>
              <FilterOutlinedIcon color={"var(--primaryColor)"} />
            </Col>
            <Col>
              <p
                style={{ margin: "0px 0px 0px 5px" }}
                className={commonStyles.filterHeadingName}
              >
                Filter
              </p>
            </Col>
          </Row>
        </>
      }
      // onClose={props.onCloseFilter}
      open={props.onOpenFilter}
    >
      <Form
        onFinish={(val) => {
          onSubmitData(val);
        }}
        layout="vertical"
        form={form}
      >
        <Row style={{ padding: "0px 20px 80px 20px" }}>
          <Col md={24}>
            <Row>
              <Col xs={24} md={24}>
                <Form.Item name="title" label="Title">
                  <Input
                    placeholder="Enter title"
                    onChange={() => {
                      if (!restBtnTitle) {
                        setRestBtnTitle(true);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              {restBtnTitle && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      title: "",
                    });
                    setRestBtnTitle(false);
                  }}
                />
              )}
              <Col xs={24} md={24}>
                <Form.Item name="company" label="Company">
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    placeholder="Select company"
                    onKeyUp={(val) => {
                      setRecuritmentReportFilter({
                        company: val.target.value,
                      });
                    }}
                    showSearch
                    notFoundContent={null}
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{
                      width: "100%",
                    }}
                  >
                    {recuritmentReportFilter.company &&
                      recuritmentReportFilter.company.length > 0 &&
                      recuritmentReportFilter.company !== " " &&
                      props.onClients.map((data, index) => {
                        return (
                          <Select.Option
                            key={index}
                            value={data.clientName} /* value={data.id} */
                          >
                            {data.clientName}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item name="jobCode" label="Job Code">
                  <Input
                    placeholder="Enter job code"
                    onChange={() => {
                      if (!restBtnJobCode) {
                        setRestBtnJobCode(true);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              {restBtnJobCode && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      jobCode: "",
                    });
                    setRestBtnJobCode(false);
                  }}
                />
              )}
              <Col xs={24} md={24}>
                <Form.Item name="billingValue" label="BillingValue">
                  <Input
                    placeholder="Enter billing value"
                    onChange={() => {
                      if (!restBtnBillingValue) {
                        setRestBtnBillingValue(true);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              {restBtnBillingValue && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      billingValue: "",
                    });
                    setRestBtnBillingValue(false);
                  }}
                />
              )}
              <Col xs={24} md={24}>
                <Form.Item name="salary" label="Salary">
                  <Input
                    placeholder="Enter salary"
                    onChange={() => {
                      if (!restBtnSalary) {
                        setRestBtnSalary(true);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              {restBtnSalary && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      salary: "",
                    });
                    setRestBtnSalary(false);
                  }}
                />
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className={commonStyles.footerShadow} style={{ width: "503px" }}>
            <Row justify="end" style={{ gap: "25px" }}>
              <Col>
                <Button
                  className={commonStyles.buttonSettingsSecondary}
                  type="default"
                  onClick={() => {
                    props.onSubmit({});
                    setRestBtnBillingValue(false);
                    setRestBtnTitle(false);
                    setRestBtnJobCode(false);
                    setRestBtnSalary(false);
                    props.onFandTReset();
                    form.resetFields();
                    props.onCloseFilter();
                    localStorage.removeItem("employeeReportsFilter");
                  }}
                >
                  Reset Filter
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}
export default RecuritmentJobFilter;
