import { Button, Form, Modal, Row, Col, Input } from "antd";
import styles from "../../AddCandidatesComp/AddCandidatesComp.module.css";
import { CloseIcon, PaperPlane } from "../../../IconsComp/Icons";

import ReactQuill from "react-quill";
import "../../../../../node_modules/react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";
import "../../../../App.less";
import { useState } from "react";
import commonCssStyles from "../../../UI/CommonCss.module.css";
import { useForm } from "antd/lib/form/Form";

function EmailModal(props) {
  const [value, setValue] = useState("");
  const [form] = useForm();
  const modules = {
    toolbar: [
      ["bold"],
      ["italic"],
      ["underline"],
      [{ list: "bullet" }],
      [{ list: "ordered" }],
      [{ align: [] }],
      ["link"],
      // ["image"],
      // ["video"],
      ["code-block"],
    ],
  };
  return (
    <>
      <Modal
        closable={false}
        className="modalModifiedEmail"
        title={
          <Row style={{ gap: "660px" }}>
            <Col>
              {" "}
              <p
                className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.popUpHeadingText}`}
              >
                {props.modalNames === "edit" ? "View Email" : "Send Email"}
              </p>
            </Col>
            <Col>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  props.handleCancel();
                }}
                className={`${commonCssStyles.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
        footer={false}
        open={props.isModalOpen}
        onOk={props.handleOk}
        width={838}
      >
        <Form
          form={form}
          onFinish={(val) => {
            props.postCandidateEmailHandler(
              { ...val, candidateId: props.id },
              (success) => {
                if (success) props.handleCancel();
              }
            );
          }}
          initialValues={
            props.ViewEmailModalOpen
              ? {
                  name: props.ViewEmailModalOpen.sentByName,
                  subject: props.ViewEmailModalOpen.subject,
                  cc: props.ViewEmailModalOpen.cc,
                  content: props.ViewEmailModalOpen.content,
                }
              : {
                  // name:props.onCandidateName?props.onCandidateName:props.onRecruitmentCandidateName?props.onRecruitmentCandidateName:""
                  name: props.onRecruitmentCandidateName
                    ? props.onRecruitmentCandidateName
                    : props.onCandidateName,
                }
          }
          layout="vertical"
        >
          {/* onRecruitmentCandidateName */}
          <Row style={{ margin: "22px" }}>
            <Col md={24}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
                label="Name"
              >
                <Input
                  readOnly
                  // defaultValue={props.onCandidateName?props.onCandidateName:props.onRecruitmentCandidateName?props.onRecruitmentCandidateName:""}
                />
              </Form.Item>
              <Form.Item
                name="subject"
                rules={[
                  {
                    required: true,
                    message: "Subject is required",
                  },
                ]}
                label={
                  <p
                    style={{ margin: "0px" }}
                    className={styles.drawerFormText}
                  >
                    Subject
                  </p>
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="cc"
                rules={[
                  {
                    required: false,
                    message: "CC is required",
                  },
                ]}
                label="CC"
              >
                <Input placeholder="Please enter comma separated email ids. e.g. matt@example.com , joe@sample.com"/>
              </Form.Item>

              <Form.Item
                name="content"
                rules={[
                  {
                    required: true,
                    message: "Content is required",
                  },
                ]}
              >
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  value={value}
                  onChange={setValue}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row
            justify="end"
            className={`${commonCssStyles.footerShadowForTemplete}`}
          >
            <Col>
              {props.modalNames === "edit" ? (
                <Button
                  onClick={() => {
                    props.handleCancel();
                  }}
                  htmlType="button"
                  className={commonCssStyles.buttonSettingsPrimary}
                  type="primary"
                  style={{ height: "45px", width: "85px" }}
                >
                  <Row align="middle">
                    <Col>
                      <p style={{ margin: "0px" }}> Cancel </p>
                    </Col>
                  </Row>
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    form.submit();
                  }}
                  htmlType="button"
                  className={commonCssStyles.buttonSettingsPrimary}
                  type="primary"
                  style={{ height: "45px", width: "137px" }}
                >
                  {/* {props.modalNames==="edit"? "View Email":"Send Email"} */}
                  <Row align="middle" style={{ gap: "16px" }}>
                    <Col>
                      <div style={{ transform: "translateY(3px)" }}>
                        <PaperPlane color={"var(--theme)"} />
                      </div>
                    </Col>
                    <Col>
                      <p style={{ margin: "0px" }}> Send </p>
                    </Col>
                  </Row>
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default EmailModal;
