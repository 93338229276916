import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Checkbox,
  DatePicker,
  Image,
  message,
  InputNumber,
  Radio,
} from "antd";
import React, { useEffect } from "react";
import styles from "./Employee.module.css";
import { CloseIcon, EditIcon } from "../../IconsComp/Icons";
import "antd-country-phone-input/dist/index.css";
import "flagpack/dist/flagpack.css";
import commonStyles from "../../UI/CommonCss.module.css";
import pdf from "../../../Assets/Image/pdf.png";
import word from "../../../Assets/Image/word.png";
import PhoneInput from "react-phone-number-input";
import { getCountriesAction } from "../../../Reusable/Store/Action/Master/MasterCountryAction";
import { UploadIcon } from "../../IconsComp/Icons";
import { useState } from "react";
import { useRef } from "react";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
import commonCssStyle from "../../UI/CommonCss.module.css";
import { connect } from "react-redux";
import AadTagsForSkills from "../../UI/AadTagsForSkills";
const AddEmployee = (props) => {
  let btnref = useRef(null);
  let btnref1 = useRef(null);
  const [form] = useForm();

  const [fileDataForImage, setFileDataForImage] = useState(null);
  const [whatsAppChecked, setWhatsappChecked] = useState(false);
  const [filesData, setFilesData] = useState("");
  const [fileType, setFileType] = useState("");
  const [checkedCurrentAddress, setCheckedCurrentAddress] = useState(false);
  const [state, setState] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [statePermanents, setStatePermanents] = useState([]);
  const [statePermanent, setStatePermanent] = useState([]);
  const [city, setCity] = useState([]);
  const [cityPermanent, setCityPermanent] = useState([]);

  const [fileRequired, setFileRequired] = useState(true);
  const [dragDocActive, setDragDocActive] = useState(false);
  const [requiredFields, setRequiredFields] = useState(false);

  // const onChangeCountry = (value, type) => {
  //   setState(
  //     props.countriesData &&
  //       props.countriesData.find((x) => x.name === value).state
  //   );
  //   setCity([]);
  //   if (type !== 1) {
  //     setTimeout(() => {
  //       form.setFieldsValue({
  //         city: initvalues.city,
  //         state: initvalues.state,
  //       });
  //     }, 300);
  //   }
  // };
  // const onChangeCountryPermanent = (value, type) => {
  //   setStatePermanent(
  //     props.countriesData &&
  //       props.countriesData.find((x) => x.name === value).state
  //   );
  //   setCityPermanent([]);
  //   if (type !== 1) {
  //     setTimeout(() => {
  //       form.setFieldsValue({
  //         permanentcity: initvalues.permanentcity,
  //         permanentstate: initvalues.permanentstate,
  //       });
  //     }, 300);
  //   }
  // };

  const onChangeCountry = (val, data) => {
    props.getCountriesHandler({ id: data.id, type: "state" }, (data, type) => {
      setStates(data);
    });
    form.setFieldsValue({
      city: initvalues.city,
      state: initvalues.state,
    });
    setCity([]);
  };

  const onChangeCountryPermanent = (val, data) => {
    props.getCountriesHandler({ id: data.id, type: "state" }, (data, type) => {
      setStatePermanents(data);
    });
    form.setFieldsValue({
      permanentcity: initvalues.permanentcity,
      permanentstate: initvalues.permanentstate,
    });
    setCityPermanent([]);
  };

  // const onChangeState = (value) => {
  //   form.setFieldsValue({
  //     city: initvalues.city,
  //   });
  //   let cityData = state && state.find((x) => x.stateName === value).city;
  //   setCity(
  //     cityData && cityData.filter((data) => data !== "")
  //       ? cityData.filter((data) => data !== "")
  //       : []
  //   );
  // };
  // const onChangeStatePermanent = (value) => {
  //   form.setFieldsValue({
  //     permanentcity: initvalues.permanentcity,
  //   });
  //   let cityPermanentData =
  //     statePermanent && statePermanent.find((x) => x.stateName === value).city;
  //   setCityPermanent(
  //     cityPermanentData && cityPermanentData.filter((data) => data !== "")
  //       ? cityPermanentData.filter((data) => data !== "")
  //       : []
  //   );
  // };

  const onChangeState = (value, data) => {
    props.getCountriesHandler({ id: data.id, type: "city" }, (data, type) => {
      setCity(data);
    });
    form.setFieldsValue({
      city: initvalues.city,
    });
  };

  const onChangeStatePermanent = (value, data) => {
    props.getCountriesHandler({ id: data.id, type: "city" }, (data, type) => {
      setCityPermanent(data);
    });
    form.setFieldsValue({
      permanentcity: initvalues.permanentcity,
    });
  };

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const handleChange = (info) => {
    if (!(info.target.files[0].size <= 2000000)) {
      message.warning("This file should be less than 2 MB", 3);
      return;
    }
    setFileDataForImage(info.target.files[0]);
  };
  useEffect(() => {
    props.getCountriesHandler({ type: "country" }, (data, type) => {
      setCountries(data);
    });
    form.setFieldsValue({
      country: "India",
      permanentcountry: "India",
    });
    props.getCountriesHandler({ id: 101, type: "state" }, (data, type) => {
      setStates(data);
      setStatePermanents(data);
    });
    setTimeout(() => {
      let currentAddress = props.employees.addressInfo.find(
        (data) => data.type === "current"
      );
      let permanentAddress = props.employees.addressInfo.find(
        (data) => data.type === "permanent"
      );
      if (
        currentAddress.location === permanentAddress.location &&
        currentAddress.state === permanentAddress.state &&
        currentAddress.city === permanentAddress.city &&
        currentAddress.address === permanentAddress.address &&
        currentAddress.pincode === permanentAddress.pincode
      ) {
        setCheckedCurrentAddress(true);
      }

      if (props.employees.addressCheck === 1) {
        setCheckedCurrentAddress(true);
      }

      if (props.employees.phone === props.employees.whatsApp) {
        setWhatsappChecked(true);
      }
      let country =
        props.employees.addressInfo.find((data) => data.type === "current") &&
        props.employees.addressInfo.find((data) => data.type === "current")
          .country;

      let state =
        props.employees.addressInfo.find((data) => data.type === "current") &&
        props.employees.addressInfo.find((data) => data.type === "current")
          .state;
      // if (country) {
      //   onChangeCountry(country, 1);
      //   if (state) {
      //     setCity(
      //       props.countriesData &&
      //         props.countriesData
      //           .find((x) => x.name.toLowerCase() === country.toLowerCase())
      //           .state.find(
      //             (x) => x.stateName.toLowerCase() === state.toLowerCase()
      //           ).city
      //     );
      //   }
      // }

      country =
        props.employees.addressInfo.find((data) => data.type === "permanent") &&
        props.employees.addressInfo.find((data) => data.type === "permanent")
          .country;

      state =
        props.employees.addressInfo.find((data) => data.type === "permanent") &&
        props.employees.addressInfo.find((data) => data.type === "permanent")
          .state;

      // if (country) {
      //   onChangeCountryPermanent(country, 1);
      //   if (state) {
      //     let cityPermanentData =
      //       props.countriesData &&
      //       props.countriesData
      //         .find((x) => x.name.toLowerCase() === country.toLowerCase())
      //         .state.find(
      //           (x) => x.stateName.toLowerCase() === state.toLowerCase()
      //         ).city;
      //     setCityPermanent(
      //       cityPermanentData && cityPermanentData.filter((data) => data !== "")
      //         ? cityPermanentData.filter((data) => data !== "")
      //         : []
      //     );
      //   }
      // }
    }, 500);
  }, []);
  const handleChangeForDocument = (info) => {
    setFileRequired(false);
    if (info.target.files[0].size >= 5000000) {
      message.warning("This file should be less than 5 MB", 3);
      return;
    }
    setFilesData(info.target.files[0].name);
    setFileType(info.target.files[0].type);
  };

  const handleDragForDoc = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragDocActive(true);
    } else if (e.type === "dragleave") {
      setDragDocActive(false);
    }
  };
  const handleDropForDoc = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragDocActive(false);
    setFileRequired(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFilesData(e.dataTransfer.files[0].name);
      setFileType(e.dataTransfer.files[0].type);
    }
  };

  const onSubmit = (val) => {
    const currentSameAsPermanentAdrress = [
      {
        type: "current",
        location: val.location,
        country: val.country,
        address: val.address,
        state: val.state,
        city: val.city,
        pincode: val.pincode,
      },
      {
        type: "permanent",
        location: val.location,
        country: val.country,
        address: val.address,
        state: val.state,
        city: val.city,
        pincode: val.pincode,
      },
    ];
    const permanentAdrressIsDifferent = [
      {
        type: "current",
        location: val.location,
        country: val.country,
        address: val.address,
        state: val.state,
        city: val.city,
        pincode: val.pincode,
      },
      {
        type: "permanent",
        location: val.permanentlocation,
        country: val.permanentcountry,
        address: val.permanentaddress,
        state: val.permanentstate,
        city: val.permanentcity,
        pincode: val.permanentpincode,
      },
    ];
    let data = {
      ...val,
      addressInfo: checkedCurrentAddress
        ? currentSameAsPermanentAdrress
        : permanentAdrressIsDifferent,
      addressCheck: checkedCurrentAddress ? 1 : 0,
      appAccess: appAccessStatus === true ? 1 : 0,
      webAccess: webAccessStatus === true ? 1 : 0,
      joiningDate: moment(val.joiningDate).format("YYYY-MM-DD"),
      dob: moment(val.dob).format("YYYY-MM-DD"),
      profileImage: fileDataForImage,
      document: filesData,
      whatsAppCheck: whatsAppChecked ? 1 : 0,
      whatsApp: whatsAppChecked ? val.phone : val.whatsApp,
      id: props.employee && props.employee.userId,
    };
    props.onSubmit(data);
  };

  const [webAccessStatus, setWebAccessStatus] = useState(false);
  const [appAccessStatus, setappAccessStatus] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  // const [dragForDocActive, setDragForDocActive] = useState(false);
  const initvalues = {
    whatsApp: "",
    city: "",
    state: "",
    permanentstate: "",
    permanentcity: "",
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFileDataForImage(e.dataTransfer.files[0]);
    }
  };

  return (
    <Drawer
      className={"modalModified"}
      extra={
        <div
          onClick={() => props.onClose()}
          className={`${commonStyles.coursorPointer} `}
        >
          {" "}
          <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
        </div>
      }
      footer={false}
      style={{
        paddingBottom: "0px",
      }}
      // className={styles.addcandidatetop}
      title={
        <p
          style={{ fontSize: "20px", margin: "0px" }}
          className={commonStyles.modalHeading}
        >
          {!props.employee ? "Add Employee" : "Edit Employee"}
        </p>
      }
      height={48}
      width={806}
      closable={false}
      // onClose={props.onClose}
      open={props.onOpen}
    >
      <Form
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
        form={form}
        onDragEnter={handleDrag}
        initialValues={
          props.employee
            ? {
                initvalues,
                empId: props.employee.empId,
                firstName: props.employee.firstName,
                lastName: props.employee.lastName,
                email: props.employee.email,
                password: props.employee.password,
                phone: props.employee.phone,
                whatsApp: props.employee.whatsApp,
                gender: props.employee.gender,
                dob: props.employee.dob && moment(props.employee.dob),
                appAccess: props.employee.appAccess == "1",
                webAccess: props.employee.webAccess == "1",
                username: props.employee.username,
                joiningDate:
                  props.employee.joiningDate &&
                  moment(props.employee.joiningDate),
                employmentType: props.employee.employmentType,
                // designation: props.employee.designation,
                department: props.employee.department,
                reportingManagerId: props.employee.reportingManagerId,
                workLocationId: props.employee.workLocationId,
                monthlySalary: props.employee.monthlySalary,
                perDayCost: props.employee.perDayCost,
                bankName: props.employee.bankName,
                bankBranchName: props.employee.bankBranchName,
                AccountName: props.employee.AccountName,
                AccountNumber: props.employee.AccountNumber,
                ifsc: props.employee.ifsc,
                permanentcity:
                  props.employee.addressInfo.find(
                    (data) => data.type === "permanent"
                  ) &&
                  props.employee.addressInfo.find(
                    (data) => data.type === "permanent"
                  ).city,

                country:
                  props.employee.addressInfo.find(
                    (data) => data.type === "current"
                  ) &&
                  props.employee.addressInfo.find(
                    (data) => data.type === "current"
                  ).country,
                permanentcountry:
                  props.employee.addressInfo.find(
                    (data) => data.type === "permanent"
                  ) &&
                  props.employee.addressInfo.find(
                    (data) => data.type === "permanent"
                  ).country,
                city:
                  props.employee.addressInfo.find(
                    (data) => data.type === "current"
                  ) &&
                  props.employee.addressInfo.find(
                    (data) => data.type === "current"
                  ).city,

                state:
                  props.employee.addressInfo.find(
                    (data) => data.type === "current"
                  ) &&
                  props.employee.addressInfo.find(
                    (data) => data.type === "current"
                  ).state,
                permanentstate:
                  props.employee.addressInfo.find(
                    (data) => data.type === "permanent"
                  ) &&
                  props.employee.addressInfo.find(
                    (data) => data.type === "permanent"
                  ).state,
                location:
                  props.employee.addressInfo.find(
                    (data) => data.type === "current"
                  ) &&
                  props.employee.addressInfo.find(
                    (data) => data.type === "current"
                  ).location,
                pincode:
                  props.employee.addressInfo.find(
                    (data) => data.type === "current"
                  ) &&
                  props.employee.addressInfo.find(
                    (data) => data.type === "current"
                  ).pincode,
                permanentpincode:
                  props.employee.addressInfo.find(
                    (data) => data.type === "permanent"
                  ) &&
                  props.employee.addressInfo.find(
                    (data) => data.type === "permanent"
                  ).pincode,
                address:
                  props.employee.addressInfo.find(
                    (data) => data.type === "current"
                  ) &&
                  props.employee.addressInfo.find(
                    (data) => data.type === "current"
                  ).address,

                permanentaddress:
                  props.employee.addressInfo.find(
                    (data) => data.type === "permanent"
                  ) &&
                  props.employee.addressInfo.find(
                    (data) => data.type === "permanent"
                  ).address,
                permanentlocation:
                  props.employee.addressInfo.find(
                    (data) => data.type === "permanent"
                  ) &&
                  props.employee.addressInfo.find(
                    (data) => data.type === "permanent"
                  ).location,
              }
            : {
                isExternal: 0,
              }
        }
        layout="vertical"
        onFinish={onSubmit}
      >
        <Row className={commonStyles.modalRowStyling}>
          <Col md={24}>
            <Row>
              <Col xs={24}>
                <Form.Item name="isExternal">
                  <Radio.Group
                    onChange={(val) => {
                      setRequiredFields(val.target.value);
                    }}
                    className="radioBtnText"
                  >
                    <Radio value={0}>Internal</Radio>
                    <Radio value={1}>External</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={
                <p
                  className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                >
                  Display Picture
                </p>
              }
              style={{ borderRadius: "1px" }}
            >
              <input
                hidden
                accept="image/*"
                type="file"
                name="fileUpload"
                onChange={handleChange}
                ref={(input) => {
                  btnref = input;
                }}
                onClick={(event) => {
                  event.currentTarget.value = null;
                }}
              />
              {fileDataForImage ||
              dragActive ||
              (props.employee && props.employee.profileImage) ? (
                <>
                  <div
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                    style={{
                      backgroundColor: "var(--secondaryColor)",
                      height: "155px",
                      borderRadius: "6px",
                    }}
                  >
                    <Image
                      onClick={(e) => {
                        e.preventDefault();
                        btnref.click();
                        return false;
                      }}
                      preview={false}
                      src={
                        fileDataForImage
                          ? URL.createObjectURL(fileDataForImage)
                          : props.employee && props.employee.profileImage
                      }
                      style={{
                        height: "124px",
                        width: "726",
                        borderRadius: "6px",
                        position: "relative",
                        display: "block",
                        margin: "16px auto 21px 21px",
                      }}
                    />
                    <span
                      onClick={() => {
                        setFileDataForImage(null);
                      }}
                      style={{
                        position: "absolute",
                        top: "11px",
                        paddingLeft: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <CloseIcon color={"var(--body-text-color)"} />
                    </span>
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        btnref.click();
                        return false;
                      }}
                      style={{
                        position: "absolute",
                        top: "35px",
                        paddingLeft: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <EditIcon color={"var(--body-text-color)"} />
                    </span>
                  </div>
                </>
              ) : (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    btnref.click();
                    return false;
                  }}
                  style={{
                    textAlign: "center",

                    padding: "20px",
                    height: "155px",
                    backgroundColor: "var(--input-color)",
                    borderRadius: "5px",
                    paddingBottom: "29px",
                  }}
                >
                  <UploadIcon color={"var(--body-text-color)"} />
                  <div>
                    <p
                      style={{
                        fontWeight: 400,
                        textAlign: "center",
                        color: "var(--upload-text-color)",
                        marginTop: "29px",
                      }}
                    >
                      Upload display picture &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; or
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Drag and drop picture
                    </p>
                  </div>
                </div>
              )}
            </Form.Item>

            <Form.Item
              style={{ marginTop: "20px" }}
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please enter first name",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                //   message:
                //     "It allows only alphabets, no whitespace at the beginning and end",
                // },
              ]}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>

            <Form.Item
              style={{ marginTop: "20px" }}
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please enter last name",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                //   message:
                //     "It allows only alphabets, no whitespace at the beginning and end",
                // },
              ]}
            >
              <Input placeholder="Enter last name" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Official Email Address"
              rules={[
                {
                  required: true,
                  message: "Please enter your email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
              onChange={(val) => {
                form.setFieldsValue({
                  username: val.target.value,
                });
              }}
            >
              <Input placeholder="Enter email address" />
            </Form.Item>

            <Form.Item
              className={`phoneInputStroke`}
              name="phone"
              label="Contact Number"
              rules={[
                {
                  required: true,
                  message: "Please enter contact number",
                },
              ]}
            >
              <PhoneInput
                placeholder="Enter contact number"
                className={`ant-input ant-cust-inputs`}
                defaultCountry="IN"
              />
            </Form.Item>
            <Row style={{ gap: "10px" }} align="middle">
              <Col>
                <Form.Item
                  name="whatsAppCheck"
                  style={{ margin: "0px", padding: "0px" }}
                >
                  <Checkbox
                    className="intellcheckbox"
                    onChange={(val) => {
                      setWhatsappChecked(val.target.checked);
                      form.setFieldsValue({ whatsApp: initvalues.whatsApp });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <p
                  className={`${commonStyles.formInputLabel} ${commonCssStyle.marginBottomRemove}`}
                >
                  Whatsapp Number is same as Contact Number
                </p>
              </Col>
            </Row>

            {!whatsAppChecked && (
              <Row style={{ marginTop: "10px" }}>
                <Col className="modelcol" md={24}>
                  <Form.Item
                    className={`phoneInputStroke`}
                    rules={[
                      {
                        required: true,
                        message: "Please enter whatsapp number",
                      },
                    ]}
                    name="whatsApp"
                    label="Whatsapp Number"
                  >
                    <PhoneInput
                      placeholder="Enter whatsapp number"
                      className="ant-input ant-cust-inputs"
                      defaultCountry="IN"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {requiredFields && requiredFields ? (
              <Form.Item
                label="Company Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter company name",
                  },
                ]}
                name={"companyName"}
              >
                <Input placeholder="Enter company name" />
              </Form.Item>
            ) : (
              <></>
            )}

            <Form.Item
              className={commonCssStyle.sectionEndGap}
              label="Gender"
              name="gender"
              rules={[
                {
                  required: requiredFields ? false : true,
                  message: "Please select gender",
                },
              ]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder="Select gender"
              >
                <Select.Option value="Male">Male</Select.Option>
                <Select.Option value="Female">Female</Select.Option>
                <Select.Option value="Other">Other</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="DOB"
              name="dob"
              rules={[
                {
                  required: false,
                  message: "Please enter date of birth",
                },
              ]}
            >
              <DatePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(16, "year"))
                }
                defaultPickerValue={moment().subtract(16, "year")}
                placeholder="Enter date of birth"
                format={dataFormate}
                className={commonStyles.datePickerBgColor}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
            <Row className={commonStyles.sectionEndGap}>
              <Col>
                <h1 className={commonStyles.HeadingFormDesign}>
                  Current Address
                </h1>
              </Col>
            </Row>
            <Row>
              <Col flex={1}>
                <Form.Item
                  name="location"
                  label="Apartment, flat/house number"
                  rules={[
                    {
                      required: requiredFields ? false : true,
                      message: "Please enter apartment, flat/house number",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "100%" }}
                    placeholder="Enter apartment, flat/house number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col flex={1}>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[
                    {
                      required: requiredFields ? false : true,
                      message: "Please enter address",
                    },
                  ]}
                >
                  <Input placeholder="Enter address" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col flex={1}>
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[
                    {
                      required: requiredFields ? false : true,
                      message: "Please select country",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    showSearch
                    onChange={onChangeCountry}
                    placeholder="Select country"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {countries &&
                      countries.length > 0 &&
                      countries.map((data, index) => {
                        return (
                          <Select.Option
                            key={index}
                            value={data.name}
                            id={data.id}
                          >
                            {data.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col flex={1}>
                <Form.Item
                  label="State"
                  name="state"
                  rules={[
                    {
                      required: requiredFields ? false : true,
                      message: "Please select state",
                    },
                  ]}
                >
                  {states && states.length === 0 ? (
                    <Input placeholder="Enter state" />
                  ) : (
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      onChange={onChangeState}
                      showSearch
                      placeholder="Select state"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {states &&
                        states.map((data, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={data.name}
                              id={data.id}
                            >
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col flex={1}>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[
                    {
                      required: requiredFields ? false : true,
                      message: "Please select city",
                    },
                  ]}
                >
                  {city.length === 0 ? (
                    <Input placeholder="Enter city" />
                  ) : (
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      showSearch
                      placeholder="Select city"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {city.map((data, index) => {
                        return (
                          <Select.Option value={data.name} key={index}>
                            {data.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col flex={1}>
                <Form.Item
                  label="Pincode"
                  name="pincode"
                  rules={[
                    {
                      required: requiredFields ? false : true,
                      message: "Please enter pincode",
                    },
                    // {
                    //   pattern: /^[0-9]+$/,
                    //   message: "Enter only number",
                    // },
                  ]}
                >
                  <Input placeholder="Enter pincode" />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ gap: "10px" }} align="middle">
              <Col>
                <Form.Item
                  name="addressCheck"
                  style={{ margin: "0px", padding: "0px" }}
                >
                  <Switch
                    checked={checkedCurrentAddress}
                    onChange={(val) => {
                      setCheckedCurrentAddress(val);
                      form.setFieldsValue({
                        permanentlocation: initvalues.permanentlocation,
                        permanentaddress: initvalues.permanentaddress,
                        permanentcountry: initvalues.permanentcountry,
                        permanentstate: initvalues.permanentstate,
                        permanentcity: initvalues.permanentcity,
                        permanentpincode: initvalues.permanentpincode,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <p
                  className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                >
                  Current address is same as permanant address
                </p>
              </Col>
            </Row>

            {checkedCurrentAddress ? (
              <></>
            ) : (
              <>
                <Row className={commonCssStyle.sectionEndGap}>
                  <Col>
                    <h1 className={commonStyles.HeadingFormDesign}>
                      Permanent Address
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col flex={1}>
                    <Form.Item
                      name="permanentlocation"
                      label="Apartment, flat/house number"
                      rules={[
                        {
                          required: requiredFields ? false : true,
                          message: "Please enter apartment, flat/house number",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Enter apartment, flat/house number"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    <Form.Item
                      name="permanentaddress"
                      label="Address"
                      rules={[
                        {
                          required: requiredFields ? false : true,
                          message: "Please enter permanent address",
                        },
                      ]}
                    >
                      <Input placeholder="Enter permanent address" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    <Form.Item
                      label="Country"
                      name="permanentcountry"
                      rules={[
                        {
                          required: requiredFields ? false : true,
                          message: "Please select country",
                        },
                      ]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        showSearch
                        onChange={onChangeCountryPermanent}
                        placeholder="Select country"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {countries &&
                          countries.length > 0 &&
                          countries.map((data, index) => {
                            return (
                              <Select.Option
                                key={index}
                                value={data.name}
                                id={data.id}
                              >
                                {data.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    <Form.Item
                      label="State"
                      name="permanentstate"
                      rules={[
                        {
                          required: requiredFields ? false : true,
                          message: "Please select state",
                        },
                      ]}
                    >
                      {statePermanents && statePermanents.length === 0 ? (
                        <Input placeholder="Enter state" />
                      ) : (
                        <Select
                          getPopupContainer={(trigger) => trigger.parentElement}
                          onChange={onChangeStatePermanent}
                          showSearch
                          placeholder="Select state"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {statePermanents &&
                            statePermanents.map((data, index) => {
                              return (
                                <Select.Option
                                  key={index}
                                  value={data.name}
                                  id={data.id}
                                >
                                  {data.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    <Form.Item
                      label="City"
                      name="permanentcity"
                      rules={[
                        {
                          required: requiredFields ? false : true,
                          message: "Please select city",
                        },
                      ]}
                    >
                      {cityPermanent.length === 0 ? (
                        <Input placeholder="Enter city" />
                      ) : (
                        <Select
                          showSearch
                          getPopupContainer={(trigger) => trigger.parentElement}
                          placeholder="Select city"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {cityPermanent &&
                            cityPermanent.map((data, index) => {
                              return (
                                <Select.Option value={data.name} key={index}>
                                  {data.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    <Form.Item
                      label="Pincode"
                      name="permanentpincode"
                      rules={[
                        {
                          required: requiredFields ? false : true,
                          message: "Please enter your pincode",
                        },
                        // {
                        //   pattern: /^[0-9]+$/,
                        //   message: "Enter only number",
                        // },
                      ]}
                    >
                      <Input placeholder="Enter pincode" />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Form.Item
              className={commonCssStyle.sectionEndGap}
              label="Employee ID"
              name="empId"
              rules={[
                {
                  required: requiredFields ? false : true,
                  message: "Please enter employee id",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9\s]+$/,
                //   message: "Invalid employee id",
                // },
              ]}
            >
              <Input placeholder="Enter employee id"></Input>
            </Form.Item>

            <Form.Item label="Joining Date " name="joiningDate">
              <DatePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                locale={"en"}
                style={{ width: "100%" }}
                className={commonStyles.datePickerBgColor}
                format={dataFormate}
              ></DatePicker>
            </Form.Item>

            <Form.Item
              label="Employment Type"
              name="employmentType"
              rules={[
                {
                  required: requiredFields ? false : true,
                  message: "Please select employment type",
                },
              ]}
            >
              <Select
                allowClear
                getPopupContainer={(trigger) => trigger.parentElement}
                style={{
                  width: "100%",
                }}
                className={`${commonStyles.border} `} /* candidateSelectHeight */
                placeholder="Select employment type"
              >
                {props.employmentType &&
                  props.employmentType.map((data, index) => {
                    return (
                      <Select.Option key={index} value={data.jobType}>
                        {data.jobType}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>

            {/* <Form.Item
              label="Designation"
              name="designation"
              rules={[
                {
                  required: true,
                  message: "Please select designation",
                },
              ]}
            >
              <Input placeholder="Enter designation" />
            </Form.Item> */}

            <Form.Item
              label="Department"
              name="department"
              rules={[
                {
                  required: requiredFields ? false : true,
                  message: "Please select department",
                },
              ]}
            >
              <Select
                style={{
                  width: "100%",
                }}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <AadTagsForSkills onType={"department"} />
                  </>
                )}
                getPopupContainer={(trigger) => trigger.parentNode}
                className={`${commonStyles.border} `} /* candidateSelectHeight */
                placeholder="Select department"
              >
                {props.departments &&
                  props.departments.map((data, index) => {
                    return (
                      <Select.Option key={index} value={data.departmentName}>
                        {data.departmentName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Reporting manager "
              name="reportingManagerId"
              rules={[
                {
                  required: false,
                  message: "Please select reporting manager",
                },
              ]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                allowClear
                style={{
                  width: "100%",
                }}
                className={`${commonStyles.border} `} /* candidateSelectHeight */
                placeholder="Select reporting manager"
              >
                {props.employees &&
                  props.employees.map((data, index) => {
                    return (
                      <Select.Option key={index} value={data.id}>
                        {data.firstName + " " + data.lastName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Work Location"
              name="workLocationId"
              rules={[
                {
                  required: requiredFields ? false : true,
                  message: "Please select work location",
                },
              ]}
            >
              <Select placeholder="Select work location">
                {props.workLocation &&
                  props.workLocation.map((data, index) => {
                    return (
                      <Select.Option key={index} value={data.id}>
                        {data.locationName}, {data.address}, {data.city},{" "}
                        {data.state}, {data.country}, {data.pincode}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              onDragEnter={handleDragForDoc}
              name="document"
              label={
                <p
                  className={`${commonStyles.formInputLabel} ${commonStyles.marginBottomRemove}`}
                >
                  Document
                </p>
              }
              style={{ borderRadius: "1px" }}
            >
              {!fileRequired ||
              dragDocActive ||
              (props.employee && props.employee.document) ? (
                <div
                  onDragEnter={handleDragForDoc}
                  onDragLeave={handleDragForDoc}
                  onDragOver={handleDragForDoc}
                  onDrop={handleDropForDoc}
                  style={{
                    backgroundColor: "var(--input-color)",
                    height: "155px",
                    borderRadius: "6px",
                  }}
                >
                  <Image
                    onClick={(e) => {
                      e.preventDefault();
                      btnref1.click();
                      return false;
                    }}
                    preview={false}
                    src={fileType === "application/pdf" ? pdf : word}
                    style={{
                      height: "118px",
                      width: "100px",
                      borderRadius: "6px",
                      position: "relative",
                      display: "block",
                      margin: "16px auto 21px 21px",
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      paddingTop: "5px",
                      paddingLeft: "1px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setFilesData("");
                      setFileRequired(true);
                    }}
                  >
                    <CloseIcon color={"var(--headings-color)"} />
                  </span>
                </div>
              ) : (
                <>
                  <input
                    hidden
                    accept="application/pdf,.docx"
                    type="file"
                    name="resume"
                    onChange={handleChangeForDocument}
                    ref={(input) => {
                      btnref1 = input;
                    }}
                    onClick={(event) => {
                      event.currentTarget.value = null;
                    }}
                  />
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      btnref1.click();
                      return false;
                    }}
                    style={{
                      textAlign: "center",
                      height: "155px",
                      backgroundColor: "var(--input-color)",
                      borderRadius: "5px",
                    }}
                  >
                    <Row justify="center" style={{ paddingTop: "31px" }}>
                      <Col>
                        <UploadIcon color={"var(--body-text-color)"} />
                      </Col>
                    </Row>
                    <div>
                      <p
                        style={{
                          fontWeight: 400,
                          textAlign: "center",
                          marginTop: "10px",
                          fontFamily: "Helvetica,ProductSans",
                          fontStyle: "normal",
                          fontSize: "16px",
                          lineHeight: "19px",
                          color: "var(--body-text-color)",
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Upload any document
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; or
                        &nbsp;&nbsp;&nbsp; Drag and drop document
                      </p>
                    </div>
                  </div>
                </>
              )}
            </Form.Item>

            <Form.Item
              name="username"
              label="Username"
              rules={[
                {
                  required: true,
                  message: "Please enter username",
                },
                {
                  type: "email",
                  message: "Please enter a valid username",
                },
              ]}
            >
              <Input readOnly placeholder="Username" />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: " Password Required" },
                // {
                //   pattern:
                //     /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
                //   message:
                //     "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character!",
                // },
              ]}
            >
              <Input.Password
                // className={styles.input}
                name="password"
                type="password"
                placeholder="Password*"
              />
            </Form.Item>
            <Form.Item
              name="bankName"
              label="Bank"
              rules={[
                {
                  required: false,
                  message: "Please enter bank",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                //   message:
                //     "It allows only Alphabets,No whitespace at the beginning and end",
                // },
              ]}
            >
              <Input placeholder="Enter bank" />
            </Form.Item>

            <Form.Item
              name="AccountNumber"
              label="Account Number"
              rules={[
                {
                  required: false,
                  message: "Please enter account number",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9\s]+$/,
                //   message:
                //     "It allows only alphanumerics, no whitespace at the beginning and end",
                // },
              ]}
            >
              <Input type="number" placeholder="Enter account number" />
            </Form.Item>

            <Form.Item
              name="AccountName"
              label="Account in the name of"
              rules={[
                {
                  required: false,
                  message: "Please enter account name",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9\s]+$/,
                //   message:
                //     "It allows only alphanumerics, no whitespace at the beginning and end",
                // },
              ]}
            >
              <Input placeholder="Enter account name" />
            </Form.Item>

            <Form.Item
              name="ifsc"
              label="IFSC Code"
              rules={[
                {
                  required: false,
                  message: "Please enter ifsc code",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Z0-9\s]+$/,
                //   message: "Invalid IFSC",
                // },

                // {
                //   min: 21,
                //   message: "IFSC is should be in 11-digit alpha-numeric format",
                // },
              ]}
            >
              <Input
                maxLength={11}
                autoComplete="off"
                className="ant-input mi_input"
                type="text"
                placeholder="Enter ifsc number"
              />
            </Form.Item>
            <Form.Item
              name="bankBranchName"
              label="Branch Name"
              rules={[
                {
                  required: false,
                  message: "Please enter branch name",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                //   message:
                //     "It allows only alphabets,no whitespace at the beginning and end",
                // },
              ]}
            >
              <Input placeholder="Enter branch name" />
            </Form.Item>
            <Form.Item
              name="monthlySalary"
              label="Salary (CTC in Lakhs)"
              rules={[
                {
                  required: requiredFields ? false : true,
                  message: "Please enter salary",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9\s]+$/,
                //   message:
                //     "It allows only Alphanumerics,no whitespace at the beginning and end",
                // },
              ]}
            >
              <Input placeholder="Enter salary" />
            </Form.Item>
            <Form.Item
              name="perDayCost"
              label="Per day cost"
              rules={[
                {
                  required: requiredFields ? false : true,
                  message: "Please enter per day cost",
                },
                // {
                //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9\s]+$/,
                //   message:
                //     "It allows only alphanumerics, no whitespace at the beginning and end",
                // },
              ]}
            >
              <Input placeholder="Enter per day cost" />
            </Form.Item>

            <Row>
              <Col sm={4}>
                <Form.Item>
                  <p
                    style={{ color: "var(--body-text-color)" }}
                    className={commonCssStyle.marginBottomRemove}
                  >
                    Web Acess
                  </p>
                </Form.Item>
              </Col>
              <Col sm={20}>
                <Row>
                  <Col>
                    <Form.Item name="webAccess">
                      <Switch
                        style={{ marginRight: "10px" }}
                        size="small"
                        onChange={(val) => {
                          setWebAccessStatus(val);
                        }}
                      ></Switch>
                    </Form.Item>
                  </Col>
                  <Col>
                    <p
                      style={{
                        color: "var(--body-text-color)",
                        marginTop: "10px",
                      }}
                      className={commonCssStyle.marginBottomRemove}
                    >
                      {webAccessStatus === true ? "Enable" : "Disable"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginBottom: "20%" }}>
              <Col sm={4}>
                <Form.Item>
                  <p
                    style={{ color: "var(--body-text-color)" }}
                    className={commonCssStyle.marginBottomRemove}
                  >
                    App Acess
                  </p>
                </Form.Item>
              </Col>
              <Col sm={20}>
                <Row>
                  <Col>
                    <Form.Item
                      name="appAccess"
                      rules={[
                        {
                          required: false,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Switch
                        style={{ marginRight: "10px" }}
                        size="small"
                        onChange={(val) => {
                          setappAccessStatus(val);
                        }}
                      ></Switch>
                    </Form.Item>
                  </Col>
                  <Col>
                    <p
                      style={{
                        color: "var(--body-text-color)",
                        marginTop: "10px",
                      }}
                      className={commonCssStyle.marginBottomRemove}
                    >
                      {appAccessStatus === true ? "Enable" : "Disable"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Col xs={24} md={24} className={commonStyles.footerShadow}>
          <Row justify="end" style={{ gap: "17px", width: "770px" }}>
            <Col>
              <Button
                onClick={props.onClose}
                style={{
                  width: "150px",
                  height: "48px",
                }}
                className={commonStyles.footerButtonAddCandidateDrawerSecondary}
              >
                Cancel
              </Button>
            </Col>

            <Col>
              <Button
                htmlType="button"
                onClick={() => {
                  form.submit();
                }}
                style={{
                  width: "150px",
                  height: "48px",
                }}
                className={commonStyles.footerButtonAddCandidateDrawerPrimary}
                type="primary"
              >
                {!props.employee ? "Add Employee" : "Update Employee"}
              </Button>
            </Col>
          </Row>
        </Col>
      </Form>
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  return {
    countriesData: state.country.countries,
    StateData: state.country.state,
    cityData: state.country.city,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCountriesHandler: (data, callback) => {
      dispatch(getCountriesAction(data, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddEmployee);
