import { Button, Card, Col, Form, Row, Modal, Input } from "antd";
import React, { useEffect, useState, useRef } from "react";
import styles from "../Application/Application.module.css";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import { Tag } from "antd";
import { BackButtonIcon, CloseIcon } from "../../../IconsComp/Icons";

import { connect } from "react-redux";
import {
  getLeadSourcesAction,
  postLeadSourcesAction,
  deleteLeadSourcesAction,
} from "../../../../Reusable/Store/Action/Master/MasterLeadSourceAction";
function SourceOfTheLead(props) {
  const [tags, setTags] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const inputRef = useRef(null);
  useEffect(() => {
    props.getLeadSourcesHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSOTChange = (event) => {
    setTags(event.target.value);
  };
  const addSOTItem = (e) => {
    e.preventDefault();
    if (tags.trim().length === 0) {
      return;
    }
    sotHandleConfirm(tags.trim());
    setTags("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  const sotHandleConfirm = (data) => {
    const val = {
      source: data,
    };
    props.postLeadSourcesHandler(val);
  };
  const handleClose = (removedTag) => {
    setDeleteId(removedTag);
    setOpenModal(true);
  };
  function closeDeleteHandler() {
    setOpenModal(false);
  }
  function confirmDeleteHandler(id) {
    props.deleteSourceOfHandler(id);
    setOpenModal(false);
  }

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags } `}
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag.id);
        }}
      >
        {tag.source}
      </Tag>
    );
    return (
      <span
        key={tag.id}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagChild =
    props.sourceOfTheLeadData && props.sourceOfTheLeadData.map(forMap);

  return (
    <>
      <Card style={{ overflow: "hidden" }}>
        <Card style={{ overflow: "hidden" }} className={styles.TagsCard1}>
          <Row align="middle" wrap={false}>
            <Col
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.backToApp();
              }}
              className={styles.SourceofTheLeadBackBtn}
            >
              <BackButtonIcon color={"var(--icons-color)"} />
            </Col>
            <Col>
              <h1 className={commonCssStyle.applicationCardName}>
                Source of the lead
              </h1>
            </Col>
          </Row>
        </Card>

        <Card
          style={{
            height: "70vh",
            margin: "0px 24px",
          }}
        >
          <Form layout="vertical">
            <Row>
              <Col className="modelcol" md={24}>
                <Form.Item
                  label={
                    <h1
                      style={{ marginTop: "20px" }}
                      className={commonCssStyle.srcOfLeade}
                    >
                      Source of Leads
                    </h1>
                  }
                  name="source"
                >
                  <Input
                    className="tagsInput"
                    placeholder="Please Enter Source of The Lead Tag"
                    ref={inputRef}
                    value={tags}
                    onChange={onSOTChange}
                    onPressEnter={addSOTItem}
                  />
                  <div
                    style={{
                      marginBottom: 16,
                    }}
                  >
                    <p>{tagChild}</p>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Card>
      <Modal
        open={openModal}
        closable={false}
        title={
          <Row >
            <Col md={20}><h3
            className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.popUpHeadingText}`}
          >
            Delete Lead Source
          </h3></Col>
          <Col md={4}> <div
        style={{float:"right"}}
        onClick={() => {
          closeDeleteHandler();
        }}
        className={`${commonCssStyle.coursorPointer} `}
        >
          <CloseIcon   color={"var(--body-text-color)" || "var(--theme)"} />
        </div></Col>
          </Row>
        }
        footer={
          <>
            <Row style={{ gap: "10px" }} justify="end" align="middle">
              <Col>
                <Button
                  className={commonCssStyle.buttonSettingsSecondary}
                  onClick={() => {
                    closeDeleteHandler();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className={commonCssStyle.buttonSettingsPrimary}
                  onClick={() => {
                    confirmDeleteHandler(deleteId);
                  }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Row>
          <Col>
            <p
              className={`${commonCssStyle.deleteConfiTextColor} ${commonCssStyle.deleteConfiTextColor}`}
            >
              Are you sure you want to delete
            </p>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    sourceOfTheLeadData: state.leadSource.leadSources,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLeadSourcesHandler: () => {
      dispatch(getLeadSourcesAction());
    },
    postLeadSourcesHandler: (data) => {
      dispatch(postLeadSourcesAction(data));
    },
    deleteSourceOfHandler: (id) => {
      dispatch(deleteLeadSourcesAction(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SourceOfTheLead);
