import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taskbytyps: [],
  tasks: [],
  taskFilterData: [],
  calendarFilterData: [],
  task: null,
  calendar: [],
  filterStatus: false,
  // taskStatusData: true,
  taskStatusData: false,
  filterStatusVar: false,
  filterData: {},
  loader: false,
  candidatesJob: [],
};

export const TaskAndCalendarSlice = createSlice({
  name: "taskAndCalendar",
  initialState,
  reducers: {
    getTaskByTypeSlice(state, action) {
      state.taskbytyps = action.payload.result;
    },
    getTasksSlice(state, action) {
      state.tasks = action.payload.result;
    },
    getTasksFilterSlice(state, action) {
      state.tasks = action.payload.result;
    },
    getTasksCalendarSlice(state, action) {
      state.calendar = action.payload.result;
    },
    getCalendarFilterSlice(state, action) {
      state.calendarFilterData = action.payload.result;
    },
    getTaskAndCalendarSlice(state, action) {
      state.taskFilterData = action.payload.result;
    },
    filterStatusSlice(state, action) {
      state.filterStatus = action.payload;
    },
    postTaskAndCalendarSlice(state, action) {
      state.tasks = [action.payload.result, ...state.task];
    },
    deleteTaskAndCalendarSlice(state, action) {
      state.tasks = state.task.filter((data) => data.id !== action.payload);
    },
    putTaskAndCalendarSlice(state, action) {
      state.task = action.payload.result;
    },
    getTaskStatusSlice(state, action) {
      state.taskStatusData = action.payload;
    },
    getTaskStatusVarSlice(state, action) {
      state.filterStatusVar = action.payload;
    },
    getTaskAndInterviewFilterData(state, action) {
      state.filterData = action.payload;
    },
    //for loading icon
    loaderSlice(state, action) {
      state.loader = action.payload;
    },

    //for getting cadidates job
    getCandidatesJobSlice(state, action) {
      state.candidatesJob = action.payload.result;
    },
  },
});
export const {
  getTaskStatusSlice,
  loaderSlice,
  getTaskStatusVarSlice,
  getTaskAndCalendarSlice,
  filterStatusSlice,
  postTaskAndCalendarSlice,
  deleteTaskAndCalendarSlice,
  putTaskAndCalendarSlice,
  getTaskByTypeSlice,
  getTasksFilterSlice,
  getTasksCalendarSlice,
  getCalendarFilterSlice,
  getTaskAndInterviewFilterData,
  getTasksSlice,
  getCandidatesJobSlice,
} = TaskAndCalendarSlice.actions;
export default TaskAndCalendarSlice.reducer;
