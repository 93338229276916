import { Form, Input } from "antd";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
function Telephonic() {
  return (
    <>
      <Form.Item required={false}>
        <Form.Item
          rules={[
            {
              required: false,
              message: "Please enter your contact name",
            },
          ]}
          label="Contact person name"
          name="contactName"
        >
          <Input placeholder="Enter Contact Person Name" />
        </Form.Item>
      </Form.Item>
      <Form.Item
        name="phone"
        label="Contact Number"
        rules={[
          {
            required: false,
            message: "Please enter your contact number",
          },
        ]}
      >
        <PhoneInput
          placeholder="Enter contact number"
          international
          className={`ant-input ant-cust-inputs antPhoneHeight`}
          defaultCountry="IN"
        />
      </Form.Item>
    </>
  );
}

export default Telephonic;
