import { useState } from "react";
import {
  Table,
  Tag,
  Dropdown,
  Menu,
  Row,
  Col,
  Button,
  Select,
  Card,
  Switch,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import moment from "moment";

import {
  ThreeDotsIcon,
  FilterOutlinedIcon,
  EditIcon,
  TrashIcon,
  UserIcon,
  NewIcon,
  PlusIcon,
  Trending,
  Hot,
  Premium,
  Featured,
  ProfileIcon,
} from "../../IconsComp/Icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Option } from "antd/lib/mentions";
import commonCssStyle from "../../UI/CommonCss.module.css";
import RecruitmentFilter from "./RecruitmentFilter";
import RecruitmentDrawer from "./RecruitmentDrawer";
import { connect } from "react-redux";
import { getRecruitmentsFilterAction } from "../../../Reusable/Store/Action/RecruitmentAction";
import { useEffect } from "react";
import { getClientsAction } from "../../../Reusable/Store/Action/ClientAction";
import { getTagsAction } from "../../../Reusable/Store/Action/Master/MasterTagsAction";
import {
  getRequirementsAction,
  getJobCodeAction,
  postRequirementsAction,
  putRequirementsAction,
} from "../../../Reusable/Store/Action/RequirementAction";
import { getEmployeesAction } from "../../../Reusable/Store/Action/EmployeesAction";
import RequirementDrawer from "../Requirement/RequirementDrawer";
import { getDepartmentsAction } from "../../../Reusable/Store/Action/Master/MasterDepartmentsAction";
import { getJobDescriptionsAction } from "../../../Reusable/Store/Action/Master/MasterJobDescriptionAction";
import { getSharingMediumsAction } from "../../../Reusable/Store/Action/Master/MasterSharingMediumAction";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import PermissionMessage from "../../UI/PermissionMessage";
import BreadcrumbForRecruitment from "./BreadcrumbForRecruitment";

function RecruitmentTable(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [deleteBtnClr, setDeleteBtnClr] = useState("var(--headings-color)");
  const [iconClr, setIconClr] = useState("var(--subHeadings-color)");
  const [assignIconClr, setAssignIconClr] = useState(
    "var(--subHeadings-color)"
  );
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  const [cfilter, setCFilter] = useState({});
  const location = useLocation();

  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      if (localStorage.getItem("token")) {
        let recuritmentFilterData = localStorage.getItem(
          "recuritmentFilterData"
        );
        let recuritmentFilterData1 = JSON.parse(recuritmentFilterData);
        if (recuritmentFilterData) {
          props.getRecruitmentFilterHandler({
            current_page: 1,
            ...recuritmentFilterData1,
          });
        } else {
          props.getRecruitmentFilterHandler({ current_page: 1 });
        }

        props.getClientsHandler();
        props.getTagHandler();
        props.getRequirementAction();
        props.getEmployeesAction();
        props.getSharingMediumsActionHandler();
        props.getJobCodeAction();
        props.getDepartmentsAction();
      }
    }
    return () => {
      unsubscribed = true;
    };
  }, []);

  if (window.location.hash === "#/recruitment") {
    localStorage.removeItem("candidateDetail");
    localStorage.removeItem("employee");
    localStorage.removeItem("clientCompany");
    localStorage.removeItem("requirementFilterData");
    localStorage.removeItem("taskFilterData");
    localStorage.removeItem("interviewFilterData");
    localStorage.removeItem("candidateDetailFromRequirement");
    localStorage.removeItem("reportsCompanyDetailFilterData");
    localStorage.removeItem("reportsjobdetailfilterdata");
    localStorage.removeItem("reportsjobcandidatefilterdata");
    localStorage.removeItem("recuritmentReportFilter");
    localStorage.removeItem("employeeReportsFilter");
    localStorage.removeItem("candidateSort");
    localStorage.removeItem("interivewSort");
    localStorage.removeItem("taskSort");
    localStorage.removeItem("requirementSort");
    localStorage.removeItem("ClientCompanySort");
    localStorage.removeItem("employeeSort");
    localStorage.removeItem("ReportsCompanyReport1Sort");
    localStorage.removeItem("reportsjobcandidatefilterdataSort");
    localStorage.removeItem("ReportsJobDetailFilterOneSort");
    localStorage.removeItem("reportRecruitmentEmployeeSort");
    let check = JSON.parse(localStorage.getItem("fromWhichPage"));
    if (
      (check && check.fromRequirement === "fromRequirement") ||
      (check && check.candidateDetail === "candidateDetail")
    ) {
      localStorage.removeItem("fromWhichPage");
    }
    // localStorage.removeItem("candidateListForPool");
    // localStorage.removeItem("recuritmentCandidateFilter");
  }

  const fetchRecords = (page) => {
    let recuritmentFilterDataLocal = JSON.parse(
      localStorage.getItem("recuritmentFilterData")
    );
    if (recuritmentFilterDataLocal) {
      const val = {
        ...cfilter,
        ...props.recruitment_filter,
        ...recuritmentFilterDataLocal,
        current_page: page,
      };
      localStorage.setItem(
        "recuritmentFilterData",
        JSON.stringify({
          ...recuritmentFilterDataLocal,
          current_page: page,
        })
      );
      props.getRecruitmentFilterHandler(val);
    } else {
      const val = {
        ...cfilter,
        ...props.recruitment_filter,
        current_page: page,
      };
      localStorage.setItem(
        "recuritmentFilterData",
        JSON.stringify({ current_page: page })
      );
      props.getRecruitmentFilterHandler(val);
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const closeHandler = () => {
    setOpen(false);
  };

  function showFilterHandler() {
    setOpenFilter(true);
  }
  const closeFilterHandler = () => {
    setOpenFilter(false);
  };

  const onSubmit = (val) => {
    props.postRequirementHandler(val, (success) => {
      if (success) {
        props.getJobCodeAction();
        setOpen(false);
      }
    });
  };
  const dateFormate =
    props.settings && props.settings.dateFormat
      ? props.settings.dateFormat
      : "DD/MM/YYYY";

  // const menu = (
  //   <Menu
  //     style={{ width: "180px" }}
  //     items={[
  //       {
  //         key: "1",
  //         label: (
  //           <Link state={{id:render.id,activeTabKey:"2"}}>
  //           <Row
  //             onMouseEnter={() => {
  //               setIconClr("#2F7BF5");
  //             }}
  //             onMouseLeave={() => {
  //               setIconClr("var(--body-text-color)");
  //             }}
  //           >
  //             <Col  style={{
  //                             padding: "5px 5px 0px 0px",
  //                             transform: "translateY(3px)",
  //                           }}>
  //               <ProfileIcon color={iconClr} />
  //             </Col>
  //             <Col style={{ padding: "5px 5px 0px 5px" }}>
  //               <p style={{ margin: "0px", color: iconClr }}>Assign Recruiter</p>
  //             </Col>
  //           </Row>
  //           </Link>
  //         ),
  //       },
  //       // {
  //       //   key: "2",
  //       //   label: (
  //       //     <Row
  //       //       onMouseEnter={() => {
  //       //         setIconClr("#2F7BF5");
  //       //       }}
  //       //       onMouseLeave={() => {
  //       //         setIconClr("black");
  //       //       }}
  //       //     >
  //       //       <Col style={{ padding: "5px 5px 0px 0px" }}>
  //       //         <EditIcon color={iconClr} />
  //       //       </Col>
  //       //       <Col style={{ padding: "5px 5px 0px 5px" }}>
  //       //         <p>Edit</p>
  //       //       </Col>
  //       //     </Row>
  //       //   ),
  //       // },
  //       // {
  //       //   key: "3",
  //       //   label: (
  //       //     <Row
  //       //       onMouseEnter={() => {
  //       //         setDeleteBtnClr("red");
  //       //       }}
  //       //       onMouseLeave={() => {
  //       //         setDeleteBtnClr("var(--headings-color)");
  //       //       }}
  //       //     >
  //       //       <Col style={{ padding: "5px 5px 0px 0px" }}>
  //       //         <TrashIcon color={deleteBtnClr} />
  //       //       </Col>
  //       //       <Col style={{ padding: "5px 5px 0px 5px", color: deleteBtnClr }}>
  //       //         <p>Delete</p>
  //       //       </Col>
  //       //     </Row>
  //       //   ),
  //       // },
  //     ]}
  //   />
  // );

  const columns = [
    {
      title: <p className={styles.LabelName}>Job Code</p>,
      dataIndex: "jobCode",
      align: "left",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Job Title</p>,
      dataIndex: "title",
      align: "left",
      render: (text, render) =>
        props.permissions &&
        props.permissions.find((data) => data === "recruitment-detail") ? (
          <p
            onClick={() => {
              props.getCheckPermissionHandler(
                "recruitment-detail",
                (success) => {
                  if (success) {
                    navigate("/recruitment/recruitmentJobdetails", {
                      state: {
                        id: render.id,
                        activeTabKey: "1",
                        recuritmentJobDetailsName: text,
                      },
                    });
                    props.getRecuritmentFilterDataHandler(...cfilter);
                  } else {
                    setOpenPMModal({
                      open: true,
                      permission: "Recruitment Details",
                    });
                  }
                }
              );
            }}
            // to="/recruitment/recruitmentJobdetails"
            className={commonCssStyle.tableDataLinkText}
            // state={{ id: render.id, activeTabKey: "1" }}
            style={{
              color: "var(--primaryColor)",
              fontFamily: "Helvetica,ProductSans",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "17px",
              margin: "0px",
              cursor: "pointer",
            }}
          >
            <Row wrap={false} style={{ gap: "2px" }}>
              <Col>{text}</Col>
              <Col className={commonCssStyle.newIcon}>
                {render.priority == "trending" ? (
                  <Trending />
                ) : render.priority == "hot" ? (
                  <Hot />
                ) : render.priority == "premium" ? (
                  <Premium />
                ) : render.priority == "featured" ? (
                  <Featured />
                ) : (
                  <NewIcon />
                )}
              </Col>
            </Row>
          </p>
        ) : (
          <Row>
            <Col>
              <p
                style={{ color: "var(--primaryColor)", margin: "0px" }}
                className={commonCssStyle.tableDataLinkText}
              >
                {text}
              </p>
            </Col>
            <Col className={commonCssStyle.newIcon}>
              {render.priority == "trending" ? (
                <Trending />
              ) : render.priority == "hot" ? (
                <Hot />
              ) : render.priority == "premium" ? (
                <Premium />
              ) : render.priority == "featured" ? (
                <Featured />
              ) : (
                <NewIcon />
              )}
            </Col>
          </Row>
        ),
    },
    {
      title: <p className={styles.LabelName}>Company Name</p>,
      dataIndex: "clientName",
      align: "left",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Start Date</p>,
      dataIndex: "startDate",
      align: "left",
      render: (data) => {
        return (
          <p
            style={{ whiteSpace: "nowrap" }}
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? moment(data).format(dateFormate) : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>End Date</p>,
      dataIndex: "endDate",
      align: "left",
      render: (data) => {
        return (
          <p
            style={{ whiteSpace: "nowrap" }}
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data !== "NA" ? moment(data).format(dateFormate) : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Created Date</p>,
      dataIndex: "createdAt",
      align: "left",
      render: (data) => {
        return (
          <p
            style={{ whiteSpace: "nowrap" }}
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? moment(data).format(dateFormate) : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Last Updated Date</p>,
      dataIndex: "lastUpdatedDate",
      align: "left",
      render: (data) => {
        return (
          <p
            style={{ whiteSpace: "nowrap" }}
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? moment(data).format(dateFormate) : "NA"}
          </p>
        );
      },
    },

    {
      title: <p className={styles.LabelName}>Active</p>,
      dataIndex: "activeCount",
      align: "left",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Screening</p>,
      dataIndex: "screeningCount",
      align: "left",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Interviewing</p>,
      dataIndex: "interviewedCount",
      align: "left",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Offered</p>,
      dataIndex: "offeredCount",
      align: "left",
      render: (data) => (
        <>
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        </>
      ),
    },
    {
      title: <p className={styles.LabelName}>Hired</p>,
      dataIndex: "hiredCount",
      align: "left",
      render: (data) => (
        <>
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        </>
      ),
    },
    {
      title: <p className={styles.LabelName}>Declined</p>,
      dataIndex: "declinedCount",
      align: "left",
      render: (data, render) => {
        return (
          <Row align="middle">
            <Col>
              <p
                className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
              >
                {data && data}
              </p>
            </Col>

            {/* {props.permissions &&
              props.permissions.find(
                (data) => data === "job-assign-employee"
              ) && (
                <Col md={6}>
                  <Dropdown
                    placement="bottomRight"
                    overlay={
                      <Menu
                        style={{ width: "180px" }}
                        items={[
                          {
                            key: "1",
                            label: (
                              <p
                                onClick={() => {
                                  props.getCheckPermissionHandler(
                                    "job-assign-employee",
                                    (success) => {
                                      if (success) {
                                        navigate("/JobDetailPage", {
                                          state: {
                                            id: render.id,
                                            activeTabKey: "2",
                                          },
                                        });
                                      } else {
                                        setOpenPMModal({
                                          open: true,
                                          permission: "Job Assign Employee",
                                        });
                                      }
                                    }
                                  );
                                }}
                                // to="/JobDetailPage"
                                // state={{ id: render.id, activeTabKey: "2" }}
                              >
                                <Row
                                  onMouseEnter={() => {
                                    setIconClr("#2F7BF5");
                                  }}
                                  onMouseLeave={() => {
                                    setIconClr("var(--body-text-color)");
                                  }}
                                >
                                  <Col style={{ padding: "2px 1px 0px 0px" }}>
                                    <ProfileIcon color={iconClr} />
                                  </Col>
                                  <Col style={{ padding: "5px 5px 0px 5px" }}>
                                    <p
                                      style={{ margin: "0px", color: iconClr }}
                                    >
                                      Assign Recruiter
                                    </p>
                                  </Col>
                                </Row>
                              </p>
                            ),
                          },
                        ]}
                      />
                    }
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      <ThreeDotsIcon color={"var(--table-text-color)"} />
                    </Link>
                  </Dropdown>
                </Col>
              )} */}
          </Row>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Status</p>,
      dataIndex: "status",
      align: "left",
      render: (data, render) => {
        return (
          <Select
            className={`${
              render.status == "open"
                ? "jobStatus3"
                : render.status == "closed"
                ? "jobStatus1"
                : "jobStatus2"
            }`}
            style={{
              /* color: "#044123", */ width: "90px",
              color:
                render.status == "open"
                  ? "#044123"
                  : render.status == "closed"
                  ? "#F83A3A"
                  : "#FF9A02",
            }}
            size="small"
            defaultValue={data}
            onChange={(val) => {
              props.getCheckPermissionHandler("job-edit", (success) => {
                if (success) {
                  props.putRequirementHandler(
                    {
                      id: render.id,
                      _method: "PUT",
                      status: val,
                    },
                    "details",
                    (success) => {
                      if (success) {
                      }
                    }
                  );
                } else {
                  setOpenPMModal({
                    open: true,
                    permission: "Job Edit",
                  });
                }
              });
            }}
          >
            <Select.Option value="open">Open</Select.Option>
            <Select.Option value="closed">Closed</Select.Option>
          </Select>
        );
      },
    },
    {
      render: (data, render) => {
        return (
          props.permissions &&
          props.permissions.find((data) => data === "job-assign-employee") && (
            <Dropdown
              placement="bottomRight"
              overlay={
                <Menu
                  style={{ width: "180px" }}
                  items={[
                    {
                      key: "1",
                      label: (
                        <p
                          onClick={() => {
                            props.getCheckPermissionHandler(
                              "job-assign-employee",
                              (success) => {
                                if (success) {
                                  navigate("/JobDetailPage", {
                                    state: {
                                      id: render.id,
                                      activeTabKey: "2",
                                    },
                                  });
                                } else {
                                  setOpenPMModal({
                                    open: true,
                                    permission: "Job Assign Employee",
                                  });
                                }
                              }
                            );
                          }}
                          // to="/JobDetailPage"
                          // state={{ id: render.id, activeTabKey: "2" }}
                        >
                          <Row
                            onMouseEnter={() => {
                              setIconClr("#2F7BF5");
                            }}
                            onMouseLeave={() => {
                              setIconClr("var(--body-text-color)");
                            }}
                          >
                            <Col style={{ padding: "2px 1px 0px 0px" }}>
                              <ProfileIcon color={iconClr} />
                            </Col>
                            <Col style={{ padding: "5px 5px 0px 5px" }}>
                              <p style={{ margin: "0px", color: iconClr }}>
                                Assign Recruiter
                              </p>
                            </Col>
                          </Row>
                        </p>
                      ),
                    },
                  ]}
                />
              }
            >
              <Link onClick={(e) => e.preventDefault()}>
                <ThreeDotsIcon color={"var(--table-text-color)"} />
              </Link>
            </Dropdown>
          )
        );
      },
    },
  ];

  const handleProvinceChange = (value) => {
    let sort = value.split("_");
    localStorage.setItem("recuritmentSort", value);
    let recuritmentFilter = JSON.parse(
      localStorage.getItem("recuritmentFilterData")
    );
    if (recuritmentFilter) {
      localStorage.setItem(
        "recuritmentFilterData",
        JSON.stringify({
          current_page: 1,
          ...cfilter,
          ...recuritmentFilter,
          sortBy: sort[0],
          sortOrder: sort[1],
        })
      );
      props.getRecruitmentFilterHandler({
        current_page: 1,
        ...cfilter,
        ...recuritmentFilter,
        sortBy: sort[0],
        sortOrder: sort[1],
      });
      setCFilter({
        current_page: 1,
        ...cfilter,
        ...recuritmentFilter,
        sortBy: sort[0],
        sortOrder: sort[1],
      });
    } else {
      localStorage.setItem(
        "recuritmentFilterData",
        JSON.stringify({
          current_page: 1,
          ...cfilter,
          sortBy: sort[0],
          sortOrder: sort[1],
        })
      );
      props.getRecruitmentFilterHandler({
        current_page: 1,
        ...cfilter,
        sortBy: sort[0],
        sortOrder: sort[1],
      });
      setCFilter({
        current_page: 1,
        ...cfilter,
        sortBy: sort[0],
        sortOrder: sort[1],
      });
    }
  };
  const recruitmentFilterHandler = (val) => {
    let recuritmentFilter = JSON.parse(
      localStorage.getItem("recuritmentFilterData")
    );
    if (recuritmentFilter) {
      const data = {
        ...recuritmentFilter,
        ...val,
        current_page: 1,
      };
      setCFilter(data);
      props.getRecruitmentFilterHandler(data);
    } else {
      const data = {
        ...val,
        current_page: 1,
      };
      setCFilter(data);
      props.getRecruitmentFilterHandler(data);
    }
  };

  return (
    <>
      <Row>
        <Col md={24}>
          <RecruitmentFilter
            filterData={props.recruitment_filter}
            employees={props.employees}
            clients={props.clients}
            requirements={props.requirements}
            tagsData={props.tagsData}
            recruitment_filter={props.recruitment_filter}
            recruitmentFilterHandler={recruitmentFilterHandler}
            onOpenFilter={openFilter}
            onCloseFilter={closeFilterHandler}
          />

          {/* <RecruitmentDrawer
             onOpen={open} 
             onClose={closeHandler} 
             /> */}

          <RequirementDrawer
            onOpen={open}
            onClose={closeHandler}
            clients={props.clients}
            departments={props.departments}
            tagsData={props.tagsData}
            jobDescriptionData={props.jobDescriptionsData}
            sharingMediumsData={props.sharingMediumsData}
            getJobCode={props.requirementJobCode}
            settindsData={props.settindsData}
            onSubmit={onSubmit}
          />

          <Row className={commonCssStyle.breadcrumbBottomMargin}>
            <Col md={24}>
              <BreadcrumbForRecruitment slide={[1, 2]} />
            </Col>
          </Row>

          <Row align="middle" justify="space-between">
            <Col>
              <h1
                style={{ marginRight: "51px" }}
                className={`${commonCssStyle.inttitle} ${commonCssStyle.mainHeadingText}`}
              >
                Live jobs &#40;{" "}
                {props.recruitment_filter && props.recruitment_filter.total
                  ? props.recruitment_filter.total
                  : "0"}{" "}
                &#41;
              </h1>

              <Button
                id="Recruitment-Filter-Btn"
                onClick={showFilterHandler}
                className={`${commonCssStyle.filterButton} `}
              >
                <Row style={{ gap: "11px" }}>
                  <Col>
                    <div style={{ transform: "translateY(2px)" }}>
                      <FilterOutlinedIcon color={"var(--primaryColor)"} />
                    </div>
                  </Col>
                  <Col push={1}>
                    <p
                      className={`${commonCssStyle.filterAndSortTextStyle} ${commonCssStyle.marginBottomRemove}`}
                    >
                      Filter
                    </p>
                  </Col>
                </Row>
              </Button>

              <Button
                id="RecruitmentSortByBtn"
                style={{
                  height: "40px",
                }}
                className={`${commonCssStyle.sortBtn}`}
                // type="default"
              >
                <Row align="middle" justify="space-between">
                  <Col>
                    <p
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      Sort By
                    </p>
                  </Col>
                  <Col>
                    <Select
                      style={{ width: "100%" }}
                      bordered={false}
                      onChange={handleProvinceChange}
                      placeholder={
                        <p
                          style={{
                            margin: "0px",
                            color: "var(--primaryColor)",
                          }}
                        >
                          Select
                        </p>
                      }
                      value={
                        localStorage.getItem("recuritmentSort")
                          ? localStorage.getItem("recuritmentSort")
                          : "Select"
                      }
                      size="small"
                      className={`${commonCssStyle.filterDrop} selectInput sortBySelectStyle selectTag selectTag1`}
                    >
                      <Select.Option
                        value="title_ASC"
                        key="1"
                        style={{ padding: "10px" }}
                      >
                        Title Ascending
                      </Select.Option>
                      <Select.Option
                        value="title_DESC"
                        key="2"
                        style={{ padding: "10px" }}
                      >
                        Title Descending
                      </Select.Option>
                      <Select.Option
                        value="lastUpdated_ASC"
                        key="3"
                        style={{ padding: "10px" }}
                      >
                        Last Updated Ascending
                      </Select.Option>
                      <Select.Option
                        value="lastUpdated_DESC"
                        key="4"
                        style={{ padding: "10px" }}
                      >
                        LastUpdated Descending
                      </Select.Option>
                      <Select.Option
                        value="createdDate_ASC"
                        key="5"
                        style={{ padding: "10px" }}
                      >
                        Created Date Ascending
                      </Select.Option>
                      <Select.Option
                        value="createdDate_DESC"
                        key="6"
                        style={{ padding: "10px" }}
                      >
                        Created Date Descending
                      </Select.Option>
                      {/* <Select.Option
                        value="department_ASC"
                        key="5"
                        style={{ padding: "10px" }}
                      >
                        Department Ascending
                      </Select.Option>
                      <Select.Option
                        value="department_DESC"
                        key="6"
                        style={{ padding: "10px" }}
                      >
                        Department Descending
                      </Select.Option> */}
                      {/* <Select.Option
                        value="jobType_ASC"
                        key="7"
                        style={{ padding: "10px" }}
                      >
                        JobType Ascending
                      </Select.Option>
                      <Select.Option
                        value="jobType_DESC"
                        key="8"
                        style={{ padding: "10px" }}
                      >
                        JobType Descending
                      </Select.Option>
                      <Select.Option
                        value="role_ASC"
                        key="9"
                        style={{ padding: "10px" }}
                      >
                        Role Ascending
                      </Select.Option>
                      <Select.Option
                        value="role_DESC"
                        key="10"
                        style={{ padding: "10px" }}
                      >
                        Role Descending
                      </Select.Option>
                      <Select.Option
                        value="clientComapny_ASC"
                        key="11"
                        style={{ padding: "10px" }}
                      >
                        Client Comapny Ascending
                      </Select.Option>
                      <Select.Option
                        value="clientComapny_DESC"
                        key="12"
                        style={{ padding: "10px" }}
                      >
                        Client Comapny Descending
                      </Select.Option> */}
                    </Select>
                  </Col>
                </Row>
              </Button>
            </Col>
            {props.permissions &&
              props.permissions.find((data) => data === "job-add") && (
                <Button
                  id="RecruitmentAddNewJobBtn"
                  type="primary"
                  style={{
                    boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.15)",
                    marginBottom: "17px",
                  }}
                  onClick={() => {
                    props.getCheckPermissionHandler("job-add", (success) => {
                      if (success) {
                        showDrawer("add", null);
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Recuritment Add",
                        });
                      }
                    });
                  }}
                  className={commonCssStyle.intaddButton}
                >
                  <Row align="middle" style={{ gap: "11px" }}>
                    <Col>
                      <PlusIcon color={"white"} />
                    </Col>
                    <Col>
                      <p className={`${commonCssStyle.marginBottomRemove} `}>
                        Add new Job
                      </p>
                    </Col>
                  </Row>
                </Button>
              )}
          </Row>
          <Col style={{ marginTop: "20px" }} xs={24}>
            <Table
              scroll={{ x: "auto" }}
              pagination={{
                size: "small",
                current:
                  props.recruitment_filter &&
                  props.recruitment_filter.current_page
                    ? props.recruitment_filter.current_page
                    : 1,
                pageSize:
                  props.recruitment_filter && props.recruitment_filter.per_page,
                total:
                  props.recruitment_filter && props.recruitment_filter.total,
                showSizeChanger: false,
                onChange: (props) => {
                  fetchRecords(props);
                },
              }}
              rowKey="key"
              columns={columns}
              dataSource={props.recruitment_filter.data}
            />
          </Col>
        </Col>
      </Row>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    recruitment_filter: state.recruitment.recruitment_filter,
    clients: state.client.clients,
    tagsData: state.tags.tags,
    requirements: state.requirements.requirements,
    employees: state.employee.employees,
    departments: state.departments.departments,
    jobDescriptionsData: state.jobDescription.jobDescriptions,
    sharingMediumsData: state.sharingMedium.sharingMediums,
    requirementJobCode: state.requirements.requirementJobCode,
    settindsData: state.settings.settings,
    permissions: state.authpermissions.permissions,
    settings: state.settings.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    putRequirementHandler: (data, type, callback) => {
      dispatch(putRequirementsAction(data, type, callback));
    },
    getRecruitmentFilterHandler: (val) => {
      dispatch(getRecruitmentsFilterAction(val));
    },
    getClientsHandler: () => {
      dispatch(getClientsAction());
    },
    getTagHandler: () => {
      dispatch(getTagsAction());
    },
    getRequirementAction: () => {
      dispatch(getRequirementsAction());
    },
    getEmployeesAction: () => {
      dispatch(getEmployeesAction());
    },
    getDepartmentsAction: () => {
      dispatch(getDepartmentsAction());
    },
    getJobDescriptionHandler: () => {
      dispatch(getJobDescriptionsAction());
    },
    getSharingMediumsActionHandler: () => {
      dispatch(getSharingMediumsAction());
    },
    getJobCodeAction: () => {
      dispatch(getJobCodeAction());
    },
    getDepartmentsAction: () => {
      dispatch(getDepartmentsAction());
    },
    postRequirementHandler: (data, callback) => {
      dispatch(postRequirementsAction(data, callback));
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentTable);
