import { Button, Card, Checkbox, Col, Modal, Row, Table } from "antd";
import React from "react";
import { connect } from "react-redux";
import commonStyleCss from "../../../UI/CommonCss.module.css";
import {
  getRequirementsFilterAction,
  postUnlinkJobFrCandidateAction,
  postAddCandidatesToJobAction,
} from "../../../../Reusable/Store/Action/RequirementAction";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router";
import { getCandidateAction } from "../../../../Reusable/Store/Action/CandidatesAction";
import { getCandidatesJobAction } from "../../../../Reusable/Store/Action/TaskAndCalendarAction";

function CandidateAssgnToJobs(props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [unlink, setUnlink] = useState("");

  const [pageNo, setPageNo] = useState("1");

  const location = useLocation();

  useEffect(() => {
    props.getCandidateHandler(location.state.id);
    props.getRequirementsFilterHandler({ current_page: pageNo });
  }, []);

  useEffect(() => {
    if (props.candidatesData && props.candidatesData.jobSummary)
      setSelectedRowKeys(
        props.candidatesData.jobSummary.map((data) => {
          return data.jobId;
        })
      );
  }, [props.candidatesData]);

  const submitData = () => {
    const val = {
      candidateId: props.candidatesData.id,
      jobId: selectedRowKeys,
      current_page: pageNo,
    };
    props.postAddJobsToCandidateHandler(val, (success) => {
      if (success) {
        props.getCandidateRelatedJobsHandler(props.candidatesData.id);
        props.getCandidateHandler(location.state.id);
        props.getRequirementsFilterHandler({ current_page: pageNo });
      }
    });
  };

  const fetchRecords = (page) => {
    setPageNo(page);
    const val = {
      ...props.requirementsFilter,
      current_page: page,
    };
    props.getRequirementsFilterHandler(val);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys, ...newSelectedRowKeys]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  function confirmDeleteHandler() {
    const data = {
      jobId: unlink,
      candidateId: props.candidatesData.id,
    };
    props.postCandidatesToJobHandler(data, (success) => {
      if (success) {
        props.getRequirementsFilterHandler({ current_page: pageNo });
        props.getCandidateHandler(location.state.id);
        setOpenModal(false);
      }
    });
    setUnlink("");
  }

  function closeUnlinkHandler() {
    setOpenModal(false);
  }
  const columns = [
    {
      dataIndex: "id",
      render: (text, render) => (
        <>
          <Checkbox
            checked={selectedRowKeys.find((x) => x === text)}
            onChange={(val) => {
              if (val.target.checked) {
                setSelectedRowKeys([...selectedRowKeys, text]);
              } else {
                let index = selectedRowKeys.indexOf(text);
                if (index != -1) {
                  setSelectedRowKeys(
                    selectedRowKeys.filter((data) => data !== text)
                  );
                }
              }
            }}
          />
        </>
      ),
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Job Code
        </p>
      ),
      dataIndex: "jobCode",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Job Title
        </p>
      ),
      dataIndex: "title",
      render: (data, render) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.coursorPointer} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Company Name
        </p>
      ),
      dataIndex: "clientName",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Department
        </p>
      ),
      dataIndex: "department",
      render: (data, render) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : "0"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Position Required
        </p>
      ),
      dataIndex: "positionRequired",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : "0"}
          </p>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      render: (text, render, i) => {
        return (
          <>
            {props.candidatesData &&
            props.candidatesData.jobSummary &&
            props.candidatesData.jobSummary.find(
              (data) => data.jobId === render.id
            ) ? (
              <Button
                onClick={() => {
                  setUnlink(render.id);
                  setOpenModal(true);
                }}
                className={commonStyleCss.buttonSettingsPrimary}
                style={{
                  width: "60px",
                  height: "30px",
                }}
                type="primary"
                size="small"
              >
                unlink
              </Button>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col md={24}>
          <Row style={{ gap: "10px", float: "right", marginBottom: "20px" }}>
            <Col>
              <Button
                type="primary"
                onClick={submitData}
                className={commonStyleCss.ButtonForNormalPrimary}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={24}>
          <Table
            pagination={{
              size: "small",
              current:
                props.requirementsFilter &&
                props.requirementsFilter.current_page
                  ? props.requirementsFilter.current_page
                  : pageNo,
              pageSize:
                props.requirementsFilter && props.requirementsFilter.per_page,
              total: props.requirementsFilter && props.requirementsFilter.total,
              showSizeChanger: false,
              onChange: (props) => {
                fetchRecords(props);
              },
            }}
            scroll={{ x: "auto" }}
            style={{ margin: "0px 5px" }}
            className="JobTable"
            columns={columns}
            dataSource={
              props.requirementsFilter &&
              props.requirementsFilter.data &&
              props.requirementsFilter.data
            }
          />
        </Col>
      </Row>
      <Modal
        open={openModal}
        onCancel={closeUnlinkHandler}
        onOk={confirmDeleteHandler}
        title={<p className={commonStyleCss.popUpHeadingText}>Unlink Job</p>}
        footer={
          <>
            <Row style={{ gap: "10px" }} justify="end" align="middle">
              <Col>
                <Button
                  type="default"
                  style={{ height: "48px", fontSize: "16px", width: "151px" }}
                  onClick={() => {
                    closeUnlinkHandler();
                  }}
                  className={commonStyleCss.buttonSettingsSecondary}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  style={{ height: "48px", fontSize: "16px", width: "151px" }}
                  type="primary"
                  className={commonStyleCss.buttonSettingsPrimary}
                  onClick={() => {
                    confirmDeleteHandler();
                  }}
                >
                  Unlink
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Row>
          <Col>
            <p className={commonStyleCss.tableHeadingTextData}>
              Are you sure you want to Unlink Job
            </p>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    requirementsFilter: state.requirements.requirement_filter,
    candidatesData: state.candidates.candidate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRequirementsFilterHandler: (val) => {
      dispatch(getRequirementsFilterAction(val));
    },
    postCandidatesToJobHandler: (val, callback) => {
      dispatch(postUnlinkJobFrCandidateAction(val, callback));
    },
    postAddJobsToCandidateHandler: (val, callback) => {
      dispatch(postAddCandidatesToJobAction(val, callback));
    },
    getCandidateHandler: (id) => {
      dispatch(getCandidateAction(id));
    },
    getCandidateRelatedJobsHandler: (id) => {
      dispatch(getCandidatesJobAction(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateAssgnToJobs);
