import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  jobListingPermissions: [],
  jobListingPermission: null,
};
export const masterJobListingPermissionSlice = createSlice({
  name: "jobListingPermission",
  initialState,
  reducers: {
    getJobListingPermissionsSlice(state, action) {
      state.jobListingPermissions = action.payload.result
    },
    postJobListingPermissionsSlice(state, action) {
      state.jobListingPermissions = [
        ...state.jobListingPermissions,
        action.payload.result,
      ];
    },
    getJobListingPermissionSlice(state, action) {
      state.jobListingPermission = action.payload.result;
    },
    putJobListingPermissionsSlice(state, action) {
      state.jobListingPermissions = state.jobListingPermissions.map((data) => {
        if (data.id === action.payload.result.id) {
          return action.payload.result;
        } else {
          return data;
        }
      });
    },
    deleteJobListingPermissionsSlice(state, action) {
      state.jobListingPermissions = state.jobListingPermissions.filter(
        (data) => data.id !== action.payload.result.id
      );
    },
  },
});
export const {
  getJobListingPermissionsSlice,
  postJobListingPermissionsSlice,
  getJobListingPermissionSlice,
  putJobListingPermissionsSlice,
  deleteJobListingPermissionsSlice,
} = masterJobListingPermissionSlice.actions;
export default masterJobListingPermissionSlice.reducer;
