import { Button, Col, Drawer, Form, Input, Row } from "antd";
import React from "react";
import styles from "../../../Screens/ClientCompany/ClientCompany.module.css";
import commonStyles from "../../../UI/CommonCss.module.css";

//flag import

import "antd-country-phone-input/dist/index.css";
import "flagpack/dist/flagpack.css";

import { CloseIcon } from "../../../IconsComp/Icons";
import PhoneInput from "react-phone-number-input";
//flag import end

const EditContactPersonModal = (props) => {
  const [form] = Form.useForm();

  const onFinish = (val) => {
    var data = {
      ...val,
      id: props.client.id,
    };

    props.onSubmit(data);
  };
  return (
    <>
      <Drawer
        className={"modalModified"}
        closable={false}
        extra={
          <div
            onClick={() => {
              form.resetFields();
              props.onCloseEditContactPersonModal();
            }}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
          </div>
        }
        footer={false}
        style={{
          paddingBottom: "0px",
        }}
        title={
          <p
            style={{ fontSize: "20px", margin: "0px" }}
            className={`${commonStyles.modalHeading}`}
          >
            {!props.client ? "Add Company" : "Edit Contact Person Details"}
          </p>
        }
        width={726}
        height={48}
        open={props.onOpenEditContactPersonModal}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          form={form}
          initialValues={
            props.client && {
              contactPerson: props.client.contactPerson,
              contactPersonPhone: props.client.contactPersonPhone,
              contactPersonEmail: props.client.contactPersonEmail,
              contactPersonDesignation: props.client.contactPersonDesignation,
            }
          }
          layout="vertical"
          onFinish={onFinish}
        >
          <Row
            className={styles.modalRowStyling}
            style={{ padding: "0px 20px 80px 20px" }}
          >
            <Col flex={1}>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Contact Person Name"
                    name="contactPerson"
                    rules={[
                      {
                        required: true,
                        message: "Please enter contact person name",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                      //   message:"It allows only alphabets, no whitespace at the beginning and end",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter contact person name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Contact Person Email"
                    name="contactPersonEmail"
                    rules={[
                      {
                        required: true,
                        message: "Please enter contact person email",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email",
                      },
                    ]}
                  >
                    <Input placeholder="Enter contact person email" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    name="contactPersonPhone"
                    label="Contact Person Number"
                    type="number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter phone number",
                      },
                    ]}
                  >
                    <PhoneInput
                      placeholder="Enter contact number"
                      className="ant-input ant-cust-inputs"
                      defaultCountry="IN"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ marginTop: "3px" }}>
                <Col flex={1}>
                  <Form.Item
                    label="Designation"
                    name="contactPersonDesignation"
                    rules={[
                      {
                        required: true,
                        message: "Please enter designation",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                      //   message:
                      //     "It allows only Alphabets, no whitespace at the beginning and end",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter contact person’s designation" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Col
            style={{ width: "726px" }}
            xs={24}
            md={24}
            className={commonStyles.footerShadow}
          >
            <Row justify="end" style={{ gap: "19px" }}>
              <Col>
                <Button
                  onClick={() => {
                    props.onCloseEditContactPersonModal();
                    form.resetFields();
                  }}
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                  className={commonStyles.buttonSettingsSecondary}
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button
                  onClick={() => {
                    form.submit();
                  }}
                  htmlType="button"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  {!props.client ? "Add Company" : "Save"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
};

export default EditContactPersonModal;
