import React, { useEffect } from "react";
import { Button, Card, Col, Form, Input, Row, Layout } from "antd";
import Lock from "../../../Assets/Image/Lock.png";
import logo from "../../../Assets/Image/recruitintelli.png";

import style from "../../../Components/Screens/Login/login.module.css";
import {
  resetPasswordAction,
  verifyLinkAction,
} from "../../../Reusable/Store/Action/AuthenticationAction";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import commonCssStyle from "../../UI/CommonCss.module.css";
import { Link } from "react-router-dom";
import { HeartFilled } from "@ant-design/icons";

const { Content } = Layout;
function ConfirmPassword({
  verifyLinkAction,
  isverify,
  resetPasswordAction,
  code,
  email,
  setState,
}) {
  let navigate = useNavigate();
  useEffect(() => {
    verifyLinkAction({ code: code, email: email });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <Content>
        <Row>
          <Col md={10} sm={0} xs={0} className={style.col}>
            <Card className={style.card}>
              <img className={style.logo} alt="example" src={logo} />
              <p className={style.pmadewith}>
                Made with <HeartFilled style={{ color: "#F83A3A" }} /> in india
              </p>
              <p className={style.pintell}>Powered by Recruitintell </p>
            </Card>
          </Col>
          <Col md={14} sm={24} xs={24} className={style.formcol}>
            <Card className={style.formCard}>
              {!isverify ? (
                <>
                  <p
                    className={`${commonCssStyle.marginBottomRemove}`}
                    style={{
                      color: "var(--body-text-color)",
                      textAlign: "center",
                      fontSize: "24px",
                    }}
                  >
                    Token has expired
                  </p>
                  <Link to="/">
                    <p
                      className={`${commonCssStyle.marginBottomRemove}`}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Please navigate to login page
                    </p>
                  </Link>
                </>
              ) : (
                <Form
                  onFinish={(value) =>
                    resetPasswordAction(
                      {
                        email: email,
                        password: value.confirmpassword,
                      },
                      navigate,
                      setState
                    )
                  }
                >
                  <p className={style.p1}>Create New Password </p>

                  <Row>
                    <Col md={2} xs={2}>
                      <img
                        alt="example"
                        className={style.inputIcons}
                        src={Lock}
                      />
                    </Col>
                    <Col xs={1}></Col>
                    <Col md={21} xs={21}>
                      {" "}
                      <Form.Item
                        name="newpassword"
                        rules={[
                          { required: true, message: " Password Required" },
                          // {
                          //   pattern:
                          //     /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[!\\@#$%^&._"'()+]){1,})(?!.*\s).{8,}$/,
                          //   message:
                          //     "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character!",
                          // },
                        ]}
                      >
                        <Input.Password
                          className={style.input}
                          name="newpassword"
                          type="password"
                          placeholder="Password"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2} xs={2}>
                      <img
                        alt="example"
                        className={style.inputIcons}
                        src={Lock}
                      />
                    </Col>
                    <Col xs={1}></Col>
                    <Col md={21} xs={21}>
                      {" "}
                      <Form.Item
                        name="confirmpassword"
                        dependencies={["newpassword"]}
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (
                                !value ||
                                getFieldValue("newpassword") === value
                              ) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                "The second passwords that you entered do not match!"
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          className={style.input}
                          name="confirmpassword"
                          type="password"
                          placeholder="Confirm Password*"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Button
                        htmlType="submit"
                        disabled={!isverify}
                        className={style.submit}
                      >
                        Reset Password
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
const mapStateToProps = (state) => {
  return {
    isverify: state.authentication.isverify,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    verifyLinkAction: (val) => {
      dispatch(verifyLinkAction(val));
    },
    resetPasswordAction: (val, navigate, setState) => {
      dispatch(resetPasswordAction(val, navigate, setState));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPassword);
