import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recruitment: [],
  recruitment_filter: [],
  interview_details: [],
  candidateAssignedRequirement: [],
  import_candidates_List_data: [],
  import_candidates_Filter_Data: [],
  recruitment_job_status: [],
  get_all_status: [],
  recruitment_call_logs: [],
  recruitment_candidates_suggestion: [],
  getInterviewFeedback: [],
};

export const RecruitmentSlice = createSlice({
  name: "recruitment",
  initialState,
  reducers: {
    recruitment_FilterSlice(state, action) {
      state.recruitment_filter = action.payload.result;
    },
    getCandidateAssignedToRecruitmentByIdSlice(state, action) {
      state.candidateAssignedRequirement = action.payload.result;
    },
    getInterviewSlice(state, action) {},
    getInterviewDetailsSlice(state, action) {
      state.interview_details = action.payload.result;
    },
    //import candidates slice
    getImportCandidatesSlice(state, action) {
      state.import_candidates_List_data = action.payload.result;
    },
    recruitment_All_Candidates_FilterSlice(state, action) {
      state.import_candidates_Filter_Data = action.payload.result;
    },
    getRecruitmentsCandidatesJobStatusSlice(state, action) {
      state.recruitment_job_status = action.payload.result;
    },
    getRecruitmentAllStatusSlice(state, action) {
      state.get_all_status = action.payload.result;
    },
    getRecruitmentCallLogSlice(state, action) {
      state.recruitment_call_logs = action.payload.result;
    },
    postInterviewSlice(state, action) {},
    putInterviewSlice(state, action) {
      state.interview_details = state.interview_details.map((data) => {
        if (data.id === action.payload.result.id) {
          return action.payload.result;
        } else {
          return data;
        }
      });
    },
    recruitment_Suggested_Candidates_FilterSlice(state, action) {
      state.recruitment_candidates_suggestion = action.payload.result;
    },

    //get interview feedback
    getInterviewFeedbackSlice(state, action) {
      state.getInterviewFeedback = action.payload.result;
    },
  },
});
export const {
  recruitment_FilterSlice,
  getInterviewSlice,
  postInterviewSlice,
  getInterviewDetailsSlice,
  getCandidateAssignedToRecruitmentByIdSlice,
  putInterviewSlice,
  getImportCandidatesSlice,
  recruitment_All_Candidates_FilterSlice,
  getRecruitmentsCandidatesJobStatusSlice,
  getRecruitmentAllStatusSlice,
  getRecruitmentCallLogSlice,
  recruitment_Suggested_Candidates_FilterSlice,
  getInterviewFeedbackSlice,
} = RecruitmentSlice.actions;
export default RecruitmentSlice.reducer;
