import { getCountriesSlice,getCitySlice, getStateSlice} from "../../Slice/Master/MasterCountrySlice";
import { COUNTRY_URL } from "../../masterpath";
import { message } from "antd";
import AxiosApi from "../../api";
const api = new AxiosApi();
export const getCountriesAction = (values,callback) => {
  return (dispatch) => {
    api.invoke(COUNTRY_URL+"/" + values.type + "/"+ values.id, "get", (data, success, statusCode) => {

      callback([],undefined)
      if (success) {
        if (statusCode === 200) 
        {
          callback(data.result,values.type)
      
          // if(values.type === "country"){
          //   dispatch(getCountriesSlice(data));
          // }else if(values.type ==="state"){
          //   dispatch(getStateSlice(data));
          // }else if(values.type === "city"){
          //   dispatch(getCitySlice(data));
          // }
        }
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
