import { Col, Row, Tabs } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  BrieftCaseIcon,
  CalendarBlankIcon,
  CalendarCheckIcon,
} from "../../IconsComp/Icons";
import commonCssStyle from "../../UI/CommonCss.module.css";
import TaskInterviewPage from "./TaskInterviewPage";
function TaskDetails(props) {
  const [key, setKey] = useState("1");
  const itemsData = [
    {
      key: "1",
      label: (
        <Row align="middle" style={{ gap: "10px" }}>
          <Col>
            <BrieftCaseIcon
              color={
                key === "1"
                  ? "var(--menu-text-color)"
                  : "var(--body-text-color)"
              }
            />
          </Col>
          <Col>
            <p
              className={commonCssStyle.tabsText}
              style={{
                margin: "0px",
                color:
                  key === "1"
                    ? "var(--menu-text-color)"
                    : "var(--body-text-color)",
              }}
            >
              Interview
            </p>
          </Col>
        </Row>
      ),
      children: <TaskInterviewPage />,
    },
  ];
  return (
    <>
      <Tabs
        style={{ width: "100%" }}
        className="taskCalendarTabs"
        defaultActiveKey="1"
        onChange={(key) => setKey(key)}
        items={itemsData}
      />
    </>
  );
}
export default TaskDetails;
