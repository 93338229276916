import {
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  List,
  Menu,
  Rate,
  Row,
  Space,
  Typography,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { connect } from "react-redux";
import { exportInterviewFeedbackAction } from "../../../../Reusable/Store/Action/TaskAndCalendarAction";
import { CaretBlackDownIcon, PlusIcon } from "../../../IconsComp/Icons";
import CommonCssStyle from "../../../UI/CommonCss.module.css";
function InterviewFeedback(props) {
  const [interviewIdArray, setInterviewIdArray] = useState([]);
  const dateFormat =
    props.settings && props.settings.dateFormat
      ? props.settings.dateFormat
      : "DD/MM/YYYY";
  const menu2 = (
    <Menu
      style={{ width: "151px", margin: "0px 45px" }}
      selectable
      defaultSelectedKeys={["1"]}
      items={[
        {
          key: "3",
          label: (
            <p
              className={`${CommonCssStyle.externalJobTextrole}`}
              style={{ marginTop: "7px" }}
              onClick={() => {
                props.exportInterviewFeedbackHandler(
                  {
                    interviewId: interviewIdArray,
                    exportType: "excel",
                  },
                  (success) => {
                    if (success) {
                      setInterviewIdArray([]);
                    }
                  }
                );
              }}
            >
              Export to Excel
            </p>
          ),
        },
        {
          key: "4",
          label: (
            <p
              style={{ marginTop: "7px" }}
              className={`${CommonCssStyle.externalJobTextrole}`}
              onClick={() => {
                props.exportInterviewFeedbackHandler(
                  {
                    interviewId: interviewIdArray,
                    exportType: "pdf",
                  },
                  (success) => {
                    if (success) {
                      setInterviewIdArray([]);
                    }
                  }
                );
              }}
            >
              Export to PDF
            </p>
          ),
        },
      ]}
    />
  );
  return (
    <Card style={{ padding: "20px" }}>
      {props.getInterviewFeedbackction &&
        props.getInterviewFeedbackction.length > 0 && (
          <Row justify="end">
            <Col>
              <Dropdown placement="bottom" overlay={menu2}>
                <Typography.Link>
                  <Space className={CommonCssStyle.tableHeadingTextData}>
                    <Button
                      style={{ width: "151px" }}
                      className={CommonCssStyle.intaddButton}
                      type="primary"
                    >
                      <Row align="middle" justify="space-around">
                        <Col>
                          <PlusIcon color={"white"} />
                        </Col>
                        <Col>Export</Col>
                        <Col>
                          <CaretBlackDownIcon color={"white"} />
                        </Col>
                      </Row>
                    </Button>
                  </Space>
                </Typography.Link>
              </Dropdown>
            </Col>
          </Row>
        )}
      {props.getInterviewFeedbackction &&
      props.getInterviewFeedbackction.length > 0 ? (
        props.getInterviewFeedbackction.map((x, index) => {
          return (
            <>
              <Row
                style={{
                  marginTop: "30px",
                  //   border: "0.1px solid lightgray",
                  //   borderRadius: "8px",
                }}
                gutter={[0, 16]}
                justify="space-between"
              >
                <Col xs={24} md={11}>
                  <List
                    style={{ height: "100%" }}
                    key={index}
                    header={
                      <>
                        <Checkbox
                          checked={interviewIdArray.find((d) => d === x.id)}
                          onClick={(val) => {
                            if (val.target.checked) {
                              setInterviewIdArray((data) => [...data, x.id]);
                            } else {
                              let i = interviewIdArray.indexOf(x.id);
                              if (i !== -1) {
                                setInterviewIdArray(
                                  interviewIdArray.filter(
                                    (data) => data !== x.id
                                  )
                                );
                              }
                            }
                          }}
                        >
                          <p
                            className={`${CommonCssStyle.marginBottomRemove} ${CommonCssStyle.subHeadingText}`}
                          >
                            {/* {`Interview (${moment(x.interview).format(
                          dateFormat
                        )})`} */}
                            {`Interview (${moment(x.interviewDate).format(
                              dateFormat
                            )})`}
                          </p>
                        </Checkbox>
                      </>
                    }
                    footer={false}
                    bordered
                  >
                    <List.Item>
                      <p
                        className={`${CommonCssStyle.marginBottomRemove} ${CommonCssStyle.subHeadingTextNormal}`}
                      >
                        Interview Details
                      </p>
                    </List.Item>
                    <List.Item>
                      <p
                        className={`${CommonCssStyle.marginBottomRemove} ${CommonCssStyle.subHeadingTextNormal}`}
                      >{`With interviewer : ${
                        x.withInterview ? x.withInterview : "N/A"
                      } `}</p>
                    </List.Item>
                    <List.Item>
                      <p
                        className={`${CommonCssStyle.marginBottomRemove} ${CommonCssStyle.subHeadingTextNormal}`}
                      >{`Interview mode : ${x.interviewType}`}</p>
                    </List.Item>
                    <List.Item>
                      <p
                        className={`${CommonCssStyle.marginBottomRemove} ${CommonCssStyle.subHeadingTextNormal}`}
                      >{`Overall feedback or Interview notes : ${
                        x.comment ? x.comment : "N/A"
                      }`}</p>
                    </List.Item>
                  </List>
                </Col>
                <Col xs={24} md={11}>
                  <List
                    style={{ height: "100%" }}
                    header={
                      <p
                        className={`${CommonCssStyle.marginBottomRemove} ${CommonCssStyle.subHeadingText}`}
                      >
                        Ratings
                      </p>
                    }
                    bordered
                    dataSource={x.performanceRating}
                    renderItem={(item) => (
                      <>
                        <List.Item>
                          <p
                            className={`${CommonCssStyle.marginBottomRemove} ${CommonCssStyle.subHeadingTextNormal}`}
                          >
                            {item.question}
                            <br />
                            <br />
                            <Rate
                              disabled
                              style={{ color: "var(--primaryColor)" }}
                              value={item.rating}
                            />
                          </p>
                        </List.Item>
                        {/* <List.Item>
                  <Rate
                    value={item.rating}
                    />
                  </List.Item> */}
                      </>
                    )}
                    footer={false}
                  />
                </Col>
              </Row>
            </>
          );
        })
      ) : (
        <>
          <h3
            style={{ color: "var(--body-text-color)", textAlign: "center" }}
            className={`${CommonCssStyle.marginBottomRemove}`}
          >
            No data
          </h3>
        </>
      )}
    </Card>
  );
}
const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    exportInterviewFeedbackHandler: (id, callback) => {
      dispatch(exportInterviewFeedbackAction(id, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InterviewFeedback);
