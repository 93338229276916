import {
  Drawer,
  Form,
  Input,
  Button,
  Tag,
  Select,
  InputNumber,
  Switch,
  Row,
  Col,
  DatePicker,
} from "antd";

import { CloseIcon, FilterOutlinedIcon } from "../../../IconsComp/Icons";
import commonStyles from "../../../UI/CommonCss.module.css";
import styles from "../../../Screens/ClientCompany/ClientCompany.module.css";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
  getCandidatesAction,
  getCandidatesFilterAction,
} from "../../../../Reusable/Store/Action/CandidatesAction";
import { getReportsCandidatesAction } from "../../../../Reusable/Store/Action/ReportsAction";
import moment from "moment";
import {
  getTagAction,
  getTagsAction,
} from "../../../../Reusable/Store/Action/Master/MasterTagsAction";
import ResetButton from "../../../UI/ResetButton";
import { getCandidatesSummaryReportsAction } from "../../../../Reusable/Store/Action/ReportsAction";

const { Option } = Select;

const tagRenderForMobileNum = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

const tagRenderForSkills = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

const tagRender = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

function ReportsCandidateTableFilter(props) {
  const [filterDatas, setFilterDatas] = useState({
    name: "",
    email: "",
    phone: "",
    skills: "",
  });

  const [restBtnLocation, setRestBtnLocation] = useState(false);
  const [restBtnExperience, setRestBtnExperience] = useState(false);
  const [restBtnSalary, setRestBtnSalary] = useState(false);
  const [restBtnAge, setRestBtnAge] = useState(false);

  const dateFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const [form] = Form.useForm();

  const resetFilter = () => {
    // form.resetFields();
    const data = {
      type: "list",
      current_page: 1,
      dateFrom: "1970-01-01",
      dateTo: "3000-01-01",
    };
    props.candidateReportFilter(data);
    form.setFieldsValue({
      jobStatus: undefined,
      leadSource: undefined,
      name: undefined,
      email: undefined,
      phone: undefined,
      skills: undefined,
      location: undefined,
      appliedDate: undefined,
    });
    // form.resetFields();
    props.onCloseFilter();
  };

  const [checkedSpecialAbilities, setCheckedSpecialAbilities] = useState(false);

  useEffect(() => {
    props.getCandidateDetailsListHandler({});
    props.getTagHandler();
    props.getReportsCandidateSummaryHandler();
  }, []);

  const onFinish = (values) => {
    const data = {
      name: values.name && values.name.length > 0 ? values.name : "",
      email: values.email && values.email.length > 0 ? values.email : "",
      phone: values.phone && values.phone.length > 0 ? values.phone : "",
      skills: values.skills && values.skills.length > 0 ? values.skills : "",
      location: values.location ? values.location : "",
      // tags: values.tags ? values.tags : "",
      experience: values.experience ? values.experience : "",
      appliedDate: values.appliedDate
        ? moment(values.appliedDate).format("YYYY-MM-DD")
        : "",
      salary: values.salary ? values.salary : "",
      specialAbility: checkedSpecialAbilities ? "1" : "",
      age: values.age ? values.age : "",
      leadSource: values.leadSource ? values.leadSource : "",
      jobStatus: values.jobStatus ? values.jobStatus : "",
      dateTo: props.toDate,
      dateFrom: props.fromDate,
    };

    if (
      data.email &&
      data.phone &&
      data.skills &&
      data.location &&
      // data.tags &&
      data.experience &&
      data.appliedDate &&
      data.salary &&
      data.specialAbility &&
      data.age &&
      data.leadSource &&
      data.jobStatus === ""
    ) {
      props.candidateReportFilter({
        type: "list",
        current_page: 1,
      });
      props.onCloseFilter();
    } else {
      props.candidateReportFilter({
        type: "list",
        current_page: 1,
        ...data,
      });
      props.onCloseFilter();
    }
  };

  return (
    <>
      <Drawer
        className="modalModified"
        closable={false}
        extra={
          <div
            onClick={() => props.onCloseFilter()}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
          </div>
        }
        width={503}
        height={48}
        title={
          <>
            <Row>
              <Col>
                <FilterOutlinedIcon color={"var(--primaryColor)"} />
              </Col>
              <Col>
                <p
                  style={{ margin: "0px 0px 0px 5px" }}
                  className={commonStyles.filterHeadingName}
                >
                  Filter
                </p>
              </Col>
            </Row>{" "}
          </>
        }
        // onClose={props.onCloseFilter}
        open={props.onOpenFilter}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          initialValues={{
            leadSource:
              props.initialvalues && props.initialvalues.leadSource
                ? props.initialvalues.leadSource
                : "",
            jobStatus:
              props.initialvalues && props.initialvalues.from
                ? props.initialvalues.from
                : "",
          }}
        >
          <Row style={{ padding: "0px 20px 80px 20px" }}>
            <Col md={24}>
              <Form.Item label="Lead Source" name="leadSource">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  allowClear
                  placeholder="Select lead source"
                >
                  {props.ReportsCandidateSummary &&
                    props.ReportsCandidateSummary.leadSummary &&
                    props.ReportsCandidateSummary.leadSummary.map((data) => {
                      return (
                        <Select.Option value={data.source}>
                          {data.source}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Job Status" name="jobStatus">
                <Select allowClear placeholder="Select job status">
                  <Select.Option value="active">Active</Select.Option>
                  <Select.Option value="applied">Applied</Select.Option>
                  <Select.Option value="screening">Screening</Select.Option>
                  <Select.Option value="interviewed">Interviewed</Select.Option>
                  <Select.Option value="offered">Offered</Select.Option>
                  <Select.Option value="hired">Hired</Select.Option>
                  <Select.Option value="offerDeclined">
                    Offer Declined
                  </Select.Option>
                  <Select.Option value="candidateDeclined">
                    Candidate Declined
                  </Select.Option>
                  <Select.Option value="rejected">Rejected</Select.Option>
                  <Select.Option value="absconded">Absconded</Select.Option>
                  <Select.Option value="blacklisted">Blacklisted</Select.Option>
                  <Select.Option value="quit">Quit</Select.Option>

                  <Select.Option value="notInConsideration">
                    Not in Consideration
                  </Select.Option>
                  <Select.Option value="deleted">Deleted</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item label="Name" name="name">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  onKeyUp={(val) => {
                    setFilterDatas({
                      name: val.target.value,
                    });
                  }}
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // className={"candidateSelectHeight"}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select name"
                  tagRender={tagRenderForMobileNum}
                >
                  {filterDatas.name &&
                    filterDatas.name !== " " &&
                    filterDatas.name.length > 0 &&
                    props.candidates.map((data) => {
                      return (
                        <Select.Option key={data.id} value={data.name}>
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Email" name="email">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  onKeyUp={(val) => {
                    setFilterDatas({
                      email: val.target.value,
                    });
                  }}
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // className={"candidateSelectHeight"}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select email"
                  tagRender={tagRenderForMobileNum}
                >
                  {filterDatas.email &&
                    filterDatas.email !== " " &&
                    filterDatas.email.length > 0 &&
                    props.candidates.map((data) => {
                      return (
                        <Select.Option key={data.id} value={data.email}>
                          {data.email}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Mobile Number" name="phone">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  // className={"candidateSelectHeight"}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  onKeyUp={(val) => {
                    setFilterDatas({ phone: val.target.value });
                  }}
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select mobile number"
                  tagRender={tagRenderForMobileNum}
                >
                  {filterDatas.phone &&
                    filterDatas.phone !== " " &&
                    filterDatas.phone.length > 0 &&
                    props.candidates.map((data) => {
                      return (
                        <Select.Option key={data.id} value={data.phone}>
                          {data.phone}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              {/* <Form.Item label="Tags" name="tags">
                  <Select
                    className={"candidateSelectHeight"}
                    mode="multiple"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select tag"
                    tagRender={tagRender}
                  >
                    {props.TagsData &&
                      props.TagsData.filter(
                        (data) => data.type === "candidate"
                      ).map((data) => {
                        return (
                          <Option key={data.id} value={data.tag}>
                            {data.tag}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item> */}

              <Form.Item label="Key Skills/Tag" name="skills">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  // className={"candidateSelectHeight"}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  onKeyUp={(val) => {
                    setFilterDatas({ skills: val.target.value });
                  }}
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select skills"
                  tagRender={tagRenderForSkills}
                >
                  {filterDatas.skills &&
                    filterDatas.skills !== " " &&
                    filterDatas.skills.length > 0 &&
                    props.TagsData &&
                    props.TagsData.filter(
                      (data) => data.type === "candidate"
                    ).map((data) => {
                      return (
                        <Option key={data.id} value={data.tag}>
                          {data.tag}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Location" name="location">
                <Input
                  onChange={() => {
                    if (!restBtnLocation) {
                      setRestBtnLocation(true);
                    }
                  }}
                  // className={"CandidateInputHeight"}
                  placeholder="Enter location"
                />
              </Form.Item>

              {restBtnLocation && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      location: "",
                    });
                    setRestBtnLocation(false);
                  }}
                />
              )}

              {/* <Form.Item label="Experience" name="experience">
                <Input
                  onChange={() => {
                    if (!restBtnExperience) {
                      setRestBtnExperience(true);
                    }
                  }}
                  // className={"CandidateInputHeight"}
                  placeholder="Enter experience"
                />
              </Form.Item> */}

              {restBtnExperience && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      experience: "",
                    });
                    setRestBtnExperience(false);
                  }}
                />
              )}

              <Form.Item label="Applied Date" name="appliedDate">
                <DatePicker
                  placeholder="Select date"
                  format={dateFormate}
                  style={{ width: "100%" }}
                  className={`${commonStyles.datePickerBgColor} `}
                />
              </Form.Item>

              <Row align="middle" style={{ gap: "17px" }}>
                <Col>
                  <p className={`${commonStyles.formInputLabel} `}>
                    Special abilities
                  </p>
                </Col>
                <Col>
                  <Form.Item name="specialAbility">
                    <Switch
                      onChange={(val) => {
                        setCheckedSpecialAbilities(val);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {restBtnAge && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      age: "",
                    });
                    setRestBtnAge(false);
                  }}
                />
              )}
            </Col>
          </Row>

          <Col className={commonStyles.footerShadow} style={{ width: "503px" }}>
            <Row justify="end" style={{ gap: "25px" }}>
              <Col>
                <Button
                  className={commonStyles.buttonSettingsSecondary}
                  type="default"
                  onClick={() => {
                    setRestBtnLocation(false);
                    setRestBtnExperience(false);
                    setRestBtnSalary(false);
                    setRestBtnAge(false);
                    props.onClearFilter();
                    setFilterDatas({
                      email: "",
                      phone: "",
                      skills: "",
                      jobStatus: null,
                      leadSource: null,
                    });
                    resetFilter();
                    // props.onCloseFilter();
                  }}
                >
                  Reset Filter
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ReportsCandidateSummary: state.reports.reportsCandidateSummary,
    candidates: state.candidates.candidates,
    candidatesFilterData: state.candidates.candidatesFilter,
    TagsData: state.tags.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCandidateDetailsListHandler: () => {
      dispatch(getCandidatesAction());
    },
    getCandidateFilterHandler: (val) => {
      dispatch(getCandidatesFilterAction(val));
    },
    getTagHandler: () => {
      dispatch(getTagsAction());
    },
    getReportsCandidateSummaryHandler: () => {
      dispatch(getCandidatesSummaryReportsAction());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsCandidateTableFilter);
