// import Modal from "antd/lib/modal/Modal";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { ClockIcon, PaperPlane, CloseIcon } from "../../../IconsComp/Icons";
import styles from "../Application/Application.module.css";

import ReactQuill from "react-quill";
import "../../../../../node_modules/react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";
import "../../../../App.less";
import { useState } from "react";
import {
  postEmailTemplateAction,
  putEmailTemplatesAction,
} from "../../../../Reusable/Store/Action/Master/MasterEmailTemplateAction";
import { connect } from "react-redux";
import commonStyles from "../../../UI/CommonCss.module.css";
function AddTemplateModal(props) {
  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const modules = {
    toolbar: [
      ["bold"],
      ["italic"],
      ["underline"],
      [{ list: "bullet" }],
      [{ list: "ordered" }],
      [{ align: [] }],
      ["link"],
      // ["image"],
      // ["video"],
      ["code-block"],
    ],
  };

  function updateHandler(val) {
    if (props.modalNameHandler === "add") {
      props.postEmailTemplateHandler({ ...val, status: 1 }, (success) => {
        if (success) {
          form.resetFields();
          props.onOk();
        }
      });
    } else {
      const data = {
        id: props.propsETIdHandler.id,
        status: props.propsETIdHandler.status,
        ...val,
      };
      props.putEmailTemplateHandler(data, (success) => {
        if (success) {
          form.resetFields();
          props.onOk(data.id);
        }
      });
    }
  }

  return (
    <>
      <Modal
        closable={false}
        extra={
          <div
            onClick={() => {
              form.resetFields();
              props.onCancel();
            }}
          >
            <ClockIcon color={"var(--body-text-color)"} />
          </div>
        }
        title={
          <Row style={{ gap: "580px" }}>
            <Col>
              {" "}
              <p
                className={`${commonStyles.marginBottomRemove} ${commonStyles.popUpHeadingText}`}
              >
                {props.propsETIdHandler && props.modalNameHandler
                  ? "Edit Email Template"
                  : "Add Email Template"}
              </p>
            </Col>
            <Col>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  props.onCancel();
                }}
                className={`${commonStyles.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
        width={840}
        open={props.onOpen}
        // onCancel={props.onCancel}
        className={`${styles.modalStyle} emailModal modalModifiedEmail`}
        footer={false}
      >
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={(val) => updateHandler(val)}
          initialValues={{
            title: props.propsETIdHandler && props.propsETIdHandler.title,
            notificationType:
              props.propsETIdHandler && props.propsETIdHandler.notificationType,
            subject: props.propsETIdHandler && props.propsETIdHandler.subject,
            cc: props.propsETIdHandler && props.propsETIdHandler.cc,
            content: props.propsETIdHandler && props.propsETIdHandler.content,
          }}
        >
          <Row className={styles.drawerRowStyling}>
            <Col flex={1}>
              <Row>
                <Col className="modelcol" md={24}>
                  <Form.Item
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please select name",
                      },
                    ]}
                    label="Name"
                  >
                    <Input className="emailTempInptCss" placeholder="" />
                  </Form.Item>
                </Col>
              </Row>

             


              

              {props.propsETIdHandler && props.modalNameHandler? 
                (<Row>
                <Col md={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please select notification type",
                      },
                    ]}
                    name="notificationType"
                    label="NotificationType"
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </Row>)
              :
 (<Row>
 <Col md={24}>
   <Form.Item
     rules={[
       {
         required: true,
         message: "Please select notification type",
       },
     ]}
     name="notificationType"
     label="NotificationType"
   >
     <Select
     
       getPopupContainer={(trigger) => trigger.parentElement}
       className="emailTempleteInput"
     >
       {props.emailNotificationData.map((data) => {
         return (
           <Select.Option
             key={data.id}
             value={data.notificationType}
           >
             {data.notificationType}
           </Select.Option>
         );
       })}
     </Select>
   </Form.Item>
 </Col>
</Row>)}
              <Row>
                <Col className="modelcol" md={24}>
                  <Form.Item
                    name="subject"
                    rules={[
                      {
                        required: true,
                        message: "Please select subject",
                      },
                    ]}
                    label="Subject"
                  >
                    <Input className="emailTempInptCss" placeholder="" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="modelcol" md={24}>
                  <Form.Item
                    name="cc"
                    rules={[
                      {
                        required: false,
                        message: "CC Required",
                      },
                    ]}
                    label="CC"
                  >
                    <Input
                      className="emailTempInptCss"
                      placeholder="Please enter comma separated email ids. e.g. matt@example.com , joe@sample.com"
                    />
                  </Form.Item>
                </Col>
              </Row>

            {props.propsETIdHandler && props.modalNameHandler? 
            ( <Form.Item
              style={{
                marginBottom:"-10px",
              }}
               name="subject"
               label="Tags can be used"
              >
                
              <p
              className={`
               ${commonStyles.lightText}`}

              >{props.propsETIdHandler&&props.propsETIdHandler.parameter}</p>
              
              </Form.Item>)
              :""}
              

              <Row>
                <Col md={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please select content",
                      },
                    ]}
                    name="content"
                  >
                    <ReactQuill
                      className="emialTemplete"
                      theme="snow"
                      modules={modules}
                      value={value}
                      onChange={setValue}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col xs={24} md={24} className={commonStyles.footerShadowForTemplete}>
            <Row
              align="middle"
              justify="end"
              style={{
                position: "relative",
                right: "10px",
                gap: "10px",
              }}
            >
              <Col>
                <Button
                  onClick={() => {
                    // props.onOk();
                    form.submit();
                  }}
                  className={commonStyles.buttonSettingsPrimary}
                  htmlType="button"
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                  type="primary"
                >
                  <Row align="middle" justify="space-around">
                    <Col style={{ transform: "translateY(3px)" }}>
                      <PaperPlane color={"var(--theme)"} />
                    </Col>
                    <Col className={styles.paperBtnNameTemp}>Save</Col>
                  </Row>
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    emailTemplates: state.emailTemplates.emailTemplates,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postEmailTemplateHandler: (val, callback) => {
      dispatch(postEmailTemplateAction(val, callback));
    },
    putEmailTemplateHandler: (data, callback) => {
      dispatch(putEmailTemplatesAction(data, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddTemplateModal);
