import {
  getInterviewAssissmentQuestionsSlice,
  getInterviewAssissmentQuestionSlice,
  postInterviewAssissmentQuestionsSlice,
  putInterviewAssissmentQuestionsSlice,
  deleteInterviewAssissmentQuestionsSlice,
} from "../../Slice/Master/MasterInterviewAssessmentQuestionsSlice";
import { INTERVIEW_ASSESSMENT_QUESTIONS_URL } from "../../masterpath";
import { message } from "antd";
import AxiosApi from "../../api";
const api = new AxiosApi();
export const getInterviewAssissmentQuestionsAction = () => {
  return (dispatch) => {
    api.invoke(
      INTERVIEW_ASSESSMENT_QUESTIONS_URL,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200)
            dispatch(getInterviewAssissmentQuestionsSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const getInterviewAssissmentQuestionAction = (id) => {
  return (dispatch) => {
    api.invoke(
      INTERVIEW_ASSESSMENT_QUESTIONS_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200)
            dispatch(getInterviewAssissmentQuestionSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postInterviewAssissmentQuestionsAction = (data) => {
  return (dispatch) => {
    api.invoke(
      INTERVIEW_ASSESSMENT_QUESTIONS_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200)
          {
            message.destroy();
            message.success(data.message, 3);
            dispatch(postInterviewAssissmentQuestionsSlice(data));
            }  else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const putInterviewAssissmentQuestionsAction = (data) => {
  return (dispatch) => {
    api.invoke(
      INTERVIEW_ASSESSMENT_QUESTIONS_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200){message.destroy();
            message.success(data.message, 3);
            dispatch(putInterviewAssissmentQuestionsSlice(data));
           } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteInterviewAssissmentQuestionsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      INTERVIEW_ASSESSMENT_QUESTIONS_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200){message.destroy();
            message.success(data.message, 3);
            dispatch(deleteInterviewAssissmentQuestionsSlice(id));}
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
