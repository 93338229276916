import {
  Button,
  Card,
  Col,
  Image,
  message,
  Modal,
  Progress,
  Row,
  Table,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BackButtonIcon, CloseIcon } from "../../IconsComp/Icons";
import commonCssStyle from "../../UI/CommonCss.module.css";
import {
  postRecruitmentImportCanidateAction,
  getImportCandidatesAction,
  getCandidatesExcelFormatAction,
  getRecruitmentDeleteImportCandidateData,
  getRecruitmentDeleteAllImportedCandidatesData,
  postAssignedEmployeeAction,
} from "../../../Reusable/Store/Action/RecruitmentAction";

import word from "../../../Assets/Image/word.png";
import pdf from "../../../Assets/Image/pdf.png";
import excel from "../../../Assets/Image/excel.png";

import { useRef } from "react";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import BreadcrumbForRequirement from "../Requirement/BreadcrumbForRequirement";
import BreadcrumbForRecruitment from "./BreadcrumbForRecruitment";

function ImportCandidate(props) {
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSelected, setDataSelected] = useState([]);
  const [fileData, setFileData] = useState("");
  const [openDeleteModal, SetOpenDeleteModal] = useState(false);
  const [deletId, setDeleteId] = useState("");
  const [filesData, setFilesData] = useState("");
  const [progress, setProgress] = useState(0);
  const [fileResult, setFileResult] = useState(false);
  const [deleteAllModal, setDeleteAllModal] = useState(false);

  let btnref = useRef(null);

  const location = useLocation();

  useEffect(() => {
    let dataType = "candidate";
    props.getImportCandidatesHandler();
  }, []);

  // const files = {
  //   onRemove: (file) => {
  //     const index = fileList.indexOf(file);
  //     const newFileList = fileList.slice();
  //     newFileList.splice(index, 1);
  //     setFileList(newFileList);
  //   },
  //   beforeUpload: (file) => {
  //     setFileList([...fileList, file]);
  //     setFileData(file);
  //     return false;
  //   },
  //   fileList,
  // };

  const handleChange = (info) => {
    setFileResult(false);

    if (!(info.target.files[0].size <= 2000000)) {
      message.warning("This file should be less than 2 MB", 3);
      setProgress(0);
      return;
    }
    setFilesData(info.target.files[0]);
    setProgress(50);
    setTimeout(() => {
      setProgress(100);
    }, 300);
  };

  function closeDeleteHandler() {
    SetOpenDeleteModal(false);
  }

  const deleteAllImportedCandidates = () => {
    props.deleteAllImportedCandiatesHandler();
    setDeleteAllModal(false);
  };

  const ImportCandidatesData = () => {
    const Importdata = {
      importFile: filesData,
    };

    props.postImportCandidatesDataHandler(Importdata, (success) => {
      if (success) {
        props.getImportCandidatesHandler();
        setFilesData("");
      }
    });
  };

  const downloadExcelFormat = () => {
    props.getCandidateExcelFormat();
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setDataSelected(newSelectedRowKeys);
  };

  const SaveData = () => {
    const val = {
      type: "import",
      candidates: dataSelected,
      jobId: location.state.id,
    };

    props.postAssignCandidateHandler(val, (success) => {});
  };

  const SaveSingleData = (data) => {
    const val = {
      type: "import",
      candidates: [data],
      jobId: location.state.id,
    };
    props.postAssignCandidateHandler(val, (success) => {});
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  function confirmDeleteHandler() {
    SetOpenDeleteModal(false);
    props.deleteCandidateDetailsHandler(deletId, (success) => {});
    setDeleteId("");
  }

  const CancelAllDelete = () => {
    setDeleteAllModal(false);
  };

  const columns = [
    {
      // title: "Name",
      title: <p className={styles.LabelName}>Name</p>,
      dataIndex: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: <p className={styles.LabelName}>Contact Number</p>,
      dataIndex: "phone",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Skills</p>,
      dataIndex: "address",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Email id</p>,
      dataIndex: "email",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: <p className={styles.LabelName}>Source</p>,
      dataIndex: "leadSource",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: "",
      render: (text, render) => (
        <>
          <Row style={{ gap: "15px" }}>
            <Col
              onClick={() => {
                SaveSingleData(render.id);
              }}
            >
              <p
                style={{ color: "var(--primaryColor)" }}
                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.coursorPointer}`}
              >
                Save
              </p>
            </Col>
            <Col
              onClick={() => {
                SetOpenDeleteModal(true);
                setDeleteId(render.id);
              }}
            >
              <p
                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.coursorPointer} ${commonCssStyle.deleteRed}`}
                style={{ color: "#DF4242" }}
              >
                Delete
              </p>
            </Col>
          </Row>
        </>
        // )
      ),
    },
  ];
  const data = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address: "New York No. 1 Lake Park",
    },
    {
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sidney No. 1 Lake Park",
    },
  ];

  return (
    <>
      <Row className={commonCssStyle.breadcrumbBottomMargin}>
        <Col md={24}>
          {location.state.onPathname === "/JobDetailPage" ? (
            <BreadcrumbForRequirement
              name={location.state.name}
              slide={[1, 2, 3]}
            />
          ) : (
            <BreadcrumbForRecruitment
              recruitment={location.state}
              name={location.state.name.title}
              slide={[1, 2, 4]}
            />
          )}
        </Col>
      </Row>
      <Card style={{ padding: "22px 40px", marginBottom: "36px" }}>
        <Row align="middle">
          <Col>
            <Row align="middle" style={{ gap: "20px" }}>
              <Col>
                {/* <Link
                  to="/recruitment/recruitmentJobdetails"
                  state={{ id: location.state.id, activeTabKey: "2" }}
                > */}
                <span
                  className={commonCssStyle.coursorPointer}
                  onClick={() => {
                    if (location.state.onPathname === "/JobDetailPage") {
                      navigate("/JobDetailPage", {
                        state: { id: location.state.id, activeTabKey: "4" },
                      });
                    } else {
                      navigate("/recruitment/recruitmentJobdetails", {
                        state: {
                          id: location.state.id,
                          // activeTabKey: "2",
                          activeTabKey: "addCandidate",
                          recuritmentJobDetailsName: location.state.name.title,
                        },
                      });
                    }
                  }}
                >
                  <BackButtonIcon color={"var(--icons-color)"} />
                </span>
                {/* </Link> */}
              </Col>
              <Col>
                <h1
                  style={{ marginRight: "51px" }}
                  className={`${commonCssStyle.mainHeadingText} ${commonCssStyle.inttitle} ${commonCssStyle.marginBottomRemove}`}
                >
                  Import Candidate
                </h1>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      {props.import_candidates_List_data.length === 0 ? (
        <Card style={{ padding: "22px" }}>
          <h4
            style={{
              marginBottom: "29px",
              fontSize: "16px",
              color: "var(--headings-color)",
              fontWeight: 500,
              fontFamily: "Helvetica,Product Sans Medium",
            }}
          >
            Import Type
          </h4>
          <p style={{ color: "var(--body-text-color)", marginBottom: "20px" }}>
            Candidate
          </p>

          {filesData && (
            <div
              style={{
                margin: "22px",
                display: "flex",
                alignItems: "center",
                padding: "14px 8px 17px 8px",
                backgroundColor: "var(--progress-card)",
                borderRadius: "8px",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Image
                  onClick={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  preview={false}
                  src={data.type === "application/pdf" ? pdf : excel}
                  style={{
                    height: "30px",
                    width: "30px",
                    borderRadius: "6px",
                    position: "relative",
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  margin: "0px 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{ color: "var(--headings-color)" }}
                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.formInputLabel}`}
                  >
                    {filesData.name}
                  </p>
                  <p
                    className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.formInputLabel}`}
                  >
                    {progress == "0" ? "0%" : progress == "50" ? "50%" : "100%"}
                  </p>
                </div>
                <Progress percent={progress} size="small" />
              </div>
              <div>
                <span
                  onClick={() => {
                    setFilesData("");
                  }}
                  style={{
                    position: "relative",
                    paddingTop: "5px",
                    paddingLeft: "1px",
                    cursor: "pointer",
                  }}
                >
                  <CloseIcon color={"var(--headings-color)"} />
                </span>
              </div>
            </div>
          )}

          <input
            accept=".xlsx"
            hidden
            type="file"
            name={["fileUpload"]}
            multiple
            onChange={handleChange}
            ref={(input) => {
              btnref = input;
            }}
          />
          {!filesData && (
            <div
              className={`dataImportUploadStyle`}
              style={{
                borderRadius: "0px",
                width: "100%",
              }}
            >
              <p
                style={{
                  marginTop: "26px",
                  textAlign: "center",
                  color: "var(--body-text-color)",
                }}
                className={`${commonCssStyle.chosenFileTextColor}`}
              >
                No File Chosen
              </p>
              <Button
                style={{
                  padding: "12px 0px",
                  display: "block",
                  position: "relative",
                  margin: "0px auto 33px auto",
                }}
                type="primary"
                className={commonCssStyle.buttonSettingsPrimary}
                onClick={(e) => {
                  e.preventDefault();
                  btnref.click();
                  return false;
                }}
              >
                Choose File
              </Button>
            </div>
          )}
          <Row style={{ gap: "15px", marginTop: "16px" }}>
            <Col>
              <Button
                className={commonCssStyle.buttonSettingsPrimary}
                onClick={ImportCandidatesData}
                type="primary"
              >
                Import
              </Button>
            </Col>
            <Col>
              <Button
                className={commonCssStyle.buttonSettingsSecondary}
                type="default"
                onClick={downloadExcelFormat}
              >
                Download
              </Button>
            </Col>
          </Row>
        </Card>
      ) : (
        <Row>
          <Col md={24}>
            <Table
              scroll={{ x: "auto" }}
              rowSelection={rowSelection}
              rowKey="id"
              className="importCandidateTable"
              title={() => (
                <>
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{ borderBottom: "1px solid #7c7c7c54" }}
                  >
                    <Col>
                      <h3
                        style={{
                          fontSize: "16px",
                          color: "var(--headings-color)",
                          padding: "22px 0px 22px 22px",
                          fontFamily: "Helvetica,ProductSans",
                        }}
                        className={`${commonCssStyle.marginBottomRemove} `}
                      >
                        Imported Candidate List
                      </h3>
                    </Col>
                    <Col>
                      <Row
                        align="middle"
                        style={{ gap: "15px", marginRight: "20px" }}
                      >
                        <Col>
                          <Button
                            type="primary"
                            className={commonCssStyle.buttonSettingsPrimary}
                            onClick={SaveData}
                          >
                            Save
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            onClick={() => {
                              setSelectedRowKeys([]);
                            }}
                            type="default"
                            className={commonCssStyle.buttonSettingsSecondary}
                          >
                            Clear
                          </Button>
                        </Col>
                        <Col
                          onClick={() => {
                            // deleteAllImportedCandidates();
                            setDeleteAllModal(true);
                          }}
                        >
                          <p
                            className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.coursorPointer} ${commonCssStyle.deleteRed}`}
                          >
                            Delete All
                          </p>
                        </Col>
                        {/* <Col
                          onClick={() => {
                            //here
                          }}
                        >
                          <p
                            style={{ color: "var(--primaryColor)" }}
                            className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.coursorPointer}`}
                          >
                            Select All
                          </p>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
              columns={columns}
              dataSource={props.import_candidates_List_data}
            />
            <Modal
              closable={false}
              title={
                <Row style={{ gap: "289px" }}>
                  <Col>
                    <p className={commonCssStyle.popUpHeadingText}>
                      Delete Candidate
                    </p>
                  </Col>
                  <Col>
                    <div
                      style={{ float: "right" }}
                      onClick={() => {
                        closeDeleteHandler();
                      }}
                      className={`${commonCssStyle.coursorPointer} `}
                    >
                      <CloseIcon
                        color={"var(--body-text-color)" || "var(--theme)"}
                      />
                    </div>
                  </Col>
                </Row>
              }
              footer={
                <>
                  <Row style={{ gap: "10px" }} justify="end" align="middle">
                    <Col>
                      <Button
                        type="default"
                        className={commonCssStyle.buttonSettingsSecondary}
                        style={{
                          height: "48px",
                          fontSize: "16px",
                          width: "181px",
                        }}
                        onClick={() => {
                          closeDeleteHandler();
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        className={commonCssStyle.buttonSettingsPrimary}
                        onClick={() => {
                          confirmDeleteHandler();
                        }}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </>
              }
              open={openDeleteModal}
              onOk={confirmDeleteHandler}
            >
              <Row>
                <Col>
                  <p className={commonCssStyle.tableHeadingTextData}>
                    Are you sure you want to delete
                  </p>
                </Col>
              </Row>
            </Modal>

            {/* delete all modal  */}
            <Modal
              closable={false}
              title={
                <Row style={{ gap: "238px" }}>
                  <Col>
                    <p className={commonCssStyle.popUpHeadingText}>
                      Delete All Candidates
                    </p>
                  </Col>
                  <Col>
                    <div
                      style={{ float: "right" }}
                      onClick={() => {
                        closeDeleteHandler();
                      }}
                      className={`${commonCssStyle.coursorPointer} `}
                    >
                      <CloseIcon
                        color={"var(--body-text-color)" || "var(--theme)"}
                      />
                    </div>
                  </Col>
                </Row>
              }
              footer={
                <>
                  <Row style={{ gap: "10px" }} justify="end" align="middle">
                    <Col>
                      <Button
                        type="default"
                        className={commonCssStyle.buttonSettingsSecondary}
                        style={{
                          height: "48px",
                          fontSize: "16px",
                          width: "181px",
                        }}
                        onClick={() => {
                          CancelAllDelete();
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        className={commonCssStyle.buttonSettingsPrimary}
                        onClick={() => {
                          deleteAllImportedCandidates();
                        }}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </>
              }
              open={deleteAllModal}
              onOk={confirmDeleteHandler}
            >
              <Row>
                <Col>
                  <p className={commonCssStyle.tableHeadingTextData}>
                    Are you sure you want to delete All Candidates
                  </p>
                </Col>
              </Row>
            </Modal>
          </Col>
        </Row>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    import_candidates_List_data: state.recruitment.import_candidates_List_data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getImportCandidatesHandler: () => {
      dispatch(getImportCandidatesAction());
    },
    getCandidateExcelFormat: () => {
      dispatch(getCandidatesExcelFormatAction());
    },
    postImportCandidatesDataHandler: (val, callback) => {
      dispatch(postRecruitmentImportCanidateAction(val, callback));
    },
    deleteCandidateDetailsHandler: (id, callback) => {
      dispatch(getRecruitmentDeleteImportCandidateData(id, callback));
    },
    deleteAllImportedCandiatesHandler: () => {
      dispatch(getRecruitmentDeleteAllImportedCandidatesData());
    },
    postAssignCandidateHandler: (val, callback) => {
      dispatch(postAssignedEmployeeAction(val, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ImportCandidate);
