import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  roleTypes: [],
  roleType: null,
  allrolestypes: [],
  roles: [],
};

export const masterRoleTypesSlice = createSlice({
  name: "roleTypes",
  initialState,
  reducers: {
    getRoleTypesSlice(state, action) {
      state.roleTypes = action.payload.result;
    },
    getAllRoleTypesSlice(state, action) {
      state.allroleTypes = action.payload.result;
    },
    getRoleSlice(state, action) {
      state.roles = action.payload.result;
    },
    getRoleTypeSlice(state, action) {
      state.roleTypes = action.payload.result;
    },
    postRoleTypesSlice(state, action) {
      state.roleTypes = [...state.roleTypes, action.payload.result];
    },
    putRoleTypesSlice(state, action) {
      state.roleTypes = state.roles.map((data) => {
        if (data.id === action.payload.result.id) {
          return action.payload.result;
        } else {
          return data;
        }
      });
    },
    deleteRoleTypesSlice(state, action) {
      state.roleTypes = state.roleTypes.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});
export const {
  getRoleTypesSlice,
  getRoleTypeSlice,
  postRoleTypesSlice,
  getRoleSlice,
  putRoleTypesSlice,
  deleteRoleTypesSlice,
  getAllRoleTypesSlice,
} = masterRoleTypesSlice.actions;
export default masterRoleTypesSlice.reducer;
