import React from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Dropdown,
  Menu,
  Space,
  Typography,
  Select,
  Switch,
  Radio,
} from "antd";
import commonCssStyle from "../../UI/CommonCss.module.css";
import {
  CalendarBlankIcon,
  CaretBlackDownIcon,
  FilterOutlinedIcon,
  ListBulletsIcon,
  PlusIcon,
} from "../../IconsComp/Icons";
import TaskCalendarInterviews from "./TaskCalendarInterviews";
import { useState } from "react";
import TaskListView from "./TaskListView";
import RegularTaskDrawer from "./RegularTaskDrawer";
import ScheduleInterviewDrawer from "../AddCandidatesComp/CandidatesSummary/ScheduleInterviewDrawer";
import { connect } from "react-redux";
import {
  deleteTaskAndCalendarAction,
  getTaskAndCalendarAction,
  getTasksAction,
  getTasksFilterAction,
  postCompletedInterviewPerformanceAction,
  postTaskAndCalendarAction,
  putTaskAndCalendarAction,
} from "../../../Reusable/Store/Action/TaskAndCalendarAction";
import { getCandidatesAction } from "../../../Reusable/Store/Action/CandidatesAction";
import { useEffect } from "react";
import { getEmployeesAction } from "../../../Reusable/Store/Action/EmployeesAction";
import { getClientsAction } from "../../../Reusable/Store/Action/ClientAction";
import {
  getRequirementsAction,
  getRequirementsFilterAction,
} from "../../../Reusable/Store/Action/RequirementAction";
import TaskFilter from "./TaskFilter";
import jwt_decode from "jwt-decode";
import { profileAction } from "../../../Reusable/Store/Action/AuthenticationAction";
import InterviewDetails from "../AddCandidatesComp/CandidatesSummary/InterviewDetails";
import InterviewPendingDetails from "../AddCandidatesComp/CandidatesSummary/InterviewPendingDetails";
import {
  filterStatusSlice,
  getTaskStatusSlice,
  getTaskStatusVarSlice,
  loaderSlice,
} from "../../../Reusable/Store/Slice/TaskAndCalendarSlice";
import { useLocation } from "react-router";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import PermissionMessage from "../../UI/PermissionMessage";
import BreadcrumbForTaskAndInterview from "./BreadcrumbForTaskAndInterview";
import LoadingIcon from "../../UI/LoadingIcon";
import moment from "moment";
function TaskCalendarTabs(props) {
  const [calendarViewStatus, setCalendarViewStatus] = useState(false);
  const [interAndTaskDetals, setInterAndTaskDetals] = useState({});
  const [colorKey, setColorKey] = useState("0");
  const [menuKey, setMenuKey] = useState("1");
  const [drawer, setDrawer] = useState(false);
  const [scheduleDrawer, setScheduleDrawer] = useState(false);
  const [modalName, setModalName] = useState("");
  const [cfilter, setCFilter] = useState({});
  const [taskFilterStatus, setTaskFilterStatus] = useState(false);
  const [fetchId, setFetchId] = useState({});
  const [interviewName, setInterviewName] = useState("");
  const [taskStatus, setTaskStatus] = useState(false); // taskStatus in true it call other task in false it call interview data
  const [interviewDetailsDrawer, setInterviewDetailsDrawer] = useState(false);
  const location = useLocation();

  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      // setTaskStatus(false);
      // debugger;
      if (
        location.state &&
        location.state.type &&
        location.state.type === "task"
      ) {
        props.getTaskByIdHandler(location.state.id, { link: 0 }, (success) => {
          if (success) {
            props.getTaskFilterHandler({ type: "task", current_page: 1 });
            setDrawer(true);
            setTaskStatus(true);
            setModalName("display");
          }
        });
      } else if (
        location.state &&
        location.state.type &&
        location.state.type === "interview"
      ) {
        props.getTaskByIdHandler(location.state.id, { link: 1 }, (success) => {
          if (success) {
            setInterAndTaskDetals(location.state.data);
            setTaskStatus(false);
            props.getTaskFilterHandler({ type: "interview", current_page: 1 });
            setInterviewDetailsDrawer(true);
            setModalName("display");
          }
        });
      } else if (
        location.state &&
        location.state.disp &&
        location.state.disp === "interviewTable"
      ) {
        setTaskStatus(false);
        props.getTaskFilterHandler({ type: "interview", current_page: 1 });
      } else if (
        location.state &&
        location.state.disp &&
        location.state.disp === "taskTable"
      ) {
        setTaskStatus(true);
        props.getTaskFilterHandler({ type: "task", current_page: 1 });
      } else if (
        location.state &&
        location.state.from &&
        location.state.from === "candidateDetails"
      ) {
        props.getTaskByIdHandler(location.state.id, { link: 1 }, (success) => {
          if (success) {
            setInterAndTaskDetals(
              location.state &&
                location.state.interviewDetals &&
                location.state.interviewDetals
            );
            setTaskStatus(false);
            let localFilterDataForInterview = localStorage.getItem(
              "interviewFilterData"
            );
            let data = JSON.parse(localFilterDataForInterview);
            props.getTaskFilterHandler({
              type: "interview",
              current_page: 1,
              ...data,
              interviewDate: data.interviewDate
                ? moment(data.interviewDate).format("YYYY-MM-DD")
                : "",
            });
            setInterviewDetailsDrawer(true);
            setModalName("display");
          }
        });
      } else {
        let localFilterData = localStorage.getItem("interviewFilterData");
        let filterData = JSON.parse(localFilterData);
        if (filterData) {
          props.getTaskFilterHandler({
            type: "interview",
            current_page: 1,
            ...filterData,
            interviewDate: filterData.interviewDate
              ? moment(filterData.interviewDate).format("YYYY-MM-DD")
              : "",
          });
        } else {
          props.getTaskFilterHandler({ type: "interview", current_page: 1 });
        }
      }
      let decoded = jwt_decode(props.tokenData);
      setFetchId(decoded);

      props.getEmployeeHandler();
      props.getCompanyHandler();
      props.getCandidateHandler();
      props.getJobNameHandler();
    }
    return () => {
      unsubscribed = true;
    };
    // props.profileHandler();
  }, [location]);
  function closeInterviewDetailsDrawerHandler() {
    setInterviewDetailsDrawer(false);
  }

  if (window.location.hash === "#/taskCalendar") {
    localStorage.removeItem("candidateDetail");
    localStorage.removeItem("employee");
    localStorage.removeItem("clientCompany");
    localStorage.removeItem("recuritmentFilterData");
    localStorage.removeItem("candidateDetailFromRequirement");
    localStorage.removeItem("candidateListForPool");
    localStorage.removeItem("recuritmentCandidateFilter");
    localStorage.removeItem("requirementFilterData");
    localStorage.removeItem("reportsCompanyDetailFilterData");
    localStorage.removeItem("reportsjobdetailfilterdata");
    localStorage.removeItem("reportsjobcandidatefilterdata");
    localStorage.removeItem("fromWhichPage");
    localStorage.removeItem("candidateDetailFromRecruitment");
    localStorage.removeItem("recuritmentReportFilter");
    localStorage.removeItem("employeeReportsFilter");
    localStorage.removeItem("candidateSort");
    localStorage.removeItem("recuritmentSort");
    localStorage.removeItem("recuritmentCandidateSort");
    localStorage.removeItem("requirementSort");
    localStorage.removeItem("ClientCompanySort");
    localStorage.removeItem("employeeSort");
    localStorage.removeItem("ReportsCompanyReport1Sort");
    localStorage.removeItem("reportsjobcandidatefilterdataSort");
    localStorage.removeItem("ReportsJobDetailFilterOneSort");
    localStorage.removeItem("reportRecruitmentEmployeeSort");
  }

  const menu2 = (
    <Menu
      style={{ width: "214px", margin: "11px 45px" }}
      selectable
      defaultSelectedKeys={["1"]}
      items={[
        props.permissions &&
          props.permissions.find((data) => data === "Task-add") && {
            key: "1",
            label: (
              <p
                onClick={() => {
                  props.getCheckPermissionHandler("Task-add", (success) => {
                    if (success) {
                      setModalName("add");
                      setMenuKey("1");
                      setDrawer(true);
                    } else {
                      setOpenPMModal({
                        open: true,
                        permission: "Task Add",
                      });
                    }
                  });
                }}
                style={{
                  color:
                    menuKey === "1"
                      ? "var(--primaryColor)"
                      : "var(--body-text-color)",
                }}
                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.darkText}`}
              >
                {/* Regular Task */}
                Other Task
              </p>
            ),
          },
        props.permissions &&
          props.permissions.find((data) => data === "interview-add") && {
            key: "2",
            label: (
              <p
                onClick={() => {
                  props.getCheckPermissionHandler(
                    "interview-add",
                    (success) => {
                      if (success) {
                        setMenuKey("2");
                        setInterviewName("task");
                        setModalName("add");
                        setScheduleDrawer(true);
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Interview Add",
                        });
                      }
                    }
                  );
                }}
                style={{
                  color:
                    menuKey === "2"
                      ? "var(--primaryColor)"
                      : "var(--body-text-color)",
                }}
                className={`${commonCssStyle.marginBottomRemove}  ${commonCssStyle.darkText}`}
              >
                Interview
              </p>
            ),
          },
      ]}
    />
  );

  const calendarViewHandler = () => {
    setCalendarViewStatus(true);
    // props.getTaskStatusHandler(true);
  };
  const setListViewHandler = () => {
    setCalendarViewStatus(false);
  };
  function closeDrawerHandler() {
    setScheduleDrawer(false);
    setModalName("");
  }
  const closeDrawer = () => {
    setDrawer(false);
    // props.getTaskFilterHandler({ current_page: 1 });
  };

  const handleProvinceChange = (value) => {
    let sort = value.split("_");

    let intertview = JSON.parse(localStorage.getItem("interviewFilterData"));
    let taskFilter = JSON.parse(localStorage.getItem("taskFilterData"));
    if (intertview || taskFilter) {
      if (!taskStatus) {
        localStorage.setItem("interivewSort", value);
        localStorage.setItem(
          "interviewFilterData",
          JSON.stringify({
            ...cfilter,
            ...intertview,
            type: "interview",
            sortBy: sort[0],
            sortOrder: sort[1],
            current_page: 1,
          })
        );
        props.getTaskFilterHandler({
          ...cfilter,
          ...intertview,
          type: "interview",
          sortBy: sort[0],
          sortOrder: sort[1],
          current_page: 1,
        });
        setCFilter({
          ...cfilter,
          ...intertview,
          type: "interview",
          sortBy: sort[0],
          sortOrder: sort[1],
          current_page: 1,
        });
      } else {
        localStorage.setItem("taskSort", value);
        localStorage.setItem(
          "taskFilterData",
          JSON.stringify({
            ...cfilter,
            ...taskFilter,
            type: "task",
            sortBy: sort[0],
            sortOrder: sort[1],
            current_page: 1,
          })
        );
        props.getTaskFilterHandler({
          ...cfilter,
          ...taskFilter,
          type: "task",
          sortBy: sort[0],
          sortOrder: sort[1],
          current_page: 1,
        });
        setCFilter({
          ...cfilter,
          ...taskFilter,
          type: "task",
          sortBy: sort[0],
          sortOrder: sort[1],
          current_page: 1,
        });
      }
    } else {
      taskStatus
        ? localStorage.setItem("taskSort", value)
        : localStorage.setItem("interivewSort", value);
      localStorage.setItem(
        "interviewFilterData",
        JSON.stringify({
          ...cfilter,
          type: taskStatus ? "task" : "interview",
          sortBy: sort[0],
          sortOrder: sort[1],
          current_page: 1,
        })
      );
      props.getTaskFilterHandler({
        ...cfilter,
        type: taskStatus ? "task" : "interview",
        sortBy: sort[0],
        sortOrder: sort[1],
        current_page: 1,
      });
      setCFilter({
        ...cfilter,
        type: taskStatus ? "task" : "interview",
        sortBy: sort[0],
        sortOrder: sort[1],
        current_page: 1,
      });
    }
  };
  const filterStatusHandler = () => {
    setTaskFilterStatus(false);
  };
  function showFilterHandler() {
    setTaskFilterStatus(true);
  }
  return (
    <>
      {(!taskStatus &&
        interviewDetailsDrawer &&
        modalName === "display" &&
        props.task.status === "pending") ||
        (props.task.status === "Pending" && (
          <>
            {/* <InterviewDetails
            typeData={"task"}
            onModalName={modalName}
            onCloseInterViewDetails={closeInterviewDetailsDrawerHandler}
            onOpenInterViewDetails={interviewDetailsDrawer}
            onInterviewDetails={props.task}
          /> */}
            <InterviewPendingDetails
              onAnswers={(val) => {
                props.postInterviewPerformanceRatingHandler(val, (success) => {
                  if (success) {
                    closeInterviewDetailsDrawerHandler();
                  }
                });
              }}
              typeData={"task"} //to show from which page the data is comming
              onModalName={modalName} //modal name like add or edit or display
              onCloseInterViewDetails={closeInterviewDetailsDrawerHandler} //used to close modal
              onOpenPendDetails={interviewDetailsDrawer} //used to open the modal
              onInterviewDetails={props.task} //sending task data
              userIdData={fetchId} //sending login user id
              onInterAndTaskDetals={interAndTaskDetals} //sending data from navigation, in state.
              onProfile={props.onProfile && props.onProfile} // sending profile data to check the role, intertview status to show conditionally it can visable other than recuriter
            />
          </>
        ))}
      {(!taskStatus &&
        interviewDetailsDrawer &&
        modalName === "display" &&
        props.task.status === "completed") ||
      props.task.status === "Completed" ? (
        <>
          <InterviewDetails
            typeData={"task"} //to show from which page the data is comming
            onModalName={modalName} //modal name like add or edit or display
            onCloseInterViewDetails={closeInterviewDetailsDrawerHandler} //used to close modal
            onOpenInterViewDetails={interviewDetailsDrawer} //used to open the modal
            onInterviewDetails={props.task} //sending task data
            userIdData={fetchId} //sending login user id
            onInterAndTaskDetals={interAndTaskDetals}
            onProfile={props.onProfile && props.onProfile} //sending data from navigation, in state.
          />
        </>
      ) : props.task.status === "Rescheduled by Candidate" ? (
        <>
          <InterviewDetails
            typeData={"task"} //to show from which page the data is comming
            onModalName={modalName} //modal name like add or edit or display
            onCloseInterViewDetails={closeInterviewDetailsDrawerHandler} //used to close modal
            onOpenInterViewDetails={interviewDetailsDrawer} //used to open the modal
            onInterviewDetails={props.task} //sending task data
            userIdData={fetchId} //sending login user id
            onInterAndTaskDetals={interAndTaskDetals}
            onProfile={props.onProfile && props.onProfile} //sending data from navigation, in state.
          />
        </>
      ) : props.task.status === "Rescheduled by Client" ? (
        <>
          <InterviewDetails
            typeData={"task"} //to show from which page the data is comming
            onModalName={modalName} //modal name like add or edit or display
            onCloseInterViewDetails={closeInterviewDetailsDrawerHandler} //used to close modal
            onOpenInterViewDetails={interviewDetailsDrawer} //used to open the modal
            onInterviewDetails={props.task} //sending task data
            userIdData={fetchId} //sending login user id
            onInterAndTaskDetals={interAndTaskDetals}
            onProfile={props.onProfile && props.onProfile} //sending data from navigation, in state.
          />
        </>
      ) : (
        props.task.status === "Candidate No Show" && (
          <>
            <InterviewDetails
              typeData={"task"} //to show from which page the data is comming
              onModalName={modalName} //modal name like add or edit or display
              onCloseInterViewDetails={closeInterviewDetailsDrawerHandler} //used to close modal
              onOpenInterViewDetails={interviewDetailsDrawer} //used to open the modal
              onInterviewDetails={props.task} //sending task data
              userIdData={fetchId} //sending login user id
              onInterAndTaskDetals={interAndTaskDetals}
              onProfile={props.onProfile && props.onProfile} //sending data from navigation, in state.
            />
          </>
        )
      )}
      {/* {!taskStatus && modalName === "edit" && (
        <InterviewDetails
          typeData={"task"}
          onCloseInterViewDetails={closeInterviewDetailsDrawerHandler}
          onOpenInterViewDetails={interviewDetailsDrawer}
          onInterviewDetails={props.task}
        />
      )} */}

      {drawer && modalName === "add" && (
        <RegularTaskDrawer
          asignById={fetchId}
          onModalName={modalName}
          onCloseDrawer={closeDrawer}
          onOpen={drawer}
          onSumbitData={props.postTaskHandler}
          employeesData={props.employeesData}
          onJobData={props.jobsData}
          onCompanyData={props.companyData}
          onCandidateData={props.candidateData}
          userIdData={fetchId}
          onInterAndTaskDetals={interAndTaskDetals}
        />
      )}
      {drawer && modalName === "edit" && (
        <RegularTaskDrawer
          onModalName={modalName}
          onCloseDrawer={closeDrawer}
          onOpen={drawer}
          onTaskData={props.task}
          onSumbitData={(val) => {
            setCFilter(val);
            props.putTaskHandler(val, (success) => {
              if (success) {
                let taskFilterDataFromLocal = JSON.parse(
                  localStorage.getItem("taskFilterData")
                );
                if (taskFilterDataFromLocal) {
                  props.getTaskFilterHandler({
                    type: "task",
                    current_page: 1,
                    ...props.filterData,
                    ...taskFilterDataFromLocal,
                  });
                } else {
                  props.getTaskFilterHandler({
                    type: "task",
                    current_page: 1,
                    ...props.filterData,
                  });
                }
                // props.getTaskFilterHandler({ type: "task", current_page: 1 });
                closeDrawer();
              }
            });
          }}
          employeesData={props.employeesData}
          onJobData={props.jobsData}
          onCompanyData={props.companyData}
          onCandidateData={props.candidateData}
          userIdData={fetchId}
          onInterAndTaskDetals={interAndTaskDetals}
        />
      )}
      {drawer && modalName === "display" && (
        <RegularTaskDrawer
          onViewType={"list"}
          onModalName={modalName}
          onCloseDrawer={closeDrawer}
          onOpen={drawer}
          onOk={() => {
            let taskFilterDataFromLocal = JSON.parse(
              localStorage.getItem("taskFilterData")
            );
            if (taskFilterDataFromLocal) {
              props.getTaskFilterHandler({
                type: "task",
                current_page: 1,
                ...props.filterData,
                ...taskFilterDataFromLocal,
              });
            } else {
              props.getTaskFilterHandler({
                type: "task",
                current_page: 1,
                ...props.filterData,
              });
            }
            closeDrawer();
          }}
          onTaskData={props.task}
          employeesData={props.employeesData}
          onJobData={props.jobsData}
          onCompanyData={props.companyData}
          onCandidateData={props.candidateData}
          userIdData={fetchId}
          onInterAndTaskDetals={interAndTaskDetals}
          onProfile={props.onProfile && props.onProfile}
        />
      )}
      {!taskStatus && modalName === "edit" && (
        <ScheduleInterviewDrawer
          onModalName={modalName} //sending modal name to find edit or add
          onTasksData={props.task} //sending task data
          onInterviewName={"task"} //specifing from schedule drawer from which page the data is comming like example candidateTable, it's noting but modal name
          onJobData={props.jobsData} //sending all jobs data
          onCandidateData={props.candidateData} //sending all candidate data
          // onOpenSchedileInterview={interviewDetailsDrawer} //used to open drawer
          onOpenSchedileInterview={scheduleDrawer} //used to open drawer
          onProfile={props.onProfile && props.onProfile}
          onCloseSchedileInterview={() => {
            // setInterviewDetailsDrawer(false); //used to close the drawer
            setScheduleDrawer(false); //used to close the drawer
            setModalName("");
            // props.getTaskFilterHandler({ task: "interview", current_page: 1 });
          }}
          onSubmit={() => {
            let interviewFilterDataFromLocal = JSON.parse(
              localStorage.getItem("interviewFilterData")
            );
            if (interviewFilterDataFromLocal) {
              props.getTaskFilterHandler({
                type: "interview",
                current_page: 1,
                ...interviewFilterDataFromLocal,
              });
            } else {
              props.getTaskFilterHandler({
                type: "interview",
                current_page: 1,
              });
            }
            setModalName("");
            closeDrawerHandler();
          }}
        />
      )}
      {modalName === "add" && (
        <ScheduleInterviewDrawer
          onModalName={modalName} //sending modal name to find edit or add
          onInterviewName={interviewName} //specifing from schedule drawer from which page the data is comming like example candidateTable, it's noting but modal name
          onJobData={props.jobsData} //sending all jobs data
          onCandidateData={props.candidateData} //sending all candidate data
          onOpenSchedileInterview={scheduleDrawer} //used to open the drawer
          onSubmit={() => {
            if (!taskStatus) {
              //after submiting data calling interview api to see the updated data in interview table
              // props.getTaskFilterHandler({
              //   type: "interview",
              //   current_page: 1,
              // });
              let interviewFilterDataFromLocal = JSON.parse(
                localStorage.getItem("interviewFilterData")
              );
              if (interviewFilterDataFromLocal) {
                props.getTaskFilterHandler({
                  type: "interview",
                  current_page: 1,
                  ...interviewFilterDataFromLocal,
                });
              } else {
                props.getTaskFilterHandler({
                  type: "interview",
                  current_page: 1,
                });
              }
            }
            closeDrawerHandler(); //used to close the drawer
          }}
          onCloseSchedileInterview={closeDrawerHandler} //used to close the drawer
        />
      )}
      <TaskFilter
        filterStatusVar={props.filterStatusVar}
        onTaskStatus={(val) => {
          setTaskStatus(val);
        }}
        employeesData={props.employeesData}
        onCalendarViewStatus={() => {
          setCalendarViewStatus(false);
        }}
        onFilterOpen={taskFilterStatus}
        taskStatus={taskStatus}
        onFilterClose={filterStatusHandler}
        onJobData={props.jobsData}
        onFilterData={(val) => {
          props.getTaskFilterHandler(val);
          setCFilter(val);
        }}
        onCompanyData={props.companyData}
        onCandidateData={props.candidateData}
      />
      <Row>
        <Col className={commonCssStyle.breadcrumbBottomMargin} md={24}>
          <BreadcrumbForTaskAndInterview
            calendarViewStatus={calendarViewStatus}
            taskStatus={taskStatus}
            slide={[1, 2]}
          />
        </Col>
      </Row>
      <Card
        style={{ padding: "10px 10px", width: "100%", marginBottom: "16px" }}
      >
        <Row gutter={[0, 16]} justify="space-between" align="middle">
          <Col>
            <Row align="middle" style={{ gap: "24px" }}>
              <Col>
                <h3
                  className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.headingText2}`}
                >
                  {taskStatus ? "Task" : "Interview"} &#40;{" "}
                  {props.tasksData && props.tasksData.total
                    ? props.tasksData.total
                    : "0"}{" "}
                  &#41;
                </h3>
              </Col>

              {/* {!calendarViewStatus && ( */}
              <Col>
                <Button
                  onClick={showFilterHandler}
                  className={`${commonCssStyle.filterButton} ${commonCssStyle.filterButtonBorder}`}
                >
                  <Row id="taskAndCalendarFilter" style={{ gap: "11px" }}>
                    <Col>
                      <span style={{ transform: "translateY(2px)" }}>
                        <FilterOutlinedIcon color={"var(--primaryColor)"} />
                      </span>
                    </Col>
                    <Col>
                      <p
                        className={`${commonCssStyle.filterAndSortTextStyle} ${commonCssStyle.marginBottomRemove}`}
                      >
                        Filter
                      </p>
                    </Col>
                  </Row>
                </Button>
              </Col>
              {/* )} */}

              {!calendarViewStatus && (
                <Col>
                  <Button
                    style={{
                      height: "40px",
                    }}
                    className={` ${commonCssStyle.sortBtn}  ${commonCssStyle.sortBtnBorder} `}
                  >
                    <Row justify="space-between">
                      <Col>
                        <p style={{ transform: "translateY(2px)" }}>Sort By</p>
                      </Col>
                      <Col>
                        {taskStatus ? (
                          <Select
                            style={{
                              width: "100%",
                              transform: "translateY(3px)",
                            }}
                            onChange={handleProvinceChange}
                            bordered={false}
                            placeholder={
                              <p
                                style={{
                                  margin: "0px",
                                  color: "var(--primaryColor)",
                                }}
                              >
                                Select
                              </p>
                            }
                            value={
                              localStorage.getItem("taskSort")
                                ? localStorage.getItem("taskSort")
                                : "Select"
                            }
                            size="small"
                            className={`${commonCssStyle.filterDrop} selectInput sortBySelectStyle selectDropDownInput `}
                          >
                            <Select.Option key="1" value="createdDate_ASC">
                              Created Date Ascending
                            </Select.Option>
                            <Select.Option key="2" value="createdDate_DESC">
                              Created Date Descending
                            </Select.Option>

                            <Select.Option key="3" value="DueDate_ASC">
                              Due Date Ascending
                            </Select.Option>
                            <Select.Option key="4" value="DueDate_DESC">
                              Due Date Descending
                            </Select.Option>
                          </Select>
                        ) : (
                          <Select
                            style={{
                              width: "100%",
                              transform: "translateY(3px)",
                            }}
                            onChange={handleProvinceChange}
                            bordered={false}
                            placeholder={
                              <p
                                style={{
                                  margin: "0px",
                                  color: "var(--primaryColor)",
                                }}
                              >
                                Select
                              </p>
                            }
                            value={
                              localStorage.getItem("interivewSort")
                                ? localStorage.getItem("interivewSort")
                                : "Select"
                            }
                            size="small"
                            className={`${commonCssStyle.filterDrop} selectInput sortBySelectStyle selectDropDownInput `}
                          >
                            <Select.Option key="1" value="createdDate_ASC">
                              Created Date Ascending
                            </Select.Option>
                            <Select.Option key="2" value="createdDate_DESC">
                              Created Date Descending
                            </Select.Option>
                            <Select.Option key="3" value="interviewDate_ASC">
                              Interview Date Ascending
                            </Select.Option>
                            <Select.Option key="4" value="interviewDate_DESC">
                              Interview Date Descending
                            </Select.Option>
                          </Select>
                        )}
                      </Col>
                    </Row>
                  </Button>
                </Col>
              )}
            </Row>
          </Col>

          <Col>
            <Row justify="end">
              <Col>
                <Dropdown placement="bottom" overlay={menu2}>
                  <Typography.Link>
                    <Space className={commonCssStyle.tableHeadingTextData}>
                      <Button
                        style={{ width: "214px" }}
                        className={commonCssStyle.intaddButton}
                        type="primary"
                      >
                        <Row align="middle" justify="space-around">
                          <Col>Add Task</Col>
                          <Col>
                            <CaretBlackDownIcon color={"white"} />
                          </Col>
                        </Row>
                      </Button>
                    </Space>
                  </Typography.Link>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row justify="space-between">
        <Col>
          <div
            style={{
              height: "43px",
              width: "auto",
              // width: "300px",
              padding: "3px 5px",
              marginBottom: "31px",
              backgroundColor: "#E0E0E0",
              borderRadius: "6px",
            }}
          >
            <Row align="middle" justify="space-between">
              <Col>
                <Card
                  style={{ padding: "5px 10px" }}
                  onMouseEnter={() => {
                    setColorKey("1");
                  }}
                  onMouseLeave={() => {
                    setColorKey("0");
                  }}
                  onClick={setListViewHandler}
                  className={`${
                    calendarViewStatus
                      ? commonCssStyle.taskBtn
                      : commonCssStyle.taskBackClrBtn
                  }`}
                >
                  {props.permissions &&
                    props.permissions.find(
                      (data) => data === "Task-list-view"
                    ) && (
                      <Row
                        className={`${commonCssStyle.coursorPointer}`}
                        style={{ gap: "4px" }}
                        align="middle"
                      >
                        <Col style={{ transform: "translateY(3px)" }}>
                          <ListBulletsIcon
                            color={calendarViewStatus ? "#1E1E1E" : "white"}
                          />
                        </Col>
                        <Col>
                          <p
                            className={` ${
                              calendarViewStatus
                                ? commonCssStyle.taskColor
                                : commonCssStyle.taskBtnTextColor
                            } ${commonCssStyle.marginBottomRemove} `}
                            style={{
                              fontSize: "14px",
                              paddingLeft: "5px",
                              //color: colorKey === "1" ? "white" : "",
                            }}
                          >
                            List View
                          </p>
                        </Col>
                      </Row>
                    )}
                </Card>
              </Col>
              {props.permissions &&
                props.permissions.find(
                  (data) => data === "Task-calendar-view"
                ) && (
                  <Col>
                    <Card
                      style={{ padding: "5px 10px" }}
                      onMouseEnter={() => {
                        setColorKey("2");
                      }}
                      onMouseLeave={() => {
                        setColorKey("0");
                      }}
                      onClick={calendarViewHandler}
                      className={`${
                        !calendarViewStatus
                          ? commonCssStyle.taskBtn
                          : commonCssStyle.taskBackClrBtn
                      }`}
                    >
                      <Row
                        style={{ gap: "8px" }}
                        align="middle"
                        className={`${commonCssStyle.coursorPointer}`}
                      >
                        <Col style={{ transform: "translateY(1px)" }}>
                          <CalendarBlankIcon
                            color={!calendarViewStatus ? "#1E1E1E" : "white"}
                          />
                        </Col>
                        <Col>
                          <p
                            className={` ${
                              calendarViewStatus
                                ? commonCssStyle.taskBtnTextColor
                                : commonCssStyle.taskColor
                            } ${commonCssStyle.marginBottomRemove}`}
                            style={{
                              fontSize: "14px",
                              paddingLeft: "1px",
                              //color: colorKey === "2" ? "white" : "",
                            }}
                          >
                            Calendar View
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                )}
            </Row>
          </div>
        </Col>
        <Col>
          <Row style={{ gap: "28px" }} align="middle">
            <Col>
              <Button
                style={{ width: "auto", height: "auto" }}
                className={commonCssStyle.buttonSettingsSecondary}
                type="default"
                onClick={() => {
                  props.loaderHandler(false);
                  props.getFilterStatusHandler(false);
                  props.getTaskFilterHandler({
                    type: "interview",
                    current_page: 1,
                  });
                  props.getTaskStatusVarHandler(true);
                  setCalendarViewStatus(false);
                  setTaskStatus(false);
                  localStorage.removeItem("taskFilterData");
                  localStorage.removeItem("interviewFilterData");
                  localStorage.removeItem("interivewSort");
                  localStorage.removeItem("taskSort");
                  setCFilter({});
                }}
              >
                &#x21bb;
              </Button>
            </Col>
            {/* {!calendarViewStatus && ( */}
            <Col>
              <Radio.Group
                onChange={(val) => {
                  props.loaderHandler(false);
                  setTaskStatus(val.target.value);
                  if (val.target.value) {
                    // props.getCheckPermissionHandler(
                    //   "Task-list-view",
                    //   (success) => {
                    //     if (success) {
                    let localFilterDataForTask =
                      localStorage.getItem("taskFilterData");
                    let data = JSON.parse(localFilterDataForTask);
                    props.getTaskFilterHandler({
                      type: "task",
                      current_page: 1,
                      ...data,
                    });
                    // } else {
                    // setOpenPMModal({
                    //   open: true,
                    //   permission: "Task List View",
                    // });
                    // }
                    //   }
                    // );
                  } else {
                    let localFilterDataForInterview = localStorage.getItem(
                      "interviewFilterData"
                    );
                    let data = JSON.parse(localFilterDataForInterview);
                    if (data) {
                      props.getTaskFilterHandler({
                        type: "interview",
                        current_page: 1,
                        ...data,
                        interviewDate: data.interviewDate
                          ? moment(data.interviewDate).format("YYYY-MM-DD")
                          : "",
                      });
                    } else {
                      props.getTaskFilterHandler({
                        type: "interview",
                        current_page: 1,
                      });
                    }
                  }
                  props.getTaskStatusHandler(val.target.value);
                }}
                value={taskStatus}
                className="radioBtnText"
              >
                <Radio value={true}>Other Task</Radio>
                <Radio value={false}>Interview</Radio>
              </Radio.Group>
            </Col>
            {/* )} */}
          </Row>
        </Col>
      </Row>
      {calendarViewStatus ? (
        <TaskCalendarInterviews
          onClanderView={calendarViewStatus}
          onViewName={taskStatus}
          onTasksData={props.tasksData}
          onCandidateData={props.candidateData}
          onJobsData={props.jobsData}
          employeesData={props.employeesData}
          onCompanyData={props.companyData}
          onSumbitData={props.postTaskHandler}
        />
      ) : (
        <>
          {props.isLoading ? (
            <TaskListView
              onProfile={props.onProfile && props.onProfile}
              renderData={(data) => {
                setInterAndTaskDetals(data);
              }}
              displayModal={(data) => {
                props.getTaskByIdHandler(
                  data,
                  { link: taskStatus ? 0 : 1 },
                  (success) => {
                    // debugger;
                    if (success) {
                      if (taskStatus) {
                        setModalName("display");
                        setDrawer(true);
                      } else {
                        setModalName("display");
                        setInterviewDetailsDrawer(true);
                      }
                    }
                  }
                );
              }}
              settingsData={props.settings}
              userIdData={fetchId}
              editData={(val) => {
                props.getTaskByIdHandler(
                  val.id,
                  { link: taskStatus ? 0 : 1 },
                  (success) => {
                    // debugger;
                    if (success) {
                      if (taskStatus) {
                        setModalName(val.modal);
                        setDrawer(true);
                      } else {
                        setModalName("edit");
                        // setInterviewDetailsDrawer(true);
                        setScheduleDrawer(true);
                      }
                    }
                  }
                );
              }}
              onDeleteData={(id) => {
                props.deleteTaskHandler(
                  id,
                  { link: taskStatus ? 0 : 1 },
                  (success) => {
                    if (success) {
                      let interviewFilter = JSON.parse(
                        localStorage.getItem("interviewFilterData")
                      );
                      let taskFilter = JSON.parse(
                        localStorage.getItem("taskFilterData")
                      );
                      if (taskStatus) {
                        if (taskFilter) {
                          props.getTaskFilterHandler({
                            type: "task",
                            current_page: 1,
                            ...taskFilter,
                          });
                        } else {
                          props.getTaskFilterHandler({
                            type: "task",
                            current_page: 1,
                          });
                        }
                      } else {
                        if (interviewFilter) {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                            ...interviewFilter,
                          });
                        } else {
                          props.getTaskFilterHandler({
                            type: "interview",
                            current_page: 1,
                          });
                        }
                      }
                      // props.getTaskFilterHandler({
                      //   type: taskStatus ? "task" : "interview",
                      //   current_page: 1,
                      // });
                    }
                  }
                );
              }}
              taskStatus={taskStatus}
              taskData={props.tasksData}
              pagnationData={(val) => {
                const data = {
                  ...cfilter,
                  ...val,
                  type: taskStatus ? "task" : "interview",
                };
                props.getTaskFilterHandler(data);
              }}
              statusData={(val) => {
                props.putTaskHandler(
                  { link: !taskStatus ? 1 : 0, ...val },
                  (success) => {
                    if (success) {
                      if (taskStatus) {
                        let taskFilterDataFromLocal = JSON.parse(
                          localStorage.getItem("taskFilterData")
                        );
                        if (taskFilterDataFromLocal) {
                          props.getTaskFilterHandler({
                            current_page: 1,
                            type: "task",
                            ...taskFilterDataFromLocal,
                          });
                        } else {
                          props.getTaskFilterHandler({
                            current_page: 1,
                            type: "task",
                          });
                        }
                      } else if (!taskStatus) {
                        let interviewFilterDataFromLocal = JSON.parse(
                          localStorage.getItem("interviewFilterData")
                        );
                        if (interviewFilterDataFromLocal) {
                          props.getTaskFilterHandler({
                            current_page: 1,
                            type: "interview",
                            ...interviewFilterDataFromLocal,
                          });
                        } else {
                          props.getTaskFilterHandler({
                            current_page: 1,
                            type: "interview",
                          });
                        }
                      }
                    }
                  }
                );
              }}
            />
          ) : (
            <div
              style={{
                textAlign: "center",
                margin: "10% auto",
              }}
            >
              <LoadingIcon />
            </div>
          )}
        </>
      )}
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    tasksData: state.taskAndCalendar.tasks,
    employeesData: state.employee.employees,
    task: state.taskAndCalendar.taskFilterData,
    jobsData: state.requirements.requirements,
    companyData: state.client.clients,
    candidateData: state.candidates.candidates,
    settings: state.settings.settings,
    filterStatus: state.taskAndCalendar.filterStatus,
    filterStatusVar: state.taskAndCalendar.filterStatusVar,
    permissions: state.authpermissions.permissions,
    ishavepermissions: state.authpermissions.ishavepermissions,
    isLoading: state.taskAndCalendar.loader,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    profileHandler: () => {
      dispatch(profileAction());
    },
    getTaskFilterHandler: (data) => {
      dispatch(getTasksFilterAction(data));
    },
    postTaskHandler: (data, callback) => {
      dispatch(postTaskAndCalendarAction(data, callback));
    },
    putTaskHandler: (data, callback) => {
      dispatch(putTaskAndCalendarAction(data, callback));
    },
    deleteTaskHandler: (id, link, callback) => {
      dispatch(deleteTaskAndCalendarAction(id, link, callback));
    },
    getTaskByIdHandler: (id, link, callback) => {
      dispatch(getTaskAndCalendarAction(id, link, callback));
    },
    getEmployeeHandler: () => {
      dispatch(getEmployeesAction());
    },
    getCompanyHandler: () => {
      dispatch(getClientsAction());
    },
    getCandidateHandler: () => {
      dispatch(getCandidatesAction());
    },
    getJobNameHandler: () => {
      dispatch(getRequirementsAction());
    },
    getFilterStatusHandler: (data) => {
      dispatch(filterStatusSlice(data));
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
    getTaskStatusHandler: (data) => {
      dispatch(getTaskStatusSlice(data));
    },
    getTaskStatusVarHandler: (data) => {
      dispatch(getTaskStatusVarSlice(data));
    },
    loaderHandler: (data) => {
      dispatch(loaderSlice(data));
    },
    postInterviewPerformanceRatingHandler: (data, callback) => {
      dispatch(postCompletedInterviewPerformanceAction(data, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskCalendarTabs);
