import {
  Drawer,
  Form,
  Input,
  Button,
  Tag,
  Select,
  InputNumber,
  Switch,
  Row,
  Col,
  DatePicker,
  Radio,
} from "antd";

import { CloseIcon, FilterOutlinedIcon } from "../../../IconsComp/Icons";
import commonStyles from "../../../UI/CommonCss.module.css";
import styles from "../../../Screens/ClientCompany/ClientCompany.module.css";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
  getCandidatesAction,
  getCandidatesFilterAction,
} from "../../../../Reusable/Store/Action/CandidatesAction";
import moment from "moment";
import {
  getTagAction,
  getTagsAction,
} from "../../../../Reusable/Store/Action/Master/MasterTagsAction";
import ResetButton from "../../../UI/ResetButton";
import { useLocation } from "react-router";
const { Option } = Select;

const tagRenderForMobileNum = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

const tagRenderForSkills = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

const tagRender = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

function Filter(props) {
  const [filterDatas, setFilterDatas] = useState({
    name: "",
    email: "",
    phone: "",
    skills: "",
  });

  const location = useLocation();

  const [restBtnLocation, setRestBtnLocation] = useState(false);
  const [restBtnExperience, setRestBtnExperience] = useState(false);
  const [restBtnSalary, setRestBtnSalary] = useState(false);
  const [restBtnAge, setRestBtnAge] = useState(false);
  const [jobAssigned, setJobAssigned] = useState("");
  const [emailUnique, setUniqueEmail] = useState([]);
  const [candidateNameUnique, setUniqueCandidateName] = useState([]);
  const [phoneUnique, setUniquePhone] = useState([]);

  useEffect(() => {
    if (props.candidates && props.candidates) {
      let uniqueCandidateName = new Set(
        props.candidates.map((data) => data.name)
      );
      let uniqueEmail = new Set(props.candidates.map((data) => data.email));
      let uniquePhone = new Set(props.candidates.map((data) => data.phone));
      setUniqueCandidateName([...uniqueCandidateName]);
      setUniqueEmail([...uniqueEmail]);
      setUniquePhone([...uniquePhone]);
    }
  }, [props.candidates]);

  const dateFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const [form] = Form.useForm();

  const resetFilter = () => {
    form.resetFields();
    const data = {
      current_page: 1,
    };
    props.candidateFilterHandleer(data);
  };

  const [checkedSpecialAbilities, setCheckedSpecialAbilities] = useState(false);

  useEffect(() => {
    props.getCandidateDetailsHandler({});
    props.getTagHandler();
  }, []);

  // useEffect(() => {
  //   const obj = JSON.parse(localStorage.getItem("candidateDetail"));
  //   if (
  //     obj
  //     // &&
  //     // location.state &&
  //     // location.state.from &&
  //     // location.state.from !== "fromRequirement" &&
  //     // location.state &&
  //     // location.state.from &&
  //     // location.state.from !== "fromRecuritment"
  //   ) {
  //     form.setFieldsValue({
  //       age: obj.age == "" || obj.age == null ? undefined : obj.age,
  //       appliedDate: obj.appliedDate ? moment(obj.appliedDate) : "",
  //       email: obj.email == "" ? undefined : obj.email,
  //       experience: obj.experience,
  //       jobAssigned: obj.jobAssigned,
  //       location: obj.location,
  //       name: obj.name == "" ? undefined : obj.name,
  //       phone: obj.phone == "" ? undefined : obj.phone,
  //       salary: obj.salary,
  //       skills: obj.skills == "" ? undefined : obj.skills,
  //       specialAbility: obj.specialAbility,
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (
      location.state &&
      location.state.from &&
      location.state.from === "fromRequirement"
    ) {
      const objRequirement = JSON.parse(
        localStorage.getItem("candidateDetailFromRequirement")
      );
      if (objRequirement) {
        form.setFieldsValue({
          age: objRequirement.age == "" ? undefined : objRequirement.age,
          appliedDate:
            objRequirement.appliedDate == ""
              ? moment(objRequirement.appliedDate)
              : "",
          email: objRequirement.email == "" ? undefined : objRequirement.email,
          experience: objRequirement.experience,
          jobAssigned: objRequirement.jobAssigned,
          location: objRequirement.location,
          name: objRequirement.name == "" ? undefined : objRequirement.name,
          phone: objRequirement.phone == "" ? undefined : objRequirement.phone,
          salary: objRequirement.salary,
          skills:
            objRequirement.skills == "" ? undefined : objRequirement.skills,
          specialAbility: objRequirement.specialAbility,
        });
        setCheckedSpecialAbilities(
          objRequirement.specialAbility == "1" ? true : false
        );
      }
    } else if (
      location.state &&
      location.state.from &&
      location.state.from === "fromRecuritment"
    ) {
      const objRecruitment = JSON.parse(
        localStorage.getItem("candidateDetailFromRecruitment")
      );
      if (objRecruitment) {
        form.setFieldsValue({
          age: objRecruitment.age == "" ? undefined : objRecruitment.age,
          appliedDate:
            objRecruitment.appliedDate == ""
              ? moment(objRecruitment.appliedDate)
              : "",
          email: objRecruitment.email == "" ? undefined : objRecruitment.email,
          experience: objRecruitment.experience,
          jobAssigned: objRecruitment.jobAssigned,
          location: objRecruitment.location,
          name: objRecruitment.name == "" ? undefined : objRecruitment.name,
          phone: objRecruitment.phone == "" ? undefined : objRecruitment.phone,
          salary: objRecruitment.salary,
          skills:
            objRecruitment.skills == "" ? undefined : objRecruitment.skills,
          specialAbility: objRecruitment.specialAbility,
        });
        setCheckedSpecialAbilities(
          objRecruitment.specialAbility == "1" ? true : false
        );
      }
    } else {
      const obj = JSON.parse(localStorage.getItem("candidateDetail"));
      if (
        obj
        // &&
        // location.state &&
        // location.state.from &&
        // location.state.from !== "fromRequirement" &&
        // location.state &&
        // location.state.from &&
        // location.state.from !== "fromRecuritment"
      ) {
        form.setFieldsValue({
          age: obj.age == "" || obj.age == null ? undefined : obj.age,
          appliedDate: obj.appliedDate ? moment(obj.appliedDate) : "",
          email: obj.email == "" ? undefined : obj.email,
          experience: obj.experience,
          jobAssigned: obj.jobAssigned,
          location: obj.location,
          name: obj.name == "" ? undefined : obj.name,
          phone: obj.phone == "" ? undefined : obj.phone,
          salary: obj.salary,
          skills: obj.skills == "" ? undefined : obj.skills,
          specialAbility: obj.specialAbility,
        });
        setCheckedSpecialAbilities(obj.specialAbility == "1" ? true : false);
      }
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     location.state &&
  //     location.state.from &&
  //     location.state.from === "fromRecuritment"
  //   ) {
  //     const objRecruitment = JSON.parse(
  //       localStorage.getItem("candidateDetailFromRecruitment")
  //     );
  //     if (objRecruitment) {
  //       form.setFieldsValue({
  //         age: objRecruitment.age == "" ? undefined : objRecruitment.age,
  //         appliedDate:
  //           objRecruitment.appliedDate == ""
  //             ? moment(objRecruitment.appliedDate)
  //             : "",
  //         email: objRecruitment.email == "" ? undefined : objRecruitment.email,
  //         experience: objRecruitment.experience,
  //         jobAssigned: objRecruitment.jobAssigned,
  //         location: objRecruitment.location,
  //         name: objRecruitment.name == "" ? undefined : objRecruitment.name,
  //         phone: objRecruitment.phone == "" ? undefined : objRecruitment.phone,
  //         salary: objRecruitment.salary,
  //         skills:
  //           objRecruitment.skills == "" ? undefined : objRecruitment.skills,
  //         specialAbility: objRecruitment.specialAbility,
  //       });
  //     }
  //   }
  // }, []);

  const onFinish = (values) => {
    const data = {
      name: values.name && values.name.length > 0 ? values.name : "",
      email: values.email && values.email.length > 0 ? values.email : "",
      phone: values.phone && values.phone.length > 0 ? values.phone : "",
      skills: values.skills && values.skills.length > 0 ? values.skills : "",
      location: values.location ? values.location : "",
      // tags: values.tags ? values.tags : "",
      experience: values.experience ? values.experience : "",
      appliedDate: values.appliedDate
        ? moment(values.appliedDate).format("YYYY-MM-DD")
        : "",
      salary: values.salary ? values.salary : "",
      specialAbility: checkedSpecialAbilities ? "1" : "",
      age: values.age ? values.age : "",
      jobAssigned: jobAssigned,
    };
    if (
      data.name &&
      data.email &&
      data.phone &&
      data.skills &&
      data.location &&
      // data.tags &&
      data.experience &&
      data.appliedDate &&
      data.salary &&
      data.specialAbility &&
      data.age === ""
    ) {
      props.candidateFilterHandleer({
        current_page: 1,
      });
      setCheckedSpecialAbilities(false);
      setJobAssigned("");
      props.onCloseFilter();
    } else {
      props.candidateFilterHandleer({
        current_page: 1,
        ...data,
      });
      props.onCloseFilter();
    }
  };

  return (
    <>
      <Drawer
        className="modalModified"
        closable={false}
        extra={
          <div
            onClick={() => props.onCloseFilter()}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
          </div>
        }
        width={503}
        height={48}
        title={
          <>
            <Row>
              <Col>
                <FilterOutlinedIcon color={"var(--primaryColor)"} />
              </Col>
              <Col>
                <p
                  style={{ margin: "0px 0px 0px 5px" }}
                  className={commonStyles.filterHeadingName}
                >
                  Filter
                </p>
              </Col>
            </Row>
          </>
        }
        // onClose={props.onCloseFilter}
        open={props.onOpenFilter}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row style={{ padding: "0px 20px 80px 20px" }}>
            <Col md={24}>
              <Form.Item label="Candidate Name" name="name">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  onKeyUp={(val) => {
                    setFilterDatas({
                      name: val.target.value,
                    });
                  }}
                  showSearch
                  notFoundContent={null}
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{
                    width: "100%",
                  }}
                  mode="multiple"
                  placeholder="Select candidate name"
                  tagRender={tagRenderForMobileNum}
                >
                  {filterDatas.name &&
                    filterDatas.name !== " " &&
                    filterDatas.name.length > 0 &&
                    candidateNameUnique &&
                    candidateNameUnique.map((data, index) => {
                      return (
                        <Select.Option key={index} value={data}>
                          {data}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Email" name="email">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  onKeyUp={(val) => {
                    setFilterDatas({
                      email: val.target.value,
                    });
                  }}
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // className={"candidateSelectHeight"}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select email"
                  tagRender={tagRenderForMobileNum}
                >
                  {filterDatas.email &&
                    filterDatas.email !== " " &&
                    filterDatas.email.length > 0 &&
                    emailUnique &&
                    emailUnique.map((data, index) => {
                      return (
                        <Select.Option key={index} value={data}>
                          {data}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item name="jobAssigned" label="Job Assigned">
                <Radio.Group
                  onChange={(val) => {
                    setJobAssigned(val.target.value);
                  }}
                  className="radioBtnText"
                >
                  <Radio value="1">Yes</Radio>
                  <Radio value="0">No</Radio>
                  <Radio value="">All</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item label="Mobile Number" name="phone">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  // className={"candidateSelectHeight"}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  onKeyUp={(val) => {
                    setFilterDatas({ phone: val.target.value });
                  }}
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select mobile number"
                  tagRender={tagRenderForMobileNum}
                >
                  {filterDatas.phone &&
                    filterDatas.phone !== " " &&
                    filterDatas.phone.length > 0 &&
                    phoneUnique &&
                    phoneUnique.map((data, index) => {
                      return (
                        <Select.Option key={index} value={data}>
                          {data}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

              {/* <Form.Item label="Tags" name="tags">
                <Select
                  className={"candidateSelectHeight"}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select tag"
                  tagRender={tagRender}
                >
                  {props.TagsData &&
                    props.TagsData.filter(
                      (data) => data.type === "candidate"
                    ).map((data) => {
                      return (
                        <Option key={data.id} value={data.tag}>
                          {data.tag}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item> */}

              <Form.Item label="Key Skills/Tag" name="skills">
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  // className={"candidateSelectHeight"}
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  onKeyUp={(val) => {
                    setFilterDatas({ skills: val.target.value });
                  }}
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select skills"
                  tagRender={tagRenderForSkills}
                >
                  {filterDatas.skills &&
                    filterDatas.skills !== " " &&
                    filterDatas.skills.length > 0 &&
                    props.TagsData &&
                    props.TagsData.filter(
                      (data) => data.type === "candidate"
                    ).map((data) => {
                      return (
                        <Option key={data.id} value={data.tag}>
                          {data.tag}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Location" name="location">
                <Input
                  onChange={() => {
                    if (!restBtnLocation) {
                      setRestBtnLocation(true);
                    }
                  }}
                  // className={"CandidateInputHeight"}
                  placeholder="Enter location"
                />
              </Form.Item>

              {restBtnLocation && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      location: "",
                    });
                    setRestBtnLocation(false);
                  }}
                />
              )}

              <Form.Item label="Experience" name="experience">
                <Input
                  onChange={() => {
                    if (!restBtnExperience) {
                      setRestBtnExperience(true);
                    }
                  }}
                  // className={"CandidateInputHeight"}
                  placeholder="Enter experience"
                />
              </Form.Item>

              {restBtnExperience && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      experience: "",
                    });
                    setRestBtnExperience(false);
                  }}
                />
              )}

              <Form.Item label="Applied Date" name="appliedDate">
                <DatePicker
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placeholder="Select date"
                  format={dateFormate}
                  style={{ width: "100%" }}
                  className={`${commonStyles.datePickerBgColor} `} /* CandidateInputHeight */
                />
              </Form.Item>

              <Form.Item label="Salary (CTC in Lakhs)" name="salary">
                <Input
                  onChange={() => {
                    if (!restBtnSalary) {
                      setRestBtnSalary(true);
                    }
                  }}
                  // className={"CandidateInputHeight"}
                  type="number"
                  placeholder="Enter salary"
                />
                {/* <Select
                  className={"candidateSelectHeight"}
                  placeholder="Select salary"
                >
                  <Select.Option value="2-2.5LPA">2-2.5LPA</Select.Option>
                  <Select.Option value="2.6-3LPA">2.6-3LPA</Select.Option>
                  <Select.Option value="3.1-3.5LPA">3.1-3.5LPA</Select.Option>
                  <Select.Option value="3.6-4LPA">3.6-4LPA</Select.Option>
                  <Select.Option value="4.1-4.5LPA">4.1-4.5LPA</Select.Option>
                </Select> */}
              </Form.Item>
              {restBtnSalary && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      salary: "",
                    });
                    setRestBtnSalary(false);
                  }}
                />
              )}
              {/* <Row align="middle" justify="space-between">
                <Col md={11}>
                  <Form.Item label="Min Salary (CTC in Lakhs)">
                    <Input
                      // className={"CandidateInputHeight"}
                      type="number"
                      placeholder="Enter min salary"
                    />
                  </Form.Item>
                </Col>
                <Col md={11}>
                  <Form.Item label="Max Salary (CTC in Lakhs)">
                    <Input
                      // className={"CandidateInputHeight"}
                      type="number"
                      placeholder="Enter max salary"
                    />
                  </Form.Item>
                </Col>
              </Row> */}

              <Row align="middle" style={{ gap: "17px" }}>
                <Col>
                  <p className={`${commonStyles.formInputLabel} `}>
                    Special abilities
                  </p>
                </Col>
                <Col>
                  <Form.Item name="specialAbility">
                    <Switch
                      onChange={(val) => {
                        setCheckedSpecialAbilities(val);
                      }}
                      checked={checkedSpecialAbilities}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label="Age" name="age">
                <InputNumber
                  onChange={() => {
                    if (!restBtnAge) {
                      setRestBtnAge(true);
                    }
                  }}
                  // className="inputNumberHeight"
                  placeholder="Enter age"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              {restBtnAge && (
                <ResetButton
                  onClearData={() => {
                    form.setFieldsValue({
                      age: "",
                    });
                    setRestBtnAge(false);
                  }}
                />
              )}
            </Col>
          </Row>

          <Col className={commonStyles.footerShadow} style={{ width: "503px" }}>
            <Row justify="end" style={{ gap: "25px" }}>
              <Col>
                <Button
                  className={commonStyles.buttonSettingsSecondary}
                  type="default"
                  onClick={() => {
                    setRestBtnLocation(false);
                    setRestBtnExperience(false);
                    setRestBtnSalary(false);
                    setRestBtnAge(false);
                    resetFilter();
                    setFilterDatas({
                      name: "",
                      email: "",
                      phone: "",
                      skills: "",
                    });
                    localStorage.removeItem("candidateDetail");
                    localStorage.removeItem("candidateSort");
                    setCheckedSpecialAbilities(false);
                    props.onCloseFilter("candidateDetail");
                  }}
                >
                  Reset Filter
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    candidates: state.candidates.candidates,
    candidatesFilterData: state.candidates.candidatesFilter,
    TagsData: state.tags.tags,
    settindsData: state.settings.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCandidateDetailsHandler: () => {
      dispatch(getCandidatesAction());
    },
    getCandidateFilterHandler: (val) => {
      dispatch(getCandidatesFilterAction(val));
    },
    getTagHandler: () => {
      dispatch(getTagsAction());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
