import {
  Drawer,
  Form,
  Input,
  Button,
  Tag,
  Select,
  Row,
  Col,
  Switch,
} from "antd";
import { useEffect, useState } from "react";

import { CloseIcon, FilterOutlinedIcon } from "../../IconsComp/Icons";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import commonStyles from "../../UI/CommonCss.module.css";
import ResetButton from "../../UI/ResetButton";

const { Option } = Select;

const tagRenderForMobileNum = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const resetFilter = () => {
    // form.resetFields();
    // props.candidateFilterHandleer({});
  };

  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

function RequirementFilter(props) {
  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="var(--primaryColor)"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          padding: "5px 17px 5px 23px",
        }}
      >
        {label}
      </Tag>
    );
  };

  const [form] = Form.useForm();
  const [experienceToMonth, setExperienceToMonth] = useState(false);
  const [experienceFromMonth, setExperienceFromMonth] = useState(false);
  const [location, setLocation] = useState(false);
  const [salary, setSalary] = useState(false);
  const [publisedJob, setPulishedJobs] = useState(false);
  const [draftJob, setDraftJobs] = useState(false);
  const [titleUnique, setUniqueTitle] = useState([]);
  const [filterDatas, setFilterDatas] = useState({
    title: "",
    jobCode: "",
    role: "",
    department: "",
    skills: "",
  });

  useEffect(() => {
    if (props.requirements && props.requirements) {
      let uniqueTitle = new Set(props.requirements.map((data) => data.title));
      setUniqueTitle([...uniqueTitle]);
    }
  }, [props.requirements]);

  useEffect(() => {
    let localFilterData = localStorage.getItem("requirementFilterData");
    if (localFilterData) {
      let data = JSON.parse(localFilterData);
      form.setFieldsValue({
        title: data.title,
        role: data.role,
        jobType: data.jobType,
        jobCode: data.jobCode,
        experienceToMonth: data.experienceToMonth,
        experienceFromMonth: data.experienceFromMonth,
        department: data.department,
        skills: data.skills,
        location: data.location,
        salary: data.salary,
        status: data.status,
        jobStatus: data.jobStatus,
      });
      setPulishedJobs(data.publishedJobs);
      setDraftJobs(data.draftJobs);
    }
  }, []);

  const departmentData = ["Design"];

  const handleChangeForMobile = (value) => {};

  const resetFilter = () => {
    form.resetFields();
  };

  const onFinish = (values) => {
    const data = {
      ...values,
      publishedJobs: publisedJob ? 1 : "",
      draftJobs: draftJob ? 1 : "",
    };
    let requirementFilter = JSON.parse(
      localStorage.getItem("requirementFilterData")
    );
    localStorage.setItem(
      "requirementFilterData",
      JSON.stringify({
        ...requirementFilter,
        ...form.getFieldsValue(),
        publishedJobs: publisedJob ? 1 : "",
        draftJobs: draftJob ? 1 : "",
      })
    );
    props.requirementFilterHandleer(data);
    props.onCloseFilter();
  };

  return (
    <>
      <Drawer
        closable={false}
        className="modalModified"
        extra={
          <div
            onClick={() => props.onCloseFilter()}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
          </div>
        }
        width={503}
        height={48}
        title={
          <>
            <Row>
              <Col>
                <FilterOutlinedIcon color={"var(--primaryColor)"} />
              </Col>
              <Col>
                <p
                  style={{ fontSize: "20px", margin: "0px 0px 0px 5px" }}
                  className={styles.txtBlueClr}
                >
                  Filter
                </p>
              </Col>
            </Row>{" "}
          </>
        }
        // footer={false}
        // onClose={props.onCloseFilter}
        open={props.onOpenFilter}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row
            className={styles.modalRowStyling}
            style={{ marginBottom: "80px" }}
          >
            <Col flex={1}>
              <Row>
                <Col flex={1}>
                  <Form.Item name="title" label="Job Title">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      // mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      className={`${commonStyles.border} candidateSelectHeight`}
                      placeholder="Select title"
                      tagRender={tagRenderForMobileNum}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      notFoundContent={null}
                      showSearch
                      onKeyUp={(val) => {
                        setFilterDatas({
                          title: val.target.value,
                        });
                      }}
                    >
                      {/* {filterDatas.title &&
                        filterDatas.title !== " " &&
                        filterDatas.title.length > 0 &&
                        props.requirements &&
                        props.requirements &&
                        props.requirements.map((data, index) => {
                          return (
                            <Option key={index} value={data.title}>
                              {data.title}
                            </Option>
                          );
                        })} */}
                      {filterDatas.title &&
                        filterDatas.title !== " " &&
                        filterDatas.title.length > 0 &&
                        titleUnique &&
                        titleUnique.map((data, index) => {
                          return (
                            <Option key={index} value={data}>
                              {data}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item name="role" label="Job Role">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      // mode="multiple"
                      showSearch
                      notFoundContent={null}
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      className={`${commonStyles.border} candidateSelectHeight`}
                      placeholder="Select job role"
                      tagRender={tagRenderForMobileNum}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onKeyUp={(val) => {
                        setFilterDatas({
                          role: val.target.value,
                        });
                      }}
                    >
                      {filterDatas.role &&
                        filterDatas.role !== " " &&
                        filterDatas.role.length > 0 &&
                        props.requirements &&
                        props.requirements &&
                        props.requirements.map((data, index) => {
                          return (
                            <Option key={index} value={data.role}>
                              {data.role}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item name="jobType" label="Job Type">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select job type"
                      className={`${commonStyles.border} candidateSelectHeight`}
                    >
                      <Select.Option value="full time">Full Time</Select.Option>
                      <Select.Option value="Internship">
                        Internship
                      </Select.Option>
                      <Select.Option value="part time">Part Time</Select.Option>
                      <Select.Option value="Contract">Contract</Select.Option>
                      <Select.Option value="Temporary">Temporary</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item name="jobCode" label="Job Id">
                    <Select
                      placeholder="Select job id"
                      showSearch
                      allowClear
                      notFoundContent={null}
                      style={{
                        width: "100%",
                      }}
                      className={`${commonStyles.border} candidateSelectHeight`}
                      onKeyUp={(val) => {
                        setFilterDatas({
                          jobCode: val.target.value,
                        });
                      }}
                    >
                      {filterDatas.jobCode &&
                        filterDatas.jobCode !== " " &&
                        filterDatas.jobCode.length > 0 &&
                        props.requirements &&
                        props.requirements &&
                        props.requirements.map((data, index) => {
                          return (
                            <Option key={index} value={data.jobCode}>
                              {data.jobCode}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item
                    label="Min Experience (in years)"
                    name="experienceToMonth"
                  >
                    <Input
                      onChange={() => {
                        if (!experienceToMonth) {
                          setExperienceToMonth(true);
                        }
                      }}
                      type="number"
                      placeholder="Enter min experience"
                      className={"CandidateInputHeight"}
                    />
                  </Form.Item>
                  {experienceToMonth && (
                    <ResetButton
                      onClearData={() => {
                        form.setFieldsValue({
                          experienceToMonth: "",
                        });
                        setExperienceToMonth(false);
                      }}
                    />
                  )}
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Max Experience (in years)"
                    name="experienceFromMonth"
                  >
                    <Input
                      onChange={() => {
                        if (!experienceFromMonth) {
                          setExperienceFromMonth(true);
                        }
                      }}
                      type="number"
                      placeholder="Enter max experience"
                      className={"CandidateInputHeight"}
                    />
                  </Form.Item>
                  {experienceFromMonth && (
                    <ResetButton
                      onClearData={() => {
                        form.setFieldsValue({
                          experienceFromMonth: "",
                        });
                        setExperienceFromMonth(false);
                      }}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item name="department" label="Department">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      allowClear
                      showSearch
                      notFoundContent={null}
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select department"
                      className={`${commonStyles.border} candidateSelectHeight`}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onKeyUp={(val) => {
                        setFilterDatas({
                          department: val.target.value,
                        });
                      }}
                    >
                      {filterDatas.department &&
                        filterDatas.department !== " " &&
                        filterDatas.department.length > 0 &&
                        props.departments &&
                        props.departments &&
                        props.departments.map((data, index) => {
                          return (
                            <Option key={index} value={data.departmentName}>
                              {data.departmentName}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item name="skills" label="Skills">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      showSearch
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      notFoundContent={null}
                      placeholder="Select skills"
                      tagRender={tagRender}
                      className={`${commonStyles.border} candidateSelectHeight`}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onKeyUp={(val) => {
                        setFilterDatas({
                          skills: val.target.value,
                        });
                      }}
                    >
                      {filterDatas.skills &&
                        filterDatas.skills !== " " &&
                        filterDatas.skills.length > 0 &&
                        props.tagsData &&
                        props.tagsData
                          .filter((data) => data.type === "skill")
                          .map((data) => {
                            return (
                              <Select.Option key={data.id} value={data.tag}>
                                {data.tag}
                              </Select.Option>
                            );
                          })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item name="location" label="Location">
                    {/* <Select
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select company name"
                    className={`${commonStyles.border} candidateSelectHeight`}
                    >
                     {props.filterData && props.filterData.data &&
                    props.filterData.data.map((data, index) => {
                      return (
                        <Option key={index} value={data.locationDetails}>
                          {data.locationDetails}
                        </Option>
                      );
                    })}
                    </Select> */}
                    <Input
                      onChange={() => {
                        if (!location) {
                          setLocation(true);
                        }
                      }}
                      placeholder="Enter location"
                      className={"CandidateInputHeight"}
                    />
                  </Form.Item>
                  {location && (
                    <ResetButton
                      onClearData={() => {
                        form.setFieldsValue({
                          location: "",
                        });
                        setLocation(false);
                      }}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item name="salary" label="Min Salary (CTC in Lakhs)">
                    {/* <Select 
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select company name"
                    className={`${commonStyles.border} candidateSelectHeight`}
                    >
                     {props.filterData && props.filterData.data &&
                    props.filterData.data.map((data, index) => {
                      return (
                        <Option key={index} value={data.maxSalary}>
                          {data.maxSalary}
                        </Option>
                      );
                    })}
                    </Select> */}
                    <Input
                      onChange={() => {
                        if (!salary) {
                          setSalary(true);
                        }
                      }}
                      type="number"
                      placeholder="Enter salary"
                      className={"CandidateInputHeight"}
                    />
                  </Form.Item>
                  {salary && (
                    <ResetButton
                      onClearData={() => {
                        form.setFieldsValue({
                          salary: "",
                        });
                        setSalary(false);
                      }}
                    />
                  )}
                </Col>
              </Row>

              {/* <Row justify="space-between">
                 <Col span={11}>
                  <Form.Item
                    label="Min Salary"
                    name="minSalary"
                    rules={[
                      {
                        required: true,
                        message: "Please enter max salary",
                      },
                    ]}
                  >
                    <Input placeholder="Enter min salary" />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Max Salary"
                    name="maxSalary"
                    rules={[
                      {
                        required: true,
                        message: "Please enter min salary",
                      },
                    ]}
                  >
                    <Input placeholder="Enter max salary" />
                  </Form.Item>
                </Col>
              </Row> */}

              <Row>
                <Col flex={1}>
                  <Form.Item name="status" label="Status">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select status"
                      className={`${commonStyles.border} candidateSelectHeight`}
                    >
                      <Select.Option value="open">open</Select.Option>
                      <Select.Option value="closed">closed</Select.Option>
                      <Select.Option value="on hold">on hold</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ gap: "30px" }}>
                <Col>
                  <Row align="middle" style={{ gap: "10px" }}>
                    <Col>
                      <p className={`${commonStyles.formInputLabel} `}>
                        Publish Jobs
                      </p>
                    </Col>
                    <Col>
                      <Form.Item name="jobStatus">
                        <Switch
                          onChange={(val) => {
                            setPulishedJobs(val);
                            {
                              val == true
                                ? setDraftJobs(false)
                                : setDraftJobs(true);
                            }
                          }}
                          checked={publisedJob}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row align="middle" style={{ gap: "10px" }}>
                    <Col>
                      <p className={`${commonStyles.formInputLabel} `}>
                        Draft Jobs
                      </p>
                    </Col>
                    <Col>
                      <Form.Item name="jobStatus">
                        <Switch
                          onChange={(val) => {
                            setDraftJobs(val);
                            {
                              val == true
                                ? setPulishedJobs(false)
                                : setPulishedJobs(true);
                            }
                          }}
                          checked={draftJob}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Col className={commonStyles.footerShadow} style={{ width: "503px" }}>
            <Row justify="end" style={{ gap: "25px" }}>
              <Col>
                <Button
                  className={commonStyles.buttonSettingsSecondary}
                  type="default"
                  onClick={() => {
                    setExperienceToMonth(false);
                    setExperienceFromMonth(false);
                    setLocation(false);
                    setSalary(false);
                    resetFilter();
                    setFilterDatas({
                      title: "",
                      jobCode: "",
                      role: "",
                      department: "",
                      skills: "",
                    });
                    localStorage.removeItem("requirementFilterData");
                    localStorage.removeItem("fromWhichPage");
                    localStorage.removeItem("requirementSort");
                    props.requirementFilterHandleer({
                      current_page: props.filterData.current_page,
                    });
                    props.onCloseFilter();
                  }}
                >
                  Reset Filter
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    localStorage.setItem(
                      "fromWhichPage",
                      JSON.stringify({
                        fromRequirement: "fromRequirement",
                      })
                    );
                    form.submit();
                  }}
                  htmlType="button"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
}

export default RequirementFilter;
