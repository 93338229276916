import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Checkbox,
  DatePicker,
  message,
  Image,
} from "antd";
import React, { useEffect } from "react";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import commonStyles from "../../UI/CommonCss.module.css";
import pdf from "../../../Assets/Image/pdf.png";
import word from "../../../Assets/Image/word.png";
import commonCssStyles from "../../UI/CommonCss.module.css";

//flag import

import "antd-country-phone-input/dist/index.css";
import "flagpack/dist/flagpack.css";

import {
  UploadIcon,
  CloseIcon,
  CurrencyInr,
  DownLoadIcon,
  MinusIcons,
  PlusForButtonIcon,
} from "../../IconsComp/Icons";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useRef } from "react";
import PhoneInput from "react-phone-number-input";
import moment from "moment";
import { connect } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { getCountriesAction } from "../../../Reusable/Store/Action/Master/MasterCountryAction";
//flag import end

const ClientCompanyDrawer = (props) => {
  const [sameasOfficial, setSameasOfficial] = useState(false);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [form] = useForm();
  const [filesData, setFilesData] = useState(null);
  const [cityStatus, setCityStatus] = useState(false);
  const [fileNameData, setFileNameData] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [dateCheck, setDateCheck] = useState(true);

  let btnref = useRef(null);

  const [fileResult, setFileResult] = useState(false);

  const [fileType, setFileType] = useState("");
  const [dragActive, setDragActive] = useState(false);

  const onChangeCountry = (val, data) => {
    props.getCountriesHandler({ id: data.id, type: "state" }, (data, type) => {
      setStates(data);
    });
    form.setFieldsValue({
      billingCity: initvalues.billingCity,
      billingState: initvalues.billingState,
    });
    setCity([]);
  };

  const onChangeState = (value, data) => {
    props.getCountriesHandler({ id: data.id, type: "city" }, (data, type) => {
      setCity(data);
    });
    form.setFieldsValue({
      billingCity: initvalues.billingCity,
    });
  };

  // const onChangeCountry = (value) => {
  //   setState(
  //     props.countriesData &&
  //       props.countriesData.find(
  //         (x) => x.name.toLowerCase() === value.toLowerCase()
  //       ).state
  //   );
  //   setTimeout(() => {
  //     if (state || state.length !== 0) {
  //       form.setFieldsValue({
  //         billingCity: initvalues.billingCity,
  //         billingState: initvalues.billingState,
  //       });
  //       setCity([]);
  //     }
  //   }, 300);
  // };

  // const onChangeState = (value) => {
  //   form.setFieldsValue({
  //     billingCity: initvalues.billingCity,
  //   });
  //   let cityData =
  //     state &&
  //     state.find((x) => x.stateName.toLowerCase() === value.toLowerCase()).city;
  //   setCity(cityData);
  //   if (cityData.length === 0 || cityData[0] === "") {
  //     setCityStatus(true);
  //   } else {
  //     setCityStatus(false);
  //   }
  // };

  const uploadDataHandler = (data) => {
    if (!filesData) {
      setFileResult(true);
      return;
    }
    const val = {
      candidateId: props.data.id,
      //uploadType: data.uploadType,
      document: filesData,
    };
    props.onOkHandle(val);
  };

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  useEffect(() => {
    // setTimeout(() => {
    //   if (props.client && props.billingCountry) {
    //     onChangeCountry(props.client.billingCountry);
    //   }
    //   if (
    //     props.client &&
    //     props.client.billingCountry &&
    //     props.client.billingState
    //   ) {
    //     setCity(
    //       props.countriesData &&
    //         props.countriesData
    //           .find(
    //             (x) =>
    //               x.name.toLowerCase() ===
    //               props.client.billingCountry.toLowerCase()
    //           )
    //           .state.find(
    //             (x) =>
    //               x.stateName.toLowerCase() ===
    //               props.client.billingState.toLowerCase()
    //           ).city
    //     );
    //   }
    // }, 500);

    props.getCountriesHandler({ type: "country" }, (data, type) => {
      setCountries(data);
    });
  }, []);
  const handleChange = (info) => {
    setFileResult(false);
    if (!(info.target.files[0].size <= 5000000)) {
      message.warning("This file should be less than 5 MB", 3);
      return;
    }
    setFilesData(info.target.files[0]);
    setFileType(info.target.files[0].type);
    setFileNameData(info.target.files[0].name);
  };

  const onFinish = (val, index) => {
    // if (!filesData) {
    //   setFileResult(true);
    //   return;
    // }

    var data = {
      ...val,
      detailsCheck: sameasOfficial ? "1" : "0",
      clientEmail: !sameasOfficial ? val.clientEmail : val.contactPersonEmail,
      clientPhone: !sameasOfficial ? val.clientPhone : val.contactPersonPhone,
      agreement: filesData ? filesData : "",
      validityStartDate: moment(val.validityStartDate).format("YYYY/MM/DD"),
      validityEndDate: moment(val.validityEndDate).format("YYYY/MM/DD"),
    };
    if (!data.agreement) delete data.agreement;

    props.onSubmit(data);
    form.resetFields();
  };

  const initvalues = {
    clientEmail: "",
    clientPhone: "",
    billingCity: "",
    billingState: "",
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFilesData(e.dataTransfer.files[0]);
      setFileType(e.dataTransfer.files[0].type);
    }
  };

  return (
    <>
      <Drawer
        className={"modalModified"}
        closable={false}
        extra={
          <div
            onClick={() => {
              form.resetFields();
              props.onClose();
            }}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
          </div>
        }
        footer={false}
        style={{
          paddingBottom: "0px",
        }}
        title={
          <p
            style={{ fontSize: "20px", margin: "0px" }}
            className={`${commonStyles.modalHeading}`}
          >
            {!props.client ? "Add Company" : "Edit Company"}
          </p>
        }
        width={726}
        height={48}
        open={props.onOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          onDragEnter={handleDrag}
          form={form}
          initialValues={
            // props.client && {
            //   initvalues,
            //   clientName: props.client.clientName,
            //   clientEmail: props.client.clientEmail,
            //   clientPhone: props.client.clientPhone,
            //   contactPerson: props.client.contactPerson,
            //   contactPersonPhone: props.client.contactPersonPhone,
            //   contactPersonEmail: props.client.contactPersonEmail,
            //   contactPersonDesignation: props.client.contactPersonDesignation,
            //   employeesCount: props.client.employeesCount,
            //   cin: props.client.cin,
            //   tan: props.client.tan,
            //   gstin: props.client.gstin,
            //   pan: props.client.pan,
            //   billingAddress: props.client.billingAddress,
            //   billingCity: props.client.billingCity,
            //   billingState: props.client.billingState,
            //   billingCountry: props.client.billingCountry,
            //   billingPincode: props.client.billingPincode,
            //   agreement: props.client.agreement,
            //   validityStartDate:
            //     props.client.validityStartDate &&
            //     moment(props.client.validityStartDate),
            //   validityEndDate:
            //     props.client.validityEndDate &&
            //     moment(props.client.validityEndDate),
            //   feesType: props.client.feesType,
            //   // fees: props.client.fees,
            //   paymentTerms: props.client.paymentTerms,
            //   replacementClause: props.client.replacementClause,
            // }
            { initvalues, fees: [""] }
          }
          layout="vertical"
          onFinish={onFinish}
        >
          <Row
            className={styles.modalRowStyling}
            style={{ padding: "0px 20px 80px 20px" }}
          >
            <Col flex={1}>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Company Name"
                    name="clientName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter company name",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                      //   message:
                      //     "It allows only alphabets, no whitespace at the beginning and end",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter company name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Contact Person Name"
                    name="contactPerson"
                    rules={[
                      {
                        required: true,
                        message: "Please enter contact person name",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                      //   message:
                      //     "It allows only alphabets, No whitespace at the beginning and end",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter contact person name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Contact Person Email"
                    name="contactPersonEmail"
                    rules={[
                      {
                        required: true,
                        message: "Please enter contact person email",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email",
                      },
                    ]}
                  >
                    <Input placeholder="Enter contact person email" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    name="contactPersonPhone"
                    label="Contact Person Number"
                    type="number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter phone number",
                      },
                    ]}
                  >
                    <PhoneInput
                      placeholder="Enter contact number"
                      className="ant-input ant-cust-inputs"
                      defaultCountry="IN"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Checkbox
                    className="intellcheckbox"
                    onChange={(val) => {
                      setSameasOfficial(val.target.checked);
                      form.setFieldsValue({
                        clientEmail: initvalues.clientEmail,
                        clientPhone: initvalues.clientPhone,
                      });
                    }}
                  >
                    <p className={commonStyles.formInputLabel}>
                      Office Contact details are same as above
                    </p>
                  </Checkbox>
                </Col>
              </Row>
              {!sameasOfficial ? (
                <>
                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        label="Official Email Address"
                        name="clientEmail"
                        rules={[
                          {
                            required: true,
                            message: "Please enter official email address",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email",
                          },
                        ]}
                      >
                        <Input placeholder="Enter official email" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        name="clientPhone"
                        label="Official Contact Number"
                        type="number"
                        rules={[
                          {
                            required: true,
                            message: "Please enter phone number",
                          },
                        ]}
                      >
                        <PhoneInput
                          placeholder="Enter contact number"
                          className="ant-input ant-cust-inputs"
                          defaultCountry="IN"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : (
                ""
              )}
              <Row style={{ marginTop: "3px" }}>
                <Col flex={1}>
                  <Form.Item
                    label="Designation"
                    name="contactPersonDesignation"
                    rules={[
                      {
                        required: true,
                        message: "Please enter designation",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                      //   message:
                      //     "It allows only alphabets, No whitespace at the beginning and end",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter contact person’s designation" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Number of Employee"
                    name="employeesCount"
                    rules={[
                      {
                        required: true,
                        message: "Please enter number of employees",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="Enter number of employees"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="TAN"
                    name="tan"
                    rules={[
                      {
                        required: false,
                        message: "Please enter tan number",
                      },
                      // {
                      //   pattern: /.*\S.*/,
                      //   message: "Whete space are not allowed",
                      // },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Z0-9\s]+$/,
                      //   message: "Invalid TAN",
                      // },
                      // {
                      //   min: 10,
                      //   message:
                      //     "TAN number Should be in 10-digit alpha-numeric format",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter tan number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="CIN"
                    name="cin"
                    rules={[
                      {
                        required: false,
                        message: "Please enter cin number",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Z0-9\s]+$/,
                      //   message: "Invalid CIN",
                      // },

                      // {
                      //   min: 21,
                      //   message:
                      //     "CIN number Should be in 21-digit alpha-numeric format",
                      // },
                      // {
                      //   pattern: /.*\S.*/,
                      //   message: "White space are not allowed",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter cin number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="GST"
                    name="gstin"
                    rules={[
                      {
                        required: false,
                        message: "Please enter gst number",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Z0-9\s]+$/,
                      //   message: "Invalid GSTN",
                      // },

                      // {
                      //   min: 15,
                      //   message:
                      //     "GSTN number Should be in 15-digit alpha-numeric format",
                      // },
                      // {
                      //   pattern: /.*\S.*/,
                      //   message: "White space are not allowed",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter gst" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="PAN"
                    name="pan"
                    rules={[
                      {
                        required: false,
                        message: "Please enter pan number",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Z0-9\s]+$/,
                      //   message: "Invalid PAN",
                      // },

                      // {
                      //   min: 10,
                      //   message:
                      //     "PAN number Should be in 10-digit alpha-numeric format",
                      // },
                      // {
                      //   pattern: /.*\S.*/,
                      //   message: "White space are not allowed",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter pan number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Billing Address"
                    name="billingAddress"
                    rules={[
                      {
                        required: true,
                        message: "Please enter billing address ",
                      },
                    ]}
                  >
                    <TextArea rows={4} placeholder="Enter billing address" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Country"
                    name="billingCountry"
                    rules={[
                      {
                        required: true,
                        message: "Please select country",
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      showSearch
                      onChange={onChangeCountry}
                      placeholder="Select country"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {countries &&
                        countries.length > 0 &&
                        countries.map((data, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={data.name}
                              id={data.id}
                            >
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="State"
                    name="billingState"
                    rules={[
                      {
                        required: true,
                        message: "Please select state",
                      },
                    ]}
                  >
                    {states && states.length === 0 ? (
                      <Input placeholder="Enter state" />
                    ) : (
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        onChange={onChangeState}
                        showSearch
                        placeholder="Select state"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {states &&
                          states.map((data, index) => {
                            return (
                              <Select.Option
                                key={index}
                                value={data.name}
                                id={data.id}
                              >
                                {data.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="City"
                    name="billingCity"
                    rules={[
                      {
                        required: true,
                        message: "Please select city",
                      },
                    ]}
                  >
                    {city.length === 0 ? (
                      <Input placeholder="Enter city" />
                    ) : (
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        showSearch
                        placeholder="Select city"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {city.map((data, index) => {
                          return (
                            <Select.Option value={data.name} key={index}>
                              {data.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="ZIP Code"
                    name="billingPincode"
                    rules={[
                      {
                        required: true,
                        message: "Please enter zip code",
                      },
                      // {
                      //   pattern: /^\d{6}?$/,
                      //   message: "Invalid zip code",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter zip code" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <input
                    hidden
                    accept="application/pdf,.docx"
                    type="file"
                    name="resume"
                    onChange={handleChange}
                    ref={(input) => {
                      btnref = input;
                    }}
                  />
                  <Form.Item name="agreement" label="Service Agreement">
                    {(props.client && props.client.agreement) ||
                    filesData ||
                    dragActive ? (
                      <div
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                        onClick={(e) => {
                          e.preventDefault();
                          btnref.click();
                          return false;
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          backgroundColor: "var(--input-color)",
                          padding: "10px 10px",
                          borderRadius: "6px",
                        }}
                      >
                        <Image
                          preview={false}
                          src={fileType === "application/pdf" ? pdf : word}
                          style={{
                            borderRadius: "6px",
                            position: "relative",
                            width: "30px",
                            display: "block",
                          }}
                        />
                        <p
                          className={commonCssStyles.marginBottomRemove}
                          style={{ color: "var(--body-text-color)" }}
                        >
                          {fileNameData}
                        </p>
                        <div
                          onClick={() => {
                            setFilesData("");
                            setFileResult(true);
                          }}
                          style={{
                            position: "relative",
                            paddingTop: "5px",
                            paddingLeft: "1px",
                            cursor: "pointer",
                          }}
                        >
                          {!props.client ? (
                            <CloseIcon
                              color={"var(--headings-color)"}
                              style={{ height: "2px" }}
                            />
                          ) : (
                            <Row align="middle">
                              <Col>
                                <CloseIcon
                                  color={"var(--headings-color)"}
                                  style={{ height: "2px" }}
                                />
                              </Col>
                              <Col
                                style={{ marginLeft: "570px" }}
                                onClick={() =>
                                  window.open(
                                    props.client && props.client.agreement
                                  )
                                }
                              >
                                <DownLoadIcon color={"var(--primaryColor)"} />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        <>
                          <div
                            onDragOver={(e) => {
                              e.preventDefault();
                              btnref.click();
                              return false;
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              btnref.click();
                              return false;
                            }}
                            style={{
                              textAlign: "center",
                              height: "155px",
                              backgroundColor: "var(--input-color)",
                              borderRadius: "5px",
                            }}
                          >
                            <Row
                              justify="center"
                              style={{ paddingTop: "31px" }}
                            >
                              <Col>
                                <UploadIcon color={"var(--body-text-color)"} />
                              </Col>
                            </Row>
                            <div>
                              <p
                                style={{
                                  fontWeight: 400,
                                  textAlign: "center",
                                  marginTop: "10px",
                                  fontFamily: "Helvetica,ProductSans",
                                  fontStyle: "normal",
                                  fontSize: "16px",
                                  lineHeight: "19px",
                                  color: "var(--body-text-color)",
                                }}
                              >
                                Upload Document&nbsp;( &nbsp;maximum file size 5
                                mb &nbsp;)
                              </p>
                            </div>
                          </div>
                        </>
                      </>
                    )}
                  </Form.Item>
                  {/* {fileResult && (
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#ff4d4f",
                        padding: "0px 0px",
                      }}
                    >
                      Please select file
                    </p>
                  )} */}
                </Col>
              </Row>

              <Row
                style={{ gap: "10px", marginBottom: "20px" }}
                className={commonCssStyles.breadcrumbBottomMargin}
              >
                <Col>
                  <Checkbox
                    defaultChecked={dateCheck}
                    onChange={(val) => {
                      setDateCheck(val.target.checked);
                    }}
                  />
                </Col>
                <Col>
                  <p className={`${commonCssStyles.marginBottomRemove}`}>
                    Agreement
                  </p>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Row justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        label="Start Date"
                        rules={[
                          {
                            required: dateCheck ? true : false,
                            message: "Please select start date",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                !getFieldValue("validityEndDate") ||
                                getFieldValue("validityEndDate") > value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                "From date must be less than To Date"
                              );
                            },
                          }),
                        ]}
                        name="validityStartDate"
                      >
                        <DatePicker
                          getPopupContainer={(trigger) => trigger.parentElement}
                          className={commonCssStyles.datePickerBgColor}
                          style={{
                            width: "100%",
                            backgroundColor: "var(--secondaryColor)",
                          }}
                          format={dataFormate}
                          placeholder="Start Date"
                        ></DatePicker>
                      </Form.Item>
                    </Col>

                    <Col span={11}>
                      <Form.Item
                        label="End Date"
                        rules={[
                          {
                            required: dateCheck ? true : false,
                            message: "Please select end date",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                !getFieldValue("validityStartDate") ||
                                getFieldValue("validityStartDate") < value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                "To date must be greater than From Date"
                              );
                            },
                          }),
                        ]}
                        name="validityEndDate"
                      >
                        <DatePicker
                          getPopupContainer={(trigger) => trigger.parentElement}
                          className={commonCssStyles.datePickerBgColor}
                          style={{
                            width: "100%",
                          }}
                          format={dataFormate}
                          placeholder="End Date"
                        ></DatePicker>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Agreed Commercials in %"
                    name="fees"
                    rules={[
                      {
                        required: true,
                        message: "Please enter agreed commercials in %",
                      },
                    ]}
                  >
                    <Input
                      // type="number"
                      placeholder="Enter agreed commercials in %"
                    />
                  </Form.Item>
                </Col>
              </Row> */}

              {/* agrred commercial  */}

              <Row className={commonCssStyles.sectionEndGap}>
                <Col>
                  <div className="ant-col ant-form-item-label">
                    <label /* className="ant-form-item-required" */>
                      Agreed Commercials in %
                    </label>
                  </div>
                </Col>
              </Row>

              <Row style={{ marginBottom: "20px" }}>
                <Col className="sharingMedium" md={24}>
                  <Form.List name="fees">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, key) => (
                          <div>
                            <Row>
                              <Col flex={1}>
                                <AddLink field={field} />
                              </Col>

                              <Col md={24} style={{ marginBottom: "10px" }}>
                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "8px",
                                    float: "right",
                                    color: "#F83A3A",
                                  }}
                                  className={`${commonStyles.marginBottomRemove} ${commonStyles.removeLocation}`}
                                >
                                  Remove
                                </p>
                                <Button
                                  className={commonStyles.removeLocationbtn}
                                  type="primary"
                                  size="small"
                                  shape="circle"
                                  style={{
                                    marginTop: "3px",
                                    marginRight: "7px",
                                    float: "right",
                                    backgroundColor: "#F83A3A",
                                    transform: "translateY(-4px)",
                                  }}
                                >
                                  <div
                                    style={{
                                      transform: "translateY(-4px)",
                                    }}
                                    onClick={() => remove(key)}
                                  >
                                    <MinusIcons color={"var(--theme)"} />
                                  </div>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        ))}
                        <Button
                          size="small"
                          onClick={() => add()}
                          type="primary"
                          shape="circle"
                          style={
                            {
                              // transform: "transformY(-20px)",
                            }
                          }
                        >
                          <div style={{ transform: "translateY(2px)" }}>
                            <PlusForButtonIcon
                              height={"19px"}
                              width={"19px"}
                              color={"var(--theme)"}
                            />
                          </div>
                        </Button>{" "}
                        <p
                          style={{
                            marginTop: "-27px",
                            marginLeft: "40px",
                          }}
                          className={`${commonStyles.marginBottomRemove} ${commonStyles.addLocation}`}
                        >
                          Add More Commercials
                        </p>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>

              {/* end of agreed commercial  */}
              <Row className={commonCssStyles.sectionEndGap}>
                <Col flex={1}>
                  <Form.Item
                    label="Payment Terms"
                    name="paymentTerms"
                    rules={[
                      {
                        required: false,
                        message: "Please enter payment terms",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                      //   message:
                      //     "It allows only alphabets, No whitespace at the beginning and end",
                      // },
                    ]}
                  >
                    <Input placeholder="Enter payment terms" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Replacement Clause"
                    name="replacementClause"
                    rules={[
                      {
                        required: false,
                        message: "Please enter replacement clause",
                      },
                      // {
                      //   pattern: /^(?!\s)(?![\s\S]*\s$)[A-Za-z\s]+$/,
                      //   message:
                      //     "It allows only alphabets, No whitespace at the beginning and end",
                      // },
                      {
                        pattern: /.*\S.*/,
                        message: "White space are not allowed",
                      },
                    ]}
                  >
                    <Input placeholder="Enter replacement clause" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col
            style={{ width: "726px" }}
            xs={24}
            md={24}
            className={commonStyles.footerShadow}
          >
            <Row justify="end" style={{ gap: "15px" }}>
              <Col>
                <Button
                  onClick={() => {
                    props.onClose();
                    setFileResult(false);
                    form.resetFields();
                  }}
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                  // className={styles.ResetFilterBtn}
                  className={commonCssStyles.buttonSettingsSecondary}
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button
                  onClick={() => {
                    form.submit();
                  }}
                  htmlType="button"
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                  // className={styles.ApplyFilterBtn}
                  className={commonCssStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  {!props.client ? "Add Company" : "Edit Company"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    settindsData: state.settings.settings,
    countriesData: state.country.countries,
    StateData: state.country.state,
    cityData: state.country.city,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCountriesHandler: (data, callback) => {
      dispatch(getCountriesAction(data, callback));
    },
  };
};
function AddLink({ field, dataFormate }) {
  const [addLink, setAddLink] = useState([]);

  return (
    <>
      <Row style={{ gap: "20px" }}>
        <Col md={24}>
          <Form.Item
            // name={[field.name, "agreedcommercial"]}
            {...field}
            rules={[
              {
                required: false,
                message: "Please enter agreed commercials in %",
              },
            ]}
          >
            <Input placeholder="Enter agreed commercials in %" type="number" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientCompanyDrawer);
