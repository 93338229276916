import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { json, useNavigate } from "react-router";
import {
  recuritmentReportAction,
  exportEmployeeDetaisAction,
  getEmployeeHitRatioAction,
  getReportsRequirementGraphAction,
  getReportsRequirementTimeGraphAction,
} from "../../../../Reusable/Store/Action/ReportsAction";
import {
  CaretBlackDownIcon,
  FilterOutlinedIcon,
  GridFour,
  ListBulletsIcon,
  PlusIcon,
} from "../../../IconsComp/Icons";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import RecruitmentReportFilter from "./RecruitmentReportFilter";
import { getRequirementsAction } from "../../../../Reusable/Store/Action/RequirementAction";
import BreadcrumbForRandR from "./BreadcrumbForRandR";
import { getEmployeesAction } from "../../../../Reusable/Store/Action/EmployeesAction";
import { getRolesAction } from "../../../../Reusable/Store/Action/Master/MasterRolesAction";
import { useForm } from "antd/lib/form/Form";
import { AreaChartOutlined } from "@ant-design/icons";
import ReactApexChart from "react-apexcharts";

function RecuritmentReport(props) {
  const [cfilter, setCFilter] = useState({});
  const [fromDate, setFromDate] = useState("1970-01-01");
  const [toDate, setToDate] = useState("3000-01-01");

  const [graphFromDate, setGraphSetFromDate] = useState("1970-01-01");
  const [graphToDate, setGraphSetToDate] = useState("3000-01-01");

  const [graphFromDate1, setGraphSetFromDate1] = useState("1970-01-01");
  const [graphToDate1, setGraphSetToDate1] = useState("3000-01-01");

  const [graphFromDate2, setGraphSetFromDate2] = useState("1970-01-01");
  const [graphToDate2, setGraphSetToDate2] = useState("3000-01-01");

  const [form] = useForm();

  const [openRectFilter, setOpenRecrtFilter] = useState(false);
  const [calendarViewStatus, setCalendarViewStatus] = useState(false);
  const [colorKey, setColorKey] = useState("0");
  const navigate = useNavigate();
  //closed jobs, intervie taken, basic details, per day cost, total hires, assigned job count, clicking should open jobs assigned and should show progress of job(all status
  //billing value, number of hires,number of candidates interviewed,shortlisted, offer decliens (all status per recruiter)

  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      let recuritmentLocalReportFilter = localStorage.getItem(
        "recuritmentReportFilter"
      );
      let recuritmentLocalReportFilter1 = JSON.parse(
        recuritmentLocalReportFilter
      );
      if (recuritmentLocalReportFilter1) {
        props.getRecuritmentReportHandler({
          ...recuritmentLocalReportFilter1,
          current_page: 1,
        });
      } else {
        props.getRecuritmentReportHandler({ current_page: 1 });
      }
      props.getEmployeeHitRatioHandler({
        dateFrom: graphFromDate,
        dateTo: graphToDate,
      });
      props.getRequirementReportsHandler({
        dateFrom: graphFromDate,
        dateTo: graphToDate,
      });
      props.getRequirementTimeGraphReportsHandler({
        dateFrom: graphFromDate,
        dateTo: graphToDate,
      });
      if (window.location.hash === "#/reports/employee") {
        localStorage.removeItem("clientCompany");
        localStorage.removeItem("employee");
        localStorage.removeItem("requirementFilterData");
        localStorage.removeItem("recuritmentFilterData");
        localStorage.removeItem("taskFilterData");
        localStorage.removeItem("interviewFilterData");
        localStorage.removeItem("candidateDetailFromRequirement");
        localStorage.removeItem("candidateListForPool");
        localStorage.removeItem("recuritmentCandidateFilter");
        localStorage.removeItem("fromWhichPage");
        localStorage.removeItem("candidateDetailFromRecruitment");
        localStorage.removeItem("reportsCompanyDetailFilterData");
        localStorage.removeItem("reportsjobdetailfilterdata");
        localStorage.removeItem("reportsjobcandidatefilterdata");
        localStorage.removeItem("employeeReportsFilter");

        localStorage.removeItem("ClientCompanySort");
localStorage.removeItem("employeeSort");
localStorage.removeItem("ReportsCompanyReport1Sort");
localStorage.removeItem("reportsjobcandidatefilterdataSort");
localStorage.removeItem("ReportsJobDetailFilterOneSort");

localStorage.removeItem("candidateSort");
localStorage.removeItem("interivewSort");
localStorage.removeItem("taskSort");
localStorage.removeItem("recuritmentSort");
localStorage.removeItem("requirementSort");
      }
    }
    return () => {
      unsubscribed = true;
    };
  }, []);

  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      props.getRequirementAction();
      props.getEmployeesAction();
      props.getRolesTypeHandler();
    }
    return () => {
      unsubscribed = true;
    };
  }, []);

  //graph

  //end of graph
  //for pie chart graph

  const series3 =
    props.employee_hit_ratio_graph && props.employee_hit_ratio_graph.value
      ? props.employee_hit_ratio_graph.value
      : [];

  const options3 = {
    chart: {
      type: "donut",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    labels:
      props.employee_hit_ratio_graph && props.employee_hit_ratio_graph.label
        ? props.employee_hit_ratio_graph.label
        : [],
  };

  //end of pie chart graph

  //for bar graph data
  const series1 = [
    {
      name: "Points",
      data:
        props.requirement_reports_graph && props.requirement_reports_graph.value
          ? props.requirement_reports_graph.value
          : [],
    },
  ];

  const options1 = {
    chart: {
      type: "bar",
      foreColor: "var(--body-text-color)",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    fill: {
      colors: ["#2f7bf5"],
    },
    dataLabels: {
      enabled: true,
    },

    xaxis: {
      categories:
        props.requirement_reports_graph && props.requirement_reports_graph.label
          ? props.requirement_reports_graph.label
          : [],
    },
  };

  //end of bar graph data

  //for piechart graph data

  const series2 =
    props.requirement_reports_time_graph &&
    props.requirement_reports_time_graph.value
      ? props.requirement_reports_time_graph.value
      : [];

  const options2 = {
    chart: {
      type: "polarArea",
    },
    fill: {
      opacity: 0.8,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels:
      props.requirement_reports_time_graph &&
      props.requirement_reports_time_graph.label
        ? props.requirement_reports_time_graph.label
        : [],
  };

  //end of piechart graph data

  const calendarViewHandler = () => {
    setCalendarViewStatus(true);
  };

  const setListViewHandler = () => {
    setCalendarViewStatus(false);
  };

  const closeRecruitFilter = () => {
    setOpenRecrtFilter(false);
  };

  const onSubmitFltr = (data) => {
    let fdatacompEmployee = JSON.parse(localStorage.getItem('recuritmentReportFilter'));

   
    props.getRecuritmentReportHandler(
      {
        ...fdatacompEmployee,
         ...data, 
         current_page: 1 },
      (success) => {
        if (success) {
          closeRecruitFilter();
        }
      }
    );

    setCFilter({
      ...fdatacompEmployee,
      ...data,
    });
  };

  const handleProvinceChange = (value,d) => {
    localStorage.setItem("reportRecruitmentEmployeeSort",JSON.stringify(d.value))
    let recuritmentReportFilter = JSON.parse(
      localStorage.getItem("recuritmentReportFilter")
    );
    let sort = value.split("_");
    if (recuritmentReportFilter) {
      props.getRecuritmentReportHandler({
        current_page: 1,
        ...cfilter,
        ...recuritmentReportFilter,
        sortBy: sort[0],
        sortOrder: sort[1],
        dateFrom: fromDate,
        dateTo: toDate,
      });
      setCFilter({
        current_page: 1,
        ...cfilter,
        ...recuritmentReportFilter,
        sortBy: sort[0],
        sortOrder: sort[1],
        dateFrom: fromDate,
        dateTo: toDate,
      });
      localStorage.setItem("recuritmentReportFilter",JSON.stringify({...recuritmentReportFilter, sortBy: sort[0],
        sortOrder: sort[1]}))
    } else {
      props.getRecuritmentReportHandler({
        current_page: 1,
        ...cfilter,
        sortBy: sort[0],
        sortOrder: sort[1],
        dateFrom: fromDate,
        dateTo: toDate,
      });
      setCFilter({
        current_page: 1,
        ...cfilter,
        sortBy: sort[0],
        sortOrder: sort[1],
        dateFrom: fromDate,
        dateTo: toDate,
      });
      localStorage.setItem("recuritmentReportFilter",JSON.stringify({current_page: 1,...cfilter, sortBy: sort[0],
        sortOrder: sort[1]}))
    }
  };

  const menu2 = (
    <Menu
      style={{ width: "151px", margin: "0px 45px" }}
      selectable
      defaultSelectedKeys={["1"]}
      items={[
        {
          key: "3",
          label: (
            <p
              className={`${commonCssStyle.externalJobTextrole}`}
              style={{ marginTop: "7px" }}
              onClick={() => {
                const data = {
                  current_page: 1,
                  ...cfilter,
                  dateFrom: fromDate,
                  dateTo: toDate,
                  type: "export",
                  exportType: "excel",
                };
                props.exportEmployeeReportHandler(data);
              }}
            >
              Export to Excel
            </p>
          ),
        },
        {
          key: "4",
          label: (
            <p
              style={{ marginTop: "7px" }}
              className={`${commonCssStyle.externalJobTextrole}`}
              onClick={() => {
                const data = {
                  current_page: 1,
                  ...cfilter,
                  dateFrom: fromDate,
                  dateTo: toDate,
                  type: "export",
                  exportType: "pdf",
                };
                props.exportEmployeeReportHandler(data);
              }}
            >
              Export to PDF
            </p>
          ),
        },
      ]}
    />
  );

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const fetchRecords = (page) => {

    let fdatacompEmployee = JSON.parse(localStorage.getItem('recuritmentReportFilter'));
if(fdatacompEmployee){
  const val = {
    ...cfilter,
    ...fdatacompEmployee,
    ...props.candidatesFilterData,
    current_page: page,
  };
  localStorage.setItem("recuritmentReportFilter",JSON.stringify({...fdatacompEmployee,current_page:page}))
  props.getRecuritmentReportHandler(val);
}else{
  const val = {
    ...cfilter,
    ...props.candidatesFilterData,
    current_page: page,
  };
  localStorage.setItem("recuritmentReportFilter",JSON.stringify({current_page:page}))
  props.getRecuritmentReportHandler(val);
}
  
  };

  const columns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Name
        </p>
      ),

      dataIndex: "firstName",
      key: "firstName",
      render: (data, record) => {
        return (
          <p
            style={{ color: "var(--primaryColor)", fontWeight: 600 }}
            className={`${commonCssStyle.tableData} ${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove}`}
            onClick={() => {
              navigate("/reports/employee/jobs", {
                state: { id: record.id, name: data },
              });
              // navigate("/reports/employee/jobs", {
              //   state: {
              //     userId: record.id,
              //     id: record.firstName + " " + record.lastName,
              //     name: data,
              //   },
              // });
            }}
          >
            {record && record.firstName + " " + record.lastName}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Email
        </p>
      ),

      dataIndex: "email",
      key: "email",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Phone
        </p>
      ),

      dataIndex: "phone",
      key: "phone",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Open Jobs
        </p>
      ),

      dataIndex: "openJobs",
      key: "openJobs",
      align: "center",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Closed Jobs
        </p>
      ),

      dataIndex: "closedJobs",
      key: "closedJobs",
      align: "center",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Interview Taken
        </p>
      ),

      dataIndex: "interviewedCount",
      key: "interviewedCount",
      align: "center",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Per Day Cost
        </p>
      ),

      dataIndex: "perDayCost",
      key: "perDayCost",
      align: "center",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Total Hires
        </p>
      ),

      dataIndex: "totalHires",
      key: "totalHires",
      align: "center",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Assigned Job Count
        </p>
      ),

      dataIndex: "totalJobs",
      key: "totalJobs",
      align: "center",
      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Status
        </p>
      ),

      dataIndex: "status",
      key: "status",
      render: (data, record) => (
        <Row>
          <Col>
            <Tag
              style={{
                backgroundColor: data === "active" ? "#D2EFCD" : "#FFD9D9",
                color: data === "active" ? "#044123" : "#F83A3A",
                padding: "5px 20px",
                lineHeight: "21px",
                fontSize: "14px",
                fontWeight: "500",
                fontStyle: "normal",
                fontFamily: "Helvetica,ProductSans",
              }}
            >
              {data}
            </Tag>
          </Col>
        </Row>
      ),
    },
  ];
  return (
    <>
      <Row>
        <Col md={24} className={commonCssStyle.breadcrumbBottomMargin}>
          <BreadcrumbForRandR page={0} />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <Row>
            <Col /* xs={24} sm={24} md={24} lg={5} */>
              <h1
                style={{ marginRight: "10px" }}
                className={`${commonCssStyle.mainHeadingText} ${commonCssStyle.inttitle}`}
              >
                Employee Report &#40;{" "}
                {props.recuritmentReport && props.recuritmentReport.total
                  ? props.recuritmentReport.total
                  : "0"}{" "}
                &#41;
              </h1>
            </Col>

            {!calendarViewStatus ? (
              <Col /* xs={24} sm={24} md={24} lg={3} */>
                <Button
                  className={`${commonCssStyle.filterButton}`}
                  id="candidates-table-filter-btn"
                  onClick={() => {
                    setOpenRecrtFilter(true);
                  }}
                >
                  <Row style={{ gap: "11px" }}>
                    <Col>
                      <div style={{ transform: "translateY(2px)" }}>
                        <FilterOutlinedIcon color={"var(--primaryColor)"} />
                      </div>
                    </Col>
                    <Col>
                      <p
                        className={`${commonCssStyle.filterAndSortTextStyle} ${commonCssStyle.marginBottomRemove}`}
                      >
                        Filter
                      </p>
                    </Col>
                  </Row>
                </Button>
              </Col>
            ) : (
              ""
            )}

            {!calendarViewStatus ? (
              <Col /* xs={24} sm={24} md={24} lg={6} */>
                <Button
                  style={{
                    height: "40px",
                    marginRight: "20px",
                  }}
                  className={` ${commonCssStyle.sortBtn}`}
                  id="candidates-table-sortby-btn"
                >
                  <Row justify="space-between">
                    <Col>
                      <p style={{ transform: "translateY(2px)" }}>Sort By</p>
                    </Col>
                    <Col>
                      <Select
                      value={JSON.parse(localStorage.getItem("reportRecruitmentEmployeeSort"))
                      ?
                      JSON.parse(localStorage.getItem("reportRecruitmentEmployeeSort")):"Select"
                    }
                        // getPopupContainer={(trigger) => trigger.parentElement}
                        style={{
                          width: "100%",
                          transform: "translateY(3px)",
                        }}
                        onChange={handleProvinceChange}
                        bordered={false}
                        placeholder={
                          <p
                            style={{
                              margin: "0px",
                              color: "var(--primaryColor)",
                            }}
                          >
                            Select
                          </p>
                        }
                        size="small"
                        className={`${commonCssStyle.filterDrop}  selectInput sortBySelectStyle selectTag selectTag1`}
                      >
                        <Select.Option key="1" value="Name_ASC">
                          Name Ascending
                        </Select.Option>
                        <Select.Option key="2" value="Name_DESC">
                          Name Descending
                        </Select.Option>
                        <Select.Option
                          key="3"
                          value="joiningDate_ASC"
                          style={{ padding: "10px" }}
                        >
                          Joining Date Ascending
                        </Select.Option>
                        <Select.Option
                          key="4"
                          value="joiningDate_DESC"
                          style={{ padding: "10px" }}
                        >
                          Joining Date Descending
                        </Select.Option>
                      </Select>
                    </Col>
                  </Row>
                </Button>
              </Col>
            ) : (
              ""
            )}

            {!calendarViewStatus ? (
              <Col>
                <Form form={form}>
                  <Row justify="space-between">
                    <Col md={11}>
                      <Form.Item className="candidateReportForm" name="from">
                        <DatePicker
                          getPopupContainer={(trigger) => trigger.parentElement}
                          name="from"
                          format={dataFormate}
                          placeholder="from"
                          style={{ width: "115px", height: "40px" }}
                          className="ReportsFrom"
                          suffixIcon={null}
                          onChange={(val) => {
                            if (val !== null) {
                              props.getRecuritmentReportHandler({
                                ...cfilter,
                                current_page: 1,
                                type: "list",
                                dateFrom: moment(val).format("YYYY-MM-DD"),
                                dateTo: toDate,
                              });
                              setFromDate(moment(val).format("YYYY-MM-DD"));
                            } else {
                              props.getRecuritmentReportHandler({
                                ...cfilter,
                                type: "list",
                                current_page: 1,
                                dateFrom: "1970-01-01",
                                dateTo: toDate,
                              });
                              setFromDate("1970-01-01");
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={11}>
                      <Form.Item className="candidateReportForm" name="to">
                        <DatePicker
                          getPopupContainer={(trigger) => trigger.parentElement}
                          onChange={(val) => {
                            if (val !== null) {
                              props.getRecuritmentReportHandler({
                                ...cfilter,
                                current_page: 1,
                                type: "list",
                                dateFrom: fromDate,
                                dateTo: moment(val).format("YYYY-MM-DD"),
                              });
                              setToDate(moment(val).format("YYYY-MM-DD"));
                            } else {
                              props.getRecuritmentReportHandler({
                                ...cfilter,
                                type: "list",
                                current_page: 1,
                                dateTo: "3000-01-01",
                                dateFrom: fromDate,
                              });
                              setToDate("3000-01-01");
                            }
                          }}
                          format={dataFormate}
                          placeholder="to"
                          style={{ width: "115px", height: "40px" }}
                          className="ReportsFrom"
                          suffixIcon={null}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Col>
        {!calendarViewStatus ? (
          <Col /* xs={24} sm={24} md={24} lg={4} */>
            <Row justify="end">
              <Dropdown placement="bottom" overlay={menu2}>
                <Typography.Link>
                  <Space className={commonCssStyle.tableHeadingTextData}>
                    <Button
                      style={{ width: "151px" }}
                      className={commonCssStyle.intaddButton}
                      type="primary"
                    >
                      <Row align="middle" justify="space-around">
                        <Col>
                          <PlusIcon color={"white"} />
                        </Col>
                        <Col>Export</Col>
                        <Col>
                          <CaretBlackDownIcon color={"white"} />
                        </Col>
                      </Row>
                    </Button>
                  </Space>
                </Typography.Link>
              </Dropdown>
            </Row>
          </Col>
        ) : (
          ""
        )}
      </Row>
      <Row>
        <Col sm={24} md={10} lg={18}>
          <div
            style={{
              height: "43px",
              width: "240px",
              padding: "3px 5px",
              backgroundColor: "#E0E0E0",
              borderRadius: "6px",
            }}
          >
            <Row align="middle" justify="space-between">
              <Col>
                <Card
                  style={{ padding: "5px 10px" }}
                  onMouseEnter={() => {
                    setColorKey("1");
                  }}
                  onMouseLeave={() => {
                    setColorKey("0");
                  }}
                  onClick={setListViewHandler}
                  className={`${
                    calendarViewStatus
                      ? commonCssStyle.taskBtn
                      : commonCssStyle.taskBackClrBtn
                  }`}
                >
                  <Row
                    className={`${commonCssStyle.coursorPointer}`}
                    style={{ gap: "8px" }}
                    align="middle"
                  >
                    <Col style={{ transform: "translateY(3px)" }}>
                      <ListBulletsIcon
                        color={calendarViewStatus ? "#1E1E1E" : "white"}
                      />
                    </Col>
                    <Col>
                      <p
                        className={` ${
                          calendarViewStatus
                            ? commonCssStyle.taskColor
                            : commonCssStyle.taskBtnTextColor
                        } ${commonCssStyle.marginBottomRemove} `}
                        style={{
                          fontSize: "14px",
                          paddingLeft: "5px",
                        }}
                      >
                        List View
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col>
                <Card
                  style={{ padding: "5px 10px" }}
                  onMouseEnter={() => {
                    setColorKey("2");
                  }}
                  onMouseLeave={() => {
                    setColorKey("0");
                  }}
                  onClick={calendarViewHandler}
                  className={`${
                    !calendarViewStatus
                      ? commonCssStyle.taskBtn
                      : commonCssStyle.taskBackClrBtn
                  }`}
                >
                  <Row
                    style={{ gap: "8px" }}
                    align="middle"
                    className={`${commonCssStyle.coursorPointer}`}
                  >
                    <Col style={{ transform: "translateY(1px)" }}>
                      <AreaChartOutlined
                        color={!calendarViewStatus ? "#1E1E1E" : "white"}
                      />
                    </Col>
                    <Col>
                      <p
                        className={` ${
                          calendarViewStatus
                            ? commonCssStyle.taskBtnTextColor
                            : commonCssStyle.taskColor
                        } ${commonCssStyle.marginBottomRemove}`}
                        style={{
                          fontSize: "14px",
                          paddingLeft: "1px",
                        }}
                      >
                        Graph View
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {!calendarViewStatus ? (
        <Row>
          <Col lg={24}>
            <Table
              className="ReportsTablescroll"
              scroll={{ x: "100vw" }}
              style={{ marginTop: "3%" }}
              // scroll={{
              //   x: "auto",
              // }}
              dataSource={props.recuritmentReport.data}
              columns={columns}
              pagination={{
                size: "small",
                current:
                  props.recuritmentReport &&
                  props.recuritmentReport.current_page,
                pageSize:
                  props.recuritmentReport && props.recuritmentReport.per_page,
                total: props.recuritmentReport && props.recuritmentReport.total,
                showSizeChanger: false,
                onChange: (props) => {
                  fetchRecords(props);
                },
              }}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col lg={24}>
            <Row>
              <Col md={24} lg={24} style={{ marginTop: "20px" }}>
                <Row>
                  <Col lg={18}>
                    <h3
                      className={commonCssStyle.subHeadingText}
                      style={{
                        marginBottom: "25px",
                        color: "var(--headings-color)",
                      }}
                    >
                      Better Hit Ratio{" "}
                    </h3>
                  </Col>
                  {calendarViewStatus ? (
                    <Col xs={24} sm={24} md={24} lg={3}>
                      <DatePicker
                        getPopupContainer={(trigger) => trigger.parentElement}
                        name="from"
                        format={dataFormate}
                        placeholder="from"
                        style={{ width: "115px", height: "40px" }}
                        className="ReportsFrom"
                        suffixIcon={null}
                        onChange={(val) => {
                          if (val !== null) {
                            props.getEmployeeHitRatioHandler({
                              dateFrom: moment(val).format("YYYY-MM-DD"),
                              dateTo: graphToDate,
                            });
                            setGraphSetFromDate(
                              moment(val).format("YYYY-MM-DD")
                            );
                          } else {
                            props.getEmployeeHitRatioHandler({
                              dateFrom: "1970-01-01",
                              dateTo: graphToDate,
                            });
                            setGraphSetFromDate("1970-01-01");
                          }
                        }}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {calendarViewStatus ? (
                    <Col xs={24} sm={24} md={24} lg={3}>
                      <Form>
                        <DatePicker
                          getPopupContainer={(trigger) => trigger.parentElement}
                          onChange={(val) => {
                            if (val !== null) {
                              props.getEmployeeHitRatioHandler({
                                dateFrom: graphFromDate,
                                dateTo: moment(val).format("YYYY-MM-DD"),
                              });
                              setGraphSetToDate(
                                moment(val).format("YYYY-MM-DD")
                              );
                            } else {
                              props.getEmployeeHitRatioHandler({
                                dateTo: "3000-01-01",
                                dateFrom: graphFromDate,
                              });
                              setGraphSetToDate("3000-01-01");
                            }
                          }}
                          format={dataFormate}
                          placeholder="to"
                          style={{ width: "115px", height: "40px" }}
                          className="ReportsFrom"
                          suffixIcon={null}
                        />
                      </Form>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>

                <Row>
                  <Col lg={12}>
                    <ReactApexChart
                      options={options3}
                      series={series3}
                      type="donut"
                      height={400}
                    />
                  </Col>
                </Row>
              </Col>

              <Col md={24} lg={24} style={{ marginTop: "6%" }}>
                <Row>
                  <Col lg={18}>
                    <h3
                      className={commonCssStyle.subHeadingText}
                      style={{
                        marginBottom: "25px",
                        color: "var(--headings-color)",
                      }}
                    >
                      Requirements Completed{" "}
                    </h3>
                  </Col>
                  {calendarViewStatus ? (
                    <Col xs={24} sm={24} md={24} lg={3}>
                      <DatePicker
                        getPopupContainer={(trigger) => trigger.parentElement}
                        name="from"
                        format={dataFormate}
                        placeholder="from"
                        style={{ width: "115px", height: "40px" }}
                        className="ReportsFrom"
                        suffixIcon={null}
                        onChange={(val) => {
                          if (val !== null) {
                            props.getRequirementReportsHandler({
                              dateFrom: moment(val).format("YYYY-MM-DD"),
                              dateTo: graphToDate1,
                            });
                            setGraphSetFromDate1(
                              moment(val).format("YYYY-MM-DD")
                            );
                          } else {
                            props.getRequirementReportsHandler({
                              dateFrom: "1970-01-01",
                              dateTo: graphToDate1,
                            });
                            setGraphSetFromDate1("1970-01-01");
                          }
                        }}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {calendarViewStatus ? (
                    <Col xs={24} sm={24} md={24} lg={3}>
                      <Form>
                        <DatePicker
                          getPopupContainer={(trigger) => trigger.parentElement}
                          onChange={(val) => {
                            if (val !== null) {
                              props.getRequirementReportsHandler({
                                dateFrom: graphFromDate1,
                                dateTo: moment(val).format("YYYY-MM-DD"),
                              });
                              setGraphSetToDate1(
                                moment(val).format("YYYY-MM-DD")
                              );
                            } else {
                              props.getRequirementReportsHandler({
                                dateTo: "3000-01-01",
                                dateFrom: graphFromDate1,
                              });
                              setGraphSetToDate1("3000-01-01");
                            }
                          }}
                          format={dataFormate}
                          placeholder="to"
                          style={{ width: "115px", height: "40px" }}
                          className="ReportsFrom"
                          suffixIcon={null}
                        />
                      </Form>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                <ReactApexChart
                  options={options1}
                  series={series1}
                  type="bar"
                  height={350}
                  // width={500}
                />
              </Col>

              <Col md={24} lg={24} style={{ marginTop: "6%" }}>
                <Row>
                  <Col lg={18}>
                    <h3
                      className={commonCssStyle.subHeadingText}
                      style={{
                        marginBottom: "25px",
                        color: "var(--headings-color)",
                      }}
                    >
                      Time taken to complete requirement{" "}
                    </h3>
                  </Col>
                  {calendarViewStatus ? (
                    <Col xs={24} sm={24} md={24} lg={3}>
                      <DatePicker
                        getPopupContainer={(trigger) => trigger.parentElement}
                        name="from"
                        format={dataFormate}
                        placeholder="from"
                        style={{ width: "115px", height: "40px" }}
                        className="ReportsFrom"
                        suffixIcon={null}
                        onChange={(val) => {
                          if (val !== null) {
                            props.getRequirementTimeGraphReportsHandler({
                              dateFrom: moment(val).format("YYYY-MM-DD"),
                              dateTo: graphToDate2,
                            });
                            setGraphSetFromDate2(
                              moment(val).format("YYYY-MM-DD")
                            );
                          } else {
                            props.getRequirementTimeGraphReportsHandler({
                              dateFrom: "1970-01-01",
                              dateTo: graphToDate2,
                            });
                            setGraphSetFromDate2("1970-01-01");
                          }
                        }}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {calendarViewStatus ? (
                    <Col xs={24} sm={24} md={24} lg={3}>
                      <Form>
                        <DatePicker
                          getPopupContainer={(trigger) => trigger.parentElement}
                          onChange={(val) => {
                            if (val !== null) {
                              props.getRequirementTimeGraphReportsHandler({
                                dateFrom: graphFromDate2,
                                dateTo: moment(val).format("YYYY-MM-DD"),
                              });
                              setGraphSetToDate2(
                                moment(val).format("YYYY-MM-DD")
                              );
                            } else {
                              props.getRequirementTimeGraphReportsHandler({
                                dateTo: "3000-01-01",
                                dateFrom: graphFromDate2,
                              });
                              setGraphSetToDate2("3000-01-01");
                            }
                          }}
                          format={dataFormate}
                          placeholder="to"
                          style={{ width: "115px", height: "40px" }}
                          className="ReportsFrom"
                          suffixIcon={null}
                        />
                      </Form>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                {calendarViewStatus ? (
                  <Row>
                    <Col lg={12}>
                      <ReactApexChart
                        options={options2}
                        series={series2}
                        type="polarArea"
                        height={400}
                        // width={500}
                      />
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <RecruitmentReportFilter
        resetDateFields={() => {
          form.resetFields();
        }}
        onOpen={openRectFilter}
        onClose={closeRecruitFilter}
        requirements={props.requirements}
        onSubmitFltr={onSubmitFltr}
        employees={props.employees}
        roleTypeData={props.roleTypeData}
        fromDate={fromDate}
        toDate={toDate}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    recuritmentReport: state.reports.recuritmentReport,
    requirements: state.requirements.requirements,
    employees: state.employee.employees,
    roleTypeData: state.roles.roles,
    employee_hit_ratio_graph: state.reports.employee_hit_ratio_graph,
    requirement_reports_graph: state.reports.requirement_reports_graph,
    requirement_reports_time_graph:
      state.reports.requirement_reports_time_graph,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRecuritmentReportHandler: (data, callback) => {
      dispatch(recuritmentReportAction(data, callback));
    },
    getRequirementAction: () => {
      dispatch(getRequirementsAction());
    },
    getEmployeesAction: () => {
      dispatch(getEmployeesAction());
    },
    getRolesTypeHandler: () => {
      dispatch(getRolesAction());
    },
    exportEmployeeReportHandler: (val) => {
      dispatch(exportEmployeeDetaisAction(val));
    },
    getEmployeeHitRatioHandler: (val) => {
      dispatch(getEmployeeHitRatioAction(val));
    },
    getRequirementReportsHandler: (val) => {
      dispatch(getReportsRequirementGraphAction(val));
    },
    getRequirementTimeGraphReportsHandler: (val) => {
      dispatch(getReportsRequirementTimeGraphAction(val));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RecuritmentReport);
