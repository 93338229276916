import { Card, Col, Row, Tabs, Image, Switch } from "antd";
import EmployeeDetails from "./EmployeeDetails";
import EmployeeRoleandPermission from "./EmployeeRoleandPermission";
import EmployeeTask from "./EmployeeTask";
import styles from "./Employee.module.css";
import EmployeeTimeline from "./EmployeeTimeline";
import { Link, useLocation } from "react-router-dom";
import {
  BackButtonIcon,
  EnvelopeIcon,
  PhoneIcon,
  WhatsappIcon,
} from "../../IconsComp/Icons";
import { connect } from "react-redux";
import {
  getEmployeeAction,
  postEmployee_permissionAction,
  postemployee_roleAction,
  putEmployeesAction,
  postEmployeeDocumentsAction,
  deleteEmployeeuploadsAction,
  getEmployeeCallLogsAction,
  getEmployee_permissionAction,
} from "../../../Reusable/Store/Action/EmployeesAction";
import jwt_decode from "jwt-decode";
import { getJobTypesAction } from "../../../Reusable/Store/Action/Master/MasterJobTypesAction";
import { getCountriesAction } from "../../../Reusable/Store/Action/Master/MasterCountryAction";
import { getDepartmentsAction } from "../../../Reusable/Store/Action/Master/MasterDepartmentsAction";
import { getPermissionsAction } from "../../../Reusable/Store/Action/Master/MasterPermissionAction";
import { getSettingsWorklocationAction } from "../../../Reusable/Store/Action/SettingsAction";
import { useEffect, useState } from "react";
import {
  getAllRoleTypesAction,
  getRolesAction,
  getRoleTypesAction,
} from "../../../Reusable/Store/Action/Master/MasterRoleTypeAction";
import { getEmployeesAction } from "../../../Reusable/Store/Action/EmployeesAction";
import commonCssStyles from "../../UI/CommonCss.module.css";
import CandidateCall from "../AddCandidatesComp/CandidatesSummary/CandidateCall";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import PermissionMessage from "../../UI/PermissionMessage";
import BreadcrumbForEmployees from "./BreadcrumbForEmployees";
import EmployeeAssignToJobs from "./EmployeeAssignToJobs";
import EmployeeRandP from "./EmployeeRandP";
import EmployeeRolesAndPermission from "./EmployeeRolesAndPermission";
import ERandP from "./ERandP";
import DefaultImage from "../../../../src/Assets/Image/DefaultImage.png";
import LoadingIcon from "../../UI/LoadingIcon";

const EmployeeSummary = (props) => {
  const [acitveKey, setActiveKey] = useState("1");
  const [countries, setCountries] = useState([]);
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  const [fetchId, setFetchId] = useState({});
  const location = useLocation();

  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      let decoded = jwt_decode(props.tokenData);
      setFetchId(decoded);
      props.getEmployeeAction(location.state.id);
      props.getEmployeesAction();
      props.getJobTypesAction();
      props.getCountryHandler({ type: "country" }, (data, type) => {
        setCountries(data);
      });
      props.getEmployeeCallLogsAction({ id: location.state.id });
      props.getPermissionsAction();
      props.getDepartmentsAction();
      props.getAllRoleTypesAction();
      props.getRolesAction();
      props.getRolesTypeHandler();
      props.getSettingsWorkLocationHandler();
    }
    return () => {
      unsubscribed = true;
    };
  }, [location]);

  function deleteDocumentHandler(data) {
    props.deleteEmployeeDocumentsHandler(data, (success) => {
      if (success) {
        props.getEmployeeAction(location.state.id);
      }
    });
  }

  return (
    <>
      <Row gutter={[0, 16]}>
        <Col className={commonCssStyles.breadcrumbBottomMargin} md={24}>
          <BreadcrumbForEmployees slide={[1, 2, 3]} />
        </Col>

        <Col md={8}>
          <Card
            style={{
              marginRight: "30px",
            }}
          >
            <div
              style={{
                textAlign: "center",
                paddingTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Col xs={4} md={4}>
                <Link to="/employee">
                  <BackButtonIcon color={"var(--body-text-color)"} />
                </Link>
              </Col>
              <Image
                preview={false}
                height={100}
                width={100}
                style={{ borderRadius: "50%", marginBottom: "20px" }}
                src={
                  props.employee && props.employee.profileImage
                    ? props.employee.profileImage
                    : DefaultImage
                }
                // preview={{
                //   src: props.employee && props.employee.profileImage,
                // }}
              />

              <p
                style={{ marginTop: "20px" }}
                className={`${commonCssStyles.marginBottomRemove} ${styles.employeeNameText}`}
              >
                {props.employee
                  ? props.employee.firstName + " " + props.employee.lastName
                  : "Loading..."}
              </p>

              <p
                style={{ marginTop: "11px" }}
                className={`${commonCssStyles.marginBottomRemove} ${styles.employeeNameSubText}`}
              >
                {props.employee && props.employee.empId
                  ? props.employee.empId
                  : "Loading..."}
              </p>
            </div>
            <Row
              style={{
                margin: "40px 20px 15px 20px",
                padding: "20px",
                background: "var(--secondary-card-color)",
                borderRadius: "6px",
                overflow: "hidden",
              }}
            >
              <Col>
                <Row>
                  <Col md={24}>
                    <p
                      style={{ fontSize: "20px", paddingBottom: "19px" }}
                      className={commonCssStyles.summaryInfoMainText}
                    >
                      Contact Information
                    </p>
                  </Col>
                </Row>
                <Col xs={24} md={24}>
                  <Row>
                    <Col>
                      <EnvelopeIcon color={"var(--body-text-color)"} />
                    </Col>
                    <Col>
                      <p
                        style={{ paddingLeft: "10px", marginTop: "4px" }}
                        className={commonCssStyles.infoTextColor}
                      >
                        {props.employee && props.employee.email
                          ? props.employee.email
                          : "NY"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <PhoneIcon color={"var(--body-text-color)"} />
                    </Col>
                    <Col>
                      <p
                        style={{ paddingLeft: "10px", marginTop: "6px" }}
                        className={commonCssStyles.infoTextColor}
                      >
                        {props.employee && props.employee.phone
                          ? props.employee.phone
                          : "NY"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <WhatsappIcon color={"var(--body-text-color)"} />
                    </Col>
                    <Col>
                      <p
                        style={{ paddingLeft: "14px", marginTop: "4px" }}
                        className={commonCssStyles.infoTextColor}
                      >
                        {props.employee && props.employee.whatsApp
                          ? props.employee.whatsApp
                          : "NY"}
                      </p>
                    </Col>
                  </Row>
                </Col>
                {/* </Row> */}
              </Col>
            </Row>
            {props.employee &&
            // props.employee.isPrimary&&
            props.employee.isPrimary == "0" ? (
              <Row
                style={{
                  margin: "15px 20px",
                  padding: "27px 32px",
                  background: " var(--secondary-card-color)",
                  borderRadius: "6px",
                }}
              >
                <Col sm={24}>
                  <Row align="middle" justify="space-between">
                    <Col>
                      <p
                        className={`${commonCssStyles.marginBottomRemove} ${styles.rolesLeftCardText}`}
                        style={{ float: "left" }}
                      >
                        Web Acess
                      </p>
                    </Col>
                    <Col>
                      <Row style={{ gap: "12px" }}>
                        {props.authPermissions &&
                          props.authPermissions.find(
                            (data) => data === "employees-edit"
                          ) && (
                            <Col>
                              {/* { props.employee && props.employee.webAccess === 1 ? d */}
                              <Switch
                                onChange={(val) => {
                                  props.getCheckPermissionHandler(
                                    "employees-edit",
                                    (success) => {
                                      if (success) {
                                        props.putEmployeesAction(
                                          {
                                            id: props.employee.userId,
                                            webAccess: val ? 1 : 0,
                                          },
                                          "details",
                                          (success) => {}
                                        );
                                      } else {
                                        setOpenPMModal({
                                          open: true,
                                          permission: "Employees Edit",
                                        });
                                      }
                                    }
                                  );
                                }}
                                size="small"
                                checked={
                                  props.employee &&
                                  props.employee.webAccess == 1
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                          )}
                        <Col>
                          {props.employee && props.employee.webAccess == 1 ? (
                            <p
                              className={`${commonCssStyles.marginBottomRemove} ${styles.rolesLeftCardText}`}
                              style={{ float: "right" }}
                            >
                              Enable
                            </p>
                          ) : (
                            <p
                              className={`${commonCssStyles.marginBottomRemove} ${styles.rolesLeftCardText}`}
                              style={{ float: "right" }}
                            >
                              Disable
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col style={{ marginTop: "20px" }} md={24} sm={24}>
                  <Row align="middle" justify="space-between">
                    <Col>
                      <p
                        className={`${commonCssStyles.marginBottomRemove} ${styles.rolesLeftCardText}`}
                        style={{ float: "left" }}
                      >
                        App Acess
                      </p>
                    </Col>
                    <Col>
                      <Row style={{ gap: "12px" }}>
                        {props.authPermissions &&
                          props.authPermissions.find(
                            (data) => data === "employees-edit"
                          ) && (
                            <Col>
                              <Switch
                                onChange={(val) => {
                                  props.getCheckPermissionHandler(
                                    "employees-edit",
                                    (success) => {
                                      if (success) {
                                        props.putEmployeesAction(
                                          {
                                            id: props.employee.userId,
                                            appAccess: val ? 1 : 0,
                                          },
                                          "details",
                                          (success) => {}
                                        );
                                      } else {
                                        setOpenPMModal({
                                          open: true,
                                          permission: "Employees Edit",
                                        });
                                      }
                                    }
                                  );
                                }}
                                size="small"
                                checked={
                                  props.employee &&
                                  props.employee.appAccess == 1
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                          )}
                        <Col>
                          {props.employee && props.employee.appAccess == 1 ? (
                            <p
                              className={`${commonCssStyles.marginBottomRemove} ${styles.rolesLeftCardText}`}
                              style={{ float: "right" }}
                            >
                              Enable
                            </p>
                          ) : (
                            <p
                              className={`${commonCssStyles.marginBottomRemove} ${styles.rolesLeftCardText}`}
                              style={{ float: "right" }}
                            >
                              Disable
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Card>
        </Col>

        <Col
          md={16}
          style={{ overflowY: "scroll", height: "100vh" }}
          className="tabsScroll"
        >
          <Tabs
            onTabClick={(val) => {
              if (val === "1") {
                setActiveKey("1");
              } else if (val === "2") {
                setActiveKey("2");
              } else if (val === "3") {
                props.getCheckPermissionHandler(
                  "employees-timeline",
                  (success) => {
                    if (success) {
                      setActiveKey("3");
                    } else {
                      setOpenPMModal({
                        open: true,
                        permission: "Employee Time Line",
                      });
                    }
                  }
                );
              } else if (val === "4") {
                props.getCheckPermissionHandler(
                  "employees-task-list",
                  (success) => {
                    if (success) {
                      setActiveKey("4");
                    } else {
                      setOpenPMModal({
                        open: true,
                        permission: "Employee Task List",
                      });
                    }
                  }
                );
              } else if (val === "5") {
                props.getCheckPermissionHandler("employee-call", (success) => {
                  if (success) {
                    setActiveKey("5");
                  } else {
                    setOpenPMModal({
                      open: true,
                      permission: "Employee Call",
                    });
                  }
                });
              }
            }}
            // defaultActiveKey="1"
            acitveKey={acitveKey}
            className="summarytab"
            items={[
              {
                label: "Details",
                key: "1",
                children: (
                  <EmployeeDetails
                    jobTypes={props.jobTypes}
                    departments={props.departments}
                    roleTypeData={props.roleTypeData}
                    employee={props.employee}
                    employees={props.employees}
                    workLocation={props.settingsWorkLocation}
                    countriesData={props.countriesData}
                    putEmployeesAction={props.putEmployeesAction}
                    postEmployeeDocuments={props.postEmployeeDocumentsHandler}
                    settindsData={props.settindsData}
                    employeeDocumentDeleteHandler={deleteDocumentHandler}
                  />
                ),
              },
              location.state.allow === 0 &&
                fetchId.id !== location.state.id && {
                  label: "Role and Permission",
                  key: "2",
                  children: (
                    <>
                      {/* <EmployeeRoleandPermission
                        id={location.state.id}
                        employee={props.employee}
                        employees={props.employees}
                        permissions={props.permissions}
                        allrolestypes={props.allrolestypes}
                        roles={props.roles}
                        postemployee_roleAction={props.postemployee_roleAction}
                        postEmployee_permissionAction={
                          props.postEmployee_permissionAction
                        }
                      /> */}
                      <EmployeeRandP
                        id={location.state.id}
                        employee={props.employee}
                        employees={props.employees}
                        permissions={props.permissions}
                        allrolestypes={props.allrolestypes}
                        roles={props.roles}
                        postemployee_roleAction={props.postemployee_roleAction}
                        postEmployee_permissionAction={
                          props.postEmployee_permissionAction
                        }
                      />
                    </>
                  ),
                },
              props.authPermissions &&
                props.authPermissions.find(
                  (data) => data === "employees-timeline"
                ) && {
                  label: "Timeline",
                  key: "3",
                  children: <EmployeeTimeline />,
                },
              props.authPermissions &&
                props.authPermissions.find(
                  (data) => data === "employees-task-list"
                ) && {
                  label: "Task",
                  key: "4",
                  children: <EmployeeTask type="employee" />,
                },
              props.authPermissions &&
                props.authPermissions.find(
                  (data) => data === "employee-call"
                ) && {
                  label: "Calls",
                  key: "5",
                  children: (
                    <CandidateCall employeeCalls={props.employee_call_logs} />
                  ),
                },
              props.authPermissions &&
                props.authPermissions.find(
                  (data) => data === "employee-job-list"
                ) && {
                  label: "Job",
                  key: "6",
                  children: (
                    // <EmployeeCallLogs employeeCalls={props.employee_call_logs} />
                    // <CandidateCall employeeCalls={props.employee_call_logs} />
                    <EmployeeAssignToJobs getJobsAssigned={location.state.id} />
                  ),
                },
            ]}
          ></Tabs>
        </Col>
      </Row>

      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    employees: state.employee.employees,
    employee: state.employee.employee,
    countriesData: state.country.countries,
    jobTypes: state.jobtype.jobTypes,
    allrolestypes: state.roleTypes.allrolestypes,
    roles: state.roleTypes.roles,
    departments: state.departments.departments,
    permissions: state.permission.permissions,
    roleTypeData: state.roleTypes.roleTypes,
    settingsWorkLocation: state.settings.settingsWorklocation,
    settindsData: state.settings.settings,
    employee_call_logs: state.employee.employee_call_logs,
    authPermissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeesAction: () => {
      dispatch(getEmployeesAction());
    },
    getEmployeeAction: (id) => {
      dispatch(getEmployeeAction(id));
    },
    getJobTypesAction: () => {
      dispatch(getJobTypesAction());
    },
    putEmployeesAction: (data, type, callback) => {
      dispatch(putEmployeesAction(data, type, callback));
    },
    getCountryHandler: (data, callback) => {
      dispatch(getCountriesAction(data, callback));
    },
    getDepartmentsAction: () => {
      dispatch(getDepartmentsAction());
    },
    getPermissionsAction: () => {
      dispatch(getPermissionsAction());
    },
    getAllRoleTypesAction: () => {
      dispatch(getAllRoleTypesAction());
    },
    getRolesAction: () => {
      dispatch(getRolesAction());
    },
    postEmployee_permissionAction: (data, callback) => {
      dispatch(postEmployee_permissionAction(data, callback));
    },
    postemployee_roleAction: (data, callback) => {
      dispatch(postemployee_roleAction(data, callback));
    },
    getRolesTypeHandler: () => {
      dispatch(getRoleTypesAction());
    },
    getSettingsWorkLocationHandler: () => {
      dispatch(getSettingsWorklocationAction());
    },
    postEmployeeDocumentsHandler: (data, type, callback) => {
      dispatch(postEmployeeDocumentsAction(data, type, callback));
    },
    deleteEmployeeDocumentsHandler: (data, callback) => {
      dispatch(deleteEmployeeuploadsAction(data, callback));
    },
    getEmployeeCallLogsAction: (id) => {
      dispatch(getEmployeeCallLogsAction(id.id));
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSummary);
