import { Card, Checkbox, Col, List, Row } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import CommonCssStyle from "../../../UI/CommonCss.module.css";
function CandidateFeedback(props) {
  const dateFormat =
    props.settings && props.settings.dateFormat
      ? props.settings.dateFormat
      : "DD/MM/YYYY";
  const data = [
    {
      interview: "13-11-2023",
      withInterview: "Satish S",
      interviewMode: "web",
      interviewRound: "3rd",
      feedback: "Candidate is good",
      assessmentQuestion: [
        "Concept. C++ is not platform-independent; the principle behind C++ programming is “write once, compile anywhere.” In contrast, because the byte code generated by the Java compiler is platform-independent, it can run on any machine, Java programs are written once and run everywhere.",
        "Concept. C++ is not platform-independent; the principle behind C++ programming is “write once, compile anywhere.” In contrast, because the byte code generated by the Java compiler is platform-independent, it can run on any machine, Java programs are written once and run everywhere.",
        "Concept. C++ is not platform-independent; the principle behind C++ programming is “write once, compile anywhere.” In contrast, because the byte code generated by the Java compiler is platform-independent, it can run on any machine, Java programs are written once and run everywhere.",
        "Concept. C++ is not platform-independent; the principle behind C++ programming is “write once, compile anywhere.” In contrast, because the byte code generated by the Java compiler is platform-independent, it can run on any machine, Java programs are written once and run everywhere.",
        "Concept. C++ is not platform-independent; the principle behind C++ programming is “write once, compile anywhere.” In contrast, because the byte code generated by the Java compiler is platform-independent, it can run on any machine, Java programs are written once and run everywhere.",
        "Concept. C++ is not platform-independent; the principle behind C++ programming is “write once, compile anywhere.” In contrast, because the byte code generated by the Java compiler is platform-independent, it can run on any machine, Java programs are written once and run everywhere.",
      ],
    },
    {
      interview: "13-12-2023",
      withInterview: "Satish",
      interviewMode: "phone",
      interviewRound: "2nd",
      feedback: "Candidate is good",
      assessmentQuestion: [
        "Concept. C++ is not platform-independent; the principle behind C++ programming is “write once, compile anywhere.” In contrast, because the byte code generated by the Java compiler is platform-independent, it can run on any machine, Java programs are written once and run everywhere.",
      ],
    },
    {
      interview: "23-12-2023",
      withInterview: "Satish",
      interviewMode: "phone",
      interviewRound: "2nd",
      feedback: "Candidate is good",
      assessmentQuestion: [
        "Concept. C++ is not platform-independent; the principle behind C++ programming is “write once, compile anywhere.” In contrast, because the byte code generated by the Java compiler is platform-independent, it can run on any machine, Java programs are written once and run everywhere.",
      ],
    },
  ];

  return (
    <Card style={{ padding: "20px" }}>
      {data.map((x, index) => {
        return (
          <Row
            style={{
              marginTop: "30px",
              //   border: "0.1px solid lightgray",
              //   borderRadius: "8px",
            }}
            gutter={[0, 16]}
            justify="space-between"
          >
            <Col xs={24} md={11}>
              <List
                style={{ height: "100%" }}
                key={index}
                header={
                  <>
                    <Checkbox
                      onClick={() => {
                        // window.open(x, "_blank");
                        console.log(x);
                      }}
                    >
                      <p
                        className={`${CommonCssStyle.marginBottomRemove} ${CommonCssStyle.subHeadingText}`}
                      >
                        {/* {`Interview (${moment(x.interview).format(
                          dateFormat
                        )})`} */}
                        {`Interview (${x.interview})`}
                      </p>
                    </Checkbox>
                  </>
                }
                footer={false}
                bordered
              >
                <List.Item>
                  <p
                    className={`${CommonCssStyle.marginBottomRemove} ${CommonCssStyle.subHeadingTextNormal}`}
                  >
                    Interview Details
                  </p>
                </List.Item>
                <List.Item>
                  <p
                    className={`${CommonCssStyle.marginBottomRemove} ${CommonCssStyle.subHeadingTextNormal}`}
                  >{`With interviewer : ${x.withInterview} `}</p>
                </List.Item>
                <List.Item>
                  <p
                    className={`${CommonCssStyle.marginBottomRemove} ${CommonCssStyle.subHeadingTextNormal}`}
                  >{`Interview mode : ${x.interviewMode}`}</p>
                </List.Item>
                <List.Item>
                  <p
                    className={`${CommonCssStyle.marginBottomRemove} ${CommonCssStyle.subHeadingTextNormal}`}
                  >{`Interview round : ${x.interviewRound}`}</p>
                </List.Item>
                <List.Item>
                  <p
                    className={`${CommonCssStyle.marginBottomRemove} ${CommonCssStyle.subHeadingTextNormal}`}
                  >{`Overall feedback or Interview notes : ${x.feedback}`}</p>
                </List.Item>
              </List>
            </Col>
            <Col xs={24} md={11}>
              <List
                style={{ height: "100%" }}
                header={
                  <p
                    className={`${CommonCssStyle.marginBottomRemove} ${CommonCssStyle.subHeadingText}`}
                  >
                    Ratings
                  </p>
                }
                bordered
                dataSource={x.assessmentQuestion}
                renderItem={(item) => (
                  <List.Item>
                    <p
                      className={`${CommonCssStyle.marginBottomRemove} ${CommonCssStyle.subHeadingTextNormal}`}
                    >
                      {item}
                    </p>
                  </List.Item>
                )}
                footer={false}
              />
            </Col>
          </Row>
        );
      })}
    </Card>
  );
}
const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(CandidateFeedback);
