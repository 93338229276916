import { Card, Checkbox, Col, Form, Row, Table } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../Employees/Employee.module.css";
import commonCssStyle from "../../UI/CommonCss.module.css";
import { connect } from "react-redux";
function ERandP(props) {
  const [data1, setData1] = useState([
    {
      key: "1",
      menu: "DashBoard",
      dashboard:
        props.permissions && props.permissions.find((x) => x === "dashboard")
          ? true
          : false,
    },
  ]);
  const columns1 = [
    {
      dataIndex: "assigned",
      render: (text, render, index) => (
        <p
          onClick={() => {
            setData1(
              data1.map((x, i) => {
                if (index === i) {
                  return {
                    ...x,
                    view: x.view ? false : true,
                  };
                } else {
                  return x;
                }
              })
            );
          }}
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.coursorPointer} ${styles.rolesSellectAllLink}`} /*  ${commonCssStyle.coursorPointer} ${styles.rolesSellectAllLink} */
        >
          DashBoard
        </p>
      ),
    },
    {
      title: "View",
      dataIndex: "view",

      render: (text, record, i) => {
        return (
          <Checkbox
            onChange={(val) => {
              setData1(
                data1.map((data, index) => {
                  if (index === i) {
                    return {
                      ...data,
                      view: val.target.checked,
                    };
                  } else {
                    return data;
                  }
                })
              );
            }}
            className={`${styles.rolesCheckBoxText} intellcheckbox`}
            checked={text}
          >
            DashBoard
          </Checkbox>
        );
      },
    },
  ];
  const [data2, setData2] = useState([
    {
      key: "1",
      menu: "Candidate",
      dashboard:
        props.permissions &&
        props.permissions.find((x) => x === "candidate-list")
          ? true
          : false,
    },
  ]);
  const columns2 = [
    {
      dataIndex: "assigned",
      render: (text, render, index) => (
        <Checkbox
          onClick={(val) => {
            // console.log(val);
            setData2(
              data2.map((x, i) => {
                if (index === i) {
                  return {
                    ...x,
                    list: x.list ? false : true,
                    add: x.add ? false : true,
                    edit: x.edit ? false : true,
                    delete: x.delete ? false : true,
                    detail: x.detail ? false : true,
                    mail_list: x.mail_list ? false : true,
                    mail_add: x.mail_add ? false : true,

                    // time_line: x.time_line ? false : true,
                    // task_list: x.task_list ? false : true,
                    // download_resume: x.download_resume ? false : true,
                    // download_recording: x.download_recording ? false : true,
                    // export: x.export ? false : true,
                    // call: x.call ? false : true,
                    // interview: x.interview ? false : true,
                  };
                } else {
                  return x;
                }
              })
            );
          }}
        >
          <p
            className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.coursorPointer} ${styles.rolesSellectAllLink}`} /*  ${commonCssStyle.coursorPointer} ${styles.rolesSellectAllLink} */
          >
            Candidate
          </p>
        </Checkbox>
      ),
    },
    {
      title: "List",
      dataIndex: "list",

      render: (text, record, i) => {
        return (
          <Checkbox
            onChange={(val) => {
              setData2(
                data2.map((data, index) => {
                  if (index === i) {
                    return {
                      ...data,
                      list: val.target.checked,
                    };
                  } else {
                    return data;
                  }
                })
              );
            }}
            className={`${styles.rolesCheckBoxText} intellcheckbox`}
            checked={text}
          >
            List
          </Checkbox>
        );
      },
    },
    {
      title: "Add",
      dataIndex: "add",

      render: (text, record, i) => {
        return (
          <Checkbox
            onChange={(val) => {
              setData2(
                data2.map((data, index) => {
                  if (index === i) {
                    return {
                      ...data,
                      add: val.target.checked,
                    };
                  } else {
                    return data;
                  }
                })
              );
            }}
            className={`${styles.rolesCheckBoxText} intellcheckbox`}
            checked={text}
          >
            Add
          </Checkbox>
        );
      },
    },
    {
      title: "Edit",
      dataIndex: "edit",

      render: (text, record, i) => {
        return (
          <Checkbox
            onChange={(val) => {
              setData2(
                data2.map((data, index) => {
                  if (index === i) {
                    return {
                      ...data,
                      edit: val.target.checked,
                    };
                  } else {
                    return data;
                  }
                })
              );
            }}
            className={`${styles.rolesCheckBoxText} intellcheckbox`}
            checked={text}
          >
            Edit
          </Checkbox>
        );
      },
    },
    {
      title: "Delete",
      dataIndex: "delete",

      render: (text, record, i) => {
        return (
          <Checkbox
            onChange={(val) => {
              setData2(
                data2.map((data, index) => {
                  if (index === i) {
                    return {
                      ...data,
                      delete: val.target.checked,
                    };
                  } else {
                    return data;
                  }
                })
              );
            }}
            className={`${styles.rolesCheckBoxText} intellcheckbox`}
            checked={text}
          >
            Delete
          </Checkbox>
        );
      },
    },
    {
      title: "Detail",
      dataIndex: "detail",

      render: (text, record, i) => {
        return (
          <Checkbox
            onChange={(val) => {
              setData2(
                data2.map((data, index) => {
                  if (index === i) {
                    return {
                      ...data,
                      detail: val.target.checked,
                    };
                  } else {
                    return data;
                  }
                })
              );
            }}
            className={`${styles.rolesCheckBoxText} intellcheckbox`}
            checked={text}
          >
            Detail
          </Checkbox>
        );
      },
    },
    {
      title: "Mail List",
      dataIndex: "mail_list",

      render: (text, record, i) => {
        return (
          <Checkbox
            onChange={(val) => {
              setData2(
                data2.map((data, index) => {
                  if (index === i) {
                    return {
                      ...data,
                      mail_list: val.target.checked,
                    };
                  } else {
                    return data;
                  }
                })
              );
            }}
            className={`${styles.rolesCheckBoxText} intellcheckbox`}
            checked={text}
          >
            Mail List
          </Checkbox>
        );
      },
    },
    {
      title: "Mail Add",
      dataIndex: "mail_add",

      render: (text, record, i) => {
        return (
          <Checkbox
            onChange={(val) => {
              setData2(
                data2.map((data, index) => {
                  if (index === i) {
                    return {
                      ...data,
                      mail_add: val.target.checked,
                    };
                  } else {
                    return data;
                  }
                })
              );
            }}
            className={`${styles.rolesCheckBoxText} intellcheckbox`}
            checked={text}
          >
            Mail Add
          </Checkbox>
        );
      },
    },
  ];
  const columns = [
    {
      title: "Menu",
      dataIndex: "menu",
      render: (text) => (
        <p
          className={`${styles.rolesCheckBoxText} ${commonCssStyle.marginBottomRemove}`} /* checked={text} */
        >
          {text ? text : "Null"}
        </p>
      ),
    },
    {
      dataIndex: "assigned",
      render: (text, render, index) => (
        <p
          onClick={() => {
            setData(
              data.map((x, i) => {
                if (index === i) {
                  return {
                    ...x,
                    view: x.view ? false : true,
                    create: x.create ? false : true,
                    edit: x.edit ? false : true,
                    delete: x.delete ? false : true,
                  };
                } else {
                  return x;
                }
              })
            );
          }}
          className={styles.rolesSellectAllLink}
        >
          Select All
        </p>
      ),
    },
    {
      title: "View",
      dataIndex: "view",

      render: (text, record, i) => {
        return (
          <Checkbox
            onChange={(val) => {
              setData(
                data.map((data, index) => {
                  if (index === i) {
                    return {
                      ...data,
                      view: val.target.checked,
                    };
                  } else {
                    return data;
                  }
                })
              );
            }}
            className={`${styles.rolesCheckBoxText} intellcheckbox`}
            checked={text}
          >
            View
          </Checkbox>
        );
      },
    },
    {
      title: "Create",
      dataIndex: "create",
      render: (text, record, i) => {
        return (
          <Checkbox
            onChange={(val) => {
              setData(
                data.map((data, index) => {
                  if (index === i) {
                    return {
                      ...data,
                      create: val.target.checked,
                    };
                  } else {
                    return data;
                  }
                })
              );
            }}
            className={`${styles.rolesCheckBoxText} intellcheckbox`}
            checked={text}
          >
            Create
          </Checkbox>
        );
      },
    },
    {
      title: "Edit",
      dataIndex: "edit",

      render: (text, record, i) => {
        return (
          <Checkbox
            onChange={(val) => {
              setData(
                data.map((data, index) => {
                  if (index === i) {
                    return {
                      ...data,
                      edit: val.target.checked,
                    };
                  } else {
                    return data;
                  }
                })
              );
            }}
            className={`${styles.rolesCheckBoxText} intellcheckbox`}
            checked={text}
          >
            Edit
          </Checkbox>
        );
      },
    },
    {
      title: "Delete",
      dataIndex: "delete",

      render: (text, record, i) => {
        return (
          <Checkbox
            onChange={(val) => {
              setData(
                data.map((data, index) => {
                  if (index === i) {
                    return {
                      ...data,
                      delete: val.target.checked,
                    };
                  } else {
                    return data;
                  }
                })
              );
            }}
            className={`${styles.rolesCheckBoxText} intellcheckbox`}
            checked={text}
          >
            Delete
          </Checkbox>
        );
      },
    },
  ];

  //   const [data, setData] = useState([]);

  const [data, setData] = useState([
    {
      key: "1",
      menu: "DashBoard",
      view:
        props.employee &&
        props.employee.permissions.find((x) => x === "dashboard-view")
          ? true
          : false,
      create:
        props.employee &&
        props.employee.permissions.find((x) => x === "dashboard-create")
          ? true
          : false,
      edit:
        props.employee &&
        props.employee.permissions.find((x) => x === "dashboard-edit")
          ? true
          : false,
      delete:
        props.employee &&
        props.employee.permissions.find((x) => x === "dashboard-delete")
          ? true
          : false,
    },
    {
      key: "2",
      menu: "Task & Calendar",
      view:
        props.employee &&
        props.employee.permissions.find((x) => x === "task-view")
          ? true
          : false,
      create:
        props.employee &&
        props.employee.permissions.find((x) => x === "task-create")
          ? true
          : false,
      edit:
        props.employee &&
        props.employee.permissions.find((x) => x === "task-edit")
          ? true
          : false,
      delete:
        props.employee &&
        props.employee.permissions.find((x) => x === "task-delete")
          ? true
          : false,
    },
    {
      key: "3",
      menu: "Client company",
      view:
        props.employee &&
        props.employee.permissions.find((x) => x === "client-view")
          ? true
          : false,
      create:
        props.employee &&
        props.employee.permissions.find((x) => x === "client-create")
          ? true
          : false,
      edit:
        props.employee &&
        props.employee.permissions.find((x) => x === "client-edit")
          ? true
          : false,
      delete:
        props.employee &&
        props.employee.permissions.find((x) => x === "client-delete")
          ? true
          : false,
    },
    {
      key: "4",
      menu: "Candidate",
      view:
        props.employee &&
        props.employee.permissions.find((x) => x === "candidate-view")
          ? true
          : false,
      create:
        props.employee &&
        props.employee.permissions.find((x) => x === "candidate-create")
          ? true
          : false,
      edit:
        props.employee &&
        props.employee.permissions.find((x) => x === "candidate-edit")
          ? true
          : false,
      delete:
        props.employee &&
        props.employee.permissions.find((x) => x === "candidate-delete")
          ? true
          : false,
    },
    {
      key: "5",
      menu: "Requirement",
      view:
        props.employee &&
        props.employee.permissions.find((x) => x === "requirement-view")
          ? true
          : false,
      create:
        props.employee &&
        props.employee.permissions.find((x) => x === "requirement-create")
          ? true
          : false,
      edit:
        props.employee &&
        props.employee.permissions.find((x) => x === "requirement-edit")
          ? true
          : false,
      delete:
        props.employee &&
        props.employee.permissions.find((x) => x === "requirement-delete")
          ? true
          : false,
    },
    {
      key: "6",
      menu: "Recruitment",
      view:
        props.employee &&
        props.employee.permissions.find((x) => x === "recruitment-view")
          ? true
          : false,
      create:
        props.employee &&
        props.employee.permissions.find((x) => x === "recruitment-create")
          ? true
          : false,
      edit:
        props.employee &&
        props.employee.permissions.find((x) => x === "recruitment-edit")
          ? true
          : false,
      delete:
        props.employee &&
        props.employee.permissions.find((x) => x === "recruitment-delete")
          ? true
          : false,
    },
    {
      key: "7",
      menu: "Employee",
      view:
        props.employee &&
        props.employee.permissions.find((x) => x === "employees-view")
          ? true
          : false,
      create:
        props.employee &&
        props.employee.permissions.find((x) => x === "employees-create")
          ? true
          : false,
      edit:
        props.employee &&
        props.employee.permissions.find((x) => x === "employees-edit")
          ? true
          : false,
      delete:
        props.employee &&
        props.employee.permissions.find((x) => x === "employees-delete")
          ? true
          : false,
    },
    {
      key: "8",
      menu: "Reports",
      view:
        props.employee &&
        props.employee.permissions.find((x) => x === "reports-view")
          ? true
          : false,
      create:
        props.employee &&
        props.employee.permissions.find((x) => x === "reports-create")
          ? true
          : false,
      edit:
        props.employee &&
        props.employee.permissions.find((x) => x === "reports-edit")
          ? true
          : false,
      delete:
        props.employee &&
        props.employee.permissions.find((x) => x === "reports-delete")
          ? true
          : false,
    },
    {
      key: "9",
      menu: "Settings",
      view:
        props.employee &&
        props.employee.permissions.find((x) => x === "settings-view")
          ? true
          : false,
      create:
        props.employee &&
        props.employee.permissions.find((x) => x === "settings-create")
          ? true
          : false,
      edit:
        props.employee &&
        props.employee.permissions.find((x) => x === "settings-edit")
          ? true
          : false,
      delete:
        props.employee &&
        props.employee.permissions.find((x) => x === "settings-delete")
          ? true
          : false,
    },
  ]);
  const onSubmit = () => {
    var input = [];
    const Settings = data.find((x) => x.menu === "Settings");
    const ClientCompany = data.find((x) => x.menu === "Client company");
    const Reports = data.find((x) => x.menu === "Reports");
    const Employee = data.find((x) => x.menu === "Employee");
    const Recruitment = data.find((x) => x.menu === "Recruitment");
    const Requirement = data.find((x) => x.menu === "Requirement");
    const Candidate = data.find((x) => x.menu === "Candidate");
    const TaskAndCalendar = data.find((x) => x.menu === "Task & Calendar");
    const DashBoard = data.find((x) => x.menu === "DashBoard");

    if (ClientCompany) {
      if (ClientCompany.view) input.push("client-view");
      if (ClientCompany.create) input.push("client-create");
      if (ClientCompany.edit) input.push("client-edit");
      if (ClientCompany.delete) input.push("client-delete");
    }
    if (Settings) {
      if (Settings.view) input.push("settings-view");
      if (Settings.create) input.push("settings-create");
      if (Settings.edit) input.push("settings-edit");
      if (Settings.delete) input.push("settings-delete");
    }
    if (Reports) {
      if (Reports.view) input.push("reports-view");
      if (Reports.create) input.push("reports-create");
      if (Reports.edit) input.push("reports-edit");
      if (Reports.delete) input.push("reports-delete");
    }
    if (Employee) {
      if (Employee.view) input.push("employees-view");
      if (Employee.create) input.push("employees-create");
      if (Employee.edit) input.push("employees-edit");
      if (Employee.delete) input.push("employees-delete");
    }
    if (Recruitment) {
      if (Recruitment.view) input.push("recruitment-view");
      if (Recruitment.create) input.push("recruitment-create");
      if (Recruitment.edit) input.push("recruitment-edit");
      if (Recruitment.delete) input.push("recruitment-delete");
    }
    if (Requirement) {
      if (Requirement.view) input.push("requirement-view");
      if (Requirement.create) input.push("requirement-create");
      if (Requirement.edit) input.push("requirement-edit");
      if (Requirement.delete) input.push("requirement-delete");
    }
    if (Candidate) {
      if (Candidate.view) input.push("candidate-view");
      if (Candidate.create) input.push("candidate-create");
      if (Candidate.edit) input.push("candidate-edit");
      if (Candidate.delete) input.push("candidate-delete");
    }
    if (TaskAndCalendar) {
      if (TaskAndCalendar.view) input.push("task-view");
      if (TaskAndCalendar.create) input.push("task-create");
      if (TaskAndCalendar.edit) input.push("task-edit");
      if (TaskAndCalendar.delete) input.push("task-delete");
    }
    if (DashBoard) {
      if (DashBoard.view) input.push("dashboard-view");
      if (DashBoard.create) input.push("dashboard-create");
      if (DashBoard.edit) input.push("dashboard-edit");
      if (DashBoard.delete) input.push("dashboard-delete");
    }
    // props.postEmployee_permissionAction(
    //   { employeeId: props.employee.userId, permission: input },
    //   (success) => {}
    // );
  };

  return (
    <>
      <Row gutter={[0, 16]}>
        <Col md={24}>
          <Card style={{ paddingBottom: "30px" }}>
            <Form>
              <Table
                scroll={{ x: "auto" }}
                showHeader={false}
                pagination={false}
                rowKey="key"
                columns={columns1}
                dataSource={data1}
                className="introletable rolesTableStyle"
              />
            </Form>
          </Card>
        </Col>
        <Col md={24}>
          <Card style={{ paddingBottom: "30px" }}>
            <Form>
              <Table
                // scroll={{
                //   x: "auto",
                // }}
                showHeader={false}
                pagination={false}
                rowKey="key"
                columns={columns2}
                dataSource={data2}
                className="introletable rolesTableStyle"
              />
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    //   getEmployeePermissionHandler: (id) => {
    //     dispatch(getEmployee_permissionAction(id));
    //   },
    //   postEmployeePermissionHandler: (data, callback) => {
    //     dispatch(postEmployee_permissionAction(data, callback));
    //   },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ERandP);
{
  /* <Col sm={24}>
                  <Card style={{ padding: "20px 20px 0px 20px" }}>
                    <Row>
                      <h1 className={styles.rolesHeadingText}>Role Permissions</h1>
                    </Row>
                    <Checkbox
                      onChange={(val) => {
                        setData(
                          data.map((x) => {
                            return {
                              ...x,
                              view: val.target.checked,
                              create: val.target.checked,
                              edit: val.target.checked,
                              delete: val.target.checked,
                            };
                          })
                        );
                      }}
                      className="intellcheckbox"
                    >
                      <h3
                        style={{ marginBottom: "20px" }}
                        className={styles.rolesSubHeading}
                      >
                        Select all Permissions
                      </h3>
                    </Checkbox>
                  </Card>
                  <Card style={{ paddingBottom: "30px" }}>
                    <Form>
                      <Table
                        scroll={{
                          x: "auto",
                        }}
                        showHeader={false}
                        pagination={false}
                        rowKey="key"
                        columns={columns}
                        dataSource={data}
                        className="introletable rolesTableStyle"
                      />
                    </Form>
                  </Card>
                </Col>
                <Col sm={24} style={{ marginTop: "10px" }}>
                  <Row justify="end" style={{ gap: "17px", margin: "20px" }}>
                    <Col> 
  {
    /* <Button
                  className={commonCssStyle.buttonSettingsSecondary}
                  onClick={() => {
                    props.onClose();
                  }}
                >
                  Cancel
                </Button> */
}
{
  /* </Col>
                    <Col>
                      {" "}
                      <Button
                        onClick={() => {
                          onSubmit();
                        }}
                        type="primary"
                        className={commonCssStyle.buttonSettingsPrimary}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                    </Col>*/
}
