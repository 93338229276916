import { Button, Card, Col, Divider, message, Row, Tag } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router";
import { BackButtonIcon, EditBorderIcon } from "../../../IconsComp/Icons";
import styles from "../../../Screens/ClientCompany/ClientCompany.module.css";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import ApplyForJobDrawer from "./ApplyForJobDrawer";
import { getExternalJobDetailAction } from "../../../../Reusable/Store/Action/PublicRequirementAction";
import { Link } from "react-router-dom";

function RecruitmentExternalJobDetailPage(props) {
  const params = useParams();
  const location = useLocation();
  const [openApplyJobDrawer, setOpenApplyJobDrawer] = useState(false);
  const [permission, setPermission] = useState("");
  const [backButtonIcon, setBackButtonIcon] = useState(false);

  const [showApplyBtn, setShowApplyBtn] = useState(true);

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.hideAppluBtn &&
      location.state.hideAppluBtn === "true"
    ) {
      setShowApplyBtn(false);
      message.success("Candidate added successfully", 3);
    }
  }, []);

  const hideApplyBtn = () => {
    setShowApplyBtn(false);
  };

  useEffect(() => {
    props.getPublicJobDetailsHandler(params.id);
    if (
      location &&
      location.state &&
      location.state.type &&
      location.state.type === "Apply now"
    ) {
      setOpenApplyJobDrawer(true);
    }
  }, []);

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.commingFrom &&
      location.state.commingFrom === "list page"
    ) {
      setBackButtonIcon(true);
    }
  }, []);

  //changing title
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    <meta
      name="description"
      content={
        props.publicRequirement_jobDeatail &&
        props.publicRequirement_jobDeatail.jobDescription &&
        props.publicRequirement_jobDeatail.jobDescription.replace(
          /(<([^>]+)>)/gi,
          ""
        )
      }
    />;
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href =
      props.publicRequirement_jobDeatail &&
      props.publicRequirement_jobDeatail.logo &&
      props.publicRequirement_jobDeatail.logo;
    <title>
      {props.publicRequirement_jobDeatail &&
        props.publicRequirement_jobDeatail.title &&
        props.publicRequirement_jobDeatail.title}
    </title>;
  }, []);

  //end

  const closeHandler = () => {
    setOpenApplyJobDrawer(false);
  };
  const forMap = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding: "10px 23px 10px 23px",
        }}
        // closable
        // onClose={(e) => {
        //   e.preventDefault();
        //   showModal();
        //   setDeleteTag(tag);
        //   setModaltype("mandatorySkills");
        // }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
          padding: "10px 0px",
        }}
      >
        {tagElem}
      </span>
    );
  };

  const forMapOprtional = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding: "10px 17px 10px 23px",
        }}
        closable={false}
        // onClose={(e) => {
        //   e.preventDefault();
        //   showModal();
        //   setDeleteTag(tag);
        //   setModaltype("optionalSkills");
        // }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
          padding: "10px 0px",
        }}
      >
        {tagElem}
      </span>
    );
  };

  const tagChild =
    props.publicRequirement_jobDeatail &&
    props.publicRequirement_jobDeatail.mandatorySkills &&
    props.publicRequirement_jobDeatail.mandatorySkills.map(forMap);

  const tagChildForOptionalSKills =
    props.publicRequirement_jobDeatail &&
    props.publicRequirement_jobDeatail.optionalSkills &&
    props.publicRequirement_jobDeatail.optionalSkills.map(forMapOprtional);

  return (
    <div
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        height: "100vh",
        padding:"20px"
        // marginLeft: "20px",
      }}
    >
      {!backButtonIcon ? (
        <Row
        // style={{ marginBottom: "2%" }}
        >
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            style={{
              marginTop: "12px",
              marginLeft: "20px",
              marginBottom: "-10px",
            }}
          >
            <p className={commonCssStyle.JobTitleNamedp}>Job Detail</p>
            {/* Job Detail */}
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col xs={24} sm={24} md={24} lg={21}>
              <Card
              // style={{margin:"2% 4% 2% 2%",padding:"1%",width:"100%"}}
              >
                {/* Job Detail */}
                <Row align="middle">
                  <Col style={{ marginLeft: "6px" }}>
                    <Link to={`/jobs/${location.state.BackButtonIcons}`}>
                      <BackButtonIcon color={"var(--icons-color)"} />
                    </Link>
                  </Col>
                  <Col>
                    <p
                      className={commonCssStyle.JobTitleNamedp}
                      style={{ marginTop: "14px" }}
                    >
                      Job Detail
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </>
      )}

      <Row gutter={[48, 24]}>
        <Col xs={24} sm={24} md={24} lg={7} style={{ marginTop: "10px" }}>
          <Card
            xs={24}
            sm={24}
            md={24}
            lg={5}
            style={{ paddingLeft: "5%", paddingTop: "5%" }}
          >
            {props.publicRequirement_jobDeatail &&
              props.publicRequirement_jobDeatail.permission &&
              props.publicRequirement_jobDeatail.permission.find(
                (x) => x === "Job Location"
              ) && (
                <Row style={{ marginBottom: "5px" }}>
                  <Col xs={24} sm={24} md={24} lg={11}>
                    <p className={`${commonCssStyle.externJobDetailHeading}`}>
                      Job Location
                    </p>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={10}>
                    <p className={`${commonCssStyle.externalPageBdyText}`}>
                      {props.publicRequirement_jobDeatail.locationDetails
                        ? props.publicRequirement_jobDeatail.locationDetails
                        : " "}
                    </p>
                  </Col>
                </Row>
              )}

            {props.publicRequirement_jobDeatail &&
              props.publicRequirement_jobDeatail.permission &&
              props.publicRequirement_jobDeatail.permission.find(
                (x) => x === "Department"
              ) && (
                <Row style={{ marginBottom: "5px" }}>
                  <Col xs={24} sm={24} md={24} lg={11}>
                    <p className={`${commonCssStyle.externJobDetailHeading}`}>
                      Department
                    </p>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={10}>
                    <p className={`${commonCssStyle.externalPageBdyText}`}>
                      {props.publicRequirement_jobDeatail.department
                        ? props.publicRequirement_jobDeatail.department
                        : " "}
                    </p>
                  </Col>
                </Row>
              )}

            {props.publicRequirement_jobDeatail &&
              props.publicRequirement_jobDeatail.permission &&
              props.publicRequirement_jobDeatail.permission.find(
                (x) => x === "Duration of work"
              ) && (
                <Row style={{ marginBottom: "5px" }}>
                  <Col xs={24} sm={24} md={24} lg={11}>
                    <p className={`${commonCssStyle.externJobDetailHeading}`}>
                      Work Duration
                    </p>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={10}>
                    <p className={`${commonCssStyle.externalPageBdyText}`}>
                      {props.publicRequirement_jobDeatail.workDuration
                        ? props.publicRequirement_jobDeatail.workDuration
                        : " "}
                    </p>
                  </Col>
                </Row>
              )}

            {props.publicRequirement_jobDeatail &&
              props.publicRequirement_jobDeatail.permission &&
              props.publicRequirement_jobDeatail.permission.find(
                (x) => x === "Min Salary"
              ) && (
                <Row style={{ marginBottom: "5px" }}>
                  <Col xs={24} sm={24} md={24} lg={11}>
                    <p className={`${commonCssStyle.externJobDetailHeading}`}>
                      CTC (in Lakhs)
                    </p>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={10}>
                    <p className={`${commonCssStyle.externalPageBdyText}`}>
                      {props.publicRequirement_jobDeatail.minSalary
                        ? props.publicRequirement_jobDeatail.minSalary
                        : " "}{" "}
                      -
                      {props.publicRequirement_jobDeatail.maxSalary
                        ? props.publicRequirement_jobDeatail.maxSalary
                        : " "}
                    </p>
                  </Col>
                </Row>
              )}

            {props.publicRequirement_jobDeatail &&
              props.publicRequirement_jobDeatail.permission &&
              props.publicRequirement_jobDeatail.permission.find(
                (x) => x === "Currency"
              ) && (
                <Row style={{ marginBottom: "5px" }}>
                  <Col xs={24} sm={24} md={24} lg={11}>
                    <p className={`${commonCssStyle.externJobDetailHeading}`}>
                      Currency
                    </p>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={10}>
                    <p className={`${commonCssStyle.externalPageBdyText}`}>
                      {props.publicRequirement_jobDeatail.currency
                        ? props.publicRequirement_jobDeatail.currency
                        : " "}
                      &nbsp;
                      {props.publicRequirement_jobDeatail
                        ? props.publicRequirement_jobDeatail.currencyText
                        : ""}
                    </p>
                  </Col>
                </Row>
              )}

            {props.publicRequirement_jobDeatail &&
              props.publicRequirement_jobDeatail.permission &&
              props.publicRequirement_jobDeatail.permission.find(
                (x) => x === "Job Code"
              ) && (
                <Row style={{ marginBottom: "5px" }}>
                  <Col xs={24} sm={24} md={24} lg={11}>
                    <p className={`${commonCssStyle.externJobDetailHeading}`}>
                      Job Code
                    </p>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={10}>
                    <p className={`${commonCssStyle.externalPageBdyText}`}>
                      {props.publicRequirement_jobDeatail.jobCode
                        ? props.publicRequirement_jobDeatail.jobCode
                        : " "}
                    </p>
                  </Col>
                </Row>
              )}

            <Row style={{ marginBottom: "5px" }}>
              <Col xs={24} sm={24} md={24} lg={11}>
                <p className={`${commonCssStyle.externJobDetailHeading}`}>
                  Work Mode
                </p>
              </Col>
              <Col xs={24} sm={24} md={24} lg={10}>
                <p className={`${commonCssStyle.externalPageBdyText}`}>
                  {props.publicRequirement_jobDeatail.workMode
                    ? props.publicRequirement_jobDeatail.workMode
                    : " "}
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={14} style={{ marginTop: "10px" }}>
          <Card
            xs={24}
            sm={24}
            md={24}
            lg={5}
            style={{ marginBottom: "40px" }}
            // style={{ paddingLeft: "2%", paddingTop: "2%" }}
          >
            <Row style={{ marginTop: "20px", marginLeft: "20px" }}>
              {props.publicRequirement_jobDeatail &&
              props.publicRequirement_jobDeatail.isJobClosed == "0" ? (
                <Col xs={24} sm={24} md={24} lg={18}>
                  <p
                    className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.textBreak}`}
                  >
                    Job Title
                  </p>
                  <p
                    className={`${commonCssStyle.textBreak} ${styles.txtBlueClrRequirement}`}
                  >
                    {props.publicRequirement_jobDeatail &&
                    props.publicRequirement_jobDeatail.permission &&
                    props.publicRequirement_jobDeatail.permission.find(
                      (x) => x === "Job Title"
                    ) &&
                    props.publicRequirement_jobDeatail.title
                      ? props.publicRequirement_jobDeatail.title
                      : " "}
                  </p>
                </Col>
              ) : (
                <Col lg={18}>
                  <p
                    className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.textBreak}`}
                  >
                    Job Title
                  </p>
                  <p
                    className={`${commonCssStyle.textBreak} ${styles.txtBlueClrRequirement}`}
                  >
                    {props.publicRequirement_jobDeatail &&
                    props.publicRequirement_jobDeatail.permission &&
                    props.publicRequirement_jobDeatail.permission.find(
                      (x) => x === "Job Title"
                    ) &&
                    props.publicRequirement_jobDeatail.title
                      ? props.publicRequirement_jobDeatail.title
                      : " "}
                  </p>
                </Col>
              )}
              {showApplyBtn ? (
                <>
                  {props.publicRequirement_jobDeatail &&
                  props.publicRequirement_jobDeatail.isJobClosed == "0" ? (
                    <Col md={4}>
                      <Link
                        to={"/jobs/apply"}
                        state={{
                          trackingCode:
                            props.publicRequirement_jobDeatail &&
                            props.publicRequirement_jobDeatail.trackingCode &&
                            props.publicRequirement_jobDeatail.trackingCode,
                          commingFrom:
                            location &&
                            location.state &&
                            location.state.commingFrom &&
                            location.state.commingFrom
                              ? location.state.commingFrom
                              : "detail",
                          BackButtonIcons:
                            location &&
                            location.state &&
                            location.state.BackButtonIcons &&
                            location.state.BackButtonIcons,
                          TagsData:
                            props.publicRequirement_jobDeatail &&
                            props.publicRequirement_jobDeatail.skills &&
                            props.publicRequirement_jobDeatail.skills,
                        }}
                      >
                        <Button
                          onClick={() => {
                            console.log("clicked");
                            // setOpenApplyJobDrawer(true);
                          }}
                          className={`${commonCssStyle.copyLinkButton} ${commonCssStyle.hipleExtApplyBtn}`}
                          type="primary"
                        >
                          Apply Now
                        </Button>
                      </Link>
                    </Col>
                  ) : (
                    <Col md={8}>
                      <p style={{ color: "red", marginRight: "5px" }}>
                        The position has been filled or removed
                      </p>
                    </Col>
                  )}
                </>
              ) : (
                ""
              )}
            </Row>

            <Divider type="horizontal" className={styles.seperator} />
            {props.publicRequirement_jobDeatail &&
              props.publicRequirement_jobDeatail.jobType &&
              props.publicRequirement_jobDeatail.permission &&
              props.publicRequirement_jobDeatail.permission.find(
                (x) => x === "Job Type"
              ) && (
                <Row className={styles.jobDetailCardMinHeight}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <p
                      className={`${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor} `}
                    >
                      Job Type
                    </p>
                    {
                      <p
                        className={`${commonCssStyle.textBreak} ${commonCssStyle.jobDetailParagraph}`}
                      >
                        {props.publicRequirement_jobDeatail &&
                        props.publicRequirement_jobDeatail
                          ? props.publicRequirement_jobDeatail.jobType
                          : " "}
                      </p>
                    }
                  </Col>
                </Row>
              )}

            <Row className={styles.jobDetailCardMinHeight}>
              <Col>
                <Row>
                  <Col>
                    <h2
                      className={`${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
                    >
                      Mandatory skills
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p
                      style={{
                        marginTop: "-20px",
                      }}
                    >
                      {tagChild}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className={styles.jobDetailCard1}>
              <Col>
                <Row>
                  <Col>
                    <h2
                      className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
                    >
                      Optional skills
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p
                      style={{
                        marginTop: "-20px",
                      }}
                    >
                      {tagChildForOptionalSKills}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>

            {props.publicRequirement_jobDeatail &&
              props.publicRequirement_jobDeatail.permission &&
              props.publicRequirement_jobDeatail.permission.find(
                (x) => x === "Experience(in years)"
              ) && (
                <Row className={styles.jobDetailCard1} style={{ gap: "25px" }}>
                  <Col>
                    <h2
                      className={`${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor}`}
                    >
                      Min Experience (in years)
                    </h2>
                    <p
                      className={`${commonCssStyle.textBreak} ${commonCssStyle.jobDetailParagraph}`}
                    >
                      {props.publicRequirement_jobDeatail.experienceToMonth
                        ? props.publicRequirement_jobDeatail.experienceToMonth
                        : " "}
                    </p>
                  </Col>
                  <Col>
                    <h2
                      className={`${commonCssStyle.textBreak} ${commonCssStyle.tableHeadingsColor}`}
                    >
                      Max Experience (in years)
                    </h2>
                    <p
                      className={`${commonCssStyle.textBreak} ${commonCssStyle.jobDetailParagraph}`}
                    >
                      {props.publicRequirement_jobDeatail.experienceFromMonth
                        ? props.publicRequirement_jobDeatail.experienceFromMonth
                        : " "}
                    </p>
                  </Col>
                </Row>
              )}

            <Divider type="horizontal" className={styles.seperator} />

            {props.publicRequirement_jobDeatail &&
              props.publicRequirement_jobDeatail.permission &&
              props.publicRequirement_jobDeatail.permission.find(
                (x) => x === "Company"
              ) && (
                <Row className={styles.jobDetailCard}>
                  <Col>
                    <p
                      className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.textBreak}`}
                    >
                      Company
                    </p>
                    <p
                      className={`${commonCssStyle.textBreak} ${commonCssStyle.jobDetailParagraph}`}
                    >
                      {props.publicRequirement_jobDeatail.company
                        ? props.publicRequirement_jobDeatail.company
                        : " "}
                    </p>
                  </Col>
                </Row>
              )}

            {props.publicRequirement_jobDeatail &&
              props.publicRequirement_jobDeatail &&
              props.publicRequirement_jobDeatail.permission &&
              props.publicRequirement_jobDeatail.permission.find(
                (x) => x === "Job Description"
              ) && (
                <Row className={styles.jobDetailCard}>
                  <Col>
                    <p
                      className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.textBreak}`}
                    >
                      Job Description
                    </p>
                    {props.publicRequirement_jobDeatail.jobDescription &&
                      props.publicRequirement_jobDeatail.jobDescription && (
                        <p
                          className={`${commonCssStyle.textBreak} ${commonCssStyle.jobDetailParagraph}`}
                          dangerouslySetInnerHTML={{
                            __html:
                              props.publicRequirement_jobDeatail.jobDescription,
                          }}
                        ></p>
                      )}
                  </Col>
                </Row>
              )}
              <Row justify="center" style={{marginBottom:"40px"}}>
              {showApplyBtn ? (
                <>
                  {props.publicRequirement_jobDeatail &&
                  props.publicRequirement_jobDeatail.isJobClosed == "0" ? (
                    <Col md={4}>
                      <Link
                        to={"/jobs/apply"}
                        state={{
                          trackingCode:
                            props.publicRequirement_jobDeatail &&
                            props.publicRequirement_jobDeatail.trackingCode &&
                            props.publicRequirement_jobDeatail.trackingCode,
                          commingFrom:
                            location &&
                            location.state &&
                            location.state.commingFrom &&
                            location.state.commingFrom
                              ? location.state.commingFrom
                              : "detail",
                          BackButtonIcons:
                            location &&
                            location.state &&
                            location.state.BackButtonIcons &&
                            location.state.BackButtonIcons,
                          TagsData:
                            props.publicRequirement_jobDeatail &&
                            props.publicRequirement_jobDeatail.skills &&
                            props.publicRequirement_jobDeatail.skills,
                        }}
                      >
                        <Button
                          onClick={() => {
                            console.log("clicked");
                            // setOpenApplyJobDrawer(true);
                          }}
                          className={`${commonCssStyle.copyLinkButton} 
                           ${commonCssStyle.rmHipleExtApplyBtn}
                          `}
                          type="primary"
                        >
                          Apply Now
                        </Button>
                      </Link>
                    </Col>
                  ) : (
                    <Col md={8}>
                      <p style={{ color: "red", marginRight: "5px" }}>
                        The position has been filled or removed
                      </p>
                    </Col>
                  )}
                </>
              ) : (
                ""
              )}
              </Row>
          </Card>
        </Col>
      </Row>
      <ApplyForJobDrawer
        openModal={openApplyJobDrawer}
        closeModal={closeHandler}
        trackingCode={
          props.publicRequirement_jobDeatail &&
          props.publicRequirement_jobDeatail.trackingCode &&
          props.publicRequirement_jobDeatail.trackingCode
        }
        hideApplyBtn={hideApplyBtn}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    publicRequirement_jobDeatail:
      state.publicRequirement.publicRequirement_jobDeatail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPublicJobDetailsHandler(id) {
      dispatch(getExternalJobDetailAction(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecruitmentExternalJobDetailPage);
