import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  emailPlaceholders: [],
  emailPlaceholder: null,
};
export const masterEmailPlaceholderSlice = createSlice({
  name: "emailPlaceholders",
  initialState,
  reducers: {
    getEmailPlaceholdersSlice(state, action) {
      state.emailPlaceholders = action.payload.result;
    },
    getEmailPlaceholderSlice(state, action) {
      state.emailPlaceholder = action.payload.result;
    },
    postEmailPlaceholdersSlice(state, action) {
      state.emailPlaceholders = [
        ...state.emailPlaceholders,
        action.payload.result,
      ];
    },
    putEmailPlaceholdersSlice(state, action) {
      state.emailPlaceholders = state.emailPlaceholders.map((data) => {
        if (data.id === action.payload.result.id) {
          return action.payload.result;
        } else {
          return data;
        }
      });
    },
    deleteEmailPlaceholderslice(state, action) {
      state.emailPlaceholders = state.emailPlaceholders.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});
export const {
  getEmailPlaceholdersSlice,
  getEmailPlaceholderSlice,
  postEmailPlaceholderSlice,
  putEmailPlaceholdersSlice,
  deleteEmailPlaceholdersSlice,
} = masterEmailPlaceholderSlice.actions;
export default masterEmailPlaceholderSlice.reducer;
