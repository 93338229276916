import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Table,
  Card,
  Row,
  Select,
  Modal,
} from "antd";
import style from "../../../Components/Screens/Settings/Setting.module.css";
import styles from "../Settings/Application/Application.module.css";
import {
  ClockIcon,
  CloseIcon,
  EditIcon,
  TrashIcon,
} from "../../IconsComp/Icons";
import { connect } from "react-redux";
import {
  getRolesAction,
  postRolesAction,
  deleteRolesAction,
  getRoleAction,
  putRolesAction,
} from "../../../Reusable/Store/Action/Master/MasterRolesAction";
import { getRoleTypesAction } from "../../../Reusable/Store/Action/Master/MasterRoleTypeAction";
import { useForm } from "antd/lib/form/Form";
import commonStyles from "../../UI/CommonCss.module.css";

function Roles(props) {
  const [hoverId, setHoverId] = useState("");
  const [role, SetRole] = useState(null);
  const [deleteRolesModal, setDeleteRolesModal] = useState(false);
  const [deleteRoleId, setDeleteRoleId] = useState("");

  useEffect(() => {
    SetRole(props.roleData);
  }, [props.roleData]);
  function confirmDeleteHandler(id) {
    setDeleteRolesModal(false);
    props.deleteRoleHandler(id);

    setDeleteRoleId("");
  }
  function closeDeleteHandler() {
    setDeleteRolesModal(false);
    setDeleteRoleId("");
  }

  const [form] = useForm();
  useEffect(() => {
    props.getRolesHandlers();
    props.getRolesTypeHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      title: (
        <p
          className={`${commonStyles.marginBottomRemove} ${commonStyles.tableHeadingText}`}
        >
          Role
        </p>
      ),
      dataIndex: "roleType",
      width: "43%",
      render: (data) => {
        return (
          <p
            className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyles.marginBottomRemove} ${commonStyles.tableHeadingText}`}
        >
          SubRole
        </p>
      ),
      dataIndex: "name",
      width: "43%",
      render: (data) => {
        return (
          <p
            className={`${commonStyles.tableData} ${commonStyles.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },

    {
      title: (
        <p
          style={{ textAlign: "left" }}
          className={`${commonStyles.marginBottomRemove} ${commonStyles.tableHeadingText}`}
        >
          Action
        </p>
      ),
      align: "right",
      dataIndex: "action",
      render: (text, render) => (
        <>
          {render.isDefault === 0 && (
            <Row style={{ gap: "30px" }} align="middle">
              {props.permissions &&
                props.permissions.find(
                  (data) => data === "settings-roles-delete"
                ) && (
                  <Col
                    onMouseEnter={() => {
                      setHoverId(render.id);
                    }}
                    onMouseLeave={() => {
                      setHoverId("");
                    }}
                    onClick={() => {
                      setDeleteRoleId(render.id);
                      setDeleteRolesModal(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <TrashIcon
                      color={
                        hoverId !== render.id ? "var(--primaryColor)" : "red"
                      }
                    />
                  </Col>
                )}
              {props.permissions &&
                props.permissions.find(
                  (data) => data === "settings-roles-edit"
                ) && (
                  <Col
                    onClick={() => {
                      props.getRolesByIdHandler(render.id);
                      setTimeout(() => {
                        form.resetFields();
                      }, 1000);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <EditIcon color={"var(--primaryColor)"} />
                  </Col>
                )}
            </Row>
          )}
        </>
      ),
    },
  ];
  function postDataHandler(val) {
    if (role)
      props.editRolesHandlers({ ...val, id: role.id }, (success) => {
        if (success) {
          SetRole(null);
          form.resetFields();
          props.getRolesHandlers();
        }
      });
    else
      props.postRoleHandler(val, (success) => {
        if (success) {
          SetRole(null);
          form.resetFields();
          props.getRolesHandlers();
        }
      });
  }
  return (
    <>
      {props.permissions &&
        props.permissions.find((data) => data === "settings-roles-add") && (
          <Card
            style={{ padding: "10px" }}
            className={`${commonStyles.coverBoady}`}
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={(val) => postDataHandler(val)}
              initialValues={
                role
                  ? {
                      roleType: role.roleType,
                      name: role.name,
                    }
                  : {}
              }
            >
              <Row>
                <Col className={style.modelcol1} md={12} sm={24}>
                  <Form.Item
                    className={style.label}
                    name="roleType"
                    rules={[
                      {
                        required: true,
                        message: "Role is Required",
                      },
                    ]}
                    label="Role"
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      className="roleSelectCss"
                      placeholder="Select Role"
                      value={null}
                      onChange={() => {
                        let roleTypeData = form.getFieldValue("roleType");
                        form.setFieldsValue({
                          name: roleTypeData,
                        });
                      }}
                    >
                      {props.roleTypeData &&
                        props.roleTypeData
                          .filter((x) => x.roleType !== "Super Admin")
                          .map((data) => {
                            return (
                              <Select.Option
                                key={data.id}
                                value={data.roleType}
                              >
                                {data.roleType}
                              </Select.Option>
                            );
                          })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col className={style.modelcol1} md={12} sm={24}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Sub Role title is Required",
                      },
                    ]}
                    label="Internal Designation"
                    className={style.label}
                  >
                    <Input
                      className="roleInput"
                      placeholder=" Enter internal designation"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ float: "right", padding: "20px" }}>
                <div className={style.btndiv}>
                  <Button
                    type="default"
                    className={commonStyles.buttonSettingsSecondary}
                    onClick={() => {
                      SetRole(null);
                      form.resetFields();
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      form.submit();
                    }}
                    htmlType="button"
                    className={commonStyles.buttonSettingsPrimary}
                    style={{ marginLeft: "17px" }}
                  >
                    Save
                  </Button>
                </div>
              </Row>
            </Form>
          </Card>
        )}

      <Card style={{ marginTop: "30px", paddingBottom: "20%" }}>
        <Table
          // scroll={{ x: "100vw" }}
          scroll={{ x: "auto" }}
          pagination={false}
          className="rolesTableStyle"
          columns={columns}
          dataSource={props.roles}
        />
      </Card>

      {/* Delete modal start */}
      <Modal
        closable={false}
        extra={
          <div
            onClick={() => {
              closeDeleteHandler();
            }}
          >
            <CloseIcon color={"var(--body-text-color)"} />
          </div>
        }
        title={
          <Row style={{ gap: "325px" }}>
            <Col>
              <p
                className={`${commonStyles.marginBottomRemove} ${commonStyles.popUpHeadingText}`}
              >
                Delete Roles
              </p>
            </Col>
            <Col>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  closeDeleteHandler();
                }}
                className={`${commonStyles.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
        footer={
          <>
            <Row style={{ gap: "10px" }} justify="end" align="middle">
              <Col>
                <Button
                  type="default"
                  className={commonStyles.buttonSettingsSecondary}
                  onClick={() => {
                    closeDeleteHandler();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className={commonStyles.buttonSettingsPrimary}
                  onClick={() => {
                    confirmDeleteHandler(deleteRoleId);
                  }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </>
        }
        open={deleteRolesModal}
        // onCancel={closeDeleteHandler}
      >
        <Row>
          <Col>
            <p
              className={`${commonStyles.deleteConfiTextColor} ${commonStyles.deleteConfiTextColor}`}
            >
              Are you sure you want to delete
            </p>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    roles: state.roles.roles,
    roleData: state.roles.role,
    roleTypeData: state.roleTypes.roleTypes,
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRolesHandlers: () => {
      dispatch(getRolesAction());
    },
    postRoleHandler: (val, callback) => {
      dispatch(postRolesAction(val, callback));
    },
    deleteRoleHandler: (id) => {
      dispatch(deleteRolesAction(id));
    },
    editRolesHandlers: (data, callback) => {
      dispatch(putRolesAction(data, callback));
    },
    getRolesByIdHandler: (id) => {
      dispatch(getRoleAction(id));
    },
    getRolesTypeHandler: () => {
      dispatch(getRoleTypesAction());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Roles);
