import { Button, Card, Col, Form, Row, Select, Input, Modal } from "antd";
import React, { useEffect, useState, useRef } from "react";
import styles from "../Application/Application.module.css";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import { Tag } from "antd";
import { connect } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";

import { BackButtonIcon, CloseIcon, PlusIcon } from "../../../IconsComp/Icons";
import {
  getSharingMediumsAction,
  postSharingMediumsAction,
  deleteSharingMediumsAction,
  putSharingMediumsAction,
} from "../../../../Reusable/Store/Action/Master/MasterSharingMediumAction";
import { useForm } from "antd/lib/form/Form";
function JobLinkForSharingMedium(props) {
  const [tags, setTags] = useState("");
  const [form] = useForm();
  const [deleteId, setDeleteId] = useState("");
  const [modal, setModal] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    props.getSharingMediumsActionHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setModal(false);
  };
  const handleOpen = (removedTag) => {
    setDeleteId(removedTag);
    setModal(true);
  };
  const deleteConfirmHandler = (data) => {
    const val = {
      id: data,
      status: 0,
    };
    props.putSharingMediumHandler(val);
    setModal(false);
  };

  const handleInputConfirm = (data) => {
    const val = {
      id: data,
      status: 1,
    };
    props.putSharingMediumHandler(val);
  };
  const forMap = (tag) => {
    if (tag === undefined) {
      return;
    }
    const tagElem = tag.status === 1 && (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        closable
        onClose={(e) => {
          e.preventDefault();
          handleOpen(tag.id);
        }}
      >
        {tag.medium}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagChild =
    props.sharingMediumsData && props.sharingMediumsData.map(forMap);

  const onJobLinkChange = (event) => {
    setTags(event.target.value);
  };
  const addJobLinkItem = (e) => {
    e.preventDefault();
    if (tags.trim().length === 0) {
      return;
    }
    form.resetFields();

    tagsHandleConfirm(tags.trim());
    setTags("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  function tagsHandleConfirm(data) {
    const val = {
      medium: data,
      status: true,
    };
    props.postSharingMediumsHandler(val, (success) => {
      if (success) {
        props.getSharingMediumsActionHandler();
      }
    });
  }

  function jobFinishingDataHandler() {}
  return (
    <>
      <Card style={{ overflow: "hidden" }}>
        <Card style={{ overflow: "hidden" }} className={styles.TagsCard1}>
          <Row align="middle" wrap={false}>
            <Col
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.backToApp();
              }}
              className={styles.SourceofTheLeadBackBtn}
            >
              <BackButtonIcon color={"var(--icons-color)"} />
            </Col>
            <Col>
              <h1 className={commonCssStyle.applicationCardName}>
                Job Link for sharing medium
              </h1>
            </Col>
          </Row>
        </Card>
        <Card
          style={{
            minHeight: "70vh",
            margin: "0px 24px",
          }}
        >
          <Form
            form={form}
            onFinish={() => {
              jobFinishingDataHandler();
            }}
            layout="vertical"
          >
            <Row>
              <Col md={24} className="modelcol">
                <Form.Item
                  label={
                    <h1
                      style={{ paddingTop: "24px" }}
                      className={commonCssStyle.darkText}
                    >
                      Sharing Medium
                    </h1>
                  }
                  name="linkForSharingMedium"
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    className="orgSelectCss"
                    allowClear
                    placeholder="Select Link Sharing Medium"
                    onChange={handleInputConfirm}
                    style={{ width: "100%" }}
                    dropdownRender={(menu) => {
                      return (
                        <>
                          {menu}

                          <Input
                            style={{
                              display: "flex",
                              position: "relative",
                              margin: "10px auto",
                              width: "98%",
                            }}
                            className="tagsSelectInput  joblinkSharingMediumSelect"
                            suffix={
                              <Button
                                type="text"
                                style={{
                                  backgroundColor: "transparent",
                                  color: "var(--headings-color)",
                                }}
                                onClick={addJobLinkItem}
                              >
                                <Row style={{ gap: "17px" }}>
                                  <Col>
                                    <PlusIcon
                                      color={"var(--body-text-color)"}
                                    />
                                  </Col>
                                  <Col>
                                    <p
                                      className={
                                        commonCssStyle.marginBottomRemove
                                      }
                                    >
                                      Add item
                                    </p>
                                  </Col>
                                </Row>
                              </Button>
                            }
                            placeholder="Please Enter Link Sharing Medium"
                            ref={inputRef}
                            value={tags}
                            onChange={onJobLinkChange}
                          />
                        </>
                      );
                    }}
                  >
                    {props.sharingMediumsData.map((data) => {
                      return (
                        (data.status === 0 || !data.status) && (
                          <Select.Option key={data.id} value={data.id}>
                            {data.medium}
                          </Select.Option>
                        )
                      );
                    })}
                  </Select>
                  <div
                    style={{
                      marginBottom: 16,
                    }}
                  >
                    <p>{tagChild}</p>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Card>
      <Modal
        open={modal}
        closable={false}
        title={
          <Row style={{ gap: "240px" }}>
            <Col>
              <h3
                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.popUpHeadingText}`}
              >
                Delete sharing medium
              </h3>
            </Col>
            <Col>
              {" "}
              <div
                style={{ float: "right" }}
                onClick={() => {
                  handleClose();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
        onCancel={() => {
          // handleClose();
        }}
        extra={
          <div
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon color={"var(--body-text-color)"} />
          </div>
        }
        footer={
          <>
            <Row style={{ gap: "10px" }} justify="end" align="middle">
              <Col>
                <Button
                  type="default"
                  className={commonCssStyle.buttonSettingsSecondary}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className={commonCssStyle.buttonSettingsPrimary}
                  onClick={() => {
                    deleteConfirmHandler(deleteId);
                  }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Row>
          <Col>
            <p
              className={`${commonCssStyle.deleteConfiTextColor} ${commonCssStyle.deleteConfiTextColor}`}
            >
              Are you sure you want to delete
            </p>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    sharingMediumsData: state.sharingMedium.sharingMediums,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSharingMediumsActionHandler: () => {
      dispatch(getSharingMediumsAction());
    },
    postSharingMediumsHandler: (val, callback) => {
      dispatch(postSharingMediumsAction(val, callback));
    },
    deleteSharingMediumsHandler: (id) => {
      dispatch(deleteSharingMediumsAction(id));
    },
    putSharingMediumHandler: (data) => {
      dispatch(putSharingMediumsAction(data));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobLinkForSharingMedium);
