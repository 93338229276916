import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Checkbox,
  Modal,
  Image,
  Radio,
  DatePicker,
  Tag,
  message,
  Divider,
  Space,
} from "antd";
import React, { useState, useRef, useEffect } from "react";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import { MinusOutlined } from "@ant-design/icons";
import commonStyles from "../../UI/CommonCss.module.css";
// import word from "../../../Assets/Image/word.png";
// import pdf from "../../../Assets/Image/pdf.png";

//flag import

import "antd-country-phone-input/dist/index.css";
import "flagpack/dist/flagpack.css";
import ReactQuill from "react-quill";

import {
  CloseIcon,
  DownLoadIcon,
  MinusIcons,
  NounListIcon,
  PlusForButtonIcon,
  PlusIcon,
  UploadIcon,
} from "../../IconsComp/Icons";
import TextArea from "antd/lib/input/TextArea";
import { Link, useLocation, useNavigate } from "react-router-dom";
//flag import end
import pdf from "../../../Assets/Image/pdf.png";
import word from "../../../Assets/Image/word.png";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReqAndRecMandatorySkills from "./ReqAndRecMandatorySkills";
import AadTagsForSkills from "../../UI/AadTagsForSkills";
import UploadJD from "../Recruitment/UploadJD";
const RequirementDrawer = (props) => {
  // it is used for both requirement and recuritment add
  const [value, setValue] = useState("");
  const [filesData, setFilesData] = useState("");
  const [fileType, setFileType] = useState("");
  const [privacyvalue, setPrivacyValue] = useState("0");
  const [fileRequired, setFileRequired] = useState(true);
  const [fileNameData, setFileNameData] = useState("");
  const [fileResult, setFileResult] = useState(false);
  const [mandatoryTags, setMandatoryTags] = useState([]);
  const [optionalTags, setOptionalTags] = useState([]);

  const [jobPublishedDraft, setJobPublishedDraft] = useState("");

  const [hiringValue, setHiringValue] = useState("external");
  const navigate = useNavigate();
  const onHiringValueChange = (e) => {
    setHiringValue(e.target.value);
  };

  const [form] = useForm();
  const onPrivacyChange = (e) => {
    setPrivacyValue(e.target.value);
  };
  let btnref = useRef(null);
  const skillsData = ["javascript"];
  const modules = {
    toolbar: [
      ["bold"],
      ["italic"],
      ["underline"],
      [{ list: "bullet" }],
      [{ list: "ordered" }],
      [{ align: [] }],
      ["link"],
      // ["image"],
      // ["video"],
      ["code-block"],
    ],
  };
  const { Option } = Select;

  const location = useLocation();
  const { pathname } = location;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [prefillData, setPrefillData] = useState();
  const [commercials, setCommercials] = useState([]);
  const [jdFileData, setJDFileData] = useState(null);

  useEffect(() => {
    form.setFieldsValue({ currency: "₹-(Rupee)" });
  });

  const onChangeCompany = (data) => {
    let companydata = props.clients && props.clients.find((x) => x.id == data);

    form.setFieldsValue({
      standardCommission: "",
      settlementTerms: companydata.paymentTerms,
      replacementClause: companydata.replacementClause,
    });
    setCommercials(companydata.fees);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangeJobDesc = (val) => {
    form.setFieldsValue({
      jobDescription: val,
    });
  };

  const tagRenderForMobileNum = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const resetFilter = () => {
      // form.resetFields();
      // props.candidateFilterHandleer({});
    };

    return (
      <Tag
        color="var(--primaryColor)"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          padding: "5px 17px 5px 23px",
        }}
      >
        {label}
      </Tag>
    );
  };

  const handleChange = (info) => {
    setFileResult(false);
    if (!(info.target.files[0].size <= 5000000)) {
      message.warning("This file should be less than 5 MB", 3);
      return;
    }
    setFilesData(info.target.files[0]);
    setFileType(info.target.files[0].type);
    setFileNameData(info.target.files[0].name);
  };

  const onFinish = (val) => {
    // if (!filesData) {
    //   setFileResult(true);
    //   return;
    // }
    if (jobPublishedDraft === "published") {
      var data = {
        ...val,
        sharingMedium: val.sharingMedium ? val.sharingMedium : [],
        jobStatus: "published",
      };
    } else {
      var data = {
        ...val,
        sharingMedium: val.sharingMedium ? val.sharingMedium : [],
        jobStatus: "draft",
      };
    }
    // console.log(jdFileData ? jdFileData[0] : null, data);
    props.onSubmit({ jdFile: jdFileData ? jdFileData[0] : null, ...data });
    // setOptionalTags([]);
    // setMandatoryTags([]);
    // props.onSubmit(data);
    // console.log({ jdFile: jdFileData, ...data }, jdFileData);
  };
  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  return (
    <>
      <Drawer
        closable={false}
        className={"modalModified"}
        extra={
          <div
            onClick={() => {
              if (location.pathname === "/clientCompany/details/requirement") {
                navigate("/clientCompany/details", {
                  state: location.state,
                });
                props.onClose();
              } else {
                props.onClose();
              }
            }}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
          </div>
        }
        footer={false}
        style={{
          paddingBottom: "0px",
        }}
        title={
          <p
            style={{ fontSize: "20px", margin: "0px" }}
            className={`${commonStyles.modalHeading}`}
          >
            Add Job
          </p>
        }
        width={726}
        height={48}
        // onClose={props.onClose}
        open={props.onOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            positionRequired: null,
            mandatorySkills: [],
            optionalSkills: [],
            salaryMode: null,
            recruiter: null,
            replacementClause: null,
            agreement: null,
            validityStartDate: null,
            validityEndDate: null,
            standardCommission: null,
            standardCommissionType: null,
            // settlementTerms:null,
            jobStatus: null,
            sharingMedium: [],
            jobDescription: "",
            hiringType: "external",
            // makePublic:"0",
            // jdFile: null,
          }}
        >
          <Row
            className={styles.modalRowStyling}
            style={{ padding: "0px 20px 80px 20px" }}
          >
            <Col flex={1}>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Job Title"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please enter job title",
                      },
                    ]}
                  >
                    <Input placeholder="Enter job title" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Job Description"
                    // name="JobDescription1"
                    rules={[
                      {
                        required: true,
                        message: "Please select job description",
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      onChange={onChangeJobDesc}
                      // mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      className={`${commonStyles.border}`}
                      placeholder="Select job description"
                      // tagRender={tagRenderForMobileNum}
                    >
                      {props.jobDescriptionData &&
                        props.jobDescriptionData.find((x) => x.status == "1") &&
                        props.jobDescriptionData.map((data, index) => {
                          return (
                            <Option key={index} value={data.jobDescription}>
                              {data.department}
                            </Option>
                          );
                        })}
                      <Option value="">other</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Job Description"
                    name="jobDescription"
                    rules={[
                      {
                        required: true,
                        message: "Please enter job description",
                      },
                    ]}
                  >
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      value={value}
                      onChange={setValue}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ marginBottom: "20px" }}>
                <Col flex={1}>
                  <UploadJD
                    word={word}
                    pdf={pdf}
                    filesData={(val) => {
                      setJDFileData(val);
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Company"
                    name="clientId"
                    rules={[
                      {
                        required: true,
                        message: "Please select company",
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      onChange={onChangeCompany}
                      // mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      className={`${commonStyles.border}`}
                      placeholder="Select company"
                      // tagRender={tagRenderForMobileNum}
                    >
                      {props.clients &&
                        props.clients.map((data, index) => {
                          return (
                            <Option key={index} value={data.id}>
                              {data.clientName}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Job Type"
                    name="jobType"
                    rules={[
                      {
                        required: true,
                        message: "Please select job type",
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      placeholder="Select job type"
                      allowClear
                    >
                      <Select.Option value="part time">Part time</Select.Option>
                      <Select.Option value="full time">Full time</Select.Option>
                      <Select.Option value="Internship">
                        Internship
                      </Select.Option>
                      <Select.Option value="Contract">Contract</Select.Option>
                      <Select.Option value="Temporary">Temporary</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item label="Duration of work" name="workDuration">
                    {/* <Select placeholder="Select work duration">
                      <Select.Option>Select work duration</Select.Option>
                    </Select> */}
                    <Input placeholder="Enter duration of work" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Job Code"
                    name="jobCode"
                    rules={[
                      {
                        required: false,
                        message: "Please enter job code",
                      },
                    ]}
                  >
                    <Row style={{ gap: "22px" }}>
                      <Col md={5}>
                        <Input
                          readOnly
                          defaultValue={
                            props.settindsData && props.settindsData.jobPrefix
                          }
                        />
                      </Col>
                      <Col md={18}>
                        <Input
                          readOnly
                          defaultValue={props.getJobCode && props.getJobCode}
                          placeholder="Enter job code"
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Designation"
                    name="role"
                    rules={[
                      {
                        required: true,
                        message: "Please enter job role",
                      },
                    ]}
                  >
                    <Input placeholder="Enter job role" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Department"
                    name="department"
                    rules={[
                      {
                        required: true,
                        message: "Please select department",
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      style={{
                        width: "100%",
                      }}
                      allowClear
                      showSearch
                      placeholder="Select department"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <AadTagsForSkills onType={"department"} />
                        </>
                      )}
                    >
                      {props.departments &&
                        props.departments.map((data, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={data.departmentName}
                            >
                              {data.departmentName}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Job Location"
                    name="locationDetails"
                    rules={[
                      {
                        required: true,
                        message: "Please enter job location",
                      },
                    ]}
                  >
                    {/* <Select placeholder="Select Job Location">
                      <Select.Option value="">Select Job Location</Select.Option>
                    </Select> */}
                    <Input placeholder="Enter location" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Area"
                    name="areaDetails"
                    rules={[
                      {
                        required: false,
                        message: "Please enter job area",
                      },
                    ]}
                  >
                    {/* <Select placeholder="Select Job Location">
                      <Select.Option value="">Select Job Location</Select.Option>
                    </Select> */}
                    <Input.TextArea placeholder="Enter area" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Work Mode"
                    name="workMode"
                    rules={[
                      {
                        required: true,
                        message: "Please select work mode",
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      placeholder="Select work mode"
                    >
                      <Select.Option value="Remote">Remote</Select.Option>
                      <Select.Option value="Office">Office</Select.Option>
                      <Select.Option value="Hybrid">Hybrid</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Job Priority"
                    name="priority"
                    // rules={[
                    //   {
                    //     required: false,
                    //     message: "Please select job priority",
                    //   },
                    // ]}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      placeholder="Select job priority"
                    >
                      <Select.Option value="trending">Trending</Select.Option>
                      <Select.Option value="hot">Hot</Select.Option>
                      <Select.Option value="premium">Premium</Select.Option>
                      <Select.Option value="featured">Featured</Select.Option>
                      <Select.Option value="">None</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Required Positions"
                    name="positionRequired"
                    rules={[
                      {
                        required: true,
                        message: "Please select position required",
                      },
                    ]}
                  >
                    <Input placeholder="Enter no of position required" />
                  </Form.Item>
                </Col>
              </Row>

              {/* <ReqAndRecMandatorySkills
                tagsData={props.tagsData}
                pathname={pathname}
              /> */}

              <Row
                style={{
                  marginBottom: "30px",
                  border: "0.1px solid var(--body-text-color)",
                  padding: "10px",
                  borderRadius: "7px",
                }}
              >
                <Col xs={24}>
                  <p style={{ color: "var(--body-text-color)" }}>
                    Mandatory Skills Or Add New Skills Preview
                  </p>
                  {mandatoryTags &&
                    mandatoryTags.length > 0 &&
                    mandatoryTags.map((tagsData, index) => {
                      return (
                        <Tag
                          className="keySkillsTags"
                          onClose={() => {
                            form.setFieldsValue({
                              mandatorySkills: form
                                .getFieldValue("mandatorySkills")
                                .filter((val) => val !== tagsData),
                            });
                            if (
                              form.getFieldValue("mandatorySkills") &&
                              form.getFieldValue("mandatorySkills").length === 0
                            ) {
                              setMandatoryTags([]);
                            }
                          }}
                          closable
                          key={index}
                        >
                          {tagsData}
                        </Tag>
                      );
                    })}
                </Col>
              </Row>

              <Form.Item
                label="Mandatory Skills"
                name="mandatorySkills"
                rules={[
                  {
                    required: true,
                    message: "Please select mandatory skills",
                  },
                ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  className={`TagsStyle `}
                  mode="tags"
                  showSearch
                  maxTagCount={0}
                  tokenSeparators={[","]}
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  onChange={(val) => {
                    setMandatoryTags(val);
                  }}
                  placeholder="Select mandatory skills"
                >
                  {props.tagsData &&
                    props.tagsData
                      .filter((data) => data.type === "skill")
                      .map((data) => {
                        return (
                          <Select.Option key={data.id} value={data.tag}>
                            {data.tag}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Form.Item>
              <Row
                style={{
                  marginBottom: "30px",
                  border: "0.1px solid var(--body-text-color)",
                  padding: "10px",
                  borderRadius: "7px",
                }}
              >
                <Col xs={24}>
                  <p style={{ color: "var(--body-text-color)" }}>
                    Optional Skills Or Add New Skills Preview
                  </p>
                  {optionalTags &&
                    optionalTags.length > 0 &&
                    optionalTags.map((tagsData, index) => {
                      return (
                        <Tag
                          className="keySkillsTags"
                          onClose={() => {
                            form.setFieldsValue({
                              optionalSkills: form
                                .getFieldValue("optionalSkills")
                                .filter((val) => val !== tagsData),
                            });
                            if (
                              form.getFieldValue("optionalSkills") &&
                              form.getFieldValue("optionalSkills").length === 0
                            ) {
                              setOptionalTags([]);
                            }
                          }}
                          closable
                          key={index}
                        >
                          {tagsData}
                        </Tag>
                      );
                    })}
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Form.Item label="Optional Skills" name="optionalSkills">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      className={`TagsStyle `}
                      style={{
                        width: "100%",
                      }}
                      mode="tags"
                      allowClear
                      showSearch
                      maxTagCount={0}
                      onChange={(val) => {
                        setOptionalTags(val);
                      }}
                      tokenSeparators={[","]}
                      // dropdownRender={(menu) => (
                      //   <>
                      //     {menu}
                      //     <AadTagsForSkills onType={"skill"} />
                      //   </>
                      // )}
                      placeholder="Select optional skills"
                    >
                      {props.tagsData &&
                        props.tagsData
                          .filter((data) => data.type === "skill")
                          .map((data) => {
                            return (
                              <Select.Option key={data.id} value={data.tag}>
                                {data.tag}
                              </Select.Option>
                            );
                          })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item
                    label="Min Experience (in years)"
                    name="experienceToMonth"
                    rules={[
                      {
                        required: true,
                        message: "Please enter min experience",
                      },
                    ]}
                  >
                    <Input type="number" placeholder="Enter min experience" />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Max Experience (in years)"
                    name="experienceFromMonth"
                    rules={[
                      {
                        required: true,
                        message: "Please enter max experience",
                      },
                    ]}
                  >
                    <Input type="number" placeholder="Enter max experience" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Currency"
                    name="currency"
                    rules={[
                      {
                        required: true,
                        message: "Please select currency",
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      placeholder="Select currency"
                    >
                      <Select.Option value="₹-(Rupee)">
                        ₹ Indian Rupee
                      </Select.Option>
                      <Select.Option value="$-(Dollar)">$ Dollar</Select.Option>
                      <Select.Option value="₩-(Korea Won)">
                        ₩ Korea Won{" "}
                      </Select.Option>
                      <Select.Option value="£-(Pound)"> £ Pound</Select.Option>
                      <Select.Option value="¥-(Yen)">¥ Yen</Select.Option>
                      <Select.Option value="₦-(Nigeria Naira)">
                        ₦ Nigeria Naira
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item
                    label="Min Salary (CTC in Lakhs)"
                    name="minSalary"
                    rules={[
                      {
                        required: true,
                        message: "Please enter max salary",
                      },
                    ]}
                  >
                    <Input type="number" placeholder="Enter min salary" />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Max Salary (CTC in Lakhs)"
                    name="maxSalary"
                    rules={[
                      {
                        required: true,
                        message: "Please enter min salary",
                      },
                    ]}
                  >
                    <Input type="number" placeholder="Enter max salary" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Salary Mode"
                    name="salaryMode"
                    rules={[
                      {
                        required: true,
                        message: "Please select salary mode",
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      placeholder="Select salary mode"
                    >
                      <Select.Option value="monthly">Monthly</Select.Option>
                      <Select.Option value="one time">One time</Select.Option>
                      <Select.Option value="per two week">
                        Per two week
                      </Select.Option>
                      <Select.Option value="per week">Per week</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Replacement Clause"
                    name="replacementClause"
                  >
                    <Input placeholder="Enter replacement clause" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Agreed commercials"
                    name="standardCommission"
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      placeholder="Select agreed commercials"
                    >
                      {commercials &&
                        commercials.map((data, index) => {
                          return (
                            <Select.Option value={data} key={index}>
                              {data}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item label="Settlement Terms" name="settlementTerms">
                    <Input
                      /* defaultValue={settlementTermsPrefill} */ placeholder="Enter settlement terms"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item label="Billing Value" name="billingValue">
                    <Input placeholder="Enter billing value" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className={commonStyles.sectionEndGap}>
                <Col>
                  <div className="ant-col ant-form-item-label">
                    <label>Sharing Job</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="sharingMedium" md={24}>
                  <Form.List name="sharingMedium">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, key) => (
                          // <div>
                          <Row>
                            <Col flex={1}>
                              <AddLink
                                MediumLink={props}
                                field={field}
                                // dataFormate={dataFormate}
                              />
                            </Col>
                            {key !== 0 && (
                              <Col md={24} style={{ marginBottom: "10px" }}>
                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "8px",
                                    float: "right",
                                    color: "#F83A3A",
                                  }}
                                  className={`${commonStyles.marginBottomRemove} ${commonStyles.removeLocation}`}
                                >
                                  Remove Link
                                </p>
                                <Button
                                  className={commonStyles.removeLocationbtn}
                                  type="primary"
                                  size="small"
                                  shape="circle"
                                  style={{
                                    marginTop: "3px",
                                    marginRight: "7px",
                                    float: "right",
                                    backgroundColor: "#F83A3A",
                                    transform: "translateY(-4px)",
                                  }}
                                >
                                  <div
                                    style={{
                                      transform: "translateY(-4px)",
                                    }}
                                    onClick={() => remove(key)}
                                  >
                                    <MinusIcons color={"var(--theme)"} />
                                  </div>
                                </Button>
                              </Col>
                            )}
                          </Row>
                          // </div>
                        ))}
                        <Button
                          size="small"
                          onClick={() => add()}
                          type="primary"
                          shape="circle"
                          style={
                            {
                              // transform: "transformY(-20px)",
                            }
                          }
                        >
                          <div style={{ transform: "translateY(2px)" }}>
                            <PlusForButtonIcon
                              height={"19px"}
                              width={"19px"}
                              color={"var(--theme)"}
                            />
                          </div>
                        </Button>{" "}
                        <p
                          style={{
                            marginTop: "-27px",
                            marginLeft: "40px",
                          }}
                          className={`${commonStyles.marginBottomRemove} ${commonStyles.addLocation}`}
                        >
                          {" "}
                          Add Link
                        </p>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </Col>
            <Col
              md={24}
              className={`modelcol ${commonStyles.sectionEndGap}`}
            ></Col>
            <Form.Item
              label="Privacy"
              name="makePublic"
              rules={[
                {
                  required: true,
                  message: "Please select private or public",
                },
              ]}
            >
              <Radio.Group
                onChange={onPrivacyChange}
                value={privacyvalue}
                className="radioBtnText"
              >
                <Radio value="1">Public</Radio>
                <Radio value="0">Private</Radio>
              </Radio.Group>
            </Form.Item>

            <Col md={24}>
              <Form.Item
                label="Internal/External Hiring"
                name="hiringType"
                rules={[
                  {
                    required: true,
                    message: "Please select internal or external",
                  },
                ]}
              >
                <Radio.Group
                  onChange={onHiringValueChange}
                  className="radioBtnText"
                >
                  <Radio value="internal">Internal</Radio>
                  <Radio value="external">External</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Col
            style={{ width: "726px" }}
            xs={24}
            md={24}
            className={commonStyles.footerShadow}
          >
            <Row justify="end">
              <Col>
                <Button
                  // onClick={props.onClose}
                  onClick={() => {
                    if (
                      location.pathname === "/clientCompany/details/requirement"
                    ) {
                      navigate("/clientCompany/details", {
                        state: location.state,
                      });
                      props.onClose();
                    } else {
                      props.onClose();
                    }
                  }}
                  style={{ border: "none" }}
                  className={styles.BtnWhite}
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button
                  // onClick={() => {
                  //   form.submit();
                  // }}
                  onClick={() => {
                    form.submit();
                    setJobPublishedDraft("draft");
                  }}
                  htmlType="button"
                  style={{
                    width: "150px",
                    height: "48px",
                    marginRight: "20px",
                  }}
                  className={commonStyles.buttonSettingsSecondary}
                >
                  Save to Draft
                </Button>
              </Col>

              <Col>
                <Button
                  // onClick={showModal}
                  onClick={() => {
                    form.submit();
                    setJobPublishedDraft("published");
                  }}
                  htmlType="button"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  Publish Job
                </Button>
              </Col>

              <Modal
                title=""
                bodyStyle={{ height: 430 }}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
              >
                <Row justify="center" style={{ marginTop: "40px" }}>
                  <Col>
                    <NounListIcon />
                  </Col>
                </Row>

                <Row>
                  <Col flex={1}>
                    {" "}
                    <Row justify="center">
                      <Col>
                        <p>
                          UX Designer Job of Devusoft company has been created
                        </p>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col>
                        <p>sucessfully</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row justify="center">
                  <Link to="/JobDetailPage">
                    <Button type="primary" className={styles.viewJobButton}>
                      View Job
                    </Button>
                  </Link>
                </Row>
              </Modal>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
};

function AddLink({ field, dataFormate, MediumLink }) {
  return (
    <>
      <Row style={{ gap: "20px" }}>
        <Col md={24}>
          <Form.Item
            {...field}
            // name="sharing medium"
            rules={[
              {
                required: false,
                message: "Please select sharing job",
              },
            ]}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              placeholder="Select sharing job"
            >
              {MediumLink.sharingMediumsData &&
                MediumLink.sharingMediumsData.map((data, index) => {
                  return (
                    <Option key={index} value={data.medium}>
                      {data.medium}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col md={19}>
          <Form.Item
            name={[field.name, "trackingLink"]}
            rules={[
              {
                required: true,
                message: "Please enter link",
              },
            ]}
            value={copyValues}
            onChange={(val)=>{setCopyValues(val.target.value)}}
          >
            <Input placeholder="Paste the link here" />
          </Form.Item>
        </Col>
        <Col md={4}>
         <CopyToClipboard  text={copyValues} onCopy={()=>setCopied(true)}>
         <Button type="primary" className={commonStyles.copyLinkButton} >
            Copy Link
          </Button>
         </CopyToClipboard>
        </Col> */}
      </Row>
    </>
  );
}

export default RequirementDrawer;
