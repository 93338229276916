import {
  getRolesSlice,
  getRoleSlice,
  postRolesSlice,
  putRolesSlice,
  deleteRolesSlice,
} from "../../Slice/Master/MasterRolesSlice";
import { ROLES_URL } from "../../masterpath";
import { message } from "antd";
import AxiosApi from "../../api";
const api = new AxiosApi();
export const getRolesAction = () => {
  return (dispatch) => {
    api.invoke(ROLES_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getRolesSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getRoleAction = (id) => {
  return (dispatch) => {
    api.invoke(ROLES_URL + "/" + id, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getRoleSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const postRolesAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      ROLES_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);

        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();

            message.success(data.message, 3);
            dispatch(postRolesSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const putRolesAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      ROLES_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(putRolesSlice(data));
            callback(true);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteRolesAction = (id) => {
  return (dispatch) => {
    api.invoke(ROLES_URL + "/" + id, "delete", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) {
          message.destroy();
          message.success(data.message, 3);
          dispatch(deleteRolesSlice(id));
        } else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
