import { Button, Col, Form, Input, Modal, Row, Select, Tag } from "antd";
import style from "../../../Screens/Settings/Setting.module.css";
import styles from "../Application/Application.module.css";
import React, { useEffect } from "react";

import ReactQuill from "react-quill";
import "../../../../../node_modules/react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";
import "../../../../App.less";
import { useState } from "react";
import { connect } from "react-redux";
import {
  postJobDescriptionsAction,
  putJobDescriptionsAction,
} from "../../../../Reusable/Store/Action/Master/MasterJobDescriptionAction";
import commonStyles from "../../../UI/CommonCss.module.css";
import { ClockIcon, CloseIcon } from "../../../IconsComp/Icons";
import { getTagsAction } from "../../../../Reusable/Store/Action/Master/MasterTagsAction";
import AadTagsForSkills from "../../../UI/AadTagsForSkills";

function AddJobDescriptionModal(props) {
  const [value, setValue] = useState("");
  const [newTagsSkills, setTagsNewSkills] = useState([]);
  const [newTagsForReqSkills, setForReqSkills] = useState([]);

  useEffect(() => {
    if (props.propsJDIdHandler && props.modalNameHandler === "edit") {
      setTagsNewSkills(
        props.propsJDIdHandler.keySkills && props.propsJDIdHandler.keySkills
      );
      setForReqSkills(
        props.propsJDIdHandler.tags && props.propsJDIdHandler.tags
      );
    }
    props.getTagHandler();
  }, []);
  const [form] = Form.useForm();
  const modules = {
    toolbar: [
      ["bold"],
      ["italic"],
      ["underline"],
      [{ list: "bullet" }],
      [{ list: "ordered" }],
      [{ align: [] }],
      ["link"],
      // ["image"],
      // ["video"],
      ["code-block"],
    ],
  };

  function jobDescriptionHandler(val) {
    if (props.modalNameHandler === "add") {
      props.postJobDescriptionHandler({ ...val, status: 1 }, (success) => {
        if (success) props.onCancel1();
      });
    } else {
      const data = {
        id: props.propsJDIdHandler.id,
        status: props.propsJDIdHandler.status,
        ...val,
      };
      props.putJobDescriptionHandler(data, (success) => {
        if (success) props.onOk1(data.id);
      });
    }
    form.resetFields();
  }

  return (
    <>
      <Modal
        closable={false}
        extra={
          <div
            onClick={() => {
              props.onCancel1();
            }}
          >
            <ClockIcon color={"var(--body-text-color)"} />
          </div>
        }
        title={
          <Row style={{ gap: "580px" }}>
            <Col>
              {" "}
              <p
                className={`${commonStyles.marginBottomRemove} ${commonStyles.popUpHeadingText}`}
              >
                {props.propsJDIdHandler && props.modalNameHandler === "edit"
                  ? "Edit Job Description"
                  : "Add Job Description"}
              </p>
            </Col>
            <Col>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  props.onCancel1();
                }}
                className={`${commonStyles.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
        width={840}
        open={props.onOpen1}
        onOk1={props.onOk1}
        // onCancel={props.onCancel1}
        className={`${styles.modalStyle} jobDescriptionModal modalModifiedEmail`}
        footer={false}
      >
        <Form
          form={form}
          onFinish={(val) => jobDescriptionHandler(val)}
          layout="vertical"
          initialValues={
            props.propsJDIdHandler &&
            props.modalNameHandler === "edit" && {
              descriptionTitle: props.propsJDIdHandler.descriptionTitle,
              department: props.propsJDIdHandler.department,
              keySkills: props.propsJDIdHandler.keySkills,
              tags: props.propsJDIdHandler.tags,
              jobDescription: props.propsJDIdHandler.jobDescription,
            }
          }
        >
          <Row className={styles.drawerRowStyling}>
            <Col flex={1}>
              <Row>
                <Col className="modelcol" md={24}>
                  <Form.Item
                    name="descriptionTitle"
                    className={style.label}
                    rules={[
                      {
                        required: true,
                        message: "Please enter description title",
                      },
                    ]}
                    label="Job Title"
                  >
                    <Input className="jobTempInptCss" placeholder="" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="modelcol" md={24}>
                  <Form.Item
                    name="department"
                    className={style.label}
                    rules={[
                      {
                        required: true,
                        message: "Please enter department",
                      },
                    ]}
                    label="Department"
                  >
                    <Input className="jobTempInptCss" placeholder="" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col md={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please enter job description",
                      },
                    ]}
                    name="jobDescription"
                    label="Job Description"
                  >
                    <ReactQuill
                      className="jobDescriptionEmailTemplete"
                      theme="snow"
                      modules={modules}
                      value={value}
                      onChange={setValue}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                style={{
                  marginBottom: "30px",
                  border: "0.1px solid var(--body-text-color)",
                  padding: "10px",
                  borderRadius: "7px",
                }}
              >
                <Col xs={24}>
                  <p style={{ color: "var(--body-text-color)" }}>
                    Key Skills/Tag Preview
                  </p>
                  {newTagsSkills &&
                    newTagsSkills.length > 0 &&
                    newTagsSkills.map((tagsData, tagIndex) => {
                      return (
                        <Tag
                          onClose={() => {
                            form.setFieldsValue({
                              keySkills: form
                                .getFieldValue("keySkills")
                                .filter((val) => val !== tagsData),
                            });
                            if (
                              form.getFieldValue("keySkills") &&
                              form.getFieldValue("keySkills").length === 0
                            ) {
                              setTagsNewSkills([]);
                            }
                          }}
                          className="keySkillsTags"
                          closable
                          key={tagIndex}
                        >
                          {tagsData}
                        </Tag>
                      );
                    })}
                </Col>
              </Row>

              <Row>
                <Col className="modelcol" md={24}>
                  <Form.Item
                    name="keySkills"
                    className={style.label}
                    rules={[
                      {
                        required: true,
                        message: "Please enter key skills",
                      },
                    ]}
                    label="Key Skills (candidate) Or Add New Key Skills (candidate)"
                  >
                    {/* <Input className="jobTempInptCss" placeholder="" /> */}
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      className={`TagsStyle `}
                      mode="tags"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      // dropdownRender={(menu) => (
                      //   <>
                      //     {menu}
                      //     <AadTagsForSkills onType={"candidate"} />
                      //   </>
                      // )}
                      showSearch
                      maxTagCount={0}
                      onChange={(val) => {
                        setTagsNewSkills(val);
                      }}
                      tokenSeparators={[","]}
                      placeholder="Please select or add new key skills"
                    >
                      {props.tagsData &&
                        props.tagsData
                          .filter((data) => data.type === "candidate")
                          .map((data) => {
                            return (
                              <Select.Option key={data.id} value={data.tag}>
                                {data.tag}
                              </Select.Option>
                            );
                          })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{
                  marginBottom: "30px",
                  border: "0.1px solid var(--body-text-color)",
                  padding: "10px",
                  borderRadius: "7px",
                }}
              >
                <Col xs={24}>
                  <p style={{ color: "var(--body-text-color)" }}>
                    Tags (Requirement) Preview
                  </p>
                  {newTagsForReqSkills &&
                    newTagsForReqSkills.length > 0 &&
                    newTagsForReqSkills.map((tagsData, tagIndex) => {
                      return (
                        <Tag
                          onClose={() => {
                            form.setFieldsValue({
                              tags: form
                                .getFieldValue("tags")
                                .filter((val) => val !== tagsData),
                            });
                            if (
                              form.getFieldValue("tags") &&
                              form.getFieldValue("tags").length === 0
                            ) {
                              setForReqSkills([]);
                            }
                          }}
                          className="keySkillsTags"
                          closable
                          key={tagIndex}
                        >
                          {tagsData}
                        </Tag>
                      );
                    })}
                </Col>
              </Row>
              <Row>
                <Col className="modelcol" md={24}>
                  <Form.Item
                    name="tags"
                    className={style.label}
                    rules={[
                      {
                        required: true,
                        message: "Please enter tags",
                      },
                    ]}
                    label="Tags (Requirement) Or Add New Tag"
                  >
                    {/* <Input className="jobTempInptCss" placeholder="" /> */}
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      className={`TagsStyle `}
                      mode="tags"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      // dropdownRender={(menu) => (
                      //   <>
                      //     {menu}
                      //     <AadTagsForSkills onType={"requirement"} />
                      //   </>
                      // )}
                      showSearch
                      onChange={(val) => {
                        setForReqSkills(val);
                      }}
                      maxTagCount={0}
                      tokenSeparators={[","]}
                      placeholder="Please select or add new tags"
                    >
                      {props.tagsData &&
                        props.tagsData
                          .filter((data) => data.type === "requirement")
                          .map((data) => {
                            return (
                              <Select.Option key={data.id} value={data.tag}>
                                {data.tag}
                              </Select.Option>
                            );
                          })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col xs={24} md={24} className={commonStyles.footerShadowForTemplete}>
            <Row justify="end" style={{ gap: "10px" }} align="middle">
              <Col>
                <Button
                  className={commonStyles.buttonSettingsSecondary}
                  onClick={() => {
                    props.onCancel1();
                  }}
                  type="default"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    form.submit();
                  }}
                  htmlType="button"
                  type="primary"
                  className={commonStyles.buttonSettingsPrimary}
                >
                  Publish
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tagsData: state.tags.tags,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postJobDescriptionHandler: (data, callback) => {
      dispatch(postJobDescriptionsAction(data, callback));
    },
    putJobDescriptionHandler: (data, callback) => {
      dispatch(putJobDescriptionsAction(data, callback));
    },
    getTagHandler: () => {
      dispatch(getTagsAction());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddJobDescriptionModal);
