import React from "react";
import { Card, Button, Empty, Row, Col, Table, Tag } from "antd";
import style from "../../../Components/Screens/Settings/Setting.module.css";
import commonCssStyle from "../../UI/CommonCss.module.css";
import moment from "moment";
import { Filedownload } from "../../IconsComp/Icons";
function BillingInvoice({
  settingsSubscription,
  dateFormat,
  settingsCurrency,
}) {
  const columns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Plan Details
        </p>
      ),
      dataIndex: "planDetails",

      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Amount
        </p>
      ),
      dataIndex: "amount",

      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Transaction Id
        </p>
      ),
      dataIndex: "transactionId",

      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Transaction Date
        </p>
      ),
      dataIndex: "transactionDate",

      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data
              ? moment(data).format(dateFormat ? dateFormat : "DD-MM-YYYY")
              : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Payment Type
        </p>
      ),
      dataIndex: "paymentType",

      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Invoice
        </p>
      ),
      dataIndex: "invoiceLink",

      render: (data, record) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && (
              <span
                className={commonCssStyle.coursorPointer}
                onClick={() => {
                  window.open(record.invoiceLink, "_blank");
                }}
              >
                <Filedownload color={"var(--primaryColor)"} />
              </span>
            )}
          </p>
        );
      },
    },

    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Transaction Status
        </p>
      ),
      dataIndex: "status",

      render: (data, record) => {
        return (
          <Row justify="space-between">
            <Col md={24}>
              {data === "active" ? (
                <Tag
                  style={{
                    backgroundColor: "#D2EFCD",
                    color: "#044123",
                    padding: "5px 20px",
                    lineHeight: "21px",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontStyle: "normal",
                    fontFamily: "Helvetica,ProductSans",
                  }}
                >
                  {data}
                </Tag>
              ) : (
                <Tag
                  style={{
                    backgroundColor: "#FFD9D9",
                    padding: "5px 20px",
                    color: "#F83A3A",
                    lineHeight: "21px",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontStyle: "normal",
                    fontFamily: "Helvetica,ProductSans",
                  }}
                >
                  {data}
                </Tag>
              )}
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <>
      {settingsSubscription && settingsSubscription.current ? (
        <>
          <Card size="large" style={{ padding: "20px" }}>
            <div className={style.taskSummaryContentContainer1}>
              <p
                style={{ marginBottom: "27px" }}
                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.headingSettingsText}`}
              >
                Plans and Billing
              </p>
              {settingsSubscription &&
                settingsSubscription.current &&
                settingsSubscription.current.invoiceLink && (
                  <p>
                    <Button
                      className={commonCssStyle.buttonSettingsPrimary}
                      style={{
                        width: "184px",
                        height: "40px",
                        borderRadius: "6px",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                        fontFamily: "Helvetica,Product Sans Medium",
                        fontStyle: "normal",
                      }}
                      onClick={() =>
                        window.open(
                          settingsSubscription.current.invoiceLink,
                          "_blank"
                        )
                      }
                      type="primary"
                    >
                      Download Invoice
                    </Button>
                  </p>
                )}
            </div>
            <div
              style={{ marginBottom: "20px" }}
              className={style.taskSummaryContentContainer}
            >
              <p
                className={`${commonCssStyle.textBreak} ${commonCssStyle.headingSettingsText}`}
              >
                {settingsSubscription.current.planDetails}
                <span
                  className={`${commonCssStyle.textBreak} ${commonCssStyle.headingSettingsText}`}
                  style={{
                    paddingLeft: "5px",
                    color: "var(--primaryColor)",
                  }}
                >
                  {settingsSubscription.current.amount}
                </span>
              </p>
            </div>
            <div style={{ paddingBottom: "40px" }}>
              <p
                style={{ maring: "0px" }}
                className={`${commonCssStyle.textBreak} ${commonCssStyle.textNormalForBillingInvoice}`}
              >
                From&nbsp;
                {moment(settingsSubscription.current.startedOn).format(
                  dateFormat ? dateFormat : "DD-MM-YYYY"
                )}
                &nbsp;&nbsp; To &nbsp;
                {moment(settingsSubscription.current.endsOn).format(
                  dateFormat ? dateFormat : "DD-MM-YYYY"
                )}
              </p>
            </div>
            <div className={style.contentContainer}>
              <div className={style.taskSummaryContentContainer}>
                <p className={`${commonCssStyle.textBreak} ${style.p}`}>
                  Amount
                </p>
                <p className={`${commonCssStyle.textBreak} ${style.p1}`}>
                  {/* {settingsCurrency} */}
                  {settingsSubscription.current.amount}
                </p>
              </div>
              <div className={style.taskSummaryContentContainer}>
                <p className={`${commonCssStyle.textBreak} ${style.p}`}>
                  Payment Method
                </p>
                <p className={`${commonCssStyle.textBreak} ${style.p1}`}>
                  {settingsSubscription.current.paymentType}
                </p>
              </div>
              <div className={style.taskSummaryContentContainer}>
                <p className={`${commonCssStyle.textBreak} ${style.p}`}>
                  Transaction Id
                </p>
                <p className={`${commonCssStyle.textBreak} ${style.p1}`}>
                  {settingsSubscription.current.transactionId}
                </p>
              </div>
              <div className={style.taskSummaryContentContainer}>
                <p className={`${commonCssStyle.textBreak} ${style.p}`}>
                  Transaction date
                </p>
                <p className={`${commonCssStyle.textBreak} ${style.p1}`}>
                  {moment(settingsSubscription.current.transactionDate).format(
                    dateFormat ? dateFormat : "DD-MM-YYYY"
                  )}
                </p>
              </div>
              <div className={style.taskSummaryContentContainer}>
                <p className={`${commonCssStyle.textBreak} ${style.p}`}>
                  Transaction status
                </p>
                <p className={`${commonCssStyle.textBreak} ${style.p1}`}>
                  {settingsSubscription.current.status}
                </p>
              </div>
            </div>
          </Card>
          <Card style={{ marginTop: "20px" }}>
            <Table
              scroll={{ x: "auto" }}
              title={() => (
                <h3
                  style={{
                    padding: "0px 0px 22px 22px",
                    borderBottom: "1px solid #7c7c7c54",
                    color: "var(--headings-color)",
                  }}
                  className={commonCssStyle.tableHeadingText}
                >
                  Plans and Billing History
                </h3>
              )}
              pagination={{
                size: "small",
              }}
              columns={columns}
              dataSource={
                settingsSubscription.history && settingsSubscription.history
              }
              style={{ marginTop: "20px" }}
              className="billingInvoiceTable"
            />
          </Card>
        </>
      ) : (
        <Empty></Empty>
      )}
    </>
  );
}

export default BillingInvoice;
