import { Card, Table } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import commonStyleCss from "../../UI/CommonCss.module.css";
import { getEmployeesAssignToJobAction } from "../../../Reusable/Store/Action/EmployeesAction";
import { useNavigate } from "react-router";

function EmployeeAssignToJobs(props) {
  useEffect(() => {
    props.getJobsAssignedHandler({ id: props.getJobsAssigned });
  }, []);

  const navigate = useNavigate();

  const columns = [
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Job Title
        </p>
      ),
      dataIndex: "title",
      render: (data, render) => {
        return (
          <p
            // onClick={() => {
            //   navigate("/recruitment/recruitmentJobdetails", {
            //     state: {
            //       id: render.jobId,
            //       activeTabKey: "3",
            //       recuritmentJobDetailsName: data,
            //       from: "clientCompany",
            //     },
            //   });
            // }}
            // style={{ color: "var(--primaryColor)" }}
            className={`${commonStyleCss.tableData} ${commonStyleCss.coursorPointer} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Job Department
        </p>
      ),
      dataIndex: "department",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Position Required
        </p>
      ),
      dataIndex: "positionRequired",
      render: (data, render) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : "0"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Job Revenue
        </p>
      ),
      dataIndex: "revenueGenerated",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {props.settindsData.currency && props.settindsData.currency}{" "}
            {data ? data : "0"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Active
        </p>
      ),
      dataIndex: "activeCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Screening
        </p>
      ),
      dataIndex: "screeningCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Interviewing
        </p>
      ),
      dataIndex: "interviewedCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Offered
        </p>
      ),
      dataIndex: "offeredCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Hired
        </p>
      ),
      dataIndex: "hiredCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Declined
        </p>
      ),
      dataIndex: "declinedCount",
      render: (data) => {
        return (
          <p
            className={`${commonStyleCss.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data}
          </p>
        );
      },
    },
  ];

  return (
    <>
      <Card>
        <Table
          scroll={{ x: "auto" }}
          // pagination={{
          //   size: "small",
          // }}
          pagination={false}
          style={{ margin: "0px 5px" }}
          size="small"
          className="JobTable"
          columns={columns}
          dataSource={props.employeeJobs && props.employeeJobs}
        />
      </Card>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    employeeJobs: state.employee.employee_jobs,
    settindsData: state.settings.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobsAssignedHandler: (val) => {
      dispatch(getEmployeesAssignToJobAction(val));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeAssignToJobs);
