// import styles from "../AddCandidatesComp.module.css";
// import style from "../../../Screens/Settings/Application/Application.module.css";
import commonCssStyle from "../../UI/CommonCss.module.css";
import {
  getRequirementsFilterAction,
  deleteRequirementsAction,
} from "../../../Reusable/Store/Action/RequirementAction";
import { useState } from "react";
import { Table, Dropdown, Menu, Row, Col, Button, Select, Modal } from "antd";
import {
  ThreeDotsIcon,
  FilterOutlinedIcon,
  TrashIcon,
  ProfileIcon,
  PlusIcon,
  CloseIcon,
  NewIcon,
  Premium,
  Hot,
  Featured,
  Trending,
} from "../../IconsComp/Icons";
import { getClientsAction } from "../../../Reusable/Store/Action/ClientAction";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import RequirementFilter from "./RequirementFilter";
import RequirementDrawer from "./RequirementDrawer";
import { getDepartmentsAction } from "../../../Reusable/Store/Action/Master/MasterDepartmentsAction";
import { getEmployeesAction } from "../../../Reusable/Store/Action/EmployeesAction";
import { getTagsAction } from "../../../Reusable/Store/Action/Master/MasterTagsAction";
import {
  postRequirementsAction,
  putRequirementsAction,
  getJobCodeAction,
  getRequirementsAction,
} from "../../../Reusable/Store/Action/RequirementAction";
import { getJobDescriptionsAction } from "../../../Reusable/Store/Action/Master/MasterJobDescriptionAction";
import { getSharingMediumsAction } from "../../../Reusable/Store/Action/Master/MasterSharingMediumAction";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import PermissionMessage from "../../UI/PermissionMessage";
import BreadcrumbForRequirement from "./BreadcrumbForRequirement";
import moment from "moment";

let { Option } = Select;
function RequirementDetailsTable(props) {
  const location = useLocation();
  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      // if (
      //   location.state &&
      //   location.state.requiredDrawerOpen &&
      //   location.state.requiredDrawerOpen
      // ) {
      //   setOpen(location.state.requiredDrawerOpen);
      // }
      if (location.pathname === "/clientCompany/details/requirement") {
        setOpen(true);
      }
      props.getJobDescriptionHandler();
      props.getClientsHandler();
      let localFilterData = localStorage.getItem("requirementFilterData");
      let data = JSON.parse(localFilterData);
      if (localFilterData) {
        props.getRequirementsFilterHandler({ current_page: 1, ...data });
      } else {
        props.getRequirementsFilterHandler({ current_page: 1 });
      }
      props.getDepartmentsAction();
      props.getEmployeesAction();
      props.getTagHandler();
      props.getJobCodeAction();
      props.getSharingMediumsActionHandler();
      // props.getRequirementsAction();
      props.getRequirementAction();
    }
    return () => {
      unsubscribed = true;
    };
  }, []);

  if (window.location.hash === "#/requirement") {
    localStorage.removeItem("candidateDetail");
    localStorage.removeItem("employee");
    localStorage.removeItem("clientCompany");
    localStorage.removeItem("recuritmentFilterData");
    localStorage.removeItem("taskFilterData");
    localStorage.removeItem("interviewFilterData");
    localStorage.removeItem("candidateDetailFromRequirement");
    localStorage.removeItem("candidateListForPool");
    localStorage.removeItem("recuritmentCandidateFilter");
    localStorage.removeItem("reportsCompanyDetailFilterData");
    localStorage.removeItem("reportsjobdetailfilterdata");
    localStorage.removeItem("reportsjobcandidatefilterdata");
    localStorage.removeItem("candidateDetailFromRecruitment");
    localStorage.removeItem("recuritmentReportFilter");
    localStorage.removeItem("employeeReportsFilter");
    localStorage.removeItem("candidateSort");
    localStorage.removeItem("interivewSort");
    localStorage.removeItem("taskSort");
    localStorage.removeItem("recuritmentSort");
    localStorage.removeItem("ClientCompanySort");
    localStorage.removeItem("employeeSort");
    localStorage.removeItem("ReportsCompanyReport1Sort");
    localStorage.removeItem("reportsjobcandidatefilterdataSort");
    localStorage.removeItem("ReportsJobDetailFilterOneSort");
    localStorage.removeItem("reportRecruitmentEmployeeSort");
    let check = JSON.parse(localStorage.getItem("fromWhichPage"));
    if (
      (check && check.fromRecuritment === "fromRecuritment") ||
      (check && check.candidateDetail === "candidateDetail")
    ) {
      localStorage.removeItem("fromWhichPage");
    }
  }

  const fetchRecords = (page) => {
    const fdata = JSON.parse(localStorage.getItem("requirementFilterData"));

    if (fdata) {
      const val = {
        ...cfilter,
        ...fdata,
        ...props.requirementsFilter,
        current_page: page,
      };
      localStorage.setItem(
        "requirementFilterData",
        JSON.stringify({ ...fdata, current_page: page })
      );
      props.getRequirementsFilterHandler(val);
    } else {
      const val = {
        ...cfilter,
        ...props.requirementsFilter,
        current_page: page,
      };
      localStorage.setItem(
        "requirementFilterData",
        JSON.stringify({ current_page: page })
      );

      props.getRequirementsFilterHandler(val);
    }
  };
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [deleteBtnClr, setDeleteBtnClr] = useState("var(--headings-color)");
  const [deletId, setDeleteId] = useState("");
  const [openDeleteModal, SetOpenDeleteModal] = useState(false);
  const [openJobModal, SetOpenJobModal] = useState(false);
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  const [cfilter, setCFilter] = useState({});
  const [iconsClrHover, setIconsClrHover] = useState(
    "var(--subHeadings-color)"
  );
  const [iconClr, setIconClr] = useState("var(--subHeadings-color)");
  const [acitveKey, setActiveKey] = useState("1");
  function confirmDeleteHandler() {
    SetOpenDeleteModal(false);
    props.deleteRequirementHandler(deletId, (success) => {});
    setDeleteId("");
  }

  function closeDeleteHandler() {
    SetOpenDeleteModal(false);
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const closeHandler = () => {
    setOpen(false);
  };

  function showFilterHandler() {
    setOpenFilter(true);
  }
  const closeFilterHandler = () => {
    setOpenFilter(false);
  };
  const editJob = () => {
    SetOpenJobModal(true);
  };

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const columns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Job Title
        </p>
      ),
      dataIndex: "title",
      render: (text, render) => (
        <>
          {props.permissions &&
          props.permissions.find((data) => data === "job-detail") &&
          render.status !== "closed" ? (
            <p
              onClick={() => {
                props.getCheckPermissionHandler("job-detail", (success) => {
                  if (success) {
                    navigate("/JobDetailPage", {
                      state: { id: render.id, activeTabKey: "1" },
                    });
                  } else {
                    setOpenPMModal({
                      open: true,
                      permission: "Job Details",
                    });
                  }
                });
              }}
              // to={"/JobDetailPage"}
              // state={{ id: render.id, activeTabKey: "1" }}
              style={{
                color: "var(--primaryColor)",
                fontFamily: "Helvetica,ProductSans",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "17px",
                margin: "0px",
                cursor: "pointer",
              }}
            >
              <Row>
                <Col>{text}</Col>
                <Col className={commonCssStyle.newIcon}>
                  {render.priority == "trending" ? (
                    <Trending />
                  ) : render.priority == "hot" ? (
                    <Hot />
                  ) : render.priority == "premium" ? (
                    <Premium />
                  ) : render.priority == "featured" ? (
                    <Featured />
                  ) : (
                    <NewIcon />
                  )}
                </Col>
              </Row>
            </p>
          ) : (
            <Row>
              <Col>{text}</Col>
              <Col className={commonCssStyle.newIcon}>
                {render.priority == "trending" ? (
                  <Trending />
                ) : render.priority == "hot" ? (
                  <Hot />
                ) : render.priority == "premium" ? (
                  <Premium />
                ) : render.priority == "featured" ? (
                  <Featured />
                ) : (
                  <NewIcon />
                )}
              </Col>
            </Row>
          )}
        </>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Job Id
        </p>
      ),
      dataIndex: "jobCode",
      render: (data) => (
        <p
          className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
        >
          {data ? data : "NA"}
        </p>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Company Name
        </p>
      ),
      dataIndex: "clientName",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Department
        </p>
      ),
      dataIndex: "department",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Position Closed
        </p>
      ),
      dataIndex: "positionClosed",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Created Date
        </p>
      ),
      dataIndex: "createdAt",
      render: (data) => (
        <p
          style={{ whiteSpace: "nowrap" }}
          className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
        >
          {data ? moment(data).format(dataFormate) : "NA"}
        </p>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Billing
        </p>
      ),
      dataIndex: "billingValue",
      render: (data) => (
        <p
          className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
        >
          {props.settindsData.currency && props.settindsData.currency}{" "}
          {data ? data : "NA"}
        </p>
      ),
    },

    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Status
        </p>
      ),
      dataIndex: "status",

      render: (text, render) => (
        <Row justify="space-between" wrap={false}>
          {props.permissions &&
          props.permissions.find((data) => data === "job-edit") ? (
            <Col>
              <Select
                className={`${
                  render.status == "open"
                    ? "jobStatus3"
                    : render.status == "closed"
                    ? "jobStatus1"
                    : "jobStatus2"
                }`}
                size="small"
                // defaultValue={render.status && render.status}
                value={render.status && render.status}
                style={{
                  /* color: "#044123", */ width: "90px",
                  color:
                    render.status == "open"
                      ? "#044123"
                      : render.status == "closed"
                      ? "#F83A3A"
                      : "#FF9A02",
                }}
                onChange={(val) => {
                  props.getCheckPermissionHandler("job-edit", (success) => {
                    if (success) {
                      props.putRequirementHandler(
                        {
                          id: render.id,
                          _method: "PUT",
                          status: val,
                        },
                        "details",
                        (success) => {
                          if (success) {
                            props.getRequirementsFilterHandler({
                              current_page: 1,
                            });
                          }
                        }
                      );
                    } else {
                      setOpenPMModal({
                        open: true,
                        permission: "Job Edit",
                      });
                    }
                  });
                }}
              >
                <Select.Option key="1" value="open">
                  Open
                </Select.Option>
                <Select.Option key="2" value="closed">
                  Closed
                </Select.Option>
                <Select.Option key="3" value="on hold">
                  On Hold
                </Select.Option>
              </Select>
            </Col>
          ) : (
            <Col>
              <p
                style={{
                  color:
                    render.status == "open"
                      ? "#044123"
                      : render.status == "closed"
                      ? "#F83A3A"
                      : "#FF9A02",
                }}
                className={`${commonCssStyle.marginBottomRemove}`}
              >
                {text}
              </p>
            </Col>
          )}

          {render.status !== "closed" && (
            <Col>
              <Dropdown
                placement="bottomRight"
                overlay={
                  <Menu
                    style={{ paddingBottom: "10px" }}
                    items={[
                      {
                        key: "1",
                        label: (
                          <p
                            onClick={() => {
                              props.getCheckPermissionHandler(
                                "job-assign-employee",
                                (success) => {
                                  if (success) {
                                    navigate("/JobDetailPage", {
                                      state: {
                                        id: render.id,
                                        activeTabKey: "2",
                                      },
                                    });
                                  } else {
                                    setOpenPMModal({
                                      open: true,
                                      permission: "Job Assign Employee",
                                    });
                                  }
                                }
                              );
                            }}
                            // to="/JobDetailPage"
                            // state={{ id: render.id, activeTabKey: "2" }}
                          >
                            <Row
                              align="middle"
                              onMouseEnter={() => {
                                setIconClr("#2F7BF5");
                              }}
                              onMouseLeave={() => {
                                setIconClr("var(--body-text-color)");
                              }}
                            >
                              <Col
                                style={{
                                  padding: "5px 5px 0px 0px",
                                  transform: "translateY(3px)",
                                }}
                              >
                                <ProfileIcon color={iconClr} />
                              </Col>

                              <Col style={{ padding: "5px 5px 0px 5px" }}>
                                <p style={{ margin: "0px", color: iconClr }}>
                                  Assign Recuriter
                                </p>
                              </Col>
                            </Row>
                          </p>
                        ),
                      },
                      // {
                      //   key: "2",
                      //   label: (
                      //     <Row
                      //       onMouseEnter={() => {
                      //         setIconClr("#2F7BF5");
                      //       }}
                      //       onMouseLeave={() => {
                      //         setIconClr("black");
                      //       }}
                      //       onClick={() => {
                      //         props.putCandidateTemplateHandler(
                      //           {
                      //             ...render,
                      //             status:
                      //               render.status === "active"
                      //                 ? "inactive"
                      //                 : "active",
                      //           },
                      //           (success) => {
                      //             props.getCandidateFilterHandler({
                      //               ...cfilter,
                      //               sortBy: "lastUpdated",
                      //               sortOrder: "DESC",
                      //             });
                      //           }
                      //         );
                      //       }}
                      //     >
                      //       {render.status && render.status === "active" ? (
                      //         <>
                      //           <Col
                      //             style={{
                      //               padding: "5px 5px 0px 0px",

                      //               transform: "translateY(3px)",
                      //             }}
                      //           >
                      //             <EditIcon color={iconClr} />
                      //           </Col>
                      //           {openJobModal && <RequirementDrawer />}
                      //           <Col style={{ padding: "11px 5px 0px 5px" }}>
                      //             <p style={{ margin: "0px", color: iconClr }}>
                      //               Edit
                      //             </p>
                      //           </Col>
                      //         </>
                      //       ) : (
                      //         <>
                      //           <Col
                      //             style={{
                      //               padding: "5px 5px 0px 0px",

                      //               transform: "translateY(3px)",
                      //             }}
                      //           >
                      //             <UserIcon color={iconClr} />
                      //           </Col>

                      //           <Col style={{ padding: "11px 5px 0px 5px" }}>
                      //             <p style={{ margin: "0px", color: iconClr }}>
                      //               Enable
                      //             </p>
                      //           </Col>
                      //         </>
                      //       )}
                      //     </Row>
                      //   ),
                      // },
                      props.permissions &&
                        props.permissions.find(
                          (data) => data === "job-delete"
                        ) && {
                          key: "3",
                          label: (
                            <Row
                              onClick={() => {
                                props.getCheckPermissionHandler(
                                  "job-delete",
                                  (success) => {
                                    if (success) {
                                      SetOpenDeleteModal(true);
                                      setDeleteId(render.id);
                                    } else {
                                      setOpenPMModal({
                                        open: true,
                                        permission: "Candidate Edit",
                                      });
                                    }
                                  }
                                );
                              }}
                              onMouseEnter={() => {
                                setDeleteBtnClr("#F83A3A");
                              }}
                              onMouseLeave={() => {
                                setDeleteBtnClr("var(--headings-color)");
                              }}
                            >
                              <Col
                                style={{
                                  padding: "5px 5px 0px 0px",
                                  transform: "translateY(3px)",
                                }}
                              >
                                <TrashIcon color={deleteBtnClr} />
                              </Col>
                              <Col
                                style={{
                                  padding: "10px 5px 0px 5px",
                                  color: deleteBtnClr,
                                }}
                              >
                                <p
                                  style={{
                                    margin: "0px",
                                    color: deleteBtnClr,
                                  }}
                                >
                                  Delete
                                </p>
                              </Col>
                            </Row>
                          ),
                        },
                    ]}
                  />
                }
              >
                <Link onClick={(e) => e.preventDefault()}>
                  <ThreeDotsIcon color={"var(--table-text-color)"} />
                </Link>
              </Dropdown>
            </Col>
          )}
        </Row>
      ),
    },
  ];

  const requirementFilterHandleer = (val) => {
    let requirementFilter = JSON.parse(
      localStorage.getItem("requirementFilterData")
    );
    const data = {
      ...val,
      ...requirementFilter,
      current_page: 1,
    };
    setCFilter(data);
    props.getRequirementsFilterHandler(data);
  };

  const onSubmit = (val) => {
    const data = {
      ...val,
      current_page: 1,
      // _method: "PUT",
    };

    props.postRequirementHandler(data, (success) => {
      if (success) {
        props.getJobCodeAction();
        setOpen(false);
        if (location.pathname === "/clientCompany/details/requirement") {
          navigate("/clientCompany/details", {
            state: location.state,
          });
        }
      }
    });
  };
  const handleProvinceChange = (value) => {
    localStorage.setItem("requirementSort", value);
    let requirementFilter = JSON.parse(
      localStorage.getItem("requirementFilterData")
    );
    let sort = value.split("_");
    if (requirementFilter) {
      localStorage.setItem(
        "requirementFilterData",
        JSON.stringify({
          current_page: 1,
          ...cfilter,
          ...requirementFilter,
          sortBy: sort[0],
          sortOrder: sort[1],
        })
      );
      props.getRequirementsFilterHandler({
        current_page: 1,
        ...cfilter,
        ...requirementFilter,
        sortBy: sort[0],
        sortOrder: sort[1],
      });
      setCFilter({
        current_page: 1,
        ...cfilter,
        ...requirementFilter,
        sortBy: sort[0],
        sortOrder: sort[1],
      });
    } else {
      localStorage.setItem(
        "requirementFilterData",
        JSON.stringify({
          current_page: 1,
          ...cfilter,
          sortBy: sort[0],
          sortOrder: sort[1],
        })
      );
      props.getRequirementsFilterHandler({
        current_page: 1,
        ...cfilter,
        sortBy: sort[0],
        sortOrder: sort[1],
      });
      setCFilter({
        current_page: 1,
        ...cfilter,
        sortBy: sort[0],
        sortOrder: sort[1],
      });
    }
  };
  const candidateFilterHandleer = (val) => {
    setCFilter(val);
    props.getCandidateFilterHandler(val);
  };
  return (
    <>
      <Row>
        <Col md={24} className={commonCssStyle.breadcrumbBottomMargin}>
          <BreadcrumbForRequirement slide={[1, 2]} />
        </Col>
        <Col md={24}>
          {open && (
            <RequirementDrawer
              onOpen={open}
              onClose={closeHandler}
              clients={props.clients}
              departments={props.departments}
              employees={props.employees}
              tagsData={props.tagsData}
              filterData={props.requirementsFilter}
              settindsData={props.settindsData}
              jobDescriptionData={props.jobDescriptionsData}
              getJobCode={props.requirementJobCode}
              onSubmit={onSubmit}
              sharingMediumsData={props.sharingMediumsData}
            />
          )}
          {/* {openFilter && ( */}
          <RequirementFilter
            requirementFilterHandleer={requirementFilterHandleer}
            filterData={props.requirementsFilter}
            onOpenFilter={openFilter}
            onCloseFilter={closeFilterHandler}
            tagsData={props.tagsData}
            requirements={props.requirements}
            departments={props.departments}
          />
          {/* )} */}

          <Row align="middle" justify="space-between">
            <Col>
              <h1
                style={{ marginRight: "51px" }}
                className={`${commonCssStyle.inttitle} ${commonCssStyle.mainHeadingText}`}
              >
                Jobs &#40;{" "}
                {props.requirementsFilter && props.requirementsFilter.total
                  ? props.requirementsFilter.total
                  : "0"}{" "}
                &#41;
              </h1>

              <Button
                onClick={showFilterHandler}
                className={`${commonCssStyle.filterButton}`}
              >
                <Row style={{ gap: "11px" }}>
                  <Col>
                    <div style={{ transform: "translateY(2px)" }}>
                      <FilterOutlinedIcon color={"var(--primaryColor)"} />
                    </div>
                  </Col>
                  <Col>
                    <p
                      className={`${commonCssStyle.filterAndSortTextStyle} ${commonCssStyle.marginBottomRemove}`}
                    >
                      Filter
                    </p>
                  </Col>
                </Row>
              </Button>

              <Button
                style={{
                  height: "40px",
                }}
                className={`${commonCssStyle.sortBtn}`}
              >
                <Row align="middle" justify="space-between">
                  <Col>
                    <p
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      Sort By
                    </p>
                  </Col>
                  <Col>
                    <Select
                      value={
                        localStorage.getItem("requirementSort")
                          ? localStorage.getItem("requirementSort")
                          : "Select"
                      }
                      style={{ width: "100%" }}
                      bordered={false}
                      onChange={handleProvinceChange}
                      placeholder={
                        <p
                          style={{
                            margin: "0px",
                            color: "var(--primaryColor)",
                          }}
                        >
                          Select
                        </p>
                      }
                      size="small"
                      className={`${commonCssStyle.filterDrop} selectInput sortBySelectStyle selectTag selectTag1`}
                    >
                      <Select.Option
                        value="title_ASC"
                        key="1"
                        style={{ padding: "10px" }}
                      >
                        Title Ascending
                      </Select.Option>
                      <Select.Option
                        value="title_DESC"
                        key="2"
                        style={{ padding: "10px" }}
                      >
                        Title Descending
                      </Select.Option>
                      {/* <Select.Option
                        value="lastUpdated_ASC"
                        key="3"
                        style={{ padding: "10px" }}
                      >
                        Last Updated Ascending
                      </Select.Option>
                      <Select.Option
                        value="lastUpdated_DESC"
                        key="4"
                        style={{ padding: "10px" }}
                      >
                        Last Updated Descending
                      </Select.Option> */}
                      <Select.Option
                        value="createdDate_ASC"
                        key="5"
                        style={{ padding: "10px" }}
                      >
                        Created Date Ascending
                      </Select.Option>
                      <Select.Option
                        value="createdDate_DESC"
                        key="6"
                        style={{ padding: "10px" }}
                      >
                        Created Date Descending
                      </Select.Option>
                      <Select.Option
                        value="department_ASC"
                        key="7"
                        style={{ padding: "10px" }}
                      >
                        Department Ascending
                      </Select.Option>
                      <Select.Option
                        value="department_DESC"
                        key="8"
                        style={{ padding: "10px" }}
                      >
                        Department Descending
                      </Select.Option>
                      <Select.Option
                        value="jobType_ASC"
                        key="9"
                        style={{ padding: "10px" }}
                      >
                        Job Type Ascending
                      </Select.Option>
                      <Select.Option
                        value="jobType_DESC"
                        key="10"
                        style={{ padding: "10px" }}
                      >
                        Job Type Descending
                      </Select.Option>
                      <Select.Option
                        value="role_ASC"
                        key="11"
                        style={{ padding: "10px" }}
                      >
                        Role Ascending
                      </Select.Option>
                      <Select.Option
                        value="role_DESC"
                        key="12"
                        style={{ padding: "10px" }}
                      >
                        Role Descending
                      </Select.Option>
                      <Select.Option
                        value="clientComapny_ASC"
                        key="13"
                        style={{ padding: "10px" }}
                      >
                        Client Comapny Ascending
                      </Select.Option>
                      <Select.Option
                        value="clientComapny_DESC"
                        key="14"
                        style={{ padding: "10px" }}
                      >
                        Client Comapny Descending
                      </Select.Option>
                    </Select>
                  </Col>
                </Row>
              </Button>
            </Col>

            {props.permissions &&
              props.permissions.find((data) => data === "job-add") && (
                <Button
                  type="primary"
                  style={{
                    boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.15)",
                    marginBottom: "17px",
                  }}
                  onClick={() => {
                    props.getCheckPermissionHandler("job-add", (success) => {
                      if (success) {
                        showDrawer("add", null);
                      } else {
                        setOpenPMModal({
                          open: true,
                          permission: "Job Add",
                        });
                      }
                    });
                  }}
                  className={commonCssStyle.intaddButton}
                >
                  <Row align="middle" style={{ gap: "11px" }}>
                    <Col>
                      <PlusIcon color={"white"} />
                    </Col>
                    <Col>
                      <p className={`${commonCssStyle.marginBottomRemove} `}>
                        Add new Job
                      </p>
                    </Col>
                  </Row>
                </Button>
              )}
          </Row>

          <Col style={{ marginTop: "20px" }} xs={24}>
            <Table
              scroll={{ x: "auto" }}
              pagination={{
                size: "small",
                current:
                  props.requirementsFilter &&
                  props.requirementsFilter.current_page
                    ? props.requirementsFilter.current_page
                    : 1,
                pageSize:
                  props.requirementsFilter && props.requirementsFilter.per_page,
                total:
                  props.requirementsFilter && props.requirementsFilter.total,
                showSizeChanger: false,
                onChange: (props) => {
                  fetchRecords(props);
                },
              }}
              rowKey="key"
              columns={columns}
              dataSource={props.requirementsFilter.data}
            />
          </Col>
        </Col>
      </Row>
      {/* modal start */}
      <Modal
        closable={false}
        title={
          <Row style={{ gap: "342px" }}>
            <Col>
              <p className={commonCssStyle.popUpHeadingText}>Delete Job</p>
            </Col>
            <Col>
              {" "}
              <div
                style={{ float: "right" }}
                onClick={() => {
                  closeDeleteHandler();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
        footer={
          <>
            <Row style={{ gap: "10px" }} justify="end" align="middle">
              <Col>
                <Button
                  className={commonCssStyle.buttonSettingsSecondary}
                  type="default"
                  style={{ height: "48px", fontSize: "16px", width: "181px" }}
                  onClick={() => {
                    closeDeleteHandler();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  className={commonCssStyle.buttonSettingsPrimary}
                  type="primary"
                  style={{
                    fontSize: "16px",
                    height: "48px",
                    width: "181px",
                    borderRadius: "7px",
                  }}
                  onClick={() => {
                    confirmDeleteHandler();
                  }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </>
        }
        open={openDeleteModal}
        onOk={confirmDeleteHandler}
      >
        <Row>
          <Col>
            <p className={commonCssStyle.tableHeadingTextData}>
              Are you sure you want to delete
            </p>
          </Col>
        </Row>
      </Modal>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    clients: state.client.clients,
    requirementsFilter: state.requirements.requirement_filter,
    departments: state.departments.departments,
    employees: state.employee.employees,
    tagsData: state.tags.tags,
    settindsData: state.settings.settings,
    jobDescriptionsData: state.jobDescription.jobDescriptions,
    requirementJobCode: state.requirements.requirementJobCode,
    sharingMediumsData: state.sharingMedium.sharingMediums,
    requirements: state.requirements.requirements,
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRequirementsFilterHandler: (val) => {
      dispatch(getRequirementsFilterAction(val));
    },
    postRequirementHandler: (data, callback) => {
      dispatch(postRequirementsAction(data, callback));
    },
    putRequirementHandler: (data, type, callback) => {
      dispatch(putRequirementsAction(data, type, callback));
    },
    deleteRequirementHandler: (id, callback) => {
      dispatch(deleteRequirementsAction(id, callback));
    },
    getClientsHandler: () => {
      dispatch(getClientsAction());
    },
    getDepartmentsAction: () => {
      dispatch(getDepartmentsAction());
    },
    getEmployeesAction: () => {
      dispatch(getEmployeesAction());
    },
    getTagHandler: () => {
      dispatch(getTagsAction());
    },
    getJobDescriptionHandler: () => {
      dispatch(getJobDescriptionsAction());
    },
    getJobCodeAction: () => {
      dispatch(getJobCodeAction());
    },
    getSharingMediumsActionHandler: () => {
      dispatch(getSharingMediumsAction());
    },
    getRequirementAction: () => {
      dispatch(getRequirementsAction());
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequirementDetailsTable);
