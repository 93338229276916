import { Button, Col, Form, Input, Row } from "antd";
import { PlusIcon } from "../IconsComp/Icons";
import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import commonStyles from "../UI/CommonCss.module.css";
import { connect } from "react-redux";
import { postTagsAction } from "../../Reusable/Store/Action/Master/MasterTagsAction";
import { postDepartmentsAction } from "../../Reusable/Store/Action/Master/MasterDepartmentsAction";
function AadTagsForSkills(props) {
  // const [newTags, setNewTags] = useState([]);
  let index = 0;
  const inputRef = useRef(null);

  const [name, setName] = useState("");
  const onNameChange = (event) => {
    setName(event.target.value);
  };

  // useEffect(() => {
  //   if (newTags && newTags.length > 0) {
  //     props.candidateTag(newTags);
  //   }
  // }, [newTags]);
  const addItem = (e) => {
    e.preventDefault();
    if (name.trim().length === 0) {
      return;
    }
    if (props.onType && props.onType === "skill") {
      const val = {
        type: "skill",
        tag: name,
      };
      props.postTagHandler(val);
      setName("");
    } else if (props.onType && props.onType === "candidate") {
      const val = {
        type: "candidate",
        tag: name,
      };
      props.postTagHandler(val);
      setName("");
      // setNewTags((x) => [...x, val.tag]);
      // props.candidateTag(val.tag);
    } else if (props.onType && props.onType === "requirement") {
      const val = {
        type: "requirement",
        tag: name,
      };
      props.postTagHandler(val);
      setName("");
    } else if (props.onType && props.onType === "department") {
      props.postDepartmentHandler({ departmentName: name }, (success) => {
        if (success) {
          setName("");
        }
      });
    }

    // setTimeout(() => {
    //   inputRef.current?.focus();
    // }, 0);
  };
  return (
    <Row justify="space-between" align="middle">
      <Col md={16}>
        <Input
          style={{
            //   display: "flex",
            //   position: "relative",
            //   margin: "10px auto",
            width: "98%",
            margin: "10px 20px",
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 8) {
              // inputRef.current.blur();
              props.onBackSpace();
            }
          }}
          className="tagsSelectInput  joblinkSharingMediumSelect"
          placeholder="Please enter new skills"
          ref={inputRef}
          value={name}
          onChange={onNameChange}
        />
      </Col>
      <Col md={7}>
        <Button
          type="text"
          style={{
            backgroundColor: "transparent",
            color: "var(--headings-color)",
          }}
          onClick={addItem}
        >
          <Row style={{ gap: "8px" }}>
            <Col>
              <PlusIcon color={"var(--body-text-color)"} />
            </Col>
            <Col>
              <p
                style={{ textAlign: "right" }}
                className={commonStyles.marginBottomRemove}
              >
                {props.onType && props.onType === "department"
                  ? "Add Department"
                  : "Add Skills"}
              </p>
            </Col>
          </Row>
        </Button>
      </Col>
    </Row>
  );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    postTagHandler: (data) => {
      dispatch(postTagsAction(data));
    },
    postDepartmentHandler: (data, callback) => {
      dispatch(postDepartmentsAction(data, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AadTagsForSkills);
