// import styles from "../AddCandidatesComp.module.css";
// import style from "../../../Screens/Settings/Application/Application.module.css";
import commonCssStyle from "../../UI/CommonCss.module.css";
import {
  getRequirementsFilterAction,
  deleteRequirementsAction,
} from "../../../Reusable/Store/Action/RequirementAction";
import { CheckOutlined } from "@ant-design/icons";
import { useState } from "react";
import {
  Table,
  Tag,
  Dropdown,
  Menu,
  Row,
  Col,
  Button,
  Select,
  Card,
  Modal,
  Space,
  Checkbox,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  ThreeDotsIcon,
  Filedownload,
  FilterOutlinedIcon,
  NewIcon,
  CalendarCheckIcon,
  DisableUserIcon,
  TrashIcon,
  UserIcon,
  ProfileIcon,
  EditIcon,
  PlusIcon,
  CaretBlackDownIcon,
  DownArrowIcon,
  DownArrow,
} from "../../IconsComp/Icons";
import { getClientsAction } from "../../../Reusable/Store/Action/ClientAction";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import {
  employee_filterAction,
  getEmployeesAction,
} from "../../../Reusable/Store/Action/EmployeesAction";
import {
  postAssignedEmployeeAction,
  getHiringTeamByIdAction,
} from "../../../Reusable/Store/Action/RequirementAction";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import { checkPermissionReducer } from "../../../Reusable/Store/Slice/AuthPermissionsSlice";
import PermissionMessage from "../../UI/PermissionMessage";
let { Option } = Select;
function AssignEmployee(props) {
  const location = useLocation();
  const [checkIsPrimary, setCheckIsPrimary] = useState({});
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      props.employee_filterAction({ current_page: 1 });
      props.getHiringTeamByIdHandler(location.state.id);
      props.getEmployeesAction();
    }
    return () => {
      unsubscribed = true;
    };
  }, []);

  useEffect(() => {
    if (props.requirement_hiring) {
      setCheckIsPrimary(
        props.requirement_hiring &&
          props.requirement_hiring.find((x) => {
            if (x.isPrimary === 1) {
              return x.userId;
            }
          })
      );
      setSelectedRowKeys(
        props.requirement_hiring.map((data) => {
          return data.userId;
        })
      );
    }
  }, [props.requirement_hiring]);

  const fetchRecords = (page) => {
    const val = {
      ...cfilter,
      //   ...props.clientsData,
      current_page: page,
    };
    props.employee_filterAction(val);
  };

  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [deleteBtnClr, setDeleteBtnClr] = useState("var(--headings-color)");
  const [deletId, setDeleteId] = useState("");
  const [openDeleteModal, SetOpenDeleteModal] = useState(false);
  const [openJobModal, SetOpenJobModal] = useState(false);
  const [isPrimaryKey, setIsPrimaryKey] = useState("");

  const [cfilter, setCFilter] = useState({});
  const [assignEmployee, setAssignEmployee] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [iconsClrHover, setIconsClrHover] = useState(
    "var(--subHeadings-color)"
  );
  const [iconClr, setIconClr] = useState("var(--subHeadings-color)");

  function confirmDeleteHandler() {
    SetOpenDeleteModal(false);
    props.deleteRequirementHandler(deletId, (success) => {});
    setDeleteId("");
  }

  function closeDeleteHandler() {
    SetOpenDeleteModal(false);
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const closeHandler = () => {
    setOpen(false);
  };

  function showFilterHandler() {
    setOpenFilter(true);
  }
  const closeFilterHandler = () => {
    setOpenFilter(false);
  };
  const editJob = () => {
    SetOpenJobModal(true);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys, ...newSelectedRowKeys]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const clearData = () => {
    setSelectedRowKeys([]);
    const val = {
      userId: [],
      jobId: location.state.id,
    };
    props.postAssignEmployeeHandler(val, (success) => {});
  };

  const submitData = () => {
    const val = {
      userId: selectedRowKeys,
      jobId: location.state.id,
    };

    if (isPrimaryKey) {
      props.postAssignEmployeeHandler(
        { ...val, primary: isPrimaryKey },
        (success) => {
          if (success) {
            setIsPrimaryKey("");
            props.getHiringTeamByIdHandler(location.state.id);
          }
        }
      );
    } else {
      props.postAssignEmployeeHandler(
        { ...val, primary: checkIsPrimary.userId },
        (success) => {
          if (success) {
            setIsPrimaryKey("");
            props.getHiringTeamByIdHandler(location.state.id);
          }
        }
      );
    }

    // props.postAssignEmployeeHandler(val, (success) => {});
  };

  const columns = [
    {
      dataIndex: "id",
      render: (text, render) => (
        <>
          <Checkbox
            disabled={
              checkIsPrimary &&
              checkIsPrimary.userId &&
              checkIsPrimary.userId === render.id
            }
            checked={selectedRowKeys.find((x) => x === text)}
            onChange={(val) => {
              if (val.target.checked) {
                setSelectedRowKeys([...selectedRowKeys, text]);
              } else {
                let index = selectedRowKeys.indexOf(text);
                if (index != -1) {
                  setSelectedRowKeys(
                    selectedRowKeys.filter((data) => data !== text)
                  );
                }
              }
            }}
          />
        </>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Name
        </p>
      ),
      dataIndex: "title",
      render: (text, render) => (
        <p
          className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
        >
          {render.firstName + " " + render.lastName}
        </p>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Employee Id
        </p>
      ),
      dataIndex: "empId",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Designation
        </p>
      ),
      dataIndex: "designation",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Department
        </p>
      ),
      dataIndex: "department",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Email & Number
        </p>
      ),
      dataIndex: "billingValue",
      render: (data, record) => (
        <>
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
            style={{ marginBottom: "9px" }}
          >
            {" "}
            {record.email}
          </p>
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {record.phone}
          </p>{" "}
        </>
      ),
    },

    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Current Job Assignment
        </p>
      ),
      dataIndex: "currentJobs",

      render: (data) => (
        <p
          className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
        >
          {data ? data : "NA"}
        </p>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Mark Primary
        </p>
      ),
      dataIndex: "isPrimary",

      render: (data, record, index) => (
        <>
          {selectedRowKeys.find((x) => x === record.id) && (
            <Button
              style={{ width: "auto", fontSize: "14px", height: "auto" }}
              type="primary"
              className={commonCssStyle.buttonSettingsPrimary}
              onClick={() => {
                setIsPrimaryKey(record.id);
              }}
              // disabled={
              //   checkIsPrimary &&
              //   checkIsPrimary.userId &&
              //   checkIsPrimary.userId === record.id
              //     ? true
              //     : false
              // }
            >
              {checkIsPrimary &&
              checkIsPrimary.userId &&
              record.id === checkIsPrimary.userId
                ? "Primary"
                : "Mark Primary"}
              {isPrimaryKey === record.id ? <CheckOutlined /> : ""}
            </Button>
          )}
        </>
      ),
    },
  ];

  const requirementFilterHandleer = (val) => {
    const data = {
      ...val,
      current_page: 1,
    };
    setCFilter(data);
    props.getRequirementsFilterHandler(data);
  };

  const onSubmit = (val) => {
    const data = {
      ...val,
      current_page: 1,
    };
    props.postRequirementHandler(data, (success) => {
      if (success) {
        setOpen(false);
      }
    });
  };
  const handleProvinceChange = (value) => {
    let sort = value.split("_");
    props.getRequirementsFilterHandler({
      ...cfilter,
      sortBy: sort[0],
      sortOrder: sort[1],
      current_page: 1,
    });
  };
  const candidateFilterHandleer = (val) => {
    setCFilter(val);
    props.getCandidateFilterHandler(val);
  };

  return (
    <>
      <Row>
        <Col md={24}>
          <Col style={{ marginTop: "20px" }} xs={24}>
            <Table
              scroll={{ x: "100vw" }}
              // rowSelection={rowSelection}
              pagination={{
                size: "small",
                current:
                  props.employee_filter && props.employee_filter.current_page
                    ? props.employee_filter.current_page
                    : 1,
                pageSize:
                  props.employee_filter && props.employee_filter.per_page,
                total: props.employee_filter && props.employee_filter.total,
                showSizeChanger: false,
                onChange: (props) => {
                  fetchRecords(props);
                },
              }}
              // pagination={{ defaultPageSize: 20 }}
              rowKey="id"
              columns={columns}
              dataSource={props.employee_filter.data}
            />
          </Col>
          <Col>
            <Row style={{ gap: "10px", float: "right" }}>
              <Col>
                <Button
                  type="default"
                  className={commonCssStyle.ButtonForNormalSecondary}
                  onClick={clearData}
                >
                  Clear
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={submitData}
                  className={commonCssStyle.ButtonForNormalPrimary}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
          props.setIsHavepermissionsHandler(false);
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    employee_filter: state.employee.employee_filter,
    requirement_hiring: state.requirements.requirement_hiring,
    employees: state.employee.employees,
    ishavepermissions: state.authpermissions.ishavepermissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    employee_filterAction: (val) => {
      dispatch(employee_filterAction(val));
    },
    postAssignEmployeeHandler: (val, callback) => {
      dispatch(postAssignedEmployeeAction(val, callback));
    },
    getHiringTeamByIdHandler: (val) => {
      dispatch(getHiringTeamByIdAction(val));
    },
    getEmployeesAction: () => {
      dispatch(getEmployeesAction());
    },
    getCheckPermissionHandler: (id) => {
      dispatch(checkPermissionAction(id));
    },
    setIsHavepermissionsHandler: (data) => {
      dispatch(checkPermissionReducer(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignEmployee);
