import {
  getDepartmentsSlice,
  getDepartmentSlice,
  postDepartmentsSlice,
  putDepartmentsSlice,
  deleteDepartmentSlice,
} from "../../Slice/Master/MasterDepartmentsSlice";
import { DEPARTMENT_URL } from "../../masterpath";

import { message } from "antd";
import AxiosApi from "../../api";
const api = new AxiosApi();
export const getDepartmentsAction = () => {
  return (dispatch) => {
    api.invoke(DEPARTMENT_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getDepartmentsSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getDepartmentAction = (id, callback) => {
  return (dispatch) => {
    api.invoke(
      DEPARTMENT_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            dispatch(getDepartmentSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postDepartmentsAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      DEPARTMENT_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(postDepartmentsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const putDepartmentsAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      DEPARTMENT_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(putDepartmentsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteDepartmentAction = (id, callback) => {
  return (dispatch) => {
    api.invoke(
      DEPARTMENT_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(deleteDepartmentSlice(id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
