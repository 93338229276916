import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import {
  CaretBlackDownIcon,
  Filedownload,
  FilterOutlinedIcon,
  PlusIcon,
} from "../../../IconsComp/Icons";
import { Link, useLocation } from "react-router-dom";
import {
  getReportsCandidatesAction,
  exportReportsCandidatesDetaisAction,
} from "../../../../Reusable/Store/Action/ReportsAction";
import { connect } from "react-redux";
import ReportsCandidateTableFilter from "./ReportsCandidateTableFilter";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";

function CandidateReportsDetailTable(props) {
  const [form1] = useForm();
  const [cfilter, setCFilter] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openCandidateFilter, setOpenCandidateFilter] = useState(false);
  const [fromDate, setFromDate] = useState("1970-01-01");
  const [toDate, setToDate] = useState("3000-01-01");

  const location = useLocation();

  useEffect(() => {
    props.getCandidateReportsHandler({
      current_page: 1,
      leadSource:
        location && location.state && location.state.leadSource
          ? location.state.leadSource
          : "",
      jobStatus:
        location && location.state && location.state.from
          ? location.state.from
          : "",
    });
    setCFilter({
      leadSource:
        location && location.state && location.state.leadSource
          ? location.state.leadSource
          : "",
      jobStatus:
        location && location.state && location.state.from
          ? location.state.from
          : "",
    });
  }, [location]);

  if (window.location.hash === "#/reports/candidatedetailtable") {
    localStorage.removeItem("candidateDetail");
    localStorage.removeItem("employee");
    localStorage.removeItem("clientCompany");
    localStorage.removeItem("recuritmentFilterData");
    localStorage.removeItem("taskFilterData");
    localStorage.removeItem("interviewFilterData");
    localStorage.removeItem("candidateDetailFromRequirement");
    localStorage.removeItem("candidateListForPool");
    localStorage.removeItem("recuritmentCandidateFilter");
    localStorage.removeItem("reportsCompanyDetailFilterData");
    localStorage.removeItem("reportsjobdetailfilterdata");
    localStorage.removeItem("reportsjobcandidatefilterdata");
    localStorage.removeItem("fromWhichPage");
    localStorage.removeItem("candidateDetailFromRecruitment");
    localStorage.removeItem("recuritmentReportFilter");
    localStorage.removeItem("employeeReportsFilter");
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys, ...newSelectedRowKeys]);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const closeCandidateFilterHandler = () => {
    setOpenCandidateFilter(false);
  };

  const handleProvinceChange = (e) => {
    if (e === "Name_ASC") {
      props.getCandidateReportsHandler({
        ...cfilter,
        sortBy: "name",
        current_page: 1,
        sortOrder: "ASC",
        type: "list",
      });
      setCFilter({
        ...cfilter,
        sortBy: "name",
        sortOrder: "ASC",
        type: "list",
      });
    } else if (e === "Name_DESC") {
      props.getCandidateReportsHandler({
        ...cfilter,
        sortBy: "name",
        current_page: 1,
        sortOrder: "DESC",
        type: "list",
      });
      setCFilter({
        ...cfilter,
        sortBy: "name",
        sortOrder: "DESC",
        type: "list",
      });
    } else if (e === "Applied_ASC") {
      props.getCandidateReportsHandler({
        ...cfilter,
        sortBy: "appliedDate",
        current_page: 1,
        sortOrder: "ASC",
        type: "list",
      });
      setCFilter({
        ...cfilter,
        sortBy: "appliedDate",
        sortOrder: "ASC",
        type: "list",
      });
    } else if (e === "Applied_DESC") {
      props.getCandidateReportsHandler({
        ...cfilter,
        sortBy: "appliedDate",
        current_page: 1,
        sortOrder: "DESC",
        type: "list",
      });
      setCFilter({
        ...cfilter,
        sortBy: "appliedDate",
        sortOrder: "DESC",
        type: "list",
      });
    } else if (e === "Last_ASC") {
      props.getCandidateReportsHandler({
        ...cfilter,
        sortBy: "lastUpdated",
        current_page: 1,
        sortOrder: "ASC",
        type: "list",
      });
      setCFilter({
        ...cfilter,
        sortBy: "lastUpdated",
        sortOrder: "ASC",
        type: "list",
      });
    } else if (e === "Last_DESC") {
      props.getCandidateReportsHandler({
        ...cfilter,
        sortBy: "lastUpdated",
        current_page: 1,
        sortOrder: "DESC",
        type: "list",
      });
      setCFilter({
        ...cfilter,
        sortBy: "lastUpdated",
        sortOrder: "DESC",
        type: "list",
      });
    }
  };
  const candidateReportFilter = (val) => {
    setCFilter(val);
    props.getCandidateReportsHandler({ ...val }, (success) => {
      if (success) {
      }
    });
  };

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const fetchRecords = (page) => {
    const val = {
      ...cfilter,
      ...props.candidatesFilterData,
      current_page: page,
    };
    props.getCandidateReportsHandler(val);
  };

  const menu2 = (
    <Menu
      style={{ width: "151px", margin: "0px 45px" }}
      selectable
      defaultSelectedKeys={["1"]}
      items={[
        {
          key: "3",
          label: (
            <p
              className={`${commonCssStyle.externalJobTextrole}`}
              style={{ marginTop: "7px" }}
              onClick={() => {
                const data = {
                  current_page: 1,
                  ...cfilter,
                  dateFrom: fromDate,
                  dateTo: toDate,
                  type: "export",
                  exportType: "excel",
                };
                props.exportCandidatesDetailReportHandler(data);
              }}
            >
              Export to Excel
            </p>
          ),
        },
        {
          key: "4",
          label: (
            <p
              style={{ marginTop: "7px" }}
              className={`${commonCssStyle.externalJobTextrole}`}
              onClick={() => {
                const data = {
                  current_page: 1,
                  ...cfilter,
                  dateFrom: fromDate,
                  dateTo: toDate,
                  type: "export",
                  exportType: "pdf",
                };
                props.exportCandidatesDetailReportHandler(data);
              }}
            >
              Export to PDF
            </p>
          ),
        },
      ]}
    />
  );

  const columns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Name
        </p>
      ),
      dataIndex: "name",
      render: (text) => (
        <p
          className={commonCssStyle.tableDataLinkText}
        >
          {text}
        </p>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Contact Number
        </p>
      ),
      dataIndex: "phone",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Email
        </p>
      ),
      dataIndex: "email",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Source
        </p>
      ),
      dataIndex: "leadSource",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Resume link
        </p>
      ),
      dataIndex: "resume",
      render: (text, render) => (
        <Row style={{ justifyContent: "center" }}>
          {text && (
            <span
              className={commonCssStyle.coursorPointer}
              onClick={() => {
                window.open(render.resume, "_blank");
              }}
            >
              <Filedownload color={"var(--primaryColor)"} />
            </span>
          )}
        </Row>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Recruiter
        </p>
      ),
      dataIndex: "teamMember",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Gender
        </p>
      ),
      dataIndex: "gender",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Whatsapp Number
        </p>
      ),
      dataIndex: "whatsApp",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          DOB
        </p>
      ),
      dataIndex: "dob",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? moment(data).format(dataFormate) : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Active Jobs
        </p>
      ),
      dataIndex: "activeJobCount",
      width: "200px",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Shortlisted Jobs
        </p>
      ),
      dataIndex: "shortlistedJobCount",
      width: "200px",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Interview Attendance
        </p>
      ),
      dataIndex: "interviewAttendanceRatio",
      width: "200px",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "N/A"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Interview Performance
        </p>
      ),
      dataIndex: "interviewPerformance",
      width: "200px",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "N/A"}
          </p>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "age",
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Status
        </p>
      ),
      dataIndex: "status",
      render: (text, render) => {
        return (
          <Row>
            <Col>
              {text === "active" ? (
                <Tag
                  style={{
                    backgroundColor: "#D2EFCD",
                    color: "#044123",
                    padding: "5px 20px",
                    lineHeight: "21px",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontStyle: "normal",
                    fontFamily: "Helvetica,ProductSans",
                  }}
                >
                  {text}
                </Tag>
              ) : (
                <Tag
                  style={{
                    backgroundColor: "#FFD9D9",
                    padding: "5px 15px",
                    color: "#F83A3A",
                    lineHeight: "21px",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontStyle: "normal",
                    fontFamily: "Helvetica,ProductSans",
                  }}
                >
                  {text}
                </Tag>
              )}
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <Breadcrumb className={commonCssStyle.breadcrumbBottomMargin}>
        <Breadcrumb.Item>
          <Link to={"/"}>
            <p
              className={`${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove} ${commonCssStyle.breadcrumbText}`}
            >
              Home
            </p>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/reports/candidatesummary"}>
            <p
              className={`${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove} ${commonCssStyle.breadcrumbText}`}
            >
              Candidate Summary
            </p>
          </Link>
        </Breadcrumb.Item>
        {location && location.state && location.state.breadcrum ? (
          <Breadcrumb.Item>
            <Link
              to={"/reports/candidatedetailtable"}
              state={{
                breadcrum: location.state.breadcrum,
                from: location.state.from,
                leadSource: location.state.leadSource,
              }}
            >
              <p
                className={`${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove} ${commonCssStyle.breadcrumbText}`}
              >
                Candidates (&nbsp;{location.state.breadcrum}&nbsp;)
              </p>
            </Link>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item>
            <Link to={"/reports/candidatedetailtable"}>
              <p
                className={`${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove} ${commonCssStyle.breadcrumbText}`}
              >
                Candidates
              </p>
            </Link>
          </Breadcrumb.Item>
        )}
      </Breadcrumb>

      <Row>
        <Col md={24}>
          <Row gutter={[16, 16]} justify="space-between">
            <Col>
              <Row
                align="middle"
                // justify="space-between"
              >
                <Col /* xs={24} sm={24} md={24} lg={6} */>
                  <h1
                    // style={{ marginRight: "21px" }}
                    className={`${commonCssStyle.mainHeadingText} ${commonCssStyle.inttitle}`}
                  >
                    Candidate Report &#40;{" "}
                    {props.candidatesReport && props.candidatesReport.total
                      ? props.candidatesReport.total
                      : "0"}{" "}
                    &#41;
                  </h1>
                </Col>

                <Col /* xs={24} sm={24} md={24} lg={2} */>
                  <Button
                    onClick={() => {
                      setOpenCandidateFilter(true);
                    }}
                    className={`${commonCssStyle.filterButton}`}
                    id="candidates-table-filter-btn"
                  >
                    <Row style={{ gap: "11px" }}>
                      <Col>
                        <div style={{ transform: "translateY(2px)" }}>
                          <FilterOutlinedIcon color={"var(--primaryColor)"} />
                        </div>
                      </Col>
                      <Col>
                        <p
                          className={`${commonCssStyle.filterAndSortTextStyle} ${commonCssStyle.marginBottomRemove}`}
                        >
                          Filter
                        </p>
                      </Col>
                    </Row>
                  </Button>
                </Col>

                <Col /* xs={24} sm={24} md={24} lg={6} */>
                  <Button
                    style={{
                      height: "40px",
                      marginRight: "20px",
                    }}
                    className={` ${commonCssStyle.sortBtn}`}
                    id="candidates-table-sortby-btn"
                  >
                    <Row justify="space-between">
                      <Col>
                        <p style={{ transform: "translateY(2px)" }}>Sort By</p>
                      </Col>
                      <Col>
                        <Select
                          style={{
                            width: "100%",
                            transform: "translateY(3px)",
                          }}
                          onChange={handleProvinceChange}
                          bordered={false}
                          placeholder={
                            <p
                              style={{
                                margin: "0px",
                                color: "var(--primaryColor)",
                              }}
                            >
                              Select
                            </p>
                          }
                          size="small"
                          className={`${commonCssStyle.filterDrop}  selectInput sortBySelectStyle selectTag selectTag1`}
                        >
                          <Select.Option key="1" value="Name_ASC">
                            Name Ascending
                          </Select.Option>
                          <Select.Option key="2" value="Name_DESC">
                            Name Descending
                          </Select.Option>
                          <Select.Option key="3" value="Applied_ASC">
                            Applied Date Ascending
                          </Select.Option>
                          <Select.Option key="4" value="Applied_DESC">
                            Applied Date Descending
                          </Select.Option>
                          <Select.Option key="5" value="Last_ASC">
                            Last Updated Ascending
                          </Select.Option>
                          <Select.Option key="6" value="Last_DESC">
                            Last Updated Descending
                          </Select.Option>
                        </Select>
                      </Col>
                    </Row>
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={[16, 16]} justify="space-between">
                <Col /* xs={24} sm={24} md={24} lg={4} */>
                  <Form form={form1}>
                    <Row justify="space-between">
                      <Col md={11}>
                        <Form.Item className="candidateReportForm" name="from">
                          <DatePicker
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            format={dataFormate}
                            placeholder="from"
                            style={{ width: "115px", height: "40px" }}
                            className="ReportsFrom"
                            suffixIcon={null}
                            onChange={(val) => {
                              if (val !== null) {
                                props.getCandidateReportsHandler({
                                  ...cfilter,
                                  current_page: 1,
                                  type: "list",
                                  dateFrom: moment(val).format("YYYY-MM-DD"),
                                  dateTo: toDate,
                                });
                                setFromDate(moment(val).format("YYYY-MM-DD"));
                              } else {
                                props.getCandidateReportsHandler({
                                  ...cfilter,
                                  type: "list",
                                  current_page: 1,
                                  dateFrom: "1970-01-01",
                                  dateTo: toDate,
                                });
                                setFromDate("1970-01-01");
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={11}>
                        <Form.Item className="candidateReportForm" name="to">
                          <DatePicker
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            onChange={(val) => {
                              if (val !== null) {
                                props.getCandidateReportsHandler({
                                  ...cfilter,
                                  current_page: 1,
                                  type: "list",
                                  dateFrom: fromDate,
                                  dateTo: moment(val).format("YYYY-MM-DD"),
                                });
                                setToDate(moment(val).format("YYYY-MM-DD"));
                              } else {
                                props.getCandidateReportsHandler({
                                  ...cfilter,
                                  type: "list",
                                  current_page: 1,
                                  dateTo: "3000-01-01",
                                  dateFrom: fromDate,
                                });
                                setToDate("3000-01-01");
                              }
                            }}
                            format={dataFormate}
                            placeholder="to"
                            style={{ width: "115px", height: "40px" }}
                            className="ReportsFrom"
                            suffixIcon={null}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>

                <Col /* xs={24} sm={24} md={24} lg={4} */>
                  <Row justify="end">
                    <Dropdown placement="bottom" overlay={menu2}>
                      <Typography.Link>
                        <Space className={commonCssStyle.tableHeadingTextData}>
                          <Button
                            style={{ width: "151px" }}
                            className={commonCssStyle.intaddButton}
                            type="primary"
                          >
                            <Row align="middle" justify="space-around">
                              <Col>
                                <PlusIcon color={"white"} />
                              </Col>
                              <Col>Export</Col>
                              <Col>
                                <CaretBlackDownIcon color={"white"} />
                              </Col>
                            </Row>
                          </Button>
                        </Space>
                      </Typography.Link>
                    </Dropdown>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={24} style={{ marginTop: "40px" }}>
          <Table
            className="ReportsTablescroll"
            scroll={{ x: "100vw" }}
            pagination={{
              size: "small",
              current:
                props.candidatesReport && props.candidatesReport.current_page,
              pageSize:
                props.candidatesReport && props.candidatesReport.per_page,
              total: props.candidatesReport && props.candidatesReport.total,
              showSizeChanger: false,
              onChange: (props) => {
                fetchRecords(props);
              },
            }}
            columns={columns}
            dataSource={
              props.candidatesReport &&
              props.candidatesReport.data &&
              props.candidatesReport.data
            }
          />
        </Col>
      </Row>
      <ReportsCandidateTableFilter
        onOpenFilter={openCandidateFilter}
        onCloseFilter={closeCandidateFilterHandler}
        candidateReportFilter={candidateReportFilter}
        initialvalues={location && location.state && location.state}
        fromDate={fromDate}
        toDate={toDate}
        onClearFilter={() => {
          form1.resetFields();
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    candidatesReport: state.reports.reportsCandidates,
    settindsData: state.settings.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCandidateReportsHandler: (val, callback) => {
      dispatch(getReportsCandidatesAction(val, callback));
    },
    exportCandidatesDetailReportHandler: (val) => {
      dispatch(exportReportsCandidatesDetaisAction(val));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateReportsDetailTable);
