import {
  getSharingMediumsSlice,
  getSharingMediumSlice,
  postSharingMediumsSlice,
  putSharingMediumsSlice,
  deleteSharingMediumsSlice,
} from "../../Slice/Master/MasterSharingMediumSlice";
import { SHARING_MEDIUM_URL } from "../../masterpath";
import { message } from "antd";
import AxiosApi from "../../api";
const api = new AxiosApi();
export const getSharingMediumsAction = () => {
  return (dispatch) => {
    api.invoke(SHARING_MEDIUM_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getSharingMediumsSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getSharingMediumAction = (id) => {
  return (dispatch) => {
    api.invoke(
      SHARING_MEDIUM_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getSharingMediumSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postSharingMediumsAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      SHARING_MEDIUM_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(postSharingMediumsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const putSharingMediumsAction = (data) => {
  return (dispatch) => {
    api.invoke(
      SHARING_MEDIUM_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(putSharingMediumsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteSharingMediumsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      SHARING_MEDIUM_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(deleteSharingMediumsSlice(id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
