import React, { useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Tabs, Tag, Divider, Modal } from "antd";
import JobInfo from "./JobInfo";
import {
  BackButtonIcon,
  BrieftCaseIcon,
  CloseIcon,
  EditBorderIcon,
  GridFour,
  UserIcon,
  UserIconForRequirement,
  UsersIcon,
} from "../../IconsComp/Icons";
import styles from "../../Screens/ClientCompany/ClientCompany.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import commonCssStyle from "../../UI/CommonCss.module.css";
import { FilterOutlinedIcon } from "../../IconsComp/Icons";
import EmployeeTask from "../Employees/EmployeeTask";
import { connect } from "react-redux";
import {
  getRequirementAction,
  putRequirementsAction,
} from "../../../Reusable/Store/Action/RequirementAction";
import { useState } from "react";
import JobInfoEditDrawer from "./JobInfoEditDrawer";
import { getClientsAction } from "../../../Reusable/Store/Action/ClientAction";
import { getDepartmentsAction } from "../../../Reusable/Store/Action/Master/MasterDepartmentsAction";
import { getTagsAction } from "../../../Reusable/Store/Action/Master/MasterTagsAction";
import { getJobDescriptionsAction } from "../../../Reusable/Store/Action/Master/MasterJobDescriptionAction";
import pdf from "../../../Assets/Image/PdfImage.png";
import word from "../../../Assets/Image/word.png";
import AssignEmployee from "./AssignEmployee";
import { getSharingMediumsAction } from "../../../Reusable/Store/Action/Master/MasterSharingMediumAction";
import { checkPermissionAction } from "../../../Reusable/Store/Action/AuthPermissionsAction";
import { checkPermissionReducer } from "../../../Reusable/Store/Slice/AuthPermissionsSlice";
import PermissionMessage from "../../UI/PermissionMessage";
import BreadcrumbForRequirement from "./BreadcrumbForRequirement";
import SourcingCandidate from "../Recruitment/SourcingCandidate";
import AddCandidateDrawer from "../AddCandidatesComp/candidateDetailsTable/drawerComp/AddCandidateDrawer";

function RequirementJobDetailPage(props) {
  const [modaltype, setModaltype] = useState("");
  const [acitveKey, setActiveKey] = useState("1");
  const [modalName, setModalName] = useState("");
  const [editRequirementDrawer, setEditRequirementDrawer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteTag, setDeleteTag] = useState("");
  const [key, setKey] = useState("0");
  const [candidateDrawer, setCandidateDrawer] = useState(false);
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  const navigate = useNavigate();
  const [duplicateKey, setDuplicateKey] = useState("1");
  const closeJobInfoHandler = () => {
    setEditRequirementDrawer(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setDeleteTag("");
    setIsModalOpen(false);
  };

  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      props.getRequirementHandler(location.state.id);
      props.getSharingMediumsActionHandler();
      setKey(location.state.activeTabKey);
      if (location.state.activeTabKey !== "1") {
        setDuplicateKey("0");
      }
      if (location.state.activeTabKey) {
        setActiveKey(location.state.activeTabKey);
      }
    }
    return () => {
      unsubscribed = true;
    };
  }, [location]);

  const sharingMediumData = (val) => {
    const data = { ...val, _method: "PUT", id: location.state.id };
    props.putRequirementHandler(data, "details", (success) => {
      if (success) {
      }
    });
  };

  const handleDelete = () => {
    let tags;
    let tagsDatas;
    if (modaltype === "mandatorySkills") {
      tags =
        props &&
        props.requiremtIdData &&
        props.requiremtIdData.mandatorySkills &&
        props.requiremtIdData.mandatorySkills.filter(
          (data) => data !== deleteTag
        );
      tagsDatas = {
        id: location.state.id,
        mandatorySkills: tags,
        _method: "PUT",
      };
    } else {
      tags =
        props &&
        props.requiremtIdData &&
        props.requiremtIdData.optionalSkills &&
        props.requiremtIdData.optionalSkills.filter(
          (data) => data !== deleteTag
        );
      tagsDatas = {
        id: location.state.id,
        optionalSkills: tags,
        _method: "PUT",
      };
    }
    props.putRequirementHandler(tagsDatas, "details", (success) => {
      if (success) {
        handleCancel();
      }
    });
  };
  const forMap = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding: "10px 17px 10px 23px",
        }}
        // closable={
        //   props.requiremtIdData &&
        //   props.requiremtIdData.mandatorySkills &&
        //   props.requiremtIdData.mandatorySkills.length === 1
        //     ? false
        //     : true
        // }
        closable
        onClose={(e) => {
          e.preventDefault();
          // props.getCheckPermissionHandler("job-delete", (success) => {
          //   if (success) {
          showModal();
          setDeleteTag(tag);
          setModaltype("mandatorySkills");
          //   } else {
          //     setOpenPMModal({
          //       open: true,
          //       permission: "Job Delete",
          //     });
          //   }
          // });
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
          padding: "10px 0px",
        }}
      >
        {tagElem}
      </span>
    );
  };

  const forMapOprtional = (tag) => {
    const tagElem = (
      <Tag
        className={`tagsStyle ${commonCssStyle.TagSelectedTags}`}
        style={{
          color: "var(--primaryColor)",
          backgroundColor: "var(--secondary-card-color)",
          fontWeight: "500",
          borderRadius: "50px",
          padding: "10px 17px 10px 23px",
        }}
        closable
        onClose={(e) => {
          e.preventDefault();
          // props.getCheckPermissionHandler("job-delete", (success) => {
          //   if (success) {
          showModal();
          setDeleteTag(tag);
          setModaltype("optionalSkills");
          //   } else {
          //     setOpenPMModal({
          //       open: true,
          //       permission: "Job Delete",
          //     });
          //   }
          // });
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
          padding: "10px 0px",
        }}
      >
        {tagElem}
      </span>
    );
  };

  const tagChild =
    props.requiremtIdData &&
    props.requiremtIdData.mandatorySkills &&
    props.requiremtIdData.mandatorySkills.map(forMap);

  const tagChildForOptionalSKills =
    props.requiremtIdData &&
    props.requiremtIdData.optionalSkills &&
    props.requiremtIdData.optionalSkills.map(forMapOprtional);

  const itemsData = [
    {
      label: (
        <Link to="/requirement">
          <BackButtonIcon color={"var(--body-text-color)"} />
        </Link>
      ),
      key: "1",
    },
    props.permissions &&
      props.permissions.find((data) => data === "job-detail") && {
        label: (
          <>
            <Row align="middle">
              <Col>
                <BrieftCaseIcon
                  color={
                    key === "1" || duplicateKey === "1"
                      ? "var(--menu-text-color)"
                      : "var(--unactive-tab-color)"
                  }
                />
              </Col>
              <Col push={1}>
                <p
                  className={commonCssStyle.tabsText}
                  style={{
                    margin: "0px",
                    color:
                      key === "1" || duplicateKey === "1"
                        ? "var(--menu-text-color)"
                        : "var(--unactive-tab-color)",
                  }}
                >
                  Job Detail
                </p>
              </Col>
            </Row>
          </>
        ),
        key: "1",
        children: (
          <>
            <Row gutter={[40, 16]}>
              <Col md={6}>
                <JobInfo
                  requiremtIdData={props.requiremtIdData}
                  locationId={location.state}
                  departments={props.departments}
                  sharingMediumsData={props.sharingMediumsData}
                />
              </Col>

              <Col md={18}>
                <Card className={styles.jobDetailsCardHeader}>
                  <Row className={styles.jobDetailCard}>
                    <Col md={23}>
                      <p className={`${commonCssStyle.tableHeadingsColor}`}>
                        Job Title
                      </p>
                      <p className={styles.txtBlueClrRequirement}>
                        {props.requiremtIdData && props.requiremtIdData
                          ? props.requiremtIdData.title
                          : "NA"}
                      </p>
                    </Col>
                    {props.permissions &&
                      props.permissions.find((data) => data === "job-edit") && (
                        <Col md={1}>
                          <div
                            className={commonCssStyle.coursorPointer}
                            onClick={() => {
                              props.getCheckPermissionHandler(
                                "job-edit",
                                (success) => {
                                  if (success) {
                                    if (!props.ishavepermissions) {
                                      setModalName("jobTitle");
                                      setEditRequirementDrawer(true);
                                    }
                                  } else {
                                    setOpenPMModal({
                                      open: true,
                                      permission: "Job Edit",
                                    });
                                  }
                                }
                              );
                            }}
                          >
                            <EditBorderIcon color={"var(--primaryColor)"} />
                          </div>
                        </Col>
                      )}
                  </Row>

                  <Divider type="horizontal" className={styles.seperator} />
                  <Row className={styles.jobDetailCardMinHeight}>
                    <Col>
                      <p className={`${commonCssStyle.tableHeadingsColor} `}>
                        Job Type
                      </p>
                      {props.requiremtIdData &&
                        props.requiremtIdData &&
                        props.requiremtIdData.jobType && (
                          <p className={commonCssStyle.jobDetailParagraph}>
                            {props.requiremtIdData && props.requiremtIdData
                              ? props.requiremtIdData.jobType
                              : "NA"}
                          </p>
                        )}
                    </Col>
                  </Row>
                  <Row className={styles.jobDetailCardMinHeight}>
                    <Col>
                      <Row>
                        <Col>
                          <h2
                            className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
                          >
                            Mandatory skills
                          </h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p
                            style={{
                              marginTop: "-20px",
                            }}
                          >
                            {tagChild}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className={styles.jobDetailCard1}>
                    <Col>
                      <Row>
                        <Col>
                          <h2
                            className={`${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
                          >
                            Optional skills
                          </h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p
                            style={{
                              marginTop: "-20px",
                            }}
                          >
                            {tagChildForOptionalSKills}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row
                    className={styles.jobDetailCard1}
                    style={{ gap: "25px" }}
                  >
                    <Col>
                      <h2 className={`${commonCssStyle.tableHeadingsColor}`}>
                        Min Experience (in years)
                      </h2>
                      <p className={commonCssStyle.jobDetailParagraph}>
                        {props.requiremtIdData && props.requiremtIdData
                          ? props.requiremtIdData.experienceToMonth
                          : "NA"}
                      </p>
                    </Col>
                    <Col>
                      <h2 className={`${commonCssStyle.tableHeadingsColor}`}>
                        Max Experience (in years)
                      </h2>
                      <p className={commonCssStyle.jobDetailParagraph}>
                        {props.requiremtIdData && props.requiremtIdData
                          ? props.requiremtIdData.experienceFromMonth
                          : "NA"}
                      </p>
                    </Col>
                  </Row>

                  <Row className={styles.jobDetailCard1}>
                    <Col>
                      <h2 className={`${commonCssStyle.tableHeadingsColor}`}>
                        Salary Mode
                      </h2>
                      <p className={commonCssStyle.jobDetailParagraph}>
                        {props.requiremtIdData && props.requiremtIdData
                          ? props.requiremtIdData.salaryMode
                          : "NA"}
                      </p>
                    </Col>
                  </Row>

                  <Row className={styles.jobDetailCard1}>
                    <Col>
                      <h2 className={`${commonCssStyle.tableHeadingsColor}`}>
                        Settlement Terms
                      </h2>
                      <p className={commonCssStyle.jobDetailParagraph}>
                        {props.requiremtIdData && props.requiremtIdData
                          ? props.requiremtIdData.settlementTerms
                          : "NA"}
                      </p>
                    </Col>
                  </Row>

                  <Row className={styles.jobDetailCard1}>
                    <Col>
                      <h2 className={`${commonCssStyle.tableHeadingsColor}`}>
                        Billing Value
                      </h2>
                      <p className={commonCssStyle.jobDetailParagraph}>
                        {props.settindsData &&
                          props.settindsData.currency &&
                          props.settindsData.currency}{" "}
                        {props.requiremtIdData && props.requiremtIdData
                          ? props.requiremtIdData.billingValue
                          : "NA"}
                      </p>
                    </Col>
                  </Row>

                  <Row className={styles.jobDetailCard1}>
                    <Col>
                      <h2 className={`${commonCssStyle.tableHeadingsColor}`}>
                        Replacement Clause
                      </h2>
                      <p className={commonCssStyle.jobDetailParagraph}>
                        {props.requiremtIdData && props.requiremtIdData
                          ? props.requiremtIdData.replacementClause
                          : "NA"}
                      </p>
                    </Col>
                  </Row>

                  <Row className={styles.jobDetailCard1}>
                    <Col>
                      <h2 className={`${commonCssStyle.tableHeadingsColor}`}>
                        Agreed Commercials
                      </h2>
                      <p className={commonCssStyle.jobDetailParagraph}>
                        {props.requiremtIdData &&
                        props.requiremtIdData &&
                        props.requiremtIdData.standardCommission
                          ? props.requiremtIdData.standardCommission + "%"
                          : "NA"}
                      </p>
                    </Col>
                  </Row>
                  <Divider type="horizontal" className={styles.seperator} />
                  <Row className={styles.jobDetailCardMinHeight}>
                    <Col>
                      <p className={`${commonCssStyle.tableHeadingsColor}`}>
                        Company
                      </p>
                      <p className={commonCssStyle.jobDetailParagraph}>
                        {props.requiremtIdData && props.requiremtIdData
                          ? props.requiremtIdData.company
                          : "NA"}
                      </p>
                    </Col>
                  </Row>
                  <Row className={styles.jobDetailCardMinHeight}>
                    <Col>
                      <p className={`${commonCssStyle.tableHeadingsColor} `}>
                        Job Description
                      </p>
                      {props.requiremtIdData &&
                        props.requiremtIdData &&
                        props.requiremtIdData.jobDescription && (
                          <p
                            className={`${commonCssStyle.jdScroll} ${commonCssStyle.jobDetailParagraph}`}
                            dangerouslySetInnerHTML={{
                              __html: props.requiremtIdData.jobDescription,
                            }}
                          ></p>
                        )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {props.requiremtIdData &&
                      props.requiremtIdData.jobDescriptionURL ? (
                        <div style={{ margin: "20px" }}>
                          <Button
                            onClick={() => {
                              if (
                                props.requiremtIdData &&
                                props.requiremtIdData.jobDescriptionURL
                              ) {
                                window.open(
                                  props.requiremtIdData.jobDescriptionURL,
                                  "_blank"
                                );
                              }
                            }}
                            type="primary"
                            // style={{ color: "var(--primaryColor)" }}
                            // className={`${commonCssStyle.coursorPointer} ${commonCssStyle.tableHeadingsColor} ${commonCssStyle.marginBottomRemove}`}
                          >
                            Download JD
                          </Button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </>
        ),
      },
    //old
    props.permissions &&
      props.permissions.find((data) => data === "job-assign-employee") && {
        label: (
          <>
            <Row>
              <Col>
                <UserIconForRequirement
                  color={
                    key === "2"
                      ? "var(--menu-text-color)"
                      : "var(--unactive-tab-color)"
                  }
                />
              </Col>
              <Col push={1}>
                <p
                  className={commonCssStyle.tabsText}
                  style={{
                    margin: "0px",
                    color:
                      key === "2"
                        ? "var(--menu-text-color)"
                        : "var(--unactive-tab-color)",
                  }}
                >
                  Assign Employees
                </p>
              </Col>
            </Row>
          </>
        ),
        key: "2",
        // defaultActiveKey:{key},
        // defaultActiveKey:"2",
        children: (
          <>
            <Row gutter={40}>
              <Col md={24}>
                {/* <JobInfo 
              requiremtIdData ={props.requiremtIdData}
              locationId={location.state}
              /> */}
                <AssignEmployee locationId={location.state} />
              </Col>
            </Row>
          </>
        ),
      },
    //old
    //new
    props.permissions &&
      props.permissions.find((data) => data === "job-task-list") && {
        label: (
          <>
            <Row>
              <Col>
                <GridFour
                  color={
                    key === "3"
                      ? "var(--menu-text-color)"
                      : "var(--unactive-tab-color)"
                  }
                />
              </Col>
              <Col push={2}>
                <p
                  className={commonCssStyle.tabsText}
                  style={{
                    margin: "0px",
                    color:
                      key === "3"
                        ? "var(--menu-text-color)"
                        : "var(--unactive-tab-color)",
                  }}
                >
                  Task
                </p>
              </Col>
            </Row>
          </>
        ),
        key: "3",
        children: (
          <>
            <Row gutter={40}>
              <Col md={6}>
                <JobInfo
                  requiremtIdData={props.requiremtIdData}
                  locationId={location.state}
                  departments={props.departments}
                  sharingMediumsData={props.sharingMediumsData}
                />
              </Col>
              <Col md={18}>
                <EmployeeTask type="job" />
              </Col>
            </Row>
          </>
        ),
      },
    props.permissions &&
      props.permissions.find((data) => data === "candidate-add") && {
        key: "4",
        label: (
          <Row align="middle" style={{ gap: "10px" }}>
            <Col>
              <UsersIcon
                color={
                  key === "4"
                    ? "var(--menu-text-color)"
                    : "var(--unactive-tab-color)"
                }
              />
            </Col>
            <Col>
              <p
                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tabsText}`}
                style={{
                  margin: "0px",
                  color:
                    key === "4"
                      ? "var(--menu-text-color)"
                      : "var(--unactive-tab-color)",
                }}
              >
                Add Candidate
              </p>
            </Col>
          </Row>
        ),
        children: (
          <>
            {/* <SourcingCandidate
            onPathname={pathname}
            name={props.requiremtIdData}
            idData={location.state}
            sharingMediumData={sharingMediumData}
            recruitmentIdData={props.requiremtIdData}
            sharingMediumsData={props.sharingMediumsData}
          /> */}
            {/*<Card style={{ padding: "20px" }}>
               <Row justify="center" align="middle">
                <Col>
                  <Button
                    icon={<PlusOutlined />}
                    className={commonCssStyle.intaddButton}
                    type="primary"
                    onClick={() => {
                      props.getCheckPermissionHandler(
                        "candidate-add",
                        (success) => {
                          if (success) {
                            setCandidateDrawer(true);
                          } else {
                            setOpenPMModal({
                              open: true,
                              permission: "Add Candidate",
                            });
                          }
                        }
                      );
                    }}
                  >
                    Add Candidate
                  </Button>
                </Col>
              </Row>
            </Card> */}
            {/* <AddCandidateDrawer
              onOpen={candidateDrawer}
              onClose={() => {
                setCandidateDrawer(false);
              }}
            /> */}
          </>
        ),
      },
  ];

  return (
    <>
      <Row className={commonCssStyle.breadcrumbBottomMargin}>
        <Col md={24}>
          <BreadcrumbForRequirement
            slide={[1, 2, 3]}
            name={props.requiremtIdData}
          />
        </Col>
      </Row>
      <Tabs
        // tabBarStyle={{ position: "fixed" }}
        onTabClick={(val) => {
          if (val == "1") {
            setActiveKey("1");
          } else if (val == "2") {
            props.getCheckPermissionHandler(
              "job-assign-employee",
              (success) => {
                if (success) {
                  setActiveKey("2");
                } else {
                  setOpenPMModal({
                    open: true,
                    permission: "Job Assign Employee",
                  });
                }
              }
            );
          } else if (val == "3") {
            props.getCheckPermissionHandler("job-task-list", (success) => {
              if (success) {
                setActiveKey("3");
              } else {
                setOpenPMModal({
                  open: true,
                  permission: "Job Task List",
                });
              }
            });
          } else if (val === "4") {
            props.getCheckPermissionHandler("candidate-add", (success) => {
              if (success) {
                setActiveKey("4");
                setCandidateDrawer(true);
                localStorage.setItem(
                  "fromWhichPage",
                  JSON.stringify({
                    fromRequirement: "fromRequirement",
                  })
                );
                navigate("/candidatesDetailsPage", {
                  state: {
                    from: "fromRequirement",
                    pageFrom: "requirement",
                    jobCode: props.requiremtIdData.jobCode,
                    title: props.requiremtIdData.title,
                    id: props.requiremtIdData.id,
                  },
                });
              } else {
                setOpenPMModal({
                  open: true,
                  permission: "Add Candidate",
                });
              }
            });
          }
        }}
        style={{ width: "100%" }}
        activeKey={acitveKey}
        className="taskCalendarTabs" /* taskClaendarTabsFixed */
        defaultActiveKey={location.state.activeTabKey}
        onChange={(key) => {
          if (key != 1) {
            setDuplicateKey("0");
          }
          setKey(key);
        }}
        addIcon={
          <Button
            type="primary"
            style={{
              margin: "11px 20px 11px 0px",
              width: "214px",
              height: "46px",
              boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.15)",
              borderRadius: "6px",
            }}
          >
            <Row align="middle" justify="space-around">
              <Col>
                <PlusOutlined />
              </Col>
              <Col>
                <p
                  style={{
                    margin: "0px",
                    fontWeight: 400,
                    fontSize: "16px",
                  }}
                >
                  Add Interviews
                </p>
              </Col>
            </Row>
          </Button>
        }
        items={itemsData}
      />
      <JobInfoEditDrawer
        openJobInfoDrawer={editRequirementDrawer}
        clients={props.clients}
        departments={props.departments}
        // tagsData={props.tagsData}
        requiremtIdData={props.requiremtIdData}
        onCloseJobInfo={closeJobInfoHandler}
        modalNameHandler={modalName}
        locationId={location.state}
        jobDescriptionData={props.jobDescriptionsData}
        sharingMediumsData={props.sharingMediumsData}
        onSubmit={(val) => {
          const data = { ...val, _method: "PUT" };
          props.putRequirementHandler(data, "details", (success) => {
            if (success) {
              setEditRequirementDrawer(false);
              props.setIsHavepermissionsHandler(false);
            }
          });
        }}
      />
      <Modal
        title={
          <Row style={{ gap: "340px" }}>
            <Col>
              <h3 className={commonCssStyle.popUpHeadingText}>Delete Tags</h3>
            </Col>
            <Col>
              <Col>
                <div
                  style={{ float: "right" }}
                  onClick={() => {
                    handleCancel();
                  }}
                  className={`${commonCssStyle.coursorPointer} `}
                >
                  <CloseIcon
                    color={"var(--body-text-color)" || "var(--theme)"}
                  />
                </div>
              </Col>
            </Col>
          </Row>
        }
        open={isModalOpen}
        onOk={handleDelete}
        // onCancel={handleCancel}
        closable={false}
        footer={
          <>
            <Button
              className={commonCssStyle.buttonSettingsSecondary}
              type="default"
              style={{
                width: "177px",
                height: "45px",
              }}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              className={commonCssStyle.buttonSettingsPrimary}
              onClick={() => {
                props.getCheckPermissionHandler("job-delete", (success) => {
                  if (success) {
                    handleDelete();
                  } else {
                    setOpenPMModal({
                      open: true,
                      permission: "Job Delete",
                    });
                  }
                });
              }}
              type="primary"
              style={{
                width: "177px",
                height: "45px",
              }}
            >
              Delete
            </Button>
          </>
        }
      >
        <p
          className={`${commonCssStyle.deleteConfiTextColor} ${commonCssStyle.marginBottomRemove}`}
        >
          Are you sure you want to delete this Tag
        </p>
      </Modal>
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
          props.setIsHavepermissionsHandler(false);
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    clients: state.client.clients,
    requiremtIdData: state.requirements.requirement,
    tagsData: state.tags.tags,
    departments: state.departments.departments,
    jobDescriptionsData: state.jobDescription.jobDescriptions,
    sharingMediumsData: state.sharingMedium.sharingMediums,
    settindsData: state.settings.settings,
    permissions: state.authpermissions.permissions,
    ishavepermissions: state.authpermissions.ishavepermissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRequirementHandler: (id) => {
      dispatch(getRequirementAction(id));
    },
    putRequirementHandler: (data, type, callback) => {
      dispatch(putRequirementsAction(data, type, callback));
    },
    getClientsHandler: () => {
      dispatch(getClientsAction());
    },
    getDepartmentsAction: () => {
      dispatch(getDepartmentsAction());
    },
    getTagHandler: () => {
      dispatch(getTagsAction());
    },
    getJobDescriptionHandler: () => {
      dispatch(getJobDescriptionsAction());
    },
    getSharingMediumsActionHandler: () => {
      dispatch(getSharingMediumsAction());
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
    setIsHavepermissionsHandler: (data) => {
      dispatch(checkPermissionReducer(data));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequirementJobDetailPage);
