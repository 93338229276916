import {
  Drawer,
  Form,
  Input,
  Button,
  Tag,
  Select,
  Row,
  Col,
  Switch,
} from "antd";
import { useEffect, useState } from "react";

import { CloseIcon, FilterOutlinedIcon } from "../../../IconsComp/Icons";
import commonStyles from "../../../UI/CommonCss.module.css";
import ResetButton from "../../../UI/ResetButton";
import styles from "../../../Screens/ClientCompany/ClientCompany.module.css";
import { connect } from "react-redux";
import { getRequirementsAction } from "../../../../Reusable/Store/Action/RequirementAction";
import { getDepartmentsAction } from "../../../../Reusable/Store/Action/Master/MasterDepartmentsAction";
import { getTagsAction } from "../../../../Reusable/Store/Action/Master/MasterTagsAction";
import { getClientsAction } from "../../../../Reusable/Store/Action/ClientAction";
import { getEmployeesAction } from "../../../../Reusable/Store/Action/EmployeesAction";
import { useForm } from "rc-field-form";
import moment from "moment";

const { Option } = Select;

const tagRenderForMobileNum = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color="var(--primaryColor)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: "5px 17px 5px 23px",
      }}
    >
      {label}
    </Tag>
  );
};

function ReportsJobDetailFilter(props) {
  useEffect(() => {
    let unsubscribed = false;
    // if (!unsubscribed) {
    props.getRequirementAction();
    props.getDepartmentsAction();
    props.getTagHandler();
    props.getClientsHandler();
    props.getEmployeesAction();
    // setFilterDatas({ clientName: props.initialValuesCompany });
    // }
    // return () => {
    //   unsubscribed = true;
    // };
  }, [props.initialValuesCompany]);

  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="var(--primaryColor)"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          padding: "5px 17px 5px 23px",
        }}
      >
        {label}
      </Tag>
    );
  };

  const [form] = Form.useForm();
  const [experienceToMonth, setExperienceToMonth] = useState(false);
  const [experienceFromMonth, setExperienceFromMonth] = useState(false);
  const [location, setLocation] = useState(false);
  const [salary, setSalary] = useState(false);
  const [publisedJob, setPulishedJobs] = useState(false);
  const [draftJob, setDraftJobs] = useState(false);
  const [filterDatas, setFilterDatas] = useState({
    title: "",
    jobCode: "",
    role: "",
    department: "",
    skills: "",
    Recruiter: "",
    clientName: null,
  });
  const resetFilter = () => {
    form.resetFields();
    form.setFieldsValue({
      company: null,
    });
    // form.resetFields();
    // props.candidateFilterHandleer({});
  };

  const [restBtnArea, setRestBtnArea] = useState(false);

  const departmentData = ["Design"];

  const handleChangeForMobile = (value) => {};

  // const resetFilter = () => {
  //   form.resetFields();
  // };

  const onFinish = (values) => {
    const data = {
      ...values,
      publishedJobs: publisedJob ? 1 : "",
      draftJobs: draftJob ? 1 : "",
      dateTo: props.toDate,
      dateFrom: props.fromDate,
    };
    props.reportsJobFilterHandleer(data);
    props.onCloseFilter();
  };

  useEffect(()=>{
    const obj = JSON.parse(localStorage.getItem("reportsjobdetailfilterdata"));
    if(obj){
      form.setFieldsValue({
        area:obj.area,
        company:obj.company,
        current_page:obj.current_page,
        department:obj.department,
        dateFrom:obj.dateFrom&&moment(obj.dateFrom),
        dateTo:obj.dateTo&&moment(obj.dateTo),
        draftJobs:obj.draftJobs,
        jobCode:obj.jobCode,
        jobStatus:obj.jobStatus,
        location:obj.location,
        priority:obj.priority,
        publishedJobs:obj.publishedJobs,
        recruiter:obj.recruiter,
        skills:obj.skills,
        status:obj.status,
        title:obj.title,
    
      })
    }
  },[])

  return (
    <>
      <Drawer
        closable={false}
        className="modalModified"
        extra={
          <div
            onClick={() => props.onCloseFilter()}
            className={`${commonStyles.coursorPointer} `}
          >
            <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
          </div>
        }
        width={503}
        height={48}
        title={
          <>
            <Row>
              <Col>
                <FilterOutlinedIcon color={"var(--primaryColor)"} />
              </Col>
              <Col>
                <p
                  style={{ fontSize: "20px", margin: "0px 0px 0px 5px" }}
                  className={styles.txtBlueClr}
                >
                  Filter
                </p>
              </Col>
            </Row>{" "}
          </>
        }
        // footer={false}
        // onClose={props.onCloseFilter}
        open={props.onOpenFilter}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          initialValues={{
            // company: props.initialValuesCompany,
            company: filterDatas.clientName,
          }}
        >
          <Row
            className={styles.modalRowStyling}
            style={{ marginBottom: "80px" }}
          >
            <Col flex={1}>
              <Row>
                <Col flex={1}>
                  <Form.Item name="title" label="Job Title">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      // mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      className={`${commonStyles.border} candidateSelectHeight`}
                      placeholder="Select title"
                      tagRender={tagRenderForMobileNum}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      notFoundContent={null}
                      showSearch
                      onKeyUp={(val) => {
                        setFilterDatas({
                          title: val.target.value,
                        });
                      }}
                    >
                      {filterDatas.title &&
                        filterDatas.title !== " " &&
                        filterDatas.title.length > 0 &&
                        props.requirements &&
                        props.requirements &&
                        props.requirements.map((data, index) => {
                          return (
                            <Option key={index} value={data.title}>
                              {data.title}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item name="jobCode" label="Job Code">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      placeholder="Select job code"
                      showSearch
                      allowClear
                      notFoundContent={null}
                      style={{
                        width: "100%",
                      }}
                      className={`${commonStyles.border} candidateSelectHeight`}
                      onKeyUp={(val) => {
                        setFilterDatas({
                          jobCode: val.target.value,
                        });
                      }}
                    >
                      {filterDatas.jobCode &&
                        filterDatas.jobCode !== " " &&
                        filterDatas.jobCode.length > 0 &&
                        props.requirements &&
                        props.requirements &&
                        props.requirements.map((data, index) => {
                          return (
                            <Option key={index} value={data.code}>
                              {data.code}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item name="company" label="Company Name">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      showSearch
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onKeyUp={(val) => {
                        setFilterDatas({ clientName: val.target.value });
                      }}
                      notFoundContent={null}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      className={`${commonStyles.border} candidateSelectHeight`}
                      placeholder="Select company name"
                      tagRender={tagRenderForMobileNum}
                    >
                      {filterDatas.clientName &&
                        filterDatas.clientName.length > 0 &&
                        filterDatas.clientName !== " " &&
                        props.clients &&
                        props.clients.map((data, index) => {
                          return (
                            <Option key={index} value={data.clientName}>
                              {data.clientName}
                            </Option>
                          );
                        })}

                      {/* {props.clients &&
                        props.clients.map((data, index) => {
                          return (
                            <Option key={index} value={data.id}>
                              {data.clientName}
                            </Option>
                          );
                        })} */}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item name="recruiter" label="Recruiter">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      showSearch
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onKeyUp={(val) => {
                        setFilterDatas({ Recruiter: val.target.value });
                      }}
                      notFoundContent={null}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      className={`${commonStyles.border} candidateSelectHeight`}
                      placeholder="Select recruiter"
                      tagRender={tagRenderForMobileNum}
                    >
                      {filterDatas.Recruiter &&
                        filterDatas.Recruiter.length > 0 &&
                        filterDatas.Recruiter !== " " &&
                        props.employees &&
                        props.employees.map((data, index) => {
                          return (
                            <Option key={index} value={data.id}>
                              {data.firstName + " " + data.lastName}
                            </Option>
                          );
                        })}

                      {/* {props.clients &&
                        props.clients.map((data, index) => {
                          return (
                            <Option key={index} value={data.id}>
                              {data.clientName}
                            </Option>
                          );
                        })} */}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item name="department" label="Department">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      allowClear
                      showSearch
                      notFoundContent={null}
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select department"
                      className={`${commonStyles.border} candidateSelectHeight`}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onKeyUp={(val) => {
                        setFilterDatas({
                          department: val.target.value,
                        });
                      }}
                    >
                      {filterDatas.department &&
                        filterDatas.department !== " " &&
                        filterDatas.department.length > 0 &&
                        props.departments &&
                        props.departments &&
                        props.departments.map((data, index) => {
                          return (
                            <Option key={index} value={data.departmentName}>
                              {data.departmentName}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item name="skills" label="Skills">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      showSearch
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      notFoundContent={null}
                      placeholder="Select skills"
                      tagRender={tagRender}
                      className={`${commonStyles.border} candidateSelectHeight`}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onKeyUp={(val) => {
                        setFilterDatas({
                          skills: val.target.value,
                        });
                      }}
                    >
                      {filterDatas.skills &&
                        filterDatas.skills !== " " &&
                        filterDatas.skills.length > 0 &&
                        props.tagsData &&
                        props.tagsData
                          .filter((data) => data.type === "skill")
                          .map((data) => {
                            return (
                              <Select.Option key={data.id} value={data.tag}>
                                {data.tag}
                              </Select.Option>
                            );
                          })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item name="location" label="Location">
                    {/* <Select
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select company name"
                      className={`${commonStyles.border} candidateSelectHeight`}
                      >
                       {props.filterData && props.filterData.data &&
                      props.filterData.data.map((data, index) => {
                        return (
                          <Option key={index} value={data.locationDetails}>
                            {data.locationDetails}
                          </Option>
                        );
                      })}
                      </Select> */}
                    <Input
                      onChange={() => {
                        if (!location) {
                          setLocation(true);
                        }
                      }}
                      placeholder="Enter location"
                      className={"CandidateInputHeight"}
                    />
                  </Form.Item>
                  {location && (
                    <ResetButton
                      onClearData={() => {
                        form.setFieldsValue({
                          location: "",
                        });
                        setLocation(false);
                      }}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item name="area" label="Area">
                    {/* <Select
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select company name"
                      className={`${commonStyles.border} candidateSelectHeight`}
                      >
                       {props.filterData && props.filterData.data &&
                      props.filterData.data.map((data, index) => {
                        return (
                          <Option key={index} value={data.locationDetails}>
                            {data.locationDetails}
                          </Option>
                        );
                      })}
                      </Select> */}
                    <Input
                      onChange={() => {
                        if (!restBtnArea) {
                          setRestBtnArea(true);
                        }
                      }}
                      placeholder="Enter area"
                      className={"CandidateInputHeight"}
                    />
                  </Form.Item>
                  {restBtnArea && (
                    <ResetButton
                      onClearData={() => {
                        form.setFieldsValue({
                          area: "",
                        });
                        setRestBtnArea(false);
                      }}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item
                    label="Job Priority"
                    name="priority"
                    // rules={[
                    //   {
                    //     required: false,
                    //     message: "Please select job priority",
                    //   },
                    // ]}
                  >
                    <Select placeholder="Select job priority" allowClear>
                      <Select.Option value="trending">Trending</Select.Option>
                      <Select.Option value="hot">Hot</Select.Option>
                      <Select.Option value="premium">Premium</Select.Option>
                      <Select.Option value="featured">Featured</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col flex={1}>
                  <Form.Item name="status" label="Status">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select status"
                      className={`${commonStyles.border} candidateSelectHeight`}
                    >
                      <Select.Option value="open">open</Select.Option>
                      <Select.Option value="closed">closed</Select.Option>
                      {/* <Select.Option value="on hold">on hold</Select.Option> */}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row style={{ gap: "30px" }}>
                <Col>
                  <Row align="middle" style={{ gap: "10px" }}>
                    <Col>
                      <p className={`${commonStyles.formInputLabel} `}>
                        Publish Jobs
                      </p>
                    </Col>
                    <Col>
                      <Form.Item name="jobStatus">
                        <Switch
                          onChange={(val) => {
                            setPulishedJobs(val);
                            {
                              val == true
                                ? setDraftJobs(false)
                                : setDraftJobs(true);
                            }
                          }}
                          checked={publisedJob}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row align="middle" style={{ gap: "10px" }}>
                    <Col>
                      <p className={`${commonStyles.formInputLabel} `}>
                        Draft Jobs
                      </p>
                    </Col>
                    <Col>
                      <Form.Item name="jobStatus">
                        <Switch
                          onChange={(val) => {
                            setDraftJobs(val);
                            {
                              val == true
                                ? setPulishedJobs(false)
                                : setPulishedJobs(true);
                            }
                          }}
                          checked={draftJob}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row> */}
              <Row>
                <Col flex={1}>
                  <Form.Item name="jobStatus" label="Job Status">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select job status"
                      className={`${commonStyles.border} candidateSelectHeight`}
                    >
                      <Select.Option value="draft">Draft</Select.Option>
                      <Select.Option value="published">Published</Select.Option>
                      <Select.Option value="completed">Completed</Select.Option>
                      <Select.Option value="live">Live</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Col className={commonStyles.footerShadow} style={{ width: "503px" }}>
            <Row justify="end" style={{ gap: "25px" }}>
              <Col>
                <Button
                  className={commonStyles.buttonSettingsSecondary}
                  type="default"
                  onClick={() => {
                    localStorage.removeItem("reportsjobdetailfilterdata");
                    localStorage.removeItem("ReportsJobDetailFilterOneSort");
                    resetFilter();
                    props.onResetFilter();
                    props.reportsJobFilterHandleer({
                      current_page: 1,
                      dateFrom: "1970-01-01",
                      dateTo: "3000-01-01",
                    });

                    setExperienceToMonth(false);
                    setExperienceFromMonth(false);
                    setLocation(false);
                    setSalary(false);
                    setFilterDatas({
                      title: "",
                      jobCode: "",
                      role: "",
                      department: "",
                      skills: "",
                      clientName: null,
                      company: null,
                    });

                    props.onCloseFilter();
                  }}
                >
                  Reset Filter
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    form.submit();
                  }}
                  htmlType="button"
                  className={commonStyles.buttonSettingsPrimary}
                  type="primary"
                >
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    employees: state.employee.employees,
    requirements: state.requirements.requirements,
    departments: state.departments.departments,
    tagsData: state.tags.tags,
    clients: state.client.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeesAction: () => {
      dispatch(getEmployeesAction());
    },
    getRequirementAction: () => {
      dispatch(getRequirementsAction());
    },
    getDepartmentsAction: () => {
      dispatch(getDepartmentsAction());
    },
    getTagHandler: () => {
      dispatch(getTagsAction());
    },
    getClientsHandler: () => {
      dispatch(getClientsAction());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsJobDetailFilter);
