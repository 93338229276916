import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import {
  CaretBlackDownIcon,
  Featured,
  Filedownload,
  FilterOutlinedIcon,
  Hot,
  NewIcon,
  PlusIcon,
  Premium,
  TrashIcon,
  Trending,
} from "../../../IconsComp/Icons";
import { Link, useLocation } from "react-router-dom";
import {
  getReportsJobAction,
  exportReportsJobDetaisAction,
} from "../../../../Reusable/Store/Action/ReportsAction";
import { connect } from "react-redux";
import moment from "moment";
import ReportsCompanyDetailFilter from "./ReportsCompanyDetailFilter";
import ReportsJobDetailFilter from "./ReportsJobDetailFilter";
import { useForm } from "antd/lib/form/Form";

function ReportsJobDetail(props) {
  const [cfilter, setCFilter] = useState({});
  const [form] = useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  const location = useLocation();
  const [menuKey, setMenuKey] = useState("1");

  const [fromDate, setFromDate] = useState("1970-01-01");
  const [toDate, setToDate] = useState("3000-01-01");

  useEffect(() => {
    // props.getJobReportsHandler({
    //   current_page: 1,
    //   company:
    //     location && location.state && location.state.id && location.state.id,
    // });
    setCFilter({
      current_page: 1,
      company:
        location && location.state && location.state.id && location.state.id,
    });
    // localStorage.setItem("reportsjobdetailfilterdata",JSON.stringify( {company:
    //   location && location.state && location.state.id && location.state.id}))
  }, [location]);

  useEffect(()=>{
    const obj = JSON.parse(localStorage.getItem("reportsjobdetailfilterdata"));
    if(obj){
      props.getJobReportsHandler({...obj,current_page: 1});
    }else{
      props.getJobReportsHandler({
        current_page: 1,
        company:
          location &&
          location.state &&
          location.state.id && 
          location.state.id,
      });
      localStorage.setItem("reportsjobdetailfilterdata",JSON.stringify({
        current_page: 1,
        company:
        location &&
        location.state &&
        location.state.id && 
        location.state.id,
      }));
    }
  },[])

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys, ...newSelectedRowKeys]);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const dataFormate =
    props.settindsData && props.settindsData.dateFormat
      ? props.settindsData.dateFormat
      : "DD/MM/YYYY";

  const fetchRecords = (page) => {

    let fDataRepJobDe = JSON.parse(localStorage.getItem("reportsjobdetailfilterdata"));

    if(fDataRepJobDe){
      const val = {
        ...cfilter,
        ...fDataRepJobDe,
        ...props.candidatesFilterData,
        current_page: page,
      };
      localStorage.setItem("reportsjobdetailfilterdata",JSON.stringify({...fDataRepJobDe,current_page:page}))

      props.getJobReportsHandler(val);
    }else{
      const val = {
        ...cfilter,
        ...props.candidatesFilterData,
        current_page: page,
      };
      localStorage.setItem("reportsjobdetailfilterdata",JSON.stringify({current_page:page}))
      props.getJobReportsHandler(val);
    }

   
  };

  function showFilterHandler() {
    setOpenFilter(true);
  }
  const closeFilterHandler = () => {
    setOpenFilter(false);
  };

  const reportsJobFilterHandleer = (val) => {
    let fDataRepJobDe = JSON.parse(localStorage.getItem("reportsjobdetailfilterdata"));

    setCFilter({ ...val });
    const data = {
      ...fDataRepJobDe,
      ...val,
      type: "list",
      current_page: 1,
    }
    props.getJobReportsHandler(data);
    localStorage.setItem("reportsjobdetailfilterdata",JSON.stringify(data))
    closeFilterHandler();
  };

  const menu2 = (
    <Menu
      style={{ width: "151px", margin: "0px 45px" }}
      selectable
      defaultSelectedKeys={["1"]}
      items={[
        {
          key: "3",
          label: (
            <p
              style={{ marginTop: "7px" }}
              className={`${commonCssStyle.externalJobTextrole}`}
              onClick={() => {
                const data = {
                  current_page: 1,
                  ...cfilter,
                  dateFrom: fromDate,
                  dateTo: toDate,
                  type: "export",
                  exportType: "excel",
                };
                props.exportJobDetailsReportHandler(data);
              }}
            >
              Export to Excel
            </p>
          ),
        },
        {
          key: "4",
          label: (
            <p
              style={{ marginTop: "7px" }}
              className={`${commonCssStyle.externalJobTextrole}`}
              onClick={() => {
                const data = {
                  current_page: 1,
                  ...cfilter,
                  dateFrom: fromDate,
                  dateTo: toDate,
                  type: "export",
                  exportType: "pdf",
                };
                props.exportJobDetailsReportHandler(data);
              }}
            >
              Export to PDF
            </p>
          ),
        },
      ]}
    />
  );

  const columns = [
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Job Title
        </p>
      ),
      dataIndex: "title",
      render: (text, render) => (
        <>
          <Link
            to={"/reports/job/candidates"}
            state={{
              // jobId: render.id,
              jobId: text,
              backButtonId:
                location &&
                location.state &&
                location.state.id &&
                location.state.id,
              Companyname: location.state.Companyname,
              jobName: text,
            }}
          >
            <Row>
              <Col>
                <p
                  style={{ cursor: "pointer" }}
                  className={commonCssStyle.tableDataLinkText}
                >
                  {text}
                </p>
              </Col>
              <Col className={commonCssStyle.newIcon}>
                {render.priority == "trending" ? (
                  <Trending />
                ) : render.priority == "hot" ? (
                  <Hot />
                ) : render.priority == "premium" ? (
                  <Premium />
                ) : render.priority == "featured" ? (
                  <Featured />
                ) : (
                  <NewIcon />
                )}
              </Col>
            </Row>
          </Link>
        </>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Company Name
        </p>
      ),
      dataIndex: "clientName",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Department
        </p>
      ),
      dataIndex: "department",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data ? data : "NA"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Public Listing
        </p>
      ),
      dataIndex: "makePublic",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data == "1" ? "YES" : "NO"}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Position Required
        </p>
      ),
      dataIndex: "positionRequired",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Active
        </p>
      ),
      dataIndex: "activeCount",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Screening
        </p>
      ),
      dataIndex: "screeningCount",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Interviewing
        </p>
      ),
      dataIndex: "interviewedCount",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Offered
        </p>
      ),
      dataIndex: "offeredCount",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Hired
        </p>
      ),
      dataIndex: "hiredCount",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Declined
        </p>
      ),
      dataIndex: "declinedCount",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Position Closed
        </p>
      ),
      dataIndex: "positionClosed",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
          >
            {data && data}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Billing
        </p>
      ),
      dataIndex: "billingValue",
      render: (data) => (
        <p
          className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
        >
          {props.settindsData.currency && props.settindsData.currency}{" "}
          {data ? data : "NA"}
        </p>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Start Date
        </p>
      ),
      dataIndex: "startDate",
      render: (data) => (
        <p
        style={{whiteSpace:"nowrap"}}
          className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
        >
          {data && data !== "NA" ? moment(data).format(dataFormate) : "NA"}
        </p>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          End Date
        </p>
      ),
      dataIndex: "endDate",
      render: (data) => (
        <p
        style={{whiteSpace:"nowrap"}}
          className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
        >
          {data && data !== "NA" ? moment(data).format(dataFormate) : "NA"}
        </p>
      ),
    },
    {
      title: (
        <p
          className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.tableHeadingsColor}`}
        >
          Time Taken to Close Job
        </p>
      ),
      dataIndex: "timeTaken",
      render: (data) => (
        <p
          className={`${commonCssStyle.tableData} ${commonCssStyle.marginBottomRemove}`}
        >
          {data ? data : "NA"}
        </p>
      ),
    },
  ];
  const handleProvinceChange = (value,d) => {
    localStorage.setItem("ReportsJobDetailFilterOneSort",JSON.stringify(d.value))

    let sort = value.split("_");
let RepJbSrtDataLocal = JSON.parse(localStorage.getItem("reportsjobdetailfilterdata"));
if(RepJbSrtDataLocal){
  props.getJobReportsHandler({
    current_page: 1,
    ...cfilter,
    ...RepJbSrtDataLocal,
    sortBy: sort[0],
    sortOrder: sort[1],
    type: "list",
  });
  setCFilter({
    current_page: 1,
    ...cfilter,
    ...RepJbSrtDataLocal,
    sortBy: sort[0],
    sortOrder: sort[1],
    type: "list",
  });
  localStorage.setItem("reportsjobdetailfilterdata",JSON.stringify({...RepJbSrtDataLocal, sortBy: sort[0],
    sortOrder: sort[1]}))
}else{
  props.getJobReportsHandler({
    current_page: 1,
    ...cfilter,
    sortBy: sort[0],
    sortOrder: sort[1],
    type: "list",
  });
  setCFilter({
    current_page: 1,
    ...cfilter,
    sortBy: sort[0],
    sortOrder: sort[1],
    type: "list",
  });
  localStorage.setItem("reportsjobdetailfilterdata",JSON.stringify({ current_page: 1,
    ...cfilter,sortBy: sort[0],
    sortOrder: sort[1]}))
}
   
  };

  return (
    <>
      <Breadcrumb className={commonCssStyle.breadcrumbBottomMargin}>
        <Breadcrumb.Item>
          <Link to={"/"}>
            <p
             onClick={()=>{
              localStorage.removeItem("reportsjobdetailfilterdata") 
              localStorage.removeItem("ReportsJobDetailFilterOneSort") 
              
            }}
              className={`${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove} ${commonCssStyle.breadcrumbText}`}
            >
              Home
            </p>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/reports/companydetailtable"}>
            <p
            onClick={()=>{
              localStorage.removeItem("reportsjobdetailfilterdata")
              localStorage.removeItem("ReportsJobDetailFilterOneSort")
            }}
              className={`${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove} ${commonCssStyle.breadcrumbText}`}
            >
              Company &nbsp;(&nbsp;{location.state.Companyname}&nbsp;)
            </p>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/reports/jobdetailtable"} state={location.state}>
            <p
              className={`${commonCssStyle.coursorPointer} ${commonCssStyle.marginBottomRemove} ${commonCssStyle.breadcrumbText}`}
            >
              Job
            </p>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col md={24}>
          <Row align="middle" justify="space-between">
            <Col>
              <Row>
                <Col /* xs={24} sm={24} md={24} lg={6} */>
                  <h1
                    style={{ marginRight: "31px" }}
                    className={`${commonCssStyle.mainHeadingText} ${commonCssStyle.inttitle}`}
                  >
                    Job Report &#40;{" "}
                    {props.jobReport && props.jobReport.total
                      ? props.jobReport.total
                      : "0"}{" "}
                    &#41;
                  </h1>
                </Col>

                <Col /* xs={24} sm={24} md={24} lg={2} */>
                  <Button
                    onClick={showFilterHandler}
                    className={`${commonCssStyle.filterButton}`}
                    id="candidates-table-filter-btn"
                  >
                    <Row style={{ gap: "11px" }}>
                      <Col>
                        <div style={{ transform: "translateY(2px)" }}>
                          <FilterOutlinedIcon color={"var(--primaryColor)"} />
                        </div>
                      </Col>
                      <Col>
                        <p
                          className={`${commonCssStyle.filterAndSortTextStyle} ${commonCssStyle.marginBottomRemove}`}
                        >
                          Filter
                        </p>
                      </Col>
                    </Row>
                  </Button>
                </Col>

                <Col /* xs={24} sm={24} md={24} lg={6} */>
                  <Button
                    style={{
                      height: "40px",
                      marginRight: "20px",
                    }}
                    className={` ${commonCssStyle.sortBtn}`}
                    id="candidates-table-sortby-btn"
                  >
                    <Row justify="space-between">
                      <Col>
                        <p style={{ transform: "translateY(2px)" }}>Sort By</p>
                      </Col>
                      <Col>
                        <Select
                         value={JSON.parse(localStorage.getItem("ReportsJobDetailFilterOneSort"))
                         ?
                         JSON.parse(localStorage.getItem("ReportsJobDetailFilterOneSort")):"Select"
                       }
                          style={{
                            width: "100%",
                            transform: "translateY(3px)",
                          }}
                          onChange={handleProvinceChange}
                          bordered={false}
                          placeholder={
                            <p
                              style={{
                                margin: "0px",
                                color: "var(--primaryColor)",
                              }}
                            >
                              Select
                            </p>
                          }
                          size="small"
                          className={`${commonCssStyle.filterDrop}  selectInput sortBySelectStyle selectTag selectTag1`}
                        >
                          <Select.Option
                            value="clientName_ASC"
                            key="0"
                            style={{ padding: "10px" }}
                          >
                            Client Name Ascending
                          </Select.Option>
                          <Select.Option
                            value="clientName_DESC"
                            key="1"
                            style={{ padding: "10px" }}
                          >
                            Client Name Descending
                          </Select.Option>
                          <Select.Option
                            value="lastUpdated_ASC"
                            key="2"
                            style={{ padding: "10px" }}
                          >
                            Last Updated Ascending
                          </Select.Option>
                          <Select.Option
                            value="lastUpdated_DESC"
                            key="3"
                            style={{ padding: "10px" }}
                          >
                            Last Updated Descending
                          </Select.Option>
                        </Select>
                      </Col>
                    </Row>
                  </Button>
                </Col>
              </Row>
            </Col>
            {/* demo  */}
            <Col /* xs={24} sm={24} md={24} lg={3} */>
              <Form form={form}>
                <Row justify="space-between">
                  <Col md={11}>
                    <Form.Item className="candidateReportForm" name="from">
                      <DatePicker
                        name="from"
                        format={dataFormate}
                        placeholder="from"
                        style={{ width: "115px", height: "40px" }}
                        className="ReportsFrom"
                        suffixIcon={null}
                        onChange={(val) => {
                          if (val !== null) {
                            props.getJobReportsHandler({
                              ...cfilter,
                              current_page: 1,
                              type: "list",
                              dateFrom: moment(val).format("YYYY-MM-DD"),
                              dateTo: toDate,
                            });
                            setFromDate(moment(val).format("YYYY-MM-DD"));
                          } else {
                            props.getJobReportsHandler({
                              ...cfilter,
                              type: "list",
                              current_page: 1,
                              dateFrom: "1970-01-01",
                              dateTo: toDate,
                            });
                            setFromDate("1970-01-01");
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={11}>
                    <Form.Item className="candidateReportForm" name="to">
                      <DatePicker
                        onChange={(val) => {
                          if (val !== null) {
                            props.getJobReportsHandler({
                              ...cfilter,
                              current_page: 1,
                              type: "list",
                              dateFrom: fromDate,
                              dateTo: moment(val).format("YYYY-MM-DD"),
                            });
                            setToDate(moment(val).format("YYYY-MM-DD"));
                          } else {
                            props.getJobReportsHandler({
                              ...cfilter,
                              type: "list",
                              current_page: 1,
                              dateTo: "3000-01-01",
                              dateFrom: fromDate,
                            });
                            setToDate("3000-01-01");
                          }
                        }}
                        format={dataFormate}
                        placeholder="to"
                        style={{ width: "115px", height: "40px" }}
                        className="ReportsFrom"
                        suffixIcon={null}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>

            {/* end of demo  */}

            <Col xs={24} sm={24} md={24} lg={4}>
              <Row>
                <Dropdown placement="bottom" overlay={menu2}>
                  <Typography.Link>
                    <Space className={commonCssStyle.tableHeadingTextData}>
                      <Button
                        style={{ width: "151px" }}
                        className={commonCssStyle.intaddButton}
                        type="primary"
                      >
                        <Row align="middle" justify="space-around">
                          <Col>
                            <PlusIcon color={"white"} />
                          </Col>
                          <Col>Export</Col>
                          <Col>
                            <CaretBlackDownIcon color={"white"} />
                          </Col>
                        </Row>
                      </Button>
                    </Space>
                  </Typography.Link>
                </Dropdown>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={24} style={{ marginTop: "40px" }}>
          <Table
            className="ReportsTablescroll"
            scroll={{ x: "100vw" }}
            pagination={{
              size: "small",
              current: props.jobReport && props.jobReport.current_page,
              pageSize: props.jobReport && props.jobReport.per_page,
              total: props.jobReport && props.jobReport.total,
              showSizeChanger: false,
              onChange: (props) => {
                fetchRecords(props);
              },
            }}
            columns={columns}
            dataSource={
              props.jobReport && props.jobReport.data && props.jobReport.data
            }
          />
        </Col>
      </Row>
      <ReportsJobDetailFilter
        onOpenFilter={openFilter}
        onCloseFilter={closeFilterHandler}
        reportsJobFilterHandleer={reportsJobFilterHandleer}
        initialValuesCompany={
          location && location.state && location.state.id && location.state.id
        }
        fromDate={fromDate}
        toDate={toDate}
        onResetFilter={() => {
          form.resetFields();
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    settindsData: state.settings.settings,
    jobReport: state.reports.reportsjob,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobReportsHandler: (val, callback) => {
      dispatch(getReportsJobAction(val, callback));
    },
    exportJobDetailsReportHandler: (val) => {
      dispatch(exportReportsJobDetaisAction(val));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsJobDetail);
