import { Avatar, Card, Col, Row } from "antd";
import {
  BuildingsIcon,
  EditBorderIcon,
  EnvelopeIcon,
  PhoneIcon,
  MapPinIcon,
  UserIcon,
  AgreedCommercialIcon,
} from "../../../IconsComp/Icons";
import { useEffect, useState } from "react";
import styles from "../../AddCandidatesComp/AddCandidatesComp.module.css";
import commonCssStyles from "../../../UI/CommonCss.module.css";
import EditClientCompanyDetailsDrawer from "./EditClientCompanyDetailsDrawer";
import EditContactPersonModal from "./EditContactPersonModal";
import EditBillingAddressDrawer from "./EditBillingAddressDrawer";
import EditAgreedCommercialsDrawer from "./EditAgreedCommercialsDrawer";
import { connect } from "react-redux";
import { getCountriesAction } from "../../../../Reusable/Store/Action/Master/MasterCountryAction";
import { checkPermissionAction } from "../../../../Reusable/Store/Action/AuthPermissionsAction";
import PermissionMessage from "../../../UI/PermissionMessage";
// import { set } from "immer/dist/internal";

function ClientCompanyDetails(props) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [permanentStates, setPermanentStates] = useState([]);
  const [permanentCity, setPermanentCity] = useState([]);
  useEffect(() => {
    props.getCountryHandler({ type: "country" }, (data, type) => {
      setCountries(data);
    });
  }, []);
  const [openEditModal, setEditOpenModal] = useState(false);
  const [openContactPersonModal, setOpenContactPersonModal] = useState(false);
  const [onOpenBillingAddressModal, setEditBillingAddressModal] =
    useState(false);
  const [onOpenAgreedCommercialModal, setOpenAgreedCommercialModal] =
    useState(false);
  const [openPMModal, setOpenPMModal] = useState({
    open: false,
    permission: "",
  });
  const closeInputHandle = () => {
    setEditOpenModal(false);
  };
  const closeContactPersonModal = () => {
    setOpenContactPersonModal(false);
  };
  const CloseEditBillingModal = () => {
    setEditBillingAddressModal(false);
  };
  const CloseAgreedCommercialsModal = () => {
    setOpenAgreedCommercialModal(false);
  };

  return (
    <>
      <Row style={{ marginTop: "-12px" }}>
        <Col xs={24} md={24}>
          <Card
            style={{
              marginBottom: "30px",
            }}
            title={
              <>
                <Row align="middle" justify="space-between">
                  <Col>
                    <h3 className={commonCssStyles.summaryInfoMainText}>
                      <Avatar
                        className={commonCssStyles.avtarBgClr}
                        size={50}
                        style={{ paddingTop: "5px", marginRight: "12px" }}
                      >
                        <BuildingsIcon color={"var(--primaryColor)"} />
                      </Avatar>
                      Company Details
                    </h3>
                  </Col>
                </Row>
              </>
            }
            extra={
              props.permissions &&
              props.permissions.find((data) => data === "client-edit") && (
                <div
                  onClick={() => {
                    props.getCheckPermissionHandler(
                      "client-edit",
                      (success) => {
                        if (success) {
                          setEditOpenModal(true);
                        } else {
                          setOpenPMModal({
                            open: true,
                            permission: "Client Edit",
                          });
                        }
                      }
                    );
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <EditBorderIcon color={"var(--primaryColor)"} />
                </div>
              )
            }
          >
            <Row
              className={styles.paddingForInfo}
              justify="space-between"
              style={{ marginTop: "2%" }}
            >
              <Col>
                <Row style={{ gap: "5px" }} justify="space-between">
                  <Col>
                    <EnvelopeIcon color={"var(--body-text-color)"} />
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <p
                          className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                          style={{ marginBottom: "8px" }}
                        >
                          Email
                        </p>
                      </Col>
                    </Row>
                    {props.permissions &&
                    props.permissions.find(
                      (data) => data === "client-contact-info"
                    ) ? (
                      <Row>
                        <Col>
                          <p className={commonCssStyles.infoTextColorBold}>
                            {props.client && props.client.clientEmail}
                          </p>
                        </Col>
                      </Row>
                    ) : (
                      <p
                        className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.infoTextColorBold}`}
                        style={{ marginLeft: "1px" }}
                      >
                        *************
                      </p>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row style={{ gap: "5px" }} justify="space-between">
                  <Col>
                    <PhoneIcon color={"var(--body-text-color)"} />
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <p
                          className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                          style={{ marginBottom: "8px" }}
                        >
                          Contact Number
                        </p>
                      </Col>
                    </Row>
                    {props.permissions &&
                    props.permissions.find(
                      (data) => data === "client-contact-info"
                    ) ? (
                      <Row>
                        <Col>
                          <p
                            className={commonCssStyles.infoTextColorBold}
                            style={{ marginLeft: "3px" }}
                          >
                            {props.client && props.client.clientPhone}
                          </p>
                        </Col>
                      </Row>
                    ) : (
                      <p
                        className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.infoTextColorBold}`}
                        style={{ marginLeft: "1px" }}
                      >
                        *************
                      </p>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row style={{ gap: "5px" }} justify="space-between">
                  <Col>
                    <PhoneIcon color={"var(--body-text-color)"} />
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <p
                          className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                          style={{ marginBottom: "8px" }}
                        >
                          Number of Employee
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p
                          className={commonCssStyles.infoTextColorBold}
                          style={{ marginLeft: "2px" }}
                        >
                          {props.client && props.client.employeesCount}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ gap: "20px" }} className={styles.paddingForInfo}>
              {/* <Col md={1}></Col> */}
              <Col>
                <Row>
                  <Col>
                    <p
                      className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                      style={{ marginBottom: "8px" }}
                    >
                      TAN
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p
                      className={commonCssStyles.infoTextColorBold}
                      style={{ marginLeft: "2px" }}
                    >
                      {props.client && props.client.tan
                        ? props.client.tan
                        : "NA"}
                    </p>
                  </Col>
                </Row>
              </Col>
              {/* <Col md={1}></Col> */}

              <Col>
                <Row>
                  <Col>
                    <p
                      className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                      style={{ marginBottom: "8px" }}
                    >
                      CIN
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p
                      className={commonCssStyles.infoTextColorBold}
                      style={{ marginLeft: "2px" }}
                    >
                      {props.client && props.client.cin ? props.client.cin :"NA"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} md={24}>
          <Card
            style={{
              marginBottom: "30px",
            }}
            title={
              <>
                <Row align="middle" justify="space-between">
                  <Col>
                    <h3 className={commonCssStyles.summaryInfoMainText}>
                      <Avatar
                        className={commonCssStyles.avtarBgClr}
                        size={50}
                        style={{ paddingTop: "5px", marginRight: "12px" }}
                      >
                        <UserIcon color={"var(--primaryColor)"} />
                      </Avatar>
                      Contact Person Details
                    </h3>
                  </Col>
                </Row>
              </>
            }
            extra={
              props.permissions &&
              props.permissions.find((data) => data === "client-edit") && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.getCheckPermissionHandler(
                      "client-edit",
                      (success) => {
                        if (success) {
                          setOpenContactPersonModal(true);
                        } else {
                          setOpenPMModal({
                            open: true,
                            permission: "Client Edit",
                          });
                        }
                      }
                    );
                  }}
                >
                  <EditBorderIcon color={"var(--primaryColor)"} />
                </div>
              )
            }
          >
            <Row
              className={styles.paddingForInfo}
              justify="space-between"
              style={{ marginTop: "2%" }}
            >
              <Col /* md={6} */>
                <Row>
                  <Col>
                    <p
                      className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                      style={{ marginBottom: "8px" }}
                    >
                      Name
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p
                      className={commonCssStyles.infoTextColorBold}
                      style={{ marginLeft: "1px" }}
                    >
                      {props.client && props.client.contactPerson}
                    </p>
                  </Col>
                </Row>
              </Col>

              <Col /* md={6} */>
                <Row>
                  <Col>
                    <p
                      className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                      style={{ marginBottom: "8px" }}
                    >
                      Designation
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p
                      className={commonCssStyles.infoTextColorBold}
                      style={{ marginLeft: "1px" }}
                    >
                      {props.client && props.client.contactPersonDesignation}
                    </p>
                  </Col>
                </Row>
              </Col>

              <Col /* md={6} */>
                <Row>
                  <Col>
                    <p
                      className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                      style={{ marginBottom: "8px" }}
                    >
                      Contact Number
                    </p>
                  </Col>
                </Row>
                {props.permissions &&
                props.permissions.find(
                  (data) => data === "client-contact-info"
                ) ? (
                  <Row>
                    <Col>
                      <p
                        className={commonCssStyles.infoTextColorBold}
                        style={{ marginLeft: "1px" }}
                      >
                        {props.client && props.client.contactPersonPhone}
                      </p>
                    </Col>
                  </Row>
                ) : (
                  <p
                    className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.infoTextColorBold}`}
                    style={{ marginLeft: "1px" }}
                  >
                    *************
                  </p>
                )}
              </Col>

              <Col /* md={6} */>
                <Row>
                  <Col>
                    <p
                      className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                      style={{ marginBottom: "8px" }}
                    >
                      Email Address
                    </p>
                  </Col>
                </Row>
                {props.permissions &&
                props.permissions.find(
                  (data) => data === "client-contact-info"
                ) ? (
                  <Row>
                    <Col>
                      <p
                        className={commonCssStyles.infoTextColorBold}
                        style={{ marginLeft: "1px" }}
                      >
                        {props.client && props.client.contactPersonEmail}
                      </p>
                    </Col>
                  </Row>
                ) : (
                  <p
                    className={`${commonCssStyles.marginBottomRemove} ${commonCssStyles.infoTextColorBold}`}
                    style={{ marginLeft: "1px" }}
                  >
                    *************
                  </p>
                )}
              </Col>
            </Row>
          </Card>
        </Col>

        {props.permissions &&
          props.permissions.find(
            (data) => data === "client-billing-detail"
          ) && (
            <Col xs={24} md={24}>
              <Card
                style={{
                  marginBottom: "30px",
                }}
                title={
                  <>
                    <Row align="middle" justify="space-between">
                      <Col>
                        <h3 className={commonCssStyles.summaryInfoMainText}>
                          <Avatar
                            className={commonCssStyles.avtarBgClr}
                            size={50}
                            style={{ paddingTop: "5px", marginRight: "12px" }}
                          >
                            <MapPinIcon color={"var(--primaryColor)"} />
                          </Avatar>
                          Billing Details
                        </h3>
                      </Col>
                    </Row>
                  </>
                }
                extra={
                  props.permissions &&
                  props.permissions.find((data) => data === "client-edit") && (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        props.getCheckPermissionHandler(
                          "client-edit",
                          (success) => {
                            if (success) {
                              setEditBillingAddressModal(true);
                            } else {
                              setOpenPMModal({
                                open: true,
                                permission: "Client Edit",
                              });
                            }
                          }
                        );
                      }}
                    >
                      <EditBorderIcon color={"var(--primaryColor)"} />
                    </div>
                  )
                }
              >
                <Row
                  justify="space-between"
                  className={styles.paddingForInfo}
                  style={{ marginTop: "24px" }}
                >
                  <Col /* md={7} */>
                    <Row>
                      <Col>
                        <p
                          className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                          style={{ marginBottom: "8px" }}
                        >
                          Billing Address
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className={commonCssStyles.infoTextColorBold}>
                          {props.client && props.client.billingAddress}
                        </p>
                      </Col>
                    </Row>
                  </Col>

                  <Col /* md={5} */>
                    <Row>
                      <Col>
                        <p
                          className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                          style={{ marginBottom: "8px" }}
                        >
                          State
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className={commonCssStyles.infoTextColorBold}>
                          {" "}
                          {props.client && props.client.billingState}
                        </p>
                      </Col>
                    </Row>
                  </Col>

                  <Col /* md={6} */>
                    <Row>
                      <Col>
                        <p
                          className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                          style={{ marginBottom: "8px" }}
                        >
                          City
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className={commonCssStyles.infoTextColorBold}>
                          {" "}
                          {props.client && props.client.billingCity}
                        </p>
                      </Col>
                    </Row>
                  </Col>

                  <Col /* md={6} */>
                    <Row>
                      <Col>
                        <p
                          className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                          style={{ marginBottom: "8px" }}
                        >
                          Zip Code
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className={commonCssStyles.infoTextColorBold}>
                          {props.client && props.client.billingPincode}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row
                  className={styles.paddingForInfo}
                  style={{ marginTop: "2%", gap: "20px" }}
                >
                  <Col /* md={7} */>
                    <Row>
                      <Col>
                        <p
                          className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                          style={{ marginBottom: "8px" }}
                        >
                          GST
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className={commonCssStyles.infoTextColorBold}>
                          {props.client && props.client.gstin ? props.client.gstin :"NA"}
                        </p>
                      </Col>
                    </Row>
                  </Col>

                  <Col /* md={5} */>
                    <Row>
                      <Col>
                        <p
                          className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                          style={{ marginBottom: "8px" }}
                        >
                          PAN
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className={commonCssStyles.infoTextColorBold}>
                          {props.client && props.client.pan?props.client.pan:"NA"}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}

        <Col xs={24} md={24}>
          <Card
            style={{
              marginBottom: "30px",
            }}
            title={
              <>
                <Row align="middle" justify="space-between">
                  <Col>
                    <h3 className={commonCssStyles.summaryInfoMainText}>
                      <Avatar
                        className={commonCssStyles.avtarBgClr}
                        size={50}
                        style={{ paddingTop: "1px", marginRight: "12px" }}
                      >
                        <AgreedCommercialIcon color={"var(--primaryColor)"} />
                      </Avatar>
                      Agreed Commercials
                    </h3>
                  </Col>
                </Row>
              </>
            }
            extra={
              props.permissions &&
              props.permissions.find((data) => data === "client-edit") && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.getCheckPermissionHandler(
                      "client-edit",
                      (success) => {
                        if (success) {
                          setOpenAgreedCommercialModal(true);
                        } else {
                          setOpenPMModal({
                            open: true,
                            permission: "Client Edit",
                          });
                        }
                      }
                    );
                  }}
                >
                  <EditBorderIcon color={"var(--primaryColor)"} />
                </div>
              )
            }
          >
            <Row className={styles.paddingForInfo} style={{ marginTop: "2%" }}>
              <Col md={6}>
                <Row>
                  <Col>
                    <p
                      className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                      style={{ marginBottom: "8px" }}
                    >
                      Agreed Commercials
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p
                      className={commonCssStyles.infoTextColorBold}
                      style={{ marginLeft: "1px" }}
                    >
                      {props.client && props.client.fees && props.client.fees
                        ? props.client.fees.map((data, index) => {
                            return (
                              data +
                              "%" +
                              (props.client.fees.length - 1 !== index
                                ? ", "
                                : "")
                            );
                          })
                        : "NA"}
                    </p>
                  </Col>
                </Row>
              </Col>

              {/* <Col md={6}>
                <Row>
                  <Col>
                    <p
                      className={`${commonCssStyles.infoTextColor} ${commonCssStyles.marginBottomRemove}`}
                      style={{ marginBottom: "8px" }}
                    >
                      Commercial Type
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p
                      className={commonCssStyles.infoTextColorBold}
                      style={{ marginLeft: "1px" }}
                    >
                      {props.client && props.client.feesType?props.client.feesType:"N/A"}
                    </p>
                  </Col>
                </Row>
              </Col> */}
            </Row>
          </Card>
        </Col>
      </Row>
      {openEditModal && (
        <EditClientCompanyDetailsDrawer
          onSubmit={(val) => {
            props.putclientTemplateHandler(val, "details", (success) => {
              if (success) {
                setEditOpenModal(false);
              }
            });
          }}
          client={props.client}
          onOpenClientCompany={openEditModal}
          onCloseClientCompany={closeInputHandle}
        />
      )}
      {openContactPersonModal && (
        <EditContactPersonModal
          onSubmit={(val) => {
            props.putclientTemplateHandler(val, "details", (success) => {
              if (success) {
                setOpenContactPersonModal(false);
              }
            });
          }}
          client={props.client}
          onOpenEditContactPersonModal={openContactPersonModal}
          onCloseEditContactPersonModal={closeContactPersonModal}
        />
      )}
      {props.client && onOpenBillingAddressModal && (
        <EditBillingAddressDrawer
          onSubmit={(val) => {
            props.putclientTemplateHandler(val, "details", (success) => {
              if (success) {
                setEditBillingAddressModal(false);
              }
            });
          }}
          onCoutriesData={(data) => {
            props.getCountryHandler(
              { id: data.id, type: data.type },
              (data, type) => {
                setTimeout(() => {
                  setStates(data);
                  setCity([]);
                }, 300);
              }
            );
          }}
          onCoutriesPermanentData={(data) => {
            props.getCountryHandler(
              { id: data.id, type: data.type },
              (data, type) => {
                setTimeout(() => {
                  setPermanentStates(data);
                  setPermanentCity([]);
                }, 300);
              }
            );
          }}
          onCityData={(data) => {
            props.getCountryHandler(
              { id: data.id, type: data.type },
              (data, type) => {
                setTimeout(() => {
                  setCity(data);
                }, 300);
              }
            );
          }}
          onPermanentDataCityData={(data) => {
            props.getCountryHandler(
              { id: data.id, type: data.type },
              (data, type) => {
                setTimeout(() => {
                  setPermanentCity(data);
                }, 300);
              }
            );
          }}
          client={props.client}
          onPermanentCity={permanentCity}
          onPermanentStates={permanentStates}
          onCity={city}
          onStatesData={states}
          countriesData={countries}
          // countriesData={props.countriesData}
          onOpenBillingAddressModal={onOpenBillingAddressModal}
          onCloseBillingAddressModal={CloseEditBillingModal}
        />
      )}
      {props.client && onOpenAgreedCommercialModal && (
        <EditAgreedCommercialsDrawer
          onSubmit={(val) => {
            props.putclientTemplateHandler(val, "details", (success) => {
              if (success) {
                setOpenAgreedCommercialModal(false);
              }
            });
          }}
          client={props.client}
          countriesData={props.countriesData}
          onOpenAgreedCommercials={onOpenAgreedCommercialModal}
          onCloseAgreedCommercials={CloseAgreedCommercialsModal}
        />
      )}
      <PermissionMessage
        onOpenPMModal={openPMModal}
        onClosePMModal={() => {
          setOpenPMModal({ open: false, permission: "" });
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    settindsData: state.settings.settings,
    countriesData: state.country.countries,
    StateData: state.country.state,
    cityData: state.country.city,
    permissions: state.authpermissions.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCountryHandler: (data, callback) => {
      dispatch(getCountriesAction(data, callback));
    },
    getCheckPermissionHandler: (id, callback) => {
      dispatch(checkPermissionAction(id, callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientCompanyDetails);
