import {
  Form,
  Input,
  Modal,
  Button,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Switch,
  Drawer,
} from "antd";
import PhoneInput from "react-phone-number-input";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import commonCssStyle from "../../../UI/CommonCss.module.css";
import { connect } from "react-redux";
import {
  putCandidateExperiencesAction,
  postCandidateExperiencesAction,
} from "../../../../Reusable/Store/Action/CandidatesAction";
import { CloseIcon } from "../../../IconsComp/Icons";
function ExperienceModal(props) {
  const [form] = useForm();
  const dateFormat = props.settindsData && props.settindsData.dateFormat;
  const experienceDataHandler = (data) => {
    const val = {
      candidateId: props.data.candidateId,
      designation: data.designation,
      companyName: data.companyName,
      to:
        data.to === null || data.to === undefined
          ? "Invalid date"
          : moment(data.to).format("YYYY"),
      from:
        data.from === null || data.from === undefined
          ? "Invalid date"
          : moment(data.from).format("YYYY"),
      summary: data.summary,
      isCurrent: data.isCurrent ? 1 : 0,
      referenceName: data.referenceName,
      referenceNumber: data.referenceNumber,
      referenceEmail: data.referenceEmail,
    };

    const putData = {
      id: props.data.id,
      designation: data.designation,
      companyName: data.companyName,
      to:
        data.to === null || data.to === undefined
          ? "Invalid date"
          : moment(data.to).format("YYYY"),
      from:
        data.from === null || data.from === undefined
          ? "Invalid date"
          : moment(data.from).format("YYYY"),
      summary: data.summary,
      isCurrent: data.isCurrent ? true : false,
      referenceName: data.referenceName,
      referenceNumber: data.referenceNumber,
      referenceEmail: data.referenceEmail,
    };

    if (props.onModalName === "add") {
      props.postExperenceHandler(val, (success) => {
        if (success) {
          form.resetFields();
          props.onOkExperienceModal();
        }
      });
    } else {
      props.putExperenceHandler(putData, (success) => {
        if (success) {
          form.resetFields();
          props.onOkExperienceModal();
        }
      });
    }
  };
  const onChange = (date, dateString) => {};
  return (
    <Drawer
      closable={false}
      className={"modalModified"}
      width={500}
      extra={
        <div
          onClick={() => props.onCloseExperienceModal()}
          className={`${commonCssStyle.coursorPointer} `}
        >
          <CloseIcon color={"var(--body-text-color)"} />
        </div>
      }
      // onClose={props.onCloseExperienceModal}
      open={props.onOpenExperienceModal}
      title={
        <p className={commonCssStyle.popUpHeadingText}>
          {props.onModalName === "edit" ? "Edit Experience" : "Add Experience"}
        </p>
      }
      footer={false}
    >
      <Form
        layout="vertical"
        initialValues={
          props.onModalName === "edit" && {
            designation: props.data.designation,
            companyName: props.data.companyName,
            from:
              props.data.from === null || props.data.from === "Invalid date"
                ? undefined
                : moment(props.data.from),
            to:
              props.data.to === null || props.data.to === "Invalid date"
                ? undefined
                : moment(props.data.to),
            summary: props.data.summary,
            isCurrent: props.data.isCurrent,
            referenceName: props.data.referenceName,
            referenceNumber: props.data.referenceNumber,
            referenceEmail: props.data.referenceEmail,
          }
        }
        form={form}
        onFinish={(val) => experienceDataHandler(val)}
      >
        <Row style={{ marginBottom: "80px", padding: "0px 20px" }}>
          <Col md={24}>
            <Form.Item
              label="Company"
              rules={[
                {
                  required: true,
                  message: "Please enter company name",
                },
              ]}
              name="companyName"
            >
              <Input placeholder="Enter company name" />
            </Form.Item>

            <Row
              style={{ gap: "10px", marginBottom: "10px", marginTop: "-18px" }}
              align="middle"
            >
              <Col>
                <Form.Item
                  // label="Current"
                  name="isCurrent"
                  style={{ margin: "0px", padding: "0px" }}
                >
                  {props.data.isCurrent === 1 ? (
                    <Switch defaultChecked />
                  ) : (
                    <Switch />
                  )}
                </Form.Item>
              </Col>
              <Col>
                <p
                  className={`${commonCssStyle.formInputLabel} ${commonCssStyle.marginBottomRemove}`}
                >
                  Current Company
                </p>
              </Col>
            </Row>

            <Form.Item
              label="Designation"
              rules={[
                {
                  required: true,
                  message: "Please enter designation",
                },
              ]}
              name="designation"
            >
              <Input placeholder="Enter designation" />
            </Form.Item>

            <Row style={{ gap: "30px" }}>
              <Col md={11}>
                <Form.Item
                  label="From"
                  rules={[
                    {
                      required: false,
                      message: "Please enter from",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          !getFieldValue("to") ||
                          getFieldValue("to") > value
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "From date must be less than To Date"
                        );
                      },
                    }),
                  ]}
                  name="from"
                >
                  <DatePicker
                    getPopupContainer={(trigger) => trigger.parentElement}
                    className={commonCssStyle.datePickerBgColor}
                    style={{
                      width: "100%",
                    }}
                    format={dateFormat}
                    placeholder="From"
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
              <Col md={11}>
                <Form.Item
                  label="To"
                  rules={[
                    {
                      required: false,
                      message: "Please enter to",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          !getFieldValue("from") ||
                          getFieldValue("from") < value
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "To date must be greater than From Date"
                        );
                      },
                    }),
                  ]}
                  name="to"
                >
                  <DatePicker
                    getPopupContainer={(trigger) => trigger.parentElement}
                    className={commonCssStyle.datePickerBgColor}
                    style={{
                      width: "100%",
                    }}
                    format={dateFormat}
                    placeholder="To"
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Summary"
              rules={[
                {
                  required: false,
                  message: "Please enter summary",
                },
              ]}
              name="summary"
            >
              <Input.TextArea placeholder="Enter summary" />
            </Form.Item>
            <Form.Item
              label="Reference Name"
              rules={[
                {
                  required: false,
                  message: "Please enter reference name",
                },
              ]}
              name="referenceName"
            >
              <Input placeholder="Enter reference name" />
            </Form.Item>
            <Form.Item
              name="referenceNumber"
              label="Reference Number"
              rules={[
                {
                  required: false,
                  message: "Please enter phone number",
                },
              ]}
            >
              <PhoneInput
                placeholder="Enter reference number"
                className="ant-input ant-cust-inputs"
                defaultCountry="IN"
              />
            </Form.Item>

            <Form.Item
              label="Reference Email"
              rules={[
                {
                  required: false,
                  message: "Please enter reference email",
                },
                {
                  type: "email",
                  message: "The input is not valid E-mail!.",
                },
              ]}
              name="referenceEmail"
            >
              <Input placeholder="Enter reference email" />
            </Form.Item>
          </Col>
        </Row>
        <Row
          className={commonCssStyle.footerShadow}
          justify="end"
          align="middle"
          style={{ gap: "10px", width: "500px" }}
        >
          <Col>
            <Button
              className={commonCssStyle.buttonSettingsSecondary}
              onClick={() => {
                props.onCloseExperienceModal();
              }}
              type="default"
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                form.submit();
              }}
              htmlType="button"
              className={commonCssStyle.buttonSettingsPrimary}
              type="primary"
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

const mapStateToProps = (state) => {
  return {
    dataFormate: state.settings.settings,
    settindsData: state.settings.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postExperenceHandler: (data, callback) => {
      dispatch(postCandidateExperiencesAction(data, callback));
    },
    putExperenceHandler: (data, callback) => {
      dispatch(putCandidateExperiencesAction(data, callback));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExperienceModal);
