import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  tags: [],
  tag: null,
};
export const masterTagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    getTagsSlice(state, action) {
      state.tags = action.payload.result;
    },
    postTagsSlice(state, action) {
      state.tags = [...state.tags, action.payload.result];
    },
    getTagSlice(state, action) {
      state.tag = action.payload.result;
    },
    putTagsSlice(state, action) {
      state.tags = state.tags.map((data) => {
        if (data.id === action.payload.result.id) {
          return action.payload.result;
        } else {
          return data;
        }
      });
    },
    deleteTagsSlice(state, action) {
      state.tags = state.tags.filter((data) => data.id !== action.payload);
    },
  },
});
export const {
  getTagsSlice,
  getTagSlice,
  postTagsSlice,
  putTagsSlice,
  deleteTagsSlice,
} = masterTagsSlice.actions;
export default masterTagsSlice.reducer;
