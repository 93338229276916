import {
  getTaskByTypeSlice,
  deleteTaskAndCalendarSlice,
  getTaskAndCalendarSlice,
  getTasksFilterSlice,
  getTasksSlice,
  postTaskAndCalendarSlice,
  putTaskAndCalendarSlice,
  getCalendarFilterSlice,
  getTasksCalendarSlice,
  getCandidatesJobSlice,
  loaderSlice,
} from "../Slice/TaskAndCalendarSlice";
import {
  INTERVIEW_URL,
  TASK_AND_CALENDAR_URL,
  TASK_FILTER_URL,
  TASK_URL,
  TASK_CALENDAR_FILTER_URL,
  FETCH_CANDIDATE_RELATED_JOBS_URL,
  INTERVIEW_PERFORMANCE_RATIO,
  EXPORT_INTERVIEW_FEEDBACK_URL,
} from "../apipath";
import { message } from "antd";
import AxiosApi from "../api";

const api = new AxiosApi();

export const getTaskByTypeAction = (typeId, type) => {
  // debugger;
  return (dispatch) => {
    api.invoke(
      TASK_AND_CALENDAR_URL + "/" + type + "/" + typeId,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getTaskByTypeSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const getTasksAction = () => {
  return (dispatch) => {
    api.invoke(TASK_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) {
          dispatch(getTasksSlice(data));
        } else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getTaskAndCalendarAction = (id, link, callback) => {
  return (dispatch) => {
    api.invoke(
      link.link === 0 ? TASK_URL + "/" + id : INTERVIEW_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        // debugger;

        callback(false);

        if (success) {
          if (statusCode === 200) {
            callback(true);
            dispatch(getTaskAndCalendarSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const getTasksFilterAction = (val) => {
  return (dispatch) => {
    api.invoke(
      TASK_FILTER_URL + val.current_page,
      "post",
      (data, success, statusCode) => {
        if (success) {
          dispatch(loaderSlice(false));
          if (statusCode === 200) {
            dispatch(loaderSlice(true));
            if (val.viewType === "calendar") {
              dispatch(getTasksCalendarSlice(data));
            } else {
              dispatch(getTasksFilterSlice(data));
            }
          } else {
            dispatch(getTasksFilterSlice({ result: [] }));
            dispatch(loaderSlice(true));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};

export const postTaskAndCalendarAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      TASK_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);

            message.destroy();
            message.success(data.message, 3);
            // dispatch(getTasksAction());
            dispatch(getTasksFilterAction({ current_page: 1 }));

            dispatch(postTaskAndCalendarSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

export const deleteTaskAndCalendarAction = (id, link, callback) => {
  return (dispatch) => {
    api.invoke(
      link.link === 0 ? TASK_URL + "/" + id : INTERVIEW_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            message.destroy();
            message.success(data.message, 3);
            // dispatch(getTasksAction());
            // dispatch(getTasksFilterAction({ current_page: 1 }));
            dispatch(deleteTaskAndCalendarSlice(id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const putTaskAndCalendarAction = (val, callback) => {
  return (dispatch) => {
    api.invoke(
      val.link === 1 ? INTERVIEW_URL + "/" + val.id : TASK_URL + "/" + val.id,
      "put",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            // if (type === "grid") {
            //   dispatch(
            //     getTasksFilterAction({ current_page: val.current_page })
            //   );
            //   dispatch(getTasksAction());
            // } else if (type === "details")
            //   dispatch(getTaskAndCalendarAction(val.id));

            callback(true);
            message.destroy();
            message.success(data.message, 3);
            dispatch(putTaskAndCalendarSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      val
    );
  };
};
export const getFilterCalendarAction = (data) => {
  return (dispatch) => {
    api.invoke(
      TASK_CALENDAR_FILTER_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            // message.success(data.message, 3);
            dispatch(getCalendarFilterSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

// for fetching candidate related jobs candidate jobs

export const getCandidatesJobAction = (val) => {
  return (dispatch) => {
    api.invoke(
      FETCH_CANDIDATE_RELATED_JOBS_URL + "/" + val,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            dispatch(getCandidatesJobSlice(data));
          } else {
            dispatch(getCandidatesJobSlice({ result: [] }));
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};

//end of candidadte jobs

// for completed interview (interview_performance_rating)

export const postCompletedInterviewPerformanceAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      INTERVIEW_PERFORMANCE_RATIO,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);

            message.destroy();
            message.success(data.message, 3);
            // dispatch(getClientsAction());
            // dispatch(client_FilterAction({ current_page: 1 }));

            // dispatch(postClientSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
//export interview feedback
export const exportInterviewFeedbackAction = (data, callback) => {
  return (dispatch) => {
    api.invoke(
      EXPORT_INTERVIEW_FEEDBACK_URL,
      "post",
      (data, success, statusCode) => {
        callback(false);
        if (success) {
          if (statusCode === 200) {
            callback(true);
            window.open(data.result);
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
