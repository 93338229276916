import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import {
  BackButtonIcon,
  CloseIcon,
  FilterOutlinedIcon,
  SearchIcon,
} from "../../IconsComp/Icons";
import commonCssStyle from "../../UI/CommonCss.module.css";
import styles from "../../Screens/Dashboard/ContentPage/ContentPage.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCandidatesFilterAction } from "../../../Reusable/Store/Action/CandidatesAction";
import { connect } from "react-redux";
import commonStyleCss from "../../UI/CommonCss.module.css";
import Filter from "../AddCandidatesComp/candidateDetailsTable/Filter";
import { postAssignedEmployeeSuggestionAction } from "../../../Reusable/Store/Action/RecruitmentAction";
import { getCandidateAssignedToRecruitmentByIdAction } from "../../../Reusable/Store/Action/RecruitmentAction";
import BreadcrumbForRequirement from "../Requirement/BreadcrumbForRequirement";
import BreadcrumbForRecruitment from "./BreadcrumbForRecruitment";
import { getRecruitmentsSuggestedCandidatesFilterAction } from "../../../Reusable/Store/Action/RecruitmentAction";
import { useForm } from "antd/lib/form/Form";

function SuggestedCandidates(props) {
  const [dataSelected, setDataSelected] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [cfilter, setCFilter] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageNo, setPageNo] = useState("1");
  const [idForSkills, setIdForSkills] = useState("");
  const [form] = Form.useForm();
  const [tagsModalOpen, setTagsModalOpen] = useState(false);

  // const [tableData, setTableData] = useState([]);
  useEffect(() => {
    let unsubscribed = false;
    if (!unsubscribed) {
      props.getFectchCandidateHandler(location.state.id);
      props.getSuggestedCandidatesHandler({ jobId: location.state.id });
    }
    return () => {
      unsubscribed = true;
    };
  }, []);

  useEffect(() => {
    if (props.candidateAssignedRequirement) {
      setSelectedRowKeys(
        props.candidateAssignedRequirement.map((data) => {
          return data.id;
        })
      );
    }
  }, [props.candidateAssignedRequirement]);

  const closeTagModalHandler = () => {
    setTagsModalOpen(false);
  };

  const tagsColumn = [
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Skills
        </p>
      ),
      dataIndex: "",
      render: (text, render) => {
        return (
          <>
            <p
              className={`${commonCssStyle.tableData} ${commonStyleCss.marginBottomRemove}`}
            >
              {text ? text : ""}
            </p>
          </>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys, ...newSelectedRowKeys]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const submitData = () => {
    const val = {
      type: "pool",
      candidates: selectedRowKeys,
      jobId: location.state.id,
    };
    props.postAssignCandidateHandler(val, (success) => {
      if (success) {
        message.success("Candidates added successfully");
        form.setFieldsValue({
          search: "",
        });
        setSelectedRowKeys([]);
        props.getSuggestedCandidatesHandler({ jobId: location.state.id });
        props.getFectchCandidateHandler(location.state.id);
      }
    });
  };

  const onSearch = (val) => {
    const value = {
      keyword: val.target.value,
      jobId: location.state.id,
    };
    props.getSuggestedCandidatesHandler(value, (success) => {});
  };

  const clearData = () => {
    setSelectedRowKeys("");
    setDataSelected("");
    const val = {
      type: "pool",
      candidates: [],
      jobId: location.state.id,
    };
    props.postAssignCandidateHandler(val, (success) => {});
  };
  function showFilterHandler() {
    setOpenFilter(true);
  }
  const closeFilterHandler = () => {
    setOpenFilter(false);
  };

  const candidateFilterHandleer = (val) => {
    setCFilter(val);
    props.getCandidateFilterHandler(val, (success) => {
      if (success) {
        closeFilterHandler();
      }
    });
  };

  const columns = [
    {
      dataIndex: "id",
      render: (text, render) => (
        <>
          <Checkbox
            checked={selectedRowKeys.find((x) => x === text)}
            onChange={(val) => {
              if (val.target.checked) {
                setSelectedRowKeys([...selectedRowKeys, text]);
              } else {
                let index = selectedRowKeys.indexOf(text);
                if (index != -1) {
                  setSelectedRowKeys(
                    selectedRowKeys.filter((data) => data !== text)
                  );
                }
              }
            }}
          />
        </>
      ),
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Name
        </p>
      ),
      dataIndex: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Contact Number
        </p>
      ),
      dataIndex: "phone",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Skills
        </p>
      ),
      dataIndex: "tags",
      render: (data, render) => {
        return (
          <>
            {data && data.length > 1 ? (
              <p
                className={`${commonCssStyle.tableData}
          ${commonStyleCss.marginBottomRemove} ${commonCssStyle.coursorPointer}`}
                onClick={() => {
                  setIdForSkills(data);
                  setTagsModalOpen(true);
                }}
              >
                {data.length > 1 ? (
                  <> &#40; {"+" + data.length} &#41; </>
                ) : (
                  data
                )}
              </p>
            ) : (
              <p
                className={`${commonCssStyle.tableData}
          ${commonStyleCss.marginBottomRemove}`}
              >
                {data}
              </p>
            )}
          </>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Email id
        </p>
      ),
      dataIndex: "email",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData}
             ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
    {
      title: (
        <p
          className={`${commonStyleCss.marginBottomRemove} ${commonStyleCss.tableHeadingsColor}`}
        >
          Source
        </p>
      ),
      dataIndex: "leadSource",
      render: (data) => {
        return (
          <p
            className={`${commonCssStyle.tableData} ${commonStyleCss.marginBottomRemove}`}
          >
            {data ? data : ""}
          </p>
        );
      },
    },
  ];

  const handleProvinceChange = (e) => {
    if (e === "Name_ASC") {
      props.getCandidateFilterHandler({
        ...cfilter,
        sortBy: "name",
        current_page: 1,
        sortOrder: "ASC",
      });
      setCFilter({ ...cfilter, sortBy: "name", sortOrder: "ASC" });
    } else if (e === "Name_DESC") {
      props.getCandidateFilterHandler({
        ...cfilter,
        sortBy: "name",
        current_page: 1,
        sortOrder: "DESC",
      });
      setCFilter({
        ...cfilter,
        sortBy: "name",

        sortOrder: "DESC",
      });
    } else if (e === "Applied_ASC") {
      props.getCandidateFilterHandler({
        ...cfilter,
        sortBy: "appliedDate",
        current_page: 1,
        sortOrder: "ASC",
      });
      setCFilter({
        ...cfilter,
        sortBy: "appliedDate",

        sortOrder: "ASC",
      });
    } else if (e === "Applied_DESC") {
      props.getCandidateFilterHandler({
        ...cfilter,
        sortBy: "appliedDate",
        current_page: 1,
        sortOrder: "DESC",
      });
      setCFilter({
        ...cfilter,
        sortBy: "appliedDate",

        sortOrder: "DESC",
      });
    } else if (e === "Last_ASC") {
      props.getCandidateFilterHandler({
        ...cfilter,
        sortBy: "lastUpdated",
        current_page: 1,
        sortOrder: "ASC",
      });
      setCFilter({
        ...cfilter,
        sortBy: "lastUpdated",

        sortOrder: "ASC",
      });
    } else if (e === "Last_DESC") {
      props.getCandidateFilterHandler({
        ...cfilter,
        sortBy: "lastUpdated",
        current_page: 1,
        sortOrder: "DESC",
      });
      setCFilter({ ...cfilter, sortBy: "lastUpdated", sortOrder: "DESC" });
    }
  };

  const fetchRecords = (page) => {
    setPageNo(page);
    const val = {
      ...cfilter,
      ...props.candidatesFilterData,
      current_page: page,
    };
    props.getCandidateFilterHandler(val);
  };
  return (
    <>
      <Row className={commonCssStyle.breadcrumbBottomMargin}>
        <Col md={24}>
          {location.state.onPathname === "/JobDetailPage" ? (
            <BreadcrumbForRequirement
              name={location.state.name}
              slide={[1, 2, 3]}
            />
          ) : (
            <BreadcrumbForRecruitment
              name={location.state.name.title}
              recruitment={location.state}
              slide={[1, 2, 4]}
            />
          )}
        </Col>
      </Row>
      <Card style={{ padding: "10px 40px", marginBottom: "36px" }}>
        <Row align="middle">
          <Col md={24}>
            <Row align="middle" justify="space-between">
              <Col md={24}>
                <Row align="middle">
                  <Col md={1}>
                    <span
                      className={commonCssStyle.coursorPointer}
                      onClick={() => {
                        if (location.state.onPathname === "/JobDetailPage") {
                          navigate("/JobDetailPage", {
                            state: { id: location.state.id, activeTabKey: "4" },
                          });
                        } else {
                          navigate("/recruitment/recruitmentJobdetails", {
                            state: {
                              id: location.state.id,
                              // activeTabKey: "2",
                              activeTabKey: "addCandidate",
                              recuritmentJobDetailsName:
                                location.state.name.title,
                            },
                          });
                        }
                      }}
                    >
                      <BackButtonIcon color={"var(--icons-color)"} />
                    </span>
                  </Col>
                  <Col md={7}>
                    <h1
                      style={{ marginRight: "5px" }}
                      className={`${commonCssStyle.mainHeadingText} ${commonCssStyle.inttitle} ${commonCssStyle.marginBottomRemove}`}
                    >
                      Candidate List &#40;
                      {props.candidatesSuggested &&
                      props.candidatesSuggested.total
                        ? props.candidatesSuggested.total
                        : "0"}
                      &#41;
                    </h1>
                  </Col>
                  <Col md={10}>
                    <Form form={form} style={{ marginBottom: "-23px" }}>
                      <Form.Item name="search">
                        <Input.Search
                          id="Search-anything-bar"
                          placeholder="Search"
                          className={`searchBarPlaceHoderClr ${styles.inputField}`}
                          prefix={<SearchIcon color={"var(--primaryColor)"} />}
                          style={{
                            borderRadius: 50,
                            width: "486px",
                            height: "42px",
                            fontSize: "20px",
                          }}
                          allowClear={true}
                          onChange={onSearch}
                        />
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <Row>
        <Col md={24}>
          <Col style={{ marginTop: "20px" }} xs={24}>
            <Table
              scroll={{ x: "auto" }}
              // pagination={{
              //   size: "small",
              //   current:
              //     props.candidatesSuggested &&
              //     props.candidatesSuggested.current_page,
              //   pageSize:
              //     props.candidatesSuggested &&
              //     props.candidatesSuggested.per_page,
              //   total:
              //     props.candidatesSuggested &&
              //     props.candidatesSuggested.total,
              //     showSizeChanger:false,
              //   onChange: (props) => {
              //     fetchRecords(props);
              //   },
              // }}
              pagination={false}
              rowKey="id"
              columns={columns}
              dataSource={props.candidatesSuggested.data}
            />
          </Col>
        </Col>
        <Col flex={1}>
          <Row style={{ gap: "10px", float: "right", marginTop: "2%" }}>
            <Col>
              <Button
                type="primary"
                onClick={submitData}
                className={commonCssStyle.ButtonForNormalPrimary}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Filter
        candidateFilterHandleer={candidateFilterHandleer}
        onOpenFilter={openFilter}
        candidateFilterData={props.candidatesFilterData}
        onCloseFilter={closeFilterHandler}
        settindsData={props.settindsData}
      />

      <Modal
        title={
          <Row>
            <Col md={20}>
              <p
                className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.popUpHeadingText} ${commonCssStyle.textBreak}`}
              >
                Skills
              </p>
            </Col>
            <Col md={4}>
              <div
                style={{ float: "right" }}
                onClick={() => {
                  closeTagModalHandler();
                }}
                className={`${commonCssStyle.coursorPointer} `}
              >
                <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
              </div>
            </Col>
          </Row>
        }
        closable={false}
        open={tagsModalOpen}
        onCancel={closeTagModalHandler}
        footer={false}
      >
        <Table
          style={{ marginTop: "-20px" }}
          scroll={{ x: "auto" }}
          className="AssignRecruiterTable"
          columns={tagsColumn}
          dataSource={idForSkills && idForSkills}
          pagination={false}
        />
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    candidatesSuggested: state.recruitment.recruitment_candidates_suggestion,
    candidateAssignedRequirement:
      state.recruitment.candidateAssignedRequirement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //new details
    getSuggestedCandidatesHandler: (val, callback) => {
      dispatch(getRecruitmentsSuggestedCandidatesFilterAction(val, callback));
    },
    postAssignCandidateHandler: (val, callback) => {
      dispatch(postAssignedEmployeeSuggestionAction(val, callback));
    },
    getFectchCandidateHandler: (id) => {
      dispatch(getCandidateAssignedToRecruitmentByIdAction(id));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestedCandidates);
