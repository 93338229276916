import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Dashboard: [],
};

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getDashboardSlice(state, action) {
      state.Dashboard = action.payload.result;
    },
  },
});

export const { getDashboardSlice } = DashboardSlice.actions;
export default DashboardSlice.reducer;
