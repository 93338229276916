import { Form, Image, message, Progress } from "antd";
import { useRef } from "react";
import { useState } from "react";
import React from "react";
import { CloseIcon, DragOrUploadFile } from "../../IconsComp/Icons";
import commonCssStyle from "../../UI/CommonCss.module.css";
function UploadJD(props) {
  let btnref = useRef(null);
  const [progress, setProgress] = useState(0);
  const [filesData, setFilesData] = useState("");
  const [fileResult, setFileResult] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const handleChange = (info) => {
    setFileResult(false);

    if (!(info.target.files[0].size <= 5000000)) {
      message.warning("This file should be less than 5 MB", 3);
      //setProgress(0);
      return;
    }

    setFilesData(info.target.files);
    props.filesData(info.target.files);
    // if (info.target.files[0]) {
    //   setProgress(50);
    //   setTimeout(() => {
    //     setProgress(100);
    //   }, 300);
    // }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    if (e.dataTransfer.files.length > 5) {
      message.warning("At a time only 5 files can select", 3);
      //setProgress(0);
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFilesData(e.dataTransfer.files);
      props.filesData(e.dataTransfer.files);
      //   if (e.dataTransfer.files) {
      //     setProgress(50);
      //     setTimeout(() => {
      //       setProgress(100);
      //     }, 300);
      //   }
    }
  };
  return (
    <>
      <input
        accept="application/pdf,.docx"
        hidden
        type="file"
        name={["fileUpload"]}
        multiple
        onChange={handleChange}
        ref={(input) => {
          btnref = input;
        }}
      />
      {/* <Form.Item label="Upload Job Description" name="jdFile"> */}
      <p
        className={`${commonCssStyle.marginBottomRemove} ${commonCssStyle.formInputLabel}`}
      >
        Upload Job Description
      </p>
      {(!filesData && filesData.length === 0) || dragActive ? (
        <div
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          className={commonCssStyle.coursorPointer}
          style={{
            // backgroundColor: "var(--secondaryColor)",
            height: "155px",
            borderRadius: "6px",
            padding: "10px 0px",
          }}
        >
          <div
            onClick={(e) => {
              e.preventDefault();
              btnref.click();
              return false;
            }}
            style={{
              textAlign: "center",
              height: "155px",
              borderRadius: "6px",
              backgroundColor: "var(--input-color)",
              borderRadius: "5px",
            }}
          >
            <div>
              <p className="ant-upload-drag-icon">
                <DragOrUploadFile color={"var(--primaryColor)"} />
              </p>
              <p
                className="ant-upload-text"
                style={{ color: "var(--body-text-color)" }}
              >
                Upload from device
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            height: "155px",
            borderRadius: "6px",
            backgroundColor: "var(--input-color)",
            borderRadius: "5px",
          }}
        >
          <div>
            <Image
              onClick={(e) => {
                e.preventDefault();
                btnref.click();
                return false;
              }}
              preview={false}
              src={
                filesData[0].type === "application/pdf" ? props.pdf : props.word
              }
              style={{
                height: "118px",
                width: "100px",
                borderRadius: "6px",
                position: "relative",
                display: "block",
                margin: "16px auto 21px 21px",
              }}
            />
            <span
              onClick={() => {
                setFilesData("");
                props.filesData("");
                setFileResult(true);
              }}
              style={{
                position: "absolute",
                paddingTop: "5px",
                paddingLeft: "1px",
                cursor: "pointer",
              }}
            >
              <CloseIcon color={"var(--headings-color)"} />
            </span>
          </div>
        </div>
      )}
      {/* </Form.Item> */}
    </>
  );
}
export default React.memo(UploadJD);
