import {
  getCandidateReasonsSlice,
  getCandidateReasonSlice,
  postCandidateReasonsSlice,
  putCandidateReasonsSlice,
  deleteCandidateReasonsSlice,
} from "../../Slice/Master/MasterCandidateReasonSlice";
import { CANDIDATE_REASON_URL } from "../../masterpath";
import { message } from "antd";
import AxiosApi from "../../api";
const api = new AxiosApi();
export const getCandidateReasonsAction = () => {
  return (dispatch) => {
    api.invoke(CANDIDATE_REASON_URL, "get", (data, success, statusCode) => {
      if (success) {
        if (statusCode === 200) dispatch(getCandidateReasonsSlice(data));
        else {
          message.destroy();
          message.error(data.message, 3);
        }
      } else {
        message.destroy();
        message.error("Something went wrong", 3);
      }
    });
  };
};
export const getCandidateReasonAction = (id) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATE_REASON_URL + "/" + id,
      "get",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) dispatch(getCandidateReasonSlice(data));
          else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
export const postCandidateReasonsAction = (data) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATE_REASON_URL,
      "post",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(postCandidateReasonsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};

export const putCandidateReasonsAction = (data) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATE_REASON_URL + "/" + data.id,
      "put",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(putCandidateReasonsSlice(data));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      },
      data
    );
  };
};
export const deleteCandidateReasonsAction = (id) => {
  return (dispatch) => {
    api.invoke(
      CANDIDATE_REASON_URL + "/" + id,
      "delete",
      (data, success, statusCode) => {
        if (success) {
          if (statusCode === 200) {
            message.destroy();
            message.success(data.message, 3);
            dispatch(deleteCandidateReasonsSlice(id));
          } else {
            message.destroy();
            message.error(data.message, 3);
          }
        } else {
          message.destroy();
          message.error("Something went wrong", 3);
        }
      }
    );
  };
};
