import { Modal, Form, Input, Button, Row, Col } from "antd";
import styles from "../ProfileComp/Profile.module.css";
import commonCssStyle from "../../UI/CommonCss.module.css";
import { useForm } from "antd/lib/form/Form";
import { CloseIcon } from "../../IconsComp/Icons";

function JobModal(props) {
  const [form] = useForm();
  return (
    <Modal
      className="modalModifiedForTemplete"
      border
      footer={false}
      title={
        <Row>
          <Col md={20}>
            <h3 className={commonCssStyle.popUpHeadingText}>Edit Job</h3>
          </Col>
          <Col md={4}>
            <div
              style={{ float: "right" }}
              onClick={() => {
                props.onClose();
              }}
              className={`${commonCssStyle.coursorPointer} `}
            >
              <CloseIcon color={"var(--body-text-color)" || "var(--theme)"} />
            </div>
          </Col>
        </Row>
      }
      open={props.onOpen}
      closable={false}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(val) =>
          props.updatedJobIngo(
            { ...val, workLocationId: props.profile.workLocationId },
            (success) => {
              if (success) props.onClose();
            }
          )
        }
        initialValues={{
          designation: props.profile.designation,
        }}
      >
        <Row>
          <Col md={24}>
            <Row style={{ padding: "0px 15px" }}>
              <Col md={24}>
                <Form.Item name="designation" label="Designation">
                  <Input placeholder="Enter Designation" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col
            xs={24}
            md={24}
            className={commonCssStyle.footerShadowForTemplete}
          >
            <Row
              justify="end"
              style={{
                position: "relative",
                gap: "10px",
              }}
            >
              <Col>
                <Button
                  className={commonCssStyle.buttonSettingsSecondary}
                  onClick={() => {
                    props.onClose();
                  }}
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  className={commonCssStyle.buttonSettingsPrimary}
                  htmlType="button"
                  onClick={() => {
                    form.submit();
                  }}
                  style={{
                    width: "150px",
                    height: "48px",
                  }}
                  type="primary"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export default JobModal;
